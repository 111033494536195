/* eslint-disable no-console, import/no-extraneous-dependencies */
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import {
  BatchSpanProcessor,
  TracerConfig,
  WebTracerProvider
} from '@opentelemetry/sdk-trace-web';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import {
  ATTR_SERVICE_NAME,
  ATTR_TELEMETRY_SDK_LANGUAGE,
  TELEMETRY_SDK_LANGUAGE_VALUE_WEBJS,
} from '@opentelemetry/semantic-conventions';
import { Resource } from '@opentelemetry/resources';
import {
  BatchLogRecordProcessor,
  LoggerProvider,
} from '@opentelemetry/sdk-logs';
import { OTLPLogExporter } from '@opentelemetry/exporter-logs-otlp-http';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { useDispatch } from 'react-redux';
import { Logger } from '@opentelemetry/api-logs';
import { useEffect, useState } from 'react';
import config from 'react-global-configuration';
import { store } from '../Main';
import { ReduxState } from '../store';
import { setTelemetryLogger } from '../store/actions/telemetryActions';

export interface TracerResult {
  logger: Logger | undefined;
}

async function Tracer(): Promise<Logger> {
  const { ZoneContextManager } = await import('@opentelemetry/context-zone');

  const resource = new Resource({
    [ATTR_SERVICE_NAME]: 'GeotraxV2_Web',
    [ATTR_TELEMETRY_SDK_LANGUAGE]: TELEMETRY_SDK_LANGUAGE_VALUE_WEBJS,
  });

  const providerConfig: TracerConfig = { resource };
  const logExporter: OTLPLogExporter = createLogExporter();
  const traceExporter: OTLPTraceExporter = createTraceExporter();
  const loggerProvider: LoggerProvider = new LoggerProvider({ resource });
  loggerProvider.addLogRecordProcessor(
    new BatchLogRecordProcessor(logExporter, { scheduledDelayMillis: 1000 })
  );

  const appLogger = loggerProvider.getLogger(
    'geotrax-web-logger',
    process.env.REACT_APP_VERSION || '0.0.0'
  );

  providerConfig.spanProcessors = [
    new BatchSpanProcessor(traceExporter, { scheduledDelayMillis: 1000 }),
  ];
  const provider = new WebTracerProvider(providerConfig);

  provider.register({
    contextManager: new ZoneContextManager(),
  });

  registerInstrumentations({
    instrumentations: [
      // getWebAutoInstrumentations initializes all the package.
      // it's possible to configure each instrumentation if needed.
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-fetch': {
          propagateTraceHeaderCorsUrls: /.*/,
          clearTimingResources: true,
          applyCustomAttributesOnSpan: (span) => {
            const details = extractStoreDetailsForTelemetry();
            applyDetailsToSpan(span, details);
          },
          // config can be added here for example
          // we can initialize the instrumentation only for prod
          // enabled: import.meta.env.PROD,
        },
      }),
    ],
  });
  return appLogger;
}

export const useTracer = (): TracerResult => {
  const [logger, setLogger] = useState<Logger | undefined>(undefined);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!logger) {
      Tracer()
        .then((result) => {
          if (result) {
            dispatch(setTelemetryLogger(result));
          }
        })
        .catch((reason) => console.error(`useTracer: Encountered an error - failed to setup tracer ${reason}`))
        .finally(() => setLogger(undefined));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logger]);

  return { logger };
};

function createLogExporter(): OTLPLogExporter {
  return new OTLPLogExporter({
    url: config.get('OPEN_TELEMETRY_LOGS_URL'),
    headers: {
      'api-key': config.get('OPEN_TELEMETRY_API_KEY'),
    },
  });
}

function createTraceExporter(): OTLPTraceExporter {
  return new OTLPTraceExporter({
    url: config.get('OPEN_TELEMETRY_TRACES_URL'),
    headers: {
      'api-key': config.get('OPEN_TELEMETRY_API_KEY'),
    },
  });
}

export function extractStoreDetailsForTelemetry(): StoreTelemetryDetailsDto {
  const currentState = store.getState() as ReduxState;
  return {
    appName: valueToString(currentState?.currentAppName),
    userUrl: valueToString(currentState?.userUrl),
    tenantUrl: valueToString(currentState?.tenantId),
    authTenantName: valueToString(currentState?.authTenantName),
    primaryProductLine: valueToString(currentState?.primaryProductLine),
    selectedProductLine: valueToString(currentState?.selectedProductLine),
    page: valueToString(((currentState?.geotraxPage ?? {}) as any).navRoute),
    region: valueToString(currentState?.currentRegion?.url),
  };
}

function valueToString(
  value: Nullish<string | number>,
  defaultValue = undefined
): Nullish<string> {
  if (value) {
    return `${value}`;
  }
  return defaultValue;
}

export type Nullish<T> = T | undefined | null;

export type StoreTelemetryDetailsDto = {
  appName: Nullish<string>;
  userUrl: Nullish<string>;
  tenantUrl: Nullish<string>;
  authTenantName: Nullish<string>;
  primaryProductLine: Nullish<string>;
  selectedProductLine: Nullish<string>;
  region: Nullish<string>;
  page: Nullish<any>;
};

export function applyDetailsToSpan(span: any, details: any): void {
  const keys: string[] = Object.keys(details);
  keys.forEach((key: string) => {
    span.setAttribute(key, details[key]);
  });
}
