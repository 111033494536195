import { DriverDto, PagedEntityDto } from '@trucktrax/trucktrax-ts-common';
import { replaceOrUpdateListById } from '../../util/adminUtil';
import {
  ADD_DRIVER, FETCH_DRIVER, SET_DRIVER_LIST, UPDATE_DRIVER,
} from '../../constants/actionConstants';
import { PayloadAction } from '../../types';

interface DriversState {
  drivers: DriverDto[]
  pageInfo?: {
    pageCount: number;
    pageStart: number;
    pageSize: number;
  }
}

export const driversReducer = (
  state: DriversState = { drivers: [] },
  action: PayloadAction<PagedEntityDto<DriverDto> | DriverDto, 'SET_DRIVER_LIST' | 'ADD_DRIVER' | 'UPDATE_DRIVER'>
): DriversState => {
  const { pageCount, pageStart, pageSize } = (action.payload as PagedEntityDto<DriverDto>) || {};
  switch (action.type) {
    case SET_DRIVER_LIST:
      return {
        ...state,
        drivers: (action.payload as PagedEntityDto<DriverDto>).items!,
        pageInfo: {
          pageCount,
          pageStart,
          pageSize,
        },
      };
    case ADD_DRIVER:
      return {
        ...state,
        drivers: [...state.drivers, action.payload as DriverDto],
      };
    case UPDATE_DRIVER: {
      const { drivers } = state;
      replaceOrUpdateListById(action.payload as DriverDto, drivers);
      return state;
    }
    default:
      return state;
  }
};

export const currentDriverReducer = (state = {}, action: PayloadAction<DriverDto, 'FETCH_DRIVER'>) => {
  switch (action.type) {
    case FETCH_DRIVER:
      return {
        ...state,
        driver: action.payload,
      };
    default:
      return state;
  }
};
