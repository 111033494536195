export enum MessageTabs {
  Inbox = 'inbox',
  Sent = 'sent',
  New = 'new'
}

const constants = {
  // Icon for message center
  THUMB_UP_ICON_STRING: '[ACK+1]',

  MESSAGES_TABS_INBOX: 'inbox',
  MESSAGES_TABS_SENT: 'sent',
  MESSAGES_TABS_NEW: 'new',
  NEW_PLACEHOLDER: 'Type a message...',
  INBOX_PLACEHOLDER: 'Type a reply...',
  LOGOUT_DRIVER: 'Log Out Driver',
  FROM: 'from',
  TO: 'to',
  DISPATCH_COMMA: 'Dispatch, ',
  DISPATCH: ', Dispatch',
  USERS: 'users',

  CANCEL_LABEL: 'Cancel',
  DISCARD_LABEL: 'Discard Changes',
  SEND_LABEL: 'Send',
  READY_MIX_VALUE: 'ReadyMix',
  READY_MIX_STRING: 'Ready-mix',
  MESSAGE_ALL_PLANTS_DRIVERS: 'Message All Plant\'s Drivers',
};

export default constants;
