import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { ExpandMore } from '@mui/icons-material';
import { Checkbox, CheckboxProps } from '@mui/material';
import { RouteDto } from '@trucktrax/trucktrax-ts-common';
import { vars } from '@trucktrax/trucktrax-common';
import styles from './RouteHistoryPanelSearchResults.module.css';
import { ConnectedDispatchFunction } from '../../../types';
import {
  clearRouteHistoryList, selectRouteHistoryList, setRouteHistoryHoverSelection,
}
  from '../../../services/routeHistoryService';
import { ReduxState } from '../../../store';
import { productLineOptions } from '../../../util/adminUtil';
import {
  setRouteHistoryProductLines,
} from '../../../store/actions/productLineActions';
import { getDeactivationType, getProductLinesResult } from '../../../util/routeHistoryUtil';

const sortIcon = (<div className={styles.sort}><ExpandMore /></div>);
/* istanbul ignore next */
const cellText = (value: any) => ((value)
  ? (<div data-tag="allowRowEvents">{value}</div>)
  : (<div className={styles.notApplicable} data-tag="allowRowEvents">N/A</div>));
/* istanbul ignore next */
const cellTruck = (value: any, truckDeactivated: any, driverDeactivated: any) => (
  <div data-tag="allowRowEvents" className={styles.cellTruck}>
    <div className={styles.truckText}>{value}</div>
    {(truckDeactivated || driverDeactivated)
      && <div className={styles.deactivatedText}>{getDeactivationType(truckDeactivated, driverDeactivated)}</div>}
  </div>
);
const columns = [
  {
    name: 'Truck',
    // return type must be 'any' or it won't compile
    selector: (r: RouteDto): any => r.truck.alias,
    sortable: true,
    cell: (r: RouteDto) => cellTruck(r.truck.alias, r.truck.deactivated, r.truck.driverDeactivated),
    width: '18rem'
  },
  {
    name: 'Order',
    selector: (r: RouteDto): any => r.order?.orderNumber,
    sortable: true,
    cell: (r: RouteDto) => cellText(r.order?.orderNumber),
    width: '9rem'
  },
  {
    name: 'Ticket #',
    selector: (r: RouteDto): any => r.ticket?.ticketNumber,
    sortable: true,
    cell: (r: RouteDto) => cellText(r.ticket?.ticketNumber),
    wdith: '10rem'
  },
];
/* istanbul ignore next */
const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold', color: '#151f28', fontSize: '12px',
    },
  },
  rows: {
    style: {
      background: vars.gray100,
      borderBottomColor: '#ffffff !important',
      borderBottomWidth: '5px !important',
      cursor: 'pointer',
    },
    highlightOnHoverStyle: {
      backgroundColor: vars.gray200,
    },
  },
};

const ListCheckbox = (props: CheckboxProps) => {
  // set the unchecked style as default
  let checkStyle = styles.checkboxUnchecked;
  // if the checkbox is the header and the value is indeterminate or is checked set the checked style.
  if ((props.name === 'select-all-rows' && props.indeterminate) || props.checked) {
    checkStyle = styles.checkboxChecked;
  }
  // return the checkbox with the proper class
  return (<Checkbox {...props} size="small" color="primary" className={checkStyle} />);
};

const NoData = () => (
  <div>
    <p>Your search returned no results.</p>
  </div>
);

export class RouteHistoryPanelSearchResults extends Component<RouteHistoryPanelSearchResultsProps> {
  static isIndeterminate = (indeterminate: boolean) => indeterminate;

  selectableRowsProps = { indeterminate: RouteHistoryPanelSearchResults.isIndeterminate };

  rowIsSelected = (route: RouteDto) => !!this.props.selectedRouteHistoryList?.includes(route.id);

  handleRowMouseEnter = (route: RouteDto) => {
    if (this.rowIsSelected(route)) {
      this.props.setRouteHistoryHoverSelection(route);
    }
  };

  handleRowMouseLeave = () => this.props.setRouteHistoryHoverSelection(null);

  handleSelectedRowsChange = ({ selectedRows }: RowChangedEvent) => {
    const rowIds = selectedRows.map(row => row.id);
    const productLines = getProductLinesResult(selectedRows, productLineOptions.length);
    this.props.setRouteHistoryProductLines(productLines);
    this.props.selectRouteHistoryList(rowIds);
  };

  render() {
    const { routeHistoryList } = this.props;
    return routeHistoryList && (
      <div>
        <div className={styles.resultCount}>
          <span className={styles.smallFont}>
            {`${routeHistoryList.length} routes found.`}
          </span>
          <button
            className={styles.textButton}
            onClick={this.props.clearRouteHistoryList}
          >
            Clear Search Results
          </button>
        </div>
        <div className={styles.floatClear} />
        <hr />
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={routeHistoryList}
          defaultSortFieldId={1}
          dense
          highlightOnHover
          noDataComponent={<NoData />}
          responsive={false}
          selectableRows
          selectableRowsComponent={ListCheckbox as unknown as ReactNode}
          selectableRowsComponentProps={this.selectableRowsProps}
          selectableRowSelected={this.rowIsSelected}
          sortIcon={sortIcon}
          onRowMouseEnter={this.handleRowMouseEnter}
          onRowMouseLeave={this.handleRowMouseLeave}
          onSelectedRowsChange={this.handleSelectedRowsChange}
        />
      </div>
    );
  }
}

type OwnProps = {};

export function mapStateToProps(state: ReduxState) {
  return {
    routeHistoryList: state.routeHistoryList,
    selectedRouteHistoryList: state.selectedRouteHistoryList,
  };
}
type DispatchProps = {
  clearRouteHistoryList: ConnectedDispatchFunction<typeof clearRouteHistoryList>;
  selectRouteHistoryList: ConnectedDispatchFunction<typeof selectRouteHistoryList>;
  setRouteHistoryHoverSelection: ConnectedDispatchFunction<typeof setRouteHistoryHoverSelection>;
  setRouteHistoryProductLines: ConnectedDispatchFunction<typeof setRouteHistoryProductLines>;
};
type ReduxProps = ReturnType<typeof mapStateToProps>;

export type RowChangedEvent = {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: RouteDto[];
};

export default connect(mapStateToProps, {
  clearRouteHistoryList,
  selectRouteHistoryList,
  setRouteHistoryHoverSelection,
  setRouteHistoryProductLines,
})(RouteHistoryPanelSearchResults);

export type RouteHistoryPanelSearchResultsProps = OwnProps & DispatchProps & ReduxProps;
