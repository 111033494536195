import { CombinedProductsDto } from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import { FETCH_PRODUCTS } from '../../constants/actionConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const productsReducer = (
  state = { products: {} as CombinedProductsDto },
  action: PayloadAction<CombinedProductsDto, 'FETCH_PRODUCTS'>
) => {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    default:
      return state;
  }
};
