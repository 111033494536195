import React, { Component } from 'react';
import deburr from 'lodash/deburr';
import cx from 'classnames';
import Downshift from 'downshift';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import styles from './Autocomplete.module.css';

class Autocomplete extends Component<AutocompleteProps> {
  static defaultProps = {
    dataTest: '',
    hintText: '',
    searchText: '',
    errorText: '',
    dataSource: [],
    dataSourceConfig: { text: 'text', value: 'value' },
    clearSearch: () => { },
    fullWidth: false,
    showClearIcon: true,
    classes: {},
    segmented: false,
    itemToString: (i: any) => (i === null ? '' : String(i)),
    responsivePlaceholder: false,
    showAll: false,
    maxLength: -1,
  };

  state: AutocompleteState = {
    inputValue: this.props.searchText,
    autocompleteHeight: this.props.autocompleteHeight,
    selectedItem: '',
    errorText: this.props.errorText,
    reset: undefined,
  };

  /*
  *  TODO: We will need to spend more time/effort in the future updating these.
  *  componentWillMount is deprecated since React 16.9.0,
  *  use UNSAFE_componentWillMount instead,
  *  see https://reactjs.org/docs/react-component.html#unsafe_componentwillmount.
  *  Use https://github.com/reactjs/react-codemod#rename-unsafe-lifecycles
  *  to automatically update your components
  */
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleAnyClick, false);
  }

  componentDidUpdate(prevProps: AutocompleteProps) {
    if (this.props.searchText !== prevProps.searchText) {
      this.setState({
        inputValue: this.props.searchText,
      });
    }
    if (this.props.autocompleteHeight !== prevProps.autocompleteHeight) {
      this.setState({
        autocompleteHeight: this.props.autocompleteHeight,
      });
    }
    if (this.props.errorText !== prevProps.errorText) {
      this.setState({
        errorText: this.props.errorText,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleAnyClick, false);
  }

  getSuggestions = (value: string) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    const suggestions = this.props.dataSource!;

    if (this.props.showAll && inputLength === 0) {
      return suggestions.slice(0, 5);
    }
    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
        const s = suggestion[this.props.dataSourceConfig!.text].toLowerCase();
        const keep = count < 5 && s && s.includes(inputValue);

        if (keep) {
          count += 1;
        }

        return keep;
      });
  };

  clearSearch = () => {
    if (this.state.reset) {
      const resetFunc = this.state.reset || (() => { });
      resetFunc();
    }

    if (this.props.clearSearch) {
      this.props.clearSearch();
    }

    this.setState({ inputValue: '' });
  };

  handleChange = (object: any) => {
    if (!object) {
      return;
    }

    const resetFunc = this.state.reset || (() => { });
    this.props.onNewRequest(object, resetFunc);
  };

  handleAnyClick = () => {
    this.setState({ errorText: '' });
  };

  handleInputChange = (event: SimpleEventHandler) => {
    this.props.onUpdateInput(event.target.value);
  };

  handleKeyDown = (event: SimpleEventHandler, reset: VoidFunc, closeMenu: VoidFunc) => {
    if (event.key === 'Enter') {
      closeMenu();
      this.props.onNewRequest(event.target.value, reset);
    }
    if (!this.state.reset) {
      this.setState({ reset });
    }
  };

  errorStyle: React.CSSProperties = {
    borderRadius: '3px',
    border: '1px solid #e9edf0',
    height: '60px',
    position: 'absolute',
    zIndex: 5000,
    fontSize: '16px',
    width: '255px',
    lineHeight: '60px',
    paddingBottom: '60px',
    paddingLeft: '16px',
    color: 'gray',
    backgroundColor: 'white',
  };

  renderSuggestion = (suggestionProps: any) => {
    const {
      suggestion, index, itemProps, highlightedIndex, selectedItem,
    } = suggestionProps;
    const dataSourceConfig = this.props.dataSourceConfig!;

    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion[dataSourceConfig.text]) > -1;
    const key = index
      ? `${suggestion[dataSourceConfig.text]}-${index}`
      : `${suggestion[dataSourceConfig.text]}`;
    const hasLabelAndIcon = dataSourceConfig.icon && dataSourceConfig.label;

    return (
      <MenuItem
        {...itemProps}
        key={key}
        selected={isHighlighted}
        component="div"
        className={styles.menuItem}
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {hasLabelAndIcon
          ? (
            <div className="flex-vertical-center">
              <div className={cx(styles.category, 'margin-right-5', 'flex-vertical-center')}>
                <i className={cx(suggestion[dataSourceConfig.icon!], 'margin-right-10')} />
                <span>{suggestion[dataSourceConfig.label!]}</span>
              </div>
              <div className="txt-bold flex-vertical-center">
                <span>{suggestion[dataSourceConfig.text]}</span>
              </div>
            </div>
          )
          : (
            <div>
              {suggestion[dataSourceConfig.text]}
            </div>
          )}
      </MenuItem>
    );
  };

  renderInput = (inputProps: any) => {
    const {
      InputProps, classes, ...other
    } = inputProps;

    return (
      <TextField
        data-test={this.props.dataTest}
        InputProps={{
          classes: {
            root: styles.inputRoot,
            input: styles.inputInput,
            focused: styles.cssFocused,
            notchedOutline: styles.notchedOutline,
          },
          ...InputProps,
        }}
        variant="outlined"
        {...other}
      />
    );
  };

  onInputValueChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  render() {
    const { classes, itemToString, responsivePlaceholder } = this.props;
    const menuStyle = {
      maxHeight: this.state.autocompleteHeight,
    };

    return (
      <div className={cx(
        'tt-search',
        responsivePlaceholder && 'tt-search--hide-placeholder'
      )}
      >
        <Downshift
          onChange={this.handleChange}
          onInputValueChange={this.onInputValueChange}
          selectedItem={this.state.selectedItem}
          inputValue={this.state.inputValue}
          itemToString={(item) => (itemToString!(item) || this.state.inputValue || '')}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
            reset,
            closeMenu,
            openMenu,
          }) => (
            <div className={styles.container}>
              {this.renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  className: styles.inputBase,
                  inputProps: {
                    className: cx(
                      'tt-input',
                      this.props.segmented ? styles.segmented : null
                    ),
                    maxLength: this.props.maxLength,
                  },
                  placeholder: this.props.hintText,
                  // Chrome tries to infer autofill and ignores autocomplete='off'
                  // a random name and autocomplete='new-password' makes it harder to autofill
                  name: String(Math.random()),
                  autoComplete: 'new-password',
                  onKeyDown: (event: any) => this.handleKeyDown(event, reset, closeMenu),
                  onChange: this.handleInputChange,
                  onFocus: openMenu,
                }),
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper style={menuStyle} className={styles.popover} square>
                    {this.getSuggestions(inputValue!)
                      .map((suggestion, index) => this.renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: suggestion,
                        }),
                        highlightedIndex,
                        selectedItem,
                      }))}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
        <div hidden={!this.state.errorText} style={this.errorStyle}>{this.props.errorText}</div>
        <i
          aria-hidden="true"
          className="icon-search"
        />
        {this.props.showClearIcon ? (
          <i
            aria-hidden="true"
            onClick={this.clearSearch}
            hidden={this.state.inputValue!.length === 0}
            className={cx('icon-cancel', styles.clearIcon)}
          />
        ) : null}
      </div>
    );
  }
}

export default Autocomplete;

export interface AutocompleteProps {
  dataTest?: string;
  /** placeholder text */
  hintText?: string;
  searchText?: string;
  /** will display error if other than empty string */
  errorText?: string;
  onUpdateInput: (value: any) => void;
  onNewRequest: (value: any, reset: VoidFunc) => void;
  dataSource?: Array<any>;
  /** an object to indicate the mapping of the date to text and value */
  dataSourceConfig?: {
    text: string,
    value: string,
    icon?: string,
    label?: string
  },
  autocompleteHeight: string;
  openOnFocus: boolean;
  fullWidth?: boolean;
  clearSearch?: VoidFunc;
  showClearIcon?: boolean;
  classes?: {};
  segmented?: boolean;
  itemToString?: (item: any) => string;
  responsivePlaceholder?: boolean;
  showAll: boolean;
  maxLength: number;
}

export interface AutocompleteState {
  inputValue?: string;
  autocompleteHeight: string;
  selectedItem: string;
  errorText?: string;
  reset?: VoidFunc | undefined;
}

interface SimpleEventHandler {
  target: {
    value: string
  },
  key: string
}
