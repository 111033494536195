import { PlantDto } from '@trucktrax/trucktrax-ts-common';
import { replaceOrUpdateListById } from '../../util/adminUtil';
import {
  ADD_PLANT,
  SET_CURRENT_PLANT,
  SET_PLANT_LIST,
  UPDATE_PLANT,
} from '../../constants/actionConstants';
import { PayloadAction } from '../../types';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const plantList = (
  state = [] as PlantDto[],
  action: PayloadAction<PlantDto | PlantDto[], 'SET_PLANT_LIST' | 'ADD_PLANT' | 'UPDATE_PLANT'>
): PlantDto[] => {
  switch (action.type) {
    case SET_PLANT_LIST:
      return action.payload as PlantDto[];
    case ADD_PLANT:
      return [...state, action.payload] as PlantDto[];
    case UPDATE_PLANT: {
      replaceOrUpdateListById(action.payload as PlantDto, state);
      return state;
    }
    default:
      return state;
  }
};

export const currentPlant = (
  state: PlantDto | null = null,
  action: PayloadAction<PlantDto, 'SET_CURRENT_PLANT'>
): PlantDto | null => {
  switch (action.type) {
    case SET_CURRENT_PLANT:
      return action.payload as PlantDto;
    default:
      return state;
  }
};
