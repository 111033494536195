// /* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import config from 'react-global-configuration';
import cx from 'classnames';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { PermissionAccess } from '@trucktrax/trucktrax-ts-common';
import styles from './PermissionsTable.module.css';
import { accessLevelListForPermission, MergedPermissionItem } from '../../../util/permissionUtil';
import { noop } from '../../../util/appUtil';
import PermissionsAccessDropDownList from './PermissionsAccessDropDownList';
import { HIDDEN_PERMISSIONS, DISPLAY_ENV_KEY } from '../../../constants/appConstants';

type TableRow = MergedPermissionItem;
type PermissionDropdownItem = {
  value: {
    access: PermissionAccess;
  }
};
export interface PermissionTableState {
  tableData: TableRow[];
}

export default class PermissionsTable extends Component<PermissionsTableProps, PermissionTableState> {
  static defaultProps: Partial<PermissionsTableProps> = {
    onDropDownChange: noop,
    isDisabled: false,
    isPending: false,
  };

  constructor(props: PermissionsTableProps) {
    super(props);

    this.state = {
      tableData: [],
    };
  }

  static getDerivedStateFromProps(props: PermissionsTableProps) {
    // sort permission list
    const tableData = props.tableData && props.tableData.length > 0
      ? props.tableData.sort((p1, p2) => p1.permission.name.localeCompare(p2.permission.name))
      : [];
    return { tableData };
  }

  updateDropDownChange = (item: TableRow, index: number, newAccess: PermissionDropdownItem) => {
    // update permission to newly selected access.
    if (item && this.state.tableData[index].access !== newAccess.value.access) {
      const updatedData = this.state.tableData.map(data => {
        if (data.permission.id === item.permission.id) {
          data.access = newAccess.value.access;
        }
        return data;
      });

      this.props.onDropDownChange!(item, updatedData as any);
      this.setState({
        tableData: updatedData,
      });
    }
  };

  render() {
    const env = (config.get(DISPLAY_ENV_KEY) || '').toUpperCase();
    const showHidden = (env === 'DEV' || env === 'LOCAL');
    return (
      <Table>
        <TableHead className={styles.headerContainer}>
          <TableRow className={styles.headerRow}>
            <TableCell className={styles.headerStyle}>
              <span className={cx('txt-bold')}>Description</span>
            </TableCell>
            <TableCell className={cx(styles.headerStyle, styles.headerAccessMargin)}>
              <span className={cx('txt-bold')}>Access</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.tableData.length > 0 && !this.props.isPending
            ? this.state.tableData.map((row, index) => {
              let access = row.access
                ? row.access.toUpperCase()
                : PermissionAccess.Deny.toUpperCase();

              // if a permission was previously set to a level it shouldn't have been,
              // (e.g. View for a Permission like Passswords where View isn't an option)
              // set it to Deny instead
              const rowAccessLevels = row.permission.possibleAccessLevels?.map(p => p.toString().toUpperCase()) ?? [];
              if (rowAccessLevels.indexOf(access) === -1) {
                access = PermissionAccess.Deny.toUpperCase();
              }

              const initialSelected = {
                icon: 'icon-check',
                label: access,
              };

              return (
                row.permission.name && (showHidden || !HIDDEN_PERMISSIONS.includes(row.permission.name)) && (
                  <TableRow
                    key={row.permission.id}
                    className={cx(styles.row)}
                  >
                    <TableCell
                      className={styles.borderLeft}
                      padding="none"
                      data-test="permission-name-cell"
                    >
                      <span className={styles.cellText}>
                        <span>{row.permission.name}</span>
                      </span>
                    </TableCell>
                    <TableCell className={styles.borderRight} padding="none">
                      <div className={styles.dropDownListContainer} data-test="permission-drop-down-menu">
                        <PermissionsAccessDropDownList
                          items={accessLevelListForPermission(row.permission)}
                          initialSelected={initialSelected}
                          updateSelected={(newAccess: PermissionDropdownItem) => this.updateDropDownChange(row, index, newAccess)}
                          isDisabled={this.props.isDisabled}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )
              );
            })
            : (
              <TableRow>
                <TableCell>
                  <div className={styles.emptyState}>
                    <div className={styles.circleLoader} />
                  </div>
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    );
  }
}

export interface PermissionsTableProps {
  tableData: any[];
  onDropDownChange?: (item: TableRow, data: TableRow & { access: PermissionAccess }) => void;
  isDisabled?: boolean;
  isPending?: boolean;
}
