/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OrderDto, ProductLine } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import DataTableContent from '../../../shared/DataTableContent';
import {
  addDataTest, addHeaderDataTest, findKeyWithUrl, getOrdersUrl,
} from '../../../../util/adminUtil';
import { getPlantList } from '../../../../services/plantsService';
import { devErrorAndLog } from '../../../../util/errorUtil';
import { ERROR_TEXT_FETCH_PLANT_LIST } from '../../../../constants/errorConstants';
import {
  ADMIN_KEYS, ADMIN_LABELS, NOT_AVAILABLE, READYMIX_STRING,
  READYMIX_VALUE,
} from '../../../../constants/appConstants';
import { ReduxState } from '../../../../store';
import { ConnectedDispatchFunction } from '../../../../types';
import { setDateRange } from '../../../../store/actions/dataTableActions';
import { formatOrderScheduledTime } from '../../../../util/orderUtil';
import HTTP_CODES from '../../../../constants/httpConstants';
import { setFavicon } from '../../../../util/appUtil';
import { DATASOURCE } from '../../../../constants/apiConstants';

export class OrdersView extends Component<OrdersViewProps> {
  state = {
    plantList: [],
  };

  baseUrl = getOrdersUrl('');

  getColumns = () => ([
    {
      Header: addHeaderDataTest(null, 'sort-order-by-number', ADMIN_LABELS.ORDER_NUMBER),
      accessor: ADMIN_KEYS.ORDER_NUMBER,
      minWidth: 75,
      style: {
        fontWeight: 'bold',
      },
      Cell: (props: { value: any; }) => addDataTest(null, 'admin-order-number', props.value),
    },
    {
      Header: addHeaderDataTest(null, 'sort-by-sched-date', ADMIN_LABELS.SCHEDULE_DATE),
      id: ADMIN_KEYS.SCHEDULED_TIME,
      accessor: (row: OrderDto) => formatOrderScheduledTime(row),
      minWidth: 100,
      Cell: (props: { value: any; }) => addDataTest(null, 'admin-sched-date', new DateWrapper(props.value).toUSDateString()),
    },
    {
      Header: addHeaderDataTest(null, 'sort-order-by-id', ADMIN_LABELS.ID),
      accessor: ADMIN_KEYS.EXTERNAL_ID,
      minWidth: 90,
      Cell: (props: { value: any; }) => addDataTest(null, 'admin-order-id', props.value),
    },
    {
      Header: ADMIN_LABELS.PLANT,
      id: ADMIN_KEYS.PLANT,
      accessor: ADMIN_KEYS.PLANT,
      minWidth: 160,
      Cell: (props: { value: any; }) => addDataTest(
        null,
        'admin-order-plant',
        props.value ? this.plantUrlAccessor(props.value) : ''
      ),
    },
    {
      Header: addHeaderDataTest(null, 'sort-by-order-customer-name', ADMIN_LABELS.CUSTOMER_NAME),
      accessor: ADMIN_KEYS.CUSTOMER_NAME,
      minWidth: 160,
      Cell: (props: { value: any; }) => addDataTest(null, 'admin-order-customer-name', props.value),
    },
    {
      Header: addHeaderDataTest(null, 'sort-by-order-customer-name', ADMIN_LABELS.PROJECT_NAME),
      accessor: ADMIN_KEYS.PROJECT_NAME,
      minWidth: 160,
      Cell: (props: { value: any; }) => addDataTest(null, 'admin-order-project-name', props.value),
    },
    {
      Header: addHeaderDataTest(null, 'sort-by-address', ADMIN_LABELS.DELIVERY_ADDRESS),
      id: 'addressLineOne',
      accessor: (row: any) => OrdersView.formatAddress(row),
      minWidth: 160,
      Cell: (props: { value: any; }) => addDataTest(null, 'admin-order-address', props.value),
    },
    {
      Header: addHeaderDataTest(null, 'sort-by-city', ADMIN_LABELS.CITY),
      accessor: ADMIN_KEYS.CITY,
      minWidth: 100,
      Cell: (props: { value: any; }) => addDataTest(null, 'admin-order-city', props.value),
    },
    {
      Header: addHeaderDataTest(null, 'sort-by-state', ADMIN_LABELS.STATE),
      accessor: ADMIN_KEYS.STATE,
      minWidth: 50,
      Cell: (props: { value: any; }) => addDataTest(null, 'orders-state', props.value),
    },
    {
      Header: addHeaderDataTest(null, 'sort-by-zip', ADMIN_LABELS.ZIP_CODE),
      accessor: ADMIN_KEYS.ZIP_CODE,
      minWidth: 100,
      Cell: (props: { value: any; }) => addDataTest(null, 'orders-zip', props.value),
    },
  ]);

  static formatAddress = (order: { addressLineTwo: string | any[]; addressLineOne: any; }) => {
    const hasSecondLine = order.addressLineTwo && order.addressLineTwo.length > 0;
    return hasSecondLine ? `${order.addressLineOne}, ${order.addressLineTwo}`
      : order.addressLineOne;
  };

  async componentDidMount() {
    if (this.props.currentRegion
      && this.props.currentRegion.url) {
      await this.setPlantList(this.props.currentRegion.url);
    }
    document.title = 'GeoTrax - TruckTrax';
    setFavicon('/favicon-geotrax.ico');
  }

  setPlantList = async (regionUrl: string) => {
    try {
      if (regionUrl && regionUrl !== '') {
        this.setState({ plantList: await getPlantList(regionUrl) });
      }
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      this.props.devErrorAndLog(ERROR_TEXT_FETCH_PLANT_LIST, `OrdersView: ${e.toString()}`, undefined, undefined, consoleOnly);
    }
  };

  plantUrlAccessor = (plant: { url: any; }) => {
    const hasPlantList = this.state && this.state.plantList;
    if (!hasPlantList) {
      return '';
    }

    return plant.url
      ? findKeyWithUrl(plant, this.state.plantList, 'name')
      : NOT_AVAILABLE;
  };

  render() {
    const columns = this.getColumns();
    const titleRegion = this.props.currentRegion != null ? this.props.currentRegion.name : '';
    const renderProductLine = (productLine: string | undefined) => {
      if (productLine === READYMIX_VALUE) {
        return READYMIX_STRING;
      }
      return productLine;
    };
    const title = `${titleRegion} ${renderProductLine(this.props.selectedProductLine) || ''} Orders`;

    return (
      <div>
        <DataTableContent
          id="order"
          key="orders-view-data-table-content"
          baseUrl={this.baseUrl}
          columns={columns}
          header={title}
          includeDateRangePicker
          startDate={DateWrapper.now.startOfDay.date}
          source={DATASOURCE.ORDER}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  currentRegion: state.currentRegion,
  selectedProductLine: state.selectedProductLine,
});

type OrdersViewReduxStateProps = ReturnType<typeof mapStateToProps>;

type OrdersViewReduxDispatchProps = {
  devErrorAndLog: ConnectedDispatchFunction<typeof devErrorAndLog>,
};

export type OrdersViewProps = OrdersViewReduxStateProps & OrdersViewReduxDispatchProps & OrdersViewOwnProps;

type OrdersViewOwnProps = {
  selectedProductLine?: ProductLine
  currentRegion?: {
    url?: string,
    name?: string
  }
};

export default connect(mapStateToProps, {
  devErrorAndLog,
  setDateRange,
})(OrdersView);
