import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HeaderAndContainer, Label, Button } from '@trucktrax/trucktrax-common';
import cx from 'classnames';
import Paper from '@mui/material/Paper';
import {
  NotificationDto, OrderDto, PlantDto, UrlKeyDto,
} from '@trucktrax/trucktrax-ts-common';
import Header from '../../../shared/Header';
import style from './OrderDetailView.module.css';
import OrderTicketList from './OrderTicketList';
import CardMap from '../../../shared/forms/CardMap';
import CardInput from '../../../shared/forms/CardInput';
import CardDisplay from '../../../shared/forms/CardDisplay';
import { getOrder, updateOrder } from '../../../../services/ordersService';
import { openSnackbar } from '../../../../store/actions/snackbarActions';
import { setDateRange } from '../../../../store/actions/dataTableActions';
import { getNotificationsForOrder, resolveNotification } from '../../../../services/notificationsService';
import { getPlant, getClosestPlant } from '../../../../services/plantsService';
import { getIdFromUrl, setFavicon } from '../../../../util/appUtil';
import { formatOrderScheduledTime, statusLabels } from '../../../../util/orderUtil';
import { NOTIFICATION_INACCURATE_ADDRESS, NOTIFICATION_CLOSER_PLANT, SUCCESS } from '../../../../constants/appConstants';
import { getTokens } from '../../../../util/authUtil';
import { ConnectedDispatchFunction, ConnectedFunction } from '../../../../types';
import { ReduxState } from '../../../../store';

export interface OrdersDetailsState {
  order?: OrderDto,
  plant?: PlantDto,
  closerPlant?: any,
  editingMap?: boolean,
  mapUpdate?: any,
  radiusMiles?: string
  notifications: NotificationDto[],
  detailsWidth: number | null,
  smallDetailsWidth: boolean
}

export class OrdersDetails extends Component<OrdersDetailsProps, OrdersDetailsState> {
  orderDetailsRef: React.RefObject<HTMLDivElement>;

  constructor(props: OrdersDetailsProps) {
    super(props);

    this.state = {
      order: undefined,
      plant: undefined,
      closerPlant: undefined,
      editingMap: false,
      mapUpdate: undefined,
      radiusMiles: '0',
      notifications: [],
      detailsWidth: null,
      smallDetailsWidth: false,
    };

    this.orderDetailsRef = React.createRef();
  }

  async componentDidMount() {
    document.title = 'GeoTrax - TruckTrax';
    setFavicon('/favicon-geotrax.ico');
    await this.getOrderDetails();
    window.addEventListener('resize', this.checkComponentWidth);
    window.addEventListener('click', this.checkComponentWidth);
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.checkComponentWidth);
    window.removeEventListener('click', this.checkComponentWidth);
  }

  async componentDidUpdate(prevProps: OrdersDetailsProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      await this.getOrderDetails();
    }
  }

  async getOrderDetails() {
    const { url } = this.props.currentRegion;
    const { id } = this.props.match.params;

    let order = await this.props.getOrder(id, url);

    if (order) {
      const plantId = Number(getIdFromUrl(order.plant.url));
      const plant = await this.props.getPlant(plantId, url!);
      const notifications = await this.props.getNotificationsForOrder(order.url);

      const notificationItems = notifications && notifications.items;
      const hasCloserPlant = notificationItems && notificationItems.length > 0
        && notificationItems.some((n: { type: string; }) => n.type === NOTIFICATION_CLOSER_PLANT);

      const geoZoneCenter = {
        latitude: order.geoZone?.zone?.circle?.center?.latitude ?? plant?.location?.latitude,
        longitude: order.geoZone?.zone?.circle?.center?.longitude ?? plant?.location?.longitude,
      };

      const closerPlant = hasCloserPlant
        ? await this.props.getClosestPlant(plantId, geoZoneCenter.latitude, geoZoneCenter.longitude, url!)
        : null;

      order = {
        ...order,
        geoZone: {
          ...order?.geoZone,
          zone: {
            ...order.geoZone?.zone,
            circle: {
              ...order?.geoZone?.zone?.circle,
              center: geoZoneCenter,
            },
          },
        },
      };

      this.setState({
        order,
        plant,
        notifications: notificationItems,
        closerPlant,
        mapUpdate: {
          location: geoZoneCenter,
          ...order.geoZone?.zone,
        },
        radiusMiles: ((order.geoZone?.zone.circle.radius ?? 0) / 1609).toFixed(1),
      });

      this.checkComponentWidth();
    }
  }

  goBack = () => {
    // Set date range to previously used filtered date range if any.
    if (this.props.location.state) {
      const { startDate, endDate } = this.props.location.state;
      if (startDate && endDate) {
        this.props.setDateRange(new Date(startDate), new Date(endDate));
      }
    }
    this.props.history.goBack();
  };

  inaccurateAddressNotification = () => {
    const { notifications } = this.state;
    return (
      <div className={style.inaccurateNotificationContainer}>
        <Label className={style.inaccurateAddressLabel}>
          <i className={cx('icon-warning', style.warningIcon)} />
          Mapped Address May be Inaccurate
        </Label>
        Edit the map to change jobsite location or
        <button
          className={cx('tt-btn', 'tt-btn-basic', style.keepAddress)}
          onClick={() => {
            const inaccurateAddressNotification = notifications.find(n => n.type === NOTIFICATION_INACCURATE_ADDRESS) as NotificationDto;
            this.markResolved(inaccurateAddressNotification);
          }}
        >
          <span>keep current location</span>
        </button>
      </div>
    );
  };

  orderPlant = (plant: any) => {
    if (!plant) {
      return null;
    }

    const { notifications } = this.state;

    const hasNotifications = notifications && notifications.length > 0;
    const closerPlantNotification = hasNotifications
      && notifications.find(n => n.type === NOTIFICATION_CLOSER_PLANT);

    const { closerPlant } = this.state;

    if (!closerPlantNotification || !closerPlant) {
      return (
        <CardDisplay
          className={style.orderItem}
          label="Plant"
          value={plant && plant.name}
          dataTest="orders-plant-field"
        />
      );
    }

    const keepMessage = `Keep as ${plant.name}`;
    return (
      <div>
        {plant.name !== closerPlant!.name && (
          <div className={style.closerPlantNotificationContainer}>
            <div className={style.closerPlantLabelContainer}>
              <Label className={style.inaccurateAddressLabel}>
                Plant
              </Label>
              <span>
                {plant.name}
              </span>
            </div>
            <div className={style.closerPlantLabelContainer}>
              <Label className={style.inaccurateAddressLabel}>
                <i className={cx('icon-warning', style.warningIcon)} />
                Closer Plant Detected
              </Label>
              <span>
                {closerPlant!.name}
              </span>
            </div>
            <span className={style.clearViewMsg}>
              Any changes to the plant must be made in ClearView
            </span>
            <br />
            <button
              className={style.keepAsBtn}
              onClick={() => this.markResolved(closerPlantNotification)}
            >
              <span>{keepMessage}</span>
            </button>
          </div>
        )}
      </div>
    );
  };

  async markResolved(notification: NotificationDto) {
    const { notifications } = this.state;
    const { openSnackbar: dispatchOpenSnackbar } = this.props;
    await this.props.resolveNotification(notification);

    const notificationIndex = notifications.indexOf(notification);
    notifications.splice(notificationIndex, 1);
    this.setState({ notifications });

    dispatchOpenSnackbar({
      snackbarBody: 'Notification has been dismissed',
      dataTest: 'orderDetails-markResolved-success-snackbar',
      snackbarType: SUCCESS,
    });
  }

  mapChanged(update: any) {
    const newCircle = update && update.circle;
    if (!newCircle) {
      return;
    }
    const { mapUpdate } = this.state;
    const radiusMiles = (newCircle.radius / 1609).toFixed(1);

    this.setState({
      mapUpdate:
        { ...mapUpdate, ...update },
      radiusMiles,
    });
  }

  async saveMapChanges() {
    const { mapUpdate, order, notifications } = this.state;
    const { openSnackbar: dispatchOpenSnackbar } = this.props;

    const newValue = {
      ...order,
      mapSource: 'GeoTrax',
      geoZone: {
        zone: {
          ...mapUpdate,
        },
      },
    } as OrderDto;
    const updatedOrder = await this.props.updateOrder(newValue);
    this.setState({
      order: updatedOrder,
      editingMap: false,
      mapUpdate: {
        ...mapUpdate,
        location: updatedOrder.geoZone?.zone.circle.center,
      },
    });
    const hasNotifications = notifications && notifications.length > 0;

    const inaccurateAddressNotification = hasNotifications
      && notifications.find(n => n.type === NOTIFICATION_INACCURATE_ADDRESS);

    if (inaccurateAddressNotification) {
      await this.markResolved(inaccurateAddressNotification);
    }

    dispatchOpenSnackbar({
      snackbarBody: 'Delivery location has been successfully updated.',
      dataTest: 'orderDetails-addressUpdated-success-snackbar',
      snackbarType: SUCCESS,
    });
  }

  updateMapRadius(newRadiusMiles: any) {
    const { mapUpdate } = this.state;
    const radiusMeters = Number(newRadiusMiles) * 1609;
    this.setState({
      mapUpdate: {
        ...mapUpdate,
        circle: {
          ...mapUpdate.circle,
          radius: radiusMeters,
        },
      },
      radiusMiles: newRadiusMiles,
    });
  }

  renderMapEditControls() {
    const {
      editingMap,
      mapUpdate,
      order,
      radiusMiles,
    } = this.state;
    if (!editingMap) {
      return null;
    }
    let hasChanges = false;
    const hasGeoZoneCenter = order?.geoZone?.zone?.circle?.center;
    if (hasGeoZoneCenter) {
      const locationChanged = mapUpdate.circle.center.latitude !== order!.geoZone!.zone.circle!.center!.latitude
        || mapUpdate.circle.center.longitude !== order!.geoZone!.zone.circle!.center!.longitude;
      const radiusChanged = mapUpdate.circle.radius !== order!.geoZone!.zone.circle!.radius;
      hasChanges = radiusChanged || locationChanged;
    }
    return (
      <div className={style.mapEditControls}>
        <span className={style.geofenceRadiusLabel}>Geofence radius:</span>
        <CardInput
          id="milesRadius"
          className={style.milesInput}
          hideCharacterCount
          onChange={(e) => this.updateMapRadius(e.milesRadius)}
          onTouch={() => { }}
          value={radiusMiles}
        />
        <span className={style.miles}>miles</span>
        <div className={style.mapEditControlsButtons}>
          <Button
            onClick={() => this.toggleMapEdit()}
            dataTest="cancel-edit-map-button"
            name="Cancel"
            buttonClassName="tt-btn-secondary"
          />
          <Button
            disabled={!hasChanges}
            onClick={() => this.saveMapChanges()}
            dataTest="save-edit-map-button"
            name="Save Changes"
            buttonClassName="tt-btn--submit"
          />
        </div>
      </div>
    );
  }

  renderEditButton() {
    const { editingMap } = this.state;
    if (editingMap) {
      return null;
    }

    return (
      <Button
        iconClassName="icon-create"
        buttonClassName={style.editMapButton}
        onClick={() => this.toggleMapEdit()}
        dataTest="edit-map-button"
        name="Edit Map"
      />
    );
  }

  toggleMapEdit() {
    const { editingMap } = this.state;
    this.setState({
      editingMap: !editingMap,
    });
  }

  getMapValue() {
    const { mapUpdate, order, editingMap } = this.state;
    if (editingMap) {
      return mapUpdate;
    }
    const hasGeoZone = order?.geoZone?.zone?.circle;
    if (hasGeoZone) {
      return {
        ...order!.geoZone!.zone,
        location: order!.geoZone!.zone.circle!.center,
      };
    }

    return {};
  }

  statusBadge = () => {
    const { order } = this.state;

    const styleMap = {
      WILL_CALL: style.willCall,
      SHIP: style.ship,
      HOLD: style.hold,
      FOB: style.fob,
      CANCELED: style.canceled,
    };

    type StyleMapType = typeof styleMap;
    type OrderStatusStyle = keyof StyleMapType;
    const status = order!.orderStatus! as OrderStatusStyle;
    if (!status) {
      return null;
    }
    return (
      <div className={cx(style.orderBadge)}>
        <span className={cx(style.statusLabel, styleMap[status])}>
          {statusLabels[status]}
        </span>
      </div>
    );
  };

  static getMapClass = (currentlyEditing: boolean, hasInaccurateAddressNotification: boolean) => {
    if (hasInaccurateAddressNotification) {
      return style.mapEditingWithNotification;
    }

    if (currentlyEditing || hasInaccurateAddressNotification) {
      return style.map;
    }

    return style.fullMap;
  };

  checkComponentWidth = () => {
    const existingRef = this.orderDetailsRef.current;
    if (existingRef) {
      setTimeout(() => {
        const width = existingRef.clientWidth;
        const isSmallDetailsWidth = width < 900;
        if (this.state.detailsWidth !== width) {
          this.setState({
            smallDetailsWidth: isSmallDetailsWidth,
          });
        }
      }, 300); // Delay allows for animations in the UI to complete before trying to calculate component width
    }
  };

  render() {
    const {
      order,
      plant,
      notifications,
      editingMap,
    } = this.state;
    if (!order) {
      return null;
    }

    const title = `Order #${order && order.orderNumber}`;

    const subtitle = (
      <button
        className={cx('tt-btn', 'tt-btn-basic', style.goBack)}
        onClick={this.goBack}
      >
        <i
          aria-hidden="true"
          className={cx('icon-arrow-back', 'icon-xsmall')}
        />
        <span>Go Back</span>
      </button>
    );

    const elementsLeft = (
      <Header
        title={title}
        subtitle={subtitle}
      />
    );

    const mapContainerStyle = {
      height: '100%',
      maxWidth: '100%',
    };

    const tenantUrl = getTokens().tenantUrl!;
    const value = this.getMapValue();
    const orderTicketProps = order && {
      orderExternalId: order.externalId,
      orderId: order.id,
      tenantUrl,
    };
    const { mapSource } = order;

    const hasNotifications = notifications && notifications.length > 0;
    const hasInaccurateAddressNotification = hasNotifications
      && notifications.some(n => n.type === NOTIFICATION_INACCURATE_ADDRESS);

    const hasStatus = order.orderStatus && true;
    const content = (
      <div className={style.pageContainer} ref={this.orderDetailsRef}>
        <section
          className={cx(
            style.layout,
            this.state.smallDetailsWidth && style.detailsSmall
          )}
        >
          <Paper className={style.details}>
            <CardDisplay
              className={cx(style.orderItem, hasStatus && style.noPadding)}
              label={<h3>{title}</h3>}
              value={formatOrderScheduledTime(order) || ''}
              dataTest="orders-orderdate-field"
            />
            {this.statusBadge()}
            <div className={style.firstDetailGroup}>
              <CardDisplay
                className={style.orderItem}
                label="Customer"
                value={order.customerName || ''}
                dataTest="orders-customer-field"
              />
              <CardDisplay
                className={style.orderItem}
                label="Project Name"
                value={order.projectName || ''}
                dataTest="orders-projectname-field"
              />
              {this.orderPlant(plant)}
              <CardDisplay
                className={style.orderItem}
                label="Delivery Address"
                value={[order.addressLineOne || '', order.addressLineTwo || '']}
                dataTest="orders-deliveryaddress-field"
              />
            </div>
            {mapSource && (
              <div className={style.sourceLabel}>
                <Label>
                  Source:
                </Label>
                {mapSource}
              </div>
            )}
          </Paper>
          <Paper className={style.mapContainer}>
            {this.renderEditButton()}
            {this.renderMapEditControls()}
            <CardMap
              className={OrdersDetails.getMapClass(editingMap!, hasInaccurateAddressNotification)}
              containerStyle={mapContainerStyle}
              // this should not be required but MapProps requires it even though
              // containerStyle is part of CardMapProps
              mapContainerStyle={mapContainerStyle}
              value={value}
              isEditable={editingMap}
              onChange={(update) => this.mapChanged(update)}
            />
            {hasInaccurateAddressNotification && this.inaccurateAddressNotification()}
          </Paper>
        </section>
        {orderTicketProps && <OrderTicketList {...orderTicketProps} />}
      </div>
    );

    return (
      <HeaderAndContainer
        containerStyle={style.headerAndContainerStyle}
        elementsLeft={elementsLeft}
        containerComponent={content}
      />
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  currentRegion: state.currentRegion,
});

type Match = {
  params: { id: number };
};
type OrdersDetailsReduxStateProps = ReturnType<typeof mapStateToProps>;

type OrdersDetailsReduxDispatchProps = {
  getOrder: ConnectedDispatchFunction<typeof getOrder>,
  updateOrder: ConnectedDispatchFunction<typeof updateOrder>,
  getPlant: ConnectedDispatchFunction<typeof getPlant>,
  getClosestPlant: ConnectedDispatchFunction<typeof getClosestPlant>,
  resolveNotification: ConnectedDispatchFunction<typeof resolveNotification>,
  getNotificationsForOrder: ConnectedDispatchFunction<typeof getNotificationsForOrder>,
  openSnackbar: ConnectedFunction<typeof openSnackbar>,
  setDateRange: ConnectedFunction<typeof setDateRange>
};
export type OrdersDetailsProps = OrdersDetailsOwnProps & OrdersDetailsReduxStateProps & OrdersDetailsReduxDispatchProps;

type OrdersDetailsOwnProps = {
  currentRegion: UrlKeyDto,
  history: any,
  match: Match,
  location: {
    state: {
      startDate?: string,
      endDate?: string
    }
  }
};

export default connect(mapStateToProps, {
  getOrder,
  updateOrder,
  getPlant,
  openSnackbar,
  getClosestPlant,
  resolveNotification,
  getNotificationsForOrder,
  setDateRange,
})(OrdersDetails);
