import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './EmptyDataTableContent.module.css';

export function EmptyDataTableContent(props: EmptyDataTableContentProps) {
  const { img, component } = props;

  return (
    <div className={styles.view}>
      <div className={styles.container}>
        <img
          className={cx(styles.img)}
          src={img.src}
          alt={img.alt}
        />
        {component}
      </div>
    </div>
  );
}

EmptyDataTableContent.propTypes = {
  img: PropTypes.shape({
    styles: PropTypes.shape({}),
    src: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  component: PropTypes.shape({}).isRequired,
};

export interface EmptyDataTableContentProps {
  img: {
    src: string;
    alt: string
  };
  component: JSX.Element;
}

export default EmptyDataTableContent;
