import { DateWrapper, constants } from '@trucktrax/trucktrax-common';
import { ProductLine, StatusEventDto } from '@trucktrax/trucktrax-ts-common';
import { MapView } from '../components/geotrax/map/mapTypes';
import { TruckInfo } from '../types';

export default function sortStatuses(statusA: StatusEventDto | null, statusB: StatusEventDto | null) {
  if (statusA === null || statusA.timestamp === null || statusA.timestamp === undefined) {
    return 1;
  }
  if (statusB === null || statusB.timestamp === null || statusB.timestamp === undefined) {
    return -1;
  }
  if (statusA.timestamp === statusB.timestamp) {
    return 0;
  }
  return new DateWrapper(statusB.timestamp).isAfter(new Date(statusA.timestamp)) ? 1 : -1;
}

export function aggFolderList(listType: string) {
  const circleIcon = 'icon-dot';
  const arrowIcon = 'icon-forward';
  const inQueueFolder = {
    id: constants.STATUS_IDS.IN_QUEUE,
    name: constants.STATUS_NAMES.InQueue,
    color: constants.STATUS_COLORS.InQueue,
    icon: circleIcon,
    text: '',
    itemList: [] as TruckInfo[],
    dataTest: `in-queue-${listType}`,
  };
  const folderList = [
    {
      id: constants.STATUS_IDS.TICKETED,
      name: constants.STATUS_NAMES.Ticketed,
      color: constants.STATUS_COLORS.Ticketed,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `ticketed-${listType}`,
    },
    {
      id: constants.STATUS_IDS.TO_JOB,
      name: constants.STATUS_NAMES.ToJob,
      color: constants.STATUS_COLORS.ToJob,
      icon: arrowIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `to-job-${listType}`,
    },
    {
      id: constants.STATUS_IDS.ON_JOB,
      name: constants.STATUS_NAMES.OnJob,
      color: constants.STATUS_COLORS.OnJob,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `on-job-${listType}`,
    },
    {
      id: constants.STATUS_IDS.UNLOADED,
      name: constants.STATUS_NAMES.Unloaded,
      color: constants.STATUS_COLORS.Unloaded,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `unloaded-${listType}`,
    },
    {
      id: constants.STATUS_IDS.RETURNING,
      name: constants.STATUS_NAMES.Returning,
      color: constants.STATUS_COLORS.Returning,
      icon: arrowIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `returning-${listType}`,
    },
    {
      id: constants.STATUS_IDS.IN_YARD,
      name: constants.STATUS_NAMES.InYard,
      color: constants.STATUS_COLORS.InYard,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `in-yard-${listType}`,
    },
    {
      id: constants.STATUS_IDS.UNKNOWN,
      name: constants.STATUS_NAMES.Unknown,
      color: constants.STATUS_COLORS.Unknown,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `unknown-${listType}`,
    }];
  // adds default folder that is not found in route view of ticket
  if (listType === constants.TRUCKS_STRING) {
    folderList.unshift(inQueueFolder);
  }
  return folderList;
}

export function cementFolderList(listType: string) {
  const circleIcon = 'icon-dot';
  const arrowIcon = 'icon-forward';
  const inQueueFolder = {
    id: constants.STATUS_IDS.IN_QUEUE,
    name: constants.STATUS_NAMES.InQueue,
    color: constants.STATUS_COLORS.InQueue,
    icon: circleIcon,
    text: '',
    itemList: [],
    dataTest: `in-queue-${listType}`,
  };
  const folderList = [
    {
      id: constants.STATUS_IDS.TICKETED,
      name: constants.STATUS_NAMES.Ticketed,
      color: constants.STATUS_COLORS.Ticketed,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `ticketed-${listType}`,
    },
    {
      id: constants.STATUS_IDS.TO_JOB,
      name: constants.STATUS_NAMES.ToPlant,
      color: constants.STATUS_COLORS.ToJob,
      icon: arrowIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `to-job-${listType}`,
    },
    {
      id: constants.STATUS_IDS.ON_JOB,
      name: constants.STATUS_NAMES.AtPlant,
      color: constants.STATUS_COLORS.OnJob,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `at-plant-${listType}`,
    },
    {
      id: constants.STATUS_IDS.UNLOAD_START,
      name: constants.STATUS_NAMES.UnloadStart,
      color: constants.STATUS_COLORS.UnloadStart,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `unload-start-${listType}`,
    },
    {
      id: constants.STATUS_IDS.UNLOADED,
      name: constants.STATUS_NAMES.UnloadEnd,
      color: constants.STATUS_COLORS.Unloaded,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `unload-end-${listType}`,
    },
    {
      id: constants.STATUS_IDS.RETURNING,
      name: constants.STATUS_NAMES.Returning,
      color: constants.STATUS_COLORS.Returning,
      icon: arrowIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `returning-${listType}`,
    },
    {
      id: constants.STATUS_IDS.IN_YARD,
      name: constants.STATUS_NAMES.AtTerminal,
      color: constants.STATUS_COLORS.InYard,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `at-terminal-${listType}`,
    },
    {
      id: constants.STATUS_IDS.UNKNOWN,
      name: constants.STATUS_NAMES.Unknown,
      color: constants.STATUS_COLORS.Unknown,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `unknown-${listType}`,
    }];
  // adds default folder that is not found in route view of ticket
  if (listType === constants.TRUCKS_STRING) {
    folderList.unshift(inQueueFolder);
  }
  return folderList;
}

export function readyMixFolderList(listType: string) {
  const circleIcon = 'icon-dot';
  const arrowIcon = 'icon-forward';
  const inQueueFolder = {
    id: constants.STATUS_IDS.IN_QUEUE,
    name: constants.STATUS_NAMES.ReadyToLoad,
    color: constants.STATUS_COLORS.InQueue,
    icon: circleIcon,
    text: '',
    itemList: [],
    dataTest: `ready-to-load-${listType}`,
  };
  const folderList = [
    {
      id: constants.STATUS_IDS.TICKETED,
      name: constants.STATUS_NAMES.Ticketed,
      color: constants.STATUS_COLORS.Ticketed,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `ticketed-${listType}`,
    },
    {
      id: constants.STATUS_IDS.LOADING,
      name: constants.STATUS_NAMES.Loaded,
      color: constants.STATUS_COLORS.Loading,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `loaded-${listType}`,
    },
    {
      id: constants.STATUS_IDS.TO_JOB,
      name: constants.STATUS_NAMES.ToJob,
      color: constants.STATUS_COLORS.ToJob,
      icon: arrowIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `to-job-${listType}`,
    },
    {
      id: constants.STATUS_IDS.ON_JOB,
      name: constants.STATUS_NAMES.OnJob,
      color: constants.STATUS_COLORS.OnJob,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `on-job-${listType}`,
    },
    {
      id: constants.STATUS_IDS.UNLOAD_START,
      name: constants.STATUS_NAMES.Pouring,
      color: constants.STATUS_COLORS.UnloadStart,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `pouring-${listType}`,
    },
    {
      id: constants.STATUS_IDS.UNLOADED,
      name: constants.STATUS_NAMES.EndPour,
      color: constants.STATUS_COLORS.Unloaded,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `end-pour-${listType}`,
    },
    {
      id: constants.STATUS_IDS.RETURNING,
      name: constants.STATUS_NAMES.Returning,
      color: constants.STATUS_COLORS.Returning,
      icon: arrowIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `returning-${listType}`,
    },
    {
      id: constants.STATUS_IDS.IN_YARD,
      name: constants.STATUS_NAMES.InPlant,
      color: constants.STATUS_COLORS.InYard,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `in-plant-${listType}`,
    },
    {
      id: constants.STATUS_IDS.UNKNOWN,
      name: constants.STATUS_NAMES.Unknown,
      color: constants.STATUS_COLORS.Unknown,
      icon: circleIcon,
      text: '',
      itemList: [] as TruckInfo[],
      dataTest: `unknown-${listType}`,
    }];
  // adds default folder that is not found in route view of ticket
  if (listType === constants.TRUCKS_STRING) {
    folderList.unshift(inQueueFolder);
  }
  return folderList;
}

export function mapAggTrucksForFolderList(trucks: TruckInfo[]) {
  const folderList = aggFolderList(constants.TRUCKS_STRING);

  trucks.forEach(truck => {
    switch (truck.data.status) {
      case constants.STATUS_IDS.IN_QUEUE:
        folderList[0].itemList.push(truck);
        break;
      // exception (Loading is rendered as Ticketed for Agg)
      case constants.STATUS_IDS.LOADING:
      case constants.STATUS_IDS.TICKETED:
        folderList[1].itemList.push(truck);
        break;
      case constants.STATUS_IDS.TO_JOB:
        folderList[2].itemList.push(truck);
        break;
      case constants.STATUS_IDS.ON_JOB:
        folderList[3].itemList.push(truck);
        break;
      case constants.STATUS_IDS.UNLOADED:
        folderList[4].itemList.push(truck);
        break;
      case constants.STATUS_IDS.RETURNING:
        folderList[5].itemList.push(truck);
        break;
      case constants.STATUS_IDS.IN_YARD:
        folderList[6].itemList.push(truck);
        break;
      // Unknown, null or other will go to Unknown
      case constants.STATUS_IDS.UNKNOWN:
      default:
        folderList[7].itemList.push(truck);
    }
  });

  return folderList;
}

export function mapCementTrucksForFolderList(trucks: TruckInfo[]) {
  const folderList = cementFolderList(constants.TRUCKS_STRING);

  trucks.forEach(truck => {
    switch (truck.data.status) {
      case constants.STATUS_IDS.IN_QUEUE:
        folderList[0].itemList.push(truck);
        break;
      // exception (Loading is rendered as Ticketed for Cement)
      case constants.STATUS_IDS.LOADING:
      case constants.STATUS_IDS.TICKETED:
        folderList[1].itemList.push(truck);
        break;
      case constants.STATUS_IDS.TO_JOB:
        folderList[2].itemList.push(truck);
        break;
      case constants.STATUS_IDS.ON_JOB:
        folderList[3].itemList.push(truck);
        break;
      case constants.STATUS_IDS.UNLOAD_START:
        folderList[4].itemList.push(truck);
        break;
      case constants.STATUS_IDS.UNLOADED:
        folderList[5].itemList.push(truck);
        break;
      case constants.STATUS_IDS.RETURNING:
        folderList[6].itemList.push(truck);
        break;
      case constants.STATUS_IDS.IN_YARD:
        folderList[7].itemList.push(truck);
        break;
      // Unknown, null or other will go to Unknown
      case constants.STATUS_IDS.UNKNOWN:
      default:
        folderList[8].itemList.push(truck);
    }
  });

  return folderList;
}

export function mapReadyMixTrucksForFolderList(trucks: TruckInfo[]) {
  const folderList = readyMixFolderList(constants.TRUCKS_STRING);

  trucks.forEach(truck => {
    switch (truck.data.status) {
      case constants.STATUS_IDS.IN_QUEUE:
        folderList[0].itemList.push(truck);
        break;
      case constants.STATUS_IDS.TICKETED:
        folderList[1].itemList.push(truck);
        break;
      case constants.STATUS_IDS.LOADING:
        folderList[2].itemList.push(truck);
        break;
      case constants.STATUS_IDS.TO_JOB:
        folderList[3].itemList.push(truck);
        break;
      case constants.STATUS_IDS.ON_JOB:
        folderList[4].itemList.push(truck);
        break;
      case constants.STATUS_IDS.UNLOAD_START:
        folderList[5].itemList.push(truck);
        break;
      case constants.STATUS_IDS.UNLOADED:
        folderList[6].itemList.push(truck);
        break;
      case constants.STATUS_IDS.RETURNING:
        folderList[7].itemList.push(truck);
        break;
      case constants.STATUS_IDS.IN_YARD:
        folderList[8].itemList.push(truck);
        break;
      // Unknown, null or other will go to Unknown
      case constants.STATUS_IDS.UNKNOWN:
      default:
        folderList[9].itemList.push(truck);
    }
  });

  return folderList;
}

// allows re-used statuses to be properly named
export function retrieveProductLineNameDiff(selectedProductLine: ProductLine) {
  const mutualStatuses = {
    [constants.STATUS_IDS.TICKETED]: constants.STATUS_NAMES.Ticketed,
    [constants.STATUS_IDS.RETURNING]: constants.STATUS_NAMES.Returning,
    [constants.STATUS_IDS.UNKNOWN]: constants.STATUS_NAMES.Unknown,
  };
  const productLineNameMap = {
    [constants.CEMENT_VALUE]: {
      ...mutualStatuses,
      [constants.STATUS_IDS.IN_QUEUE]: constants.STATUS_NAMES.InQueue,
      [constants.STATUS_IDS.TO_JOB]: constants.STATUS_NAMES.ToPlant,
      [constants.STATUS_IDS.ON_JOB]: constants.STATUS_NAMES.AtPlant,
      [constants.STATUS_IDS.UNLOAD_START]: constants.STATUS_NAMES.UnloadStart,
      [constants.STATUS_IDS.UNLOADED]: constants.STATUS_NAMES.UnloadEnd,
      [constants.STATUS_IDS.IN_YARD]: constants.STATUS_NAMES.AtTerminal,
      // exception (Loading is rendered as Ticketed for Cement)
      [constants.STATUS_IDS.LOADING]: constants.STATUS_NAMES.Ticketed,
    },
    // Aggregates have no re-used fields
    [constants.AGGREGATES_VALUE]: {
      ...mutualStatuses,
      [constants.STATUS_IDS.IN_QUEUE]: constants.STATUS_NAMES.InQueue,
      [constants.STATUS_IDS.TO_JOB]: constants.STATUS_NAMES.ToJob,
      [constants.STATUS_IDS.ON_JOB]: constants.STATUS_NAMES.OnJob,
      [constants.STATUS_IDS.UNLOADED]: constants.STATUS_NAMES.Unloaded,
      [constants.STATUS_IDS.IN_YARD]: constants.STATUS_NAMES.InYard,
      // exception (Loading is rendered as Ticketed for Agg)
      [constants.STATUS_IDS.LOADING]: constants.STATUS_NAMES.Ticketed,
    },
    [constants.READY_MIX_VALUE]: {
      ...mutualStatuses,
      [constants.STATUS_IDS.IN_QUEUE]: constants.STATUS_NAMES.ReadyToLoad,
      [constants.STATUS_IDS.LOADING]: constants.STATUS_NAMES.Loaded,
      [constants.STATUS_IDS.TO_JOB]: constants.STATUS_NAMES.ToJob,
      [constants.STATUS_IDS.ON_JOB]: constants.STATUS_NAMES.OnJob,
      [constants.STATUS_IDS.UNLOAD_START]: constants.STATUS_NAMES.Pouring,
      [constants.STATUS_IDS.UNLOADED]: constants.STATUS_NAMES.EndPour,
      [constants.STATUS_IDS.IN_YARD]: constants.STATUS_NAMES.InPlant,
    },
  };
  return productLineNameMap[selectedProductLine];
}

export function getStatusNameFromMapView(position: MapView) {
  const productLine = position?.productLine ?? ProductLine.None;
  const productLineStatuses = retrieveProductLineNameDiff(productLine as ProductLine);
  const status = position?.status ?? constants.STATUS_IDS.UNKNOWN;

  return (productLineStatuses && productLineStatuses[status])
    ? productLineStatuses[status]
    : constants.STATUS_NAMES.Unknown;
}
