import React, { ChangeEventHandler, Component, MouseEvent } from 'react';
import cx from 'classnames';
import { Button } from '@trucktrax/trucktrax-common';
import { ClickAwayListener } from '@mui/material';
import { CannedMessagesDto, RegionDto } from '@trucktrax/trucktrax-ts-common';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import constants from '../../../../constants/messagingConstants';
import styles from './ComposeMessage.module.css';
import stylesPopper from '../AutoCompleteWithChip.module.css';
import { getCannedMessages } from '../../../../services/cannedMessagesService';

class ComposeMessage extends Component<ComposeMessageProps, ComposeMessageState> {
  textarea: HTMLTextAreaElement | undefined;

  popoverInputNode: any;

  constructor(props: ComposeMessageProps) {
    super(props);
    this.state = {
      popperOpen: false,
      availableItemsList: [],
    };
  }

  async componentDidMount() {
    if (this.props.placeholder !== constants.NEW_PLACEHOLDER) {
      // eslint-disable-next-line no-unused-expressions
      this.textarea?.focus();
    }
    const response = await getCannedMessages(this.props.currentRegion.id);
    this.findItemByProductLine(response?.data?.items);
  }

  // See TODO in FeaturesTable.js regarding componentWillReceiveProps
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: ComposeMessageProps) {
    if (nextProps.selectedMessageId !== this.props.selectedMessageId
      && this.props.placeholder !== constants.NEW_PLACEHOLDER) {
      this.textarea!.focus();
    }
  }

  // Function to find item by selectedProductLine
  findItemByProductLine = (messages: CannedMessagesDto[]) => {
    const foundItem = messages?.find(item => item.productLine === this.props.selectedProductLine);
    if (foundItem) {
      const availableItems = foundItem?.messages.map((message: string) => ({
        url: '',
        fullName: message,
      }));
      this.setState({ availableItemsList: availableItems });
    }

    return foundItem || null; // Returns null if no match is found
  };

  clearForm = () => {
    const empty: any = {
      target: {
        value: '',
      },
    };

    this.props.onChange(empty);
    // refocus on textarea
    this.textarea!.focus();
  };

  textAreaRef = (input: HTMLTextAreaElement) => {
    this.textarea = input;
    this.popoverInputNode = input;
  };

  static defaultClickAway = () => { };

  togglePopper = () => {
    this.setState((prevState) => ({
      popperOpen: !prevState.popperOpen
    }));
  };

  handleClickAway = () => {
    this.setState(() => ({
      popperOpen: false
    }));
  };

  handleItemClick = (item: AvailableItem) => {
    this.props.onMessageFieldChange(item.fullName);
    this.setState({
      popperOpen: false,
    });
  };

  renderSuggestion = (params: any) => {
    const {
      item,
      itemProps,
    } = params;
    return (
      <MenuItem
        {...itemProps}
        key={`${item.fullName}`}
        component="div"
        className={stylesPopper.menuItem}
        onClick={() => this.handleItemClick(item)}
      >
        {item.fullName}
      </MenuItem>
    );
  };

  renderPopper = () => {
    const { popperOpen } = this.state;
    return (
      <Popper
        id="simple-popper"
        open={popperOpen}
        anchorEl={this.popoverInputNode}
        className={styles.popper}
        placement="top-start"
      >
        <Paper
          data-test="message-recipient-dropdown-item"
          square
          classes={{
            root: stylesPopper.popperPaper,
          }}
          style={{
            width: this.popoverInputNode ? this.popoverInputNode.clientWidth : null,
            maxHeight: 180
          }}
        >
          {
            (this.state.availableItemsList).map((item, index) => this.renderSuggestion({
              item,
              index,
              itemProps: {},
              highlightedIndex: -1,
            }))
          }
        </Paper>
      </Popper>
    );
  };

  renderClickAwayListener(show: boolean | undefined) {
    if (show) {
      return (
        <ClickAwayListener onClickAway={this.handleClickAway ?? ComposeMessage.defaultClickAway}>
          <i
            aria-hidden="true"
            className={cx(
              'icon-keyboard-arrow-down',
              styles.textareaIcon,
              styles.iconKeyboardArrowDown
            )}
            onClick={() => {
              this.togglePopper();
            }}
          />
        </ClickAwayListener>

      );
    }
    return <></>;
  }

  render() {
    return (
      <form className={styles.composeMessage}>
        {/* text body */}
        <div className={styles.textareaContainer}>
          <textarea
            data-test="compose-message-text-area"
            className={cx('tt-input', styles.textAreaWithIcon)}
            rows={5}
            maxLength={255}
            name="messageContent"
            value={this.props.value}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            ref={this.textAreaRef}
          />
          {this.renderClickAwayListener((this.state.availableItemsList).length > 0)}
        </div>
        {/* footer */}
        <div className={styles.footer}>
          {/* character count */}
          <span
            data-test="character-count"
            className={cx(styles.characters, {
              [styles.maxCharcount]: this.props.value.length === 255,
            })}
          >
            {this.props.value.length}
            /255
            <br />
            characters
          </span>
          {/* buttons */}
          <span className={styles.actions}>
            {/* cancel */}
            <Button
              data-test="message-compose-cancel"
              onClick={this.clearForm}
              buttonClassName="tt-btn-basic margin-right-20"
              disabled={this.props.value.length === 0}
              name={constants.CANCEL_LABEL}
              dataTest="message-center-cancel-button"
            />
            {/* send */}
            <Button
              onClick={this.props.onSubmit}
              buttonClassName="tt-btn--submit"
              name={constants.SEND_LABEL}
              disabled={this.props.submitDisabled}
              iconClassName="icon-send margin-right-10"
              dataTest="message-center-send-button"
            />
          </span>
        </div>
        {/* Popper */}
        {this.renderPopper()}
      </form>
    );
  }
}

export interface AvailableItem {
  url: string;
  fullName: string;
}

interface ComposeMessageState {
  popperOpen: boolean;
  availableItemsList: AvailableItem[];
}

export interface ComposeMessageProps {
  value: string;
  submitDisabled: boolean;
  placeholder: string;
  selectedMessageId?: string | null;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
  selectedProductLine?: string;
  currentRegion: RegionDto;
  onMessageFieldChange: (message: string) => void;
}

export default ComposeMessage;
