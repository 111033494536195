import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@mui/material';
import { Label } from '@trucktrax/trucktrax-common';
import { ProductLine } from '@trucktrax/trucktrax-ts-common';
import {
  ADMIN_KEYS, ADMIN_LABELS, READYMIX_STRING, READYMIX_VALUE
} from '../../constants/appConstants';
import styles from './ProductLineSelect.module.css';
import { productLineOptions } from '../../util/adminUtil';
import { ReduxState } from '../../store';

type LabelValuePair = { label: string, value: any };

export class ProductLineSelect extends Component<ProductLineSelectProps> {
  static defaultProps: Partial<ProductLineSelectProps> = {
    primaryProductLine: ProductLine.None,
    secondaryProductLines: [],
    selectedProductLine: ProductLine.None,
  };

  assignedProductLines = productLineOptions
    .filter(pl => pl.value === this.props.primaryProductLine || this.props.secondaryProductLines.includes(pl.value));

  static labelize = (pl: LabelValuePair) => (pl.value === READYMIX_VALUE ? READYMIX_STRING : pl.label);

  selectionChanged = (e: any) => {
    const newProductLine = e.target.value as ProductLine ?? undefined;
    if (newProductLine) {
      this.props.onSelectionChanged(newProductLine);
    }
  };

  mapToRadioButtons = (pl: LabelValuePair) => (
    <FormControlLabel
      data-test="radio-button-label"
      key={pl.value}
      value={pl.value}
      label={ProductLineSelect.labelize(pl)}
      classes={{ label: styles.radioLabel, disabled: styles.radioLabelDisabled }}
      disabled={this.props.disabled}
      control={(
        <Radio
          classes={{ root: styles.radioBtn, checked: styles.radioBtnSelected, disabled: styles.radioBtnDisabled }}
          checked={pl.value === this.props.selectedProductLine}
          onChange={this.selectionChanged}
        />
      )}
    />);

  static renderLabel = () => (
    <Label data-test="product-line-label" className={styles.productLines}>
      {' '}
      {ADMIN_LABELS.PRODUCT_LINE}
      {' '}
    </Label>
  );

  renderRadioButtons = () => {
    const howMany = this.assignedProductLines.length;
    switch (howMany) {
      case null:
      case undefined:
      case 0:
        return ProductLineSelect.renderNoProductLine(); // there's nothing
      case 1:
        return this.renderSingleProductLine(); // just the name, not radio buttons
      default:
        return this.renderMultipleProductLines(); // radio buttons
    }
  };

  static renderNoProductLine = () => (null);

  renderSingleProductLine = () => (
    <Label
      data-test="single-product-line"
      className={styles.singleProductLine}
    >
      {this.assignedProductLines[0].label}
    </Label>
  );

  renderMultipleProductLines = () => (
    <div>
      <RadioGroup name={ADMIN_KEYS.PRODUCT_LINE} data-test="product-line-radio-group">
        {[...this.assignedProductLines]
          .map(this.mapToRadioButtons)}
      </RadioGroup>
    </div>);

  render() {
    return (
      <div className={styles.productLinesContainer}>
        <FormControl>
          {ProductLineSelect.renderLabel()}
          {this.renderRadioButtons()}
        </FormControl>
      </div>
    );
  }
}

export type ProductLineSelectionChangedEvent = { target: { value: ProductLine } };

export type ProductLineSelectProps = {
  disabled?: boolean,
  primaryProductLine: ProductLine,
  secondaryProductLines: ProductLine[],
  selectedProductLine: ProductLine,
  onSelectionChanged: (productLine: ProductLine) => void
};

function mapStateToProps(state: ReduxState) {
  return {
    selectedProductLine: state.selectedProductLine,
    primaryProductLine: state.primaryProductLine,
    secondaryProductLines: state.secondaryProductLines,
  };
}

export default connect<any, any, any, ReduxState>(mapStateToProps, {
})(ProductLineSelect);
