import { postRequest } from './requestService';
import { getProjectionsBaseUrl } from '../util/apiUtil';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export async function postCsv(
  file: string | File,
  type: string,
  region: string,
  onSuccess: (data: any, type?: string) => void,
  onError: (error: any) => void
) {
  const url = `${getProjectionsBaseUrl()}/csv/${type}`;
  const formData = new FormData();
  formData.append('csvFile', file);
  const params = { region };
  try {
    const response = await postRequest(url, formData, params);
    onSuccess(response.data, type);
  } catch (e) {
    onError(e);
  }
}
