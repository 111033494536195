import React from 'react';
import { ProductLine, RegionDto, UserRegionDto } from '@trucktrax/trucktrax-ts-common';
import LogIn from './components/login/LoginView';
import App, { AppPropsForPanels } from './App';
import DriversView from './components/admin/drivers/DriversView';
import PlantsView from './components/admin/plants/PlantsView';
import UsersView from './components/admin/users/UsersView';
import TrucksView from './components/admin/trucks/TrucksView';
import DeviceDetailView from './components/admin/devices/DeviceDetailView';
import DeviceDetailViewGlinx from './components/admin/devices/DeviceDetailViewGLinx';
import RegionDetailView from './components/admin/regions/RegionDetailView';
import DevicesView from './components/admin/devices/DevicesView';
import GeozoneDetailView from './components/admin/geozones/GeozoneDetailView';
import GeozonesView from './components/admin/geozones/GeozonesView';
import HaulersView from './components/admin/haulers/HaulersView';
import ScalesView from './components/admin/scales/ScalesView';
import RegionsView from './components/admin/regions/RegionsView';
import ChecklistsView from './components/admin/checklists/ChecklistsView';
import TicketsView from './components/admin/tickets/TicketsView';
import ChecklistDetailView from './components/admin/checklists/ChecklistDetailView';
import FeatureFlagsView from './components/admin/featureFlags/FeatureFlagsView';
import DriverDetailView from './components/admin/drivers/DriverDetailView';
import PlantDetailView from './components/admin/plants/PlantDetailView';
import UsersAdminDetails from './components/admin/users/UserDetailView';
import TrucksDetailView from './components/admin/trucks/TrucksDetailView';
import ScalesDetailView from './components/admin/scales/ScalesDetailView';
import AdminView from './components/admin/AdminView';
import DocsView from './components/geotrax/docs/DocsView';
import ScaleTraxDocsView from './components/scaletrax/docs/ScaleTraxDocsView';
import MapView from './components/geotrax/map/MapView';
import MapPanelContent from './components/shared/sidenav/MapPanelContent';
import AdminPanelContent from './components/shared/sidenav/AdminPanelContent';
import { DocsPanelContent } from './components/shared/sidenav/DocsPanelContent';
import ResetPasswordView from './components/resetPassword/ResetPasswordView';
import PermissionsView from './components/admin/permissions/PermissionsView';
import GroupDetailView from './components/admin/permissions/groups/GroupPermissionsView';
import UserDetailView from './components/admin/permissions/users/UserPermissionsView';
import ReportsView from './components/admin/reports/ReportsView';
import GuideWebView from './components/geotrax/docs/guides/GuideWebView';
import ScaleTraxGuideWebView from './components/scaletrax/docs/guides/ScaleTraxGuideWebView';
import GuideMobileView from './components/geotrax/docs/guides/GuideMobileView';
import GuideGeotraxLiteView from './components/geotrax/docs/guides/GuideGeotraxLiteView';
import ReleaseDocs from './components/geotrax/docs/releases/ReleaseDoc';
import OrdersPanelContent from './components/geotrax/orders/all/OrdersPanelContent';
import OrdersView from './components/geotrax/orders/all/OrdersView';
import OrderDetails from './components/geotrax/orders/all/OrderDetailView';
import WelcomeView from './components/welcome/WelcomeView';
import MessageCenterPopover from './components/shared/messages/MessageCenterPopover';
import {
  ADMIN_ROUTE, DOCS_ROUTE, HISTORY_ROUTE, MAP_ROUTE, ORDERS_ROUTE, RELEASENOTES_FOLDER, SCALETRAXRELEASENOTES_FOLDER,
} from './constants/appConstants';
import { MESSAGE_WINDOW, NOT_FOUND_PATH } from './constants/apiConstants';
import NotificationsView from './components/geotrax/orders/notifications/NotificationsView';
import RedirectRoute from './components/shared/routing/RedirectRoute';
import VideoListView from './components/geotrax/docs/videos/VideoListView';
import VideoDetailView from './components/geotrax/docs/videos/VideoDetailView';
import {
  DOCS_VIDEOS_MOBILE_FOLDER,
  DOCS_VIDEOS_MOBILE_PATH,
  DOCS_VIDEOS_MOBILE_PREFIX,
  DOCS_VIDEOS_MOBILE_TITLE,
  DOCS_VIDEOS_WEB_FOLDER,
  DOCS_VIDEOS_WEB_PATH,
  DOCS_VIDEOS_WEB_PREFIX,
  DOCS_VIDEOS_WEB_TITLE,
  SCALETRAX_DOCS_VIDEOS_WEB_FOLDER,
  SCALETRAX_DOCS_VIDEOS_WEB_PATH,
  SCALETRAX_DOCS_VIDEOS_WEB_PREFIX,
  SCALETRAX_DOCS_VIDEOS_WEB_TITLE,
} from './constants/docsConstants';
import ScaleTraxView from './components/scaletrax/ScaleTraxView';
import ScaleTraxTicketing from './components/scaletrax/ticketing/ScaleTraxTicketing';
import HaulersDetailView from './components/admin/haulers/HaulerDetailView';
import ScaleTraxDocsPanelContent from './components/shared/sidenav/ScaleTraxDocsPanelContent';
import ScaleTraxTicketsList from './components/scaletrax/ticketing/ScaleTraxTicketsList';
import ScaleTraxTicket from './components/scaletrax/ticketing/ScaleTraxTicket';
import VehicleTypesView from './components/admin/vehicleTypes/VehicleTypesView';
import VehicleTypesDetailView from './components/admin/vehicleTypes/VehicleTypesDetailView';
import RouteHistoryView from './components/geotrax/routeHistory/RouteHistoryView';
import RouteHistorySearchPanel from './components/geotrax/routeHistory/RouteHistoryPanelContainer';

export const publicRoutes = [
  {
    path: '/login',
    component: LogIn,
  },
  {
    path: '/resetPassword',
    component: ResetPasswordView,
  },
  {
    path: '/welcome',
    component: WelcomeView,
  },
];

export type DisplayRegion = {
  display?: string,
  value?: string,
  productLines: ProductLine[]
};

const geotraxRoutes = [
  {
    path: '/geotrax/map',
    exact: true,
    component: MapView,
    panelComponent: MapPanelContent,
    panelLabel: 'Map',
  },
  {
    path: '/geotrax/orders/all/:id',
    component: OrderDetails,
  },
  {
    path: '/geotrax/orders/all',
    component: OrdersView,
  },
  {
    path: '/geotrax/orders/notifications',
    component: NotificationsView,
  },
  {
    path: '/geotrax/orders',
    component: () => <RedirectRoute to="/geotrax/orders/all" />,
    panelComponent: OrdersPanelContent,
    panelLabel: 'Orders',
    routes: [],
  },
  {
    path: '/geotrax/docs',
    component: DocsView,
    panelComponent: DocsPanelContent,
    panelLabel: 'Docs',
    routes: [
      {
        name: 'User Guides Web',
        path: '/geotrax/docs/guide/web',
        component: GuideWebView,
      },
      {
        name: 'User Guides Mobile',
        path: '/geotrax/docs/guide/mobile',
        component: GuideMobileView,
      },
      {
        name: 'User Guides GeoTrax Lite',
        path: '/geotrax/docs/guide/geotraxlite',
        component: GuideGeotraxLiteView,
      },
      {
        name: 'ReleaseDocs',
        path: '/geotrax/docs/releases',
        component: ReleaseDocs,
        folder: RELEASENOTES_FOLDER,
      },
      {
        path: `${DOCS_VIDEOS_WEB_PATH}/video`,
        breadcrumb: DOCS_VIDEOS_WEB_TITLE,
        link: DOCS_VIDEOS_WEB_PATH,
        component: VideoDetailView,
        folder: DOCS_VIDEOS_WEB_FOLDER,
        prefix: DOCS_VIDEOS_WEB_PREFIX,
      },
      {
        path: `${DOCS_VIDEOS_MOBILE_PATH}/video`,
        breadcrumb: DOCS_VIDEOS_MOBILE_TITLE,
        link: DOCS_VIDEOS_MOBILE_PATH,
        component: VideoDetailView,
        folder: DOCS_VIDEOS_MOBILE_FOLDER,
        prefix: DOCS_VIDEOS_MOBILE_PREFIX,
      },
      {
        path: DOCS_VIDEOS_WEB_PATH,
        title: DOCS_VIDEOS_WEB_TITLE,
        component: VideoListView,
        folder: DOCS_VIDEOS_WEB_FOLDER,
        prefix: DOCS_VIDEOS_WEB_PREFIX,
      },
      {
        path: DOCS_VIDEOS_MOBILE_PATH,
        title: DOCS_VIDEOS_MOBILE_TITLE,
        component: VideoListView,
        folder: DOCS_VIDEOS_MOBILE_FOLDER,
        prefix: DOCS_VIDEOS_MOBILE_PREFIX,
      },
    ],
  },
  {
    path: '/geotrax/routeHistory',
    component: RouteHistoryView,
    panelComponent: RouteHistorySearchPanel,
    panelLabel: 'Route History',
    routes: [],
  },
  {
    path: MESSAGE_WINDOW,
    component: MessageCenterPopover,
    panelComponent: null,
  },
];

const adminRoutes = [
  {
    path: '/admin',
    component: AdminView,
    panelComponent: AdminPanelContent,
    panelLabel: 'Admin',
    routes: [
      {
        path: '/admin/drivers/deactivated',
        component: DriversView,
        deactivated: true,
        tab: 1,
      },
      {
        path: '/admin/drivers/:id',
        component: DriverDetailView,
      },
      {
        path: '/admin/drivers',
        component: DriversView,
        tab: 0,
      },
      {
        path: '/admin/users/deactivated',
        component: UsersView,
        deactivated: true,
        tab: 1,
      },
      {
        path: '/admin/users/:id',
        component: UsersAdminDetails,
      },
      {
        path: '/admin/users',
        component: UsersView,
        tab: 0,
      },
      {
        path: '/admin/trucks/deactivated',
        component: TrucksView,
        deactivated: true,
        tab: 1,
      },
      {
        path: '/admin/trucks/:id',
        component: TrucksDetailView,
      },
      {
        path: '/admin/trucks',
        component: TrucksView,
      },
      {
        path: '/admin/vehicletypes/:id',
        component: VehicleTypesDetailView,
      },
      {
        path: '/admin/vehicletypes',
        component: VehicleTypesView,
      },
      {
        path: '/admin/plants/deactivated',
        component: PlantsView,
        deactivated: true,
        tab: 1,
      },
      {
        path: '/admin/plants/:id',
        component: PlantDetailView,
      },
      {
        path: '/admin/plants',
        component: PlantsView,
        tab: 0,
      },
      {
        path: '/admin/devices/deactivated',
        component: DevicesView,
        deactivated: true,
        tab: 1,
      },
      {
        path: '/admin/devices/glinx/:id',
        component: DeviceDetailViewGlinx,
      },
      {
        path: '/admin/devices/:id',
        component: DeviceDetailView,
      },
      {
        path: '/admin/devices/glinx/:id',
        component: DeviceDetailViewGlinx,
      },
      {
        path: '/admin/devices/glinx',
        component: DevicesView,
        tab: 0,
      },
      {
        path: '/admin/devices',
        component: DevicesView,
        tab: 0,
      },
      {
        path: '/admin/haulers/:id',
        component: HaulersDetailView,
      },
      {
        path: '/admin/haulers',
        component: HaulersView,
      },
      {
        path: '/admin/scales/deactivated',
        component: ScalesView,
        deactivated: true,
        tab: 1,
      },
      {
        path: '/admin/scales/:id',
        component: ScalesDetailView,
      },
      {
        path: '/admin/scales',
        component: ScalesView,
        tab: 0,
      },
      {
        path: '/admin/geozones/:id',
        component: GeozoneDetailView,
      },
      {
        path: '/admin/geozones',
        component: GeozonesView,
      },
      {
        path: '/admin/regions/:id',
        component: RegionDetailView,
      },
      {
        path: '/admin/regions',
        component: RegionsView,
      },
      {
        path: '/admin/checklists/:id',
        component: ChecklistDetailView,
      },
      {
        path: '/admin/checklists',
        component: ChecklistsView,
      },
      {
        path: '/admin/tickets',
        component: TicketsView,
      },
      {
        path: '/admin/permissions/groups/:id',
        component: GroupDetailView,
      },
      {
        path: '/admin/permissions/groups',
        component: PermissionsView,
        tab: 0,
      },
      {
        path: '/admin/permissions/users/:id',
        component: UserDetailView,
      },
      {
        path: '/admin/permissions/users',
        component: PermissionsView,
        tab: 1,
      },
      {
        path: '/admin/reports',
        component: ReportsView,
      },
      {
        path: '/admin/permissions',
        component: () => <RedirectRoute to="/admin/permissions/groups" />,
      },
    ],
  },
];

const scaleTraxRoutes = [
  {
    panelComponent: () => <></>,
    name: 'ScaleTrax',
    path: '/scaletrax/ticketing',
    component: ScaleTraxView,
    routes: [
      {
        name: 'ScaleTrax Ticketing',
        path: '/scaletrax/ticketing',
        component: ScaleTraxTicketing,
      },
    ],
  },
  {
    path: '/scaletrax/scaletraxticketslist/:id',
    component: ScaleTraxTicket,
  },
  {
    name: 'Search Tickets',
    path: '/scaletrax/scaletraxticketslist',
    component: ScaleTraxTicketsList,
  },
  {
    name: 'Search Tickets',
    path: '/scaletrax/scaletraxticketslist',
    component: ScaleTraxTicketsList,
  },
  {
    component: ScaleTraxDocsView,
    panelLabel: 'Docs',
    path: '/scaletrax/docs',
    panelComponent: ScaleTraxDocsPanelContent,
    routes: [
      {
        name: 'User Guides Web',
        path: '/scaletrax/docs/guide/web',
        component: ScaleTraxGuideWebView,
      },
      {
        name: 'ReleaseDocs',
        path: '/scaletrax/docs/releases',
        component: ReleaseDocs,
        folder: SCALETRAXRELEASENOTES_FOLDER,
      },
      {
        path: `${SCALETRAX_DOCS_VIDEOS_WEB_PATH}/video`,
        breadcrumb: SCALETRAX_DOCS_VIDEOS_WEB_TITLE,
        link: SCALETRAX_DOCS_VIDEOS_WEB_PATH,
        component: VideoDetailView,
        folder: SCALETRAX_DOCS_VIDEOS_WEB_FOLDER,
        prefix: SCALETRAX_DOCS_VIDEOS_WEB_PREFIX,
      },
      {
        path: SCALETRAX_DOCS_VIDEOS_WEB_PATH,
        title: SCALETRAX_DOCS_VIDEOS_WEB_TITLE,
        component: VideoListView,
        folder: SCALETRAX_DOCS_VIDEOS_WEB_FOLDER,
        prefix: SCALETRAX_DOCS_VIDEOS_WEB_PREFIX,
      },
    ],
  },
  {
    panelComponent: () => <></>,
    name: 'ScaleTrax',
    path: '/scaletrax',
    component: ScaleTraxView,
    routes: [],
  },
];

export const privateRoutes = [
  {
    path: '/',
    component: App,
    routes: [
      ...geotraxRoutes,
      ...adminRoutes,
      ...scaleTraxRoutes,
    ],
  },
];

export const featureflagRoute = {
  key: 'featureflags-route',
  name: 'Feature Flags',
  path: '/admin/featureflags',
  component: FeatureFlagsView,
};

export const featureflagRouteRedirect = {
  key: 'featureflags-redirect',
  from: '/admin/featureflags',
};

export const adminRouteNotFound = {
  key: 'admin-notfound',
  from: '/admin/*',
  to: NOT_FOUND_PATH,
};

export const adminRouteRedirect = {
  key: 'admin-redirect',
  from: '/admin',
};

export const mapRouteNotFound = {
  key: 'map-notfound',
  from: '/*',
  to: NOT_FOUND_PATH,
};

export const mapRouteRedirect = {
  key: 'map-redirect',
  from: '/',
  to: '/geotrax/map',
};

export const docsRouteNotFound = {
  key: 'docs-notfound',
  from: '/geotrax/docs/*',
  to: NOT_FOUND_PATH,
};

export const docsRouteRedirect = {
  key: 'docs-redirect',
  from: '/geotrax/docs',
  to: '/geotrax/docs/guide/web',
};

export const scaleTraxDocsRouteNotFound = {
  key: 'scaletraxdocs-notfound',
  from: '/scaletrax/docs/*',
  to: NOT_FOUND_PATH,
};

export const scaleTraxDocsRouteRedirect = {
  key: 'scaletraxdocs-redirect',
  from: '/scaletrax/docs',
  to: '/scaletrax/docs/guide/web',
};

export const scaleTraxRouteNotFound = {
  key: 'scaletrax-notfound',
  from: '/scaletrax/*',
  to: NOT_FOUND_PATH,
};

export const scaleTraxRouteTicketsListRedirect = {
  key: 'scaletrax-redirect',
  from: '/scaletrax/scaletraxticketslist',
  to: '/scaletrax/scaletraxticketslist',
};

export const scaleTraxRouteRedirect = {
  key: 'scaletrax-redirect',
  from: '/scaletrax',
  to: '/scaletrax/ticketing',
};

export const getCurrentRegionId = (
  region: UserRegionDto & { name: string } | undefined,
  defaultValue: '' | null
) => region?.url || defaultValue;

export const toDisplayRegions = (r: RegionDto): DisplayRegion => ({ display: r.name, value: r.url, productLines: r.productLines ?? [] });

// props for order panel component
const getOrderProps = (appProps: AppPropsForPanels) => ({
  regions: appProps.regions ?? [],
  currentRegionId: getCurrentRegionId(appProps.currentRegion, ''),
  currentRegion: appProps.currentRegion,
  onRegionChange: appProps.changeRegion,
  geotraxPage: appProps.geotraxPage,
});

// props for map panel component
const getMapProps = (appProps: AppPropsForPanels) => ({
  regions: appProps.regions ?? [],
  currentRegionId: getCurrentRegionId(appProps.currentRegion, ''),
  onRegionChange: appProps.changeRegion,
  activeTrucks: appProps.activeTrucks,
  selectedMarker: appProps.selectedMarker,
  dropdownIsOpen: appProps.dropdownIsOpen,
  openStateMap: appProps.openStateMap,
  toggleDropdownIsOpen: appProps.toggleDropdownIsOpen,
  setOpenStateMap: appProps.checkSetOpenStateMap,
  primaryProductLine: appProps.primaryProductLine,
  secondaryProductLine: appProps.secondaryProductLine,
  secondaryProductLines: appProps.secondaryProductLines,
  updateMap: appProps.updateMap,
  currentRegion: appProps.currentRegion,
  geotraxPage: appProps.geotraxPage,
});

// props for admin panel component
const getAdminProps = (appProps: AppPropsForPanels) => ({
  regions: appProps.regions ?? [],
  currentRegionId: getCurrentRegionId(appProps.currentRegion, null),
  onRegionChange: appProps.changeRegion,
  categories: appProps.categories,
});

// props for docs panel component
const getDocsProps = (appProps: AppPropsForPanels) => ({
  dropdownIsOpen: appProps.guideDropdownIsOpen,
  toggleDropdownIsOpen: appProps.toggleGuideDropdownIsOpen,
  geotraxPage: appProps.geotraxPage,
  currentRegion: appProps.currentRegion,
  userPermission: appProps.userPermission,
});

// props for route history component
const getRouteHistoryProps = (appProps: AppPropsForPanels) => ({
  activeTrucks: appProps.activeTrucks,
  currentRegion: appProps.currentRegion,
  currentRegionId: getCurrentRegionId(appProps.currentRegion, ''),
  dropdownIsOpen: appProps.dropdownIsOpen,
  geotraxPage: appProps.geotraxPage,
  openStateMap: appProps.openStateMap,
  primaryProductLine: appProps.primaryProductLine,
  regions: appProps.regions?.map(toDisplayRegions),
});

export type RouteInfo = {
  path: string;
  component: React.ComponentType;
  panelLabel?: string;
  panelComponent?: React.ComponentType<{ test?: string }>;
};

export const getPanelProps = (appProps: AppPropsForPanels, route: RouteInfo) => {
  const routeDictionary = [
    { name: MAP_ROUTE, action: getMapProps },
    { name: ADMIN_ROUTE, action: getAdminProps },
    { name: DOCS_ROUTE, action: getDocsProps },
    { name: ORDERS_ROUTE, action: getOrderProps },
    { name: HISTORY_ROUTE, action: getRouteHistoryProps },
    { name: '', action: () => ({}) },
  ];

  const getProps = routeDictionary
    .filter(({ name }) => route.path.includes(name))
    .map(({ action }) => action)[0];

  return getProps(appProps);
};
