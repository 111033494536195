import React, { Component } from 'react';
import { TTDatePicker, DateWrapper } from '@trucktrax/trucktrax-common';
import styles from './DateRangePicker.module.css';
import { noop } from '../../util/appUtil';
import { DATE_INPUT_FORMAT } from '../../constants/appConstants';
import { SimpleEvent } from '../../types';

export interface DateRangePickerState {
  startDate?: Date;
  endDate?: Date;
}

export class DateRangePicker extends Component<DateRangePickerProps, DateRangePickerState> {
  static defaultProps: Partial<DateRangePickerProps> = {
    startDate: undefined,
    endDate: undefined,
    shouldDisableFuture: true,
    onChange: noop,
    minDate: '',
    maxDate: '',
  };

  constructor(props: DateRangePickerProps) {
    super(props);

    const { startDate, endDate } = props;

    this.state = {
      startDate,
      endDate,
    };
  }

  onStartDateChange = (value: string | Date) => {
    if (!DateWrapper.isValidDate(value)) { return; }

    const { endDate } = this.state;
    const newStartDate = new Date(value);
    let newEndDate = endDate ?? newStartDate;
    if (newStartDate > newEndDate) {
      newEndDate = newStartDate;
    }
    this.setDateRangeState(new Date(newStartDate), new Date(newEndDate));
  };

  onEndDateChange = (value: string | Date) => {
    if (!DateWrapper.isValidDate(value)) { return; }

    const { startDate } = this.state;
    const newEndDate = new Date(value);
    let newStartDate = startDate ?? newEndDate;
    if (newEndDate < newStartDate) {
      newStartDate = newEndDate;
    }
    this.setDateRangeState(new Date(newStartDate), new Date(newEndDate));
  };

  setDateRangeState = (startDate: Date, endDate: Date) => {
    this.setState({ startDate, endDate }, () => {
      this.onDateRangeChange();
    });
  };

  // The on Blur event guarantee that date fields are always valid.
  onStartDateBlur = (event: SimpleEvent) => {
    if (DateWrapper.isValidDate(event.target.value)) { return; }

    const { startDate } = this.state;
    event.target.value = new DateWrapper(startDate!).toUSDateString();
    this.onStartDateChange(startDate!);
  };

  onEndDateBlur = (event: SimpleEvent) => {
    if (DateWrapper.isValidDate(event.target.value)) { return; }

    const { endDate } = this.state;
    event.target.value = new DateWrapper(endDate!).toUSDateString();
    this.onEndDateChange(endDate!);
  };

  onDateRangeChange = () => {
    const { startDate, endDate } = this.state;
    if (!DateWrapper.isValidDate([startDate, endDate])) { return; }

    this.props.onChange!(this.state);
  };

  render() {
    return (
      <div className={styles.wrapper}>
        {/* Start Date modal */}
        {
          !this.props.hideLabel
          && (
            <span className={styles.dateRange}>
              <span>Date&nbsp;</span>
              <span>Range:</span>
            </span>
          )
        }
        <div className={styles.dateField}>
          <TTDatePicker
            dataTest="dataBlock-startDate-picker"
            value={this.state.startDate}
            isRequired
            label=""
            onChangeCallback={this.onStartDateChange}
            onBlurCallback={this.onStartDateBlur}
            shouldDisableFuture={this.props.shouldDisableFuture}
            placeholder={DATE_INPUT_FORMAT}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
          />
        </div>
        <span className={styles.spacing}>&ndash;</span>
        {/* End Date modal */}
        <div className={styles.dateField}>
          <TTDatePicker
            dataTest="dataBlock-endDate-picker"
            value={this.state.endDate}
            isRequired
            label=""
            onChangeCallback={this.onEndDateChange}
            onBlurCallback={this.onEndDateBlur}
            shouldDisableFuture={this.props.shouldDisableFuture}
            placeholder={DATE_INPUT_FORMAT}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
          />
        </div>
      </div>
    );
  }
}

export interface DateRangePickerProps {
  startDate?: Date;
  endDate?: Date;
  shouldDisableFuture?: boolean;
  onChange?: (state: DateRangePickerState) => void;
  minDate: string;
  maxDate: string;
  hideLabel?: boolean;
}

export default DateRangePicker;
