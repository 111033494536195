import React, { Component } from 'react';
import cx from 'classnames';
import { FloatingActionButton } from '@trucktrax/trucktrax-common';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import { connect } from 'react-redux';
import { createDataTableRecord } from '../../../services/dataTableService';
import { GEOZONES_PATH } from '../../../constants/apiConstants';
import { onSubmitSuccess } from '../../../util/adminUtil';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import AdminAddModal from '../../shared/admin/AdminAddModal';
import { ADD_GEOZONE_ERROR_MESSAGE } from '../../../constants/errorConstants';
import { ADD_GEOZONE_SUCCESS_TEXT } from '../../../constants/successConstants';
import {
  ADD_CUSTOM_GEOZONE_MODAL_TITLE,
  ADD_CUSTOM_GEOZONE_BUTTON_TEXT,
  ADMIN_KEYS,
  ADMIN_LABELS,
  CIRCLE,
  POLYGON,
  OR,
  INPUT_FORM,
  MAP_FORM,
  LOCATION,
} from '../../../constants/appConstants';
import styles from './GeozonesAddModal.module.css';
import { noop } from '../../../util/appUtil';
import AddFeatureModal from '../../shared/AddFeatureModal';
import { ReduxState } from '../../../store';
import { checkNullInRequired } from '../../../util/validation';

export class GeozonesAddModal extends Component<GeozoneAddModalProps> {
  onSubmit = (dto: any, onError: any, onSuccess: any) => {
    const { createAdminTableData: post } = this.props;
    const toastMessages = {
      success: ADD_GEOZONE_SUCCESS_TEXT,
      fail: ADD_GEOZONE_ERROR_MESSAGE,
    };

    const currentRegionUrl = this.props.currentRegion!.url;
    const customGeozoneUrl = getGeotraxBaseUrl() + GEOZONES_PATH;
    const callbacks = [this.props.discardAcceptAction, onSuccess];

    const {
      circle,
      polygon,
      name,
      description,
      location,
    } = dto;

    const geoZoneDto = {
      name,
      zone: {
        circle,
        polygon,
      },
      region: { url: currentRegionUrl },
      description,
      location,
    };

    post(
      customGeozoneUrl,
      geoZoneDto,
      () => onSubmitSuccess(callbacks),
      onError,
      toastMessages,
      true,
      currentRegionUrl
    );
  };

  config = () => [{
    type: INPUT_FORM,
    key: ADMIN_KEYS.NAME,
    label: ADMIN_LABELS.NAME,
    maxLength: 25,
    dataTest: 'custom-geozone-name-input-data-test',
    errorDataTest: 'custom-geozone-name-input-missing-error',
    className: cx('halfWidthSpace', 'baseMargin'),
    isRequired: true,
    customValidation: checkNullInRequired,
  }, {
    type: INPUT_FORM,
    key: ADMIN_KEYS.DESCRIPTION,
    label: ADMIN_LABELS.DESCRIPTION,
    dataTest: 'description-input-data-test',
    errorDataTest: 'description-input-missing-error',
    className: 'widthSpace',
    maxLength: 50,
    isRequired: false,
  },
  {
    type: MAP_FORM,
    key: ADMIN_LABELS.GEOFENCE,
    label: ADMIN_LABELS.GEOZONE,
    isEditable: true,
    resetBtn: true,
    accessor: (x: any) => x,
    className: styles.map,
    showDrawingManager: true,
    defaultMapZoom: 4,
    showGeoZone: true,
    showMarker: true,
    isRequired: true,
    containerStyle: {
      height: '300px',
      width: '100%',
      position: 'relative',
    },
  },
  {
    // Sets initial state for location to show USA map
    type: 'default',
    key: 'location',
    initialSelected: {
      value: this.getCurrentRegionLocation(),
    },
  }];

  getCurrentRegionLocation() {
    const { currentRegion, regionList } = this.props;

    const selectedRegion = regionList?.find(r => r.url === currentRegion?.url);

    return {
      latitude: selectedRegion?.location?.lat || selectedRegion?.location?.latitude,
      longitude: selectedRegion?.location?.lng || selectedRegion?.location?.longitude,
    };
  }

  render() {
    return (
      <div>
        <FloatingActionButton onClick={this.props.openModal} />
        <AddFeatureModal
          title={ADD_CUSTOM_GEOZONE_MODAL_TITLE}
          isOpen={this.props.isOpen}
          onCancel={this.props.closeModal}
          style={styles.geozonesAddModal}
        >
          <AdminAddModal
            config={this.config()}
            options={{
              requiredKeys: [{
                keys: [CIRCLE, POLYGON, LOCATION],
                relationship: OR,
              }],
            }}
            addButtonText={ADD_CUSTOM_GEOZONE_BUTTON_TEXT}
            addButtonDataTest="custom_geozones-add-button-data-test"
            onRightBtnClick={this.onSubmit}
            firstModal={false}
            discardAcceptAction={this.props.discardAcceptAction}
            discardRejectAction={this.props.discardRejectAction}
            isCloseRequest={this.props.isCloseRequest}
          />
        </AddFeatureModal>
      </div>
    );
  }
}

interface GeozoneAddModalOwnProps {
  createAdminTableData: typeof createDataTableRecord,
  openModal: VoidFunc,
  closeModal: VoidFunc,
  discardAcceptAction: typeof noop,
  discardRejectAction: typeof noop,
  isOpen: boolean,
  isCloseRequest: boolean,
}
const mapStateToProps = (state: Partial<ReduxState>) => ({
  currentRegion: state.currentRegion,
  regionList: state.regionList,
});

type ReduxStateProps = ReturnType<typeof mapStateToProps>;
export type GeozoneAddModalProps = ReduxStateProps & GeozoneAddModalOwnProps;

export default connect<any, any, any>(mapStateToProps, {
  createAdminTableData: createDataTableRecord,
})(GeozonesAddModal);
