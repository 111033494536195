import { PositionDto } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { getRequest } from './requestService';
import { getTicketingBaseUrl } from '../util/apiUtil';
import { devErrorAndLog } from '../util/errorUtil';

export async function getClusteredLocations(ticketUrl?: string, startTimestamp?: string, endTimestamp?: string) {
  // GET route statuses
  const url = `${getTicketingBaseUrl()}/geolocations/cluster`;
  let params = {
    ticket: ticketUrl,
    startTimestamp,
  };
  if (endTimestamp) {
    params = Object.assign(params, { endTimestamp });
  }

  try {
    const response = await getRequest(url, params);
    const sortedPositions = response.data.items
      .sort((x: PositionDto, y: PositionDto) => DateWrapper.sort(x.timestamp, y.timestamp));
    return sortedPositions as PositionDto[];
  } catch (e: any) {
    devErrorAndLog(
      'Unable to retrieve clustered timestamps',
      `geolocationsService: url: ${url} + params: + ${JSON.stringify(params)}`,
      e.toString()
    );

    return [];
  }
}

export async function getLocations(ticketUrl?: string, timestampAfter?: string, timestampBefore?: string) {
  const url = `${getTicketingBaseUrl()}/geolocations`;
  let params = {
    ticket: ticketUrl,
    timestampAfter,
  };
  if (timestampBefore) {
    params = Object.assign(params, { timestampBefore });
  }

  try {
    const response = await getRequest(url, params);
    const sortedPositions = response.data.items
      .sort((x: PositionDto, y: PositionDto) => DateWrapper.sort(x.timestamp, y.timestamp));
    return sortedPositions as PositionDto[];
  } catch (e: any) {
    devErrorAndLog(
      'Unable to retrieve geolocations',
      `geolocationsService: url: ${url} + params: + ${JSON.stringify(params)}`,
      e.toString()
    );

    return [];
  }
}
