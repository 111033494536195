const tenantsIcon = 'icon-home';
const driversIcon = 'icon-wheel';
const usersIcon = 'icon-headset-mic';
const regionsIcon = 'icon-map';

export const FEATURE_ITEM_EXAMPLES: { [key: string]: string } = {
  driversData: 'Ella Dorband',
  usersData: 'Ella Dorband',
  regionsData: 'Beaverton',
  tenantsData: 'Calportland',
};

export const FEATURES_DROPDOWN_LIST = [
  {
    icon: driversIcon,
    label: 'Drivers',
    dataSource: 'driversData',
  },
  {
    icon: usersIcon,
    label: 'Users',
    dataSource: 'usersData',
  },
  {
    icon: regionsIcon,
    label: 'Regions',
    dataSource: 'regionsData',
  },
  {
    icon: tenantsIcon,
    label: 'Tenants',
    dataSource: 'tenantsData',
  },
];

export const constants = {
  CANCEL_LABEL: 'Cancel',
  DISCARD_LABEL: 'Discard Changes',
  ADD_FEATURE_FLAG: 'Add Feature Flag',
  APPLY_CHANGES: 'Apply Changes',
};

export const ICON = {
  drivers: driversIcon,
  users: usersIcon,
  regions: regionsIcon,
  tenants: tenantsIcon,
};
