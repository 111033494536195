import React, { Component } from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import { Link, Route, Switch } from 'react-router-dom';
import {
  LeftNav, Modal, Notification, SidebarSelection, Snackbar,
} from '@trucktrax/trucktrax-common';
import { ModalType } from '@trucktrax/trucktrax-common/build/constants';
import {
  DriverDto,
  RegionDto,
  PermissionAccess,
  ProductLine,
  QualityFactorDto,
  UrlKeyDto,
  UserRegionDto,
  VoidFunc,
} from '@trucktrax/trucktrax-ts-common';
import axios from 'axios';
import {
  ConnectedDispatchFunction,
  TruckInfo,
  ModalOptions,
  PositionDetailsDto,
  SidebarTitleType,
  ConnectedFunction,
  TruckTraxApps,
} from './types';
import { logOut } from './services/authService';
import {
  setPrimaryProductLine,
  setSecondaryProductLine,
  setSecondaryProductLines,
  setSelectedProductLine,
} from './services/productLinesService';
import { closeModal, openModal } from './store/actions/errorModalActions';
import {
  closeSnackbar,
  closeSnackbarNotification,
  openSnackbar,
  SnackbarOptions,
} from './store/actions/snackbarActions';
import { fetchAssignedRegionList } from './services/regionsService';
import { messageSocketInit } from './services/messagesService';
import { ticketSocketInit } from './services/ticketsService';
import styles from './App.module.css';
import { getTrucksInCurrentRegion, getTruckToDriverMap } from './util/positionsUtil';
import { getPrimaryProductLineFromToken, getSecondaryProductLineFromToken, getSecondaryProductLinesFromToken } from './util/userUtil';
import { hasAppAccessInAnyRegion, RegionAccessMap } from './util/permissionUtil';
import { MarkerInfo, refreshPositions, selectMarker } from './store/actions/mapviewActions';
import { setCurrentAppName } from './store/actions/appActions';
import { getMapview } from './services/mapviewsService';
import {
  AdminCategory,
  categoriesForAdmin,
  geotraxNavItems,
  adminNavItems,
  scaleTraxNavItems,
  PLANTS_TEXT,
  HAULERS_TEXT,
  TRUCKS_TEXT,
  GEOZONES_TEXT,
  VEHICLE_TYPES_TEXT,
  VEHICLES_TEXT,
  NavItem,
  featureFlagVehicleNavItem,
} from './constants/navConstants';
import {
  messagesActionItems,
  ticketingActionItems,
  appSelectorActionItems,
  notebookActionItems,
} from './util/navUtil';
import MessageCenterContainer from './components/shared/messages/MessageCenterContainer';
import appVersionAndEnvString, { noop } from './util/appUtil';
import { setReduxDriverList } from './services/driversService';
import { fetchPlantListAndMapFilters } from './services/plantsService';
import { fetchUsers } from './services/usersService';
import { adminPermissionForUserInCurrentRegion, fetchPermissionsListByUserUrl } from './services/permissionsService';
import RouteWithSubRoutes from './components/shared/routing/RouteWithSubRoutes';
import RedirectRoute from './components/shared/routing/RedirectRoute';
import {
  adminRouteNotFound, docsRouteNotFound, getPanelProps, mapRouteNotFound, mapRouteRedirect, RouteInfo,
} from './routes';
import setSidebarSelected, { setOpenStateMap, toggleDropdownIsOpen, toggleGuideDropdownIsOpen } from './store/actions/sidebarActions';
import { setSessionId, setUserFullName } from './store/actions/authActions';
import IdleService from './services/idleService';
import NotFoundView from './components/notFound/NotFoundView';
import { getTokens } from './util/authUtil';
import {
  DEFAULT,
  IDLE_TIMEOUT_BODY,
  LOGOUT,
  MESSAGE_SELECTED,
  NOTEBOOK_SELECTED,
  NotificationType,
  SIDENAV_REFRESH_IN_MILLISECONDS,
  TICKETS_SELECTED,
  CANCEL_LABEL,
  NEW_MESSAGE_WINDOW,
  SELECTED_PRODUCT_LINE,
  GEOTRAX_LITE_FLAG,
} from './constants/appConstants';
import { MESSAGE_WINDOW, NOT_FOUND_PATH } from './constants/apiConstants';
import { SnackbarNotificationState, SnackbarState } from './store/reducers/globalComponentsReducers';
import { MessageCenterState } from './store/reducers/messageCenterReducers';
import { AppName } from './store/reducers/appReducer';
import { ReduxState } from './store';
import NotebookPopover from './components/scaletrax/NotebookPopover';
import { refreshGeoTraxState } from './services/appService';
import TicketsPopover from './components/geotrax/ticketing/TicketsPopover';
import MessageCenterPopover from './components/shared/messages/MessageCenterPopover';
import geotraxPageSelected from './store/actions/geotraxActions';
import { IS_MESSAGE_WINDOW_OPEN, OPEN_SIDEBAR_MESSAGES } from './constants/localStorageConstants';
import logoAdminSvg from './assets/img/logosAdmin.svg';
import logoGeotraxSvg from './assets/img/logosGeotrax.svg';
import logoScaletraxSvg from './assets/img/logosScaletrax.svg';
import { isGeoTraxPage } from './util/adminUtil';
import { isFeatureFlagEnabled } from './util/featureFlagUtil';

const PanelLabelWrapper = (route: any) => (route.panelLabel ? (
  <h3 className="page-title">{route.panelLabel}</h3>
) : <></>);

export const renderSidebarInnerComponents = (appProps: AppPropsForPanels) => (route: RouteInfo, index: number) => {
  const PanelContent = route.panelComponent;
  const panelProps = getPanelProps(appProps, route);

  return PanelContent && (
    <Route
      key={`${route.path.split('/')[1]}-content-${index}`}
      path={route.path}
      render={() => (
        <PanelContent
          test={route.panelLabel}
          {...panelProps}
        />
      )}
    />
  );
};

export class App extends Component<AppProps, AppState> {
  static defaultProps: Partial<AppProps> = {
    modalType: DEFAULT,
    acceptDialog: noop,
    selectedMarker: undefined,
    positions: [],
    messageSubscription: [{
      unsubscribe: () => { },
    }],
    regions: [{
      name: '',
      url: '',
      archived: false,
      deleted: false,
      id: 0,
    }],
    currentRegion: { url: '', defaultRegion: false, name: '' },
    drivers: [],
    dataTestCancel: undefined,
    dataTestOk: undefined,
    routes: [],
    sidebarSelection: undefined,
    currentTicketQualityFactors: [],
    acceptText: undefined,
    selectedProductLine: ProductLine.None,
    primaryProductLine: ProductLine.None,
    secondaryProductLine: ProductLine.None,
    secondaryProductLines: [],
    userPermission: {},
    idleTime: 120,
    sessionId: null,
    userUrl: undefined,
    cancelText: undefined,
    disabled: false,
    noActions: false,
    geotraxPage: undefined,
  };

  constructor(props: AppProps) {
    super(props);

    this.state = { adminAccess: [], isTicketsOpen: false };
  }

  signal = axios.CancelToken.source();

  primaryProductLine: ProductLine | null = null;

  secondaryProductLine: ProductLine | null = null;

  secondaryProductLines: ProductLine[] | null = [];

  selectedProductLine: ProductLine | null = null;

  trucksInterval?: NodeJS.Timeout;

  userFullName: string = '';

  componentDidMount() {
    const isWindowOpen = window.localStorage.getItem(IS_MESSAGE_WINDOW_OPEN);
    // Event listener to local storage message window workflow
    window.addEventListener('storage', (event) => {
      // Check if the a local storage change
      if (event.storageArea !== localStorage) return;
      // Check if the change is for the messages workflow
      if (event.key === OPEN_SIDEBAR_MESSAGES) {
        // Check the window messages flags
        const openSidebar = window.localStorage.getItem(OPEN_SIDEBAR_MESSAGES);
        if (openSidebar === 'true') {
          // Remove the local storage variables
          window.localStorage.removeItem(OPEN_SIDEBAR_MESSAGES);
          window.localStorage.removeItem(IS_MESSAGE_WINDOW_OPEN);
          // Open the messages sidebar
          this.props.setSidebarSelected!(MESSAGE_SELECTED, true);
        }
      }
    });
    const { idleTime } = this.props;
    new IdleService().init(idleTime ?? 120, this.handleIdleTime);
    this.startTrucksInterval();

    const { accessToken } = getTokens();
    this.primaryProductLine = getPrimaryProductLineFromToken(accessToken!) || null;
    this.secondaryProductLine = getSecondaryProductLineFromToken(accessToken!) || null;
    this.secondaryProductLines = getSecondaryProductLinesFromToken(accessToken!) || null;
    this.selectedProductLine = localStorage.getItem(SELECTED_PRODUCT_LINE) as ProductLine;
    this.props.setPrimaryProductLine(this.primaryProductLine ?? ProductLine.None);
    this.props.setSecondaryProductLine(this.secondaryProductLine ?? ProductLine.None);
    this.props.setSecondaryProductLines(this.secondaryProductLines ?? []);

    if (isWindowOpen !== 'true') {
      this.props.setSelectedProductLine(this.selectedProductLine ?? this.primaryProductLine ?? ProductLine.None);
    }
    this.props.setUserFullName(this.userFullName);

    this.props.fetchAssignedRegionList(this.socketInit, this.props.getMapview);
    if (this.props.currentRegion?.url) {
      this.fetchAdminData();
      this.fetchGeoTraxData();
    }

    this.initializePermissions();
    this.updateTicketsOpen();
    this.props.setCurrentAppName(this.normalizedAppPath as AppName);
  }

  handleIdleTime = () => {
    if (getTokens().accessToken === null) {
      return;
    }
    const { sessionId } = this.props;
    this.props.logOut();
    if (sessionId !== null) {
      this.props.openSnackbar({
        snackbarBody: IDLE_TIMEOUT_BODY,
        snackbarType: DEFAULT,
      });
      this.props.setSessionId('');
    }
  };

  async componentDidUpdate(prevProps: AppProps) {
    let isMessageWindowOutdate = false;
    if (!isEqual(this.props.currentRegion, prevProps.currentRegion)) {
      await this.initializePermissions();
      this.fetchAdminData();
      this.fetchGeoTraxData();
      this.setAdminCategoryAccess();
      // Check if the message window is open
      const isWindowOpen = window.localStorage.getItem(IS_MESSAGE_WINDOW_OPEN);
      if (isWindowOpen === 'true') {
        // Refresh the message window
        isMessageWindowOutdate = true;
      }
    }
    if (this.props.userPermission !== prevProps.userPermission) {
      this.setAdminCategoryAccess();
    }
    this.updateTicketsOpen();
    // Is Message Window outdate
    if (isMessageWindowOutdate) {
      // Refresh the Message Window
      App.refreshMessageWindow();
    }
  }

  componentWillUnmount() {
    this.clearTrucksInterval();
  }

  updateTicketsOpen = () => {
    const isTicketsSidebar = this.props.sidebarSelection?.sidebarTitle === TICKETS_SELECTED
      || this.props.sidebarSelection?.sidebarTitle === NOTEBOOK_SELECTED;

    const ticketsSidebarHasChanged = isTicketsSidebar && this.props.sidebarSelection.isSelected !== this.state.isTicketsOpen;

    if (ticketsSidebarHasChanged) {
      this.setState({
        isTicketsOpen: this.props.sidebarSelection.isSelected,
      });
    }
  };

  async initializePermissions() {
    const byPassRedisCache = this.normalizedAppPath === 'admin';
    await this.props.fetchPermissionsListByUserUrl(this.props.userUrl ?? '', byPassRedisCache);
  }

  socketInit = (defaultRegion: UrlKeyDto, currentSubscription?: any) => {
    this.props.messageSocketInit(defaultRegion, currentSubscription, this.props.selectedProductLine || ProductLine.None);
    ticketSocketInit(defaultRegion, this.props.selectedProductLine);
  };

  startTrucksInterval = () => {
    this.trucksInterval = global.setInterval(() => {
      this.props.refreshPositions();
    }, SIDENAV_REFRESH_IN_MILLISECONDS);
  };

  clearTrucksInterval = () => {
    global.clearInterval(this.trucksInterval!);
  };

  fetchGeoTraxData = () => {
    if (isGeoTraxPage(this.props.geotraxPage?.navRoute)) {
      this.props.setReduxDriverList(this.props.currentRegion?.url!, [], true);
    }
  };

  fetchAdminData = () => {
    if (this.props.currentRegion && this.props.geotraxPage) {
      this.props.setReduxDriverList(this.props.currentRegion.url!, [this.props.selectedProductLine], true);
      this.props.fetchPlantListAndMapFilters(
        this.props.currentRegion.url!,
        this.props.selectedProductLine as ProductLine,
        this.props.userUrl || undefined
      );
      this.props.fetchUsers('', this.signal.token);
    }
  };

  handleModalClose = () => {
    this.props.closeModal();
  };

  handleSnackbarClose = () => {
    this.props.closeSnackbar();
  };

  handleSnackbarNotificationClose = () => {
    this.props.closeSnackbarNotification();
  };

  changeRegion = async (regionUrl: string) => {
    const currentRegion = this.props.regions!.filter(region => region.url === regionUrl)[0];
    const { userUrl, messageSubscription } = this.props;
    const byPassRedisCache = this.normalizedAppPath === 'admin';
    const selectedProductLine = this.props.selectedProductLine ?? ProductLine.None;
    this.props.setSidebarSelected!(MESSAGE_SELECTED, false);
    this.props.refreshGeoTraxState(
      currentRegion,
      selectedProductLine,
      userUrl ?? undefined,
      messageSubscription,
      byPassRedisCache
    );
  };

  onClick = (item: NavItem) => {
    this.props.geotraxPageSelected!(item);
  };

  openLogOutDialog = () => {
    const modal: ModalOptions = {
      acceptDialog: this.props.logOut,
      modalType: DEFAULT,
      modalBody: 'Are you sure you want to log out?',
      modalTitle: 'Confirm Logout',
      modalOpen: true,
      acceptText: LOGOUT,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    };
    this.props.openModal(modal);
  };

  static renderAppSubroutes = (puRoute: RouteInfo) => (
    <RouteWithSubRoutes
      key={puRoute.path}
      {...puRoute}
    />
  );

  static renderSidebarLabels = (route: RouteInfo, index: number) => (
    <Route
      key={`${route.path.split('/')[1]}-label-${index}`}
      path={route.path}
      component={PanelLabelWrapper}
    />
  );

  getSortedTrucksInRegion() {
    const trucks = getTrucksInCurrentRegion(this.props.positions ?? [], this.props.currentRegion?.url ?? '');
    return sortBy(trucks, 'data.markerId');
  }

  getAppProps(categories: { admin: AdminCategory[] }, activeTrucks: TruckInfo[]): AppPropsForPanels {
    return {
      ...this.props,
      categories,
      activeTrucks,
      changeRegion: this.changeRegion,
      primaryProductLine: this.primaryProductLine ?? ProductLine.None,
      secondaryProductLine: this.secondaryProductLine ?? ProductLine.None,
      secondaryProductLines: this.secondaryProductLines ?? [],
      checkSetOpenStateMap: this.checkSetOpenStateMap,
      userFullName: this.userFullName,
    };
  }

  getAdminPermissionAccessForRegion = (category: { name: string }, currentRegion: string) => {
    let adminPermission = category.name;
    if (category.name === GEOZONES_TEXT) {
      adminPermission = PLANTS_TEXT;
    } else if (category.name === HAULERS_TEXT) {
      adminPermission = TRUCKS_TEXT;
    } else if (category.name === VEHICLE_TYPES_TEXT || category.name === VEHICLES_TEXT) {
      adminPermission = TRUCKS_TEXT;
    }

    return adminPermissionForUserInCurrentRegion(
      this.props.userPermission,
      currentRegion,
      adminPermission
    );
  };

  /**
   * Sets the visible admin categories based on permissions and feature flags
   */
  setAdminCategoryAccess = () => {
    const currentRegion = (this.props.currentRegion) ? this.props.currentRegion.url : '';

    const adminCategories = categoriesForAdmin.filter(
      c => this.getAdminPermissionAccessForRegion(c, currentRegion!) !== PermissionAccess.Deny
    );

    const modifiedAdminCategories = this.applyFeatureFlagsToAdminCategories(adminCategories);
    this.setState({ adminAccess: modifiedAdminCategories ?? adminCategories });
  };

  /**
  * @param flags the list of feature flags
  * @param categories the list of admin categories
  * @returns the modified list of admin categories or undefined if categories has no items
  */
  applyFeatureFlagsToAdminCategories = (categories: AdminCategory[]): AdminCategory[] | undefined => {
    if (categories?.length === 0) {
      return undefined;
    }
    const items = [...categories];
    const isGeotraxLiteEnabled = isFeatureFlagEnabled(
      this.props.userPermission,
      this.props.currentRegion?.url ?? '',
      GEOTRAX_LITE_FLAG
    );
    if (isGeotraxLiteEnabled) {
      items.splice(
        featureFlagVehicleNavItem.index,
        featureFlagVehicleNavItem.itemsOffset,
        ...featureFlagVehicleNavItem.items
      );
    }
    return items;
  };

  checkSetOpenStateMap = (stateMap: { [index: number]: boolean }) => {
    if (!isEqual(stateMap, this.props.openStateMap)) {
      this.props.setOpenStateMap(stateMap);
    }
  };

  getFeaturedNavItems() {
    const { location } = this.props;
    const [appPath] = location.pathname.substring(1).split('/');
    switch (appPath.toLowerCase()) {
      case 'admin':
        return adminNavItems;
      case 'geotrax':
        return geotraxNavItems;
      case 'scaletrax':
        return scaleTraxNavItems;
      default:
        return [];
    }
  }

  getFeaturedAppIcon() {
    switch (this.normalizedAppPath) {
      case 'admin':
        return logoAdminSvg;
      case 'geotrax':
        return logoGeotraxSvg;
      case 'scaletrax':
        return logoScaletraxSvg;
      default:
        return '';
    }
  }

  static refreshMessageWindow = () => {
    // Close the current window
    const openWindow = window.open('', NEW_MESSAGE_WINDOW);
    const response = openWindow?.close();
    // Open a new window
    window.open(MESSAGE_WINDOW, NEW_MESSAGE_WINDOW);
    window.localStorage.removeItem(OPEN_SIDEBAR_MESSAGES);
    return response;
  };

  get shouldIncludeMessageActions() {
    const appsWithMessageCenter = ['geotrax'];
    return appsWithMessageCenter.some(app => app === this.normalizedAppPath);
  }

  get shouldIncludeTicketActions() {
    const appsWithTicketing = ['geotrax'];
    return appsWithTicketing.some(app => app === this.normalizedAppPath);
  }

  get shouldIncludeNotebook() {
    const appsWithNotebook = ['scaletrax'];
    return appsWithNotebook.some(app => app === this.normalizedAppPath);
  }

  get shouldIncludeAppSwitcher() {
    const apps: TruckTraxApps[] = [
      TruckTraxApps.GeoTrax,
      TruckTraxApps.ScaleTrax,
      TruckTraxApps.Admin,
    ];
    const accessibleApps = apps.filter(app => hasAppAccessInAnyRegion(app, this.props.userPermission));
    return accessibleApps.length > 1;
  }

  get shouldIncludeSidePanel() {
    const appsWithSidePanel = ['geotrax', 'admin'];
    return appsWithSidePanel.some(app => app === this.normalizedAppPath)
      || this.props.location.pathname.includes('/scaletrax/docs');
  }

  get normalizedAppPath() {
    const { location } = this.props;
    const [appPath] = location.pathname.substring(1).split('/');
    return appPath.toLocaleLowerCase();
  }

  render() {
    const truckToDriverMap = getTruckToDriverMap(this.props.drivers);

    const emptyItem = {};
    const ticketItem = this.shouldIncludeTicketActions ? ticketingActionItems : emptyItem;
    const notebookItem = this.shouldIncludeNotebook ? notebookActionItems : emptyItem;
    const messageItem = this.shouldIncludeMessageActions ? messagesActionItems : emptyItem;
    const appMenuItem = this.shouldIncludeAppSwitcher ? appSelectorActionItems : emptyItem;
    const actionItems = {
      ...ticketItem,
      ...notebookItem,
      ...messageItem,
      ...appMenuItem,
    };

    // render sidebar panel inner components
    const activeTrucks = (this.props.currentRegion ? this.getSortedTrucksInRegion() : [])
      .filter(({ data: { isExternal } }) => ((this.props.showInternalTrucks && !isExternal)
        || (this.props.showExternalTrucks && isExternal)));
    const categories = {
      admin: this.state.adminAccess,
    };
    const appProps = this.getAppProps(categories, activeTrucks);
    const sideBarContent: (JSX.Element | undefined)[] = this.props.routes
      .map(renderSidebarInnerComponents(appProps));

    // render sidebar panel labels
    const sideBarLabel = this.props.routes.map(App.renderSidebarLabels);

    // left nav props
    const navProps = {
      onLogout: this.openLogOutDialog,
      onClick: this.onClick,
      appVersion: appVersionAndEnvString(),
      actionItems,
      linkComponent: Link,
      routeComponent: Route,
    };

    const featuredNavItems = this.getFeaturedNavItems();
    const featuredAppIcon = this.getFeaturedAppIcon();
    const leftNav = (
      <LeftNav
        appIcon={featuredAppIcon}
        {...this.props}
        navItems={featuredNavItems}
        panelContent={sideBarContent}
        panelLabel={sideBarLabel}
        sidePanelHidden={!this.shouldIncludeSidePanel}
        {...navProps}
      />
    );

    // render app subroutes
    const configRoutes = this.props.routes.map(App.renderAppSubroutes);

    // add redirect from / to /map
    configRoutes.push(<RedirectRoute
      {...mapRouteRedirect}
    />);

    // add redirect from /* to 404
    configRoutes.push(<RedirectRoute
      {...mapRouteNotFound}
    />);

    // add redirect from /admin/* to 404
    configRoutes.push(<RedirectRoute
      {...adminRouteNotFound}
    />);

    // add redirect for /docs/* to 404
    configRoutes.push(<RedirectRoute
      {...docsRouteNotFound}
    />);

    const renderRoutes = () => {
      const mainStyle = this.state.isTicketsOpen
        ? styles.mainWithTickets
        : styles.main;

      return (
        <div className={mainStyle}>
          <Switch>
            {configRoutes}
          </Switch>
        </div>
      );
    };

    if (this.props.location.pathname === NOT_FOUND_PATH) {
      return (
        <div className={styles.main}>
          <NotFoundView />
        </div>
      );
    }

    if (this.props.location.pathname === MESSAGE_WINDOW) {
      return (
        <div className={styles.layoutContainer}>
          <MessageCenterPopover
            driverList={this.props.drivers}
            currentRegion={this.props.currentRegion}
            trucksToDriversMap={truckToDriverMap}
            selectedProductLine={this.props.selectedProductLine}
          />
        </div>
      );
    }

    return (
      <div className={styles.layoutContainer}>
        <Snackbar
          snackbarBody={this.props.snackbar?.snackbarBody ?? ''}
          snackbarOpen={this.props.snackbar?.snackbarOpen}
          dataTest={this.props.snackbar?.dataTest}
          handleSnackbarClose={this.handleSnackbarClose}
          snackbarType={this.props.snackbar?.snackbarType ?? ''}
          manualClose={this.props.snackbar?.manualClose}
          snackbarBodyElement={this.props.snackbar?.snackbarBodyElement}
        />

        {leftNav}
        {renderRoutes()}
        <Modal
          onOK={this.props.acceptDialog}
          acceptText={this.props.acceptText}
          type={this.props.modalType as ModalType}
          body={this.props.modalBody as string}
          title={this.props.modalTitle}
          isOpen={this.props.modalOpen}
          dataTestOk={this.props.dataTestOk}
          dataTestCancel={this.props.dataTestCancel}
          onCancel={this.handleModalClose}
          cancelText={this.props.cancelText}
          disabled={this.props.disabled}
          noActions={this.props.noActions}
          isDeleteConfirmation={this.props.isDeleteConfirmation}
        />
        <Notification
          snackbarNotificationBody={this.props.snackbarNotification?.snackbarNotificationBody ?? {}}
          snackbarNotificationOpen={this.props.snackbarNotification?.snackbarNotificationOpen}
          dataTest={this.props.snackbar?.dataTest}
          handleSnackbarClose={this.handleSnackbarNotificationClose}
        />
        <TicketsPopover history={this.props.history} />
        <NotebookPopover />
        <MessageCenterContainer
          driverList={this.props.drivers}
          currentRegion={this.props.currentRegion}
          trucksToDriversMap={truckToDriverMap}
          selectedProductLine={this.props.selectedProductLine}
        />
      </div>
    );
  }
}

export interface AppState {
  adminAccess: AdminCategory[],
  isTicketsOpen: boolean
}

function mapStateToProps(state: Partial<ReduxState>): AppReduxStateProps {
  return {
    flags: state.flags,
    snackbar: state.snackbar,
    snackbarNotification: state.snackbarNotification,
    closeDialog: state.openModal?.closeDialog,
    acceptDialog: state.openModal?.acceptDialog,
    acceptText: state.openModal?.acceptText,
    modalType: state.openModal?.modalType,
    modalBody: state.openModal?.modalBody as string,
    modalTitle: state.openModal?.modalTitle,
    modalOpen: state.openModal?.modalOpen,
    isDeleteConfirmation: state.openModal?.isDeleteConfirmation,
    dataTestCancel: state.openModal?.dataTestCancel,
    dataTestOk: state.openModal?.dataTestOk,
    messageSubscription: state.messageSubscription,
    regions: state.assignedRegionList as any,
    currentRegion: state.currentRegion as any,
    positions: state.positionList,
    showInternalTrucks: state.mapFilters?.showTrucks,
    showExternalTrucks: state.mapFilters?.showExtTrucks,
    messageCenter: state.messages,
    drivers: state.driverList?.drivers ?? [],
    selectedMarker: state.selectedMarker,
    currentTicketQualityFactors: state.currentTicketQualityFactors ?? [],
    sidebarSelection: state.sidebar,
    dropdownIsOpen: state.dropdownIsOpen ?? false,
    guideDropdownIsOpen: state.guideDropdownIsOpen ?? false,
    openStateMap: state.openStateMap ?? {},
    selectedProductLine: state.selectedProductLine ?? state.primaryProductLine ?? ProductLine.None,
    primaryProductLine: state.primaryProductLine ?? ProductLine.None,
    secondaryProductLine: state.secondaryProductLine ?? ProductLine.None,
    secondaryProductLines: [ProductLine.Cement],
    userPermission: state.userPermission || {},
    idleTime: state.minutesToIdleLogout,
    sessionId: state.sessionId ?? null,
    userUrl: state.userUrl ?? null,
    userFullName: state.userFullName ?? null,
    cancelText: state.openModal?.cancelText || CANCEL_LABEL,
    disabled: state.openModal?.disabled || false,
    noActions: state.openModal?.noActions || false,
    geotraxPage: state.geotraxPage,
  };
}

export interface AppReduxStateProps {
  flags: any,
  snackbar?: SnackbarState,
  snackbarNotification?: SnackbarNotificationState,
  closeDialog?: () => void,
  acceptDialog?: () => void,
  acceptText?: string,
  modalType?: NotificationType,
  modalBody: string,
  modalTitle?: string,
  modalOpen?: boolean,
  isDeleteConfirmation?: boolean;
  dataTestCancel?: string,
  dataTestOk?: string,
  messageSubscription?: { unsubscribe: () => void }[],
  regions?: RegionDto[],
  currentRegion?: UserRegionDto & { name: string },
  showInternalTrucks?: boolean,
  showExternalTrucks?: boolean,
  positions?: PositionDetailsDto[],
  messageCenter?: MessageCenterState,
  drivers: DriverDto[],
  selectedMarker?: Partial<MarkerInfo>,
  currentTicketQualityFactors: QualityFactorDto[],
  sidebarSelection?: SidebarSelection,
  dropdownIsOpen: boolean,
  guideDropdownIsOpen: boolean,
  openStateMap: { [index: number]: boolean },
  selectedProductLine: ProductLine,
  primaryProductLine: ProductLine,
  secondaryProductLine: ProductLine,
  secondaryProductLines: ProductLine[],
  userPermission: RegionAccessMap,
  idleTime?: number,
  sessionId: string | null,
  userUrl: string | null,
  userFullName: string | null,
  cancelText: string,
  disabled: boolean,
  noActions: boolean,
  geotraxPage?: NavItem
}
export interface AppOwnProps {
  history: {
    push: () => void
  };
  routes: RouteInfo[];
  location: {
    pathname: string;
  };
}

export type AppProps = AppReduxStateProps & AppOwnProps & AppDispatchProps;

export interface AppDispatchProps {
  updateMap: () => void;
  openModal: (options: ModalOptions) => void;
  closeModal: () => void;
  openSnackbar: (options: SnackbarOptions) => void;
  closeSnackbar: () => void;
  closeSnackbarNotification: () => void;
  logOut: () => void;
  messageSocketInit: (region: UrlKeyDto, currentSubscription: any, productLine: ProductLine) => void;
  fetchAssignedRegionList: (
    socketInitCallback: (defaultRegion: UrlKeyDto, currentSubscription?: any) => void,
    getMapviewsCallback: (regionUrl: string) => void
  ) => void;
  selectMarker: (truck: MarkerInfo, fromTickets: boolean) => void;
  setReduxDriverList: (regionUrl: string, productLines: ProductLine[], byPassRedisCache?: boolean) => void;
  fetchPlantListAndMapFilters: (regionUrl: string, productLine: ProductLine, userUrl?: string) => void;
  fetchUsers: ConnectedDispatchFunction<typeof fetchUsers>;
  setOpenStateMap: (map: { [index: number]: boolean }) => void;
  toggleDropdownIsOpen: () => void;
  toggleGuideDropdownIsOpen: () => void;
  refreshPositions: () => void;
  setPrimaryProductLine: (productLine: ProductLine) => void;
  setSecondaryProductLine: (productLine: ProductLine) => void;
  setSecondaryProductLines: (productLines: ProductLine[]) => void;
  setSelectedProductLine: (productline: ProductLine) => void;
  fetchPermissionsListByUserUrl: (userUrl: string, byPassRedisCache?: boolean) => void;
  setSessionId: (sessionId: string) => void;
  getMapview: (regionUrl: string) => void;
  setCurrentAppName: ConnectedFunction<typeof setCurrentAppName>,
  setSidebarSelected?: (sidebarTitle: SidebarTitleType, isSelected: boolean) => void;
  geotraxPageSelected?: (page: NavItem) => void;
  setUserFullName: (userFullName: string) => void;
  refreshGeoTraxState: (
    region?: { url?: string, name?: string },
    productLine?: ProductLine,
    userUrl?: string,
    messageSubscriptions?: {
      url?: string,
      unsubscribe: VoidFunc
    }[],
    byPassRedisCache?: boolean
  ) => void;
}

const dispatchMappedToProps: AppDispatchProps = {
  updateMap: getMapview,
  getMapview,
  openModal,
  closeModal,
  openSnackbar,
  closeSnackbar,
  closeSnackbarNotification,
  messageSocketInit,
  fetchAssignedRegionList,
  logOut,
  setCurrentAppName,
  selectMarker,
  setReduxDriverList,
  fetchPlantListAndMapFilters,
  fetchUsers,
  setOpenStateMap,
  toggleDropdownIsOpen,
  toggleGuideDropdownIsOpen,
  refreshPositions,
  setSecondaryProductLine,
  setSecondaryProductLines,
  setPrimaryProductLine,
  setSelectedProductLine,
  fetchPermissionsListByUserUrl,
  setSessionId,
  setSidebarSelected,
  geotraxPageSelected,
  setUserFullName,
  refreshGeoTraxState,
} as any;

export default connect<AppReduxStateProps, AppDispatchProps>(
  state => mapStateToProps(state),
  dispatchMappedToProps
)(App);

export interface AppPropsForPanels extends AppProps {
  categories: { admin: AdminCategory[] };
  activeTrucks: TruckInfo[];
  changeRegion: (regionUrl: string) => Promise<void>;
  checkSetOpenStateMap: (map: { [index: number]: boolean }) => void;
}
