import { vars } from '@trucktrax/trucktrax-common';

const tabs = {
  width: '100%',
  backgroundColor: 'transparent',
  fontSize: '16px',
};

const tab = {
  height: '54px',
  whiteSpace: 'nowrap',
  lineHeight: '1',
  letterSpacing: '0.02857em',
  fontWeight: 'bolder',
  fontStretch: 'normal',
  textTransform: 'capitalize',
  '&[aria-selected="true"]': {
    color: vars.gray900,
  },
  '&[aria-selected="false"]': {
    color: vars.gray500,
  },
};

const flexContainer = {
  height: '100%',
  width: '320px',
};

const indicator = {
  backgroundColor: vars.orange,
  height: '3px',
  transform: 'none',
  position: 'absolute',
};

const button = {
  width: '100%',
  padding: '.1rem 1.3rem .35rem 1.3rem',
  minHeight: '4rem',
  minWidth: '4rem',
  fontFamily: vars.fontFamilyRegular,
  fontSize: '1.6rem',
  lineHeight: '20px',
  borderRadius: vars.borderradius,
  border: 'none',
  cursor: 'pointer',
  transition: vars.transition,
  boxShadow: '0 1px 5px 0 rgba(26, 39, 57, 0.15)',
  background: `${vars.green}`,
  textTransform: 'capitalize',
  color: vars.white,
  '& span': {
    fontFamily: `${vars.fontFamilyRegular}`,
    fontSize: '1.6rem',
    color: `${vars.white}`,
    textTransform: 'none',
  },
  '&:hover': {
    background: `${vars.greenHover}`,
  },
};

// this object will be used to create a theme with mui/material createTheme
// it will apply default styles to all usages of mui Tabs, Tab, and Button
// https://mui.com/material-ui/customization/theme-components/
const muiStyleOverrides = {
  components: {
    MuiTabs: {
      styleOverrides: {
        root: tabs,
        flexContainer,
        indicator,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: tab,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: button,
      }
    }
  },
};

export default muiStyleOverrides;
