import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import { noop } from '../util/appUtil';
import { IDLE_EVENTS, LAST_ACTIVE } from '../constants/appConstants';

// updates LAST ACTIVITY time on every interaction
// compares LAST ACTIVITY time to NOW time every minute
// if  difference between LAST ACTIVITY and NOW > IDLE_TIME, it calls onIdleTime
export default class IdleService {
  idleTime = 0; // in minutes

  onIdleTime?: VoidFunc = undefined;

  interval?: NodeJS.Timeout = undefined;

  static get lastInteractedAtMS() {
    const strValue = localStorage.getItem(LAST_ACTIVE) || '0';
    return Number(strValue);
  }

  static set lastInteractedAtMS(value: number) {
    localStorage.setItem(LAST_ACTIVE, value.toString());
  }

  init = (idleTime: number, onIdleTime = noop) => {
    this.idleTime = idleTime;
    this.onIdleTime = onIdleTime;

    IdleService.activityDetected();
    IdleService.addListeners();

    const minute = 60 * 1000;
    this.interval = setInterval(this.checkTime, minute);
  };

  checkTime = () => {
    const now = IdleService.currentTime();
    const elapsedMS = now - IdleService.lastInteractedAtMS;
    const elapsedMinutes = elapsedMS / 1000 / 60;
    if (elapsedMinutes > this.idleTime) {
      this.stop();

      if (this.onIdleTime) {
        this.onIdleTime();
      }
    }
  };

  static currentTime = () => Date.now();

  static activityDetected = () => {
    const now = IdleService.currentTime();
    IdleService.lastInteractedAtMS = now;
    localStorage.setItem(LAST_ACTIVE, now.toString());
  };

  static addListeners = () => {
    IDLE_EVENTS.forEach((event) => {
      window.addEventListener(event, IdleService.activityDetected);
    });
  };

  stop = () => {
    clearInterval(this.interval!);

    IDLE_EVENTS.forEach((event) => {
      window.removeEventListener(event, IdleService.activityDetected);
    });
  };
}
