import {
  BaseDto,
  CustomerDto,
  DriverDto,
  LocationDto,
  OperatorNotesDto,
  PlantDto,
  ProductDto,
  ProductLine,
  ProjectDto,
  TruckDetailsDto,
  UrlKeyDto,
  UserPermissionAccessDto,
  UserRegionDto,
  TruckDto,
  VoidFunc,
} from '@trucktrax/trucktrax-ts-common';
import { Action } from 'redux';
import {
  APP_SWITCHER_SELECTED,
  MESSAGE_SELECTED,
  NOTEBOOK_SELECTED,
  TICKETS_SELECTED,
} from './constants/appConstants';

export interface InputField {
  type: string,
  meta: {
    submitFailed?: boolean,
    error?: string,
    touched?: boolean
  },
  label?: string,
  input?: any,
  name?: string
}

export interface Permission {
  access: string,
  permissionUrl: string,
  permission: string
}

export interface UserPermission {
  regions: { regionUrl: string }[],
  permissions: Permission[],
  groupUrl: string,
  tenantUrl: string
}

export interface Marker {
  ticket: {
    url: string
  }
}

export interface LogoutDriverInfo {
  firstName?: string,
  lastName?: string,
  driver?: {
    firstName?: string,
    lastName?: string,
    url?: string
  },
  trackedObject?: {
    truckAlias?: string
  }
}

export interface LogoutUserInfo {
  firstName?: string;
  lastName?: string;
  id?: string
}

export interface UserPermissions {
  [permissionName: string]: UserPermissionAccessDto
}

export interface UserPermissionsByRegion {
  [regionUrl: string]: UserPermissions
}

export interface TicketStatusMarker {
  url: string;
  region: {
    url: string
  };
}

export type ModalType = 'ERROR' | 'SUCCESS' | 'DEFAULT' | 'ACCENT' | 'FAIL';

export interface ModalOptions {
  modalType: ModalType,
  modalTitle?: string,
  modalBody: JSX.Element | string,
  modalOpen: boolean,
  isDeleteConfirmation?: boolean;
  acceptDialog?: VoidFunc,
  acceptText?: string,
  cancelText?: string,
  disabled?: boolean,
  noActions?: boolean
}

export interface ScaleTraxSearchFilters {
  currentPlant?: PlantDto,
  currentTruck?: TruckDto,
  currentProduct?: ProductDto,
  currentCustomer?: CustomerDto,
  currentProject?: ProjectDto,
  startDate?: Date,
  endDate?: Date
}

export interface PositionDetailsDto extends BaseDto {
  source: UrlKeyDto;
  driver: DriverDto;
  region: UrlKeyDto;
  trackedObject: TruckDetailsDto;
  speed: number;
  bearing: number;
  location: LocationDto;
  active: boolean;
  timestamp: string;
  status: string;
  statusTimestamp: string;
  productLine: ProductLine;
  ticket?: UrlKeyDto;
}

export interface TruckInfo {
  id: string;
  name: string;
  text: string;
  data: {
    markerId: string;
    ticketUrl: string;
    lat: number;
    lng: number;
    isActive: boolean;
    status: string | null;
    isExternal: boolean | undefined;
  }
}

export interface AuthTokenPayload {
  // eslint-disable-next-line camelcase
  access_token: string;
  // eslint-disable-next-line camelcase
  refresh_token: string;
  // eslint-disable-next-line camelcase
  expires_in: number;
  tenantId: string;
  loginTime: string;
  primaryProductLine: ProductLine;
  secondaryProductLine: ProductLine;
  secondaryProductLines: ProductLine[];
  id: number;
  assignedRegions: UserRegionDto[];
  isPasswordTemporary: boolean;
  url: string;
  user: string;
  userFullName: string;
}

export interface HttpError {
  response?: {
    data?: {
      message?: string
    },
    status?: number
  }
}

export interface UserRegionWithName extends UserRegionDto {
  name?: string;
}

export type ConnectedFunction<T extends (...args: any) => any> = (...args: Parameters<T>) => ReturnType<T>;

export type ConnectedDispatchFunction<T extends (...args: any) => any> = (...args: Parameters<T>) => ReturnType<ReturnType<T>>;

/* traits */
export interface HasProductLines {
  primaryProductLine: ProductLine;
  secondaryProductLine: ProductLine;
}

export interface HasNameParts {
  firstName?: string;
  lastName?: string;
}

export interface HasUrl {
  url?: string;
}

export interface MaybeDefaultRegion {
  url: string;
  defaultRegion: boolean;
}

export type DropDownListItem = {
  label: string | undefined,
  key?: string,
  value?: HasUrl | MaybeDefaultRegion[],
  disabled?: boolean
};

export interface HasName {
  name?: string;
}

export interface HasDescription {
  description: string;
}

export interface IsRemovable {
  archived: boolean,
  deleted: boolean
}

export interface HasId {
  id: number;
}

export interface MayHaveExternalId {
  externalId?: string;
}

export interface HasAddress {
  addressLineOne?: string;
  addressLineTwo?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}

export type SimpleEvent<T = string> = {
  target: {
    value: T;
  }
};

export type ZoneType = 'plant' | 'inqueue' | 'return';

export type SidebarTitleType = typeof MESSAGE_SELECTED
  | typeof TICKETS_SELECTED
  | typeof APP_SWITCHER_SELECTED
  | typeof NOTEBOOK_SELECTED;

export enum ScaleState {
  unknown = 0,
  connecting = 1,
  connected = 2,
  timedOut = 3,
  disconnected = 4,
}

export interface ReceivedWeight {
  scaleId: string;
  weight: number;
  isMoving: boolean;
  scaleState: ScaleState;
}

export interface ScaleHubState {
  connected: boolean;
}

export interface OperatorNotesPage {
  items: OperatorNotesDto[],
  plantUrl: string,
  page: number
}

export enum TruckTraxApps {
  ScaleTrax,
  GeoTrax,
  Admin
}

export interface AppPath {
  type: TruckTraxApps,
  name: string,
  horizontalIcon: string,
  verticalIcon: string,
  path: string
}

export enum TicketStateSwitcherType {
  Tabs,
  DropDown
}

export enum TicketStateValue {
  AllTickets,
  Closed,
  Voided,
  Open,
  Active
}

export interface TicketListFilters {
  ticketStateValue: TicketStateValue,
  filterDateStart: Date,
  filterDateEnd: Date,
  searchTerm: string
}

export type DateInputType = string | Date | number | null | undefined;

export enum LoadingTimeValue {
  FIVE = 5,
  TEN = 10,
  FIFTEEN = 15,
  TWENTY = 20,
  TWENTYFIVE = 25,
  THIRTY = 30,
}

export interface PayloadAction<TPayload, TString = string>
  extends Action<TString> {
  payload: TPayload;
}
