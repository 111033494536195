const UNABLE_TO_RETRIEVE = 'Unable to retrieve ';
const UNABLE_TO_RETRIEVE_LIST_OF = `${UNABLE_TO_RETRIEVE}list of `;

// GET LIST
export const ERROR_TEXT_FETCH_ADMIN_TABLE = 'Unable to retrieve list.';
export const ERROR_TEXT_FETCH_ADMIN_TABLE_FOR_EXPORT = 'Unable to retrieve list for export.';
export const ERROR_TEXT_FETCH_DRIVER_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}drivers.`;
export const ERROR_TEXT_FETCH_MARKER_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}markers.`;
export const ERROR_TEXT_FETCH_MESSAGE_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}messages.`;
export const ERROR_TEXT_FETCH_PLANT_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}plants.`;
export const ERROR_TEXT_FETCH_VEHICLE_TYPES = `${UNABLE_TO_RETRIEVE_LIST_OF}vehicle types.`;
export const ERROR_TEXT_FETCH_ASSIGNED_REGION_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}assigned regions.`;
export const ERROR_TEXT_FETCH_REGION_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}regions.`;
export const ERROR_TEXT_FETCH_STATUS_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}statuses.`;
export const ERROR_TEXT_FETCH_TENANT_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}tenants.`;
export const ERROR_TEXT_FETCH_HAULER_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}haulers.`;
export const ERROR_TEXT_FETCH_TRUCK_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}vehicles.`;
export const ERROR_TEXT_FETCH_SCALE_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}scales.`;
export const ERROR_TEXT_FETCH_PRINTERS_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}printers.`;
export const ERROR_TEXT_FETCH_ORDER_TICKET_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}order tickets.`;
export const ERROR_TEXT_FETCH_CURRENT_TICKET_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}current tickets.`;
export const ERROR_TEXT_FETCH_PAST_TICKET_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}past tickets.`;
export const ERROR_TEXT_FETCH_USER_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}users.`;
export const ERROR_TEXT_FETCH_SUBSCRIPTION_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}subscriptions.`;
export const ERROR_TEXT_FETCH_FILE_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}files.`;
export const ERROR_TEXT_FETCH_NOTIFICATION_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}notifications.`;
export const ERROR_TEXT_FETCH_CHECKLIST_QUESTIONS_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}checklist questions.`;
export const ERROR_TEXT_FETCH_TICKET_QUALITY_FACTORS = `${UNABLE_TO_RETRIEVE_LIST_OF}quality factors.`;
export const ERROR_TEXT_FETCH_GEOLOCATION = `${UNABLE_TO_RETRIEVE}geolocation.`;
export const ERROR_TEXT_FETCH_CUSTOMER_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}customers.`;
export const ERROR_TEXT_FETCH_PROJECT_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}projects.`;
export const ERROR_TEXT_FETCH_PRODUCT_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}products.`;
export const ERROR_TEXT_FETCH_CITY_LIST = `${UNABLE_TO_RETRIEVE_LIST_OF}cities.`;
export const ERROR_TEXT_FETCH_OPERATOR_NOTES = `${UNABLE_TO_RETRIEVE_LIST_OF}operator notes.`;

// GET ONE
export const ERROR_TEXT_FETCH_ORDER = `${UNABLE_TO_RETRIEVE}order.`;
export const ERROR_TEXT_FETCH_PLANT = `${UNABLE_TO_RETRIEVE}plant.`;
export const ERROR_TEXT_FETCH_SCALE = `${UNABLE_TO_RETRIEVE}scale.`;
export const ERROR_TEXT_FETCH_USER_PERMISSIONS = `${UNABLE_TO_RETRIEVE}user permissions.`;

// POST
export const ERROR_TEXT_LOGOUT_REGION = 'Unable to logout all users and drivers in region.';
export const ERROR_TEXT_USER_LOGOUT = 'Unable to logout user ';
export const ERROR_SEND_MESSAGE = 'Due to technical issues, the message was not sent. Please try again.';
export const ERROR_TEXT_RESET_USER_PASSWORD = 'Unable to reset the user\'s password.';

// PUT
export const ERROR_TEXT_UPDATE_SUBSCRIPTION_LIST = 'Unable to update list of subscriptions.';
export const ERROR_TEXT_UPDATE_CHECKLIST_DETAILS = 'Unable to update checklist details';
export const ERROR_TEXT_UPDATE = 'Unable to update';
export const ERROR_TEXT_UPDATE_TRUCK = 'Unable to update vehicle details.';
export const ERROR_TEXT_UPDATE_SCALE = 'Unable to update scale details.';

// OTHER
export const ERROR_TEXT_POST_UPDATE_CHECKLIST_QUESTION = 'Unable to post or update checklist question';
export const ERROR_TEXT_REMOVE_CHECKLIST_QUESTION = 'Unable to remove checklist question';
export const ERROR_TEXT_POST_BLANK_CHECKLISTS_QUESTION = 'Blank questions';

// 500
export const GENERAL_ERROR_HANDLER_TYPE = 'General Error Handler Error';

const UNABLE_TO_ADD = 'Unable to add ';
const PLEASE_TRY_AGAIN = '. Please try again.';

export const ADD_REGION_ERROR_MESSAGE = `${UNABLE_TO_ADD}region${PLEASE_TRY_AGAIN}`;
export const ADD_TRUCK_ERROR_MESSAGE = `${UNABLE_TO_ADD}vehicle${PLEASE_TRY_AGAIN}`;
export const ADD_VEHICLE_TYPE_ERROR_MESSAGE = `${UNABLE_TO_ADD}vehicle type${PLEASE_TRY_AGAIN}`;
export const ADD_SCALE_ERROR_MESSAGE = `${UNABLE_TO_ADD}scale${PLEASE_TRY_AGAIN}`;
export const ADD_USER_ERROR_MESSAGE = `${UNABLE_TO_ADD}user${PLEASE_TRY_AGAIN}`;
export const ADD_PLANT_ERROR_MESSAGE = `${UNABLE_TO_ADD}plant${PLEASE_TRY_AGAIN}`;
export const ADD_PERMISSIONS_GROUP_ERROR_MESSAGE = `${UNABLE_TO_ADD}permissions group${PLEASE_TRY_AGAIN}`;
export const ADD_DEVICE_ERROR_MESSAGE = `${UNABLE_TO_ADD}device${PLEASE_TRY_AGAIN}`;
export const ADD_DRIVER_ERROR_MESSAGE = `${UNABLE_TO_ADD}driver${PLEASE_TRY_AGAIN}`;
export const ADD_GEOZONE_ERROR_MESSAGE = `${UNABLE_TO_ADD}geozone${PLEASE_TRY_AGAIN}`;
export const ADD_CHECKLIST_ERROR_MESSAGE = `${UNABLE_TO_ADD}checklist${PLEASE_TRY_AGAIN}`;
export const ADD_TARE_ERROR_MESSAGE = `${UNABLE_TO_ADD}tare${PLEASE_TRY_AGAIN}`;
export const ADD_HAULER_ERROR_MESSAGE = `${UNABLE_TO_ADD}hauler${PLEASE_TRY_AGAIN}`;
export const ADMIN_UPDATE_ERROR = `Unable to save changes${PLEASE_TRY_AGAIN}`;
export const INVALID_CREDENTIALS = `Wrong username or password${PLEASE_TRY_AGAIN}`;
export const USER_LOCKED_OUT = 'Too many login attempts have been made. The application will be locked for 15 minutes.';

export const FETCH_PERMISSIONS_FAIL_MESSAGE = 'Unable to retrieve the list of permissions';
export const FETCH_PERMISSION_GROUP_FAIL_MESSAGE = 'Unable to retrieve the list of permission groups';
export const FETCH_ASSIGNED_PERMISSION_FAIL_MESSAGE = 'Unable to retrieve the list of permissions';

export const SERVER_ERROR = 'Server error; please try again later';
export const TOO_MANY_LOGIN_ATTEMPTS = 'Too many login attempts have been made. '
  + 'The application will be locked for 15 minutes.';
export const WINDOWS_AUTH_ERROR = 'There was an error logging you in with windows authentication.  Please contact an adminstrator.';
export const USER_UNAUTHORIZED = 'You have been logged out by an admin.';
export const SESSION_EXPIRED = 'Your session has expired.';

export const USER_PASSWORD_EXPIRED = 'User account has expired';
export const USER_PASSWORD_EXPIRED_MESSAGE = 'Your password has expired and must be changed.';

export const CREATE_FLAG_FAIL_MSG = 'Creating Feature Flag failed';
export const FEATURE_FLAG_DELETE_FAIL_MODAL_MESSAGE = 'Delete Feature Flag Failed';
export const FEATURE_FLAG_TOGGLE_FAIL_MODAL_MESSAGE = 'Toggle Feature Flag Failed';

export const UNABLE_TO_RESOLVE_NOTIFICATION = 'Marking notification resolved failed';

export const UNABLE_TO_SET_HOME_LOCATION = 'Unable to set home location';
