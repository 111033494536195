import React from 'react';
import { Redirect } from 'react-router-dom';

export default function RedirectRoute(props: RedirectRouteProps) {
  const {
    state = {},
    from = '',
    to = '',
    exact = false,
  } = props;

  if (state) {
    return (
      <Redirect
        from={props.from}
        to={{ pathname: to, state }}
        exact={exact}
      />
    );
  }
  return (
    <Redirect
      from={from}
      to={to}
      exact={exact}
    />
  );
}

export interface RedirectRouteProps {
  from?: string;
  to?: string;
  exact?: boolean;
  state?: any;
}
