import React from 'react';
import { Switch } from 'react-router-dom';
import { scaleTraxDocsRouteNotFound, scaleTraxDocsRouteRedirect } from '../../../routes';

import RedirectRoute from '../../shared/routing/RedirectRoute';
import RouteWithSubRoutes from '../../shared/routing/RouteWithSubRoutes';

export function ScaleTraxDocsView(props: ScaleTraxDocsViewProps) {
  const configRoutes = props.routes.map((puRoute) => (
    <RouteWithSubRoutes
      key={puRoute.path}
      {...puRoute}
    />
  ));

  // add redirect for /scaletrax/docs to /scaletrax/docs/guide/web
  configRoutes.push(<RedirectRoute
    key={scaleTraxDocsRouteRedirect.key}
    from={scaleTraxDocsRouteRedirect.from}
    to={scaleTraxDocsRouteRedirect.to}
  />);

  // add redirect for /scaletrax/docs/* to 404
  configRoutes.push(<RedirectRoute
    key={scaleTraxDocsRouteNotFound.key}
    from={scaleTraxDocsRouteNotFound.from}
    to={scaleTraxDocsRouteNotFound.to}
  />);

  return (
    <Switch>
      {configRoutes}
    </Switch>
  );
}

export interface Route {
  path?: string,
  component?: React.ComponentType<any>
}

export interface ScaleTraxDocsViewProps {
  routes: Route[]
}

export default ScaleTraxDocsView;
