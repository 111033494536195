import * as signalR from '@microsoft/signalr';
import { UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import config from 'react-global-configuration';
import recordLog from '../../util/errorUtil';
import { store } from '../../Main';
import { removeTicket, syncTicket } from '../../store/actions/ticketActions';
import { fetchCurrentTicketForTruck, fetchCurrentTicketList, fetchTicket } from '../ticketsService';
import {
  JOIN_GROUP,
  RECEIVE_MESSAGE,
  RECONNECT_TIMEOUT,
} from '../../constants/appConstants';
import {
  HTTP_BRIDGE_BASE_URL,
  CLOSE_TICKET_GROUP,
  TICKETING_SOCKET,
  SYNC_TICKET_GROUP,
} from '../../constants/apiConstants';
import { getTokens } from '../../util/authUtil';

export class TicketingSocket {
  connected: boolean;

  hubConnection: signalR.HubConnection | null;

  currentRegion?: UrlKeyDto;

  selectedProductLine?: string;

  constructor() {
    this.connected = false;
    this.hubConnection = null;
  }

  onResponse = async (response: string, selectedProductLine?: string) => {
    const { message: socketTicket, group } = JSON.parse(response);
    // although ticket is passed as the payload, pull it fresh from the database by id to capture possible updates
    // some possible race conditions exist
    const ticket = await fetchTicket(socketTicket.id);
    if (!ticket || ticket?.externalInfo?.plantProductLine !== selectedProductLine) {
      return;
    }
    if (group === SYNC_TICKET_GROUP) {
      store.dispatch(syncTicket(ticket));
    } else if (group === CLOSE_TICKET_GROUP) {
      store.dispatch(removeTicket(ticket));
      const nextTicket = await fetchCurrentTicketForTruck(ticket.truck?.url!, this.currentRegion?.url, selectedProductLine);
      if (nextTicket) {
        store.dispatch(syncTicket(nextTicket));
      }
    }
  };

  async connect(currentRegion?: UrlKeyDto, selectedProductLine?: string) {
    const { tenantId: tenant } = store.getState();
    const { accessToken } = getTokens();

    // do not try to connect if we're not logged in
    if (!accessToken) {
      return;
    }
    if (this.connected) {
      return;
    }
    if (!currentRegion?.url) {
      return;
    }

    this.currentRegion = currentRegion;
    this.selectedProductLine = selectedProductLine;

    const regionUrl = this.currentRegion!.url!.trim();
    const TICKETING_SOCKET_URL = `${config.get(HTTP_BRIDGE_BASE_URL)}${TICKETING_SOCKET}`;

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${TICKETING_SOCKET_URL}?tk=${accessToken}`, { accessTokenFactory: () => accessToken! })
      .configureLogging(signalR.LogLevel.None)
      .build();

    this.hubConnection.on(RECEIVE_MESSAGE, (message) => {
      this.onResponse(message, selectedProductLine);
    });

    this.hubConnection.onclose(async () => {
      this.disconnect();
      if (this.hubConnection) {
        this.hubConnection!.off(RECEIVE_MESSAGE);
        this.connect(this.currentRegion, selectedProductLine);
      }
    });

    try {
      await this.hubConnection.start();
      // TODO: Remove tenant from websocket group (work is done in future card)
      await Promise.all([SYNC_TICKET_GROUP, CLOSE_TICKET_GROUP]
        .map(group => (`${group}${tenant}/${regionUrl}`))
        .map(group => (this.hubConnection!.invoke(JOIN_GROUP, group))));
      this.connected = true;
      // retrieving all tickets after connection to account for new tickets received
      // from app refresh to websocket connection success
      store.dispatch<any>(fetchCurrentTicketList(this.currentRegion!.url, selectedProductLine));
    } catch (err) {
      recordLog('warn', 'Ticketing Socket', err);
      this.reconnect();
    }
  }

  reconnect() {
    setTimeout(
      () => this.connect(this.currentRegion, this.selectedProductLine),
      RECONNECT_TIMEOUT
    );
  }

  async disconnect(destroyHub?: boolean) {
    if (!this.hubConnection) {
      return;
    }
    await this.hubConnection.stop();
    this.connected = false;

    this.currentRegion = undefined;
    this.selectedProductLine = undefined;

    if (destroyHub) {
      this.hubConnection = null;
    }
  }
}

export default new TicketingSocket();
