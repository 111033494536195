import {
  SET_OPEN_STATE_MAP,
  SET_SIDEBAR_SELECTED,
  TOGGLE_DROPDOWN_IS_OPEN,
  TOGGLE_GUIDE_DROPDOWN_IS_OPEN,
} from '../../constants/actionConstants';
import { SidebarTitleType } from '../../types';

export const setSidebarSelected = (sidebarTitle: SidebarTitleType, isSelected: boolean) => ({
  type: SET_SIDEBAR_SELECTED,
  payload: { sidebarTitle, isSelected },
});

export default setSidebarSelected;

export const toggleDropdownIsOpen = () => ({
  type: TOGGLE_DROPDOWN_IS_OPEN,
});

export const toggleGuideDropdownIsOpen = () => ({
  type: TOGGLE_GUIDE_DROPDOWN_IS_OPEN,
});

export const setOpenStateMap = (openStateMap: { [key: number]: boolean }) => ({
  type: SET_OPEN_STATE_MAP,
  payload: openStateMap,
});
