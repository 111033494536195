import { Dispatch } from 'redux';
import {
  ProductLine,
  VoidFunc,
} from '@trucktrax/trucktrax-ts-common';
import { MESSAGE_SELECTED } from '../constants/appConstants';
import { setReduxDriverList } from './driversService';
import { setFeatureFlagsWithUserUrl } from './featureFlagsService';
import { getMapview, trackingSocketInit } from './mapviewsService';
import { getInboxMessages, messageSocketInit } from './messagesService';
import { fetchPlantListAndMapFilters } from './plantsService';
import { setSelectedProductLine } from './productLinesService';
import { setCurrentRegion } from './regionsService';
import { fetchCurrentTicketList, ticketSocketInit } from './ticketsService';
import { updateToFieldValue } from '../store/actions/messageActions';
import setSidebarSelected from '../store/actions/sidebarActions';
import { clearSelectedTicket, resetTicketView } from '../store/actions/ticketActions';

// eslint-disable-next-line import/prefer-default-export
export const refreshGeoTraxState = (
  region?: { url?: string, name?: string },
  productLine?: ProductLine,
  userUrl?: string,
  messageSubscriptions?: {
    url?: string,
    unsubscribe: VoidFunc
  }[],
  byPassRedisCache: boolean = false
) => async (dispatch: Dispatch, getState: any) => {
  if (!region?.url || !productLine) {
    return;
  }

  setSelectedProductLine(productLine)(dispatch);
  dispatch(setSidebarSelected(MESSAGE_SELECTED, false));

  if (region.name) {
    setCurrentRegion({ ...region, defaultRegion: false, name: region.name } as any)(dispatch);
  }

  dispatch(clearSelectedTicket());
  dispatch(resetTicketView());
  dispatch(updateToFieldValue([]));

  getMapview(region.url)(dispatch, getState);
  fetchPlantListAndMapFilters(region.url, productLine, userUrl)(dispatch);
  setReduxDriverList(region.url, [productLine], byPassRedisCache)(dispatch);

  getInboxMessages(undefined, undefined, region, productLine)(dispatch);
  fetchCurrentTicketList(region.url, productLine)(dispatch);

  messageSocketInit(region, messageSubscriptions);
  ticketSocketInit(region, productLine);
  trackingSocketInit(region.url ?? '');

  if (userUrl) {
    setFeatureFlagsWithUserUrl(userUrl, region.url);
  }
};
