import { RouteDto } from '@trucktrax/trucktrax-ts-common';

/**
 * Returns the product lines list from the route history search result
 * @param {RouteDto[]} routeHistoryListResult route history list result
 * @param {number} validProductLinesLength valid product lines length
 * @returns {string[]} product lines from the result list
 */
// eslint-disable-next-line import/prefer-default-export
export function getProductLinesResult(routeHistoryListResult: RouteDto[], validProductLinesLength: number): string[] {
  const routeProductLines: string[] = [];
  if (routeHistoryListResult === undefined || routeHistoryListResult.length === 0) return routeProductLines;
  for (let index = 0; index < routeHistoryListResult.length;) {
    if (routeHistoryListResult[index].route !== undefined) {
      const routeProductLine = routeHistoryListResult[index].route[0].productLine;
      if (routeProductLines.length === validProductLinesLength) break;
      if (!routeProductLines.includes(routeProductLine)) {
        routeProductLines.push(routeProductLine);
      }
    }
    index += 1;
  }

  return routeProductLines;
}

/**
 * Returns the text indicating one or more deactivated resources on a route history search result
 * @param {any} deactivatedTruck indicates if there's a deactivated truck
 * @param {any} deactivatedDriver indicates if there's a deactivated driver
 * @returns {string} text for deactivated resource(s)
 */
// eslint-disable-next-line import/prefer-default-export
export function getDeactivationType(deactivatedTruck: any, deactivatedDriver: any): string {
  if (!deactivatedTruck && !deactivatedDriver) {
    return '';
  }
  let deactivatedType = '';
  if (deactivatedTruck && deactivatedDriver) {
    deactivatedType = 'Truck and Driver';
  } else if (deactivatedTruck) {
    deactivatedType = 'Truck';
  } else {
    deactivatedType = 'Driver';
  }
  return `Deactivated ${deactivatedType}`;
}
