import { CannedMessagesDto } from '@trucktrax/trucktrax-ts-common';
import { CANNED_MESSAGES_PATH } from '../constants/apiConstants';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { getRequest, postRequest, putRequest } from './requestService';

// if productLine is not passed in, then get all canned messages for region
export async function getCannedMessages(regionId: number, productLine?: string) {
  const url = `${getGeotraxBaseUrl() + CANNED_MESSAGES_PATH}`;
  const params: any = {
    regionId,
    deleted: false,
    archived: false,
  };

  if (productLine) {
    params.productLine = productLine;
  }

  const response = await getRequest(url, params);
  return response;
}

export async function postCannedMessages(body: CannedMessagesDto) {
  const url = `${getGeotraxBaseUrl() + CANNED_MESSAGES_PATH}`;
  const response = await postRequest(url, body);
  return response;
}

export async function putCannedMessages(id: number, body: CannedMessagesDto) {
  const url = `${getGeotraxBaseUrl() + CANNED_MESSAGES_PATH}/${id}`;
  const response = await putRequest(url, body);
  return response;
}
