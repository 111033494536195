import React from 'react';
import { HeaderAndContainer } from '@trucktrax/trucktrax-common';
import { Card, CardContent } from '@mui/material';
import styles from './UserGuidesCommon.module.css';
import Header from '../../../shared/Header';
import AdminCardStyles from '../../../shared/admin/AdminCard.module.css';
import CardImage from '../../../shared/forms/CardImage';
import DocsTable from '../../../shared/docs/DocsTable';
import userGuidesMobileJpg from '../../../../assets/img/userGuidesMobile.jpg';

export function GuideGeotraxLiteView() {
  const cardStyle = {
    root: AdminCardStyles.card,
  };

  const documents = [
    {
      name: 'Getting Started',
      filepath: 'userguides/lite/userguide_mobile_Getting_Started.pdf',
    },
    {
      name: 'Profile and Truck Selection',
      filepath:
        'userguides/lite/userguide_mobile_Profile_and_Truck_Selection.pdf',
    },
    {
      name: 'Messages',
      filepath: 'userguides/lite/userguide_mobile_Messages.pdf',
    },
    {
      name: 'Tickets',
      filepath: 'userguides/lite/userguide_mobile_Tickets.pdf',
    },
    {
      name: 'Reports',
      filepath: 'userguides/lite/userguide_mobile_Reports.pdf',
    },

  ];

  const guideBody = (
    <div className={AdminCardStyles.bodyWrapper}>
      <div className={AdminCardStyles.cardWrapper}>
        <Card classes={cardStyle}>
          <CardImage src={userGuidesMobileJpg} className={styles.topImg} />
          <CardContent>
            <div className={AdminCardStyles.cardText}>
              <h2 className={styles.header}>GeoTrax Lite User Guide</h2>

              <p>
                GeoTrax® is a cloud-based software application comprised of mobile and web applications
                to track and manage bulk construction materials deliveries. The mobile app functions
                on Android® devices without the need for dedicated, truck-mounted hardware.
                When equipped with the GeoTrax application, on startup the device communicates with a
                remote cloud-based database that functions as the data repository and communicates with
                the web application to perform a variety of functions. The web application is designed
                to interface bi-directionally with a client’s ticketing software.
              </p>

              <div className={styles.divider} />
              <DocsTable documents={documents} folder="userguides/mobile" />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );

  const elementsLeft = <Header title="User Guides" />;

  return (
    <div>
      <div>
        <HeaderAndContainer elementsLeft={elementsLeft} />
      </div>
      {guideBody}
    </div>
  );
}

export default GuideGeotraxLiteView;
