/* eslint-disable max-len */
import React, { Component } from 'react';
import { FolderDropdown } from '@trucktrax/trucktrax-common';
import styles from './DocsPanelContent.module.css';
import {
  categoriesForScaleTraxGuides,
  categoriesForScaleTraxRelease,
  categoriesForScaleTraxTrainingVideos,
} from '../../../constants/navConstants';
import SidenavLink from '../SidenavLink';
import { setFavicon } from '../../../util/appUtil';

interface ScaleTraxDocsPanelState {
  isVideoOpen: boolean
}

export class ScaleTraxDocsPanelContent extends Component<ScaleTraxDocsPanelContentProps, ScaleTraxDocsPanelState> {
  constructor(props: ScaleTraxDocsPanelContentProps) {
    super(props);

    this.state = {
      isVideoOpen: false,
    };
  }

  async componentDidMount() {
    document.title = 'ScaleTrax - TruckTrax';
    setFavicon('/favicon-scaletrax.ico');
  }

  render() {
    const count = 2;
    const url = window.location.pathname;
    const dropdownIsActive = (url && url.includes('guide')) as boolean;
    const videoIsActive = (url && url.includes('video')) as boolean;

    const userGuideTabs = categoriesForScaleTraxGuides.map(config => (<SidenavLink {...config} key={config.name} />));
    const releaseTabs = categoriesForScaleTraxRelease.map(config => (<SidenavLink {...config} key={config.name} />));
    const videoTabs = categoriesForScaleTraxTrainingVideos.map(config => (<SidenavLink {...config} key={config.name} />));

    return (
      <div className={styles.docsPanelContent}>
        <FolderDropdown
          dataTestName="leftnav-user-guides-dropdown-menu"
          icon="icon-directions"
          docsMode
          classes={styles.folder}
          contentClasses={styles.folderContent}
          count={count}
          isOpen={this.props.dropdownIsOpen}
          name="User Guides"
          onClick={this.props.toggleDropdownIsOpen}
          isActive={dropdownIsActive}
        >
          {userGuideTabs as any}
        </FolderDropdown>
        <div>
          {releaseTabs}
        </div>
        <FolderDropdown
          dataTestName="leftnav-training-videos-dropdown-menu"
          icon="icon-video"
          docsMode
          classes={styles.folder}
          contentClasses={styles.folderContent}
          count={count}
          isOpen={this.state.isVideoOpen}
          name="Training Videos"
          onClick={() => this.setState({ isVideoOpen: !this.state.isVideoOpen })}
          isActive={videoIsActive}
        >
          {videoTabs as any}
        </FolderDropdown>
      </div>
    );
  }
}

export interface ScaleTraxDocsPanelContentProps {
  dropdownIsOpen: boolean;
  toggleDropdownIsOpen: () => void;
}

export default ScaleTraxDocsPanelContent;
