/* eslint-disable no-console */
import { UserPreferencesDto } from '@trucktrax/trucktrax-ts-common';
import { getRequest, putRequest } from './requestService';
import { USERS_PATH } from '../constants/apiConstants';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { getIdFromUrl } from '../util/appUtil';

const defaultUserPreferences: UserPreferencesDto = {
  mapFilters: {},
};

export async function getUserPreferences(userUrl: string) {
  const id = getIdFromUrl(userUrl);
  const url = `${getGeotraxBaseUrl()}${USERS_PATH}/preferences/${id}`;

  try {
    const response = await getRequest(url);
    const fetchedUserPreferences = response.data;
    return fetchedUserPreferences ? fetchedUserPreferences as UserPreferencesDto : defaultUserPreferences;
  } catch (e: any) {
    console.log('getUserPreferences error = ', e);
    return undefined;
  }
}

export async function updateUserPreferences(userUrl: string, userPreferences: UserPreferencesDto) {
  const id = getIdFromUrl(userUrl);
  const url = `${getGeotraxBaseUrl()}${USERS_PATH}/preferences/${id}`;

  try {
    const response = await putRequest(url, { preferences: JSON.stringify(userPreferences) });
    const updatedUserPreferences = response.data;
    return updatedUserPreferences ? updatedUserPreferences as UserPreferencesDto : defaultUserPreferences;
  } catch (e: any) {
    console.log('updateUserPreferences error = ', e);
    return undefined;
  }
}
