/* eslint-disable */
import * as signalR from '@microsoft/signalr';
import { SCALES_SOCKET } from '../../constants/apiConstants';
import {
  DISCONNECT_FROM_SCALE,
  SCALE_STATE_NOTIFIED,
  RECEIVE_WEIGHT_MESSAGE,
  RECONNECT_TIMEOUT,
  CONNECT_TO_SCALE,
  JOIN_GROUP,
} from '../../constants/appConstants';
import { store } from '../../Main';
import {
  scaleWeightReceived,
  scaleStateNotified,
  setScaleHubState,
} from '../../store/actions/scalesActions';
import { ScaleState } from '../../types';
import { getBridgeBaseUrl } from '../../util/apiUtil';
import { getTokens } from '../../util/authUtil';
import recordLog from '../../util/errorUtil';

export class ScalesSocket {
  hubConnection?: signalR.HubConnection;
  MAX_JOINRETRIES = 30; // arbitrary 30 retries
  REJOIN_TIMEOUT = 2000; // keeps retrying for 1 minute every 2 seconds
  retryJoinCount = 0;

  scaleId?: string = '';

  mockDataInterval?: number; // Add this line

  constructor() {
    this.hubConnection = undefined;
  }

  async connect(scaleId: string) {

    if (this.hubConnection) {
      return;
    }

    try {
      const { accessToken } = getTokens();
      if (!accessToken) {
        return;
      }

      this.scaleId = scaleId;
      const SCALESSOCKET_URL = `${getBridgeBaseUrl()}${SCALES_SOCKET}`;
      this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${SCALESSOCKET_URL}?tk=${accessToken}`, { accessTokenFactory: () => accessToken! })
      .configureLogging(signalR.LogLevel.Debug)
      .build();

      this.hubConnection.onclose(() => this.reconnect(scaleId));

      // establish onResponse as the method to call when weight is received
      this.hubConnection.on(RECEIVE_WEIGHT_MESSAGE, (response: any) => {
        this.onResponse(response);
      });

      await this.hubConnection.start();

      // each scale has its own group named for its Id
      await this.retryJoinGroup(scaleId);

      store.dispatch(setScaleHubState({ connected: true }));

    } catch (error: any) {
      recordLog('warn', 'Connecting to Scales Socket', error);
      this.scaleId = undefined;
      this.hubConnection = undefined;
      store.dispatch(setScaleHubState({ connected: false }));
      this.reconnect(scaleId);
    }
  }

  async retryJoinGroup(scaleId: string) {
    this.scaleId = scaleId;

    while (this.retryJoinCount < this.MAX_JOINRETRIES) {
      if (this.hubConnection && this.hubConnection.state && this.hubConnection.state === signalR.HubConnectionState.Connected) {
        try {
          await this.hubConnection!.invoke(JOIN_GROUP, scaleId);
          this.retryJoinCount = 0; // Reset retry count upon success
          return; // Group joined successfully
        } catch (error) {
          recordLog('warn', 'Error joining group', error);
        }
      }

      // If not connected or joining failed, wait before retrying
      await new Promise(resolve => setTimeout(resolve, this.REJOIN_TIMEOUT));
      this.retryJoinCount++;
    }

    throw new Error('Failed to join group after multiple retries.');
  }

  reconnect(scaleId: string) {
    store.dispatch(setScaleHubState({ connected: false }));
    this.hubConnection = undefined;

    global.setTimeout(() => {
      this.connect(scaleId);
    }, RECONNECT_TIMEOUT);
  }

  async disconnect() {
    if (!this.hubConnection) {
       return;
    }

    try {
      await this.hubConnection.stop();
      store.dispatch(setScaleHubState({ connected: false }));
    } catch (error: any) {
      recordLog('warn', 'Error disconnecting from Scales Socket', error);
    } finally {
      this.scaleId = undefined;
      this.hubConnection = undefined;
    }
  }

  onResponse = (response: any) => {
    const { group , message }= JSON.parse(response);
    if (!this.scaleId || this.scaleId != group) {
      return;
    }

    store.dispatch(scaleWeightReceived(message.scaleId!, message.weight, message.isMoving));
  };

  onScaleStateNotified = (scaleState: ScaleState) => {
    if (!this.scaleId) {
      return;
    }

    store.dispatch(scaleStateNotified(this.scaleId, scaleState));
  };
}

export default new ScalesSocket();
