/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import { Button, Driver, Tooltip } from '@trucktrax/trucktrax-common';
import { MessageTabs } from '../../../constants/messagingConstants';
import styles from './MessageCenter.module.css';
import SidebarContainer from './SidebarContainer';
import {
  MessageCenterInfo,
  ConversationToMessagesDto,
} from './index.d';
import { AcknowledgeDto } from '../../../services/messagesService';
import { IS_MESSAGE_WINDOW_OPEN } from '../../../constants/localStorageConstants';
import { MESSAGE_WINDOW } from '../../../constants/apiConstants';
import { NEW_MESSAGE_WINDOW } from '../../../constants/appConstants';

export class MessageCenter extends Component<MessageCenterProps, MessageCenterState> {
  constructor(props: MessageCenterProps) {
    super(props);
    this.state = {
      panelOpen: false,
    };
  }

  static defaultProps = {
    currentRegion: {
      url: '',
    },
    onGetSentMessages: () => { },
  };

  closeMessageCenter = () => {
    this.setState({ panelOpen: false });
    this.props.onCloseMessageCenter();
  };

  setIsPanelOpen = (stateValueFromChild: boolean) => {
    this.setState({ panelOpen: stateValueFromChild });
  };

  expandClick = () => {
    window.localStorage.setItem(IS_MESSAGE_WINDOW_OPEN, 'true');
    window.open(MESSAGE_WINDOW, NEW_MESSAGE_WINDOW);
    this.closeMessageCenter();
  };

  // Make sure to catch the window closing to clear the localStorage or it'll break messages
  componentDidMount() {
    window.addEventListener('beforeunload', this.noteWindowClose);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.noteWindowClose);
  }

  noteWindowClose() {
    window.localStorage.removeItem(IS_MESSAGE_WINDOW_OPEN);
  }

  render() {
    const { panelOpen } = this.state;
    return this.props.messagesOpen
      && (
        <div className={cx(
          styles.popover,
          styles.modalRoot,
          panelOpen && styles.panelOpen
        )}
        >
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <span
                data-test="datablock-breadcrumb-link"
                role="button"
                aria-hidden
              >
                <span className={styles.titleHeader}>MESSAGES</span>
              </span>
            </div>
            {!this.props.isExpand && (
              <div className={styles.headerActions}>
                {(!panelOpen || (this.props.currentTab === MessageTabs.New)) && (
                  <Tooltip
                    theme=""
                    text="Open in a new tab"
                    placement="right"
                  >
                    <button
                      onClick={this.expandClick}
                      className={styles.expendedButton}
                      aria-hidden="true"
                    >
                      <i className={cx('icon-new-tab', styles.newWindowIcon)} />
                    </button>
                  </Tooltip>
                )}
                <Button
                  onClick={this.closeMessageCenter}
                  buttonClassName={cx(
                    styles['datablock-info-close'],
                    'tt-btn-basic'
                  )}
                  iconClassName={cx('icon-close', 'icon-white')}
                  dataTest="dataBlock-close-button"
                />
              </div>
            )}

          </div>
          {/* message center */}
          <SidebarContainer
            currentTab={this.props.currentTab}
            currentRegion={this.props.currentRegion}
            onToFieldChange={this.props.onToFieldChange}
            onCurrentRecipientSearchTextChange={this.props.onCurrentRecipientSearchTextChange}
            driverList={this.props.driverList}
            onMessageFieldChange={this.props.onMessageFieldChange}
            onSubmit={this.props.onSubmit}
            messageCenter={this.props.messageCenter}
            onUpdateReplyMap={this.props.onUpdateReplyMap}
            replyMap={this.props.replyMap}
            onTabChange={this.props.onTabChange}
            onAcknowledge={this.props.onAcknowledge}
            onMarkUnread={this.props.onMarkUnread}
            allMessages={this.props.allMessages}
            unReadCount={this.props.unReadCount}
            onGetInboxMessages={this.props.onGetInboxMessages}
            selectedProductLine={this.props.selectedProductLine}
            setIsPanelOpen={(stateValueFromChild) => this.setIsPanelOpen(stateValueFromChild)}
            isExpand={this.props.isExpand}
          />
        </div>
      );
  }
}

export interface MessageCenterState {
  panelOpen: boolean;
}

export interface MessageCenterProps {
  currentTab: MessageTabs;
  currentRegion: UrlKeyDto;
  onToFieldChange: () => void;
  onCurrentRecipientSearchTextChange: (newSearchText: string) => void;
  messagesOpen: boolean;
  onCloseMessageCenter: () => void;
  onMessageFieldChange: (message: string) => void;
  onSubmit: () => void;
  messageCenter: MessageCenterInfo;
  driverList: Driver[];
  onUpdateReplyMap: () => void;
  onTabChange: () => void;
  onAcknowledge: () => void;
  onMarkUnread: (ack: AcknowledgeDto) => void;
  onGetInboxMessages: () => void;
  allMessages: ConversationToMessagesDto[];
  unReadCount: boolean;
  replyMap: Map<string, string>;
  selectedProductLine?: string;
  isExpand?: boolean;
}

export default MessageCenter;
