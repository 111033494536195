import { FeatureFlagDto, SubscriptionDto } from '@trucktrax/trucktrax-ts-common';
import { store } from '../Main';
import { setFeatureFlagObject } from '../store/actions/featureFlagsActions';
import { RegionAccessMap } from './permissionUtil';

interface FlagMap {
  failedCalls: boolean;
  defaultValue: boolean;
  isDeleted: boolean;
  [flagName: string]: SubscriptionDto | boolean;
}

export function mapFeatureFlagsToAssignments(featureFlags: FeatureFlagDto[] | null, featureFlagAssignments: SubscriptionDto[] | null) {
  const featureFlagMap: FlagMap = {
    failedCalls: false,
    defaultValue: false,
    isDeleted: true,
  };

  if (featureFlags == null || featureFlagAssignments == null) {
    featureFlagMap.failedCalls = true;
    return featureFlagMap;
  }

  const arrayToObject = (assignments: SubscriptionDto[]) => assignments.reduce((acc, assignment) => {
    acc[assignment.featureFlag!.url!] = assignment;
    return acc;
  }, {} as { [flagUrl: string]: SubscriptionDto });

  const assignmentMap = arrayToObject(featureFlagAssignments);

  featureFlags.forEach((featureFlag) => {
    featureFlagMap[featureFlag.name!] = !!assignmentMap[featureFlag.url!]
      && !(featureFlag.deleted || featureFlag.archived);
  });

  return featureFlagMap;
}

export const addFlagToStorage = (featureFlag: FeatureFlagDto, onSuccessCallback: (flags: FeatureFlagDto[]) => void) => {
  const { featureFlagObj: featureFlags } = store.getState();
  const flags = featureFlags || [];
  flags.push(featureFlag);
  store.dispatch(setFeatureFlagObject(flags));
  onSuccessCallback(flags);
};

// after successful delete call, remove flag from locateStorage
export const setStoreAndStorage = (flag: FeatureFlagDto) => {
  const { featureFlagObj: featureFlags } = store.getState();
  let flags: FeatureFlagDto[] = featureFlags || [];
  flags = flags.filter(o => o.id !== flag.id);
  store.dispatch(setFeatureFlagObject(flags));
  return flags;
};

interface Region { url: string }
interface RegionMap { name: string }

export function sort(items: any) {
  if (items) {
    items.sort((o1: any, o2: any) => {
      const item1 = o1.item.toLowerCase(); // ignore upper and lowercase
      const item2 = o2.item.toLowerCase(); // ignore upper and lowercase
      if (item1 < item2) {
        return -1;
      }
      if (item1 > item2) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }
  return items;
}

export function getCommaSeparatedRegions(
  regionMap: { [key: string]: RegionMap },
  regions: Region[]
) {
  let commaSeparateName = '';
  if (regionMap && regions) {
    const regionNames = regions.map(r => {
      if (regionMap[r.url] && regionMap[r.url].name) {
        return regionMap[r.url].name;
      }
      return null;
    }).filter(r => r != null);
    commaSeparateName = `(${regionNames.join(', ')})`;
  }
  return commaSeparateName;
}

export function flattenDataForDisplay(objectToFlatten: { [key: string]: any[] }) {
  let flattenedList: any = [];
  Object.keys(objectToFlatten).forEach((key) => {
    flattenedList = flattenedList?.concat(objectToFlatten[key]);
  });
  return flattenedList;
}

export const isFeatureFlagEnabled = (flagsAndPermissions: RegionAccessMap, regionUrl: string, featureFlagName: string) => {
  const flagsAndPermissionsForRegion = flagsAndPermissions?.[regionUrl];
  return !!flagsAndPermissionsForRegion?.[featureFlagName];
};
