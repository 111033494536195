import React, { Component } from 'react';
import { render } from 'react-dom';
import {
  GoogleMap,
  Circle, Polygon,
} from '@react-google-maps/api';
import {
  LocationDto,
  OrderDto,
  PlantDto,
  UrlKeyDto,
  GeoZoneDto,
  UserPreferencesDto,
} from '@trucktrax/trucktrax-ts-common';
import { postGISToGoogleMapsPoints } from '@trucktrax/trucktrax-common';

import { connect } from 'react-redux';
import { DefaultLocation, MapView } from './mapTypes';
import * as constants from '../../../constants/mapConstants';
import mapStyles from './map-style';
import RecenterMapBtn from './RecenterMapBtn';
import TrafficBtn from './TrafficBtn';
import PlantMarkerWithInfoPane from './markers/PlantMarkerWithInfoPane';
import JobsiteMarker from './markers/JobsiteMarker';
import PositionMarker from './markers/PositionMarker';
import markerColors from './markers/MarkerColors';

import { ZoneType } from '../../../types';
import { GEOZONE_TYPE, GEOZONE_SHAPE_STYLE } from '../../../constants/appConstants';
import { ReduxState } from '../../../store';
import { getUserPreferences } from '../../../services/userPreferencesService';
import { getIdFromUrl } from '../../../util/appUtil';
import { USER_OVERRIDE } from '../../../constants/localStorageConstants';

interface MapChangeEvent {
  location?: LocationDto;
  circle?: {
    center: LocationDto;
    radius: number;
  };
  polygon?: {
    points: LocationDto[];
  }
}

type ShapeStyles = {
  fillColor: string;
  strokeColor: string;
  fillOpacity: number;
  strokeWeight: number;
  clickable: boolean;
  zIndex: number;
  draggable: boolean;
  editable: boolean;
};

export class TTMap extends Component<TTMapProps, TTMapState> {
  circleRef?: google.maps.Circle;

  defaultZoom = 9;

  listenersRef?: google.maps.MapsEventListener[];

  map?: google.maps.Map;

  polygonRef?: google.maps.Polygon;

  gmapControlPositions = (window as any).google.maps.ControlPosition;

  mapPositions = {
    leftBottom: this.gmapControlPositions.LEFT_BOTTOM,
    rightBottom: this.gmapControlPositions.RIGHT_BOTTOM,
    topRight: this.gmapControlPositions.TOP_RIGHT,
  };

  traffic: google.maps.TrafficLayer | null = null;

  constructor(props: TTMapProps) {
    super(props);
    this.state = {
      isFirstMapRender: true,
      showTraffic: false,
      isMapHasBeenBounded: false,
    };
  }

  /** brings last selected to top and the rest of selected above unselected */
  static calculateZIndex = (lastMarkerSelected: string, truckId: string, isSelected: boolean) => {
    const [top, middle, bottom] = [100, 50, 10];
    if (lastMarkerSelected === truckId && isSelected) {
      return top;
    }
    return (isSelected) ? middle : bottom;
  };

  static mapsToLocationDto = (mapsLatLng: google.maps.LatLngLiteral | google.maps.LatLng | undefined | null) => {
    if (!mapsLatLng) {
      return {
        latitude: 0,
        longitude: 0,
      };
    }

    if (typeof mapsLatLng?.lat === 'function') {
      const latLng = mapsLatLng as google.maps.LatLng;
      return {
        latitude: latLng.lat(),
        longitude: latLng.lng(),
      };
    }

    const latLngLiteral = mapsLatLng as google.maps.LatLngLiteral;
    return {
      latitude: latLngLiteral.lat,
      longitude: latLngLiteral.lng,
    } as LocationDto;
  };

  async componentDidMount() {
    this.reCenterMap();
    const { userOverrides } = this.props;
    const { selectedMarker } = this.props;
    if ((!selectedMarker.lat || !selectedMarker.lng) && userOverrides) {
      this.defaultZoom = userOverrides?.zoom;
    }
    const userPreferences = await this.fetchUserPreferences();
    const { currentRegion, selectedProductLine } = this.props;
    const regionId = getIdFromUrl(currentRegion.url);

    const preferencesForRegionAndProductline = userPreferences?.mapFilters?.[regionId]?.[selectedProductLine!];

    const { viewLiveTraffic } = preferencesForRegionAndProductline || { viewLiveTraffic: false };
    this.showLiveTraffic(viewLiveTraffic);
  }

  fetchUserPreferences = async () => {
    const userPreferences = await getUserPreferences(this.props.userUrl!);
    return userPreferences;
  };

  showLiveTraffic = (show: boolean) => {
    if (!this.traffic) {
      return;
    }
    // traffic layer off or on; if the google maps traffic layer is available allow it
    this.traffic!.setMap(!show ? null : this.map!);
    // rerender the traffic lights button with color or gray icons including hover
    this.map!.controls[this.mapPositions.rightBottom].pop();
    this.renderTrafficButton(show);
    // toggle state
    this.setState({ showTraffic: show });
  };

  async componentDidUpdate(prevProps: TTMapProps) {
    const {
      currentRegion, plantList, ordersList, showTrucks, showExtTrucks, selectedProductLine
    } = this.props;
    if (showTrucks !== prevProps.showTrucks
      || (currentRegion?.url !== prevProps.currentRegion?.url)
      || (plantList?.length !== prevProps.plantList?.length)
      || (ordersList?.length !== prevProps.ordersList?.length)
      || showExtTrucks !== prevProps.showExtTrucks) {
      this.reCenterMap();
    }

    if (selectedProductLine !== prevProps.selectedProductLine) {
      const userPreferences = await this.fetchUserPreferences();
      const regionId = getIdFromUrl(currentRegion.url);
      const preferencesForRegionAndProductline = userPreferences?.mapFilters?.[regionId]?.[selectedProductLine!];
      const { viewLiveTraffic } = preferencesForRegionAndProductline || { viewLiveTraffic: false };
      this.showLiveTraffic(viewLiveTraffic);
    }
  }

  onLoad = (map: any) => {
    this.map = map;
    const traffic = new window.google.maps.TrafficLayer();
    this.traffic = traffic;
    this.renderRecenterMapButton();
    this.renderTrafficButton(this.state.showTraffic);
  };

  renderRecenterMapButton = () => {
    const controlButtonDiv = document.createElement('div');
    render(<RecenterMapBtn onClick={this.reCenterMap} />, controlButtonDiv);
    this.map!.controls[this.mapPositions.rightBottom].push(controlButtonDiv);
  };

  handleTrafficButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, isEnabled: boolean) => {
    this.showLiveTraffic(isEnabled);
  };

  renderTrafficButton(colorImg: boolean) {
    const controlButtonDiv = document.createElement('div');
    render(<TrafficBtn
      onClick={this.handleTrafficButtonClick}
      colorImg={colorImg}
      userUrl={this.props.userUrl!}
      currentRegion={this.props.currentRegion?.url || ''}
      selectedProductLine={this.props.selectedProductLine!}
    />, controlButtonDiv);

    this.map!.controls[this.mapPositions.rightBottom].push(controlButtonDiv);
  }

  validMarkerFilter = (item: any) => (
    item.active === true
    && item.trackedObject
    && item.region
    && item.region?.url === this.props.currentRegion.url
    && item.driver
  );

  zoomToBounds = () => {
    let boundsNumber = 0;
    let isOnlyValidMarkers = true;
    const bounds = new (window as any).google.maps.LatLngBounds();

    const extendBounds = (lat?: number, lng?: number): number => {
      const point = new (window as any).google.maps.LatLng(lat, lng);
      bounds.extend(point);
      return 1;
    };

    const {
      markers, currentRegion, plantList, ordersList,
    } = this.props;

    if (!this.state.isFirstMapRender) return;

    const validMarkerList = markers!.filter(this.validMarkerFilter);

    if (validMarkerList.length > 1) {
      validMarkerList.forEach(position => {
        const { latitude, longitude } = position.location || { latitude: 0, longitude: 0 };
        boundsNumber += extendBounds(latitude, longitude);
      });
    } else {
      const position = validMarkerList.length === 1 ? validMarkerList[0].location : currentRegion.location;
      const { latitude, longitude } = position!;
      boundsNumber += extendBounds(latitude, longitude);
      // no need to increment boundsNumber a second time
      extendBounds(latitude! + 0.0003, longitude! + 0.0003);
    }

    // add plants to map
    if (plantList && this.props.showPlants) {
      plantList.forEach(plant => {
        const { latitude, longitude } = plant.location || { latitude: 0, longitude: 0 };
        boundsNumber += extendBounds(latitude, longitude);
      });
      isOnlyValidMarkers = false;
    }

    // add orders to map
    if (ordersList && ordersList.length > 0) {
      ordersList.forEach(order => {
        const { latitude, longitude } = order.geoZone?.zone.circle?.center || { latitude: 0, longitude: 0 };
        boundsNumber += extendBounds(latitude, longitude);
      });
      isOnlyValidMarkers = false;
    }
    if (!this.map) {
      return;
    }

    const userMapBoundsOverride = new (window as any).google.maps.LatLngBounds();
    const userMapCenterOverride = JSON.parse(localStorage.getItem(USER_OVERRIDE) || '{}');
    if (userMapCenterOverride && userMapCenterOverride.lat && userMapCenterOverride.lng) {
      const userLatLngOverride = new (window as any).google.maps.LatLng(userMapCenterOverride.lat, userMapCenterOverride.lng);
      userMapBoundsOverride.extend(userLatLngOverride);
      this.map.setCenter(userMapBoundsOverride.getCenter());
      this.map.setZoom(userMapCenterOverride.zoom);
      this.setState({ isFirstMapRender: false });
      return;
    }

    if (boundsNumber <= 1 || isOnlyValidMarkers) {
      this.map.setCenter(bounds.getCenter());
      this.map.setZoom(this.defaultZoom);
    } else {
      this.map.fitBounds(bounds);
    }

    this.setState({ isFirstMapRender: false });
  };

  reCenterMap = () => {
    this.setState({ isFirstMapRender: true }, this.zoomToBounds);
  };

  handleCenterChange = () => {
    if (this.map && this.map.getCenter && this.props.onCenterChange) {
      this.props.onCenterChange!(JSON.stringify(this.map.getCenter()?.toJSON()));
    }
  };

  generateTrucks = () => {
    const { markers } = this.props;
    const filteredMarkers = markers && markers.filter(this.validMarkerFilter);
    return filteredMarkers?.map(position => {
      const urlToArray = position.trackedObject!.url!.split('/');
      const truckId = urlToArray[urlToArray.length - 1];
      const isSelected = (this.props.popupToggleMap as PopupToggleMapType)[truckId];

      const marker: MarkerType = {
        markerId: truckId,
        type: 'Truck',
        markerData: {
          markerId: truckId,
          type: 'Truck',
          driverDetails: position.driver,
          truckDetails: position,
          ticket: position.ticket!,
        },
      };

      // brings last selected to top and the rest of selected above unselected
      const { lastMarkerSelected } = this.props.popupToggleMap as PopupToggleObjectType;
      const zIndex = TTMap.calculateZIndex(lastMarkerSelected, truckId, isSelected);
      const truckHasTicket = !!position.ticket;
      const truckMarkers = (
        <PositionMarker
          isRouteHistory={false}
          key={truckId}
          position={position}
          zIndex={zIndex}
          mapRef={this.map}
          onClick={() => this.onMarkerClick(marker)}
          isSelected={isSelected}
          truckHasTicket={truckHasTicket}
          onCloseClick={() => this.onMarkerClose(marker)}
          onLogoutDriver={() => this.handleLogoutDriver(position)}
          markerData={marker.markerData}
          markerStatusMap={this.props.markerStatusMap}
          driverInfoMessageCallback={this.props.driverInfoMessageCallback}
          ticketInfoTicketsCallBack={this.props.ticketInfoTicketsCallBack}
        />
      );
      return truckMarkers;
    });
  };

  renderJobsites = (plantColors: any) => {
    if (!this.props.ordersList) {
      return [];
    }

    return this.props.ordersList.map(order => {
      const markerColor = plantColors && plantColors[order?.plant?.url ?? ''];
      const orderId = order.id.toString();
      const isSelected = (this.props.popupToggleMap as PopupToggleMapType)[orderId];

      const marker: MarkerType = {
        markerId: orderId,
        type: 'Order',
        markerData: order,
      };

      return (
        <JobsiteMarker
          key={order.id}
          order={order}
          plantList={this.props.plantList}
          mapRef={this.map}
          showGeofence={this.props.showJobsitesGeofences}
          markerColor={markerColor}
          onClick={() => this.onMarkerClick(marker)}
          onCloseClick={() => this.onMarkerClose(marker)}
          isSelected={isSelected}
          orderDetailsCallback={() => this.props.orderDetailsCallback!(marker)}
          messageDriversOnOrder={this.props.messageDriversOnOrder}
        />
      );
    });
  };

  prevChange = {};

  onChange(change: MapChangeEvent) {
    if (!this.map) return;
    if (JSON.stringify(this.prevChange) === JSON.stringify(change)) {
      return;
    }

    this.prevChange = change;
    if (this.props.onChange !== undefined) { this.props.onChange(change); }
  }

  onCircleLoad = (circle: google.maps.Circle) => {
    this.circleRef = circle;
    if (!this.state.isMapHasBeenBounded) {
      this.setState({ isMapHasBeenBounded: true }, () => this.zoomToBoundsCP());
    }
  };

  onCircleUnmount = () => {
    this.listenersRef = [];
    this.circleRef = undefined;
  };

  onCircleChanged = () => {
    if (!this.map) return;
    this.onCircleComplete(this.circleRef!);
  };

  onCircleComplete = (circle?: google.maps.Circle) => {
    if (!this.map) return;
    if (!circle) return;
    const center = TTMap.mapsToLocationDto(circle.getCenter());
    this.onChange({
      circle: {
        center,
        radius: circle.getRadius(),
      },
      polygon: undefined,
    });
  };

  renderGeozoneCircle = (shapeStyles: ShapeStyles) => {
    const zone = this.props.currentGeozone?.zone;
    return (zone?.circle && zone?.circle.center)
      && (
        <Circle
          center={{
            lat: zone?.circle.center.latitude!,
            lng: zone?.circle.center.longitude!,
          }}
          onLoad={this.onCircleLoad}
          radius={zone?.circle.radius}
          onCenterChanged={this.onCircleChanged}
          onUnmount={this.onCircleUnmount}
          options={shapeStyles}
        />
      );
  };

  onPolygonChanged = () => {
    this.onPolygonComplete(this.polygonRef!);
  };

  // Clean up refs
  onPolygonUnmount = () => {
    this.listenersRef!.forEach(lis => lis.remove());
    this.listenersRef = [];
    this.polygonRef = undefined;
  };

  onPolygonComplete = (polygon: google.maps.Polygon) => {
    const newPolygon = polygon.getPath().getArray().map(point => ({
      lat: point.lat(),
      lng: point.lng(),
    }));
    const points = TTMap.googleMapsToPostGISPoints(newPolygon) ?? [];
    this.onChange({
      circle: undefined,
      polygon: {
        points,
      },
    });
  };

  static googleMapsToPostGISPoints = (points?: { lat: number, lng: number }[]) => {
    if (!points) return null;

    points.push(points[0]);
    return points.map(point => ({
      latitude: point.lat,
      longitude: point.lng,
    }));
  };

  getGeofenceStyle(zoneType?: ZoneType) {
    const shapeStyles: ShapeStyles = {
      fillColor: '#67B4F6',
      strokeColor: '#67B4F6',
      fillOpacity: 0.25,
      strokeWeight: 2,
      clickable: false,
      zIndex: 1,
      draggable: true,
      editable: this.props.isEditable ?? false,
    };

    type GeozoneType = typeof GEOZONE_SHAPE_STYLE;
    type GeozoneShapeStyle = {
      fillColor: string;
      strokeColor: string;
      zIndex: number;
    };

    const zone: { [geozoneName: string]: GeozoneShapeStyle } = {};

    zone[GEOZONE_TYPE.PLANT] = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.PLANT as keyof GeozoneType],
      zIndex: 3,

    };
    zone[GEOZONE_TYPE.INQUEUE] = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.INQUEUE as keyof GeozoneType],
      zIndex: 2,
    };
    zone[GEOZONE_TYPE.RETURN] = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.RETURN as keyof GeozoneType],
      zIndex: 1,
    };

    return zoneType
      ? { ...shapeStyles, ...zone[zoneType] }
      : shapeStyles;
  }

  onPolygonLoad = (polygon: google.maps.Polygon) => {
    this.polygonRef = polygon;
    if (!this.listenersRef) this.listenersRef = [];
    const path = polygon.getPath();
    this.listenersRef.forEach(lis => lis.remove());
    this.listenersRef = [
      path.addListener('set_at', this.onPolygonChanged),
      path.addListener('insert_at', this.onPolygonChanged),
      path.addListener('remove_at', this.onPolygonChanged),
    ];

    if (!this.state.isMapHasBeenBounded) {
      this.setState({ isMapHasBeenBounded: true }, () => this.zoomToBoundsCP());
    }
  };

  isValidLocation = () => {
    const location = this.props.currentGeozone?.location;
    const lat = location?.latitude ?? 0;
    const lng = location?.longitude ?? 0;
    return (lat !== 0 || lng !== 0);
  };

  zoomToBoundsCP = () => {
    if (!this.map) return;
    const bounds = new window.google.maps.LatLngBounds();

    if (this.isValidLocation()) {
      const location = this.props.currentGeozone?.location;
      bounds.extend(new window.google.maps.LatLng(
        (location?.latitude ?? 0) - 0.001,
        (location?.longitude ?? 0) - 0.001
      ));
      bounds.extend(new window.google.maps.LatLng(
        (location?.latitude ?? 0) + 0.001,
        (location?.longitude ?? 0) + 0.001
      ));
    }

    if (this.circleRef) {
      bounds.union(this.circleRef.getBounds()!);
    }

    if (this.polygonRef) {
      this.polygonRef.getPath().getArray().forEach(point => {
        bounds.extend(point);
      });
    }

    this.map.fitBounds(bounds);
  };

  generateGeozonePolygon = (shapeStyles: ShapeStyles) => {
    const zone = this.props.currentGeozone?.zone;
    return (zone?.polygon && zone?.polygon.points) // && !this.props.editMarkerOnly)
      && (
        <Polygon
          path={postGISToGoogleMapsPoints(this.props.currentGeozone?.zone?.polygon?.points) as any}
          onLoad={this.onPolygonLoad}
          editable
          // Event used when dragging the whole Polygon
          onDragEnd={this.onPolygonChanged}
          // Event used when manipulating and adding points
          onMouseUp={this.onPolygonChanged}
          onUnmount={this.onPolygonUnmount}
          options={shapeStyles}
        />
      );
  };

  onMarkerClick = (marker: MarkerType) => this.props.onMarkerClick!(marker);

  onMarkerClose = (marker: MarkerType) => this.props.onMarkerClose!(marker);

  handleLogoutDriver = (position: any) => this.props.handleLogoutDriver!(position);

  assignPlantColors = () => {
    const { plantList } = this.props;

    if (!plantList) { return {}; }

    const plantColorAssignments = plantList.reduce((arr, plant) => {
      const { url } = plant;
      const colorIndex = plant.id % markerColors.length;
      const colorAssignment = { ...arr, [url!]: markerColors[colorIndex] };

      return colorAssignment;
    }, {});

    return plantColorAssignments;
  };

  generatePlants = (plantColors: any) => this.props.plantList && (this.props.showPlants)
    && this.props.plantList.map(plant => {
      const markerColor = plantColors && plantColors[plant.url!];
      const plantId = plant.id.toString();
      const isSelected = (this.props.popupToggleMap as PopupToggleMapType)[plantId];
      const marker: MarkerType = {
        markerId: plantId,
        type: 'Plant',
        markerData: plant,
      };

      const { lastMarkerSelected } = this.props.popupToggleMap as PopupToggleObjectType;
      const zIndex = TTMap.calculateZIndex(lastMarkerSelected, plantId, isSelected);

      return (
        <PlantMarkerWithInfoPane
          key={plant.id}
          plant={plant}
          mapRef={this.map}
          showGeofence={this.props.showPlantsGeofences}
          markerColor={markerColor}
          updateToFieldValueWithPlantDrivers={this.props.updateToFieldValueWithPlantDrivers}
          onClick={() => this.onMarkerClick(marker)}
          onCloseClick={() => this.onMarkerClose(marker)}
          isSelected={isSelected}
          zIndex={zIndex}
        />
      );
    });

  static renderTableRow = (header: string, value: string | undefined) => {
    const val = value === '' ? undefined : value; // empty strings have no place here
    return (
      <tr>
        <th>{header}</th>
        <td>{val ?? 'N/A'}</td>
      </tr>
    );
  };

  render() {
    const plantColors = this.assignPlantColors();
    const containerStyle = {
      width: '100%',
      height: '100%',
    };
    const definedZoom = this.map?.getZoom() ?? this.defaultZoom;
    const geoFenceStyle = this.getGeofenceStyle(this.props.geofenceAreaType);
    const map = (
      <GoogleMap
        center={this.props.center}
        onDrag={this.props.drag}
        mapContainerStyle={containerStyle}
        options={{
          zoom: definedZoom,
          streetViewControl: false,
          mapTypeControl: true,
          fullscreenControl: false,
          rotateControl: false,
          controlSize: constants.GM_CONTROLSIZE,
          mapTypeControlOptions: {
            mapTypeIds: [
              (window as any).google.maps.MapTypeId.ROADMAP,
              (window as any).google.maps.MapTypeId.SATELLITE,
            ],
            style: (window as any).google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: this.mapPositions.topRight,
          },
          styles: mapStyles,
          disableDoubleClickZoom: false,
        }}
        onLoad={this.onLoad}
        onDragEnd={this.handleCenterChange}
        onZoomChanged={() => this.props.onZoomChange(this.map?.getZoom() || 0)}
      >
        {this.generateTrucks()}
        {this.generatePlants(plantColors)}
        {this.renderJobsites(plantColors)}
        {this.renderGeozoneCircle(geoFenceStyle)}
        {this.generateGeozonePolygon(geoFenceStyle)}
      </GoogleMap>
    );
    return map;
  }
}

export type PopupToggleMapType = { [key: string]: boolean };
type PopupToggleObjectType = { lastMarkerSelected: string };

interface RegionWithLocation extends UrlKeyDto {
  location?: LocationDto
}

export interface MarkerDataType {
  markerId: string,
  type: string,
  driverDetails: any,
  truckDetails?: MapView,
  ticket: UrlKeyDto,
  status?: string
}

export interface MarkerType {
  markerId: string,
  type: string,
  markerData: OrderDto | MarkerDataType | PlantDto
}

export function mapStateToProps(state: ReduxState) {
  return {
    routeHistoryHoverSelection: state.routeHistoryHoverSelection,
    userOverrides: state.userOverrides,
    selectedMarker: state.selectedMarker,
    userUrl: state.userUrl
  };
}

type ReduxProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps {
  polygon?: {
    points: LocationDto[];
  };
  showPlants?: boolean,
  showPlantsGeofences?: boolean,
  showJobsitesGeofences?: boolean,
  currentRegion: RegionWithLocation,
  ordersList?: OrderDto[],
  markers?: MapView[],
  plantList?: PlantDto[],
  center?: DefaultLocation,
  drag?: () => void,
  driverInfoMessageCallback?: (obj: any) => void,
  updateToFieldValueWithPlantDrivers: (url: string) => void,
  ticketInfoTicketsCallBack?: (obj: any) => void,
  handleLogoutDriver?: (obj: any) => void,
  orderDetailsCallback?: (marker: MarkerType) => void,
  messageDriversOnOrder?: (order: OrderDto) => void,
  onCenterChange?: (json: string) => void,
  popupToggleMap?: PopupToggleMapType | PopupToggleObjectType,
  onMarkerClick?: (marker: MarkerType) => void,
  onMarkerClose?: (marker: MarkerType) => void,
  showTrucks?: boolean,
  showExtTrucks?: boolean,
  markerStatusMap?: {
    [x: string]: string;
  },
  currentGeozone?: GeoZoneDto,
  geofenceAreaType?: ZoneType,
  isEditable?: boolean,
  selectedProductLine?: string,
  onChange?: (evt: MapChangeEvent) => void;
  onZoomChange: (zoomLevel: number) => void;
}

export interface TTMapState {
  isFirstMapRender: boolean,
  showTraffic: boolean,
  isMapHasBeenBounded: boolean
  userPreferences?: UserPreferencesDto;
}

export type TTMapProps = OwnProps & ReduxProps;

export default connect(mapStateToProps)(TTMap);
