import { DateWrapper } from '@trucktrax/trucktrax-common';

const formatRegex = /^(\d{4})-(\d{2})-(\d{2})/;

function throwIfWrongFormat(date: string) {
  if (!formatRegex.test(date)) {
    throw new Error(`Invalid date ${date}.  Must be formatted as yyyy-MM-dd`);
  }
  return true;
}

export function downloadBlobFromUrl(blob: any, filename: string, viewonly = false) {
  const blobUrl = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  if (!viewonly) {
    anchor.download = filename;
  } else {
    anchor.target = '_blank';
  }
  anchor.href = blobUrl;
  anchor.click();
}

export function getFileNameFromHeaders(response: Response) {
  const header = response.headers.get('Content-Disposition');
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(header!);
  return matches ? matches[1].replace(/['"]/g, '') : '';
}

export function dateToStartOfDayISO8601(date: string) {
  throwIfWrongFormat(date);
  return new Date(date).toISOString();
}

export function dateToEndOfDayISO8601(date: string) {
  throwIfWrongFormat(date);
  return new DateWrapper(date).add({ days: 1 }).toISOString();
}
