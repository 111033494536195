/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { ProductLine, CannedMessagesDto } from '@trucktrax/trucktrax-ts-common';
import { Button } from '@trucktrax/trucktrax-common';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styles from './DispatcherCannedMessages.module.scss';
import {
  ACCENT,
  ADMIN_LABELS,
  ARE_YOU_SURE,
  CANCEL_LABEL,
} from '../../../constants/appConstants';
import QuestionMarkSvg from '../../../assets/img/questionMark.svg';
import { ModalOptions, ModalType } from '../../../types';

interface Props {
  productLines: Array<ProductLine>;
  messages: CannedMessagesDto[];
  setMessages: (m: CannedMessagesDto[]) => void;
  isEditMode: boolean;
  openModal: (modalConfig: ModalOptions) => void;
  closeModal: () => void;
}

const CANNED_MESSAGE_CHARACTER_LIMIT = 255;

const DispatcherCannedMessages: React.FC<Props> = ({
  productLines = [],
  messages,
  setMessages,
  isEditMode,
  openModal,
  closeModal
}) => {
  const [selectedTab, setSelectedTab] = useState<ProductLine>(productLines?.[0]);
  const selectedTabIndex = productLines ? productLines?.findIndex(p => p === selectedTab) : -1;
  const aTabIsSellected = selectedTabIndex !== -1;
  const currentMessages = messages.find(m => m.productLine === selectedTab)?.messages || [];

  const messageInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [focusLastInput, setFocusLastInput] = useState(false);

  // Determine if any messages are empty to conditionally disable the add button
  const isAddButtonDisabled = currentMessages.some(message => message.trim() === '');

  // focuses a newly added input
  useEffect(() => {
    if (focusLastInput && currentMessages.length > 0) {
      const lastInputIndex = currentMessages.length - 1;
      messageInputRefs.current[lastInputIndex]?.focus();
      setFocusLastInput(false); // Reset the focus trigger
    }
  }, [currentMessages.length, focusLastInput]);

  // re-selects product line if the previously selected product line is disabled
  useEffect(() => {
    if (!productLines?.includes(selectedTab)) {
      setSelectedTab(productLines?.[0] || null);
    }
  }, [productLines, selectedTab]);

  const modifyMessages = (operation: 'add' | 'remove' | 'update', index?: number, value?: string) => {
    const indexProductLine = messages.findIndex(m => m.productLine === selectedTab);
    if (indexProductLine >= 0) {
      const newMessages = [...messages[indexProductLine].messages];
      if (operation === 'add') {
        newMessages.push('');
        setFocusLastInput(true);
      } else if (operation === 'remove' && typeof index === 'number') {
        newMessages.splice(index, 1);
      } else if (operation === 'update' && typeof index === 'number' && value !== undefined) {
        // disallow special characters
        // defined by Robin and SGTX-2190 as anything other than a letter, number, a space, or one of the following exceptions ; , ! . - # ?
        const cleanedValue = value.replace(/[^a-zA-Z0-9;,.!#? -]/g, '');
        newMessages[index] = cleanedValue;
      }

      const updatedMessages = [...messages];
      updatedMessages[indexProductLine] = {
        ...messages[indexProductLine],
        messages: newMessages,
      };

      setMessages(updatedMessages);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Enter' && !isAddButtonDisabled && currentMessages[index].trim() !== '') {
      modifyMessages('add');
    }
  };

  const activateRemovalConfirmationModal = (index: number) => {
    const messageText = currentMessages[index];

    // skip the confirmation modal if the message is already empty
    if (messageText.trim() === '') {
      modifyMessages('remove', index);
      return;
    }

    openModal({
      modalType: ACCENT as ModalType,
      modalTitle: ARE_YOU_SURE,
      modalBody: (
        <p>
          Delete &quot;
          {messageText}
          &quot;?
        </p>
      ),
      modalOpen: true,
      isDeleteConfirmation: true,
      acceptText: 'Delete Message',
      cancelText: CANCEL_LABEL,
      disabled: false,
      acceptDialog: () => {
        modifyMessages('remove', index);
        closeModal();
      },
    });
  };

  return (
    <section className={styles.container}>
      <div className={styles.cannedMessagesLabel}>{ADMIN_LABELS.DISPATCHER_CANNED_MESSAGES}</div>
      <div className={styles.cannedMessagesContainer}>
        <div className={styles.tabsContainer}>
          {aTabIsSellected && (
            <Tabs
              value={selectedTabIndex}
              onChange={(e, tabIndex) => {
                setSelectedTab(productLines[tabIndex]);
              }}
            >
              {productLines?.map((p) => (
                <Tab key={p} label={p === ProductLine.ReadyMix ? 'Ready-mix' : p} />
              ))}
            </Tabs>
          )}
          {!aTabIsSellected && (
            <Tabs />
          )}
        </div>
        {currentMessages.length === 0 && (
          <div className={styles.emptyStateContainer}>
            <img alt="canned-messages-empty-state" src={QuestionMarkSvg} />
            <div className={styles.emptyStateMessage}>
              {isEditMode && aTabIsSellected && (
                <>
                  <div>You haven&apos;t added any messages yet.</div>
                  <div>Click Add a Message to get started.</div>
                </>
              )}
              {!isEditMode && (
                <>
                  <div>There currently aren&apos;t any Canned</div>
                  <div>Messages configured. Click Edit Region</div>
                  <div>to add them.</div>
                </>
              )}
              {isEditMode && !aTabIsSellected && (
                <>
                  <div>Add product lines above before adding</div>
                  <div>canned messages.</div>
                </>
              )}

            </div>
          </div>
        )}
        {currentMessages.length > 0 && (
          <div className={cx(styles.messagesContainer, isEditMode && styles.hasFooter)}>
            {currentMessages.map((m, index) => (
              <div className={cx(styles.messageRow, !isEditMode && styles.readOnly)} key={index}>
                {isEditMode && (
                  <>
                    <div className={styles.messageInputContainer}>
                      <input
                        ref={(el) => { messageInputRefs.current[index] = el; }}
                        className={styles.messageInput}
                        type="text"
                        maxLength={CANNED_MESSAGE_CHARACTER_LIMIT}
                        value={m}
                        onChange={(e) => modifyMessages('update', index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                      />
                      <div className={styles.messageInputCharacterCount}>
                        {m.length}
                        /
                        {CANNED_MESSAGE_CHARACTER_LIMIT}
                        {' '}
                        chars
                      </div>
                    </div>
                    <button className={styles.removeMessageBtn} onClick={() => activateRemovalConfirmationModal(index)}>
                      <i className={cx('icon-delete', styles.iconDelete)} />
                    </button>
                  </>
                )}
                {!isEditMode && (
                  <div className={styles.readOnlyMessage}>{m}</div>
                )}
              </div>
            ))}
          </div>
        )}
        {isEditMode && aTabIsSellected && (
          <div className={styles.bottomBtnContainer}>
            <Button
              disabled={isAddButtonDisabled}
              buttonClassName="tt-btn-secondary"
              name="Add Message"
              onClick={() => modifyMessages('add')}
              iconClassName="icon-add"
              dataTest="add-message-button-data-test"
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default DispatcherCannedMessages;
