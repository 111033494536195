import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import styles from '../header/VehicleSearch.module.css';
import { SEARCH } from '../../../constants/appConstants';
import Autocomplete from '../forms/Autocomplete';

export class TicketSearch extends Component<TicketSearchProps> {
  static defaultProps = {
    searchTerm: '',
  };

  state = {
    dataSource: [],
    hintText: SEARCH,
    errorText: '',
  };

  debouncedCall = debounce(() => {
  }, 1000, {
    leading: false,
    trailing: true,
  });

  handleUpdateInput = (searchText: string) => {
    this.props.setTerm(searchText);
    this.debouncedCall();
  };

  clearSearch = () => {
    this.props.clearSearchText();
    this.handleUpdateInput('');
  };

  render() {
    const hintText = this.props.placeholder ?? this.state.hintText;
    return (
      <div className={cx(styles.searchWrapper, styles.ticketSearchWrapper)}>
        <Autocomplete
          dataTest="ticket-search-tool"
          hintText={hintText}
          onUpdateInput={this.handleUpdateInput}
          searchText={this.props.searchTerm}
          dataSource={this.state.dataSource}
          errorText={this.state.errorText}
          openOnFocus={false}
          autocompleteHeight="500px"
          clearSearch={this.clearSearch}
          onNewRequest={() => { }}
          maxLength={this.props.maxLength}
        />
      </div>
    );
  }
}

export interface TicketSearchProps {
  searchTerm?: string,
  clearSearchText: VoidFunc,
  setTerm: (searchTerm: string) => void,
  placeholder?: string,
  maxLength?: number
}

const mapStateToProps = (state: any) => ({
  dataSource: state.ticketsList,
  currentRegion: state.currentRegion,
  ticketsList: state.ticketsList,
});

export default connect<any, any, any>(mapStateToProps)(TicketSearch);
