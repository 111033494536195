import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Label } from '@trucktrax/trucktrax-common';
import { UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import styles from './ImportCsvModal.module.css';
import CardRadio from '../forms/CardRadio';
import { GEOTRAX_LITE_FLAG, IMPORT_CSV_BTN_TEXT } from '../../../constants/appConstants';
import FooterMessage from '../forms/FooterMessage';
import { RegionAccessMap } from '../../../util/permissionUtil';
import { isFeatureFlagEnabled } from '../../../util/featureFlagUtil';
import { ReduxState } from '../../../store';

export type CsvType = 'trucks' | 'plants' | 'drivers' | 'users';
export interface ImportCsvModalState {
  errorMessage: string;
  csvFileName: string;
  radioValue: CsvType;
}

export class ImportCsvModal extends Component<ImportCsvModalProps, ImportCsvModalState> {
  static defaultProps = {
    errors: [],
    disabled: false,
  };

  csvFile: React.RefObject<HTMLInputElement>;

  constructor(props: ImportCsvModalProps) {
    super(props);

    this.csvFile = React.createRef();
    this.state = {
      errorMessage: 'Required field',
      csvFileName: '',
      radioValue: 'trucks',
    };
  }

  componentDidMount() {
    if (!this.csvFile.current) {
      return;
    }

    this.csvFile.current!.addEventListener('change', e => this.updateFileName(e));
  }

  componentWillUnmount() {
    if (!this.csvFile.current) {
      return;
    }

    this.csvFile.current!.removeEventListener('change', e => this.updateFileName(e));
  }

  onSubmit = () => {
    if (this.csvFile.current) {
      const file = this.csvFile.current.files![0];
      this.props.postCsv(file, this.state.radioValue);
      this.csvFile.current.value = '';
    }
  };

  updateRadioValue = ({ csvType }: { [name: string]: CsvType }) => {
    this.setState({ radioValue: csvType });
  };

  updateFileName = (event: Event) => {
    event.preventDefault();
    this.props.clearErrors();

    const hasFile = this.csvFile?.current?.files?.length! > 0 || false;
    const csvFileName = hasFile ? this.csvFile?.current?.files![0].name : '';
    this.setState({ csvFileName: csvFileName ?? '' });
  };

  // Create some blobs we can use as download link targets below
  /* eslint-disable max-len */
  csvTemplates = {
    trucks: 'data:text/csv;charset=utf-8,truck_alias,external_id,plant_external_id,primary_product_line,vehicle_type,default_driver_external_id',
    drivers: 'data:text/csv;charset=utf-8,pin,first_name,last_name,primary_product_line,plant_external_id,external_id',
    externalDrivers: 'data:text/csv;charset=utf-8,geotrax_lite_access,first_name,last_name,email,primary_product_line,hauler_external_ids',
    plants: 'data:text/csv;charset=utf-8,external_id,name,address_line_one,address_line_two,city,state,zip_code,plant_lat,plant_lon,map_radius,primary_product_line',
    users: 'data:text/csv;charset=utf-8,first_name,last_name,username,external_id,single_sign_on_id,email,phone,primary_product_line,secondary_product_line,primary_region_id,secondary_region_id,permission_id',
  };
  /* eslint-enable max-len */

  render() {
    const { errors } = this.props;
    const isGeoTraxLiteEnabled = isFeatureFlagEnabled(
      this.props.userPermission,
      this.props.currentRegion?.url ?? '',
      GEOTRAX_LITE_FLAG,
    );

    const trucksRadioOption = {
      label: (
        <React.Fragment>
          <span>Trucks (</span>
          <a href={this.csvTemplates.trucks} download="trucks-template.csv">Download CSV template</a>
          <span>)</span>
        </React.Fragment>
      ),
      value: 'trucks',
    };

    const driversRadioOption = {
      label: (
        <React.Fragment>
          <span>{isGeoTraxLiteEnabled ? 'Internal Drivers (' : 'Drivers ('}</span>
          <a href={this.csvTemplates.drivers} download="drivers-template.csv">Download CSV template</a>
          <span>)</span>
        </React.Fragment>
      ),
      value: 'drivers',
    };

    const externalDriversRadioOption = {
      label: (
        <React.Fragment>
          <span>External Drivers (</span>
          <a href={this.csvTemplates.externalDrivers} download="external-drivers-template.csv">Download CSV template</a>
          <span>)</span>
        </React.Fragment>
      ),
      value: 'externalDrivers',
    };

    const plantsRadioOption = {
      label: (
        <React.Fragment>
          <span>Plants (</span>
          <a href={this.csvTemplates.plants} download="plants-template.csv">Download CSV template</a>
          <span>)</span>
        </React.Fragment>
      ),
      value: 'plants',
    };

    const usersRadioOption = {
      label: (
        <React.Fragment>
          <span>Users (</span>
          <a href={this.csvTemplates.users} download="users-template.csv">Download CSV template</a>
          <span>)</span>
        </React.Fragment>
      ),
      value: 'users',
    };

    const radioButtons = isGeoTraxLiteEnabled
      ? [
        trucksRadioOption,
        driversRadioOption,
        externalDriversRadioOption,
        plantsRadioOption,
        usersRadioOption,
      ]
      : [
        trucksRadioOption,
        driversRadioOption,
        plantsRadioOption,
        usersRadioOption,
      ];

    const hasErrors = errors && errors.length > 0;
    const isDefaultMessage = this.state.errorMessage === 'Required field';
    return (
      <div className={styles.csvContainer}>
        <div className={styles.modalContent}>
          <div className={styles.csvForm}>
            <CardRadio
              value={this.state.radioValue}
              id="csvType"
              onChange={this.updateRadioValue}
              label="Data Type"
              className={styles.cardRadio}
              initialSelected={radioButtons[0]}
              radioButtons={radioButtons}
            />
            <Label className={styles.fileNameHeader} isRequired>
              CSV File
            </Label>
            <span
              className={hasErrors
                ? styles.fileNameError
                : styles.fileName}
            >
              {this.state.csvFileName
                && (
                  <i
                    aria-hidden="true"
                    className={cx('icon-spreadsheet', styles.csvIcon)}
                  />
                )}
              <span className={styles.fileNameText}>
                {this.state.csvFileName}
              </span>
            </span>
            {hasErrors
              && (
                <div className={styles.errorList}>
                  <span className={styles.listWarning}>
                    <i
                      aria-hidden="true"
                      className={cx('icon-warning', styles.warningIcon)}
                    />
                    <span className={styles.listWarningText}>
                      The following error(s) were found:
                    </span>
                  </span>
                  <ul className={styles.unorderedList}>
                    {errors!.map(x => (
                      <li
                        key={x.field + x.message}
                        style={styles}
                      >
                        {!x.field ? x.message : `${x.field}: ${x.message}`}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            <input
              id="file"
              name="formFile"
              className={styles.input}
              ref={this.csvFile}
              type="file"
              accept="text/csv"
            />
            <label
              htmlFor="file"
              className={cx(styles.inputLabel, 'tt-btn-secondary')}
            >
              Choose File
            </label>
          </div>
        </div>
        <div className={styles.featureFooter}>
          <FooterMessage
            showAsterisk={isDefaultMessage}
            text={this.state.errorMessage}
            errorStyle={!isDefaultMessage}
          />
          <Button
            onClick={this.onSubmit}
            buttonClassName="tt-btn tt-btn--submit"
            name={IMPORT_CSV_BTN_TEXT}
            dataTest="import-csv-submit-btn"
            disabled={!this.state.csvFileName || this.props.disabled}
          />
        </div>
      </div>
    );
  }
}

export interface CsvErrror {
  field: string;
  message: string;
}

export interface ImportCsvModalProps {
  // own props
  postCsv: (file: File, type: CsvType) => void;
  errors?: CsvErrror[],
  clearErrors: () => void;
  disabled?: boolean;
  // redux props
  currentRegion?: UrlKeyDto;
  userPermission: RegionAccessMap;
}

const mapStateToProps = (state: ReduxState) => ({
  currentRegion: state.currentRegion ?? { url: '' },
  userPermission: state.userPermission || {},
});

export default connect(mapStateToProps)(ImportCsvModal);
