import React, { Component } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Popover from '@mui/material/Popover';
import { AnswerType, ChecklistDto, RegionDto } from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import { Button } from '@trucktrax/trucktrax-common';
import styles from '../ChecklistQuestions.module.css';
import CopyToRegionsComponent from '../CopyToRegionsComponent';
import { EditableQuestion } from '../ChecklistQuestions';

export default class ChecklistQuestionsTable extends Component<ChecklistQuestionsTableProps> {
  state = {
    anchorEl: null,
  };

  openCopyRegionsBtn = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseCopyRegions = () => {
    this.setState({ anchorEl: null });
  };

  handleCopyingQuestions = (regionsChecked: RegionDto[]) => {
    this.props.handleCopyingQuestions(regionsChecked);
    this.handleCloseCopyRegions();
  };

  hasSelectAll = () => {
    const { questionsSelected } = this.props;
    return questionsSelected && questionsSelected.length > 0;
  };

  hasSelectedIndeterminate = () => {
    const { questionsSelected, questions } = this.props;
    return questionsSelected && questions
      && !(questionsSelected.length === 0 || questionsSelected.length === questions.length);
  };

  isSelectedCheckbox = (item: Question) => {
    const { questionsSelected } = this.props;
    return questionsSelected && questionsSelected.some(q => q === item);
  };

  removeOne = (item: Question) => {
    const { handleRemoveQuestions } = this.props;
    handleRemoveQuestions([item]);
  };

  removeAll = () => {
    const { questions, handleRemoveQuestions } = this.props;
    handleRemoveQuestions(questions.filter(q => this.isSelectedCheckbox(q)));
  };

  renderEditQuestions = () => {
    const {
      questionsSelected,
      questions,
      questionFocus,
      regionValue,
      regionList,
      checklistDetails,
      assignedRegionList,
      handleAddQuestion,
      handleSelectAll,
      handleSelectCheckbox,
      handleQuestionChange,
      handleQuestionFocus,
    } = this.props;

    const isOpen = Boolean(this.state.anchorEl);
    const copyRegionsId = isOpen ? 'copy-regions-popover' : undefined;
    const copyRegionsBtnStyle = isOpen ? styles.activeCopyRegions : null;
    const showActionBtns = questionsSelected.length > 0;
    const deleteQuestionsBtn = (
      <button
        className={styles.questionsListHeaderDeleteBtn}
        onClick={this.removeAll}
      >
        <i className={cx(styles.questionsListHeaderDeleteIcon, 'icon-delete')} />
        <span className={styles.questionsListHeaderTxt}>Delete</span>
      </button>
    );

    const copyRegionsBtn = (
      <div>
        <button
          className={cx(styles.questionsListCopyRegionBtn, copyRegionsBtnStyle)}
          onClick={this.openCopyRegionsBtn}
        >
          <i className={cx(styles.questionsCopyRegionsIcon, 'icon-share')} />
          <span className={styles.questionsListHeaderTxt}>Copy to Region</span>
          <i className={cx(styles.questionsCopyRegionsArrowIcon, 'icon-keyboard-arrow-down')} />
        </button>
        <Popover
          id={copyRegionsId}
          open={isOpen}
          anchorEl={this.state.anchorEl}
          onClose={this.handleCloseCopyRegions}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <CopyToRegionsComponent
            regions={regionList}
            checklistRegions={checklistDetails.regions!}
            assignedRegions={assignedRegionList}
            selectedRegion={regionList[regionValue]}
            copyQuestionsToRegionsCallback={this.handleCopyingQuestions}
          />
        </Popover>
      </div>
    );

    const hasSelectAll = this.hasSelectAll();
    const hasSelectedIndeterminate = this.hasSelectedIndeterminate();

    const headerEditContent = (questions.length > 0)
      ? (
        <div className={styles.questionsListHeaderEdit}>
          <span className={styles.questionsListHeaderTxt}>
            <Checkbox
              checked={hasSelectAll}
              indeterminate={hasSelectedIndeterminate}
              onChange={() => handleSelectAll(hasSelectAll)}
              color="primary"
              className={styles.questionsListCheckbox}
            />
            <span className={styles.checkboxLabel}>
              {hasSelectAll ? 'Unselect All' : 'Select All'}
            </span>
          </span>
          {showActionBtns && deleteQuestionsBtn}
          {showActionBtns && copyRegionsBtn}
        </div>
      ) : null;

    const questionTextHeader = (questions.length > 0)
      ? (
        <div>
          <p className={styles.questionsTextEdit}>
            Question Text
          </p>
        </div>
      ) : null;

    return (
      <div className={styles.questionsListContainer}>
        {headerEditContent}
        <div className={styles.questionsListContent}>
          {questionTextHeader}
          {
            ChecklistQuestionsTable.sortQuestions(questions).map(
              (item, idx) => {
                const key = item.id ? item.id : item.temp;
                return (
                  <div className={styles.questionEdit} key={`div1-${key}`}>
                    <Checkbox
                      checked={this.isSelectedCheckbox(item)}
                      onChange={() => handleSelectCheckbox(item, this.isSelectedCheckbox(item))}
                      color="primary"
                      className={styles.questionsListCheckbox}
                      key={`chk1-${key}`}
                    />
                    <div className={styles.questionInput} key={`div2-${key}`}>
                      <input
                        type="text"
                        className="tt-input"
                        maxLength={75}
                        onChange={handleQuestionChange}
                        onFocus={() => handleQuestionFocus(idx)}
                        onBlur={() => handleQuestionFocus(-1)}
                        value={item.question}
                        data-test="edit-question-text-input"
                        id={`question-${idx}`}
                        key={`txt1-${key}`}
                      />
                      {questionFocus === idx && (
                        <div className={styles.characterCount} key={`div3-${key}`}>
                          {item.question!.length}
                          /75 chars
                        </div>
                      )}
                    </div>
                    <Button
                      onClick={() => this.removeOne(item)}
                      buttonClassName={cx('tt-btn-basic', styles.removeQuestionBtn)}
                      dataTest="remove-checklist-question"
                      iconClassName="icon-delete icon-small icon-blue"
                      key={`btn1-${key}`}
                    />
                  </div>
                );
              }
            )
          }
          <div className={styles.addQuestionsContainer}>
            <Button
              onClick={handleAddQuestion}
              buttonClassName={cx(styles.addQuestionBtn, 'tt-btn-secondary margin-right-10')}
              name="Add a Question"
              dataTest="add-checklist-question"
              iconClassName="icon-add"
            />
          </div>
        </div>
        <div />
      </div>
    );
  };

  renderViewQuestions = () => {
    const { questions } = this.props;

    if (questions.length === 0) {
      // Questions empty state
      return (
        <div className={styles.emptyQuestionsContainer} key="emptyQuestionsContainer">
          <div className={styles.emptyQuestions} key="emptyQuestions">
            <span className={cx('icon-help', styles.emptyQuestionsIcon)} />
            <span className={styles.emptyQuestionsLabel}>
              You haven&apos;t added any questions yet. Click Edit Checklist to get started.
            </span>
          </div>
        </div>
      );
    }

    const { answerType } = this.props.checklistDetails;

    return (
      <div className={styles.questionsListContainer} key="questionListContainer">
        <div className={styles.questionsListHeader} key="questionListHeader">
          <span className={styles.questionsListHeaderTxt}>Question Text</span>
          <span
            className={cx(
              styles.questionsListHeaderTxt,
              questions.length > 5 && styles.questionsOverflowPadding
            )}
          >
            Options
          </span>
        </div>
        <div className={styles.questionsList}>
          {
            ChecklistQuestionsTable.sortQuestions(questions).map((item) => {
              const key = item.id ? item.id : item.temp;
              return (
                <div className={styles.question} key={key}>
                  <span className={styles.questionText}>{item.question}</span>
                  <span className={styles.answerType}>{ChecklistQuestionsTable.answerTypeMapper(answerType)}</span>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  static sortQuestions = (questions: EditableQuestion[]) => questions.sort((q1, q2) => {
    // Empty question goes to the end
    if (!q2.id || !q2.question) { return -1; }
    if (!q1.id || !q1.question) { return 1; }
    return q1.question!.localeCompare(q2.question!);
  });

  static answerTypeMapper = (answerType: AnswerType) => {
    const answerTypeMap: { [key: string]: string } = {
      yesno: 'Yes / No',
      passfail: 'Pass / Fail',
    };

    return answerTypeMap[answerType.toLowerCase()];
  };

  render() {
    const { editContent } = this.props;
    if (editContent) {
      return this.renderEditQuestions();
    }
    return this.renderViewQuestions();
  }
}

type Question = EditableQuestion;
export interface ChecklistQuestionsTableProps {
  questionsSelected: Question[];
  questions: Question[];
  checklistDetails: ChecklistDto;
  questionFocus: number;
  regionValue: number;
  regionList: RegionDto[];
  assignedRegionList: RegionDto[];
  handleAddQuestion: VoidFunction;
  handleRemoveQuestions: (questions: Question[]) => void;
  handleSelectAll: (selectAll: boolean) => void;
  handleSelectCheckbox: (question: Question, selected: boolean) => void;
  handleQuestionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleQuestionFocus: (index: number) => void;
  handleCopyingQuestions: (regions: RegionDto[]) => void;
  editContent: boolean;
}
