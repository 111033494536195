/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* eslint-disable */
import {
  Card,
  CardContent,
  Tab,
  Tabs,
  Fab,
  Paper,
} from '@mui/material';
import React, { ChangeEvent, Component, ReactNode } from 'react';
import cx from 'classnames';
import {
  ScaleTraxTicketDto,
  PlantDto,
  TruckDto,
  HaulerDto,
  ScaleDto,
  CustomerDto,
  ProjectDto,
  OrderDto,
  DriverDto,
  ProductLine,
  ProductDto,
  CombinedProductsDto,
  QuantityDto,
  Quantity,
  UnitOfMeasure,
  DeliveryType,
  CityDto,
  ScaleType,
  TicketResponseDto,
  PrintableTicketDto,
  getMixDesignItem,
  UrlKeyDto,
} from '@trucktrax/trucktrax-ts-common';
import {
  Button,
  DropDownList,
  DateWrapper,
} from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';
import { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import { devErrorAndLog } from '../../../util/errorUtil';
import LoadingCircle from '../../shared/LoadingCircle';
import adminCardStyles from '../../shared/admin/AdminCard.module.css';
import CardInput from '../../shared/forms/CardInput';
import styles from './ScaleTraxTicketing.module.css';
import {
  ConnectedDispatchFunction, ConnectedFunction, ModalOptions, ReceivedWeight,
} from '../../../types';
import { fetchHaulerList } from '../../../services/haulersService';
import { setReduxDriverList } from '../../../services/driversService';
import { fetchScaleList, updateScale } from '../../../services/scalesService';
import { fetchPrinterList, getTicketForPrint, printTicket } from '../../../services/printService';
import {
  fetchTruck, setReduxTruckList, hasTare, updateTruck,
} from '../../../services/trucksService';
import { ReduxState } from '../../../store';
import { openModal, closeModal } from '../../../store/actions/errorModalActions';
import { ScaleTraxAutocomplete } from '../../shared/forms/ScaleTraxAutocomplete';
import {
  fetchCustomerList,
  fetchProjectList,
  fetchProductList,
  queueRecentProject,
  fetchCityList,
} from '../../../services/integrationService';
import { fetchOpenOrders, getOrdersByOrderUrls } from '../../../services/ordersService';
import { fetchPreviousTicket, createTicket, fetchTicket } from '../../../services/ticketsService';
import {
  fetchPlantList, getDefaultPlant, setCurrentPlant,
} from '../../../services/plantsService';
import {
  STATE_LIST, CANCEL_LABEL, SUCCESS, ERROR,
} from '../../../constants/appConstants';
import { itemsToLabelValue, UOM_LABELS, weightOptions } from '../../../util/adminUtil';
import ScaleSection from './ScaleSection';
import NotesSection from './NotesSection';
import ScaleTraxFooter from './Footer';
import { openSnackbar } from '../../../store/actions/snackbarActions';
import { deliveryTypeOptions } from '../../admin/haulers/HaulersAddModal';
import { getUserByUrl } from '../../../services/usersService';
import { getIdFromUrl, noop, setFavicon } from '../../../util/appUtil';
import QuantityConverter from '../../../util/quantityConverter';
import { stringToUnitOfMeasure } from '../../../util/ticketUtil';
import notesIndicator from '../../../assets/img/notes-indicator.svg';
import CardSelect from '../../shared/forms/CardSelect';

const cardStyle = {
  root: cx(adminCardStyles.card, styles.scaleTraxCard),
};

export interface ScaleTraxTicketingState {
  selectedTabIndex: number;
  currentPlant?: PlantDto,
  currentHauler?: HaulerDto,
  currentTruck?: TruckDto,
  truckError?: string,
  haulerError?: string,
  cityError?: string,
  addressStateError?: string,
  address1Error?: string,
  currentDriver?: DriverDto,
  currentProduct?: ProductDto,
  productError?: string,
  printerError?: string,
  tareError?: string,
  lotNum?: string,
  lotDisabled: boolean,
  lotLabel: string
  currentCustomer?: CustomerDto,
  currentCity?: CityDto,
  customerError?: string,
  deliveryTypeError?: string,
  currentProject?: ProjectDto,
  projectError?: string,
  currentOrder?: OrderDto,
  customersList: CustomerDto[],
  projectsList: ProjectDto[],
  allProducts?: CombinedProductsDto,
  citiesList: CityDto[],
  ordersList: OrderDto[],
  address1?: string,
  address2?: string,
  city?: string,
  addressState?: string,
  zip?: string,
  jobNumber?: string,
  poNumber?: string,
  isNewOrder: boolean
  currentScale?: ScaleDto,
  scaleWeight: QuantityDto,
  deliveryInstructions: string,
  orderNotes: string,
  deliveryType?: DeliveryType,
  isActionDisabled: boolean,
  ticketResponse?: TicketResponseDto,
  currentPrinter: any,
  printCount: number,
  printableTicket?: PrintableTicketDto,
  prevProductNumber?: string
}

type TInputMaskCorrect = Omit<InputMaskProps, 'children'> & { children?: (inputProps: any) => JSX.Element };
const InputMaskCorrect: React.FC<TInputMaskCorrect> = ({ children, ...props }) => {
  const child = children as ReactNode;
  return (
    <InputMask {...props}>
      {child}
    </InputMask>
  );
};

export class ScaleTraxTicketing extends Component<ScaleTraxTicketingProps, ScaleTraxTicketingState> {
  static defaultProps = {
    recentProjects: [],
  };

  private targetRef: React.RefObject<HTMLDivElement>;

  private initialState: ScaleTraxTicketingState;

  haulerRef = React.createRef<ScaleTraxAutocomplete<HaulerDto>>();

  truckRef = React.createRef<ScaleTraxAutocomplete<TruckDto>>();

  driverRef = React.createRef<ScaleTraxAutocomplete<DriverDto>>();

  customerRef = React.createRef<ScaleTraxAutocomplete<CustomerDto>>();

  projectRef = React.createRef<ScaleTraxAutocomplete<ProjectDto>>();

  orderRef = React.createRef<ScaleTraxAutocomplete<OrderDto>>();

  productRef = React.createRef<ScaleTraxAutocomplete<ProductDto>>();

  cityRef = React.createRef<ScaleTraxAutocomplete<CityDto>>();

  constructor(props: ScaleTraxTicketingProps) {
    super(props);

    this.initialState = {
      selectedTabIndex: 0,
      currentTruck: undefined,
      truckError: undefined,
      haulerError: undefined,
      currentHauler: undefined,
      currentDriver: undefined,
      currentOrder: undefined,
      currentCustomer: undefined,
      customerError: undefined,
      deliveryTypeError: undefined,
      currentProduct: undefined,
      productError: undefined,
      currentProject: undefined,
      currentCity: undefined,
      projectError: undefined,
      printerError: undefined,
      lotDisabled: true,
      lotLabel: '',
      lotNum: '',
      customersList: [],
      projectsList: [],
      ordersList: [],
      citiesList: [],
      allProducts: undefined,
      jobNumber: undefined,
      poNumber: undefined,
      isNewOrder: false,
      currentScale: undefined,
      scaleWeight: {
        type: Quantity.WEIGHT,
        unitOfMeasure: UnitOfMeasure.POUNDS,
        gross: 0,
        tare: 0,
        net: 0,
        delivered: 0,
      },
      deliveryInstructions: '',
      orderNotes: '',
      isActionDisabled: false,
      ticketResponse: undefined,
      currentPrinter: undefined,
      printCount: 1,
      printableTicket: undefined,
      address1: undefined,
      address2: undefined,
      addressState: undefined,
      zip: '',
      deliveryType: undefined,
    };
    this.state = this.initialState;

    this.targetRef = React.createRef();
  }

  fetchInitial(plant: PlantDto | undefined) {
    this.props.fetchPlantList(this.props.currentRegion?.url, undefined, this.props.currentUser!);
    this.props.fetchHaulerList(undefined, this.props.currentRegion?.url);
    this.props.fetchScaleList(this.state.currentPlant!);
    this.props.fetchCustomerList(this.props.currentRegion?.url);
    this.props.fetchProjectList(this.props.currentRegion?.url);
    this.props.fetchCityList(this.props.currentRegion?.url);
    this.props.fetchOpenOrders(this.props.currentRegion?.url, this.state.currentPlant?.url);
    this.props.setCurrentPlant(plant);
    if (this.state.currentPlant) {
      this.setScaleUOMDefault();
      this.determineLotOrCertInformation();
      this.determineDefaultScale();
    }
    if (!this.state.currentScale) {
      this.setState({ isActionDisabled: true });
    }
    this.props.setReduxTruckList(undefined, this.state.currentPlant?.region?.url, this.state.currentPlant?.productLine);
    this.props.setReduxDriverList(this.state.currentPlant?.region?.url, [this.state.currentPlant?.productLine]);
    this.props.fetchPrinterList(plant?.scaleHostAddress);
  }

  async setScaleUOMDefault() {
    const newScaleWeight = this.state.scaleWeight;
    newScaleWeight.unitOfMeasure = this.state.currentPlant?.defaultUom ?? UnitOfMeasure.POUNDS;
    this.setState({
      scaleWeight: newScaleWeight
    });
  }

  async setCurrentPlant() {
    let { currentPlant } = this.state;
    if (currentPlant) { return; } // keep whatever current plant is in state

    currentPlant = this.props.currentPlant ?? (await this.props.getDefaultPlant(this.props.currentUser!));
    this.setState({ currentPlant }, () => this.fetchInitial(currentPlant));

    // set the current plant as the currentPlant in redux for use by SearchTickets component and potentially others
    this.props.setCurrentPlant(currentPlant);
  }

  resetForm() {
    this.props.closeModal();
    this.setCurrentPlant();
    if (this.state.currentPlant) {
      this.props.fetchOpenOrders(
        this.props.currentRegion?.url,
        this.state.currentPlant!.url
      );
    }

    const resetState: ScaleTraxTicketingState = {
      ...this.initialState,
      customersList: this.props.customersList,
      citiesList: this.props.citiesList,
      projectsList: this.props.projectsList,
      ordersList: this.props.ordersList,
      // scale section should be unaffected by reset
      currentScale: this.state.currentScale,
      scaleWeight: this.state.scaleWeight,
      currentPrinter: { text: this.state.currentScale?.defaultPrinter ?? 'Select a printer' },
      printableTicket: this.state.printableTicket,
    };

    this.setState(resetState);
    if (this.state.currentPlant) {
      this.determineLotOrCertInformation(undefined, true);
      this.determineDefaultScale();

      const autocompleteRefs = [
        this.haulerRef,
        this.truckRef,
        this.driverRef,
        this.customerRef,
        this.projectRef,
        this.productRef,
        this.orderRef,
        this.cityRef,
      ];
      autocompleteRefs.forEach(this.clearAutocompleteRef);
    }
  }

  clearAutocompleteRef = (ref: React.RefObject<ScaleTraxAutocomplete<any>>) => {
    if (!ref.current) { return; }
    ref.current.clear();
  };

  componentDidMount() {
    document.title = 'ScaleTrax - TruckTrax';
    setFavicon('/favicon-scaletrax.ico');
    this.resetForm();
  }

  determineDefaultScale() {
    if (!this.props.scalesList || this.props.scalesList.length === 0) { return; }

    const { currentScale } = this.state;
    let defaultScale = this.props.scalesList[0];
    try {
      const previouslyUsedScales = JSON.parse(localStorage.previouslyUsedScales);
      const currentUserId = getIdFromUrl(this?.props?.currentUser ?? undefined);

      const previousScale = this.props.scalesList.find((s: any) => s.id === previouslyUsedScales[currentUserId].value);

      defaultScale = previousScale !== undefined ? previousScale : this.props.scalesList[0];
    } catch (e) {
      [defaultScale] = this.props.scalesList;
    }

    let updateState = !!defaultScale;

    // don't update state if it's already the currentScale
    if (currentScale && defaultScale && currentScale.id === defaultScale.id) {
      updateState = false;
    }
    if (updateState) {
      this.setState({
        currentScale: defaultScale,
        currentPrinter: { text: defaultScale?.defaultPrinter ?? 'Select a printer' }
      });
    }
  }

  // lotNum is for Aggregates, certID is for Cement, both are stored in lotNum field in state with different text labels
  determineLotOrCertInformation(lotOrCert?: string, clearLotOrCert?: boolean) {
    const lotDisabled = this.state.currentPlant! && this.state.currentPlant!.productLine !== ProductLine.Aggregates;
    let { lotNum } = this.state;

    // if the user's current plant is ProductLine.Cement then the lotnum text field is disabled and the label is Cert ID
    // For Cement productLine products the certId comes from the ProductDto of the currentProduct from the product dropdown
    // and the text field is disabled
    if (lotDisabled && this.state.currentProduct) {
      lotNum = this.state.currentProduct.certId ? this.state.currentProduct.certId : '';
    }

    // if the user's current plant is ProductLine.Aggregates (!lotDisabled),
    // default lotNum comes from currentOrder
    if (!lotDisabled) {
      const tempLotNum = 'AL0T'; // OrderDto does not have a lotNum property yet, this is a placeholder
      lotNum = this.state.currentOrder ? tempLotNum : '';
    }
    // if it's passed in override the default value with what was passed in (either from text input or dropdown)
    lotNum = clearLotOrCert ? '' : lotOrCert || lotNum;

    const lotLabel = this.state.currentPlant!.productLine === ProductLine.Aggregates ? 'Lot' : 'Cert ID';

    this.setState({
      lotDisabled,
      lotNum,
      lotLabel,
    });
  }

  updateCurrentProduct() {
    if (!this.state.prevProductNumber) { return; }

    const currentProduct = this.props.allProducts?.projectProducts
      .concat(this.props.allProducts.otherProducts)
      .find(p => p.productNumber === this.state.prevProductNumber);

    this.setState({ currentProduct });
  }

  updateProducts(previousAllProducts: CombinedProductsDto) {
    if (this.props.allProducts === previousAllProducts) { return; }

    this.setState({ allProducts: this.props.allProducts });
    // autoselect the previous ticket's product from the dropdown
    this.updateCurrentProduct();
  }

  updateCurrentRegion(previousRegionUrl?: string) {
    const currentRegionUrl = this.props.currentRegion?.url;
    if (currentRegionUrl === previousRegionUrl) { return; }

    this.props.fetchCustomerList(currentRegionUrl);
    this.props.fetchProjectList(currentRegionUrl);
    this.props.fetchCityList(currentRegionUrl);
    this.props.fetchOpenOrders(currentRegionUrl, this.state.currentPlant!.url);
  }

  componentDidUpdate(prevProps: ScaleTraxTicketingProps) {
    if (!this.state.currentPlant) {
      return;
    }

    if (this.state.currentScale === undefined
      || this.props.scalesList !== prevProps.scalesList) {
      this.determineDefaultScale();
    }

    this.updateCurrentRegion(prevProps.currentRegion?.url);

    if (this.props.projectsList !== prevProps.projectsList) {
      this.setState({ projectsList: this.props.projectsList });
    }

    this.updateProducts(prevProps.allProducts);

    if (this.props.customersList !== prevProps.customersList) {
      this.setState({ customersList: this.props.customersList });
    }

    if (this.props.ordersList !== prevProps.ordersList) {
      this.setState({ ordersList: this.props.ordersList });
    }

    if (this.props.citiesList !== prevProps.citiesList) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ citiesList: this.props.citiesList });
    }
  }

  handleHaulerChange(value: HaulerDto) {
    this.props.setReduxTruckList(value?.url, this.state.currentPlant?.region?.url, this.state.currentPlant?.productLine);
    this.setState({
      haulerError: undefined,
      currentHauler: value,
      deliveryType: value.deliveryType,
    });
    this.checkSaveAction('currentHauler', value);
  }

  updateInputHauler = (event: ChangeEvent<{}> | undefined, value?: HaulerDto, reason?: AutocompleteChangeReason) => {
    switch (reason) {
      case 'selectOption':
        this.handleHaulerChange(value!);
        break;
      case 'clear':
        this.props.setReduxTruckList(undefined, this.state.currentPlant?.region?.url, this.state.currentPlant?.productLine);
        this.setState({
          currentHauler: undefined,
        });
        this.disableSaveAndPrint('updateInputHauler');
        break;
      default:
        break;
    }
  };

  handleCityChange(cityInfo: CityDto) {
    const state: any = { value: cityInfo.state };
    this.setState({
      addressState: state,
      currentCity: cityInfo,
      cityError: undefined,
      zip: '',
    });
    this.props.fetchCityList(this.props.currentRegion!.url, state.value);
    this.checkSaveAction('currentCity', cityInfo);
  }

  updateInputCity = (event: ChangeEvent<{}> | undefined, value?: CityDto, reason?: AutocompleteChangeReason) => {
    switch (reason) {
      case 'selectOption':
        this.handleCityChange(value!);
        break;
      case 'clear':
        this.setState({
          currentCity: undefined,
          zip: '',
        });
        this.disableSaveAndPrint('updateInputCity');
        break;
      default:
        break;
    }
  };

  handleInvalidTruck(truck?: TruckDto) {
    if (truck) { return; }
    this.setState({
      currentTruck: truck,
      truckError: 'Failed to load truck info',
    });
  }

  getDriver(truck?: TruckDto) {
    return this.getDriverByKey(truck?.driver) || this.getDriverByKey(truck?.defaultDriver);
  }

  getDriverByKey(driver?: UrlKeyDto) {
    return (driver?.url)
      ? this.props.driverList.find(d => d.url === driver.url)
      : undefined;
  }

  async handleTruckChanged(value: TruckDto) {
    this.props.fetchHaulerList(value?.url, this.props.currentRegion?.url);
    const truck = await fetchTruck(value.id);
    const currentDriver = this.getDriver(truck);

    this.handleInvalidTruck(truck);

    const scaleWeight: QuantityDto = {
      ...this.state.scaleWeight,
      tare: this.state.currentPlant?.productLine === ProductLine.Cement ? 0 : (truck?.currentTare?.weight ?? 0),
    };
    if (truck?.haulers?.length === 1) {
      // eslint-disable-next-line array-callback-return
      this.props.haulersList.map(hauler => {
        if (hauler.url === truck?.haulers?.[0]?.url) {
          this.setState({
            currentHauler: hauler
          });
        }
      });
    } else if (truck?.haulers?.length === 0) {
      this.setState({
        currentHauler: undefined
      });
    } else if (truck?.haulers?.length && truck?.haulers?.length > 1) {
      const haulerUrls = truck.haulers.map(h => h.url);
      if (!haulerUrls.includes(this.state.currentHauler?.url)) {
        this.setState({
          currentHauler: undefined
        });
      }
    }

    this.setState({
      currentTruck: truck,
      currentDriver,
      scaleWeight,
      truckError: !value.vehicleType ? 'Selected Truck has no Vehicle Type.' : undefined,
    });

    // Check Save Action
    this.checkSaveAction('currentTruck', value);
  }

  updateInputTruck = async (event: ChangeEvent<{}> | undefined, value?: TruckDto, reason?: AutocompleteChangeReason) => {
    switch (reason) {
      case 'selectOption': {
        await this.handleTruckChanged(value!);
        break;
      }
      case 'clear':
        this.props.fetchHaulerList(undefined, this.props.currentRegion?.url);
        this.setState({
          currentTruck: undefined,
          currentDriver: undefined,
          truckError: undefined,
          currentHauler: undefined
        });
        this.disableSaveAndPrint('updateInputTruck');
        break;
      default:
        break;
    }
  };

  handleDriverChanged(value: DriverDto) {
    this.setState({
      currentDriver: value,
    });
    this.checkSaveAction('currentDriver', value);
  }

  updateInputDriver = (event: ChangeEvent<{}> | undefined, value?: DriverDto, reason?: AutocompleteChangeReason) => {
    switch (reason) {
      case 'selectOption':
        this.handleDriverChanged(value!);
        break;
      case 'clear':
        this.props.fetchHaulerList(undefined, this.props.currentRegion?.url);
        this.setState({
          currentDriver: undefined,
        });
        this.disableSaveAndPrint('updateInputDriver');
        break;
      default:
        break;
    }
  };

  onAddress1Change = (e: any) => {
    const value = e.address1;
    this.setState(prevState => ({
      address1: value,
      address1Error: undefined,
      isNewOrder: this.isOrderUpdated(
        value,
        prevState.address2,
        prevState.jobNumber,
        prevState.poNumber
      ),
      currentOrder: this.setOrder(prevState.currentOrder),
    }));
    this.checkSaveAction('address1', value);
  };

  onAddress2Change = (e: any) => {
    const value = e.address2;
    this.setState(prevState => ({
      address2: value,
      isNewOrder: this.isOrderUpdated(
        prevState.address1,
        value,
        prevState.jobNumber,
        prevState.poNumber
      ),
      currentOrder: this.setOrder(prevState.currentOrder),
    }));
  };

  handleAddressState(addressState: any) {
    if (this.state.currentCity?.state !== addressState?.value) {
      this.setState({
        currentCity: undefined,
        zip: '',
      });
    }
    this.setState({
      addressState,
      addressStateError: undefined,
      isActionDisabled: true,
    });
    this.props.fetchCityList(this.props.currentRegion!.url, addressState.value);
    this.checkSaveAction('addressState', addressState);
  }

  onAddressStateChange = (event: ChangeEvent<{}> | undefined, value?: any, reason?: AutocompleteChangeReason) => {
    switch (reason) {
      case 'selectOption':
        this.handleAddressState(value!);
        break;
      case 'clear':
        this.props.fetchCityList(this.props.currentRegion!.url, undefined);
        this.setState({
          addressState: undefined,
          zip: '',
        });
        this.disableSaveAndPrint('onAddressStateChange');
        break;
      default:
        break;
    }
  };

  onZipChange = (event: any) => {
    const { value } = event.target;
    this.setState({ zip: value });
    this.checkSaveAction('zip', value);
  };

  onJobNumberChange = (e: any) => {
    const value = e.jobNumberInput;
    this.setState(prevState => ({
      jobNumber: value,
      isNewOrder: this.isOrderUpdated(
        prevState.address1,
        prevState.address2,
        value,
        prevState.poNumber
      ),
      currentOrder: this.setOrder(prevState.currentOrder),
    }));
  };

  onPONumberChange = (e: any) => {
    const value = e.poNumberInput;
    this.setState(prevState => ({
      poNumber: value,
      isNewOrder: this.isOrderUpdated(
        prevState.address1,
        prevState.address2,
        prevState.jobNumber,
        value
      ),
      currentOrder: this.setOrder(prevState.currentOrder),
    }));
  };

  isOrderUpdated = (address1?: string, address2?: string, jobNumber?: string, poNumber?: string) => {
    const { currentOrder } = this.state;
    return (
      currentOrder !== undefined
      && (ScaleTraxTicketing.areNotEquivalent(address1, currentOrder.addressLineOne)
        || ScaleTraxTicketing.areNotEquivalent(address2, currentOrder.addressLineTwo)
        || ScaleTraxTicketing.areNotEquivalent(jobNumber, currentOrder.jobNumber)
        || ScaleTraxTicketing.areNotEquivalent(poNumber, currentOrder.purchaseOrder))
    );
  };

  static areNotEquivalent = (lhs?: string, rhs?: string) => (lhs ?? '') !== (rhs ?? '');

  setOrder = (order?: OrderDto) => (order ? { ...order } : order);

  revertOrder = () => {
    this.setState(prevState => ({
      jobNumber: this.state.currentOrder?.jobNumber,
      poNumber: this.state.currentOrder?.purchaseOrder,
      address1: this.state.currentOrder?.addressLineOne ?? this.state.currentProject?.addressLineOne ?? '',
      address2: this.state.currentOrder?.addressLineTwo ?? this.state.currentProject?.addressLineTwo ?? '',
      isNewOrder: false,
      currentOrder: this.setOrder(prevState.currentOrder),
    }));
  };

  filterProjects = (customer?: CustomerDto) => {
    let projects = this.props.projectsList;
    if (customer) {
      projects = projects.filter(p => p.customerExternalId === customer.customerExternalId);
    }
    return projects;
  };

  filterCustomers = (project?: ProjectDto) => {
    let customers = this.props.customersList;
    if (project) {
      customers = customers.filter(c => c.customerExternalId === project.customerExternalId);
    }
    return customers;
  };

  filterCities = (project?: ProjectDto) => {
    let cities = this.props.citiesList;
    if (project) {
      cities = cities.filter(c => c.cityName === project.city);
    }
    return cities;
  };

  filterCitiesByOrder = (order?: OrderDto) => {
    let cities = this.props.citiesList;
    if (order) {
      cities = cities.filter(c => c.cityName === order.city);
    }
    return cities;
  };

  filterOrders = (customer?: CustomerDto, project?: ProjectDto) => {
    let orders = this.props.ordersList;

    if (customer) {
      orders = orders.filter(o => o.customerExternalId === customer.customerExternalId);
    }

    if (project) {
      orders = orders.filter(o => o.projectExternalId === project.projectExternalId);
    }

    return orders;
  };

  updateInputProduct = (event: ChangeEvent<{}> | undefined, value: any | null) => {
    this.setState({
      currentProduct: value ?? undefined,
      productError: undefined,
    });

    this.checkSaveAction('currentProduct', value);

    // if it's a Cement Plant then CertId needs to be adjusted based on product
    if (this.state.currentPlant!.productLine === ProductLine.Cement) {
      this.determineLotOrCertInformation(value?.certId, !value?.certId);
    }
  };

  updateInputCustomer = (event: ChangeEvent<{}> | undefined, value?: CustomerDto, reason?: AutocompleteChangeReason) => {
    switch (reason) {
      case 'selectOption': {
        const projectsList = this.filterProjects(value);
        const currentProject = ((!!value) && (projectsList.length === 1)) ? projectsList[0] : undefined;
        const { currentRegion } = this.props;
        const { currentPlant } = this.state;
        const plantExternalId = currentPlant?.externalId ?? '';
        if (currentProject) {
          this.props.fetchProductList(currentRegion!.url!, currentProject?.projectExternalId, plantExternalId);
        }
        const currentProduct = undefined;
        this.props.queueRecentProject(currentProject);

        const ordersList = this.filterOrders(value, currentProject);
        const { projectError: existingProjectError } = this.state;
        this.determineLotOrCertInformation(undefined, true);
        this.setState({
          currentCustomer: value,
          customerError: undefined,
          currentOrder: undefined,
          currentProject,
          projectError: currentProject ? undefined : existingProjectError,
          projectsList,
          ordersList,
          currentProduct
        });

        this.checkSaveAction('currentCustomer', value);
        break;
      }
      case 'clear':
        this.determineLotOrCertInformation(undefined, true);
        this.setState({
          currentCustomer: undefined,
          currentProject: undefined,
          currentOrder: undefined,
          projectsList: this.props.projectsList,
          customersList: this.props.customersList,
          ordersList: this.props.ordersList,
        });
        this.disableSaveAndPrint('clearedCustomer');
        break;
      default:
        break;
    }
  };

  updateDeliveryType = (value?: any) => {
    if (value) {
      const deliveryType = value.undefined;
      this.setState({
        deliveryTypeError: undefined,
        deliveryType,
      });
    }
  };

  lotChanged = (e: any) => this.determineLotOrCertInformation(e.lotInput);

  decrementPrintCount = () => this.addToPrintCount(-1);

  incrementPrintCount = () => this.addToPrintCount(1);

  addToPrintCount(amount: number) {
    let { printCount } = this.state;
    printCount += amount;
    if (printCount > 0) this.setState({ printCount });
  }

  updateInputPrinter = (item?: any) => {
    if (item && item.value.url) {
      this.setState({
        currentPrinter: item,
        printerError: undefined,
      });
    }
  };

  validateInputProject(value?: ProjectDto) {
    this.checkSaveAction('currentProject', value);
    this.checkSaveAction('address1', value?.addressLineOne);
    this.checkSaveAction('addressState', value?.state);
    this.checkSaveAction('zip', value?.zipCode);
  }

  updateInputProject = (event: ChangeEvent<{}> | undefined, value?: ProjectDto, reason?: AutocompleteChangeReason) => {
    switch (reason) {
      case 'selectOption': {
        const customersList = this.filterCustomers(value);
        const { currentPlant } = this.state;
        const plantExternalId = currentPlant?.externalId ?? '';

        const currentCustomer = (customersList.length === 1) ? customersList[0] : this.state.currentCustomer;
        const ordersList = this.filterOrders(currentCustomer, value);

        const currentProject = value;
        const projectExtId = currentProject ? currentProject.projectExternalId : undefined;

        this.props.fetchProductList(this.props.currentRegion!.url!, projectExtId, plantExternalId);
        this.props.fetchCityList(this.props.currentRegion!.url, value?.state);
        const cities = this.filterCities(value);
        const currentCity = cities.length ? cities[0] : undefined;
        this.props.queueRecentProject(currentProject);

        const orderNotes = this.getProjectOrderNotes(value);
        const deliveryInstructions = this.getProjectDeliveryInstructions(value);

        const { customerError: existingCustomerError } = this.state;
        const { deliveryTypeError: existingDeliveryTypeError } = this.state;
        this.setState({
          currentProject,
          projectError: undefined,
          currentOrder: undefined,
          currentProduct: undefined,
          productError: undefined,
          cityError: undefined,
          addressStateError: undefined,
          address1Error: undefined,
          currentCustomer,
          customerError: currentCustomer ? undefined : existingCustomerError,
          ordersList,
          address1: value?.addressLineOne,
          address2: value?.addressLineTwo,
          currentCity,
          addressState: { value: value?.state } as any,
          zip: value?.zipCode,
          orderNotes: orderNotes ?? this.state.orderNotes,
          deliveryInstructions: deliveryInstructions ?? this.state.deliveryInstructions,
          deliveryType: currentProject?.deliveryType ?? this.state.currentHauler?.deliveryType,
          deliveryTypeError: currentProject?.deliveryType ? undefined : existingDeliveryTypeError,
        });
        this.validateInputProject(value);
        break;
      }
      case 'clear':
        this.props.fetchCityList(this.props.currentRegion!.url, value?.state);
        this.setState({
          currentProject: undefined,
          currentProduct: undefined,
          currentOrder: undefined,
          customersList: this.props.customersList,
          ordersList: this.filterOrders(this.state.currentCustomer, undefined),
          address1: undefined,
          address2: undefined,
          currentCity: undefined,
          addressState: undefined,
          zip: '',
        });
        this.disableSaveAndPrint('clearedProject');
        break;
      default:
        break;
    }
  };

  handleOrderChanged(order: OrderDto, updateNotes = true) {
    const currentCustomer = this.props.customersList.find(c => c.customerExternalId === order?.customerExternalId);
    const currentProject = this.props.projectsList.find(c => c.projectExternalId === order?.projectExternalId);
    const plantExternalId = this.state.currentPlant?.externalId ?? '';
    this.props.fetchProductList(this.props.currentRegion!.url!, currentProject?.projectExternalId, plantExternalId);

    this.props.queueRecentProject(currentProject);
    const cities = this.filterCitiesByOrder(order);
    const currentCity = cities.length ? cities[0] : undefined;
    const { deliveryInstructions, orderNotes } = this.state;
    this.determineLotOrCertInformation(undefined, true);
    this.setState({
      currentOrder: order,
      currentCustomer,
      currentProject,
      address1: order?.addressLineOne,
      address2: order?.addressLineTwo,
      addressState: { value: order?.state } as any,
      currentCity,
      zip: order?.zipCode,
      poNumber: order?.purchaseOrder,
      jobNumber: order?.jobNumber,
      isNewOrder: false,
      deliveryInstructions: updateNotes
        ? this.getOrderDeliveryInstructions(order) ?? this.state.deliveryInstructions
        : deliveryInstructions,
      orderNotes: updateNotes ? this.getOrderOrderNotes(order) ?? this.state.orderNotes : orderNotes,
    });
  }

  updateInputOrder = (event: ChangeEvent<{}> | undefined, value?: OrderDto, reason?: AutocompleteChangeReason) => {
    switch (reason) {
      case 'selectOption': {
        this.handleOrderChanged(value!);
        break;
      }
      case 'clear':
        this.determineLotOrCertInformation(undefined, true);
        this.setState({
          currentOrder: undefined,
          address1: undefined,
          address2: undefined,
          currentCity: undefined,
          addressState: undefined,
          zip: '',
          poNumber: undefined,
          jobNumber: undefined,
          isNewOrder: false,
        });
        break;
      default:
        break;
    }
  };

  updateScale = (scale: ScaleDto, quantity: QuantityDto) => {
    this.setState({
      currentScale: scale,
      scaleWeight: quantity,
      tareError: '',
      currentPrinter: { text: scale?.defaultPrinter || 'Select a printer' }
    });
  };

  updateTare = (truck: TruckDto) => {
    this.setState({
      tareError: '',
      currentTruck: truck,
    });
  };

  isMaxWeightExceeded = (
    maxWeight: number,
    currentScale: ScaleDto,
    receivedWeight: ReceivedWeight,
    scaleWeight: QuantityDto,
    currentUom: UnitOfMeasure,
    weightUom: UnitOfMeasure,
  ): boolean => {
    if (maxWeight === 0) {
      this.disableSaveAndPrint('maxWeightIsZero');
      return true;
    }

    let relevantWeight = currentScale?.scaleType === ScaleType.LIVE ? receivedWeight.weight : scaleWeight.gross;
    if (currentUom !== weightUom) {
      relevantWeight = QuantityConverter.convertValue(relevantWeight, currentUom, weightUom) ?? 0;
    }
    // this gets rechecked afterwards with 'checkSaveAction' for other form value validation
    let isActionDisabled = false;
    let disabledReason = '';
    if (relevantWeight > maxWeight) {
      isActionDisabled = true;
      disabledReason = 'overMaxWeight';
    }
    if (scaleWeight.gross - scaleWeight.tare <= 0) {
      isActionDisabled = true;

      disabledReason = 'netZeroOrNegative';
    }
    if (scaleWeight.tare === 0) {
      isActionDisabled = true;
      disabledReason = 'tareZeroOrNegative';
    }
    if (isActionDisabled === false) {
      // Check Save Action
      this.checkSaveAction('isMaxWeightExceeded passed validation - checking other values');
    } else {
      this.disableSaveAndPrint(`isMaxWeightExceeded${disabledReason}`);
    }
    return relevantWeight > maxWeight;
  };

  isScaleMaxWeightExceeded = (currentUom: UnitOfMeasure): boolean => {
    const { currentScale, scaleWeight, currentPlant } = this.state;
    const { receivedWeight } = this.props;

    if (!currentScale) {
      return false;
    }

    const maxWeight = currentScale?.maxWeight ?? 0;
    const maxWeightUom = stringToUnitOfMeasure(currentPlant?.defaultUom);
    return this.isMaxWeightExceeded(maxWeight, currentScale, receivedWeight, scaleWeight, currentUom, maxWeightUom);
  };

  isTruckTypeMaxWeightExceeded = (currentUom: UnitOfMeasure): boolean => {
    const { currentScale, scaleWeight, currentTruck } = this.state;
    const { receivedWeight } = this.props;

    if (!currentScale || !currentTruck) {
      return false;
    }

    const maxWeight = currentTruck?.vehicleType?.maxWeight ?? 0;
    const maxWeightUom = stringToUnitOfMeasure(currentTruck?.vehicleType?.unitOfMeasure);
    return this.isMaxWeightExceeded(maxWeight, currentScale, receivedWeight, scaleWeight, currentUom, maxWeightUom);
  };

  getProjectOrderNotes = (project?: ProjectDto) => {
    const shipperNotes = project?.shipperNotes ?? '';
    const orderComments = project?.orderComments ?? '';
    if (!shipperNotes && !orderComments) {
      return '';
    }
    return `${shipperNotes}\n${orderComments}`;
  };

  getProjectDeliveryInstructions = (project?: ProjectDto) => project?.ticketNotes ?? '';

  getOrderOrderNotes = (order?: OrderDto) => order?.orderNotes ?? '';

  getOrderDeliveryInstructions = (order?: OrderDto) => order?.deliveryInstructions ?? '';

  haulerDtoToString = (option: HaulerDto) => option.name || '';

  cityDtoToString = (option: CityDto) => option.displayName || '';

  truckDtoToString = (option: TruckDto) => option.truckAlias || '';

  customerDtoToString = (option: CustomerDto) => `${option.customerExternalId} - ${option.customerName}`;

  driverDtoToString = (option: DriverDto) => (option.firstName ? `${option.id}, ${option.firstName} ${option.lastName}` : '');

  projectDtoToString = (option: ProjectDto) => `${option.projectExternalId} - ${option.projectTitle}`;

  orderDtoToString = (option: OrderDto) => (this.state.isNewOrder ? `${option.orderNumber}*` : `${option.orderNumber}`);

  productDtoToString = (option: ProductDto) => `${option.productNumber} - ${option.productDescription}`;

  stateToString = (option: any) => option.value;

  renderHeaderPlant = () => {
    const { currentPlant } = this.state;
    const { plantsList } = this.props;

    const items = plantsList
      .map(s => ({
        value: s.id,
        label: s.name,
        text: s.name,
      }))
      .sort((s1, s2) => {
        const s1Text = s1.text ?? '';
        const s2Text = s2.text ?? '';
        return s1Text.localeCompare(s2Text);
      });

    const initialSelected = items.find(i => i.value === currentPlant?.id);

    return (
      <div key="plant-header" className={styles.plantHeader}>
        <h4>Loading Plant</h4>
        {items.length <= 1 && (
          <h3>
            {this.state.currentPlant?.name || 'No plants assigned'}
          </h3>
        )}
        {items.length > 1 && (
          <DropDownList
            items={items}
            initialSelected={initialSelected || items[0]}
            updateSelected={this.confirmUpdatePlant}
            selectBlue
          />
        )}
      </div>
    );
  };

  updateWithNewPlantInfo = (plant: PlantDto | undefined) => {
    this.fetchInitial(plant);
    this.resetForm();
  };

  updateInputPlant = (item?: any) => {
    const plant = this.props.plantsList.find(s => s.id === item.value) as PlantDto;
    // current scale needs to be removed, it is plant-based
    this.setState({
      currentPlant: plant,
      currentScale: undefined,
      currentPrinter: { text: 'Select a printer' }
    }, () => this.updateWithNewPlantInfo(plant));
  };

  confirmUpdatePlant = (item?: any) => {
    if (this.checkFormModified()) {
      const modal: ModalOptions = {
        acceptDialog: () => this.updateInputPlant(item),
        modalType: 'ACCENT',
        modalBody: 'Changing plants will discard the ticket that you currently have in progress.  This cannot be undone.',
        modalTitle: 'Discard your ticket in progress?',
        modalOpen: true,
        acceptText: 'Discard ticket in progress',
        cancelText: CANCEL_LABEL,
        disabled: false,
        noActions: false,
      };
      this.props.openModal(modal);
    } else {
      this.updateInputPlant(item);
    }
  };

  /**
   * Validate the data for the current required fields to save
   * @param {string} currentPropName current property name
   * @param {any} currentPropValue current property value
   * @param {string?} newPropName new property name
   * @param {any} newPropValue new property value
   * @returns {boolean} isValid?
   */
  isValidData = (currentPropName: string, currentPropValue: any, newPropName?: string, newPropValue?: any): boolean => {
    // Check if it is a new value change
    if (currentPropName === newPropName) {
      if (newPropValue === undefined || newPropValue === '') {
        return false;
      }
    } else if (currentPropValue === undefined) {
      return false;
    }

    // Valid data
    return true;
  };

  checkSaveAction(newPropName?: string, newPropValue?: any) {
    const {
      address1,
      addressState,
      currentCity,
      currentCustomer,
      currentHauler,
      currentProduct,
      currentProject,
      deliveryType,
      currentTruck,
      scaleWeight,
      zip,
    } = this.state;

    if (this.props.receivedWeight?.isMoving) {
      this.disableSaveAndPrint('receivedWeight.isMoving');
      return;
    }
    if (!this.isValidData('currentProject', currentProject, newPropName, newPropValue)) {
      this.disableSaveAndPrint(`checkSaveAction${'currentProject'}`);
      return;
    }
    if (!this.isValidData('currentCustomer', currentCustomer, newPropName, newPropValue)) {
      this.disableSaveAndPrint(`checkSaveAction${'currentCustomer'}`);
      return;
    }
    if (!this.isValidData('currentTruck', currentTruck, newPropName, newPropValue)) {
      this.disableSaveAndPrint(`checkSaveAction${'currentTruck'}`);
      return;
    }
    if (!this.isValidData('currentProduct', currentProduct, newPropName, newPropValue)) {
      this.disableSaveAndPrint(`checkSaveAction${'currentProduct'}`);
      return;
    }
    if (!this.isValidData('currentHauler', currentHauler, newPropName, newPropValue)) {
      this.disableSaveAndPrint(`checkSaveAction${'currentHauler'}`);
      return;
    }
    if (!this.isValidData('currentCity', currentCity, newPropName, newPropValue)) {
      this.disableSaveAndPrint(`checkSaveAction${'currentCity'}`);
      return;
    }
    if (!this.isValidData('addressState', addressState, newPropName, newPropValue)) {
      this.disableSaveAndPrint(`checkSaveAction${'addressState'}`);
      return;
    }
    if (!this.isValidData('address1', address1, newPropName, newPropValue)) {
      this.disableSaveAndPrint(`checkSaveAction${'address1'}`);
      return;
    }
    if (!this.isValidData('deliveryType', deliveryType, newPropName, newPropValue)) {
      this.disableSaveAndPrint(`checkSaveAction${'deliveryType'}`);
      return;
    }
    if (!this.isValidData('zip', zip, newPropName, newPropValue)) {
      this.disableSaveAndPrint(`checkSaveAction${'zip'}`);
      return;
    }
    if (scaleWeight.gross === 0) {
      this.disableSaveAndPrint('checkSaveActionScaleWeightGrossIsZero}');
      return;
    }
    if (this.state.currentScale == null) {
      this.disableSaveAndPrint('checkSaveActionScaleNotAvailable');
      return;
    }
    this.enableSaveAndPrint('checkSaveActionPassedValidations');
  }

  validateRequiredFields() {
    const {
      currentProject,
      currentCustomer,
      currentTruck,
      currentProduct,
      currentHauler,
      currentCity,
      addressState,
      address1,
      deliveryType,
    } = this.state;

    let {
      projectError,
      customerError,
      truckError,
      productError,
      haulerError,
      cityError,
      addressStateError,
      address1Error,
      tareError,
    } = this.state;

    const missingErrorMessage = 'This field is required.';
    if (!currentProject) {
      projectError = missingErrorMessage;
    }
    if (!currentCustomer) {
      customerError = missingErrorMessage;
    }
    if (!currentHauler) {
      haulerError = missingErrorMessage;
    }
    if (!currentCity) {
      cityError = missingErrorMessage;
    }
    if (!addressState) {
      addressStateError = missingErrorMessage;
    }
    if (!address1) {
      address1Error = missingErrorMessage;
    }
    if (!currentTruck) {
      truckError = missingErrorMessage;
    } else if (!hasTare(currentTruck)) {
      tareError = 'Tare required.';
    }
    if (!currentProduct) {
      productError = missingErrorMessage;
    }

    const deliveryTypeError = deliveryType ? undefined : missingErrorMessage;

    const hasErrors = [projectError, customerError, truckError, haulerError, cityError,
      productError, tareError, deliveryTypeError].map(err => (err ?? '').trim())
      .some(err => err.length > 0);

    if (hasErrors) {
      this.setState({
        projectError,
        customerError,
        truckError,
        productError,
        haulerError,
        cityError,
        addressStateError,
        address1Error,
        tareError,
        deliveryTypeError,
      });

      this.props.openSnackbar({
        snackbarBody: 'Please fill out all required fields',
        snackbarType: 'ERROR',
      });
    }

    return !hasErrors;
  }

  performPrint = async () => {
    const validated = this.validateRequiredFields();
    /*
    we will use the default printer configured in printhost for this plant

    if (!validated || !this.state.currentPrinter || this.state.currentPrinter.text === 'Select a printer') {
      if (!this.state.currentPrinter || this.state.currentPrinter.text === 'Select a printer') {
        this.props.openSnackbar({
          snackbarBody: 'Please select a printer',
          dataTest: 'scaletrax-ticketing-missing-print-snackbar',
          snackbarType: 'ERROR',
          manualClose: true,
        });
        this.setState({ printerError: 'Choose a printer' });
      }
      return;
    }*/

    // save it
    const ticket = await this.getTicketForCreate();
    const ticketResponse = await this.createTicket(ticket);

    if (ticketResponse && ticketResponse.ticket) {
      const modalBody = (
        <div className={cx(styles.docContainer, styles.loading)}>
          <div className={cx(styles.loadingText)}>Printing Ticket</div>
          <div className={cx(styles.spinner)}><LoadingCircle size={88} /></div>
        </div>
      );

      const modal: ModalOptions = {
        modalTitle: undefined,
        modalType: 'DEFAULT',
        modalBody,
        modalOpen: true,
        noActions: true,
      };

      // the printing ticket spinner
      this.props.openModal(modal);

      const requestTicket = await fetchTicket(ticketResponse.ticket.id);

      const printableTicket = await getTicketForPrint(requestTicket!, ticketResponse.order, this.props.currentUser!);
      const isOriginal = true; // we are printing the ORIGINAL ticket.
      const response = await printTicket(
        printableTicket,
        '', // empty string for current printer will trigger printhost to use the default configured printer
        this.state.printCount,
        this.state.currentPlant!.url!,
        isOriginal
      );

      // close the spinner
      this.props.closeModal();

      if (response?.status === 200) {
        // eslint-disable-next-line max-len
        const msg = `${this.state.printCount} copies of ticket ${ticketResponse.ticket.ticketNumber} sent to the printer. Ticket associated with order #${ticketResponse.order.orderNumber}.`;
        this.props.openSnackbar({
          snackbarBody: msg,
          snackbarType: SUCCESS,
        });
        this.resetForm();
        return;
      }
      const ticketUrl = `/scaletrax/scaletraxticketslist/${ticketResponse.ticket.id}`;
      const errorDiv = (
        <div>
          Print Failed.  Ticket
          {' '}
          <a href={ticketUrl} className={cx(styles.errorLink)}>
            #
            {ticketResponse.ticket.ticketNumber}
          </a>
          {' '}
          was saved but not printed.
        </div>
      );

      this.props.openSnackbar({
        snackbarBody: '',
        snackbarType: ERROR,
        manualClose: true,
        snackbarBodyElement: errorDiv,
      });
      this.resetForm();
    }
  };

  saveTicket = async () => {
    const validated = this.validateRequiredFields();
    if (!validated) {
      return;
    }
    // save it
    const ticket = await this.getTicketForCreate();

    const ticketResponse = await this.createTicket(ticket);
    if (ticketResponse && ticketResponse.ticket) {
      const msg = `Ticket #${ticketResponse.ticket.ticketNumber} saved and associated with order #${ticketResponse.order.orderNumber}`;
      this.props.openSnackbar({
        snackbarBody: msg,
        snackbarType: 'SUCCESS',
      });
      this.resetForm();
    }
    // don't reset the form if the ticket failed to be created
  };

  async getTicketForCreate() {
    const ticketedTimestamp = DateWrapper.now.toISOString();
    const currentUser = await getUserByUrl(this.props.currentUser!);

    const ticketUom = weightOptions.find(i => i.value === this.state.scaleWeight.unitOfMeasure)?.label ?? UOM_LABELS.POUNDS;

    const ticket = {
      createdBy: this.props.currentUser,
      addressLineOne: this.state.address1,
      addressLineTwo: this.state.address2,
      cityId: this.state.currentCity?.cityId,
      customerExternalId: this.state.currentCustomer?.customerExternalId,
      deliveryInstructions: this.state.deliveryInstructions,
      deliveryType: this.state.deliveryType,
      driverExternalId: this.state.currentDriver?.externalId,
      driverFullName: `${this.state.currentDriver?.firstName} ${this.state.currentDriver?.lastName}`,
      driverUrl: this.state.currentDriver?.url,
      grossWeight: this.state.scaleWeight.gross,
      grossWeightUom: ticketUom,
      haulerExternalId: this.state.currentHauler?.externalId,
      haulerUrl: this.state.currentHauler?.url,
      jobNumber: this.state.jobNumber,
      lotNum: this.state.lotNum,
      netWeight: this.state.scaleWeight.net,
      netWeightUom: ticketUom,
      orderNotes: this.state.orderNotes,
      orderStatus: 1,
      paymentType: '1', // placeholder
      plant: {
        externalId: this.state.currentPlant?.externalId,
        url: this.state.currentPlant?.url,
        name: this.state.currentPlant?.name,
        productLine: this.state.currentPlant?.productLine,
      },
      productExternalId: this.state.currentProduct?.productExternalId,
      productNumber: this.state.currentProduct?.productNumber,
      projectExternalId: this.state.currentProject?.projectExternalId,
      purchaseOrder: this.state.poNumber,
      scale: {
        name: this.state.currentScale?.name,
        url: this.state.currentScale?.url,
        scaleType: this.state.currentScale?.scaleType,
      },
      state: this.state.addressState,
      tareWeight: this.state.scaleWeight.tare,
      ticketedTimestamp,
      truck: {
        licensePlate: this.state.currentTruck?.licensePlate,
        externalId: this.state.currentTruck?.externalId,
        url: this.state.currentTruck?.url,
        truckAlias: this.state.currentTruck?.truckAlias,
        region: {
          url: this.props.currentRegion.url,
        },
        primaryProductLine: this.state.currentTruck?.primaryProductLine,
      },
      userExternalId: currentUser.externalId,
      zipCode: this.state.zip,
      weighmaster: `${currentUser.lastName}, ${currentUser.firstName}`,
    } as ScaleTraxTicketDto;

    return ticket;
  }

  async createTicket(ticket: ScaleTraxTicketDto) {
    const validated = this.validateRequiredFields();
    if (!validated) {
      return null;
    }

    const modalBody = (
      <div className={cx(styles.docContainer, styles.loading)}>
        <div className={cx(styles.loadingText)}>Saving Ticket</div>
        <div className={cx(styles.spinner)}><LoadingCircle size={88} /></div>
      </div>
    );

    const modal: ModalOptions = {
      modalTitle: undefined,
      modalType: 'DEFAULT',
      modalBody,
      modalOpen: true,
      noActions: true,
    };

    // the waiting  spinner
    this.props.openModal(modal);

    // response can be an error or TicketResponseDto
    const response = await createTicket(ticket);
    // close the spinner
    this.props.closeModal();

    if (response && response.ticket) {
      return response as TicketResponseDto;
    }

    const errorMessage = response?.data?.message || 'Unkown Error';
    // we do have a response.message error text most likely if we're here, it has been logged in recordLog
    this.props.openSnackbar({
      snackbarBody: `${errorMessage}`,
      snackbarType: ERROR,
    });
    return null;
  }

  renderActionButtons = () => (
    <div className={styles.saveAddQueueBtnGroup} key="action-buttons">
      <Button
        onClick={this.saveTicket}
        buttonClassName="tt-btn-secondary"
        name="Save"
        iconClassName="icon-save"
        dataTest="save-ticket"
        disabled={this.state.isActionDisabled}
      />
    </div>
  );

  renderPrintButton = () => (
    <Button
      onClick={this.performPrint}
      buttonClassName={styles.printButtonTicketing}
      name="Print"
      iconClassName="icon-printer icon-white"
      dataTest="print-ticket"
      disabled={this.state.isActionDisabled}
    />
  );

  setDefaultPrint = (item: { url: string }) => {
    if (this.state.currentScale && this.state.currentPlant) {
      const scale: ScaleDto = {
        ...this.state.currentScale,
        defaultPrinter: item.url,
        plant: this.state.currentPlant
      };
      this.props.updateScale(this.state.currentPlant.id, scale);
      this.setState({
        currentScale: { ...this.state.currentScale, defaultPrinter: item.url },
        currentPrinter: { text: item.url }
      });
      this.props.fetchScaleList(this.state.currentPlant!);
    }
  };

  renderPrintSection = () => {
    let { currentPrinter } = this.state;
    const currentPrint = this.state.currentScale?.defaultPrinter;
    const printerList = this.props.printerList && this.props.printerList?.length > 0 && Array.isArray(this.props.printerList)
      && typeof this.props?.printerList[0] !== 'undefined' && this.props?.printerList[0] ? this.props.printerList?.map((item => ({
        label: item?.printerName,
        text: currentPrint === item?.printerName ? `${item?.printerName} (Default printer)` : item?.printerName,
        value: { url: item?.printerName },
        infoLabel: currentPrint === item?.printerName ? 'Default printer' : undefined,
        actionLabel: currentPrint !== item?.printerName ? 'Set as default' : undefined,
        action: this.setDefaultPrint
      }))) : [];

    const selectAPrinter = {
      label: 'Select a printer',
      text: 'Select a printer',
      value: { url: undefined },
      actionLabel: '',
      infoLabel: '',
      action: this.setDefaultPrint
    };

    printerList.push(selectAPrinter);
    if (currentPrinter === undefined) currentPrinter = selectAPrinter;
    let initialSelected = printerList.find(i => i.label === currentPrinter.text);
    if (!initialSelected) initialSelected = selectAPrinter;
    const { printerError } = this.state;

    return (
      <div key="print-section" className={styles.printSection}>
        <div style={{ display: 'flex' }}>
          <div className={styles.printerList}>
            <DropDownList
              items={printerList}
              initialSelected={initialSelected}
              updateSelected={this.updateInputPrinter}
              data-test="permission-drop-down-menu"
              segmented
              hasError={!!printerError}
            />
          </div>
          <div>
            {this.renderPrintButton()}
          </div>
        </div>
        <div className={styles.printQuantityDiv}>
          <div className={styles.printCountQty}>
            QTY:
          </div>
          <Button
            onClick={this.decrementPrintCount}
            buttonClassName={styles.addQtyBtn}
            iconClassName="icon-remove"
            dataTest="edit-feature-flag-list-item"
          />
          <div className={styles.printCount}>
            {this.state.printCount}
          </div>
          <Button
            onClick={this.incrementPrintCount}
            buttonClassName={styles.addQtyBtn}
            iconClassName="icon-add"
            dataTest="edit-feature-flag-list-item"
          />
        </div>
      </div>
    );
  };

  confirmLoadPreviousTicket = async () => {
    this.props.closeModal();

    const previousTicket = await fetchPreviousTicket(this.state.currentPlant!.url!, this.state.currentTruck?.url);
    if (!previousTicket) {
      const resourceName = this.state.currentTruck ? this.state.currentTruck.truckAlias : this.state.currentPlant?.name;
      this.props.openSnackbar({
        snackbarBody: `${resourceName} has no prior tickets.`,
        snackbarType: 'ERROR',
        dataTest: 'scaletrax-ticketing-no-prior-ticket-snackbar',
      });
      return;
    }

    const [haulers, trucks, drivers, orders] = await Promise.all([
      this.props.fetchHaulerList(undefined, this.props.currentRegion?.url),
      this.props.setReduxTruckList(undefined, this.state.currentPlant?.region?.url, this.state.currentPlant?.productLine),
      this.props.setReduxDriverList(),
      this.props.getOrdersByOrderUrls(previousTicket.order?.url!),
    ]);
    const hauler = haulers!.find(h => h.url === previousTicket.haulerEntity?.url);
    const truck = trucks!.find(t => t.url === previousTicket.truck?.url);
    const driver = drivers!.find(d => d.url === previousTicket.driver?.url);
    // get the product from the previous ticket
    const prevProductNumber = getMixDesignItem(previousTicket)?.productNumber;
    this.setState({ prevProductNumber });

    await this.handleTruckChanged(truck!);
    this.handleDriverChanged(driver!);
    if (hauler) {
      this.handleHaulerChange(hauler);
    }

    let order: OrderDto | undefined;
    if (orders?.length) {
      this.props.fetchCityList(this.props.currentRegion?.url);
      [order] = orders!;
      const {
        ordersList,
      } = this.state;
      const orderExists = order && ordersList.some(o => o.orderNumber === order!.orderNumber);

      if (!orderExists) {
        ordersList.push(order);
      }
      this.setState({ ordersList });
      const previousTicketDate = new DateWrapper(previousTicket.ticketedTimestamp);
      const today = new Date();
      const isForPreviousDay = previousTicketDate.isBefore(today);
      this.handleOrderChanged(order!, !isForPreviousDay);
    }
  };

  disableSaveAndPrint = (reason?: string) => {
    this.setState({ isActionDisabled: true });
  };

  enableSaveAndPrint = (reason?: string) => {
    this.setState({ isActionDisabled: false });
  };

  loadPreviousTicket = () => {
    const {
      currentCustomer,
      currentDriver,
      currentHauler,
      currentOrder,
      currentProduct,
      currentProject,
    } = this.state;
    const values = [currentCustomer, currentDriver, currentHauler, currentOrder, currentProduct, currentProject];
    const hasAnySelectedValues = values.some(v => !!v);
    if (!hasAnySelectedValues) {
      this.confirmLoadPreviousTicket();
      return;
    }

    const modal: ModalOptions = {
      acceptDialog: this.confirmLoadPreviousTicket,
      modalType: 'ACCENT',
      modalBody: 'Using the previous ticket will replace the information you entered in the form. You can\'t undo this.',
      modalTitle: 'Previous ticket information',
      modalOpen: true,
      acceptText: 'Use Previous Ticket',
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    };
    this.props.openModal(modal);
  };

  renderVehicleSection = () => {
    const { driverList } = this.props;
    const {
      currentHauler, currentTruck, currentDriver, truckError, haulerError,
    } = this.state;
    return (
      <div className={styles.vehicleSection}>
        <div className={styles.header}>
          <span className={styles.VehicleScaleLabel}>VEHICLE</span>
        </div>

        <div className={styles.buttonsContainer}>
          <div className={styles.sectionRightPaddingFullWidth}>
            <Button
              onClick={this.loadPreviousTicket}
              name="Previous Ticket Info"
              buttonClassName={cx(styles.previousTicket, 'tt-btn-secondary')}
              dataTest="scaletrax-ticketing-import-previous"
            />
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.labelText}>
            Hauler
            <span className={styles.asterisk}>*</span>
          </div>
          <div className={styles.searchAutocomplete}>
            <ScaleTraxAutocomplete
              ref={this.haulerRef}
              options={this.props.haulersList}
              getOptionLabel={this.haulerDtoToString}
              getOptionSelected={(option: any, value: any) => option.externalId === value.externalId}
              onChange={this.updateInputHauler}
              value={currentHauler}
              hasError={!!haulerError}
            />
            <span className={styles.fieldValidationError}>{haulerError}</span>
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.labelText}>
            Truck
            <span className={styles.asterisk}>*</span>
          </div>
          <div className={styles.searchAutocomplete}>
            <ScaleTraxAutocomplete
              ref={this.truckRef}
              options={this.props.trucksList}
              getOptionLabel={this.truckDtoToString}
              getOptionSelected={(option: any, value: any) => option.externalId === value.externalId}
              onChange={this.updateInputTruck}
              value={currentTruck}
              hasError={!!truckError}
            />
            <span className={styles.fieldValidationError}>{truckError}</span>
          </div>
        </div>

        <div className={styles.formGroup}>
          <div className={styles.labelText}>
            Driver
          </div>
          <ScaleTraxAutocomplete
            ref={this.driverRef}
            options={driverList}
            getOptionLabel={this.driverDtoToString}
            getOptionSelected={(option: any, value: any) => option.externalId === value.externalId}
            onChange={this.updateInputDriver}
            value={currentDriver}
          />
        </div>
      </div>
    );
  };

  renderTicketingSection = () => {
    const {
      currentProject,
      currentOrder,
      orderNotes,
      deliveryInstructions,
    } = this.state;

    const customerOrderTab = (
      <Tab
        label="Customer/Order"
        value={0}
      />
    );

    const shouldShowOrderNotes = (!!orderNotes)
      && this.getOrderOrderNotes(currentOrder) === orderNotes;
    const shouldShowOrderDelivery = (!!deliveryInstructions)
      && this.getOrderDeliveryInstructions(currentOrder) === deliveryInstructions;

    const shouldShowProjectNotes = (!!orderNotes)
      && this.getProjectOrderNotes(currentProject) === orderNotes;
    const shouldShowProjectDelivery = (!!deliveryInstructions)
      && this.getProjectDeliveryInstructions(currentProject) === deliveryInstructions;

    const shouldShowNotesIcon = shouldShowOrderNotes
      || shouldShowOrderDelivery
      || shouldShowProjectNotes
      || shouldShowProjectDelivery;

    const notesTab = (
      <Tab
        label={(
          <div className={styles.notesTabButton}>
            <span>Notes</span>
            {
              shouldShowNotesIcon
              && (<img src={notesIndicator} alt="notes-indicator" />)
            }
          </div>
        )}
        value={2}
      />
    );

    return (
      <div>
        <div className={styles.tabsContainer}>
          <Tabs
            value={this.state.selectedTabIndex}
            onChange={(_evt: any, tab: any) => this.setState({ selectedTabIndex: tab })}
            variant="scrollable"
            scrollButtons={false}
          >
            {customerOrderTab}
            {notesTab}
          </Tabs>
        </div>
        {this.renderCurrentTab()}
      </div>
    );
  };

  renderCurrentTab() {
    const { selectedTabIndex } = this.state;
    switch (selectedTabIndex) {
      case 2:
        return this.renderNotesSection();
      case 0:
      default:
        return this.renderCustomerOrderSection();
    }
  }

  renderNotesSection = () => {
    const { orderNotes, deliveryInstructions } = this.state;

    const onNotesChange = (newDeliveryInstructions: string, newOrderNotes: string) => {
      this.setState({
        deliveryInstructions: newDeliveryInstructions,
        orderNotes: newOrderNotes,
      });
    };

    return (
      <NotesSection
        deliveryInstructions={deliveryInstructions}
        orderNotes={orderNotes}
        onChange={onNotesChange}
      />
    );
  };

  renderOrderAutocompleteTableColumns = () => (
    <div className={cx(styles.orderAutocompleteTableLine, styles.orderAutocompleteTableHeader)}>
      <div>#</div>
      <div>Date</div>
      <div>Customer</div>
      <div>Project</div>
      <div>Origin Plant</div>
      <div>Delv Type</div>
    </div>
  );

  renderProductAutocompleteOption = (option: any, state: ProductDto) => {
    if (React.isValidElement(state)) {
      return state;
    }

    const isOptionSelected = this.state.currentProduct?.productExternalId === state?.productExternalId;

    return (
      <div
        className={cx(styles.orderAutocompleteTableLine, isOptionSelected && styles.selected)}
        onClick={(e) => {
          option.onClick({
            ...e,
            changeEventValue: state
          });
        }}
        key={option.key}
      >
        {option.key}
      </div>
    );
  };

  renderProjectAutocompleteOption = (option: any, state: ProjectDto) => {
    if (React.isValidElement(state)) {
      return state;
    }

    const isOptionSelected = this.state.currentProject?.customerExternalId === state.customerExternalId
      && this.state.currentProject?.projectExternalId === state.projectExternalId;

    return (
      <div
        className={cx(styles.orderAutocompleteTableLine, isOptionSelected && styles.selected)}
        onClick={(e) => {
          option.onClick({
            ...e,
            changeEventValue: state
          });
        }}
        key={option.key}
      >
        {option.key}
      </div>
    );
  };

  renderOrderAutocompleteTableOption = (option: any, state: OrderDto) => {
    if (React.isValidElement(state)) {
      return state;
    }

    const plant = this.props.plantsList.find(p => p.url === state?.plant?.url);
    const plantName = plant?.name ?? '';

    return (
      <div
        className={cx(styles.orderAutocompleteTableLine, this.state.currentOrder?.orderNumber === state?.orderNumber && styles.selected)}
        onClick={(e) => {
          option.onClick({
            ...e,
            changeEventValue: state
          });
        }}
      >
        <div>{state.orderNumber}</div>
        <div>{new DateWrapper(state.scheduledTime).toUSDateString()}</div>
        <div>{state.customerName}</div>
        <div>{state.projectName}</div>
        <div>{plantName}</div>
        <div>[0]</div>
      </div>
    );
  };

  filterOrdersAutocomplete = (options: OrderDto[], state: FilterOptionsState<OrderDto>) => {
    const filter = state.inputValue.toLowerCase();
    return options.filter(
      o => `${o.orderNumber}`.includes(filter)
        || new DateWrapper(o.scheduledTime).toUSDateString().includes(filter)
        || o.customerName?.toLowerCase().includes(filter)
        || o.projectName?.toLowerCase().includes(filter)
        || this.props.plantsList.some(
          p => p.url === o.plant?.url
            && p.name?.toLowerCase().includes(filter)
        )
    );
  };

  renderCustomerOrderSection = () => {
    const {
      currentCustomer,
      customerError,
      deliveryTypeError,
      customersList,
      ordersList,
    } = this.state;
    const allOrdersList = [
      (<div className={styles.autocompleteGroupTitle}>ALL ORDERS</div>),
      this.renderOrderAutocompleteTableColumns(),
      ...ordersList.sort((o1, o2) => o1.orderNumber - o2.orderNumber),
    ];

    return (
      <div className={styles.container}>
        <div className={styles.customerSection}>
          <div className={styles.formGroup}>
            <div className={styles.labelText}>
              Customer
              <span className={styles.asterisk}>*</span>
            </div>
            <div className={styles.searchAutocomplete}>
              <ScaleTraxAutocomplete
                ref={this.customerRef}
                options={customersList}
                getOptionLabel={this.customerDtoToString}
                onChange={this.updateInputCustomer}
                value={currentCustomer}
                hasError={!!customerError}
              />
              <span className={styles.fieldValidationError}>{customerError}</span>
            </div>
          </div>
          {this.renderProject()}
          {this.renderProductAndAddress()}
        </div>
        <div className={styles.orderJobSection}>
          <div className={cx(styles.formGroup, styles.orderAutocomplete)}>
            <div className={styles.labelText}>
              Order
            </div>
            <div className={styles.searchAutocomplete}>
              <ScaleTraxAutocomplete
                ref={this.orderRef}
                options={allOrdersList}
                value={this.state.currentOrder}
                getOptionLabel={this.orderDtoToString}
                getOptionSelected={(option: any, value: any) => option.orderNumber === value.orderNumber}
                onChange={this.updateInputOrder}
                renderOption={this.renderOrderAutocompleteTableOption}
                filterOptions={this.filterOrdersAutocomplete}
              />
            </div>
            {this.state.isNewOrder && (
              <div className={styles.newOrderAlert}>
                *New order # will be created based on changes below.
                <button className={styles.revertOrder} onClick={this.revertOrder}>Revert Changes</button>
              </div>
            )}
            <div className={styles.formGroup}>
              {(this.state.currentProject?.deliveryType || this.state.currentHauler?.deliveryType)
                ? (
                  <div className={styles.formGroup}>
                    <div className={cx(styles.labelText)}>Delivery Type</div>
                    <CardInput
                      id="deliveryType"
                      className="widthSpace"
                      disabled
                      value={this.state.currentProject?.deliveryType ?? this.state.currentHauler?.deliveryType}
                    />
                  </div>
                )
                : (
                  <>
                    <div className={styles.deliveryTypeDropDown}>
                      <CardSelect
                        label="Delivery Type"
                        items={deliveryTypeOptions.map(dt => ({
                          label: dt.label,
                          text: dt.text,
                          value: dt.value,
                          key: dt.key.toString(),
                        }))}
                        initialSelected={{ label: 'Select...', name: 'Select...' } as any}
                        onChange={this.updateDeliveryType}
                        isRequired
                        value={this.state.deliveryType}
                        errorMessage={deliveryTypeError}
                      />
                    </div>
                    <span className={styles.fieldValidationErrorDeliveryType}>{deliveryTypeError}</span>
                  </>
                )}
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.deliveryTypeJobNumberSection}>
              <div className={styles.formGroup}>
                <div className={cx(styles.labelText)}>Job Number</div>
                <CardInput
                  id="jobNumberInput"
                  className="widthSpace"
                  hideCharacterCount
                  value={this.state.jobNumber}
                  onChange={this.onJobNumberChange}
                  onTouch={noop}
                />
              </div>
              <div className={styles.formGroup}>
                <div className={cx(styles.labelText)}>PO Number</div>
                <CardInput
                  id="poNumberInput"
                  className="widthSpace"
                  hideCharacterCount
                  value={this.state.poNumber}
                  onChange={this.onPONumberChange}
                  onTouch={noop}
                />
              </div>
            </div>
            <div className={styles.paymentTypeCertSection}>
              <div className={styles.formGroup}>
                <div className={cx(styles.labelText)}>{this.state.lotLabel}</div>
                <CardInput
                  id="lotInput"
                  disabled={this.state.lotDisabled}
                  className="widthSpace"
                  hideCharacterCount
                  onChange={this.lotChanged}
                  onTouch={noop}
                  value={this.state.lotNum}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderProject = () => {
    const {
      currentProject,
      projectsList,
      projectError,
    } = this.state;

    const allProjects = [];

    let { recentProjects } = this.props;
    recentProjects = recentProjects.filter(p1 => projectsList.some(p2 => p1.projectExternalId === p2.projectExternalId));

    if (recentProjects.length) {
      allProjects.push(<div className={styles.autocompleteGroupTitle}>RECENT PROJECTS</div>);
      allProjects.push(...recentProjects);
    }

    const oldProjects = projectsList.filter(p1 => !recentProjects.some(p2 => p2.projectExternalId === p1.projectExternalId));
    if (oldProjects.length) {
      allProjects.push(<div className={styles.autocompleteGroupTitle}>ALL PROJECTS</div>);
      allProjects.push(...oldProjects);
    }

    return (
      <div className={styles.formGroup}>
        <div className={styles.labelText}>
          Project
          <span className={styles.asterisk}>*</span>
        </div>
        <div className={styles.searchAutocomplete}>
          <ScaleTraxAutocomplete
            ref={this.projectRef}
            options={allProjects}
            getOptionLabel={this.projectDtoToString}
            onChange={this.updateInputProject}
            value={currentProject}
            hasError={!!projectError}
            renderOption={this.renderProjectAutocompleteOption}
          />
          <span className={styles.fieldValidationError}>{projectError}</span>
        </div>
      </div>
    );
  };

  renderProductAndAddress = () => {
    const {
      currentProject,
      productError,
      currentCity,
      cityError,
      addressStateError,
      address1Error,
      addressState,
    } = this.state;
    if (!currentProject) {
      return null;
    }

    const projectProducts = this.state.allProducts?.projectProducts ?? [];
    const plantProducts = this.state.allProducts?.otherProducts ?? [];

    const dropDownProductsList = [
      (<div className={styles.autocompleteGroupTitle}>PROJECT PRODUCTS</div>),
      ...projectProducts,
      (<div className={styles.autocompleteGroupTitle}>ALL PRODUCTS FOR CURRENT PLANT</div>),
      ...plantProducts,
    ];

    const dropDownStates = STATE_LIST.map(itemsToLabelValue);
    return (
      <div className={styles.sectionNoPadding}>
        <div className={styles.container}>
          <div className={styles.leftLine} />
          {/* left line beside product dropdown } */}
          <div className={styles.productAddressBox}>
            <div className={styles.labelText}>
              Product
              <span className={styles.asterisk}>*</span>
            </div>
            <div className={styles.searchAutocomplete}>
              <ScaleTraxAutocomplete
                ref={this.productRef}
                options={dropDownProductsList}
                getOptionLabel={this.productDtoToString}
                onChange={this.updateInputProduct}
                value={this.state.currentProduct}
                hasError={!!productError}
                renderOption={this.renderProductAutocompleteOption}
              />
              <span className={styles.fieldValidationError}>{productError}</span>
            </div>
            <div className={styles.formGroup}>
              <CardInput
                id="address1"
                label="Delivery Address Line 1"
                disabled={false}
                isRequired
                maxLength={255}
                hideCharacterCount
                onChange={this.onAddress1Change}
                onTouch={noop}
                value={this.state.address1}
                errorMessage={address1Error}
              />
              <span className={styles.fieldValidationError}>{address1Error}</span>
            </div>
            <div className={styles.formGroup}>
              <div className={cx(styles.labelText)}>Delivery Address Line 2</div>
              <CardInput
                id="address2"
                disabled={false}
                className="widthSpace"
                maxLength={255}
                hideCharacterCount
                onChange={this.onAddress2Change}
                onTouch={noop}
                value={this.state.address2}
              />
            </div>
            {' '}
            {/** ***************  end address line 2 ******************* */}
            <div className={styles.container}>
              <div className={styles.formCity}>
                <div className={styles.labelText}>
                  City
                  <span className={styles.asterisk}>*</span>
                </div>
                <div className={styles.searchAutocomplete}>
                  <ScaleTraxAutocomplete
                    ref={this.cityRef}
                    options={this.props.citiesList}
                    getOptionLabel={this.cityDtoToString}
                    getOptionSelected={(option: any, value: any) => option.cityName === value.cityName}
                    onChange={this.updateInputCity}
                    value={currentCity}
                    hasError={!!cityError}
                  />
                  <span className={styles.fieldValidationError}>{cityError}</span>
                </div>
              </div>
              <div className={styles.state}>
                <div className={styles.labelText}>
                  State
                  <span className={styles.asterisk}>*</span>
                </div>
                <div className={styles.searchAutocomplete}>
                  <ScaleTraxAutocomplete
                    options={dropDownStates.map(st => ({
                      value: st.value,
                    }))}
                    getOptionLabel={this.stateToString}
                    getOptionSelected={(option: any, value: any) => option.value === value.value}
                    onChange={this.onAddressStateChange}
                    value={addressState as any}
                    disableClearable={!!addressState}
                    hasError={!!addressStateError}
                  />
                  <span className={styles.fieldValidationError}>{addressStateError}</span>
                </div>
              </div>
              <div className={styles.zip}>
                <div id="zipcode" className={cx(styles.labelText)}>
                  Zip
                </div>
                <input
                  aria-labelledby="zipcode"
                  onChange={this.onZipChange}
                  type="text"
                  className="tt-input"
                  defaultValue={this.state.zip}
                />
              </div>
            </div>
          </div>
        </div>
        {' '}
        {/* end product/address container */}
      </div>
    );
  };

  confirmClear = () => {
    const modal: ModalOptions = {
      acceptDialog: () => this.resetForm(),
      modalType: 'ACCENT',
      modalBody: 'Are you sure you want to clear the entire form? You can\'t undo this.',
      modalTitle: 'Clear Form',
      modalOpen: true,
      acceptText: 'Clear Form',
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    };
    this.props.openModal(modal);
  };

  checkFormModified() {
    const formValues = [
      this.state.currentCustomer,
      this.state.currentDriver,
      this.state.currentHauler,
      this.state.currentTruck,
      this.state.currentOrder,
      this.state.currentProduct,
      this.state.currentProject,
      this.state.address1,
      this.state.address2,
      this.state.currentCity,
      this.state.addressState,
      this.state.zip,
      this.state.deliveryType,
      this.state.jobNumber,
      this.state.lotNum,
      this.state.poNumber,
    ];
    return formValues.some(v => !!v);
  }

  renderClearButton() {
    if (!this.checkFormModified()) {
      return null;
    }

    return (
      <Fab className={styles.floatingButton} variant="extended" onClick={this.confirmClear}>
        <i
          className="icon-backspace"
        />
        Clear Form
      </Fab>
    );
  }

  renderContainer = () => {
    if (this.state.currentPlant) {
      return (
        <div className={styles.bodyWrapper}>
          <div className={styles.cardWrapper}>
            <Card classes={cardStyle}>
              <CardContent>
                <div className={styles.cardContents}>
                  <div className={styles.container}>
                    {this.renderVehicleSection()}
                    <div className={styles.scaleSection}>
                      <ScaleSection
                        truck={this.state.currentTruck}
                        scale={this.state.currentScale}
                        quantity={this.state.scaleWeight}
                        tareError={this.state.tareError}
                        onChange={this.updateScale}
                        onTared={this.updateTare}
                        isScaleMaxWeightExceeded={this.isScaleMaxWeightExceeded}
                        isTruckTypeMaxWeightExceeded={this.isTruckTypeMaxWeightExceeded}
                        receivedWeight={this.props.receivedWeight}
                      />
                    </div>
                  </div>
                  <div>
                    {this.renderTicketingSection()}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <ScaleTraxFooter />
          {this.renderClearButton()}
        </div>
      );
    }
    return (
      <div><ScaleTraxFooter /></div>
    );
  };

  render() {
    return (
      <div className={styles.footer}>
        <Paper
          className={cx(
            styles.topHeader
          )}
        >
          <div className={cx(
            styles.innerHeaderStyle,
          )}
          >
            <div
              className={cx(
                styles.leftHeader
              )}
            >
              {this.renderHeaderPlant()}
            </div>
            <div
              className={cx(
                styles.rightHeader
              )}
            >
              {this.renderActionButtons()}
              {this.renderPrintSection()}
            </div>
          </div>
        </Paper>
        {this.renderContainer()}
      </div>
    );
  }
}

export function mapStateToProps(state: ReduxState) {
  return {
    haulersList: state.haulersList?.haulers,
    trucksList: state.trucksList?.trucks,
    driverList: state.driverList?.drivers,
    customersList: state.customersList.customers,
    allProducts: state.allProducts.products,
    citiesList: state.citiesList.cities,
    projectsList: state.projectsList.projects,
    currentRegion: state.currentRegion,
    currentPlant: state.currentPlant,
    scalesList: state.scalesList?.scales ?? [],
    printerList: state.printerList?.printers ?? [],
    userPermission: state.userPermission,
    plantsList: state.plantList,
    usersList: state.usersList.users,
    ordersList: state.ordersList.orders,
    currentUser: state.userUrl,
    recentProjects: state.recentProjects,
    receivedWeight: state.receivedWeight as ReceivedWeight,
  };
}

type ScaleTraxTicketingReduxProps = ReturnType<typeof mapStateToProps>;

type ScaleTraxTicketingDispatchProps = {
  fetchHaulerList: ConnectedDispatchFunction<typeof fetchHaulerList>;
  fetchScaleList: ConnectedDispatchFunction<typeof fetchScaleList>;
  fetchPrinterList: ConnectedDispatchFunction<typeof fetchPrinterList>;
  fetchCustomerList: ConnectedDispatchFunction<typeof fetchCustomerList>;
  fetchProjectList: ConnectedDispatchFunction<typeof fetchProjectList>;
  fetchProductList: ConnectedDispatchFunction<typeof fetchProductList>;
  fetchCityList: ConnectedDispatchFunction<typeof fetchCityList>;
  fetchOpenOrders: ConnectedDispatchFunction<typeof fetchOpenOrders>;
  fetchPlantList: ConnectedDispatchFunction<typeof fetchPlantList>;
  setReduxDriverList: ConnectedDispatchFunction<typeof setReduxDriverList>;
  setReduxTruckList: ConnectedDispatchFunction<typeof setReduxTruckList>;
  getDefaultPlant: ConnectedDispatchFunction<typeof getDefaultPlant>;
  openSnackbar: ConnectedFunction<typeof openSnackbar>;
  queueRecentProject: ConnectedDispatchFunction<typeof queueRecentProject>;
  openModal: ConnectedFunction<typeof openModal>;
  closeModal: ConnectedFunction<typeof closeModal>;
  setCurrentPlant: ConnectedDispatchFunction<typeof setCurrentPlant>;
  getOrdersByOrderUrls: ConnectedDispatchFunction<typeof getOrdersByOrderUrls>;
  updateScale: (plantId: number, scale: ScaleDto) => Promise<any>
};

type ScaleTraxTicketingOwnProps = {
  receivedWeight: ReceivedWeight,
};

export type ScaleTraxTicketingProps = ScaleTraxTicketingReduxProps & ScaleTraxTicketingDispatchProps & ScaleTraxTicketingOwnProps;
export default connect(mapStateToProps, {
  fetchHaulerList,
  fetchPrinterList,
  fetchScaleList,
  setReduxDriverList,
  setReduxTruckList,
  fetchCustomerList,
  fetchProjectList,
  fetchCityList,
  fetchProductList,
  fetchOpenOrders,
  fetchPlantList,
  getDefaultPlant,
  updateTruck,
  openModal,
  closeModal,
  queueRecentProject,
  setCurrentPlant,
  openSnackbar,
  getOrdersByOrderUrls,
  updateScale
})(ScaleTraxTicketing);
