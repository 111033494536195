import { SET_MODAL_DISABLED_STATE, SET_MODAL_OPTIONS } from '../../constants/actionConstants';
import { FAIL, OK } from '../../constants/appConstants';
import { ModalOptions } from '../../types';

export const openModal = (options: ModalOptions) => ({
  type: SET_MODAL_OPTIONS,
  payload: { ...options, modalOpen: true },
});

export const setDisabledState = (disabled: boolean) => ({
  type: SET_MODAL_DISABLED_STATE,
  payload: disabled,
});

export const closeModal = () => ({
  type: SET_MODAL_OPTIONS,
  payload: {
    // do not add more properties to prevent content flicker
    modalOpen: false,
    // need to reset this value when modal closes or else it will persist between unrelated modals
    isDeleteConfirmation: false,
  },
});

export const openFailModal = (modalTitle: string, modalBody: string) => ({
  type: SET_MODAL_OPTIONS,
  payload: {
    modalOpen: true,
    modalType: FAIL,
    modalTitle,
    modalBody,
    acceptText: OK,
    disabled: false,
  },
});
