import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  PermissionAccess, PlantDto, ProductLine, UnitOfMeasure, UrlKeyDto,
} from '@trucktrax/trucktrax-ts-common';
import { Label } from '@trucktrax/trucktrax-common';
import {
  FormControl, FormControlLabel, MenuItem, Radio, RadioGroup,
  Select,
  Tooltip,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import InfoIcon from '@mui/icons-material/Info';
import styles from './PlantDetailView.module.css';
import AdminCard from '../../shared/admin/AdminCard';
import { isEveryZoneValid } from '../../../util/validation';
import {
  accessValue,
  defaultLoadingTimeOptions,
  defaultProductLineRadioOptions,
  getAddressPartOne,
  getAddressPartTwo,
  getExternalId,
  getOwnershipType,
  getProductLine,
  getRegionUrlToNameMap,
  getUrl,
  itemsToLabelValue,
  mapAccessorForPlantDetailGeozone,
  nameAndUrlItemToDropdownOptions,
  ownershipTypeRadioOptions,
  weightOptions,
} from '../../../util/adminUtil';
import { addToOrUpdateListInStore } from '../../../store/actions/dataTableActions';
import { createDataTableRecord, updateDataTableRecord } from '../../../services/dataTableService';
import { PLANTS_TEXT } from '../../../constants/navConstants';
import { UPDATE_PLANT } from '../../../constants/actionConstants';
import {
  ACCENT,
  ADMIN_KEYS,
  ADMIN_LABELS,
  ARE_YOU_SURE,
  ARE_YOU_SURE_ACTIVATE,
  ARE_YOU_SURE_DEACTIVATE,
  CARD_SWITCH,
  COMPOSITE,
  DROPDOWN_FORM,
  EDIT_BTN,
  EXTERNAL,
  GEOZONE_TYPE,
  INPUT_FORM,
  INTERNAL,
  MAP_MULTIZONES_FORM,
  ONCE_ITS_ACTIVATED,
  ONCE_ITS_DEACTIVATED,
  RADIO_FORM,
  READYMIX_VALUE,
  SAVE_BTN,
  STATE_LIST,
  TEXT_DISPLAY,
  TICKETS_CAN_BE_CREATED,
  TICKETS_CANNOT_BE_CREATED,
  TRUCKS_ENTERING_WILL_STATUS,
  TRUCKS_ENTERING_WILL_NOT_STATUS,
  YES_ACTIVATE_PLANT,
  YES_DEACTIVATE_PLANT,
  NUMERIC_INPUT_FORM,
  CUSTOM_COMPONENT,
  CANCEL_LABEL, GEOTRAX_LITE_FLAG,
} from '../../../constants/appConstants';
import { GEOZONES_PATH, PLANTS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { noop } from '../../../util/appUtil';
import { ADD_GEOZONE_SUCCESS_TEXT } from '../../../constants/successConstants';
import { ADD_GEOZONE_ERROR_MESSAGE } from '../../../constants/errorConstants';
import { CARD_MAP_HEIGHT } from '../../../constants/mapConstants';
import StatusContainer from '../status/StatusContainer';
import { openModal, closeModal } from '../../../store/actions/errorModalActions';
import { isFeatureFlagEnabled } from '../../../util/featureFlagUtil';
import { RegionAccessMap } from '../../../util/permissionUtil';

export class PlantDetailView extends Component<PlantDetailViewProps> {
  state = {
    edit: false,
    plantDetails: {
      name: '',
      geozones: [{}],
      archived: false,
      ownershipType: '',
      editableTicketWeights: false,
      gtLiteParticipant: false,
      editableLoadedTime: true,
      productLine: '',
      defaultUom: undefined as UnitOfMeasure | undefined,
      backfillLoadedTimeDuration: undefined
    },
    selectedZone: GEOZONE_TYPE.PLANT,
    launchToEdit: false,
    productLine: '',
    defaultUom: undefined as UnitOfMeasure | undefined,
    isToggleSwitchChecked: false, // Initial state for the toggle switch
  };

  isGeotraxLiteEnabled = () => isFeatureFlagEnabled(
    this.props.userPermission,
    this.props.currentRegion?.url ?? '',
    GEOTRAX_LITE_FLAG
  );

  onDeactivatePlant = () => {
    const modalBody = (
      <p className="margin-bottom-5">
        {ONCE_ITS_DEACTIVATED}

        <ul>
          <li>{TICKETS_CANNOT_BE_CREATED}</li>
          <li>{TRUCKS_ENTERING_WILL_NOT_STATUS}</li>
        </ul>

        {ARE_YOU_SURE_DEACTIVATE}
      </p>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.deactivatePlant,
      acceptText: YES_DEACTIVATE_PLANT,
      cancelText: CANCEL_LABEL,
      disabled: false,
    });
  };

  onActivatePlant = () => {
    const modalBody = (
      <p className="margin-bottom-5">
        {ONCE_ITS_ACTIVATED}

        <ul>
          <li>{TICKETS_CAN_BE_CREATED}</li>
          <li>{TRUCKS_ENTERING_WILL_STATUS}</li>
        </ul>

        {ARE_YOU_SURE_ACTIVATE}
      </p>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.activatePlant,
      acceptText: YES_ACTIVATE_PLANT,
      cancelText: CANCEL_LABEL,
      disabled: false,
    });
  };

  onChangeHandler = (newValue: any) => {
    // If ownership type is INTERNAL or product line is Ready-mix, change editableTicketWeights to false
    if ((newValue.ownershipType === INTERNAL || newValue.productLine === READYMIX_VALUE)
      && newValue.editableTicketWeights) {
      newValue.editableTicketWeights = false;
    }

    // If ownership type is INTERNAL or product line is Ready-mix, change editableLoadedTime to false
    if ((newValue.ownershipType === INTERNAL || newValue.productLine === READYMIX_VALUE)
      && newValue.editableLoadedTime) {
      newValue.editableLoadedTime = false;
    }
    // productLine has its own handler and state, we have to keep track of it, grab it from state if it exists and isn't empty string
    newValue.productLine = this.state.productLine?.length ? this.state.productLine : newValue.productLine;
    this.setState({
      plantDetails: {
        ...newValue,
      },
      productLine: newValue.productLine,
    });
  };

  handleProductLineChange = () => (event: any) => {
    const productLine = event.target.value;
    const { plantDetails } = this.state;
    let { defaultUom } = this.state;
    plantDetails.productLine = productLine;
    if (productLine === ProductLine.ReadyMix) {
      defaultUom = undefined;
      plantDetails.defaultUom = undefined;
    } else if (this.state.defaultUom === undefined) {
      plantDetails.defaultUom = weightOptions[0].value;
      defaultUom = weightOptions[0].value;
    }

    this.setState({
      productLine,
      plantDetails,
      defaultUom,
    });
  };

  handleUomChange = () => (event: any) => {
    const defaultUom = event.target.value;
    const { plantDetails } = this.state;
    plantDetails.defaultUom = defaultUom;
    this.setState({
      defaultUom,
      plantDetails: { ...this.state.plantDetails, defaultUom },
    });
  };

  getDefaultUom = () => (
    this.state.plantDetails.defaultUom !== undefined ? this.state.plantDetails.defaultUom : weightOptions[0].value
  );

  onSelectedZoneChange = (newValue: any) => {
    this.setState({ selectedZone: newValue });
  };

  handleToggleSwitchChange = (event: any) => {
    this.setState({ isToggleSwitchChecked: event.target.checked });
    this.setState({
      plantDetails: {
        ...this.state.plantDetails,
        backfillLoadedTimeDuration: event.target.checked === true ? undefined : 10
      }
    });
  };

  handleDropdownChange = (event: any) => {
    this.setState({ plantDetails: { ...this.state.plantDetails, backfillLoadedTimeDuration: event.target.value } });
  };

  getLoadingTime = () => (
    this.state.plantDetails.backfillLoadedTimeDuration !== undefined ? this.state.plantDetails.backfillLoadedTimeDuration
      : defaultLoadingTimeOptions[1].value
  );

  /**
   * Saving edited plant information not including geozones
   * Then do a PUT update if geozone exisits,
   * otherwise make a POST call to create the missing/edited geozone
   */
  save = (dto: any, onSuccess: any, onError: any) => {
    if (this.state.plantDetails.productLine !== '') {
      dto.productLine = this.state.plantDetails.productLine;
    }
    dto.defaultUom = this.state.plantDetails.defaultUom;

    if (this.state.plantDetails.productLine !== ProductLine.ReadyMix
      && this.state.plantDetails.defaultUom === undefined) {
      dto.defaultUom = weightOptions[0].value;
    } else if (this.state.plantDetails.productLine === ProductLine.ReadyMix) {
      dto.scaleHostAddress = '';
    }
    if (this.state.plantDetails.productLine === ProductLine.Aggregates) {
      dto.backfillLoadedTimeDuration = this.state.plantDetails.backfillLoadedTimeDuration;
    }

    const {
      geozones,
      ...updatedPlant
    } = dto;

    const { createAdminTableData: post, updateAdminTableData: put } = this.props;
    const currentRegionUrl = this.props.currentRegion?.url;

    const onSaveSuccess = (updatedDto: any) => {
      this.setState({
        edit: false,
        selectedZone: GEOZONE_TYPE.PLANT,
      });
      const toastMessages = {
        success: ADD_GEOZONE_SUCCESS_TEXT,
        fail: ADD_GEOZONE_ERROR_MESSAGE,
      };
      updatedDto.data.geozones = [];

      // update geozones
      const addOrEditGeozones = geozones!.map((zone: { id: any; }) => {
        if (zone.id) {
          return put(
            `${this.geoZoneUrl()}/${zone.id}`,
            zone,
            a => updatedDto.data.geozones.push(a.data),
            onError,
            false,
            currentRegionUrl
          );
        }

        return post(
          this.geoZoneUrl(),
          zone,
          a => updatedDto.data.geozones.push(a),
          noop,
          toastMessages,
          true,
          currentRegionUrl
        );
      });

      Promise.all(addOrEditGeozones).then(() => {
        onSuccess(updatedDto);
        this.props.addToOrUpdateListInStore(updatedDto, UPDATE_PLANT);
      });
      // end update geozones
    };
    updatedPlant.externalId = updatedPlant.externalId === '' ? null : updatedPlant.externalId;
    put(this.baseUrl(), updatedPlant, onSaveSuccess, onError, false, currentRegionUrl);
  };

  updateArchived = (dto: any, onSuccess: any, onError: any, toastMessages: any) => {
    const { geozones } = this.state.plantDetails;
    const onSaveSuccess = (updatedDto: any) => {
      const plantDetails = updatedDto && updatedDto.data ? updatedDto.data : {};
      plantDetails.geozones = geozones;
      this.setState({
        edit: false,
        plantDetails,
      });
      onSuccess(updatedDto);
      this.props.addToOrUpdateListInStore(updatedDto, UPDATE_PLANT);
      this.props.closeModal();
    };
    const currentRegionUrl = this.props.currentRegion?.url;
    this.props.updateAdminTableData(this.baseUrl(), dto, onSaveSuccess, onError, false, currentRegionUrl, toastMessages);
  };

  toggleEdit = () => {
    this.setState({
      edit: !this.state.edit,
      selectedZone: GEOZONE_TYPE.PLANT,
      launchToEdit: false,
      productLine: '',
    });
  };

  launchToEdit = () => {
    this.setState({
      launchToEdit: true,
    });
  };

  static addressAccessor = (item: any) => (`${getAddressPartOne(item)}\n${getAddressPartTwo(item)}`);

  static getWeightOption = (option: any) => {
    const index: keyof typeof UnitOfMeasure = option;
    const value: UnitOfMeasure = UnitOfMeasure[index];
    return weightOptions.find(o => o.value === value);
  };

  static uomAccessor = (item: PlantDto) => {
    if (item.defaultUom === undefined) {
      return undefined;
    }
    return `${PlantDetailView.getWeightOption(item.defaultUom)?.label}`;
  };

  static loadedtimeAccessor = (item: PlantDto) => {
    if (item.backfillLoadedTimeDuration === undefined) {
      return 'Not set';
    }

    return `${item.backfillLoadedTimeDuration} minutes`;
  };

  static externalIdAccessor = (item: any) => `${getExternalId(item)}`;

  regionUrlAccessor = (row: any) => (getRegionUrlToNameMap(this.props.regions)[getUrl(row)!]);

  baseUrl = () => `${getGeotraxBaseUrl() + PLANTS_PATH}/${this.props.match.params.id}`;

  geoZoneUrl = () => `${getGeotraxBaseUrl() + PLANTS_PATH}/${this.props.match.params.id}${GEOZONES_PATH}`;

  fetchPlantGeozone = async (adminCardResponse: { data: PlantDto }) => {
    const { data } = adminCardResponse;
    this.setData(data);
  };

  setData = (data: PlantDto) => {
    this.setState({
      plantDetails: { ...data },
    });
  };

  isActivateDisabled = () => {
    const { geozones, archived } = this.state.plantDetails;
    if (!archived) { return false; }

    const hasGeozones = geozones && geozones.length;
    const hasZeroGeozones = !hasGeozones || geozones.length === 0;
    if (hasZeroGeozones) { return true; }

    const hasValidGeozoneCenter = (geozone: { zone: { circle: { center: any; }; }; }) => {
      const zoneCenter = geozone?.zone?.circle?.center;
      if (!zoneCenter) {
        return true;
      }
      return (zoneCenter.latitude !== 0 && zoneCenter.longitude !== 0);
    };
    const zonesWithInvalidCenters = geozones.filter((geozone: any) => !hasValidGeozoneCenter(geozone));
    return zonesWithInvalidCenters.length >= geozones.length;
  };

  productLines = (
    radioBtn: string,
    radioBtnSelected: string,
    labelStyle: string,
    selectedUom?: UnitOfMeasure,
    backfillLoadedTimeDuration?: Number
  ) => (
    <FormControl className={styles.productLines}>
      <Label>
        {ADMIN_LABELS.PRODUCT_LINE}
        <span className={styles.asterisk}>*</span>
      </Label>
      <RadioGroup
        defaultValue={this.state.plantDetails.productLine}
        name={ADMIN_KEYS.PRODUCT_LINE}
        onChange={this.handleProductLineChange()}
      >
        <FormControlLabel
          classes={{ label: labelStyle }}
          value={defaultProductLineRadioOptions[0].value}
          control={<Radio classes={{ root: radioBtn, checked: radioBtnSelected }} />}
          label={defaultProductLineRadioOptions[0].label}
        />
        {this.state.plantDetails.productLine === ProductLine.Aggregates
          && (
            <>
              {this.uom(radioBtn, radioBtnSelected, labelStyle, selectedUom)}
              {this.toggleSwitch(styles.LoadedTimeLabel, backfillLoadedTimeDuration)}
              {!!backfillLoadedTimeDuration && this.dropdownBox(styles.loadTimeSelect, styles.LoadedTimeLabel)}
            </>
          )}

        <FormControlLabel
          classes={{ label: labelStyle }}
          value={defaultProductLineRadioOptions[1].value}
          control={<Radio classes={{ root: radioBtn, checked: radioBtnSelected }} />}
          label={defaultProductLineRadioOptions[1].label}
        />
        {this.state.plantDetails.productLine === ProductLine.Cement
          && this.uom(radioBtn, radioBtnSelected, labelStyle, selectedUom)}

        <FormControlLabel
          classes={{ label: labelStyle }}
          value={defaultProductLineRadioOptions[2].value}
          control={<Radio classes={{ root: radioBtn, checked: radioBtnSelected }} />}
          label={defaultProductLineRadioOptions[2].label}
        />
      </RadioGroup>
    </FormControl>
  );

  toggleSwitch = (toggleSwitchStyle: string, backfillLoadedTimeDuration: any) => (
    <div className={styles.LoadedTimeLabel}>
      <div className={styles.labelWithTooltip}>
        <Label className={styles.LoadedTimeLabel}>{ADMIN_LABELS.LOADED_TIME}</Label>
        <Tooltip title="When on, driver must enter Loaded Time manually" arrow placement="top">
          <InfoIcon className={styles.tooltipIcon} />
        </Tooltip>
      </div>
      <FormControlLabel
        control={
          <Switch
            classes={{ root: toggleSwitchStyle, checked: (backfillLoadedTimeDuration !== undefined).toString() }}
            checked={(backfillLoadedTimeDuration === undefined || this.state.isToggleSwitchChecked)}
            onChange={this.handleToggleSwitchChange}
          />
        }
        label={ADMIN_LABELS.SHOW_LOADED_TIME}
      />
    </div>
  );

  dropdownBox = (dropdownStyle: string | undefined, labelStyle: string) => (
    <FormControl className={labelStyle}>
      <div className={styles.labelWithTooltip}>
        <Label className={labelStyle}>
          Auto-backfilled Loaded Time
          <span className={styles.asterisk}>*</span>
        </Label>
        <Tooltip title="Automatically sets loaded Time based on selection below" arrow placement="top">
          <InfoIcon className={styles.tooltipIcon} />
        </Tooltip>
      </div>
      <Select
        className={dropdownStyle}
        value={this.getLoadingTime()}
        onChange={this.handleDropdownChange}
        MenuProps={{
          PaperProps: {
            style: {
              width: 'auto',
              minWidth: '100%',
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        fullWidth
      >
        {defaultLoadingTimeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  uom = (radioBtn: string, radioBtnSelected: string, labelStyle: string, selectedUom?: UnitOfMeasure) => {
    const selectedUomValue = selectedUom ?? weightOptions[0].value;
    return (
      <div className={styles.uom}>
        <Label className={styles.uomLabel}>{ADMIN_LABELS.DEFAULT_SCALE_UNIT}</Label>
        <RadioGroup
          defaultValue={this.getDefaultUom()}
          name={ADMIN_KEYS.DEFAULT_SCALE_UNIT}
          onChange={this.handleUomChange()}
        >
          {weightOptions.map((uom) => (
            <FormControlLabel
              key={uom.value}
              classes={{ label: labelStyle }}
              value={uom.value}
              control={(
                <Radio
                  checked={selectedUomValue.toString() === uom.value.toString()}
                  classes={{ root: radioBtn, checked: radioBtnSelected }}
                />
              )}
              label={uom.label}
            />
          ))}
        </RadioGroup>
      </div>
    );
  };

  viewOnlyItems() {
    if (this.state.edit) {
      return null;
    }

    const hasEditPermission = this.props.plantsPermissionAccess === PermissionAccess.Edit;
    const isArchived = this.state.plantDetails.archived;
    const isDisabled = this.isActivateDisabled();
    return (
      <StatusContainer
        category="plant"
        isArchived={isArchived}
        isDisabled={isDisabled}
        hasEditPermission={hasEditPermission}
        containerClassName={styles.statusCtr}
        onActivateClick={this.onActivatePlant}
        onDeactivateClick={this.onDeactivatePlant}
      />
    );
  }

  deactivatePlant = () => {
    const dto = {
      ...this.state.plantDetails,
      archived: true,
    };
    const toastMessages = {
      success: `“${dto.name}” has been successfully deactivated.`,
      fail: `Unable to deactivate “${dto.name}.”`,
    };
    this.updateArchived(dto, noop, null, toastMessages);
  };

  activatePlant = () => {
    const dto = {
      ...this.state.plantDetails,
      archived: false,
    };
    const toastMessages = {
      success: `"${dto.name}" has been successfully activated.`,
      fail: `Unable to activate “${dto.name}.”`,
    };
    this.updateArchived(dto, noop, null, toastMessages);
  };

  getBreadcrumbPath() {
    if (!this.state.plantDetails.archived) {
      return this.props.location.pathname;
    }
    // construct the breadcrumb path for deactivated plants
    const paths = this.props.location.pathname.split('/');
    const id = paths.pop();
    if (id) {
      paths.push('deactivated', id);
    }
    return paths.join('/');
  }

  render() {
    const {
      ownershipType,
      editableTicketWeights,
      productLine,
      defaultUom,
      backfillLoadedTimeDuration,
    } = this.state.plantDetails;

    const hasMissingGeozones = this.isActivateDisabled();
    const selectedUom = this.state.defaultUom ?? PlantDetailView.getWeightOption(defaultUom)?.value;
    const readConfigWithoutPlantEditPermission = [
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.PLANT_NAME,
        accessor: ADMIN_KEYS.NAME,
        className: cx(styles.name, 'large'),
        dataTest: 'plants-drilldown-name',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.EXTERNAL_ID,
        accessor: PlantDetailView.externalIdAccessor,
        className: cx(styles.externalId),
        dataTest: 'plants-drilldown-externalId',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.OWNERSHIP_TYPE,
        accessor: getOwnershipType,
        className: styles.ownershipType,
        dataTest: 'users-drilldown-ownership',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.PRODUCT_LINE,
        accessor: getProductLine,
        className: styles.productLines,
        dataTest: 'users-drilldown-product',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.SCALE_HOST_ADDRESS,
        accessor: ADMIN_KEYS.SCALE_HOST_ADDRESS,
        className: styles.productLines,
        dataTest: 'users-drilldown-scale-host-address',
        display: productLine !== ProductLine.ReadyMix,
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.LATITUDE,
        accessor: ADMIN_KEYS.LATITUDE,
        className: styles.latitude,
        dataTest: 'plants-drilldown-latitude',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.REGION,
        accessor: this.regionUrlAccessor,
        className: styles.region,
        dataTest: 'plants-drilldown-region',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.LONGITUDE,
        accessor: ADMIN_KEYS.LONGITUDE,
        className: styles.longitude,
        dataTest: 'plants-drilldown-longitude',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.ADDRESS,
        accessor: PlantDetailView.addressAccessor,
        className: styles.address,
        dataTest: 'plants-drilldown-address',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.DEFAULT_SCALE_UNIT,
        accessor: PlantDetailView.uomAccessor,
        dataTest: 'users-drilldown-uom',
        display: productLine !== ProductLine.ReadyMix,
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.LOADED_TIME_LABEL,
        accessor: PlantDetailView.loadedtimeAccessor,
        dataTest: 'users-drilldown-time',
        display: productLine === ProductLine.Aggregates,
      },
      {
        type: MAP_MULTIZONES_FORM,
        label: ADMIN_LABELS.GEOZONES,
        key: ADMIN_LABELS.GEOZONES,
        className: cx(styles.map, 'margin-bottom-40', styles.largeLabel),
        dataTest: 'plants-drilldown-geofence',
        isEditable: false,
        resetBtn: false,
        missingGeozones: hasMissingGeozones,
        launchToEdit: this.launchToEdit,
        accessor: mapAccessorForPlantDetailGeozone,
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.EDIT_WEIGHT_ALLOWED,
        accessor: ADMIN_KEYS.EDIT_TICKET_WEIGHTS,
        className: styles.editWeightsRead,
        display: ownershipType === EXTERNAL,
      }
    ];

    const isGeoTraxLiteEnabled = this.isGeotraxLiteEnabled();

    if (isGeoTraxLiteEnabled) {
      readConfigWithoutPlantEditPermission.push({
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.GEOTRAX_LITE_PARTICIPANT,
        key: ADMIN_KEYS.GEOTRAX_LITE_PARTICIPANT,
        accessor: ADMIN_KEYS.GEOTRAX_LITE_PARTICIPANT
      } as any);
    }

    const readConfigWithPlantEditPermission = [
      ...readConfigWithoutPlantEditPermission,
      {
        type: EDIT_BTN,
        name: 'Edit Plant',
        iconClassName: 'icon-create',
        dataTest: 'plants-drilldown-edit',
      },
    ];

    const dropDownRegions = nameAndUrlItemToDropdownOptions(this.props.regions);
    const dropDownStates = STATE_LIST.map(itemsToLabelValue);

    // this configuration will be useful when editing
    const editConfig: any[] = [
      readConfigWithoutPlantEditPermission[0],
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.NAME,
        accessor: ADMIN_KEYS.NAME,
        label: ADMIN_LABELS.PLANT_NAME,
        dataTest: 'name-input-data-test',
        errorDataTest: 'name-input-missing-error',
        className: cx(styles.nameEdit, 'large'),
        isRequired: true,
        maxLength: 255,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.EXTERNAL_ID,
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        label: ADMIN_LABELS.EXTERNAL_ID,
        dataTest: 'externalId-input-data-test',
        errorDataTest: 'externalId-input-missing-error',
        className: cx(styles.externalId, 'large'),
        isRequired: false,
        maxLength: 50,
      },
      {
        type: COMPOSITE,
        className: styles.addressGroup,
        components: [
          {
            type: INPUT_FORM,
            key: ADMIN_KEYS.ADDRESS_LINE_ONE,
            accessor: ADMIN_KEYS.ADDRESS_LINE_ONE,
            label: ADMIN_LABELS.ADDRESS_LINE_ONE,
            dataTest: 'addressLineOne-input-data-test',
            errorDataTest: 'addressLineOne-input-missing-error',
            className: cx(styles.addressLineOne),
            isRequired: true,
            maxLength: 255,
          },
          {
            type: INPUT_FORM,
            key: ADMIN_KEYS.ADDRESS_LINE_TWO,
            accessor: ADMIN_KEYS.ADDRESS_LINE_TWO,
            label: ADMIN_LABELS.ADDRESS_LINE_TWO,
            dataTest: 'addressLineTwo-input-data-test',
            errorDataTest: 'addressLineTwo-input-missing-error',
            className: cx(styles.addressLineTwo),
            isRequired: false,
            maxLength: 255,
          },
          {
            type: INPUT_FORM,
            key: ADMIN_KEYS.CITY,
            label: ADMIN_LABELS.CITY,
            accessor: ADMIN_KEYS.CITY,
            dataTest: 'city-input-data-test',
            errorDataTest: 'city-input-missing-error',
            className: styles.city,
            isRequired: true,
            maxLength: 255,
          },
          {
            type: DROPDOWN_FORM,
            key: ADMIN_KEYS.STATE,
            label: ADMIN_LABELS.STATE,
            accessor: ADMIN_KEYS.STATE,
            className: styles.state,
            items: dropDownStates,
            initialSelected: dropDownStates[0],
            isRequired: true,
            dataTest: 'region-input-data-test',
          },
          {
            type: NUMERIC_INPUT_FORM,
            key: ADMIN_KEYS.ZIP_CODE,
            label: ADMIN_LABELS.ZIP_CODE,
            accessor: ADMIN_KEYS.ZIP_CODE,
            maxLength: 10,
            dataTest: 'zipCode-input-data-test',
            errorDataTest: 'zipCode-input-missing-error',
            className: styles.zip,
            isRequired: true,
          },
        ],
      },
      {
        type: DROPDOWN_FORM,
        className: cx(styles.regionDropdown, 'large'),
        label: ADMIN_LABELS.REGION,
        key: ADMIN_KEYS.REGION,
        accessor: ADMIN_KEYS.REGION,
        items: dropDownRegions,
        isRequired: true,
        dataTest: 'region-input-data-test',
      },
      {
        type: COMPOSITE,
        className: styles.location,
        components: [
          {
            type: TEXT_DISPLAY,
            label: ADMIN_LABELS.LATITUDE,
            accessor: ADMIN_KEYS.LATITUDE,
            className: styles.latitudeEditMode,
            key: 'latitude',
            dataTest: 'plants-drilldown-latitude',
          },
          {
            type: TEXT_DISPLAY,
            label: ADMIN_LABELS.LONGITUDE,
            accessor: ADMIN_KEYS.LONGITUDE,
            className: styles.longitudeEditMode,
            key: 'longitude',
            dataTest: 'plants-drilldown-longitude',
          },
        ],
      },
      {
        type: COMPOSITE,
        className: styles.mapContainer,
        key: ADMIN_KEYS.GEOZONES,
        customValidation: isEveryZoneValid,
        components: [{
          type: MAP_MULTIZONES_FORM,
          label: ADMIN_LABELS.GEOZONES,
          key: ADMIN_KEYS.GEOZONES,
          accessor: mapAccessorForPlantDetailGeozone,
          className: styles.mapEdit,
          dataTest: 'plants-drilldown-geofence',
          shouldAutoRefresh: false,
          isEditable: true,
          resetBtn: true,
          selectedZone: this.state.selectedZone,
          handleSelectedZoneChange: this.onSelectedZoneChange,
          errorMessage: this.props.errorMessage,
          containerStyle: {
            height: CARD_MAP_HEIGHT,
            maxWidth: '100%',
          },
        }],
      },
      {
        type: CUSTOM_COMPONENT,
        key: ADMIN_KEYS.PRODUCT_LINES,
        component: () => (
          this.productLines(styles.radioBtn, styles.radioBtnSelected, styles.radioLabel, selectedUom, backfillLoadedTimeDuration)
        ),
      },
    ];

    if (productLine !== ProductLine.ReadyMix) {
      editConfig.push({
        type: INPUT_FORM,
        key: ADMIN_KEYS.SCALE_HOST_ADDRESS,
        label: ADMIN_LABELS.SCALE_HOST_ADDRESS,
        accessor: (row: any) => accessValue(ADMIN_KEYS.SCALE_HOST_ADDRESS, row, null, ''),
        disabled: productLine === ProductLine.ReadyMix,
        placeholder: '0.0.0.0',
        dataTest: 'scale-host-address-input-data-test',
        errorDataTest: 'scale-host-address-input-missing-error',
        maxLength: 255
      });
    }

    const editConfig2nd = [
      {
        type: RADIO_FORM,
        accessor: ADMIN_KEYS.OWNERSHIP_TYPE,
        className: styles.ownershipTypeEdit,
        dataTest: 'plant-drilldown-ownershipType',
        key: ADMIN_KEYS.OWNERSHIP_TYPE,
        radioButtonGroupName: ADMIN_KEYS.OWNERSHIP_TYPE,
        label: ADMIN_LABELS.OWNERSHIP_TYPE,
        radioButtons: ownershipTypeRadioOptions,
        isRequired: true,
      },
      {
        type: CARD_SWITCH,
        label: ADMIN_LABELS.EDIT_WEIGHT_ALLOWED,
        accessor: ADMIN_KEYS.EDIT_TICKET_WEIGHTS,
        className: styles.editWeights,
        key: ADMIN_KEYS.EDIT_TICKET_WEIGHTS,
        display: ownershipType === EXTERNAL,
        checked: editableTicketWeights && productLine !== READYMIX_VALUE,
        editing: true,
        disabled: productLine === READYMIX_VALUE,
      },
    ];

    if (isGeoTraxLiteEnabled) {
      editConfig2nd.push({
        type: CARD_SWITCH,
        label: ADMIN_LABELS.GEOTRAX_LITE_PARTICIPANT,
        accessor: ADMIN_KEYS.GEOTRAX_LITE_PARTICIPANT,
        className: styles.editGtLiteParticipant,
        key: ADMIN_KEYS.GEOTRAX_LITE_PARTICIPANT,
        display: true,
        editing: true,
      } as any);
    }

    editConfig2nd.push(
      {
        type: SAVE_BTN,
      } as any
    );

    editConfig.push(...editConfig2nd);

    let configValue;
    if (this.props.plantsPermissionAccess === PermissionAccess.Edit) {
      if (this.state.edit) {
        configValue = editConfig;
      } else {
        configValue = readConfigWithPlantEditPermission;
      }
    } else {
      configValue = readConfigWithoutPlantEditPermission;
    }

    const pathname = this.getBreadcrumbPath();

    return (
      <AdminCard
        edit={this.state.edit}
        url={this.baseUrl()}
        options={{
          requiredKeys: [{
            keys: [ADMIN_KEYS.GEOZONES],
          }],
          callback: this.fetchPlantGeozone,
        }}
        className={cx(
          styles.wrapper,
          this.state.edit && styles.editWrapper
        )}
        onToggleEdit={this.toggleEdit}
        launchToEdit={this.state.launchToEdit}
        save={this.save}
        onChangeHandler={this.onChangeHandler}
        config={configValue}
        pathName={pathname}
        headerAccessor="name"
        externalKeys={[ADMIN_KEYS.PRODUCT_LINES]}
      >
        {this.viewOnlyItems()}
      </AdminCard>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentRegion: state.currentRegion,
  plantsPermissionAccess: state.adminPermissionAccess[PLANTS_TEXT],
  regions: state.assignedRegionList ?? [],
  userPermission: state.userPermission || {},
});

type Location = {
  pathname: string;
};

type Match = {
  params: { id: string };
};

export interface PlantDetailViewProps {
  createAdminTableData: typeof createDataTableRecord,
  updateAdminTableData: typeof updateDataTableRecord,
  addToOrUpdateListInStore: typeof addToOrUpdateListInStore,
  match: Match,
  closeModal: typeof closeModal,
  openModal: typeof openModal,
  regions: [{ url: string }],
  errorMessage: {},
  plantsPermissionAccess?: string,
  currentRegion: UrlKeyDto,
  location: Location
  userPermission: RegionAccessMap,
}

export default connect<any, any, any>(mapStateToProps, {
  addToOrUpdateListInStore,
  createAdminTableData: createDataTableRecord,
  updateAdminTableData: updateDataTableRecord,
  openModal,
  closeModal,
})(PlantDetailView);
