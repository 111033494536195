import { Dispatch } from 'redux';
import { UrlKeyDto, VoidFunc, ProductLine } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { openFailModal } from '../store/actions/errorModalActions';
import recordLog, { devErrorAndLog } from '../util/errorUtil';
import MessagingSocket from './sockets/messagingSocket';
import { getRequest, postRequest } from './requestService';
import { getIdFromUrl, noop } from '../util/appUtil';
import {
  EMPTY_ALL_FIELDS,
  OPEN_SNACKBAR,
  REMOVE_MESSAGE_FROM_REPLY_MAP,
  SET_CONVERSATION_LIST,
  UPDATE_MESSAGE,
} from '../constants/actionConstants';
import { ERROR_SEND_MESSAGE, ERROR_TEXT_FETCH_MESSAGE_LIST } from '../constants/errorConstants';
import { getMessagingBaseUrl } from '../util/apiUtil';
import { store } from '../Main';
import HTTP_CODES from '../constants/httpConstants';

export interface MessageDto {
  body: string
}

export interface AcknowledgeDto {
  id?: number,
  body?: string,
  partyMember?: UrlKeyDto,
  region?: UrlKeyDto,
  conversation: UrlKeyDto
}

export function sendMessage(
  message: MessageDto,
  optionalMessageId?: number | string,
  disableButtonCallback?: VoidFunc,
  selectedProductLine?: ProductLine
) {
  return async (dispatch: Dispatch) => {
    const url = getMessagingBaseUrl();
    // Trims message body after checking type to prevent errors
    const contents = message.body?.toString() ?? '';
    const body = {
      ...message,
      body: contents.replace(/\s+/g, ' ').trim(),
    };

    const params = { productLine: selectedProductLine };
    try {
      await postRequest(url, body, params);
      if (optionalMessageId) {
        dispatch({
          type: REMOVE_MESSAGE_FROM_REPLY_MAP,
          payload: optionalMessageId,
        });
      } else {
        dispatch({
          type: EMPTY_ALL_FIELDS,
        });
      }

      dispatch({
        type: OPEN_SNACKBAR,
        payload: 'Message sent',
        dataTest: 'created-message-data-test',
      });

      // Re-enables 'send' buttons
      if (disableButtonCallback) {
        disableButtonCallback();
      }
    } catch (e: any) {
      const title = e?.toString() ?? 'Unknown Messaging Error';
      dispatch(openFailModal(title, ERROR_SEND_MESSAGE));
      recordLog('error', ERROR_SEND_MESSAGE, {
        title,
        body,
        error: e,
      });

      // Re-enables 'send' buttons
      if (disableButtonCallback) {
        disableButtonCallback();
      }
    }
  };
}

export function sendAcknowledgement(ack: AcknowledgeDto) {
  return async (dispatch: Dispatch) => {
    // party member
    let partyMemberUrl = '';
    if (ack.partyMember) {
      partyMemberUrl = ack.partyMember.url ?? '';
    }
    // region url
    let regionUrl = '';
    if (ack.region) {
      regionUrl = ack.region.url ?? '';
    }
    // conversation id
    let convoId = -1;
    if (ack.conversation) {
      const len = ack.conversation.url?.split('/').length ?? 0;
      convoId = Number(ack.conversation.url?.split('/')[len - 1]);
    }
    // url
    const url = `${getMessagingBaseUrl()}/conversation/${convoId}/acknowledge`;

    const queryParams = {
      partyMember: partyMemberUrl,
      region: regionUrl,
    };

    try {
      await postRequest(url, {}, queryParams);
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_SEND_MESSAGE));
      recordLog('error', ERROR_SEND_MESSAGE, e.toString());
    }
  };
}

export function fetchMessageList(
  page?: number,
  messageList?: [],
  actionType?: string,
  currentRegion?: string,
  selectedProductLine?: string
) {
  return async (dispatch: Dispatch) => {
    const url = getMessagingBaseUrl();
    const params = {
      partyMember: currentRegion,
      page: page || 0,
      sort: 'lastUpdatedDate,asc',
      productLine: selectedProductLine,
      sentAfter: DateWrapper.now.subtractDays(10).toISODateString(),
    };
    try {
      const response = await getRequest(url, params);
      dispatch({
        type: SET_CONVERSATION_LIST,
        payload: (messageList || []).concat(response.data),
      });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(
        ERROR_TEXT_FETCH_MESSAGE_LIST,
        `messagesService url: ${url} + params: + ${JSON.stringify(params)}`,
        e.toString(),
        undefined,
        consoleOnly
      ) as any);
    }
  };
}

export function updateMessage(
  ack: AcknowledgeDto,
  isNotAcked?: boolean,
  isNotInitialLoad?: boolean,
  onSuccess?: (isSuccess: boolean) => void
) {
  return async (dispatch: Dispatch) => {
    const url = getMessagingBaseUrl();
    const params = {
      page: 0,
      sort: 'lastUpdatedDate,asc',
      conversation: ack.conversation.url,
    };

    if (isNotInitialLoad) {
      if (isNotAcked) {
        sendAcknowledgement(ack)(dispatch);
      }
      return;
    }

    try {
      const response = await getRequest(url, params);
      dispatch({
        type: UPDATE_MESSAGE,
        payload: response.data,
      });

      // set onSuccess callback
      if (onSuccess) {
        onSuccess(true);
      }

      // acknowledge message if isNotAcked is true
      if (isNotAcked) {
        sendAcknowledgement(ack)(dispatch);
      }
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_TEXT_FETCH_MESSAGE_LIST));
      recordLog('error', `${ERROR_TEXT_FETCH_MESSAGE_LIST} conversationurl:${ack.conversation.url}`, e);
    }
  };
}

export function markUnread(ack: AcknowledgeDto) {
  return async (dispatch: Dispatch) => {
    const partyMemberUrl = ack.partyMember?.url ?? '';
    const regionUrl = ack.region?.url ?? '';
    const convoId = getIdFromUrl(ack.conversation.url);
    const url = `${getMessagingBaseUrl()}/conversation/${convoId}/markUnread`;

    const queryParams = {
      partyMember: partyMemberUrl,
      region: regionUrl,
    };

    try {
      await postRequest(url, {}, queryParams);
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_SEND_MESSAGE));
    }
  };
}

export function getInboxMessages(
  page?: number,
  messageList?: [],
  currentRegion?: UrlKeyDto | string,
  selectedProductLine?: string
) {
  const url = (typeof currentRegion === 'string') ? currentRegion : currentRegion?.url;
  return fetchMessageList(page, messageList, '', url, selectedProductLine);
}

export function messageSocketDisconnect() {
  MessagingSocket.disconnect(true);
}

export function messageSocketInit(
  currentRegion: UrlKeyDto,
  currentSubscriptions?: { url?: string, unsubscribe: () => void }[]
) {
  const { userUrl: dispatcherId } = store.getState();

  messageSocketDisconnect();
  MessagingSocket.connect(
    dispatcherId ?? '',
    currentRegion,
    currentSubscriptions
  );
  return noop;
}
