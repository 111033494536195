import React, { Component } from 'react';
import cx from 'classnames';
import { Button } from '@trucktrax/trucktrax-common';
import styles from '../../geotrax/docs/guides/UserGuidesCommon.module.css';
import EditResourceModal from './EditResourceModal';
import {
  CONTENT_TYPE_PDF,
  CONTENT_TYPE_ZIP,
  DOWNLOAD_ALL_ZIP,
} from '../../../constants/appConstants';
import { STATIC_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { GUIDE_UPLOADING, PDF_EXTENSION, SUBMIT_GUIDE_TEXT } from '../../../constants/docsConstants';
import { REPLACE_GUIDE_SUCCESS } from '../../../constants/successConstants';
import ResourceTable, { ResourceInfo } from './ResourceTable';
import { downloadFile } from '../../../services/requestService';
import pdfIcon from '../../../assets/img/pdfIcon.svg';

export interface DocsTableState {
  isModalOpen: boolean;
  editFileName?: string;
  editFilepath?: string;
  isDownloadAllDisabled: boolean;
}

export class DocsTable extends Component<DocsTableProps, DocsTableState> {
  constructor(props: DocsTableProps) {
    super(props);
    this.state = {
      isModalOpen: false,
      editFileName: undefined,
      editFilepath: undefined,
      isDownloadAllDisabled: false,
    };
  }

  onDownload = async (name: string, filepath: string, openInBrowser: boolean) => {
    const url = `${getGeotraxBaseUrl() + STATIC_PATH}`;
    const params = { file: filepath };
    const filename = filepath.replace(this.props.folder, '');
    await downloadFile(url, params, filename, CONTENT_TYPE_PDF, openInBrowser);
  };

  downloadFolder = async () => {
    const url = `${getGeotraxBaseUrl() + STATIC_PATH}`;
    const params = { folder: this.props.folder };
    this.setState({ isDownloadAllDisabled: true });
    try {
      await downloadFile(url, params, 'userguides.zip', CONTENT_TYPE_ZIP, false);
    } finally {
      // whether success or failure, re-enable the button
      this.setState({ isDownloadAllDisabled: false });
    }
  };

  onEdit = (name: string, filepath: string) => {
    this.setState({
      isModalOpen: true,
      editFileName: name,
      editFilepath: filepath,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    return (
      <div>
        <h4 className={cx(styles.instructionDocuments, 'margin-top-0')}>Instruction Documents</h4>
        <ResourceTable
          name="Instruction Documents"
          resources={this.props.documents}
          onOpen={(name: string, filepath: string) => this.onDownload(name, filepath, true)}
          onEdit={this.onEdit}
          onDownload={(name: string, filepath: string) => this.onDownload(name, filepath, false)}
          iconSrc={pdfIcon}
        />
        <div className={styles.downloadAllZip}>
          <Button
            onClick={() => this.downloadFolder()}
            buttonClassName="tt-btn tt-btn--submit"
            iconClassName="icon-file-download"
            name={DOWNLOAD_ALL_ZIP}
            dataTest="download-all-docs-zip"
            disabled={this.state.isDownloadAllDisabled}
          />
        </div>
        <EditResourceModal
          title={`EDIT ${this.state.editFileName} DOC`}
          isOpen={this.state.isModalOpen}
          extension={PDF_EXTENSION}
          typeIcon="icon-drive-file"
          filepath={this.state.editFilepath}
          onCancel={this.closeModal}
          onComplete={this.closeModal}
          successText={REPLACE_GUIDE_SUCCESS}
          uploadingText={GUIDE_UPLOADING}
          submitText={SUBMIT_GUIDE_TEXT}
        />
      </div>
    );
  }
}

export interface DocsTableProps {
  documents: ResourceInfo[];
  folder: string;
}

export default DocsTable;
