import React, { Component } from 'react';
import cx from 'classnames';
import { HeaderAndContainer } from '@trucktrax/trucktrax-common';
import { Card, CardContent } from '@mui/material';
import ResourceTable from '../../../shared/docs/ResourceTable';
import styles from '../guides/UserGuidesCommon.module.css';
import AdminCardStyles from '../../../shared/admin/AdminCard.module.css';
import Header from '../../../shared/Header';
import { getGeotraxBaseUrl } from '../../../../util/apiUtil';
import { STATIC_PATH } from '../../../../constants/apiConstants';
import { downloadFile, getRequest } from '../../../../services/requestService';
import { CONTENT_TYPE_MP4 } from '../../../../constants/appConstants';
import { videoFilepathToName } from '../../../../util/doctUtil';
import { MP4_EXTENSION, SUBMIT_VIDEO_TEXT, VIDEO_UPLOADING } from '../../../../constants/docsConstants';
import EditResourceModal from '../../../shared/docs/EditResourceModal';
import { REPLACE_VIDEO_SUCCESS } from '../../../../constants/successConstants';
import btnPlaySvg from '../../../../assets/img/btnPlay.svg';

export class VideoListView extends Component<VideoListViewProps, VideoListViewState> {
  constructor(props: VideoListViewProps) {
    super(props);

    this.state = {
      resources: [],
      isModalOpen: false,
      editFilepath: undefined,
    };
  }

  async componentDidMount() {
    await this.getVideoList();
  }

  getVideoList = async () => {
    const listUrl = `${getGeotraxBaseUrl() + STATIC_PATH}/docs`;
    const results = await getRequest(listUrl, { file: this.props.folder });
    const resources = results?.data?.filter((resource: typeof MP4_EXTENSION) => resource.includes(MP4_EXTENSION))
      .map((filepath: string) => ({
        name: videoFilepathToName(filepath, this.props.folder + this.props.prefix),
        filepath,
      }));
    this.setState({ resources });
  };

  onOpen = (name: string, filepath: string) => {
    this.props.history.push(`${this.props.path}/video?filepath=${filepath}`);
  };

  onEdit = (name: string, filepath: string) => {
    this.setState({
      isModalOpen: true,
      editFilepath: filepath,
    });
  };

  onDownload = async (name: string, filepath: string) => {
    const url = `${getGeotraxBaseUrl() + STATIC_PATH}`;
    const params = { file: filepath };
    const filename = filepath.replace(this.props.folder, '');
    await downloadFile(url, params, filename, CONTENT_TYPE_MP4, false);
  };

  onCancel = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  onSuccess = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    const elementsLeft = <Header title="Training Videos" />;

    const containerComponent = (
      <div className={AdminCardStyles.bodyWrapper}>
        <div className={AdminCardStyles.cardWrapper}>
          <Card className={styles.cardStyle}>
            <CardContent>
              <div className={cx(AdminCardStyles.cardText, styles.videosList)}>
                <h2 className={cx(styles.instructionDocuments, 'margin-top-0')}>{this.props.title}</h2>
                <ResourceTable
                  name=""
                  resources={this.state.resources}
                  onOpen={this.onOpen}
                  onEdit={this.onEdit}
                  onDownload={this.onDownload}
                  iconSrc={btnPlaySvg}
                />
                <EditResourceModal
                  title="EDIT VIDEO SOURCE"
                  isOpen={this.state.isModalOpen}
                  extension={MP4_EXTENSION}
                  typeIcon="icon-video"
                  filepath={this.state.editFilepath}
                  onCancel={this.onCancel}
                  onComplete={this.onSuccess}
                  successText={REPLACE_VIDEO_SUCCESS}
                  uploadingText={VIDEO_UPLOADING}
                  submitText={SUBMIT_VIDEO_TEXT}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );

    return (
      <HeaderAndContainer
        elementsLeft={elementsLeft}
        containerComponent={containerComponent}
      />
    );
  }
}

export interface History {
  push: (history: string) => void;
}

export interface VideoListViewProps {
  title: string,
  folder: string,
  history: History,
  path: string,
  prefix: string
}

export interface VideoListViewState {
  resources: any[],
  isModalOpen: boolean,
  editFilepath: undefined | string
}

export default VideoListView;
