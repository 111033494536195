import { PayloadAction } from '../../types';
import { SOCKET_SUBSCRIPTION } from '../../constants/actionConstants';

interface Unsubscribable {
  unsubscribe: () => void;
}
// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const messageSubscriptionReducer = (
  state = [] as Unsubscribable[],
  action: PayloadAction<Unsubscribable[], 'SOCKET_SUBSCRIPTION'>
) => {
  switch (action.type) {
    case SOCKET_SUBSCRIPTION:
      return [...action.payload];
    default:
      return state;
  }
};
