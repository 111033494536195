import React, { Component } from 'react';
import { FolderDropdown } from '@trucktrax/trucktrax-common';

export default class SideNavFolderDropdown extends Component<SideNavFolderDropdownProps, SideNavFolderDropdownState> {
  constructor(props: SideNavFolderDropdownProps) {
    super(props);
    this.state = {
      isOpen: props.isOpen ?? false,
    };
  }

  switchOpen = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    return (
      <FolderDropdown
        key={this.props.name}
        dataTestName={this.props.name}
        icon={this.props.icon}
        docsMode
        count={0}
        isOpen={this.state.isOpen}
        name={this.props.name}
        onClick={this.switchOpen}
      >
        {this.props.children}
      </FolderDropdown>
    );
  }
}

export interface SideNavFolderDropdownProps {
  name: string,
  icon?: string,
  isOpen?: boolean,
  children: JSX.Element
}

export interface SideNavFolderDropdownState {
  isOpen: boolean
}
