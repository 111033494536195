import jwtDecode from 'jwt-decode';
import { Dispatch } from 'redux';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { AuthTokenPayload } from '../types';

import {
  AUTH_TENANT_NAME,
  IS_PASSWORD_TEMPORARY,
  REFRESH_TOKEN,
  TENANT_ID,
  TOKEN,
  USER_FULL_NAME,
} from '../constants/localStorageConstants';
import {
  setIsPasswordTemporary,
  setMinutesToIdleLogout,
  setRefreshToken,
  setSessionId,
  setTenantId,
  setToken,
  setUserId,
  setUsername,
  setUserUrl,
  setUserFullName,
} from '../store/actions/authActions';
import { setDefaultRegionList } from '../store/actions/regionActions';
import {
  setPrimaryProductLine,
  setSecondaryProductLine,
  setSecondaryProductLines,
} from '../store/actions/productLineActions';

export function getTokens() {
  return {
    accessToken: localStorage.getItem(TOKEN),
    refreshToken: localStorage.getItem(REFRESH_TOKEN),
    isPasswordTemporary: localStorage.getItem(IS_PASSWORD_TEMPORARY),
    tenantUrl: localStorage.getItem(TENANT_ID),
    userFullName: localStorage.getItem(USER_FULL_NAME),
  };
}

export function setTokens(dispatch: Dispatch, data: AuthTokenPayload, sessionId: string) {
  dispatch(setToken(data.access_token));
  dispatch(setRefreshToken(data.refresh_token));
  dispatch(setIsPasswordTemporary(data.isPasswordTemporary));
  dispatch(setUserId(data.id));
  dispatch(setUsername(data.user));
  dispatch(setTenantId(data.tenantId));
  dispatch(setUserUrl(data.url));
  dispatch(setMinutesToIdleLogout(data.expires_in / 60));
  dispatch(setUserFullName(data.userFullName));

  const defaultRegion = data.assignedRegions.filter(region => region.defaultRegion);
  dispatch(setDefaultRegionList(defaultRegion));

  if (sessionId) {
    dispatch(setSessionId(sessionId));
  }
  dispatch(setPrimaryProductLine(data.primaryProductLine));
  dispatch(setSecondaryProductLine(data.secondaryProductLine));
  dispatch(setSecondaryProductLines(data.secondaryProductLines));
}

export function deleteTokens(tenantName: string) {
  // Grab values that we want to keep after clear
  // eslint-disable-next-line prefer-const
  let keptLocalStorage: Record<string, string> = {};
  ['previouslyUsedScales'].forEach((key) => {
    const value = localStorage[key];
    if (value !== undefined) {
      keptLocalStorage[key] = value;
    }
  });
  // don't clear the tenant name
  localStorage.clear();
  localStorage.setItem(AUTH_TENANT_NAME, tenantName);
  // Replace those grabbed values
  Object.keys(keptLocalStorage).forEach((key) => {
    localStorage.setItem(key, keptLocalStorage[key]);
  });
}

export function isExpired(token: string) {
  try {
    const { exp } = jwtDecode(token);
    const now = DateWrapper.now.date.getTime();
    return now > (exp * 1000);
  } catch (e) {
    return true;
  }
}
