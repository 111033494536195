/* eslint-disable import/prefer-default-export */

import { ProductLine } from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import {
  SET_PRIMARY_PRODUCT_LINE,
  SET_SECONDARY_PRODUCT_LINE,
  SET_SECONDARY_PRODUCT_LINES,
  SET_SELECTED_PRODUCT_LINE,
  SET_ROUTE_HISTORY_PRODUCT_LINES,
  CLEAN_ROUTE_HISTORY_PRODUCT_LINES,
} from '../../constants/actionConstants';
import {
  PRIMARY_PRODUCT_LINE, SECONDARY_PRODUCT_LINE, SECONDARY_PRODUCT_LINES, SELECTED_PRODUCT_LINE,
} from '../../constants/appConstants';

const localSelectedProductLine = localStorage.getItem(SELECTED_PRODUCT_LINE) as ProductLine;
const localPrimaryProductLine = localStorage.getItem(PRIMARY_PRODUCT_LINE) as ProductLine;
const localSecondaryProductLine = localStorage.getItem(SECONDARY_PRODUCT_LINE) as ProductLine;
const localSecondaryProductLines = localStorage.getItem(SECONDARY_PRODUCT_LINES) as string;

export const selectedProductLine = (
  state = localSelectedProductLine || localPrimaryProductLine,
  action: PayloadAction<ProductLine, 'SET_SELECTED_PRODUCT_LINE'>
) => {
  if (action.type === SET_SELECTED_PRODUCT_LINE) {
    localStorage.setItem(SELECTED_PRODUCT_LINE, action.payload);
    return action.payload;
  }
  return state;
};

export const primaryProductLine = (
  state = localPrimaryProductLine,
  action: PayloadAction<ProductLine, 'SET_PRIMARY_PRODUCT_LINE'>
) => {
  if (action.type === SET_PRIMARY_PRODUCT_LINE) {
    localStorage.setItem(PRIMARY_PRODUCT_LINE, action.payload);
    return action.payload;
  }
  return state;
};

export const secondaryProductLine = (
  state = localSecondaryProductLine,
  action: PayloadAction<ProductLine, 'SET_SECONDARY_PRODUCT_LINE'>
) => {
  if (action.type === SET_SECONDARY_PRODUCT_LINE) {
    localStorage.setItem(SECONDARY_PRODUCT_LINE, action.payload);
    return action.payload;
  }
  return state;
};

export const secondaryProductLines = (
  state = localSecondaryProductLines,
  action: PayloadAction<ProductLine, 'SET_SECONDARY_PRODUCT_LINES'>
) => {
  if (action.type === SET_SECONDARY_PRODUCT_LINES) {
    localStorage.setItem(SECONDARY_PRODUCT_LINES, action.payload);
    return action.payload;
  }
  return state;
};

export const routeHistoryProductLines = (
  state: string[] = [],
  action: PayloadAction<string[], 'SET_ROUTE_HISTORY_PRODUCT_LINES' | 'CLEAN_ROUTE_HISTORY_PRODUCT_LINES'>
) => {
  switch (action.type) {
    case SET_ROUTE_HISTORY_PRODUCT_LINES:
      return action.payload;
    case CLEAN_ROUTE_HISTORY_PRODUCT_LINES:
      return [];
    default:
      return state;
  }
};
