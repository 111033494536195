import React from 'react';
import cx from 'classnames';
import { ProductLine } from '@trucktrax/trucktrax-ts-common';
import styles from './PlantsAddModal.module.css';
import {
  editableWeightsRadioOptions,
  getAddressPartOne,
  getAddressPartTwo,
  getExternalId,
  getOwnershipType,
  getRegionUrlToNameMap,
  itemsToLabelValue,
  productlineLanguageReturnGeozone, mapAccessorForAddPlantSummary,
  mapAccessor,
  nameAndUrlItemToDropdownOptions,
  ownershipTypeRadioOptions,
  geozoneBoundaryRadioOptions,
  getUrl,
  getAddress,
  getUom,
  getScaleHostAddress,
  getLoadingTime,
  gtLiteParticipantRadioOptions,
} from '../../../util/adminUtil';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  CARD_SWITCH,
  CUSTOM_COMPONENT,
  DROPDOWN_FORM,
  INPUT_FORM,
  MAP_FORM,
  MAP_MULTIZONES_FORM,
  MAP_LOCATION,
  RADIO_FORM,
  STATE_LIST,
  SHOW_YES,
  SHOW_NO,
  STEP_ONE_PLANT_MODAL_TITLE,
  STEP_TWO_PLANT_MODAL_TITLE,
  STEP_THREE_PLANT_MODAL_TITLE,
  STEP_FOUR_PLANT_MODAL_TITLE,
  STEP_FIVE_PLANT_MODAL_TITLE,
  PLANT_ADD_MODAL_ORDER,
  TEXT_DISPLAY,
  READYMIX_VALUE,
  GEOZONE_TYPE,
  EXTERNAL,
  NUMERIC_INPUT_FORM,
  PLEASE_CREATE_GEOZONE,
} from '../../../constants/appConstants';
import { HasAddress } from '../../../types';
import { checkNullInRequired, geoZoneIsValid } from '../../../util/validation';

export type GeoZoneInfo = {
  circle?: {
    center: {
      latitude: number;
      longitude: number;
    };
    radius: number;
  };
  polygon?: {
    points: {
      latitude: number;
      longitude: number;
    }[];
  }
};

export interface PlantFormDto extends HasAddress {
  geozones: {
    plant: any
  }
}

const titleModalOne = () => (
  <div className={styles.fullWidth}>
    <h3
      className={cx(
        styles.headerText,
        'txt-bold',
        'margin-top-0',
        'margin-bottom-10'
      )}
    >
      {STEP_ONE_PLANT_MODAL_TITLE}
    </h3>
  </div>
);

const modalOne = (
  regions: any,
  editableTicketWeightsAccessor: any,
  gtLiteParticipantAccessor: boolean,
  defaultRegionList: any,
  productLine: any
) => {
  const dropDownRegions = nameAndUrlItemToDropdownOptions(regions); // this.props.regions
  const defaultRegion = dropDownRegions.find((item: any) => item.value.url === defaultRegionList[0].url);

  const dropDownStates = STATE_LIST.map(itemsToLabelValue);

  const editConfig: any[] = [{
    type: CUSTOM_COMPONENT,
    key: 'title 1',
    component: titleModalOne,
  },
  {
    type: INPUT_FORM,
    key: ADMIN_KEYS.EXTERNAL_ID,
    label: ADMIN_LABELS.EXTERNAL_ID,
    dataTest: 'externalId-input-data-test',
    errordataTest: 'externalId-input-missing-error',
    className: styles.mediumWidth,
    isRequired: false,
    maxLength: 50,
  },
  {
    type: INPUT_FORM,
    key: ADMIN_KEYS.NAME,
    label: ADMIN_LABELS.PLANT_NAME,
    dataTest: 'name-input-data-test',
    errorDataTest: 'name-input-missing-error',
    className: styles.fullWidth,
    customValidation: checkNullInRequired,
    isRequired: true,
    maxLength: 255,
  }, {
    type: DROPDOWN_FORM,
    key: ADMIN_KEYS.REGION,
    label: ADMIN_LABELS.REGION,
    className: styles.dropdownWidth,
    initialSelected: defaultRegion,
    items: dropDownRegions,
    customValidation: checkNullInRequired,
    isRequired: true,
    dataTest: 'region-input-data-test',
  }, {
    type: INPUT_FORM,
    key: ADMIN_KEYS.ADDRESS_LINE_ONE,
    label: ADMIN_LABELS.ADDRESS_LINE_ONE,
    dataTest: 'addressLineOne-input-data-test',
    errorDataTest: 'addressLineOne-input-missing-error',
    className: styles.fullWidth,
    customValidation: checkNullInRequired,
    isRequired: true,
    maxLength: 255,
  }, {
    type: INPUT_FORM,
    key: ADMIN_KEYS.ADDRESS_LINE_TWO,
    label: ADMIN_LABELS.ADDRESS_LINE_TWO,
    dataTest: 'addressLineTwo-input-data-test',
    errorDataTest: 'addressLineTwo-input-missing-error',
    className: styles.fullWidth,
    isRequired: false,
    maxLength: 255,
  }, {
    type: INPUT_FORM,
    key: ADMIN_KEYS.CITY,
    label: ADMIN_LABELS.CITY,
    dataTest: 'city-input-data-test',
    errorDataTest: 'city-input-missing-error',
    className: styles.city,
    customValidation: checkNullInRequired,
    isRequired: true,
    maxLength: 255,
  }, {
    type: DROPDOWN_FORM,
    key: ADMIN_KEYS.STATE,
    label: ADMIN_LABELS.STATE,
    className: styles.state,
    items: dropDownStates,
    initialSelected: dropDownStates[0],
    isRequired: true,
    dataTest: 'region-input-data-test',
  }, {
    type: NUMERIC_INPUT_FORM,
    key: ADMIN_KEYS.ZIP_CODE,
    label: ADMIN_LABELS.ZIP_CODE,
    maxLength: 10,
    dataTest: 'zipCode-input-data-test',
    errorDataTest: 'zipCode-input-missing-error',
    className: styles.zip,
    customValidation: checkNullInRequired,
    isRequired: true,
  },
  ];

  if (productLine !== ProductLine.ReadyMix) {
    editConfig.push({
      type: INPUT_FORM,
      key: ADMIN_KEYS.SCALE_HOST_ADDRESS,
      label: ADMIN_LABELS.SCALE_HOST_ADDRESS,
      className: styles.mediumWidth,
      placeholder: '0.0.0.0',
      dataTest: 'scale-host-address-input-data-test',
      errorDataTest: 'scale-host-address-input-missing-error',
      maxLength: 255
    });
  }

  const editConfig2nd = [
    {
      type: RADIO_FORM,
      key: ADMIN_KEYS.OWNERSHIP_TYPE,
      label: ADMIN_LABELS.OWNERSHIP_TYPE,
      radioButtonGroupName: ADMIN_KEYS.OWNERSHIP_TYPE,
      className: styles.ownershipTypeM1,
      radioButtons: ownershipTypeRadioOptions,
      isRequired: true,
      initialSelected: ownershipTypeRadioOptions[0],
    },
    {
      type: CARD_SWITCH,
      key: ADMIN_KEYS.EDIT_TICKET_WEIGHTS,
      accessor: editableTicketWeightsAccessor,
      initialSelected: editableWeightsRadioOptions[0],
    },
    {
      type: CARD_SWITCH,
      key: ADMIN_KEYS.GEOTRAX_LITE_PARTICIPANT,
      accessor: gtLiteParticipantAccessor,
      initialSelected: gtLiteParticipantRadioOptions[0],
    },
    {
      // Sets initial state for location
      type: MAP_LOCATION,
      key: 'location',
      initialSelected: {
        value: {
          latitude: 0,
          longitude: 0,
        },
      },
    },
    {
      // Sets initial state for geozones
      type: MAP_LOCATION,
      key: 'geozones',
      initialSelected: {
        value: {
          plant: {
            location: {
              latitude: 0,
              longitude: 0,
            },
            circle: null,
            polygon: null,
          },
          inqueue: {
            location: {
              latitude: 0,
              longitude: 0,
            },
            circle: null,
            polygon: null,
          },
          return: {
            location: {
              latitude: 0,
              longitude: 0,
            },
            circle: null,
            polygon: null,
          },
        },
      },
    },
    {
      // Sets initial state for inqueue geozone same as plant's
      type: RADIO_FORM,
      key: ADMIN_KEYS.INQUEUE_SAMEAS_PLANT,
      initialSelected: { value: geozoneBoundaryRadioOptions[0].value },
      display: false,
    },
    {
      // Sets initial state for return geozone same as plant's
      type: RADIO_FORM,
      key: ADMIN_KEYS.RETURN_SAMEAS_PLANT,
      initialSelected: { value: geozoneBoundaryRadioOptions[0].value },
      display: false,
    },
  ];
  editConfig.push(...editConfig2nd);
  return editConfig;
};

const titleModalTwo = (productLine: string) => (
  <div className={styles.geozoneTitle}>
    <h3 className={cx(styles.headerText, 'txt-bold')}>
      {STEP_TWO_PLANT_MODAL_TITLE}
    </h3>
    <p className="margin-bottom-20">
      Draw a geofence around the plant using the tools in the upper right corner of the map.
    </p>
    <p>
      When a vehicle exits this geozone, it will set the driver&#39;s ticket status to&nbsp;
      <span className={cx('txt-bold')}>
        {productLine === 'Cement' ? 'To Plant' : 'To Job'}
        {' '}
        &#40;&nbsp;
        <i
          className={cx(
            'icon-forward',
            styles.inlineStatusIcon,
            styles.statusIconArrow,
            styles.statusIconToJob
          )}
        />
        &nbsp;&#41;
      </span>
      .
    </p>
  </div>
);

const modalTwo = (productLine: any) => [
  {
    type: CUSTOM_COMPONENT,
    key: GEOZONE_TYPE.PLANT,
    component: () => titleModalTwo(productLine),
  },
  {
    type: MAP_FORM,
    key: 'geozones.plant', // path to the property in AdminAddModal's state.value
    containerStyle: {
      height: '400px',
      width: '100%',
      position: 'relative',
    },
    isEditable: true,
    resetBtn: true,
    accessor: (x: PlantFormDto) => ({
      ...mapAccessor(x.geozones.plant),
      address: getAddress(x),
      geofenceAreaType: GEOZONE_TYPE.PLANT,
    }),
    className: styles.map,
    showGeoZone: true,
    showMarker: true,
    isRequired: true,
    customValidation: validatePlantGeozone,
  },
];

// `matchPlantGeozone` is always false bc this IS the plant geozone
export const validatePlantGeozone = (value: GeoZoneInfo) => validateCustomGeozone(value, false);

const titleModalThree = (productLine: any) => (
  <div className={styles.geozoneTitle}>
    <h3 className={cx(styles.headerText, 'txt-bold')}>
      {STEP_THREE_PLANT_MODAL_TITLE}
    </h3>
    <p>
      Would you like to customize the boundaries for the In Queue geozone?
      When a vehicle enters this geozone without a ticket, it will set the vehicle status to&nbsp;
      <span className={cx('txt-bold')}>
        {productLine === READYMIX_VALUE ? 'Ready to Load' : 'In Queue'}
        {' '}
        &#40;
        <i
          className={cx(
            'icon-dot',
            styles.inlineStatusIcon,
            styles.statusIconDot,
            styles.statusIconInQueue
          )}
        />
        &#41;
      </span>
      .
    </p>
  </div>
);

const modalThree = (productLine: any, matchPlantGeozone: any) => [
  {
    type: CUSTOM_COMPONENT,
    key: GEOZONE_TYPE.INQUEUE,
    component: () => titleModalThree(productLine),
  },
  {
    type: RADIO_FORM,
    key: ADMIN_KEYS.INQUEUE_SAMEAS_PLANT,
    radioButtonGroupName: ADMIN_KEYS.INQUEUE_SAMEAS_PLANT,
    className: styles.geozoneBoundariesOptions,
    radioButtons: geozoneBoundaryRadioOptions,
    isRequired: false,
    initialSelected: null,
  },
  {
    type: MAP_FORM,
    key: 'geozones.inqueue', // path to the property in AdminAddModal's state.value
    containerStyle: {
      height: '400px',
      width: '100%',
      position: 'relative',
    },
    isEditable: !matchPlantGeozone,
    resetBtn: true,
    accessor: (x: HasAddress & { geozones: { [x: string]: any; }; }) => ({
      ...mapAccessor(x.geozones[GEOZONE_TYPE.INQUEUE]),
      // address: getAddress(x),
      geofenceAreaType: GEOZONE_TYPE.INQUEUE,
    }),
    className: styles.geozoneMap,
    showGeoZone: true,
    showMarker: false,
    customValidation: (zone: GeoZoneInfo) => validateCustomGeozone(zone, matchPlantGeozone),
  },
];

const titleModalFour = (productLine: any) => (
  <div className={styles.geozoneTitle}>
    <h3 className={cx(styles.headerText, 'txt-bold')}>
      {STEP_FOUR_PLANT_MODAL_TITLE}
    </h3>
    <p>
      Would you like to customize the boundaries for the Return geozone?
      When a vehicle enters this geozone, it will set the driver&#39;s ticket status to&nbsp;
      <span className={cx('txt-bold')}>
        {productlineLanguageReturnGeozone(productLine)}
        {' '}
        &#40;
        <i className={cx(
          'icon-dot',
          styles.inlineStatusIcon,
          styles.statusIconDot,
          styles.statusIconReturn
        )}
        />
        &#41;
      </span>
      .
    </p>
  </div>
);

const modalFour = (productLine: any, matchPlantGeozone: any) => [
  {
    type: CUSTOM_COMPONENT,
    key: GEOZONE_TYPE.RETURN,
    component: () => titleModalFour(productLine),
  },
  {
    type: RADIO_FORM,
    key: ADMIN_KEYS.RETURN_SAMEAS_PLANT,
    radioButtonGroupName: ADMIN_KEYS.RETURN_SAMEAS_PLANT,
    className: styles.geozoneBoundariesOptions,
    radioButtons: geozoneBoundaryRadioOptions,
    isRequired: false,
    initialSelected: null,
  },
  {
    type: MAP_FORM,
    key: 'geozones.return', // path to the property in AdminAddModal's state.value
    containerStyle: {
      height: '400px',
      width: '100%',
      position: 'relative',
    },
    isEditable: !matchPlantGeozone,
    resetBtn: true,
    accessor: (x: { geozones: { [x: string]: any; }; }) => ({
      ...mapAccessor(x.geozones[GEOZONE_TYPE.RETURN]),
      // address: getAddress(x),
      geofenceAreaType: GEOZONE_TYPE.RETURN,
    }),
    className: styles.geozoneMap,
    showGeoZone: true,
    showMarker: false,
    customValidation: (zone: GeoZoneInfo) => validateCustomGeozone(zone, matchPlantGeozone),
  },
];

const titleModalFive = () => (
  <div className={styles.geozoneTitle}>
    <h3 className={cx(styles.headerText, styles.reviewTitleText, 'txt-bold')}>
      {STEP_FIVE_PLANT_MODAL_TITLE}
    </h3>
  </div>
);

const geozoneIndex = () => (
  <div className={cx(styles.geozonesIndex, 'margin-top-20')}>
    <span className={cx(styles.text, 'margin-bottom-5')}>
      <i
        className={cx(
          'icon-forward',
          styles.listedStatusIcon,
          styles.statusIconArrow,
          styles.statusIconToJob
        )}
      />
      {PLANT_ADD_MODAL_ORDER[1]}
      &nbsp;
      Geozone
    </span>
    <span className={cx(styles.text, 'margin-bottom-5')}>
      <i
        className={cx(
          'icon-dot',
          styles.listedStatusIcon,
          styles.statusIconDot,
          styles.statusIconInQueue
        )}
      />
      {PLANT_ADD_MODAL_ORDER[2]}
      &nbsp;
      Geozone
    </span>
    <span className={cx(styles.text, 'margin-bottom-5')}>
      <i className={cx(
        'icon-dot',
        styles.listedStatusIcon,
        styles.statusIconDot,
        styles.statusIconReturn
      )}
      />
      {PLANT_ADD_MODAL_ORDER[3]}
      &nbsp;
      Geozone
    </span>
  </div>
);

const modalFive = (regions: any, editableTicketWeightsAccessor: any, gtLiteParticipant: string, productLine: any) => {
  const defaultConfig = [
    {
      type: CUSTOM_COMPONENT,
      key: 'plantReview',
      component: titleModalFive,
    },
    {
      type: MAP_MULTIZONES_FORM,
      label: ADMIN_LABELS.GEOZONES,
      key: ADMIN_LABELS.GEOZONES,
      isEditable: false,
      resetBtn: false,
      accessor: mapAccessorForAddPlantSummary,
      className: styles.mapSummary,
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.EXTERNAL_ID,
      key: ADMIN_LABELS.EXTERNAL_ID,
      accessor: (item: any) => `${getExternalId(item)}`,
      className: styles.externalId,
      initialSelected: null,
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.PLANT_NAME,
      key: ADMIN_LABELS.PLANT_NAME,
      accessor: ADMIN_KEYS.NAME,
      className: styles.name,
      initialSelected: null,
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.ADDRESS,
      key: ADMIN_LABELS.ADDRESS,
      accessor: (item: any) => `${getAddressPartOne(item)}\n${getAddressPartTwo(item)}`,
      className: styles.address,
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.REGION,
      key: ADMIN_KEYS.REGION,
      accessor: (item: any) => getRegionUrlToNameMap(regions)[getUrl(item)!],
      className: styles.region,
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.LATITUDE,
      key: ADMIN_KEYS.LATITUDE,
      accessor: ADMIN_KEYS.LATITUDE,
      className: styles.latitude,
      initialSelected: null,
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.LONGITUDE,
      key: ADMIN_KEYS.LONGITUDE,
      accessor: ADMIN_KEYS.LONGITUDE,
      className: styles.longitude,
      initialSelected: null,
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.PRODUCT_LINE,
      key: ADMIN_KEYS.PRODUCT_LINE,
      accessor: ADMIN_KEYS.PRODUCT_LINE,
      className: styles.productLinesM2,
      dataTest: 'users-drilldown-product',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.DEFAULT_SCALE_UNIT,
      key: ADMIN_KEYS.DEFAULT_SCALE_UNIT,
      accessor: (item: any) => `${getUom(item)}`,
      className: styles.uomM2,
      dataTest: 'users-drilldown-uom',
      display: productLine !== ProductLine.ReadyMix,
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.GEOTRAX_LITE_PARTICIPANT,
      key: ADMIN_KEYS.GEOTRAX_LITE_PARTICIPANT,
      accessor: () => gtLiteParticipant,
      className: styles.gtLiteParticipantM2,
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.OWNERSHIP_TYPE,
      key: ADMIN_KEYS.OWNERSHIP_TYPE,
      accessor: getOwnershipType,
      className: styles.ownershipTypeM2,
      dataTest: 'users-drilldown-ownershipType',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.LOADED_TIME_LABEL,
      key: ADMIN_KEYS.LOADED_TIME,
      accessor: (item: any) => `${getLoadingTime(item)}`,
      className: styles.LoadTimeIndex,
      dataTest: 'users-drilldown-ltime',
      display: productLine === ProductLine.Aggregates,
    },
    {
      type: CUSTOM_COMPONENT,
      key: 'geozonesIconIndex',
      component: geozoneIndex,
    },
  ];

  if (productLine !== READYMIX_VALUE) {
    defaultConfig.push({
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.SCALE_HOST_ADDRESS,
      key: ADMIN_KEYS.SCALE_HOST_ADDRESS,
      accessor: (row: any) => getScaleHostAddress(row),
      className: styles.scaleHostAddressM2,
      dataTest: 'users-drilldown-scale-host-address',
    });
  }

  if (editableTicketWeightsAccessor) {
    defaultConfig.push({
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.EDIT_WEIGHT_ALLOWED,
      key: ADMIN_KEYS.EDIT_WEIGHT_ALLOWED,
      accessor: () => editableTicketWeightsAccessor,
      className: styles.editWeightsRead,
      dataTest: 'users-drilldown-editweights',
    });
  }
  return defaultConfig;
};

export const validateCustomGeozone = (value: GeoZoneInfo, matchPlantGeozone: boolean) => {
  const zone = { zone: { ...value } };
  return (
    (!matchPlantGeozone && !geoZoneIsValid(zone))
      ? PLEASE_CREATE_GEOZONE
      : null
  );
};

const modalHandler = (
  position: any,
  regions: any,
  plantDetails: any,
  editableTicketWeightsAccessor: any,
  gtLiteParticipantAccessor: boolean,
  defaultRegionList: any
) => {
  switch (position) {
    case 1:
      return modalOne(regions, editableTicketWeightsAccessor, gtLiteParticipantAccessor, defaultRegionList, plantDetails.productLine);
    case 2:
      return modalTwo(plantDetails.productLine);
    case 3:
      return modalThree(plantDetails.productLine, plantDetails[ADMIN_KEYS.INQUEUE_SAMEAS_PLANT] === 'true');
    case 4:
      return modalFour(plantDetails.productLine, plantDetails[ADMIN_KEYS.RETURN_SAMEAS_PLANT] === 'true');
    case 5: {
      let editableWeight = null;
      if (plantDetails.ownershipType === EXTERNAL) {
        editableWeight = editableTicketWeightsAccessor ? SHOW_YES : SHOW_NO;
      }
      const gtLiteParticipant = gtLiteParticipantAccessor ? SHOW_YES : SHOW_NO;
      return modalFive(regions, editableWeight, gtLiteParticipant, plantDetails.productLine);
    }
    default:
      return modalOne(regions, editableTicketWeightsAccessor, gtLiteParticipantAccessor, defaultRegionList, plantDetails.productLine);
  }
};

export default modalHandler;
