import React, { Component } from 'react';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import { LoadingCircle } from '../LoadingCircle';
import styles from './TicketList.module.css';

export class RefreshCurrentTicketList extends Component<RefreshCurrentTicketListProps, RefreshCurrentTicketListState> {
  constructor(props: RefreshCurrentTicketListProps) {
    super(props);
    this.state = {
      isFetching: false,
    };
  }

  onRefreshList = async () => {
    this.setState({ isFetching: true });
    await this.props.onClick();
    this.setState({ isFetching: false });
  };

  render() {
    return (
      <div className={styles.refreshCurrentTicketList}>
        <button
          onClick={this.onRefreshList}
        >
          {this.state.isFetching
            ? (
              <span className={styles.loadingCircleContainer}>
                <LoadingCircle size={17} />
              </span>
            ) : <i className="icon-refresh" />}

          <span className={this.state.isFetching ? styles.loadingText : styles.boldText}>Refresh list</span>
        </button>
      </div>
    );
  }
}

export interface RefreshCurrentTicketListProps {
  onClick: VoidFunc;
}

export interface RefreshCurrentTicketListState {
  isFetching: boolean;
}
