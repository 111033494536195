import React, { Component } from 'react';
import { parseISO, format } from 'date-fns';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { REPORTS_TEXT } from '../../../constants/navConstants';
import DataTableContent from '../../shared/DataTableContent';
import {
  addDataTest,
  addHeaderDataTest,
  formatNumberWithCommas,
} from '../../../util/adminUtil';
import { ADMIN_KEYS, ADMIN_LABELS } from '../../../constants/appConstants';
import { DATASOURCE, REPORTS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import DateRangePicker, { DateRangePickerState } from '../../shared/DateRangePicker';
import styles from './ReportsView.module.css';

export interface ReportsViewProps { }
export interface ReportsViewState {
  filterDateStart: Date;
  filterDateEnd: Date;
}

export class ReportsView extends Component<ReportsViewProps, ReportsViewState> {
  constructor(props: ReportsViewProps) {
    super(props);

    const startOfToday = DateWrapper.now.startOfDay;

    this.state = {
      filterDateEnd: startOfToday.date,
      filterDateStart: startOfToday.previousDay.date,
    };
  }

  onDateRangeChange = (drpState: DateRangePickerState) => {
    const { startDate, endDate } = drpState;

    if (startDate && endDate) {
      this.setState(
        {
          filterDateStart: startDate,
          filterDateEnd: endDate,
        }
      );
    }
  };

  static getFormattedDate = (isoDateStr?: string) => {
    const validIsoDateString = '2024-01-01T00:00:00.000Z'; // placeholder value to prevent errors, will not be shown in the UI
    return format(parseISO(isoDateStr || validIsoDateString), 'MM/dd/yyyy HH:mm');
  };

  static getColumns() {
    return [
      {
        Header: addHeaderDataTest(null, 'sort-truck-by-name', ADMIN_LABELS.TRUCK_ALIAS),
        id: ADMIN_KEYS.TRUCK_ALIAS,
        accessor: ADMIN_KEYS.TRUCK_ALIAS,
        Cell: (props: any) => addDataTest(props, 'admin-reports-vehicle-name', props.value),
      },
      {
        Header: addHeaderDataTest(null, 'sort-truck-by-timestamp', ADMIN_LABELS.TIMESTAMP),
        id: ADMIN_KEYS.TIMESTAMP,
        accessor: ADMIN_KEYS.TIMESTAMP,
        Cell: (props: any) => addDataTest(props, 'admin-reports-timestamp', ReportsView.getFormattedDate(props.value)),
      },
      {
        Header: addHeaderDataTest(null, 'sort-truck-by-engine-hours', ADMIN_LABELS.ENGINE_HOURS),
        id: ADMIN_KEYS.ENGINE_HOURS,
        accessor: ADMIN_KEYS.ENGINE_HOURS,
        Cell: (props: any) => addDataTest(props, 'admin-reports-engine-hours', formatNumberWithCommas(props.value)),
      },
      {
        Header: addHeaderDataTest(null, 'sort-truck-by-odometer', ADMIN_LABELS.ODOMETER),
        id: ADMIN_KEYS.ODOMETER,
        accessor: ADMIN_KEYS.ODOMETER,
        Cell: (props: any) => addDataTest(props, 'admin-reports-odometer', formatNumberWithCommas(props.value)),
      },
      {
        Header: addHeaderDataTest(null, 'sort-truck-by-total-fuel', ADMIN_LABELS.TOTAL_FUEL),
        id: ADMIN_KEYS.TOTAL_FUEL,
        accessor: ADMIN_KEYS.TOTAL_FUEL,
        Cell: (props: any) => addDataTest(props, 'admin-reports-total-fuel', formatNumberWithCommas(props.value)),
      },
    ];
  }

  getQueryParams = () => {
    const { filterDateStart, filterDateEnd } = this.state;
    return {
      timestampBefore: new DateWrapper(filterDateEnd).endOfDay.toString(),
      timestampAfter: new DateWrapper(filterDateStart).startOfDay.toString(),
    };
  };

  render() {
    const { filterDateStart, filterDateEnd } = this.state;
    const baseUrl = getGeotraxBaseUrl() + REPORTS_PATH;
    const columns = ReportsView.getColumns();

    const reportsViewHeader = (
      <div className={styles.filters}>
        <div className={styles.dateRangePickerWrapper}>
          <DateRangePicker
            startDate={filterDateStart}
            endDate={filterDateEnd}
            shouldDisableFuture
            onChange={this.onDateRangeChange}
            minDate="1920-01-01"
            maxDate={DateWrapper.now.startOfDay.toString()}
          />
        </div>
      </div>
    );

    return (
      <DataTableContent
        baseUrl={baseUrl}
        columns={columns}
        header={REPORTS_TEXT}
        byPassRedisCache
        renderTabs={reportsViewHeader}
        queryParams={this.getQueryParams()}
        enableExport
        exportedFileName="vehicle_reports"
        source={DATASOURCE.REPORT}
      />
    );
  }
}

export default ReportsView;
