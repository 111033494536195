import React from 'react';

/**
 * render Tab Panel content
 * @param {*} props props for tab panel
 */
export default function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
  const {
    children,
    value = 0,
    index = 0,
  } = props;

  return (
    <div>
      {value === index && children}
    </div>
  );
}

export interface TabPanelProps {
  value: number;
  index: number;
}
