import config from 'react-global-configuration';
import { UnitOfMeasure } from '@trucktrax/trucktrax-ts-common';
import { DISPLAY_ENV_KEY, ENVIRONMENTS_LIST } from '../constants/appConstants';
import { TruckTraxApps, AppPath } from '../types';
import logoAdminHorizontalSvg from '../assets/img/logos-admin-horizontal.svg';
import logoAdminVerticalSvg from '../assets/img/logos-admin-vertical.svg';
import logoGeotraxHorizontalSvg from '../assets/img/logos-geo-trax-horizontal.svg';
import logoGeotraxVerticalSvg from '../assets/img/logos-geo-trax-vertical.svg';
import logoScaletraxHorizontalSvg from '../assets/img/logos-scale-trax-horizontal.svg';
import logoScaletraxVerticalSvg from '../assets/img/logos-scale-trax-vertical.svg';

export default function appVersionAndEnvString() {
  const envKey = config.get(DISPLAY_ENV_KEY).toString().toUpperCase();
  if (ENVIRONMENTS_LIST.find((env) => env === envKey)) {
    if (envKey === 'PROD') {
      return process.env.REACT_APP_VERSION;
    }
    return `${process.env.REACT_APP_VERSION} [${envKey}]`;
  }
  return `${process.env.REACT_APP_VERSION} [UNK]`;
}

export function hasProp(object: any, property: string) {
  return Object.prototype.hasOwnProperty.call(object, property);
}

export function noop() { }

export const sleep = (ms: number) => new Promise(resolve => { setTimeout(resolve, ms); });

export function getSourceIdFromUrl(url?: string) {
  if (!url) {
    return null;
  }

  const strArr = url ? url.split('/') : url;
  return `/${strArr[strArr.length - 2]}/${strArr[strArr.length - 1]}`;
}

export function getLastOfUrlPath(url?: string) {
  return url ? url.split('/').pop() : url;
}

export function getIdFromUrl(url?: string) {
  if (!url) {
    return '';
  }

  const urlToArray = url ? url.split('/') : url;
  return urlToArray[urlToArray.length - 1];
}

export function setFavicon(path: string) {
  const favicon = document.getElementById('favicon');
  // eslint-disable-next-line no-unused-expressions
  favicon?.setAttribute('href', path);
}

export const getLastWord = (sentence: string) => {
  if (sentence && sentence.length > 0) {
    const words = sentence.split(' ');
    return words.pop();
  }
  return '';
};

export const getTwoDecimalStr = (num: string | number) => (
  num
    ? Number.parseFloat(num.toString()).toFixed(2)
    : null
);

export const getIntStr = (num: string | number | undefined) => (
  (num !== undefined && num !== null)
    ? `${Number.parseInt(num.toString(), 10)}`
    : null
);

export const ouncesToGallons = (value: number) => (value / 128);

export const toFixedIfNecessary = (value: number, decimalPlaces: number = 2) => {
  if (Math.round(value) === value) {
    return value.toFixed(0);
  }

  return value.toFixed(decimalPlaces);
};

export const formatValue = (value?: number, unit?: UnitOfMeasure | number | any) => {
  if (value === null || value === undefined) {
    return '';
  }

  const str = toFixedIfNecessary(value, 3);
  const int = str.split('.')[0];
  let decimal = str.split('.')[1];

  if (unit === UnitOfMeasure.POUNDS || unit === UnitOfMeasure.KILOGRAMS) {
    return int;
  }

  if (str.indexOf('.') < 0) { return `${str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.00`; }

  while (decimal.endsWith('0')) {
    decimal = decimal.substring(0, decimal.length - 1);
  }

  if (decimal) {
    return `${int.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${decimal}`;
  }

  return int;
};

export const apps: AppPath[] = [
  {
    type: TruckTraxApps.GeoTrax,
    name: TruckTraxApps[TruckTraxApps.GeoTrax],
    horizontalIcon: logoGeotraxHorizontalSvg,
    verticalIcon: logoGeotraxVerticalSvg,
    path: '/geotrax',
  },
  {
    type: TruckTraxApps.ScaleTrax,
    name: TruckTraxApps[TruckTraxApps.ScaleTrax],
    horizontalIcon: logoScaletraxHorizontalSvg,
    verticalIcon: logoScaletraxVerticalSvg,
    path: '/scaletrax',
  },
  {
    type: TruckTraxApps.Admin,
    name: TruckTraxApps[TruckTraxApps.Admin],
    horizontalIcon: logoAdminHorizontalSvg,
    verticalIcon: logoAdminVerticalSvg,
    path: '/admin',
  },
];

export function getUnitOfMeasureLabel(uom?: UnitOfMeasure) {
  if (uom === UnitOfMeasure.POUNDS || uom?.toString() === UnitOfMeasure[UnitOfMeasure.POUNDS]) {
    return 'LBS';
  }
  if (uom === UnitOfMeasure.KILOGRAMS || uom?.toString() === UnitOfMeasure[UnitOfMeasure.KILOGRAMS]) {
    return 'KGS';
  }
  if (uom === UnitOfMeasure.METRIC_TONS || uom?.toString() === UnitOfMeasure[UnitOfMeasure.METRIC_TONS]) {
    return 'MTS';
  }

  return 'TNS';
}

export function isEmptyObject(obj: object): boolean {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
