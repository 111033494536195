import { RegionDto, UserRegionDto } from '@trucktrax/trucktrax-ts-common';
import { replaceOrUpdateListById } from '../../util/adminUtil';
import {
  ADD_REGION, REMOVE_USER_OVERRIDE,
  SET_ASSIGNED_REGION_LIST,
  SET_CURRENT_REGION,
  SET_DEFAULT_REGION as SET_DEFAULT_REGION_LIST,
  SET_REGION_LIST,
  SET_SELECTED_REGION, SET_USER_OVERRIDE,
  UPDATE_REGION,
} from '../../constants/actionConstants';
import { CURRENT_REGION } from '../../constants/appConstants';
import { DEFAULT_REGION, ASSIGNED_REGIONS, USER_OVERRIDE } from '../../constants/localStorageConstants';
import recordLog from '../../util/errorUtil';
import { PayloadAction } from '../../types';

const localStorageRegion = JSON.parse(localStorage.getItem(CURRENT_REGION) ?? 'null');

// state is a region item object
export const currentRegion = (
  state = localStorageRegion,
  action: PayloadAction<UserRegionDto, 'SET_CURRENT_REGION'>
): UserRegionDto => {
  switch (action.type) {
    case SET_CURRENT_REGION:
      localStorage.setItem(CURRENT_REGION, JSON.stringify(action.payload));
      return { ...action.payload };
    default:
      return state;
  }
};

let localStorageAssignedRegions: UserRegionDto[] = [];
try {
  localStorageAssignedRegions = JSON.parse(localStorage.getItem(ASSIGNED_REGIONS) ?? '[]');
} catch (error) {
  // eslint-disable-next-line no-console
  console.error('Error parsing assigned regions from local storage', error);
  recordLog('Assigned Regions', 'regionsReducer', error);
}

export const assignedRegionList = (
  state = localStorageAssignedRegions || [] as UserRegionDto[],
  action: PayloadAction<UserRegionDto[], 'SET_ASSIGNED_REGION_LIST'>
) => {
  switch (action.type) {
    case SET_ASSIGNED_REGION_LIST:
      localStorage.setItem(ASSIGNED_REGIONS, JSON.stringify(action.payload));
      return action.payload;
    default:
      return state;
  }
};

const initialRegionListState: RegionDto[] = [];
export const regionList = (
  state = initialRegionListState,
  action: PayloadAction<RegionDto | RegionDto[], 'SET_REGION_LIST' | 'ADD_REGION' | 'UPDATE_REGION'>
): RegionDto[] => {
  switch (action.type) {
    case SET_REGION_LIST:
      return action.payload as RegionDto[];
    case ADD_REGION:
      return [...state, action.payload as RegionDto];
    case UPDATE_REGION: {
      replaceOrUpdateListById(action.payload as RegionDto, state);
      return state;
    }
    default:
      return state;
  }
};

const localStorageDefaultRegion = JSON.parse(localStorage.getItem(DEFAULT_REGION) ?? '[]');

// state is a region item object
export const defaultRegionList = (
  state = localStorageDefaultRegion,
  action: PayloadAction<RegionDto[], 'SET_DEFAULT_REGION'>
) => {
  switch (action.type) {
    case SET_DEFAULT_REGION_LIST:
      localStorage.setItem(DEFAULT_REGION, JSON.stringify(action.payload));
      return action.payload;
    default:
      return state;
  }
};

// Selected region for Admin permission proposes
export const selectedRegion = (
  state: RegionDto = {
    id: 0,
    deleted: false,
    archived: false,
  },
  action: PayloadAction<RegionDto, 'SET_SELECTED_REGION'>
): RegionDto => {
  if (action.type === SET_SELECTED_REGION) {
    return action.payload as RegionDto;
  }
  return state;
};

const localStorageUserOverride = localStorage.getItem(USER_OVERRIDE);
const localStorageUserOverrideObject = localStorageUserOverride ? JSON.parse(localStorageUserOverride) : null;

export const userOverrides = (
  state: UserOverride = localStorageUserOverrideObject,
  action: PayloadAction<{ lat: number; lng: number; zoom: number }, typeof SET_USER_OVERRIDE | typeof REMOVE_USER_OVERRIDE>
): UserOverride => {
  switch (action.type) {
    case SET_USER_OVERRIDE:
      localStorage.setItem(USER_OVERRIDE, JSON.stringify(action.payload));
      return action.payload;
    case REMOVE_USER_OVERRIDE:
      localStorage.removeItem(USER_OVERRIDE);
      return null;
    default:
      return state;
  }
};

export const selectUserOverride = (state: any) => state.userOverrides;

export type UserOverride = { lat: number; lng: number; zoom: number; } | null;
