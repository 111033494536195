import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DiscardProgressCard, FloatingActionButton, HeaderAndContainer,
} from '@trucktrax/trucktrax-common';
import { Prompt } from 'react-router-dom';
import cx from 'classnames';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Card, CardContent } from '@mui/material';
import Header from '../../../shared/Header';
import AdminCardStyles from '../../../shared/admin/AdminCard.module.css';
import CardImage from '../../../shared/forms/CardImage';
import styles from '../guides/UserGuidesCommon.module.css';
import { downloadBlobFromUrl } from '../../../../util/reportsUtil';
import { userHasEditPermissionInAnyRegion } from '../../../../services/permissionsService';
import fetchFileList from '../../../../services/staticResourceService';
import ReleaseDocAddModal from './ReleaseDocAddModal';
import { noop } from '../../../../util/appUtil';
import {
  ADD_NEW_VERSION_DOC,
  CONTENT_TYPE_PDF,
  DISCARD_PROMPT_TEXT,
  USER_DOCUMENTS,
} from '../../../../constants/appConstants';
import { STATIC_PATH } from '../../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../../util/apiUtil';
import { UserPermissionsByRegion } from '../../../../types';
import AddFeatureModal from '../../../shared/AddFeatureModal';
import pdfIconSvg from '../../../../assets/img/pdfIcon.svg';
import trashCanSvg from '../../../../assets/img/trash-illus.svg';

export class ReleaseDoc extends Component<ReleaseDocProps, ReleaseDocState> {
  constructor(props: ReleaseDocProps) {
    super(props);
    const { token: accessToken } = this.props;

    this.state = {
      docsBaseUrl: `${getGeotraxBaseUrl() + STATIC_PATH}?tk=${accessToken}`,
      hasEditPermission: userHasEditPermissionInAnyRegion(props.userPermission, USER_DOCUMENTS),
      isCancelModalOpen: false,
      isAddModalOpen: false,
    };
  }

  async componentDidMount() {
    await this.props.fetchFileList({ file: this.props.folder });
    this.setState({
      hasEditPermission: userHasEditPermissionInAnyRegion(this.props.userPermission, USER_DOCUMENTS),
    });
  }

  static downloadFile = async (url: string, name: string, openOnly: boolean, contentType: string) => {
    const response = await fetch(url, { headers: { Accept: contentType } });
    const blob = await response.blob();
    downloadBlobFromUrl(blob, name, openOnly);
  };

  openPdf = async (name: string, file: string, openOnly: boolean) => {
    const url = `${this.state.docsBaseUrl}&file=${file}`;
    if (openOnly) {
      await ReleaseDoc.downloadFile(url, name, true, CONTENT_TYPE_PDF);
    } else {
      await ReleaseDoc.downloadFile(
        url,
        file.substring(file.lastIndexOf('/') + 1),
        false,
        CONTENT_TYPE_PDF
      );
    }
  };

  openAddModal = () => {
    this.setState({
      isAddModalOpen: true,
    });
  };

  toggleCancelModal = (isOpen: boolean) => {
    this.setState({
      isCancelModalOpen: isOpen,
    });
  };

  closeModalOnSuccess = async () => {
    this.toggleCancelModal(false);
    this.setState({
      isAddModalOpen: false,
    });
    await this.props.fetchFileList({ file: this.props.folder });
  };

  closeModalOnDiscard = () => {
    this.toggleCancelModal(false);
    this.setState({
      isAddModalOpen: false,
    });
  };

  render() {
    const elementsLeft = <Header title="Releases" />;

    const sortVersions = (arr: any[]) => arr.sort((a: any, b: any) => {
      const trimFolderName = (x: string) => x.replace(this.props.folder, '');
      const aDigits = trimFolderName(a).split('.');
      const bDigits = trimFolderName(b).split('.');
      const sortNumerically = (x: any, y: any) => (
        y.localeCompare(x, 'en', { numeric: true, sensitivity: 'base' })
      );
      return (
        sortNumerically(aDigits[0], bDigits[0])
        || sortNumerically(aDigits[1], bDigits[1])
        // Last set of digits should be sorted as a string rather than integer value
        // Example: .20, .150, .10
        || bDigits[2].localeCompare(aDigits[2])
      );
    });

    return (
      <div>
        <div>
          <HeaderAndContainer elementsLeft={elementsLeft} />
        </div>
        <div className={AdminCardStyles.bodyWrapper}>
          <div className={AdminCardStyles.cardWrapper}>
            <Card className={styles.cardStyle}>
              <CardContent>
                <div className={AdminCardStyles.cardText}>
                  <h2 className={styles.header}>Release Notes</h2>
                  <div>
                    <div className={styles.tableContainer}>
                      <Table>
                        <TableBody>
                          {
                            sortVersions(this.props.releasenotes).map((d: any) => (
                              <TableRow
                                key={`idx-${d}`}
                                hover
                              >
                                <TableCell className={styles.openPdfButton}>
                                  <button onClick={() => this.openPdf(d, d, true)}>
                                    <CardImage src={pdfIconSvg} className={styles.pdfLogo} />
                                    <span className={styles.itemNameCtr}>
                                      <span className={styles.itemName}>
                                        {d.replace(this.props.folder, '').replace('.pdf', '')}
                                      </span>
                                      <i className={cx('icon-new-tab', 'margin-left-5')} />
                                    </span>
                                  </button>
                                </TableCell>
                                <TableCell className={styles.downloadButton}>
                                  <button onClick={() => this.openPdf(d, d, false)}>
                                    <i className="icon-file-download" />
                                    <span>Download</span>
                                  </button>
                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          {
            this.state.hasEditPermission
            && (<FloatingActionButton onClick={() => this.openAddModal()} />
            )
          }
        </div>
        <AddFeatureModal
          title={ADD_NEW_VERSION_DOC}
          isOpen={this.state.isAddModalOpen}
          onCancel={() => this.toggleCancelModal(true)}
          style={styles.reportsModal}
        >
          <ReleaseDocAddModal
            onCancel={() => this.toggleCancelModal(true)}
            onSubmit={this.closeModalOnSuccess}
            folder={this.props.folder}
          />
        </AddFeatureModal>
        <AddFeatureModal
          title=""
          isOpen={this.state.isCancelModalOpen}
          onCancel={noop}
        >
          <DiscardProgressCard
            discardAcceptAction={this.closeModalOnDiscard}
            discardRejectAction={() => this.toggleCancelModal(false)}
            imageSrc={trashCanSvg}
            altText="Trash"
          />
        </AddFeatureModal>
        <Prompt
          when={this.state.isCancelModalOpen}
          message={DISCARD_PROMPT_TEXT}
        />
      </div>
    );
  }
}

export interface ReleaseDocProps {
  userPermission: UserPermissionsByRegion,
  fetchFileList: (file: {}) => void,
  releasenotes: string[],
  token: string,
  folder: string
}

export interface ReleaseDocState {
  docsBaseUrl: string,
  hasEditPermission: boolean,
  isCancelModalOpen: boolean,
  isAddModalOpen: boolean,
}

function mapStateToProps(state: any) {
  return {
    userPermission: state.userPermission,
    releasenotes: state.releasenotes,
    token: state.token,
  };
}

export default connect(mapStateToProps, { fetchFileList })(ReleaseDoc);
