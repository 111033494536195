import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import Popover from '@mui/material/Popover';
import {
  Button, Label, Tooltip,
} from '@trucktrax/trucktrax-common';
import {
  AssignedPermissionDto,
  GroupDto,
  PermissionAccess,
  PermissionDto,
  RegionDto,
} from '@trucktrax/trucktrax-ts-common';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AxiosResponse } from 'axios';
import AdminCard from '../../../shared/admin/AdminCard';
import PermissionTable from '../../../shared/admin/PermissionsTable';
import {
  ASSIGNED_PERMISSION_PATH,
  fetchAssignedPermissionList,
  fetchPermissionList,
  GROUPS_PATH,
  handleUserPermissionUpdate,
  NewAssignedPermissionDto,
  userHasEditPermission,
  userHasEditPermissionInAnyRegion,
  userHasViewPermission,
} from '../../../../services/permissionsService';
import { getRequest } from '../../../../services/requestService';
import { updateDataTableRecord } from '../../../../services/dataTableService';
import { createAssignedPermissionDto, mapUserPermissionListByRegionByGroup, RegionAccessMap } from '../../../../util/permissionUtil';
import { noop } from '../../../../util/appUtil';
import sortRegionsAlpha from '../../../../util/regionUtil';
import { devErrorAndLog } from '../../../../util/errorUtil';
import styles from './GroupPermissionsView.module.css';
import TabPanel from '../../../shared/TabPanel';
import SecurityPermissionsAdminMembers from './Members';
import {
  ADV_SECURITY_PERMS,
  PERMISSION_NAMES,
} from '../../../../constants/appConstants';
import { getSecurityBaseUrl } from '../../../../util/apiUtil';
import { SUCCESSFULLY_MODIFIED, SUCCESSFULLY_UPDATED } from '../../../../constants/successConstants';
import { ADMIN_UPDATE_ERROR } from '../../../../constants/errorConstants';
import { NOT_FOUND_PATH } from '../../../../constants/apiConstants';
import { ReduxState } from '../../../../store';
import { ConnectedDispatchFunction } from '../../../../types';
import { setSelectedRegion } from '../../../../store/actions/regionActions';
import HTTP_CODES from '../../../../constants/httpConstants';

export interface GroupPermissionsViewState {
  anchorEl: any;
  isPending: boolean;
  editName: string;
  permissionTableData: any[];
  defaultGroupData: Partial<GroupDto>;
  regionValue: number;
  isPermissionEditable: boolean;
  hasAdvancedAccess: boolean;
}

export class GroupPermissionsView extends Component<GroupPermissionsViewProps, GroupPermissionsViewState> {
  nameRef?: HTMLParagraphElement;

  constructor(props: GroupPermissionsViewProps) {
    super(props);

    this.state = {
      anchorEl: null,
      isPending: false,
      editName: '',
      permissionTableData: [],
      defaultGroupData: {},
      regionValue: 0,
      isPermissionEditable: false,
      hasAdvancedAccess: false,
    };
  }

  get hasUserPermission() {
    const { userPermission } = this.props;
    const hasUserPermission = userPermission && Object.keys(userPermission).length > 0;
    return hasUserPermission;
  }

  async componentDidMount() {
    try {
      this.setState({
        hasAdvancedAccess: userHasEditPermissionInAnyRegion(this.props.userPermission, ADV_SECURITY_PERMS),
      });
      await this.fetchGroupRecord();
      await this.props.fetchPermissionList(this.props.currentRegion.url);

      this.setInitialSelectedRegion();
      await this.fetchAssignedPermissionListByGroupByRegion();
      this.setPermissionGroupData(this.props.permissionList, this.props.assignedPermissionList);
    } catch (e) {
      this.onError(e);
    }
  }

  componentDidUpdate(prevProps: GroupPermissionsViewProps, prevState: GroupPermissionsViewState) {
    const { permissionList, assignedPermissionList, regionList } = this.props;
    const { userPermission, currentRegion } = this.props;

    if (!this.hasUserPermission) {
      return;
    }

    if (JSON.stringify(prevProps.userPermission) !== JSON.stringify(userPermission)) {
      this.setState({
        hasAdvancedAccess: userHasEditPermissionInAnyRegion(this.props.userPermission, ADV_SECURITY_PERMS),
      });
    }

    if (currentRegion && currentRegion.url) {
      const hasEditPermission = userHasEditPermission(userPermission, currentRegion.url, PERMISSION_NAMES.SECURITY_PERMISSIONS);
      const hasViewPermission = userHasViewPermission(userPermission, currentRegion.url, PERMISSION_NAMES.SECURITY_PERMISSIONS);

      // handle when user set security permission in selected region to Deny.
      if (!hasEditPermission && !hasViewPermission && !this.state.hasAdvancedAccess) {
        this.props.history.push(NOT_FOUND_PATH);
      }
    }

    if (regionList.length > 0
      && permissionList.length > 0) {
      if (
        prevProps.permissionList !== permissionList
        || prevProps.assignedPermissionList !== assignedPermissionList) {
        this.setPermissionGroupData(permissionList, assignedPermissionList);
      }
    }
    if (this.state.regionValue !== null
      && this.state.regionValue !== undefined
      && prevState.regionValue !== this.state.regionValue) {
      this.fetchAssignedPermissionListByGroupByRegion();
    }
  }

  componentWillUnmount() {
    // clean selected region
    this.props.setSelectedRegion({
      id: 0,
      deleted: false,
      archived: false,
    });
  }

  fetchAssignedPermissionListByGroupByRegion = async () => {
    this.setState({ isPending: true });
    const group = this.state.defaultGroupData;
    const region = this.props.regionList[this.state.regionValue];

    if (group && region) {
      await this.props.fetchAssignedPermissionList(
        {
          group: group.url,
          regionUrl: region.url,
        },
        true
      );
    }
    this.setState({ isPending: false });
  };

  static hasRegionPermissions = (userPermission: RegionAccessMap, regionUrl: string) => {
    const hasEditPermission = userHasEditPermission(userPermission, regionUrl, PERMISSION_NAMES.SECURITY_PERMISSIONS);
    const hasViewPermission = userHasViewPermission(userPermission, regionUrl, PERMISSION_NAMES.SECURITY_PERMISSIONS);

    if (hasEditPermission || hasViewPermission) {
      return true;
    }
    return false;
  };

  setInitialSelectedRegion = () => {
    const { regionList, userPermission, selectedRegion } = this.props;
    if (!this.hasUserPermission) {
      return;
    }

    let firstViewableRegionUrl: string | null = null;
    type Permission = {
      access: PermissionAccess;
      permissionUrl?: string;
    };
    let regionSecurityPermission: null | Permission = null;
    if (selectedRegion.id !== 0) {
      firstViewableRegionUrl = selectedRegion.url as string;
      if (GroupPermissionsView.hasRegionPermissions(userPermission, firstViewableRegionUrl)) {
        regionSecurityPermission = userPermission[firstViewableRegionUrl][PERMISSION_NAMES.SECURITY_PERMISSIONS];
      }
      const regionValue = regionList.findIndex(r => r.url === firstViewableRegionUrl);
      const isPermissionEditable = this.state.hasAdvancedAccess
        || ((regionSecurityPermission! as Permission).access === PermissionAccess.Edit);
      this.setState({
        regionValue,
        isPermissionEditable,
      });
      return;
    }

    if (regionList && regionList.length > 0) {
      Object.keys(userPermission).forEach(regionUrl => {
        if (!firstViewableRegionUrl) {
          if (GroupPermissionsView.hasRegionPermissions(userPermission, regionUrl)) {
            regionSecurityPermission = userPermission[regionUrl][PERMISSION_NAMES.SECURITY_PERMISSIONS];
            firstViewableRegionUrl = regionUrl;
            return true;
          }
        }
        return false;
      });

      const regionValue = this.state.hasAdvancedAccess
        ? 0 : regionList.findIndex(r => r.url === firstViewableRegionUrl);
      const isPermissionEditable = this.state.hasAdvancedAccess
        || ((regionSecurityPermission! as Permission).access === PermissionAccess.Edit);
      this.setState({
        regionValue,
        isPermissionEditable,
      });
    }
  };

  onError = (e: any) => {
    const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
    this.props.devErrorAndLog('Unable to retrieve record', `GroupPermissionsView: ${e.toString()}`, undefined, undefined, consoleOnly);
  };

  fetchGroupRecord = async () => {
    try {
      this.setState({ isPending: true });
      const response = await getRequest(this.groupsBaseUrl(), { region: this.props.currentRegion.url });
      this.setState({
        defaultGroupData: response.data,
        editName: response.data.name,
        isPending: false,
      });
    } catch (e) {
      this.onError(e);
    }
  };

  getNameRef = (ref: HTMLParagraphElement | null) => {
    if (!ref) {
      return;
    }

    this.nameRef = ref;
  };

  generateTabs = (regions: RegionDto[]) => {
    let generatedTabs: JSX.Element[] = [];

    if (regions.length > 0) {
      // alphabetize region names
      const regionTabs = sortRegionsAlpha(regions);
      const { assignedRegionList, userPermission } = this.props;
      const assignedRegionUrlList = new Set();

      Object.keys(userPermission).forEach(regionUrl => {
        if (!assignedRegionList.some(ar => ar.url === regionUrl)) {
          return;
        }

        if (GroupPermissionsView.hasRegionPermissions(userPermission, regionUrl)
          || this.state.hasAdvancedAccess) {
          assignedRegionUrlList.add(regionUrl);
        }
      });

      generatedTabs = regionTabs.map((region) => {
        const isDisabled = !this.state.hasAdvancedAccess && !assignedRegionUrlList.has(region.url);

        if (isDisabled) {
          return (
            <Tab
              key={region.name}
              classes={
                {
                  root: styles.btnContainerDisabled,
                }
              }
              className={styles.disabledClickIcon}
              disabled
              label={(
                <Tooltip
                  key={region.name}
                  text="You do not have access to edit this region"
                  placement="top"
                  enterDelay={600}
                  classes={{
                    popper: styles.lockPopper,
                    tooltip: styles.lockTooltip,
                  }}
                >
                  <span
                    className={
                      styles.disabledRegionName
                    }
                  >
                    {region.name}
                  </span>
                </Tooltip>
              )}

            />
          );
        }

        return (
          <Tab
            key={region.name}
            label={region.name}
            classes={
              {
                root: styles.btnContainer,
              }
            }
          />
        );
      });
    }
    return (generatedTabs);
  };

  generateTabContents = (selectedTab: number, regions: RegionDto[]) => {
    const selectedRegion = regions[selectedTab] || null;
    const memberSectionTab = selectedRegion && (
      <TabPanel value={selectedTab} index={selectedTab} key={`idx=${selectedRegion.name}`}>
        <SecurityPermissionsAdminMembers group={this.state.defaultGroupData as GroupDto} region={selectedRegion} />
      </TabPanel>
    );

    return memberSectionTab;
  };

  // Name input component
  handleEditNameOpen = () => {
    this.setState({ anchorEl: this.nameRef });
  };

  handleEditNameClose = () => {
    this.setState({
      anchorEl: null,
      editName: this.state.defaultGroupData.name ?? '',
    });
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ regionValue: newValue });
  };

  onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      editName: e.target.value,
    });
  };

  onGroupUpdateSuccess = (updatedDto: AxiosResponse<GroupDto>) => {
    this.setState({
      defaultGroupData: updatedDto.data,
      isPending: false,
    });

    if (this.state.anchorEl !== null) {
      this.handleEditNameClose();
    }
  };

  onAssignedPermissionUpdateSuccess = (newAssignedPermissions: AssignedPermissionDto[], updatedItem: NewAssignedPermissionDto) => {
    const defaultGroupData: Partial<GroupDto> = {
      ...this.state.defaultGroupData,
      assignedPermissions: newAssignedPermissions,
    };

    const { regionList, assignedRegionList } = this.props;
    const selectedRegion = regionList[this.state.regionValue];
    const isUserAMember = defaultGroupData.members?.find(u => u.member!.url === this.props.userUrl);
    const isAssignedRegion = assignedRegionList.find(r => r.url === selectedRegion.url);

    this.setState({
      defaultGroupData,
      isPending: false,
    });

    if (isUserAMember && isAssignedRegion) {
      this.props.handleUserPermissionUpdate(selectedRegion, updatedItem);
    }
  };

  onSave = (newData: Partial<GroupDto>) => {
    const groupDto = {
      ...this.state.defaultGroupData,
      ...newData,
      // Remove assigned permissions,
      // as we are only updating the name of the group.
      // And permissions are ignored by the backend.
      assignedPermissions: [],
    };

    const { updateAdminTableData: put } = this.props;

    const toastMessages = {
      success: `Permission group name ${SUCCESSFULLY_MODIFIED}`,
      fail: ADMIN_UPDATE_ERROR,
    };

    put(
      this.groupsBaseUrl(),
      groupDto,
      this.onGroupUpdateSuccess,
      this.onError,
      false,
      undefined,
      toastMessages
    );
  };

  groupsBaseUrl = () => `${getSecurityBaseUrl()}${GROUPS_PATH}/${this.props.match.params.id}`;

  setPermissionGroupData = (
    permissionList: PermissionDto[],
    assignedPermissionList: AssignedPermissionDto[]
  ) => {
    const permissionTableData = mapUserPermissionListByRegionByGroup(
      permissionList,
      assignedPermissionList
    );

    const { userPermission, regionList } = this.props;

    let isPermissionEditable = false;

    Object.keys(userPermission).forEach(regionUrl => {
      if (regionUrl === regionList[this.state.regionValue].url) {
        Object.keys(userPermission[regionUrl]).forEach(permissionName => {
          if (permissionName === PERMISSION_NAMES.SECURITY_PERMISSIONS) {
            const p = userPermission[regionUrl][permissionName];
            isPermissionEditable = p.access === PermissionAccess.Edit;
          }
        });
      }
    });

    this.setState({
      permissionTableData,
      isPermissionEditable: (isPermissionEditable || this.state.hasAdvancedAccess),
    });
  };

  handleDropdownChange = (updatedItem: any) => {
    const { updateAdminTableData: put } = this.props;
    const url = getSecurityBaseUrl() + ASSIGNED_PERMISSION_PATH;

    const toastMessages = {
      success: `Permission group ${SUCCESSFULLY_UPDATED}`,
      fail: ADMIN_UPDATE_ERROR,
    };

    const selectedRegion = this.props.regionList[this.state.regionValue];
    const group = { url: this.state.defaultGroupData.url };
    const assignedPermissionDto = createAssignedPermissionDto(updatedItem, selectedRegion, group);
    // put if id
    if (assignedPermissionDto.id) {
      put(
        url.concat(`/${assignedPermissionDto.id}`),
        assignedPermissionDto,
        updatedDataSet => this.onAssignedPermissionUpdateSuccess(updatedDataSet.data, updatedItem),
        this.onError,
        false,
        selectedRegion.url,
        toastMessages
      );
    }
  };

  editIconComponent = () => (
    <Button
      onClick={this.handleEditNameOpen}
      disabled={false}
      buttonClassName={cx('icon-create', styles.editNameIcon)}
      dataTest="edit-permission-group-name"
    />
  );

  render() {
    const { location, regionList } = this.props;
    const {
      anchorEl, defaultGroupData: group, editName,
    } = this.state;
    const disable = editName && editName.length > 0 ? editName.trim() === '' : true;

    const tabs = (
      <Tabs
        value={this.state.regionValue}
        onChange={this.handleTabChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {this.generateTabs(regionList)}
      </Tabs>
    );

    return (
      <AdminCard
        edit={false}
        url={this.groupsBaseUrl()}
        currentRegion={this.props.currentRegion}
        className={styles.wrapper}
        pathName={`${location.pathname}`}
        config={[]}
        onToggleEdit={noop}
        save={this.onSave}
        openFailModal={noop}
        headerAccessor="name"
        inactiveFields={[]}
      >
        <div className={styles.inputNameContainer}>
          <div>
            <Label className={styles.nameLabel}>Name</Label>
            <p
              className={styles.inputName}
              ref={this.getNameRef}
            >
              <span className={anchorEl && (styles.editText)}>{group.name}</span>
              {userHasEditPermissionInAnyRegion(this.props.userPermission, ADV_SECURITY_PERMS)
                && this.editIconComponent()}
            </p>
            <Popover
              classes={{
                paper: styles.paper,
              }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={this.handleEditNameClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div className={styles.editPopover}>
                <span className={styles.arrowClass} />
                <input
                  type="text"
                  className={cx('tt-input', styles.inputStyle)}
                  placeholder=""
                  maxLength={40}
                  onChange={this.onNameChange}
                  onBlur={noop}
                  value={editName}
                  data-test="edit-permission-group-name-input"
                  // Chrome tries to infer autofill and ignores autocomplete='off'
                  // a random name and autocomplete='new-password' makes it harder to autofill
                  name={Math.random().toString()}
                  autoComplete="new-password"
                />
                <Button
                  onClick={() => this.onSave({ name: editName.trim() })}
                  buttonClassName={cx(
                    'tt-btn--submit',
                    styles.buttonStyle
                  )}
                  disabled={disable}
                  name="Save"
                  dataTest="edit-permission-group-name-submit"
                />
                <Button
                  onClick={this.handleEditNameClose}
                  buttonClassName={cx(
                    'tt-btn-secondary',
                    styles.buttonStyle
                  )}
                  disabled={false}
                  name="Cancel"
                  dataTest="edit-permission-group-name-cancel"
                />
              </div>
            </Popover>
          </div>
        </div>
        {/* Regions tabs */}
        <div className={styles.regionsTabContainer}>
          {tabs}
        </div>

        {/* Permission table  */}
        <div className={cx(styles.permissionsLabel)}>
          <span className={cx('txt-bold')}>
            <i
              aria-hidden="true"
              className={cx('icon-lock-solid')}
            />
            <span className={styles.marginLeft}>Permissions</span>
          </span>
        </div>

        <div className={styles.permissionTableContainer}>
          <PermissionTable
            tableData={this.state.permissionTableData}
            onDropDownChange={this.handleDropdownChange}
            isPending={this.state.isPending}
            isDisabled={!this.state.isPermissionEditable}
          />
        </div>
        <div className={styles.permissionTableContainer}>
          {this.generateTabContents(this.state.regionValue, regionList)}
        </div>

        {/* End Permission table */}

      </AdminCard>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    permissionList: state.permissionList.permissionList,
    userPermission: state.userPermission,
    regionList: state.regionList,
    assignedPermissionList: state.assignedPermissionList.assignedPermissionList,
    flags: state.flags,
    assignedRegionList: state.assignedRegionList,
    currentRegion: state.currentRegion,
    userUrl: state.userUrl,
    selectedRegion: state.selectedRegion,
  };
}

type GroupPermissionsViewReduxStateProps = ReturnType<typeof mapStateToProps>;

type GroupPermissionsViewOwnProps = {
  match: {
    params: {
      id: string
    }
  },
  location: {
    pathname: string
  },
  history: {
    push: (path: string) => void
  }
};

type GroupPermissionsViewReduxDispatchProps = {
  fetchPermissionList: ConnectedDispatchFunction<typeof fetchPermissionList>,
  fetchAssignedPermissionList: ConnectedDispatchFunction<typeof fetchAssignedPermissionList>,
  updateAdminTableData: ConnectedDispatchFunction<typeof updateDataTableRecord>,
  devErrorAndLog: ConnectedDispatchFunction<typeof devErrorAndLog>,
  handleUserPermissionUpdate: ConnectedDispatchFunction<typeof handleUserPermissionUpdate>,
  setSelectedRegion: ConnectedDispatchFunction<typeof setSelectedRegion>
};

export type GroupPermissionsViewProps = GroupPermissionsViewOwnProps
  & GroupPermissionsViewReduxDispatchProps
  & GroupPermissionsViewReduxStateProps;

export default connect(mapStateToProps, {
  fetchPermissionList,
  fetchAssignedPermissionList,
  updateAdminTableData: updateDataTableRecord,
  devErrorAndLog,
  handleUserPermissionUpdate,
  setSelectedRegion,
})(GroupPermissionsView);
