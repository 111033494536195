import { Dispatch } from 'redux';
import { CancelToken } from 'axios';
import { getRequest } from './requestService';
import { devErrorAndLog } from '../util/errorUtil';
import { ERROR_TEXT_FETCH_TENANT_LIST } from '../constants/errorConstants';
import { FETCH_TENANTS } from '../constants/actionConstants';
import { CANCEL_API_DUE_TO_PAGE_CHANGE } from '../constants/appConstants';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { TENANTS_PATH } from '../constants/apiConstants';
import HTTP_CODES from '../constants/httpConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export function fetchTenantList(cancelToken?: CancelToken) {
  return async (dispatch: Dispatch) => {
    const TENANT_ENDPOINT = getGeotraxBaseUrl() + TENANTS_PATH;

    try {
      const response = await getRequest(TENANT_ENDPOINT, null, cancelToken);
      dispatch({
        type: FETCH_TENANTS,
        payload: response.data.items,
      });
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(ERROR_TEXT_FETCH_TENANT_LIST, `endpoint: ${TENANT_ENDPOINT}`, e.toString(), undefined, consoleOnly) as any);
      }
    }
  };
}
