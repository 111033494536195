import { RegionDto, UserRegionDto } from '@trucktrax/trucktrax-ts-common';
import {
  REMOVE_USER_OVERRIDE,
  SET_ASSIGNED_REGION_LIST,
  SET_DEFAULT_REGION,
  SET_SELECTED_REGION, SET_USER_OVERRIDE,
} from '../../constants/actionConstants';
import { UserOverride } from '../reducers/regionReducers';

export const setDefaultRegionList = (regionList: UserRegionDto[]) => ({
  type: SET_DEFAULT_REGION,
  payload: regionList,
});

export const setAssignedRegionList = (regionList: UserRegionDto[]) => ({
  type: SET_ASSIGNED_REGION_LIST,
  payload: regionList,
});

export const setSelectedRegion = (selectedRegion: RegionDto) => ({
  type: SET_SELECTED_REGION,
  payload: selectedRegion,
});

export const setUserOverride = (overrides: UserOverride) => ({
  type: SET_USER_OVERRIDE,
  payload: overrides
});

export const removeUserOverride = () => ({
  type: REMOVE_USER_OVERRIDE
});

export default setDefaultRegionList;
