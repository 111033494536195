import { getTokens } from '../../util/authUtil';
import {
  AUTH_ERROR,
  AUTH_USER,
  LOGIN_PENDING,
  LOGOUT_USER,
  REFRESH_TOKEN_PENDING,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_OK,
  SET_USER_PASSWORD_EXPIRED,
  SET_MINUTES_TO_IDLE_TIME,
  SET_SESSION_ID,
  SET_USER_URL,
  SET_AUTH_TENANT_NAME,
  CLEAR_AUTH_TENANT_NAME,
  SET_TENANT_ID,
  SET_TOKEN,
  SET_REFRESH_TOKEN,
  SET_USER_NAME,
  SET_USER_ID,
  SET_IS_PASSWORD_TEMPORARY,
  SET_USER_FULL_NAME,
} from '../../constants/actionConstants';
import {
  MINUTES_TO_IDLE_LOGOUT,
  SESSION_ID,
  USER_URL,
  AUTH_TENANT_NAME,
  TENANT_ID,
  TOKEN,
  REFRESH_TOKEN,
  IS_PASSWORD_TEMPORARY,
  USER_NAME,
  USER_ID,
  USER_FULL_NAME,
} from '../../constants/localStorageConstants';
import { PayloadAction } from '../../types';

const isPasswordTemporary = () => {
  const { isPasswordTemporary: hasTemporaryPassword } = getTokens();
  return hasTemporaryPassword === 'true';
};

const getUserFullName = () => {
  const { userFullName } = getTokens();
  return userFullName;
};

const isAuthenticated = () => {
  const { accessToken } = getTokens();
  const hasTempPassword = isPasswordTemporary();

  const authenticated = !!accessToken && !hasTempPassword;
  return authenticated;
};

export type AuthAction
  = 'AUTH_USER' |
  'LOGOUT_USER' |
  'RESET_PASSWORD' |
  'RESET_PASSWORD_OK' |
  'AUTH_ERROR' |
  'RESET_PASSWORD_FAIL' |
  'SET_USER_PASSWORD_EXPIRED' |
  'LOGIN_PENDING';

// this is the source of truth for the app authentication
export interface AuthState {
  error?: string;
  fieldErrors?: string;
  authenticated?: boolean;
  isPasswordTemporary?: boolean;
  logInPending?: boolean;
  userFullName: string | null;
}

export const authReducer = (state: AuthState = {
  error: '',
  fieldErrors: '',
  authenticated: isAuthenticated(),
  isPasswordTemporary: isPasswordTemporary(),
  logInPending: false,
  userFullName: getUserFullName(),
}, action: PayloadAction<string | boolean | undefined, AuthAction>): AuthState => {
  const authResultState = {
    error: '',
    fieldErrors: '',
    authenticated: state.authenticated ? state.authenticated : isAuthenticated(),
    isPasswordTemporary: state.isPasswordTemporary
      ? state.isPasswordTemporary
      : isPasswordTemporary(),
    logInPending: state.logInPending ? state.logInPending : false,
    userFullName: getUserFullName(),
  };

  switch (action.type) {
    case AUTH_USER:
    case LOGOUT_USER:
    case RESET_PASSWORD:
    case RESET_PASSWORD_OK:
      return authResultState;
    case AUTH_ERROR:
      authResultState.error = action.payload as string;
      return authResultState;
    case RESET_PASSWORD_FAIL:
      authResultState.fieldErrors = action.payload as string;
      return authResultState;
    case SET_USER_PASSWORD_EXPIRED:
      return {
        ...state,
        error: action.payload as string,
        authenticated: false,
      };
    case LOGIN_PENDING:
      return {
        ...state,
        logInPending: action.payload as boolean,
      };
    default:
      return state;
  }
};

export const newTokenRequest = (state = { pending: false }, action: PayloadAction<boolean, 'REFRESH_TOKEN_PENDING'>) => {
  switch (action.type) {
    case REFRESH_TOKEN_PENDING:
      return {
        ...state,
        pending: action.payload,
      };
    default:
      return state;
  }
};

const localStorageMinutesToIdleLogout = Number(localStorage.getItem(MINUTES_TO_IDLE_LOGOUT));

export const minutesToIdleLogout = (
  state = localStorageMinutesToIdleLogout,
  action: PayloadAction<number, 'SET_MINUTES_TO_IDLE_TIME'>
) => {
  switch (action.type) {
    case SET_MINUTES_TO_IDLE_TIME:
      localStorage.setItem(MINUTES_TO_IDLE_LOGOUT, action.payload.toString());
      return action.payload;
    default:
      return state;
  }
};

const localStorageSessionId = localStorage.getItem(SESSION_ID);

export const sessionId = (state = localStorageSessionId, action: PayloadAction<string, 'SET_SESSION_ID'>) => {
  switch (action.type) {
    case SET_SESSION_ID:
      localStorage.setItem(SESSION_ID, action.payload);
      return action.payload;
    default:
      return state;
  }
};

const localStorageUserUrl = localStorage.getItem(USER_URL);

export const userUrl = (state = localStorageUserUrl, action: { type: 'SET_USER_URL', payload: string }) => {
  switch (action.type) {
    case SET_USER_URL:
      localStorage.setItem(USER_URL, action.payload);
      return action.payload;
    default:
      return state;
  }
};

const localStorageUserFullName = localStorage.getItem(USER_FULL_NAME);

export const userFullName = (state = localStorageUserFullName, action: { type: 'SET_USER_FULL_NAME', payload: string }) => {
  switch (action.type) {
    case SET_USER_FULL_NAME:
      localStorage.setItem(USER_FULL_NAME, action.payload);
      return action.payload;
    default:
      return state;
  }
};

const localStorageAuthTenantName = localStorage.getItem(AUTH_TENANT_NAME);

export const authTenantName = (
  state = localStorageAuthTenantName,
  action: PayloadAction<string, 'SET_AUTH_TENANT_NAME' | 'CLEAR_AUTH_TENANT_NAME'>
) => {
  switch (action.type) {
    case SET_AUTH_TENANT_NAME:
      localStorage.setItem(AUTH_TENANT_NAME, action.payload);
      return action.payload;
    case CLEAR_AUTH_TENANT_NAME:
      localStorage.removeItem(AUTH_TENANT_NAME);
      return null;
    default:
      return state;
  }
};

const localStorageTenantId = localStorage.getItem(TENANT_ID);

export const tenantId = (state = localStorageTenantId, action: PayloadAction<string, 'SET_TENANT_ID'>) => {
  switch (action.type) {
    case SET_TENANT_ID:
      localStorage.setItem(TENANT_ID, action.payload);
      return action.payload;
    default:
      return state;
  }
};

const localStorageToken = localStorage.getItem(TOKEN);

export const token = (state = localStorageToken, action: PayloadAction<string, 'SET_TOKEN'>) => {
  switch (action.type) {
    case SET_TOKEN:
      localStorage.setItem(TOKEN, action.payload);
      return action.payload;
    default:
      return state;
  }
};

const localStorageRefreshToken = localStorage.getItem(REFRESH_TOKEN);

export const refreshToken = (state = localStorageRefreshToken, action: PayloadAction<string, 'SET_REFRESH_TOKEN'>) => {
  switch (action.type) {
    case SET_REFRESH_TOKEN:
      localStorage.setItem(REFRESH_TOKEN, action.payload);
      return action.payload;
    default:
      return state;
  }
};

const localStorageIsPasswordTemporary = localStorage.getItem(IS_PASSWORD_TEMPORARY);

export const hasTemporaryPassword = (
  state = localStorageIsPasswordTemporary,
  action: PayloadAction<boolean, 'SET_IS_PASSWORD_TEMPORARY'>
) => {
  switch (action.type) {
    case SET_IS_PASSWORD_TEMPORARY:
      localStorage.setItem(IS_PASSWORD_TEMPORARY, action.payload.toString());
      return action.payload;
    default:
      return state;
  }
};

const localStorageUserName = localStorage.getItem(USER_NAME);

export const username = (state = localStorageUserName, action: PayloadAction<string, 'SET_USER_NAME'>) => {
  switch (action.type) {
    case SET_USER_NAME:
      localStorage.setItem(USER_NAME, action.payload);
      return action.payload;
    default:
      return state;
  }
};

const localStorageUserId = localStorage.getItem(USER_ID);
export const userId = (state = localStorageUserId, action: PayloadAction<string, 'SET_USER_ID'>) => {
  switch (action.type) {
    case SET_USER_ID:
      localStorage.setItem(USER_ID, action.payload);
      return action.payload;
    default:
      return state;
  }
};
