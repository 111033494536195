import { GeoZoneDto } from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import { SET_CURRENT_GEOZONE } from '../../constants/actionConstants';

const initialGeozoneState: GeoZoneDto = {
  id: 0,
  deleted: false,
  archived: false,
};

// eslint-disable-next-line import/prefer-default-export
export const currentGeozone = (
  state = initialGeozoneState,
  action: PayloadAction<GeoZoneDto | undefined, 'SET_CURRENT_GEOZONE'>
): GeoZoneDto => {
  if (action.type === SET_CURRENT_GEOZONE) {
    return { ...action.payload as GeoZoneDto };
  }
  return state;
};
