import React, { useState, MouseEvent } from 'react';
import cx from 'classnames';
import Popover from '@mui/material/Popover';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import { Button } from '@trucktrax/trucktrax-common';
import styles from './InfoPane.module.css';
import * as C from '../../../constants/mapConstants';

function InfoPane(props: InfoPaneProps) {
  const {
    infoPaneTitle,
    infoPaneContent,
    onCloseClick,
    onLogoutClick,
    truckHasTicket = true,
  }: InfoPaneProps = props;

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [disableLogoutButton, setDisableLogoutButton] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const logoutButton = (
    <Button
      onClick={handleClick}
      buttonClassName={cx('driver-info-close', 'tt-btn-basic', styles.logoutBtn)}
      dataTest="truck-infopane-more-detail-button"
      iconClassName={cx('icon-more-horiz', 'icon-gray-400', 'icon-regular')}
    />
  );

  const logoutClick = () => {
    handleClose();

    if (onLogoutClick) {
      onLogoutClick();
    }

    setDisableLogoutButton(true);
    setTimeout(() => {
      setDisableLogoutButton(false);
    }, 65000);
  };

  const logoutPopover = (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Button
        onClick={logoutClick}
        disabled={disableLogoutButton}
        buttonClassName={cx('tt-btn-text-dark', styles.commitLogoutBtn)}
        dataTest="truck-infopane-logout-button"
        name={C.LOGOUT_DRIVER}
      />
    </Popover>
  );

  return (
    <div className={styles.infoBoxWrap} data-test="vehicle-detail-panel">
      {infoPaneTitle && (
        <div className={styles.title}>
          {infoPaneTitle}
          <div className={styles.actionButtons}>
            <div className={cx(styles.logoutPopoverContainer, 'margin-right-5')}>
              {!truckHasTicket && logoutButton}
              {logoutPopover}
            </div>
            <Button
              onClick={onCloseClick}
              buttonClassName={cx('driver-info-close', 'tt-btn-basic', styles.closeBtn)}
              dataTest="truck-infopane-close-button"
              iconClassName={cx('icon-close', 'icon-gray-400', 'icon-small')}
            />
          </div>
        </div>
      )}
      {infoPaneContent && (
        <div className={styles.infoBoxContainer}>
          {infoPaneContent}
        </div>
      )}
    </div>
  );
}

export default InfoPane;

export interface InfoPaneProps {
  infoPaneTitle?: JSX.Element | string,
  infoPaneContent?: JSX.Element,
  truckHasTicket?: boolean,
  onLogoutClick?: VoidFunc,
  onCloseClick: VoidFunc
}
