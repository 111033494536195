import * as _ from 'lodash';
import { QualityFactorDto, TicketDto } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import {
  ADD_TICKETS,
  CLEAR_SELECTED_TICKET,
  EMPTY_PAST_TICKET,
  REMOVE_TICKET,
  RESET_CURRENT_TICKETS_COLUMNS,
  RESET_PAST_TICKET_DATES,
  RESET_TICKET_VIEW,
  SET_CURRENT_TICKETS_COLUMNS,
  SET_PAST_TICKET_CURRENT_PAGE,
  SET_PAST_TICKET_LIST,
  SET_PAST_TICKET_MAX_PAGES,
  SET_PAST_TICKET_FILTERED_TOTAL,
  SET_SELECTED_TICKET,
  SET_TICKET_LIST,
  SET_TICKET_VIEW,
  SYNC_TICKET,
  TOGGLE_TICKETS_POPOVER,
  UPDATE_PAST_TICKET_END_DATE,
  UPDATE_PAST_TICKET_START_DATE,
  UPDATE_TICKET_TIMELINE,
  SET_TICKET_QUALITY_FACTORS,
  SET_SCALETRAX_SEARCH_FILTERS,
  SAVE_TICKET_LIST_FILTERS,
  SET_CENTER_TICKET_PRINT_VIEW,
  IS_OPEN_TICKET,
  NEED_TO_REFRESH_DATA,
} from '../../constants/actionConstants';
import { VIEW_TICKET_DETAILS } from '../../constants/appConstants';

import {
  ScaleTraxSearchFilters,
  TicketListFilters,
  PayloadAction,
} from '../../types';
import { CurrentColumns } from '../actions/ticketActions';

export const currentTicketsColumns = (
  state = [] as CurrentColumns,
  action: PayloadAction<CurrentColumns, 'SET_CURRENT_TICKETS_COLUMNS' | 'RESET_CURRENT_TICKETS_COLUMNS'>
) => {
  switch (action.type) {
    case SET_CURRENT_TICKETS_COLUMNS:
      return action.payload;
    case RESET_CURRENT_TICKETS_COLUMNS:
      return {};
    default:
      return state;
  }
};

export const selectedTicket = (
  state: null | Partial<TicketDto> = {},
  action: PayloadAction<TicketDto | undefined, 'SET_SELECTED_TICKET' | 'CLEAR_SELECTED_TICKET'>
): {} | TicketDto | null => {
  switch (action.type) {
    case SET_SELECTED_TICKET:
      return { ...state, ...action.payload };
    case CLEAR_SELECTED_TICKET:
      return null;
    default:
      return state;
  }
};

export const ticketView = (
  state = VIEW_TICKET_DETAILS,
  action: PayloadAction<'details' | 'route' | undefined, 'SET_TICKET_VIEW' | 'RESET_TICKET_VIEW'>
) => {
  switch (action.type) {
    case SET_TICKET_VIEW:
      return action.payload;
    case RESET_TICKET_VIEW:
      return VIEW_TICKET_DETAILS;
    default:
      return state;
  }
};

export const ticketsReducer = (
  state = [] as TicketDto[],
  action: PayloadAction<TicketDto[] | TicketDto, 'SET_TICKET_LIST' | 'SYNC_TICKET' | 'REMOVE_TICKET'>
): TicketDto[] => {
  switch (action.type) {
    case SET_TICKET_LIST:
      return action.payload as TicketDto[];
    case SYNC_TICKET: {
      const ticketsArray = state.slice();
      const ticket = action.payload as TicketDto;
      const index = ticketsArray.findIndex(item => item.url === ticket.url);
      const truckIdx = ticket?.truck?.url ? ticketsArray.findIndex(item => item?.truck?.url === ticket?.truck?.url) : -1;

      if (index > -1) {
        ticketsArray[index] = ticket;
      } else if (truckIdx === -1) {
        ticketsArray.splice(ticketsArray.length, 0, ticket);
      }
      return ticketsArray;
    }
    case REMOVE_TICKET:
      return _.filter(state, (ticket) => ticket.id !== (action.payload as TicketDto).id);
    default:
      return state;
  }
};

export const currentTicketQualityFactors = (
  state = [] as QualityFactorDto[],
  action: PayloadAction<QualityFactorDto[], 'SET_TICKET_QUALITY_FACTORS'>
) => {
  switch (action.type) {
    case SET_TICKET_QUALITY_FACTORS:
      return action.payload;
    default:
      return state;
  }
};

export const pastTicketList = (
  state = [] as TicketDto[],
  action: PayloadAction<TicketDto[], 'ADD_TICKETS' | 'SET_PAST_TICKET_LIST' | 'EMPTY_PAST_TICKET'>
) => {
  switch (action.type) {
    case ADD_TICKETS:
      return [...state, ...action.payload];
    case SET_PAST_TICKET_LIST:
      return action.payload;
    case EMPTY_PAST_TICKET:
      return [];
    default:
      return state;
  }
};

export const ticketTimeline = (
  state = 'current',
  action: PayloadAction<'current' | 'past', 'UPDATE_TICKET_TIMELINE'>
) => {
  switch (action.type) {
    case UPDATE_TICKET_TIMELINE:
      return action.payload;
    default:
      return state;
  }
};

export const ticketsPopoverReducer = (
  state = false,
  action: PayloadAction<boolean, 'TOGGLE_TICKETS_POPOVER'>
) => {
  switch (action.type) {
    case TOGGLE_TICKETS_POPOVER:
      return action.payload;
    default:
      return state;
  }
};

export const pastTicketStartDate = (
  state = DateWrapper.now.previousWeekday.date,
  action: PayloadAction<Date, 'UPDATE_PAST_TICKET_START_DATE' | 'RESET_PAST_TICKET_DATES'>
) => {
  switch (action.type) {
    case UPDATE_PAST_TICKET_START_DATE:
      return action.payload;
    case RESET_PAST_TICKET_DATES:
      return DateWrapper.now.previousWeekday.date;
    default:
      return state;
  }
};

export const pastTicketEndDate = (
  state = DateWrapper.now.previousWeekday.date,
  action: PayloadAction<Date, 'UPDATE_PAST_TICKET_END_DATE' | 'RESET_PAST_TICKET_DATES'>
) => {
  switch (action.type) {
    case UPDATE_PAST_TICKET_END_DATE:
      return action.payload;
    case RESET_PAST_TICKET_DATES:
      return DateWrapper.now.previousWeekday.date;
    default:
      return state;
  }
};

const pastTicketInitialState = {
  currentPage: 1,
  maxPages: 1,
  filteredTotal: 0,
};

export const pastTicketPagination = (
  state = pastTicketInitialState,
  action: PayloadAction<number, 'SET_PAST_TICKET_CURRENT_PAGE' | 'SET_PAST_TICKET_MAX_PAGES' | 'SET_PAST_TICKET_FILTERED_TOTAL'>
) => {
  switch (action.type) {
    case SET_PAST_TICKET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PAST_TICKET_MAX_PAGES:
      return {
        ...state,
        maxPages: action.payload,
      };
    case SET_PAST_TICKET_FILTERED_TOTAL:
      return {
        ...state,
        filteredTotal: action.payload,
      };
    default:
      return state;
  }
};

export const scaleTraxSearchFilters = (
  state: any | null = null,
  action: PayloadAction<any, 'SET_SCALETRAX_SEARCH_FILTERS'>
): any | null => {
  switch (action.type) {
    case SET_SCALETRAX_SEARCH_FILTERS:
      return action.payload as ScaleTraxSearchFilters;
    default:
      return state;
  }
};

export const ticketListFilters = (
  state: TicketListFilters | null = null,
  action: PayloadAction<any, 'SAVE_TICKET_LIST_FILTERS'>
): TicketListFilters | null => {
  switch (action.type) {
    case SAVE_TICKET_LIST_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

export const isTicketPrintViewCenter = (
  state = false,
  action: PayloadAction<boolean, 'SET_CENTER_TICKET_PRINT_VIEW'>
) => {
  switch (action.type) {
    case SET_CENTER_TICKET_PRINT_VIEW:
      return action.payload;
    default:
      return state;
  }
};

export const isOpenTicket = (
  state = false,
  action: PayloadAction<boolean, 'IS_OPEN_TICKET'>
) => {
  switch (action.type) {
    case IS_OPEN_TICKET:
      return action.payload;
    default:
      return state;
  }
};

export const needToRefreshData = (
  state = false,
  action: PayloadAction<boolean, 'NEED_TO_REFRESH_DATA'>
) => {
  switch (action.type) {
    case NEED_TO_REFRESH_DATA:
      return action.payload;
    default:
      return state;
  }
};
