import { ProjectDto } from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import { FETCH_PROJECTS, QUEUE_RECENT_PROJECT } from '../../constants/actionConstants';
import { RECENT_PROJECTS_HISTORY } from '../../constants/localStorageConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const projectsReducer = (
  state = { projects: [] as ProjectDto[] },
  action: PayloadAction<ProjectDto[], 'FETCH_PROJECTS'>
) => {
  switch (action.type) {
    case FETCH_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    default:
      return state;
  }
};

const getRecentProjects = () => {
  const value = localStorage.getItem(RECENT_PROJECTS_HISTORY);
  if (!value) { return []; }
  return JSON.parse(value) as ProjectDto[];
};

const setRecentProjects = (recentProjects: ProjectDto[]) => {
  localStorage.setItem(RECENT_PROJECTS_HISTORY, JSON.stringify(recentProjects));
};

export const recentProjectsReducer = (
  state = getRecentProjects(),
  action: PayloadAction<ProjectDto, 'QUEUE_RECENT_PROJECT'>
) => {
  switch (action.type) {
    case QUEUE_RECENT_PROJECT: {
      const currentProject = action.payload;
      let recentProjects = state;

      if (!currentProject) { return state; }

      if (recentProjects.some(r => r.projectExternalId === currentProject.projectExternalId)) {
        recentProjects = recentProjects.filter(r => r.projectExternalId !== currentProject.projectExternalId);
      }

      recentProjects.unshift(currentProject);

      while (recentProjects.length > 10) {
        recentProjects.pop();
      }

      setRecentProjects(recentProjects);
      return recentProjects;
    }
    default:
      return state;
  }
};
