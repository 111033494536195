import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import styles from './NotesSection.module.css';

export class NotesSection extends Component<NotesSectionProps> {
  deliveryInstructionsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const deliveryInstructions = event.target.value;
    this.props.onChange(
      deliveryInstructions,
      this.props.orderNotes
    );
  };

  onOrderNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const orderNotes = event.target.value;
    this.props.onChange(
      this.props.deliveryInstructions,
      orderNotes
    );
  };

  render() {
    return (
      <div className={styles.notesSection}>
        <label htmlFor="deliveryInstructionsInput">Delivery Instructions</label>
        <textarea
          id="deliveryInstructionsInput"
          onChange={this.deliveryInstructionsChange}
          maxLength={4000}
          value={this.props.deliveryInstructions}
        />

        <label htmlFor="orderNotesInput">Ship Notes</label>
        <textarea
          id="orderNotesInput"
          onChange={this.onOrderNotesChange}
          maxLength={4000}
          value={this.props.orderNotes}
        />
      </div>
    );
  }
}

export function mapStateToProps() {
  return {};
}

export type NotesSectionProps = {
  deliveryInstructions: string,
  orderNotes: string,
  onChange: (newDeliveryInstructions: string, newOrderNotes: string) => void
};

export default connect(mapStateToProps)(NotesSection);
