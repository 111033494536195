/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Label } from '@trucktrax/trucktrax-common';
import { noop } from '../../../util/appUtil';
import { SHOW_NO, SHOW_YES } from '../../../constants/appConstants';
import styles from '../admin/AdminCard.module.css';

export default class CardSwitch extends Component<CardSwitchProps> {
  static defaultProps: Partial<CardSwitchProps> = {
    display: false,
    editing: false,
    checked: false,
    disabled: false,
    label: '',
    className: '',
    id: '',
    onChange: noop,
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const { id } = this.props;
    const event = {
      [id!]: checked,
    };
    this.props.onChange!(event);
  };

  render() {
    const {
      display,
      editing,
      label,
      checked,
      className,
      disabled,
    } = this.props;

    if (display) {
      return (
        <div className={className}>
          <Label htmlFor="edit-switch">
            {label!}
          </Label>
          {
            editing
              ? (
                <div className={styles.switchContainer}>
                  <FormControlLabel
                    control={(
                      <Switch
                        id="edit-switch"
                        checked={checked}
                        onChange={this.onChange}
                        color="primary"
                        disabled={disabled}
                        className={disabled ? styles.switchNotAllowed : ''}
                      />
                    )}
                    label={checked ? SHOW_YES : SHOW_NO}
                    classes={{ label: styles.switchLabel }}
                    disabled={disabled}
                  />
                </div>
              )
              : <p>{checked ? SHOW_YES : SHOW_NO}</p>
          }
        </div>
      );
    }
    return null;
  }
}

export interface CardSwitchProps {
  display?: boolean;
  editing?: boolean;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  className?: string;
  id?: string;
  onChange?: (e: { [name: string]: boolean }) => void;
}
