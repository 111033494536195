/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { Label } from '@trucktrax/trucktrax-common';
import { isUndefined } from '../../../util/adminUtil';

function mapValues(value: any[], dataTest: string, commaSeparated: boolean) {
  return value.map((v, index) => {
    if (isUndefined(String(v))) {
      return (
        <div key={`skeleton-${v}`} className="skeleton" />
      );
    }

    if (commaSeparated) {
      const addComma = index < value.length - 1 ? ', ' : '';

      return (
        <span
          style={{
            fontSize: '1.6rem',
          }}
          data-test={dataTest}
          title={v}
          key={v}
        >
          {`${v}${addComma}`}
        </span>
      );
    }

    return (
      <p
        style={{
          margin: '0 0 0 0',
          minHeight: '0px',
        }}
        data-test={dataTest}
        title={v}
        key={v}
      >
        {v}
      </p>
    );
  });
}

export default function CardDisplay({
  value,
  label = '',
  className = '',
  dataTest = '',
  textClassName = '',
  hideEmpty = false,
  commaSeparated = false,
  hideTitle = false,
  display = true,
}: CardDisplayProps) {
  if (!display) {
    return null;
  }
  const isList = Array.isArray(value);
  if (isList) {
    if (value.length === 0 && hideEmpty) {
      return (<div className={className} />);
    }
    return (
      <div className={className}>
        <Label>{label}</Label>
        {mapValues(value, dataTest, commaSeparated)}
      </div>
    );
  }

  return (
    <div className={className}>
      <Label>{label}</Label>
      {
        isUndefined(String(value))
          ? <div className="skeleton" />
          : (
            <p
              className={textClassName}
              data-test={dataTest}
              title={hideTitle ? undefined : value}
            >
              {value}
            </p>
          )
      }
    </div>
  );
}

export interface CardDisplayProps {
  value?: any;
  label?: string | JSX.Element;
  className?: string;
  textClassName?: string;
  dataTest?: string;
  hideEmpty?: boolean;
  commaSeparated?: boolean;
  hideTitle?: boolean;
  display?: boolean
}
