import { PermissionAccess } from '@trucktrax/trucktrax-ts-common';
import { SET_ADMIN_PERMISSION_ACCESS } from '../../constants/actionConstants';

export type AdminPermissionAccess = { adminPermission: string, result: PermissionAccess };

const setAdminPermissionAccess = (value: AdminPermissionAccess) => ({
  type: SET_ADMIN_PERMISSION_ACCESS,
  payload: value,
});

export default setAdminPermissionAccess;
