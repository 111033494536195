import {
  differenceBy, keys, pickBy, pull,
} from 'lodash';
import { Dispatch } from 'redux';
import { FeatureFlagDto, SubscriptionDto, VoidFunc } from '@trucktrax/trucktrax-ts-common';
import {
  deleteRequest, getRequest, postRequest, putRequest,
} from './requestService';
import { addFlagToStorage, mapFeatureFlagsToAssignments, setStoreAndStorage } from '../util/featureFlagUtil';
import recordLog, { devErrorAndLog } from '../util/errorUtil';
import { store } from '../Main';
import { openSnackbar } from '../store/actions/snackbarActions';

import { replaceAllFlags, replaceAllSubscriptions, setFeatureFlagObject } from '../store/actions/featureFlagsActions';
import { setFlagsAction } from '../util/flagUtil';
import { ERROR_TEXT_FETCH_SUBSCRIPTION_LIST, ERROR_TEXT_UPDATE_SUBSCRIPTION_LIST } from '../constants/errorConstants';
import { FEATURE_FLAG_MAP } from '../constants/localStorageConstants';
import { FLAGS_EDIT_SUCCESS_PREFIX } from '../constants/appConstants';
import { getSecurityBaseUrl } from '../util/apiUtil';
import { SUCCESSFULLY_MODIFIED } from '../constants/successConstants';
import { FEATURE_FLAG_ASSIGNMENT_PATH, FLAGS_PATH } from '../constants/apiConstants';
import { refreshPermissions } from '../util/permissionUtil';
import { openFailModal } from '../store/actions/errorModalActions';
import HTTP_CODES from '../constants/httpConstants';

type GetState = () => { userUrl: string };
export type Item = { itemUrl: string };

export async function getFeatureFlagAssignments(userUrl: string, defaultRegionUrl?: string) {
  const { tenantId: tenant } = store.getState();
  const subscriber = `${userUrl},${defaultRegionUrl},${tenant}`;

  const url = getSecurityBaseUrl() + FEATURE_FLAG_ASSIGNMENT_PATH;
  const params = { subscriber };
  try {
    const response = await getRequest(url, params);
    return response.data.items;
  } catch (e) {
    return null;
  }
}

export async function getAllFeatureFlags() {
  const url = getSecurityBaseUrl() + FLAGS_PATH;
  try {
    const [nonArchivedFlags, archivedFlags] = await Promise.all([
      getRequest(url),
      getRequest(url, { isArchived: true }),
    ]);

    const featureFlags = [...nonArchivedFlags.data.items, ...archivedFlags.data.items];
    store.dispatch(replaceAllFlags({ featureFlags }));
    store.dispatch(setFeatureFlagObject(featureFlags));
    return featureFlags;
  } catch (e) {
    return null;
  }
}

export async function setFeatureFlagsWithUserUrl(userUrl: string, defaultRegionUrl?: string) {
  const featureFlagAssignments = await getFeatureFlagAssignments(userUrl, defaultRegionUrl);
  const featureFlags = await getAllFeatureFlags();

  const featureFlagMap = mapFeatureFlagsToAssignments(featureFlags, featureFlagAssignments);
  const availFlags = pull(keys(pickBy(featureFlagMap)), 'isDeleted');
  if (availFlags.length > 0) {
    const { userUrl: activeUserUrl } = store.getState();
    recordLog('Available feature flags from backend for user: ', activeUserUrl, availFlags);
  }
  localStorage.setItem(FEATURE_FLAG_MAP, JSON.stringify(featureFlagMap));
  store.dispatch(setFlagsAction({ features: featureFlagMap }));
}

export function deleteFeatureFlag(
  flag: FeatureFlagDto,
  onSuccess: (flag: FeatureFlagDto) => void,
  onError: (exception: any) => void
) {
  return async (dispatch: Dispatch) => {
    const baseUrl = getSecurityBaseUrl() + FLAGS_PATH;
    const url = `${baseUrl}/${flag.id}`;
    try {
      await deleteRequest(url, 'DeleteFeatureFlag');
      onSuccess(flag);
      const featureFlags = setStoreAndStorage(flag);
      dispatch(replaceAllFlags({ featureFlags }));

      const { userUrl } = store.getState();
      await refreshPermissions(dispatch, userUrl ?? '');
    } catch (e) {
      onError(e);
    }
  };
}

export function fetchSubscriptionsForFlagUrl(featureflag: string) {
  return async (dispatch: Dispatch) => {
    const url = getSecurityBaseUrl() + FEATURE_FLAG_ASSIGNMENT_PATH;
    const params = { featureflag };
    try {
      const response = await getRequest(url, params);
      dispatch(replaceAllSubscriptions(response.data.items));
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(
        ERROR_TEXT_FETCH_SUBSCRIPTION_LIST,
        `driversService: url: ${url} + params: + ${JSON.stringify(params)}`,
        e.toString(),
        undefined,
        consoleOnly
      ) as any);
    }
  };
}

export function generateSubscriptionPostPromises(
  list: Item[],
  featureFlag: FeatureFlagDto
) {
  const url = getSecurityBaseUrl() + FEATURE_FLAG_ASSIGNMENT_PATH;
  const promises: Promise<any>[] = [];

  list.forEach((item) => {
    const postBody = {
      subscriber: { url: item.itemUrl },
      featureFlag: { url: featureFlag.url },
    };
    promises.push(postRequest(url, postBody));
  });

  return promises;
}

export function generateSubscriptionDeletePromises(
  list: Item[],
  permissionsList: SubscriptionDto[]
) {
  const url = getSecurityBaseUrl() + FEATURE_FLAG_ASSIGNMENT_PATH;
  const promises: Promise<VoidFunc>[] = [];

  list.forEach(item => {
    const subscription = permissionsList.find(entity => item.itemUrl === entity?.subscriber?.url);
    if (subscription) {
      promises.push(deleteRequest(`${url}/${subscription.id}`));
    }
  });

  return promises;
}

export function postAndDeleteForNewSubscriptionList(
  flagToEdit: FeatureFlagDto,
  flagToEditSubscriptions: SubscriptionDto[],
  original: Item[],
  newList: Item[],
  enableButtonCallback: VoidFunc,
  closeModalCallback: VoidFunc
) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    // compare original list vs new list to find new subscriptions and which to delete
    const deleteList = differenceBy(original, newList, 'itemUrl');
    const postList = differenceBy(newList, original, 'itemUrl');
    // generate post and delete lists
    let promises = generateSubscriptionPostPromises(postList, flagToEdit);
    promises = promises.concat(generateSubscriptionDeletePromises(deleteList, flagToEditSubscriptions));
    // use Promise.all to make sure all async calls succeed
    try {
      await Promise.all(promises);
      dispatch(openSnackbar({
        snackbarType: 'SUCCESS',
        snackbarBody: `${FLAGS_EDIT_SUCCESS_PREFIX} "${flagToEdit.name}" ${SUCCESSFULLY_MODIFIED}`,
        dataTest: 'feature-flag-edit-success-notification',
      }));
      enableButtonCallback();
      closeModalCallback();

      await refreshPermissions(dispatch, getState().userUrl);
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_TEXT_UPDATE_SUBSCRIPTION_LIST));
      recordLog('error', ERROR_TEXT_UPDATE_SUBSCRIPTION_LIST, e.toString());
    }
  };
}

export function toggleFeatureFlag(
  flag: FeatureFlagDto,
  allFlags: FeatureFlagDto[],
  onError: (exception: any) => void,
  reToggle: VoidFunc
) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    // eslint-disable-next-line no-param-reassign
    flag.archived = !flag.archived;

    const baseUrl = getSecurityBaseUrl() + FLAGS_PATH;
    const url = `${baseUrl}/${flag.id}`;
    try {
      const response = await putRequest(url, flag);
      const index = allFlags.findIndex(el => el.id === response.data.id);
      // eslint-disable-next-line no-param-reassign
      allFlags[index] = response.data;
      dispatch(replaceAllFlags({ featureFlags: allFlags }));
      reToggle();

      await refreshPermissions(dispatch, getState().userUrl);
    } catch (e) {
      onError(e);
    }
  };
}

export async function postSubscriptionsToFeatureFlags(
  subscriberUrls: string[],
  response: { data: FeatureFlagDto },
  onSuccess: (flags?: FeatureFlagDto[]) => void,
  onError: (exception: any) => void
) {
  if (subscriberUrls.length > 0) {
    const promises: Promise<any>[] = [];
    const url = getSecurityBaseUrl() + FEATURE_FLAG_ASSIGNMENT_PATH;

    subscriberUrls.forEach(subscriberUrl => {
      const postBody = {
        subscriber: { url: subscriberUrl },
        featureFlag: { url: response.data.url },
      };
      promises.push(postRequest(url, postBody));
    });

    try {
      await Promise.all(promises);
      addFlagToStorage(response.data, onSuccess);
    } catch (e) {
      onError(e);
    }
  } else {
    addFlagToStorage(response.data, onSuccess);
  }

  const { userUrl } = store.getState();
  await refreshPermissions(null, userUrl ?? '');
}

export interface FeatureFlagPostInfo {
  featureFlag: {
    name: string;
    description: string;
  };
  subscriberUrls: string[];
}

export async function postFeatureFlagWithSubscriptions(
  flagsAndSubscribers: FeatureFlagPostInfo,
  onSuccess: (flags?: FeatureFlagDto[]) => void,
  onError: (exception: any) => void
) {
  const { featureFlag, subscriberUrls } = flagsAndSubscribers;
  const url = getSecurityBaseUrl() + FLAGS_PATH;

  try {
    const response = await postRequest(url, featureFlag);
    await postSubscriptionsToFeatureFlags(subscriberUrls, response, onSuccess, onError);
  } catch (e) {
    onError(e);
  }
}
