import { Dispatch } from 'redux';
import {
  AssignedPermissionDto,
  GroupDto,
  GroupMemberDto,
  GroupMemberFullDto,
  NameUrlKeyDto,
  PermissionAccess,
  RegionDto,
  UrlKeyDto,
  UserPermissionAccessDto,
} from '@trucktrax/trucktrax-ts-common';
import {
  deleteRequest, getRequest, postRequest, putRequest,
} from './requestService';
import recordLog, { devErrorAndLog } from '../util/errorUtil';
import { store } from '../Main';
import setAdminPermissionAccess from '../store/actions/permissionAccessAction';
import {
  ERROR_TEXT_FETCH_USER_PERMISSIONS,
  FETCH_ASSIGNED_PERMISSION_FAIL_MESSAGE,
  FETCH_PERMISSIONS_FAIL_MESSAGE,
  FETCH_PERMISSION_GROUP_FAIL_MESSAGE,
} from '../constants/errorConstants';
import {
  FETCH_ASSIGNED_PERMISSIONS,
  FETCH_PERMISSION_GROUPS,
  FETCH_PERMISSIONS,
  SET_USER_PERMISSION_LIST,
} from '../constants/actionConstants';
import {
  ADV_SECURITY_PERMS,
  DEFAULT_PAGE_SIZE,
  PERMISSION_NAMES,
  FEATURE_FLAG_ACCESS_FLAG,
} from '../constants/appConstants';
import { GROUP_MEMBERS_PATH } from '../constants/apiConstants';
import { getSecurityBaseUrl } from '../util/apiUtil';
import { getAccessNum, RegionAccessMap, sortPermissionAccessLowToHigh } from '../util/permissionUtil';
import { UserPermissions, UserPermissionsByRegion } from '../types';
import HTTP_CODES from '../constants/httpConstants';

export const PERMISSIONS_USERS_REGIONS_PATH = '/permissions/users/regions';
export const PERMISSIONS_PATH = '/permissions';
export const ASSIGNED_PERMISSION_PATH = '/assignedPermission';
export const GROUPS_PATH = '/groups';
export const GROUP_MEMBERS_FULL_MEMBER_PATH = '/groupMembersFull/member';
export const GROUP_MEMBERS_FILTER = '/groupMembers/filter';

export interface NewAssignedPermissionDto {
  permission: {
    name: string,
    url: string
  },
  group: {
    url: string
  },
  access: PermissionAccess
}

type GetState = () => {
  userPermission: UserPermissionsByRegion,
  userUrl: string
};

/**
 * Fetch permissions list by user url from backend
 * @param {string} userUrl user url
 * @param {boolean} byPassRedisCache Flag to identify if we need to use Redis Cache or not
 * @returns Dispatch user permission list
 */
export function fetchPermissionsListByUserUrl(userUrl: string, byPassRedisCache: boolean = false) {
  return async (dispatch: Dispatch) => {
    const url = getSecurityBaseUrl() + PERMISSIONS_USERS_REGIONS_PATH;
    const param = { userUrl, byPassRedisCache };

    try {
      const response = await getRequest(url, param);
      dispatch({
        type: SET_USER_PERMISSION_LIST,
        payload: response.data,
      });
    } catch (e) {
      recordLog('Fetch Permission by User url Error', url, e);
    }
  };
}

export function setUserPermissionObject(permissionObject: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_USER_PERMISSION_LIST,
      payload: permissionObject,
    });
  };
}

export function fetchPermissionList(regionUrl?: string) {
  return async (dispatch: Dispatch) => {
    const url = getSecurityBaseUrl() + PERMISSIONS_PATH;
    const params: { size: number, region?: string } = { size: DEFAULT_PAGE_SIZE };

    if (regionUrl) {
      params.region = regionUrl;
    }

    try {
      const response = await getRequest(url, params);
      dispatch({
        type: FETCH_PERMISSIONS,
        payload: response.data.items,
      });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), FETCH_PERMISSIONS_FAIL_MESSAGE, e, undefined, consoleOnly) as any);
    }
  };
}

export function fetchPermissionGroupList() {
  return async (dispatch: Dispatch) => {
    const url = getSecurityBaseUrl() + GROUPS_PATH;
    const params = { size: DEFAULT_PAGE_SIZE };
    try {
      const response = await getRequest(url, params);
      dispatch({
        type: FETCH_PERMISSION_GROUPS,
        payload: response.data.items,
      });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), FETCH_PERMISSION_GROUP_FAIL_MESSAGE, e, undefined, consoleOnly) as any);
    }
  };
}

export function fetchAssignedPermissionList(
  { group, regionUrl }: { group?: string, regionUrl?: string },
  byPassRedisCache: boolean = false
) {
  return async (dispatch: Dispatch) => {
    const url = getSecurityBaseUrl() + ASSIGNED_PERMISSION_PATH;

    const params = {
      size: DEFAULT_PAGE_SIZE,
      group,
      regionUrl,
      byPassRedisCache,
    };

    try {
      const response = await getRequest(url, params);
      dispatch({
        type: FETCH_ASSIGNED_PERMISSIONS,
        payload: response.data.items,
      });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), FETCH_ASSIGNED_PERMISSION_FAIL_MESSAGE, e, undefined, consoleOnly) as any);
    }
  };
}

export async function fetchGroupMembersWithFilter(
  groupId: number,
  regionUrl: string,
  partialName: string
): Promise<NameUrlKeyDto[]> {
  const url = getSecurityBaseUrl() + GROUP_MEMBERS_FILTER;
  const response = await getRequest(
    url,
    {
      groupId,
      regionUrl,
      partialName,
      size: 5,
    }
  );

  return response.data.items;
}

export async function postMember(groupUrl: string, userUrl: string, regionUrl?: string): Promise<GroupMemberDto> {
  const membersUrl = getSecurityBaseUrl() + GROUP_MEMBERS_PATH;

  const response = await postRequest(
    membersUrl,
    {
      group: { url: groupUrl },
      member: { url: userUrl },
    },
    { region: regionUrl }
  );

  return response?.data ?? null;
}

export async function putMember(permissionMember: { id: number }, regionUrl?: string): Promise<GroupMemberDto> {
  const membersUrl = `${getSecurityBaseUrl() + GROUP_MEMBERS_PATH}/${permissionMember.id}`;
  const response = await putRequest(
    membersUrl,
    permissionMember,
    { region: regionUrl }
  );

  return response?.data;
}

export async function deleteMember(id: number, regionUrl?: string) {
  try {
    const membersUrl = `${getSecurityBaseUrl()}${GROUP_MEMBERS_PATH}/${id}`;
    await deleteRequest(
      membersUrl,
      { region: regionUrl }
    );
  } catch (e) {
    recordLog(`Error deleting member ${id}`, e);
  }
}

export function userHasEditPermission(
  userPermissions: RegionAccessMap,
  regionUrl: string,
  permissionName: string
) {
  const userPermissionsByRegion = userPermissions[regionUrl];

  if (!userPermissionsByRegion) {
    return false;
  }
  const permission = permissionName === PERMISSION_NAMES.FEATURE_FLAGS
    ? userPermissionsByRegion[FEATURE_FLAG_ACCESS_FLAG]
    : userPermissionsByRegion[permissionName];

  if (!permission) {
    return false;
  }
  return permission.access === PermissionAccess.Edit;
}

export function userHasEditPermissionInAnyRegion(
  userPermissions: RegionAccessMap,
  permissionName: string
) {
  let hasAccess = false;

  Object.keys(userPermissions).forEach(regionUrl => {
    if (userHasEditPermission(userPermissions, regionUrl, permissionName)) {
      hasAccess = true;
    }
  });

  return hasAccess;
}

export function userHasViewPermission(
  userPermissions: RegionAccessMap,
  regionUrl: string,
  permissionName: string
) {
  const userPermissionsByRegion = userPermissions[regionUrl];
  if (!userPermissionsByRegion) {
    return false;
  }

  const permission = userPermissionsByRegion[permissionName];
  if (!permission) {
    return false;
  }

  return permission.access === PermissionAccess.View;
}

//  check permission for each category for Admin and store it in Redux for each category.
export function adminPermissionForUserInCurrentRegion(
  userPermissions: RegionAccessMap,
  currentRegionUrl: string,
  adminPermission: string
) {
  let result = PermissionAccess.Deny;
  const adminPermissionName = adminPermission.replace(/\s/g, '');

  if (userHasEditPermission(userPermissions, currentRegionUrl, adminPermissionName)) {
    result = PermissionAccess.Edit;
  } else if (userHasViewPermission(userPermissions, currentRegionUrl, adminPermissionName)) {
    result = PermissionAccess.View;
  }

  if (adminPermissionName === PERMISSION_NAMES.SECURITY_PERMISSIONS
    && userHasEditPermissionInAnyRegion(userPermissions, ADV_SECURITY_PERMS)) {
    result = PermissionAccess.Edit;
  }

  store.dispatch(setAdminPermissionAccess({
    adminPermission,
    result,
  }));
  return result;
}

export function getUserPermissions(params: any) {
  return async (dispatch: Dispatch): Promise<GroupMemberFullDto | null> => {
    const url = getSecurityBaseUrl() + GROUP_MEMBERS_FULL_MEMBER_PATH;
    try {
      const response = await getRequest(url, params);
      return response.data;
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_USER_PERMISSIONS, e, undefined, consoleOnly) as any);
      return null;
    }
  };
}

async function updateCurrentPermission(
  currentPermission: UserPermissionAccessDto,
  newAssignedPermission: NewAssignedPermissionDto,
  selectedRegionPermissions: UserPermissions,
  userUrl: string,
  region: UrlKeyDto
) {
  const { permission, access, group } = newAssignedPermission;
  const newAccessNum = getAccessNum(access);
  const currentAccessNum = getAccessNum(currentPermission.access);

  if (newAccessNum === currentAccessNum) {
    // check if groupsUrl list includes group.url
    const shouldAddToGroupUrls = currentPermission.groupUrls?.indexOf(group.url) === -1;
    if (shouldAddToGroupUrls) {
      currentPermission.groupUrls!.push(group.url);
    }
    return;
  }

  if (newAccessNum < currentAccessNum) {
    // handle user permission demotion
    selectedRegionPermissions[permission.name] = {
      ...currentPermission,
      access,
      groupUrls: [group.url],
    };

    return;
  }

  // handle user permission promotion
  if (currentPermission.groupUrls?.length === 1
    && currentPermission.groupUrls[0] === group.url) {
    selectedRegionPermissions[permission.name] = {
      ...currentPermission,
      access,
      groupUrls: [group.url],
    };

    return;
  }

  // if user belongs to multiple groups in region, permission is updated to the lowest in the category.
  const url = getSecurityBaseUrl() + GROUP_MEMBERS_FULL_MEMBER_PATH;
  const response = await getRequest(url, { memberUrl: userUrl, regionUrl: region.url });
  const userGroups = response.data.groups as GroupDto[];

  const assignedPermissions = userGroups.reduce((acc: any, g: any) => acc.concat(g.assignedPermissions), []) as AssignedPermissionDto[];

  const sortedSelectedPermissions = sortPermissionAccessLowToHigh(
    assignedPermissions.filter((ap: AssignedPermissionDto) => ap.permission?.url === permission.url)
  );

  const lowestAccessLevel = sortedSelectedPermissions[0].access;
  const lowestGroupUrls = sortedSelectedPermissions.filter(
    (p: AssignedPermissionDto) => p.access === lowestAccessLevel,
    (result: AssignedPermissionDto) => result.group?.url
  ).map(
    (result: AssignedPermissionDto) => result.group!.url!
  );

  selectedRegionPermissions[permission.name] = {
    ...currentPermission,
    access: lowestAccessLevel,
    groupUrls: lowestGroupUrls,
  };
}

// handle user permission update in a group user belongs to by updating USER_PERMISSION_OBJECT
export function handleUserPermissionUpdate(region: UrlKeyDto, newAssignedPermission: NewAssignedPermissionDto) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const { userPermission, userUrl } = getState();
    const newUserPermissionDictionary = {
      ...userPermission,
    };

    const { permission, access, group } = newAssignedPermission;
    const selectedRegionPermissions = userPermission[region.url!];
    const shouldUpdatePermission = Object.values(PERMISSION_NAMES).includes(permission.name ?? '');

    if (!shouldUpdatePermission) {
      return;
    }

    const currentPermission = selectedRegionPermissions[permission.name ?? ''];
    if (currentPermission) {
      await updateCurrentPermission(
        currentPermission,
        newAssignedPermission,
        selectedRegionPermissions,
        userUrl,
        region
      );
    } else {
      selectedRegionPermissions[permission.name ?? ''] = {
        permission: permission.name,
        permissionUrl: permission.url,
        access,
        groupUrls: [group.url!],
      };
    }
    newUserPermissionDictionary[region.url!] = { ...selectedRegionPermissions };
    dispatch(setUserPermissionObject(newUserPermissionDictionary));
  };
}

type UserPermissionAccessByPermissionName = {
  [permissionName: string]: UserPermissions;
};

export async function initializeNewRegion(region: RegionDto): Promise<UserPermissionAccessByPermissionName> {
  const url = `${getSecurityBaseUrl() + ASSIGNED_PERMISSION_PATH}/initialize-region`;

  const response = await postRequest(
    url,
    region
  );

  return response?.data ?? null;
}
