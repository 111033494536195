import { HaulerDto } from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import { FETCH_HAULERS } from '../../constants/actionConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const haulersReducer = (
  state = { haulers: [] as HaulerDto[] },
  action: PayloadAction<HaulerDto[], 'FETCH_HAULERS'>
) => {
  switch (action.type) {
    case FETCH_HAULERS:
      return {
        ...state,
        haulers: action.payload,
      };
    default:
      return state;
  }
};
