import React, { Component, ComponentType, ReactNode } from 'react';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  DiscardProgressCard, HeaderAndContainer, TTBreadCrumb,
} from '@trucktrax/trucktrax-common';
import { UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import { Link, Prompt, Redirect } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import styles from './AdminCard.module.css';
import {
  accessValue,
  getOtherRequiredFields,
  KeyRelationship,
} from '../../../util/adminUtil';
import Header from '../Header';
import { getRequest } from '../../../services/requestService';
import { devErrorAndLog } from '../../../util/errorUtil';
import DynamicForms from '../forms/DynamicForms';
import { isChangedValid, areAllRequiredValuesValid } from '../../../util/validation';
import { noop } from '../../../util/appUtil';
import {
  CHECKBOX_FORM,
  CHECKBOX_STATELESS_FORM,
  COMPOSITE,
  DISCARD_PROMPT_TEXT,
  DROPDOWN_FORM,
  INPUT_FORM,
  MAP_FORM,
  MAP_MULTIZONES_FORM,
  NONE_STRING,
  RADIO_FORM,
  NUMERIC_INPUT_FORM,
  INVALID_STALE_TOKEN_LOGOUT,
} from '../../../constants/appConstants';
import { NOT_FOUND_PATH } from '../../../constants/apiConstants';
import AddFeatureModal from '../AddFeatureModal';
import AdminActionButtons from './AdminActionButtons';
import trash from '../../../assets/img/trash-illus.svg';
import HTTP_CODES from '../../../constants/httpConstants';

type ErrorMessage = { message: string, zone?: Zone | null };
export type AdminErrors = { [key: string]: string | ErrorMessage };
export interface AdminResourceCardState {
  isCloseRequest: boolean;
  notFound: boolean;
  cardWidth: number | null,
  smallCardWidth: boolean
}

export class AdminResourceCard extends Component<AdminResourceCardProps, AdminResourceCardState> {
  static defaultProps: Partial<AdminResourceCardProps> = {
    launchToEdit: false,
    className: '',
    errorObject: {
      error: false,
      errorMessage: '',
    },
    pathName: '',
    options: { requiredKeys: [] },
    externalKeys: [],
    currentRegion: { url: '' },
    additionalComponents: [],
    disableEdit: false,
  };

  adminCardRef: React.RefObject<HTMLDivElement>;

  constructor(props: AdminResourceCardProps) {
    super(props);

    this.state = {
      isCloseRequest: false,
      notFound: false,
      cardWidth: null,
      smallCardWidth: false,
    };

    this.adminCardRef = React.createRef();
  }

  checkComponentWidth = () => {
    const existingRef = this.adminCardRef.current;
    if (existingRef) {
      setTimeout(() => {
        const width = existingRef.clientWidth;
        const isSmallCardWidth = width < 560;
        if (this.state.cardWidth !== width) {
          this.setState({
            smallCardWidth: isSmallCardWidth,
          });
        }
      }, 300); // Delay allows for animations in the UI to complete before trying to calculate component width
    }
  };

  componentDidMount() {
    this.fetchAdminRecord();
    // Component can change size/appearance either on browser resize or clicking on UI elements,
    // such as expanding or collapsing the Message Center panel
    window.addEventListener('resize', this.checkComponentWidth);
    window.addEventListener('click', this.checkComponentWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkComponentWidth);
    window.removeEventListener('click', this.checkComponentWidth);
  }

  componentDidUpdate(prevProps: AdminResourceCardProps) {
    if (this.props.launchToEdit && !prevProps.launchToEdit) {
      this.toggleEdit();
    }

    if (this.props.options?.shouldRefetchData) {
      this.fetchAdminRecord();
    }

    if (this.props !== prevProps) {
      this.checkComponentWidth();
    }
  }

  onSave = () => {
    const { value } = this.props;
    const validationErrors: AdminErrors = {};

    (this.props.config).forEach((field) => {
      const key = field.key!;
      if (value[key] && typeof value[key] === 'object' && value[key].url === null) {
        delete value[key];
      }
      if (field.customValidation) {
        const validationError = field.customValidation(value[key]);
        if (validationError) {
          validationErrors[key] = validationError;
        }
      }
    });

    if (!(Object.keys(validationErrors).length > 0)) {
      this.props.save(this.props.value, this.setData, (errors) => {
        this.props.alterErrors(errors);
      });
    } else {
      this.props.alterErrors(validationErrors);
    }
  };

  onError = (e: any) => {
    let consoleOnly = false;
    if (e.response) {
      const { status } = e.response;
      if (status === HTTP_CODES.not_found) {
        this.setState({ notFound: true });
        return;
      }
      if (status === HTTP_CODES.forbidden) {
        consoleOnly = true;
      }
    }
    if (e !== INVALID_STALE_TOKEN_LOGOUT) {
      this.props.devErrorAndLog('Unable to retrieve record', `AdminCard: ${e.toString()}`, undefined, undefined, consoleOnly);
    }
  };

  onSuccess = (response: AxiosResponse) => {
    if (this.props.options!.callback) {
      this.props.options!.callback(response);
    }
    this.setData(response);
  };

  setData = (response: AxiosResponse) => {
    let { data } = response;
    if (this.props.options!.alterData) {
      data = this.props.options!.alterData(data);
    }
    this.props.alterDetails(data);
  };

  getForm(config: FormConfig, key: string, value: any) {
    let updatedValue = value;
    if (config.type === INPUT_FORM) {
      config.persistedObject = this.props.details;
      config.errorMessage = this.props.errors[config.key!] as string;
    }
    if (config.type === DROPDOWN_FORM) {
      updatedValue = this.props.value[key];
      config.persistedObject = this.props.details;
      config.initialSelected = config.initialSelected ?? { name: `Please Select ${config.label}` };
    }
    if (config.type === RADIO_FORM) {
      config.errorMessage = this.props.errors[config.key!] as string;
    }
    if (config.type === CHECKBOX_FORM) {
      config.isSelected = this.props.details[config.key!];
      config.errorMessage = this.props.errors[config.key!] as string;
    }
    if (config.type === CHECKBOX_STATELESS_FORM) {
      config.errorMessage = this.props.errors[config.key!] as string;
    }
    if (config.type === NUMERIC_INPUT_FORM) {
      config.isSelected = this.props.details[config.key!];
      config.errorMessage = this.props.errors[config.key!] as string;
    }
    if (config.type === MAP_FORM) {
      if (this.props.value.polygon !== undefined) {
        config.polygon = this.props.value.polygon;
      }
      if (this.props.value.circle !== undefined) {
        config.circle = this.props.value.circle;
      }
    }
    if (config.type === MAP_MULTIZONES_FORM) {
      const error = this.props.errors[config.key!] as ErrorMessage;
      if (error && error.message) {
        config.errorMessage = error.message;
        // switch to the tab/zone that the error happened on
        if (config.handleSelectedZoneChange) {
          // only change the selected zone if the error.zone is not null
          // and different than the config.selectedZone
          if (error.zone && config.selectedZone !== error.zone) {
            config.handleSelectedZoneChange(error.zone);
            // ensure that the tab/zone is only changed
            // once by setting error.zone to null
            error.zone = null;
          }
        }
      } else {
        config.errorMessage = error;
      }
    }

    return (
      <DynamicForms
        id={key}
        edit={this.props.edit}
        onTouch={() => { }}
        setData={this.setData}
        {...config}
        component={config.component!}
        className={config.className}
        key={key}
        type={config.type}
        value={updatedValue}
      />
    );
  }

  isChangedValid() {
    if (this.props.customChangeCheck) {
      return this.props.customChangeCheck();
    }
    return isChangedValid(this.props.details, this.props.value);
  }

  isRequiredValid() {
    const compositeComponents = this.props.config.filter(form => form.type === COMPOSITE);
    const compositeForms = compositeComponents.reduce<CompositeComponentInfo[]>((acc, c) => [...acc, ...(c.components ?? [])], []);
    const allForms = [...this.props.config, ...compositeForms];
    const requiredComponents = allForms.filter(form => !!form.isRequired && !form.disabled);
    const keysArray = requiredComponents.map(form => form.key!);

    // add geozone data to validation list
    const { requiredKeys } = this.props.options!;
    getOtherRequiredFields(requiredKeys!, keysArray, this.props.value);
    return areAllRequiredValuesValid(keysArray, this.props.value);
  }

  fetchAdminRecord = async () => {
    let url = `${this.props.url}`;
    url = url.replace('/glinx', '');
    try {
      const currentRegionUrl = this.props.currentRegion!.url;
      const params = { region: currentRegionUrl };
      const response: AxiosResponse = await getRequest(url, params);
      this.onSuccess(response);
    } catch (e) {
      this.onError(e);
    }
  };

  static generateBreadcrumb = (pathName: string, header: string) => {
    const rtnVal = [];
    let backLink = '';
    let backLinkLabel = '';

    // generate back link
    const currPath = pathName;
    const arrPath = currPath.split('/');
    const arrPathLinkBack = [...arrPath.slice(0, 3)];
    backLink = arrPathLinkBack.join('/');

    if (backLink.includes('GLinx')) {
      backLink = backLink.replace('devices/glinx', 'devices');
    }
    // generate link label
    const initial = arrPath[2];

    if (initial) {
      const arrLabel = initial.split('-');

      if (arrLabel.length === 2) {
        backLinkLabel = `${arrLabel[0].charAt(0).toUpperCase()}${arrLabel[0].slice(1)} `;
        backLinkLabel += `${arrLabel[1].charAt(0).toUpperCase()}${arrLabel[1].slice(1)}`;
      } else {
        backLinkLabel = initial.charAt(0).toUpperCase() + initial.slice(1);
      }
    }

    // constructing the object to pass to BreadCrumb
    // first breadcrumb link
    rtnVal.push(
      {
        toLink: backLink,
        linkLabel: backLinkLabel,
      }
    );

    // secondary breadcrumb link
    if (arrPath[4]) {
      let secondaryBackLinkLabel = '';
      let secondaryBackLink = '';
      const labelFromLink = arrPath[3];

      secondaryBackLinkLabel = labelFromLink.charAt(0).toUpperCase() + labelFromLink.slice(1);

      // generate secondary back link
      const arrPathLinkSecondaryBack = [...arrPath.slice(0, 4)];
      secondaryBackLink = arrPathLinkSecondaryBack.join('/');

      rtnVal.push(
        {
          toLink: secondaryBackLink,
          linkLabel: secondaryBackLinkLabel,
        }
      );
    }

    // last breadcrumb
    rtnVal.push(
      {
        linkLabel: header,
      }
    );

    return rtnVal;
  };

  requestToggleEdit = async () => {
    if (this.isChangedValid()) {
      this.setState({ isCloseRequest: !this.state.isCloseRequest });
    } else {
      await this.toggleEdit();
    }
  };

  toggleEdit = async () => {
    this.props.onToggleEdit();
    // fetchAdminRecord is needed to refresh state.value and state.details
    // for screens that activate and deactivate records (Drivers, Users,...)
    await this.fetchAdminRecord();
    this.setState({
      isCloseRequest: false,
    });
    this.props.alterErrors({});
    this.props.alterValue(this.props.details);
  };

  dynamicForms = (config: AdminCardConfig, i: number) => {
    const {
      accessor,
      items,
      itemFilter,
    } = config;
    const details = this.props.details?.id ? this.props.details : undefined;
    const accessed = this.props.edit ? this.props.value : details;
    const emptyText = this.props.edit ? '' : undefined;
    let value = accessValue(accessor!, accessed, undefined, emptyText);
    const key = config.key || String(i);
    config.items = itemFilter ? itemFilter(items, this.props.value) : items;

    if (config.type === COMPOSITE) {
      const components = config.components!.map(item => {
        const v = accessValue(item.accessor, this.props.value, undefined, '');
        return this.getForm(item, item.key, v);
      });

      value = accessValue(accessor!, accessed, NONE_STRING, NONE_STRING);
      return (
        <div key={key} className={config.className}>
          {components}
        </div>
      );
    }
    return this.getForm(config, key, value);
  };

  render() {
    if (this.state.notFound) {
      return (
        <Redirect to={NOT_FOUND_PATH} />
      );
    }

    const {
      children, pathName, config, additionalComponents, disableEdit, headerAccessor,
    } = this.props;

    // styles
    const cardStyle = {
      root: styles.card,
    };

    // determine header to display
    let header = '';

    if (this.props.details && this.props.details.id) {
      header = accessValue(this.props.headerAccessor as string, this.props.details);
      // this is an exception for devices only since name is not required
      header = header || 'No name';
    }

    // generate bread crumb data
    const breadcrumbData = AdminResourceCard.generateBreadcrumb(pathName!, header);

    const card = (
      <div className={styles.bodyWrapper} ref={this.adminCardRef}>
        <div className={styles.cardWrapper}>
          <Card classes={cardStyle}>
            <CardContent>
              <div className={cx(
                this.props.className,
                styles.cardText,
                this.state.smallCardWidth && styles.cardTextSmall
              )}
              >
                {config.map(this.dynamicForms)}
                {children}
              </div>
            </CardContent>
          </Card>
        </div>
        {additionalComponents!.length > 0 ? additionalComponents!.map((additionalComponent: any) => (
          <div className={styles.additionalComponent}>
            {additionalComponent}
          </div>
        )) : null}
        <AddFeatureModal
          title=""
          isOpen={this.state.isCloseRequest}
          onCancel={noop}
        >
          <DiscardProgressCard
            discardAcceptAction={this.toggleEdit}
            discardRejectAction={this.requestToggleEdit}
            imageSrc={trash}
            altText="Trash"
          />
        </AddFeatureModal>
        <Prompt
          when={this.props.edit && this.isChangedValid()}
          message={DISCARD_PROMPT_TEXT}
        />
      </div>
    );

    const breadCrumb = (
      <TTBreadCrumb
        data={breadcrumbData}
        linkComponent={Link}
      />
    );

    const elementLeft = (
      <Header
        title={header}
        subtitle={breadCrumb}
      />
    );

    const elementsRight = (
      <AdminActionButtons
        configData={config}
        isDisabled={this.props.disabled}
        disableEdit={disableEdit || !this.props.details}
        overrideDisabledReason={!this.props.details ? '' : undefined}
        toggleEdit={this.toggleEdit}
        isChangedValid={this.isChangedValid()}
        isRequiredValid={this.isRequiredValid()}
        onSave={this.onSave}
        requestToggleEdit={this.requestToggleEdit}
        category={headerAccessor as string}
      />
    );

    return (
      <HeaderAndContainer
        elementsLeft={elementLeft}
        elementsRight={elementsRight}
        containerComponent={card}
        containerStyle={styles.headerAndContainer}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentRegion: state.currentRegion,
});

type Zone = any;
type CompositeComponentInfo = {
  type: string;
  key: string;
  accessor: string;
  label: string;
  dataTest: string;
  errorDataTest: string;
  className: string;
  isRequired: boolean,
  maxLength: number,
  disabled?: boolean
};

export type AdminCardConfig = {
  key?: string;
  className?: string;
  components?: CompositeComponentInfo[]
  component?: ComponentType<any>;
  isRequired?: boolean;
  type: string;
  accessor?: string | ((value: any) => any);
  items?: any[];
  itemFilter?: (item: any, value: any) => any[];
  customValidation?: (value: any) => string;
  [other: string]: any;
};

export interface FormConfig {
  type: string;
  key?: string;
  label?: string;
  persistedObject?: any;
  initialSelected?: any;
  errorMessage?: string | ErrorMessage;
  checked?: boolean;
  polygon?: any;
  circle?: any;
  selectedZone?: Zone | null;
  component?: ComponentType<any>;
  className?: string;
  isSelected?: boolean;
  handleSelectedZoneChange?: (zone: Zone) => void;
}

export type KeyInfo = {
  keys: string[];
  relationship: string;
};

export interface AdminResourceCardProps {
  edit: boolean;
  launchToEdit?: boolean;
  url: string;
  config: AdminCardConfig[]
  className?: string;
  onToggleEdit: () => void;
  save: (
    value: any,
    onData: (response: AxiosResponse) => void,
    onError: (errors: AdminErrors) => void
  ) => void;
  errorObject?: {
    error: boolean;
    errorMessage: string;
  };
  pathName?: string;
  devErrorAndLog: (modalTitle: string, modalBody: string, errorObject?: any, extraInfo?: string, consoleOnly?: boolean) => void;
  externalKeys?: string[];
  headerAccessor: string | ((...args: any) => void);
  options?: {
    callback?: (response: AxiosResponse) => void;
    alterData?: (data: any) => any;
    additionalUrl?: string;
    requiredKeys?: KeyRelationship[];
    additionalData?: any;
    shouldRefetchData?: boolean;
  };
  currentRegion?: UrlKeyDto;
  additionalComponents?: ComponentType[] | ReactNode[];
  disableEdit?: boolean;
  details: any | null;
  alterDetails: (details: any) => any;
  value: any;
  alterValue: (value: any) => any;
  errors: AdminErrors;
  alterErrors: (errors: AdminErrors) => AdminErrors;
  customChangeCheck?: () => boolean;
  disabled: boolean;
  children?: ReactNode;
}

export default connect<any, any, any>(mapStateToProps, {
  devErrorAndLog,
})(AdminResourceCard);
