import { Action } from 'redux';
import { SidebarSelection } from '@trucktrax/trucktrax-common';
import { PayloadAction } from '../../types';
import {
  SET_OPEN_STATE_MAP,
  SET_SIDEBAR_SELECTED,
  TOGGLE_DROPDOWN_IS_OPEN,
  TOGGLE_GUIDE_DROPDOWN_IS_OPEN,
} from '../../constants/actionConstants';
import { GEOTRAX_PAGE_SELECTED, MESSAGE_SELECTED } from '../../constants/appConstants';
import { NavItem } from '../../constants/navConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const sidebar = (
  state = { sidebarTitle: MESSAGE_SELECTED, isSelected: false },
  action: { type: string, payload: SidebarSelection }
) => {
  switch (action.type) {
    case SET_SIDEBAR_SELECTED:
      return {
        sidebarTitle: action?.payload?.sidebarTitle ?? state.sidebarTitle,
        isSelected: action?.payload?.isSelected ?? state.isSelected,
      } as SidebarSelection;
    default:
      return state as SidebarSelection;
  }
};

export const getCurrentPageUrl = () => ({
  navRoute: window.location.pathname,
});

export const geotraxPage = (
  state = getCurrentPageUrl(),
  action: { type: string, payload: NavItem }
) => {
  switch (action.type) {
    case GEOTRAX_PAGE_SELECTED:
      return action.payload;
    default:
      return state;
  }
};

// this exists in redux since requirement is to keep state when switching to admin
export const dropdownIsOpen = (
  state = true,
  action: Action<'TOGGLE_DROPDOWN_IS_OPEN'>
) => {
  switch (action.type) {
    case TOGGLE_DROPDOWN_IS_OPEN:
      return !state;
    default:
      return state;
  }
};

export const guideDropdownIsOpen = (
  state = true,
  action: Action<'TOGGLE_GUIDE_DROPDOWN_IS_OPEN'>
) => {
  switch (action.type) {
    case TOGGLE_GUIDE_DROPDOWN_IS_OPEN:
      return !state;
    default:
      return state;
  }
};

// this exists in redux since requirement is to keep state when switching to admin
type OpenByIndexMap = { [index: number]: boolean };
export const openStateMap = (
  state: OpenByIndexMap = {},
  action: PayloadAction<{ [key: number]: boolean }, 'SET_OPEN_STATE_MAP'>
): OpenByIndexMap => {
  switch (action.type) {
    case SET_OPEN_STATE_MAP:
      return { ...action.payload };
    default:
      return state;
  }
};
