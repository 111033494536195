import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { PermissionAccess } from '@trucktrax/trucktrax-ts-common';
import DataTableContent from '../../shared/DataTableContent';
import {
  addDataTest,
  addHeaderDataTest,
  capitalizeFirst,
  formatScaleMaxWeight,
} from '../../../util/adminUtil';
import { SCALES_TEXT } from '../../../constants/navConstants';
import { ADMIN_KEYS, ADMIN_LABELS } from '../../../constants/appConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { DATASOURCE, PLANTS_PATH, SCALES_PATH } from '../../../constants/apiConstants';
import styles from './ScalesView.module.css';
import ScalesAddModal from './ScalesAddModal';

export interface ScalesViewState {
  isOpen: boolean,
  isCloseRequest: boolean
}

export class ScalesView extends Component<ScalesViewProps, ScalesViewState> {
  static defaultProps = {
    tab: 0,
    scalesPermissionAccess: '',
  };

  state = {
    isOpen: false,
    isCloseRequest: false,
  };

  baseUrl = `${getGeotraxBaseUrl() + PLANTS_PATH}${SCALES_PATH}/all`;

  static getColumns = () => [
    {
      Header: addHeaderDataTest(null, 'sort-scale-by-name', ADMIN_LABELS.SCALE_NAME),
      id: ADMIN_KEYS.NAME,
      accessor: ADMIN_KEYS.NAME,
      minWidth: 100,
      Cell: (props: { value: string; }) => addDataTest(null, 'sort-scale-by-name', props.value),
    },
    {
      Header: addHeaderDataTest(null, 'sort-scale-by-plant', ADMIN_LABELS.SCALE_PLANT),
      id: ADMIN_KEYS.PLANT_NAME,
      accessor: ADMIN_KEYS.PLANT_NAME,
      minWidth: 150,
      Cell: (props: { value: string; }) => addDataTest(null, 'sort-scale-by-plant', props.value),
    },
    {
      Header: addHeaderDataTest(null, 'sort-scale-by-type', ADMIN_LABELS.SCALE_TYPE),
      id: ADMIN_KEYS.SCALETYPE,
      accessor: ADMIN_KEYS.SCALETYPE,
      minWidth: 100,
      Cell: (props: any) => addDataTest(null, 'sort-scale-by-type', capitalizeFirst(props.value)),
    },
    {
      Header: addHeaderDataTest(null, 'sort-scale-by-maxweight', ADMIN_LABELS.SCALE_MAXWEIGHT),
      id: ADMIN_KEYS.MAXWEIGHT,
      accessor: (row: any) => formatScaleMaxWeight(row),
      minWidth: 100,
    },
    {
      Header: addHeaderDataTest(null, 'sort-scale-by-netaddress', ADMIN_LABELS.SCALE_NETADDRESS),
      id: ADMIN_KEYS.NETADDRESS,
      accessor: ADMIN_KEYS.NETADDRESS,
      minWidth: 100,
      Cell: (props: { value: string; }) => addDataTest(null, 'sort-scale-by-netaddress', props.value ? props.value : '-'),
    }];

  handleTabChange = (_event: any, newValue: number) => {
    if (newValue === 1) {
      this.props.history.push('/admin/scales/deactivated');
    } else if (newValue === 0) {
      this.props.history.push('/admin/scales');
    }
  };

  requestCloseModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      isCloseRequest: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: false,
    });
  };

  render() {
    const hasPermissionAccess = this.props.scalesPermissionAccess === PermissionAccess.Edit;
    const columns = ScalesView.getColumns();
    const tabs = (
      <Tabs
        className={styles.tabs}
        value={this.props.tab}
        onChange={this.handleTabChange}
        classes={{ root: styles.tabsContainer }}
      >
        <Tab label="Active" />
        <Tab label="Deactivated" />
      </Tabs>
    );

    const props: any = {
      baseUrl: this.baseUrl,
      columns,
      header: SCALES_TEXT,
      renderTabs: tabs,
      isArchived: this.props.tab === 1,
      canAddNewItem: hasPermissionAccess,
      source: DATASOURCE.SCALE
    };

    return (
      <div className={styles.container}>
        <DataTableContent {...props} />
        {hasPermissionAccess
          && (
            <ScalesAddModal
              isOpen={this.state.isOpen}
              openAddModal={this.openModal}
              closeAddModal={this.requestCloseModal}
              discardAcceptAction={this.closeModal}
              discardRejectAction={this.openModal}
              isCloseRequest={this.state.isCloseRequest}
            />
          )}
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  scalesPermissionAccess: state.adminPermissionAccess[SCALES_TEXT],
});

interface History {
  push: (p: string) => void
}

export interface ScalesViewProps {
  scalesPermissionAccess?: string,
  tab: number,
  history: History
}
export default connect(mapStateToProps, {})(ScalesView);
