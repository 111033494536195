import {
  CLEAR_SELECTED_MARKER,
  CLOSE_ALL_POPUPS,
  CLOSE_POPUP,
  OPEN_POPUP,
  REFRESH_POSITIONS,
  SELECT_MARKER,
  SET_IS_PANNED,
  SET_POSITION_LIST,
  UPDATE_POSITION,
  ACTIVE_PIN_MAP,
  SET_PIN_RADIUS,
} from '../../constants/actionConstants';
import { DEFAULT_RADIUS } from '../../constants/mapConstants';
import { PositionDetailsDto, PayloadAction } from '../../types';
import { MarkerInfo, PinLocationInfo } from '../actions/mapviewActions';

// state contains map of open popups { 135: true, 136: false }
// payload is markerID
export const popupToggleMap = (
  state = { lastMarkerSelected: null },
  action: PayloadAction<string, 'OPEN_POPUP' | 'CLOSE_POPUP' | 'CLOSE_ALL_POPUPS'>
): { lastMarkerSelected?: string | null } => {
  switch (action.type) {
    case OPEN_POPUP:
      return {
        ...state,
        ...{ [action.payload]: true },
        lastMarkerSelected: action.payload,
      };
    case CLOSE_POPUP:
      return { ...state, ...{ [action.payload]: false } };
    case CLOSE_ALL_POPUPS:
      // returns new object with mapped values
      return {};
    default:
      return state;
  }
};

export const selectedMarker = (
  state: Partial<MarkerInfo> = {
    isPannedTo: false,
  },
  action: PayloadAction<MarkerInfo, 'SELECT_MARKER' | 'SET_IS_PANNED' | 'CLEAR_SELECTED_MARKER'>
): Partial<MarkerInfo> => {
  switch (action.type) {
    case SELECT_MARKER:
      return {
        ...state,
        ...action.payload,
        isPannedTo: false,
      };
    case SET_IS_PANNED:
      return {
        ...state,
        isPannedTo: true,
      };
    case CLEAR_SELECTED_MARKER:
      return {
        isPannedTo: false,
      };
    default:
      return state;
  }
};

export const positionList = (
  state: PositionDetailsDto[] = [],
  action: PayloadAction<PositionDetailsDto | PositionDetailsDto[]>
): PositionDetailsDto[] => {
  switch (action.type) {
    case SET_POSITION_LIST:
      return action.payload as PositionDetailsDto[];
    case UPDATE_POSITION: {
      // if it exists, it updates position
      const some = state.some(position => position.id === (action.payload as PositionDetailsDto).id);
      if (some) {
        return state.map(
          (position) => {
            // updates new properties without deleting previous ones
            if (position.id === (action.payload as PositionDetailsDto).id) {
              return {
                ...position,
                ...action.payload,
                // updates new trackedObject properties without deleting previous ones
                trackedObject: {
                  ...position.trackedObject,
                  ...(action.payload as PositionDetailsDto).trackedObject,
                },
              };
            }
            return position;
          }
        );
      }
      // otherwise, adds non existent position
      return [...state, action.payload as PositionDetailsDto];
    }
    // refreshes positions to update UI
    case REFRESH_POSITIONS:
      return [...state];
    default:
      return state;
  }
};

/**
 * Activates the pin map
 * @param {PinLocationInfo} state current state of the pin
 * @param {PayloadAction<PinLocationInfo, 'ACTIVE_PIN_MAP'>} action action with the new values
 * @returns {PinLocationInfo} pin location info response
 */
export const activePinMap = (
  state = {
    pinMode: false,
    pinDropped: false,
    removePin: false,
    location: '',
    lat: undefined,
    lng: undefined,
  } as PinLocationInfo,
  action: PayloadAction<PinLocationInfo, 'ACTIVE_PIN_MAP'>
) => {
  if (action.type === ACTIVE_PIN_MAP) {
    return action.payload;
  }
  return state;
};

/**
 * Sets the current pin radius
 * @param {string} state current state of the pin radius
 * @param {PayloadAction<string, 'SET_PIN_RADIUS'>} action action with the new values
 * @returns {string} radius response
 */
export const pinRadiusMiles = (
  state = DEFAULT_RADIUS.toString(),
  action: PayloadAction<string, 'SET_PIN_RADIUS'>
) => {
  if (action.type === SET_PIN_RADIUS) {
    return action.payload as string;
  }
  return state;
};
