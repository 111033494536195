import { QualityFactorDto, TicketDto } from '@trucktrax/trucktrax-ts-common';
import {
  ADD_TICKETS,
  CLEAR_SELECTED_TICKET,
  EMPTY_PAST_TICKET,
  REMOVE_TICKET,
  RESET_CURRENT_TICKETS_COLUMNS,
  RESET_PAST_TICKET_DATES,
  RESET_TICKET_VIEW,
  SET_CURRENT_TICKETS_COLUMNS,
  SET_PAST_TICKET_CURRENT_PAGE,
  SET_PAST_TICKET_LIST,
  SET_PAST_TICKET_MAX_PAGES,
  SET_PAST_TICKET_FILTERED_TOTAL,
  SET_SELECTED_TICKET,
  SET_TICKET_LIST,
  SET_TICKET_VIEW,
  SET_TICKET_QUALITY_FACTORS,
  SYNC_TICKET,
  TOGGLE_TICKETS_POPOVER,
  UPDATE_PAST_TICKET_END_DATE,
  UPDATE_PAST_TICKET_START_DATE,
  UPDATE_TICKET_TIMELINE,
  SET_SCALETRAX_SEARCH_FILTERS,
  SAVE_TICKET_LIST_FILTERS,
  SET_CENTER_TICKET_PRINT_VIEW,
  IS_OPEN_TICKET,
  NEED_TO_REFRESH_DATA,
} from '../../constants/actionConstants';
import currentTicketColumns from '../../components/shared/tickets/ticketListConfigurations/currentTickets';
import { ScaleTraxSearchFilters, TicketListFilters } from '../../types';

export const setTicketList = (ticketList: TicketDto[]) => ({
  type: SET_TICKET_LIST,
  payload: ticketList,
});

export const setScaleTraxSearchFilters = (scaleTraxSearchFilters: ScaleTraxSearchFilters) => ({
  type: SET_SCALETRAX_SEARCH_FILTERS,
  payload: scaleTraxSearchFilters,
});

export const setPastTickets = (ticketList: TicketDto[]) => ({
  type: SET_PAST_TICKET_LIST,
  payload: ticketList,
});

export const addPastTickets = (ticketList: TicketDto) => ({
  type: ADD_TICKETS,
  payload: ticketList,
});

export const setPastTicketCurrentPage = (currentPage: number) => ({
  type: SET_PAST_TICKET_CURRENT_PAGE,
  payload: currentPage,
});

export const setPastTicketMaxPages = (maxPages: number) => ({
  type: SET_PAST_TICKET_MAX_PAGES,
  payload: maxPages,
});

export const setPastTicketFilteredTotal = (filteredTotal: number) => ({
  type: SET_PAST_TICKET_FILTERED_TOTAL,
  payload: filteredTotal,
});

export const emptyPastTicketList = () => ({
  type: EMPTY_PAST_TICKET,
});

export const updatePastTicketStartDate = (newStartDate: string) => ({
  type: UPDATE_PAST_TICKET_START_DATE,
  payload: newStartDate,
});

export const updatePastTicketEndDate = (newEndDate: string) => ({
  type: UPDATE_PAST_TICKET_END_DATE,
  payload: newEndDate,
});

export const resetPastTicketDates = () => ({
  type: RESET_PAST_TICKET_DATES,
});

export const updateTicketTimeline = (newTimeline?: 'current' | 'past') => {
  const timeline = newTimeline || 'current';
  return {
    type: UPDATE_TICKET_TIMELINE,
    payload: timeline,
  };
};

export const syncTicket = (ticket: TicketDto) => ({
  type: SYNC_TICKET,
  payload: ticket,
});

export const removeTicket = (ticket: TicketDto) => ({
  type: REMOVE_TICKET,
  payload: ticket,
});

export const toggleTicketsPopover = (value: boolean) => ({
  type: TOGGLE_TICKETS_POPOVER,
  payload: value,
});

export const selectTicket = (ticket: TicketDto) => ({
  type: SET_SELECTED_TICKET,
  payload: ticket,
});

export const clearSelectedTicket = () => ({
  type: CLEAR_SELECTED_TICKET,
});

export const updateTicketView = (view: string) => ({
  type: SET_TICKET_VIEW,
  payload: view,
});

export const resetTicketView = () => ({
  type: RESET_TICKET_VIEW,
});

export const resetCurrentTicketsColumns = () => ({
  type: RESET_CURRENT_TICKETS_COLUMNS,
});

type GetCurrentColumnsFunction = typeof currentTicketColumns;
export type CurrentColumns = ReturnType<GetCurrentColumnsFunction>;

export const setCurrentTicketsColumns = (columns: CurrentColumns) => ({
  payload: columns,
  type: SET_CURRENT_TICKETS_COLUMNS,
});

export const setCurrentTicketQualityFactors = (qf: QualityFactorDto[]) => ({
  payload: qf,
  type: SET_TICKET_QUALITY_FACTORS,
});

export const saveTicketListFilters = (filters: TicketListFilters) => ({
  payload: filters,
  type: SAVE_TICKET_LIST_FILTERS,
});

export const centerTicketPrintView = (isPrintView: boolean) => ({
  type: SET_CENTER_TICKET_PRINT_VIEW,
  payload: isPrintView,
});

export const isOpenTicket = (isOpenTicket: boolean) => ({
  type: IS_OPEN_TICKET,
  payload: isOpenTicket,
});

export const needToRefreshData = (needToRefreshData: boolean) => ({
  type: NEED_TO_REFRESH_DATA,
  payload: needToRefreshData,
});
