import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { PermissionAccess } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import DataTableContent from '../../shared/DataTableContent';
import {
  addDataTest, addHeaderDataTest, getAddress, getRegionUrlToNameMap, getUrl,
} from '../../../util/adminUtil';
import PlantsAddModal from './PlantsAddModal';
import { PLANTS_TEXT } from '../../../constants/navConstants';
import { ADMIN_KEYS, ADMIN_LABELS, NOT_AVAILABLE } from '../../../constants/appConstants';
import { DATASOURCE, PLANTS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import styles from './PlantsView.module.css';

export class PlantsView extends Component<PlantsViewProps> {
  static defaultProps = {
    regions: [],
    regionList: [],
    currentRegion: '',
    plantsPermissionAccess: '',
    tab: 0,
  };

  state = {
    isOpen: false,
    isCloseRequest: false,
  };

  getColumns() {
    if (this.props.tab === 1) {
      return [
        {
          Header: addHeaderDataTest(null, 'sort-plant-by-name', ADMIN_LABELS.PLANT_NAME),
          accessor: ADMIN_KEYS.NAME,
          minWidth: 250,
          Cell: (props: any) => addDataTest(props, 'admin-plant-name', props.value),
        }, {
          Header: addHeaderDataTest(null, 'sort-plant-by-externalId', ADMIN_LABELS.EXTERNAL_ID),
          accessor: ADMIN_KEYS.EXTERNAL_ID,
          minWidth: 130,
          style: {
            fontWeight: '900',
          },
          Cell: (props: { value: string; }) => addDataTest(null, 'sort-plant-by-externalId', props.value),
        }, {
          Header: ADMIN_LABELS.PRODUCT_LINE,
          id: ADMIN_KEYS.PRODUCT_LINE,
          accessor: ADMIN_KEYS.PRODUCT_LINE,
          minWidth: 190,
          Cell: (props: { value: string; }) => addDataTest(null, 'admin-plants-product-line', props.value || NOT_AVAILABLE),
        }, {
          Header: ADMIN_LABELS.REGION,
          id: ADMIN_KEYS.REGION,
          accessor: (row: any) => getRegionUrlToNameMap(this.props.regionList)[getUrl(row)!],
          minWidth: 100,
          sortable: false,
        },
        {
          Header: addHeaderDataTest(null, 'sort-plants-by-deactivated-date', ADMIN_LABELS.DEACTIVATED_DATE),
          id: ADMIN_KEYS.ARCHIVED_DATE,
          accessor: ADMIN_KEYS.ARCHIVED_DATE,
          minWidth: 110,
          Cell: (props: { value: string; }) => addDataTest(
            null,
            'admin-plants-deactivated-date',
            props.value && new DateWrapper(props.value).toUSDateString()
          ),
        },
      ];
    }
    return [
      {
        Header: addHeaderDataTest(null, 'sort-plant-by-name', ADMIN_LABELS.PLANT_NAME),
        accessor: ADMIN_KEYS.NAME,
        minWidth: 250,
        Cell: (props: any) => addDataTest(props, 'admin-plant-name', props.value),
      }, {
        Header: addHeaderDataTest(null, 'sort-plant-by-externalId', ADMIN_LABELS.EXTERNAL_ID),
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        minWidth: 130,
        style: {
          fontWeight: '900',
        },
        Cell: (props: { value: string; }) => addDataTest(null, 'sort-plant-by-externalId', props.value),
      }, {
        Header: addHeaderDataTest(null, 'sort-plant-by-address', ADMIN_LABELS.ADDRESS),
        id: ADMIN_KEYS.ADDRESS_LINE_ONE,
        accessor: getAddress,
        minWidth: 350,
        Cell: (props: { value: string; }) => addDataTest(null, 'admin-plant-address', props.value),
      }, {
        Header: ADMIN_LABELS.PRODUCT_LINE,
        id: ADMIN_KEYS.PRODUCT_LINE,
        accessor: ADMIN_KEYS.PRODUCT_LINE,
        minWidth: 190,
        Cell: (props: { value: string; }) => addDataTest(null, 'admin-plants-product-line', props.value || NOT_AVAILABLE),
      }, {
        Header: ADMIN_LABELS.REGION,
        id: ADMIN_KEYS.REGION,
        accessor: (row: any) => getRegionUrlToNameMap(this.props.regionList)[getUrl(row)!],
        minWidth: 100,
        sortable: false,
      }, {
        Header: addHeaderDataTest(null, 'sort-plant-by-ownership-type', ADMIN_LABELS.OWNERSHIP_TYPE),
        id: ADMIN_KEYS.OWNERSHIP_TYPE,
        accessor: ADMIN_KEYS.OWNERSHIP_TYPE,
        minWidth: 190,
        Cell: (props: { value: string; }) => addDataTest(null, 'admin-plants-ownership-type', props.value || NOT_AVAILABLE),
      },
    ];
  }

  requestCloseModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      isCloseRequest: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: false,
    });
  };

  handleTabChange = (_event: any, newValue: number) => {
    if (newValue === 1) {
      this.props.history.push('/admin/plants/deactivated');
    } else if (newValue === 0) {
      this.props.history.push('/admin/plants');
    }
  };

  render() {
    const baseUrl = getGeotraxBaseUrl() + PLANTS_PATH;
    const columns = this.getColumns();
    const hasPermissionAccess = this.props.plantsPermissionAccess === PermissionAccess.Edit;

    const tabs = (
      <Tabs
        value={this.props.tab}
        onChange={this.handleTabChange}
        classes={
          {
            root: styles.tabsContainer,
          }
        }
      >
        <Tab label="Active" />
        <Tab label="Deactivated" />
      </Tabs>
    );
    const props: any = {
      baseUrl,
      columns,
      header: PLANTS_TEXT,
      renderTabs: tabs,
      isArchived: this.props.tab === 1,
      canAddNewItem: hasPermissionAccess,
      source: DATASOURCE.PLANT
    };
    return (
      <div className={styles.container}>
        <DataTableContent {...props} />
        {hasPermissionAccess && (
          <PlantsAddModal
            isOpen={this.state.isOpen}
            openModal={this.openModal}
            closeModal={this.requestCloseModal}
            regions={this.props.regions}
            discardAcceptAction={this.closeModal}
            discardRejectAction={this.openModal}
            isCloseRequest={this.state.isCloseRequest}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  regionList: state.regionList,
  regions: state.assignedRegionList,
  currentRegion: state.currentRegion,
  plantsPermissionAccess: state.adminPermissionAccess[PLANTS_TEXT],
});

type History = {
  push: (p: string) => void
};

export interface PlantsViewProps {
  regionList: any,
  regions: any[],
  plantsPermissionAccess?: string
  tab: number,
  history: History
}
export default connect(mapStateToProps)(PlantsView);
