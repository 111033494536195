import React from 'react';
import ReactDOM from 'react-dom/client';
import '@trucktrax/trucktrax-common/src/styles/tt_icomoon.css';
import '@trucktrax/trucktrax-common/src/styles/components/tt_fonts.css';
import { LoadScript } from '@react-google-maps/api';
import './styles/global.css';
import { Provider } from 'react-redux';
import Main, { store } from './Main';
import { useTracer } from './telemetry/Tracer';

const AppInstrumentationWrapper = () => <Main {...{ tracer: useTracer() }} />;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <LoadScript
    googleMapsApiKey=""
    googleMapsClientId={process.env.REACT_APP_GOOGLE_API_KEY}
    libraries={['drawing', 'geometry', 'places']}
  >
    {/* @ts-ignore */}
    <Provider store={store}>
      {/* @ts-ignore */}
      <AppInstrumentationWrapper />
    </Provider>
  </LoadScript>
);
