import React from 'react';
import cx from 'classnames';
import { constants as C } from '@trucktrax/trucktrax-common';
import styles from './MarkerLabel.module.css';

export default function MarkerLabel(props: MarkerLabelProps) {
  const { name, color }: MarkerLabelProps = props;
  return (
    <div
      className={styles.label}
      style={{ background: color }}
      data-test="vehicle-hover-detail-panel"
    >
      <span className={cx(
        styles.text,
        color === C.STATUS_COLORS.InQueue
          || color === C.STATUS_COLORS.UnloadStart
          || color === C.STATUS_COLORS.Returning
          ? styles.labelTextBlack
          : styles.labelTextWhite
      )}
      >
        {name}
      </span>
    </div>
  );
}

export interface MarkerLabelProps {
  name: string,
  color: string
}
