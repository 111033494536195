/*
---------------
Jest can't handle the UnitOfMeasure enums, they always are undefined at runtime
https://stackoverflow.com/questions/54310718/undefined-typescript-enum-at-compile-time
tests will work as long as you don't test for anything in the quantity values
can't support this function for coverage

As of 11/6/2022 all branches that are not enum tests in getTicketForPrint are 100% covered by tests

*/
/* istanbul ignore file */
/* eslint-disable max-len */
import { Dispatch } from 'redux';
import {
  OrderDto, PrintableTicketDto, ScaleDto, TicketDto, UnitOfMeasure,
} from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { getRequest, postRequest } from './requestService';
import { getTicketingBaseUrl } from '../util/apiUtil';
import { PRINTING_PATH } from '../constants/apiConstants';
import { FETCH_PRINTERS } from '../constants/actionConstants';
import recordLog from '../util/errorUtil';
import { ERROR_TEXT_FETCH_PRINTERS_LIST } from '../constants/errorConstants';
import { fetchTruck } from './trucksService';
import { formatValue, getIdFromUrl } from '../util/appUtil';
import { fetchPlant } from './plantsService';
import { getScale } from './scalesService';
import QuantityConverter from '../util/quantityConverter';

export function fetchPrinterList(scaleHostAddress?: string) {
  return async (dispatch: Dispatch) => {
    const url = (scaleHostAddress ?? getTicketingBaseUrl()) + PRINTING_PATH;

    try {
      const response = await getRequest(url);
      dispatch({
        type: FETCH_PRINTERS,
        payload: response.data
      });
    } catch (e: any) {
      recordLog(
        ERROR_TEXT_FETCH_PRINTERS_LIST,
        `printService: url: ${url} `,
        e.toString()
      );
    }
  };
}

export async function getPrinterList(scaleHostAddress?: string) {
  const url = (scaleHostAddress ?? getTicketingBaseUrl()) + PRINTING_PATH;
  try {
    const printerList: { printerName: string }[] = [{ printerName: '' }];

    return printerList;
  } catch (e: any) {
    recordLog(
      ERROR_TEXT_FETCH_PRINTERS_LIST,
      `printService: url: ${url} `,
      e.toString()
    );
    return undefined;
  }
}

// ticketing-backend signature and route
//  [Route("/ticketing/api/tickets/{ticketId}/print")]
// public async Task PrintPDF([FromBody] object bodyContent, [FromQuery] string id, [FromQuery] string plantUrl, [FromQuery] string printerId, [FromQuery] bool isOriginal = false)

export async function printTicket(ticket: PrintableTicketDto, printerId: string, copies: number, plantUrl: string, isOriginal: boolean = false) {
  const id = ticket.ticketNumber;
  const printingPath = PRINTING_PATH.replace('{ticketId}', id);
  const url = (getTicketingBaseUrl()) + printingPath;
  const params = {
    id, plantUrl, printerId, isOriginal
  };

  try {
    const response = await postRequest(url, ticket, params);
    return response;
  } catch (e: any) {
    recordLog('error', `ScaleTraxTicketing failed to print ticket - dto: ${JSON.stringify(ticket)} `, JSON.stringify(e));
    return e;
  }
}

export async function getTicketForPrint(ticket: TicketDto, order: OrderDto, userUrl: string) {
  try {
    const printTruck = await fetchTruck(Number(getIdFromUrl(ticket.truck!.url)));
    const printPlant = await fetchPlant(Number(getIdFromUrl(ticket.plant!.url)));
    const ticketScale = await getScale(ticket.scale!.url) as ScaleDto;

    // there will be one line item, use its quantity
    const quantity = ticket.ticketLineItems![0]!.quantity!;

    const printableQuantity = {
      tonsUOM: '',
      tonsNetWeight: '',
      gross: '',
      tare: '',
      net: '',
      netUOM: '',
    };

    /*
    convert everything into tons/lbs, metric tons/kgs
    these strings are formatted to be what they need to be when printed on the paper ticket
    */

    if (quantity.unitOfMeasure === UnitOfMeasure[UnitOfMeasure.POUNDS]) {
      printableQuantity.tonsUOM = 'TONS';
      printableQuantity.tonsNetWeight = formatValue(QuantityConverter.convertValue(quantity.net, UnitOfMeasure.POUNDS, UnitOfMeasure.SHORT_TONS));
      printableQuantity.gross = formatValue(quantity.gross);
      printableQuantity.net = formatValue(quantity.net);
      printableQuantity.tare = formatValue(quantity.tare);
      printableQuantity.netUOM = 'LBS';
    }

    if (quantity.unitOfMeasure === UnitOfMeasure[UnitOfMeasure.SHORT_TONS]) {
      printableQuantity.tonsUOM = 'TONS';
      printableQuantity.tonsNetWeight = formatValue(quantity.net);
      printableQuantity.gross = formatValue(QuantityConverter.convertValue(quantity.gross, UnitOfMeasure.SHORT_TONS, UnitOfMeasure.POUNDS));
      printableQuantity.net = formatValue(QuantityConverter.convertValue(quantity.net, UnitOfMeasure.SHORT_TONS, UnitOfMeasure.POUNDS));
      printableQuantity.tare = formatValue(QuantityConverter.convertValue(quantity.tare, UnitOfMeasure.SHORT_TONS, UnitOfMeasure.POUNDS));
      printableQuantity.netUOM = 'LBS';
    }

    if (quantity.unitOfMeasure === UnitOfMeasure[UnitOfMeasure.KILOGRAMS]) {
      printableQuantity.tonsUOM = 'MTS';
      printableQuantity.tonsNetWeight = formatValue(QuantityConverter.convertValue(quantity.net, UnitOfMeasure.KILOGRAMS, UnitOfMeasure.METRIC_TONS));
      printableQuantity.gross = formatValue(quantity.gross);
      printableQuantity.net = formatValue(quantity.net);
      printableQuantity.tare = formatValue(quantity.tare);
      printableQuantity.netUOM = 'LBS';
    }

    if (quantity.unitOfMeasure === UnitOfMeasure[UnitOfMeasure.METRIC_TONS]) {
      printableQuantity.tonsUOM = 'MTS';
      printableQuantity.tonsNetWeight = formatValue(quantity.net);
      printableQuantity.gross = formatValue(QuantityConverter.convertValue(quantity.gross, UnitOfMeasure.METRIC_TONS, UnitOfMeasure.KILOGRAMS));
      printableQuantity.net = formatValue(QuantityConverter.convertValue(quantity.net, UnitOfMeasure.METRIC_TONS, UnitOfMeasure.KILOGRAMS));
      printableQuantity.tare = formatValue(QuantityConverter.convertValue(quantity.tare, UnitOfMeasure.METRIC_TONS, UnitOfMeasure.KILOGRAMS));
      printableQuantity.netUOM = 'KGS';
    }

    /*
    passing non-breaking space (HTML &nbsp;) for empty attributes so that table cells get their shape when rendered as PDF, also so that the
    attributes aren't dropped from the collection for being null; if they aren't there then the dictionary of attributes
    in the print server won't contain them, and the {{attribute}} in the html ticket template will not be replaced
    */
    const nbsp = '&nbsp;';

    const printableTicket = {
      plantName: printPlant!.name,
      plantAddress1: printPlant!.addressLineOne ?? nbsp,
      customerNumber: order.customerExternalId,
      customerName: order.customerName ?? nbsp,
      PONumber: order.purchaseOrder ?? nbsp,
      tonsUOM: printableQuantity.tonsUOM,
      tonsNetWeight: printableQuantity.tonsNetWeight,
      deliveryAddress: ticket.address ?? nbsp,
      deliveryInstructions: ticket.deliveryInstructions && ticket.deliveryInstructions?.length > 0 ? ticket.deliveryInstructions : nbsp,
      ticketNumber: ticket.ticketNumber.toString() ?? nbsp,
      UOM: printableQuantity.netUOM,
      gross: printableQuantity.gross,
      net: printableQuantity.net,
      tare: printableQuantity.tare,
      plantExternalId: printPlant?.externalId,
      ticketedTimestamp: new DateWrapper(ticket.ticketedTimestamp).toUSDateString(),
      orderNumber: order.orderNumber.toString() ?? nbsp,
      jobTitle: `${order.projectExternalId} ${order.projectName}`,
      lotNum: ticket.lotNum ?? nbsp,
      productNumber: ticket.ticketLineItems![0].productNumber ?? nbsp,
      productDescription: ticket.ticketLineItems![0].productDescription ?? nbsp,
      scale: ticketScale.name ?? nbsp,
      hauler: ticket.hauler ?? nbsp,
      truckLicense: printTruck!.licensePlate ?? nbsp,
      truckAlias: printTruck!.truckAlias ?? nbsp,
      deliveryType: order.fob ? 'FOB' : 'DEL',
      timeIn: new DateWrapper(printTruck!.currentTare!.timestamp).toString('HH:mm'),
      timeOut: new DateWrapper(ticket.ticketedTimestamp).toString('HH:mm'),
      scaleType: ticketScale.scaleType ?? 'MANUAL',
      weighMaster: ticket.weighmaster ?? 'N/A',
    } as PrintableTicketDto;
    return printableTicket;
  } catch (e: any) {
    recordLog('error', `failed to create printable ticket: ${JSON.stringify(ticket)} + ${JSON.stringify(order)}`, JSON.stringify(e));
    return e;
  }
}
