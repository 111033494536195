// Custom override of react-tables pagination.
// Started with this default: https://github.com/react-tools/react-table/blob/master/src/pagination.js
// Combined with this example: https://codesandbox.io/s/012ywx6mp0
import React, { Component } from 'react';

import styles from './Pagination.module.css';
import { noop } from '../../../util/appUtil';

export const defaultButton = (props: React.PropsWithChildren<any>) => (
  <button type="button" className={styles.geotraxPageBtn} {...props}>
    {props.children}
  </button>
);

export const pageButton = (props: React.PropsWithChildren<any>) => (
  <button type="button" {...props}>
    {props.children}
  </button>
);

export const ellipse = () => (
  <div className={styles.paginationEllipse}>
    <div className={styles.ellipse}>...</div>
  </div>
);

export interface PaginationState {
  visiblePages: number[]
}

export class ReactTablePagination extends Component<PaginationProps, PaginationState> {
  static defaultProps: Partial<PaginationProps> = {
    pages: 0,
    page: 0,
    canPrevious: false,
    canNext: false,
    onPageChange: noop,
    PreviousComponent: undefined,
    NextComponent: undefined,
    PageButtonComponent: undefined,
  };

  constructor(props: PaginationProps) {
    super(props);

    this.state = {
      visiblePages: [],
    };
  }

  componentDidUpdate(prevProps: PaginationProps, prevState: PaginationState) {
    if (this.props.pages !== null && this.props !== prevProps) {
      if (prevState.visiblePages.length === 0) {
        this.setState({
          visiblePages: ReactTablePagination.getVisiblePages(0, this.props.pages!),
        });
      } else {
        const visiblePages = ReactTablePagination.getVisiblePages(this.props.page! + 1, this.props.pages!);

        this.setState({
          visiblePages: ReactTablePagination.filterPages(visiblePages, this.props.pages!),
        });
      }
    }
  }

  static getVisiblePages = (page: number, total: number) => {
    if (total < 6) {
      return ReactTablePagination.filterPages([1, 2, 3, 4, 5], total);
    }

    if (page % 4 >= 0 && page > 3 && page + 1 < total) {
      return [1, page - 1, page, page + 1, total];
    }
    if (page % 4 >= 0 && page > 3 && page + 1 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }

    return [1, 2, 3, 4, total];
  };

  getSafePage = (page: number) => {
    let tempPage = page;

    // Number.isNaN does not check for other types of objects
    // see: https://stackoverflow.com/a/45011633
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(tempPage)) {
      tempPage = this.props.page!;
    }
    return Math.min(Math.max(tempPage, 0), this.props.pages! - 1);
  };

  changePageDefault = (page: number) => {
    const visiblePages = ReactTablePagination.getVisiblePages(page, this.props.pages!);
    const tempPage = this.getSafePage(page);

    this.setState({
      visiblePages: ReactTablePagination.filterPages(visiblePages, tempPage),
    });
    if (this.props.page !== tempPage) {
      this.props.onPageChange!(tempPage!);
    }
  };

  changePage = (page: number) => {
    const activePage = this.props.page! + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = ReactTablePagination.getVisiblePages(page, this.props.pages!);

    this.setState({
      visiblePages: ReactTablePagination.filterPages(visiblePages, this.props.pages!),
    });

    this.props.onPageChange!(page - 1);
  };

  static filterPages = (visiblePages: number[], totalPages: number) => visiblePages.filter(page => page <= totalPages);

  render() {
    const {
      // Props
      page = 0,
      canPrevious,
      canNext,
      PreviousComponent = defaultButton,
      NextComponent = defaultButton,
      PageButtonComponent = pageButton,
    } = this.props;
    const { visiblePages } = this.state;
    const activePage = page + 1;

    return (
      <div className={styles.geotraxPagination}>
        <div className="-previous" data-test="load-previous-page-button">
          <PreviousComponent
            onClick={() => {
              if (!canPrevious) return;
              this.changePageDefault(page - 1);
            }}
            disabled={!canPrevious}
            tabIndex={!canPrevious ? '-1' : 0}
          >
            <div className={styles.paginationPrevLabel} />
          </PreviousComponent>
        </div>
        <div className="-center">
          {visiblePages.map((vPage, index, array) => (
            <div className={styles.paginationButton} key={vPage}>
              {array[index - 1] + 1 < vPage ? ellipse() : null}
              <PageButtonComponent
                className={
                  activePage === vPage
                    ? styles.geotraxPageBtnActive
                    : styles.geotraxPageBtn
                }
                onClick={() => this.changePage(vPage)}
                tabIndex={activePage === vPage ? '-1' : 0}
              >
                {vPage}
              </PageButtonComponent>
            </div>
          ))}
        </div>
        <div className="-next" data-test="load-next-page-button">
          <NextComponent
            onClick={() => {
              if (!canNext) return;
              this.changePageDefault(page! + 1);
            }}
            disabled={!canNext}
            tabIndex={!canNext ? '-1' : 0}
          >
            <div className={styles.paginationNextLabel} />
          </NextComponent>
        </div>
      </div>
    );
  }
}

export interface PaginationProps {
  pages?: number;
  page?: number;
  canPrevious?: boolean;
  canNext?: boolean;
  onPageChange?: (page: number) => void;
  PreviousComponent?: React.ComponentType<any>;
  NextComponent?: React.ComponentType<any>;
  PageButtonComponent: React.ComponentType<any>;
}

export default ReactTablePagination;
