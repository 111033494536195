import React, { Component } from 'react';
import { Label, MultiSelectDropDownList } from '@trucktrax/trucktrax-common';
import cx from 'classnames';
import styles from '../admin/AdminAddModal.module.css';
import { getEventFromTarget } from '../../../util/adminUtil';
import { noop } from '../../../util/appUtil';

export default class CardMultiSelect extends Component<CardMultiSelectProps> {
  static defaultProps: Partial<CardMultiSelectProps> = {
    value: [],
    className: '',
    isRequired: false,
    onChange: noop,
    placeholder: '',
    lockMessage: undefined,
    disabled: undefined,
    errorMessage: '',
  };

  onChange = (e: any) => {
    const event = getEventFromTarget({ target: e }, this.props.id);
    this.props.onChange!(event);
  };

  render() {
    const {
      value,
      className,
      items,
      label,
      placeholder,
      lockMessage,
      disabled,
      errorMessage,
    } = this.props;
    const selected = value ? value.map(v => ({ key: v.key })) : [];

    return (
      <div className={className}>
        {(value || items.length)
          ? (
            <div>
              <Label isRequired={this.props.isRequired} className={disabled ? styles.labelDisabled : ''}>{label}</Label>
              <div
                className={cx(styles.dropDownListDiv, errorMessage && styles.hasError)}
                data-test={this.props.dataTest}
              >
                <MultiSelectDropDownList
                  disabled={this.props.disabled}
                  items={items}
                  initialSelected={selected}
                  updateSelected={this.onChange}
                  placeholder={placeholder}
                  lockMessage={lockMessage}
                />
              </div>
              {errorMessage && <div className={styles.multiSelectErrorMessage}>{errorMessage}</div>}
            </div>
          )
          : (
            <div className="skeleton" />
          )}
      </div>
    );
  }
}

export interface CardMultiSelectProps {
  id: string;
  value?: any[];
  className: string;
  onChange?: (v: ReturnType<typeof getEventFromTarget>) => void;
  label: string;
  isRequired?: boolean;
  items: any[];
  dataTest: string;
  placeholder?: string;
  lockMessage?: string;
  disabled?: boolean;
  errorMessage?: string;
}
