import { OperatorNotesDto, SimpleNameUrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import { FETCH_OPERATOR_NOTES, FETCH_OPERATOR_NOTES_OPERATORS } from '../../constants/actionConstants';
import { OperatorNotesPage, PayloadAction } from '../../types';

export const operatorNotesReducer = (
  state: OperatorNotesPage = {
    items: [] as OperatorNotesDto[],
    plantUrl: '',
    page: 1,
  },
  action: PayloadAction<OperatorNotesPage, 'FETCH_OPERATOR_NOTES'>
) => {
  switch (action.type) {
    case FETCH_OPERATOR_NOTES:
      return {
        ...state,
        items: action.payload.items,
        plantUrl: action.payload.plantUrl,
        page: action.payload.page,
      };
    default:
      return state;
  }
};

export const notebookOperatorsReducer = (
  state = { operators: [] as SimpleNameUrlKeyDto[] },
  action: PayloadAction<SimpleNameUrlKeyDto[], 'FETCH_OPERATOR_NOTES_OPERATORS'>
) => {
  switch (action.type) {
    case FETCH_OPERATOR_NOTES_OPERATORS:
      return {
        ...state,
        operators: action.payload,
      };
    default:
      return state;
  }
};
