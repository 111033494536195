import React, { Component } from 'react';
import { RegionDto, UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import { Driver } from '@trucktrax/trucktrax-common';
import { MessageTabs } from '../../../../constants/messagingConstants';
import Tabs from './LeftSidebar/Tabs';
import NewMessage from './LeftSidebar/NewMessage';
import MessageList from './LeftSidebar/MessageList';
import styles from './LeftSidebar.module.css';
import {
  ConversationMessagesDto,
  ConversationToMessagesDto,
  MessageCenterInfo,
} from '../index.d';

class LeftSidebar extends Component<LeftSidebarProps> {
  tabBasedContent = () => {
    // new message
    if (this.props.currentTab === MessageTabs.New) {
      return (
        <NewMessage
          driverList={this.props.driverList}
          messageCenter={this.props.messageCenter}
          onToFieldChange={this.props.onToFieldChange}
          onCurrentRecipientSearchTextChange={this.props.onCurrentRecipientSearchTextChange}
          onMessageFieldChange={this.props.onMessageFieldChange}
          onSubmit={this.props.onSubmit}
          delayDriverFocus={this.props.delayDriverFocus}
          selectedProductLine={this.props.selectedProductLine}
          currentRegion={this.props.currentRegion as RegionDto}
        />
      );
      // inbox or sent
    } if (this.props.currentTab === MessageTabs.Inbox) {
      const messages = this.props.allMessages;
      const onNextPage = this.props.onGetInboxMessages;
      return (
        <MessageList
          messages={messages}
          onNextPage={onNextPage}
          currentRegion={this.props.currentRegion}
          currentTab={this.props.currentTab}
          onMarkUnread={this.props.onMarkUnread}
          onItemClicked={this.props.onItemClicked}
          selectedMessage={this.props.selectedMessage}
          driverList={this.props.driverList}
          selectedProductLine={this.props.selectedProductLine}
        />
      );
    }
    return (<div />);
  };

  render() {
    return (
      <div className={styles.leftSidebar}>
        <Tabs
          onTabChange={this.props.onTabChange}
          currentTab={this.props.currentTab}
          unreadCount={this.props.unreadCount}
        />
        {this.tabBasedContent()}
      </div>
    );
  }
}

export interface LeftSidebarProps {
  onTabChange: (tab: MessageTabs) => void;
  currentTab: MessageTabs;
  unreadCount: boolean;
  onSubmit: (
    messageCenter: MessageCenterInfo,
    messageId: number | null,
    callback?: () => void
  ) => void;
  allMessages: ConversationToMessagesDto[];
  onGetInboxMessages: () => void;
  currentRegion: UrlKeyDto;
  selectedMessage?: ConversationMessagesDto;
  delayDriverFocus?: boolean;
  driverList: Driver[];
  messageCenter: MessageCenterInfo;
  onToFieldChange: () => void;
  onCurrentRecipientSearchTextChange: (newSearchText: string) => void;
  onMessageFieldChange: (message: string) => void;
  onItemClicked: (selectedMessage: ConversationMessagesDto) => void;
  selectedProductLine?: string;
  onMarkUnread: (selectedMessage: ConversationMessagesDto) => void;
}

export default LeftSidebar;
