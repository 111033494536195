/* eslint-disable */
import React, { Component, ReactNode } from 'react';
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import cx from 'classnames';
import TextField from '@mui/material/TextField';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import styles from './ScaleTraxAutocomplete.module.css';

export interface ScaleTraxAutocompleteProps<T> {
  hasError?: boolean,
  options: (T | JSX.Element | ReactNode | any)[],
  getOptionLabel: (option: T) => string,
  getOptionSelected?: any,
  onChange: (
    event?: React.ChangeEvent<{}>,
    value?: T,
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => void,
  value?: T,
  renderOption?: (option: T, state: any) => ReactNode,
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[],
  isOpen?: boolean,
  disableClearable?: boolean,
  onBlur?: VoidFunc
}

export interface ScaleTraxAutocompleteState<T> {
  isOpen: boolean,
  disableClearable: boolean,
  text: string,
  value?: T,
  filterOptions: (options: T[], state: FilterOptionsState<T>) => T[]
}

export class ScaleTraxAutocomplete<T>
  extends Component<ScaleTraxAutocompleteProps<T>, ScaleTraxAutocompleteState<T>> {
  constructor(props: ScaleTraxAutocompleteProps<T>) {
    super(props);
    this.state = {
      isOpen: props.isOpen ?? false,
      text: this.placeholder,
      value: this.props.value,
      filterOptions: this.props.filterOptions ?? this.localFilterOptions,
      disableClearable: this.props.disableClearable ?? false,
    };
  }

  placeholder = 'Select...';

  searchTextInputRef = React.createRef<HTMLInputElement>();

  autoCompleteKey = new Date().toISOString();

  componentDidMount() {
    if (this.props.isOpen) {
      this.onOpen();
    }
  }

  componentDidUpdate() {
    const newValue = this.props.value;
    if (newValue !== this.state.value) {
      this.setState({
        value: newValue,
        text: this.getText(newValue),
      });
    }

    if (!!this.props.isOpen && !this.state.isOpen) {
      this.setState({ isOpen: true });
      this.onOpen();
    }
  }

  getText = (value?: T) => (value ? this.getOptionLabel(value) : this.placeholder);

  getOptionLabel = (option: T | ReactNode) => {
    if (React.isValidElement(option)) {
      return '';
    }

    return this.props.getOptionLabel(option as T);
  };

  clear() {
    const textInputField = this.searchTextInputRef?.current;
    if (textInputField) {
      this.clearInputText(textInputField);
    }
  }

  onChange = (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => {
    // upgrading to v5 of MUI broke the onChange for ScaleTraxAutocomplete used with the renderProp function
    // this is hacky workaround to get the value off the event object instead
    if (React.isValidElement(value)) {
      const newValue = (event as any)?.changeEventValue;
      if (newValue) {
        value = newValue;
      } else {
        return;
      }
    }

    const text = this.getText(value);
    const newValue = value === null ? undefined : value;
    this.setState({ text, value: newValue });
    this.props.onChange(event, newValue, reason, details);
  };

  onOpen = () => {
    this.setState({ isOpen: true });

    const textInputField = this.searchTextInputRef?.current;
    if (textInputField) {
      textInputField.focus();
      this.selectInputText(textInputField);
    }
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  renderOption = (option: T | ReactNode, state: any) => {
    if (React.isValidElement(option)) {
      return (<div className={styles.reactNode}>{option}</div>);
    }

    if (this.props.renderOption) {
      return this.props.renderOption(option as T, state);
    }

    return null;
  };

  localFilterOptions = (options: T[], state: FilterOptionsState<T>) => options.filter(
    o => this.getOptionLabel(o).toLowerCase()
      .includes((state.inputValue ?? '').toLowerCase())
  );

  filterOptions = (options: any[], state: FilterOptionsState<T>) => {
    const newList: (T | ReactNode)[] = [];
    let tempList: T[] = [];

    // Sort T elements ignoring ReactNodes
    // This way, if there are multiple lists with headers,
    // it keeps the headers in the correct position.
    // See ~/src/components/scaletrax/ticketing/ScaleTraxTicketing.tsx > allOrdersList for an example
    options.forEach(option => {
      if (React.isValidElement(option)) {
        tempList = this.state.filterOptions(tempList, state);

        newList.push(...tempList);
        tempList = [];
        newList.push(option as ReactNode);
      } else {
        tempList.push(option);
      }
    });

    tempList = this.state.filterOptions(tempList, state);
    newList.push(...tempList);
    return newList as any;
  };

  selectInputText = (input: HTMLInputElement) => {
    if (!input?.children?.length) {
      return;
    }

    const inputDiv = input.children[0];
    if (!inputDiv.children.length) {
      return;
    }

    global.setTimeout(() => {
      (inputDiv.children[0] as any).select();
    }, 50);
  };

  clearInputText = (input: HTMLInputElement) => {
    if (!input?.children?.length) {
      return;
    }

    const inputDiv = input.children[0];
    if (!inputDiv.children.length) {
      return;
    }

    global.setTimeout(() => {
      (inputDiv.children[0] as any).value = '';
      this.autoCompleteKey = new Date().toISOString();
      this.setState({
        isOpen: false,
        text: this.placeholder,
        value: undefined,
      });
      this.props.onChange(undefined, undefined, 'removeOption');
    }, 50);
  };

  getSearchStyles = () => {
    const {
      isOpen,
      value,
      disableClearable,
    } = this.state;

    let searchStyle = styles.searchOpen;
    let searchTextStyle = styles.searchTextOpen;

    if (isOpen) {
      searchStyle = styles.searchOpen;
      searchTextStyle = styles.searchTextOpen;
    } else if (value && !disableClearable) {
      searchStyle = styles.searchClosedWithValue;
      searchTextStyle = styles.searchTextClosedWithValue;
    } else {
      searchStyle = styles.searchClosed;
      searchTextStyle = styles.searchTextClosed;
    }

    return { searchStyle, searchTextStyle };
  };

  render() {
    const {
      isOpen,
      value,
      text,
    } = this.state;
    const { options, getOptionSelected } = this.props;
    const { searchStyle, searchTextStyle } = this.getSearchStyles();

    return (
      <div className={styles.autocompleteContainer}>
        {isOpen && (
          <div className={styles.openSelection}>
            <span>{text}</span>
          </div>
        )}
        <span className={cx(styles.autocomplete, !value && styles.emptySelection)}>
          <Autocomplete
            popupIcon={
              <i aria-hidden="true" className={cx('icon-chevron-left', styles.iconRight)} />
            }
            openOnFocus
            open={this.props.isOpen}
            disablePortal
            disableClearable={this.props.disableClearable}
            onBlur={this.props.onBlur}
            key={`autocomplete-${this.autoCompleteKey}`}
            // Note: this value can never be undefined or the Autocomplete gets mad!
            value={value || null}
            options={options}// as AutocompleteRenderOptionState[]}
            renderInput={((params: AutocompleteRenderInputParams) => (
              <div className={searchStyle}>
                {isOpen && (<i className="icon-search" />)}
                <TextField
                  {...params}
                  onBlur={this.props.onBlur}
                  key={`textfield-${this.autoCompleteKey}`}
                  ref={this.searchTextInputRef}
                  className={cx(searchTextStyle, this.props.hasError && !isOpen && styles.validationError)}
                  placeholder={isOpen ? 'Search...' : this.placeholder}
                  InputProps={{ ...params.InputProps }}
                />
              </div>
            ))}
            getOptionLabel={this.getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            onChange={this.onChange}
            onOpen={this.onOpen}
            onClose={this.onClose}
            // This only adds the renderOption attribute when it's been passed.
            {...(() => {
              if (this.props.renderOption) {
                return { renderOption: this.renderOption } as any;
              }
              return null;
            })()}
          />
        </span>
      </div>
    );
  }
}
