import { combineReducers } from 'redux';
import { FeatureFlagDto, SubscriptionDto } from '@trucktrax/trucktrax-ts-common';
import { createFlagsReducer } from '../../util/flagUtil';
import { SET_ALL_FEATUREFLAGS, SET_ALL_SUBSCRIPTIONS, SET_FEATURE_FLAG_OBJECT } from '../../constants/actionConstants';
import { FEATURE_FLAG_MAP, FEATURE_FLAG_OBJECT } from '../../constants/localStorageConstants';
import { PayloadAction } from '../../types';

// all current feature flags loaded by default from local storage in case of a refresh.
const featureFlagObject = localStorage.getItem(FEATURE_FLAG_OBJECT);
export const allFlagsReducer = (
  state = (featureFlagObject ? JSON.parse(featureFlagObject) : []),
  action: { type: 'SET_ALL_FEATUREFLAGS', featureFlags: FeatureFlagDto[] }
): FeatureFlagDto[] => {
  switch (action.type) {
    case SET_ALL_FEATUREFLAGS:
      localStorage.setItem(FEATURE_FLAG_OBJECT, JSON.stringify(action.featureFlags));
      return action.featureFlags;
    default:
      return state;
  }
};

export const allSubscriptionsReducer = (
  state: SubscriptionDto[] = [],
  action: { type: 'SET_ALL_SUBSCRIPTIONS', subscriptions: SubscriptionDto[] }
): SubscriptionDto[] => {
  switch (action.type) {
    case SET_ALL_SUBSCRIPTIONS:
      return action.subscriptions;
    default:
      return state;
  }
};

const flagsMap = localStorage.getItem(FEATURE_FLAG_MAP);
export const flagReducer = createFlagsReducer({
  features: flagsMap ? JSON.parse(flagsMap) : {},
});

const localStorageFeatureFlagObj = JSON.parse(localStorage.getItem(FEATURE_FLAG_OBJECT) ?? '[]');
export const featureFlagObj = (
  state = localStorageFeatureFlagObj,
  action: PayloadAction<FeatureFlagDto[], 'SET_FEATURE_FLAG_OBJECT'>
): FeatureFlagDto[] => {
  switch (action.type) {
    case SET_FEATURE_FLAG_OBJECT:
      if (!action.payload) {
        localStorage.removeItem(FEATURE_FLAG_OBJECT);
      } else {
        localStorage.setItem(FEATURE_FLAG_OBJECT, JSON.stringify(action.payload));
      }
      return action.payload;
    default:
      return state;
  }
};

export const flagReducerCombined = combineReducers({
  featuresList: flagReducer,
  allFlags: allFlagsReducer,
  subscriptions: allSubscriptionsReducer,
});
