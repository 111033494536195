import {
  REFRESH_TOKEN_PENDING,
  SET_MINUTES_TO_IDLE_TIME,
  SET_SESSION_ID,
  SET_USER_URL,
  SET_AUTH_TENANT_NAME,
  CLEAR_AUTH_TENANT_NAME,
  SET_TENANT_ID,
  SET_TOKEN,
  SET_REFRESH_TOKEN,
  SET_IS_PASSWORD_TEMPORARY,
  SET_USER_NAME,
  SET_USER_ID,
  SET_USER_FULL_NAME,
} from '../../constants/actionConstants';

export const newAccessToken = (isTokenRequestInProgress: boolean) => ({
  type: REFRESH_TOKEN_PENDING,
  payload: isTokenRequestInProgress,
});

export const setMinutesToIdleLogout = (minutes: number) => ({
  type: SET_MINUTES_TO_IDLE_TIME,
  payload: minutes,
});

export const setSessionId = (sessionId: string) => ({
  type: SET_SESSION_ID,
  payload: sessionId,
});

export const setUserUrl = (userUrl: string) => ({
  type: SET_USER_URL,
  payload: userUrl,
});

export const setAuthTenantName = (authTenantName: string) => ({
  type: SET_AUTH_TENANT_NAME,
  payload: authTenantName,
});

export const clearAuthTenantName = () => ({
  type: CLEAR_AUTH_TENANT_NAME,
});

export const setTenantId = (tenantId: string) => ({
  type: SET_TENANT_ID,
  payload: tenantId,
});

export const setToken = (token: string) => ({
  type: SET_TOKEN,
  payload: token,
});

export const setRefreshToken = (refreshToken: string) => ({
  type: SET_REFRESH_TOKEN,
  payload: refreshToken,
});

export const setIsPasswordTemporary = (isPasswordTemporary: boolean) => ({
  type: SET_IS_PASSWORD_TEMPORARY,
  payload: isPasswordTemporary,
});

export const setUsername = (userName: string) => ({
  type: SET_USER_NAME,
  payload: userName,
});

export const setUserId = (userId: number) => ({
  type: SET_USER_ID,
  payload: userId,
});

export const setUserFullName = (userFullName: string) => ({
  type: SET_USER_FULL_NAME,
  payload: userFullName,
});

export default newAccessToken;
