/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/label-has-for,no-undef */
import React, { Component, useState } from 'react';
import concat from 'lodash/concat';
import {
  Circle, GoogleMap, Marker, Polygon, DrawingManager, StandaloneSearchBox,
} from '@react-google-maps/api';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Button, Label, postGISToGoogleMapsPoints, vars, constants, Tooltip,
} from '@trucktrax/trucktrax-common';
import cx from 'classnames';
import { uniqueId } from 'lodash';
import { noop } from '../../../util/appUtil';
import { getAddressGeocode, removeAutocomplete } from '../../../util/adminUtil';
import {
  GEOZONE_SHAPE_STYLE,
  GEOZONE_TYPE,
  PLANT_NO_GEOZONES,
  PLANT_NO_ACTIVATION,
} from '../../../constants/appConstants';
import { CARD_MAP_HEIGHT } from '../../../constants/mapConstants';
import styles from './CardMap.module.css';
import { ZoneType } from '../../../types';
import icons from '../../geotrax/map/icons';

/**
 * Intended use
 * Displaying and updating a map with single adress/marker location (center)
 * with multiple geozones (cirle or polygon).
 */

type ExtendedPolygon = (google.maps.Polygon | {}) & { [zoneName: string]: google.maps.Polygon | null };
type ExtendedCircle = (google.maps.Circle | {}) & { [zoneName: string]: google.maps.Circle | null };
type MapChangeEvent = {
  location?: Location;
  geozones?: Geozones;
};

type ShapeStyle = {
  fillColor: string;
  strokeColor: string;
  fillOpacity: number;
  strokeWeight: number;
  zIndex: number;
  clickable: boolean;
};

export class Map extends Component<MapProps> {
  static defaultProps: Partial<MapProps> = {
    showSearchBox: true,
    showDrawingManager: true,
    isEditable: false,
    showMarker: true,
    onChange: () => {
    },
    defaultMapZoom: 15,
    selectedZone: undefined,
    mapContainerStyle: {
      height: CARD_MAP_HEIGHT,
      maxWidth: '100%',
    },
  };

  state = {
    isMapHasBeenBounded: false,
  };

  circleRef?: ExtendedCircle;

  polygonRef?: ExtendedPolygon;

  mapRef?: google.maps.Map;

  searchBoxRef?: google.maps.places.SearchBox;

  listenersRef?: {
    [zoneType: string]: google.maps.MapsEventListener[];
  };

  componentDidMount() {
    if (this.props.address
      && (!this.props.location
        || (this.props.location.latitude === 0
          && this.props.location.longitude === 0))) {
      // gets a location using the Geocoding API
      // https://developers.google.com/maps/documentation/geocoding/intro
      // should only get called here once if an address prop is given
      getAddressGeocode(this.props.address, this.onGeocodeSuccess);
    } else {
      this.onChange({
        location: this.props.location,
      });
    }
    removeAutocomplete();
  }

  async componentDidUpdate() {
    if (!this.props.isEditable) {
      this.zoomToBounds();
    }
  }

  componentWillUnmount() {
    this.circleRef = {};
    this.polygonRef = {};
  }

  hasGeozone = () => {
    const keys = concat(
      this.circleRef ? Object.keys(this.circleRef) : [],
      this.polygonRef ? Object.keys(this.polygonRef) : []
    );

    const zoneNames = ['plant', 'return', 'inqueue'];
    return zoneNames.some(zone => keys.includes(zone));
  };

  zoomToBounds = () => {
    if (!this.hasGeozone()) return;
    const bounds = new google.maps.LatLngBounds();
    const { location } = this.props;

    if (location
      && location.latitude !== 0
      && location.longitude !== 0) {
      bounds.extend(new google.maps.LatLng(location.latitude, location.longitude));
    }

    if (this.circleRef) {
      Object.values(this.circleRef).forEach(circle => {
        if (circle) {
          bounds.union(circle.getBounds()!);
        }
      });
    }
    if (this.polygonRef) {
      const allPoints = Object.values(this.polygonRef).reduce<Set<{ lat: number, lng: number }>>((acc, polygon) => {
        if (!polygon) { return acc; }
        return new Set([...acc, ...polygon.getPath().getArray()]);
      }, new Set());

      if (allPoints.size > 0) {
        allPoints.forEach(point => {
          bounds.extend(point);
        });
      }
    }
    if (this.mapRef?.fitBounds) this.mapRef.fitBounds(bounds);
  };

  onChange(change: MapChangeEvent) {
    this.props.onChange!(change);
  }

  onSearchBoxUpdate = () => {
    const places = this.searchBoxRef!.getPlaces() ?? [];
    const bounds = new google.maps.LatLngBounds();

    places.forEach(place => {
      const { location } = place.geometry!;
      if (place.geometry?.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry!.location!);
      }

      this.onChange({
        location: {
          latitude: location?.lat() ?? 0,
          longitude: location?.lng() ?? 0,
        },
      });
    });
    if (this.mapRef) this.mapRef.fitBounds(bounds);
  };

  onMarkerComplete = (marker: google.maps.Marker) => {
    // change
    this.onChange({
      location: {
        latitude: marker.getPosition()?.lat() ?? 0,
        longitude: marker.getPosition()?.lng() ?? 0,
      },
    });
    marker.setMap(null); // clear marker
  };

  onCircleDrawingComplete = (circle: google.maps.Circle) => {
    this.onCircleComplete(circle);
    if (circle.setMap) {
      circle.setMap(null);
    }
  };

  onCircleComplete = (circle: google.maps.Circle) => {
    // change
    const geozones: { [zoneName: string]: GeozoneInfo } = {};
    Object.keys(this.props.geozones!).forEach(key => {
      const zoneType = key as ZoneType;
      if (zoneType === this.props.selectedZone) { // new/changed circle
        geozones[key] = {
          name: key,
          zone: {
            circle: {
              center: {
                latitude: circle.getCenter()?.lat() ?? 0,
                longitude: circle.getCenter()?.lng() ?? 0,
              },
              radius: circle.getRadius(),
            },
            polygon: undefined,
          },
        };
      } else { // old/unchanged circle
        const geoZone = this.props.geozones![key as keyof Geozones];
        geozones[key] = {
          name: key,
          zone: { ...geoZone.zone },
        };
      }
    });

    const zones = geozones as Geozones;
    this.onChange({ geozones: zones });
  };

  onPolygonDrawingComplete = (polygon: google.maps.Polygon) => {
    this.onPolygonComplete(polygon);
    if (polygon.setMap) {
      polygon.setMap(null);
    }
  };

  onPolygonComplete = (polygon: google.maps.Polygon) => {
    const newPolygon = polygon.getPath().getArray().map(point => ({
      lat: point.lat() as number,
      lng: point.lng() as number,
    }));

    const geozones: { [zoneName: string]: GeozoneInfo } = {};
    Object.keys(this.props.geozones!).forEach(key => {
      if (key === this.props.selectedZone!) {
        geozones[key] = {
          name: key,
          zone: {
            circle: undefined,
            polygon: {
              points: Map.googleMapsToPostGISPoints(newPolygon) ?? [],
            },
          },
        };
      } else {
        const geoZone = this.props.geozones![key as keyof Geozones];
        geozones[key] = {
          name: key,
          zone: { ...geoZone.zone },
        };
      }
    });
    const zones = geozones as Geozones;
    this.onChange({ geozones: zones });
  };

  onGeocodeSuccess = (json: google.maps.GeocoderResponse) => {
    const bounds = new google.maps.LatLngBounds();
    const place = json.results[0];

    if (place) {
      const { location } = place.geometry;

      Object.values(place.geometry.viewport)
        .forEach(v => {
          bounds.extend(v);
        });

      // change
      this.onChange({
        location: {
          latitude: location.lat(),
          longitude: location.lng(),
        },
      });
    } else if (this.props.location) {
      this.onChange({
        location: this.props.location,
      });
    }

    this.zoomToBounds();
  };

  onMarkerLoad = () => {
    this.zoomToBounds();
  };

  onPolygonChanged = () => {
    this.onPolygonComplete(this.polygonRef![this.props.selectedZone!]!);
  };

  onCircleRadiusChanged = (zoneType: ZoneType) => {
    if (!this.circleRef || !this.circleRef[this.props.selectedZone!]) return;
    const oldCircleRadius = this.props.geozones![this.props.selectedZone!]!.zone.circle!.radius;
    const newCircleRadius = this.circleRef![this.props.selectedZone!]!.getRadius();
    if (oldCircleRadius !== newCircleRadius && zoneType === this.props.selectedZone) {
      this.onCircleComplete(this.circleRef[this.props.selectedZone!]!);
    }
  };

  // center_changed from the google maps API fires on every render cycle, no matter the reason.
  // It emits a complex object, which can trigger an infinite rendering loop
  // separate it out and confirm that the center lat/lng has changed before firing change event
  onCircleCenterChanged = (zoneType: ZoneType) => {
    if (!this.circleRef || !this.circleRef[this.props.selectedZone!]) return;
    const oldLatitude = this.props.geozones![this.props.selectedZone!]!.zone.circle!.center.latitude;
    const newLatitude = this.circleRef![this.props.selectedZone!]!.getCenter()?.lat();
    const oldLongitude = this.props.geozones![this.props.selectedZone!]!.zone.circle!.center.longitude;
    const newLongitude = this.circleRef[this.props.selectedZone!]!.getCenter()?.lng();
    if ((oldLatitude !== newLatitude || oldLongitude !== newLongitude) && zoneType === this.props.selectedZone) {
      this.onCircleComplete(this.circleRef[this.props.selectedZone!]!);
    }
  };

  getCenter() {
    return this.props.location
      ? {
        lat: this.props.location.latitude,
        lng: this.props.location.longitude,
      }
      : {
        lat: 0,
        lng: 0,
      };
  }

  onPolygonLoad = (polygon: google.maps.Polygon, zoneType: ZoneType) => {
    if (!this.polygonRef) {
      this.polygonRef = {};
    }
    // handles to poly paths are maintained manually, while circle listeners are part of the circle itself
    if (!this.listenersRef) {
      this.listenersRef = {};
    }
    if (!this.listenersRef[zoneType]) {
      this.listenersRef[zoneType] = [];
    }

    const path = polygon.getPath();
    this.listenersRef[zoneType].forEach(lis => lis.remove());
    this.listenersRef[zoneType].push(
      path.addListener('set_at', this.onPolygonChanged),
      path.addListener('insert_at', this.onPolygonChanged),
      path.addListener('remove_at', this.onPolygonChanged)
    );

    this.polygonRef[zoneType] = polygon;
    if (!this.state.isMapHasBeenBounded) {
      this.setState({ isMapHasBeenBounded: true }, () => this.zoomToBounds());
    }
  };

  onPolygonUnmount = (polygon: google.maps.Polygon, zoneType: ZoneType) => {
    this.polygonRef![zoneType] = null;
    this.listenersRef![zoneType].forEach(lis => lis.remove());
    google.maps.event.clearInstanceListeners(polygon);
    polygon.setMap(null);
  };

  onCircleLoad = (circle: google.maps.Circle, zoneType: ZoneType) => {
    if (!this.circleRef) {
      this.circleRef = {};
    }

    this.circleRef![zoneType] = circle;
    circle.setMap(this.mapRef!);
    if (!this.state.isMapHasBeenBounded) {
      this.setState({ isMapHasBeenBounded: true }, () => this.zoomToBounds());
    }
  };

  onCircleUnmount = (circle: google.maps.Circle, zoneType: ZoneType) => {
    this.circleRef![zoneType] = null;
    google.maps.event.clearInstanceListeners(circle);

    circle.setMap(null);
  };

  getSearchBoxRef = (el: google.maps.places.SearchBox) => {
    this.searchBoxRef = el;
  };

  onMapLoad = (map: google.maps.Map) => {
    this.mapRef = map;
    if (!this.circleRef) {
      this.circleRef = {};
    }
    if (!this.polygonRef) {
      this.polygonRef = {};
    }
    this.zoomToBounds();
  };

  onMapUnmount = () => {
    this.circleRef = {};
    this.polygonRef = {};
  };

  searchBox() {
    const canSearch = (this.props.showSearchBox && this.props.isEditable);
    if (!canSearch) {
      return null;
    }

    return (
      <StandaloneSearchBox
        onLoad={this.getSearchBoxRef}
        onPlacesChanged={this.onSearchBoxUpdate}
      >
        <input
          type="text"
          placeholder="Type address for marker"
          className={cx('tt-input', styles.mapInput)}
          onFocus={removeAutocomplete}
          style={{
            width: '240px',
            height: '32px',
            margin: '5px',
            textOverflow: 'ellipses',
            position: 'absolute',
          }}
        />
      </StandaloneSearchBox>
    );
  }

  static getGeofenceStyle = (zoneType?: ZoneType) => {
    const shapeStyles: ShapeStyle = {
      fillColor: '#67B4F6',
      strokeColor: '#67B4F6',
      fillOpacity: 0.25,
      strokeWeight: 2,
      zIndex: 1,
      clickable: false,
    };

    type GeozoneStyleType = typeof GEOZONE_SHAPE_STYLE;
    const zone: { [zoneName: string]: Partial<ShapeStyle> } = {};
    zone.plant = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.PLANT as keyof GeozoneStyleType],
      zIndex: 3,
    };
    zone.inqueue = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.INQUEUE as keyof GeozoneStyleType],
      zIndex: 2,
    };
    zone.return = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.RETURN as keyof GeozoneStyleType],
      zIndex: 1,
    };

    return zoneType
      ? ({ ...shapeStyles, ...zone[zoneType] })
      : shapeStyles;
  };

  drawingManager() {
    const canDraw = (this.props.showDrawingManager && this.props.isEditable);
    if (!canDraw) {
      return null;
    }

    return (
      <DrawingManager
        options={{
          drawingControl: true,
          drawingControlOptions: {
            position: this.props.showSearchBox
              ? google.maps.ControlPosition.TOP_RIGHT
              : google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              google.maps.drawing.OverlayType.MARKER,
              google.maps.drawing.OverlayType.CIRCLE,
              google.maps.drawing.OverlayType.POLYGON,
            ],
          },
          circleOptions: GEOZONE_SHAPE_STYLE[this.props.selectedZone!],
          polygonOptions: GEOZONE_SHAPE_STYLE[this.props.selectedZone!],
        }}
        onMarkerComplete={this.onMarkerComplete}
        onCircleComplete={this.onCircleDrawingComplete}
        onPolygonComplete={this.onPolygonDrawingComplete}
      />
    );
  }

  static googleMapsToPostGISPoints = (points?: { lat: number, lng: number }[]) => {
    if (!points) return null;
    points.push(points[0]);
    return points.map(point => ({
      latitude: point.lat ?? 0,
      longitude: point.lng ?? 0,
    }));
  };

  getZoneShape = (zoneType: ZoneType, value: GeozoneInfo) => {
    if (!value || !value.zone || !zoneType) return null;

    // zone is editable when isEditableTRUE selectedZone matches geozone key
    const editable = this.props.isEditable && this.props.selectedZone === zoneType;
    const options = Map.getGeofenceStyle(zoneType);
    const { zone } = value;
    if (zone.circle) {
      return (
        <Circle
          key={zoneType}
          center={{
            lat: zone.circle.center.latitude,
            lng: zone.circle.center.longitude,
          }}
          radius={zone.circle.radius}
          onRadiusChanged={() => this.onCircleRadiusChanged(zoneType)}
          onCenterChanged={() => this.onCircleCenterChanged(zoneType)}
          onLoad={e => this.onCircleLoad(e, zoneType)}
          onUnmount={e => this.onCircleUnmount(e, zoneType)}
          options={{
            ...options, editable, draggable: editable, clickable: editable,
          }}
        />
      );
    } if (zone.polygon) {
      return (
        <Polygon
          key={zoneType}
          path={postGISToGoogleMapsPoints(zone.polygon.points) as any}
          onLoad={e => this.onPolygonLoad(e, zoneType)}
          onUnmount={e => this.onPolygonUnmount(e, zoneType)}
          // Event used when dragging the whole Polygon
          onDragEnd={this.onPolygonChanged}
          // Event used when manipulating and adding points
          onMouseUp={this.onPolygonChanged}
          options={{ ...options, editable }}
        />
      );
    }

    return null;
  };

  // Draw different geozone shapes based on geozones information
  shapes = () => {
    if (!this.props.geozones) return null;

    const { geozones } = this.props;
    const shapeElms = Object.values(GEOZONE_TYPE).map((key => this.getZoneShape(key as ZoneType, geozones[key as ZoneType])));
    return shapeElms;
  };

  marker() {
    const flagIconAnchorX = 4.5;
    const flagIconAnchorY = 20;

    const icon = {
      path: icons.flagPath,
      fillColor: vars.gray700,
      fillOpacity: 1,
      strokeColor: vars.white,
      strokeWeight: 2,
      // this anchors the image so the beginning of the flag pole is in the geolocation
      anchor: new window.google.maps.Point(flagIconAnchorX, flagIconAnchorY),
    };

    const editable = this.props.isEditable && this.props.selectedZone === GEOZONE_TYPE.PLANT;

    return this.props.location && (
      <Marker
        icon={icon}
        position={{
          lat: this.props.location.latitude,
          lng: this.props.location.longitude,
        }}
        visible={this.props.showMarker}
        draggable={editable}
        onLoad={this.onMarkerLoad}
        onDragEnd={marker => {
          // change
          this.onChange({
            location: {
              latitude: marker?.latLng?.lat() ?? 0,
              longitude: marker?.latLng?.lng() ?? 0,
            },
          });
        }}
      />
    );
  }

  render() {
    const containerStyle = {
      width: '100%',
      maxWidth: this.props.mapContainerStyle!.maxWidth,
      minHeight: this.props.mapContainerStyle!.minHeight,
      height: this.props.mapContainerStyle!.height,
    };

    return (
      <GoogleMap
        zoom={this.props.defaultMapZoom}
        mapContainerStyle={containerStyle}
        // use initial center from props (marker, circle and polygon should use state)
        center={this.mapRef?.getCenter() || this.getCenter()}
        onLoad={this.onMapLoad}
        onUnmount={this.onMapUnmount}
        options={{
          draggable: this.props.isEditable,
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: true,
          controlSize: constants.GM_CONTROLSIZE,
          mapTypeControlOptions: {
            mapTypeIds: [
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.SATELLITE,
            ],
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          panControl: false,
          zoomControl: true,
          rotateControl: false,
          fullscreenControl: false,
        }}
      >
        {this.searchBox()}
        {/* DRAWING MANAGER */}
        {this.drawingManager()}
        {/* SINGLE MARKER */}
        {this.marker()}
        {/* MULTI SHAPES */}
        {this.shapes()}
      </GoogleMap>
    );
  }
}

type Geozones = {
  plant: GeozoneInfo;
  inqueue: GeozoneInfo;
  return: GeozoneInfo;
};
export interface MapProps {
  location?: Location;
  geozones?: Geozones;
  address?: string;
  showSearchBox?: boolean;
  showDrawingManager?: boolean;
  isEditable?: boolean;
  showMarker?: boolean;
  onChange?: (e: MapChangeEvent) => void;
  selectedZone?: ZoneType;
  defaultMapZoom?: number;
  mapContainerStyle?: {
    height: string;
    minHeight?: string;
    maxWidth: string;
  }
}

/* start CardMapMultizones */

export default function CardMapMultizones({
  className = '',
  label = '',
  value = { geozones: undefined, address: undefined, location: undefined },
  onChange = noop,
  resetBtn = false,
  edit = false,
  containerStyle = {
    height: CARD_MAP_HEIGHT,
    maxWidth: '100%',
  },
  selectedZone = GEOZONE_TYPE.PLANT as ZoneType,
  handleSelectedZoneChange = noop,
  errorMessage = '',
  missingGeozones = false,
  launchToEdit = noop,
  ...rest
}: CardMapMultizonesProps) {
  const [tooltipKey, setToolTipKey] = useState(uniqueId());
  type GeozoneKey = keyof Geozones;
  const hasPlantGeozone = (value && value.geozones)
    ? (value.geozones[GEOZONE_TYPE.PLANT as GeozoneKey].zone.circle
      || value.geozones[GEOZONE_TYPE.PLANT as GeozoneKey].zone.polygon)
    : null;
  const disableDuplicateGeofence = !edit
    || selectedZone === GEOZONE_TYPE.PLANT
    || !hasPlantGeozone
    || (JSON.stringify(value.geozones![GEOZONE_TYPE.PLANT as GeozoneKey].zone)
      === JSON.stringify(value.geozones![selectedZone as GeozoneKey].zone));
  const errorBorder = errorMessage.length > 0 && (styles.redBorder);
  const border = errorMessage.length === 0 && (styles.border);

  const geozoneLabels = {
    inqueue: 'In Queue',
    plant: 'Plant',
    return: 'Return',
  };

  return (
    <div
      className={cx(className, styles.map)}
    >
      {label && (
        <Label
          htmlFor="details-map"
          isRequired={!!edit}
        >
          {label}
        </Label>
      )}
      {missingGeozones && (
        <div className={styles.noGeozoneContainer}>
          <Label className={styles.noGeozoneLabel}>
            <i className={cx('icon-warning', styles.warningIcon)} />
            {PLANT_NO_GEOZONES}
          </Label>
          {PLANT_NO_ACTIVATION}
          <button
            className={cx('tt-btn', 'tt-btn-basic', styles.editPlantLink)}
            onClick={() => {
              launchToEdit();
            }}
          >
            <span>Edit Plant</span>
          </button>
        </div>
      )}
      <div className={cx(border, errorBorder)}>
        {resetBtn && (
          <Tabs
            value={selectedZone}
            classes={{
              root: styles.tabs,
            }}
            onChange={(_, newValue) => handleSelectedZoneChange(newValue)}
          >
            <Tab
              label={GEOZONE_TYPE.PLANT}
              value={GEOZONE_TYPE.PLANT}
              classes={{
                root: styles.tab,
              }}
              icon={(
                <i className={cx(
                  'icon-forward',
                  styles.marginRight,
                  styles.inlineStatusIcon,
                  styles.statusIconToJob,
                  styles.statusIconArrow
                )}
                />
              )}
            />
            <Tab
              label="In Queue"
              value={GEOZONE_TYPE.INQUEUE}
              classes={{
                root: styles.tab,
              }}
              icon={(
                <i className={cx(
                  'icon-dot',
                  styles.marginRight,
                  styles.inlineStatusIcon,
                  styles.statusIconInQueue,
                  styles.statusIconDot
                )}
                />
              )}
            />
            <Tab
              label={GEOZONE_TYPE.RETURN}
              value={GEOZONE_TYPE.RETURN}
              classes={{
                root: styles.tab,
              }}
              icon={(
                <i className={cx(
                  'icon-dot',
                  styles.marginRight,
                  styles.inlineStatusIcon,
                  styles.statusIconReturn,
                  styles.statusIconDot
                )}
                />
              )}
            />
          </Tabs>
        )}
        <Map
          mapContainerStyle={containerStyle}
          {...value}
          onChange={onChange}
          isEditable={edit}
          selectedZone={selectedZone}
          {...rest}
        />
        {resetBtn
          && (
            <div className={cx(styles.zoneActionCt)}>
              <Button
                buttonClassName={cx(
                  'tt-btn',
                  styles.clearGeozonesBtn,
                  !edit && styles.btnDisabled
                )}
                iconClassName={cx('icon-clear-circle-outline')}
                name="Clear Geofence"
                dataTest="reset-geofence-btn"
                disabled={!edit}
                onClick={() => {
                  const newGeozones = { ...value.geozones };
                  newGeozones[selectedZone!]!.zone.circle = undefined;
                  newGeozones[selectedZone!]!.zone.polygon = undefined;
                  onChange({ geozones: newGeozones as Geozones });
                }}
              />
              {!disableDuplicateGeofence ? <Tooltip
                key={tooltipKey}
                classes={{
                  popper: styles.dupPopper,
                  tooltip: styles.dupTooltip,
                }}
                text={`This will replace the current ${geozoneLabels[selectedZone]} geofence to match the Plant geofence.`}
              >
                <span className={styles.dupAcrossContainer}>
                  <Button
                    buttonClassName={cx(
                      'tt-btn',
                      styles.dupAcrossBtn,
                      disableDuplicateGeofence && styles.btnDisabled
                    )}
                    iconClassName={cx('icon-content-copy')}
                    name="Duplicate Across Geofences"
                    dataTest="duplicate-across-geofences-btn"
                    disabled={disableDuplicateGeofence}
                    onClick={() => {
                      setToolTipKey(uniqueId());
                      const newGeozones = { ...value.geozones };
                      newGeozones[selectedZone!]!.zone = { ...value.geozones![GEOZONE_TYPE.PLANT as GeozoneKey].zone };

                      onChange({ geozones: newGeozones as Geozones });
                    }}
                  />
                </span>
              </Tooltip> : <Button
                buttonClassName={cx(
                  'tt-btn',
                  styles.dupAcrossBtn,
                  disableDuplicateGeofence && styles.btnDisabled
                )}
                iconClassName={cx('icon-content-copy')}
                name="Duplicate Across Geofences"
                dataTest="duplicate-across-geofences-btn"
                disabled={disableDuplicateGeofence}
                onClick={() => {
                  setToolTipKey(uniqueId());
                  const newGeozones = { ...value.geozones };
                  newGeozones[selectedZone!]!.zone = { ...value.geozones![GEOZONE_TYPE.PLANT as GeozoneKey].zone };

                  onChange({ geozones: newGeozones as Geozones });
                }}
              />}
            </div>
          )}
      </div>
      {errorMessage.length > 0 && (
        <Label
          htmlFor="details-map-error-message"
          className={cx('tt-label--help', styles.error)}
          data-test="incomplete-geozones-input-error"
        >
          {errorMessage}
        </Label>
      )}
    </div>
  );
}

type GeozoneInfo = {
  zone: {
    circle?: {
      radius: number;
      center: Location;
    };
    polygon?: {
      points: Location[];
    };
  },
  name: string;
};
type Location = {
  latitude: number;
  longitude: number;
};

export interface CardMapMultizonesProps {
  handleSelectedZoneChange?: (zone: ZoneType) => void;
  className?: string;
  label?: string;
  missingGeozones?: boolean;
  value?: {
    geozones?: {
      plant: GeozoneInfo;
      inqueue: GeozoneInfo;
      return: GeozoneInfo;
    },
    address?: string;
    location?: Location;
  };
  onChange?: (e: MapChangeEvent) => void;
  launchToEdit?: () => void;
  resetBtn?: boolean;
  edit?: boolean;
  containerStyle?: {
    height: string;
    minHeight?: string;
    maxWidth: string
  };
  selectedZone?: ZoneType;
  errorMessage?: string;
}
