/* eslint-disable no-underscore-dangle */

/**
 * Represents a validated date time range with start and end dates.
 *
 * @interface
 * @name IDateTimeRange
 */
interface IDateTimeRange {
  /**
   * The start date of the range.
   * @type {Date}
   */
  start: Date;

  /**
   * The end date of the range.
   * @type {Date}
   */
  end: Date;
}

/**
 * A class that represents a validated date time range with start and end dates.
 *
 * @class
 * @implements {IDateTimeRange}
 */
export default class DateTimeRange implements IDateTimeRange {
  /**
   * The private start date of the range.
   * @type {Date}
   * @private
   */
  private readonly _start: Date;

  /**
   * The private end date of the range.
   * @type {Date}
   * @private
   */
  private readonly _end: Date;

  /**
   * Creates a new instance of an IMMUTABLE ValidatedDateTimeRange class with the specified start and end dates.
   * Throws an error if the start date is greater than or equal to the end date.
   *
   * @constructor
   * @param {Date} startDate - The start date (with time) of the range.
   * @param {Date} endDate - The end date (with time) of the range.
   * @throws {Error} If the start date is greater than or equal to the end date.
   */
  constructor(startDate: Date|string, endDate: Date|string) {
    if (startDate >= endDate) {
      throw new Error('Start date must be before end date.');
    }
    const start = (startDate instanceof Date) ? startDate : new Date(startDate);
    const end = (endDate instanceof Date) ? endDate : new Date(endDate);

    // create new Dates to ensure immutability
    this._start = new Date(start);
    this._end = new Date(end);
  }

  /**
   * @returns {Date} A new Date object that represents the start date of the range.
   */
  get start(): Date {
    return new Date(this._start);
  }

  /**
   * @returns {Date} A new Date object that represents the end date of the range.
   */
  get end(): Date {
    return new Date(this._end);
  }
}
