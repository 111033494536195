/* eslint-disable */
import React, { Component } from 'react';
import cx from 'classnames';
import {
  TruckDto,
  ScaleDto,
  QuantityDto,
  UnitOfMeasure,
  ScaleType,
  ProductLine,
} from '@trucktrax/trucktrax-ts-common';
import { DropDownList, DateWrapper } from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import { TareHistoryDto } from '@trucktrax/trucktrax-ts-common/build/Dtos/Trucks/TareHistoryDto';
import styles from './ScaleSection.module.css';
import { ConnectedDispatchFunction, ReceivedWeight, ScaleState } from '../../../types';
import { updateTruck, hasTare } from '../../../services/trucksService';
import { ReduxState } from '../../../store';
import { formatValue, getIdFromUrl } from '../../../util/appUtil';
import scalesSocket from '../../../services/sockets/scalesSocket';
import CardNumericInput from '../../shared/forms/CardNumericInput';
import QuantityConverter from '../../../util/quantityConverter';
import LoadingCircle from '../../shared/LoadingCircle';
import { UOM_LABELS, weightOptions } from '../../../util/adminUtil';

export interface ScaleSectionState {
  currentTruck?: TruckDto,
  currentScale?: ScaleDto,
  currentQuantity: QuantityDto,
  grossString: string,
  tareString: string,
  netString: string,
  isTareSaved: boolean
}

export class ScaleSection extends Component<ScaleSectionProps, ScaleSectionState> {
  constructor(props: ScaleSectionProps) {
    super(props);

    this.state = {
      currentScale: props.scale,
      currentTruck: props.truck,
      currentQuantity: props.quantity,
      grossString: formatValue(props.quantity.gross, props.quantity.unitOfMeasure),
      tareString: formatValue(props.quantity.tare, props.quantity.unitOfMeasure),
      netString: formatValue(props.quantity.net, props.quantity.unitOfMeasure),
      isTareSaved: false,
    };
  }

  async componentDidUpdate() {
    const {
      scale,
      truck,
      quantity,
      receivedWeight,
    } = this.props;

    let {
      currentScale,
      currentTruck,
      currentQuantity,
      grossString,
      tareString,
      netString,
    } = this.state;

    let hasChanged = false;

    if (currentScale?.id !== scale?.id) {
      hasChanged = true;
      currentScale = await this.updateScale(currentScale, scale);
    }

    /*   this is zeroing out the quantity, something is not matching in the QuantityConverter even when all values are equal,
         something in the cache list of potential units of measure is not matching,
         It is having trouble converting POUNDS to POUNDS, ubclear what changed

    if (!QuantityConverter.areEquals(currentQuantity, quantity)) {
      hasChanged = true;
      currentQuantity = QuantityConverter.convertQuantity(quantity, currentQuantity.unitOfMeasure);
    }*/

    if (currentTruck !== truck) {
      hasChanged = true;
      ({ currentTruck, currentQuantity, tareString } = this.updateQuantitiesAndTruckTare(currentQuantity, currentScale, truck));
    }

    if (this.checkAutoScaleHasDifferentWeight(currentScale, receivedWeight, truck, quantity)) {
      hasChanged = true;
      quantity.gross = receivedWeight.weight;
      grossString = formatValue(quantity.gross, currentQuantity.unitOfMeasure);
    }

    // reset weight values when truck is not selected
    if (!truck) {
      currentQuantity.tare = 0;
      tareString = formatValue(currentQuantity.tare, currentQuantity.unitOfMeasure);

      quantity.gross = 0;
      currentQuantity.gross = 0;
      grossString = formatValue(quantity.gross, currentQuantity.unitOfMeasure);
    }

    currentQuantity.net = currentQuantity.gross - currentQuantity.tare;
    netString = formatValue(currentQuantity.net, currentQuantity.unitOfMeasure);

    if (hasChanged) {
      this.setState({
        currentScale,
        currentTruck,
        currentQuantity,
        grossString,
        tareString,
        netString,
        isTareSaved: false,
      });
    }
  }


  async updateScale(currentScale?: ScaleDto, newScale?: ScaleDto) {
    if (currentScale?.id) {
      await scalesSocket.disconnect();
    }

    if (this.isAutoScale(newScale) && newScale?.id) {
      const plant = this.props.currentPlant;
      await scalesSocket.connect(newScale?.id.toString());
    }
    return newScale;
  }

  updateQuantitiesAndTruckTare(
    currentQuantity: QuantityDto,
    currentScale?: ScaleDto,
    newTruck?: TruckDto
  ): {
    currentTruck?: TruckDto,
    currentQuantity: QuantityDto,
    tareString: string
  } {
    let newQuantity: QuantityDto = {
      ...currentQuantity,
      tare: 0,
    };

    if (this.isAutoScale(currentScale)) {
      newQuantity.gross = 0;
    }

    const truckUom = weightOptions.find(w => w.label === newTruck?.currentTare?.uom)?.value;

    if (!this.isTareExpired(newTruck?.currentTare, newTruck?.scaleExpirationDays)) {
      const truckTare = newTruck?.currentTare?.weight ?? 0;
      const newUom = newQuantity.unitOfMeasure;

      newQuantity.unitOfMeasure = truckUom!;
      newQuantity.tare = newTruck?.primaryProductLine === ProductLine.Cement ? 0 : truckTare;

      newQuantity = QuantityConverter.convertQuantity(newQuantity, newUom);
    }

    const tareString = formatValue(newQuantity.tare, truckUom);
    this.props.onChange(currentScale!, newQuantity);
    return { currentTruck: newTruck, currentQuantity: newQuantity, tareString };
  }

  checkAutoScaleHasDifferentWeight(
    currentScale: ScaleDto | undefined,
    receivedWeight: ReceivedWeight,
    newTruck: TruckDto | undefined,
    newQuantity: QuantityDto
  ) {
    if (this.isAutoScale(currentScale)
      && newTruck
      && receivedWeight.scaleId === currentScale?.id?.toString()
      && receivedWeight.weight !== newQuantity.gross) {
      return true;
    } {
      return false
    }
  }

  getTareInCurrentUnitOfMeasure = (tare?: TareHistoryDto) => {
    const { currentQuantity } = this.state;
    const truckUom = weightOptions.find(w => w.label === tare?.uom)?.value;

    let quantity = {
      ...currentQuantity,
      unitOfMeasure: truckUom,
      tare: tare?.weight ?? 0,
    } as QuantityDto;

    quantity = QuantityConverter.convertQuantity(quantity, currentQuantity.unitOfMeasure);
    return quantity.tare;
  };

  displayTare = (tare?: TareHistoryDto) => {
    const { currentQuantity } = this.state;
    const tareValue = this.getTareInCurrentUnitOfMeasure(tare);
    const uom = weightOptions.find(w => w.value === currentQuantity.unitOfMeasure);

    return `${formatValue(tareValue, currentQuantity.unitOfMeasure)} ${uom?.label}`;
  };

  onChange = () => {
    const { currentScale, currentQuantity } = this.state;
    currentQuantity.net = currentQuantity.gross - currentQuantity.tare;

    this.setState({
      currentQuantity,
      netString: formatValue(currentQuantity.net, currentQuantity.unitOfMeasure),
      isTareSaved: false,
    });

    if (currentScale) {
      this.props.onChange(currentScale, currentQuantity);
    }
  };

  handleContainerClick = () => {
    this.setState({
      grossString: '',
    });
  };

  handleContainerKeyboardEvent = (event: any) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  onGrossValueChange = (value: string) => {
    const { currentQuantity } = this.state;
    currentQuantity.gross = Number.isNaN(Number(value)) ? currentQuantity.gross : Number(value);

    this.setState({
      currentQuantity,
      grossString: `${currentQuantity.gross}`,
    }, this.onChange);
  };

  onTareValueChange = (value: string) => {
    const { currentQuantity } = this.state;
    currentQuantity.tare = Number(value);

    this.setState({
      currentQuantity,
      tareString: value,
    }, this.onChange);
  };

  scaleDtoToString = (option: ScaleDto) => option.name || '';

  displayUser = (tare?: TareHistoryDto) => {
    if (!tare) { return ''; }
    const user = this.props.usersList.find(u => u.url === tare.weighmaster?.url);
    return `${user?.lastName}, ${user?.firstName}`;
  };

  isTareExpired = (tare?: TareHistoryDto, expirationDays?: number) => {
    if (!tare || !expirationDays) { return false; }

    const today = new Date();
    const tareDate = new Date(tare.timestamp);
    tareDate.setDate(tareDate.getDate() + expirationDays);
    return (tareDate < today);
  };

  isTareButtonDisabled = () => (this.state.grossString === '' || Number(this.state.grossString) === 0
    || this.state.isTareSaved || !this.props.truck
    || this.props.isScaleMaxWeightExceeded(this.state.currentQuantity.unitOfMeasure)
    || this.props.isTruckTypeMaxWeightExceeded(this.state.currentQuantity.unitOfMeasure));

  displayTimestamp = (tare?: TareHistoryDto) => (tare ? new DateWrapper(tare.timestamp).toString('MM/dd/yyyy h:mm a') : '');

  unitOfMeasureLabel = () => {
    const uom = this.state.currentQuantity.unitOfMeasure;
    const tuple = weightOptions.find(o => o.value === uom);
    return tuple?.label ?? '';
  };

  calculateTareChange = () => {
    const { currentTruck, currentQuantity } = this.state;
    const currentTareWeight = this.getTareInCurrentUnitOfMeasure(currentTruck?.currentTare);
    const previousTareWeight = this.getTareInCurrentUnitOfMeasure(currentTruck?.previousTare);
    const uom = weightOptions.find(w => w.value === currentQuantity.unitOfMeasure);
    const uomLabel = uom?.label;

    if (!currentTareWeight && !previousTareWeight) {
      return '';
    }

    if (!currentTareWeight) {
      return `+${formatValue(previousTareWeight, uom?.value)} ${uomLabel}`;
    }

    if (!previousTareWeight) {
      return `+${formatValue(currentTareWeight, uom?.value)} ${uomLabel}`;
    }

    const weight = formatValue(currentTareWeight - previousTareWeight, uom?.value);
    const sign = currentTareWeight > previousTareWeight ? '+' : '';
    return `${sign}${weight} ${uomLabel}`;
  };

  isAutoScale = (scale?: ScaleDto) => {
    return scale?.scaleType === ScaleType.LIVE;
  }

  onTareClick = () => {
    const {
      currentTruck,
      currentQuantity,
    } = this.state;
    let {
      tareString,
      netString,
    } = this.state;

    if (!currentTruck) {
      return;
    }

    if (currentQuantity.gross !== 0) {
      currentQuantity.tare = currentQuantity.gross;
      currentQuantity.net = 0;
      tareString = formatValue(currentQuantity.tare, currentQuantity?.unitOfMeasure);
      netString = formatValue(currentQuantity.net, currentQuantity?.unitOfMeasure);
      this.setState({ tareString, netString });
    }

    if (currentQuantity.tare !== 0) {
      currentTruck.previousTare = currentTruck.currentTare;
      currentTruck.currentTare = {
        scale: this.state.currentScale?.id || 0,
        weighmaster: {
          url: this.props.currentUser ?? undefined,
        },
        weight: this.state.currentQuantity.tare,
        timestamp: (new Date()).toISOString(),
        manual: this.state.currentScale?.scaleType === ScaleType.MANUAL || false,
        uom: weightOptions.find(i => i.value === this.state.currentQuantity.unitOfMeasure)?.label ?? UOM_LABELS.POUNDS,
      };

      this.setState({ currentTruck, isTareSaved: true });
      this.props.onTared(currentTruck);
    }

    this.props.updateTruck(currentTruck);
  };

  uomChange = (item?: any) => {
    const uom = (item?.value ?? UnitOfMeasure.POUNDS) as UnitOfMeasure;
    const { currentQuantity } = this.state;
    const newQuantity = QuantityConverter.convertQuantity(currentQuantity, uom);

    this.setState({
      currentQuantity: { ...newQuantity },
      grossString: formatValue(newQuantity.gross, uom),
      tareString: formatValue(newQuantity.tare, uom),
      netString: formatValue(newQuantity.net, uom),
    }, this.onChange);
  };

  renderUomDropDown = () => {
    const { currentQuantity } = this.state;
    const initialSelected = weightOptions.find(w => w.value === currentQuantity.unitOfMeasure);

    return (
      <div className={styles.uomContainer}>
        <span className={styles.label}>UNITS:</span>
        <DropDownList
          items={weightOptions}
          initialSelected={initialSelected}
          updateSelected={this.uomChange}
          displayPrompt={false}
        />
      </div>
    );
  };

  updateInputScale = (item?: any) => {
    const currentQuantity = {
      ...this.state.currentQuantity,
      gross: 0,
    };

    const scale = this.props.scalesList.find(s => s.id === item?.value);

    if (scale && currentQuantity) {
      let previouslyUsedScales = JSON.parse(localStorage.previouslyUsedScales || '{}');
      const userID = getIdFromUrl(this?.props?.currentUser ?? undefined);
      previouslyUsedScales[userID] = item;

      localStorage.previouslyUsedScales = JSON.stringify(previouslyUsedScales);

      this.setState({
        grossString: formatValue(currentQuantity.gross, currentQuantity?.unitOfMeasure),
        currentQuantity
      });

      this.props.onChange(scale, currentQuantity);
    }
  };

  renderScaleDropdown = () => {
    const { currentScale } = this.state;
    const { scalesList } = this.props;

    if (!currentScale && (!scalesList || scalesList.length === 0)) {
      return null;
    }

    const items = scalesList
      .map(s => ({
        value: s.id,
        label: s.name,
        text: s.name,
      }))
      .sort((s1, s2) => {
        const s1Text = s1.text ?? '';
        const s2Text = s2.text ?? '';
        return s1Text.localeCompare(s2Text);
      });

    let initiallySelected;
    try {
      const savedPreviousScales = JSON.parse(localStorage.previouslyUsedScales);
      const currentUserId = getIdFromUrl(this?.props?.currentUser ?? undefined);
      initiallySelected = savedPreviousScales[currentUserId] ?? items[0];
    } catch (e) {
      initiallySelected = items.find(i => i.value === currentScale?.id) || items[0];
    }

    return (
      <DropDownList
        items={items}
        initialSelected={initiallySelected}
        updateSelected={this.updateInputScale}
      />
    );
  };

  get hasInvalidNet() {
    if (!this.state.currentTruck) {
      // can't have invalid Net if a Truck isn't selected
      return false;
    }
    return this.state.currentQuantity.net <= 0;
  }

  renderWeightAlerts() {
    const { receivedWeight, scaleHub, scale } = this.props;
    const hasAlert = this.hasInvalidNet || receivedWeight.isMoving || !scaleHub.connected
      || this.props.isScaleMaxWeightExceeded(this.state.currentQuantity.unitOfMeasure)
      || this.props.isTruckTypeMaxWeightExceeded(this.state.currentQuantity.unitOfMeasure);

    if (!hasAlert || !scale) {
      return null;
    }

    if (this.isAutoScale(scale) && !scaleHub.connected) {
      return (
        <div className={styles.weightAlert}>
          <LoadingCircle className={styles.loadingCircle} size={16} />
          &nbsp;
          DISCONNECTED. ATTEMPTING TO CONNECT TO SCALE HOST...
        </div>
      );
    }

    if (this.isAutoScale(scale) && !scaleHub.connected) {
      return (
        <div className={styles.weightAlert}>
          <LoadingCircle className={styles.loadingCircle} size={16} />
          &nbsp;
          DISCONNECTED. ATTEMPTING TO CONNECT TO SCALE...
        </div>
      );
    }

    if (this.props.isScaleMaxWeightExceeded(this.state.currentQuantity.unitOfMeasure)) {
      return (
        <div className={styles.weightAlertCaps}>
          <i
            aria-hidden="true"
            className="icon-warning"
          />
          &nbsp;
          Maximum scale weight exceeded
        </div>
      );
    }

    if (this.props.isTruckTypeMaxWeightExceeded(this.state.currentQuantity.unitOfMeasure)) {
      const fromUom = weightOptions.find(i => i.name === this.props.truck?.vehicleType?.unitOfMeasure)?.value ?? UnitOfMeasure.POUNDS;
      const toUom = this.state.currentQuantity.unitOfMeasure ?? UnitOfMeasure.POUNDS;
      const maxVehicleTypeWeight = QuantityConverter.convertValue(this.props.truck?.vehicleType?.maxWeight ?? 0, fromUom, toUom);

      return (
        <div className={styles.weightAlertCaps}>
          <i
            aria-hidden="true"
            className="icon-warning"
          />
          &nbsp;
          Maximum weight exceeded for this vehicle type
          (
          {formatValue(maxVehicleTypeWeight, toUom) ?? 0}
          {' '}
          {weightOptions.find(i => i.value === this.state.currentQuantity.unitOfMeasure)?.label ?? UOM_LABELS.POUNDS}
          S)
        </div>
      );
    }

    if (this.hasInvalidNet) {
      return (
        <div className={styles.weightAlert}>
          <i
            aria-hidden="true"
            className="icon-warning"
          />
          &nbsp;
          Net weight must be more than zero
        </div>
      );
    }

    if (receivedWeight.isMoving) {
      return (
        <div className={styles.weightWarning}>
          <LoadingCircle className={styles.loadingCircle} size={16} />
          &nbsp;
          SCALE CURRENTLY IN MOTION...
        </div>
      );
    }

    return null;
  }

  allowingDecimals = () => {
    const unit = this.state.currentQuantity?.unitOfMeasure;
    return unit !== UnitOfMeasure.POUNDS && unit !== UnitOfMeasure.KILOGRAMS;
  };

  /* eslint-disable jsx-a11y/interactive-supports-focus */
  renderWeightEntries = () => (
    <div className={styles.scaleRectangleContainer}>
      <div
        className={styles.scaleElementRectangle}
        onClick={this.handleContainerClick}
        role="textbox"
        onKeyPress={this.handleContainerKeyboardEvent}
      >

        <CardNumericInput
          id="grossInput"
          label="Scale"
          disabled={this.isAutoScale(this.state.currentScale)}
          className="widthSpace"
          maxLength={10}
          allowDecimal={this.allowingDecimals()}
          fractionalDigits={this.allowingDecimals() ? 2 : 0}
          hideCharacterCount
          onChange={(e) => this.onGrossValueChange(e.grossInput)}
          onTouch={() => this.setState({
            grossString: formatValue(this.state.currentQuantity.gross, this.state.currentQuantity?.unitOfMeasure)
          })}
          errorMessage={this.props.isScaleMaxWeightExceeded(this.state.currentQuantity.unitOfMeasure)
            || this.props.isTruckTypeMaxWeightExceeded(this.state.currentQuantity.unitOfMeasure) ? ' ' : undefined}
          value={formatValue(this.state.currentQuantity.gross, this.state.currentQuantity?.unitOfMeasure)}
        />
        <div className={styles.uom}>{this.unitOfMeasureLabel()}</div>
      </div>

      <div className={styles.scaleElementRectangleTare}>
        <CardNumericInput
          id="tareInput"
          label="Tare"
          className="widthSpace"
          maxLength={10}
          hideCharacterCount
          allowDecimal={this.allowingDecimals()}
          fractionalDigits={this.allowingDecimals() ? 2 : 0}
          onTouch={() => { }}
          onChange={() => { }}
          disabled
          errorMessage={
            this.isTareExpired(this.state.currentTruck?.currentTare, this.state.currentTruck?.scaleExpirationDays)
              ? '' : this.props.tareError
          }
          value={this.state.tareString}
        />
        <div className={styles.uom}>{this.unitOfMeasureLabel()}</div>
      </div>

      <div className={styles.scaleElementRectangleTare}>
        <CardNumericInput
          id="netInput"
          label="Net"
          disabled
          errorWhileDisabled
          className="widthSpace"
          maxLength={10}
          hideCharacterCount
          allowDecimal={this.allowingDecimals()}
          fractionalDigits={this.allowingDecimals() ? 2 : 0}
          errorMessage={this.hasInvalidNet ? 'Net weight must be more than zero' : undefined}
          onTouch={() => { }}
          value={this.state.netString}
        />
        <div className={styles.uom}>{this.unitOfMeasureLabel()}</div>
      </div>
    </div>
  );

  renderWeightDashes = () => (
    <div className={styles.scaleDashesContainer}>
      <div className={styles.scaleDashesRectangle}>
        <span className={styles.scaleDashLabel}>Scale</span>
        <div className={styles.scaleDashUOM}>
          -
          {this.unitOfMeasureLabel()}
        </div>
      </div>

      <div className={styles.scaleDashesRectangle}>
        <span className={styles.scaleDashLabel}>Tare</span>
        <div className={styles.scaleDashUOM}>
          -
          {this.unitOfMeasureLabel()}
        </div>
      </div>

      <div className={styles.scaleDashesRectangle}>
        <span className={styles.scaleDashLabel}>Net</span>
        <div className={styles.scaleDashUOM}>
          -
          {this.unitOfMeasureLabel()}
        </div>
      </div>
    </div>
  );

  displayScale = (tare?: TareHistoryDto) => {
    const { scalesList } = this.props;
    return scalesList.find(s => s.id === tare?.scale)?.name ?? '';
  };

  render() {
    const { scaleHub, scalesList, receivedWeight } = this.props;

    if (!scalesList.length) {
      return null;
    }

    const { currentScale, currentTruck } = this.state;

    const liveScale = currentScale?.scaleType === ScaleType.LIVE || false;
    const validScaleReadings = !liveScale || (receivedWeight.weight !== 0);

    return (
      <>
        <div className={styles.header}>
          <span className={styles.VehicleScaleLabel}>SCALE</span>
          {this.renderUomDropDown()}
        </div>

        <div className={styles.scaleContainer}>
          {this.renderScaleDropdown()}
          {this.renderWeightAlerts()}
          <div className={styles.container}>

            {validScaleReadings ? this.renderWeightEntries() : this.renderWeightDashes()}

            <div className={styles.tareContainer}>
              {this.state.currentTruck
                && (
                  <div>
                    <div className={cx(styles.labelText, styles.truckLabelText)}>
                      Truck
                      {' '}
                      {this.state.currentTruck?.truckAlias}
                    </div>

                    <div>
                      {!hasTare(currentTruck) && (
                        <span className={styles.tareRequiredText}>
                          Tare Required.
                        </span>
                      )}
                    </div>

                    <div>
                      {this.state.currentTruck?.currentTare && (
                        <div>
                          <div className={cx(styles.labelText, styles.tareLabelText)}>CURRENT TARE</div>
                          <div className={styles.tareDetails}>
                            <div>{this.displayTare(this.state.currentTruck?.currentTare)}</div>
                            <div>{this.displayScale(this.state.currentTruck?.currentTare)}</div>
                            <div>{this.displayUser(this.state.currentTruck?.currentTare)}</div>
                            {this.isTareExpired(this.state.currentTruck?.currentTare, this.state.currentTruck?.scaleExpirationDays)
                              ? (
                                <div className={styles.tareExpireText}>
                                  <div className={styles.tareExpireText}>
                                    {this.displayTimestamp(this.state.currentTruck?.currentTare)}
                                  </div>
                                  <i className={cx('icon-warning', 'margin-right-5')} />
                                  EXPIRED
                                </div>
                              )
                              : (
                                <div>
                                  {this.displayTimestamp(this.state.currentTruck?.currentTare)}
                                  {this.state.currentTruck?.currentTare?.manual
                                    && (
                                      <span className={styles.tareManualText}>
                                        {' '}
                                        *Manual
                                      </span>
                                    )}
                                </div>
                              )}

                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      {this.state.currentTruck?.previousTare && (
                        <div>
                          <div className={cx(styles.labelText, styles.tareLabelText)}>PREVIOUS TARE</div>
                          <div className={styles.tareDetails}>
                            <div>{this.displayTare(this.state.currentTruck?.previousTare)}</div>
                            <div>{this.displayScale(this.state.currentTruck?.previousTare)}</div>
                            <div>{this.displayUser(this.state.currentTruck?.previousTare)}</div>
                            {this.isTareExpired(this.state.currentTruck?.previousTare, this.state.currentTruck?.scaleExpirationDays)
                              ? (
                                <div className={styles.tareExpireText}>
                                  <div className={styles.tareExpireText}>
                                    {this.displayTimestamp(this.state.currentTruck?.previousTare)}
                                  </div>
                                  <i className={cx('icon-warning', 'margin-right-5')} />
                                  EXPIRED
                                </div>
                              )
                              : (
                                <div>
                                  {this.displayTimestamp(this.state.currentTruck?.previousTare)}
                                  {this.state.currentTruck?.previousTare?.manual
                                    && (
                                      <span className={styles.tareManualText}>
                                        {' '}
                                        *Manual
                                      </span>
                                    )}
                                </div>
                              )}

                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      {(this.state.currentTruck?.previousTare && this.state.currentTruck?.currentTare) && (
                        <div>
                          <div className={cx(styles.labelText, styles.tareLabelText)}>
                            CHANGE:
                            <span>
                              {' '}
                              {this.calculateTareChange()}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                  </div>
                )}
            </div>

          </div>

        </div>

        <div className={styles.btnGroup}>
          <button
            onClick={this.onTareClick}
            name="Tare"
            className={cx('tt-btn', styles.tareButton)}
            disabled={this.isTareButtonDisabled()}
            tabIndex={-1}
          >
            Tare
          </button>
        </div>
      </>
    );
  }
}

export function mapStateToProps(state: ReduxState) {
  return {
    scalesList: state.scalesList?.scales ?? [],
    plantsList: state.plantList,
    currentPlant: state.currentPlant,
    usersList: state.usersList.users,
    currentUser: state.userUrl,
    scaleHub: state.scaleHub,
  };
}
type ScaleSectionReduxProps = ReturnType<typeof mapStateToProps>;

type ScaleSectionDispatchProps = {
  updateTruck: ConnectedDispatchFunction<typeof updateTruck>;
};

type ScaleSectionOwnProps = {
  truck?: TruckDto,
  scale?: ScaleDto,
  quantity: QuantityDto,
  tareError?: string,
  onTared: (truck: TruckDto) => void,
  onChange: (scale: ScaleDto, quantity: QuantityDto) => void,
  isScaleMaxWeightExceeded: (currentUom: UnitOfMeasure) => boolean,
  isTruckTypeMaxWeightExceeded: (currentUom: UnitOfMeasure) => boolean,
  receivedWeight: ReceivedWeight
};

export type ScaleSectionProps = ScaleSectionReduxProps & ScaleSectionDispatchProps & ScaleSectionOwnProps;

export default connect(
  mapStateToProps,
  { updateTruck }
)(ScaleSection);
