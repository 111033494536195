import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PermissionAccess,
  ProductLine, TicketDto, UserRegionDto, VoidFunc
} from '@trucktrax/trucktrax-ts-common';
import {
  DateWrapper, Label, TopNav, SidebarSelection
} from '@trucktrax/trucktrax-common';
import { Checkbox, FormControlLabel } from '@mui/material';
import debounce from 'lodash/debounce';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DataTableContent, { listenToSource } from '../../shared/DataTableContent';
import {
  addDataTest, addHeaderDataTest, getRegionUrlToNameMap, getUrl
} from '../../../util/adminUtil';
import { OPEN_TICKETS_TEXT } from '../../../constants/navConstants';
import {
  ACCENT,
  ADMIN_KEYS,
  ADMIN_LABELS,
  ADMIN_SEARCH_HINT,
  ARE_YOU_SURE, CANCEL_LABEL,
  CONFIRM_CLOSE_TICKETS, ERROR, SUCCESS, TICKET_ERROR_CLOSE, TICKET_MULTIPLE_COSE, TICKETS_CLOSE_SUCCESS,
  TICKETS_SELECTED
} from '../../../constants/appConstants';
import { DATASOURCE, TICKETS_PATH } from '../../../constants/apiConstants';
import { getTicketingBaseUrl } from '../../../util/apiUtil';
import { ReduxState } from '../../../store';
import styles from './TicketsView.module.scss';
import { addProductLinePill } from './TicketsUtils';
import Title from '../../shared/header/Title';
import Autocomplete from '../../shared/forms/Autocomplete';
import { closeModal, openModal } from '../../../store/actions/errorModalActions';
import { closeOpenTicket } from '../../../services/ticketsService';
import { openSnackbar } from '../../../store/actions/snackbarActions';
import { ConnectedFunction, SidebarTitleType } from '../../../types';
import setSidebarSelected from '../../../store/actions/sidebarActions';
import { selectTicket, clearSelectedTicket, isOpenTicket } from '../../../store/actions/ticketActions';
import { TicketsQueryParams } from './TicketsQueryParams';

export class TicketsView extends Component<TicketsViewProps, TicketsViewState> {
  constructor(props: TicketsViewProps) {
    super(props);
    this.state = {
      productLines: [],
      searchTerm: '',
      ticketsToClose: []
    };
  }

  componentDidUpdate(prevProps: TicketsViewProps) {
    if (this.props.sidebarSelection.isSelected !== prevProps.sidebarSelection.isSelected) {
      if (!this.props.sidebarSelection.isSelected) {
        if (this.props.needToRefreshData) {
          this.setState({ dataTableNeedsRefresh: !this.state.dataTableNeedsRefresh });
          this.setState({ ticketsToClose: [] });
        }
      }
    }
  }

  clearTicketsToClose = () => {
    this.setState({ ticketsToClose: [] });
  };

  generateCheckboxForTicket = (ticket: TicketDto) => {
    const checkBox = (
      <div className={styles.rowCheckbox}>
        <Checkbox
          checked={this.state.ticketsToClose.map(existentTickets => existentTickets.ticketNumber).includes(ticket.ticketNumber)}
          onClick={(event) => {
            event.stopPropagation();
            this.handleTicketSelected(ticket);
          }}
        />
      </div>
    );
    return checkBox;
  };

  handleTicketSelected = (ticket: TicketDto) => {
    this.setState(prevState => {
      if (prevState.ticketsToClose.map(existentTicket => existentTicket.ticketNumber).includes(ticket.ticketNumber)) {
        return { ticketsToClose: prevState.ticketsToClose.filter(removedTicket => removedTicket.ticketNumber !== ticket.ticketNumber) };
      }
      return { ticketsToClose: [...prevState.ticketsToClose, ticket] };
    });
  };

  generateHeaderCheckbox = () => {
    const checkBox = (
      <span className={styles.headerContainer}>
        <Checkbox
          className={styles.rowCheckbox}
          indeterminate={this.state.ticketsToClose.length > 0 && this.state.ticketsToClose.length < this.props.dataTableList.items.length}
          checked={this.state.ticketsToClose.length > 0 && this.state.ticketsToClose.length === this.props.dataTableList.items.length}
          onChange={this.handleSelectAll}
        />
      </span>
    );
    return checkBox;
  };

  getColumns = () => {
    const hasPermissionAccess = this.props.ticketsPermissionAccess === PermissionAccess.Edit;
    if (!hasPermissionAccess) {
      return this.baseColumns();
    }
    return [{
      Header: this.generateHeaderCheckbox(),
      minWidth: 90,
      sortable: false,
      Cell: (props: any) => this.generateCheckboxForTicket(props.original),
    }, ...this.baseColumns()];
  };

  baseColumns = () => [
    {
      Header: addHeaderDataTest(null, 'sort-tickets-by-number', ADMIN_LABELS.TICKET_NUMBER),
      accessor: ADMIN_KEYS.TICKET_NUMBER,
      minWidth: 100,
      sortable: true,
      Cell: (props: any) => addDataTest(
        TicketsView.isToday(props.row[ADMIN_KEYS.TICKETED_TIMESTAMP]) ? styles.highlightText : '',
        'admin-tickets-numbers',
        props.value
      ),
    },
    {
      Header: addHeaderDataTest(null, 'sort-tickets-by-product-line', ADMIN_LABELS.PRODUCT_LINE),
      accessor: ADMIN_KEYS.EXTERNAL_INFO_PRODUCT_LINE,
      minWidth: 120,
      sortable: true,
      Cell: (props: any) => addProductLinePill(props.value),
    },
    {
      Header: addHeaderDataTest(null, 'sort-tickets-by-region', ADMIN_LABELS.REGION),
      id: ADMIN_KEYS.REGION,
      accessor: (row: any) => getRegionUrlToNameMap(this.props.regions)[getUrl(row)!],
      minWidth: 100,
      sortable: true,
      Cell: (props: any) => addDataTest(
        TicketsView.isToday(props.row[ADMIN_KEYS.TICKETED_TIMESTAMP]) ? styles.highlightText : '',
        'admin-tickets-regions',
        props.value
      ),
    },
    {
      Header: addHeaderDataTest(null, 'sort-tickets-by-date', ADMIN_LABELS.DATE),
      accessor: ADMIN_KEYS.TICKETED_TIMESTAMP,
      minWidth: 120,
      sortable: true,
      Cell: (props: any) => addDataTest(
        TicketsView.isToday(props.value) ? styles.alertText : '',
        'admin-tickets-date',
        new DateWrapper(props.value).toUSDateString()
      ),
    },
    {
      Header: addHeaderDataTest(null, 'sort-tickets-by-truck', ADMIN_LABELS.TRUCK),
      accessor: ADMIN_KEYS.EXTERNAL_INFO_TRUCK_ALIAS,
      minWidth: 140,
      sortable: true,
      Cell: (props: any) => addDataTest(
        TicketsView.isToday(props.row[ADMIN_KEYS.TICKETED_TIMESTAMP]) ? styles.highlightText : '',
        'admin-tickets-truck',
        props.value
      ),
    },
    {
      Header: addHeaderDataTest(null, 'sort-tickets-by-driver', ADMIN_LABELS.CURRENT_LAST_DRIVER),
      accessor: ADMIN_KEYS.EXTERNAL_INFO_DRIVER_NAME,
      minWidth: 170,
      sortable: true,
      Cell: (props: any) => addDataTest(
        TicketsView.isToday(props.row[ADMIN_KEYS.TICKETED_TIMESTAMP]) ? styles.highlightText : '',
        'admin-tickets-driver',
        props.value
      ),
    },
    {
      Header: addHeaderDataTest(null, 'sort-tickets-by-order', ADMIN_LABELS.ORDER),
      accessor: ADMIN_KEYS.ORDER_ORDER_NUMBER,
      minWidth: 84,
      sortable: true,
      Cell: (props: any) => addDataTest(
        TicketsView.isToday(props.row[ADMIN_KEYS.TICKETED_TIMESTAMP]) ? styles.highlightText : '',
        'admin-tickets-order',
        props.value
      ),
    },
    {
      Header: addHeaderDataTest(null, 'sort-tickets-by-customer', ADMIN_LABELS.CUSTOMER),
      accessor: ADMIN_KEYS.ORDER_CUSTOMER_NAME,
      minWidth: 240,
      sortable: true,
      Cell: (props: any) => addDataTest(
        TicketsView.isToday(props.row[ADMIN_KEYS.TICKETED_TIMESTAMP]) ? styles.highlightText : '',
        'admin-tickets-customer',
        props.value
      ),
    },
    {
      Header: addHeaderDataTest(null, 'sort-tickets-by-address', ADMIN_LABELS.ADDRESS),
      accessor: ADMIN_KEYS.ADDRESS,
      minWidth: 320,
      sortable: true,
      Cell: (props: any) => addDataTest(
        TicketsView.isToday(props.row[ADMIN_KEYS.TICKETED_TIMESTAMP]) ? styles.highlightText : '',
        'admin-tickets-address',
        props.value
      ),
    },
    {
      Header: addHeaderDataTest(null, 'sort-tickets-by-plant-name', ADMIN_LABELS.LOADING_PLANT),
      accessor: ADMIN_KEYS.EXTERNAL_INFO_PLANT_NAME,
      minWidth: 320,
      sortable: true,
      Cell: (props: any) => addDataTest(
        TicketsView.isToday(props.row[ADMIN_KEYS.TICKETED_TIMESTAMP]) ? styles.highlightText : '',
        'admin-tickets-plant-name',
        props.value
      ),
    },
  ];

  static isToday = (date: string) => new DateWrapper(date).toUSDateString() === new DateWrapper().toUSDateString();

  getLeftElementFromHeader = () => {
    const elementLeft = (
      <div className={styles.leftHeader}>
        <Title
          key="title"
          title={OPEN_TICKETS_TEXT}
        />
        <div className={styles.productLineFilter}>
          {this.renderProductLinesFilters()}
        </div>
      </div>);

    return elementLeft;
  };

  getRightElementFromHeader = () => (
    <div className={styles.searchTermFilter}>
      <div className={styles.searchWrapper}>
        <Autocomplete
          hintText={ADMIN_SEARCH_HINT.OpenTickets}
          onUpdateInput={this.handleUpdateInput}
          openOnFocus={false}
          autocompleteHeight="500px"
          clearSearch={() => this.clearSearch()}
          onNewRequest={() => {
          }}
        />
      </div>
    </div>
  );

  handleSelectAll = () => {
    const { items } = this.props.dataTableList;

    this.setState(prevState => {
      if (prevState.ticketsToClose.length === 0 && items.length > 0) {
        return { ticketsToClose: items };
      }
      return { ticketsToClose: [] };
    });
  };

  handleUpdateInput = debounce((searchText: string) => {
    this.clearTicketsToClose();
    this.setState({ searchTerm: searchText.trim() });
  }, 500);

  clearSearch = () => {
    this.clearTicketsToClose();
    this.setState({ searchTerm: '' });
  };

  renderProductLinesFilters = () => {
    const productLines = [ProductLine.Aggregates, ProductLine.Cement, ProductLine.ReadyMix];

    return (
      <div>
        <Label className={styles.filterTitle}>
          {ADMIN_LABELS.PRODUCT_LINE}
        </Label>
        {productLines?.map((pl, index) => {
          const key = `checkbox${pl}${index}`;
          return (
            <FormControlLabel
              control={(
                <Checkbox
                  name={key}
                  key={key}
                  onChange={(e) => this.onProductLineChangeHandler(e, pl)}
                />
              )}
              label={addProductLinePill(pl)}
              key={key}
              className={styles.checkboxLabel}
            />
          );
        })}
      </div>
    );
  };

  onProductLineChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, selectedProductLine: ProductLine) => {
    const { productLines } = this.state;
    if (event.target.checked) {
      productLines.push(selectedProductLine);
    } else {
      productLines.splice(productLines.indexOf(selectedProductLine, 0), 1);
    }
    this.setState({ productLines });
    this.clearTicketsToClose();
  };

  getQueryParams = (): TicketsQueryParams => {
    const { productLines, dataTableNeedsRefresh } = this.state;

    return {
      InProductLines: productLines.join(','),
      SearchTerm: this.state.searchTerm,
      IsClosed: false,
      IsVoided: false,
      dataTableNeedsRefresh,
    };
  };

  confirmCloseTickets = () => {
    const modalBody = (
      <span>
        <p>{TICKET_MULTIPLE_COSE}</p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.closeTickets,
      acceptText: CONFIRM_CLOSE_TICKETS,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  closeTickets = async () => {
    const toastMessages = {
      success: TICKETS_CLOSE_SUCCESS,
      fail: TICKET_ERROR_CLOSE,
    };

    const ticketsWillClose = this.state.ticketsToClose.map((ticket: TicketDto) => ({
      ...ticket,
      closed: true,
    }));

    let totalErrors = 0;
    const failedTickets: TicketDto[] = [];

    await Promise.all(ticketsWillClose.map(async ticket => {
      const updated = await closeOpenTicket(ticket);
      if (updated instanceof Error) {
        totalErrors += 1;
        failedTickets.push(ticket);
      }
    }));

    if (totalErrors === 0) {
      this.props.openSnackbar({
        snackbarBody: toastMessages.success,
        dataTest: 'close-tickets-success-snackbar',
        snackbarType: SUCCESS,
      });
    } else {
      this.props.openSnackbar({
        snackbarBody: `${totalErrors} ${totalErrors === 1 ? 'ticket' : 'tickets'} ${toastMessages.fail}`,
        dataTest: 'close-tickets-error-snackbar',
        snackbarType: ERROR,
      });
    }

    this.props.closeModal();
    this.setState({ ticketsToClose: failedTickets });
    this.setState(prevState => ({ dataTableNeedsRefresh: !prevState.dataTableNeedsRefresh }));
    this.props.setSidebarSelected!(TICKETS_SELECTED, false);
    this.props.clearSelectedTicket();
  };

  backendRowClick = (rowInfo?: any) => {
    this.props.clearSelectedTicket();
    this.props.selectTicket(rowInfo.original);
    this.props.isOpenTicket(true);
    this.props.setSidebarSelected!(TICKETS_SELECTED, true);
  };

  render() {
    const baseUrl = getTicketingBaseUrl() + TICKETS_PATH;
    const columns = this.getColumns();
    const elementLeft = this.getLeftElementFromHeader();
    const elementRight = this.getRightElementFromHeader();
    const hasTicketsToClose = this.state.ticketsToClose.length > 0;
    const buttonSectionClass = hasTicketsToClose ? styles.enabledCloseSection : styles.disabledCloseSection;
    const hasPermissionAccess = this.props.ticketsPermissionAccess === PermissionAccess.Edit;

    const customHeader = (
      <div>
        <TopNav
          headerStyle={styles.headerStyle}
          elementsLeft={elementLeft}
          elementsRight={elementRight}
        />
        <div className={buttonSectionClass}>
          <div key="action-buttons">
            {hasPermissionAccess && (
              <button
                onClick={this.confirmCloseTickets}
                disabled={!hasTicketsToClose}
              >
                <RemoveCircleOutlineIcon />
                <span>
                  {
                    (this.state.ticketsToClose.length > 1 ? ADMIN_LABELS.CLOSE_TICKET_PLURAL : ADMIN_LABELS.CLOSE_TICKET_SINGULAR)
                  }
                </span>
              </button>
            )}
          </div>
          <span hidden={!hasTicketsToClose || !hasPermissionAccess}>{ADMIN_LABELS.CLOSE_TICKETS_WARNING}</span>
        </div>
      </div>
    );

    return (
      <DataTableContent
        baseUrl={baseUrl}
        columns={columns}
        header={OPEN_TICKETS_TEXT}
        byPassRedisCache
        defaultSortedId={ADMIN_KEYS.TICKETED_TIMESTAMP}
        customHeader={customHeader}
        queryParams={this.getQueryParams()}
        onRowClick={this.backendRowClick}
        source={DATASOURCE.TICKET}
      />
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  ticketsPermissionAccess: state.adminPermissionAccess[OPEN_TICKETS_TEXT] ?? '',
  regions: state.assignedRegionList,
  dataTableList: listenToSource(state),
  sidebarSelection: state.sidebar,
  needToRefreshData: state.needToRefreshData,
});

export interface TicketsViewProps {
  clearSelectedTicket: VoidFunc;
  closeModal: typeof closeModal;
  dataTableList: { items: TicketDto[] };
  isOpenTicket: (isOpenTicket: boolean) => void;
  needToRefreshData: boolean,
  openModal: typeof openModal;
  openSnackbar: ConnectedFunction<typeof openSnackbar>;
  regions: UserRegionDto[];
  selectTicket: (ticket: TicketDto) => void;
  setSidebarSelected?: (sidebarTitle: SidebarTitleType, isSelected: boolean) => void,
  sidebarSelection?: SidebarSelection,
  ticketsPermissionAccess?: string,
}

export interface TicketsViewState {
  dataTableNeedsRefresh?: boolean;
  isOpen?: boolean;
  productLines: ProductLine[];
  searchTerm: string;
  ticketsToClose: TicketDto[];
}

export default connect(mapStateToProps, {
  clearSelectedTicket,
  closeModal,
  isOpenTicket,
  openModal,
  openSnackbar,
  selectTicket,
  setSidebarSelected,
})(TicketsView);
