import React from 'react';
import { find, isEqual } from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import cx from 'classnames';
import { Dispatch } from 'redux';
import {
  DeviceType,
  ProductLine,
  UserRegionDto,
  PlantDto,
  GeometryDto,
  LocationDto,
  DriverDto,
  ChecklistDto,
  UnitOfMeasure,
  ScaleDto,
  VehicleTypeDto,
  HaulerDto,
  UrlKeyDto,
} from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import recordLog from './errorUtil';
import {
  ADMIN_KEYS,
  AGGREGATE_STRING,
  CEMENT_STRING,
  EXTERNAL,
  INTERNAL,
  NONE_STRING,
  NOT_AVAILABLE,
  OR,
  READYMIX_STRING,
  READYMIX_VALUE,
  OPTION_LOGIN_STRING,
  OPTION_LOGIN_VALUE,
  GEOZONE_TYPE,
  DEVICE_LABELS,
  LIVE_SCALE_RADIO,
  MANUAL_SCALE_RADIO,
  LIVE_SCALE_RADIO_LABEL,
  MANUAL_SCALE_RADIO_LABEL,
  ACCENT,
  ARE_YOU_SURE,
  CANCEL_LABEL,
  SSO_ACCEPT_LABEL,
  SSOID_REMOVAL_WEB_USER_BODY,
  AGGREGATE_VALUE,
  CEMENT_VALUE,
  ADMIN_LABELS,
} from '../constants/appConstants';
import { ORDERS_PATH } from '../constants/apiConstants';
import { getTicketingBaseUrl } from './apiUtil';
import {
  HasName,
  HasNameParts,
  HasUrl,
  HasDescription,
  MayHaveExternalId,
  HasProductLines,
  HasId,
  IsRemovable,
  HasAddress,
  UserRegionWithName,
  SimpleEvent,
  DropDownListItem,
  MaybeDefaultRegion,
  ModalType,
  LoadingTimeValue
} from '../types';
import { UserDtoSecondaryRegions } from '../components/admin/users/UsersAddModal';

export const UOM_LABELS = {
  SHORT_TONS: 'TON',
  SHORT_TONS_AS_TN: 'TN',
  METRIC_TONS: 'MT',
  POUNDS: 'LB',
  KILOGRAMS: 'KG',
  GRAMS: 'G',
  CUBIC_METERS: 'M3',
  CUBIC_YARDS: 'CY',
  METERS: 'M',
  YARDS: 'YD',
  FEET: 'FT',
  INCHES: 'IN',
  CENTIMETERS: 'CM',
  GALLONS: 'GAL',
  FLUID_OUNCES: 'FL OZ',
  WEIGHT_OUNCES: 'OZ',
  EACH: 'EA',
  LOAD: 'LD',
};

export const LOADINGTIME_LABELS = {
  FIVE: '5 min prior to ticketed time',
  TEN: '10 min prior to ticketed time',
  FIFTEEN: '15 min prior to ticketed time',
  TWENTY: '20 min prior to ticketed time',
  TWENTYFIVE: '25 min prior to ticketed time',
  THIRTY: '30 min prior to ticketed time',
};

export const weightOptions = [
  {
    label: 'LB',
    name: 'POUNDS',
    value: UnitOfMeasure.POUNDS,
  },
  {
    label: 'TN',
    name: 'SHORT_TONS',
    value: UnitOfMeasure.SHORT_TONS,
  },
  {
    label: 'KG',
    name: 'KILOGRAMS',
    value: UnitOfMeasure.KILOGRAMS,
  },
  {
    label: 'MT',
    name: 'METRIC_TONS',
    value: UnitOfMeasure.METRIC_TONS,
  },
];

export const defaultScaleUnitOptions = [
  {
    label: UOM_LABELS.POUNDS,
    value: UnitOfMeasure.POUNDS,
  },
  {
    label: UOM_LABELS.SHORT_TONS_AS_TN,
    value: UnitOfMeasure.SHORT_TONS,
  },
  {
    label: UOM_LABELS.KILOGRAMS,
    value: UnitOfMeasure.KILOGRAMS,
  },
  {
    label: UOM_LABELS.METRIC_TONS,
    value: UnitOfMeasure.METRIC_TONS,
  },
];

export const defaultLoadingTimeOptions = [
  {
    label: LOADINGTIME_LABELS.FIVE,
    value: LoadingTimeValue.FIVE,
  },
  {
    label: LOADINGTIME_LABELS.TEN,
    value: LoadingTimeValue.TEN,
  },
  {
    label: LOADINGTIME_LABELS.FIFTEEN,
    value: LoadingTimeValue.FIFTEEN,
  },
  {
    label: LOADINGTIME_LABELS.TWENTY,
    value: LoadingTimeValue.TWENTY,
  },
  {
    label: LOADINGTIME_LABELS.TWENTYFIVE,
    value: LoadingTimeValue.TWENTYFIVE,
  },
  {
    label: LOADINGTIME_LABELS.THIRTY,
    value: LoadingTimeValue.THIRTY,
  },
];

export const productLineOptions = [
  {
    label: AGGREGATE_STRING,
    value: ProductLine.Aggregates,
  },
  {
    label: CEMENT_STRING,
    value: ProductLine.Cement,
  },
  {
    label: READYMIX_STRING,
    value: ProductLine.ReadyMix,
  },
];

export const formatProductLineString = (value: string) => (value === READYMIX_VALUE ? READYMIX_STRING : value);

export const secondaryProductLineAccessor = (row: any) => row.secondaryProductLines?.map(formatProductLineString).join(', ') ?? [];

export const addDataTest = (
  className: string | null,
  dataTest: string | null,
  value: any
) => <span className={className ?? undefined} data-test={dataTest}>{formatProductLineString(value)}</span>;

export const addHeaderDataTest = (
  className: string | null,
  dataTest: string | null,
  value: any
) => addDataTest(cx(className, 'rt-resizable-header-content'), dataTest, value);

export const getRegionUrlToNameMap = (rs: (HasUrl & HasName)[]) => rs.reduce((acc, r) => {
  acc[r.url!] = r.name!;
  return acc;
}, {} as { [key: string]: string });

export const GeozoneName = (item: HasName) => item.name;
export const GeozoneDescription = (item: HasDescription) => item.description;

export const getFullName = (item: HasNameParts) => `${item.lastName}, ${item.firstName}`;

export const getProductLine = (item: PlantDto) => formatProductLineString(item.productLine);
export const getPrimaryProductLine = (item: HasProductLines) => formatProductLineString(item.primaryProductLine);
export const getSecondaryProductLine = (item: HasProductLines) => formatProductLineString(item.secondaryProductLine);

export const getPrimaryPlantName = (item: DriverDto) => item.primaryPlant!.name;

export const getUrl = (item: { region?: HasUrl }) => (item.region ? item.region.url : '');

export const getOwnershipType = (item: PlantDto) => (item.ownershipType === EXTERNAL ? EXTERNAL : INTERNAL);

export const getDefaultUrl = (items: { regions?: UserRegionDto[] }) => {
  const { regions } = items;
  if (regions) {
    const defaultRegion = regions.filter(obj => obj.defaultRegion);
    return defaultRegion ? defaultRegion[0].url : '';
  }
  return '';
};

export interface KeyRelationship {
  keys: string[];
  relationship: string;
}
export const getOtherRequiredFields = (requiredKeys: KeyRelationship[], keysArray: string[], value: any) => {
  requiredKeys.forEach(keyInfo => {
    if (keyInfo.relationship === OR) {
      const { keys } = keyInfo;
      let noValueFlag = true;
      keys.forEach(k => {
        if (value[k]) {
          keysArray.push(k);
          noValueFlag = false;
          return false;
        }
        return true;
      });
      if (noValueFlag) {
        keysArray.push(...keys);
      }
    } else if (keyInfo.keys[0] === 'geozones' && value[keyInfo.keys[0]]) {
      keysArray.push('geozones');
    }
  });
};

interface Accessor {
  [key: string]: any
}
export const findKeyWithUrl = <T extends HasUrl & Accessor>(value: T, list: T[], key?: string) => {
  const hasValue = value?.url && list;
  const found = hasValue ? find(list, ['url', value.url]) : '';

  if (key) {
    return found ? (found[key] as string) : '';
  }
  return found ?? '';
};

export const sanitizeText = (s: string, removeWhitespace = false) => {
  // sanitizes string by stripping off all special characters
  const sanitizedText = removeWhitespace
    ? s.match(/[a-zA-Z0-9'-]/g)
    // except for white space, single quote and hyphen
    : s.match(/[a-zA-Z0-9 '-]/g);
  if (sanitizedText) {
    return sanitizedText.join('');
  }
  return '';
};

export const getAddressPartOne = (item: HasAddress) => {
  let address = '';
  if (item.addressLineOne) {
    address += `${item.addressLineOne}`;
  }
  if (item.addressLineTwo) {
    address += ` ${item.addressLineTwo}`;
  }
  return address;
};

export const getAddressPartTwo = (item: HasAddress) => {
  let address = '';
  if (item.city) {
    address += `${item.city}`;
  }
  if (item.state) {
    address += `, ${item.state}`;
  }
  if (item.zipCode) {
    address += `, ${item.zipCode}`;
  }
  return address;
};

export const getExternalId = (item: MayHaveExternalId) => {
  let externalId = '';
  if (item.externalId) {
    externalId += `${item.externalId}`;
  }
  return externalId;
};

export const getAddress = (item: HasAddress) => {
  let address = '';
  const addressPartOne = getAddressPartOne(item);
  const addressPartTwo = getAddressPartTwo(item);
  if (addressPartOne && addressPartTwo) {
    address = `${addressPartOne}, ${addressPartTwo}`;
  }
  return address;
};

// Allow any to support both PlantDto and PlantUrlDto
export const getUom = (item: any) => (
  defaultScaleUnitOptions.find(o => o.value === item.defaultUom)?.label ?? defaultScaleUnitOptions[0].label
);

export const getLoadingTime = (item: PlantDto | undefined) => {
  const loadTimeValue = defaultLoadingTimeOptions.find(o => o.value === item?.backfillLoadedTimeDuration)?.value;
  return loadTimeValue !== undefined ? `${loadTimeValue} minutes` : 'Not set';
};

export const getOrdersUrl = (path: string) => `${getTicketingBaseUrl()}${ORDERS_PATH}${path}`;

export const onSubmitSuccess = (callbacks: ((obj: any) => void)[] = [], object = {}) => {
  callbacks.forEach(c => {
    if (c) {
      c(object);
    }
  });
};

export const getPrimaryRegionView = (item: { regions: UserRegionWithName[] }, allRegions: UserRegionWithName[]) => {
  const { regions } = item;
  const defaultRegion = regions.find(region => region.defaultRegion === true);
  const primaryRegion = allRegions.find(r => r.url === defaultRegion?.url);
  return primaryRegion;
};

export const getSecondaryRegionsView = (
  item: { secondaryRegions: any },
  allRegions: UserRegionWithName[]
): UserRegionWithName[] => {
  const { secondaryRegions } = item;

  // Map the names from allRegions.name to regions.name where region.url === allRegions.url
  const updatedRegions = secondaryRegions.map((region: { key: string | undefined; }) => {
    // Find the matching region from allRegions
    const match = allRegions.find(ar => ar.url === region.key);
    // If a match is found, return a new object with the updated name; otherwise, return the original region
    return match ? { ...region, name: match.name, productLines: match.productLines } : region;
  });
  return updatedRegions;
};

export const getDefaultRegion = (item: { regions: UserRegionWithName[] }, allRegions: UserRegionWithName[]) => {
  const { regions } = item;
  const defaultRegion = regions.find(region => region.defaultRegion === true);
  return getRegionUrlToNameMap(allRegions)[getUrl({ region: defaultRegion! })!];
};

export const getSecondaryRegions = (item: { secondaryRegions: (HasUrl & { key?: string })[] }, allRegions: UserRegionWithName[]) => {
  const { secondaryRegions } = item;
  const regionMap = getRegionUrlToNameMap(allRegions);

  const result = secondaryRegions
    .map(r => ({ url: r.key ?? '' }))
    .map(o => regionMap[getUrl({ region: o })!])
    .filter(s => s !== undefined);

  return result.length ? result : NOT_AVAILABLE;
};

export const getScaleHostAddress = <T extends Accessor>(
  details?: T) => {
  if (!details) return undefined;
  const accessor = 'scaleHostAddress';
  let value;
  try {
    value = accessor.split('.').reduce((o, i) => o[i], details);
  } catch (e) {
    value = undefined;
  }
  if (isNull(value) || isUndefined(value)) return undefined;
  if (typeof value === 'string' && (value as string).length === 0) return undefined;
  return value;
};

export const isNull = (value: any) => ((value === null) || (value === 'null'));
export const isUndefined = (value: any) => ((value === undefined) || (value === 'undefined'));

/** This function will return:
 *  undefined: if item is not loaded yet (used to show loading skeleton)
 *  'na' ('N/A'): item is loaded but there is no property from database
 *  'empty' ('N/A'): item is loaded, property exists but value is null (same as na but flexible)
 *  'value' (string): item is loaded, property and value exist (what get's displayed)
 *
 *  All admin view details cards rely on this function. Be cautious if modifying it */
export const accessValue = <T extends Accessor>(
  accessor: string | ((item: T) => string),
  details?: T,
  naText?: string | null,
  emptyText?: string | null) => {
  if (!details) return undefined;
  const na = isUndefined(naText) ? NOT_AVAILABLE : naText;
  const empty = isUndefined(emptyText) ? NOT_AVAILABLE : emptyText;
  let value;
  try {
    if (typeof accessor === 'string') {
      value = accessor.split('.').reduce((o, i) => o[i], details);
    } else if (typeof accessor === 'function') {
      value = accessor(details);
    }
  } catch (e) {
    value = na;
  }
  if (typeof value === 'boolean') {
    if (accessor === ADMIN_KEYS.DRIVER_IS_EXTERNAL) {
      return value;
    }

    return value ? 'Yes' : 'No';
  }
  if (isNull(value)) return empty;
  if (isUndefined(value)) return na;
  return value;
};

export const checkForChange = (initialValue: any, newValue: any) => {
  if (typeof initialValue === 'string' && typeof newValue === 'string') {
    return initialValue !== newValue;
  }
  return !isEqual(initialValue, newValue);
};

export const checkForValidity = (type: string, value = '', isRequired = false) => (
  type === 'dropdown' || ((!isRequired || (!!value && isRequired)) && type !== 'dropdown')
);

interface FormObjectItem {
  valid: boolean;
  changed: boolean;
}

export const disableSave = (formValidationObject: { value: FormObjectItem }) => {
  const values = Object.values(formValidationObject);
  const changedSet = new Set();
  const validSet = new Set();
  values.forEach((value) => {
    validSet.add(value.valid);
    changedSet.add(value.changed);
  });
  const isNotValid = validSet.has(false);
  const changed = changedSet.has(true);
  return !changed || isNotValid;
};

export const adaptListOfStringsToFitDropdown = (stringList: string[]) => stringList.map(s => ({ label: s }));

interface Option {
  label: string | JSX.Element;
  value: string | boolean;
  key?: string;
  disabled?: boolean;
}

export const itemsToLabelValue = (item: string) => ({
  label: item,
  value: item,
});

export const itemsObjToLabelValue = (item: Option) => ({
  label: item.label,
  value: item.value,
});

export const itemsObjToLabelValueDisabled = (item: Option) => ({
  label: item.label,
  value: item.value,
  disabled: item.disabled,
});

export const generateRadioButtons = (
  valuesArray: Option[],
  style: string,
  selectedStyle: string,
  labelStyle?: string,
  disabledStyle?: string
) => (valuesArray.map((radio) => (
  <FormControlLabel
    key={radio.key ? radio.key : radio.value.toString()}
    value={radio.value}
    classes={
      {
        label: labelStyle,
      }
    }
    control={(
      <Radio
        classes={
          {
            root: style,
            checked: selectedStyle,
            disabled: disabledStyle,
          }
        }
        disabled={radio.disabled}
      />
    )}
    label={radio.label}
  />
)));

export const haulerListToMultiSelectOptions = (haulers: HaulerDto[]) => haulers.map(hauler => ({
  label: hauler.name,
  value: { url: hauler.url },
  key: hauler.url!,
  disabled: false,
}));

export const haulerListToMultiSelectOptionsById = (haulers: HaulerDto[]) => haulers.map(hauler => ({
  label: hauler.name,
  value: { id: hauler.id },
  key: hauler.id,
  disabled: false,
}));

export const driverListToDropDownOptions = (drivers: DriverDto[]) => drivers.map(driver => ({
  label: `${driver.lastName}, ${driver.firstName}`,
  value: { url: driver.url },
}));

export const driverListAdminToDropDownOptions = (drivers: DriverDto[], productLine: string) => drivers
  .filter(driver => driver.primaryProductLine === productLine)
  .map(driver => ({
    label: `${driver.lastName}, ${driver.firstName}`,
    value: { url: driver.url },
  }));

export const nameAndUrlItemToDropdownOptions = (items: (HasName & HasUrl)[]) => items.map(item => ({
  label: item.name,
  value: { url: item.url },
}));

export const vehicleTypesToDropDownOptions = (vehicleTypes: VehicleTypeDto[], currentRegion: UserRegionDto & UrlKeyDto) => vehicleTypes
  .map(item => ({
    label: item.name,
    region: item.region,
    value: { id: item.id },
  }))
  .filter(item => item.region.url === currentRegion.url);

export type DropDownOptions = {
  label: string | undefined,
  region: any,
  value: {
    id: number
  }
};

export const nameAndUrlToRegionListItems = (items: (HasName & HasUrl)[]) => items.map(item => ({
  label: item.name!,
  value: { url: item.url! },
  key: item.url!,
  disabled: false,
}));

export const defaultRegionListForDropdown = (
  tenantRegions: UserRegionDto[],
  assignedRegions: UserRegionWithName[],
  secondaryRegion?: UserRegionDto[],
  productLines?: ProductLine[],
  forceFilter: Boolean = false,
) => {
  const defaultRegion = !secondaryRegion;

  const getListItem = (region: UserRegionWithName, disabled: boolean): DropDownListItem => ({
    label: region.name!,
    key: region.url!,
    value: [{
      url: region.url!,
      defaultRegion,
    }],
    disabled,
  });

  const result: DropDownListItem[] = [];

  // Helper function to check if the region contains any of the specified product lines
  const containsProductLine = (region: UserRegionWithName): boolean => {
    if (!forceFilter && (!productLines || productLines.length === 0 || !region.productLines)) {
      return true; // No product lines specified, or region does not define product lines, consider all
    }

    if (!productLines?.length) return false;

    let regionProductLine = region.productLines;

    if (typeof regionProductLine === 'string') {
      regionProductLine = (regionProductLine as string).split(',') as ProductLine[];
    }

    return productLines!.some(productLine => regionProductLine?.includes(productLine));
  };

  // Filter and sort regions with product lines
  if (assignedRegions) {
    assignedRegions
      .filter(containsProductLine) // Filter regions by product lines
      .sort((r1, r2) => r1.name!.localeCompare(r2.name!))
      .forEach(r => {
        result.push(getListItem(r, false));
      });
  }

  // Filter and sort regions without product lines
  if (tenantRegions) {
    tenantRegions
      .filter(r1 => containsProductLine(r1)) // Apply the same product line filter
      .sort((r1: UserRegionWithName, r2: UserRegionWithName) => r1.name!.localeCompare(r2.name!))
      .forEach((r1: UserRegionDto) => {
        if (result.findIndex(r2 => (r2.value as MaybeDefaultRegion[])[0].url === r1.url) < 0) {
          result.push(getListItem(r1, true));
        }
      });
  }
  return result;
};

// accepts a plant DTO's defaultUom and converts it to UI-friendly
export const getWeightOption = (option: any) => {
  const index: keyof typeof UnitOfMeasure = option;
  const value: UnitOfMeasure = UnitOfMeasure[index];
  return weightOptions.find(o => o.value === value);
};

export const formatScaleMaxWeight = (scale: ScaleDto) => {
  if (scale.plant === undefined) {
    return '';
  }
  const { defaultUom } = scale.plant;

  const displayUom = defaultUom ? `${getWeightOption(defaultUom)?.label}` : '';

  return scale.maxWeight
    ? `${scale.maxWeight.toLocaleString()} ${displayUom}`
    : `0 ${displayUom}`;
};

export const capitalizeFirst = (word: any) => (word === undefined ? ''
  : (word[0].toUpperCase() + word.substring(1).toLowerCase()));

export const plantListForDropdown = (plantList: PlantDto[]) => plantList.map(plant => ({
  label: plant.name,
  value: { url: plant.url, defaultUom: plant.defaultUom ? plant.defaultUom : 'POUNDS', name: plant.name },
}));

export function findDefaultRegion(regions: DropDownListItem[]) {
  return (_dispatch: Dispatch, getState: () => any) => {
    const { defaultRegionList: storeRegions } = getState();
    return regions.find(item => {
      // this allows finding regions for a 2 variants used in modals and details
      // 1) nameAndUrlItemToDropdownOptions
      // 2) regionsForDefaultDropdown
      const compare = Array.isArray(item.value) ? item.value[0].url : item.value?.url;
      return compare === storeRegions[0].url;
    });
  };
}

export const findSelectedRegion = (url: string, regions: { key?: string | undefined }[]) => regions.find(item => item.key === url);

export const defaultDriverConfigurationRadioOptions = [
  {
    label: 'Internal',
    value: false,
  },
  {
    label: 'External',
    value: true,
  },
].map(itemsObjToLabelValueDisabled);

export const defaultProductLineRadioOptions = [
  {
    label: AGGREGATE_STRING,
    value: AGGREGATE_VALUE,
  },
  {
    label: CEMENT_STRING,
    value: CEMENT_VALUE,
  },
  {
    label: READYMIX_STRING,
    value: READYMIX_VALUE,
  },
].map(itemsObjToLabelValueDisabled);

export const productLineRadioOptionsNoScaleTrax = [
  {
    label: AGGREGATE_STRING,
    value: AGGREGATE_VALUE,
    disabled: true,
  },
  {
    label: CEMENT_STRING,
    value: CEMENT_VALUE,
    disabled: true,
  },
  {
    label: READYMIX_STRING,
    value: READYMIX_VALUE,
  },
].map(itemsObjToLabelValueDisabled);

export const defaultProductLineRadioOptionsDisabled = [
  {
    label: AGGREGATE_STRING,
    value: AGGREGATE_VALUE,
    disabled: true,
  },
  {
    label: CEMENT_STRING,
    value: CEMENT_VALUE,
    disabled: true,
  },
  {
    label: READYMIX_STRING,
    value: READYMIX_VALUE,
    disabled: true,
  },
].map(itemsObjToLabelValueDisabled);

// eslint-disable-next-line max-len
export const filteredProductLineRadioOptions = (productLines?: ProductLine[]) => defaultProductLineRadioOptions.filter(option => productLines?.includes(option.value as ProductLine));

export const scaleTypeRadioOptions = [
  {
    label: LIVE_SCALE_RADIO_LABEL,
    value: LIVE_SCALE_RADIO,
    disabled: false,
  },
  {
    label: MANUAL_SCALE_RADIO_LABEL,
    value: MANUAL_SCALE_RADIO,
    disabled: false,
  },
].map(itemsObjToLabelValueDisabled);

export const checklistOptionsWhenToPresent = [
  {
    label: `${OPTION_LOGIN_STRING}`,
    value: `${OPTION_LOGIN_VALUE}`,
  },
].map(itemsObjToLabelValue);

export const secondaryProductLineRadioOptions = [
  {
    label: NONE_STRING,
    value: NONE_STRING,
  },
  {
    label: AGGREGATE_STRING,
    value: AGGREGATE_VALUE,
  },
  {
    label: CEMENT_STRING,
    value: CEMENT_VALUE,
  },
  {
    label: READYMIX_STRING,
    value: READYMIX_VALUE,
  },
].map(itemsObjToLabelValue);

export const ownershipTypeRadioOptions = [
  {
    label: INTERNAL,
    value: 'Internal',
  },
  {
    label: EXTERNAL,
    value: 'External',
  },
].map(itemsObjToLabelValue);

// allows true/false values without any explicit mapping
export const vehicleOwnershipTypeRadioOptions = [
  {
    label: INTERNAL,
    value: 'false',
  },
  {
    label: EXTERNAL,
    value: 'true',
  },
].map(itemsObjToLabelValue);

export const geozoneBoundaryRadioOptions = [
  {
    label: 'No, keep boundaries the same as the Plant geozone',
    value: 'true',
  },
  {
    label: 'Yes, add custom geozone boundaries',
    value: 'false',
  },
].map(itemsObjToLabelValue);

export const editableWeightsRadioOptions = [
  {
    label: 'No',
    value: false,
  },
  {
    label: 'Yes',
    value: true,
  },
].map(itemsObjToLabelValue);

export const gtLiteParticipantRadioOptions = [
  {
    label: 'No',
    value: false,
  },
  {
    label: 'Yes',
    value: true,
  },
].map(itemsObjToLabelValue);

export const replaceOrUpdateListById = (object: HasId & IsRemovable, list: HasId[] = []) => {
  const index = list.findIndex(item => item.id === object.id);
  if (index > -1) {
    if (object.archived || object.deleted) {
      list.splice(index, 1);
    } else {
      list.splice(index, 1, object);
    }
  } else {
    list.push(object);
  }
};

type SecondaryRegion = {
  key: string,
};
type Region = {
  url: string,
  defaultRegion: boolean,
};

export const dtoToUserDto = (dto: UserDtoSecondaryRegions | any) => {
  const regions: Region[] = [];
  const secondaryRegions: SecondaryRegion[] = [];
  if (dto.regions?.length) {
    for (let i = 0; i < dto.regions.length; i += 1) {
      regions.push({
        url: dto.regions[i].url,
        defaultRegion: dto.regions[i].defaultRegion,
      });
    }
  }
  if (dto.secondaryRegions?.length) {
    for (let j = 0; j < dto.secondaryRegions.length; j += 1) {
      secondaryRegions.push({
        key: dto.secondaryRegions[j].key,
      });
    }
  }
  return {
    // this field is required by backend but are not editable
    // this field won't actually update the password but is required
    password: 'password',
    ...dto,
    regions,
    secondaryRegions,
  };
};

export const getEventFromTarget = (e: React.ChangeEvent<HTMLInputElement> | SimpleEvent, id: string) => {
  const v = (e.target && e.target.value) ? e.target.value : '';
  return { [id]: v };
};

export const mapAccessor = (x: any) => ({
  location: {
    latitude: accessValue(ADMIN_KEYS.LATITUDE, x, null, null),
    longitude: accessValue(ADMIN_KEYS.LONGITUDE, x, null, null),
  },
  circle: accessValue('circle', x, null, null),
  polygon: accessValue('polygon', x, null, null),
});

// use ADMIN_KEYS and ADMIN_LABELS to convert a key to a human readable label
// e.g. if adminKeysValue === 'productLine', this function should return 'Product Line'
export const getAdminLabelByKey = (adminKeysValue: string) => {
  const key = Object.entries(ADMIN_KEYS).find(([k, v]) => v === adminKeysValue)?.[0];
  if (key && ADMIN_LABELS[key as keyof typeof ADMIN_LABELS]) {
    return ADMIN_LABELS[key as keyof typeof ADMIN_LABELS];
  }

  return adminKeysValue; // default to the adminKeysValue if no label is found
};

export const mapAccessorForPlantDetailGeozone = (values: PlantDto) => {
  type GeozoneTypeMap = typeof GEOZONE_TYPE;
  // Format geozones under selected plant in a format required by CardMapMultizones.
  const geozones = Object.values(GEOZONE_TYPE).reduce((ac, a) => {
    // handle case of Plant geozones are empty.
    if (!values || !values.geozones || values.geozones.length < 1) {
      return ({ ...ac, [a]: { zone: { circle: null, polygon: null } } });
    }
    // Find zone value based on geozone name equals to said geozone type
    // if none found, copy plant's geozone for said geozone type
    // if no geozone name: "plant", use a geozone under any names not in the list of geozone type
    // NOTE - in the past, plant geozone is named after plant's.
    const geozone = values.geozones.find(g => g.name === a) || (
      values.geozones.find(g => g.name === GEOZONE_TYPE.PLANT || !GEOZONE_TYPE[g.name as keyof GeozoneTypeMap])
    );
    return ({ ...ac, [a]: { zone: { ...geozone?.zone } } });
  }, {});

  return ({
    geozones,
    location: values.location,
    address: getAddress(values),
  });
};

interface AddPlant extends HasAddress {
  geozones: {
    [key: string]: EditGeozone;
  },
  location: LocationDto;
}

export const mapAccessorForAddPlantSummary = (values: AddPlant) => {
  // Format plant's geozones to fit the requirement from CardMapMultizones.
  const geozones = Object.keys(values.geozones).reduce((ac, a) => ({ ...ac, [a]: { zone: { ...values.geozones[a] } } }), {});
  return ({
    geozones,
    location: values.location,
    address: getAddress(values),
  });
};

export const removeAutocomplete = () => {
  // Chrome intentionally ignores autocomplete="off | false"
  // but if you set to "new-password", then it ignores auto-fill.
  // https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3
  Array.from(document.getElementsByClassName('tt-input')).forEach(e => e.setAttribute('autoComplete', 'new-password'));
};

export const getAddressGeocode = (address: string, onSuccess: (resp: google.maps.GeocoderResponse) => any) => {
  const url = new URL('https://maps.googleapis.com/maps/api/geocode/json');
  type StringAccessor = { [key: string]: string };
  const params: StringAccessor = {
    address: address.split(' ').join('+'),
    key: process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY!,
  };
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

  return fetch(url.toString())
    .then(response => response.json())
    .then(onSuccess)
    .catch(error => {
      // no catch if error to avoid disruptions
      // user can still add the address manually if this endpoint doesn't work
      recordLog('Error retrieving location from Google Geocoding API', error);
    });
};

export const expirationDate = (daysToExpire: number) => DateWrapper.now.addDays(daysToExpire).endOfDay.date;

export const isAdminPage = (path?: string): boolean => !!path && path.replace(/^\//, '').startsWith('admin');

export const isGeoTraxPage = (path?: string): boolean => !!path && path.replace(/^\//, '').startsWith('geotrax');

export const isTokenExpired = (date: string) => DateWrapper.now.isAfter(new Date(date));

export const exactFieldLength = (value: ArrayLike<any>, length: number, key?: string) => {
  if (value?.toString().length > 0 && value.toString().length !== length) {
    return key ? `${key} must be exactly ${length} characters long.`
      : `Must be exactly ${length} characters long.`;
  }
  return null;
};

// validate leading/trailing/in between spacing
export const validateSpacing = (value: string) => {
  const validateValue = value.split(' ');

  // if there are empty strings in array
  if (validateValue.includes('')) {
    return 'Please remove any leading/trailing or extra in between spacing.';
  }

  return null;
};

export const productlineLanguageReturnGeozone = (productLine: ProductLine) => {
  if (!productLine) {
    return '';
  }
  const product = productLine.trim().toLowerCase();

  switch (product) {
    case AGGREGATE_STRING.toLowerCase():
      return 'In Yard';
    case CEMENT_STRING.toLowerCase():
      return 'At Terminal';
    case READYMIX_STRING.toLowerCase():
    case READYMIX_VALUE.toLowerCase():
      return 'In Plant';
    default:
      return 'In Yard';
  }
};

export interface EditGeozone {
  name: string;
  zone: GeometryDto;
}

// Handle changes involve Edit Plant Geozones
export const formatEditPlantGeozones = (prevState: PlantDto, updatedGeozones: { [key: string]: EditGeozone }) => {
  type GeozoneTypeMap = typeof GEOZONE_TYPE;
  const prevGeozones = prevState.geozones || [];
  let newValue = null;

  if (prevGeozones.length === 0) {
    newValue = Object.entries(updatedGeozones).map(([key, value]) => ({
      name: key,
      zone: value.zone,
      plant: { url: prevState.url },
      region: prevState.region,
    }));
  } else if (prevGeozones.length > 0) {
    // if plant has pre-defiend geozones, update zones based on geozone name
    // if plant does not have a complete list of geozone, create a new one

    newValue = Object.entries(updatedGeozones).map(([key, value]) => {
      const prevZone = prevGeozones.find(g => g.name === key);

      if (prevZone) {
        return { ...prevZone, name: key, zone: value.zone };
      }

      if (!prevZone && key === GEOZONE_TYPE.PLANT) {
        const plant = prevGeozones.find(g => !GEOZONE_TYPE[g.name as keyof GeozoneTypeMap]);
        return { ...plant, name: key, zone: value.zone };
      }
      return {
        name: key,
        zone: value.zone,
        plant: { url: prevState.url },
        region: prevState.region,
      };
    });
  }
  return newValue;
};

export const formatHaulerMultiSelectData = (haulers: any[]) => {
  // Because of backend differences in how haulers are stored on Vehicles and Drivers
  // we have to do some formatting to ensure both formats are supported by the Haulers multiselect
  const needToFormatHaulersForDriverPage = haulers.length > 0 && haulers[0].key && typeof haulers[0].key === 'number';
  if (needToFormatHaulersForDriverPage) {
    return haulers.map(h => Number(h.key));
  }

  return haulers;
};

export const displayTriggerAccessor = (checklist: ChecklistDto) => {
  const displayTriggerMap = {
    login: 'After login',
    logout: 'After logout',
    truckselection: 'After vehicle selection',
  };
  const displayTriggerValue = checklist.displayTrigger;
  type DisplayMap = typeof displayTriggerMap;
  type DisplayMapKey = keyof DisplayMap;
  const key = displayTriggerValue.toLowerCase() as DisplayMapKey;
  return displayTriggerMap[key];
};

export const getDeviceTypeLabel = (value?: DeviceType) => {
  switch (value) {
    case (DeviceType.GLINX): return DEVICE_LABELS.GLINX;
    case (DeviceType.TABLET): return DEVICE_LABELS.TABLET;
    default: return value;
  }
};

/**
 * Checks to see if an existing value has been removed.
 * @param initialValue The possible initial value
 * @param newValue The possible new value
 * @returns True if:
 * 1. There is no initial value (regardless of new value), or
 * 2. There is an initial value and a new value (regardess of equality).
 *
 * ...Otherwise, false.
 */
export const existingValueHasBeenRemoved = (initialValue?: string, newValue?: string) => {
  const hasInitialValue = !!initialValue && initialValue.length > 0;
  const hasNewValue = !!newValue && newValue.length > 0;
  return hasInitialValue && !hasNewValue;
};

/**
 * The props needed to create a modal for confirming SSO removal.
 * YOU need to set the acceptDialog property, and it must call closeModal().
 */
export const ssoIdRemovalModalProps = {
  modalType: ACCENT as ModalType,
  modalTitle: ARE_YOU_SURE,
  modalBody: (<p>{SSOID_REMOVAL_WEB_USER_BODY}</p>),
  modalOpen: true,
  acceptText: SSO_ACCEPT_LABEL,
  cancelText: CANCEL_LABEL,
  disabled: false,
  noActions: false,
};

const asciiInformation = [
  { label: 'null', name: 'NUL', value: 0 },
  { label: 'start of heading', name: 'SOH', value: 1 },
  { label: 'start of text', name: 'STX', value: 2 },
  { label: 'end of text', name: 'ETX', value: 3 },
  { label: 'end of transmission', name: 'EOT', value: 4 },
  { label: 'enquiry', name: 'ENQ', value: 5 },
  { label: 'acknowledge', name: 'ACK', value: 6 },
  { label: 'bell', name: 'BEL', value: 7 },
  { label: 'backspace', name: 'BS', value: 8 },
  { label: 'horizontal tab', name: 'HT', value: 9 },
  { label: 'line feed', name: 'LF', value: 10 },
  { label: 'vertical tab', name: 'VT', value: 11 },
  { label: 'form feed', name: 'FF', value: 12 },
  { label: 'carriage return', name: 'CR', value: 13 },
  { label: 'shift out', name: 'SO', value: 14 },
  { label: 'shift in', name: 'SI', value: 15 },
  { label: 'data link escape', name: 'DLE', value: 16 },
  { label: 'device control 1', name: 'DC1', value: 17 },
  { label: 'device control 2', name: 'DC2', value: 18 },
  { label: 'device control 3', name: 'DC3', value: 19 },
  { label: 'device control 4', name: 'DC4', value: 20 },
  { label: 'negative acknowledge', name: 'NAK', value: 21 },
  { label: 'synchronous idle', name: 'SYN', value: 22 },
  { label: 'end of transmission block', name: 'ETB', value: 23 },
  { label: 'cancel', name: 'CAN', value: 24 },
  { label: 'end of medium', name: 'EM', value: 25 },
  { label: 'substitute', name: 'SUB', value: 26 },
  { label: 'escape', name: 'ESC', value: 27 },
  { label: 'file separator', name: 'FS', value: 28 },
  { label: 'group separator', name: 'GS', value: 29 },
  { label: 'record separator', name: 'RS', value: 30 },
  { label: 'unit separator', name: 'US', value: 31 },
  { label: 'space', name: 'SP', value: 32 },
  { label: 'exclamation mark', name: '!', value: 33 },
  { label: 'double quotes (or speech marks)', name: '"', value: 34 },
  { label: 'number sign', name: '#', value: 35 },
  { label: 'dollar', name: '$', value: 36 },
  { label: 'per cent sign', name: '%', value: 37 },
  { label: 'ampersand', name: '&', value: 38 },
  { label: 'single quote', name: '', value: 39 },
  { label: 'open parenthesis (or open bracket)', name: '(', value: 40 },
  { label: 'close parenthesis (or close bracket)', name: ')', value: 41 },
  { label: 'asterisk', name: '*', value: 42 },
  { label: 'plus', name: '+', value: 43 },
  { label: 'comma', name: ',', value: 44 },
  { label: 'hyphen-minus', name: '-', value: 45 },
  { label: 'period, dot or full stop', name: '.', value: 46 },
  { label: 'slash or divide', name: '/', value: 47 },
  { label: 'zero', name: '0', value: 48 },
  { label: 'one', name: '1', value: 49 },
  { label: 'two', name: '2', value: 50 },
  { label: 'three', name: '3', value: 51 },
  { label: 'four', name: '4', value: 52 },
  { label: 'five', name: '5', value: 53 },
  { label: 'six', name: '6', value: 54 },
  { label: 'seven', name: '7', value: 55 },
  { label: 'eight', name: '8', value: 56 },
  { label: 'nine', name: '9', value: 57 },
  { label: 'colon', name: ':', value: 58 },
  { label: 'semicolon', name: ';', value: 59 },
  { label: 'less than (or open angled bracket)', name: '<', value: 60 },
  { label: 'equals', name: '=', value: 61 },
  { label: 'greater than (or close angled bracket)', name: '>', value: 62 },
  { label: 'question mark', name: '?', value: 63 },
  { label: 'at sign', name: '@', value: 64 },
  { label: 'uppercase a', name: 'A', value: 65 },
  { label: 'uppercase b', name: 'B', value: 66 },
  { label: 'uppercase c', name: 'C', value: 67 },
  { label: 'uppercase d', name: 'D', value: 68 },
  { label: 'uppercase e', name: 'E', value: 69 },
  { label: 'uppercase f', name: 'F', value: 70 },
  { label: 'uppercase g', name: 'G', value: 71 },
  { label: 'uppercase h', name: 'H', value: 72 },
  { label: 'uppercase i', name: 'I', value: 73 },
  { label: 'uppercase j', name: 'J', value: 74 },
  { label: 'uppercase k', name: 'K', value: 75 },
  { label: 'uppercase l', name: 'L', value: 76 },
  { label: 'uppercase m', name: 'M', value: 77 },
  { label: 'uppercase n', name: 'N', value: 78 },
  { label: 'uppercase o', name: 'O', value: 79 },
  { label: 'uppercase p', name: 'P', value: 80 },
  { label: 'uppercase q', name: 'Q', value: 81 },
  { label: 'uppercase r', name: 'R', value: 82 },
  { label: 'uppercase s', name: 'S', value: 83 },
  { label: 'uppercase t', name: 'T', value: 84 },
  { label: 'uppercase u', name: 'U', value: 85 },
  { label: 'uppercase v', name: 'V', value: 86 },
  { label: 'uppercase w', name: 'W', value: 87 },
  { label: 'uppercase x', name: 'X', value: 88 },
  { label: 'uppercase y', name: 'Y', value: 89 },
  { label: 'uppercase z', name: 'Z', value: 90 },
  { label: 'opening bracket', name: '[', value: 91 },
  { label: 'backslash', name: '\\', value: 92 },
  { label: 'closing bracket', name: ']', value: 93 },
  { label: 'caret - circumflex', name: '^', value: 94 },
  { label: 'underscore', name: '_', value: 95 },
  { label: 'grave accent', name: '`', value: 96 },
  { label: 'lowercase a', name: 'a', value: 97 },
  { label: 'lowercase b', name: 'b', value: 98 },
  { label: 'lowercase c', name: 'c', value: 99 },
  { label: 'lowercase d', name: 'd', value: 100 },
  { label: 'lowercase e', name: 'e', value: 101 },
  { label: 'lowercase f', name: 'f', value: 102 },
  { label: 'lowercase g', name: 'g', value: 103 },
  { label: 'lowercase h', name: 'h', value: 104 },
  { label: 'lowercase i', name: 'i', value: 105 },
  { label: 'lowercase j', name: 'j', value: 106 },
  { label: 'lowercase k', name: 'k', value: 107 },
  { label: 'lowercase l', name: 'l', value: 108 },
  { label: 'lowercase m', name: 'm', value: 109 },
  { label: 'lowercase n', name: 'n', value: 110 },
  { label: 'lowercase o', name: 'o', value: 111 },
  { label: 'lowercase p', name: 'p', value: 112 },
  { label: 'lowercase q', name: 'q', value: 113 },
  { label: 'lowercase r', name: 'r', value: 114 },
  { label: 'lowercase s', name: 's', value: 115 },
  { label: 'lowercase t', name: 't', value: 116 },
  { label: 'lowercase u', name: 'u', value: 117 },
  { label: 'lowercase v', name: 'v', value: 118 },
  { label: 'lowercase w', name: 'w', value: 119 },
  { label: 'lowercase x', name: 'x', value: 120 },
  { label: 'lowercase y', name: 'y', value: 121 },
  { label: 'lowercase z', name: 'z', value: 122 },
  { label: 'opening brace', name: '{', value: 123 },
  { label: 'vertical bar', name: '|', value: 124 },
  { label: 'closing brace', name: '}', value: 125 },
  { label: 'equivalency sign - tilde', name: '~', value: 126 },
  { label: 'delete', name: 'DEL', value: 127 },
  { label: 'euro sign', name: '€', value: 128 },
  { label: 'single low-9 quotation mark', name: '‚', value: 130 },
  { label: 'latin small letter f with hook', name: 'ƒ', value: 131 },
  { label: 'double low-9 quotation mark', name: '„', value: 132 },
  { label: 'horizontal ellipsis', name: '…', value: 133 },
  { label: 'dagger', name: '†', value: 134 },
  { label: 'double dagger', name: '‡', value: 135 },
  { label: 'modifier letter circumflex accent', name: 'ˆ', value: 136 },
  { label: 'per mille sign', name: '‰', value: 137 },
  { label: 'latin capital letter s with caron', name: 'Š', value: 138 },
  { label: 'single left-pointing angle quotation', name: '‹', value: 139 },
  { label: 'latin capital ligature oe', name: 'Œ', value: 140 },
  { label: 'latin capital letter z with caron', name: 'Ž', value: 142 },
  { label: 'left single quotation mark', name: '‘', value: 145 },
  { label: 'right single quotation mark', name: '’', value: 146 },
  { label: 'left double quotation mark', name: '“', value: 147 },
  { label: 'right double quotation mark', name: '”', value: 148 },
  { label: 'bullet', name: '•', value: 149 },
  { label: 'en dash', name: '–', value: 150 },
  { label: 'em dash', name: '—', value: 151 },
  { label: 'small tilde', name: '˜', value: 152 },
  { label: 'trade mark sign', name: '™', value: 153 },
  { label: 'latin small letter s with caron', name: 'š', value: 154 },
  { label: 'single right-pointing angle quotation mark', name: '›', value: 155 },
  { label: 'latin small ligature oe', name: 'œ', value: 156 },
  { label: 'latin small letter z with caron', name: 'ž', value: 158 },
  { label: 'latin capital letter y with diaeresis', name: 'Ÿ', value: 159 },
  { label: 'non-breaking space', name: 'NBSP', value: 160 },
  { label: 'inverted exclamation mark', name: '¡', value: 161 },
  { label: 'cent sign', name: '¢', value: 162 },
  { label: 'pound sign', name: '£', value: 163 },
  { label: 'currency sign', name: '¤', value: 164 },
  { label: 'yen sign', name: '¥', value: 165 },
  { label: 'pipe, broken vertical bar', name: '¦', value: 166 },
  { label: 'section sign', name: '§', value: 167 },
  { label: 'spacing diaeresis - umlaut', name: '¨', value: 168 },
  { label: 'copyright sign', name: '©', value: 169 },
  { label: 'feminine ordinal indicator', name: 'ª', value: 170 },
  { label: 'left double angle quotes', name: '«', value: 171 },
  { label: 'negation', name: '¬', value: 172 },
  { label: 'soft hyphen', name: '­SHY', value: 173 },
  { label: 'registered trade mark sign', name: '®', value: 174 },
  { label: 'spacing macron - overline', name: '¯', value: 175 },
  { label: 'degree sign', name: '°', value: 176 },
  { label: 'plus-or-minus sign', name: '±', value: 177 },
  { label: 'superscript two - squared', name: '²', value: 178 },
  { label: 'superscript three - cubed', name: '³', value: 179 },
  { label: 'acute accent - spacing acute', name: '´', value: 180 },
  { label: 'micro sign', name: 'µ', value: 181 },
  { label: 'pilcrow sign - paragraph sign', name: '¶', value: 182 },
  { label: 'middle dot - georgian comma', name: '·', value: 183 },
  { label: 'spacing cedilla', name: '¸', value: 184 },
  { label: 'superscript one', name: '¹', value: 185 },
  { label: 'masculine ordinal indicator', name: 'º', value: 186 },
  { label: 'right double angle quotes', name: '»', value: 187 },
  { label: 'fraction one quarter', name: '¼', value: 188 },
  { label: 'fraction one half', name: '½', value: 189 },
  { label: 'fraction three quarters', name: '¾', value: 190 },
  { label: 'inverted question mark', name: '¿', value: 191 },
  { label: 'latin capital letter a with grave', name: 'À', value: 192 },
  { label: 'latin capital letter a with acute', name: 'Á', value: 193 },
  { label: 'latin capital letter a with circumflex', name: 'Â', value: 194 },
  { label: 'latin capital letter a with tilde', name: 'Ã', value: 195 },
  { label: 'latin capital letter a with diaeresis', name: 'Ä', value: 196 },
  { label: 'latin capital letter a with ring above', name: 'Å', value: 197 },
  { label: 'latin capital letter ae', name: 'Æ', value: 198 },
  { label: 'latin capital letter c with cedilla', name: 'Ç', value: 199 },
  { label: 'latin capital letter e with grave', name: 'È', value: 200 },
  { label: 'latin capital letter e with acute', name: 'É', value: 201 },
  { label: 'latin capital letter e with circumflex', name: 'Ê', value: 202 },
  { label: 'latin capital letter e with diaeresis', name: 'Ë', value: 203 },
  { label: 'latin capital letter i with grave', name: 'Ì', value: 204 },
  { label: 'latin capital letter i with acute', name: 'Í', value: 205 },
  { label: 'latin capital letter i with circumflex', name: 'Î', value: 206 },
  { label: 'latin capital letter i with diaeresis', name: 'Ï', value: 207 },
  { label: 'latin capital letter eth', name: 'Ð', value: 208 },
  { label: 'latin capital letter n with tilde', name: 'Ñ', value: 209 },
  { label: 'latin capital letter o with grave', name: 'Ò', value: 210 },
  { label: 'latin capital letter o with acute', name: 'Ó', value: 211 },
  { label: 'latin capital letter o with circumflex', name: 'Ô', value: 212 },
  { label: 'latin capital letter o with tilde', name: 'Õ', value: 213 },
  { label: 'latin capital letter o with diaeresis', name: 'Ö', value: 214 },
  { label: 'multiplication sign', name: '×', value: 215 },
  { label: 'latin capital letter o with slash', name: 'Ø', value: 216 },
  { label: 'latin capital letter u with grave', name: 'Ù', value: 217 },
  { label: 'latin capital letter u with acute', name: 'Ú', value: 218 },
  { label: 'latin capital letter u with circumflex', name: 'Û', value: 219 },
  { label: 'latin capital letter u with diaeresis', name: 'Ü', value: 220 },
  { label: 'latin capital letter y with acute', name: 'Ý', value: 221 },
  { label: 'latin capital letter thorn', name: 'Þ', value: 222 },
  { label: 'latin small letter sharp s - ess-zed', name: 'ß', value: 223 },
  { label: 'latin small letter a with grave', name: 'à', value: 224 },
  { label: 'latin small letter a with acute', name: 'á', value: 225 },
  { label: 'latin small letter a with circumflex', name: 'â', value: 226 },
  { label: 'latin small letter a with tilde', name: 'ã', value: 227 },
  { label: 'latin small letter a with diaeresis', name: 'ä', value: 228 },
  { label: 'latin small letter a with ring above', name: 'å', value: 229 },
  { label: 'latin small letter ae', name: 'æ', value: 230 },
  { label: 'latin small letter c with cedilla', name: 'ç', value: 231 },
  { label: 'latin small letter e with grave', name: 'è', value: 232 },
  { label: 'latin small letter e with acute', name: 'é', value: 233 },
  { label: 'latin small letter e with circumflex', name: 'ê', value: 234 },
  { label: 'latin small letter e with diaeresis', name: 'ë', value: 235 },
  { label: 'latin small letter i with grave', name: 'ì', value: 236 },
  { label: 'latin small letter i with acute', name: 'í', value: 237 },
  { label: 'latin small letter i with circumflex', name: 'î', value: 238 },
  { label: 'latin small letter i with diaeresis', name: 'ï', value: 239 },
  { label: 'latin small letter eth', name: 'ð', value: 240 },
  { label: 'latin small letter n with tilde', name: 'ñ', value: 241 },
  { label: 'latin small letter o with grave', name: 'ò', value: 242 },
  { label: 'latin small letter o with acute', name: 'ó', value: 243 },
  { label: 'latin small letter o with circumflex', name: 'ô', value: 244 },
  { label: 'latin small letter o with tilde', name: 'õ', value: 245 },
  { label: 'latin small letter o with diaeresis', name: 'ö', value: 246 },
  { label: 'division sign', name: '÷', value: 247 },
  { label: 'latin small letter o with slash', name: 'ø', value: 248 },
  { label: 'latin small letter u with grave', name: 'ù', value: 249 },
  { label: 'latin small letter u with acute', name: 'ú', value: 250 },
  { label: 'latin small letter u with circumflex', name: 'û', value: 251 },
  { label: 'latin small letter u with diaeresis', name: 'ü', value: 252 },
  { label: 'latin small letter y with acute', name: 'ý', value: 253 },
  { label: 'latin small letter thorn', name: 'þ', value: 254 },
  { label: 'latin small letter y with diaeresis', name: 'ÿ', value: 255 },
];

export const asciiCodes = asciiInformation.map(item => ({
  ...item,
  fullLabel: `${item.name} (${item.label})`,
}));

export const formatNumberWithCommas = (number?: number): string => number?.toLocaleString('en-US') || '';
