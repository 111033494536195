/* eslint-disable no-array-constructor */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { cloneDeep } from 'lodash';
import {
  DriverDto, HaulerDto, PermissionAccess, UrlKeyDto
} from '@trucktrax/trucktrax-ts-common';
import styles from './DriverDetailView.module.css';
import AdminCard from '../../shared/admin/AdminCard';
import {
  defaultProductLineRadioOptions,
  getFullName,
  getPrimaryProductLine,
  getRegionUrlToNameMap,
  getSecondaryProductLine,
  getPrimaryPlantName,
  nameAndUrlItemToDropdownOptions,
  secondaryProductLineRadioOptions,
  getUrl,
  existingValueHasBeenRemoved,
  ssoIdRemovalModalProps,
  haulerListToMultiSelectOptionsById,
  defaultDriverConfigurationRadioOptions,
} from '../../../util/adminUtil';
import { addToOrUpdateListInStore } from '../../../store/actions/dataTableActions';
import { openModal, closeModal } from '../../../store/actions/errorModalActions';
import { updateDataTableRecord } from '../../../services/dataTableService';
import { noop } from '../../../util/appUtil';
import { DRIVERS_TEXT } from '../../../constants/navConstants';
import { getPlantList } from '../../../services/plantsService';
import { fetchHaulerList } from '../../../services/haulersService';
import { devErrorAndLog } from '../../../util/errorUtil';
import { ERROR_TEXT_FETCH_PLANT_LIST } from '../../../constants/errorConstants';
import { UPDATE_DRIVER } from '../../../constants/actionConstants';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  DROPDOWN_FORM,
  INPUT_FORM,
  NOT_AVAILABLE,
  NONE_STRING,
  RADIO_FORM,
  SAVE_BTN,
  TEXT_DISPLAY,
  ACCENT,
  ARE_YOU_SURE,
  CONFIRM_DEACTIVATE_DRIVER,
  DEACTIVATE_DRIVER_BODY,
  CONFIRM_ACTIVATE_DRIVER,
  ACTIVATE_DRIVER_BODY,
  EDIT_BTN,
  NUMERIC_INPUT_FORM,
  CANCEL_LABEL,
  INPUT_TYPE_TEXT,
  SSOID_REMOVAL_DRIVER_BODY,
  MULTIDROPDOWN_FORM,
  CARD_SWITCH,
  GEOTRAX_LITE_FLAG,
} from '../../../constants/appConstants';
import { DRIVERS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import StatusContainer from '../status/StatusContainer';
import { isLoggedIn } from '../../../services/driversService';
import HTTP_CODES from '../../../constants/httpConstants';
import { RegionAccessMap } from '../../../util/permissionUtil';
import { isFeatureFlagEnabled } from '../../../util/featureFlagUtil';
import { AdminConfig } from '../../shared/admin/AdminAddModal';

export class DriverDetailView extends Component<DriverDetailViewProps> {
  static defaultProps = {
    currentRegion: {
      url: '',
    },
  };

  state = {
    edit: false,
    isDriverLoggedIn: true,
    region: undefined,
    primaryProductLine: undefined,
    initialRegion: '',
    initialPrimaryProductLine: '',
    plantList: [] as Plant[],
    driverDetails: {
      archived: false,
      deleted: false,
      employeeNumber: undefined,
      firstName: '',
      id: 0,
      lastName: '',
      primaryPlant: {
        name: '',
        url: '',
      },
      isExternal: false,
      gtLightAccess: false,
    },
  };

  async componentDidMount() {
    await this.props.fetchHaulerList(undefined, this.props.currentRegion.url, true);
  }

  onChange = async (dto: any) => {
    const region = dto.region && dto.region.url;
    const { primaryProductLine, isExternal, gtLightAccess } = dto;

    if (isExternal !== this.state.driverDetails.isExternal) {
      this.setState({
        driverDetails: {
          ...this.state.driverDetails,
          isExternal,
        },
      });
    }

    if (gtLightAccess !== this.state.driverDetails.gtLightAccess) {
      this.setState({
        driverDetails: {
          ...this.state.driverDetails,
          gtLightAccess,
        },
      });
    }

    if (
      region !== this.state.region
      || primaryProductLine !== this.state.primaryProductLine
    ) {
      // resets previously chosen primary plant
      dto[ADMIN_KEYS.PRIMARY_PLANT] = undefined;
      this.setState({
        region,
        primaryProductLine,
      });
      await this.setPlantList(region, primaryProductLine);
    }
  };

  handleCallback = (response: { data: DriverDto; }) => {
    const { data } = response;

    this.setData(data);
    this.getInitialPlantList(data);
    this.setIsDriverLoggedIn(data);
  };

  handleAlterData = (data: DriverDto) => {
    this.setData(data);
    return data;
  };

  setIsDriverLoggedIn = async (data: DriverDto) => {
    const isDriverLoggedIn = await isLoggedIn(data);
    this.setState({ isDriverLoggedIn });
  };

  setData = (data: DriverDto) => {
    this.setState({
      driverDetails: { ...data },
    });
  };

  getInitialPlantList = async (dto: { region?: any; primaryProductLine?: any; }) => {
    const regionUrl = dto.region.url;
    const { primaryProductLine } = dto;
    this.setState({
      initialRegion: regionUrl,
      initialPrimaryProductLine: primaryProductLine,
      region: regionUrl,
      primaryProductLine,
    });
    await this.setPlantList(regionUrl, primaryProductLine);
  };

  setPlantList = async (region: string | undefined, selectedProductLine: string | undefined) => {
    try {
      this.setState({ plantList: await getPlantList(region, selectedProductLine) });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      this.props.devErrorAndLog(ERROR_TEXT_FETCH_PLANT_LIST, `Driver Details ${e.toString()}`, undefined, undefined, consoleOnly);
    }
  };

  baseUrl = () => `${getGeotraxBaseUrl() + DRIVERS_PATH}/${this.props.match.params.id}`;

  toggleEdit = async () => {
    // resets to initial when closing edit mode
    if (this.state.edit) {
      const region = { url: this.state.initialRegion };
      const primaryProductLine = this.state.initialPrimaryProductLine;
      await this.onChange({
        region,
        primaryProductLine,
      });
    }
    this.setState({ edit: !this.state.edit });
  };

  regionUrlAccessor = (row: any) => (getRegionUrlToNameMap(this.props.regions)[getUrl(row)!]);

  save = (dto: any, onSuccess: any, onError: any, toastMessages: any) => {
    // unset conditional form fields as needed
    // e.g. unset internal-only fields for external drivers, and vice versa
    const copy = cloneDeep(dto);
    if (copy.isExternal) {
      copy.pin = undefined;
      copy.primaryPlant = undefined;
      copy.secondaryProductLine = undefined;

      // unset email if it's empty (email not required for external drivers without Geotrax Lite access)
      if (copy.email === '') {
        copy.email = undefined;
      }
    } else {
      copy.haulers = undefined;
      copy.gtLightAccess = false;
      copy.email = undefined;
      copy.secondaryProductLine = (copy.secondaryProductLine === NOT_AVAILABLE || !copy.secondaryProductLine)
        ? NONE_STRING : copy.secondaryProductLine;
    }

    const onSaveSuccess = (updatedDto: any) => {
      const driverDetails = updatedDto && updatedDto.data ? updatedDto.data : {};
      this.setState({
        edit: false,
        driverDetails,
      });
      onSuccess(updatedDto);
      this.props.addToOrUpdateListInStore(updatedDto, UPDATE_DRIVER);
    };

    const currentRegionUrl = this.props.currentRegion.url;
    if (existingValueHasBeenRemoved(this.state.driverDetails.employeeNumber, copy.employeeNumber)) {
      this.activateSSOWarningModal(copy, onSaveSuccess, onError, toastMessages);
    } else {
      this.props.updateAdminTableData(this.baseUrl(), copy, onSaveSuccess, onError, false, currentRegionUrl, toastMessages);
    }
  };

  activateSSOWarningModal = (userDto: any, onSuccess: any, onError: any, toastMessages: any) => {
    this.props.openModal({
      ...ssoIdRemovalModalProps,
      modalBody: (<p>{SSOID_REMOVAL_DRIVER_BODY}</p>),
      acceptDialog: () => this.updateSSOID(userDto, onSuccess, onError, toastMessages),
    });
  };

  updateSSOID = (dto: any, onSuccess: any, onError: any, toastMessages: any) => {
    const { currentRegion, closeModal, updateAdminTableData } = this.props;
    closeModal();
    updateAdminTableData(this.baseUrl(), dto, onSuccess, onError, false, currentRegion.url, toastMessages);
  };

  static primaryPlantAccessor = (value: any) => (value
    ? getPrimaryPlantName(value)
    : NOT_AVAILABLE
  );

  viewOnlyItems() {
    if (this.state.edit) {
      return null;
    }

    const hasEditPermission = this.props.driversPermissionAccess === PermissionAccess.Edit;
    const shouldDisabled = !this.state.driverDetails.archived && this.state.isDriverLoggedIn && !hasEditPermission;
    return (
      <StatusContainer
        category="driver"
        isArchived={this.state.driverDetails.archived}
        isDisabled={shouldDisabled}
        hasEditPermission={hasEditPermission}
        containerClassName={styles.statusCtr}
        onActivateClick={this.activateWarningModal}
        onDeactivateClick={this.deactivateWarningModal}
      />
    );
  }

  activateWarningModal = () => {
    const modalBody = (
      <span>
        <p>{ACTIVATE_DRIVER_BODY}</p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.activateDriver,
      acceptText: CONFIRM_ACTIVATE_DRIVER,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateWarningModal = () => {
    const modalBody = (
      <span>
        <p>{DEACTIVATE_DRIVER_BODY}</p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.deactivateDriver,
      acceptText: CONFIRM_DEACTIVATE_DRIVER,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateDriver = () => {
    const dto = {
      ...this.state.driverDetails,
      archived: true,
    };

    if (!dto.primaryPlant || dto.primaryPlant.url === null) {
      const p: Plant = {};
      const plant = this.state.plantList.length ? this.state.plantList[0] : p;

      dto.primaryPlant = {
        name: plant.name || '',
        url: plant.url || '',
      };
    }

    const toastMessages = {
      success: `“${dto.lastName}, ${dto.firstName}” has been successfully deactivated.`,
      fail: `Unable to deactivate “${dto.lastName}, ${dto.firstName}.”`,
    };
    this.props.closeModal();
    this.save(dto, noop, null, toastMessages);
  };

  activateDriver = () => {
    const dto = {
      ...this.state.driverDetails,
      archived: false,
    } as DriverDto;

    if (!dto.primaryPlant || dto.primaryPlant.url === null) {
      const plant = this.state.plantList.length ? this.state.plantList[0] : {};
      dto.primaryPlant = {
        name: plant?.name,
        url: plant?.url,
      };
    }

    const toastMessages = {
      success: `"${dto.lastName}, ${dto.firstName}" has been successfully activated.`,
      fail: `Unable to activate “${dto.lastName}, ${dto.firstName}.”`,
    };
    this.props.closeModal();
    this.save(dto, noop, null, toastMessages);
  };

  getBreadcrumbPath() {
    if (!this.state.driverDetails.archived) {
      return this.props.location.pathname;
    }
    // construct the breadcrumb path for deactivated drivers
    const paths = this.props.location.pathname.split('/');
    const id = paths.pop();
    if (id) {
      paths.push('deactivated', id);
    }
    return paths.join('/');
  }

  haulerAccessor = (dto: DriverDto) => {
    const haulerIds = dto.haulers;
    const haulerNames = this.props.haulersList.filter(h => haulerIds?.includes(h.id)).map(p => p.name);

    return haulerNames?.length ? haulerNames : NONE_STRING;
  };

  static haulerAccessorEdit = (dto: DriverDto) => {
    // convert to the format required for multiselect form control
    const haulerIds = dto.haulers ?? [];
    return haulerIds.map((id: number) => ({
      key: id,
      value: { id },
    }));
  };

  static gtlAccessor = (dto: DriverDto) => (dto.gtLightAccess ? 'Yes' : 'No');

  static isExternalAccessor = (dto: DriverDto) => (dto.isExternal ? 'External' : 'Internal');

  isGeotraxLiteEnabled = () => isFeatureFlagEnabled(
    this.props.userPermission,
    this.props.currentRegion?.url ?? '',
    GEOTRAX_LITE_FLAG
  );

  getViewConfigWithoutEdit() {
    const { isExternal } = this.state.driverDetails;

    const commonFields = [
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.NAME,
        label: ADMIN_LABELS.NAME,
        accessor: getFullName,
        className: cx(styles.fullName, 'large'),
        dataTest: 'drivers-drilldown-name',
      },
    ];

    // driverTypeFields will be omitted if GTL feature flag is not enabled for the current admin user
    const driverTypeFields = [
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.DRIVER_IS_EXTERNAL,
        label: ADMIN_LABELS.DRIVER_IS_EXTERNAL,
        accessor: DriverDetailView.isExternalAccessor,
        dataTest: 'drivers-drilldown-isExternal',
      },
    ];

    const internalFields = [
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.PIN,
        label: ADMIN_LABELS.PIN,
        accessor: ADMIN_KEYS.PIN,
        className: cx(styles.pin),
        dataTest: 'drivers-drilldown-pin',
      },
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.EXTERNAL_ID,
        label: ADMIN_LABELS.EXTERNAL_ID,
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        className: cx(styles.externalId),
        dataTest: 'drivers-drilldown-externalid',
      },
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.SSO_ID,
        label: ADMIN_LABELS.SSO_ID,
        accessor: ADMIN_KEYS.SSO_ID,
        className: styles.externalId,
        dataTest: 'drivers-drilldown-ssoId',
      },
    ];

    const externalFields = [
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.GEOTRAX_LITE_ACCESS,
        label: ADMIN_LABELS.GEOTRAX_LITE_ACCESS,
        accessor: DriverDetailView.gtlAccessor,
        dataTest: 'drivers-drilldown-gtl-access',
      },
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.EMAIL,
        label: ADMIN_LABELS.EMAIL,
        accessor: ADMIN_KEYS.EMAIL,
        className: cx(styles.email),
        dataTest: 'drivers-drilldown-email',
      },
    ];

    const additionalCommonFields = [
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.REGION,
        label: ADMIN_LABELS.REGION,
        accessor: this.regionUrlAccessor,
        className: styles.region,
        dataTest: 'drivers-drilldown-region',
      },
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        label: ADMIN_LABELS.PRIMARY_PRODUCT_LINE,
        accessor: getPrimaryProductLine,
        className: styles.defaultProductLine,
        dataTest: 'drivers-drilldown-product',
      },
    ];

    // Additional fields for internal drivers
    const additionalInternalFields = [
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.SECONDARY_PRODUCT_LINE,
        label: ADMIN_LABELS.SECONDARY_PRODUCT_LINE,
        accessor: getSecondaryProductLine,
        className: styles.secondaryProductLine,
        dataTest: 'drivers-drilldown-product2',
      },
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.PRIMARY_PLANT,
        label: ADMIN_LABELS.PRIMARY_PLANT,
        accessor: getPrimaryPlantName,
        className: styles.primaryPlant,
        dataTest: 'drivers-drilldown-primaryPlant',
      },
    ];

    const additionalExternalFields = [
      {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.HAULERS,
        label: ADMIN_LABELS.HAULER,
        accessor: this.haulerAccessor,
        className: styles.haulers,
        dataTest: 'drivers-drilldown-hauler',
      }
    ];

    // Assemble the final configuration based on the GeotraxLite feature flag
    let viewConfig: AdminConfig[] = [];
    if (this.isGeotraxLiteEnabled()) {
      viewConfig = [
        ...commonFields,
        ...driverTypeFields,
        ...(isExternal ? externalFields : internalFields),
        ...additionalCommonFields,
        ...(isExternal ? additionalExternalFields : additionalInternalFields),
      ];
    } else {
      // GeotraxLite is not enabled; default to internal driver configuration
      viewConfig = [
        ...commonFields,
        ...internalFields,
        ...additionalCommonFields,
        ...additionalInternalFields,
      ];
    }

    return viewConfig;
  }

  getViewConfigWithEdit() {
    const viewConfigWithoutEdit = this.getViewConfigWithoutEdit();
    return [
      ...viewConfigWithoutEdit,
      {
        type: EDIT_BTN,
        name: 'Edit Driver',
        iconClassName: 'icon-create',
        dataTest: 'drivers-drilldown-edit',
        disabled: this.state.isDriverLoggedIn,
        category: 'driver',
      },
    ];
  }

  getEditConfig() {
    const { isExternal } = this.state.driverDetails;
    const viewConfigWithoutEdit = this.getViewConfigWithoutEdit();
    const dropDownRegions = nameAndUrlItemToDropdownOptions(this.props.regions);
    const dropDownPlants = nameAndUrlItemToDropdownOptions(this.state.plantList);

    // viewConfigWithoutEdit[0] is the full name field
    // currently hidden by CSS (not sure why)
    const initialCommonFields = [
      viewConfigWithoutEdit[0],
    ];

    const driverTypeFields = [
      {
        type: RADIO_FORM,
        key: ADMIN_KEYS.DRIVER_IS_EXTERNAL,
        accessor: ADMIN_KEYS.DRIVER_IS_EXTERNAL,
        label: ADMIN_LABELS.DRIVER_IS_EXTERNAL,
        radioButtonGroupName: ADMIN_KEYS.DRIVER_IS_EXTERNAL,
        className: styles.driverConfiguration,
        radioButtons: defaultDriverConfigurationRadioOptions,
      },
    ];

    const initialExternalFields = [
      {
        id: ADMIN_KEYS.GEOTRAX_LITE_ACCESS,
        type: CARD_SWITCH,
        key: ADMIN_KEYS.GEOTRAX_LITE_ACCESS,
        label: ADMIN_LABELS.GEOTRAX_LITE_ACCESS,
        display: true,
        editing: true,
      },
    ];

    const commonFields = [
      {
        type: INPUT_FORM,
        accessor: 'firstName',
        className: styles.firstName,
        key: ADMIN_KEYS.FIRST_NAME,
        label: ADMIN_LABELS.FIRST_NAME,
        maxLength: 50,
        dataTest: 'firstName-input-data-test',
        errorDataTest: 'firstName-input-missing-error',
        isRequired: true,
      },
      {
        type: INPUT_FORM,
        accessor: 'lastName',
        className: styles.lastName,
        key: ADMIN_KEYS.LAST_NAME,
        label: ADMIN_LABELS.LAST_NAME,
        maxLength: 50,
        dataTest: 'lasttName-input-data-test',
        errorDataTest: 'lasttName-input-missing-error',
        isRequired: true,
      },
    ];

    const internalFields = [
      {
        type: INPUT_FORM,
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        className: styles.editExternalId,
        key: ADMIN_KEYS.EXTERNAL_ID,
        label: ADMIN_LABELS.EXTERNAL_ID,
        maxLength: 50,
        dataTest: 'externalid-input-data-test',
        errorDataTest: 'externalid-input-missing-error',
        isRequired: false,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.SSO_ID,
        accessor: ADMIN_KEYS.SSO_ID,
        label: ADMIN_LABELS.SSO_ID_EN,
        inputType: INPUT_TYPE_TEXT,
        dataTest: 'ssoId-input-data-test',
        errorDataTest: 'ssolId-input-missing-error',
        className: styles.externalIdEdit,
        isRequired: false,
        maxLength: 50,
      },
      {
        type: NUMERIC_INPUT_FORM,
        accessor: ADMIN_KEYS.PIN,
        className: styles.editPin,
        key: ADMIN_KEYS.PIN,
        label: ADMIN_LABELS.PIN,
        maxLength: 6,
        dataTest: 'pin-input-data-test',
        errorDataTest: 'pin-input-missing-error',
        isRequired: true,
      },
    ];

    const externalFields = [
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.EMAIL,
        accessor: ADMIN_KEYS.EMAIL,
        label: ADMIN_LABELS.EMAIL,
        inputType: INPUT_TYPE_TEXT,
        maxLength: 50,
        dataTest: 'email-input-data-test',
        errorDataTest: 'email-input-missing-error',
        className: styles.emailEdit,
        isRequired: this.state.driverDetails.gtLightAccess,
      },
    ];

    const additionalCommonFields = [
      {
        type: DROPDOWN_FORM,
        className: styles.editRegion,
        label: ADMIN_LABELS.REGION,
        key: ADMIN_KEYS.REGION,
        items: dropDownRegions,
        isRequired: true,
        dataTest: 'region-input-data-test',
      },
      {
        type: RADIO_FORM,
        accessor: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        className: styles.defaultProductLine,
        dataTest: 'users-drilldown-product',
        key: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        radioButtonGroupName: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        label: ADMIN_LABELS.PRIMARY_PRODUCT_LINE,
        radioButtons: defaultProductLineRadioOptions,
        initialSelected: defaultProductLineRadioOptions[0].value,
        isRequired: true,
      },
    ];

    const additionalInternalFields = [
      {
        type: RADIO_FORM,
        accessor: ADMIN_KEYS.SECONDARY_PRODUCT_LINE,
        className: styles.secondaryProductLine,
        key: ADMIN_KEYS.SECONDARY_PRODUCT_LINE,
        radioButtonGroupName: ADMIN_KEYS.SECONDARY_PRODUCT_LINE,
        label: ADMIN_LABELS.SECONDARY_PRODUCT_LINE,
        radioButtons: secondaryProductLineRadioOptions,
        initialSelected: secondaryProductLineRadioOptions[0].value,
      },
      {
        type: DROPDOWN_FORM,
        label: ADMIN_LABELS.PRIMARY_PLANT,
        key: ADMIN_KEYS.PRIMARY_PLANT,
        items: dropDownPlants,
        isRequired: true,
        accessor: getPrimaryPlantName,
        className: styles.editPrimaryPlant,
        dataTest: 'primaryPlant-input-data-test',
      },
    ];

    const additionalExternalFields = [
      {
        type: MULTIDROPDOWN_FORM,
        key: ADMIN_KEYS.HAULERS,
        label: ADMIN_LABELS.HAULER,
        className: cx('baseDropDown', 'bottomWidthSpace'),
        items: haulerListToMultiSelectOptionsById(this.props.haulersList),
        accessor: DriverDetailView.haulerAccessorEdit,
        placeholder: 'Select One or Multiple Haulers...',
        dataTest: 'hauler-input-data-test',
        isRequired: this.state.driverDetails.gtLightAccess,
      },
    ];

    // assemble the final configuration based on the GeotraxLite feature flag
    let editConfig: AdminConfig[] = [];
    if (this.isGeotraxLiteEnabled()) {
      editConfig = [
        ...initialCommonFields,
        ...driverTypeFields,
        ...(isExternal ? initialExternalFields : []),
        ...commonFields,
        ...(isExternal ? externalFields : internalFields),
        ...additionalCommonFields,
        ...(isExternal ? additionalExternalFields : additionalInternalFields),
        {
          type: SAVE_BTN,
          key: SAVE_BTN,
        },
      ];
    } else {
      // GeotraxLite is not enabled; default to internal driver configuration
      editConfig = [
        ...initialCommonFields,
        ...commonFields,
        ...internalFields,
        ...additionalCommonFields,
        ...additionalInternalFields,
        {
          type: SAVE_BTN,
          key: SAVE_BTN,
        },
      ];
    }

    return editConfig;
  }

  getConfigValue() {
    const viewConfigWithoutEdit = this.getViewConfigWithoutEdit();
    const viewConfigWithEdit = this.getViewConfigWithEdit();
    const editConfig = this.getEditConfig();
    const hasEditPermission = this.props.driversPermissionAccess === PermissionAccess.Edit;

    if (!hasEditPermission) {
      return viewConfigWithoutEdit;
    }

    if (this.state.edit) {
      return editConfig;
    }

    const driverArchivedOrUnknown = this.state.driverDetails.archived === undefined
      || this.state.driverDetails.archived;

    if (driverArchivedOrUnknown) {
      return viewConfigWithoutEdit;
    }

    return viewConfigWithEdit;
  }

  render() {
    const pathname = this.getBreadcrumbPath();
    const configValue = this.getConfigValue();
    const shouldDisabled = !this.state.driverDetails.archived && this.state.isDriverLoggedIn;

    return (
      <AdminCard
        edit={this.state.edit}
        url={this.baseUrl()}
        className={cx(
          styles.wrapper,
          this.state.edit && styles.editWrapper
        )}
        onToggleEdit={this.toggleEdit}
        save={this.save}
        config={configValue}
        pathName={pathname}
        headerAccessor={getFullName}
        onChangeHandler={this.onChange}
        options={{
          callback: this.handleCallback,
          alterData: this.handleAlterData,
          requiredKeys: [],
        }}
        disableEdit={shouldDisabled}
      >
        {this.viewOnlyItems()}
      </AdminCard>
    );
  }
}

const mapStateToProps = (state: any) => ({
  regions: state.assignedRegionList,
  plantList: state.plantList,
  driversPermissionAccess: state.adminPermissionAccess[DRIVERS_TEXT],
  currentRegion: state.currentRegion,
  haulersList: state.haulersList?.haulers,
  userPermission: state.userPermission || {},
});

type Plant = {
  name?: string;
  url?: string;
};

type Location = {
  pathname: string;
};

type Match = {
  params: { id: string };
};

export interface DriverDetailViewProps {
  updateAdminTableData: typeof updateDataTableRecord,
  devErrorAndLog: typeof devErrorAndLog,
  addToOrUpdateListInStore: typeof addToOrUpdateListInStore,
  match: Match,
  closeModal: typeof closeModal,
  openModal: typeof openModal,
  driversPermissionAccess?: string,
  regions: any[],
  currentRegion: UrlKeyDto,
  location: Location,
  haulersList: HaulerDto[],
  fetchHaulerList: typeof fetchHaulerList,
  userPermission: RegionAccessMap,
}
export default connect<any, any, any>(mapStateToProps, {
  updateAdminTableData: updateDataTableRecord,
  fetchHaulerList,
  addToOrUpdateListInStore,
  devErrorAndLog,
  closeModal,
  openModal,
})(DriverDetailView);
