import { Dispatch } from 'redux';
import { OrderDto, ProductLine, UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { getRequest, putRequest } from './requestService';
import { devErrorAndLog } from '../util/errorUtil';
import { ERROR_TEXT_FETCH_ORDER } from '../constants/errorConstants';
import { ORDERS_PATH } from '../constants/apiConstants';
import { getTicketingBaseUrl } from '../util/apiUtil';
import { DEFAULT_PAGE_SIZE } from '../constants/appConstants';
import { ReduxState } from '../store';
import { FETCH_ORDERS } from '../constants/actionConstants';
import DateTimeRange from '../util/DateTimeRange';
import HTTP_CODES from '../constants/httpConstants';

type GetState = () => {
  dataTableDateRange: {
    dateRange: {
      startDate: string,
      endDate: string
    }
  },
  currentRegion?: UrlKeyDto,
  selectedProductLine: ProductLine
};

export function getOrder(id?: number, region?: string) {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const url = `${getTicketingBaseUrl()}${ORDERS_PATH}/${id}`;
    const params = {
      regionUrl: region,
      isArchived: false,
    };
    try {
      const response = await getRequest(url, params);

      // some orders do not have a geozone associated. default to an existing but fake geozone
      // so it can be edited manually if need be

      const { regionList = [] } = getState();
      const givenRegion = regionList.find(r => r.url === region);
      if (!response.data.geoZone) {
        response.data.geoZone = {
          zone: {
            circle: {
              radius: 1000,
              center: {
                latitude: givenRegion?.location?.lat || givenRegion?.location?.latitude,
                longitude: givenRegion?.location?.lng || givenRegion?.location?.longitude,
              },
            },
          },
        };
      }
      return response.data;
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), `${ERROR_TEXT_FETCH_ORDER} getOrder - id:${id}`, e.toString(), undefined, consoleOnly) as any);
      return undefined;
    }
  };
}

export function updateOrder(order: OrderDto) {
  return async (dispatch: Dispatch) => {
    const url = `${getTicketingBaseUrl()}${ORDERS_PATH}/${order.id}`;
    try {
      const response = await putRequest(url, order);
      return response.data;
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(
        e.toString(),
        `${ERROR_TEXT_FETCH_ORDER} updateOrder - id:${order.id}`,
        e.toString(),
        undefined,
        consoleOnly
      ) as any);
      return undefined;
    }
  };
}

export function getOrdersByOrderUrls(commaSeparatedUrls: string, region?: string) {
  return async (dispatch: Dispatch) => {
    const url = `${getTicketingBaseUrl()}${ORDERS_PATH}`;
    const params = {
      regionUrl: region,
      isArchived: false,
      orderUrls: commaSeparatedUrls,
    };
    try {
      const response = await getRequest(url, params);
      const { items } = response.data;
      return items as OrderDto[];
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(
        e.toString(),
        `${ERROR_TEXT_FETCH_ORDER} getOrdersByOrderUrls: ${JSON.stringify(commaSeparatedUrls)}`,
        e.toString(),
        undefined,
        consoleOnly
      ) as any);
      return undefined;
    }
  };
}

export function getOrdersForMap(
  region: string,
  startDate: string,
  endDate: string,
  selectedProductLine: ProductLine
) {
  return async (dispatch: Dispatch) => {
    const url = `${getTicketingBaseUrl()}${ORDERS_PATH}`;
    const params = {
      region,
      isArchived: false,
      isDeleted: false,
      startDate: new Date(startDate).toUTCString(),
      endDate: new Date(endDate).toUTCString(),
      productLine: selectedProductLine,
    };
    try {
      const response = await getRequest(url, params);
      return response.data.items;
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_ORDER, e.toString(), undefined, consoleOnly) as any);
      return undefined;
    }
  };
}

export async function fetchOrdersList(
  region: string,
  timespan: DateTimeRange,
  inProductLines?: string,
  selectedProductLine?: ProductLine
) {
  const url = `${getTicketingBaseUrl()}${ORDERS_PATH}`;
  const params = {
    region,
    isArchived: false,
    isDeleted: false,
    startDate: timespan.start.toUTCString(),
    endDate: timespan.end.toUTCString(),
    inProductLines,
    productLine: selectedProductLine,
  };
  try {
    const response = await getRequest(url, params);
    return response.data.items;
  } catch (e: any) {
    return undefined;
  }
}

export function getOrdersByProductLine(region?: string) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const url = `${getTicketingBaseUrl()}${ORDERS_PATH}`;
    const { dataTableDateRange } = getState();
    const { dateRange } = dataTableDateRange || {};

    const params = {
      region: region || getState().currentRegion?.url || '',
      productLine: getState().selectedProductLine,
      isArchived: false,
      isDeleted: false,
      size: DEFAULT_PAGE_SIZE,
      page: 1,
    };
    const hasDateRange = dateRange && true;
    const withDateParams = hasDateRange
      ? {
        ...params,
        startDate: new Date(dateRange.startDate).toUTCString(),
        endDate: new Date(dateRange.endDate).toUTCString(),
      }
      : params;

    try {
      const response = await getRequest(url, withDateParams);
      return response.data;
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_ORDER, e, ` getOrdersByProductLine - region:${region}`, consoleOnly) as any);
      return undefined;
    }
  };
}

export function fetchOpenOrders(regionUrl?: string, plantUrl?: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    if (!regionUrl || !plantUrl) {
      return;
    }

    const url = `${getTicketingBaseUrl()}${ORDERS_PATH}`;
    const params = {
      regionUrl,
      plantUrl,
      productLine: getState().selectedProductLine,
      isArchived: false,
      isDeleted: false,
      startDate: DateWrapper.now.previousDay.endOfDay.toISOString(),
      endDate: DateWrapper.now.nextDay.endOfDay.toISOString(),
      page: 1,
    };

    try {
      let pageResponse = await getRequest(url, params);
      const firstPageItems = pageResponse?.data?.items ?? [];
      let allPageItems = [...firstPageItems];

      while (pageResponse.data.pageStart < pageResponse.data.pageCount) {
        params.page = pageResponse.data.pageStart + 1;
        // eslint-disable-next-line no-await-in-loop
        pageResponse = await getRequest(url, params);
        allPageItems = [
          ...allPageItems,
          ...(pageResponse?.data?.items ?? []),
        ];
      }

      dispatch({
        type: FETCH_ORDERS,
        payload: allPageItems ?? [],
      });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_ORDER, e, ` fetchOpenOrders - region:${regionUrl}`, consoleOnly) as any);
    }
  };
}

export const fetchOrder = async (
  id: number
): Promise<OrderDto | undefined> => {
  const url = `${getTicketingBaseUrl() + ORDERS_PATH}/${id}`;
  const response = await getRequest(url);
  return response ? response.data : undefined;
};
