import React, { Component } from 'react';
import { Circle, Polygon } from '@react-google-maps/api';
import { PlantDto } from '@trucktrax/trucktrax-ts-common';
import { postGISToGoogleMapsPoints, vars } from '@trucktrax/trucktrax-common';
import TTMarker from '../TTMarker';
import icons from '../icons';

export default class PlantMarker extends Component<PlantMarkerProps> {
  static defaultProps = {
    showGeofence: false,
    markerColor: vars.gray700,
    mapRef: undefined,
  };

  renderGeoZones() {
    const {
      plant, showGeofence, markerColor,
    } = this.props;
    if (showGeofence && plant.geozones && plant.geozones.length > 0) {
      const shapeStyles = {
        fillColor: markerColor,
        fillOpacity: 0.25,
        strokeColor: markerColor,
        strokeWeight: 2,
        clickable: false,
        zIndex: 1,
      };

      const geozoneMarkers = [];

      for (let i = 0; i < plant.geozones.length; i += 1) {
        const geozone = plant.geozones[i].zone;
        const geozoneUrl = plant.geozones[i].url;
        if (geozone?.circle) {
          const position = {
            lat: geozone?.circle?.center?.latitude ?? 0,
            lng: geozone?.circle?.center?.longitude ?? 0,
          };

          geozoneMarkers.push((
            <Circle
              center={position}
              radius={geozone.circle.radius}
              options={shapeStyles}
              key={geozoneUrl}
            />
          ));
        } else if (geozone?.polygon) {
          geozoneMarkers.push((
            <Polygon
              path={postGISToGoogleMapsPoints(geozone!.polygon!.points!) as google.maps.LatLngLiteral[]}
              options={shapeStyles}
              key={geozoneUrl}
            />
          ));
        }
      }
      return geozoneMarkers;
    }
    return null;
  }

  markerRef: any;

  render() {
    const { plant, markerColor, mapRef } = this.props;
    if (mapRef && this.markerRef) {
      this.markerRef.setMap(mapRef);
    }
    try {
      const position = {
        lat: plant.location!.latitude ?? 0,
        lng: plant.location!.longitude ?? 0,
      };

      const plantIconAnchorX = 11;
      const plantIconAnchorY = 17;

      const icon = {
        path: icons.housePath,
        fillColor: markerColor,
        fillOpacity: 1,
        strokeWeight: 0,
        anchor: new (window as any).google.maps.Point(plantIconAnchorX, plantIconAnchorY),
      };

      const shadowIcon = {
        ...icon,
        fillColor: 'black',
        fillOpacity: 0.6,
        scale: 1.075,
        strokeWeight: 2,
        strokeColor: 'black',
        strokeOpacity: 0.15,
        anchor: new (window as any).google.maps.Point(plantIconAnchorX + 0.5, plantIconAnchorY - 1),
      };
      return (
        <div key={plant.url}>
          <TTMarker
            position={position}
            icon={icon}
            shadowIcon={shadowIcon}
            isLabelHover
          />
          {this.renderGeoZones()}
        </div>

      );
    } catch (e) {
      // this avoids rendering component on errors
      // to debug, log this exception
      return null;
    }
  }
}

export interface PlantMarkerProps {
  plant: PlantDto,
  showGeofence?: boolean,
  markerColor?: string,
  mapRef?: any
}
