import { Dispatch } from 'redux';
import { DriverDto, TruckDetailsDto } from '@trucktrax/trucktrax-ts-common';
import { overrideMapFilter } from './mapFilterActions';
import {
  CLEAR_SELECTED_MARKER,
  CLOSE_ALL_POPUPS,
  CLOSE_POPUP,
  OPEN_POPUP,
  REFRESH_POSITIONS,
  SELECT_MARKER,
  SET_IS_PANNED,
  SET_POSITION_LIST,
  UPDATE_POSITION,
  ACTIVE_PIN_MAP,
  SET_PIN_RADIUS,
} from '../../constants/actionConstants';
import { MarkerType, MARKER_TYPE_ORDER, MARKER_TYPE_TRUCK } from '../../constants/mapConstants';
import { PositionDetailsDto } from '../../types';

export const setPositionList = (positionList: PositionDetailsDto[]) => ({
  type: SET_POSITION_LIST,
  payload: positionList,
});

export const updatePosition = (position: PositionDetailsDto) => ({
  type: UPDATE_POSITION,
  payload: position,
});

interface TrackedObject {
  isExternal: boolean;
}

interface TruckDetailsDtoNew extends TruckDetailsDto {
  trackedObject?: TrackedObject;
}

export interface MarkerInfo {
  id: string;
  markerId: string;
  type: MarkerType;
  isPannedTo?: boolean;
  lat?: number;
  lng?: number;
  isExternal?: boolean;
  markerData: {
    markerId: string,
    type: MarkerType,
    driverDetails: DriverDto;
    truckDetails: TruckDetailsDtoNew;
  }
}

/**
* Pin Location Info Interface
*/
export interface PinLocationInfo {
  pinMode?: boolean;
  pinDropped?: boolean;
  removePin?: boolean;
  location?: string;
  lat?: number;
  lng?: number;
}

export const selectMarker = (truck: MarkerInfo, fromTickets = false) => (dispatch: Dispatch) => {
  dispatch({
    type: SELECT_MARKER,
    payload: truck,
  });
  dispatch({
    type: OPEN_POPUP,
    payload: truck.markerId,
  });
  if (!fromTickets) {
    if (truck.type === MARKER_TYPE_ORDER) {
      dispatch(overrideMapFilter({ showJobsites: true }));
    } else if (truck.type === MARKER_TYPE_TRUCK && truck.markerData?.truckDetails?.trackedObject?.isExternal) {
      dispatch(overrideMapFilter({ showExtTrucks: true }));
    } else if (truck.type === MARKER_TYPE_TRUCK && !truck.markerData?.truckDetails?.trackedObject?.isExternal) {
      dispatch(overrideMapFilter({ showTrucks: true }));
    } else if (truck?.isExternal) {
      dispatch(overrideMapFilter({ showExtTrucks: true }));
    } else if (!truck?.isExternal) {
      dispatch(overrideMapFilter({ showTrucks: true }));
    }
  }
};

export const clearSelectedMarker = () => ({
  type: CLEAR_SELECTED_MARKER,
});

export const setIsPanned = () => ({
  type: SET_IS_PANNED,
});

export const openPopup = (markerId: string) => ({
  type: OPEN_POPUP,
  payload: markerId,
});

export const closePopup = (markerId: string) => ({
  type: CLOSE_POPUP,
  payload: markerId,
});

export const closeAllPopups = () => ({
  type: CLOSE_ALL_POPUPS,
});

export const refreshPositions = () => ({
  type: REFRESH_POSITIONS,
});

/**
 * Sets the pin map information
 * @param {PinLocationInfo} pinInfo pin Info map
 */
export const setPinMap = (pinInfo: PinLocationInfo) => ({
  type: ACTIVE_PIN_MAP,
  payload: pinInfo,
});

export const setPinRadius = (radius: string) => ({
  type: SET_PIN_RADIUS,
  payload: radius,
});
