import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  GeoZoneDto,
} from '@trucktrax/trucktrax-ts-common';
import {
  clearSelectedMarker,
  closePopup,
  PinLocationInfo,
  refreshPositions,
  setIsPanned,
} from '../../../store/actions/mapviewActions';
import { trackingSocketInit, activatePinMap, updatePinRadius } from '../../../services/mapviewsService';
import setSidebarSelected from '../../../store/actions/sidebarActions';
import styles from './RouteHistoryMapWrapper.module.css';
import { disableTabNavigation, enableTabNavigation } from '../../../services/keyboardService';
import { selectRouteMarker } from '../../../services/ticketsService';

import { TICKETS_SELECTED } from '../../../constants/appConstants';
import { ConnectedDispatchFunction } from '../../../types';
import Map, { MarkerType } from './RouteHistoryTTMap';
import { ReduxState } from '../../../store';

export class RouteHistoryMapWrapper extends Component<RouteHistoryMapWrapperAllProps, RouteHistoryMapWrapperState> {
  static defaultProps: Partial<RouteHistoryMapWrapperAllProps> = {
    currentRegion: { url: '', defaultRegion: false },
    selectedMarker: {
      markerId: '0',
      lat: 1,
      lng: 1,
      isPannedTo: false,
    },
    positions: [],
    sidebarSelection: undefined,
    currentGeozone: undefined,
  };

  private map: React.RefObject<HTMLInputElement>;

  constructor(props: RouteHistoryMapWrapperAllProps) {
    super(props);
    this.map = React.createRef();
    this.state = {
      location: {
        lat: parseFloat(this.props.lat.toString()),
        lng: parseFloat(this.props.lng.toString()),
      },
      defaultLocation: {
        lat: parseFloat(this.props.lat.toString()),
        lng: parseFloat(this.props.lng.toString()),
      },
      selectedMarker: null,
      tabNavigationListener: null,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.setState({ tabNavigationListener: disableTabNavigation() as any });
    trackingSocketInit(this.props.currentRegion!.url!);
  }

  // TODO fix in gtx-6006
  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps: RouteHistoryMapWrapperAllProps) {
    const regionLng = parseFloat(nextProps.lng.toString());
    const regionLat = parseFloat(nextProps.lat.toString());
    // changes location only if a new region is selected.
    // Center handled through google maps OnCenterChange
    if (regionLng !== this.state.defaultLocation.lng
      || regionLat !== this.state.defaultLocation.lat) {
      this.setState({
        location: {
          lat: regionLat,
          lng: regionLng,
        },
        defaultLocation: {
          lat: regionLat,
          lng: regionLng,
        },
      });
    }
    if (nextProps.selectedMarker && nextProps.selectedMarker.markerId) {
      // prevents map reposition when no lat long are received
      // this happens on click of popup only, and not from search or left side navigation.
      if (nextProps.selectedMarker.lat
        && nextProps.selectedMarker.lng
        && !nextProps.selectedMarker.isPannedTo) {
        // set state clears the selected truck to prevent re-focusing on the selected truck
        this.setState({
          selectedMarker: {
            lat: nextProps.selectedMarker.lat,
            lng: nextProps.selectedMarker.lng,
          } as unknown as string,
          location: {
            lat: nextProps.selectedMarker.lat,
            lng: nextProps.selectedMarker.lng,
          },
        }, () => {
          this.props.setIsPanned();
          // pushes call to event loop callback and out of javascript runtime to ensure it refreshes last
          setTimeout(this.props.refreshPositions, 0);
        });
      }
    } else {
      this.setState({
        selectedMarker: null,
      });
    }
  }

  componentWillUnmount() {
    enableTabNavigation(this.state.tabNavigationListener);
  }

  handleRouteMarkerClick = (targetMarker: MarkerType) => {
    this.props.selectRouteMarker(
      targetMarker as any
    );
  };

  panToSelectedVehicle = () => {
    // pans to selected vehicle only if the selected truck has not already been panned to
    if (this.state.selectedMarker && (this.state.selectedMarker as any).isPannedTo) {
      // handle datablock not to block the view of selected truck
      // shrink padding size until datablock size is less than half the screen
      const padding = this.props.sidebarSelection?.sidebarTitle === TICKETS_SELECTED
        && this.map.current!.clientHeight < 850
        ? 0.00012 - (Math.log(this.map.current!.clientHeight - 539) * 0.00001)
        : 0;
      return {
        lng: 0,
        ...this.state.selectedMarker as {},
        lat: (this.state.selectedMarker as any).lat - padding,
      };
    }
    return this.state.location;
  };

  handleMarkerClose = (targetMarker: MarkerType) => {
    this.props.closePopup(targetMarker.markerId ?? '');
    this.props.clearSelectedMarker();
  };

  /**
  * Replicates pin changes in the redux storage
  * @param {PinLocationInfo} pinInfo pinInfo object with new values
  */
  onPinChanged = (pinInfo: PinLocationInfo) => {
    this.props.activatePinMap(pinInfo);
  };

  /**
  * Replicates pin radius value changes in the redux storage
  * @param {string} radius new radius value in miles
  */
  onPinRadiusChanged = (radius: string) => {
    this.props.updatePinRadius(radius);
  };

  handleCenterChange = (center?: any) => {
    this.setState({ location: center });
  };

  render() {
    return (
      <div
        className={styles.mapPane}
        data-test="region-map"
        ref={this.map}
      >
        <Map
          center={this.panToSelectedVehicle()}
          currentRegion={this.props.currentRegion!}
          markers={[]}
          popupToggleMap={this.props.popupToggleMap}
          onRouteMarkerClick={this.handleRouteMarkerClick}
          onMarkerClose={this.handleMarkerClose}
          onCenterChange={this.handleCenterChange}
          currentGeozone={this.props.currentGeozone}
          pinMap={this.props.activePinMap.pinMode}
          onPinChanged={this.onPinChanged}
          onPinRadiusChanged={this.onPinRadiusChanged}
          isRouteHistory={this.props.isRouteHistory}
          routeHistoryProductLines={this.props.routeHistoryProductLines}
          routeHistoryList={this.props.routeHistoryList}
          selectedRouteHistoryList={this.props.selectedRouteHistoryList}
        />
      </div>
    );
  }
}

export interface SelectedMarker {
  markerId: number;
  lat: number;
  lng: number;
  isPannedTo: boolean;
}

export interface FeatureList {
  featuresList: {
    features: any,
    allFlags: any[]
  }
}

export interface RouteHistoryMapWrapperState {
  location: {
    lat: number,
    lng: number
  },
  defaultLocation: {
    lat: number,
    lng: number
  },
  selectedMarker: string | null,
  tabNavigationListener: any
}

export interface RouteHistoryMapWrapperOwnProps {
  lng: number,
  lat: number,
  currentGeozone?: GeoZoneDto,
  isRouteHistory?: boolean
}

function mapStateToProps(state: ReduxState) {
  return {
    selectedMarker: state.selectedMarker,
    popupToggleMap: state.popupToggleMap,
    currentRegion: state.currentRegion,
    positions: state.positionList,
    sidebarSelection: state.sidebar,
    flags: state.flags as any,
    selectedProductLine: state.selectedProductLine,
    activePinMap: state.activePinMap,
    routeHistoryProductLines: state.routeHistoryProductLines,
    routeHistoryList: state.routeHistoryList,
    selectedRouteHistoryList: state.selectedRouteHistoryList,
  };
}

interface RouteHistoryMapWrapperDispatchProps {
  closePopup: (popup: string) => void,
  clearSelectedMarker: () => void,
  setIsPanned: () => void,
  selectRouteMarker: ConnectedDispatchFunction<typeof selectRouteMarker>,
  refreshPositions: () => void,
  activatePinMap: (pinInfo: PinLocationInfo) => void,
  updatePinRadius: (radius: string) => void
}

type RouteHistoryMapWrapperReduxStateProps = ReturnType<typeof mapStateToProps>;

export type RouteHistoryMapWrapperAllProps =
  RouteHistoryMapWrapperOwnProps
  & RouteHistoryMapWrapperDispatchProps
  & RouteHistoryMapWrapperReduxStateProps;

export default connect(
  mapStateToProps,
  {
    closePopup,
    clearSelectedMarker,
    setSidebarSelected,
    setIsPanned,
    refreshPositions,
    activatePinMap,
    updatePinRadius,
    selectRouteMarker,
  }
)(RouteHistoryMapWrapper);
