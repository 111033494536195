import { Dispatch } from 'redux';
import { RegionDto, UrlKeyDto, UserRegionDto } from '@trucktrax/trucktrax-ts-common';
import { getRequest } from './requestService';
import { devErrorAndLog } from '../util/errorUtil';
import { ERROR_TEXT_FETCH_ASSIGNED_REGION_LIST, ERROR_TEXT_FETCH_REGION_LIST } from '../constants/errorConstants';
import {
  EMPTY_ALL_FIELDS,
  SET_ASSIGNED_REGION_LIST,
  SET_CURRENT_REGION,
  SET_REGION_LIST,
} from '../constants/actionConstants';
import { REGIONS_PATH } from '../constants/apiConstants';
import { reauthorize } from './authService';
import { setUserPermissionObject, initializeNewRegion } from './permissionsService';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { UserPermissionsByRegion } from '../types';
import { getIdFromUrl } from '../util/appUtil';
import { INVALID_STALE_TOKEN_LOGOUT } from '../constants/appConstants';
import HTTP_CODES from '../constants/httpConstants';

type GetStore = () => {
  assignedRegionList: UserRegionDto[],
  currentRegion: UrlKeyDto,
  defaultRegionList: UserRegionDto[],
  currentSubscription: UrlKeyDto
};

type GetState = () => {
  userPermission: UserPermissionsByRegion,
  currentRegion: UrlKeyDto,
  assignedRegionList: UserRegionDto[]
};

interface AssignedRegionList { data: UserRegionDto[] }

export function setCurrentRegion(value: UrlKeyDto | string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: EMPTY_ALL_FIELDS });
    dispatch({
      type: SET_CURRENT_REGION,
      payload: value,
    });
  };
}

export async function getRegionByUrl(url?: string) {
  if (!url) { return undefined; }

  const id = getIdFromUrl(url);
  const backendUrl = `${getGeotraxBaseUrl()}${REGIONS_PATH}/${id}`;

  const result = await getRequest(backendUrl);
  return result?.data ?? null;
}

export async function getRegionById(id?: string | number) {
  if (!id) { return undefined; }
  const backendUrl = `${getGeotraxBaseUrl()}${REGIONS_PATH}/${id}`;

  const result = await getRequest(backendUrl);
  return result?.data ?? null;
}

export function fetchAssignedRegionList(
  socketInitCallback: (region: UrlKeyDto, subscription: UrlKeyDto) => void,
  getMapviewsCallback: (regionUrl: string) => void
) {
  return async (dispatch: Dispatch<any>, getStore: GetStore) => {
    const {
      assignedRegionList,
      currentRegion,
      defaultRegionList,
      currentSubscription,
    } = getStore();

    const url = `${getGeotraxBaseUrl() + REGIONS_PATH}/assignment`;
    const params = {
      destination: (assignedRegionList || [])
        .map(region => region.url).join(','),
    };

    try {
      const response: AssignedRegionList = await getRequest(url, params);
      const regions = response.data;

      dispatch({
        type: SET_ASSIGNED_REGION_LIST,
        payload: regions,
      });

      // set first region as default,
      const preferredDefaultRegion = (regions && defaultRegionList.length)
        ? regions.filter(region => region.url === defaultRegionList[0].url)
        : defaultRegionList;

      const defaultRegion = preferredDefaultRegion && preferredDefaultRegion.length > 0
        ? preferredDefaultRegion[0]
        : regions[0];

      if (currentRegion) {
        socketInitCallback(currentRegion, getStore().currentSubscription);
        getMapviewsCallback(currentRegion.url!);
        return;
      }

      // if there is no current region selected or previously saved in local storage,
      // the app defaults to defaultRegion services
      socketInitCallback(defaultRegion, currentSubscription);
      getMapviewsCallback(defaultRegion.url!);
      dispatch({
        type: SET_CURRENT_REGION,
        payload: defaultRegion,
      });
    } catch (e: any) {
      if (e !== INVALID_STALE_TOKEN_LOGOUT) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_ASSIGNED_REGION_LIST, e, undefined, consoleOnly) as any);
      }
    }
  };
}

export function handleRegionAdded(region: RegionDto) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const newUserPermissionDictionary = await initializeNewRegion(region);
    // get a new token with assigned_regions that include this new one.
    await dispatch(reauthorize());
    const { assignedRegionList } = getState();

    dispatch(setUserPermissionObject(newUserPermissionDictionary));

    // update assigned regions
    const allAssignedRegions = [...assignedRegionList, region];
    dispatch({
      type: SET_ASSIGNED_REGION_LIST,
      payload: allAssignedRegions,
    });

    // have new region active
    dispatch(setCurrentRegion(region));
  };
}

export function fetchRegionList() {
  return async (dispatch: Dispatch<any>) => {
    const url = getGeotraxBaseUrl() + REGIONS_PATH;
    try {
      const response = await getRequest(url, {
        isDeleted: false,
        isArchived: false,
      });
      dispatch({
        type: SET_REGION_LIST,
        payload: response.data.items,
      });
    } catch (e: any) {
      if (e !== INVALID_STALE_TOKEN_LOGOUT) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_REGION_LIST, e, undefined, consoleOnly) as any);
      }
    }
  };
}
