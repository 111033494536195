import React, { Component } from 'react';
import cx from 'classnames';
import { MessageTabs } from '../../../../../constants/messagingConstants';
import styles from './Tabs.module.css';

class Tabs extends Component<TabsProps> {
  onClickInbox = () => {
    this.props.onTabChange(MessageTabs.Inbox);
  };

  onClickNew = () => {
    this.props.onTabChange(MessageTabs.New);
  };

  counter = () => (
    this.props.unreadCount
      ? (
        <span
          data-test="inbox-unread-counter"
          className={styles.count}
        >
          &nbsp;
        </span>
      ) : null
  );

  render() {
    const inboxClassName = cx(
      styles.tab,
      { [styles.selected]: this.props.currentTab === MessageTabs.Inbox }
    );
    const composeClassName = cx(
      styles.tab,
      { [styles.selected]: this.props.currentTab === MessageTabs.New }
    );
    return (
      <div className={styles.tabs}>
        {/* inbox */}
        <button
          className={inboxClassName}
          onClick={this.onClickInbox}
          data-test="message-center-inbox-button"
        >
          <i
            aria-hidden="true"
            className="icon-mail margin-right-10"
          />
          <span className={styles.title}>Inbox</span>
          {/* counter */}
          {this.counter()}
        </button>
        {/* compose message */}
        <button
          className={composeClassName}
          onClick={this.onClickNew}
          data-test="message-center-new-button"
        >
          <i
            aria-hidden="true"
            className="icon-translate margin-right-10"
          />
          <span className={styles.title}>Compose</span>
        </button>
      </div>
    );
  }
}

export interface TabsProps {
  onTabChange: (tab: MessageTabs) => void;
  unreadCount: boolean;
  currentTab: MessageTabs;
}

export default Tabs;
