import React from 'react';
import cx from 'classnames';
import styles from './FooterMessage.module.css';

function FooterMessage(props: FooterMessageProps) {
  const text = props.text || '';
  const showAsterisk = props.showAsterisk || false;
  const errorStyle = props.errorStyle || false;

  return (
    <span className={styles.footerMessageContainer}>
      {showAsterisk && <span className={styles.asterisk}>*</span>}
      <span
        className={cx(
          styles.text,
          errorStyle && styles.errorMessageText
        )}
      >
        {text}
      </span>
    </span>
  );
}

export default FooterMessage;

export interface FooterMessageProps {
  text?: string;
  showAsterisk?: boolean;
  errorStyle?: boolean;
}
