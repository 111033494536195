import React from 'react';
import { HeaderAndContainer } from '@trucktrax/trucktrax-common';
import { Card, CardContent } from '@mui/material';
import styles from './UserGuidesCommon.module.css';
import Header from '../../../shared/Header';
import AdminCardStyles from '../../../shared/admin/AdminCard.module.css';
import CardImage from '../../../shared/forms/CardImage';
import DocsTable from '../../../shared/docs/DocsTable';
import userGuidesWebJpg from '../../../../assets/img/userGuidesWeb.jpg';

export function GuideWebView() {
  const cardStyle = {
    root: AdminCardStyles.card,
  };

  const documents = [
    {
      name: 'Getting Started',
      filepath: 'userguides/web/userguide_web_Getting_Started.pdf',
    },
    {
      name: 'Region Map',
      filepath: 'userguides/web/userguide_web_Region_Map.pdf',
    },
    {
      name: 'Message Center',
      filepath: 'userguides/web/userguide_web_Message_Center.pdf',
    },
    {
      name: 'Tickets',
      filepath: 'userguides/web/userguide_web_Tickets.pdf',
    },
    {
      name: 'Admin',
      filepath: 'userguides/web/userguide_web_Admin.pdf',
    },
    {
      name: 'Orders',
      filepath: 'userguides/web/userguide_web_Orders.pdf',
    },
    {
      name: 'Documents',
      filepath: 'userguides/web/userguide_web_Documents.pdf',
    },
  ];

  const guideBody = (
    <div className={AdminCardStyles.bodyWrapper}>
      <div className={AdminCardStyles.cardWrapper}>
        <Card classes={cardStyle}>
          <CardImage src={userGuidesWebJpg} className={styles.topImg} />
          <CardContent>
            <div className={AdminCardStyles.cardText}>
              <h2 className={styles.header}>GeoTrax Web User Guide</h2>

              <p>
                GeoTrax® is a cloud-based software application comprised of mobile and web applications
                to track and manage bulk construction materials deliveries. The mobile app functions
                on Android® devices without the need for dedicated, truck-mounted hardware.
                When equipped with the GeoTrax application, on startup the device communicates with a
                remote cloud-based database that functions as the data repository and communicates with
                the web application to perform a variety of functions. The web application is designed
                to interface bi-directionally with a client’s ticketing software.
              </p>

              <div className={styles.divider} />
              <DocsTable documents={documents} folder="userguides/web" />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );

  const elementsLeft = <Header title="User Guides" />;

  return (
    <div>
      <div>
        <HeaderAndContainer elementsLeft={elementsLeft} />
      </div>
      {guideBody}
    </div>
  );
}

export default GuideWebView;
