import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  PermissionAccess,
  VehicleTypeDto,
} from '@trucktrax/trucktrax-ts-common';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { ADMIN_VEHICLE_TYPES_PATH, VEHICLE_TYPES_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import AdminCard from '../../shared/admin/AdminCard';
import { TRUCKS_TEXT } from '../../../constants/navConstants';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  DROPDOWN_FORM,
  EDIT_BTN,
  INPUT_FORM,
  NUMERIC_INPUT_FORM,
  SAVE_BTN,
  TEXT_DISPLAY,
} from '../../../constants/appConstants';
import styles from './VehicleTypesDetailView.module.css';
import { updateDataTableRecord } from '../../../services/dataTableService';
import {
  closeModal,
  openModal,
} from '../../../store/actions/errorModalActions';
import { getUnitOfMeasureLabel } from '../../../util/appUtil';
import VehicleTypesAddModal from './VehicleTypesAddModal';

export class VehicleTypesDetailView extends Component<VehicleTypesDetailViewProps, VehicleTypesDetailViewState> {
  state = {
    edit: false,
    errorObject: {
      error: false,
      errorMessage: '',
    },
    currentUom: '',
  };

  save = (dto: VehicleTypeDto, onSuccess: any, onError: any) => {
    if (dto.maxWeight) {
      dto.maxWeight = Math.trunc(dto.maxWeight * 100) / 100;
    }
    const currentRegionUrl = this.props.currentRegion.url;
    dto.region = { url: currentRegionUrl };

    const onSaveSuccess = (updatedDto: any) => {
      this.setState({
        edit: false,
      });
      onSuccess(updatedDto);
      this.props.closeModal();
    };

    this.props.updateAdminTableData(this.baseUrl(), dto, onSaveSuccess, onError, false, currentRegionUrl);
  };

  toggleEdit = () => {
    this.setState({
      edit: !this.state.edit,
    });
  };

  baseUrl = () => `${getGeotraxBaseUrl() + VEHICLE_TYPES_PATH}/${this.props.match.params.id}`;

  uomAccessor = (row?: VehicleTypeDto) => {
    if (!row?.unitOfMeasure) {
      return null;
    }

    const { currentUom } = this.state;
    const rowUom = `${row.unitOfMeasure}`;

    if (!currentUom) {
      this.setState({ currentUom: rowUom });
      return rowUom;
    }

    if (rowUom === currentUom) {
      return rowUom;
    }

    this.setState({ currentUom: rowUom });

    row.maxWeight = 0;
    return rowUom;
  };

  render() {
    const readConfigWithoutEditPermission = [
      {
        type: TEXT_DISPLAY,
        label: `${ADMIN_LABELS.VEHICLE_TYPE} ${ADMIN_LABELS.NAME}`,
        accessor: ADMIN_KEYS.NAME,
        dataTest: 'name-drilldown-name',
        className: styles.textDisplay,
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.GROSS_MAX_WEIGHT,
        accessor: (row: VehicleTypeDto) => `${row.maxWeight?.toLocaleString()} ${getUnitOfMeasureLabel(row.unitOfMeasure)}`,
        dataTest: 'haulers-drilldown-id',
      }];

    const readConfigWithEditPermission = [
      ...readConfigWithoutEditPermission,
      {
        type: EDIT_BTN,
        name: 'Edit Vehicle Type',
        iconClassName: 'icon-create',
        dataTest: 'haulers-drilldown-edit',
      },
    ];

    const editConfig = [
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.NAME,
        accessor: ADMIN_KEYS.NAME,
        label: `${ADMIN_LABELS.VEHICLE_TYPE} ${ADMIN_LABELS.NAME}`,
        maxLength: 50,
        dataTest: 'name-input-data-test',
        errorDataTest: 'name-input-missing-error',
        className: cx('widthSpace', 'baseMargin', styles.name),
        isRequired: true,
      }, {
        type: NUMERIC_INPUT_FORM,
        key: ADMIN_KEYS.MAXWEIGHT,
        accessor: ADMIN_KEYS.MAXWEIGHT,
        label: (
          <>
            {ADMIN_LABELS.GROSS_MAX_WEIGHT}
            <span className={styles.required}>*</span>
          </>
        ),
        dataTest: 'maxWeight-input-data-test',
        errorDataTest: 'maxWeight-input-missing-error',
        className: styles.maxWeight,
        maxLength: 12,
        allowDecimal: true,
        thousandSeparator: true,
      },
      {
        type: DROPDOWN_FORM,
        className: styles.uomDropDown,
        label: ADMIN_LABELS.DEFAULT_SCALE_UNIT,
        key: ADMIN_KEYS.UNIT_OF_MEASURE,
        accessor: this.uomAccessor,
        initialSelected: VehicleTypesAddModal.unitOfMeasures[0],
        items: VehicleTypesAddModal.unitOfMeasures,
        isRequired: true,
        dataTest: 'region-input-data-test',
      },
      {
        type: SAVE_BTN,
      },
    ];

    let configValue;
    if (this.props.trucksPermissionAccess === PermissionAccess.Edit) {
      if (this.state.edit) {
        configValue = editConfig;
      } else {
        configValue = readConfigWithEditPermission;
      }
    } else {
      configValue = readConfigWithoutEditPermission;
    }

    return (
      <>
        <Link className={styles.vehicleTypesAnchor} to={ADMIN_VEHICLE_TYPES_PATH}>
          Vehicle Types
        </Link>
        <AdminCard
          edit={this.state.edit}
          url={this.baseUrl()}
          className={cx(
            styles.wrapper,
            this.state.edit && styles.editWrapper
          )}
          onToggleEdit={this.toggleEdit}
          save={this.save}
          config={configValue}
          errorObject={this.state.errorObject}
          pathName="/admin/Vehicle Types/?"
          headerAccessor="name"
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  trucksPermissionAccess: state.adminPermissionAccess[TRUCKS_TEXT],
  currentRegion: state.currentRegion,
});

export interface VehicleTypesDetailViewProps {
  updateAdminTableData: typeof updateDataTableRecord,
  closeModal: typeof closeModal,
  trucksPermissionAccess?: string,
  match: { params: { id: string } },
  currentRegion: { url: string },
}

export interface VehicleTypesDetailViewState {
  edit: boolean,
  errorObject: {
    error: boolean,
    errorMessage: string
  },
  currentUom: string
}

export default connect<any, any, any>(mapStateToProps, {
  updateAdminTableData: updateDataTableRecord,
  openModal,
  closeModal,
})(VehicleTypesDetailView);
