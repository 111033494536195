import React, { Component } from 'react';
import { Overlay } from '@trucktrax/trucktrax-common';
import { NOTFOUND_HEADER, NOTFOUND_NOTIFICATION, NOTFOUND_STATUS } from '../../constants/appConstants';
import bgNotFoundJpg from '../../assets/img/bgNotfound.jpg';

// eslint-disable-next-line react/prefer-stateless-function
export class NotFoundView extends Component {
  render() {
    return (
      <Overlay
        bgImageSrc={bgNotFoundJpg}
        headerStatus={NOTFOUND_STATUS}
        headerText={NOTFOUND_HEADER}
        text={<div>{NOTFOUND_NOTIFICATION}</div>}
        altText={NOTFOUND_NOTIFICATION}
      />
    );
  }
}

export default NotFoundView;
