import React, { Component } from 'react';
import {
  Circle, GoogleMap, Marker, Polygon, DrawingManager, StandaloneSearchBox,
} from '@react-google-maps/api';
import {
  Button, Label, postGISToGoogleMapsPoints, vars, constants,
} from '@trucktrax/trucktrax-common';
import { LocationDto } from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import { noop } from '../../../util/appUtil';
import { getAddressGeocode, removeAutocomplete } from '../../../util/adminUtil';
import { GEOZONE_TYPE, GEOZONE_SHAPE_STYLE, ADMIN_LABELS } from '../../../constants/appConstants';
import { CARD_MAP_HEIGHT } from '../../../constants/mapConstants';
import styles from './CardMap.module.css';
import { ZoneType } from '../../../types';
import icons from '../../geotrax/map/icons';

interface MapChangeEvent {
  location?: LocationDto;
  circle?: {
    center: LocationDto;
    radius: number;
  };
  polygon?: {
    points: LocationDto[];
  }
}

type ShapeStyles = {
  fillColor: string;
  strokeColor: string;
  fillOpacity: number;
  strokeWeight: number;
  clickable: boolean;
  zIndex: number;
  draggable: boolean;
  editable: boolean;
};

interface MapState {
  location?: LocationDto,
  isMapHasBeenBounded: boolean
}

export class Map extends Component<MapProps, MapState> {
  static defaultProps: Partial<MapProps> = {
    showSearchBox: true,
    showDrawingManager: true,
    isEditable: false,
    onChange: noop,
    editMarkerOnly: false,
    defaultMapZoom: 15,
    showMarker: false,
    showGeoZone: false,
  };

  constructor(props: MapProps) {
    super(props);

    this.state = {
      location: props.location,
      isMapHasBeenBounded: false,
    };
  }

  circleRef?: google.maps.Circle;

  polygonRef?: google.maps.Polygon;

  mapRef?: google.maps.Map;

  searchBoxRef?: google.maps.places.SearchBox;

  listenersRef?: google.maps.MapsEventListener[];

  componentDidMount() {
    if (this.props.address && !this.isValidLocation()) {
      // gets a location using the Geocoding API
      // https://developers.google.com/maps/documentation/geocoding/intro
      // should only get called here once if an address prop is given
      getAddressGeocode(this.props.address, this.onGeocodeSuccess);
    } else {
      this.onChange({
        location: this.props.location,
      });
    }

    removeAutocomplete();
  }

  componentDidUpdate() {
    const { location } = this.props;
    if (location !== this.state.location) {
      this.setState({ location });
    }
  }

  componentWillUnmount() {
    this.circleRef = undefined;
    this.polygonRef = undefined;
  }

  isValidLocation = () => {
    const { location } = this.state;
    const lat = location?.latitude ?? 0;
    const lng = location?.longitude ?? 0;
    return (lat !== 0 || lng !== 0);
  };

  onPolygonLoad = (polygon: google.maps.Polygon) => {
    this.polygonRef = polygon;
    if (!this.listenersRef) this.listenersRef = [];
    const path = polygon.getPath();
    this.listenersRef.forEach(lis => lis.remove());
    this.listenersRef = [
      path.addListener('set_at', this.onPolygonChanged),
      path.addListener('insert_at', this.onPolygonChanged),
      path.addListener('remove_at', this.onPolygonChanged),
    ];

    if (!this.state.isMapHasBeenBounded) {
      this.setState({ isMapHasBeenBounded: true }, () => this.zoomToBounds());
    }
  };

  zoomToBounds = () => {
    if (!this.mapRef) return;
    const bounds = new window.google.maps.LatLngBounds();

    if (this.isValidLocation()) {
      const { location } = this.state;
      bounds.extend(new window.google.maps.LatLng(
        (location?.latitude ?? 0) - 0.001,
        (location?.longitude ?? 0) - 0.001
      ));
      bounds.extend(new window.google.maps.LatLng(
        (location?.latitude ?? 0) + 0.001,
        (location?.longitude ?? 0) + 0.001
      ));
    }

    if (this.circleRef) {
      bounds.union(this.circleRef.getBounds()!);
    }

    if (this.polygonRef) {
      this.polygonRef.getPath().getArray().forEach(point => {
        bounds.extend(point);
      });
    }

    this.mapRef.fitBounds(bounds);
  };

  prevChange = {};

  onChange(change: MapChangeEvent) {
    if (!this.mapRef) return;
    if (JSON.stringify(this.prevChange) === JSON.stringify(change)) {
      return;
    }

    this.prevChange = change;
    this.props.onChange!(change);
  }

  onSearchBoxUpdate = () => {
    const places = this.searchBoxRef!.getPlaces() ?? [];
    const bounds = new window.google.maps.LatLngBounds();

    places.forEach(place => {
      if (!place) {
        return;
      }

      const { location } = place.geometry!;

      if (place.geometry?.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry!.location!);
      }

      // change
      this.onChange({
        location: Map.mapsToLocationDto(location),
      });
    });

    this.mapRef!.fitBounds(bounds);
  };

  static mapsToLocationDto = (mapsLatLng: google.maps.LatLngLiteral | google.maps.LatLng | undefined | null) => {
    if (!mapsLatLng) {
      return {
        latitude: 0,
        longitude: 0,
      };
    }

    if (typeof mapsLatLng?.lat === 'function') {
      const latLng = mapsLatLng as google.maps.LatLng;
      return {
        latitude: latLng.lat(),
        longitude: latLng.lng(),
      };
    }

    const latLngLiteral = mapsLatLng as google.maps.LatLngLiteral;
    return {
      latitude: latLngLiteral.lat,
      longitude: latLngLiteral.lng,
    } as LocationDto;
  };

  onMarkerComplete = (marker: google.maps.Marker) => {
    const location = Map.mapsToLocationDto(marker.getPosition());
    this.onChange({ location });
    marker.setMap(null); // clear circle
  };

  onCircleDrawingComplete = (circle: google.maps.Circle) => {
    this.onCircleComplete(circle);
    if (circle.setMap) {
      // new circle created via onCircleComplete, can delete this one
      circle.setMap(null);
    }
  };

  onCircleComplete = (circle?: google.maps.Circle) => {
    if (!this.mapRef) return;
    if (!circle) return;
    const center = Map.mapsToLocationDto(circle.getCenter());
    this.onChange({
      circle: {
        center,
        radius: circle.getRadius(),
      },
      polygon: undefined,
    });
  };

  onPolygonDrawingComplete = (polygon: google.maps.Polygon) => {
    this.onPolygonComplete(polygon);

    if (polygon.setMap) {
      // new polygon created via onPolygonComplete, can delete this one
      polygon.setMap(null);
    }
  };

  onPolygonComplete = (polygon: google.maps.Polygon) => {
    const newPolygon = polygon.getPath().getArray().map(point => ({
      lat: point.lat(),
      lng: point.lng(),
    }));
    const points = Map.googleMapsToPostGISPoints(newPolygon) ?? [];
    this.onChange({
      circle: undefined,
      polygon: {
        points,
      },
    });
  };

  onGeocodeSuccess = (json: google.maps.GeocoderResponse) => {
    const bounds = new window.google.maps.LatLngBounds();
    const place = json.results[0];

    if (place) {
      const { location } = place.geometry;

      Object.values(place.geometry.viewport)
        .forEach(v => {
          bounds.extend(v);
        });

      // change
      this.onChange({
        location: Map.mapsToLocationDto(location),
      });
    } else if (this.isValidLocation()) {
      this.onChange({
        location: this.state.location,
      });
    }

    this.zoomToBounds();
  };

  onPolygonChanged = () => {
    this.onPolygonComplete(this.polygonRef!);
  };

  // Clean up refs
  onPolygonUnmount = () => {
    this.listenersRef!.forEach(lis => lis.remove());
    this.listenersRef = [];
    this.polygonRef = undefined;
  };

  onCircleUnmount = () => {
    this.listenersRef = [];
    this.circleRef = undefined;
  };

  onCircleChanged = () => {
    if (!this.mapRef) return;
    this.onCircleComplete(this.circleRef!);
  };

  getCenter() {
    // default to current map center, to not change viewport on update.
    const center = this.mapRef?.getCenter();
    const lat = center?.lat();
    const lng = center?.lng();

    const hasCenter = !!lat && !!lng;
    if (hasCenter) {
      return { lat, lng };
    }

    const { location } = this.state;
    return {
      lat: location?.latitude ?? 0,
      lng: location?.longitude ?? 0,
    };
  }

  onCircleLoad = (circle: google.maps.Circle) => {
    this.circleRef = circle;
    if (!this.state.isMapHasBeenBounded) {
      this.setState({ isMapHasBeenBounded: true }, () => this.zoomToBounds());
    }
  };

  getSearchBoxRef = (el: google.maps.places.SearchBox) => {
    this.searchBoxRef = el;
  };

  onMapLoad = (map: google.maps.Map) => {
    this.mapRef = map;
  };

  searchBox() {
    return (this.props.showSearchBox && this.props.isEditable)
      && (
        <StandaloneSearchBox
          onLoad={this.getSearchBoxRef}
          onPlacesChanged={this.onSearchBoxUpdate}
        >
          <input
            type="text"
            placeholder="Type address for marker"
            className={cx('tt-input', styles.mapInput)}
            onFocus={removeAutocomplete}
            style={{
              width: '240px',
              height: '32px',
              margin: '5px',
              textOverflow: 'ellipses',
              position: 'absolute',
            }}
          />
        </StandaloneSearchBox>
      );
  }

  drawingManager(shapeStyles: ShapeStyles) {
    let drawingModesArray: google.maps.drawing.OverlayType[] = [];
    if (this.props.showGeoZone === true && this.props.showMarker === true) {
      drawingModesArray = [
        window.google.maps.drawing.OverlayType.MARKER,
        window.google.maps.drawing.OverlayType.CIRCLE,
        window.google.maps.drawing.OverlayType.POLYGON,
      ];
    } else if (this.props.showMarker === true && this.props.showGeoZone === false) {
      drawingModesArray = [
        window.google.maps.drawing.OverlayType.MARKER,
      ];
    } else if (this.props.showGeoZone === true && this.props.showMarker === false) {
      drawingModesArray = [
        window.google.maps.drawing.OverlayType.CIRCLE,
        window.google.maps.drawing.OverlayType.POLYGON,
      ];
    }

    return (this.props.showDrawingManager && this.props.isEditable)
      && (
        <DrawingManager
          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: this.props.showSearchBox
                ? window.google.maps.ControlPosition.TOP_RIGHT
                : window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: drawingModesArray,
            },
            circleOptions: shapeStyles,
            polygonOptions: shapeStyles,
          }}
          onMarkerComplete={this.onMarkerComplete}
          onCircleComplete={this.onCircleDrawingComplete}
          onPolygonComplete={this.onPolygonDrawingComplete}
        />
      );
  }

  static googleMapsToPostGISPoints = (points?: { lat: number, lng: number }[]) => {
    if (!points) return null;

    points.push(points[0]);
    return points.map(point => ({
      latitude: point.lat,
      longitude: point.lng,
    }));
  };

  polygon = (shapeStyles: ShapeStyles) => (this.props.polygon && this.props.polygon.points && !this.props.editMarkerOnly)
    && (
      <Polygon
        path={postGISToGoogleMapsPoints(this.props.polygon.points) as any}
        onLoad={this.onPolygonLoad}
        editable
        // Event used when dragging the whole Polygon
        onDragEnd={this.onPolygonChanged}
        // Event used when manipulating and adding points
        onMouseUp={this.onPolygonChanged}
        onUnmount={this.onPolygonUnmount}
        options={shapeStyles}
      />
    );

  circle(shapeStyles: ShapeStyles) {
    return (this.props.circle && this.props.circle.center && !this.props.editMarkerOnly)
      && (
        <Circle
          center={{
            lat: this.props.circle.center.latitude!,
            lng: this.props.circle.center.longitude!,
          }}
          onLoad={this.onCircleLoad}
          radius={this.props.circle.radius}
          onRadiusChanged={() => this.onCircleChanged()}
          onCenterChanged={() => this.onCircleChanged()}
          onUnmount={() => this.onCircleUnmount()}
          options={shapeStyles}
        />
      );
  }

  marker() {
    const icon = {
      path: icons.flagPath,
      fillColor: vars.gray700,
      fillOpacity: 1,
      strokeColor: vars.white,
      strokeWeight: 2,
      // this anchors the image so the beginning of the flag pole is in the geolocation
      anchor: new window.google.maps.Point(5, 30),
    };

    const editable = this.props.isEditable;
    if (!this.isValidLocation()) {
      return undefined;
    }

    return (
      <Marker
        icon={icon}
        position={{
          lat: this.state.location?.latitude ?? 0,
          lng: this.state.location?.longitude ?? 0,
        }}
        visible={this.props.showMarker}
        draggable={editable}
        onDragEnd={marker => {
          // change
          this.onChange({
            location: {
              latitude: marker?.latLng?.lat(),
              longitude: marker?.latLng?.lng(),
            },
          });
        }}
      />
    );
  }

  getGeofenceStyle(zoneType?: ZoneType) {
    const shapeStyles: ShapeStyles = {
      fillColor: '#67B4F6',
      strokeColor: '#67B4F6',
      fillOpacity: 0.25,
      strokeWeight: 2,
      clickable: false,
      zIndex: 1,
      draggable: true,
      editable: this.props.isEditable ?? false,
    };

    type GeozoneType = typeof GEOZONE_SHAPE_STYLE;
    type GeozoneShapeStyle = {
      fillColor: string;
      strokeColor: string;
      zIndex: number;
    };

    const zone: { [geozoneName: string]: GeozoneShapeStyle } = {};

    zone[GEOZONE_TYPE.PLANT] = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.PLANT as keyof GeozoneType],
      zIndex: 3,

    };
    zone[GEOZONE_TYPE.INQUEUE] = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.INQUEUE as keyof GeozoneType],
      zIndex: 2,
    };
    zone[GEOZONE_TYPE.RETURN] = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.RETURN as keyof GeozoneType],
      zIndex: 1,
    };

    return zoneType
      ? { ...shapeStyles, ...zone[zoneType] }
      : shapeStyles;
  }

  render() {
    const containerStyle = {
      width: this.props.mapContainerStyle.width || '100%',
      minHeight: this.props.mapContainerStyle.minHeight,
      height: this.props.mapContainerStyle.height || CARD_MAP_HEIGHT,
    };

    if (!this.state.location) {
      return null;
    }

    return (
      <GoogleMap
        zoom={this.props.defaultMapZoom}
        // use initial center from props (marker, circle and polygon should use state)
        center={this.mapRef?.getCenter()! || this.getCenter()}
        mapContainerStyle={containerStyle}
        onLoad={this.onMapLoad}
        options={{
          draggable: this.props.isEditable,
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: true,
          controlSize: constants.GM_CONTROLSIZE,
          mapTypeControlOptions: {
            mapTypeIds: [
              window.google.maps.MapTypeId.ROADMAP,
              window.google.maps.MapTypeId.SATELLITE,
            ],
            position: window.google.maps.ControlPosition.LEFT_BOTTOM,
          },
          panControl: false,
          zoomControl: true,
          rotateControl: false,
          fullscreenControl: false,
        }}
      >
        {this.searchBox()}
        {/* DRAWING MANAGER */}
        {this.drawingManager(this.getGeofenceStyle(this.props.geofenceAreaType))}
        {/* SINGLE MARKER */}
        {this.marker()}
        {/* SINGLE CIRCLE */}
        {this.circle(this.getGeofenceStyle(this.props.geofenceAreaType))}
        {/* SINGLE POLYGON */}
        {this.polygon(this.getGeofenceStyle(this.props.geofenceAreaType))}
      </GoogleMap>
    );
  }
}

type MapContainerStyle = {
  height?: string;
  minHeight?: string;
  width?: string;
  maxWidth: string;
  border?: string;
};

export interface MapProps {
  circle?: {
    center: LocationDto;
    radius: number;
  };
  polygon?: {
    points: LocationDto[];
  };
  location?: LocationDto;
  mapContainerStyle: MapContainerStyle;
  address?: string;
  showSearchBox?: boolean;
  showDrawingManager?: boolean;
  isEditable?: boolean;
  onChange?: (evt: MapChangeEvent) => void;
  editMarkerOnly?: boolean;
  defaultMapZoom?: number;
  geofenceAreaType?: ZoneType;
  showMarker?: boolean;
  showGeoZone?: boolean;
}

/* Start CardMap */
export default function CardMap({
  className = '',
  label = '',
  value = { circle: undefined, location: undefined, polygon: undefined },
  onChange = noop,
  resetBtn = false,
  isEditable = false,
  errorMessage,
  containerStyle = {
    height: CARD_MAP_HEIGHT,
    maxWidth: '100%',
    border: `1px solid ${vars.gray200}`,
  },
  ...rest
}: CardMapProps) {
  const isEnabled = isEditable && (value.circle || value.polygon);
  return (
    <div
      className={cx(className, styles.map)}
    >
      {label && <Label htmlFor="details-map">{label}</Label>}
      {errorMessage ? <p className={styles.errorMessageMap}>{errorMessage}</p> : <></>}
      <div className={cx(className, errorMessage ? styles.redBorderMap : '')}>
        <Map
          {...value}
          onChange={onChange}
          isEditable={isEditable}
          {...rest}
          mapContainerStyle={containerStyle}
        />
      </div>
      {resetBtn
        && (
          <Button
            buttonClassName={cx('tt-btn-secondary', 'tt-btn-bottom', 'full-width')}
            iconClassName="icon-undo"
            name={label === ADMIN_LABELS.GEOZONE ? 'Clear Geozone' : 'Reset Geofence'}
            dataTest="reset-geofence-btn"
            disabled={!isEnabled}
            onClick={() => {
              onChange({
                circle: undefined,
                polygon: undefined,
              });
            }}
          />
        )}
    </div>
  );
}

export interface CardMapProps extends MapProps {
  className?: string;
  label?: string;
  value?: MapChangeEvent;
  onChange?: (evt: MapChangeEvent) => void;
  resetBtn?: boolean;
  containerStyle?: MapContainerStyle;
  errorMessage?: string;
}
