import { Dispatch } from 'redux';
import {
  AssignedPermissionDto, GroupDto, PermissionAccess, PermissionDto,
} from '@trucktrax/trucktrax-ts-common';
import {
  ADV_SECURITY_PERMS,
  FEATURE_FLAG_ACCESS_FLAG,
  PERMISSION_NAMES,
} from '../constants/appConstants';
import { FEATURE_FLAGS_FLAGS_PATH } from '../constants/apiConstants';
import { fetchPermissionsListByUserUrl } from '../services/permissionsService';
import {
  TruckTraxApps,
  HasId,
  HasName,
  HasUrl,
} from '../types';
import { categoriesForAdmin } from '../constants/navConstants';

export type PermissionInfo = HasName & HasUrl & HasId;

type GroupDtoByGroupUrlMap = {
  [groupUrl: string]: GroupDto;
};

type UserPermissionRowByPermissionUrlMap = {
  [permissionUrl: string]: UserPermissionRow;
};

export interface RegionAccessMap {
  [regionUrl: string]: {
    [permission: string]: {
      access: PermissionAccess;
      permissionUrl?: string;
    }
  }
}

export interface CreatePermission extends PermissionInfo {
  access: string;
  region?: HasUrl;
  group?: HasUrl
}

export interface MergedPermissionItem {
  access?: string;
  permission: PermissionDto;
  name?: string;
  url?: string;
  id: number;
}

export interface UserPermissionRow {
  permission: string | null;
  permissionUrl: string;
  access: string;
  inheritance: string | GroupDto[];
}

export default function sortPermissionName(records: MergedPermissionItem[]) {
  return records.sort((r1, r2) => {
    if (r1.permission.name! < r2.permission.name!) {
      return -1;
    }
    if (r1.permission.name! > r2.permission.name!) {
      return 1;
    }
    return 0;
  });
}

/**
 * checks if user has at least one 'Edit' or 'View' access for any permission in the specified region
 * @param regionAccessMap The map containing the permissions.
 * @param currentRegionUrl The region to check.
 * @returns True if the user has access to anything in the specified region; otherwise, false.
 */
export function isUserWithAtleastOnePermission(regionAccessMap: RegionAccessMap, currentRegionUrl: string) {
  let atleastOnePermission = false;

  const advancedPermissionFeatureFlags = [
    ADV_SECURITY_PERMS,
    FEATURE_FLAG_ACCESS_FLAG,
  ];

  const region = regionAccessMap[currentRegionUrl];
  if (region) {
    Object.keys(region!).forEach(permissionName => {
      const permission = region[permissionName];
      const hasAdvancedPermission = advancedPermissionFeatureFlags.includes(permissionName);
      const forFeatureFlags = permission.permissionUrl?.includes(FEATURE_FLAGS_FLAGS_PATH) ?? false;

      if (hasAdvancedPermission || !forFeatureFlags) {
        if (permission.access === PermissionAccess.Edit
          || permission.access === PermissionAccess.View) {
          atleastOnePermission = true;
        }
      }
    });
  }

  return atleastOnePermission;
}
/**
 * Checks if user has at least one of the specified permissions in the specified region.
 * @param regionAccessMap The map containing the permissions.
 * @param currentRegionUrl The region to check.
 * @param givenPermissions The list of permissions to check.
 * @returns True if the user has one or more permissions in the specified region; otherwise, false.
 */
export function isUserWithAtleastOneOfGivenPermissions(
  regionAccessMap: RegionAccessMap,
  currentRegionUrl: string,
  givenPermissions: string[]
) {
  const regionPermissions = regionAccessMap[currentRegionUrl];
  if (!regionPermissions) {
    return false;
  }

  const permissionNames = Object.keys(regionPermissions);
  const relevantPermissionNames = permissionNames.filter(permName => givenPermissions.indexOf(permName) > -1);
  const hasRelevantPermission = relevantPermissionNames.some(permissionName => {
    const permission = regionPermissions[permissionName];
    return permission.access !== PermissionAccess.Deny;
  });

  return hasRelevantPermission;
}

/**
 * Checks if the user has admin access in a specific region
 * @param permissionObj The map containing the permissions.
 * @param regionUrl The region to check for Admin permissions in.
 * @returns True if the user has admin access in the region.
 */
export function hasAdminAccessInGivenRegion(permissionObj: RegionAccessMap, regionUrl: string) {
  if (!permissionObj) { return false; }

  const allRegions = Object.keys(permissionObj);

  // if you have one of these "categoriesForAdmin" permissions it qualifies you as having Admin permissions
  const adminPermissions = categoriesForAdmin.map(c => c.name);
  const hasAdminAccessInRegion = allRegions.some(
    region => region === regionUrl && isUserWithAtleastOneOfGivenPermissions(permissionObj, region, adminPermissions)
  );
  return hasAdminAccessInRegion;
}

/**
 * Checks if the user has access to the specified app in any region.
 * @param app The app to check.
 * @param permissionObj The map containing the permissions.
 * @returns True if the user has access to the specified app in any region; otherwise, false.
 */
export const hasAppAccessInAnyRegion = (app: TruckTraxApps, permissionObj: RegionAccessMap) => {
  if (!permissionObj) { return false; }

  const allRegions = Object.keys(permissionObj);

  if (app === TruckTraxApps.Admin) {
    const adminPermissions = categoriesForAdmin.map(c => c.name);
    const hasAdminAccessInAnyRegion = allRegions.some(
      region => isUserWithAtleastOneOfGivenPermissions(permissionObj, region, adminPermissions)
    );
    return hasAdminAccessInAnyRegion;
  }

  const forScaleTrax = app === TruckTraxApps.ScaleTrax;
  const appName = TruckTraxApps[app];
  const permissionName = forScaleTrax ? 'Weighmaster' : appName;

  return allRegions.some(
    region => permissionObj[region][permissionName]?.access && permissionObj[region][permissionName]?.access !== PermissionAccess.Deny
  );
};

export const accessLevelListForPermission = (permission: PermissionDto) => permission
  .possibleAccessLevels?.map(level => ({
    label: level === PermissionAccess.Deny ? 'NONE' : level.toUpperCase(),
    value: { access: level },
  }));

export const createAssignedPermissionDto = (permission: CreatePermission, region: HasUrl, group?: HasUrl) => {
  const dto = { ...permission };
  if (dto) {
    dto.region = dto.region || { url: region.url };
    dto.group = dto.group || group;
  }
  return dto;
};

export const createAssignedPermissionList = (permissions: CreatePermission[], regions: HasUrl[]) => {
  const assignedPermissionList = [];
  const totalPermissionCount = permissions.length * regions.length;

  /**
   * When a new group is created, identical permissions applied throughout regions
   * within Global Admin user's tenant.
   * Avoid nested loop by multiplying the size of permissions by the no. of regions
   */
  if (permissions.length > 0 && regions.length > 0) {
    for (let i = 0; i < totalPermissionCount; i += 1) {
      const pIndex = i % permissions.length;
      const rIndex = parseInt(String(i / permissions.length), 10);
      const assignedPermission = createAssignedPermissionDto(permissions[pIndex], regions[rIndex]);
      assignedPermissionList.push(assignedPermission);
    }
  }

  return assignedPermissionList;
};

export const createDefaultAssignedPermissionList = (permissionList: PermissionInfo[]): MergedPermissionItem[] => {
  if (!permissionList.length) { return []; }

  return permissionList.map(permission => ({
    access: PermissionAccess.Deny,
    ...permission,
    permission,
  }));
};

export const mapUserPermissionListByRegionByGroup = (permissionList: PermissionInfo[], assignedPermissionList: AssignedPermissionDto[]) => {
  let mergedPermissionList = createDefaultAssignedPermissionList(permissionList);

  if (mergedPermissionList.length > 0 && assignedPermissionList.length > 0) {
    assignedPermissionList.forEach(p => {
      const updateIndex = mergedPermissionList.findIndex(
        d => d.permission.url!.toUpperCase() === p.permission!.url!.toUpperCase()
      );
      if (updateIndex > -1) {
        mergedPermissionList[updateIndex] = {
          name: '',
          ...p,
          permission: mergedPermissionList[updateIndex].permission,
        };
      }
    });
    mergedPermissionList = sortPermissionName(mergedPermissionList);
  }
  return mergedPermissionList;
};

/**
 * Converts a permission access level string to a number.
 * @param access The access level of the permission.
 * @returns a number representing the permission level.
 */
export const getAccessNum = (access: string) => {
  switch (access.toUpperCase()) {
    case PermissionAccess.Edit.toUpperCase():
      return 2;
    case PermissionAccess.View.toUpperCase():
      return 1;
    case PermissionAccess.Deny.toUpperCase():
      return 0;
    default:
      return -1;
  }
};

/**
 * Creates a list of denied permissions, which are missing from any group.
 * @param groups The list of groups.
 * @param assignedPermissionList All the permissions that exist in all the groups.
 * @returns A list of the missing denied permissions.
 * @description If a permission is set to DENY in a group, that key is just missing from that group.
 */
const deniedPermissions = (groups: GroupDto[], assignedPermissionList: AssignedPermissionDto[]): AssignedPermissionDto[] => {
  // if a permission is missing from a group, it is DENY in that group - but it's
  // difficult to compare to a permission that isn't there, so we create them.

  const deniedPermissions = [] as AssignedPermissionDto[];

  assignedPermissionList.forEach(p => {
    groups.forEach(group => {
      group.assignedPermissions = group.assignedPermissions ?? []; // ensure it's defined

      // get all the permission.url's for this group
      const groupPermissionUrls = new Set(group.assignedPermissions
        .map(ap => ap.permission?.url ?? '')
        .filter(url => url !== ''));

      // if this permission is not in the group, it's DENY in this group
      if (!groupPermissionUrls.has(p.permission!.url!)) {
        // so add the DENY version to the deniedPermissions collection
        deniedPermissions.push({
          permission: p.permission,
          group: { url: group.url },
          access: PermissionAccess.Deny,
          region: p.region,
        } as AssignedPermissionDto);
      }
    });
  });

  return deniedPermissions;
};

export const getPermissionsGroupsAndAccess = (groups: GroupDto[]) => {
  // note: a permission of DENY is just left out of the group's collection.
  // e.g. the "Deny All" group has an empty assignedPermissions collection.

  if (!groups || groups.length === 0) { return {}; }

  let assignedPermissionsList = groups
    .flatMap(group => group.assignedPermissions || []);

  assignedPermissionsList = [
    ...assignedPermissionsList,
    // add the missing DENY permissions to the collection
    ...deniedPermissions(groups, assignedPermissionsList)
  ];

  const groupObj = groups.reduce((acc, g) => {
    acc[g.url!] = {
      id: g.id,
      name: g.name!,
      url: g.url!,
      archived: false,
      deleted: false,
    };
    return acc;
  }, {} as GroupDtoByGroupUrlMap);

  const userPermissionTableDraft: UserPermissionRowByPermissionUrlMap = {};

  assignedPermissionsList.forEach(ap => {
    const permissionUrl = ap.permission!.url!;
    const access = ap.access!.toUpperCase();
    const groupUrl = ap.group!.url!;

    if (!userPermissionTableDraft[permissionUrl]
      || getAccessNum(userPermissionTableDraft[permissionUrl].access) < getAccessNum(access)) {
      // if the access in the table is lower than this one, replace it
      userPermissionTableDraft[permissionUrl] = {
        permission: null,
        permissionUrl,
        access,
        inheritance: [groupObj[groupUrl]],
      };
    } else if (userPermissionTableDraft[permissionUrl].access.toUpperCase() === access) {
      // if the access in the table is the same as this one, add it to the inheritance list
      const inheritance = userPermissionTableDraft[permissionUrl].inheritance as GroupDto[];
      const groupToAdd = groupObj[groupUrl];
      if (!inheritance.includes(groupToAdd)) {
        inheritance.push(groupToAdd);
      }
    }
  });

  return userPermissionTableDraft;
};

export const createDefaultUserPermissionsTable = (permissionList: PermissionInfo[], userGroups: GroupDto[]) => {
  let userPermissionList = null;
  if (userGroups
    && permissionList
    && userGroups.length > 0
    && permissionList.length > 0) {
    const draft = getPermissionsGroupsAndAccess(userGroups);
    userPermissionList = permissionList.map(p => {
      const url = p.url!;
      const name = p.name!;
      const row = draft[url];

      if (row) {
        row.permission = name;
        if (row.access.toUpperCase() === PermissionAccess.Deny.toUpperCase()) {
          row.inheritance = 'All Groups';
        }
      } else {
        draft[url] = {
          permission: name,
          permissionUrl: url,
          access: PermissionAccess.Deny.toUpperCase(),
          inheritance: 'Default Permission',
        };
      }

      // Permission Reports only show access DENY or EDIT only.
      if (draft[url].permission === PERMISSION_NAMES.REPORTS
        && draft[url].access.toUpperCase() === PermissionAccess.View.toUpperCase()) {
        draft[url].access = PermissionAccess.Deny.toUpperCase();
      }

      return draft[url];
    });

    userPermissionList.sort((p1, p2) => p1.permission!.localeCompare(p2.permission!));
  }
  return userPermissionList;
};

export const sortUserGroups = <T extends HasName>(groupArr: T[]) => {
  if (!groupArr) return null;
  groupArr.sort((g1, g2) => g1.name!.localeCompare(g2.name!));
  return groupArr;
};

export const sortPermissionAccessLowToHigh = (
  assignedPermissions: AssignedPermissionDto[]
) => assignedPermissions.sort((ap1, ap2) => {
  if (getAccessNum(ap1.access!) < getAccessNum(ap2.access!)) {
    return -1;
  }
  if (getAccessNum(ap1.access!) >= getAccessNum(ap2.access!)) {
    return 1;
  }
  return 0;
});

export const refreshPermissions = async (dispatch: Dispatch | null, userUrl: string) => {
  if (dispatch) {
    await dispatch<any>(fetchPermissionsListByUserUrl(userUrl));
  } else {
    await fetchPermissionsListByUserUrl(userUrl);
  }
};
