/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

import { TicketDto, VoidFunc } from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import { connect } from 'react-redux';
import { VoidTicketDto } from '@trucktrax/trucktrax-ts-common/build/Dtos/Tickets/VoidTicketDto';
import { Button, DropDownList } from '@trucktrax/trucktrax-common';
import styles from './ScaleTraxTicket.module.css';
import TicketDetails from '../../shared/tickets/details/TicketDetails';
import { updateTicketView, selectTicket, clearSelectedTicket } from '../../../store/actions/ticketActions';
import { openSnackbar } from '../../../store/actions/snackbarActions';
import { voidTicket } from '../../../services/integrationService';
import {
  CANCEL_LABEL,
  DEFAULT,
  ERROR,
  PRINT_LABEL,
  PRINT_TICKET_TITLE,
  SUCCESS,
  VIEW_TICKET_DETAILS,
} from '../../../constants/appConstants';

import ScaleTraxFooter from './Footer';
import { openModal, setDisabledState as setModalDisabledState, closeModal } from '../../../store/actions/errorModalActions';
import { ModalOptions } from '../../../types';
import LoadingCircle from '../../shared/LoadingCircle';
import { getPrinterList, getTicketForPrint, printTicket } from '../../../services/printService';
import { fetchOrder } from '../../../services/ordersService';
import { getIdFromUrl } from '../../../util/appUtil';
import printerBlue from '../../../assets/img/printer-blue.svg';
import { fetchPlant } from '../../../services/plantsService';

type ScaleTraxTicketState = {
  voidTicketInfo?: VoidTicketDto,
  printCount: number;
  currentPrinter?: any;
  printerList?: any;
};

export class ScaleTraxTicket extends Component<ScaleTraxTicketProps, ScaleTraxTicketState> {
  static defaultProps = {
    selectedTicket: undefined,
    ticketView: VIEW_TICKET_DETAILS,
    selectedProductLine: null,
  };

  constructor(props: ScaleTraxTicketProps) {
    super(props);

    this.state = {
      currentPrinter: undefined,
      printCount: 1,
    };
  }

  async componentDidMount() {
    const plant = await fetchPlant(Number(getIdFromUrl(this.props.selectedTicket?.plant!.url)));
    const printerList = await getPrinterList(plant?.scaleHostAddress);

    this.setState({ printerList });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // they chose a printer or changed the number of copies
    if (prevState.currentPrinter !== this.state.currentPrinter
      || (prevState.printCount !== this.state.printCount)) {
      this.reprintTicketModal();
    }
  }

  static validateVoidInfo = (voidTicketInfo?: VoidTicketDto) => {
    if (!voidTicketInfo) {
      return false;
    }

    return !!voidTicketInfo?.disposalMethodExternalId && !!voidTicketInfo?.voidReasonExternalId && !!voidTicketInfo?.comment;
  };

  openTicketList = () => {
    this.props.clearSelectedTicket();
  };

  renderPrintButton = () => (
    <button className={styles.printButton} disabled={false} onClick={() => this.reprintTicketModal()}>
      <img src={printerBlue} alt="print" className={styles.printIconStyle} />
      <span className={styles.printButtonText}>Reprint</span>
    </button>
  );

  incrementPrintCount(amount: number) {
    let { printCount } = this.state;
    printCount += amount;
    if (printCount > 0 && printCount < 6) this.setState({ printCount });
  }

  updateInputPrinter = (item?: any) => {
    if (item && item.value.url) {
      this.setState({
        currentPrinter: item,
      });
    }
  };

  reprintTicketModal() {
    const modalBody = (
      <span>
        <div className={styles.scaleContainer}>
          {this.renderPrintersAndCopies()}
        </div>
      </span>
    );
    this.props.openModal({
      acceptDialog: this.performPrint,
      modalType: DEFAULT,
      modalTitle: PRINT_TICKET_TITLE,
      modalBody,
      modalOpen: true,
      acceptText: PRINT_LABEL,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  }

  performPrint = async () => {
    const ticket = this.props.selectedTicket!;
    const order = await fetchOrder(Number(getIdFromUrl(ticket?.order!.url)));

    const { printCount } = this.state;
    if (!ticket || !order || !printCount) {
      return;
    }

    const modalBody = (
      <div className={cx(styles.docContainer, styles.loading)}>
        <div className={cx(styles.loadingText)}>Printing Ticket</div>
        <div className={cx(styles.spinner)}><LoadingCircle size={88} /></div>
      </div>
    );

    const modal: ModalOptions = {
      modalTitle: undefined,
      modalType: 'DEFAULT',
      modalBody,
      modalOpen: true,
      noActions: true,
    };

    this.props.closeModal();

    // the printing ticket spinner
    this.props.openModal(modal);

    const printableTicket = await getTicketForPrint(ticket, order, this.props?.currentUser);
    const plant = await fetchPlant(Number(getIdFromUrl(ticket.plant!.url)));

    // currently always passing empty string, PrintHost will use the default printer for the plant
    const response = await printTicket(printableTicket, '', printCount, plant!.url!, false);

    let msg = `${this.state.printCount} copies of ticket ${ticket.ticketNumber} sent to the printer.`;

    // close the spinner
    this.props.closeModal();

    if (response.status !== 200) {
      msg = 'Print failed. Ticket was not printed.';
      this.props.openSnackbar({
        snackbarBody: msg,
        snackbarType: ERROR,
      });
      return;
    }
    this.props.openSnackbar({
      snackbarBody: msg,
      snackbarType: SUCCESS,
      manualClose: false,
    });
  };

  renderPrintersAndCopies() {
    let { currentPrinter, printerList } = this.state;
    const { printCount } = this.state;

    printerList = printerList && printerList.length > 0 ? printerList.map(((item: { printerName: any; }) => ({
      label: item.printerName,
      text: item.printerName,
      value: { url: item.printerName },
    }))) : [];

    const selectAPrinter = {
      label: 'Select a printer',
      text: 'Select a printer',
      value: { url: undefined },
    };

    if (currentPrinter === undefined) currentPrinter = selectAPrinter;
    const initial = printerList.find((i: { text: any; }) => i.text === currentPrinter.text);

    return (
      <div key="print-section" className={styles.printSection}>
        <div>
          <div className={styles.label} style={{ display: 'flex' }}>
            <label htmlFor="printerSelectionDDL" className={styles.label}>Printer </label>
            <span className={styles.asterisk}>*</span>
          </div>
          <div id="printerSelectionDDL" className={cx(styles.formField)}>
            <DropDownList
              items={printerList}
              initialSelected={initial ?? {
                name: 'Select a printer',
                label: 'Select a printer',
              }}
              updateSelected={this.updateInputPrinter}
              data-test="permission-drop-down-menu"
            />
          </div>
        </div>
        <div className={styles.label}>
          Copies
        </div>
        <div className={styles.printQuantityDiv}>
          <Button
            onClick={() => { this.incrementPrintCount(-1); }}
            buttonClassName={styles.addQtyBtn}
            iconClassName="icon-remove"
            dataTest="edit-feature-flag-list-item"
          />
          <div className={styles.printCount}>
            {printCount}
          </div>
          <Button
            onClick={() => { this.incrementPrintCount(1); }}
            buttonClassName={styles.addQtyBtn}
            iconClassName="icon-add"
            dataTest="edit-feature-flag-list-item"
          />
        </div>
      </div>
    );
  }

  renderBody = () => {
    const {
      selectedTicket, history,
    } = this.props;

    return (
      <div className={styles.bodyWrapper}>
        <div className={styles.cardWrapper}>
          <TicketDetails
            selectedTicket={selectedTicket!}
            scaleTrax
            plantList={[]}
            history={history}
          />
        </div>
      </div>
    );
  };

  render() {
    const { selectedTicket } = this.props;
    if (!selectedTicket?.id) {
      return (<></>);
    }

    return (
      <>
        <div id="ticketPopover-paperElement" className={styles.paper}>
          <div className={styles.detailHeader}>
            <div>
              <div className={styles.ticketNumber}>
                Ticket #
                {selectedTicket?.ticketNumber}
              </div>
              <div className={styles.goBack}>
                <span
                  onClick={this.openTicketList}
                  data-test="datablock-breadcrumb-link"
                  role="button"
                  aria-hidden
                >
                  <i
                    className={cx(
                      styles.backArrow,
                      'icon-arrow-back',
                      'icon-smallest'
                    )}
                  />

                  <span className={styles.goBackText}>Go Back</span>
                </span>
              </div>
            </div>
            <div className={styles.buttons}>
              {this.renderPrintButton()}
            </div>
          </div>
          {this.renderBody()}
        </div>
        <div><ScaleTraxFooter /></div>
      </>
    );
  }
}

export interface ScaleTraxTicketProps {
  selectedTicket?: TicketDto,
  history: {
    push: (path: string) => void
  },
  clearSelectedTicket: VoidFunc,
  openModal: typeof openModal,
  closeModal: typeof closeModal,
  openSnackbar: typeof openSnackbar,
  currentUser?: any
}

export function mapStateToProps(state: any) {
  return {
    selectedProductLine: state.selectedProductLine,
    selectedTicket: state.selectedTicket,
    ticketView: state.ticketView,
    currentUser: state.userUrl,
  };
}

export default connect<any, any, any>(mapStateToProps, {
  updateTicketView,
  selectTicket,
  clearSelectedTicket,
  openModal,
  closeModal,
  openSnackbar,
  setModalDisabledState,
  voidTicket,
})(ScaleTraxTicket as any);
