import React, { Component } from 'react';
import { HeaderAndContainer, DateWrapper } from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import { UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import Header from '../../../shared/Header';
import NotificationCard from './NotificationCard';
import styles from './NotificationsView.module.css';
import { devErrorAndLog } from '../../../../util/errorUtil';
import { ERROR_TEXT_FETCH_NOTIFICATION_LIST } from '../../../../constants/errorConstants';
import {
  EMPTY_NOTIFICATIONS,
  NOTIFICATIONS_CONFIG,
} from '../../../../constants/appConstants';
import { getIdFromUrl } from '../../../../util/appUtil';
import { getNotificationsList } from '../../../../services/notificationsService';
import DateRangePicker, { DateRangePickerState } from '../../../shared/DateRangePicker';
import EmptyView from '../../../shared/EmptyView';
import { ReduxState } from '../../../../store';
import { ConnectedDispatchFunction } from '../../../../types';
import HTTP_CODES from '../../../../constants/httpConstants';

export class NotificationsView extends Component<NotificationsViewProps> {
  state = {
    notifications: [],
    startDate: DateWrapper.now.startOfDay.date,
    endDate: DateWrapper.now.startOfDay.date,
  };

  async componentDidMount() {
    const { startDate, endDate } = this.state;
    await this.fetchNotificationList(startDate.toString(), endDate.toString());
  }

  async fetchNotificationList(startDate: string, endDate: string) {
    const { url } = this.props.currentRegion;
    try {
      const notifications = await getNotificationsList(url, startDate, endDate);
      this.setState({ notifications });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      this.props.devErrorAndLog(
        ERROR_TEXT_FETCH_NOTIFICATION_LIST,
        `NotificationsView: ${e.toString()}`,
        undefined,
        undefined,
        consoleOnly
      );
    }
  }

  async componentDidUpdate(prevProps: NotificationsViewProps) {
    const { currentRegion } = this.props;
    if (prevProps.currentRegion && currentRegion && prevProps.currentRegion.url !== currentRegion.url) {
      const { startDate, endDate } = this.state;
      await this.fetchNotificationList(startDate.toString(), endDate.toString());
    }
  }

  onDateRangeChange = async (drpState: DateRangePickerState) => {
    const { startDate, endDate } = drpState;
    if (startDate && endDate) {
      await this.fetchNotificationList(startDate.toString(), endDate.toString());
    }
  };

  todaysDate = DateWrapper.now.startOfDay;

  minDate = this.todaysDate.toUSDateString();

  maxDate = this.todaysDate.addDays(7).toUSDateString();

  render() {
    const elementLeft = (
      <Header
        title="Notifications Feed"
      />
    );

    const elementRight = (
      <DateRangePicker
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        shouldDisableFuture={false}
        onChange={this.onDateRangeChange}
        minDate={this.minDate}
        maxDate={this.maxDate}
      />
    );

    let containerComponent;

    if (!this.state.notifications.length) {
      containerComponent = (
        <EmptyView
          icon="icon-notifications-off"
          text={EMPTY_NOTIFICATIONS}
        />
      );
    } else {
      containerComponent = (
        <div className={styles.wrapper}>
          <div className={styles.list}>
            {
              this.state.notifications.map(NotificationsView.getNotification)
            }
          </div>
        </div>
      );
    }

    return (
      <HeaderAndContainer
        elementsLeft={elementLeft}
        elementsRight={elementRight}
        containerComponent={containerComponent}
        containerStyle={styles.headerAndContainer}
      />
    );
  }

  static getNotification = (notificationDto: any) => {
    const ntype: keyof typeof NOTIFICATIONS_CONFIG = notificationDto.type;

    const {
      message,
      order,
      orderStatus,
    } = notificationDto;
    const config = NOTIFICATIONS_CONFIG[ntype];
    const title = config ? config.title : ntype;
    const icon = config ? config.icon : '';
    const link = order && order.url && `/geotrax/orders/all/${getIdFromUrl(order.url)}`;

    return (
      <NotificationCard
        key={link}
        icon={icon}
        title={title}
        message={message}
        link={link}
        status={orderStatus}
      />
    );
  };
}

export interface NotificationsViewProps {
  devErrorAndLog: ConnectedDispatchFunction<typeof devErrorAndLog>,
  currentRegion: UrlKeyDto
}

const mapStateToProps = (state: ReduxState) => ({
  currentRegion: state.currentRegion,
});

export default connect(mapStateToProps, {
  devErrorAndLog,
})(NotificationsView);
