import { PayloadAction } from '../../types';
import { SET_RELEASENOTES_DOCS_LIST } from '../../constants/actionConstants';

const releaseDocsReducer = (
  state = [] as string[],
  action: PayloadAction<string[], 'SET_RELEASENOTES_DOCS_LIST'>
): string[] => {
  switch (action.type) {
    case SET_RELEASENOTES_DOCS_LIST:
      return action.payload;
    default:
      return state;
  }
};
export default releaseDocsReducer;
