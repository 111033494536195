import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { ProductLine } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { getRequest, postRequest, putRequest } from './requestService';
import { devErrorAndLog } from '../util/errorUtil';
import { openSnackbar } from '../store/actions/snackbarActions';
import { addItemToAdminTableItems, setAdminRecord, setDataTableNeedsRefresh } from '../store/actions/dataTableActions';
import { FETCH_TABLE_DATA, TABLE_IS_LOADING, UPDATE_COMBINED_DATASOURCES } from '../constants/actionConstants';
import { ADMIN_UPDATE_ERROR, ERROR_TEXT_FETCH_ADMIN_TABLE, ERROR_TEXT_FETCH_ADMIN_TABLE_FOR_EXPORT } from '../constants/errorConstants';
import {
  CANCEL_API_DUE_TO_PAGE_CHANGE,
  DEFAULT_PAGE_SIZE,
  ERROR,
  SUCCESS,
} from '../constants/appConstants';
import { ADMIN_UPDATE_SUCCESS_TOAST_TEXT } from '../constants/successConstants';
import { store } from '../Main';

type FetchDataTableSourceState = {
  selectedProductLine: ProductLine;
  dataTableSearch: {
    searchTerm: string;
  };
  dataTableDateRange?: {
    dateRange?: {
      startDate: string;
      endDate: string;
    };
  };
  dataTableStatusFilter?: {
    statusFilter?: string;
  };
};

type FetchDataTableDataState = () => {
  dataSources: { [key: string]: FetchDataTableSourceState };
  selectedProductLine: ProductLine;
  dataTableSearch: {
    searchTerm: string;
  };
  dataTableDateRange?: {
    dateRange?: {
      startDate: string;
      endDate: string;
    };
  };
  dataTableStatusFilter?: {
    statusFilter?: string;
  };
  source?: string;
};
export type SortData = {
  id?: string | number,
  desc?: string
};

// different API endpoints might have slightly different naming conventions, e.g. deleted vs isDeleted
type FetchDataTableDataParamsType = {
  page: number;
  size: number;
  startDate?: string;
  endDate?: string;
  statusFilter?: string;
  productLine?: string;
  sort?: string;
  searchTerm: string;
  byPassRedisCache?: boolean;
  deleted?: boolean;
  isDeleted?: boolean;
  archived?: boolean;
  isArchived?: boolean;
  source: string;
};

type CreateDataTableRecordState = () => { tenantId: string };

export type ToastMessagesType = {
  success: string,
  fail: string
};
export type ErrorMap = { [key: string]: any };

/**
 * Add the filter by region depending on the type of request
 * @param {FetchDataTableDataParamsType} params current params
 * @param {bool} isRegionUrl flag to determinate if the region param name is regionUrl
 * @param {string} region region value
 * @returns {FetchDataTableDataParamsType} new params with the region filter
 */
function filterByRegion(params: FetchDataTableDataParamsType, isRegionUrl: boolean, region: string): FetchDataTableDataParamsType {
  if (isRegionUrl) {
    return Object.assign(params, { regionUrl: region });
  }
  return Object.assign(params, { region });
}

export function fetchDataTableData(
  url: string,
  region: string | null | undefined,
  size: number,
  page: number,
  sort: SortData[],
  cancelToken = null,
  isArchived = false,
  queryParams = {},
  byPassRedisCache: boolean = false,
  backendTicket: boolean | undefined = false,
  source: string | undefined = undefined,
  columns: any[] = []
) {
  return async (dispatch: Dispatch<any>, getState: FetchDataTableDataState) => {
    dispatch({ type: TABLE_IS_LOADING, payload: { source } });
    dispatch(setDataTableNeedsRefresh(false));

    const params = buildDataTableParams(
      url,
      region,
      sort,
      isArchived,
      queryParams,
      getState,
      page,
      size,
      source
    );

    if (byPassRedisCache) {
      params.byPassRedisCache = byPassRedisCache;
    }

    try {
      const response = await getRequest(url, params, cancelToken);
      const { data } = response;
      if (Array.isArray(response.data)) {
        response.data = paginateFlattenedResponse(response.data, params);
      }
      data.baseUrl = url;
      response.data.extras = buildCombinedDataSourcePayload(
        getState,
        columns,
        source
      );
      dispatch({
        type: FETCH_TABLE_DATA,
        payload: response.data,
      });
      dispatch({
        type: UPDATE_COMBINED_DATASOURCES,
        payload: response.data,
      });
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        devErrorAndLog(
          ERROR_TEXT_FETCH_ADMIN_TABLE,
          `datatableservice fetchDataTableData: url: ${url} + params: + ${JSON.stringify(params)}`,
          e.toString()
        );
      }
    }
  };
}

export function fetchDataTableDataForExport(
  url: string,
  region: string | null | undefined,
  sort: SortData[],
  isArchived = false,
  queryParams = {},
  byPassRedisCache: boolean = false
) {
  return async (dispatch: Dispatch<any>, getState: FetchDataTableDataState): Promise<any> => {
    const pageSizeForExport = 5000;

    const params = buildDataTableParams(
      url,
      region,
      sort,
      isArchived,
      queryParams,
      getState,
      1, // Always the first page for exports
      pageSizeForExport,
      undefined
    );

    if (byPassRedisCache) {
      params.byPassRedisCache = byPassRedisCache;
    }

    try {
      const response = await getRequest(url, params);
      return response.data;
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        devErrorAndLog(
          ERROR_TEXT_FETCH_ADMIN_TABLE_FOR_EXPORT,
          `datatableservice fetchDataTableDataForExport: url: ${url} + params: + ${JSON.stringify(params)}`,
          e.toString()
        );
      }
      return undefined;
    }
  };
}

function buildDataTableParams(
  url: string,
  region: string | null | undefined,
  sort: SortData[],
  isArchived: boolean,
  queryParams: any,
  getState: FetchDataTableDataState,
  page: number,
  size: number,
  source: string | undefined // TODO: MEKELL Come back here fix export table data download this will break it
): FetchDataTableDataParamsType {
  let params: FetchDataTableDataParamsType = {
    page,
    size,
    ...queryParams,
  };

  const stateSnapshot = getState();
  const sourceData = stateSnapshot?.dataSources && source ? stateSnapshot.dataSources[source!] : getState();
  const isTrucks = url.includes('trucks');
  const isOrders = url.includes('orders');
  const isHaulers = url.includes('haulers');

  if (isTrucks) {
    params = { ...params, deleted: false, archived: isArchived };
  } else {
    params = { ...params, isDeleted: false, isArchived };
  }

  if (region) {
    params = filterByRegion(params, isTrucks || isHaulers, region);
  }

  if (isOrders && !isTrucks) {
    const { selectedProductLine } = sourceData;
    params = { ...params, productLine: selectedProductLine };
  }

  if (sort && sort.length) {
    params = { ...params, sort: `${sort[0].id},${sort[0].desc ? 'desc' : 'asc'}` };
  }

  const { dataTableSearch, dataTableDateRange, dataTableStatusFilter } = sourceData;
  const { searchTerm } = dataTableSearch;
  if (searchTerm && searchTerm.length) {
    params = { ...params, searchTerm };
  }

  const { dateRange } = dataTableDateRange || {};
  // ticketList supplies the start and end date params via filterDateStart and filterDateEnd
  // from the ticketListFilters state
  if (dateRange && source !== 'ticketList') {
    params = {
      ...params,
      startDate: new DateWrapper(dateRange.startDate).startOfDay.toISOString(),
      endDate: new DateWrapper(dateRange.endDate).endOfDay.toISOString(),
    };
  }

  const statusFilter = dataTableStatusFilter?.statusFilter;
  if (statusFilter) {
    params = { ...params, statusFilter };
  }

  return params;
}

function handleExceptions(
  dispatch: Dispatch<any>,
  dataTest: string,
  error?: any,
  toastMessages?: ToastMessagesType,
  callback?: (errorMap: ErrorMap) => void
) {
  const statusCode = error?.response?.data?.status;
  const is400 = statusCode === 409 || statusCode === 400;
  const hasErrors = (error?.response?.data?.errors?.length || 0) > 0;
  const errorMap: ErrorMap = {};

  if (is400 && hasErrors) {
    const { errors } = error.response.data;
    errors.forEach((e: any) => {
      if (errorMap[e.field]) {
        errorMap[e.field].push(e.message);
      } else {
        errorMap[e.field] = [e.message];
      }
    });
  } else {
    const snackbarBody = toastMessages?.fail || ADMIN_UPDATE_ERROR;
    dispatch(openSnackbar({
      snackbarBody,
      dataTest,
      snackbarType: ERROR,
    }));
  }

  if (callback) {
    callback(errorMap);
  }
}

export function createDataTableRecord(
  url: string,
  dto: any,
  successCallback?: (data: any) => void,
  enableButtonCallback?: (errorMap: ErrorMap) => void,
  toastMessages?: ToastMessagesType,
  addToCurrentRegion?: boolean,
  currentRegionUrl?: string
) {
  return async (dispatch: Dispatch<any>, getState: CreateDataTableRecordState) => {
    const { tenantId } = getState();
    const body = Object.assign(dto, { tenant: { url: tenantId } });
    const params = currentRegionUrl ? { region: currentRegionUrl } : {};

    try {
      const response = await postRequest(url, body, params);

      if (toastMessages?.success) {
        dispatch(openSnackbar({
          snackbarBody: toastMessages.success,
          dataTest: 'device-actions-createDevice-success-snackbar',
          snackbarType: SUCCESS,
        }));
      }

      dispatch(setDataTableNeedsRefresh(true));

      // Re-enables 'send' buttons
      if (successCallback) {
        successCallback(response.data);
      }

      // adds device to table if the new device url is the same as the currently viewed region
      if (addToCurrentRegion) {
        dispatch(addItemToAdminTableItems(response.data));
      }
    } catch (error) {
      handleExceptions(
        dispatch,
        'device-actions-createDevice-error-snackbar',
        error,
        toastMessages,
        enableButtonCallback
      );
    }
  };
}

export function updateDataTableRecord(
  url: string,
  body: any,
  onSuccess?: (response: AxiosResponse<any>) => void,
  onError?: (errorMap: ErrorMap) => void,
  hideSnackbar?: boolean,
  currentRegionUrl?: string,
  toastMessages?: ToastMessagesType
) {
  return async (dispatch: Dispatch) => {
    try {
      const params = currentRegionUrl ? { region: currentRegionUrl } : {};
      const response = await putRequest(url, body, params);

      if (!hideSnackbar) {
        const snackbarBody = toastMessages && toastMessages.success
          ? toastMessages.success
          : ADMIN_UPDATE_SUCCESS_TOAST_TEXT;
        dispatch(openSnackbar({
          snackbarBody,
          dataTest: 'device-actions-updateDevice-success-snackbar',
          snackbarType: SUCCESS,
        }));
      }

      if (onSuccess) {
        onSuccess(response);
      }
      // set updated record
      dispatch(setAdminRecord(response.data));
    } catch (error) {
      handleExceptions(
        dispatch,
        'update-dto-error-snackbar',
        error,
        toastMessages,
        onError
      );
    }
  };
}

/**
 * This is a workaround to simulate a naive frontend pagination (specifically for Regions) which
 * converts non-paginated (or flattened) responses to an object that conforms to our paginated data.
 *
 * *This is intended as a temporary workaround since all our backend
 * endpoints should/will be returning responses with only paginated data*
 * @deprecated Remove this after {@link https://trucktrax.atlassian.net/browse/DEV-2349 DEV-2349} has been completed
 * @param data the response data that will be the items
 * @param params the params containing the expected pagination settings
 * @returns an object that conforms to our paginated response data
 */
function paginateFlattenedResponse(
  data: any[],
  params: FetchDataTableDataParamsType
) {
  const numberOfItems = data?.length ?? 1;
  const pageCount = Math.ceil(numberOfItems / DEFAULT_PAGE_SIZE);
  return {
    items: data,
    pageCount: pageCount === 0 ? 1 : pageCount,
    pageSize: params.size ?? DEFAULT_PAGE_SIZE,
    pageStart: params.page ?? 1,
    responseConvertedToPagination: true
  };
}

function buildCombinedDataSourcePayload(
  getState: FetchDataTableDataState,
  columns: any[],
  source: string | undefined
) {
  const newState = getState();
  const ticketListDateRangeOverride: { startDate: string; endDate: string } = {
    startDate: '',
    endDate: '',
  };

  // ticketList stores the filterDate state for start and end date in the stores ticketList state
  if (source === 'ticketList') {
    const storeState = store.getState();
    ticketListDateRangeOverride.startDate = storeState.ticketListFilters?.filterDateStart.toString() ?? '';
    ticketListDateRangeOverride.endDate = storeState.ticketListFilters?.filterDateEnd.toString() ?? '';
  }

  return {
    selectedProductLine: newState.selectedProductLine,
    dataTableSearch: newState.dataTableSearch,
    dataTableDateRange: ticketListDateRangeOverride || newState.dataTableDateRange,
    dataTableStatusFilter: newState.dataTableStatusFilter,
    source,
    columns,
  };
}
