/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { TTInput } from '@trucktrax/trucktrax-common';
import cx from 'classnames';
import { getEventFromTarget, isUndefined } from '../../../util/adminUtil';
import { INPUT_TYPE_TEXT } from '../../../constants/appConstants';
import styles from './CardNumericInput.module.css';

export default class CardNumericInput extends Component<CardNumericInputProps> {
  static defaultProps: Partial<CardNumericInputProps> = {
    value: '',
    className: '',
    dataTest: '',
    placeholder: '',
    errorDataTest: '',
    isRequired: false,
    allowDecimal: false,
    label: '',
    id: '',
    maxLength: 20,
    errorMessage: '',
    disabled: false,
    hideCharacterCount: false,
    fractionalDigits: 2,
    increment: undefined,
    errorWhileDisabled: false,
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = this.props;
    let { value } = e.target;
    const showIncrementButtons = this.props.increment?.showIncrementButtons ?? false;

    if (showIncrementButtons) {
      const numericValue = this.limitNumericValue(Number(value));
      value = numericValue.toString();
    }

    this.props.onChange({ [id!]: value });
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.props.onTouch(e, this.props.id!);
  };

  limitNumericValue = (value: number) => {
    const minValue = this.props.increment?.minValue ?? 0;
    const maxValue = this.props.increment?.maxValue ?? 0;

    if (value < minValue) {
      return minValue;
    }

    if (value > maxValue) {
      return maxValue;
    }

    return value;
  };

  incrementButtonClicked = (incrementStep: number) => {
    const { id, value } = this.props;

    let numericValue = Number(value);
    numericValue += incrementStep;
    numericValue = this.limitNumericValue(numericValue);
    this.props.onChange({ [id!]: `${numericValue}` });
  };

  renderMinusButton = () => {
    const value = Number(this.props.value);
    const minValue = this.props.increment?.minValue ?? 0;

    const incrementStep = -this.props.increment!.incrementStep;
    const buttonStyles = cx('tt-btn-secondary', styles.minusButton);

    return (
      <button
        className={buttonStyles}
        onClick={() => this.incrementButtonClicked(incrementStep)}
        disabled={value <= minValue}
      >
        <i className="icon-remove" />
      </button>
    );
  };

  renderPlusButton = () => {
    const value = Number(this.props.value);
    const maxValue = this.props.increment?.maxValue ?? 0;

    const { incrementStep } = this.props.increment!;
    const buttonStyles = cx('tt-btn-secondary', styles.plusButton);

    return (
      <button
        className={buttonStyles}
        onClick={() => this.incrementButtonClicked(incrementStep)}
        disabled={value >= maxValue}
      >
        <i className="icon-add" />
      </button>
    );
  };

  static numberWithCommas = (num: string | number) => num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

  numericValue = (value?: string) => {
    if (Number.isNaN(value)) {
      return '';
    }

    // Some scales don't do decimals, so they get rounded up.
    if (!Number.isNaN(value) && this.props.fractionalDigits === 0) {
      return value === '' ? '' : `${parseFloat(value ?? '0.00')}`;
    }

    const { thousandSeparator } = this.props;
    if (thousandSeparator) {
      const withCommas = CardNumericInput.numberWithCommas(value!);
      if (withCommas.includes('.')) {
        const parts = withCommas.split('.');
        const whole = parts[0];
        const decimal = parts[1].substring(0, this.props.fractionalDigits);
        return `${whole}.${decimal}`;
      }
      return withCommas;
    }

    return value!.toString();
  };

  renderNumericInput = () => {
    const {
      isRequired,
      value,
      label,
      maxLength,
      dataTest,
      placeholder,
      errorDataTest,
      disabled,
      allowDecimal,
      hideCharacterCount,
      errorMessage,
      increment,
    } = this.props;

    const showIncrementButtons = increment?.showIncrementButtons ?? false;
    const containerClassName = showIncrementButtons ? styles.numericInputContainer : undefined;

    return (
      <div className={containerClassName}>
        {showIncrementButtons && this.renderMinusButton()}
        <TTInput
          id={label!}
          maxLength={maxLength}
          value={this.numericValue(value)}
          onChangeCallback={this.onChange}
          onBlurCallback={this.onBlur}
          dataTest={dataTest!}
          placeholder={placeholder}
          errorDataTest={errorDataTest!}
          disabled={disabled}
          isRequired={isRequired}
          type={INPUT_TYPE_TEXT}
          allowDecimal={allowDecimal}
          errorWhileDisabled={this.props.errorWhileDisabled}
          isNumberInput
          hideCharacterCount={hideCharacterCount && !errorMessage}
          error={!!errorMessage}
          errorMessage={errorMessage ? [errorMessage] : []}
        />
        {showIncrementButtons && this.renderPlusButton()}
      </div>
    );
  };

  render() {
    const {
      value,
      className,
    } = this.props;

    return (
      <div className={className}>
        {
          isUndefined(value)
            ? <div className="skeleton" />
            : this.renderNumericInput()
        }
      </div>
    );
  }
}

interface IncrementType {
  showIncrementButtons: boolean,
  incrementStep: number,
  minValue: number,
  maxValue: number
}

export interface CardNumericInputProps {
  onTouch: (e: React.FocusEvent<HTMLInputElement>, id: string) => void;
  onChange: (e: ReturnType<typeof getEventFromTarget>) => void;
  className?: string;
  value?: string;
  id?: string;
  label?: string;
  dataTest?: string;
  placeholder?: string;
  errorDataTest?: string;
  isRequired?: boolean;
  disabled?: boolean;
  allowDecimal?: boolean;
  maxLength?: number;
  hideCharacterCount?: boolean;
  errorMessage?: string;
  fractionalDigits?: number;
  increment?: IncrementType;
  thousandSeparator?: boolean;
  errorWhileDisabled?: boolean;
}
