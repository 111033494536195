import jwtDecode from 'jwt-decode';
import { PRIMARY_PRODUCT_LINE, SECONDARY_PRODUCT_LINE, SECONDARY_PRODUCT_LINES } from '../constants/appConstants';

export function getPrimaryProductLineFromToken(token: string) {
  try {
    const { primaryProductLine } = jwtDecode(token);
    localStorage.setItem(PRIMARY_PRODUCT_LINE, primaryProductLine);
    return primaryProductLine;
  } catch (e) {
    return null;
  }
}

export function getSecondaryProductLineFromToken(token: string) {
  try {
    const { secondaryProductLine } = jwtDecode(token);
    localStorage.setItem(SECONDARY_PRODUCT_LINE, secondaryProductLine);
    return secondaryProductLine;
  } catch (e) {
    return null;
  }
}

export function getSecondaryProductLinesFromToken(token: string) {
  try {
    const { secondaryProductLines } = jwtDecode(token);
    localStorage.setItem(SECONDARY_PRODUCT_LINES, secondaryProductLines);
    return secondaryProductLines;
  } catch (e) {
    return null;
  }
}
