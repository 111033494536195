import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  DeviceDto,
  DriverDto,
  PartialVehicleTypeDto,
  PermissionAccess,
  PlantDto,
  ProductLine,
  TruckDto,
  UrlKeyDto,
} from '@trucktrax/trucktrax-ts-common';
import { Button, DateWrapper } from '@trucktrax/trucktrax-common';
import styles from './TrucksDetailView.module.css';
import AdminCard from '../../shared/admin/AdminCard';
import {
  defaultProductLineRadioOptions,
  driverListAdminToDropDownOptions,
  findKeyWithUrl,
  getPrimaryProductLine,
  haulerListToMultiSelectOptions,
  plantListForDropdown,
  vehicleOwnershipTypeRadioOptions,
  vehicleTypesToDropDownOptions,
} from '../../../util/adminUtil';
import { ToastMessagesType, updateDataTableRecord } from '../../../services/dataTableService';
import { TRUCKS_TEXT, TABLET_DEVICE_ROUTE, GLINX_DEVICE_ROUTE } from '../../../constants/navConstants';
import { getPlantList } from '../../../services/plantsService';
import { devErrorAndLog } from '../../../util/errorUtil';
import { ERROR_TEXT_FETCH_PLANT_LIST, ERROR_TEXT_FETCH_VEHICLE_TYPES } from '../../../constants/errorConstants';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  DROPDOWN_FORM,
  EDIT_BTN,
  INPUT_FORM,
  NOT_AVAILABLE,
  RADIO_FORM,
  SAVE_BTN,
  TEXT_DISPLAY,
  CONFIRM_UNASSIGN_DEVICE,
  ACCENT,
  NUMERIC_INPUT_FORM,
  CONFIRM_ACTIVATE_TRUCK,
  ACTIVATE_TRUCK_BODY,
  DEACTIVATE_TRUCK_BODY,
  CONFIRM_DEACTIVATE_TRUCK,
  ARE_YOU_SURE,
  CANCEL_LABEL,
  MULTIDROPDOWN_FORM,
  READYMIX_VALUE,
  CARD_SWITCH,
  GROUPED_STATES_ABBV,
  EXTERNAL,
  INTERNAL,
  GEOTRAX_LITE_FLAG
} from '../../../constants/appConstants';
import { TRUCKS_PATH, DEVICES_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { openModal, closeModal } from '../../../store/actions/errorModalActions';
import { noop } from '../../../util/appUtil';
import { isTrackedObjectActive } from '../../../services/mapviewsService';
import StatusContainer from '../status/StatusContainer';
import { getVehicleTypes } from '../../../services/trucksService';
import { ConnectedDispatchFunction } from '../../../types';
import { fetchHaulerList } from '../../../services/haulersService';
import { ReduxState } from '../../../store';
import { setReduxDriverList } from '../../../services/driversService';
import HTTP_CODES from '../../../constants/httpConstants';
import { isFeatureFlagEnabled } from '../../../util/featureFlagUtil';

export const renderAssignedGlinxLink = (hasLink: boolean, unassignLink: JSX.Element | null) => (truck: TruckDto) => {
  const device = truck.associatedGlinx;
  if (!device || !device.id) { return null; }

  const label = `${device.externalId} - ${device.name}`;
  return (
    <span className="nowrap-ellipsis">
      <Link className={styles.deviceLink} to={`${GLINX_DEVICE_ROUTE}${device.id}`}>
        {label}
      </Link>
      {unassignLink}
    </span>
  );
};

export const renderAssignedTabletLink = (hasLink: boolean) => (truck: TruckDto) => {
  const device = truck.associatedTablet;
  if (!device || !device.id) { return null; }

  const label = `${device?.externalId} - ${device?.phoneNumber}`;

  if (hasLink) {
    return (
      <Link className={styles.deviceLink} to={`${TABLET_DEVICE_ROUTE}${device.id}`}>
        {label}
      </Link>
    );
  }

  return label;
};

export class TrucksDetailView extends Component<TrucksDetailViewProps> {
  state = {
    primaryProductLine: undefined,
    edit: false,
    truckAlias: '',
    device: null,
    truck: undefined as TruckDto | undefined,
    errorObject: {
      error: false,
      errorMessage: '',
    },
    plantList: [],
    vehicleTypes: [],
    shouldRefetchData: false,
    isTruckInUse: true,
    shouldDisplayAdditionalFields: false,
    showFlex: false,
    isReadyMixProductLine: false,
  };

  async componentDidMount() {
    await this.props.fetchHaulerList(undefined, this.props.currentRegion.url, true);
    await this.props.setReduxDriverList(this.props.currentRegion!.url!);
  }

  save = (dto: any, onSuccess: any, onError: any, toasts: ToastMessagesType) => {
    // For now we are forcing it to None
    let preUpdateDto = { ...dto, secondaryProductLine: 'None' };

    preUpdateDto = TrucksDetailView.updateHaulers(preUpdateDto);

    if (preUpdateDto.externalId) {
      const externalId = preUpdateDto.externalId.trim();
      preUpdateDto.externalId = externalId === '' ? null : externalId;
    }

    if (preUpdateDto.defaultDriver) {
      const defaultDriverUrl = preUpdateDto.defaultDriver.url;
      preUpdateDto.defaultDriver = defaultDriverUrl ? { url: defaultDriverUrl } : null;
    }

    if (preUpdateDto.archived) {
      preUpdateDto.defaultDriver = null;
    }

    if (preUpdateDto.primaryProductLine !== ProductLine.ReadyMix) {
      preUpdateDto.isGlinxEnabled = false;
      preUpdateDto.isFlexSystem = false;
    } else if (!preUpdateDto.isGlinxEnabled) {
      preUpdateDto.isFlexSystem = false;
    }

    const plantUrl = dto.plant?.url;
    if (!plantUrl) {
      preUpdateDto.plant = null;
    } else if (plantUrl && this.state.plantList.length > 0) {
      // determines if the plant exists in the current list of plants.
      // This checks if the currently selected plant has the assigned productLine, particularly when updated the productLine
      const isPlantFromProductLine = this.state.plantList.find((plant: PlantDto) => plant.url === plantUrl);
      preUpdateDto.plant = isPlantFromProductLine ? preUpdateDto.plant : null;
    }

    const vehicleTypeId = dto.vehicleType?.id;
    preUpdateDto.vehicleType = vehicleTypeId
      ? this.state.vehicleTypes.find((vehicleType: PartialVehicleTypeDto) => vehicleType.id === vehicleTypeId)
      : null;

    const onSaveSuccess = (updatedDto: any) => {
      const truckDetails = updatedDto && updatedDto.data ? updatedDto.data : {};
      this.setState({ edit: false, truck: truckDetails });
      onSuccess(updatedDto);
    };

    const currentRegionUrl = this.props.currentRegion.url;
    this.props.updateAdminTableData(this.baseUrl(), preUpdateDto, onSaveSuccess, onError, false, currentRegionUrl, toasts);
  };

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  baseUrl = () => `${getGeotraxBaseUrl() + TRUCKS_PATH}/${this.props.match.params.id}`;

  plantUrlAccessor = (truck: { plant: { url: any; }; }) => (truck.plant.url
    ? findKeyWithUrl(truck.plant, this.state.plantList, 'name')
    : NOT_AVAILABLE
  );

  defaultDriverUrlAccessor = () => {
    const { truck } = this.state;
    const defaultDriverUrl = truck?.defaultDriver?.url;
    if (!defaultDriverUrl) {
      return null;
    }

    const matchingDriver = this.props.drivers.find(d => d.url === defaultDriverUrl);
    if (!matchingDriver) {
      return null;
    }
    return `${matchingDriver.lastName}, ${matchingDriver.firstName}`;
  };

  static updateHaulers = (dto: TruckDto) => {
    if (!dto.haulers) {
      return dto;
    }
    if (dto.primaryProductLine === READYMIX_VALUE) {
      dto.haulers = [];
      return dto;
    }
    dto.haulers = dto.haulers.map((p: any) => ({ url: p.key ?? p.url }));
    return dto;
  };

  haulerAccessor = (dto: TruckDto) => {
    const haulerUrls = dto.haulers?.map(h => h.url);

    if (haulerUrls) {
      const haulerNames = this.props.haulersList
        .filter(h => haulerUrls.some(url => url === h.url))
        ?.map(p => p.name);

      return haulerNames?.length ? haulerNames : NOT_AVAILABLE;
    }

    return NOT_AVAILABLE;
  };

  static haulerAccessorEdit = (dto: TruckDto) => {
    const haulers = dto.haulers ?? [];

    return haulers.map((h: any) => ({
      key: h.key ?? h.url,
      value: { url: h.key ?? h.url },
      url: h.key ?? h.url,
    }));
  };

  static licenseAccesor = (dto: TruckDto) => {
    const licensePlate = dto?.licensePlate || '';
    const licenseState = dto?.licenseState ? `(${dto.licenseState})` : '';
    return `${licensePlate} ${licenseState}`.trim() || null;
  };

  static isExternalAccessor = (dto: TruckDto) => {
    if (dto?.isExternal === undefined) {
      return null;
    }
    return dto.isExternal ? EXTERNAL : INTERNAL;
  };

  static shouldDisplayAdditionalFields = (dto?: { primaryProductLine?: ProductLine }) => (
    dto?.primaryProductLine === ProductLine.Aggregates
    || dto?.primaryProductLine === ProductLine.Cement
  );

  recordLoaded = async (dto: any) => {
    dto.vehicleType = dto.primaryProductLine !== ProductLine.ReadyMix ? dto.vehicleType : null;

    const { data } = dto;
    await this.setVehicleTypes();
    await this.setPlantList(data.primaryProductLine);

    const isReadyMixProductLine = data.primaryProductLine && data.primaryProductLine === ProductLine.ReadyMix;

    const shouldDisplayAdditionalFields = TrucksDetailView.shouldDisplayAdditionalFields(data);
    const showFlex = isReadyMixProductLine && data.isGlinxEnabled && !shouldDisplayAdditionalFields;
    this.setState({
      truckAlias: data.truckAlias,
      truck: data,
      shouldRefetchData: false,
      shouldDisplayAdditionalFields,
      isReadyMixProductLine,
      showFlex,
    });

    this.setAssociatedGlinx(data.associatedGlinx);
    this.setIsTruckInUse(data.url);
  };

  recordChanged = async (updatedDto: any) => {
    await this.setPlantList(updatedDto.primaryProductLine);
    updatedDto.vehicleType = updatedDto.primaryProductLine !== ProductLine.ReadyMix ? updatedDto.vehicleType : null;

    const isReadyMixProductLine = updatedDto.primaryProductLine && updatedDto.primaryProductLine === ProductLine.ReadyMix;

    const shouldDisplayAdditionalFields = TrucksDetailView.shouldDisplayAdditionalFields(updatedDto);
    const showFlex = updatedDto.isGlinxEnabled && !shouldDisplayAdditionalFields;
    if (!showFlex) {
      updatedDto.isFlexEnabled = false;
    }
    this.setState({
      isReadyMixProductLine,
      shouldDisplayAdditionalFields,
      truck: updatedDto,
      showFlex,
    });
  };

  setIsTruckInUse = async (url: string) => {
    const hasEditPermission = this.props.trucksPermissionAccess === PermissionAccess.Edit;

    if (hasEditPermission) {
      const isTruckInUse = await isTrackedObjectActive(url);
      this.setState({ isTruckInUse });
    }
  };

  async setVehicleTypes() {
    try {
      const vehicleTypes = await getVehicleTypes();
      this.setState({ vehicleTypes });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      this.props.devErrorAndLog(
        ERROR_TEXT_FETCH_VEHICLE_TYPES,
        `TrucksDetailView: ${e.toString()}`,
        undefined,
        undefined,
        consoleOnly
      );
    }
  }

  async setPlantList(primaryProductLine: string | undefined) {
    if (primaryProductLine !== this.state.primaryProductLine) {
      const { currentRegion } = this.props;
      try {
        const plantList = await getPlantList(currentRegion.url, primaryProductLine);
        this.setState({
          plantList,
          primaryProductLine,
        });
      } catch (e: any) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        this.props.devErrorAndLog(
          ERROR_TEXT_FETCH_PLANT_LIST,
          `TrucksDetailView: ${e.toString()}`,
          ERROR_TEXT_FETCH_PLANT_LIST,
          undefined,
          consoleOnly
        );
      }
    }
  }

  async setAssociatedGlinx(device: DeviceDto) {
    this.setState({ device });
  }

  unassignWarningModal = () => {
    const device = (this.state.device ? this.state.device : undefined) as DeviceDto | undefined;
    const deviceName = device?.name;
    const modalBody = (
      <span>
        <p>
          {'Vehicle '}
          <span className="txt-bold">{this.state.truckAlias}</span>
          {' will no longer be assigned to this G-Linx, and the last assigned vehicle for '}
          <span className="txt-bold">{deviceName}</span>
          {' will show as "None."'}
        </p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: () => this.unassignDevice(device),
      acceptText: CONFIRM_UNASSIGN_DEVICE,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  getBreadcrumbPath() {
    const isArchived = this.state.truck?.archived === true;
    if (!isArchived) {
      return this.props.location.pathname;
    }
    // construct the breadcrumb path for deactivated drivers
    const paths = this.props.location.pathname.split('/');
    const id = paths.pop();
    if (id) {
      paths.push('deactivated', id);
    }
    return paths.join('/');
  }

  unassignDevice = (device?: DeviceDto) => {
    if (!device) return;
    const dto: DeviceDto = {
      ...device,
      truck: undefined,
    };

    const toastMessages = {
      success: `Device “${dto.name}” has been successfully unassigned.`,
      fail: `Unable to unassign G-linx device “${dto.name}.”`,
    };

    this.props.closeModal();
    this.saveDevice(dto, noop, noop, toastMessages);
  };

  saveDevice = (
    dto: DeviceDto,
    onSuccess: { (): void; (arg0: any): void; },
    onError: ((errorMap: { [key: string]: string[]; }) => void) | undefined,
    toastMessages: { success: string; fail: string; } | undefined
  ) => {
    const url = `${getGeotraxBaseUrl() + DEVICES_PATH}/${dto.id}`;
    const currentRegionUrl = this.props.currentRegion.url;

    const onSaveSuccess = () => {
      if (!this.state.truck) return;
      this.setState({
        edit: false,
        shouldRefetchData: true,
      });
    };

    this.props.updateAdminTableData(url, dto, onSaveSuccess, onError, false, currentRegionUrl, toastMessages);
  };

  static defaultUrlAccessor = (row: { [x: string]: { url: string; }; }, rowName: string) => {
    if (!row[rowName]) {
      row[rowName] = { url: '' };
    }

    return `${rowName}.url`;
  };

  static defaultIdAccessor = (row: { [x: string]: { id: string; }; }, rowName: string) => {
    if (!row[rowName]) {
      row[rowName] = { id: '' };
    }

    return `${rowName}.id`;
  };

  static isGlinxEnabledAccessor = (truck: TruckDto) => (truck.isGlinxEnabled ? 'Yes' : 'No');

  static isFlexSystemAccessor = (truck: TruckDto) => (truck.isFlexSystem ? 'Yes' : 'No');

  createUnassignLink = (deviceName?: string) => (
    deviceName
      ? (
        <Button
          buttonClassName={cx(styles.unassignButton, 'tt-btn-basic', 'margin-left-10')}
          name="Unassign"
          dataTest="UnAssign-truck"
          onClick={this.unassignWarningModal}
        />
      )
      : null
  );

  getReadConfig = () => {
    const currentTareDate = (row?: TruckDto) => (row?.currentTare?.timestamp
      ? new DateWrapper(row.currentTare.timestamp).toUSDateString()
      : '');

    const tareExpirationDate = (row?: TruckDto) => (row?.currentTare?.timestamp
      ? new DateWrapper(row.currentTare.timestamp).addDays(row.scaleExpirationDays).toUSDateString()
      : '');

    const readConfig = [
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.EXTERNAL_ID,
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        className: cx(styles.externalId, 'large'),
        dataTest: 'trucks-drilldown-id',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.VEHICLE_ALIAS,
        accessor: ADMIN_KEYS.TRUCK_ALIAS,
        className: styles.truckAlias,
        dataTest: 'trucks-drilldown-alias',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.PRODUCT_LINE,
        accessor: getPrimaryProductLine,
        className: styles.defaultProductLine,
        dataTest: 'trucks-drilldown-product',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.LICENSE_PLATE,
        accessor: TrucksDetailView.licenseAccesor,
        className: styles.license,
        dataTest: 'trucks-drilldown-license-plate',
      } as any,
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.VEHICLE_TYPE,
        accessor: ADMIN_KEYS.VEHICLE_TYPE_NAME,
        className: styles.defaultProductLine,
        dataTest: 'trucks-drilldown-vehicle-type',
      }
    ];

    const isGeotraxLiteEnabled = isFeatureFlagEnabled(
      this.props.userPermission,
      this.props.currentRegion?.url ?? '',
      GEOTRAX_LITE_FLAG
    );

    if (isGeotraxLiteEnabled) {
      readConfig.splice(1, 0, {
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.IS_EXTERNAL,
        label: ADMIN_LABELS.OWNERSHIP,
        accessor: TrucksDetailView.isExternalAccessor,
        className: styles.ownership,
      });
    }

    if (this.state.isReadyMixProductLine) {
      readConfig.push(
        {
          type: TEXT_DISPLAY,
          label: ADMIN_LABELS.GLINX_ENABLED,
          accessor: TrucksDetailView.isGlinxEnabledAccessor,
          className: styles.isGlinxEnabled,
          dataTest: 'trucks-drilldown-is-glinx-enabled',
        },
        {
          type: TEXT_DISPLAY,
          label: ADMIN_LABELS.FLEX_SYSTEM,
          accessor: TrucksDetailView.isFlexSystemAccessor,
          className: styles.isFlexSystem,
          dataTest: 'trucks-drilldown-is-flex-system',
        } as any
      );
    }

    readConfig.push({
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.DEFAULT_DRIVER,
      accessor: this.defaultDriverUrlAccessor,
      className: styles.defaultDriver,
      dataTest: 'trucks-drilldown-previous',
    });

    if (this.state.shouldDisplayAdditionalFields) {
      readConfig.push({
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.HAULER,
        accessor: this.haulerAccessor,
        className: styles.haulers,
        dataTest: 'trucks-drilldown-hauler',
      } as any);
    }

    if (this.state.shouldDisplayAdditionalFields) {
      readConfig.push({
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.SCALE_TARE_DATE,
        accessor: currentTareDate,
        className: styles.readTareDate,
        dataTest: 'trucks-drilldown-scale_tare_date',
      } as any);
    }

    readConfig.push({
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.HOME_PLANT,
      accessor: this.plantUrlAccessor,
      className: styles.homePlant,
      dataTest: 'trucks-drilldown-homeplant',
    });

    if (this.state.shouldDisplayAdditionalFields) {
      readConfig.push({
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.SCALE_EXPIRATION_DAYS,
        accessor: ADMIN_KEYS.SCALE_EXPIRATION_DAYS,
        className: styles.readTareExpirationDays,
        dataTest: 'trucks-drilldown-scale_expiration_days',
      } as any);
    }

    if (this.state.shouldDisplayAdditionalFields) {
      readConfig.push({
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.SCALE_EXPIRATION_DATE,
        accessor: tareExpirationDate,
        className: styles.readTareExpirationDate,
        dataTest: 'trucks-drilldown-scale_expiration_date',
      } as any);
    }

    readConfig.push({
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.LAST_ASSIGNED_TABLET,
      key: ADMIN_LABELS.LAST_ASSIGNED_TABLET,
      accessor: renderAssignedTabletLink(true),
      className: styles.tabletAlias,
      dataTest: 'trucks-assigned-tablet',
      hideTitle: true,
    });

    if (this.props.trucksPermissionAccess === PermissionAccess.Edit) {
      readConfig.push({
        type: EDIT_BTN,
        name: 'Edit Vehicle',
        iconClassName: 'icon-create',
        dataTest: 'trucks-drilldown-edit',
        disabled: this.state.isTruckInUse,
        category: 'truck',
      } as any);
    }

    return readConfig;
  };

  getEditConfig = () => {
    const driversDropdownOptions = driverListAdminToDropDownOptions(
      this.props.drivers,
      this.state.primaryProductLine ?? ProductLine.Aggregates
    );
    const glinxDevice = (row: TruckDto) => row.associatedGlinx;
    const glinxName = glinxDevice.name;
    const unassignLink = this.createUnassignLink(glinxName);
    const plantsDropdownOptions = plantListForDropdown(this.state.plantList || []);
    const vehicleTypesDropDownOptions = vehicleTypesToDropDownOptions(this.state.vehicleTypes || [], this.props.currentRegion);
    const isExternalTruck = this.state.truck?.isExternal;
    const editConfig = [
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.EXTERNAL_ID,
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        className: cx(styles.externalId, 'large'),
        dataTest: 'trucks-drilldown-id',
      },
      {
        type: INPUT_FORM,
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        className: cx(styles.editExternalId, 'large'),
        dataTest: 'trucks-drilldown-id',
        key: ADMIN_KEYS.EXTERNAL_ID,
        label: ADMIN_LABELS.EXTERNAL_ID,
        maxLength: 50,
        errorDataTest: 'externalId-input-missing-error',
        isRequired: false,
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.LAST_ASSIGNED_TABLET,
        key: ADMIN_LABELS.LAST_ASSIGNED_TABLET,
        accessor: renderAssignedTabletLink(false),
        className: styles.tabletAlias,
        dataTest: 'trucks-assigned-tablet',
        hideTitle: true,
      },
      {
        type: INPUT_FORM,
        accessor: ADMIN_KEYS.TRUCK_ALIAS,
        className: styles.truckAlias,
        dataTest: 'trucks-drilldown-alias',
        key: ADMIN_KEYS.TRUCK_ALIAS,
        label: ADMIN_LABELS.VEHICLE_ALIAS,
        maxLength: 20,
        errorDataTest: 'truckAlias-input-missing-error',
        isRequired: true,
      },
      {
        type: TEXT_DISPLAY,
        key: ADMIN_LABELS.LAST_ASSIGNED_GLINX,
        label: ADMIN_LABELS.LAST_ASSIGNED_GLINX,
        accessor: renderAssignedGlinxLink(true, unassignLink),
        className: styles.glinxAlias,
        dataTest: 'trucks-assigned-glinx',
        hideTitle: true,
      },
      {
        type: INPUT_FORM,
        accessor: ADMIN_KEYS.LICENSE_PLATE,
        className: styles.truckAlias,
        dataTest: 'trucks-drilldown-licenseplate',
        key: ADMIN_KEYS.LICENSE_PLATE,
        label: ADMIN_LABELS.LICENSE_PLATE,
        maxLength: 20,
        errorDataTest: 'truckLicense-input-missing-error',
      },
      {
        type: DROPDOWN_FORM,
        key: ADMIN_KEYS.LICENSE_STATE,
        label: ADMIN_LABELS.LICENSE_STATE,
        className: styles.licenseState,
        items: GROUPED_STATES_ABBV,
        initialSelected: { value: '' },
        dataTest: 'licensestate-input-data-test',
      },
      {
        type: RADIO_FORM,
        accessor: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        className: styles.defaultProductLine,
        dataTest: 'users-drilldown-product',
        key: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        radioButtonGroupName: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        label: ADMIN_LABELS.PRODUCT_LINE,
        radioButtons: defaultProductLineRadioOptions,
      }];

    const isGeotraxLiteEnabled = isFeatureFlagEnabled(
      this.props.userPermission,
      this.props.currentRegion?.url ?? '',
      GEOTRAX_LITE_FLAG
    );

    if (isGeotraxLiteEnabled) {
      // adding as the first item in the editConfig array
      editConfig.unshift({
        type: RADIO_FORM,
        key: ADMIN_KEYS.IS_EXTERNAL,
        accessor: () => `${this.state.truck?.isExternal}`,
        label: ADMIN_LABELS.OWNERSHIP,
        radioButtonGroupName: ADMIN_KEYS.IS_EXTERNAL,
        className: styles.ownership,
        radioButtons: vehicleOwnershipTypeRadioOptions,
        initialSelected: (isExternalTruck ? vehicleOwnershipTypeRadioOptions[1] : vehicleOwnershipTypeRadioOptions[0]).value,
        isRequired: true,
        items: vehicleOwnershipTypeRadioOptions,
      } as any);
    }

    if (!this.state.isReadyMixProductLine) {
      editConfig.push({
        type: DROPDOWN_FORM,
        accessor: (row: { [name: string]: { id: string; }; }) => TrucksDetailView.defaultIdAccessor(row, ADMIN_KEYS.VEHICLE_TYPE),
        className: styles.homePlantEdit,
        dataTest: 'trucks-drilldown-vehicle-type',
        key: ADMIN_KEYS.VEHICLE_TYPE,
        label: ADMIN_LABELS.VEHICLE_TYPE,
        items: vehicleTypesDropDownOptions,
        isRequired: true,
      } as any);
    }

    if (this.state.shouldDisplayAdditionalFields) {
      editConfig.push({
        type: MULTIDROPDOWN_FORM,
        key: ADMIN_KEYS.HAULERS,
        label: ADMIN_LABELS.HAULER,
        className: cx('baseDropDown', 'bottomWidthSpace'),
        items: haulerListToMultiSelectOptions(this.props.haulersList),
        accessor: TrucksDetailView.haulerAccessorEdit,
        initialSelected: { value: [] },
        placeholder: 'Select One or Multiple Haulers...',
        dataTest: 'hauler-input-data-test',
      } as any);
    }

    editConfig.push({
      type: DROPDOWN_FORM,
      accessor: (row: { [x: string]: { url: string; }; }) => TrucksDetailView.defaultUrlAccessor(row, ADMIN_KEYS.PLANT),
      className: styles.homePlantEdit,
      dataTest: 'trucks-drilldown-homeplant',
      key: ADMIN_KEYS.PLANT,
      label: ADMIN_LABELS.HOME_PLANT,
      items: plantsDropdownOptions,
      isRequired: false,
    } as any);

    editConfig.push({
      type: DROPDOWN_FORM,
      accessor: (row: { [x: string]: { url: string; }; }) => TrucksDetailView.defaultUrlAccessor(row, ADMIN_KEYS.DEFAULT_DRIVER),
      className: styles.defaultDriverEdit,
      dataTest: 'trucks-drilldown-defaultDriver',
      key: ADMIN_KEYS.DEFAULT_DRIVER,
      label: ADMIN_LABELS.DEFAULT_DRIVER,
      items: driversDropdownOptions,
      isRequired: false,
    } as any);

    editConfig.push({
      type: SAVE_BTN,
    } as any);

    if (this.state.shouldDisplayAdditionalFields) {
      editConfig.push({
        type: NUMERIC_INPUT_FORM,
        accessor: ADMIN_KEYS.SCALE_EXPIRATION_DAYS,
        className: styles.tareExpirationDays,
        dataTest: 'trucks-drilldown-scaleExpirationDays',
        key: ADMIN_KEYS.SCALE_EXPIRATION_DAYS,
        label: (
          <span className={styles.label}>
            {ADMIN_LABELS.SCALE_EXPIRATION_DAYS}
            <span className={styles.asterisk}> *</span>
          </span>
        ),
        errorDataTest: 'scaleExpirationDays-input-missing-error',
        hideCharacterCount: true,
        increment: {
          showIncrementButtons: true,
          incrementStep: 1,
          minValue: 0,
          maxValue: 300,
        },
      } as any);
    } else {
      editConfig.push({
        id: ADMIN_KEYS.IS_GLINX_ENABLED,
        type: CARD_SWITCH,
        key: ADMIN_KEYS.IS_GLINX_ENABLED,
        label: ADMIN_LABELS.GLINX_ENABLED,
        display: true,
        editing: true,
        // onChange: this.toggleOnChange(ADMIN_KEYS.IS_GLINX_ENABLED)
      } as any);
      // Flex System is only relevant if the Glinx is enabled
      if (this.state.showFlex) {
        editConfig.push({
          id: ADMIN_KEYS.IS_FLEX_SYSTEM,
          type: CARD_SWITCH,
          key: ADMIN_KEYS.IS_FLEX_SYSTEM,
          label: ADMIN_LABELS.FLEX_SYSTEM,
          display: true,
          editing: true,
          // onChange: this.toggleOnChange(ADMIN_KEYS.IS_FLEX_SYSTEM)
        } as any);
      }
    }

    return editConfig;
  };

  viewOnlyItems() {
    if (this.state.edit) {
      return null;
    }

    const hasEditPermission = this.props.trucksPermissionAccess === PermissionAccess.Edit;
    const shouldDisabled = !this.state.truck?.archived && this.state.isTruckInUse;
    return (
      <StatusContainer
        category="truck"
        isArchived={this.state.truck?.archived}
        isDisabled={shouldDisabled}
        hasEditPermission={hasEditPermission}
        containerClassName={styles.statusCtr}
        onActivateClick={this.activateWarningModal}
        onDeactivateClick={this.deactivateWarningModal}
      />
    );
  }

  activateWarningModal = () => {
    const modalBody = (
      <span>
        <p>{ACTIVATE_TRUCK_BODY}</p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.activateTruck,
      acceptText: CONFIRM_ACTIVATE_TRUCK,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateWarningModal = () => {
    const modalBody = (
      <span>
        <p>{DEACTIVATE_TRUCK_BODY}</p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.deactivateTruck,
      acceptText: CONFIRM_DEACTIVATE_TRUCK,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateTruck = () => {
    const dto = {
      ...this.state.truck,
      archived: true,
    };

    const toastMessages = {
      success: `“${dto.truckAlias}” has been successfully deactivated.`,
      fail: `Unable to deactivate “${dto.truckAlias}.”`,
    };
    this.props.closeModal();
    this.save(dto, noop, null, toastMessages);
  };

  activateTruck = () => {
    const dto = {
      ...this.state.truck,
      archived: false,
    } as TruckDto;

    const toastMessages = {
      success: `"${dto.truckAlias}" has been successfully activated.`,
      fail: `Unable to activate “${dto.truckAlias}.”`,
    };
    this.props.closeModal();
    this.save(dto, noop, null, toastMessages);
  };

  render() {
    const pathname = this.getBreadcrumbPath();
    const configValue = (this.state.edit)
      ? this.getEditConfig()
      : this.getReadConfig();

    return (
      <AdminCard
        edit={this.state.edit}
        url={this.baseUrl()}
        className={cx(
          styles.wrapper,
          this.state.edit && styles.editWrapper
        )}
        onToggleEdit={this.toggleEdit}
        save={this.save}
        config={configValue}
        errorObject={this.state.errorObject}
        pathName={pathname}
        headerAccessor="truckAlias"
        options={{
          requiredKeys: [],
          callback: this.recordLoaded,
          shouldRefetchData: this.state.shouldRefetchData,
        }}
        onChangeHandler={this.recordChanged}
        disableEdit={this.state.isTruckInUse}
      >
        {this.viewOnlyItems()}
      </AdminCard>
    );
  }
}

type Location = {
  pathname: string;
};

type Match = {
  params: { id: string };
};

const mapStateToProps = (state: ReduxState) => ({
  drivers: state.driverList.drivers,
  trucksPermissionAccess: state.adminPermissionAccess[TRUCKS_TEXT],
  currentRegion: state.currentRegion ?? { url: '' },
  haulersList: state.haulersList?.haulers,
  updateAdminTableData: updateDataTableRecord,
  devErrorAndLog,
  userPermission: state.userPermission || {},
});

type TrucksDetailViewReduxProps = ReturnType<typeof mapStateToProps>;

type TrucksDetailViewDispatchProps = {
  setReduxDriverList: ConnectedDispatchFunction<typeof setReduxDriverList>;
  fetchHaulerList: ConnectedDispatchFunction<typeof fetchHaulerList>;
};

type TrucksDetailViewOwnProps = {
  updateAdminTableData: typeof updateDataTableRecord,
  devErrorAndLog: typeof devErrorAndLog,
  match: Match,
  closeModal: typeof closeModal,
  openModal: typeof openModal,
  trucksPermissionAccess?: string,
  drivers: DriverDto[],
  currentRegion: UrlKeyDto,
  location: Location,
};

export type TrucksDetailViewProps = TrucksDetailViewReduxProps & TrucksDetailViewDispatchProps & TrucksDetailViewOwnProps;

export default connect(
  mapStateToProps,
  {
    updateAdminTableData: updateDataTableRecord,
    devErrorAndLog,
    openModal,
    closeModal,
    setReduxDriverList,
    fetchHaulerList,
    currentRegion: { url: '' },
    drivers: [] as DriverDto[],
    trucksPermissionAccess: '',
  }
)(TrucksDetailView);
