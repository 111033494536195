import { OrderDto } from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import { FETCH_ORDERS } from '../../constants/actionConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const ordersReducer = (
  state = { orders: [] as OrderDto[] },
  action: PayloadAction<OrderDto[], 'FETCH_ORDERS'>
) => {
  switch (action.type) {
    case FETCH_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    default:
      return state;
  }
};
