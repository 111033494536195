import { DateWrapper } from '@trucktrax/trucktrax-common';
import { PayloadAction } from '../../types';
import {
  OVERRIDE_MAP_FILTER,
  OPEN_MAP_FILTERS,
  RESET_MAP_FILTERS,
  TOGGLE_MAP_FILTER,
} from '../../constants/actionConstants';

export const mapFilterInitialState = {
  showTrucks: true,
  showPlants: false,
  showJobsites: false,
  showPlantsGeofences: false,
  showJobsitesGeofences: false,
  wasFilterOverridden: false,
  ordersStartDate: DateWrapper.now.startOfDay.date,
  ordersEndDate: DateWrapper.now.startOfDay.date,
  plantList: [] as any,
  shouldSave: false,
  showExtTrucks: true,
};

export type MapFilterState = typeof mapFilterInitialState;

export interface MapFiltersPayload {
  [name: string]: boolean | Date
}

export interface MapFilterOrdersDateRange {
  ordersStartDate?: Date;
  ordersEndDate?: Date;
}

type AllowedPayloads = (MapFiltersPayload & MapFilterOrdersDateRange) | undefined;
// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const mapFilters = (
  state = mapFilterInitialState,
  action: PayloadAction<AllowedPayloads, 'TOGGLE_MAP_FILTER' | 'OPEN_MAP_FILTER' | 'OVERRIDE_MAP_FILTER' | 'RESET_MAP_FILTERS'>
): MapFilterState => {
  switch (action.type) {
    case TOGGLE_MAP_FILTER:
      return {
        ...state,
        ...action.payload,
        wasFilterOverridden: false,
      };
    case OPEN_MAP_FILTERS:
      return {
        ...state,
        wasFilterOverridden: false,
      };
    case OVERRIDE_MAP_FILTER: {
      const payload = action.payload as MapFiltersPayload;
      const wasFilterOverridden = state.wasFilterOverridden
        || Object
          .keys(payload)
          .some(key => state[key as keyof MapFilterState] !== payload[key]);
      return {
        ...state,
        ...action.payload,
        wasFilterOverridden,
      };
    }
    case RESET_MAP_FILTERS:
      mapFilterInitialState.plantList = state.plantList.map((plant: any) => ({
        ...plant,
        isSelected: true,
      }));
      return {
        ...mapFilterInitialState,
      };
    default:
      return state;
  }
};
