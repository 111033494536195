/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Marker, InfoBox } from '@react-google-maps/api';
import cx from 'classnames';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import InfoPane from './InfoPane';
import styles from './TTMarker.module.css';
import { ReduxState } from '../../../store';

export class TTMarker extends Component<TTMarkerProps, TTMarkerState> {
  positionRef: React.RefObject<HTMLDivElement>;

  infoPaneRef: React.RefObject<HTMLDivElement>;

  markerRef: React.RefObject<Marker>;

  constructor(props: TTMarkerProps) {
    super(props);
    this.positionRef = React.createRef();
    this.infoPaneRef = React.createRef();
    this.markerRef = React.createRef();
    this.state = {
      showLabelInfo: !this.props.isLabelHover,
      rerenderComplete: false,
    };
  }

  componentDidUpdate(prevProps: TTMarkerProps) {
    if (prevProps.isSelected !== this.props.isSelected) {
      // ensures refs are available for info pane to render in right location
      setTimeout(this.forceUpdate.bind(this), 0);
    }
  }

  onLogoutClickHandle = () => {
    this.props.onLogoutDriver!();
  };

  onMarkerClick = () => {
    if (this.props.onClick && this.markerRef.current) {
      this.props.onClick!(this.markerRef.current);
    }
    // ensures refs are available for info pane to render in right location
    setTimeout(this.forceUpdate.bind(this), 0);
  };

  showLabel = (e: google.maps.MapMouseEvent,) => this.handleMouseHover(e, true);

  hideLabel = (e: google.maps.MapMouseEvent,) => this.handleMouseHover(e, false);

  handleMouseHover = (e: google.maps.MapMouseEvent, showLabel: boolean) => {
    e.stop();
    if (!this.props.isLabelHover) return;
    this.setState({
      showLabelInfo: (this.props.isPlantMarker || this.props.isRouteHistory) ? showLabel : true,
    });
  };

  getInfoPane = () => {
    const {
      mapRef,
      infoPaneContent,
      infoPaneTitle,
      onCloseClick,
      truckHasTicket,
    } = this.props;

    // constants
    const big = 27;
    const small = 11;
    const headerCenterTop = 30;
    const sideBreak = 100;

    const bigPadding = (big * 1.5);
    const smallPadding = (small * 3);

    let arrowClass;
    let infoPaneTransform;
    let arrowStyle;

    const div = mapRef && mapRef.getDiv();
    const mapBox = div && div.getBoundingClientRect();
    const mapTop = mapBox?.top ?? 0;
    const mapLeft = mapBox?.left ?? 0;
    const mapRight = mapBox?.right ?? 0;
    const mapWidth = mapBox?.width ?? 0;
    const mapHeight = mapBox?.height ?? 0;

    const positionRef = this.positionRef.current;
    const markerBox = positionRef && positionRef.getBoundingClientRect();
    const markerY = markerBox?.top ?? 0;
    const markerX = markerBox?.left ?? 0;

    const infoPaneRef = this.infoPaneRef.current;
    const paneBox = infoPaneRef && infoPaneRef.getBoundingClientRect();
    const paneHeight = paneBox?.height ?? 0;

    const movePanelRight = (markerX - mapLeft) < sideBreak;
    const movePanelLeft = markerX > (mapRight - sideBreak);

    const panelTopPercent = Math.floor(((markerY - mapTop) / mapHeight) * 100);
    const panelTopPercentTrim = Math.min(Math.max(panelTopPercent, 10), 80);
    const topBreakPercent = Math.floor(((headerCenterTop + (big * 2)) / paneHeight) * 100);
    const isArrowBlack = panelTopPercentTrim <= topBreakPercent;

    const getVerticalArrowStyle = (top: number) => ({
      top: `calc(${top - 6}%)`,
      transform: 'translateY(-50%)',
    });

    if (movePanelRight) {
      if (isArrowBlack) {
        arrowClass = styles.arrowLeftTop;
        infoPaneTransform = `translate(${bigPadding}px, ${-headerCenterTop}px)`;
      } else {
        arrowClass = styles.arrowLeftBottom;
        infoPaneTransform = `translate(${bigPadding}px, ${-panelTopPercentTrim}%)`;
        arrowStyle = getVerticalArrowStyle(panelTopPercentTrim);
      }
    } else if (movePanelLeft) {
      if (isArrowBlack) {
        arrowClass = styles.arrowRightTop;
        infoPaneTransform = `translate(calc(-100% - ${bigPadding}px), ${-headerCenterTop}px)`;
      } else {
        arrowClass = styles.arrowRightBottom;
        infoPaneTransform = `translate(calc(-100% - ${bigPadding}px), ${-panelTopPercentTrim}%)`;
        arrowStyle = getVerticalArrowStyle(panelTopPercentTrim);
      }
    } else {
      const movePanelDown = (markerY - mapTop) < (paneHeight + bigPadding);
      const panelLeftPercent = Math.floor(((markerX - mapLeft) / mapWidth) * 100);
      const panelLeftPercentTrim = Math.min(Math.max(panelLeftPercent, 10), 90);

      const getHorizontalArrowStyle = (left: number) => ({
        left: `calc(${left}%)`,
        transform: 'translateX(-50%)',
      });
      if (movePanelDown) {
        arrowClass = styles.arrowTop;
        infoPaneTransform = `translate(-${panelLeftPercentTrim}%, ${smallPadding}px)`;
        arrowStyle = getHorizontalArrowStyle(panelLeftPercentTrim);
      } else {
        arrowClass = styles.arrowBottom;
        infoPaneTransform = `translate(-${panelLeftPercentTrim}%, calc(-100% - ${bigPadding}px))`;
        arrowStyle = getHorizontalArrowStyle(panelLeftPercentTrim);
      }
    }

    const isPositionable = mapRef
      && positionRef
      && infoPaneRef
      && ((markerX !== 0) || (markerY !== 0));

    const infoPane = (
      <div
        style={{
          width: '0px',
          height: '0px',
          overflow: 'visible',
          opacity: isPositionable ? 1 : 0,
          pointerEvents: isPositionable ? 'all' : 'none',
        }}
        ref={this.positionRef}
      >
        <div
          className={cx({
            [styles.infoBox]: true,
          })}
          style={{ transform: infoPaneTransform }}
          ref={this.infoPaneRef}
        >
          <InfoPane
            onCloseClick={onCloseClick!}
            onLogoutClick={this.onLogoutClickHandle}
            truckHasTicket={truckHasTicket}
            infoPaneTitle={infoPaneTitle}
            infoPaneContent={infoPaneContent}
          />
          <div
            className={arrowClass}
            style={arrowStyle}
          />
        </div>
      </div>
    );

    // This next bit fixes the hover bug (https://trucktrax.atlassian.net/browse/GTX-7674);
    // it basically discards the first render and triggers a second to populate positionRef,
    // infoPaneRef, and markerRef so the calculations in getInfoPane() give a useful result.
    if (!isPositionable && !this.state.rerenderComplete) {
      setTimeout(() => this.setState({ rerenderComplete: true }), 1);
    }
    return infoPane;
  };

  centerMap = (markerPosition?: any) => {
    const { mapRef } = this.props;
    const centerPosition: any = {
      lat: markerPosition.lat,
      lng: markerPosition.lng,
    };
    if (mapRef) {
      mapRef.setCenter(centerPosition);
    }
  };

  render() {
    const {
      position,
      zIndex = 9,
      icon,
      shadowIcon,
      iconHighlight,
      isSelected,
      pixelOffset,
      label,
      isRouteHover,
      manualInfoPosition,
    } = this.props;

    const positionLiteral = {
      lat: position.lat ?? 0,
      lng: position.lng ?? 0,
    };

    const positionLabel = new google.maps.LatLng(positionLiteral.lat, positionLiteral.lng);

    if (manualInfoPosition) {
      // force re-center the map, but only if a manual info position is needed
      global.setTimeout(() => this.centerMap(positionLiteral), 100);
      // we do this twice to smooth out the rendering a bit
      global.setTimeout(() => this.centerMap(positionLiteral), 1000);
    }

    const labelValue = this.state.showLabelInfo ? label : <></>;

    return (
      <div style={{ zIndex }}>
        {shadowIcon
          && (
            <Marker
              position={positionLiteral}
              icon={shadowIcon}
              zIndex={zIndex - 2}
            />
          )}
        {iconHighlight
          && (
            <Marker
              position={positionLiteral}
              icon={iconHighlight}
              zIndex={zIndex - 1}
            />
          )}
        <Marker
          position={positionLiteral}
          icon={icon}
          onClick={this.onMarkerClick}
          zIndex={zIndex}
          ref={this.markerRef}
          onMouseOver={isRouteHover ? this.onMarkerClick : this.showLabel}
          onMouseOut={isRouteHover ? this.props.onCloseClick : this.hideLabel}
        >
          {(label || isSelected)
            && (
              <InfoBox
                options={{
                  zIndex,
                  pixelOffset,
                  disableAutoPan: !isSelected,
                  alignBottom: true,
                  closeBoxURL: '',
                  enableEventPropagation: true,
                  maxWidth: 0,
                  boxClass: styles.infoBoxWrapper,
                  infoBoxClearance: {
                    width: 1,
                    height: 1,
                    equals: (other) => other?.width === 1 && other?.height === 1,
                  },
                  isHidden: false,
                  pane: 'floatPane',
                  boxStyle: {
                    pointerEvents: 'none',
                  },

                }}
                position={positionLabel}
              >
                {isSelected ? this.getInfoPane() : labelValue}
              </InfoBox>
            )}
        </Marker>
      </div>
    );
  }
}

type MapRefType = {
  getDiv: () => {
    getBoundingClientRect: () => DOMRect
  },
  setCenter?: any
};

export function mapStateToProps(state: ReduxState) {
  return {
    routeHistoryHoverSelection: state.routeHistoryHoverSelection,
  };
}
type ReduxProps = ReturnType<typeof mapStateToProps>;

export interface TTMarkerState {
  showLabelInfo: boolean;
  rerenderComplete: boolean;
}

export interface TTMarkerOwnProps {
  position: {
    statusColor?: string,
    statusText?: string,
    lat?: number,
    lng?: number,
    bearing?: number
  },
  zIndex?: number,
  icon: any,
  shadowIcon?: any,
  iconHighlight?: any,
  onClick?: (marker: Marker) => void,
  isSelected?: boolean,
  onCloseClick?: VoidFunc,
  infoPaneContent?: JSX.Element,
  infoPaneTitle?: JSX.Element | string,
  truckHasTicket?: boolean,
  label?: JSX.Element,
  onLogoutDriver?: VoidFunc,
  mapRef?: MapRefType,
  pixelOffset?: google.maps.Size;
  isLabelHover?: boolean,
  isRouteHover?: boolean,
  isRouteHistory?: boolean,
  isPlantMarker?: boolean,
  manualInfoPosition?: boolean
}

export type TTMarkerProps = TTMarkerOwnProps & ReduxProps;

export default connect(mapStateToProps)(TTMarker);
