import React, { Component, ReactNode } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import {
  DeviceDto, DeviceType, PermissionAccess, RegionDto, UrlKeyDto,
} from '@trucktrax/trucktrax-ts-common';
import { Label, vars } from '@trucktrax/trucktrax-common';
import styles from './DeviceDetailView.module.css';
import AdminCard from '../../shared/admin/AdminCard';
import { openModal, closeModal } from '../../../store/actions/errorModalActions';
import {
  exactFieldLength,
  getRegionUrlToNameMap,
  nameAndUrlItemToDropdownOptions,
  getDeviceTypeLabel,
  getUrl,
} from '../../../util/adminUtil';
import { getIdFromUrl, noop } from '../../../util/appUtil';
import { updateDataTableRecord } from '../../../services/dataTableService';
import { DEVICES_TEXT } from '../../../constants/navConstants';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  ACCENT,
  CONFIRM_DEACTIVATE_DEVICE,
  ACTIVATE_DEVICE_BODY1,
  ACTIVATE_DEVICE_BODY2,
  DEACTIVATE_DEVICE_BODY1,
  DEACTIVATE_DEVICE_BODY2,
  CONFIRM_ACTIVATE_DEVICE,
  ARE_YOU_SURE,
  DROPDOWN_FORM,
  EDIT_BTN,
  INPUT_FORM,
  INPUT_TYPE_TEL,
  SAVE_BTN,
  TEXT_DISPLAY,
  CUSTOM_COMPONENT,
  NUMERIC_INPUT_FORM,
  CANCEL_LABEL,
} from '../../../constants/appConstants';
import { DEVICES_PATH, TRUCKS_PATH, ADMIN_TRUCKS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import StatusContainer from '../status/StatusContainer';

import { isLoggedIn } from '../../../services/deviceService';
import { getRequest } from '../../../services/requestService';

export class DeviceDetailView extends Component<DeviceDetailViewProps> {
  static defaultProps = {
    currentRegion: {
      url: '',
    },
  };

  state = {
    edit: false,
    deviceDetails: {
      archived: false,
      externalId: '',
    },
    isTabletLoggedIn: false,
    truckAlias: '',
    truckNavUrl: '',
  };

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  baseUrl = () => `${getGeotraxBaseUrl() + DEVICES_PATH}/${this.props.match.params.id}`;

  regionUrlAccessor = (row: DeviceDto) => (getRegionUrlToNameMap(this.props.regions)[getUrl(row)!]);

  save = (dto: DeviceDto, onSuccess: any, onError: any, toastMessages: any) => {
    const onSaveSuccess = (updatedDto: { data: DeviceDto; }) => {
      const deviceDetails = updatedDto && updatedDto.data ? updatedDto.data : {};
      this.setState({
        edit: false,
        deviceDetails,
      });
      onSuccess(updatedDto);
    };
    const currentRegionUrl = this.props.currentRegion.url;
    this.props.updateAdminTableData(this.baseUrl(), dto, onSaveSuccess, onError, false, currentRegionUrl, toastMessages);
  };

  static getTruckById = async (truckBaseUrl: string, params: any) => {
    const responseTruck = await getRequest(truckBaseUrl, params);
    return responseTruck ? responseTruck.data : {};
  };

  handleCallback = async (response: { data: any }) => {
    const { data } = response;
    let truckAlias = 'None';
    const params = {
      tenant: data.tenant?.url,
    };
    try {
      const truckUrl = data.truck?.url;
      if (truckUrl) {
        const truckId = getIdFromUrl(truckUrl);
        const truckBaseUrl = `${getGeotraxBaseUrl()}${TRUCKS_PATH}/${truckId}`;
        const truckResponse = await DeviceDetailView.getTruckById(truckBaseUrl, params);
        truckAlias = truckResponse.truckAlias || truckAlias;
        const currentURL = `${window.location.protocol}//${window.location.host}`;
        const truckNavUrl = () => `${currentURL}${ADMIN_TRUCKS_PATH}/${truckId}`;
        this.setState({ truckNavUrl: truckNavUrl() });
      } else {
        this.setState({ truckNavUrl: '' });
      }
    } catch (e) {
      truckAlias = 'None';
    }
    data.truckAlias = truckAlias;
    data.deviceType = 'TABLET';
    this.setState({ truckAlias });

    this.setData(data);
    this.setIsTabletLoggedIn(data);
  };

  handleAlterData = (data: DeviceDto) => {
    this.setData(data);
    this.setIsTabletLoggedIn(data);

    return data;
  };

  setIsTabletLoggedIn = async (data: DeviceDto) => {
    const hasEditPermission = this.props.devicesPermissionAccess === PermissionAccess.Edit;

    if (hasEditPermission && DeviceType[data?.deviceType]?.toString() === DeviceType.TABLET.toString()) {
      const isTabletLoggedIn = await isLoggedIn(data);
      this.setState({ isTabletLoggedIn });
    }
  };

  setData = (data: DeviceDto) => {
    this.setState({
      deviceDetails: { ...data },
    });
  };

  static checkLength = (value: any) => exactFieldLength(value, 15, ADMIN_LABELS.IMEINUM);

  getViewConfigWithoutEdit = () => [
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.EXTERNAL_ID,
      accessor: ADMIN_KEYS.EXTERNAL_ID,
      className: cx(styles.externalId, 'large'),
      dataTest: 'devices-drilldown-external-id',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.DEVICE_TYPE,
      accessor: (row: { deviceType: any; }) => getDeviceTypeLabel(row.deviceType),
      className: styles.deviceType,
      dataTest: 'devices-drilldown-deviceType',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.IMEINUM,
      accessor: ADMIN_KEYS.IMEINUM,
      className: styles.imeiNum,
      dataTest: 'devices-drilldown-imei',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.SERIAL_NUMBER,
      accessor: ADMIN_KEYS.SERIAL_NUMBER,
      className: styles.serial,
      dataTest: 'devices-drilldown-serial',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.NAME,
      accessor: ADMIN_KEYS.NAME,
      className: styles.name,
      dataTest: 'devices-drilldown-name',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.REGION,
      accessor: this.regionUrlAccessor,
      className: styles.region,
      dataTest: 'devices-drilldown-region',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.ANDROID_VERSION,
      accessor: ADMIN_KEYS.SOFTWARE_VERSION,
      className: styles.android,
      dataTest: 'devices-drilldown-android',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.MANUFACTURER,
      accessor: ADMIN_KEYS.MANUFACTURER,
      className: styles.manufacturer,
      dataTest: 'devices-drilldown-manufacturer',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.PHONE,
      accessor: ADMIN_KEYS.PHONE,
      className: styles.phone,
      dataTest: 'devices-drilldown-phone',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.MODEL_NUMBER,
      accessor: ADMIN_KEYS.MODEL_NUMBER,
      className: styles.model,
      dataTest: 'devices-drilldown-model',
    },
    {
      type: CUSTOM_COMPONENT,
      key: ADMIN_LABELS.LAST_ASSIGNED_TRUCK,
      className: styles.truckAlias,
      component: () => this.createLink(this.state.truckAlias),
    },
    {
      type: CUSTOM_COMPONENT,
      name: ADMIN_KEYS.STATUS_CONTAINER,
      className: styles.statusCtr,
      component: () => this.viewOnlyItems(),
    },
  ];

  getViewConfigWithEdit = () => [
    ...this.getViewConfigWithoutEdit(),
    {
      type: EDIT_BTN,
      name: 'Edit Device',
      iconClassName: 'icon-create',
      dataTest: 'devices-drilldown-edit',
      disabled: this.state.isTabletLoggedIn,
      category: 'device',
    },
  ];

  // this configuration will be useful when editing
  getEditConfig = () => [
    {
      type: INPUT_FORM,
      accessor: ADMIN_KEYS.EXTERNAL_ID,
      className: cx(styles.externalId, 'large'),
      key: ADMIN_KEYS.EXTERNAL_ID,
      label: ADMIN_LABELS.EXTERNAL_ID,
      maxLength: 50,
      dataTest: 'external-id-input-data-test',
      errorDataTest: 'external-id-input-missing-error',
      isRequired: true,
      disabled: true,
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.DEVICE_TYPE,
      accessor: (row: { deviceType: any; }) => getDeviceTypeLabel(row.deviceType),
      className: styles.deviceType,
      dataTest: 'devices-drilldown-deviceType',
    },
    {
      type: NUMERIC_INPUT_FORM,
      accessor: ADMIN_KEYS.IMEINUM,
      className: styles.imeiNumEdit,
      key: ADMIN_KEYS.IMEINUM,
      label: ADMIN_LABELS.IMEINUM,
      maxLength: 15,
      dataTest: 'imei-input-data-test',
      errorDataTest: 'imei-input-missing-error',
      customValidation: DeviceDetailView.checkLength,
    },
    {
      type: INPUT_FORM,
      accessor: ADMIN_KEYS.SERIAL_NUMBER,
      className: styles.serialEdit,
      key: ADMIN_KEYS.SERIAL_NUMBER,
      label: ADMIN_LABELS.SERIAL_NUMBER,
      maxLength: 255,
      dataTest: 'serial-input-data-test',
      errorDataTest: 'serial-input-missing-error',
    },
    {
      type: INPUT_FORM,
      accessor: ADMIN_KEYS.NAME,
      className: styles.nameEdit,
      key: ADMIN_KEYS.NAME,
      label: ADMIN_LABELS.NAME,
      maxLength: 25,
      dataTest: 'name-input-data-test',
      errorDataTest: 'name-input-missing-error',
    },
    {
      type: INPUT_FORM,
      accessor: ADMIN_KEYS.MANUFACTURER,
      key: ADMIN_KEYS.MANUFACTURER,
      label: ADMIN_LABELS.MANUFACTURER,
      maxLength: 50,
      dataTest: 'manufacturer-input-data-test',
      errorDataTest: 'manufacturer-input-missing-error',
      className: styles.manufacturerEdit,
    },
    {
      type: INPUT_FORM,
      accessor: ADMIN_KEYS.MODEL_NUMBER,
      key: ADMIN_KEYS.MODEL_NUMBER,
      label: ADMIN_LABELS.MODEL_NUMBER,
      maxLength: 20,
      dataTest: 'model-input-data-test',
      errorDataTest: 'model-input-missing-error',
      className: styles.modelEdit,
    },
    {
      type: INPUT_FORM,
      accessor: ADMIN_KEYS.SOFTWARE_VERSION,
      key: ADMIN_KEYS.SOFTWARE_VERSION,
      label: ADMIN_LABELS.ANDROID_VERSION,
      maxLength: 20,
      dataTest: 'android-input-data-test',
      errorDataTest: 'android-input-missing-error',
      className: styles.androidEdit,
      disabled: true,
    },
    {
      type: NUMERIC_INPUT_FORM,
      accessor: ADMIN_KEYS.PHONE,
      key: ADMIN_KEYS.PHONE,
      label: ADMIN_LABELS.PHONE,
      maxLength: 20,
      inputType: INPUT_TYPE_TEL,
      dataTest: 'phone-input-data-test',
      errorDataTest: 'phone-input-missing-error',
      className: styles.phoneEdit,
      isRequired: true,
    },
    {
      type: DROPDOWN_FORM,
      className: styles.regionEdit,
      key: ADMIN_KEYS.REGION,
      label: ADMIN_LABELS.REGION,
      items: nameAndUrlItemToDropdownOptions(this.props.regions),
      isRequired: true,
      dataTest: 'region-input-data-test',
      accessor: this.regionUrlAccessor,
    },
    {
      type: CUSTOM_COMPONENT,
      key: ADMIN_LABELS.LAST_ASSIGNED_TRUCK,
      className: styles.truckAlias,
      component: () => this.createLink(this.state.truckAlias),
    },
    {
      type: SAVE_BTN,
    },
  ];

  viewOnlyItems() {
    if (this.state.edit) return null;

    const hasEditPermission = this.props.devicesPermissionAccess === PermissionAccess.Edit;
    const shouldBeDisabled = !this.state.deviceDetails.archived && this.state.isTabletLoggedIn;
    return (
      <StatusContainer
        category="device"
        isArchived={this.state.deviceDetails.archived}
        hasEditPermission={hasEditPermission}
        containerClassName={styles.statusCtr}
        onActivateClick={this.activateWarningModal}
        onDeactivateClick={this.deactivateWarningModal}
        isDisabled={shouldBeDisabled}
      />
    );
  }

  createLink = (truckAlias: {} | null | undefined) => (

    <div className={styles.truckAlias}>
      <Label className={styles.truckAliasLabel}>
        {ADMIN_LABELS.LAST_ASSIGNED_TRUCK}
      </Label>
      <p>
        {truckAlias === 'None' ? truckAlias
          : (
            <a
              style={{ color: `${vars.darkblue}`, minHeight: '4rem' }}
              href={this.state.truckNavUrl}
            >
              {truckAlias as ReactNode}
            </a>
          )}
      </p>
    </div>

  );

  activateWarningModal = () => {
    const modalBody = (
      <span>
        <p>{ACTIVATE_DEVICE_BODY1}</p>
        <p className="margin-bottom-5">{ACTIVATE_DEVICE_BODY2}</p>
      </span>
    );

    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.activateDevice,
      acceptText: CONFIRM_ACTIVATE_DEVICE,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateWarningModal = () => {
    const modalBody = (
      <span>
        <p>{DEACTIVATE_DEVICE_BODY1}</p>
        <p className="margin-bottom-5">{DEACTIVATE_DEVICE_BODY2}</p>
      </span>
    );

    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.deactivateDevice,
      acceptText: CONFIRM_DEACTIVATE_DEVICE,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateDevice = () => {
    const dto = {
      ...this.state.deviceDetails,
      archived: true,
    } as DeviceDto;

    const toastMessages = {
      success: `Device “${dto.externalId}” has been successfully deactivated.`,
      fail: `Unable to deactivate “${dto.externalId}.”`,
    };

    this.props.closeModal();
    this.save(dto, noop, null, toastMessages);
  };

  activateDevice = () => {
    const dto = {
      ...this.state.deviceDetails,
      archived: false,
    } as DeviceDto;
    const toastMessages = {
      success: `Device "${dto.externalId}" has been successfully activated.`,
      fail: `Unable to activate “${dto.externalId}.”`,
    };

    this.props.closeModal();
    this.save(dto, noop, null, toastMessages);
  };

  getBreadcrumbPath() {
    if (!this.state.deviceDetails.archived) {
      return this.props.location.pathname;
    }
    // construct the breadcrumb path for deactivated devices
    const paths = this.props.location.pathname.split('/');
    const id = paths.pop();
    if (id) {
      paths.push('deactivated', id);
    }
    return paths.join('/');
  }

  getConfigValue() {
    const viewConfigWithoutEdit = this.getViewConfigWithoutEdit();
    const viewConfigWithEdit = this.getViewConfigWithEdit();
    const editConfig = this.getEditConfig();
    const hasEditPermission = this.props.devicesPermissionAccess === PermissionAccess.Edit;

    if (!hasEditPermission) {
      return viewConfigWithoutEdit;
    }

    if (this.state.edit) {
      return editConfig;
    }

    const deviceArchivedOrUnknown = this.state.deviceDetails.archived === undefined
      || this.state.deviceDetails.archived;

    if (deviceArchivedOrUnknown) {
      return viewConfigWithoutEdit;
    }

    return viewConfigWithEdit;
  }

  render() {
    const pathname = this.getBreadcrumbPath();
    const configValue = this.getConfigValue();

    return (
      <AdminCard
        edit={this.state.edit}
        url={this.baseUrl()}
        className={cx(
          styles.wrapper,
          this.state.edit && styles.editWrapper
        )}
        onToggleEdit={this.toggleEdit}
        save={this.save}
        config={configValue}
        pathName={pathname}
        headerAccessor="name"
        options={{
          callback: this.handleCallback,
          alterData: this.handleAlterData,
          requiredKeys: [],
        }}
        disableEdit={this.state.isTabletLoggedIn}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  regions: state.assignedRegionList,
  devicesPermissionAccess: state.adminPermissionAccess[DEVICES_TEXT],
  currentRegion: state.currentRegion,
});

type Location = {
  pathname: string;
};
type Match = {
  params: { id: string };
};
export interface DeviceDetailViewProps {
  updateAdminTableData: typeof updateDataTableRecord,
  match: Match,
  closeModal: typeof closeModal,
  openModal: typeof openModal,
  devicesPermissionAccess?: string,
  regions: RegionDto[],
  currentRegion: UrlKeyDto,
  location: Location
}

export default connect<any, any, any>(mapStateToProps, {
  updateAdminTableData: updateDataTableRecord,
  openModal,
  closeModal,
})(DeviceDetailView);
