import React, { RefObject } from 'react';
import 'react-table/react-table.css';
import cx from 'classnames';
import { connect } from 'react-redux';
import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import styles from './AppSwitcher.module.css';
import CardImage from './forms/CardImage';
import { ReduxState } from '../../store';
import { APP_SWITCHER_SELECTED } from '../../constants/appConstants';
import setSidebarSelected from '../../store/actions/sidebarActions';
import { AppPath, ConnectedFunction, TruckTraxApps } from '../../types';
import { hasAppAccessInAnyRegion, RegionAccessMap } from '../../util/permissionUtil';
import { apps } from '../../util/appUtil';

export const launchApp = (app: AppPath) => {
  // Re-align window.name based on current path, because there are a few ways it can get out of sync
  const path = window.location.href;
  if (path.includes(TruckTraxApps[TruckTraxApps.GeoTrax].toLowerCase())) {
    window.name = TruckTraxApps[TruckTraxApps.GeoTrax];
  } else if (path.includes(TruckTraxApps[TruckTraxApps.Admin].toLowerCase())) {
    window.name = TruckTraxApps[TruckTraxApps.Admin];
  } else if (TruckTraxApps[TruckTraxApps.ScaleTrax].toLowerCase()) {
    window.name = TruckTraxApps[TruckTraxApps.ScaleTrax];
  }

  const tab = window.open(undefined, app.name);
  if (!tab) { // tab should always exist, but this check makes TS compiler happy
    return;
  }

  // At this point, tab will point to the window object of either a blank new tab (scenario 1)
  // or an existing tab if you already had one where window.name == app.name (scenarios 2 and 3)

  if (tab.location.href === 'about:blank') {
    // scenario 1 of 3:
    // A new tab was opened because a tab with a matching name wasn't found
    tab.location.href = app.path; // performs location redirect in new tab
  } else if (window.name === tab.name) {
    // scenario 2 of 3:
    // Not only was an existing tab found with a matching name, but it was the current tab.
    // E.g. the user clicked Geotrax while they were already on Geotrax
    // Let's refresh the page for them
    window.location.reload();
  } else {
    // scenario 3 of 3:
    // An existing tab with matching name was found, and focus has already been switched (by window.open)
    // so nothing else needs to happen
  }

  // calling tab.focus() doesn't seem to be necessary in Chrome
  // TODO: test in Edge and remove if not necessary
  tab.focus();
};

type AppSwitcherState = {
  anchorEl: HTMLElement | null | undefined;
};

export class AppSwitcher extends React.Component<AppSwitcherProps, AppSwitcherState> {
  anchorRef: RefObject<HTMLDivElement>;

  constructor(props: AppSwitcherProps) {
    super(props);

    this.anchorRef = React.createRef();
    this.state = {
      anchorEl: null,
    };
  }

  static hasAppAccess = (app: AppPath, userPermission: RegionAccessMap) => hasAppAccessInAnyRegion(app.type, userPermission);

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        anchorEl: this.anchorRef.current?.parentElement,
      });
    }, 50);
  }

  render() {
    const { appSwitcherIsOpen, userPermission } = this.props;
    const validApps = apps.filter(app => AppSwitcher.hasAppAccess(app, userPermission!));
    if (validApps.length < 2) {
      return null;
    }

    return (
      <div className={styles.wrapper} ref={this.anchorRef}>
        <Popover
          classes={{ paper: styles.paper }}
          open={appSwitcherIsOpen}
          onClose={() => this.props.setSidebarSelected(APP_SWITCHER_SELECTED, false)}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          anchorEl={this.state.anchorEl}
        >
          <div className={styles.header}>
            <span className={styles.headerText}>TRUCKTRAX APPS</span>
          </div>
          <span className={styles.arrow} />
          <Table>
            <TableBody>
              {validApps.map(app => (
                <TableRow
                  hover
                  key={app.name}
                  onClick={() => launchApp(app)}
                >
                  <TableCell className={styles.openAppButton}>
                    <CardImage
                      src={app.horizontalIcon}
                      className={styles.appLogo}
                    />
                    <i className={cx('icon-new-tab', styles.newWindowIcon)} />
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </Popover>
      </div>
    );
  }
}

export const mapStateToProps = (state: Partial<ReduxState>) => ({
  appSwitcherIsOpen: (state.sidebar?.isSelected && state.sidebar?.sidebarTitle === APP_SWITCHER_SELECTED),
  userPermission: state.userPermission,
});

type AppSwitcherReduxStateProps = ReturnType<typeof mapStateToProps>;
type AppSwitcherReduxDispatchProps = {
  setSidebarSelected: ConnectedFunction<typeof setSidebarSelected>
};

export type AppSwitcherProps = AppSwitcherReduxDispatchProps & AppSwitcherReduxStateProps;

export default connect<any, any, any>(mapStateToProps, {
  setSidebarSelected,
})(AppSwitcher);
