import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Breakpoint } from '@mui/material';
import React from 'react';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import { ContentHeader } from '@trucktrax/trucktrax-common';
import styles from './AddFeatureModal.module.css';

/**
 * You can close this dialog by clicking outside the dialog, or with the 'Esc' key.
 */
function AddFeatureModal(props: FeatureModalProps) {
  const {
    isOpen = true,
    style = '',
    disableEnforceFocus = false,
    children,
    title,
    onCancel,
    modalSize,
  }: FeatureModalProps = props;

  const titleElement = (
    <ContentHeader
      onClose={onCancel}
      title={title}
      data-test="cancel-add-feature-flag-button"
    />
  );

  // styles
  const dialogStyles = {
    paper: style,
  };
  const dialogTitleStyles = {
    root: styles.dialogTitle,
  };
  const dialogContentTextStyles = {
    root: styles.dialogContentText,
  };

  return (
    <div>
      <Dialog
        fullWidth={!!modalSize}
        maxWidth={modalSize}
        classes={dialogStyles}
        open={isOpen}
        disableEnforceFocus={disableEnforceFocus}
      >
        <DialogTitle classes={dialogTitleStyles}>
          {titleElement}
        </DialogTitle>
        <DialogContent
          classes={dialogContentTextStyles}
        >
          <DialogContentText component="div">
            {children}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export interface FeatureModalProps {
  children: any,
  title: string,
  onCancel: VoidFunc,
  isOpen?: boolean,
  style?: string,
  disableEnforceFocus?: boolean,
  modalSize?: Breakpoint,
}

export default AddFeatureModal;
