import React, { ReactNode } from 'react';

import styles from './Header.module.css';

export function Header(props: HeaderProps) {
  const {
    title = '',
    subtitle = null,
  } = props;

  if (!title) {
    return null;
  }
  return (
    <div className={styles.titleComponent}>
      <h5
        data-test="section-title"
        className={styles.title}
      >
        {title}
      </h5>
      <div className={styles.subTitle}>
        {subtitle}
      </div>
    </div>
  );
}

export interface HeaderProps {
  title?: string;
  subtitle?: ReactNode
}

export default Header;
