import React, { ComponentType } from 'react';
import CardMap from './CardMap';
import CardMapMultizones from './CardMapMultizones';
import CardDisplay from './CardDisplay';
import CardMatch from './CardMatch';
import CardRadio from './CardRadio';
import CardImage from './CardImage';
import CardInput from './CardInput';
import CardSelect from './CardSelect';
import CardSwitch from './CardSwitch';
import CardMultiSelect from './CardMultiSelect';
import CardCheckbox from './CardCheckbox';
import {
  CARD_SWITCH,
  CHECKBOX_FORM,
  CHECKBOX_STATELESS_FORM,
  CUSTOM_COMPONENT,
  DROPDOWN_FORM,
  IMAGE_DISPLAY,
  INPUT_FORM,
  NUMERIC_INPUT_FORM,
  MAP_FORM,
  MAP_MULTIZONES_FORM,
  MULTIDROPDOWN_FORM,
  RADIO_FORM,
  TEXT_DISPLAY,
  VALUE_MATCH_DISPLAY,
} from '../../../constants/appConstants';
import CardNumericInput from './CardNumericInput';
import CardCheckboxStateless from './CardCheckboxStateless';

export interface DynamicFormsProps {
  type: string;
  id: React.Key;
  component?: ComponentType<any> | null;
  [componentProp: string]: any;
}

export default function DynamicForms(props: DynamicFormsProps) {
  const {
    type,
    id,
    component: Component = null,
  } = props;

  switch (type) {
    case IMAGE_DISPLAY:
      return <CardImage key={id} {...props} />;
    case TEXT_DISPLAY:
      return <CardDisplay key={id} {...props} />;
    case VALUE_MATCH_DISPLAY:
      return <CardMatch key={id} {...props as any} />;
    case RADIO_FORM:
      return <CardRadio key={id} {...props as any} />;
    case CHECKBOX_FORM:
      return <CardCheckbox key={id} {...props as any} />;
    case CHECKBOX_STATELESS_FORM:
      return <CardCheckboxStateless key={id} {...props as any} />;
    case INPUT_FORM:
      return <CardInput key={id} {...props as any} />;
    case NUMERIC_INPUT_FORM:
      return <CardNumericInput key={id} {...props as any} />;
    case DROPDOWN_FORM:
      return <CardSelect key={id} {...props as any} />;
    case MULTIDROPDOWN_FORM:
      return <CardMultiSelect key={id} {...props as any} />;
    case MAP_FORM:
      return <CardMap key={id} {...props as any} />;
    case MAP_MULTIZONES_FORM:
      return <CardMapMultizones key={id} {...props as any} />;
    case CARD_SWITCH:
      return <CardSwitch key={id} {...props as any} />;
    case CUSTOM_COMPONENT:
      return Component && <Component key={id} {...props as any} />;
    default:
      return null;
  }
}
