import { isNumber } from 'lodash';
import { GeometryDto, GeoZoneDto } from '@trucktrax/trucktrax-ts-common';
import { ADMIN_KEYS, ADMIN_LABELS, GEOZONE_TYPE } from '../constants/appConstants';

export const valueHasChanged = (prev: any, curr: any) => {
  if (!prev || !curr
    || !Object.keys(prev).length
    || !Object.keys(curr).length) return false;

  const changes = Object.keys(curr).filter(key => prev[key] !== curr[key]);

  return !!changes.length;
};

export const geoZoneIsValid = (geozone: Pick<GeoZoneDto, 'zone'>) => {
  const hasCircle = !!geozone.zone?.circle;
  const hasPolygon = !!geozone.zone?.polygon;

  return hasCircle || hasPolygon;
};

// check if current value is different from previous's
// change is valid when the values are different
export const isChangedValid = (prev: any, curr: any) => {
  if (!prev || !curr
    || !Object.keys(prev).length
    || !Object.keys(curr).length
    || Object.keys(curr).length === 0) {
    return false;
  }

  const changes = Object.keys(curr).filter(key => {
    if (key === 'checked') return null;
    if (key === 'productLines' && typeof prev[key] === 'string' && Array.isArray(curr[key])) {
      const newFieldData = curr[key].sort().join(',');
      if (prev[key] !== newFieldData) {
        return key;
      }
      return null;
    }

    if (JSON.stringify(prev[key]) !== JSON.stringify(curr[key])) {
      return key;
    }
    return null;
  });
  if (!changes.length) return false;

  const isValid = changes.every(key => {
    if (typeof curr[key] === 'string' || typeof prev[key] === 'string') {
      if (prev[key] !== undefined) {
        return prev[key] !== curr[key];
      }

      try {
        return curr[key].trim() !== '';
      } catch {
        return curr[key] !== '';
      }
    }
    // this is specific for CHECKBOX_FORM checkboxes used in driver checklists
    // and it ends up in all CHECKBOX_FORM components; return true so save button
    // stays lit up even when CHECKBOX_FORM components are used
    if (key === 'productLines') {
      return true;
    }

    return JSON.stringify(prev[key]) !== JSON.stringify(curr[key]);
  });

  return isValid;
};

// validate whether all values in required field are NOT null,an empty string, or undefined.
export const areAllRequiredValuesValid = (keysArray: string[], state: any) => keysArray.every(key => {
  const value = typeof state[key] === 'boolean' ? `${state[key]}` : state[key];
  if (!value) return false;
  if (key === 'geozones') {
    // This key can come from either the plant add, or the plant detail page
    // unfortunately the data is in completely different formats, handled separately here.
    const geozonesPayload = value as ({ plant: GeometryDto, inqueue: GeometryDto, return: GeometryDto } | GeoZoneDto[]);
    let hasAllValidZones = true;
    if ('plant' in geozonesPayload && 'inqueue' in geozonesPayload && 'return' in geozonesPayload) {
      // We are coming from the add plant modal; we will do detail page geozone validation after the save button click
      const geozonesArray = [{ zone: geozonesPayload.plant }, { zone: geozonesPayload.inqueue }, { zone: geozonesPayload.return }];
      hasAllValidZones = geozonesArray.every(g => geoZoneIsValid(g));
    }
    return hasAllValidZones;
  }
  if (key === ADMIN_LABELS.GEOFENCE) {
    return geoZoneIsValid(value);
  }

  if (key === ADMIN_KEYS.HAULERS) {
    return state[ADMIN_KEYS.HAULERS] && state[ADMIN_KEYS.HAULERS].length > 0;
  }

  const isValidStr = typeof value === 'string' && value.trim() !== '';
  const isValidUrlOrIdObj = typeof value === 'object' && (value && value.url !== '' && value.id !== '');
  const isValidInt = typeof value === 'number' && isNumber(value);

  return isValidStr || isValidUrlOrIdObj || isValidInt;
});

export const checkNullInRequired = (value: any) => {
  if (value === '' || value === undefined) {
    return 'This field is required';
  }
  return '';
};

export const validateHaulersDropdown = (value: Array<any> | undefined) => {
  if (!value || !value.length) {
    return 'This field is required';
  }

  return '';
};

/**
 * Perform zone requirement for each item in geozones
 */
export const isEveryZoneValid = (geozones: GeoZoneDto[]) => {
  const invalidZone = geozones.find(key => !geoZoneIsValid(key));
  if (!invalidZone) return null;
  const name = invalidZone.name === GEOZONE_TYPE.INQUEUE
    ? 'an In Queue'
    : `a ${invalidZone.name!.charAt(0).toUpperCase()}${invalidZone.name!.slice(1)}`;
  return {
    zone: invalidZone.name,
    message: `You must draw ${name} Geofence.`,
  };
};
