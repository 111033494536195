import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import {
  constants, FolderList, IconTitleUnderline,
  DateWrapper,
} from '@trucktrax/trucktrax-common';
import {
  PlantDto, PositionDto, ProductLine, StatusEventDto, TicketDto,
} from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import { fetchStatusList } from '../../../../services/statusesService';
import styles from './RouteInfo.module.css';
import Route from './RouteInfoMap';
import { openFailModal } from '../../../../store/actions/errorModalActions';
import { StatusFolder, statusFolderList, removePositionsWithDuplicateMinutes } from '../../../../util/ticketUtil';
import {
  aggFolderList,
  cementFolderList,
  readyMixFolderList,
  retrieveProductLineNameDiff,
} from '../../../../util/statusesUtil';
import {
  AGGREGATE_STRING,
  CEMENT_STRING,
  READYMIX_VALUE,
  STATUSES_STRING,
  TICKET_STATUS_CLOSED_DRIVER,
  TICKET_STATUS_CLOSED_SYSTEM,
  TICKET_STATUS_OPEN,
} from '../../../../constants/appConstants';
import { getLocations } from '../../../../services/geolocationsService';

const { STATUS_IDS } = constants;

export class RouteInfo extends Component<RouteInfoProps, RouteInfoState> {
  static defaultProps = {
    selectedProductLine: undefined,
  };

  constructor(props: RouteInfoProps) {
    super(props);
    this.state = {
      foldersOpen: {
        Ticketed: false,
        ToJob: false,
        OnJob: false,
        Unloaded: false,
        Returning: false,
        InYard: false,
        Unknown: false,
      },
      selectedItem: null,
      mapCentered: true,
      driverClosed: false,
    };
  }

  async componentDidMount() {
    await this.getRouteStatuses();
  }

  componentWillUnmount() {
    clearInterval(this.state as any);
  }

  getRouteStatuses = async () => {
    // GET route statuses
    const { selectedTicket } = this.props;
    const statusList = await this.props.fetchStatusList(selectedTicket);
    // Determine if statuses attested
    const inYardStatus = statusList?.filter(i => i.status === 'InYard').pop();
    this.setState({
      driverClosed: inYardStatus?.driverAttested ?? false,
    });
    // Map returned statuses, sort chronologically and push to state
    const sortedStatuses = statusList
      ?.map((i): StatusFolder => ({
        name: i.status!,
        time: i.timestamp!,
        itemList: [],
        color: '',
        dataTest: '',
        icon: '',
        id: i.id.toString(),
        text: '',
        location: i.location,
      }))
      .filter(i => i.name !== 'DeliveryStart') // Ignore non-UI statuses
      .sort((x, y) => DateWrapper.sort(x.time, y.time));
    await this.getPositionsForAllStatuses(sortedStatuses ?? []);
  };

  getPositionsForAllStatuses = async (sortedStatuses: StatusFolder[]) => {
    const positions: PositionDto[] = [];

    await Promise.all(sortedStatuses.map(async (status, index) => {
      const nextStatus = sortedStatuses[index + 1];
      let nextStatusTime = nextStatus?.time;
      if (status.name === constants.STATUS_IDS.IN_YARD && this.props.selectedTicket?.closedTimestamp) {
        nextStatusTime = this.props.selectedTicket.closedTimestamp;
      }

      const positionsForOneStatus = await this.getPositionsForStatus(sortedStatuses, status, status.time!, nextStatusTime);
      positions.push(...positionsForOneStatus);
    }));

    this.setState({
      positions: { data: { items: positions } },
      statuses: sortedStatuses,
    });
  };

  // GET historical route and sort timestamps by status
  getPositionsForStatus = async (sortedStatuses: StatusFolder[], statusFolder: StatusFolder, startTime: string, endTime?: string) => {
    const { selectedTicket } = this.props;
    const currentStatus = statusFolder;

    // GET route locations for the timestamps of this status
    let positions = await getLocations(selectedTicket?.url, startTime, endTime);
    positions = removePositionsWithDuplicateMinutes(positions);

    positions.forEach((item: PositionDto, index: number) => {
      this.addLocationItemToFolder(currentStatus, item, index, positions.length);
    });

    // if we do not have clustered geolocations returned, but current status has a location stored with it, add it to the folder
    if (!positions.length && currentStatus.location) {
      const item = {
        id: -1, // normally id of record returned from geolocations, not currently referenced after this
        ticket: selectedTicket,
        speed: 0,
        bearing: 0,
        active: true,
        timestamp: currentStatus.time,
        location: currentStatus.location,
        productLine: this.props.selectedProductLine!,
      } as PositionDto;
      this.addLocationItemToFolder(currentStatus, item, 0, 1);
    }

    return positions;
  };

  addLocationItemToFolder = (currentStatus: StatusFolder, item: PositionDto, index: number, count: number) => {
    const statusNameMap = retrieveProductLineNameDiff(this.props.selectedProductLine!);
    // Convert timestamp to human readable time (e.g. 12:37 PM)
    const textTime = RouteInfo.getReadableTimestamp(item.timestamp);
    const productLine = this.getFolderListOfProductLine().filter(i => i.id === currentStatus?.name)[0];

    const itemObj = {
      id: item.id.toString(),
      name: textTime,
      historical: true,
      color: productLine?.color,
      position: item,
    };

    // push the item to the list
    currentStatus.itemList.push(itemObj);
    const positionStatus = statusNameMap[currentStatus.name];

    // Update state.positions to include status for timestamp
    // All items historical unless ticket is open and last timestamp is ToJob/Returning
    item.status = currentStatus.name;
    item.historical = true;

    if (
      this.props.ticketTimeline === 'current'
      && (positionStatus === statusNameMap[STATUS_IDS.TO_JOB]
        || positionStatus === statusNameMap[STATUS_IDS.RETURNING])
      && index === count - 1
    ) {
      item.historical = false;
      // Update the corresponding statusList item to historical=false for folderList
      const currentId = item.id.toString();
      // eslint-disable-next-line array-callback-return
      if (positionStatus === statusNameMap[STATUS_IDS.TO_JOB]
        || positionStatus === statusNameMap[STATUS_IDS.RETURNING]) {
        currentStatus.itemList.forEach((timestamp: any) => {
          if (timestamp.id === currentId) {
            timestamp.historical = false;
          }
        });
      }
    }
  };

  static getReadableTimestamp = (timestamp: string | undefined) => new DateWrapper(timestamp).toString('h:mm a');

  getFolderList = (ticketStatuses?: StatusFolder[]) => statusFolderList(ticketStatuses ?? [], this.getFolderListOfProductLine());

  getFolderListOfProductLine = () => {
    switch (this.props.selectedProductLine) {
      case READYMIX_VALUE:
        return readyMixFolderList(STATUSES_STRING);
      case CEMENT_STRING:
        return cementFolderList(STATUSES_STRING);
      case AGGREGATE_STRING:
        return aggFolderList(STATUSES_STRING);
      default:
        return [];
    }
  };

  setFoldersOpen = (updated: FoldersOpenState) => {
    this.setState({ foldersOpen: updated });
  };

  static getTitle = (icon: string, title: string, value?: string, className?: string) => (
    <div className={className}>
      <div className="flex-vertical-center">
        <i className={cx(icon, styles.markerIcon, 'margin-right-10')} />
        <span className="txt-bold">{title}</span>
      </div>
      <div className={styles.titleValue}>{value}</div>
    </div>
  );

  getTicketClosedBy = (closed: boolean) => {
    if (this.props.selectedProductLine !== READYMIX_VALUE) {
      return null;
    }
    const icon = closed ? 'icon-check-circle' : 'icon-more-horiz';
    const { driverClosed } = this.state;
    let message = TICKET_STATUS_OPEN;
    if (closed) {
      message = driverClosed ? TICKET_STATUS_CLOSED_DRIVER : TICKET_STATUS_CLOSED_SYSTEM;
    }
    return (
      <div className="margin-top-10">
        <div className="flex-vertical-center">
          <i className={cx(icon, styles.markerIcon, 'margin-right-10')} />
          <span>{message}</span>
        </div>
      </div>
    );
  };

  selectItem = (item: any) => {
    if (this.state.selectedItem === null || this.state.selectedItem !== item.id) {
      this.setState({ selectedItem: item.id });
    } else {
      this.setState({ selectedItem: null });
    }
  };

  updateSelectedItem = (position: any) => {
    const statusNameMap = retrieveProductLineNameDiff(this.props.selectedProductLine!);
    const stringId = position.id.toString();

    // if the selected item had status id of "ToJob", then open the "To Job" folder
    const folderId = this.getFolderListOfProductLine()
      .find((pl) => statusNameMap[pl.id] === statusNameMap[position.status])?.id;

    const nextFoldersOpen = folderId ? {
      ...this.state.foldersOpen,
      [folderId]: true,
    } : this.state.foldersOpen;

    this.setState({
      selectedItem: stringId,
      foldersOpen: nextFoldersOpen,
      mapCentered: true,
    });
  };

  clearSelectedItem = () => this.setState({ selectedItem: null });

  recenterMap = () => this.setState({ mapCentered: true });

  render() {
    const { selectedTicket, plantList } = this.props;
    const startPlant = plantList.find(plant => plant.url === selectedTicket?.plant?.url);
    const isTicketClosed = !!selectedTicket?.closed;

    return (
      <div className={styles.ticketContentContainer}>
        <h2 className={styles.ticketRouteHeader}>Route Info</h2>
        <div className={styles.routeInfoContainer}>
          <Paper className={styles.routeInfoSidebar}>
            <div className="h5">
              {RouteInfo.getTitle(
                'icon-home',
                'Start Plant',
                startPlant?.name,
                ''
              )}
              {RouteInfo.getTitle(
                'icon-flag',
                'Jobsite',
                selectedTicket?.order?.customerName,
                'margin-top-10'
              )}
              {this.getTicketClosedBy(isTicketClosed)}
            </div>
            <IconTitleUnderline
              className="margin-top-40"
              icon="icon-access-time"
              title="Status History"
            />
            <FolderList
              folderList={this.getFolderList(this.state.statuses)}
              onFolderClick={this.setFoldersOpen as any}
              onItemClick={this.selectItem}
              selectedItemId={this.state.selectedItem}
              openStateMap={this.state.foldersOpen as any}
              isFolderNameBold={false}
            />
          </Paper>
          {this.state.positions
            && (
              <div className={styles.mapContainer}>
                <Route
                  statusFolders={this.getFolderList(this.state.statuses)}
                  productLineFolders={this.getFolderListOfProductLine()}
                  positionsList={this.state.positions}
                  selectedItemId={this.state.selectedItem}
                  updateSelectedItem={this.updateSelectedItem}
                  clearSelectedItem={this.clearSelectedItem}
                  recenterMap={this.recenterMap}
                  mapCentered={this.state.mapCentered}
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}

interface FoldersOpenState {
  Ticketed: boolean,
  ToJob: boolean,
  OnJob: boolean,
  Unloaded: boolean,
  Returning: boolean,
  InYard: boolean,
  Unknown: boolean
}

export interface RouteInfoProps {
  selectedTicket: TicketDto,
  plantList: PlantDto[],
  ticketTimeline: string,
  fetchStatusList: (ticket: TicketDto) => Promise<StatusEventDto[]>,
  selectedProductLine?: ProductLine,
}

export interface RouteInfoState {
  positions?: {
    data: {
      items: PositionDto[]
    }
  },
  foldersOpen: FoldersOpenState,
  statuses?: StatusFolder[],
  selectedItem: any,
  mapCentered: boolean,
  driverClosed: boolean,
}

function mapStateToProps(state: any) {
  return {
    selectedTicket: state.selectedTicket,
    plantList: state.plantList,
    ticketTimeline: state.ticketTimeline,
    selectedProductLine: state.selectedProductLine,
  };
}

export default connect<any, any, any>(mapStateToProps, {
  openFailModal,
  fetchStatusList,
})(RouteInfo);
