import axios from 'axios';
import { Dispatch } from 'redux';
import { HttpErrorResponse } from '@trucktrax/trucktrax-ts-common';
import recordLog from '../util/errorUtil';
import { deleteTokens, setTokens } from '../util/authUtil';
import { clearAuthTenantName, setAuthTenantName } from '../store/actions/authActions';
import { setAssignedRegionList } from '../store/actions/regionActions';
import { replaceAllFlags } from '../store/actions/featureFlagsActions';
import { setFeatureFlagsWithUserUrl } from './featureFlagsService';
import { setFlagsAction } from '../util/flagUtil';
import { messageSocketDisconnect } from './messagesService';
import { ticketSocketDisconnect } from './ticketsService';
import { trackingSocketDisconnect } from './mapviewsService';
import { fetchPermissionsListByUserUrl } from './permissionsService';
import { closeSnackbar, openSnackbar, openSnackbarNotification } from '../store/actions/snackbarActions';
import { closeModal, openFailModal } from '../store/actions/errorModalActions';
import {
  postRequest, putRequest, forceLogOut,
} from './requestService';
import {
  AUTH_ERROR,
  AUTH_USER,
  CLEAR_MESSAGES,
  EMPTY_ALL_FIELDS,
  LOGIN_PENDING,
  LOGOUT_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_OK,
  SET_ACCOUNT_LOCKED,
  SET_USER_PASSWORD_EXPIRED,
} from '../constants/actionConstants';
import {
  ERROR_TEXT_LOGOUT_REGION, ERROR_TEXT_USER_LOGOUT, INVALID_CREDENTIALS,
  SERVER_ERROR,
  USER_LOCKED_OUT,
  USER_PASSWORD_EXPIRED,
  USER_PASSWORD_EXPIRED_MESSAGE,
  USER_UNAUTHORIZED,
} from '../constants/errorConstants';
import {
  FEATURE_FLAG_OBJECT,
  IS_MESSAGE_WINDOW_OPEN,
  REFRESH_TOKEN,
} from '../constants/localStorageConstants';
import {
  ACCOUNT_IN_USE,
  ACCOUNT_LOCKED,
  CLIENT_ID,
  COMPANY_NOT_FOUND,
  COMPANY_NOT_FOUND_MESSAGE,
  USER_IS_DEACTIVATED,
  GRANT_TYPE,
  LOGOUT_ALL_SUCCESS_TEXT,
  SUCCESS,
  WCS,
  USER_LOGOUT_SUCCESS_TEXT,
  NEW_MESSAGE_WINDOW,
  INVALID_CREDENTIALS_ERROR,
  USER_LOCKED_OUT_ERROR,
  USER_PASSWORD_EXPIRED_ERROR,
  ACCOUNT_IN_USE_ERROR,
  USER_IS_DEACTIVATED_ERROR,
} from '../constants/appConstants';
import { getAuthBaseUrl } from '../util/apiUtil';
import { getUserRequest } from './usersService';
import { AuthTokenPayload } from '../types';
import { setFavicon } from '../util/appUtil';

type GetReauthorizeState = () => {
  authTenantName: string,
  sessionId: string
};

export interface LoginData {
  email?: string;
  password?: string;
  tenantName?: string;
  isSaml?: boolean;
}

type GetLoginState = () => { authTenantName: string };
type LoginResponse = {
  data: AuthTokenPayload,
  headers: {
    sessionid: string
  };
};

type GetLogoutState = () => {
  sessionId: string,
  refreshToken: string,
  authTenantName: string
};

interface LogoutDriverInfo {
  firstName?: string;
  lastName?: string;
  driver?: {
    firstName?: string,
    lastName?: string,
    url?: string
  };
  trackedObject?: {
    truckAlias?: string
  };
}

interface LogoutUserInfo {
  firstName?: string;
  lastName?: string;
  id?: string
}

type GetLogoutAllState = () => {
  currentRegion?: {
    url?: string
  }
};

interface ResetPasswordData { newPassword: string }

type GetResetPasswordState = () => {
  sessionId: string,
  username: string
};

export function reauthorize() {
  return async (dispatch: Dispatch<any>, getState: GetReauthorizeState) => {
    try {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);
      const { authTenantName, sessionId } = getState();
      const url = getAuthBaseUrl();
      const response = await axios.post(url, {}, {
        params: {
          client_id: CLIENT_ID,
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          userType: 'user',
          tenant_name: authTenantName,
        },
        headers: {
          Authorization: `Basic ${WCS}`,
          sessionId,
        },
      });

      const { data, headers } = response;
      setTokens(dispatch, data, headers.sessionid);
    } catch (e) {
      forceLogOut(USER_UNAUTHORIZED);
    }
  };
}

const handle401 = (error: HttpErrorResponse, dispatch: Dispatch) => {
  const message = error.response?.data?.message;
  if (!message) {
    dispatch({
      type: AUTH_ERROR,
      payload: INVALID_CREDENTIALS,
    });
    return;
  }

  switch (message.toUpperCase().trim()) {
    case COMPANY_NOT_FOUND.toUpperCase().trim():
      dispatch({
        type: AUTH_ERROR,
        payload: COMPANY_NOT_FOUND_MESSAGE,
      });
      dispatch(clearAuthTenantName());
      return;
    case ACCOUNT_LOCKED.toUpperCase().trim():
      dispatch({
        type: AUTH_ERROR,
        payload: USER_LOCKED_OUT,
      });
      return;
    default:
      dispatch({
        type: AUTH_ERROR,
        payload: INVALID_CREDENTIALS,
      });
      break;
  }
};

const handle403 = (error: HttpErrorResponse, dispatch: Dispatch) => {
  const message = error.response?.data?.message;
  if (!message) {
    dispatch({
      type: AUTH_ERROR,
      payload: INVALID_CREDENTIALS,
    });
    return;
  }

  switch (message.toUpperCase().trim()) {
    case ACCOUNT_IN_USE.toUpperCase().trim():
      dispatch({
        type: AUTH_ERROR,
        payload: ACCOUNT_IN_USE,
      });
      break;
    case USER_IS_DEACTIVATED.toUpperCase().trim():
      dispatch({
        type: AUTH_ERROR,
        payload: USER_IS_DEACTIVATED,
      });
      return;
    case ACCOUNT_LOCKED.toUpperCase().trim():
      dispatch({ type: SET_ACCOUNT_LOCKED });
      return;
    case USER_PASSWORD_EXPIRED.toUpperCase().trim():
      dispatch({
        type: SET_USER_PASSWORD_EXPIRED,
        payload: USER_PASSWORD_EXPIRED_MESSAGE,
      });
      return;
    default:
      dispatch({
        type: AUTH_ERROR,
        payload: INVALID_CREDENTIALS,
      });
      break;
  }
};

interface ServerError {
  response?: {
    status?: number,
    data?: string
  }
}

const handle500 = (error: ServerError, dispatch: Dispatch) => {
  const messageArray = error.response?.data?.split('\n');
  const message = messageArray && messageArray.length > 0 ? messageArray[0] : '';

  // Compare against the error message to determine the error to display
  switch (message.toUpperCase().trim()) {
    case INVALID_CREDENTIALS_ERROR.toUpperCase().trim():
      dispatch({
        type: AUTH_ERROR,
        payload: INVALID_CREDENTIALS,
      });
      return;
    case USER_LOCKED_OUT_ERROR.toUpperCase().trim():
      dispatch({
        type: AUTH_ERROR,
        payload: USER_LOCKED_OUT,
      });
      return;
    case USER_PASSWORD_EXPIRED_ERROR.toUpperCase().trim():
      dispatch({
        type: SET_USER_PASSWORD_EXPIRED,
        payload: USER_PASSWORD_EXPIRED_MESSAGE,
      });
      return;
    case ACCOUNT_IN_USE_ERROR.toUpperCase().trim():
      dispatch({
        type: AUTH_ERROR,
        payload: ACCOUNT_IN_USE,
      });
      return;
    case USER_IS_DEACTIVATED_ERROR.toUpperCase().trim():
      dispatch({
        type: AUTH_ERROR,
        payload: USER_IS_DEACTIVATED,
      });
      return;
    default:
      dispatch({
        type: AUTH_ERROR,
        payload: SERVER_ERROR,
      });
      break;
  }
};

export function logIn({
  email: username, password, tenantName, isSaml
}: LoginData) {
  return async (dispatch: Dispatch<any>, getState: GetLoginState) => {
    const { authTenantName } = getState();

    let response;
    let url;
    const loginTenantName = tenantName || authTenantName;
    try {
      if (!isSaml) {
        // NORMAL USERNAME/PASSWORD LOGIN
        url = getAuthBaseUrl();
        response = await axios.post<any, LoginResponse>(url, {
          username,
          password,
        }, {
          params: {
            client_id: CLIENT_ID,
            grant_type: GRANT_TYPE,
            userType: 'user',
            tenant_name: loginTenantName,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${WCS}`,
          },
          withCredentials: true,
        });
      } else {
        // WINDOWS AUTH COMPLETE ALREADY
        // at this point the user is already logged in on the server
        // transparently send the HttpCookies that contain our tokens and login payload
        // get them back as the regular headers for normal web login
        url = `${getAuthBaseUrl()}/token_exchange`;
        response = await axios.post<any, LoginResponse>(url, {
          params: {
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${WCS}`,
          },
          withCredentials: true,
        });
      }
      const { data, headers } = response;

      // redirecting happens automatically from react router v4 (LoginView and PrivateRoute component)
      // fetch regions also happens when the map is mounted
      // messageSocketInit happens when App gets mounted
      if (response.data.isPasswordTemporary) {
        setTokens(dispatch, data, headers.sessionid);
        dispatch({ type: RESET_PASSWORD });
      } else {
        // Postpone the regions request so it doesn't overflow the limit of the auth response.
        setTokens(dispatch, data, headers.sessionid);
        const user = await getUserRequest(data.id);
        if (user !== null) {
          data.assignedRegions = user.regions;
        }
        dispatch(setAssignedRegionList(data.assignedRegions));
        const defaultRegion = data.assignedRegions!.filter(region => region.defaultRegion);
        setFeatureFlagsWithUserUrl(data.url, defaultRegion[0].url)
          .then();
        // fetch user permission by userUrl
        fetchPermissionsListByUserUrl(data.url)(dispatch);
        dispatch({ type: AUTH_USER }); // dispatch action by thunk
        dispatch({
          type: LOGIN_PENDING,
          payload: false,
        });
        // Hide any currently visible snackbars upon successful login
        dispatch(closeSnackbar());
      }
    } catch (error: any) {
      dispatch({
        type: LOGIN_PENDING,
        payload: false,
      });

      switch (error.response && error.response.status) {
        case 401:
          handle401(error, dispatch);
          break;
        case 403:
          handle403(error, dispatch);
          break;
        case 404:
          dispatch({
            type: AUTH_ERROR,
            payload: INVALID_CREDENTIALS,
          });
          break;
        case 500: // fall through to default
          handle500(error, dispatch);
          break;
        default:
          // shows server error in card
          dispatch({
            type: AUTH_ERROR,
            payload: SERVER_ERROR,
          });
      }
    }
  };
}

export function setTenantName({ email: username, password, tenantName }: LoginData) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const isValid = await isValidTenant(tenantName!);
      if (!isValid) {
        dispatch(clearAuthTenantName);
        dispatch({
          type: AUTH_ERROR,
          payload: COMPANY_NOT_FOUND_MESSAGE,
        });
        return;
      }
      dispatch(setAuthTenantName(tenantName!));
      dispatch({ type: AUTH_USER }); // dispatch action by thunk
      dispatch({
        type: LOGIN_PENDING,
        payload: false,
      });
      dispatch(closeSnackbar());
    } catch (e: any) {
      dispatch(clearAuthTenantName);
      dispatch({
        type: AUTH_ERROR,
        payload: COMPANY_NOT_FOUND_MESSAGE,
      });
    }
  };
}

const removeMessageWindow = () => {
  // Check if the message window is open
  const isWindowOpen = window.localStorage.getItem(IS_MESSAGE_WINDOW_OPEN);
  if (isWindowOpen === 'true') {
    // Close the window
    const openWindow = window.open('', NEW_MESSAGE_WINDOW);
    return openWindow?.close();
  }
  return null;
};

export function logOut() {
  return async (dispatch: Dispatch<any>, getState: GetLogoutState) => {
    const { sessionId, refreshToken, authTenantName } = getState();

    removeMessageWindow();
    deleteTokens(authTenantName);

    // refresh the title and icon
    document.title = 'TruckTrax';
    setFavicon('/');

    // disconnect from sockets
    messageSocketDisconnect();
    ticketSocketDisconnect();
    trackingSocketDisconnect();

    dispatch({ type: LOGOUT_USER });
    dispatch({ type: CLEAR_MESSAGES });
    dispatch({ type: EMPTY_ALL_FIELDS });
    dispatch(replaceAllFlags({ featureFlags: [] }));
    localStorage.removeItem(FEATURE_FLAG_OBJECT);
    dispatch(setFlagsAction({ defaultValue: false }));
    const url = `${getAuthBaseUrl()}/logout`;
    const params = { tokenId: refreshToken };
    recordLog('LogOut Attempt', url, 'success');
    try {
      await axios.post(url, {}, {
        params,
        headers: {
          Accept: 'application/json',
          Authorization: `Basic ${WCS}`,
          sessionId,
        },
        withCredentials: true,
      });
      recordLog('LogOut Success', url, 'success');
    } catch (e) {
      recordLog('LogOut Error', url, e);
    }
  };
}

export function logoutDriver(info: LogoutDriverInfo) {
  return async (dispatch: Dispatch) => {
    dispatch(openSnackbarNotification({
      payload: {
        firstName: info?.firstName || info?.driver?.firstName,
        lastName: info?.lastName || info?.driver?.lastName,
        truckAlias: info?.trackedObject?.truckAlias,
      },
      dataTest: 'snackbar-logout-driver-notification',
    }));

    const url = `${getAuthBaseUrl()}/logout-driver`;
    const params = { driverUrl: info?.driver?.url };
    await postRequest(url, {}, params);
  };
}

export function logoutUser(info: LogoutUserInfo) {
  return async (dispatch: Dispatch) => {
    const url = `${getAuthBaseUrl()}/logout-user`;
    const param = {
      id: info.id?.trim(),
    };

    const userFirstName = info.firstName;
    const userLastName = info.lastName;
    const errorText = `${ERROR_TEXT_USER_LOGOUT} ${userLastName}, ${userFirstName}`;

    try {
      await postRequest(url, {}, param);
      dispatch(closeModal());
      dispatch(openSnackbar({
        snackbarBody: `User ${userLastName}, ${userFirstName} ${USER_LOGOUT_SUCCESS_TEXT}`,
        dataTest: 'logout-user-success',
        snackbarType: SUCCESS,
      }));
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), errorText));
      recordLog('error', `${errorText} url:${url}`, e);
    }
  };
}

// logs out all drivers and users in current region
export function logoutAll() {
  return async (dispatch: Dispatch<any>, getState: GetLogoutAllState) => {
    const url = `${getAuthBaseUrl()}/logout-all`;
    const params = {
      region: getState()?.currentRegion?.url,
    };

    try {
      await postRequest(url, {}, params);
      dispatch(closeModal());
      dispatch(logOut());
      dispatch(openSnackbar({
        snackbarBody: LOGOUT_ALL_SUCCESS_TEXT,
        dataTest: 'logout-all-success',
        snackbarType: SUCCESS,
      }));
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_TEXT_LOGOUT_REGION));
      recordLog('error', `${ERROR_TEXT_LOGOUT_REGION} url:${url}`, e);
    }
  };
}

export function resetPassword({ newPassword }: ResetPasswordData) {
  return async (dispatch: Dispatch<any>, getState: GetResetPasswordState) => {
    const { sessionId, username } = getState();
    const url = `${getAuthBaseUrl()}/change-password`;
    const options = {
      headers: {
        sessionId,
      },
    };
    const body = { newPassword };
    try {
      await putRequest(url, body, {}, options);
      dispatch(logIn({
        email: username,
        password: newPassword,
      }));
      dispatch({ type: RESET_PASSWORD_OK });
      dispatch({
        type: LOGIN_PENDING,
        payload: false,
      });
    } catch (error: any) {
      if ((!!error.response) && (!!error.response.data)) {
        let payload = '';

        if (error && error.response && error.response.data
          && error.response.data.errors && error.response.data.errors.length > 0) {
          payload = error.response.data.errors[0].message;
        }
        dispatch({
          type: RESET_PASSWORD_FAIL,
          payload,
        });
      }
    }
  };
}

export async function isValidTenant(tenantName: string) {
  const url = `${getAuthBaseUrl()}/validate-tenant-name?tenantname=${tenantName}`;
  // allow it to throw
  const response = await axios.get<any, boolean>(url, {
    params: {
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${WCS}`,
    },
    withCredentials: true,
  });
  return response;
}
