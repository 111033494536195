import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Driver } from '@trucktrax/trucktrax-common';
import { ProductLine, UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import { MESSAGE_SELECTED } from '../../../constants/appConstants';
import { OPEN_SIDEBAR_MESSAGES } from '../../../constants/localStorageConstants';
import setSidebarSelected from '../../../store/actions/sidebarActions';
import { SidebarTitleType } from '../../../types';
import { noop } from '../../../util/appUtil';
import { TrucksToDriverMap } from './index.d';
import MessageCenterContainer from './MessageCenterContainer';
import styles from './MessageCenterPopover.module.css';

export class MessageCenterPopover extends Component<MessageCenterPopoverProps, MessageCenterPopoverState> {
  static defaultProps: Partial<MessageCenterPopoverProps> = {
    driverList: [],
    currentRegion: undefined,
    trucksToDriversMap: {},
    selectedProductLine: undefined,
    setSidebarSelected: noop,
  };

  // Setup the before unload event listener when the window is closed by the user
  static setupWindowCloseListener = () => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      window.localStorage.setItem(OPEN_SIDEBAR_MESSAGES, 'true');
    });
  };

  componentDidMount() {
    document.title = 'Messages - GeoTrax';
    this.props.setSidebarSelected!(MESSAGE_SELECTED, true);
    // Activate the event listener
    MessageCenterPopover.setupWindowCloseListener();
  }

  render() {
    return (
      <div className={styles.main}>
        <MessageCenterContainer
          driverList={this.props.driverList}
          currentRegion={this.props.currentRegion}
          trucksToDriversMap={this.props.trucksToDriversMap}
          selectedProductLine={this.props.selectedProductLine}
          isExpand
        />
      </div>
    );
  }
}

interface MessageCenterPopoverState {
  isMessageCenterOpen: boolean;
  isTicketsOpen: boolean;
}

export interface DriverWithProductLine extends Driver {
  primaryProductLine: ProductLine;
  secondaryProductLine: ProductLine;
}

export interface MessageCenterPopoverProps {
  driverList?: DriverWithProductLine[];
  currentRegion?: UrlKeyDto;
  trucksToDriversMap?: TrucksToDriverMap;
  selectedProductLine?: ProductLine;
  setSidebarSelected?: (sidebarTitle: SidebarTitleType, isSelected: boolean) => void;
}

function mapStateToProps(state: any) {
  return {
    currentRegion: state.currentRegion,
    selectedProductLine: state.selectedProductLine,
  };
}

export default connect<any, any, any>(mapStateToProps, { setSidebarSelected })(MessageCenterPopover);
