import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TTDatePicker, DateWrapper } from '@trucktrax/trucktrax-common/';
import cx from 'classnames';
import { setDateRange } from '../../../store/actions/dataTableActions';
import { fetchDataTableData } from '../../../services/dataTableService';
import styles from './DataTableDateRange.module.css';
import { DEFAULT_PAGE_SIZE } from '../../../constants/appConstants';
import { SimpleEvent } from '../../../types';
import { ReduxState } from '../../../store';
import { DataTableContentColumn } from '../DataTableContent';

export interface DataTableDateRangeState {
  startDate: Date;
  endDate: Date;
  pickerWidth: number | null;
  smallPickerWidth: boolean;
}

export class DataTableDateRange extends Component<
  DataTableDateRangeProps,
  DataTableDateRangeState
> {
  static defaultProps: Partial<DataTableDateRangeProps> = {
    currentRegion: {
      url: '',
      defaultRegion: false,
    },
    baseUrl: '',
    noRegionFilter: false,
    dataTableDateRange: undefined,
  };

  today: DateWrapper;

  datePickerCtRef: React.RefObject<HTMLDivElement>;

  constructor(props: DataTableDateRangeProps) {
    super(props);
    this.today = DateWrapper.now.startOfDay;
    this.state = {
      // the callback existence indicates datatabledaterange is being used in scaletraxticketslist which defaults to just today's tickets
      startDate: this.props.startDate ?? (this.props.callback ? this.today.date : this.today.previousWeekday.date),
      endDate: this.props.endDate ?? this.today.date,
      pickerWidth: null,
      smallPickerWidth: false
    };
    this.datePickerCtRef = React.createRef();
  }

  fetchData = () => {
    const { dataSources, source, columns } = this.props;
    const targetSource = dataSources[this.props.source];
    this.props.fetchDataTableData(
      targetSource?.baseUrl,
      this.props.noRegionFilter ? '' : this.props.currentRegion.url!,
      DEFAULT_PAGE_SIZE,
      1, // trucktrax microservices are not zero-based.,
      [],
      null,
      false,
      {},
      false,
      false,
      source,
      columns
    );
  };

  onStartDateChange = (event: Date) => {
    let startDate = event;
    const startDateIsValid = DateWrapper.isValidDate(startDate);

    const shouldAdjustToMaxPast = startDateIsValid
      && this.props.dataTableDateRange?.dateRange?.maxPast
      && startDate < this.props.dataTableDateRange.dateRange?.maxPast;

    if (shouldAdjustToMaxPast) {
      startDate = this.props.dataTableDateRange!.dateRange?.maxPast!;
    }

    const endDate = startDateIsValid && startDate > this.state.endDate
      ? startDate
      : this.state.endDate;

    this.updateDateState(startDate, endDate);
  };

  onEndDateChange = (event: Date) => {
    let endDate = event;
    const endDateIsValid = DateWrapper.isValidDate(endDate);

    const shouldAdjustToMaxFuture = endDateIsValid
      && this.props.dataTableDateRange?.dateRange.maxFuture
      && endDate > this.props.dataTableDateRange.dateRange.maxFuture;

    if (shouldAdjustToMaxFuture) {
      endDate = this.props.dataTableDateRange!.dateRange.maxFuture!;
    }

    const startDate = endDateIsValid && endDate < this.state.startDate
      ? endDate
      : this.state.startDate;
    this.updateDateState(startDate, endDate);
  };

  updateDateState = (startDate: Date, endDate: Date) => {
    this.setState(
      { startDate, endDate },
      () => {
        if (DateWrapper.isValidDate([this.state.startDate, this.state.endDate])) {
          this.handleDateRangeUpdate();
        }
      }
    );
  };

  // The on Blur event guarantee that date fields are always valid.
  onStartDateBlur = (event: SimpleEvent<string>) => {
    if (DateWrapper.isValidDate(event.target.value)) { return; }

    const { previousWeekday } = this.today;
    event.target.value = previousWeekday.toUSDateString();
    this.onStartDateChange(previousWeekday.date);
  };

  onEndDateBlur = (event: SimpleEvent<string>) => {
    if (DateWrapper.isValidDate(event.target.value)) { return; }

    event.target.value = this.today.toUSDateString();
    this.onEndDateChange(this.today.date);
  };

  handleDateRangeUpdate = () => {
    const { startDate, endDate } = this.state;
    const validSelections = DateWrapper.isValidDate([this.state.startDate, this.state.endDate]);
    if (!validSelections) {
      return;
    }

    this.props.setDateRange(
      startDate,
      endDate,
      this.props.dataTableDateRange?.dateRange?.maxPast,
      this.props.dataTableDateRange?.dateRange.maxFuture
    );
    if (this.props.callback) {
      this.props.callback(startDate, endDate);
    } else {
      this.fetchData();
    }
  };

  checkComponentWidth = () => {
    const existingRef = this.datePickerCtRef.current;
    if (existingRef) {
      setTimeout(() => {
        const width = existingRef.clientWidth;
        const isSmallPickerWidth = width < 400;
        if (this.state.pickerWidth !== width) {
          this.setState({
            smallPickerWidth: isSmallPickerWidth,
          });
        }
      }, 300); // Delay allows for animations in the UI to complete before trying to calculate component width
    }
  };

  componentDidMount() {
    if (
      this.props.dataTableDateRange
      && this.props.dataTableDateRange.dateRange
    ) {
      const {
        startDate, endDate, maxFuture, maxPast,
      } = this.props.dataTableDateRange.dateRange;
      this.setState({ startDate, endDate });
      this.props.setDateRange(startDate, endDate, maxPast, maxFuture);
    } else {
      this.props.setDateRange(this.state.startDate, this.state.endDate);
    }
    // Component can change size/appearance either on browser resize or clicking on UI elements,
    // such as expanding or collapsing the Message Center panel
    window.addEventListener('resize', this.checkComponentWidth);
    window.addEventListener('click', this.checkComponentWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkComponentWidth);
    window.removeEventListener('click', this.checkComponentWidth);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props !== prevProps) {
      this.checkComponentWidth();
    }
  }

  render() {
    const maxFuture = this.props.maxDatePickerFuture ? this.props.maxDatePickerFuture : new Date('1/1/3000');
    return (
      <div className={styles.datePickerRef} ref={this.datePickerCtRef}>
        <div
          className={cx(
            this.state.smallPickerWidth
              ? styles.datePickerCtSmall
              : styles.datePickerCt
          )}
        >
          {/* Start Date modal */}
          {!this.state.smallPickerWidth && (
            <span className={styles.dateRange}>
              <span>Date&nbsp;</span>
              <span>Range:</span>
            </span>
          )}
          <div className={styles.dateField}>
            <TTDatePicker
              minDate={this.props.dataTableDateRange?.dateRange?.maxPast?.toISOString()}
              maxDate={this.props.dataTableDateRange?.dateRange?.maxFuture?.toISOString()
                ? this.props.dataTableDateRange?.dateRange?.maxFuture?.toISOString() : maxFuture.toISOString()}
              dataTest="dataTableDateRange-startDate-picker"
              value={this.state.startDate}
              isRequired
              label=""
              onChangeCallback={this.onStartDateChange}
              onBlurCallback={this.onStartDateBlur}
              placeholder="MM/dd/yyyy"
            />
          </div>
          <span className={styles.spacing}>&mdash;</span>
          {/* End Date modal */}
          <div className={styles.dateField}>
            <TTDatePicker
              minDate={this.props.dataTableDateRange?.dateRange?.maxPast?.toISOString()}
              maxDate={this.props.dataTableDateRange?.dateRange?.maxFuture?.toISOString()
                ? this.props.dataTableDateRange?.dateRange?.maxFuture?.toISOString() : maxFuture.toISOString()}
              dataTest="dataTableDateRange-endDate-picker"
              value={this.state.endDate}
              isRequired
              label=""
              onChangeCallback={this.onEndDateChange}
              onBlurCallback={this.onEndDateBlur}
              placeholder="MM/dd/yyyy"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  baseUrl: '',
  dataSources: state.combinedDataSources?.dataSources || {},
  currentRegion: state.currentRegion,
  dataTableDateRange: state.dataTableDateRange,
});

const connector = connect(mapStateToProps, {
  fetchDataTableData,
  setDateRange,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface DataTableDateRangeProps extends PropsFromRedux {
  noRegionFilter: boolean;
  callback?: (startDate: Date, endDate: Date) => void;
  maxDatePickerFuture?: Date;
  startDate?: Date;
  endDate?: Date;
  source: string;
  columns: DataTableContentColumn[];
}

export default connector(DataTableDateRange);
