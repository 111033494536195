import { Dispatch } from 'redux';
import { HaulerDto } from '@trucktrax/trucktrax-ts-common';
import { getRequest } from './requestService';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { devErrorAndLog } from '../util/errorUtil';
import { FETCH_HAULERS } from '../constants/actionConstants';
import { HAULERS_PATH } from '../constants/apiConstants';
import { ERROR_TEXT_FETCH_HAULER_LIST } from '../constants/errorConstants';
import { CANCEL_API_DUE_TO_PAGE_CHANGE } from '../constants/appConstants';
import HTTP_CODES from '../constants/httpConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export function fetchHaulerList(truckUrl?: string, regionUrl?: string, byPassRedisCache: boolean = false) {
  return async (dispatch: Dispatch) => {
    const url = getGeotraxBaseUrl() + HAULERS_PATH;
    const params = {
      truckUrl: truckUrl ?? '',
      regionUrl: regionUrl ?? '',
      byPassRedisCache,
    };

    try {
      const response = await getRequest(url, params);
      dispatch({
        type: FETCH_HAULERS,
        payload: response.data.items,
      });

      return response.data.items as HaulerDto[];
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          ERROR_TEXT_FETCH_HAULER_LIST,
          `haulersService: url: ${url} + params: + ${JSON.stringify(params)}`,
          e.toString(),
          undefined,
          consoleOnly
        ) as any);
      }
    }
    return undefined;
  };
}
