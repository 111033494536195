import React, { Component } from 'react';
import { FloatingActionButton } from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import {
  PlantDto, ScaleType, ScaleDto, VoidFunc,
} from '@trucktrax/trucktrax-ts-common';
import {
  defaultProductLineRadioOptions,
  getWeightOption,
  onSubmitSuccess,
  plantListForDropdown,
  scaleTypeRadioOptions,
} from '../../../util/adminUtil';
import { getIdFromUrl, noop } from '../../../util/appUtil';
import { openModal, closeModal } from '../../../store/actions/errorModalActions';
import { createDataTableRecord } from '../../../services/dataTableService';
import { fetchPlantList } from '../../../services/plantsService';
import { fetchScaleList } from '../../../services/scalesService';
import AdminAddModal from '../../shared/admin/AdminAddModal';
import styles from './ScalesAddModal.module.css';
import { ADD_SCALE_ERROR_MESSAGE } from '../../../constants/errorConstants';
import {
  ADD_SCALE_BUTTON_TEXT,
  ADD_SCALE_MODAL_TITLE,
  ADMIN_KEYS,
  ADMIN_LABELS,
  DROPDOWN_FORM,
  INPUT_FORM,
  RADIO_FORM,
  NUMERIC_INPUT_FORM,
} from '../../../constants/appConstants';
import { PLANTS_PATH, SCALES_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ADD_SCALE_SUCCESS_TEXT } from '../../../constants/successConstants';

import AddFeatureModal from '../../shared/AddFeatureModal';
import { ReduxState } from '../../../store';
import { checkNullInRequired } from '../../../util/validation';

const scaleTypeOptions = scaleTypeRadioOptions;

export class ScalesAddModal extends Component<ScalesAddModalProps> {
  static defaultProps = {
    currentRegion: {
      url: '',
    },
  };

  state = {
    maxWeightLabel: `${ADMIN_LABELS.ADD_SCALE_MAXWEIGHT}`,
    showNetAddress: true,
    plantList: [],
    selectedPlant: '',
  };

  componentDidMount() {
    this.setPlantList();
    ScalesAddModal.setScaleOptions(true);
    this.setState({ showNetAddress: true });
  }

  componentDidUpdate(prevProps: ScalesAddModalOwnProps) {
    if (this.props.isOpen !== prevProps.isOpen && !this.props.isOpen) {
      ScalesAddModal.setScaleOptions(true);
      this.setState({ selectedPlant: '', showNetAddress: true });
    }
  }

  // admin card passes checked object on the dto for checkbox that is not part of the ScaleDto
  onSubmit = (dto: any, onError: any, onSuccess: any) => {
    const { createAdminTableData: post } = this.props;
    const plantid = getIdFromUrl(dto.plant!.url);
    const url = `${getGeotraxBaseUrl() + PLANTS_PATH}/${plantid}${SCALES_PATH}`;
    const currentRegionUrl = this.props.currentRegion.url;

    const scaleDto = {
      ...dto,
      plant: dto.plant,
    } as ScaleDto;

    const successMsg = `${scaleDto.name} ${ADD_SCALE_SUCCESS_TEXT}`;

    const toastMessages = {
      success: successMsg,
      fail: ADD_SCALE_ERROR_MESSAGE,
    };

    const callbacks = [this.props.discardAcceptAction, onSuccess];
    post(url, scaleDto, () => onSubmitSuccess(callbacks), onError, toastMessages, true, currentRegionUrl);
  };

  async setPlantList() {
    const { currentRegion } = this.props;
    this.props.fetchPlantList(currentRegion.url, undefined, undefined, 'Aggregates,Cement');
  }

  // allow any because the forms put other attributes on the object than the standard ScaleDto has
  recordChanged = (scale: any) => {
    const showNetAddress = scale && scale.scaleType.toString().toUpperCase() === ScaleType.LIVE;

    // get UOM from selected plant
    const { defaultUom } = scale.plant;
    const maxWeightLabel = defaultUom
      ? `${ADMIN_LABELS.ADD_SCALE_MAXWEIGHT} in ${getWeightOption(defaultUom)?.label}` : ADMIN_LABELS.ADD_SCALE_MAXWEIGHT;

    ScalesAddModal.setScaleOptions(false);
    this.setState({
      showNetAddress, maxWeightLabel, selectedPlant: scale.plant
    });
  };

  isPlantSelected = () => this.state.selectedPlant !== '';

  static setScaleOptions = (isDisabled: boolean) => {
    scaleTypeOptions.forEach(e => { e.disabled = isDisabled; });
  };

  config = () => {
    const plantsDropdownOptions = plantListForDropdown(this.props.plantsList);

    const addConfig = [
      {
        type: DROPDOWN_FORM,
        key: ADMIN_KEYS.PLANT,
        label: ADMIN_LABELS.PLANT,
        items: plantsDropdownOptions,
        className: 'fullWidthSpace',
        allowEmpty: true,
        initialSelected: { label: 'Select a Plant...' },
        dataTest: 'plants-input-data-test',
        customValidation: checkNullInRequired,
        isRequired: true,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.NAME,
        label: ADMIN_LABELS.SCALE_NAME,
        maxLength: 50,
        dataTest: 'scaleName-input-data-test',
        errorDataTest: 'scaleName-input-missing-error',
        className: 'fullWidthSpace',
        customValidation: checkNullInRequired,
        isRequired: true,
        hideCharacterCount: true,
        disabled: !this.isPlantSelected(),
      },
      {
        type: NUMERIC_INPUT_FORM,
        key: ADMIN_KEYS.MAXWEIGHT,
        label: this.state.maxWeightLabel,
        maxLength: 10,
        dataTest: 'maxWeight-input-data-test',
        errorDataTest: 'maxWeight-input-missing-error',
        className: 'fullWidthSpace',
        customValidation: checkNullInRequired,
        isRequired: true,
        hideCharacterCount: true,
        disabled: !this.isPlantSelected(),
      },
      {
        type: RADIO_FORM,
        key: ADMIN_KEYS.SCALETYPE,
        className: styles.defaultProductLine,
        label: ADMIN_LABELS.SCALE_TYPE,
        radioButtonGroupName: ADMIN_KEYS.SCALETYPE,
        accessor: ADMIN_KEYS.SCALETYPE,
        radioButtons: scaleTypeOptions,
        initialSelected: scaleTypeOptions[0],
        isRequired: true,
        disabled: !this.isPlantSelected(),
      },
      {
        type: RADIO_FORM,
        accessor: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        className: styles.defaultProductLine,
        dataTest: 'users-drilldown-product',
        key: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        radioButtonGroupName: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        label: ADMIN_LABELS.PRIMARY_PRODUCT_LINE,
        radioButtons: defaultProductLineRadioOptions,
      },
    ];

    if (this.state.showNetAddress) {
      addConfig.push({
        type: INPUT_FORM,
        className: 'fullWidthSpace',
        dataTest: 'scales-drilldown-headNetAddress',
        key: ADMIN_KEYS.NETADDRESS,
        errorDataTest: 'netAddress-input-missing-error',
        hideCharacterCount: true,
        customValidation: checkNullInRequired,
        isRequired: true,
        label: ADMIN_LABELS.SCALE_HEADNETADDRESS,
        disabled: !this.isPlantSelected(),
      } as any);
    }

    return addConfig;
  };

  render() {
    if (!this.state.plantList) return null;
    return (
      <div>
        <FloatingActionButton onClick={this.props.openAddModal} />
        <AddFeatureModal
          title={ADD_SCALE_MODAL_TITLE}
          isOpen={this.props.isOpen}
          onCancel={this.props.closeAddModal}
          style={styles.scalesAddModal}
        >
          <AdminAddModal
            config={this.config()}
            addButtonText={ADD_SCALE_BUTTON_TEXT}
            addButtonDataTest="scales-add-button-data-test"
            onChangeHandler={this.recordChanged}
            onRightBtnClick={this.onSubmit}
            discardAcceptAction={this.props.discardAcceptAction}
            discardRejectAction={this.props.discardRejectAction}
            isCloseRequest={this.props.isCloseRequest}
          />
        </AddFeatureModal>
      </div>
    );
  }
}

export interface ScalesAddModalOwnProps {
  discardAcceptAction: typeof noop,
  discardRejectAction: typeof noop,
  openAddModal: VoidFunc,
  closeAddModal: VoidFunc,
  plantsList?: PlantDto[],
  currentRegion?: any,
  isCloseRequest: boolean,
  isOpen: boolean
}
export function mapStateToProps(state: ReduxState) {
  return {
    currentRegion: state.currentRegion ?? { url: '' },
    plantsList: state.plantList,
    scalesList: state.scalesList?.scales ?? [],
  };
}
type ScalesAddModalReduxProps = ReturnType<typeof mapStateToProps>;
type ScalesAddModalDispatchProps = {
  createAdminTableData: (...args: Parameters<typeof createDataTableRecord>) => void,
  fetchPlantList: (...args: Parameters<typeof fetchPlantList>) => void;
};
export type ScalesAddModalProps = ScalesAddModalReduxProps & ScalesAddModalDispatchProps & ScalesAddModalOwnProps;

export default connect(mapStateToProps, {
  createAdminTableData: createDataTableRecord,
  fetchPlantList,
  fetchScaleList,
  openModal,
  closeModal,
})(ScalesAddModal);
