import React, { Component } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DropDownList } from '@trucktrax/trucktrax-common';
import styles from './TicketStateSwitcher.module.css';
import { TicketStateSwitcherType, TicketStateValue } from '../../../types';

export default class TicketStateSwitcher extends Component<TicketStateSwitcherProps, TicketStateSwitcherState> {
  eventTimeout?: NodeJS.Timeout;

  constructor(props: TicketStateSwitcherProps) {
    super(props);
    this.state = { selectedValue: props.initialValue };
  }

  componentDidMount() {
    // this triggers a resize event due to bug with Tab indicator with incorrect size
    // when inside a Popover (related with animation timing)
    this.eventTimeout = global.setTimeout(() => window.dispatchEvent(new CustomEvent('resize')), 0);
  }

  componentWillUnmount() {
    global.clearTimeout(this.eventTimeout!);
  }

  onChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    const enumValue = Number(newValue) as TicketStateValue;
    this.setState({ selectedValue: enumValue });
    this.props.onChange(enumValue);
  };

  onChangeDropDown = (item: any) => {
    this.setState({ selectedValue: item.value });
    this.props.onChange(item.value);
  };

  renderTabs = () => (
    <Tabs
      value={`${this.state.selectedValue}`}
      onChange={this.onChangeTab}
    >
      <Tab
        label="Current Tickets"
        value={`${TicketStateValue.Active as number}`}
      />
      <Tab
        label="Past Tickets"
        value={`${TicketStateValue.Closed as number}`}
        data-test="past-tickets-tab"
      />
    </Tabs>
  );

  renderDropDown = () => {
    const items = [
      { value: TicketStateValue.AllTickets, label: 'All Tickets' },
      { value: TicketStateValue.Closed, label: 'Closed' },
      { value: TicketStateValue.Voided, label: 'Voided' },
      { value: TicketStateValue.Open, label: 'Open' },
      { value: TicketStateValue.Active, label: 'Active' },
    ];

    const initialSelected = items.find(i => this.state.selectedValue === i.value);

    return (
      <div className={styles.dropdown}>
        <label htmlFor="ticketStateSwitcherDropDown">
          Ticket State
        </label>
        <DropDownList
          items={items}
          initialSelected={initialSelected!}
          updateSelected={this.onChangeDropDown}
        />
      </div>
    );
  };

  render() {
    if (this.props.switcherType === TicketStateSwitcherType.Tabs) {
      return this.renderTabs();
    }

    return this.renderDropDown();
  }
}

export interface TicketStateSwitcherProps {
  onChange: (tabIndex: TicketStateValue) => void,
  switcherType: TicketStateSwitcherType,
  initialValue: TicketStateValue
}

export interface TicketStateSwitcherState {
  selectedValue: TicketStateValue
}
