import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataTableContent from '../../shared/DataTableContent';
import {
  addDataTest, addHeaderDataTest, getAddress,
} from '../../../util/adminUtil';
import { HAULERS_TEXT } from '../../../constants/navConstants';
import { ADMIN_KEYS, ADMIN_LABELS } from '../../../constants/appConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { DATASOURCE, HAULERS_PATH } from '../../../constants/apiConstants';
import HaulersAddModal from './HaulersAddModal';

export interface HaulersViewState {
  isOpen: boolean,
  isCloseRequest: boolean
}

export interface HaulersViewProps { }

export class HaulersView extends Component<HaulersViewProps, HaulersViewState> {
  constructor(props: HaulersViewProps) {
    super(props);

    this.state = {
      isOpen: false,
      isCloseRequest: false,
    };
  }

  static getColumns = () => [
    {
      Header: addHeaderDataTest(null, 'sort-hauler-by-name', ADMIN_LABELS.HAULER_NAME),
      id: ADMIN_KEYS.NAME,
      accessor: ADMIN_KEYS.NAME,
      minWidth: 100,
      Cell: (props: { value: string; }) => addDataTest(null, 'sort-hauler-by-name', props.value),
    },
    {
      Header: addHeaderDataTest(null, 'sort-hauler-by-externalId', ADMIN_LABELS.EXTERNAL_ID),
      id: ADMIN_KEYS.EXTERNAL_ID,
      accessor: ADMIN_KEYS.EXTERNAL_ID,
      minWidth: 50,
      style: {
        fontWeight: '900',
      },
      Cell: (props: { value: string; }) => addDataTest(null, 'sort-hauler-by-externalId', props.value),
    },
    {
      Header: addHeaderDataTest(null, 'sort-hauler-by-address', ADMIN_LABELS.ADDRESS),
      id: ADMIN_KEYS.ADDRESS_LINE_ONE,
      accessor: getAddress,
      minWidth: 350,
      Cell: (props: { value: string; }) => addDataTest(null, 'sort-hauler-by-address', props.value),
    },
  ];

  requestCloseModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      isCloseRequest: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: false,
    });
  };

  render() {
    const baseUrl = getGeotraxBaseUrl() + HAULERS_PATH;
    const columns = HaulersView.getColumns();

    const props: any = {
      baseUrl,
      columns,
      header: HAULERS_TEXT,
      byPassRedisCache: true,
      source: DATASOURCE.HAULER
    };

    return (
      <div>
        <DataTableContent {...props} />
        <HaulersAddModal
          isOpen={this.state.isOpen}
          openModal={this.openModal}
          closeModal={this.requestCloseModal}
          discardAcceptAction={this.closeModal}
          discardRejectAction={this.openModal}
          isCloseRequest={this.state.isCloseRequest}
        />
      </div>
    );
  }
}

export default connect(() => ({}))(HaulersView);
