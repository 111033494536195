import React from 'react';
import { HeaderAndContainer } from '@trucktrax/trucktrax-common';
import { Card, CardContent } from '@mui/material';
import styles from './UserGuidesCommon.module.css';
import Header from '../../../shared/Header';
import AdminCardStyles from '../../../shared/admin/AdminCard.module.css';
import CardImage from '../../../shared/forms/CardImage';
import DocsTable from '../../../shared/docs/DocsTable';
import { SCALETRAX_USERGUIDES_FOLDER } from '../../../../constants/docsConstants';
import userGuidesWebJpg from '../../../../assets/img/userGuidesWeb.jpg';

export function ScaleTraxGuideWebView() {
  const cardStyle = {
    root: AdminCardStyles.card,
  };

  const documents = [
    {
      name: 'Getting Started',
      filepath: `${SCALETRAX_USERGUIDES_FOLDER}userguide_web_Getting_Started.pdf`,
    },
  ];

  const guideBody = (
    <div className={AdminCardStyles.bodyWrapper}>
      <div className={AdminCardStyles.cardWrapper}>
        <Card classes={cardStyle}>
          <CardImage src={userGuidesWebJpg} className={styles.topImg} />
          <CardContent>
            <div className={AdminCardStyles.cardText}>
              <h2 className={styles.header}>ScaleTrax Web User Guide</h2>

              <p>
                ScaleTrax® is a cloud-based truck ticketing software application comprised of mobile and web applications
                to track and manage bulk construction materials deliveries.
              </p>

              <div className={styles.divider} />
              <DocsTable documents={documents} folder={SCALETRAX_USERGUIDES_FOLDER} />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );

  const elementsLeft = <Header title="User Guides" />;

  return (
    <div>
      <div>
        <HeaderAndContainer elementsLeft={elementsLeft} />
      </div>
      {guideBody}
    </div>
  );
}

export default ScaleTraxGuideWebView;
