import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PermissionAccess, UnitOfMeasure } from '@trucktrax/trucktrax-ts-common';
import DataTableContent from '../../shared/DataTableContent';
import { addHeaderDataTest } from '../../../util/adminUtil';
import { TRUCKS_TEXT, VEHICLE_TYPES_TEXT } from '../../../constants/navConstants';
import { ADMIN_KEYS, ADMIN_LABELS } from '../../../constants/appConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import styles from './VehicleTypesView.module.css';
import { DATASOURCE, VEHICLE_TYPES_PATH } from '../../../constants/apiConstants';
import { getUnitOfMeasureLabel } from '../../../util/appUtil';
import VehicleTypesAddModal from './VehicleTypesAddModal';

export class VehicleTypesView extends Component<VehicleTypesViewProps> {
  state = {
    isOpen: false,
    isCloseRequest: false,
  };

  static getColumns = () => [
    {
      Header: addHeaderDataTest(null, 'sort-by-name', ADMIN_LABELS.VEHICLE_TYPE),
      accessor: ADMIN_KEYS.NAME,
    },
    {
      Header: addHeaderDataTest(null, 'sort-by-weight', ADMIN_LABELS.GROSS_MAX_WEIGHT),
      accessor: ADMIN_KEYS.MAXWEIGHT,
      minWidth: 150,
      Cell: (dto?: { original?: { maxWeight?: number, unitOfMeasure?: UnitOfMeasure } }) => {
        if (!dto?.original?.maxWeight || !dto.original?.unitOfMeasure) {
          return '0';
        }

        return `${dto.original.maxWeight.toLocaleString()} ${getUnitOfMeasureLabel(dto.original.unitOfMeasure)}`;
      },
    },
  ];

  requestCloseModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      isCloseRequest: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: false,
    });
  };

  render() {
    const columns = VehicleTypesView.getColumns();
    const hasPermissionAccess = this.props.trucksPermissionAccess === PermissionAccess.Edit;

    const props: any = {
      baseUrl: getGeotraxBaseUrl() + VEHICLE_TYPES_PATH,
      columns,
      header: VEHICLE_TYPES_TEXT,
      canAddNewItem: hasPermissionAccess,
      byPassRedisCache: true,
      source: DATASOURCE.VEHICLE_TYPE
    };

    return (
      <div className={styles.container}>
        <DataTableContent {...props} />
        {hasPermissionAccess && (
          <VehicleTypesAddModal
            isOpen={this.state.isOpen}
            openModal={this.openModal}
            closeModal={this.requestCloseModal}
            discardAcceptAction={this.closeModal}
            discardRejectAction={this.openModal}
            isCloseRequest={this.state.isCloseRequest}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  trucksPermissionAccess: state.adminPermissionAccess[TRUCKS_TEXT],
});

interface History {
  push: (p: string) => void
}

export interface VehicleTypesViewProps {
  trucksPermissionAccess?: string,
  history: History
}

export default connect(mapStateToProps)(VehicleTypesView);
