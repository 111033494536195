import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { FloatingActionButton } from '@trucktrax/trucktrax-common';
import cx from 'classnames';
import { connect } from 'react-redux';
import { DriverDto, UrlKeyDto, VoidFunc } from '@trucktrax/trucktrax-ts-common';
import {
  defaultProductLineRadioOptions,
  defaultDriverConfigurationRadioOptions,
  findDefaultRegion,
  nameAndUrlItemToDropdownOptions,
  nameAndUrlToRegionListItems,
  onSubmitSuccess,
  secondaryProductLineRadioOptions,
  haulerListToMultiSelectOptions,
} from '../../../util/adminUtil';
import AdminAddModal, { AdminConfig } from '../../shared/admin/AdminAddModal';
import { addToOrUpdateListInStore } from '../../../store/actions/dataTableActions';
import { fetchHaulerList } from '../../../services/haulersService';
import { getPlantList } from '../../../services/plantsService';
import { createDataTableRecord } from '../../../services/dataTableService';
import { devErrorAndLog } from '../../../util/errorUtil';
import styles from './DriversAddModal.module.css';
import { ADD_DRIVER_ERROR_MESSAGE, ERROR_TEXT_FETCH_PLANT_LIST } from '../../../constants/errorConstants';
import { ADD_DRIVER } from '../../../constants/actionConstants';
import {
  ADD_DRIVER_BUTTON_TEXT,
  ADD_DRIVER_MODAL_TITLE,
  ADMIN_KEYS,
  ADMIN_LABELS,
  CARD_SWITCH,
  DROPDOWN_FORM,
  GEOTRAX_LITE_FLAG,
  INPUT_FORM,
  MULTIDROPDOWN_FORM,
  NUMERIC_INPUT_FORM,
  RADIO_FORM,
} from '../../../constants/appConstants';
import { DRIVERS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ADD_DRIVER_SUCCESS_TEXT } from '../../../constants/successConstants';
import { getIdFromUrl, noop } from '../../../util/appUtil';
import AddFeatureModal from '../../shared/AddFeatureModal';
import HTTP_CODES from '../../../constants/httpConstants';
import { checkNullInRequired, validateHaulersDropdown } from '../../../util/validation';
import { ConnectedDispatchFunction } from '../../../types';
import { RegionAccessMap } from '../../../util/permissionUtil';
import { isFeatureFlagEnabled } from '../../../util/featureFlagUtil';

export class DriversAddModal extends Component<DriversAddModalProps> {
  static defaultProps = {
    currentRegion: {
      url: '',
    },
  };

  state = {
    region: undefined,
    plantList: [],
    primaryProductLine: undefined,
    isExternal: false,
    gtLightAccess: false,
  };

  async componentDidMount() {
    await this.props.fetchHaulerList(undefined, this.props.currentRegion.url, true);
  }

  async componentDidUpdate(prevProps: any) {
    // triggers when it opens instead of componentWillMount which trigger earlier and once when the table renders
    if (!prevProps.isOpen && this.props.isOpen) {
      const dropDownRegions = nameAndUrlToRegionListItems(this.props.regions);
      const region = (this.props.findDefaultRegion(dropDownRegions) as unknown as Region).value;

      const primaryProductLine = defaultProductLineRadioOptions[0].value;
      await this.onChange({
        region,
        primaryProductLine,
      });
    }
  }

  setPlantList = async (region: any, selectedProductLine: any) => {
    try {
      const plantList = await getPlantList(region, selectedProductLine);
      this.setState({ plantList });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      this.props.devErrorAndLog(ERROR_TEXT_FETCH_PLANT_LIST, `Add Driver: ${e.toString()}`, undefined, undefined, consoleOnly);
    }
  };

  onSuccess = (driver: DriverDto) => {
    this.props.addToOrUpdateListInStore(driver, ADD_DRIVER);
    this.props.discardAcceptAction();
  };

  onSubmit = (dto: DriverDto, onError: any, buttonCallback: any) => {
    // unset the conditional form fields as needed
    // e.g. unset internal-only fields for external drivers, and vice versa
    const copy = cloneDeep(dto);
    if (copy.isExternal) {
      copy.pin = undefined;
      copy.primaryPlant = undefined;
      copy.secondaryProductLine = undefined;
      copy.gtLightAccess = this.state.gtLightAccess;
      // convert haulers to array of ids
      copy.haulers = copy.haulers?.map((item: any) => {
        const haulerUrl = item.key;
        const haulerId = getIdFromUrl(haulerUrl);
        return Number(haulerId);
      });

      // unset email if it's empty (email is not required for external drivers w/o GTL access)
      if (copy.email === '') {
        copy.email = undefined;
      }
    } else {
      copy.haulers = undefined;
      copy.gtLightAccess = false;
      copy.email = undefined;
    }

    const { createAdminTableData: post } = this.props;
    const toastMessages = {
      success: ADD_DRIVER_SUCCESS_TEXT,
      fail: ADD_DRIVER_ERROR_MESSAGE,
    };

    const currentRegion = this.props.currentRegion.url === dto.region?.url;
    const currentRegionUrl = this.props.currentRegion.url;
    const url = getGeotraxBaseUrl() + DRIVERS_PATH;
    const callbacks = [this.onSuccess, buttonCallback];
    post(
      url,
      copy,
      (driver) => onSubmitSuccess(callbacks, driver),
      onError,
      toastMessages,
      currentRegion,
      currentRegionUrl
    );
  };

  onChange = async (changesObj: any) => {
    const region = changesObj.region && changesObj.region.url;
    const { primaryProductLine, isExternal } = changesObj;

    if (isExternal !== this.state.isExternal) {
      this.setState({
        isExternal,
      });
    }

    if (
      region !== this.state.region
      || primaryProductLine !== this.state.primaryProductLine
    ) {
      // resets previously chosen primary plant
      changesObj[ADMIN_KEYS.PRIMARY_PLANT] = undefined;
      this.setState({
        region,
        primaryProductLine,
      });
      await this.setPlantList(region, primaryProductLine);
    }
  };

  handleSwitchChange = (event: {
    [x: string]: boolean;
  }) => {
    this.setState({
      gtLightAccess: event.gtLightAccess,
    });
  };

  config = () => {
    const dropDownRegions = nameAndUrlToRegionListItems(this.props.regions);
    const defaultRegion = this.props.findDefaultRegion(dropDownRegions);
    const dropDownPlants = nameAndUrlItemToDropdownOptions(this.state.plantList);

    // driverTypeFields will be omitted if GTL feature flag is not enabled for the current admin user
    const driverTypeFields = [
      {
        type: RADIO_FORM,
        key: ADMIN_KEYS.DRIVER_IS_EXTERNAL,
        label: ADMIN_LABELS.DRIVER_IS_EXTERNAL,
        radioButtonGroupName: ADMIN_KEYS.DRIVER_IS_EXTERNAL,
        className: 'widthSpace margin-top flexHorizontalRadio',
        radioButtons: defaultDriverConfigurationRadioOptions,
        initialSelected: defaultDriverConfigurationRadioOptions[0],
      },
    ];

    // Initial fields for external drivers
    const initialExternalFields = [
      {
        id: ADMIN_KEYS.GEOTRAX_LITE_ACCESS,
        type: CARD_SWITCH,
        key: ADMIN_KEYS.GEOTRAX_LITE_ACCESS,
        label: ADMIN_LABELS.GEOTRAX_LITE_ACCESS,
        checked: this.state.gtLightAccess, // CardSwitch requires state to be controlled from the parent component
        display: true,
        editing: true,
        onChange: this.handleSwitchChange,
      },
    ];

    // Fields for both internal and external drivers
    const commonFields = [
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.FIRST_NAME,
        label: ADMIN_LABELS.FIRST_NAME,
        maxLength: 50,
        dataTest: 'firstName-input-data-test',
        errorDataTest: 'firstName-input-missing-error',
        className: 'widthSpace',
        customValidation: checkNullInRequired,
        isRequired: true,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.LAST_NAME,
        label: ADMIN_LABELS.LAST_NAME,
        maxLength: 50,
        dataTest: 'lastName-input-data-test',
        errorDataTest: 'lastName-input-missing-error',
        className: 'widthSpace',
        customValidation: checkNullInRequired,
        isRequired: true,
      },
    ];

    // Fields specific to internal drivers
    const internalFields = [
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.EXTERNAL_ID,
        label: ADMIN_LABELS.EXTERNAL_ID,
        maxLength: 50,
        dataTest: 'externalId-input-data-test',
        errorDataTest: 'externalId-input-missing-error',
        className: cx('widthSpace', 'baseMargin'),
        isRequired: false,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.SSO_ID,
        label: ADMIN_LABELS.SSO_ID_EN,
        maxLength: 50,
        dataTest: 'ssoId-input-data-test',
        errorDataTest: 'ssoId-input-missing-error',
        className: 'widthSpace',
        isRequired: false,
      },
      {
        type: NUMERIC_INPUT_FORM,
        key: ADMIN_KEYS.PIN,
        label: ADMIN_LABELS.PIN,
        maxLength: 6,
        dataTest: 'pin-input-data-test',
        errorDataTest: 'pin-input-missing-error',
        className: cx('quarterWidthSpace', 'baseMargin'),
        customValidation: checkNullInRequired,
        isRequired: true,
      },
    ];

    // Fields specific to external drivers
    const externalFields = [
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.EMAIL,
        label: ADMIN_LABELS.EMAIL,
        maxLength: 50,
        dataTest: 'email-input-data-test',
        errorDataTest: 'email-input-missing-error',
        className: cx('widthSpace', 'baseMargin'),
        customValidation: this.state.gtLightAccess ? checkNullInRequired : undefined,
        isRequired: this.state.gtLightAccess, // email is not required for external users w/o GTL access
      },
    ];

    // Fields common after the conditional fields
    const additionalCommonFields = [
      {
        type: DROPDOWN_FORM,
        key: ADMIN_KEYS.REGION,
        label: ADMIN_LABELS.REGION,
        className: 'baseDropDown',
        items: dropDownRegions,
        initialSelected: defaultRegion,
        isRequired: true,
        dataTest: 'region-input-data-test',
      },
      {
        type: RADIO_FORM,
        key: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        label: ADMIN_LABELS.PRIMARY_PRODUCT_LINE,
        radioButtonGroupName: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        className: 'widthSpace margin-top',
        radioButtons: defaultProductLineRadioOptions,
        isRequired: true,
        errorDataTest: 'primaryProductLine-duplication-error',
        initialSelected: defaultProductLineRadioOptions[0],
      },
    ];

    // Additional fields for internal drivers
    const additionalInternalFields = [
      {
        type: RADIO_FORM,
        key: ADMIN_KEYS.SECONDARY_PRODUCT_LINE,
        label: ADMIN_LABELS.SECONDARY_PRODUCT_LINE,
        radioButtonGroupName: ADMIN_KEYS.SECONDARY_PRODUCT_LINE,
        radioButtons: secondaryProductLineRadioOptions,
        className: 'smallMargin',
        errorDataTest: 'secondaryProductLine-duplication-error',
        initialSelected: secondaryProductLineRadioOptions[0],
      },
      {
        type: DROPDOWN_FORM,
        label: ADMIN_LABELS.PRIMARY_PLANT,
        key: ADMIN_KEYS.PRIMARY_PLANT,
        initialSelected: { name: 'Select a Primary Plant...' },
        items: dropDownPlants,
        customValidation: checkNullInRequired,
        isRequired: true,
        allowEmpty: true,
        className: cx('baseDropDown', 'bottomWidthSpace'),
        dataTest: 'primaryPlant-input-data-test',
      },
    ];

    // Additional fields for external drivers
    const additionalExternalFields = [
      {
        type: MULTIDROPDOWN_FORM,
        key: ADMIN_KEYS.HAULERS,
        label: ADMIN_LABELS.HAULER,
        className: cx('baseDropDown', 'bottomWidthSpace'),
        items: haulerListToMultiSelectOptions(this.props.haulersList),
        initialSelected: { value: [] },
        placeholder: 'Select One or Multiple Haulers...',
        dataTest: 'hauler-input-data-test',
        // intentionally only required if GTL access is enabled
        isRequired: this.state.gtLightAccess,
        customValidation: this.state.gtLightAccess ? validateHaulersDropdown : undefined,
      },
    ];

    // Assemble the final configuration based on the GeotraxLite feature flag
    let addConfig: AdminConfig[] = [];
    const isGeotraxLiteEnabled = isFeatureFlagEnabled(
      this.props.userPermission,
      this.props.currentRegion?.url ?? '',
      GEOTRAX_LITE_FLAG
    );

    if (isGeotraxLiteEnabled) {
      addConfig = [
        ...driverTypeFields,
        ...(this.state.isExternal ? initialExternalFields : []),
        ...commonFields,
        ...(this.state.isExternal ? externalFields : internalFields),
        ...additionalCommonFields,
        ...(this.state.isExternal ? additionalExternalFields : additionalInternalFields),
      ];
    } else {
      // GeotraxLite is not enabled; default to internal driver configuration
      addConfig = [
        ...commonFields,
        ...internalFields,
        ...additionalCommonFields,
        ...additionalInternalFields,
      ];
    }

    return addConfig;
  };

  render() {
    return (
      <div>
        <FloatingActionButton onClick={this.props.openModal} />
        <AddFeatureModal
          title={ADD_DRIVER_MODAL_TITLE}
          isOpen={this.props.isOpen}
          onCancel={this.props.closeModal}
          style={styles.driversAddModal}
        >
          <AdminAddModal
            config={this.config()}
            addButtonText={ADD_DRIVER_BUTTON_TEXT}
            addButtonDataTest="drivers-add-button-data-test"
            onRightBtnClick={this.onSubmit}
            discardAcceptAction={this.props.discardAcceptAction}
            discardRejectAction={this.props.discardRejectAction}
            isCloseRequest={this.props.isCloseRequest}
            onChangeHandler={this.onChange}
          />
        </AddFeatureModal>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentRegion: state.currentRegion,
  haulersList: state.haulersList?.haulers,
  userPermission: (state.userPermission || {}) as RegionAccessMap,
});

type Region = {
  label: string,
  value: {
    id: string,
    url: string,
    name: string
  }
};

type DriversAddModalReduxProps = ReturnType<typeof mapStateToProps>;

type DriversAddModalDispatchProps = {
  fetchHaulerList: ConnectedDispatchFunction<typeof fetchHaulerList>;
};

type DriversAddModalOwnProps = {
  createAdminTableData: typeof createDataTableRecord,
  addToOrUpdateListInStore: typeof addToOrUpdateListInStore,
  findDefaultRegion: typeof findDefaultRegion,
  devErrorAndLog: typeof devErrorAndLog,
  discardAcceptAction: typeof noop,
  discardRejectAction: typeof noop,
  closeModal: VoidFunc,
  openModal: VoidFunc,
  regions: any[],
  currentRegion: UrlKeyDto,
  isCloseRequest: boolean,
  isOpen: boolean
};

export type DriversAddModalProps = DriversAddModalOwnProps & DriversAddModalReduxProps & DriversAddModalDispatchProps;

export default connect<any, any, any>(mapStateToProps, {
  currentRegion: { url: '' },
  fetchHaulerList,
  createAdminTableData: createDataTableRecord,
  addToOrUpdateListInStore,
  devErrorAndLog,
  findDefaultRegion,
})(DriversAddModal);
