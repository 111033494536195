const HTTP_CODES = {
  ok: 200,
  created: 201,
  accepted: 202,
  no_content: 204,
  bad_request: 400,
  unauthorized: 401,
  forbidden: 403,
  not_found: 404,
  conflict: 409,
  gone: 410,
  precondition_failed: 412,
  unprocessable_entity: 422,
  internal_server_error: 500,
  bad_gateway: 502,
};

export default HTTP_CODES;
