import { PayloadAction } from '../../types';
import {
  FETCH_PRINTERS,
} from '../../constants/actionConstants';

// eslint-disable-next-line import/prefer-default-export
export const printerReducer = (
  state = { printers: [] as any[] },
  action: PayloadAction<any[], 'FETCH_PRINTERS'>
) => {
  switch (action.type) {
    case FETCH_PRINTERS:
      return {
        ...state,
        printers: action.payload,
      };
    default:
      return state;
  }
};
