/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { TTInput } from '@trucktrax/trucktrax-common';
import { getEventFromTarget, isUndefined } from '../../../util/adminUtil';
import { INPUT_TYPE_TEXT } from '../../../constants/appConstants';

export default class CardInput extends Component<CardInputProps> {
  static defaultProps: Partial<CardInputProps> = {
    value: '',
    className: '',
    dataTest: '',
    placeholder: '',
    errorDataTest: '',
    isRequired: false,
    label: '',
    id: '',
    maxLength: 20,
    inputType: INPUT_TYPE_TEXT,
    errorMessage: '',
    disabled: false,
    errorWhileDisabled: false,
    hideCharacterCount: false,
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event = getEventFromTarget(e, this.props.id!);
    this.props.onChange(event);
  };

  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.props.onTouch(e, this.props.id!);
  };

  render() {
    const {
      isRequired,
      value,
      label,
      maxLength,
      dataTest,
      placeholder,
      errorDataTest,
      disabled,
      hideCharacterCount,
      className,
      errorMessage,
      errorWhileDisabled,
    } = this.props;

    return (
      <div className={className}>
        {!isUndefined(value)
          ? (
            <TTInput
              id={label!}
              maxLength={maxLength}
              value={value}
              onChangeCallback={this.onChange}
              onBlurCallback={this.onBlur}
              dataTest={dataTest!}
              placeholder={placeholder}
              errorDataTest={errorDataTest!}
              disabled={disabled}
              errorWhileDisabled={errorWhileDisabled}
              isRequired={isRequired}
              type={this.props.inputType}
              hideCharacterCount={hideCharacterCount && !errorMessage}
              error={!!errorMessage}
              errorMessage={errorMessage ? [errorMessage] : []}
            />
          )
          : <div className="skeleton" />}
      </div>
    );
  }
}

export interface CardInputProps {
  onTouch: (e: React.FocusEvent<HTMLInputElement>, id: string) => void;
  onChange: (e: ReturnType<typeof getEventFromTarget>) => void;
  className?: string;
  value?: string;
  id?: string;
  label?: string;
  dataTest?: string;
  placeholder?: string;
  errorDataTest?: string;
  isRequired?: boolean;
  disabled?: boolean;
  maxLength?: number;
  inputType?: string;
  hideCharacterCount?: boolean;
  errorMessage?: string;
  errorWhileDisabled?: boolean;
}
