import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DriverDto, GeoZoneDto, OrderDto, PositionDto, TicketDto,
} from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import {
  clearSelectedMarker,
  closePopup,
  refreshPositions,
  setIsPanned,
} from '../../../store/actions/mapviewActions';
import { logoutDriver } from '../../../services/authService';
import { trackingSocketInit } from '../../../services/mapviewsService';
import { clearSelectedTicket, selectTicket } from '../../../store/actions/ticketActions';
import setSidebarSelected from '../../../store/actions/sidebarActions';
import styles from './MapWrapper.module.css';
import { disableTabNavigation, enableTabNavigation } from '../../../services/keyboardService';
import { selectOrderMarker, selectTruckMarker, selectRouteMarker } from '../../../services/ticketsService';

import { retrieveProductLineNameDiff } from '../../../util/statusesUtil';
import { getValidOrdersListInDateRange } from '../../../util/orderUtil';
import { TICKETS_SELECTED } from '../../../constants/appConstants';
import { MARKER_TYPE_ORDER } from '../../../constants/mapConstants';
import {
  ConnectedDispatchFunction,
  LogoutDriverInfo,
  Marker,
  SidebarTitleType,
} from '../../../types';
import TTMap, { MarkerDataType, MarkerType } from './TTMap';
import { ReduxState } from '../../../store';
import { getAddressGeocode, getAddressPartOne, getAddressPartTwo } from '../../../util/adminUtil';

export class MapWrapper extends Component<MapWrapperAllProps, MapWrapperState> {
  static defaultProps: Partial<MapWrapperAllProps> = {
    currentRegion: { url: '', defaultRegion: false },
    selectedMarker: {
      markerId: '0',
      lat: 1,
      lng: 1,
      isPannedTo: false,
    },
    positions: [],
    sidebarSelection: undefined,
    ordersList: [],
    currentGeozone: undefined,
  };

  private map: React.RefObject<HTMLInputElement>;

  private userDidDrag = false;

  constructor(props: MapWrapperAllProps) {
    super(props);
    this.map = React.createRef();
    this.state = {
      location: {
        lat: parseFloat(this.props.lat.toString()),
        lng: parseFloat(this.props.lng.toString()),
      },
      defaultLocation: {
        lat: parseFloat(this.props.lat.toString()),
        lng: parseFloat(this.props.lng.toString()),
      },
      selectedMarker: null,
      tabNavigationListener: null,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.setState({ tabNavigationListener: disableTabNavigation() as any });
    trackingSocketInit(this.props.currentRegion!.url!);
  }

  // TODO fix in gtx-6006
  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps: MapWrapperAllProps) {
    const regionLng = parseFloat(nextProps.lng.toString());
    const regionLat = parseFloat(nextProps.lat.toString());
    // changes location only if a new region is selected.
    // Center handled through google maps OnCenterChange
    if (regionLng !== this.state.defaultLocation.lng
      || regionLat !== this.state.defaultLocation.lat) {
      this.setState({
        location: {
          lat: regionLat,
          lng: regionLng,
        },
        defaultLocation: {
          lat: regionLat,
          lng: regionLng,
        },
      });
    }
    if (nextProps.selectedMarker && nextProps.selectedMarker.markerId) {
      // prevents map reposition when no lat long are received
      // this happens on click of popup only, and not from search or left side navigation.
      if (nextProps.selectedMarker.lat
        && nextProps.selectedMarker.lng
        && !nextProps.selectedMarker.isPannedTo) {
        // set state clears the selected truck to prevent re-focusing on the selected truck
        this.setState({
          selectedMarker: {
            lat: nextProps.selectedMarker.lat,
            lng: nextProps.selectedMarker.lng,
          } as unknown as string,
          location: {
            lat: nextProps.selectedMarker.lat,
            lng: nextProps.selectedMarker.lng,
          },
        }, () => {
          this.props.setIsPanned();
          // pushes call to event loop callback and out of javascript runtime to ensure it refreshes last
          setTimeout(this.props.refreshPositions, 0);
        });
      }
    } else {
      this.setState({
        selectedMarker: null,
      });
    }
    if (nextProps.showJobsites
      && (!this.props.showJobsites
        || (nextProps.currentRegion !== this.props.currentRegion)
        || (nextProps.ordersStartDate !== this.props.ordersStartDate)
        || (nextProps.ordersEndDate !== this.props.ordersEndDate)
        || (nextProps.selectedProductLine !== this.props.selectedProductLine))) {
      this.props.orderListOn(nextProps.ordersStartDate, nextProps.ordersEndDate);
    }
    if (!nextProps.showJobsites && this.props.showJobsites) {
      this.props.orderListOff();
    }
  }

  componentWillUnmount() {
    enableTabNavigation(this.state.tabNavigationListener);
  }

  // filters date range, duplicated addresses and renders if zone and radius exist
  getValidOrders = () => {
    const { ordersStartDate, ordersEndDate, ordersList } = this.props;
    const startDate = new DateWrapper(ordersStartDate).startOfDay.date;
    const endDate = new DateWrapper(ordersEndDate).endOfDay.date;
    // if zone or radius do not exist - get coordinates from the order address
    // eslint-disable-next-line no-unused-expressions
    ordersList?.forEach(order => {
      if (!order.geoZone?.zone.circle?.radius) {
        this.getGeoZone(order);
      }
    });
    const ordersMatchingPlants = ordersList?.filter((order) => {
      const plant: any = this.props.plantListFilter.find((plant: any) => (
        plant.url === order.plant.url));
      return plant ? plant.isSelected : true;
    }) ?? [];
    const filteredOrders = MapWrapper.filterOrdersExcludeFOB(ordersMatchingPlants);
    return getValidOrdersListInDateRange(filteredOrders ?? [], startDate, endDate);
  };

  // https://trucktrax.atlassian.net/browse/DEV-2393 requests that FOB orders are removed so that they are not displayed
  static filterOrdersExcludeFOB = (orders: OrderDto[]): OrderDto[] => orders?.filter((order: OrderDto) => !order.fob) ?? [];

  getGeoZone = (order: OrderDto) => {
    const address = `${getAddressPartOne(order)} ${getAddressPartTwo(order)}`;
    getAddressGeocode(address, this.onGeocodeSuccess)
      .then((location: any) => {
        order.geoZone = {
          zone: {
            circle: {
              radius: 1.0,
              center: {
                latitude: location.lat,
                longitude: location.lng,
              },
            },
          },
        };
      });
  };

  onGeocodeSuccess = (json: google.maps.GeocoderResponse): google.maps.LatLng => {
    const place = json.results[0];
    const { location } = place.geometry;
    this.props.refreshPositions();
    return location;
  };

  // Same for all external and internal trucks
  getFilteredTrucksMarkers = (markers: PositionDto[]) => {
    const { plantListFilter } = this.props;
    const allPlantsSelected = plantListFilter.every((plant: any) => plant.isSelected);
    const noPlantsSelected = plantListFilter.every((plant: any) => !plant.isSelected);
    // we'll say the user is "filtering by plants" if they have selected some plants, but not all
    const userIsFilteringByPlants = !allPlantsSelected && !noPlantsSelected;

    // filter truck markers based on selected plants
    const filteredMarkers = markers.filter((marker) => {
      // if the truck has a ticket, let's use the plant url of that ticket
      let associatedPlantUrl = (marker.ticket as TicketDto)?.plant?.url;

      // if truck is not ticketed, then let's use the url of the truck's home plant instead
      if (!associatedPlantUrl) {
        associatedPlantUrl = marker.trackedObject?.homePlantUrl;
      }

      // AC #3 of dev-1867: if truck is not associated with a plant, and the user is not filtering by plants, show the marker
      if (!userIsFilteringByPlants && !associatedPlantUrl) {
        return true;
      }

      const isAssociatedPlantSelected = !!plantListFilter.find((plant: any) => (
        plant.url === associatedPlantUrl && plant.isSelected));

      // I don't understand when !!marker.trackedObject.url would ever be false
      // but we had this check before, so I'm leaving it in for now
      return isAssociatedPlantSelected && !!marker?.trackedObject?.url;
    });
    return filteredMarkers.map(marker => {
      // removes closed tickets from marker which modifies panel content display
      const ticket = (marker.ticket && this.isTicketOpen(marker.ticket.url)) ? marker.ticket : null;
      return {
        ...marker,
        ticket,
      };
    });
  };

  handleMarkerClick = (targetMarker: MarkerType) => {
    const markerData = targetMarker.markerData as MarkerDataType;

    if (targetMarker.type === MARKER_TYPE_ORDER) {
      this.props.selectOrderMarker(targetMarker as any);
      return;
    }

    const ticketUrl = markerData?.ticket?.url ?? '';
    const truckAlias = markerData?.truckDetails?.trackedObject?.truckAlias ?? '';

    this.props.selectTruckMarker(
      targetMarker as any,
      ticketUrl,
      truckAlias
    );
  };

  handleLocation() {
    // pans to selected vehicle only if the selected truck has not already been panned to
    if (this.state.selectedMarker && (this.state.selectedMarker as any).isPannedTo) {
      // handle datablock not to block the view of selected truck
      // shrink padding size until datablock size is less than half the screen
      const padding = this.props.sidebarSelection?.sidebarTitle === TICKETS_SELECTED
        && this.map.current!.clientHeight < 850
        ? 0.00012 - (Math.log(this.map.current!.clientHeight - 539) * 0.00001)
        : 0;
      return {
        lng: 0,
        ...this.state.selectedMarker as {},
        lat: (this.state.selectedMarker as any).lat - padding,
      };
    }
    if ((!this.props.selectedMarker.lat || !this.props.selectedMarker.lng) && this.props.userOverrides && !this.userDidDrag) {
      return { lat: this.props.userOverrides.lat, lng: this.props.userOverrides.lng };
    }
    return this.state.location;
  }

  handleMarkerClose = (targetMarker: MarkerType) => {
    this.props.closePopup(targetMarker.markerId ?? '');
    this.props.clearSelectedMarker();
  };

  driverInfoMessageCallback = (driver?: DriverDto) => {
    this.props.driverInfoMessageCallback(driver);
  };

  ticketInfoTicketsCallBack = (marker: Marker) => {
    this.props.clearSelectedTicket();
    this.props.selectTicket(marker.ticket as TicketDto);
    this.props.setSidebarSelected(TICKETS_SELECTED, true);
    this.props.ticketInfoTicketsCallBack(marker);
  };

  orderDetailsCallback = (targetMarker: MarkerType) => {
    this.props.closePopup(targetMarker.markerId ?? '');
    this.props.orderDetailsCallback(targetMarker);
  };

  handleCenterChange = (center?: any) => {
    this.setState({ location: center });
    this.props.handleCenterChange(center);
  };

  isTicketOpen = (ticketUrl?: string) => this.props.ticketsList
    && !!(this.props.ticketsList.find(ticket => ticket.url === ticketUrl));

  render() {
    const truckMarkers = this.props.positions as PositionDto[];
    const filteredTruckMarkers = this.getFilteredTrucksMarkers(truckMarkers).map(m => {
      // This checks the condition only when m.active is true  or truck is loggedin
      if (m.active) {
        // this is not mutating props because getFilteredTrucksMarkers returns a copy of props.positions
        m.active = m.trackedObject?.isExternal ? this.props.showExtTrucks : this.props.showTrucks;
      }
      return m;
    });

    // filter plants for active plants of selected productline.
    const filteredPlantList = this.props.showPlants || this.props.showJobsites
      ? this.props.plantList.filter(p => {
        const isOfSelectedProductline = p.productLine === this.props.selectedProductLine;
        const isActive = p.archived === false && p.deleted === false;
        const plant: any = this.props.plantListFilter.find((plant: any) => (plant.id === p.id));
        return isActive && isOfSelectedProductline && plant ? plant.isSelected : true;
      })
      : [];
    const filteredOrdersList = this.props.showJobsites
      ? this.getValidOrders()
      : [];
    return (
      <div
        className={styles.mapPane}
        data-test="region-map"
        ref={this.map}
      >
        <TTMap
          center={this.handleLocation()}
          drag={() => { this.userDidDrag = true; }}
          currentRegion={this.props.currentRegion!}
          markers={filteredTruckMarkers}
          plantList={filteredPlantList}
          showPlants={this.props.showPlants}
          popupToggleMap={this.props.popupToggleMap}
          onMarkerClick={this.handleMarkerClick}
          onMarkerClose={this.handleMarkerClose}
          onCenterChange={this.handleCenterChange}
          driverInfoMessageCallback={(driver: DriverDto) => {
            this.driverInfoMessageCallback(driver);
          }}
          updateToFieldValueWithPlantDrivers={(url: string) => {
            this.props.updateToFieldValueWithPlantDrivers(url);
          }}
          ordersList={filteredOrdersList}
          ticketInfoTicketsCallBack={(ticket: Marker) => {
            this.ticketInfoTicketsCallBack(ticket);
          }}
          messageDriversOnOrder={this.props.messageDriversOnOrder}
          handleLogoutDriver={this.props.logoutDriver}
          orderDetailsCallback={this.orderDetailsCallback}
          showPlantsGeofences={this.props.showPlantsGeofences}
          showJobsitesGeofences={this.props.showJobsitesGeofences}
          markerStatusMap={retrieveProductLineNameDiff(this.props.selectedProductLine)}
          currentGeozone={this.props.currentGeozone}
          showTrucks={this.props.showTrucks}
          onZoomChange={this.props.handleZoomChange}
          showExtTrucks={this.props.showExtTrucks}
          selectedProductLine={this.props.selectedProductLine}
        />
      </div>
    );
  }
}

export interface SelectedMarker {
  markerId: number;
  lat: number;
  lng: number;
  isPannedTo: boolean;
}

export interface FeatureList {
  featuresList: {
    features: any,
    allFlags: any[]
  }
}

export interface MapWrapperState {
  location: {
    lat: number,
    lng: number
  },
  defaultLocation: {
    lat: number,
    lng: number
  },
  selectedMarker: string | null,
  tabNavigationListener: any,
}

export interface MapWrapperOwnProps {
  lng: number,
  lat: number,
  driverInfoMessageCallback: (driver?: DriverDto) => void,
  updateToFieldValueWithPlantDrivers: (url: string) => void,
  ticketInfoTicketsCallBack: (ticket: Marker) => void,
  orderDetailsCallback: (orderDetail: MarkerType) => void,
  messageDriversOnOrder?: (order: OrderDto) => void,
  orderListOn: (arg0: Date, arg1: Date) => void,
  orderListOff: () => void,
  ordersList?: OrderDto[],
  currentGeozone?: GeoZoneDto,
  handleZoomChange: (zoomLevel: number) => void,
  handleCenterChange: (center: any) => void
}

function mapStateToProps(state: ReduxState) {
  return {
    selectedMarker: state.selectedMarker,
    popupToggleMap: state.popupToggleMap,
    currentRegion: state.currentRegion,
    positions: state.positionList,
    ticketsList: state.ticketsList,
    sidebarSelection: state.sidebar,
    flags: state.flags as any,
    plantList: state.plantList,
    showTrucks: state.mapFilters.showTrucks,
    showPlants: state.mapFilters.showPlants,
    showJobsites: state.mapFilters.showJobsites,
    showPlantsGeofences: state.mapFilters.showPlantsGeofences,
    showJobsitesGeofences: state.mapFilters.showJobsitesGeofences,
    selectedProductLine: state.selectedProductLine,
    ordersStartDate: state.mapFilters.ordersStartDate,
    ordersEndDate: state.mapFilters.ordersEndDate,
    plantListFilter: state.mapFilters.plantList,
    showExtTrucks: state.mapFilters.showExtTrucks,
    userOverrides: state.userOverrides,
  };
}

interface MapWrapperDispatchProps {
  closePopup: (popup: string) => void,
  clearSelectedMarker: () => void,
  clearSelectedTicket: () => void,
  selectTicket: (ticket: TicketDto) => void,
  setIsPanned: () => void,
  setSidebarSelected: (sidebarTitle: SidebarTitleType, isSelected: boolean) => void,
  selectTruckMarker: ConnectedDispatchFunction<typeof selectTruckMarker>,
  selectOrderMarker: ConnectedDispatchFunction<typeof selectOrderMarker>,
  logoutDriver: (logout: LogoutDriverInfo) => void,
  refreshPositions: () => void
}

type MapWrapperReduxStateProps = ReturnType<typeof mapStateToProps>;

export type MapWrapperAllProps = MapWrapperOwnProps & MapWrapperDispatchProps & MapWrapperReduxStateProps;

export default connect(
  mapStateToProps,
  {
    closePopup,
    clearSelectedMarker,
    selectTicket,
    setSidebarSelected,
    clearSelectedTicket,
    logoutDriver,
    setIsPanned,
    refreshPositions,
    selectTruckMarker,
    selectOrderMarker,
    selectRouteMarker,
  }
)(MapWrapper);
