import { Quantity, QuantityDto, UnitOfMeasure } from '@trucktrax/trucktrax-ts-common';

interface QuantityCache {
  unitOfMeasure: UnitOfMeasure,
  value: number
}

/** Converts QuantityDto's into new Unit of Measures */
export default class QuantityConverter {
  private static cacheList: QuantityCache[][] = [];

  /*
  * Since unit conversion tends to lose precision, convert all units at once and cache them.
  * This way, going back and forth from a UOM to another, should hold the old values and return them without
  * progressively losing precision.
  */
  public static convertQuantity(fromQuantity: QuantityDto, toUom: UnitOfMeasure) {
    // If the values are 0, it does not matter. 0 is 0 anyway.
    if (fromQuantity.gross === 0
      && fromQuantity.tare === 0
      && fromQuantity.net === 0) {
      return {
        ...fromQuantity,
        unitOfMeasure: toUom,
      };
    }

    if (fromQuantity.type === Quantity.WEIGHT) {
      return QuantityConverter.convertWeightQuantity(fromQuantity, toUom);
    }

    // We currently only convert weight quantities.
    return { ...fromQuantity };
  }

  public static areEquals(quantity1: QuantityDto, quantity2: QuantityDto) {
    if (quantity1.type === Quantity.WEIGHT) {
      const quantity2Equality = QuantityConverter.convertWeightQuantity(quantity2, quantity1.unitOfMeasure);
      return quantity1.gross === quantity2Equality.gross
        && quantity1.tare === quantity2Equality.tare;
    }

    return true;
  }

  private static convertWeightQuantity(fromQuantity: QuantityDto, toUom: UnitOfMeasure): QuantityDto {
    return {
      ...fromQuantity,
      unitOfMeasure: toUom,
      gross: QuantityConverter.convertValue(fromQuantity.gross, fromQuantity.unitOfMeasure, toUom) ?? 0,
      tare: QuantityConverter.convertValue(fromQuantity.tare, fromQuantity.unitOfMeasure, toUom) ?? 0,
      net: QuantityConverter.convertValue(fromQuantity.net, fromQuantity.unitOfMeasure, toUom) ?? 0,
    };
  }

  public static convertValue(value: number, fromUom: UnitOfMeasure, toUom: UnitOfMeasure) {
    let cache = QuantityConverter.cacheList.find(
      c => c.some(
        v => v.unitOfMeasure === fromUom && v.value === value
      )
    );

    if (!cache) {
      const uoms = [
        UnitOfMeasure.POUNDS,
        UnitOfMeasure.SHORT_TONS,
        UnitOfMeasure.METRIC_TONS,
        UnitOfMeasure.KILOGRAMS,
      ];

      cache = [];

      uoms.forEach(u => {
        cache!.push({ value: QuantityConverter.getValueInUom(value, fromUom, u), unitOfMeasure: u });
      });

      QuantityConverter.cacheList.push(cache);
    }

    return cache.find(c => c.unitOfMeasure === toUom)?.value;
  }

  private static getValueInUom = (value: number, fromUom: UnitOfMeasure, toUom: UnitOfMeasure) => {
    if (fromUom === toUom) {
      return value;
    }

    const multiplier = QuantityConverter.getMultiplierForUom(fromUom, toUom);
    const roundFactor = 1000; // Rounds to 3 decimal places
    return Math.round(value * multiplier * roundFactor) / roundFactor;
  };

  private static getMultiplierForUom = (fromUom: UnitOfMeasure, toUom: UnitOfMeasure) => {
    switch (fromUom) {
      case UnitOfMeasure.SHORT_TONS: {
        switch (toUom) {
          case UnitOfMeasure.POUNDS: return 2000;
          case UnitOfMeasure.METRIC_TONS: return 0.907185;
          case UnitOfMeasure.KILOGRAMS: return 907.185;
          default: return 1;
        }
      } case UnitOfMeasure.POUNDS: {
        switch (toUom) {
          case UnitOfMeasure.SHORT_TONS: return 0.0005;
          case UnitOfMeasure.METRIC_TONS: return 0.000453592;
          case UnitOfMeasure.KILOGRAMS: return 0.453592;
          default: return 1;
        }
      } case UnitOfMeasure.METRIC_TONS: {
        switch (toUom) {
          case UnitOfMeasure.SHORT_TONS: return 1.10231;
          case UnitOfMeasure.POUNDS: return 2204.62;
          case UnitOfMeasure.KILOGRAMS: return 1000;
          default: return 1;
        }
      } case UnitOfMeasure.KILOGRAMS: {
        switch (toUom) {
          case UnitOfMeasure.SHORT_TONS: return 0.00110231;
          case UnitOfMeasure.POUNDS: return 2.20462;
          case UnitOfMeasure.METRIC_TONS: return 0.001;
          default: return 1;
        }
      } default: {
        return 1;
      }
    }
  };
}
