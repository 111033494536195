import React from 'react';
import cx from 'classnames';
import styles from './Bubble.module.css';

interface BubbleProps {
  dataTest: string;
  message: string;
  author?: string;
  isFrom?: boolean;
  isIcon: boolean;
}

const Bubble = (props: BubbleProps = { dataTest: '', isIcon: false, message: '' }) => {
  const {
    isFrom, message, isIcon, dataTest, author,
  } = props;
  const sent = isFrom ? null : styles.sent;
  const longMessage = message.length > 20 ? styles.longMessage : null;
  const fit = isIcon ? styles.shrinkAndKeepLeft : null;
  return (
    <div className={styles.container} data-test={dataTest}>
      <div className={cx(styles.bubble, styles.right, sent, longMessage, fit)}>
        <span className={cx(styles.content, sent)} lang="en-US">
          {message}
        </span>
      </div>
      <div
        data-test={dataTest + author}
        className={cx(styles.author, isFrom ? styles.right : styles.left)}
      >
        {author}
      </div>
    </div>
  );
};

Bubble.defaultProps = {
  author: '',
  isFrom: null,
};

export default Bubble;
