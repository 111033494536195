import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import debounce from 'lodash/debounce';
import { clearSearchTerm, setSearchTerm } from '../../../store/actions/dataTableActions';
import { fetchDataTableData } from '../../../services/dataTableService';
import styles from './VehicleSearch.module.css';
import { ADMIN_SEARCH_HINT, DEFAULT_PAGE_SIZE } from '../../../constants/appConstants';
import Autocomplete from '../forms/Autocomplete';
import { VEHICLES_TEXT } from '../../../constants/navConstants';
import { DataTableContentColumn } from '../DataTableContent';

export interface DataTableSearchState {
  dataSource: any[];
  searchText: string;
  hintText: string;
}

export class DataTableSearch extends Component<DataTableSearchProps, DataTableSearchState> {
  static defaultProps: Partial<DataTableSearchProps> = {
    currentRegion: {
      url: '',
    },
    baseUrl: '',
    noRegionFilter: false,
    searchText: '',
    isArchived: false,
    queryParams: {},
    source: '',
  };

  constructor(props: DataTableSearchProps) {
    super(props);

    this.state = {
      dataSource: [],
      searchText: '',
      hintText: this.getHintText(),
    };
  }

  getHintText = () => {
    let headerText = this.props.header;
    if (headerText.includes('Orders')) {
      return ADMIN_SEARCH_HINT.Orders;
    }

    if (headerText.includes(VEHICLES_TEXT)) {
      return ADMIN_SEARCH_HINT.Trucks;
    }

    headerText = headerText.replace(' ', '');

    type HintMap = typeof ADMIN_SEARCH_HINT;
    return ADMIN_SEARCH_HINT[headerText as keyof HintMap];
  };

  componentDidUpdate(prevProps: DataTableSearchProps) {
    if (this.props.header !== prevProps.header) {
      this.setState({
        hintText: this.getHintText(),
        searchText: '',
      });
    }
    if (this.props.searchText !== prevProps.searchText) {
      this.setState({
        searchText: this.props.searchText!,
      });
    }
  }

  fetchData = () => {
    this.props.fetchDataTableData(
      this.props.baseUrl ?? '',
      this.props.noRegionFilter ? '' : this.props.currentRegion!.url!,
      DEFAULT_PAGE_SIZE,
      1, // trucktrax microservices are not zero-based.,
      [],
      null,
      this.props.isArchived,
      this.props.queryParams,
      false,
      false,
      this.props.source,
      this.props.columns
    );
  };

  debouncedCall = debounce((searchText: string) => {
    this.props.setSearchTerm(searchText);
    this.fetchData();
  }, 1000, {
    leading: false,
    trailing: true,
  });

  handleUpdateInput = (searchText: string) => {
    this.setState({
      searchText,
    });
    this.debouncedCall(searchText);
  };

  clearSearch() {
    this.props.clearSearchTerm();
    this.fetchData();
  }

  render() {
    return (
      <div className={styles.searchWrapper}>
        <Autocomplete
          dataTest="header-truck-search"
          hintText={this.state.hintText}
          onUpdateInput={this.handleUpdateInput}
          searchText={this.state.searchText}
          dataSource={this.state.dataSource}
          openOnFocus={false}
          autocompleteHeight="500px"
          clearSearch={() => this.clearSearch()}
          onNewRequest={() => {
          }}
        />
      </div>
    );
  }
}

export interface DataTableSearchProps {
  currentRegion?: UrlKeyDto;
  fetchDataTableData: typeof fetchDataTableData;
  baseUrl?: string;
  header: string;
  setSearchTerm: (term: string) => void;
  noRegionFilter?: boolean;
  searchText?: string;
  clearSearchTerm: () => void;
  isArchived?: boolean;
  queryParams?: any;
  source: string;
  columns: DataTableContentColumn[];
}

const mapStateToProps = (state: any) => ({
  dataSource: state.dataTableList.items,
  isLoading: state.dataTableList.isLoading,
  baseUrl: state.dataTableList.baseUrl,
  currentRegion: state.currentRegion,
  searchText: state.dataTableSearch.searchTerm,
});

export default connect<any, any, any>(mapStateToProps, {
  fetchDataTableData,
  setSearchTerm,
  clearSearchTerm,
})(DataTableSearch);
