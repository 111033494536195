import { Dispatch } from 'redux';
import { formatInTimeZone } from 'date-fns-tz';
import { OperatorNotesDto } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { getRequest, postRequest } from './requestService';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import recordLog, { devErrorAndLog } from '../util/errorUtil';
import { openFailModal } from '../store/actions/errorModalActions';
import { OPERATOR_NOTES_PATH, OPERATOR_NOTES_OPERATORS_PATH } from '../constants/apiConstants';
import { ERROR_TEXT_FETCH_OPERATOR_NOTES } from '../constants/errorConstants';
import { CANCEL_API_DUE_TO_PAGE_CHANGE } from '../constants/appConstants';
import { FETCH_OPERATOR_NOTES, FETCH_OPERATOR_NOTES_OPERATORS } from '../constants/actionConstants';
import HTTP_CODES from '../constants/httpConstants';

/*
* Expresses a date in the UTC standard, in the format 'yyyy-MM-dd HH:mm:ss'
* For example, if the date object passed in could be represented as Friday Aug 04 2023 11:59:59 GMT-0700 (Pacific Daylight Time)
* Then expected output would be 2023-08-05 06:59:59
* because UTC is 7 hours ahead of PDT, or the America/Los_Angeles Time Zone
* PDT was just an example, this should work whatever the user's local timezone may be
*/
export const formatTimestamp = (timestamp: Date) => formatInTimeZone(timestamp, 'UTC', 'yyyy-MM-dd HH:mm:ss');

export function fetchOperatorNotes(
  timestamp: Date,
  plantUrl?: string,
  userUrl?: string,
  page?: number
) {
  return async (dispatch: Dispatch) => {
    if (!plantUrl) { return; }

    const url = getGeotraxBaseUrl() + OPERATOR_NOTES_PATH;
    const params = {
      timestamp: formatTimestamp(timestamp),
      plantUrl,
      page: page ?? 1,
    } as any;
    if (userUrl) {
      params.userUrl = userUrl;
    }

    try {
      const response = await getRequest(url, params);
      dispatch({
        type: FETCH_OPERATOR_NOTES,
        payload: {
          items: response.data.items,
          plantUrl,
          page,
        },
      });
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          ERROR_TEXT_FETCH_OPERATOR_NOTES,
          `url: ${url} params: ${JSON.stringify(params)}`,
          e.toString(),
          undefined,
          consoleOnly
        ) as any);
      }
    }
  };
}

export function fetchNotebookOperators(timestamp?: Date, plantUrl?: string) {
  return async (dispatch: Dispatch) => {
    if (!timestamp || !plantUrl) {
      return;
    }

    try {
      const url = getGeotraxBaseUrl() + OPERATOR_NOTES_OPERATORS_PATH;
      const params = {
        timestamp: formatTimestamp(timestamp),
        plantUrl,
      };

      const response = await getRequest(url, params);
      dispatch({
        type: FETCH_OPERATOR_NOTES_OPERATORS,
        payload: response.data,
      });
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          ERROR_TEXT_FETCH_OPERATOR_NOTES,
          `planturl: ${plantUrl}, timestamp: ${timestamp}`,
          e.toString(),
          undefined,
          consoleOnly
        ) as any);
      }
    }
  };
}

export function saveOperatorNotes(plantUrl?: string, userUrl?: string, notes?: string) {
  return async (dispatch: Dispatch) => {
    const url = getGeotraxBaseUrl() + OPERATOR_NOTES_PATH;

    if (!plantUrl || !userUrl || !notes) {
      return;
    }

    try {
      const newNote: OperatorNotesDto = {
        plant: { url: plantUrl },
        user: { url: userUrl },
        deleted: false,
        archived: false,
        id: 0,
        notes,
      };

      await postRequest(url, newNote);
      dispatch<any>(fetchOperatorNotes(new DateWrapper().endOfDay.date, plantUrl, ''));
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        dispatch(openFailModal(e.toString(), ERROR_TEXT_FETCH_OPERATOR_NOTES));
        recordLog(ERROR_TEXT_FETCH_OPERATOR_NOTES, e.toString());
      }
    }
  };
}
