import forEach from 'lodash/forEach';
import {
  getMixDesignItem,
  TicketDto,
  PositionDto,
  UnitOfMeasure,
  QualityFactorDto,
  StatusEventDto,
  LocationDto,
} from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { sanitizeText, UOM_LABELS } from './adminUtil';
import {
  PositionDetailsDto,
} from '../types';
import {
  STATUS_EVENT_ORDER,
  TICKET_SEARCH_FIELDS,
} from '../constants/appConstants';

export function matchTicketToPosition(tickets: TicketDto[], position: PositionDetailsDto) {
  const newPosition = position;
  forEach(tickets, (ticket) => {
    if (ticket && ticket.truck!.url === newPosition.trackedObject.url) {
      newPosition.ticket = ticket;
      const ticketPosition: PositionDto = {
        ...position,
        ticket: { url: ticket.url },
      };

      ticket.position = ticketPosition;
    }
  });
  return newPosition;
}

export function setTicketsToPositions(tickets: TicketDto[], allPositions: PositionDetailsDto[]) {
  const positions = allPositions || [];
  forEach(positions, (position) => {
    if (position && position.trackedObject) {
      matchTicketToPosition(tickets, position);
    }
  });
  return positions;
}

const hasMatchingKey = (ticket: TicketDto, sanitizedSearch: string) => {
  type TicketKey = keyof TicketDto;
  const keys = Object.keys(ticket) as TicketKey[];
  const relevantKeys = keys.filter(k => TICKET_SEARCH_FIELDS.includes(k));

  const hasMatch = relevantKeys.some(curr => {
    if (curr === 'plant' && ticket[curr]?.name) {
      return sanitizeText(ticket.plant!.name ?? '', true)
        .toLowerCase()
        .includes(sanitizedSearch);
    }

    if (curr === 'driver') {
      const driverName = ticket.externalInfo?.driverName ?? '';
      const [lastName, firstName] = driverName.split(',').map(name => name.trim());

      const partsAndWhole = [lastName, firstName, driverName];
      const availableParts = partsAndWhole.filter(p => !!p);
      const anyPartIncludesSearch = availableParts.some(
        text => sanitizeText(text, true).toLowerCase().includes(sanitizedSearch)
      );

      return anyPartIncludesSearch;
    }

    const truckAlias = ticket.truck?.truckAlias ?? ticket.externalInfo?.truckAlias;
    if (curr === 'truck' && truckAlias) {
      return sanitizeText(truckAlias, true)
        .toLowerCase()
        .includes(sanitizedSearch);
    }

    if (curr === 'order' && ticket[curr]) {
      const externalId = ticket.order?.externalId ?? '';
      const customerName = ticket.order?.customerName ?? '';
      const projectName = ticket.order?.projectName ?? '';
      const parts = [externalId, customerName, projectName];
      const availableParts = parts.filter(p => !!p);
      const anyPartIncludesSearch = availableParts.some(
        text => sanitizeText(text, true).toLowerCase().includes(sanitizedSearch)
      );
      return anyPartIncludesSearch;
    }

    if (curr === 'externalInfo' && ticket[curr]?.plantName) {
      return sanitizeText(ticket.externalInfo!.plantName ?? '', true)
        .toLowerCase()
        .includes(sanitizedSearch);
    }

    return sanitizeText(ticket[curr]?.toString() ?? '', true)
      .toLowerCase()
      .includes(sanitizedSearch);
  });

  return hasMatch;
};

export function filterBySearchTerm(arrayOfObject: any[], searchTerm: string) {
  if (!searchTerm) {
    return arrayOfObject;
  }

  // sanitize user input
  const sanitizedSearch = sanitizeText(searchTerm, true).toLowerCase();

  return arrayOfObject.filter((obj) => hasMatchingKey(obj, sanitizedSearch));
}

type StatusEventOrderType = typeof STATUS_EVENT_ORDER;
type StatusEventOrderTypeKey = keyof StatusEventOrderType;

export function getLatestStatus(statuses: StatusEventDto[]) {
  let latestStatus = null;
  if (statuses.length > 0) {
    let latestIndex = 0;
    statuses.forEach(element => {
      const key = element.status as StatusEventOrderTypeKey;
      if (STATUS_EVENT_ORDER[key] >= latestIndex) {
        latestIndex = STATUS_EVENT_ORDER[key];
        latestStatus = element;
      }
    });
  }
  return latestStatus;
}

export function tonsLbsConversion(weight = 0, convertTo: UnitOfMeasure | null = null) {
  if (weight > 0 && convertTo !== null) {
    return convertTo === UnitOfMeasure.SHORT_TONS
      ? Number.parseFloat((weight / 2000).toString())
      : Number.parseFloat((weight * 2000).toString());
  }

  return weight;
}

export function metrictonsKgsConversion(weight = 0, convertTo: UnitOfMeasure | null = null) {
  if (weight > 0 && convertTo !== null) {
    return convertTo === UnitOfMeasure.METRIC_TONS
      ? Number.parseFloat((weight / 1000).toString())
      : Number.parseFloat((weight * 1000).toString());
  }

  return weight;
}

export function stringToUnitOfMeasure(unit: string) {
  switch (unit) {
    case 'SHORT_TONS':
      return UnitOfMeasure.SHORT_TONS;
    case 'METRIC_TONS':
      return UnitOfMeasure.METRIC_TONS;
    case 'POUNDS':
      return UnitOfMeasure.POUNDS;
    case 'KILOGRAMS':
      return UnitOfMeasure.KILOGRAMS;
    case 'GRAMS':
      return UnitOfMeasure.GRAMS;
    case 'CUBIC_METERS':
      return UnitOfMeasure.CUBIC_METERS;
    case 'CUBIC_YARDS':
      return UnitOfMeasure.CUBIC_YARDS;
    case 'METERS':
      return UnitOfMeasure.METERS;
    case 'YARDS':
      return UnitOfMeasure.YARDS;
    case 'FEET':
      return UnitOfMeasure.FEET;
    case 'INCHES':
      return UnitOfMeasure.INCHES;
    case 'CENTIMETERS':
      return UnitOfMeasure.CENTIMETERS;
    case 'GALLONS':
      return UnitOfMeasure.GALLONS;
    case 'FLUID_OUNCES':
      return UnitOfMeasure.FLUID_OUNCES;
    case 'WEIGHT_OUNCES':
      return UnitOfMeasure.WEIGHT_OUNCES;
    case 'EACH':
      return UnitOfMeasure.EACH;
    case 'LOAD':
      return UnitOfMeasure.LOAD;
    default:
      return UnitOfMeasure.POUNDS;
  }
}

export function formatWeight(unitDisplay: UnitOfMeasure | string, unitMeasure: UnitOfMeasure | string, weight: string | number) {
  const normalizedDisplay = typeof unitDisplay === 'number' ? unitDisplay as UnitOfMeasure : stringToUnitOfMeasure(unitDisplay as string);
  const normalizedMeasure = typeof unitMeasure === 'number' ? unitMeasure as UnitOfMeasure : stringToUnitOfMeasure(unitMeasure as string);

  let w = Number(weight);
  if (normalizedDisplay !== normalizedMeasure) {
    w = tonsLbsConversion(w, normalizedDisplay);
  }
  const isInTons = normalizedDisplay === stringToUnitOfMeasure('SHORT_TONS');
  return isInTons
    ? w.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    : w.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
}

export function formatWeightMetric(unitDisplay: UnitOfMeasure | string, unitMeasure: UnitOfMeasure | string, weight: string | number) {
  const normalizedDisplay = typeof unitDisplay === 'number' ? unitDisplay as UnitOfMeasure : stringToUnitOfMeasure(unitDisplay as string);
  const normalizedMeasure = typeof unitMeasure === 'number' ? unitMeasure as UnitOfMeasure : stringToUnitOfMeasure(unitMeasure as string);

  let w = Number(weight);
  if (normalizedDisplay !== normalizedMeasure) {
    w = metrictonsKgsConversion(w, normalizedDisplay);
  }
  const isInTons = normalizedDisplay === stringToUnitOfMeasure('METRIC_TONS');
  return isInTons
    ? w.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    : w.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
}

const SCALE_TYPE_LABELS = {
  MANUAL: 'Manual',
  LIVE: 'Live',
  KIOSK: 'Kiosk',
};

export const getQuantityValue = (ticket: TicketDto) => {
  const item = getMixDesignItem(ticket);
  const validValue = item && item?.quantity && item?.quantity.gross;
  if (!validValue) {
    return undefined;
  }
  return item!.quantity!.gross.toFixed(2);
};

export const getUomLabel = (ticket: TicketDto) => {
  const item = getMixDesignItem(ticket);
  const validValue = item && item?.quantity && item?.quantity?.unitOfMeasure;
  if (!validValue) {
    return undefined;
  }
  type UomLabelType = typeof UOM_LABELS;
  return UOM_LABELS[item!.quantity!.unitOfMeasure as keyof UomLabelType] || item!.quantity!.unitOfMeasure;
};

export const getScaleTypeLabel = (ticket: TicketDto) => {
  type ScaleTypeLabels = typeof SCALE_TYPE_LABELS;
  return (
    SCALE_TYPE_LABELS[
    ticket.externalInfo?.scaleType as keyof ScaleTypeLabels
    ] || 'Unknown'
  );
};

export const getSlumpLabel = (ticket: TicketDto) => {
  // Without this OR condition, a valid value of zero inches will return false
  const validValue = ticket && (ticket.slump || ticket.slump === 0);
  if (!validValue) {
    return undefined;
  }
  return `${ticket.slump}″`;
};

export function getReadableTimestamp(timestamp: string) {
  return new DateWrapper(timestamp).format('h:mm a');
}

export function getReadableDate(timestamp: string) {
  return new DateWrapper(timestamp).format('MM/dd/yy');
}

export interface StatusFolderItem {
  id: string;
  name: string;
  time?: string;
  historical?: boolean;
  color?: string;
  position?: PositionDto;
}

export interface StatusFolder {
  id: string;
  name: string;
  color: string;
  icon: string;
  text: string,
  dateText?: string,
  timeText?: string,
  itemList: StatusFolderItem[],
  dataTest: string;
  time?: string;
  location?: LocationDto;
}

export function statusFolderList(ticketStatuses: StatusFolder[], folderList: StatusFolder[]) {
  // Push all route info times to their respective statuses
  if (ticketStatuses && folderList) {
    folderList.forEach((item) => {
      // eslint-disable-next-line array-callback-return
      ticketStatuses.find((status) => {
        if (item.id === status.name) {
          item.itemList = status.itemList;
          item.time = status.time;
          item.text = `${getReadableDate(status.time!)}   ${getReadableTimestamp(status.time!)}`;
          item.dateText = `${getReadableDate(status.time!)}`;
          item.timeText = `${getReadableTimestamp(status.time!)}`;
        }
      });
    });
  }
  return folderList;
}

// this function assumes positions have already been sorted by timestamp
export function removePositionsWithDuplicateMinutes(positions: PositionDto[]) {
  if (positions.length > 1) {
    const uniquePositions = []; // unique meaning each will have a unique minute
    let lastPositionTime = new Date(positions[0].timestamp as string).getTime();
    uniquePositions.push(positions[0]);

    for (let i = 1; i < positions.length; i += 1) {
      const currentPositionTime = new Date(positions[i].timestamp as string).getTime();
      if (Math.floor(currentPositionTime / 60000) !== Math.floor(lastPositionTime / 60000)) {
        uniquePositions.push(positions[i]);
        lastPositionTime = currentPositionTime;
      }
    }

    return uniquePositions;
  }

  return positions;
}

export function subtotal(arr: number[]) {
  if (!arr.length) return 0;
  return arr.reduce((acc, curr) => {
    const accu = acc + curr;
    return accu;
  });
}

interface Additive {
  value: number;
  timestamp: string;
}

interface QualityFactorHashMap {
  INITIAL_DRUM_COUNT: number | undefined,
  FINAL_DRUM_COUNT: number | undefined,
  FINAL_LOAD_COUNT: number | undefined;
  CONCRETE_REMAINING: number;
  SLUMP: number;
  CYLINDER_TEST: 'Yes' | 'No';
  WATER_ADDED: Additive[];
  // eslint-disable-next-line camelcase
  WATER_ADDED_Total: number;
  ADMIXTURE_ADDED: Additive[]
  ADMIXTURE_ADDED_TOTAL: number
}
type QualityFactorKey = keyof QualityFactorHashMap;

export function qualityFactorDataConverter(arr: QualityFactorDto[]) {
  const qfHash: QualityFactorHashMap = {
    INITIAL_DRUM_COUNT: undefined,
    FINAL_DRUM_COUNT: undefined,
    FINAL_LOAD_COUNT: undefined,
    CONCRETE_REMAINING: 0,
    SLUMP: 0,
    CYLINDER_TEST: 'No',
    WATER_ADDED: [],
    WATER_ADDED_Total: 0,
    ADMIXTURE_ADDED: [],
    ADMIXTURE_ADDED_TOTAL: 0,
  };

  if (!arr || !arr.length) return qfHash;

  arr.forEach(({ peripheralDataType, value, timestamp }) => {
    const key = peripheralDataType as QualityFactorKey;
    if (Array.isArray(qfHash[key] as Additive[])) {
      (qfHash[key] as Additive[]).push({ value: value as number, timestamp: new DateWrapper(timestamp!).format('M/dd/yyyy, h:mm a') });
    } else {
      (qfHash[key] as number) = value as number;
    }
  });

  qfHash.FINAL_LOAD_COUNT = (qfHash.INITIAL_DRUM_COUNT !== undefined && qfHash.FINAL_DRUM_COUNT !== undefined)
    && qfHash.FINAL_DRUM_COUNT - qfHash.INITIAL_DRUM_COUNT >= 0
    ? qfHash.FINAL_DRUM_COUNT - qfHash.INITIAL_DRUM_COUNT
    : undefined;
  qfHash.WATER_ADDED_Total = subtotal(qfHash.WATER_ADDED.map(w => w.value));
  qfHash.ADMIXTURE_ADDED_TOTAL = subtotal(qfHash.ADMIXTURE_ADDED.map(a => a.value));

  return qfHash;
}
