import React, { Component } from 'react';
import cx from 'classnames';
import dragBarIcon from '../../assets/img/dragBarIcon.svg';
import styles from './VerticalSplitPane.module.scss';

class VerticalSplitPane extends Component<VerticalSplitPaneProps, VerticalSplitPaneState> {
  private transparentDragImage: HTMLImageElement | undefined;

  private containerRef: React.RefObject<HTMLDivElement>;

  private lowerPaneHeightMax = 700;

  private lowerPaneHeightMin = 100;

  constructor(props: VerticalSplitPaneProps) {
    super(props);
    this.state = {
      isResizing: false,
      lowerPaneHeight: 400, // this is a default/fallback, after component mounts we will reset this value to be 35% of parent
      mouseYPositionAfterLastDragEvent: null,
    };

    this.containerRef = React.createRef();
  }

  get upperPaneHeight(): string {
    return (this.props.showLowerPane) ? `calc(100% - ${this.state.lowerPaneHeight}px)` : '100%';
  }

  get lowerPaneHeight(): string {
    return (this.props.showLowerPane) ? `${this.state.lowerPaneHeight}px` : '0';
  }

  componentDidMount() {
    // the transparent drag image needs to be created before usage (rather than creating the empty image on drag start)
    // or else it will be buggy in chrome
    this.transparentDragImage = new Image();
    this.transparentDragImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';

    // measure the height of the entire container, and set lower pane height to be 35%, as specified by design
    if (this.containerRef.current) {
      const height = this.containerRef.current.offsetHeight;
      let initialLowerPaneHeight = height * 0.35;
      initialLowerPaneHeight = Math.min(initialLowerPaneHeight, this.lowerPaneHeightMax);
      initialLowerPaneHeight = Math.max(initialLowerPaneHeight, this.lowerPaneHeightMin);

      this.setState({
        lowerPaneHeight: initialLowerPaneHeight
      });
    }
  }

  onDragStart = (e: React.DragEvent) => {
    if (this.transparentDragImage !== undefined) {
      e.dataTransfer.setDragImage(this.transparentDragImage, 0, 0);
    }

    this.setState({
      isResizing: true,
      mouseYPositionAfterLastDragEvent: e.clientY,
    });
  };

  onDragEnd = () => {
    this.setState({
      isResizing: false,
      mouseYPositionAfterLastDragEvent: null,
    });
  };

  // move the sliding boundary on drag
  onDrag = (e: React.DragEvent) => {
    if (!this.state.isResizing || !e.clientY) {
      return;
    }

    const diff = this.state.mouseYPositionAfterLastDragEvent !== null ? this.state.mouseYPositionAfterLastDragEvent - e.clientY : 0;
    let nextLowerPaneHeight = this.state.lowerPaneHeight + diff;
    nextLowerPaneHeight = Math.min(nextLowerPaneHeight, this.lowerPaneHeightMax);
    nextLowerPaneHeight = Math.max(nextLowerPaneHeight, this.lowerPaneHeightMin);

    this.setState({
      lowerPaneHeight: nextLowerPaneHeight,
      mouseYPositionAfterLastDragEvent: e.clientY,
    });
  };

  render() {
    return (
      <div className={styles.verticalSplitPaneContainer} ref={this.containerRef}>
        <div className={styles.pane} style={{ height: this.upperPaneHeight }}>
          {this.props.upperPane}
        </div>
        {this.props.showLowerPane && (
          <button
            className={cx(styles.slidingPaneBoundary, this.state.isResizing && styles.isResizing)}
            draggable
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}
            onDrag={this.onDrag}
          >
            <img src={dragBarIcon} alt="drag indicator" />
          </button>
        )}
        <div className={styles.pane} style={{ height: this.lowerPaneHeight }}>
          {this.props.lowerPane}
        </div>
      </div>
    );
  }
}

export interface VerticalSplitPaneState {
  lowerPaneHeight: number;
  isResizing: boolean;
  mouseYPositionAfterLastDragEvent: number | null;
}

export interface VerticalSplitPaneProps {
  upperPane: JSX.Element;
  lowerPane: JSX.Element;
  showLowerPane: boolean;
}

export default VerticalSplitPane;
