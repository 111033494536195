// eslint-disable-next-line import/no-extraneous-dependencies
import { Logger } from '@opentelemetry/api-logs';
import { PayloadAction } from '../../types';
import { SET_TELEMETRY_LOGGER } from '../../constants/actionConstants';

// eslint-disable-next-line import/prefer-default-export
export const telemetryLogger = (
  state: any = null,
  action: PayloadAction<Logger, 'SET_TELEMETRY_LOGGER'>
): any => {
  switch (action.type) {
    case SET_TELEMETRY_LOGGER:
      return action.payload ?? state;
    default:
      return state;
  }
};
