import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  ProductLine, RegionDto, UserRegionDto,
  VoidFunc
} from '@trucktrax/trucktrax-ts-common';
import RegionsSelect from './RegionsSelect';
import ProductLineSelect from '../ProductLineSelect';
import { setSelectedProductLine } from '../../../store/actions/productLineActions';
import { ReduxState } from '../../../store';
import { refreshGeoTraxState } from '../../../services/appService';
import { noop } from '../../../util/appUtil';

/**
 * Filters the Regions by the selected product line, and ensures Region is valid
 * for the selected product line.
 *
 * There are OnChange hooks available to tie into.
 */

export class ProductLineAndRegionManager extends Component<ProductLineAndRegionManagerProps> {
  static defaultProps: Partial<ProductLineAndRegionManagerProps> = {
    regions: [],
    selectedProductLine: ProductLine.None,
    onProductLineChanged: noop,
    onRegionChanged: noop,
  };

  defaultRegion = () => this.props.regions.find(r => r.url === this.props.defaultRegionUrl);

  isProductLineInCurrentRegion = (pl: ProductLine) => pl && this.props.currentRegion && this.props.currentRegion.productLines?.includes(pl);

  isProductLineInDefaultRegion = (pl: ProductLine) => pl && this.defaultRegion()?.productLines?.includes(pl);

  static ByNameSorter = (r1: RegionDto, r2: RegionDto) => r1.name!.localeCompare(r2?.name!);

  BySelectedProductLineFilter = (r: RegionDto) => r.productLines?.includes(this.props.selectedProductLine);

  regionsWithSelectedProductLine = () => this.props.regions
    .filter(this.BySelectedProductLineFilter);

  validRegionsSortedByName = () => this
    .regionsWithSelectedProductLine()
    .sort(ProductLineAndRegionManager.ByNameSorter);

  bestRegionForProductLine = (pl: ProductLine) => {
    if (this.isProductLineInCurrentRegion(pl)) {
      return this.props.currentRegion;
    }

    if (this.isProductLineInDefaultRegion(pl)) {
      return this.defaultRegion()!;
    }

    return this.validRegionsSortedByName().at(0)!;
  };

  updateRegionForProductLine = (pl: ProductLine) => {
    const { currentRegion, onRegionChanged } = this.props;
    const bestRegion = this.bestRegionForProductLine(pl);

    if (currentRegion === bestRegion) {
      return currentRegion;
    }
    // change region once bestRegion is findable, race conditions apply
    if (bestRegion) {
      onRegionChanged(bestRegion.url!);
    }
    return bestRegion;
  };

  onProductLineChanged = (pl: ProductLine) => {
    const { refreshGeoTraxState, userUrl, messageSubscription } = this.props;
    setSelectedProductLine(pl);
    const validRegion = this.updateRegionForProductLine(pl);

    refreshGeoTraxState(
      validRegion,
      pl,
      userUrl,
      messageSubscription
    );

    this.props.onProductLineChanged(pl);
  };

  onRegionChanged = (region: RegionDto) => {
    this.props.onRegionChanged(region.url!);
  };

  render() {
    const regionsWithSelectedProductLine = this.regionsWithSelectedProductLine();
    const productLinesDisabled = this.props.productLinesDisabled ?? false;

    // covers the edge case where the current region is not in the selected Product Line
    // (like possibly when navigating to Map or Orders from Admin);
    this.updateRegionForProductLine(this.props.selectedProductLine);

    return (
      <div>
        <ProductLineSelect
          disabled={productLinesDisabled}
          onSelectionChanged={this.onProductLineChanged}
        />
        <RegionsSelect
          onSelectionChanged={this.onRegionChanged}
          regions={regionsWithSelectedProductLine}
        />
      </div>
    );
  }
}

export interface ProductLineAndRegionManagerProps {
  defaultRegionUrl: string;
  regions: RegionDto[];
  currentRegion: UserRegionDto;
  messageSubscription: { unsubscribe: () => void }[];
  productLinesDisabled?: boolean;
  selectedProductLine: ProductLine;
  userUrl?: string;
  onProductLineChanged: (productLine: ProductLine) => void;
  onRegionChanged: (regionUrl: string) => void;
  refreshGeoTraxState: (
    region?: { url?: string, name?: string },
    productLine?: ProductLine,
    userUrl?: string,
    messageSubscriptions?: {
      url?: string,
      unsubscribe: VoidFunc
    }[]
  ) => void;
}

function mapStateToProps(state: ReduxState) {
  return {
    currentRegion: state.currentRegion,
    defaultRegionUrl: state.defaultRegionList[0].url,
    messageSubscription: state.messageSubscription,
    selectedProductLine: state.selectedProductLine,
    userUrl: state.userUrl,
  };
}

export default connect<any, any, any, ReduxState>(mapStateToProps, {
  setSelectedProductLine,
  refreshGeoTraxState
})(ProductLineAndRegionManager);
