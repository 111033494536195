import { HasName } from '../types';

export default function sortRegionsAlpha<T extends HasName>(regions: T[]) {
  return regions.sort((r1, r2) => {
    if (r1.name!.toUpperCase() < r2.name!.toUpperCase()) {
      return -1;
    }
    if (r1.name!.toUpperCase() > r2.name!.toUpperCase()) {
      return 1;
    }
    return 0;
  });
}
