import React, { Component } from 'react';
import { FloatingActionButton } from '@trucktrax/trucktrax-common';
import cx from 'classnames';
import { connect } from 'react-redux';
import { ProductLine, UrlKeyDto, VoidFunc } from '@trucktrax/trucktrax-ts-common';
import styles from './UsersAddModal.module.css';
import {
  defaultRegionListForDropdown,
  dtoToUserDto, isUndefined,
  onSubmitSuccess,
} from '../../../util/adminUtil';
import { addToOrUpdateListInStore } from '../../../store/actions/dataTableActions';
import { createDataTableRecord } from '../../../services/dataTableService';
import AdminAddModal, { AdminConfig } from '../../shared/admin/AdminAddModal';
import { ADD_USER } from '../../../constants/actionConstants';
import {
  ADD_USER_BUTTON_TEXT,
  ADD_USER_MODAL_TITLE,
  ADMIN_KEYS,
  ADMIN_LABELS,
  CUSTOM_COMPONENT,
  DROPDOWN_FORM,
  INPUT_FORM,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_TEL,
  INPUT_TYPE_TEXT,
  MULTIDROPDOWN_FORM,
  NUMERIC_INPUT_FORM,
} from '../../../constants/appConstants';
import { USERS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ADD_USER_SUCCESS_TEXT } from '../../../constants/successConstants';
import { ADD_USER_ERROR_MESSAGE } from '../../../constants/errorConstants';
import { noop } from '../../../util/appUtil';
import AddFeatureModal from '../../shared/AddFeatureModal';
import { DropDownListItem, MaybeDefaultRegion } from '../../../types';
import { isDisabledPlants } from './userPlants';
import ProductLines from './ProductLines';
import { checkNullInRequired } from '../../../util/validation';

interface ProductLinesChangedArg {
  productLines: {
    primaryProductLine: ProductLine | undefined,
    secondaryProductLines: ProductLine[],
  }
}

type AdminAddModalOnChangeEvent = (arg: ProductLinesChangedArg) => void;

export class UsersAddModal extends Component<UsersAddModalProps, UsersAddModalState> {
  constructor(props: UsersAddModalProps) {
    super(props);
    this.state = {
      regions: [],
      secondaryRegions: [],
      primaryProductLine: undefined,
      secondaryProductLines: [] as ProductLine[],
    };
  }

  componentDidUpdate(prevProps: UsersAddModalProps) {
    if (this.props.isOpen !== prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        primaryProductLine: undefined,
        secondaryProductLines: [],
      });
    }
  }

  onSuccess = (user: any) => {
    this.props.addToOrUpdateListInStore(user, ADD_USER);
    this.props.discardAcceptAction();
  };

  handlePrimaryProductLineChange = (selectedProductLine: ProductLine, adminAddModalOnChange: AdminAddModalOnChangeEvent) => {
    this.setState({
      primaryProductLine: selectedProductLine,
    });

    // clearing possible validation error
    adminAddModalOnChange({
      productLines: {
        primaryProductLine: selectedProductLine,
        secondaryProductLines: this.state.secondaryProductLines
      }
    });
  };

  handleSecondaryProductLineChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedProductLine: ProductLine,
    adminAddModalOnChange: AdminAddModalOnChangeEvent
  ) => {
    const { secondaryProductLines, primaryProductLine } = this.state;

    const addProductLine = () => {
      const updatedProductLines = [...secondaryProductLines, selectedProductLine];
      const newPrimaryProductLine = updatedProductLines.length === 1 ? selectedProductLine : primaryProductLine;
      return { updatedProductLines, newPrimaryProductLine };
    };

    const removeProductLine = () => {
      const updatedProductLines = secondaryProductLines.filter(line => line !== selectedProductLine);
      let newPrimaryProductLine = primaryProductLine;
      if (primaryProductLine === selectedProductLine) {
        newPrimaryProductLine = updatedProductLines.length > 0 ? updatedProductLines.sort((a, b) => a.localeCompare(b))[0] : undefined;
      }
      return { updatedProductLines, newPrimaryProductLine };
    };

    const { updatedProductLines, newPrimaryProductLine } = event.target.checked ? addProductLine() : removeProductLine();

    this.setState({
      secondaryProductLines: updatedProductLines,
      primaryProductLine: newPrimaryProductLine
    });

    // clearing possible validation error
    adminAddModalOnChange({
      productLines: {
        primaryProductLine: newPrimaryProductLine,
        secondaryProductLines: updatedProductLines
      }
    });
  };

  static appendRegions = (dtoCopy: any) => {
    // Create a combined region value
    if (dtoCopy.secondaryRegions && dtoCopy.regions?.length) {
      for (let i = 0; i < dtoCopy.secondaryRegions.length; i += 1) {
        // eslint-disable-next-line no-unused-expressions
        dtoCopy.regions?.push({
          url: dtoCopy.secondaryRegions[i].key,
          defaultRegion: false,
        });
      }
    }
    delete dtoCopy.secondaryRegions;
    return dtoCopy;
  };

  appendPlants = (dtoCopy: any) => {
    if (!dtoCopy.plant && !dtoCopy.plants) {
      return dtoCopy;
    }

    dtoCopy.plants = (dtoCopy.plants ?? []).map((p: any) => ({ url: p.key, default: false }));
    if (dtoCopy.plant) {
      dtoCopy.plants.push({ url: dtoCopy.plant as string, default: true });
      delete dtoCopy.plant;
    }

    if (this.isDisabledPlants()) {
      dtoCopy.plants = [];
    }

    return dtoCopy;
  };

  onSubmit = (dto: UserDtoSecondaryRegions, onError: any, buttonCallback: any) => {
    dto.primaryProductLine = this.state.primaryProductLine;
    dto.secondaryProductLines = this.state.secondaryProductLines?.filter(line => line !== this.state.primaryProductLine) ?? [];

    // used to get an empty password field which apparently is required
    let dtoCopy = dtoToUserDto(dto);
    dtoCopy = UsersAddModal.appendRegions(dtoCopy);
    dtoCopy = this.appendPlants(dtoCopy);

    // Display Error message if they are the same and prevent saving.
    const { createAdminTableData: post } = this.props;
    const toastMessages = {
      success: ADD_USER_SUCCESS_TEXT,
      fail: ADD_USER_ERROR_MESSAGE,
    };
    const url = getGeotraxBaseUrl() + USERS_PATH;

    const addToCurrentRegion = this.props.currentRegion.url === dtoCopy.regions[0].url;
    const currentRegionUrl = this.props.currentRegion.url;
    const callbacks = [this.onSuccess, buttonCallback];

    post(
      url,
      dtoCopy,
      (u) => {
        onSubmitSuccess(callbacks, u);
      },
      onError,
      toastMessages,
      addToCurrentRegion,
      currentRegionUrl
    );
  };

  defaultRegionAccessor = (dto: AddModalUserDto) => {
    if (isUndefined(this.state.primaryProductLine)) {
      dto.regions = [];
      return dto.regions;
    }

    if (dto.regions.length) {
      const fullRegion = this.props.regionList.find(region => region.url === dto.regions[0].url);

      const regionProductLines = (typeof fullRegion.productLines === 'string')
        ? fullRegion.productLines.split(',')
        : fullRegion.productLines;

      if (!regionProductLines.find((pl: ProductLine | undefined) => pl === this.state.primaryProductLine)) {
        dto.regions = [];
        return dto.regions;
      }
    }

    if (this.state.regions !== dto.regions) {
      dto.plant = undefined;
      dto.plants = [];
      this.setState({ regions: dto.regions });
    }

    return dto.regions;
  };

  secondaryRegionAccessor = (dto: AddModalUserDto) => {
    try {
      if (!this.state.secondaryProductLines.length) {
        return [];
      }

      if (this.state.secondaryRegions !== dto.secondaryRegions) {
        dto.plant = undefined;
        dto.plants = [];
        this.setState({ secondaryRegions: dto.secondaryRegions ?? [] });
      }

      return dto.secondaryRegions?.filter(r => r.key !== dto.regions[0].url) ?? [];
    } catch (e) {
      return [];
    }
  };

  discardAcceptAction = () => {
    this.props.discardAcceptAction();
  };

  isDisabledPlants = () => {
    const { primaryProductLine, secondaryProductLines } = this.state;
    return isDisabledPlants(primaryProductLine, secondaryProductLines);
  };

  productLines = (config: AdminConfig) => (
    <ProductLines
      errorMessage={config.errorMessage}
      primaryProductLine={this.state.primaryProductLine}
      secondaryProductLines={this.state.secondaryProductLines}
      onPrimaryProductLineChange={(p) => this.handlePrimaryProductLineChange(p, config.onChange)}
      onSecondaryProductLineChange={(e, p) => this.handleSecondaryProductLineChange(e, p, config.onChange)}
    />
  );

  validateProductLines = (): string => (
    (this.state.primaryProductLine) ? '' : 'A Primary Product Line is required'
  );

  static validateDefaultRegion = (regions: MaybeDefaultRegion[]): string => {
    const isValid = regions.filter(r => r.defaultRegion).length === 1;
    return (isValid)
      ? '' : 'A Primary Region is required';
  };

  getSecondaryDropdownItems = () => {
    const regionList = this.props.regionList.filter(
      region => this.props.assignedRegionList.some(assignedRegion => assignedRegion.id === region.id)
    );

    const dropDownSecondaryRegions = defaultRegionListForDropdown(
      [],
      regionList,
      this.props.secondaryRegionList,
      this.state.secondaryProductLines,
      true
    );

    if (!this.state.regions?.length) {
      return dropDownSecondaryRegions;
    }

    return dropDownSecondaryRegions.map((region) => (
      {
        ...region,
        disabled: this.state.regions.some(r => r.url === region.key),
      }));
  };

  config = (): AdminConfig[] => {
    // eslint-disable-next-line max-len
    const primaryProductLineFilter = this.state.primaryProductLine ? [this.state.primaryProductLine] : [];
    const regionList = this.props.regionList.filter(
      region => this.props.assignedRegionList.some(assignedRegion => assignedRegion.id === region.id)
    );

    const dropDownPrimaryRegions = defaultRegionListForDropdown(
      [],
      regionList,
      this.props.secondaryRegionList,
      primaryProductLineFilter,
      true
    );

    return [{
      type: INPUT_FORM,
      key: ADMIN_KEYS.FIRST_NAME,
      label: ADMIN_LABELS.FIRST_NAME,
      dataTest: 'firstName-input-data-test',
      errorDataTest: 'firstName-input-missing-error',
      className: 'widthSpace',
      // TODO: max length is 50 in backend, shortened to 24 for username generation
      maxLength: 50,
      isRequired: true,
      customValidation: checkNullInRequired,
    }, {
      type: INPUT_FORM,
      key: ADMIN_KEYS.LAST_NAME,
      label: ADMIN_LABELS.LAST_NAME,
      dataTest: 'lastName-input-data-test',
      errorDataTest: 'lastName-input-missing-error',
      className: 'widthSpace',
      // TODO: max length is 50 in backend, shortened to 24 for username generation
      maxLength: 50,
      isRequired: true,
      customValidation: checkNullInRequired,
    }, {
      type: INPUT_FORM,
      key: ADMIN_KEYS.USERNAME,
      label: ADMIN_LABELS.USERNAME,
      dataTest: 'username-input-data-test',
      errorDataTest: 'username-input-missing-error',
      inputType: INPUT_TYPE_TEXT,
      className: 'widthSpace',
      maxLength: 50,
      isRequired: true,
      customValidation: checkNullInRequired,
    },
    {
      type: INPUT_FORM,
      key: ADMIN_KEYS.EMAIL,
      label: ADMIN_LABELS.EMAIL,
      dataTest: 'email-input-data-test',
      errorDataTest: 'eMail-input-missing-error',
      inputType: INPUT_TYPE_EMAIL,
      className: 'widthSpace',
      maxLength: 100,
      isRequired: true,
      customValidation: checkNullInRequired,
    }, {
      type: NUMERIC_INPUT_FORM,
      key: ADMIN_KEYS.PHONE,
      label: ADMIN_LABELS.PHONE,
      dataTest: 'phoneNumber-input-data-test',
      errorDataTest: 'phoneNumber-input-missing-error',
      inputType: INPUT_TYPE_TEL,
      className: 'widthSpace',
      maxLength: 20,
      isRequired: false,
    }, {
      type: INPUT_FORM,
      key: ADMIN_KEYS.EXTERNAL_ID,
      label: ADMIN_LABELS.EXTERNAL_ID,
      maxLength: 50,
      dataTest: 'externalId-input-data-test',
      errorDataTest: 'externalId-input-missing-error',
      className: 'widthSpace',
      isRequired: false,
    },
    {
      type: INPUT_FORM,
      key: ADMIN_KEYS.SSO_ID,
      label: ADMIN_LABELS.SSO_ID_EN,
      maxLength: 50,
      dataTest: 'ssoId-input-data-test',
      errorDataTest: 'ssoId-input-missing-error',
      className: 'widthSpace',
      isRequired: false,
    }, {
      type: CUSTOM_COMPONENT,
      key: ADMIN_KEYS.PRODUCT_LINES,
      component: (c) => this.productLines(c as AdminConfig),
      customValidation: this.validateProductLines,
    },
    {
      type: DROPDOWN_FORM,
      key: ADMIN_KEYS.REGIONS,
      label: ADMIN_LABELS.DEFAULT_REGION,
      className: cx('bottomDropDown', 'widthSpace'),
      items: dropDownPrimaryRegions,
      initialSelected: { label: 'Select a Region...' },
      allowEmpty: true,
      isRequired: true,
      dataTest: 'defaultRegion-input-data-test',
      disabled: !(dropDownPrimaryRegions.length),
      accessor: this.defaultRegionAccessor,
      tooltip: ADMIN_LABELS.TOOLTIP_PRIMARY_REGION,
      customValidation: UsersAddModal.validateDefaultRegion,
    }, {
      type: MULTIDROPDOWN_FORM,
      key: ADMIN_KEYS.SECONDARY_REGIONS,
      label: ADMIN_LABELS.SECONDARY_REGION,
      className: cx('bottomDropDown', 'widthSpace'),
      items: this.getSecondaryDropdownItems(),
      initialSelected: { value: [] },
      isRequired: false,
      placeholder: 'Select a Region...',
      dataTest: 'secondaryRegion-input-data-test',
      disabled: !(this.state.regions?.length && this.state.secondaryProductLines.length),
      accessor: this.secondaryRegionAccessor,
    }];
  };

  render() {
    return (
      <div>
        <FloatingActionButton onClick={this.props.openModal} />
        <AddFeatureModal
          title={ADD_USER_MODAL_TITLE}
          isOpen={this.props.isOpen}
          onCancel={this.props.closeModal}
          style={styles.usersAddModal}
        >
          <AdminAddModal
            config={this.config()}
            addButtonText={ADD_USER_BUTTON_TEXT}
            addButtonDataTest="users-add-button-data-test"
            onRightBtnClick={this.onSubmit}
            discardAcceptAction={this.discardAcceptAction}
            discardRejectAction={this.props.discardRejectAction}
            isCloseRequest={this.props.isCloseRequest}
            externalKeys={[ADMIN_KEYS.SECONDARY_REGIONS]}
          />
        </AddFeatureModal>
      </div>
    );
  }
}

export interface UserDtoSecondaryRegions {
  secondaryRegions?: {
    key: string,
  }[],
  plant?: string | {},
  plants?: { key?: string, url?: string, default?: boolean }[],
  primaryProductLine: ProductLine | undefined,
  secondaryProductLines: ProductLine[],
}

export interface UsersAddModalProps {
  createAdminTableData: typeof createDataTableRecord,
  addToOrUpdateListInStore: typeof addToOrUpdateListInStore,
  discardAcceptAction: typeof noop,
  discardRejectAction: typeof noop,
  closeModal: VoidFunc,
  openModal: VoidFunc,
  assignedRegionList: any[],
  secondaryRegionList: any[],
  regionList: any[],
  currentRegion: UrlKeyDto,
  isCloseRequest: boolean,
  isOpen: boolean
}

export interface UsersAddModalState {
  regions: MaybeDefaultRegion[],
  secondaryRegions: DropDownListItem[],
  primaryProductLine: ProductLine | undefined,
  secondaryProductLines: ProductLine[],
}

export interface AddModalUserDto {
  primaryProductLine: ProductLine,
  secondaryProductLines: ProductLine[],
  plant?: string | {},
  plants?: { key?: string }[],
  regions: MaybeDefaultRegion[],
  secondaryRegions?: DropDownListItem[]
}

const mapStateToProps = (state: any) => ({
  currentRegion: state.currentRegion,
  assignedRegionList: state.assignedRegionList,
});

export default connect<any, any, any>(mapStateToProps, {
  createAdminTableData: createDataTableRecord,
  addToOrUpdateListInStore,
})(UsersAddModal);
