import React, { Component, ComponentType } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import {
  FeatureFlagDto,
  PermissionAccess,
  SubscriptionDto,
  UserRegionDto,
  VoidFunc,
} from '@trucktrax/trucktrax-ts-common';
import { fetchRegionList } from '../../services/regionsService';
import {
  adminRouteNotFound,
  adminRouteRedirect,
  featureflagRoute,
  featureflagRouteRedirect,
} from '../../routes';
import RouteWithSubRoutes from '../shared/routing/RouteWithSubRoutes';
import RedirectRoute from '../shared/routing/RedirectRoute';
import { setTicketsToPositions } from '../../util/ticketUtil';
import {
  categoriesForAdmin,
  GEOZONES_TEXT,
  HAULERS_TEXT,
  TRUCKS_TEXT,
  PLANTS_TEXT,
  REGIONS_TEXT,
  AdminCategory,
} from '../../constants/navConstants';
import {
  ADMIN_SUB_ROUTE_REGEX,
  FEATURE_FLAG_ACCESS_FLAG,
} from '../../constants/appConstants';
import { userHasEditPermissionInAnyRegion } from '../../services/permissionsService';
import { ReduxState } from '../../store';
import { RegionAccessMap } from '../../util/permissionUtil';
import { setFavicon } from '../../util/appUtil';

export class AdminView extends Component<AdminViewProps> {
  static defaultProps: AdminViewProps = {
    fetchRegionList: () => { },
    routes: [],
    positions: [],
    ticketsList: [],
    adminPermissionAccess: {},
    location: { pathname: '' },
  };

  state = {
    previousRegionUrl: this.props.currentRegion ? this.props.currentRegion.url : null,
    hasFeatureFlagAccess: false,
  };

  componentDidMount() {
    const hasRegionPermissions = this.props.adminPermissionAccess[REGIONS_TEXT] !== PermissionAccess.Deny;
    if (hasRegionPermissions) {
      this.props.fetchRegionList();
    }
    this.updatePermissions();
    document.title = 'Admin - TruckTrax';
    setFavicon('/favicon-admin.ico');
  }

  componentDidUpdate(prevProps: AdminViewProps) {
    if (this.regionHasChanged && prevProps.currentRegion) {
      this.setState({
        previousRegionUrl: prevProps.currentRegion.url,
      });
    }
    this.updatePermissions();
  }

  get regionHasChanged() { return this.props.currentRegion && this.state.previousRegionUrl !== this.props.currentRegion.url; }

  updatePermissions = () => {
    const hasFeatureFlagAccess = this.props.userPermission
      && userHasEditPermissionInAnyRegion(this.props.userPermission, FEATURE_FLAG_ACCESS_FLAG);

    if (this.state.hasFeatureFlagAccess !== hasFeatureFlagAccess) {
      this.setState({ hasFeatureFlagAccess });
    }
  };

  getPermissionAccessForCategory = (category: AdminCategory) => {
    // Shared permissions between different UI locations.
    const permissionName = (() => {
      switch (category.name) {
        case GEOZONES_TEXT: return PLANTS_TEXT;
        case HAULERS_TEXT: return TRUCKS_TEXT;
        default: return category.name;
      }
    })();

    return this.props.adminPermissionAccess[permissionName];
  };

  generateRoutesDefault = (hasFeatureFlagAccess: boolean) => {
    const deniedRoutes: (string | undefined)[] = [];
    const adminCategories: AdminCategory[] = [];

    categoriesForAdmin.forEach((category: AdminCategory) => {
      const adminPermissionAccess = this.getPermissionAccessForCategory(category);

      if (adminPermissionAccess === PermissionAccess.Deny) {
        // routes and subroutes with 'Deny' permission access. Needs to be refactored when we
        // have more than 1 subroutes.
        deniedRoutes.push(category.navRoute);
        deniedRoutes.push(`${category.navRoute}/:id`);
      } else {
        adminCategories.push(category);
      }
    });

    const firstAllowedRoute = adminCategories
      .filter(cat => cat.navRoute && !deniedRoutes.includes(cat.navRoute))
      .map(cat => cat.navRoute)[0] ?? '';

    const configRoutes = this.props.routes.map((puRoute) => {
      if (!deniedRoutes.includes(puRoute.path)) {
        return (
          <RouteWithSubRoutes
            key={puRoute.path}
            {...puRoute}
          />
        );
      }
      if (this.regionHasChanged) {
        // For region change redirect to first allowed route.
        return (
          <RedirectRoute
            key={adminRouteNotFound.key}
            from={puRoute.path}
            to={firstAllowedRoute}
          />
        );
      }
      if (deniedRoutes.includes(puRoute.path)) {
        // For denied routes redirect to first allowed route.
        return (
          <RedirectRoute
            key={puRoute.path}
            from={puRoute.path}
            to={firstAllowedRoute}
          />
        );
      }
      return null;
    });

    const currentPath = this.props.location.pathname;
    // On region change redirect route from /admin/xxx/:id to /admin/xxx
    if (this.regionHasChanged && currentPath.match(ADMIN_SUB_ROUTE_REGEX)) {
      const currentPathParamArr = currentPath.split('/');
      currentPathParamArr.pop(); // remove :id (/[0-9]) value from path
      const currentPathWithoutId = currentPathParamArr.join('/');

      return (
        <RedirectRoute
          key={adminRouteNotFound.key}
          from={currentPath}
          to={currentPathWithoutId}
        />
      );
    }

    if (hasFeatureFlagAccess) {
      // feature flag route
      configRoutes.push(<RouteWithSubRoutes
        {...featureflagRoute}
        key={featureflagRoute.key}
      />);
    } else {
      // add redirect for /admin/featureflags to /admin/{first allowed route}
      configRoutes.push(<RedirectRoute
        key={featureflagRouteRedirect.key}
        from={featureflagRouteRedirect.from}
        to={firstAllowedRoute}
      />);
    }

    // add redirect for /admin to /admin/{first allowed route}
    configRoutes.push(<RedirectRoute
      key={adminRouteRedirect.key}
      from={adminRouteRedirect.from}
      to={firstAllowedRoute}
    />);

    // add redirect for /admin/* to 404
    configRoutes.push(<RedirectRoute
      {...adminRouteNotFound}
      key={adminRouteNotFound.key}
    />);

    return <Switch>{configRoutes}</Switch>;
  };

  render() {
    if (!this.state.previousRegionUrl) {
      return null;
    }
    setTicketsToPositions(this.props.ticketsList, this.props.positions);
    return this.generateRoutesDefault(this.state.hasFeatureFlagAccess);
  }
}

export interface AdminViewProps {
  fetchRegionList: VoidFunc,
  routes: {
    path?: string,
    component?: ComponentType<any>
  }[],
  flags?: {
    featuresList: any;
    allFlags: FeatureFlagDto[];
    subscriptions: SubscriptionDto[];
  },
  positions: any[],
  currentRegion?: UserRegionDto,
  ticketsList: any[],
  adminPermissionAccess: { [key: string]: PermissionAccess },
  location: { pathname: string },
  userPermission?: RegionAccessMap,
  userHasEditPermissionInAnyRegions?: VoidFunc
}

export function mapStateToProps(state: ReduxState) {
  return {
    regions: state.assignedRegionList,
    flags: state.flags,
    positions: state.positionList,
    currentRegion: state.currentRegion,
    ticketsList: state.ticketsList,
    userPermission: state.userPermission,
    adminPermissionAccess: state.adminPermissionAccess,
  };
}

export default connect(mapStateToProps, {
  fetchRegionList,
  userHasEditPermissionInAnyRegion
})(AdminView);
