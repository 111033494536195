import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import {
  Checkbox, FormControlLabel, Tab, Tabs
} from '@mui/material';
import {
  DriverDto, PermissionAccess, PlantDto, ProductLine
} from '@trucktrax/trucktrax-ts-common';
import DataTableContent from '../../shared/DataTableContent';
import {
  accessValue, addDataTest, addHeaderDataTest,
} from '../../../util/adminUtil';
import TrucksAddModal from './TrucksAddModal';
import { TRUCKS_TEXT, VEHICLES_TEXT } from '../../../constants/navConstants';
import {
  ADMIN_KEYS, ADMIN_LABELS, NOT_AVAILABLE
} from '../../../constants/appConstants';
import { DATASOURCE, TRUCK_PROJECTIONS } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import styles from './TrucksView.module.css';
import { setReduxDriverList } from '../../../services/driversService';
import { ConnectedDispatchFunction } from '../../../types';

const FlexSystemWrapper = (props: { original: { highlight: string; }; classes: string[]; value: boolean; }) => {
  if (props.original.highlight) {
    props.classes[1] = 'new-highlight';
  }
  return props.value ? <i aria-hidden="true" data-test="admin-truck-is-flex-system" className={cx('icon-check')} />
    : <i aria-hidden="true" data-test="admin-truck-is-flex-system" className={cx('icon-close', styles.notEnabled)} />;
};

const TruckHomePlantWrapper = (props: { value: { url?: string; name?: string; }; }) => addDataTest(
  null,
  'admin-truck-home-plant',
  props.value ? TrucksView.plantUrlAccessor(props.value) : ''
);

const GlinxEnabledWrapper = (props: { original: { highlight: string; }; classes: string[]; value: boolean; }) => {
  if (props.original.highlight) {
    props.classes[1] = 'new-highlight';
  }
  return props.value ? <i aria-hidden="true" data-test="admin-truck-is-glinx-enabled" className={cx('icon-check')} />
    : <i aria-hidden="true" data-test="admin-truck-is-glinx-enabled" className={cx('icon-close', styles.notEnabled)} />;
};

export class TrucksView extends Component<TrucksViewProps> {
  static defaultProps = {
    plantList: [],
    drivers: [],
    trucksPermissionAccess: '',
    tab: 0,
  };

  state = {
    isOpen: false,
    isCloseRequest: false,
    displayInternal: true,
    displayExternal: false,
    displayAggregates: false,
    displayCement: false,
    displayReadyMix: false,
  };

  async componentDidMount() {
    this.props.setReduxDriverList(this.props.currentRegion!.url!);
    this.setProductLineCheckboxes();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.currentRegion?.url !== this.props.currentRegion?.url) {
      this.setProductLineCheckboxes();
    }
  }

  setProductLineCheckboxes() {
    const productLineDisplay = {
      displayAggregates: true,
      displayCement: true,
      displayReadyMix: true
    };

    this.setState(
      { ...productLineDisplay }
    );
  }

  static plantUrlAccessor = (plant: { url?: string; name?: string; }) => (plant.url
    ? plant.name
    : NOT_AVAILABLE);

  static driverUrlAccessor = (driver: { url?: string; name?: string; }) => (driver.name
    ? TrucksView.formatDriverName(driver.name)
    : NOT_AVAILABLE);

  static formatDriverName = (driverName?: string) => {
    const name = driverName?.trim().split(' ') ?? [];

    const firstName = name[name.length - 1] ?? '';
    delete name[name.length - 1];
    const lastName = name.join(' ').trim();

    return `${[lastName, firstName].join(', ')}`;
  };

  handleDisplayCheckChange = (event: any) => {
    const { displayInternal, displayExternal } = this.state;
    const { target } = event;
    const { name, checked } = target;
    const reverseInternalValue = name === 'displayInternal' && !checked && !displayExternal;
    const reverseExternalValue = name === 'displayExternal' && !checked && !displayInternal;
    const displayList = ['displayInternal', 'displayExternal', 'displayAggregates', 'displayCement', 'displayReadyMix'];

    if (reverseInternalValue) {
      this.setState({
        displayInternal: checked,
        displayExternal: true,
      });
    } else if (reverseExternalValue) {
      this.setState({
        displayInternal: true,
        displayExternal: checked,
      });
    } else if (displayList.indexOf(name) !== -1) {
      this.setState({
        [name]: checked,
      });
    }
  };

  getQueryParams = () => {
    const {
      displayInternal,
      displayExternal,
      displayAggregates,
      displayCement,
      displayReadyMix
    } = this.state;
    let params = {};
    if (Number(displayInternal) + Number(displayExternal) === 1) {
      params = { isExternal: `${displayInternal ? 'false' : 'true'}` };
    }
    const queryProductLines = [];
    if (displayAggregates) queryProductLines.push(ProductLine.Aggregates);
    if (displayCement) queryProductLines.push(ProductLine.Cement);
    if (displayReadyMix) queryProductLines.push(ProductLine.ReadyMix);
    params = { productLines: queryProductLines.join(','), ...params };
    return params;
  };

  static getColumns = () => [
    {
      Header: addHeaderDataTest(null, 'sort-truck-by-alias', ADMIN_LABELS.VEHICLE_ALIAS),
      accessor: ADMIN_KEYS.TRUCK_ALIAS,
      minWidth: 130,
      Cell: (props: { original: { highlight: string; }; classes: string[]; value: string; }) => {
        if (props.original.highlight) {
          props.classes[1] = 'new-highlight';
        }
        return addDataTest(null, 'admin-truck-alias', props.value);
      },
    },
    {
      Header: addHeaderDataTest(null, 'sort-truck-by-id', ADMIN_LABELS.EXTERNAL_ID),
      accessor: ADMIN_KEYS.EXTERNAL_ID,
      Cell: (props: { original: { highlight: string; }; classes: string[]; value: string; }) => {
        if (props.original.highlight) {
          props.classes[1] = 'new-highlight';
        }
        return addDataTest(null, 'admin-external-id', props.value);
      },
    },
    {
      Header: addHeaderDataTest(null, 'sort-truck-by-fleet', ADMIN_LABELS.OWNERSHIP),
      id: ADMIN_KEYS.IS_EXTERNAL,
      accessor: (row: any) => (row.isExternal),
      sortable: true,
      Cell: (props: { original: { highlight: string; }; classes: string[]; value: boolean; }) => {
        if (props.original.highlight) {
          props.classes[1] = 'new-highlight';
        }
        return addDataTest(null, 'admin-truck-fleet', props.value ? 'External' : 'Internal');
      },
    },
    {
      Header: addHeaderDataTest(null, 'sort-truck-by-primary', ADMIN_LABELS.PRODUCT_LINE),
      id: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
      accessor: (row: any) => accessValue(ADMIN_KEYS.PRIMARY_PRODUCT_LINE, row),
      Cell: (props: { original: { highlight: string; }; classes: string[]; value: string; }) => {
        if (props.original.highlight) {
          props.classes[1] = 'new-highlight';
        }
        return addDataTest(null, 'admin-truck-primary-product-line', props.value || NOT_AVAILABLE);
      },
    },
    {
      Header: addHeaderDataTest(null, 'sort-truck-by-vehicletype', ADMIN_LABELS.VEHICLE_TYPE),
      id: ADMIN_KEYS.VEHICLE_TYPE_NAME,
      accessor: (row: any) => accessValue(ADMIN_KEYS.VEHICLE_TYPE_NAME, row),
      sortable: true,
      Cell: (props: { original: { highlight: string; }; classes: string[]; value: string; }) => {
        if (props.original.highlight) {
          props.classes[1] = 'new-highlight';
        }
        return addDataTest(null, 'admin-truck-vehicle-type', props.value || NOT_AVAILABLE);
      },
    },
    {
      Header: addHeaderDataTest(null, 'sort-truck-by-is-glinx-enabled', ADMIN_LABELS.GLINX_ENABLED),
      id: ADMIN_KEYS.IS_GLINX_ENABLED,
      accessor: (row: any) => row.isGlinxEnabled,
      sortable: true,
      className: styles.CenterCell,
      Cell: GlinxEnabledWrapper,
    },
    {
      Header: addHeaderDataTest(null, 'sort-truck-by-is-flex-system', ADMIN_LABELS.FLEX_SYSTEM),
      id: ADMIN_KEYS.IS_FLEX_SYSTEM,
      accessor: (row: any) => row.isFlexSystem,
      sortable: true,
      className: styles.CenterCell,
      Cell: FlexSystemWrapper,
    },
    {
      Header: addHeaderDataTest(null, 'truck-home-plant-header', ADMIN_LABELS.HOME_PLANT),
      id: ADMIN_KEYS.PLANT,
      accessor: (row: any) => accessValue(ADMIN_KEYS.PLANT, row),
      minWidth: 135,
      sortable: true,
      Cell: TruckHomePlantWrapper,
    },
    {
      Header: addHeaderDataTest(null, 'truck-default-driver-header', ADMIN_LABELS.DEFAULT_DRIVER),
      id: ADMIN_KEYS.DEFAULT_DRIVER,
      accessor: (row: any) => accessValue(ADMIN_KEYS.DEFAULT_DRIVER, row),
      minWidth: 125,
      sortable: true,
      Cell: (props: { value: { url?: string; }; }) => addDataTest(
        null,
        'admin-truck-default-driver',
        props.value ? TrucksView.driverUrlAccessor(props.value) : ''
      ),
    },
  ];

  requestCloseModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      isCloseRequest: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: false,
    });
  };

  handleTabChange = (_event: any, newValue: number) => {
    if (newValue === 1) {
      this.props.history.push('/admin/trucks/deactivated');
    } else if (newValue === 0) {
      this.props.history.push('/admin/trucks');
    }
  };

  render() {
    const columns = TrucksView.getColumns();
    const hasPermissionAccess = this.props.trucksPermissionAccess === PermissionAccess.Edit;
    const tabs = (
      <div className={styles.headerContainer}>
        <Tabs
          value={this.props.tab}
          onChange={this.handleTabChange}
          classes={
            {
              root: styles.tabsContainer,
            }
          }
        >
          <Tab label="Active" />
          <Tab label="Deactivated" />
        </Tabs>
        <div className={styles.checkboxContainer}>
          <div className={styles.checkboxTitle}>
            Fleet
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.displayInternal}
                onChange={this.handleDisplayCheckChange}
                name="displayInternal"
                color="primary"
              />
            )}
            label={<span className={styles.checkboxLabel}>Internal</span>}
            classes={{
              root: styles.checkboxLabelContainer,
            }}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.displayExternal}
                onChange={this.handleDisplayCheckChange}
                name="displayExternal"
                color="primary"
              />
            )}
            label={<span className={styles.checkboxLabel}>External</span>}
            classes={{
              root: styles.checkboxLabelContainer,
            }}
          />

          <div className={styles.checkboxSpacing} />

          <div className={styles.checkboxTitle}>
            Product Line
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.displayAggregates}
                onChange={this.handleDisplayCheckChange}
                name="displayAggregates"
                color="primary"
              />
            )}
            label={<span className={styles.checkboxLabel}>AGG</span>}
            classes={{
              root: styles.checkboxLabelContainer,
            }}
          />

          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.displayCement}
                onChange={this.handleDisplayCheckChange}
                name="displayCement"
                color="primary"
              />
            )}
            label={<span className={styles.checkboxLabel}>CEM</span>}
            classes={{
              root: styles.checkboxLabelContainer,
            }}
          />

          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.displayReadyMix}
                onChange={this.handleDisplayCheckChange}
                name="displayReadyMix"
                color="primary"
              />
            )}
            label={<span className={styles.checkboxLabel}>RM</span>}
            classes={{
              root: styles.checkboxLabelContainer,
            }}
          />
        </div>
      </div>
    );

    const props: any = {
      baseUrl: getGeotraxBaseUrl() + TRUCK_PROJECTIONS,
      columns,
      header: VEHICLES_TEXT,
      renderTabs: tabs,
      isArchived: this.props.tab === 1,
      canAddNewItem: hasPermissionAccess,
      queryParams: this.getQueryParams(),
      byPassRedisCache: true,
      source: DATASOURCE.VEHICLE
    };

    return (
      <div className={styles.container}>
        <DataTableContent {...props} />
        {hasPermissionAccess && (
          <TrucksAddModal
            isOpen={this.state.isOpen}
            openModal={this.openModal}
            closeModal={this.requestCloseModal}
            drivers={this.props.drivers}
            discardAcceptAction={this.closeModal}
            discardRejectAction={this.openModal}
            isCloseRequest={this.state.isCloseRequest}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentRegion: state.currentRegion,
  regions: state.assignedRegionList,
  plantList: state.plantList,
  drivers: state.driverList.drivers,
  trucksPermissionAccess: state.adminPermissionAccess[TRUCKS_TEXT],
});

type TrucksViewReduxProps = ReturnType<typeof mapStateToProps>;

type TrucksViewDispatchProps = {
  setReduxDriverList: ConnectedDispatchFunction<typeof setReduxDriverList>;
};

interface History {
  push: (p: string) => void
}

export interface TrucksViewOwnProps {
  plantList?: PlantDto[],
  drivers?: DriverDto[],
  trucksPermissionAccess?: string,
  history: History,
  tab: number
}

export type TrucksViewProps = TrucksViewReduxProps & TrucksViewDispatchProps & TrucksViewOwnProps;

export default connect(mapStateToProps, { setReduxDriverList })(TrucksView);
