import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import cx from 'classnames';
import { Button } from '@trucktrax/trucktrax-common';
import styles from './WelcomeView.module.css';
import {
  DEFAULT,
  IDLE_TIMEOUT_BODY,
} from '../../constants/appConstants';
import {
  openSnackbar,
  SnackbarOptions,
} from '../../store/actions/snackbarActions';
import { logOut } from '../../services/authService';
import { setSessionId } from '../../store/actions/authActions';
import IdleService from '../../services/idleService';
import { ReduxState } from '../../store';
import { hasAppAccessInAnyRegion } from '../../util/permissionUtil';
import { AppPath, ConnectedDispatchFunction } from '../../types';
import { fetchPermissionsListByUserUrl } from '../../services/permissionsService';
import { apps } from '../../util/appUtil';
import { getTokens } from '../../util/authUtil';

export class WelcomeView extends Component<WelcomeViewProps> {
  static defaultProps = {
    userFullName: '',
    authenticated: false,
    idleTime: 120,
    sessionId: null,
  };

  constructor(props: WelcomeViewProps) {
    super(props);

    if (!this.props.authenticated) {
      this.props.history.replace('/login');
    }
  }

  idleTimeOrDefault = () => {
    const { idleTime } = this.props;
    return idleTime ?? 120;
  };

  componentDidMount() {
    new IdleService().init(this.idleTimeOrDefault(), this.onIdleTime);

    this.initPermissions();
  }

  onIdleTime = () => {
    if (getTokens().accessToken === null) {
      return;
    }
    const { sessionId } = this.props;
    this.props.logOut();
    if (sessionId !== null) {
      this.props.openSnackbar({
        snackbarBody: IDLE_TIMEOUT_BODY,
        snackbarType: DEFAULT,
      });
      this.props.setSessionId('');
      this.props.history.replace('/login');
    }
  };

  get permissionsInitialized() {
    const hasUserPermissions = this.props.userPermission && Object.keys(this.props.userPermission).length > 0;
    return hasUserPermissions;
  }

  initPermissions() {
    if (this.permissionsInitialized) {
      return;
    }

    this.props.fetchPermissionsListByUserUrl(this.props.userUrl!);
  }

  onLogoutClick = () => {
    this.props.logOut();
    this.props.history.replace('/login');
  };

  openApp = (app: AppPath) => {
    window.name = app.name;
    this.props.history.push(app.path);
  };

  render() {
    const { authenticated, userPermission } = this.props;

    if (!authenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      );
    }

    const validApps = apps.filter(app => hasAppAccessInAnyRegion(app.type, userPermission));
    if (this.permissionsInitialized && validApps.length === 1) {
      return <Redirect to={`/${validApps[0].name}`} />;
    }

    return (
      <div
        className={styles.background}
      >
        <div className={validApps.length ? styles.container : styles.containerWithoutPermissions}>
          <div className={styles.greeting}>
            Welcome,
            {' '}
            <span className={styles.userFullName}>{this.props.userFullName}</span>
            .
          </div>
          {validApps.length ? (
            <div>
              {validApps.map((app) => (
                <button
                  key={app.name}
                  className={styles.navButton}
                  onClick={() => this.openApp(app)}
                >
                  <div className={styles.appContainer}>
                    <img
                      className={cx(styles.logo, 'margin-bottom-20')}
                      src={app.verticalIcon}
                      alt={`${app.name}-logo`}
                    />
                    <span className={styles.text}>
                      {`Launch ${app.name}`}
                      <i className={cx('icon-new-tab', 'margin-left-5')} />
                    </span>
                  </div>
                </button>
              ))}
            </div>
          ) : (
            <div>
              <p>
                Your account permissions must be set before you can access any
                apps.
                <br />
                Please contact an administrator to have your permissions set up.
              </p>
            </div>
          )}

          <Button
            onClick={this.onLogoutClick}
            buttonClassName="tt-btn-secondary margin-top-20"
            name="Log Out"
            dataTest="welcome-logout-button"
          />
        </div>
      </div>
    );
  }
}

export interface WelcomeViewOwnProps {
  history: {
    push: (path: string) => void,
    replace: (path: string) => void
  };
  openSnackbar: (options: SnackbarOptions) => void;
  setSessionId: (sessionId: string) => void;
  logOut: () => void;
  fetchPermissionsListByUserUrl: ConnectedDispatchFunction<typeof fetchPermissionsListByUserUrl>
}

export function mapStateToProps(state: ReduxState) {
  return {
    userFullName: state.auth.userFullName,
    userUrl: state.userUrl,
    authenticated: state.auth.authenticated,
    idleTime: state.minutesToIdleLogout,
    sessionId: state.sessionId ?? null,
    userPermission: state.userPermission,
  };
}

type WelcomeViewReduxProps = ReturnType<typeof mapStateToProps>;
export type WelcomeViewProps = WelcomeViewReduxProps & WelcomeViewOwnProps;

export default connect(mapStateToProps, {
  logOut,
  openSnackbar,
  setSessionId,
  fetchPermissionsListByUserUrl,
})(WelcomeView);
