import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ChecklistDto, PermissionAccess } from '@trucktrax/trucktrax-ts-common';
import DataTableContent from '../../shared/DataTableContent';
import { addDataTest, addHeaderDataTest } from '../../../util/adminUtil';
import ChecklistAddModal from './ChecklistAddModal';
import { CHECKLISTS_TEXT } from '../../../constants/navConstants';
import { ADMIN_KEYS, ADMIN_LABELS } from '../../../constants/appConstants';
import { CHECKLISTS_PATH, DATASOURCE } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ReduxState } from '../../../store';

export interface ChecklistsViewState {
  isOpen: boolean;
  isCloseRequest: boolean;
}

export class ChecklistsView extends Component<ChecklistsViewProps, ChecklistsViewState> {
  state = {
    isOpen: false,
    isCloseRequest: false,
  };

  static getColumns = () => [
    {
      Header: addHeaderDataTest(null, 'sort-checklists-by-name', ADMIN_LABELS.NAME),
      accessor: ADMIN_KEYS.NAME,
      minWidth: 150,
      sortable: true,
      Cell: (props: any) => addDataTest('txt-bold', 'admin-checklists-name', props.value),
    },
  ];

  requestCloseModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: true,
    });
  };

  closeModal = (checklist?: ChecklistDto) => {
    const callback = () => {
      if (checklist && checklist.id) {
        this.props.history.push(`/admin${CHECKLISTS_PATH}/${checklist.id}`);
      }
    };

    this.setState({
      isOpen: false,
      isCloseRequest: false,
    }, callback);
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: false,
    });
  };

  render() {
    const baseUrl = getGeotraxBaseUrl() + CHECKLISTS_PATH;
    const columns = ChecklistsView.getColumns();
    const hasPermissionAccess = this.props.checklistsPermissionAccess === PermissionAccess.Edit;

    return (
      <div>
        <DataTableContent
          baseUrl={baseUrl}
          columns={columns}
          header={CHECKLISTS_TEXT}
          canAddNewItem={hasPermissionAccess}
          byPassRedisCache
          source={DATASOURCE.CHECKLIST}
        />
        {hasPermissionAccess && (
          <ChecklistAddModal
            isOpen={this.state.isOpen}
            openModal={this.openModal}
            closeModal={this.requestCloseModal}
            discardAcceptAction={this.closeModal}
            discardRejectAction={this.openModal}
            isCloseRequest={this.state.isCloseRequest}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  checklistsPermissionAccess: state.adminPermissionAccess[CHECKLISTS_TEXT] ?? '',
});

type ChecklistsViewReduxStateProps = ReturnType<typeof mapStateToProps>;
type ChecklistViewRouterProps = {
  history: {
    push: (path: string) => void;
  }
};
export type ChecklistsViewProps = ChecklistsViewReduxStateProps & ChecklistViewRouterProps;

export default withRouter(connect(mapStateToProps)(ChecklistsView));
