import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Label, DebouncedInput } from '@trucktrax/trucktrax-common';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { Link } from 'react-router-dom';
import { GroupDto, GroupMemberDto, RegionDto } from '@trucktrax/trucktrax-ts-common';
import styles from './Members.module.css';
import { getLastOfUrlPath } from '../../../../util/appUtil';
import {
  deleteMember,
  postMember,
  putMember,
  userHasEditPermission,
  userHasEditPermissionInAnyRegion,
  userHasViewPermission,
  fetchGroupMembersWithFilter,
} from '../../../../services/permissionsService';
import {
  ADV_SECURITY_PERMS,
  PERMISSION_NAMES,
  SECURITY_NO_MEMBERS_IN_PERMISSIONS_GROUP_MESSAGE,
} from '../../../../constants/appConstants';
import { ReduxState } from '../../../../store';

export interface MembersState {
  hasAdvancedAccess: boolean,
  regionMembers: GroupMemberDto[]
}

type SearchResult = {
  key?: string;
  value?: string;
};

const membersByName = (a: GroupMemberDto, b: GroupMemberDto): -1 | 0 | 1 => {
  // Check if either name is undefined
  if (!a.name && !b.name) return 0;
  if (!a.name) return -1; // a.name undefined; it comes first
  if (!b.name) return 1; // b.name undefined; it comes first

  // Compare the names
  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
};

export class Members extends Component<MembersProps, MembersState> {
  static defaultProps: Partial<MembersProps> = {
    group: undefined,
    region: undefined,
    userHasEditPermissionInAnyRegion,
    fetchGroupMembersWithFilter,
    putMember,
    postMember,
    deleteMember,
  };

  constructor(props: MembersProps) {
    super(props);
    this.state = {
      hasAdvancedAccess: false,
      regionMembers: [],
    };
  }

  async componentDidMount() {
    const hasAdvancedAccess = this.props.userHasEditPermissionInAnyRegion!(this.props.userPermission, ADV_SECURITY_PERMS);
    await this.onMount(hasAdvancedAccess);
  }

  async componentDidUpdate(prevProps: MembersProps) {
    // set region member when tab change.
    if (JSON.stringify(prevProps.group) !== JSON.stringify(this.props.group)
      || (JSON.stringify(prevProps.region) !== JSON.stringify(this.props.region))) {
      this.setRegionMembers();
    }
  }

  onMount = async (hasAdvancedAccess: boolean) => {
    this.setState({ hasAdvancedAccess });
    this.setRegionMembers();
  };

  setRegionMembers = () => {
    const { members } = this.props.group!;
    const regionUrl = this.props.region?.url;

    if (!members || !regionUrl) {
      return;
    }
    const regionMembers = members.filter(m => !m.deleted && m.memberRegions && m.memberRegions.includes(regionUrl));
    this.setState({ regionMembers });
  };

  onSearchItemSelected = async (newMember: SearchResult) => {
    const existingMembers = this.props.group!.members!.filter(m => m.member!.url === newMember.key);
    let response: GroupMemberDto | undefined;

    if (existingMembers.length > 0) {
      await Promise.all(existingMembers.map(async m => {
        m.deleted = false;
        response = await this.props.putMember!(m, this.props.region!.url);
      }));
    } else {
      response = await this.props.postMember!(this.props.group!.url!, newMember.key!, this.props.region!.url!);
      this.addNewGroupMembership(response!);
    }
    if (response && !response.memberRegions) {
      response.memberRegions = [this.props.region!.url!];
    }
    this.setRegionMembers();
  };

  addNewGroupMembership = (response: GroupMemberDto) => {
    const existingMembers = this.props.group?.members?.filter((member) => member.id === response.id);
    const existingMember = existingMembers && existingMembers.length && existingMembers[0];
    if (existingMember) {
      // already in the membership list, add memberRegions for the one it was just added to
      existingMember.memberRegions!.push(...response.memberRegions!);
    } else {
      // first membership
      this.props.group!.members!.push(response!);
    }
  };

  onSearchFilter = async (text: string): Promise<SearchResult[]> => {
    const result = await this.props.fetchGroupMembersWithFilter!(
      this.props.group!.id,
      this.props.region!.url!,
      text
    );

    return result.map(r => ({
      key: r.url,
      value: r.fullName,
    }));
  };

  onRemoveAllClick = async () => {
    await Promise.all(this.state.regionMembers.map(async member => {
      await this.removeMember(member);
    }));
    this.setRegionMembers();
  };

  onRowRemoveClick = async (row: GroupMemberDto) => {
    await this.removeMember(row);
    this.setRegionMembers();
  };

  removeMember = async (member: GroupMemberDto) => {
    await this.props.deleteMember!(member.id, this.props.region!.url);
    member.deleted = true;
  };

  static replaceUrl = ({ member, memberRegions }: GroupMemberDto) => `/admin/permissions/users/${getLastOfUrlPath(member!.url!)}`
    + `?regions=${memberRegions}`;

  hasEditPermission = () => userHasEditPermission(
    this.props.userPermission,
    this.props.region!.url!,
    PERMISSION_NAMES.SECURITY_PERMISSIONS
  ) || this.state.hasAdvancedAccess;

  hasPermissionForUsers = () => this.state.hasAdvancedAccess
    || userHasViewPermission(this.props.userPermission, this.props.region!.url!, PERMISSION_NAMES.USERS)
    || userHasEditPermission(this.props.userPermission, this.props.region!.url!, PERMISSION_NAMES.USERS);

  canViewUserDetail = () => (
    (
      userHasViewPermission(
        this.props.userPermission,
        this.props.region!.url!,
        PERMISSION_NAMES.SECURITY_PERMISSIONS
      )
      || userHasEditPermission(
        this.props.userPermission,
        this.props.region!.url!,
        PERMISSION_NAMES.SECURITY_PERMISSIONS
      )
    )
  ) || this.state.hasAdvancedAccess;

  renderTableData = (members: GroupMemberDto[]) => (
    <div className={styles.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <span>Name</span>
            </TableCell>
            <TableCell>
              <span>Region</span>
            </TableCell>
            <TableCell className={styles.rightAlign}>
              {
                this.hasEditPermission()
                && (
                  <button
                    className={cx('tt-btn', 'tt-btn-text--small', styles.removeAllBtn)}
                    onClick={this.onRemoveAllClick}
                  >
                    <i className="icon-delete margin-right-5" />
                    <span>Remove All</span>
                  </button>
                )
              }
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            members.map(row => (
              <TableRow
                key={`idx-${row.member!.url}`}
                className={styles.memberRow}
                hover
              >
                <TableCell>
                  {
                    this.canViewUserDetail()
                      ? (
                        <Link
                          to={{ pathname: Members.replaceUrl(row) }}
                          className={styles.usernameLink}
                        >
                          {row.name}
                        </Link>
                      )
                      : (<span>{row.name}</span>)
                  }
                </TableCell>
                <TableCell>{this.props.region?.name}</TableCell>
                <TableCell className={styles.rightAlign}>
                  {
                    this.hasEditPermission()
                    && (
                      <button
                        id="removeButton"
                        className={cx('tt-btn', 'tt-btn-basic', styles.removeButton)}
                        onClick={() => {
                          this.onRowRemoveClick(row);
                        }}
                      >
                        <i
                          aria-hidden="true"
                          className="icon-close"
                        />
                      </button>
                    )
                  }
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </div>
  );

  static renderEmptyTable = () => (
    <div className={styles.emptyTable}>
      <div>
        <i
          aria-hidden="true"
          className={cx('icon-headset-mic', styles.icon)}
        />
        <span className={styles.crossLine} />
      </div>
      <div>
        <p>
          {SECURITY_NO_MEMBERS_IN_PERMISSIONS_GROUP_MESSAGE}
        </p>
      </div>
    </div>
  );

  render() {
    const hasPermissionToAddUser = this.hasEditPermission() && this.hasPermissionForUsers();
    const members = this.state.regionMembers;

    return (
      <div className={styles.permissionsAdminSection}>
        <div className={cx(styles.marginTop, styles.permissionsHeader)}>
          <span className={cx('txt-bold')}>
            <i
              aria-hidden="true"
              className={cx('icon-headset-mic', styles.icon)}
            />
            <span className={styles.marginLeft}> Members</span>
          </span>
        </div>
        <div className={styles.permissionsDivider}>
          <Divider />
        </div>

        {
          hasPermissionToAddUser
          && (
            <Label className={styles.permissionsLabel}>
              Add&nbsp;
              {this.props.region!.name}
              &nbsp;member:
            </Label>
          )
        }

        <div className={styles.permissionsContainer}>
          <div className={styles.dropDownSearchContainer}>
            <div className={styles.searchContainer}>
              {
                hasPermissionToAddUser
                && (
                  <DebouncedInput
                    itemSelected={this.onSearchItemSelected}
                    filter={this.onSearchFilter}
                    placeholder="e.g. “Matthew McConaughey”…"
                  />
                )
              }
            </div>
          </div>
          {members.length > 0
            ? this.renderTableData([...members].sort(membersByName))
            : Members.renderEmptyTable()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    userPermission: state.userPermission,
    users: state.usersList.users,
  };
}
type MembersReduxStateProps = ReturnType<typeof mapStateToProps>;
type MembersOwnProps = {
  group?: GroupDto,
  region?: RegionDto,
  userHasEditPermissionInAnyRegion?: typeof userHasEditPermissionInAnyRegion;
  fetchGroupMembersWithFilter?: typeof fetchGroupMembersWithFilter;
  putMember?: typeof putMember;
  postMember?: typeof postMember;
  deleteMember?: typeof deleteMember;
};

export type MembersProps = MembersReduxStateProps & MembersOwnProps;

export default connect(mapStateToProps, {})(Members);
