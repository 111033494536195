import { CustomerDto } from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import { FETCH_CUSTOMERS } from '../../constants/actionConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const customersReducer = (
  state = { customers: [] as CustomerDto[] },
  action: PayloadAction<CustomerDto[], 'FETCH_CUSTOMERS'>
) => {
  switch (action.type) {
    case FETCH_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    default:
      return state;
  }
};
