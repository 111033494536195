import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PermissionAccess } from '@trucktrax/trucktrax-ts-common';
import DataTableContent from '../../shared/DataTableContent';
import {
  addDataTest, addHeaderDataTest, GeozoneDescription, GeozoneName,
} from '../../../util/adminUtil';
import GeozonesAddModal from './GeozonesAddModal';
import { GEOZONES_TEXT, PLANTS_TEXT } from '../../../constants/navConstants';
import { ADMIN_KEYS, ADMIN_LABELS } from '../../../constants/appConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { DATASOURCE, GEOZONES_PATH } from '../../../constants/apiConstants';
import { ReduxState } from '../../../store';

export interface GeozonesViewState {
  isOpen: boolean;
  isCloseRequest: boolean;
}
export class GeozonesView extends Component<GeozonesViewProps, GeozonesViewState> {
  state = {
    isOpen: false,
    isCloseRequest: false,
  };

  static getColumns = () => [
    {
      Header: addHeaderDataTest(null, 'sort-goezone-by-name', ADMIN_LABELS.NAME),
      id: ADMIN_KEYS.NAME,
      accessor: GeozoneName,
      minWidth: 65,
      sortable: true,
      Cell: (props: { value: string; }) => addDataTest('txt-bold', 'sort-goezone-by-name', props.value),
    }, {
      Header: addHeaderDataTest(null, 'geozone-description', ADMIN_LABELS.DESCRIPTION),
      id: ADMIN_KEYS.DESCRIPTION,
      accessor: GeozoneDescription,
      minWidth: 130,
      sortable: false,
      Cell: (props: { value: string; }) => addDataTest(null, 'geozone-description', props.value),
    }];

  requestCloseModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      isCloseRequest: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: false,
    });
  };

  render() {
    const baseUrl = getGeotraxBaseUrl() + GEOZONES_PATH;
    const columns = GeozonesView.getColumns();

    const hasPermissionAccess = this.props.geozonesPermissionAccess === PermissionAccess.Edit;
    const props: any = {
      baseUrl,
      columns,
      header: GEOZONES_TEXT,
      canAddNewItem: hasPermissionAccess,
      byPassRedisCache: true,
      source: DATASOURCE.GEOZONE
    };
    return (
      <div>
        <DataTableContent {...props} />
        {hasPermissionAccess && (
          <GeozonesAddModal
            isOpen={this.state.isOpen}
            openModal={this.openModal}
            closeModal={this.requestCloseModal}
            discardAcceptAction={this.closeModal}
            discardRejectAction={this.openModal}
            isCloseRequest={this.state.isCloseRequest}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  geozonesPermissionAccess: state.adminPermissionAccess[PLANTS_TEXT] ?? '',
});

type GeozonesViewReduxStateProps = ReturnType<typeof mapStateToProps>;
export type GeozonesViewProps = GeozonesViewReduxStateProps;

export default connect(mapStateToProps)(GeozonesView);
