import React, {
  ChangeEvent,
  Component,
  UIEvent,
} from 'react';
import { connect } from 'react-redux';
import {
  Button,
  DropdownItem,
  SidebarSelection,
  TTDatePicker,
  DateWrapper,
} from '@trucktrax/trucktrax-common';
import cx from 'classnames';
import { OperatorNotesDto, PlantDto, SimpleNameUrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import ReactToPrint from 'react-to-print';
import styles from './NotebookPopover.module.css';
import setSidebarSelected from '../../store/actions/sidebarActions';
import { NOTEBOOK_SELECTED } from '../../constants/appConstants';
import { ConnectedDispatchFunction, OperatorNotesPage, SidebarTitleType } from '../../types';
import { ReduxState } from '../../store';
import {
  fetchOperatorNotes,
  saveOperatorNotes,
  fetchNotebookOperators,
} from '../../services/operatorNotesService';
import { ScaleTraxAutocomplete } from '../shared/forms/ScaleTraxAutocomplete';
import CardImage from '../shared/forms/CardImage';
import { noop } from '../../util/appUtil';
import printerBlackSvg from '../../assets/img/printer-black.svg';
import iconChevronDown from '../../assets/img/icon-chevron-down.svg';

export class NotebookPopover extends Component<NotebookPopoverProps, NotebookPopoverState> {
  static notebookOperatorsEveryone = ({
    label: 'Everyone',
    value: '',
  });

  dateFormat = 'EEEE, MMM d yyyy';

  datePickerFormat = 'MMM dd';

  // eslint-disable-next-line class-methods-use-this
  get today() { return DateWrapper.now.startOfDay; }

  textarea: HTMLTextAreaElement | undefined;

  notebookBody: HTMLElement | undefined;

  constructor(props: NotebookPopoverProps) {
    super(props);
    this.state = {
      isExpanded: false,
      isNotebookOpen: false,
      isOperatorSearchOpen: false,
      endDate: this.today.date,
      currentNotes: [],
      isTypingNote: false,
      newNoteText: '',
      currentOperator: NotebookPopover.notebookOperatorsEveryone,
      currentNotesPage: 1,
    };
  }

  componentDidMount() {
    this.fetchOperatorNotes(1);
    this.updateNotebookOpen();
    this.fetchNotebookOperators();
  }

  componentDidUpdate(prevProps: NotebookPopoverProps) {
    this.updateNotebookOpen();

    if (prevProps.currentPlant !== this.props.currentPlant) {
      this.componentDidUpdatePlant();
      return;
    }

    this.componentDidUpdateNotes(prevProps);
  }

  componentDidUpdatePlant() {
    this.setState({
      currentOperator: NotebookPopover.notebookOperatorsEveryone,
      endDate: this.today.date,
    }, () => {
      this.fetchOperatorNotes(1);
      this.fetchNotebookOperators();
    });
  }

  componentDidUpdateNotes(prevProps: NotebookPopoverProps) {
    const { operatorNotes } = this.props;
    const { currentNotes } = this.state;

    const hasChangedPlant = operatorNotes.plantUrl !== prevProps.operatorNotes.plantUrl;
    const isInitialPage = operatorNotes.page < 2;

    if (currentNotes !== operatorNotes.items
      && (hasChangedPlant || isInitialPage)) {
      this.setState({ currentNotes: operatorNotes.items });
      return;
    }

    const newNotes = operatorNotes.items.filter(newNote => !currentNotes.find(n => n.id === newNote.id));
    const hasNewNotes = newNotes && newNotes.length;
    if (hasNewNotes) {
      let notes = [...newNotes, ...this.state.currentNotes.filter(n => !!n)];
      notes = notes.filter(n => !!n.user?.name);
      notes = notes.sort((n1, n2) => DateWrapper.sort(n1.createdTimestamp, n2.createdTimestamp));
      notes = notes.reverse();
      this.setState({ currentNotes: notes });
    }
  }

  fetchOperatorNotes = (pageNumber: number) => {
    const { currentPlant } = this.props;
    const { endDate, currentOperator } = this.state;

    this.setState({ currentNotesPage: pageNumber });

    this.props.fetchOperatorNotes(
      new DateWrapper(endDate).endOfDay.date,
      currentPlant?.url,
      currentOperator.value,
      pageNumber
    );
  };

  updateNotebookOpen = () => {
    const isNotebookSidebar = this.props.sidebarSelection?.sidebarTitle === NOTEBOOK_SELECTED;
    const notebookSidebarHasChanged = isNotebookSidebar && this.props.sidebarSelection.isSelected !== this.state.isNotebookOpen;

    if (notebookSidebarHasChanged) {
      const isNotebookOpen = this.props.sidebarSelection.isSelected;
      this.setState({ isNotebookOpen });

      if (!isNotebookOpen) {
        this.setState({ isTypingNote: false, isExpanded: false });
      }
    }
  };

  fetchNotebookOperators = () => {
    const { currentPlant } = this.props;
    const { endDate } = this.state;
    this.props.fetchNotebookOperators(endDate, currentPlant?.url);
  };

  renderHeader = () => {
    const { isExpanded } = this.state;

    return (
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <span
            data-test="datablock-breadcrumb-link"
            role="button"
            aria-hidden
          >
            <span className={styles.headerText}>NOTEBOOK</span>
          </span>
        </div>
        <div>
          <Button
            onClick={this.expandClick}
            buttonClassName={cx(
              styles['datablock-info-close'],
              'tt-btn-basic'
            )}
            iconClassName={cx(
              {
                'icon-expand': !isExpanded,
                'icon-collapse': isExpanded,
              },
              'icon-white'
            )}
            dataTest="dataBlock-expand-button"
          />
          <Button
            onClick={this.closePopover}
            buttonClassName={cx(
              styles['datablock-info-close'],
              'tt-btn-basic'
            )}
            iconClassName={cx('icon-close', 'icon-white')}
            dataTest="dataBlock-close-button"
          />
        </div>
      </div>
    );
  };

  static triggerFunc = () => (
    <button className={styles.printBtn}>
      <CardImage src={printerBlackSvg} />
    </button>
  );

  expandClick = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  closePopover = () => {
    this.props.setSidebarSelected!(NOTEBOOK_SELECTED, false);
  };

  renderToolbar = () => {
    const { endDate } = this.state;
    const disableTodayButton = new DateWrapper(endDate).toString(this.datePickerFormat) === this.today.toString(this.datePickerFormat);

    return (
      <div className={styles.toolbar}>
        <div className={styles.toolbarLeft}>
          {this.renderOperatorSearch()}
          <div className={styles.datePicker}>
            <TTDatePicker
              dataTest="dataBlock-date-picker"
              value={endDate}
              isRequired={false}
              label=""
              onChangeCallback={this.onDatePickerChanged}
              onBlurCallback={noop}
              shouldDisableFuture
              minDate="2000-01-01 00:00:00"
              maxDate={this.today.toString('yyyy-MM-dd')}
              keyboardIcon={(
                <div className={styles.datePickerBtn}>
                  <span>
                    {new DateWrapper(endDate).toString(this.datePickerFormat)}
                  </span>
                  <CardImage src={iconChevronDown} className={styles.iconChevronDown} />
                </div>
              )}
            />
          </div>
          <button
            className={disableTodayButton ? styles.todayBtnDisabled : styles.todayBtn}
            disabled={disableTodayButton}
            onClick={this.onTodayBtnClick}
          >
            Today
          </button>
        </div>
        <div className={styles.toolbarRight}>
          <ReactToPrint
            trigger={NotebookPopover.triggerFunc}
            content={() => this.notebookBody as any}
          />
        </div>
      </div>
    );
  };

  static getOptionLabel = (option: { label?: string }) => option.label ?? '';

  closeOperatorSearch = () => { this.setState({ isOperatorSearchOpen: false }); };

  renderOperatorSearch = () => {
    const { currentOperator, isOperatorSearchOpen } = this.state;

    if (isOperatorSearchOpen) {
      const { notebookOperators } = this.props;
      const items: DropdownItem<string>[] = (notebookOperators ?? []).map(o => ({
        label: o.name,
        value: o.url,
      }));
      items.unshift(NotebookPopover.notebookOperatorsEveryone);

      return (
        <div className={styles.operatorSearch}>
          <div className={styles.operatorSearchAutoComplete}>
            <ScaleTraxAutocomplete
              options={items}
              onChange={this.onCurrentOperatorChanged}
              value={currentOperator}
              getOptionLabel={NotebookPopover.getOptionLabel}
              isOpen={isOperatorSearchOpen}
              onBlur={this.closeOperatorSearch}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={styles.operatorSearch}>
        <button
          className={styles.operatorSearchBtn}
          onClick={() => this.setState({ isOperatorSearchOpen: true })}
        >
          {currentOperator.label ?? NotebookPopover.notebookOperatorsEveryone.label}
          <CardImage src={iconChevronDown} className={styles.iconChevronDown} />
        </button>
        {
          (!!currentOperator.value)
          && (
            <button
              className={styles.operatorSearchBtnRemove}
              onClick={() => this.onCurrentOperatorChanged(undefined, undefined)}
            >
              <i className="icon-close" />
            </button>
          )
        }
      </div>
    );
  };

  onCurrentOperatorChanged = (event: ChangeEvent<{}> | undefined, value?: DropdownItem<string>) => {
    this.setState(
      {
        currentOperator: value ?? NotebookPopover.notebookOperatorsEveryone,
        isOperatorSearchOpen: false,
      },
      () => this.fetchOperatorNotes(1)
    );
  };

  onDatePickerChanged = (newDate: Date) => {
    this.setState({
      endDate: new DateWrapper(newDate).endOfDay.date,
      currentOperator: NotebookPopover.notebookOperatorsEveryone,
    }, () => {
      this.fetchNotebookOperators();
      this.fetchOperatorNotes(1);
    });
  };

  onTodayBtnClick = () => {
    this.setState(
      { endDate: this.today.date },
      () => {
        this.fetchNotebookOperators();
        this.fetchOperatorNotes(1);
      }
    );
  };

  renderBody = () => {
    const { currentNotes, endDate } = this.state;
    let lastDate = this.today.toString(this.dateFormat);
    const shouldShowTextInput = new DateWrapper(endDate).toString(this.dateFormat) === lastDate;

    return (
      <div
        className={styles.body}
        onScroll={this.onBodyScroll}
        ref={(el) => { this.notebookBody = el || undefined; }}
      >
        {shouldShowTextInput && this.renderTextSection()}
        {currentNotes.map(note => {
          const noteDate = new DateWrapper(note.createdTimestamp)
            .startOfDay
            .toString(this.dateFormat);

          if (noteDate === lastDate) {
            return NotebookPopover.renderNote(note);
          }

          lastDate = noteDate;
          return (
            <div key={`notes${lastDate}`}>
              <p className={styles.date}>{noteDate}</p>
              {NotebookPopover.renderNote(note)}
            </div>
          );
        })}
      </div>
    );
  };

  onBodyScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target as any;
    if (scrollTop + clientHeight + 5 < scrollHeight) {
      return;
    }

    const { currentNotesPage } = this.state;
    this.fetchOperatorNotes(currentNotesPage + 1);
  };

  renderTextSection = () => {
    const { isTypingNote } = this.state;

    if (isTypingNote) {
      return this.renderTextInput();
    }

    return (
      <button
        className={styles.textInputUnselected}
        onClick={this.onTextInputToggleClick}
      >
        <p className={styles.date}>{this.today.toString(this.dateFormat)}</p>
        <p className={styles.note}>Type something...</p>
      </button>
    );
  };

  onTextInputToggleClick = () => {
    this.setState({ isTypingNote: true });
    global.setTimeout(() => {
      if (this.textarea) {
        this.textarea.focus();
      }
    }, 100);
  };

  renderTextInput = () => (
    <div className={styles.textInputSelected}>
      <p className={styles.date}>{this.today.toString(this.dateFormat)}</p>
      <textarea
        aria-label="text input"
        value={this.state.newNoteText}
        onChange={this.onTextInputChange}
        ref={(input: HTMLTextAreaElement) => { this.textarea = input; }}
        maxLength={4080}
      />
      <div>
        <button onClick={this.onTextInputDiscard} className="tt-btn tt-btn-secondary">Discard</button>
        <button onClick={this.onTextInputSave} className="tt-btn">Save</button>
      </div>
    </div>
  );

  onTextInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      newNoteText: event.target.value,
    });
  };

  onTextInputSave = () => {
    const { currentPlant, currentUser } = this.props;
    const { newNoteText, currentNotes } = this.state;
    const newNotes = [...currentNotes];

    if (newNoteText) {
      const addedNote = {
        notes: newNoteText,
        createdTimestamp: DateWrapper.now.format('yyyy-MM-dd hh:mm:ssXXX'),
        user: {
          url: currentUser,
          name: '',
        },
        archived: false,
        deleted: false,
        id: 0,
        plant: { url: currentPlant?.url ?? '' },
      } as OperatorNotesDto;

      newNotes.unshift(addedNote);
      this.props.saveOperatorNotes(currentPlant?.url, currentUser, newNoteText);
    }

    this.setState({
      isTypingNote: false,
      newNoteText: '',
      currentNotes: newNotes,
    });
  };

  onTextInputDiscard = () => {
    this.setState({
      isTypingNote: false,
      newNoteText: '',
    });
  };

  static renderNote = (note: OperatorNotesDto) => {
    const dateTime = new DateWrapper(note.createdTimestamp).toString('h:mma');
    const createdBy = `${note.user.name} at ${dateTime}`;

    return (
      <div key={`note-${note.id}`}>
        <p className={styles.note}>{note.notes}</p>
        <p className={styles.createdBy}>{createdBy}</p>
      </div>
    );
  };

  render() {
    const { isExpanded, isNotebookOpen } = this.state;

    if (!isNotebookOpen) {
      return null;
    }

    const containerStyle = isExpanded
      ? styles.popoverContainerExpanded
      : styles.popoverContainer;

    return (
      <div className={cx(containerStyle, styles.fullTicketBlockWidth)}>
        <div
          className={cx(styles.popover, {
            [styles.expandedPopover]: isExpanded,
          })}
        >
          {this.renderHeader()}
          {this.renderToolbar()}
          {this.renderBody()}
        </div>
      </div>
    );
  }
}

export interface NotebookPopoverProps {
  setSidebarSelected?: (sidebarTitle: SidebarTitleType, isSelected: boolean) => void,
  sidebarSelection?: SidebarSelection,
  currentPlant?: PlantDto,
  currentUser: string,
  operatorNotes: OperatorNotesPage,
  notebookOperators: SimpleNameUrlKeyDto[],
  fetchOperatorNotes: ConnectedDispatchFunction<typeof fetchOperatorNotes>,
  saveOperatorNotes: ConnectedDispatchFunction<typeof saveOperatorNotes>,
  fetchNotebookOperators: ConnectedDispatchFunction<typeof fetchNotebookOperators>,
}

export interface NotebookPopoverState {
  isExpanded: boolean,
  isNotebookOpen: boolean,
  isOperatorSearchOpen: boolean,
  endDate: Date,
  currentNotes: OperatorNotesDto[],
  isTypingNote: boolean,
  newNoteText: string,
  currentOperator: DropdownItem<string>,
  currentNotesPage: number
}

export default connect<any, any, any>((state: Partial<ReduxState>) => ({
  sidebarSelection: state.sidebar,
  currentAppName: state.currentAppName,
  currentPlant: state.currentPlant,
  currentUser: state.userUrl,
  operatorNotes: state.operatorNotes,
  notebookOperators: state.notebookOperators?.operators,
}), {
  setSidebarSelected,
  fetchOperatorNotes,
  saveOperatorNotes,
  fetchNotebookOperators,
})(NotebookPopover as any);
