/* eslint-disable max-len */
import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Button, Label } from '@trucktrax/trucktrax-common';
import {
  PermissionAccess,
  ProductLine,
  UrlKeyDto,
  UserDto,
  UserPlantDto,
} from '@trucktrax/trucktrax-ts-common';
import styles from './UserDetailView.module.css';
import { openModal, closeModal } from '../../../store/actions/errorModalActions';
import { hasAppAccessInAnyRegion } from '../../../util/permissionUtil';
import { isUserLoggedIn, resetUserPassword } from '../../../services/usersService';
import {
  defaultRegionListForDropdown,
  dtoToUserDto,
  existingValueHasBeenRemoved,
  getFullName,
  ssoIdRemovalModalProps,
  getPrimaryRegionView,
  getSecondaryRegionsView,
} from '../../../util/adminUtil';
import { addToOrUpdateListInStore } from '../../../store/actions/dataTableActions';
import { updateDataTableRecord } from '../../../services/dataTableService';
import { USERS_TEXT } from '../../../constants/navConstants';
import { UPDATE_USER } from '../../../constants/actionConstants';
import {
  ACCENT,
  ACTIVATE_USER_BODY,
  ADMIN_KEYS,
  ADMIN_LABELS,
  ARE_YOU_SURE,
  CANCEL_LABEL,
  CHECKBOX_STATELESS_FORM,
  CONFIRM_ACTIVATE_USER,
  CONFIRM_DEACTIVATE_USER,
  CONTINUE_RESET_USER_PASSWORD,
  CUSTOM_COMPONENT,
  DEACTIVATE_USER_BODY,
  DROPDOWN_FORM,
  EDIT_USER_BTN,
  ERROR,
  INPUT_FORM,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_TEL,
  INPUT_TYPE_TEXT,
  MULTIDROPDOWN_FORM,
  NOT_AVAILABLE,
  NUMERIC_INPUT_FORM,
  RESET_AND_CREATE_NEW_PASSWORD_MSG,
  RESET_AND_SEND_USER_PASSWORD_MSG,
  RESET_USER_PASSWORD_TITLE,
  SAVE_BTN,
  SHOW_NO,
  SHOW_YES,
  TEXT_DISPLAY,
} from '../../../constants/appConstants';
import { USERS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { noop } from '../../../util/appUtil';
import StatusContainer from '../status/StatusContainer';
import { getPlant } from '../../../services/plantsService';
import { DropDownListItem, MaybeDefaultRegion, TruckTraxApps } from '../../../types';
import { fetchPlants, isDisabledPlants } from './userPlants';
import AdminResourceCard, { AdminErrors } from '../../shared/admin/AdminResourceCard';
import { isChangedValid } from '../../../util/validation';
import ProductLines from './ProductLines';
import ProductLineRegionDetails from './ProductLineRegionDetails';

/**
 * Deselects invalid secondary plant selections - which can occur due to changes
 * in other selections on the form (such as changes in region or product line)
 * @param dto The DTO with a collection of selected secondary plants
 * @param items The items in the dropdown list
 * @returns A revised collection of items that are common to both lists
 */
function deselectInvalidSecondaryPlantSelections(dto: editUserDto, items: keys[]): plant[] {
  // this must only run when there are items in BOTH collections or it breaks horribly
  if (dto?.plants?.length && dto.plants.length > 0 && items?.length && items.length > 0) {
    const itemKeys = items.map(i => i.key);
    return dto.plants.filter(plant => itemKeys.includes(plant.key!));
  }
  // ... or return the original collection unmolested
  return dto.plants;
}

export class UserDetailView extends Component<UserDetailViewProps> {
  static defaultProps = {
    regions: [],
    assignedRegionList: [],
    usersPermissionAccess: '',
    currentRegion: { url: '' },
  };

  state = {
    dto: undefined as (editUserDto | undefined),
    disabled: true,
    edit: false,
    errors: {} as AdminErrors,
    plants: [] as { key: string, label: string }[],
    userDetails: {
      activated: true,
      archived: false,
      deleted: false,
      email: '',
      employeeNumber: undefined,
      firstName: '',
      id: 0,
      isWeighMaster: [],
      lastName: '',
      passwordCreatedTimestamp: '',
      plants: [] as UserPlantDto[],
      primaryProductLine: '' as ProductLine,
      regions: [],
      secondaryProductLines: [] as ProductLine[],
      secondaryRegions: [],
      tempPassword: true,
      url: '',
      userLoginStatus: true,
    },
  };

  componentDidUpdate(prevProps: Readonly<UserDetailViewProps>, prevState: any): void {
    if (prevState.userDetails.url !== this.state.userDetails.url) {
      this.getUserStatus(this.state.userDetails.url);
    }
  }

  onSaveSuccess = (updatedDto: any, onSuccess: any) => {
    this.setState({
      edit: false,
    });
    // somewhat complicated: if the incoming data is there then use it, else use either the
    // potentially empty state.userDetails (if it isn't null) or use just an empty set
    let data = updatedDto && updatedDto.data ? updatedDto.data : this.state.userDetails || {};
    // then check if the incoming data object is undefined or if its length is zero and the
    // state.userDetails.id is set to the default of 0, in these two cases return an empty set
    if (updatedDto === undefined || (Object.keys(updatedDto).length === 0 && this.state.userDetails.id === 0)) {
      data = {};
    }
    this.setData(data);
    onSuccess(updatedDto);
    this.props.addToOrUpdateListInStore(updatedDto, UPDATE_USER);
    this.getUserStatus(this.state.userDetails.url);
  };

  onSuccess = (onSuccess: any) => (updatedDto: any) => this.onSaveSuccess(updatedDto, onSuccess);

  static appendRegions = (userDto: any) => {
    // Create a combined region value
    const combinedRegion = userDto.regions;
    const { secondaryRegions } = userDto;
    if (secondaryRegions) {
      secondaryRegions.forEach((o: { key: any; }) => combinedRegion.push({
        url: o.key,
        defaultRegion: false,
      }));
      userDto.regions = combinedRegion;
      delete userDto.secondaryRegions;
    }

    return userDto;
  };

  appendPlants = (userDto: any) => {
    if (!userDto.plant && !userDto.plants) {
      return userDto;
    }

    userDto.plants = (userDto.plants ?? []).map((p: UserDto) => ({ url: p.url, default: false }));
    if (userDto.plant) {
      // remove plant if was loaded as a secondary
      userDto.plants = (userDto.plants ?? []).filter((plant: { url: any; }) => plant.url !== userDto.plant);
      userDto.plants.push({ url: userDto.plant as string, default: true });
      delete userDto.plant;
    }

    if (this.isDisabledPlants()) {
      userDto.plants = [];
    }

    return userDto;
  };

  save = (dto: any, onSuccess: any, onError: any, toastMessages: any, activationChange?: boolean) => {
    let userDto = dtoToUserDto(dto);
    userDto = UserDetailView.appendRegions(userDto);
    if (!activationChange) { userDto = this.appendPlants(userDto); }
    delete userDto.isWeighMaster;

    // remove primaryProductLine from secondaryProductLines; we added it for the "active" checkboxes but it can't be saved
    userDto.secondaryProductLines = userDto.secondaryProductLines.filter((p: any) => p !== userDto.primaryProductLine);

    // employee number and SSO ID are two names for the same thing
    if (existingValueHasBeenRemoved(this.state.userDetails.employeeNumber, userDto.employeeNumber)) {
      this.activateSSOWarningModal(userDto, onSuccess, onError, toastMessages);
    } else {
      this.UpdateUserData(userDto, onSuccess, onError, toastMessages);
    }
  };

  productLines = () => (
    <ProductLines
      primaryProductLine={this.state.dto!.primaryProductLine}
      secondaryProductLines={this.state.dto!.secondaryProductLines}
      onPrimaryProductLineChange={this.handlePrimaryProductLineChange}
      onSecondaryProductLineChange={this.handleSecondaryProductLineChange}
    />
  );

  productLineAndRegionDetails() {
    if (this.state.edit) {
      return null;
    }
    return (
      <ProductLineRegionDetails
        primaryProductLine={this.state.userDetails.primaryProductLine}
        secondaryProductLines={this.state.userDetails.secondaryProductLines}
        primaryRegion={getPrimaryRegionView(this.state.userDetails, this.props.regions)}
        secondaryRegions={getSecondaryRegionsView(this.state.userDetails, this.props.regions)}
      />
    );
  }

  UpdateUserData(userDto: any, onSuccess: any, onError: any, toastMessages: any) {
    const currentRegionUrl = this.props.currentRegion.url;
    this.props.updateAdminTableData(
      this.baseUrl(),
      userDto,
      this.onSuccess(onSuccess),
      onError,
      false,
      currentRegionUrl,
      toastMessages
    );
  }

  handleSecondaryRegionChange = (event: any) => {
    const secondaryRegions = event?.secondaryRegions.map((r: { key: any; }) => ({ key: r.key }));
    this.setState({
      dto: {
        ...this.state.dto,
        secondaryRegions,
      },
      disabled: false,
    });
  };

  handlePrimaryProductLineChange = (event: ProductLine) => {
    const { dto } = this.state;
    dto!.primaryProductLine = event;
    this.setState({
      dto: {
        ...dto,
        isWeighMaster: [],
        plant: undefined,
        plants: [],
      },
      errors: {},
      disabled: false,
    }, this.fetchPlants);
  };

  handleSecondaryProductLineChange = (event: any, selectedProductLine: ProductLine) => {
    const { dto } = this.state;

    // can not deselect primary product line from being active
    if (dto?.primaryProductLine === selectedProductLine) return;

    let removedLine;
    if (dto?.secondaryProductLines.includes(selectedProductLine)) {
      removedLine = selectedProductLine;
      dto.secondaryProductLines = dto.secondaryProductLines.filter(p => p !== selectedProductLine);
    } else {
      dto!.secondaryProductLines.push(selectedProductLine);
    }
    const clearPlants = isDisabledPlants(dto?.primaryProductLine, [selectedProductLine]);

    // Remove regions that are not in the available product line - they get removed in the dropdown elsewhere, this is changing the list in state so when it is saved it matches
    const regions = defaultRegionListForDropdown(this.props.regions, this.props.assignedRegionList, this.props.secondaryRegion, dto!.secondaryProductLines, true);
    const validKeys = new Set(regions.map(region => region.key));
    if (dto?.secondaryRegions) {
      dto.secondaryRegions = dto.secondaryRegions.filter(region => validKeys.has(region.key));
    }

    this.setState({
      dto: {
        ...dto,
        isWeighMaster: clearPlants ? [] : dto?.isWeighMaster,
        plant: clearPlants || removedLine ? undefined : dto?.plant,
        plants: clearPlants || removedLine ? [] : dto?.plants,
      },
      errors: {},
      disabled: false,
    }, this.fetchPlants);
  };

  handleWeighMasterChange = (event: any) => {
    const isWeighMaster = event.weighmaster.length > 0;
    this.setState({
      dto: {
        ...this.state.dto,
        plant: isWeighMaster ? this.state.dto?.plant : '',
        plants: isWeighMaster ? this.state.dto?.plants : [],
        isWeighMaster: isWeighMaster ? [ADMIN_LABELS.WEIGHMASTER] : [],
      },
      errors: {},
      disabled: false,
    }, this.fetchPlants);
  };

  activateSSOWarningModal = (userDto: any, onSuccess: any, onError: any, toastMessages: any) => {
    this.props.openModal({
      ...ssoIdRemovalModalProps,
      acceptDialog: () => {
        this.props.closeModal();
        this.UpdateUserData(userDto, onSuccess, onError, toastMessages);
      },
    });
  };

  disablePlantsSelect = () => this.isDisabledPlants() || (this.state.dto && this.state.dto?.isWeighMaster?.length <= 0);

  // Generic Handler
  handleUpdate = (event: any) => {
    this.setState({
      dto: {
        ...this.state.dto,
        ...event,
      },
      errors: {},
      disabled: false,
    });
  };

  getAcceptDialog = () => {
    this.props.resetUserPassword(this.state.userDetails, this.props.currentRegion.url!);
  };

  setData = (data: any) => {
    // Need to modify region and secondary regions to be in appropriate format
    if (data.regions || data.secondaryRegions) {
      data.secondaryRegions = this.secondaryRegionAccessorEdit(data, true);
      this.setDataUpdateRegions(data);
    }
    if (data.regions) {
      data.regions = this.defaultRegionAccessorEdit(data, true);
    }

    let { dto } = this.state;

    dto = data;
    dto!.secondaryProductLines = dto!.secondaryProductLines.indexOf(dto!.primaryProductLine) >= 0
      ? dto!.secondaryProductLines
      : [...dto!.secondaryProductLines, dto!.primaryProductLine];

    this.setState({
      dto,
      userDetails: { ...data },
    });
    return data;
  };

  setValue = (value: any) => {
    this.setState({
      dto: {
        ...value,
        secondaryProductLines: [...value.secondaryProductLines],
        plants: [...value.plants],
        isWeighMaster: value.plant || (value.plants && value.plants.length > 0) ? [ADMIN_LABELS.WEIGHMASTER] : [],
      },
      userDetails: {
        ...this.state.userDetails,
        plant: this.state.dto?.plant,
        isWeighMaster: value.plant || (value.plants && value.plants.length > 0) ? [ADMIN_LABELS.WEIGHMASTER] : [],
      },
    });
    return value;
  };

  setErrors = (errors: AdminErrors) => {
    this.setState({ errors });
  };

  checkForChange = () => {
    let previous: any = { ...this.state.userDetails };
    let current = { ...this.state.dto };
    // Check plants and secondary regions separately, since they're handled differently
    if (UserDetailView.checkPlantChanges(previous, current)) { return true; }
    if (UserDetailView.checkRegionChanges(previous, current)) { return true; }
    if (UserDetailView.checkSSOIDChanges(previous, current)) { return true; }
    previous = {
      ...previous,
      isWeighMaster: [],
      plants: [],
      plant: undefined,
      secondaryRegions: [],
    };
    current = {
      ...current,
      isWeighMaster: [],
      plants: [],
      plant: undefined,
      secondaryRegions: [],
    };
    return isChangedValid(previous, current);
  };

  static checkPlantChanges = (previous: any, current: any) => {
    if (previous.plant !== current.plant) { return true; }
    const prevPlants = previous.plants?.map((p: { key: string | undefined; }) => p.key).filter((p: string) => p !== current.plant).sort();
    const currPlants = current.plants?.map((p: { key: string | undefined; }) => p.key).filter((p: string) => p !== current.plant).sort();
    if (JSON.stringify(prevPlants) !== JSON.stringify(currPlants)) { return true; }
    return false;
  };

  static checkRegionChanges = (previous: any, current: any) => {
    if (previous.secondaryRegions?.length !== current.secondaryRegions?.length) { return true; }
    const prevRegions = previous.secondaryRegions?.map((r: { key: string | undefined; }) => r.key).sort();
    const currRegions = current.secondaryRegions?.map((r: { key: string | undefined; }) => r.key).sort();
    if (JSON.stringify(prevRegions) !== JSON.stringify(currRegions)) { return true; }
    return false;
  };

  static checkSSOIDChanges = (previous: any, current: any) => {
    if (previous.employeeNumber !== current.employeeNumber) { return true; }
    return false;
  };

  baseUrl = () => `${getGeotraxBaseUrl() + USERS_PATH}/${this.props.match.params.id}`;

  toggleEdit = () => {
    if (this.state.edit) {
      this.setState({ edit: false });
    } else {
      this.setState(
        {
          dto: {
            ...this.state.dto,
            isWeighMaster: (this.state.userDetails.plants && this.state.userDetails.plants.length > 0)
              ? [ADMIN_LABELS.WEIGHMASTER] : [],
          },
          edit: true,
        }
      );
    }
  };

  viewDefaultPlantAccessor = (dto: editUserDto) => {
    const defaultPlantUrl = dto.plants.find(p => p.default)?.url;

    const label = this.state.plants.find(p => p.key === defaultPlantUrl)?.label;
    return label ?? NOT_AVAILABLE;
  };

  viewSecondaryPlantAccessor = (dto: editUserDto) => {
    const secondaryPlantUrls = dto.plants.filter(p => !p.default)?.map(p => p.url);

    const secondaryPlantNames = this.state.plants
      .filter(p => secondaryPlantUrls.some(u => u === p.key))
      ?.map(p => p.label);

    return secondaryPlantNames?.length ? secondaryPlantNames : NOT_AVAILABLE;
  };

  fetchPlants = async () => {
    const { dto } = this.state;
    if (!dto) { return; }

    const plants = await fetchPlants(
      dto.primaryProductLine,
      dto.secondaryProductLines,
      dto.regions ?? [],
      dto.secondaryRegions ?? []
    );

    if (this.isDisabledPlants() || !plants || !plants.length) {
      dto.plant = undefined;
      dto.plants = [];
      this.setState({ dto });
    }

    this.setState({ plants });
  };

  setDataUpdateRegions = (dto: editUserDto) => {
    try {
      const regions = dto.regions ?? [];
      if (this.state.dto?.regions !== regions
        || dto.secondaryRegions !== this.state.dto?.secondaryRegions) {
        this.setState({ dto }, this.fetchPlants);
      }
    } catch (e) {
      return null;
    }
    return null;
  };

  defaultRegionAccessorEdit = (dto: editUserDto, noFetch: boolean = false) => {
    try {
      const regions = dto.regions ?? [];

      if (this.state.dto?.regions !== regions && !noFetch) {
        this.setState({ dto }, this.fetchPlants);
      }

      return regions.filter(r => r.defaultRegion === true);
    } catch (e) {
      return [];
    }
  };

  secondaryRegionAccessorEdit = (dto: editUserDto, noFetch: boolean = false) => {
    try {
      const defaultRegion = dto.regions?.find(r => r.defaultRegion);

      if ((dto.secondaryRegions !== this.state.dto?.secondaryRegions
        || dto.regions !== this.state.dto?.regions) && !noFetch) {
        this.setState({ dto }, this.fetchPlants);
      }

      if (dto.secondaryRegions) {
        return dto.secondaryRegions.filter(r => r.key !== defaultRegion?.url);
      }

      if (dto.regions) {
        return dto.regions.filter(r => !r.defaultRegion).map(r => ({ key: r.url }));
      }

      if (!this.state || !this.state.userDetails || !this.state.userDetails.regions) {
        return [];
      }

      const regions = this.state.userDetails.regions.filter((r: { defaultRegion: boolean }) => !r.defaultRegion);
      return regions.map((r: { url: any; }) => ({ key: r.url }));
    } catch (e) {
      return [];
    }
  };

  secondaryRegionFilter = (items: any, values: { regions: any[]; }) => {
    try {
      const regions = defaultRegionListForDropdown(items, this.props.assignedRegionList, this.props.secondaryRegion, this.state.dto!.secondaryProductLines, false);
      return regions
        .filter(r => r.key !== values.regions[values.regions.findIndex(i => i.defaultRegion === true)].url);
    } catch (e) {
      return items;
    }
  };

  isDisabledPlants = () => {
    const { dto } = this.state;
    return isDisabledPlants(dto?.primaryProductLine, dto?.secondaryProductLines);
  };

  primaryPlantAccessor = (dto: editUserDto) => {
    if (this.isDisabledPlants()) {
      return undefined;
    }

    if (!dto.plant) {
      dto.plant = dto.plants.find(d => d.default)?.url;
    }

    return dto.plant;
  };

  /**
 * Modifies the collection of secondary plants displayed in the dropdown
 * @param items The original collection.
 * @param dto the user data from the form
 * @returns secondary plants after modification
 */
  static secondaryPlantsFilter = (items?: keys[], dto?: editUserDto) => {
    // doing this here vs the secondaryPlantsAccessor because the `items` collection
    // does not exist in that method. Janky but it works.
    if (dto && items) {
      dto.plants = deselectInvalidSecondaryPlantSelections(dto, items);
    }
    return (items ?? [])
      // disable the selected primary plant
      .map(i => ({ ...i, disabled: i.key === dto?.plant }));
  };

  secondaryPlantsAccessor = (dto: editUserDto) => {
    if (this.isDisabledPlants()) {
      return [];
    }

    const plants = dto?.plants ?? [];
    plants.forEach(p => {
      if (!p.key) {
        p.key = p.url;
      }
    });

    const result = plants.filter(p => !p.default && p.key !== dto?.plant);
    return result;
  };

  viewWeighMasterAccessor = (dto: editUserDto) => {
    if (this.viewDefaultPlantAccessor(dto) === NOT_AVAILABLE
      && this.viewSecondaryPlantAccessor(dto) === NOT_AVAILABLE) {
      return SHOW_NO;
    }
    return SHOW_YES;
  };

  resetUserPassword = () => {
    if (!this.state.edit) {
      return (
        <div className={styles.resetPassword}>
          <Label htmlFor="Password">Password</Label>
          <Button
            buttonClassName={cx(styles.buttonClass, 'tt-btn-basic')}
            name="Reset Password"
            dataTest="reset-password"
            onClick={this.openResetPasswordModal}
          />
        </div>
      );
    }
    return null;
  };

  openResetPasswordModal = () => {
    const modalBody = (
      <p className={styles.resetPasswordBody}>
        {RESET_AND_SEND_USER_PASSWORD_MSG}
        <b>
          {this.state.userDetails.email}
          .
        </b>
        {RESET_AND_CREATE_NEW_PASSWORD_MSG}
      </p>
    );
    this.props.openModal({
      modalType: ERROR,
      modalTitle: RESET_USER_PASSWORD_TITLE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.getAcceptDialog,
      acceptText: CONTINUE_RESET_USER_PASSWORD,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  viewOnlyItems() {
    if (this.state.edit) {
      return null;
    }

    const hasEditPermission = this.props.usersPermissionAccess === PermissionAccess.Edit;
    return (
      <StatusContainer
        category="user"
        isArchived={this.state.userDetails.archived}
        hasEditPermission={hasEditPermission}
        containerClassName={styles.statusCtr}
        onActivateClick={this.activateWarningModal}
        onDeactivateClick={this.deactivateWarningModal}
      />
    );
  }

  activateWarningModal = () => {
    const modalBody = (
      <span>
        <p>{ACTIVATE_USER_BODY}</p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.activateUser,
      acceptText: CONFIRM_ACTIVATE_USER,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateWarningModal = () => {
    const modalBody = (
      <span>
        <p>{DEACTIVATE_USER_BODY}</p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.deactivateUser,
      acceptText: CONFIRM_DEACTIVATE_USER,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateUser = () => {
    const dto = {
      ...this.state.userDetails,
      archived: true,
    };
    const toastMessages = {
      success: `“${dto.lastName}, ${dto.firstName}” has been successfully deactivated`,
      fail: `Unable to deactivate “${dto.lastName}, ${dto.firstName}”`,
    };
    this.props.closeModal();
    this.save(dto, noop, null, toastMessages, true);
  };

  activateUser = () => {
    const dto = {
      ...this.state.userDetails,
      archived: false,
    };
    const toastMessages = {
      success: `"${dto.lastName}, ${dto.firstName}" has been successfully activated`,
      fail: `Unable to activate “${dto.lastName}, ${dto.firstName}”`,
    };
    this.props.closeModal();
    this.save(dto, noop, null, toastMessages, true);
  };

  getBreadcrumbPath() {
    if (!this.state.userDetails.archived) {
      return this.props.location.pathname;
    }
    // construct the breadcrumb path for deactivated users
    const paths = this.props.location.pathname.split('/');
    const id = paths.pop();
    if (id) {
      paths.push('deactivated', id);
    }
    return paths.join('/');
  }

  getUserStatus = async (userUrl: string) => {
    if (userUrl && userUrl.length > 0) {
      const loginStatus = await isUserLoggedIn(userUrl);
      if (loginStatus !== this.state.userDetails.userLoginStatus) {
        this.setState({ userDetails: { ...this.state.userDetails, userLoginStatus: loginStatus } });
      }
    }
  };

  getViewConfigWithoutEdit(showScaleTraxInfo: boolean) {
    const columns = [
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.NAME,
        accessor: getFullName,
        className: cx(styles.fullName, 'large'),
        dataTest: 'users-drilldown-name',
        textClassName: 'wrap-words',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.USERNAME,
        accessor: ADMIN_KEYS.USERNAME,
        className: styles.username,
        dataTest: 'users-drilldown-username',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.EMAIL,
        accessor: ADMIN_KEYS.EMAIL,
        className: styles.email,
        dataTest: 'users-drilldown-email',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.PHONE,
        accessor: ADMIN_KEYS.PHONE,
        className: styles.phoneNumber,
        dataTest: 'users-drilldown-phone',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.EXTERNAL_ID,
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        className: styles.externalId,
        dataTest: 'users-drilldown-externalId',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.SSO_ID,
        accessor: ADMIN_KEYS.SSO_ID,
        className: styles.externalId,
        dataTest: 'users-drilldown-ssoId',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.DEFAULT_PLANT,
        accessor: this.viewDefaultPlantAccessor,
        className: styles.plants,
        dataTest: 'users-drilldown-default-plant',
        display: showScaleTraxInfo
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.SECONDARY_PLANTS,
        accessor: this.viewSecondaryPlantAccessor,
        className: styles.plants,
        dataTest: 'users-drilldown-secondary-plants',
        display: showScaleTraxInfo
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.WEIGHMASTER,
        accessor: this.viewWeighMasterAccessor,
        className: styles.plants,
        dataTest: 'users-drilldown-weighmaster',
        display: showScaleTraxInfo
      },
    ];

    return columns;
  }

  getViewConfigWithEdit(showScaleTraxInfo: boolean) {
    const viewConfigWithoutEdit = this.getViewConfigWithoutEdit(showScaleTraxInfo);
    const userLoginStatusBoolean = this.state.userDetails.userLoginStatus || this.state.userDetails.userLoginStatus === undefined;
    const userDetails = `${this.state.userDetails.lastName},${this.state.userDetails.firstName},
    ${this.state.userDetails.id},${userLoginStatusBoolean}`;

    return [
      ...viewConfigWithoutEdit,
      {
        type: EDIT_USER_BTN,
        key: userDetails,
        iconClassName: 'icon-create',
        dataTest: 'users-drilldown-edit',
      },
    ];
  }

  getEditConfig(showScaleTraxInfo: boolean) {
    const { userDetails, dto } = this.state;
    const viewConfigWithoutEdit = this.getViewConfigWithoutEdit(showScaleTraxInfo);

    let primaryDropDownRegions = [];
    let secondaryDropDownRegions = [];

    const primaryProductLine = dto?.primaryProductLine ? dto.primaryProductLine : userDetails.primaryProductLine;

    primaryDropDownRegions = primaryProductLine
      ? defaultRegionListForDropdown(this.props.regions, this.props.assignedRegionList, this.props.secondaryRegion, [primaryProductLine])
      : defaultRegionListForDropdown(this.props.regions, this.props.assignedRegionList, this.props.secondaryRegion);

    // if we haven't been modifying it yet, use the data from userDetails
    let allowedProductLines = userDetails?.secondaryProductLines && userDetails.secondaryProductLines.indexOf(primaryProductLine) >= 0
      ? userDetails.secondaryProductLines
      : [...(userDetails?.secondaryProductLines ?? []), primaryProductLine].filter(Boolean);

    // use the data that's been modified in state
    if (dto?.secondaryProductLines) {
      allowedProductLines = dto?.secondaryProductLines.indexOf(primaryProductLine) >= 0
        ? dto?.secondaryProductLines
        : [...(dto?.secondaryProductLines ?? []), dto?.primaryProductLine].filter(Boolean);
    }

    secondaryDropDownRegions = defaultRegionListForDropdown(this.props.regions, this.props.assignedRegionList, this.props.secondaryRegion, allowedProductLines);

    const weighmasterCheckbox = {
      label: ADMIN_LABELS.WEIGHMASTER,
      value: ADMIN_LABELS.WEIGHMASTER,
    };

    const columns = [
      viewConfigWithoutEdit[0],
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.FIRST_NAME,
        accessor: ADMIN_KEYS.FIRST_NAME,
        className: cx(styles.firstName, 'large'),
        label: ADMIN_LABELS.FIRST_NAME,
        maxLength: 50,
        dataTest: 'firstName-input-data-test',
        errorDataTest: 'firstName-input-missing-error',
        isRequired: true,
        onChange: this.handleUpdate,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.LAST_NAME,
        accessor: ADMIN_KEYS.LAST_NAME,
        className: cx(styles.lastName, 'large'),
        label: ADMIN_LABELS.LAST_NAME,
        maxLength: 50,
        dataTest: 'lasttName-input-data-test',
        errorDataTest: 'lasttName-input-missing-error',
        isRequired: true,
        onChange: this.handleUpdate,
      },
      {
        type: INPUT_FORM,
        accessor: ADMIN_KEYS.USERNAME,
        className: styles.usernameEdit,
        key: ADMIN_KEYS.USERNAME,
        label: ADMIN_LABELS.USERNAME,
        inputType: INPUT_TYPE_TEXT,
        maxLength: 50,
        dataTest: 'username-input-data-test',
        errorDataTest: 'username-input-missing-error',
        isRequired: true,
        onChange: this.handleUpdate,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.EXTERNAL_ID,
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        label: ADMIN_LABELS.EXTERNAL_ID,
        dataTest: 'externalId-input-data-test',
        errorDataTest: 'externalId-input-missing-error',
        className: styles.externalIdEdit,
        isRequired: false,
        maxLength: 50,
        onChange: this.handleUpdate,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.SSO_ID,
        accessor: ADMIN_KEYS.SSO_ID,
        label: ADMIN_LABELS.SSO_ID_EN,
        inputType: INPUT_TYPE_TEXT,
        dataTest: 'ssoId-input-data-test',
        errorDataTest: 'ssolId-input-missing-error',
        className: styles.externalIdEdit,
        isRequired: false,
        maxLength: 50,
        onChange: this.handleUpdate,
      },
      {
        type: INPUT_FORM,
        accessor: ADMIN_KEYS.EMAIL,
        className: styles.emailEdit,
        key: ADMIN_KEYS.EMAIL,
        label: ADMIN_LABELS.EMAIL,
        inputType: INPUT_TYPE_EMAIL,
        maxLength: 100,
        dataTest: 'email-input-data-test',
        errorDataTest: 'email-input-missing-error',
        isRequired: true,
        onChange: this.handleUpdate,
      },
      {
        type: NUMERIC_INPUT_FORM,
        accessor: ADMIN_KEYS.PHONE,
        className: styles.phoneNumberEdit,
        key: ADMIN_KEYS.PHONE,
        label: ADMIN_LABELS.PHONE,
        inputType: INPUT_TYPE_TEL,
        maxLength: 20,
        dataTest: 'phoneNumber-input-data-test',
        errorDataTest: 'phoneNumber-input-missing-error',
        isRequired: false,
        onChange: this.handleUpdate,
      },
      {
        type: CUSTOM_COMPONENT,
        key: ADMIN_KEYS.PRODUCT_LINES,
        component: () => this.productLines(),
      },
      {
        type: DROPDOWN_FORM,
        className: styles.defaultRegionEdit,
        key: ADMIN_KEYS.REGIONS,
        label: ADMIN_LABELS.DEFAULT_REGION,
        items: primaryDropDownRegions,
        isRequired: true,
        accessor: this.defaultRegionAccessorEdit,
        dataTest: 'region-input-data-test',
        onChange: this.handleUpdate,
      },
      {
        type: MULTIDROPDOWN_FORM,
        key: ADMIN_KEYS.SECONDARY_REGIONS,
        label: ADMIN_LABELS.SECONDARY_REGION,
        className: styles.secondaryRegionEdit,
        items: secondaryDropDownRegions,
        itemFilter: this.secondaryRegionFilter,
        isRequired: false,
        accessor: this.secondaryRegionAccessorEdit,
        placeholder: 'Select a Region...',
        lockMessage: 'You do not have access to edit this region',
        dataTest: 'secondaryRegion-input-data-test',
        onChange: this.handleSecondaryRegionChange,
      },
    ];

    const scaleTraxColumns = [
      {
        type: CHECKBOX_STATELESS_FORM,
        key: ADMIN_KEYS.WEIGHMASTER,
        accessor: ADMIN_LABELS.WEIGHMASTER,
        items: [weighmasterCheckbox],
        disabledItems: this.isDisabledPlants() ? [weighmasterCheckbox] : [],
        dataTest: 'weighmaster-input-data-test',
        className: styles.weighmaster,
        selected: this.state.dto?.isWeighMaster,
        onChange: this.handleWeighMasterChange,
      },
      {
        type: DROPDOWN_FORM,
        key: ADMIN_KEYS.PLANT,
        label: ADMIN_LABELS.DEFAULT_PLANT,
        className: cx('baseDropDown', 'bottomWidthSpace'),
        items: this.state.plants,
        initialSelected: { label: 'Select a plant...', key: null, value: { url: null } },
        dataTest: 'defaultRegion-input-data-test',
        disabled: this.disablePlantsSelect(),
        accessor: this.primaryPlantAccessor,
        displayPrompt: false,
        isRequired: true,
        allowEmpty: true,
        onChange: this.handleUpdate,
      },
      {
        type: MULTIDROPDOWN_FORM,
        key: ADMIN_KEYS.PLANTS,
        label: ADMIN_LABELS.SECONDARY_PLANTS,
        className: cx('baseDropDown', 'bottomWidthSpace'),
        items: this.state.plants,
        itemFilter: UserDetailView.secondaryPlantsFilter,
        accessor: this.secondaryPlantsAccessor,
        initialSelected: { value: [] },
        placeholder: 'Select one or multiple plants...',
        lockMessage: 'You cannot select this plant',
        dataTest: 'secondaryRegion-input-data-test',
        disabled: this.disablePlantsSelect(),
        onChange: this.handleUpdate,
      },
    ];

    const saveBtn = [
      {
        type: SAVE_BTN,
      },
    ];

    if (showScaleTraxInfo) {
      return [...columns, ...scaleTraxColumns, ...saveBtn];
    }
    return [...columns, ...saveBtn];
  }

  getConfigValue() {
    const showScaleTraxInfo = !hasAppAccessInAnyRegion(TruckTraxApps.ScaleTrax, this.props.userPermission);
    const viewConfigWithoutEdit = this.getViewConfigWithoutEdit(showScaleTraxInfo);
    const viewConfigWithEdit = this.getViewConfigWithEdit(showScaleTraxInfo);
    const editConfig = this.getEditConfig(showScaleTraxInfo);
    const hasEditPermission = this.props.usersPermissionAccess === PermissionAccess.Edit;

    if (!hasEditPermission) {
      return viewConfigWithoutEdit;
    }

    if (this.state.edit) {
      return editConfig;
    }

    const userArchivedOrUnknown = this.state.userDetails.archived === undefined
      || this.state.userDetails.archived;

    if (userArchivedOrUnknown) {
      return viewConfigWithoutEdit;
    }

    return viewConfigWithEdit;
  }

  render() {
    const pathname = this.getBreadcrumbPath();
    const configValue = this.getConfigValue();
    return (
      <AdminResourceCard
        edit={this.state.edit}
        url={this.baseUrl()}
        options={{
          requiredKeys: [],
        }}
        className={cx(
          styles.wrapper,
          this.state.edit && styles.editWrapper
        )}
        details={this.state.userDetails}
        alterDetails={this.setData}
        value={this.state.dto}
        alterValue={this.setValue}
        errors={this.state.errors}
        alterErrors={this.setErrors}
        customChangeCheck={this.checkForChange}
        onToggleEdit={this.toggleEdit}
        save={this.save}
        config={configValue}
        pathName={pathname}
        headerAccessor={getFullName}
        externalKeys={[ADMIN_KEYS.SECONDARY_REGIONS, ADMIN_KEYS.REGIONS, ADMIN_KEYS.PRODUCT_LINES]}
        disabled={this.state.disabled}
      >

        {
          this.state.userDetails.archived !== undefined
            && !this.state.userDetails.archived
            && this.props.usersPermissionAccess === PermissionAccess.Edit
            ? this.resetUserPassword() : ''
        }

        <div className={styles.spacer} />
        {this.viewOnlyItems()}
        {
          this.productLineAndRegionDetails()
        }
      </AdminResourceCard>
    );
  }
}

const mapStateToProps = (state: any) => ({
  regions: state.regionList,
  flags: state.flags,
  assignedRegionList: state.assignedRegionList,
  currentRegion: state.currentRegion,
  usersPermissionAccess: state.adminPermissionAccess[USERS_TEXT],
  userPermission: state.userPermission,
});

type Location = {
  pathname: string;
};

type Match = {
  params: { id: string };
};
export default connect<any, any, any>(
  mapStateToProps,
  {
    updateAdminTableData: updateDataTableRecord,
    addToOrUpdateListInStore,
    resetUserPassword,
    openModal,
    closeModal,
    getPlant,
  }
)(UserDetailView);

export interface UserDetailViewProps {
  updateAdminTableData: typeof updateDataTableRecord,
  closeModal: typeof closeModal,
  openModal: typeof openModal,
  addToOrUpdateListInStore: typeof addToOrUpdateListInStore,
  resetUserPassword: typeof resetUserPassword,
  usersPermissionAccess?: string,
  assignedRegionList?: any,
  secondaryRegion?: any,
  regions: any[],
  match: Match,
  currentRegion: UrlKeyDto,
  location: Location,
  userPermission: any,
}

export interface UsersDetailViewState {
  edit: boolean,
  dto?: editUserDto
}

/**
 * @interface
 * @description Represents the state of the fields on the form
 */
export interface editUserDto {
  primaryProductLine: ProductLine,
  secondaryProductLines: ProductLine[],
  /** @property {string} plant - The URL of the SELECTED primary plant  */
  plant?: string,
  /** @property {plant[]} plants - The SELECTED secondary plants  */
  plants: plant[],
  regions: MaybeDefaultRegion[],
  secondaryRegions?: DropDownListItem[],
  isWeighMaster: string[],
  employeeNumber?: string
}

interface plant {
  url?: string,
  key?: string,
  default: boolean
}

interface keys {
  key: string
}
