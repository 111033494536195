/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import {
  DropdownItem, DropDownList, Label, Tooltip
} from '@trucktrax/trucktrax-common';
import { ListSubheader, MenuItem, Select } from '@mui/material';
import styles from '../admin/AdminAddModal.module.css';
import { getEventFromTarget } from '../../../util/adminUtil';
import { noop } from '../../../util/appUtil';
import { SimpleEvent } from '../../../types';

export default class CardSelect extends Component<CardSelectProps> {
  static defaultProps: Partial<CardSelectProps> = {
    value: '',
    className: '',
    isRequired: false,
    allowEmpty: false,
    onChange: noop,
    disabled: false,
    displayPrompt: true,
    alwaysShowLabel: false,
  };

  onChange = (e: Item) => {
    const event = getEventFromTarget({ target: e } as SimpleEvent, this.props.id);
    this.props.onChange!(event);
  };

  static emptyHeader = (showLabel: boolean | undefined, header: JSX.Element) => {
    if (!showLabel) {
      return <div className="skeleton" />;
    }
    return (
      <div>
        {header}
        <div className="skeleton" />
      </div>
    );
  };

  render() {
    const {
      value,
      className,
      items,
      label,
      initialSelected,
      allowEmpty,
      isRequired,
      disabled,
      displayPrompt,
      errorMessage,
      tooltip,
      alwaysShowLabel,
    } = this.props;

    // cloned to allow prevent mutation of original
    const itemsClone = [...items];
    if (!isRequired) {
      // moves 'none' value to top of drop down list
      itemsClone.unshift({
        label: 'None',
        key: 'emptyValueKey',
        value: { url: null },
        isNone: true,
      });
    }
    const selected = itemsClone.find(item => {
      const itemsMatch = JSON.stringify(item.value) === JSON.stringify(value);
      const itemUrlsMatch = value?.url && JSON.stringify(item.value.url) === JSON.stringify(value.url!);
      const itemIdsMatch = value?.id && JSON.stringify(item.value.id) === JSON.stringify(value.id!);
      return itemsMatch || itemUrlsMatch || itemIdsMatch;
    });
    type GroupedItem = Item & { group?: string };
    type Grouped = { [group: string]: GroupedItem[] };
    const groups = (itemsClone as GroupedItem[]).reduce<Grouped>((all, cur) => {
      const groupname = cur?.group ?? '^ungrouped';
      if (!all[groupname]) {
        all[groupname] = [];
      }

      all[groupname].push(cur);
      return all;
    }, {});
    const renderHeader = (
      <div className={styles.labelWithTooltip}>
        <Label isRequired={this.props.isRequired} className={disabled ? styles.labelDisabled : ''}>
          {label}
        </Label>
        {tooltip && (
          <div className={styles.tooltipSpacer}>
            <Tooltip text={tooltip}>
              <span className="icon-help" />
            </Tooltip>
          </div>
        )}
      </div>
    );

    return (
      <div className={className}>
        {(value || itemsClone.length || allowEmpty)
          ? (
            <div>
              {renderHeader}
              <div
                className={styles.dropDownListDiv}
                data-test={this.props.dataTest}
              >
                {Object.keys(groups).length > 1 ? (
                  <Select
                    className={styles.selectContainer}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      style: {
                        maxHeight: '450px',
                        width: '100%',
                        zIndex: 1501,
                        paddingTop: 0,
                        paddingBottom: 0,
                        marginTop: 5,
                      },
                    }}
                    onChange={e => {
                      const matchingItem = items.find(i => i.label === e.target.value);
                      if (matchingItem) {
                        this.onChange(matchingItem);
                      }
                    }}
                    inputProps={{
                      className: styles.select,
                    }}
                    value={selected || initialSelected}
                    renderValue={(item: any) => {
                      if (!item) {
                        return 'Select an item...';
                      }
                      return <p>{item.value}</p>;
                    }}
                  >
                    {Object.keys(groups).filter(key => key !== '^ungrouped' && groups[key].length > 0).map(key => [
                      (
                        <ListSubheader key={`group-header-${key}`} className={styles.groupHeader} disableSticky>
                          {key}
                        </ListSubheader>
                      ),
                      groups[key].map(item => (
                        <MenuItem
                          key={`group-item-${key}-${item.value}`}
                          className={styles.groupItem}
                          value={item.value}
                        >
                          {item.label}
                        </MenuItem>
                      )),
                    ])}
                  </Select>
                )
                  : (
                    <DropDownList
                      items={itemsClone}
                      initialSelected={selected || initialSelected}
                      updateSelected={this.onChange}
                      disabled={disabled}
                      displayPrompt={displayPrompt}
                      hasError={!!errorMessage}
                      errorMessage={errorMessage ? [errorMessage] : []}
                    />
                  )}
              </div>
              {this.props.subLabel && <Label className={styles.subLabel}>{this.props.subLabel}</Label>}
            </div>
          )
          : CardSelect.emptyHeader(alwaysShowLabel, renderHeader)}
      </div>
    );
  }
}

type Item = DropdownItem<any>;

export interface CardSelectProps {
  id: string;
  // eslint-disable-next-line react/forbid-prop-types
  value?: any;
  className?: string;
  onChange?: (item: Item) => void;
  label: string;
  subLabel?: string;
  isRequired?: boolean;
  initialSelected: Item;
  items: Item[];
  dataTest: string;
  allowEmpty?: boolean;
  disabled?: boolean;
  displayPrompt?: boolean;
  errorMessage?: string;
  tooltip?: string;
  alwaysShowLabel?: boolean;
}
