import React from 'react';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import {
  CellInfo,
  RowInfo,
  ColumnProjection,
  GetColumnsProps,
} from './ticketListConfigurations.types';
import styles from '../TicketList.module.css';
import voidedTicket from '../../../../assets/img/voidedTicket.png';

export default function getColumns(props: GetColumnsProps): ColumnProjection[] {
  const { cellStyle, columnWidths, headerStyle } = props;

  const voidedAccessor = (isVoided?: boolean, content?: any) => {
    if (!isVoided) {
      return `${content}`;
    }

    return (
      <div className={styles.voidedCellStyle}>
        {`${content}`}
      </div>
    );
  };

  const ticketNumberAccessor = (row: RowInfo) => (
    <div className={row.voided ? styles.voidedCellStyle : undefined}>
      {`${row.ticketNumber}`}
      {row.voided && (
        <img src={voidedTicket} alt="voided" />
      )}
    </div>
  );

  const ticketedTimestampAccessor = (row: RowInfo) => voidedAccessor(row?.voided, new DateWrapper(row.ticketedTimestamp).toUSDateString());

  const truckAliasAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row.externalInfo?.truckAlias ?? '');

  const driverNameAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row?.externalInfo?.driverName ?? '');

  const driverNameCell = (cellInfo: CellInfo) => cellInfo.value;

  const tsluAccessor = (row: RowInfo) => voidedAccessor(
    row?.voided,
    (row ? props.getSecondsSinceLastUpdate!(row, props.positions) : 0)
  );

  const tsluCell = (cellInfo: CellInfo) => (
    cellInfo?.value ? props.renderTimeSinceLastUpdate!(Number(cellInfo.value)) : '');

  const orderNumberAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row?.order?.orderNumber ?? 0);

  const customerAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row?.order?.customerName ?? '');

  const projectAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row?.order?.projectName ?? '');

  const orderNumberCell = (cellInfo: CellInfo) => (
    cellInfo && cellInfo.original ? props.orderDetails!(cellInfo.original) : '');

  const addressAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row.address ?? '');

  const loadingPlantAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row.externalInfo?.plantName ?? '');

  const widthConfigurationsMap: { [key: string]: number } = columnWidths
    .reduce((accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.id]: currentValue.value,
    }), {});

  let tickets = [
    {
      Header: 'Ticket #',
      id: 'ticketNumber',
      accessor: ticketNumberAccessor,
      fixed: 'left' as const,
      style: cellStyle,
      width: widthConfigurationsMap.ticketNumCurrent || 110,
      headerStyle,
    },
    {
      Header: 'Date',
      id: 'ticketedTimestamp',
      accessor: ticketedTimestampAccessor,
      fixed: 'left' as const,
      style: cellStyle,
      width: widthConfigurationsMap.date || 120,
      headerStyle,
    },
    {
      Header: 'Truck',
      id: 'truckAlias',
      accessor: truckAliasAccessor,
      className: 'vehicle-cell',
      headerClassName: 'vehicle-header',
      // fixed: 'left',
      width: widthConfigurationsMap.truckAliasCurrent || 145,
      style: cellStyle,
      headerStyle,
    },
    {
      Header: 'Current/Last Driver',
      id: 'driverName',
      accessor: driverNameAccessor,
      Cell: driverNameCell,
      className: 'driver-cell',
      headerClassName: 'driver-header',
      width: widthConfigurationsMap.driverFullName || 160,
      style: cellStyle,
      headerStyle,
    },
    {
      Header: 'Last Tablet Update',
      id: 'tslu',
      accessor: tsluAccessor,
      Cell: tsluCell,
      className: 'time-cell',
      headerClassName: 'time-header',
      style: cellStyle,
      width: widthConfigurationsMap.tslu || 195,
      headerStyle,
    },
    {
      Header: 'Order',
      id: 'orderNumber',
      accessor: orderNumberAccessor,
      Cell: orderNumberCell,
      className: 'order-cell',
      headerClassName: 'order-header',
      style: cellStyle,
      width: widthConfigurationsMap.order || 80,
      headerStyle,
    },
    {
      Header: 'Customer',
      id: 'customerName',
      accessor: customerAccessor,
      className: 'name-cell',
      headerClassName: 'name-header',
      style: cellStyle,
      width: widthConfigurationsMap.customerNameCurrent || 200,
      headerStyle,
    },
    {
      Header: 'Project',
      id: 'ProjectName',
      accessor: projectAccessor,
      className: 'name-cell',
      headerClassName: 'name-header',
      style: cellStyle,
      width: widthConfigurationsMap.projectNameCurrent || 200,
      headerStyle,
    },
    {
      Header: 'Delivery Address',
      id: 'address',
      accessor: addressAccessor,
      className: 'address-cell',
      headerClassName: 'address-header',
      minWidth: 300,
      width: widthConfigurationsMap.addressCurrent || 300,
      style: cellStyle,
      headerStyle,
    },
    {
      Header: 'Loading Plant',
      id: 'loadingPlant',
      accessor: loadingPlantAccessor,
      className: 'ticket-cell',
      headerClassName: 'ticket-header',
      style: cellStyle,
      minWidth: 300,
      width: widthConfigurationsMap.ticketingPlantCurrent || 300,
      headerStyle,
    },
  ];

  // Check the Remove of Last Tablet Update if Is ScaleTrax
  if (props.isScaleTrax === true) tickets = tickets.filter((t => t.id !== 'tslu'));
  return tickets;
}
