import { Dispatch } from 'redux';
import { PlantDto, ProductLine, UserDto } from '@trucktrax/trucktrax-ts-common';
import { getRequest } from './requestService';
import { getUserByUrl } from './usersService';
import { getUserPreferences } from './userPreferencesService';
import recordLog, { devErrorAndLog } from '../util/errorUtil';
import { ERROR_TEXT_FETCH_PLANT, ERROR_TEXT_FETCH_PLANT_LIST } from '../constants/errorConstants';
import { SET_PLANT_LIST, TOGGLE_MAP_FILTER } from '../constants/actionConstants';
import { PLANTS_PATH, GEOZONES_PATH } from '../constants/apiConstants';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { getIdFromUrl } from '../util/appUtil';
import * as plantsActions from '../store/actions/plantsActions';
import { INVALID_STALE_TOKEN_LOGOUT } from '../constants/appConstants';
import HTTP_CODES from '../constants/httpConstants';

export async function getPlantList(
  region?: string,
  productLine?: string,
  isDeleted?: boolean,
  isArchived?: boolean,
  inProductLines?: string
) {
  const url = getGeotraxBaseUrl() + PLANTS_PATH;
  const params: { region?: string, productLine?: string, isDeleted?: boolean, isArchived?: boolean, inProductLines?: string } = {};

  if (region) {
    params.region = region;
  }
  if (productLine) {
    params.productLine = productLine;
  }
  if (inProductLines) {
    params.inProductLines = inProductLines;
  }

  params.isDeleted = !!isDeleted;
  params.isArchived = !!isArchived;
  let response;
  try {
    response = await getRequest(url, params);
  } catch (e: any) {
    recordLog('getPlantList Attempt', url, 'fail');
  }
  return (response && response.data) ? response.data.items : [];
}

export function getClosestPlant(currentPlantId: number, latitude: number, longitude: number, region: string) {
  return async (dispatch: Dispatch) => {
    const url = getGeotraxBaseUrl() + PLANTS_PATH;
    const params = {
      latitude,
      longitude,
      region,
    };
    const fullUrl = `${url}/closest/${currentPlantId}`;
    try {
      const response = await getRequest(fullUrl, params);
      return response.data;
    } catch (e: any) {
      if (e !== INVALID_STALE_TOKEN_LOGOUT) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          e.toString(),
          ERROR_TEXT_FETCH_PLANT,
          e,
          `url: ${url} params: ${JSON.stringify(params)}`,
          consoleOnly
        ) as any);
      }
      return undefined;
    }
  };
}

export function getDefaultPlant(userUrl: string) {
  return async (dispatch: Dispatch) => {
    try {
      const user = await getUserByUrl(userUrl) as UserDto;
      // user assigned plants
      if (!user.plants) {
        return undefined;
      }
      // find the default assigned plant
      const defaultUserPlant = user.plants.find(p => p.default);
      if (!defaultUserPlant) {
        return undefined;
      }
      // get the full DTO
      const url = `${getGeotraxBaseUrl() + PLANTS_PATH}/${getIdFromUrl(defaultUserPlant.url)}`;
      const params = {
        isArchived: false,
      };
      const response = await getRequest(url, params);
      return response.data;
    } catch (e: any) {
      if (e !== INVALID_STALE_TOKEN_LOGOUT) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          e.toString(),
          ERROR_TEXT_FETCH_PLANT,
          e,
          `userurl: ${userUrl}`,
          consoleOnly
        ) as any);
      }
      return undefined;
    }
  };
}

export function setCurrentPlant(plant?: PlantDto) {
  return (dispatch: Dispatch) => {
    dispatch(plantsActions.setCurrentPlant(plant));
  };
}

export function fetchPlantList(region?: string, selectedProductLine?: string, userUrl?: string, inProductLines?: string) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await getPlantList(region, selectedProductLine, undefined, undefined, inProductLines);
      let plants = [];
      // if a user is passed, filter the plants list by plants assigned to the user
      if (userUrl) {
        const user = await getUserByUrl(userUrl) as UserDto;
        // eslint-disable-next-line arrow-body-style
        plants = user.plants && response.filter((el: { url: string | undefined; }) => {
          // eslint-disable-next-line arrow-body-style
          return user.plants!.some((f) => {
            return f.url === el.url;
          });
        });
      } else {
        plants = response;
      }
      const plantFilter = plants ? plants.map((plant: any) => ({
        id: plant.id, name: plant.name, url: plant.url, isSelected: true,
      })) : [];
      dispatch({
        type: TOGGLE_MAP_FILTER,
        payload: { plantList: plantFilter },
      });
      dispatch({
        type: SET_PLANT_LIST,
        payload: plants,
      });
    } catch (e: any) {
      if (e !== INVALID_STALE_TOKEN_LOGOUT) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          e.toString(),
          ERROR_TEXT_FETCH_PLANT_LIST,
          e,
          `region:${region} selectedProductLine: ${selectedProductLine}`,
          consoleOnly
        ) as any);
      }
    }
  };
}

export function fetchPlantListAndMapFilters(region: string, selectedProductLine: ProductLine, userUrl?: string) {
  return async (dispatch: Dispatch) => {
    try {
      if (!userUrl) {
        return;
      }

      // these will be used if we can't find the user's preferences, e.g. on a user's first time using the app
      const defaultFilterValues = {
        showTrucks: true,
        showPlants: false,
        showPlantsGeofences: false,
        showJobsites: false,
        showJobsitesGeofences: false,
        showExtTrucks: true,
      };

      const savedPreferences = await getUserPreferences(userUrl);
      const regionId = getIdFromUrl(region);
      const preferencesForRegionAndProductline = savedPreferences?.mapFilters?.[regionId]?.[selectedProductLine];

      const {
        showTrucks, showPlants, showPlantsGeofences, showJobsites, showJobsitesGeofences, showExtTrucks
      } = preferencesForRegionAndProductline || defaultFilterValues;

      const selectedPlantIds = preferencesForRegionAndProductline && preferencesForRegionAndProductline.selectedPlantIds;

      const plants = await getPlantList(region, selectedProductLine);
      const plantListForMapFilter = plants ? plants.map((plant: any) => ({
        id: plant.id,
        name: plant.name,
        url: plant.url,
        // default to true if we weren't able to find selectedPlantIds
        isSelected: selectedPlantIds ? selectedPlantIds.includes(plant.id) : true,
      })) : [];

      dispatch({
        type: SET_PLANT_LIST,
        payload: plants,
      });
      dispatch({
        type: TOGGLE_MAP_FILTER,
        payload: {
          plantList: plantListForMapFilter,
          showTrucks,
          showPlants,
          showPlantsGeofences,
          showJobsites,
          showJobsitesGeofences,
          showExtTrucks
        }
      });
    } catch (e: any) {
      if (e !== INVALID_STALE_TOKEN_LOGOUT) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          e.toString(),
          ERROR_TEXT_FETCH_PLANT_LIST,
          e,
          `region:${region} selectedProductLine: ${selectedProductLine}`,
          consoleOnly
        ) as any);
      }
    }
  };
}

export function getPlant(id: number, region?: string) {
  return async (dispatch: Dispatch) => {
    const url = `${getGeotraxBaseUrl() + PLANTS_PATH}/${id}`;
    const params: any = { isArchived: false };

    if (region) {
      params.regionUrl = region;
    }

    try {
      const response = await getRequest(url, params);
      return response.data;
    } catch (e: any) {
      if (e !== INVALID_STALE_TOKEN_LOGOUT) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          e.toString(),
          ERROR_TEXT_FETCH_PLANT,
          e,
          `url: ${url} params: ${JSON.stringify(params)}`,
          consoleOnly
        ) as any);
      }
      return undefined;
    }
  };
}

export const fetchPlant = async (
  id: number
): Promise<PlantDto | undefined> => {
  const url = `${getGeotraxBaseUrl() + PLANTS_PATH}/${id}`;
  try {
    const response = await getRequest(url);
    return response ? response.data : undefined;
  } catch (e) {
    if (e !== INVALID_STALE_TOKEN_LOGOUT) {
      throw e;
    }
  }
  return undefined;
};

export async function fetchGeozoneList(
  region?: string,
  isDeleted?: boolean,
  isArchived?: boolean,
  inProductLines?: string
) {
  const url = getGeotraxBaseUrl() + GEOZONES_PATH;
  const params: { region?: string, productLine?: string, isDeleted?: boolean, isArchived?: boolean, inProductLines?: string } = {};

  if (region) {
    params.region = region;
  }
  if (inProductLines) {
    params.inProductLines = inProductLines;
  }

  params.isDeleted = !!isDeleted;
  params.isArchived = !!isArchived;
  let response;
  try {
    response = await getRequest(url, params);
  } catch (e: any) {
    recordLog('getGeozoneList Attempt', url, 'fail');
  }
  return (response && response.data) ? response.data.items : [];
}

export function setPlantList(plants: PlantDto[]) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_PLANT_LIST,
      payload: plants,
    });
  };
}
