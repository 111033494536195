import {
  AssignedPermissionDto,
  GroupDto,
  PermissionAccess,
  PermissionDto,
} from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import {
  FETCH_ASSIGNED_PERMISSIONS,
  FETCH_PERMISSION_GROUPS,
  FETCH_PERMISSIONS,
  SET_ADMIN_PERMISSION_ACCESS,
  SET_USER_PERMISSION_LIST,
} from '../../constants/actionConstants';
import { RegionAccessMap } from '../../util/permissionUtil';

export const userPermissionReducer = (
  state: RegionAccessMap = {},
  action: PayloadAction<RegionAccessMap, 'SET_USER_PERMISSION_LIST'>
): RegionAccessMap => {
  switch (action.type) {
    case SET_USER_PERMISSION_LIST:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

interface SetAdminAccess {
  result: PermissionAccess;
  adminPermission: string;
}

export const adminPermissionAccessReducer = (
  state: { [category: string]: PermissionAccess } = {},
  action: PayloadAction<SetAdminAccess, 'SET_ADMIN_PERMISSION_ACCESS'>
) => {
  switch (action.type) {
    case SET_ADMIN_PERMISSION_ACCESS:
      if (state[action.payload.adminPermission] === action.payload.result) {
        return state;
      }
      return {
        ...state,
        [action.payload.adminPermission]: action.payload.result,
      };
    default:
      return state;
  }
};

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const permissionsReducer = (
  state = { permissionList: [] as PermissionDto[] },
  action: PayloadAction<PermissionDto[], 'FETCH_PERMISSIONS'>
): { permissionList: PermissionDto[] } => {
  switch (action.type) {
    case FETCH_PERMISSIONS:
      return {
        ...state,
        permissionList: action.payload,
      };
    default:
      return state;
  }
};

export const permissionGroupsReducer = (
  state = { permissionGroupList: [] },
  action: PayloadAction<GroupDto[], 'FETCH_PERMISSION_GROUPS'>
): { permissionGroupList: GroupDto[] } => {
  switch (action.type) {
    case FETCH_PERMISSION_GROUPS:
      return {
        ...state,
        permissionGroupList: action.payload,
      };
    default:
      return state;
  }
};

export const assignedPermissionsReducer = (
  state: { assignedPermissionList: AssignedPermissionDto[] } = { assignedPermissionList: [] },
  action: PayloadAction<AssignedPermissionDto[], 'FETCH_ASSIGNED_PERMISSIONS'>
): { assignedPermissionList: AssignedPermissionDto[] } => {
  switch (action.type) {
    case FETCH_ASSIGNED_PERMISSIONS:
      return {
        ...state,
        assignedPermissionList: action.payload,
      };
    default:
      return state;
  }
};
