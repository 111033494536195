/* eslint-disable no-undef */
import { Dispatch } from 'redux';
import {
  LocationDto, PositionDto, ProductLine, TicketDto, TicketGeozoneDto,
} from '@trucktrax/trucktrax-ts-common';
import { getRequest } from './requestService';
import TrackingSocket from './sockets/trackingSocket';
import {
  setPositionList, setPinMap, setPinRadius, PinLocationInfo,
} from '../store/actions/mapviewActions';
import { UPDATE_POSITION } from '../constants/actionConstants';
import { ERROR_TEXT_FETCH_MARKER_LIST } from '../constants/errorConstants';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { MAPVIEWS_DETAILS_PATH, MAPVIEWS_TRACKED_OBJECT_ACTIVE_PATH } from '../constants/apiConstants';
import { devErrorAndLog } from '../util/errorUtil';
import HTTP_CODES from '../constants/httpConstants';

type GetState = () => {
  currentRegion: {
    url: string
  },
  selectedProductLine: ProductLine
};

export function getMapview(regionUrl?: string) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const url = `${getGeotraxBaseUrl()}${MAPVIEWS_DETAILS_PATH}`;
    const params = {
      region: regionUrl || getState().currentRegion.url,
      isActive: true,
      primaryProductLine: getState()?.selectedProductLine ?? null,
    };
    try {
      const response = await getRequest(url, params);
      dispatch(setPositionList(response.data.items));
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_MARKER_LIST, e, `- regionUrl:${regionUrl}`, consoleOnly) as any);
    }
  };
}

export function getDirections(
  points?: (LocationDto | undefined)[],
  callback?: (result: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus) => void
) {
  if (!points || !points.length || !callback) {
    return;
  }

  const origin = new google.maps.LatLng(points[0]?.latitude ?? 0, points[0]?.longitude ?? 0);
  const destination = new google.maps.LatLng(
    points[points.length - 1]?.latitude ?? 0,
    points[points.length - 1]?.longitude ?? 0
  );
  const inBetween = points.map(w => ({
    location: new google.maps.LatLng(w?.latitude ?? 0, w?.longitude ?? 0),
    stopover: true,
  }));

  const service = new google.maps.DirectionsService();
  service.route({
    origin,
    destination,
    waypoints: inBetween,
    travelMode: google.maps.TravelMode.DRIVING,
  }, callback);
}

export function getDistanceMatrix(
  truckPoint: LocationDto,
  destinationPoint: LocationDto,
  callback: (response: google.maps.DistanceMatrixResponse | null) => void
) {
  const truck = new google.maps.LatLng(truckPoint.latitude ?? 0, truckPoint.longitude ?? 0);
  const destination = new google.maps.LatLng(destinationPoint.latitude ?? 0, destinationPoint.longitude ?? 0);
  const service = new google.maps.DistanceMatrixService();

  service.getDistanceMatrix({
    origins: [truck],
    destinations: [destination],
    travelMode: google.maps.TravelMode.DRIVING,
    drivingOptions: {
      departureTime: new Date(),
      trafficModel: google.maps.TrafficModel.PESSIMISTIC,
    },
    unitSystem: google.maps.UnitSystem.IMPERIAL,
  }, callback);
}

export interface PositionWithTicketGeozone extends PositionDto {
  ticket: {
    geoZone: TicketGeozoneDto;
    url: string;
  }
}

export function updatePositionWithTruckEta(position: PositionWithTicketGeozone) {
  return (dispatch: Dispatch) => {
    const hasLocation = Object.keys(position?.location || {}).length > 0;
    const truckLatLng = hasLocation ? position.location : null;

    const ticket = position?.ticket as TicketDto | undefined;

    const hasTicketGeoZone = ticket?.geoZone?.zone?.circle?.center;
    const destinationLatLng = hasTicketGeoZone ? ticket!.geoZone!.zone!.circle!.center : null;

    if (truckLatLng && destinationLatLng) {
      return getDistanceMatrix(
        truckLatLng,
        destinationLatLng,
        (resp: google.maps.DistanceMatrixResponse | null) => {
          // avoids overwriting status with response status
          const { status, ...rest } = resp!.rows[0].elements[0];
          const payload = { ...position, ...rest };
          dispatch({
            type: UPDATE_POSITION,
            payload,
          });
        }
      );
    }

    return null;
  };
}

export async function isTrackedObjectActive(trackedObjectUrl: string) {
  const url = `${getGeotraxBaseUrl()}${MAPVIEWS_TRACKED_OBJECT_ACTIVE_PATH}`;
  const params = {
    trackedObjectUrl,
  };

  try {
    const response = await getRequest(url, params);
    return response.data;
  } catch (e) {
    return false;
  }
}

export function trackingSocketDisconnect() {
  TrackingSocket.disconnect(true);
}

export function trackingSocketInit(currentRegion: string) {
  trackingSocketDisconnect();
  TrackingSocket.connect(currentRegion);
}

export function activatePinMap(pinInfo: PinLocationInfo) {
  return (dispatch: Dispatch) => {
    dispatch(setPinMap(pinInfo));
  };
}

export function updatePinRadius(radius: string) {
  return (dispatch: Dispatch) => {
    dispatch(setPinRadius(radius));
  };
}
