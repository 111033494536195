import { SET_APP_NAME } from '../../constants/actionConstants';
import { PayloadAction } from '../../types';

export type AppName = 'scaletrax' | 'admin' | 'geotrax';

export const currentAppNameReducer = (state: AppName | null = null, action: PayloadAction<AppName, 'SET_APP_NAME'>) => {
  switch (action.type) {
    case SET_APP_NAME:
      return action.payload;
    default:
      return state;
  }
};
export default currentAppNameReducer;
