/* eslint-disable react/no-this-in-sfc */
// this rule conflicts with the rule 'class methods use this'
// we have to choose one or restructure this component
import React, { Component, ComponentType } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReduxState } from '../../../store';
import { hasAppAccessInAnyRegion } from '../../../util/permissionUtil';
import { NOT_FOUND_PATH } from '../../../constants/apiConstants';
import { TruckTraxApps } from '../../../types';

const EmptyDiv = () => <div />;

export function GetRendered(props: any, hasPermission: boolean, featureFlagAccess: boolean, routes?: RouteInfo[], rest?: any) {
  return (p: any) => {
    const authorized = props.authenticated && featureFlagAccess;
    if (!authorized) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: p.location },
          }}
        />
      );
    }

    if (p.location?.pathname === NOT_FOUND_PATH) {
      return <props.component routes={routes} {...p} {...rest} />;
    }

    if (!hasPermission) {
      return <Redirect to={NOT_FOUND_PATH} />;
    }

    return <props.component routes={routes} {...p} {...rest} />;
  };
}
export class PrivateRoute extends Component<PrivateRouteProps> {
  static defaultProps: Partial<PrivateRouteProps> = {
    featureFlag: '',
    routes: [],
    component: EmptyDiv,
  };

  getFeatureFlagAccess(featureFlag: string) {
    return featureFlag.length === 0
      || (this.props.flags.featuresList as any).features[featureFlag];
  }

  hasAppPermission(): boolean {
    const permissionsActivated = this.props.userPermission && Object.keys(this.props.userPermission).length > 0;
    if (!permissionsActivated) {
      return true;
    }

    if (this.props.currentAppName === 'geotrax') {
      if (!hasAppAccessInAnyRegion(TruckTraxApps.GeoTrax, this.props.userPermission)) {
        return false;
      }
    }

    if (this.props.currentAppName === 'scaletrax') {
      if (!hasAppAccessInAnyRegion(TruckTraxApps.ScaleTrax, this.props.userPermission)) {
        return false;
      }
    }

    return true;
  }

  render() {
    const {
      component, featureFlag, routes, ...rest
    } = this.props;
    const featureFlagAccess = this.getFeatureFlagAccess(featureFlag);
    const rendered = GetRendered(this.props, this.hasAppPermission(), featureFlagAccess, routes, rest);
    return (
      <Route
        render={rendered}
      />
    );
  }
}

type RouteInfo = {
  path: string;
  component: JSX.Element;
};

function mapStateToProps(state: ReduxState) {
  return {
    authenticated: state.auth.authenticated,
    flags: state.flags,
    currentAppName: state.currentAppName,
    userPermission: state.userPermission,
  };
}
type PrivateRouteReduxProps = ReturnType<typeof mapStateToProps>;
type PrivateRouteOwnProps = {
  path: string;
  featureFlag: string;
  routes: RouteInfo[];
  component: ComponentType<any>;
};
export type PrivateRouteProps = PrivateRouteOwnProps & PrivateRouteReduxProps;

export default connect(mapStateToProps)(PrivateRoute);
