import React, { Component } from 'react';
import { Tooltip } from '@trucktrax/trucktrax-common';
import { UserPreferencesDto } from '@trucktrax/trucktrax-ts-common';
import styles from './TrafficBtn.module.css';
import trafficLayerEnabledHoverSvg from '../../../assets/img/traffic-layer-enabled-hover.svg';
import trafficLayerEnabledSvg from '../../../assets/img/traffic-layer-enabled.svg';
import trafficLayerHiddenHoverSvg from '../../../assets/img/traffic-layer-hidden-hover.svg';
import trafficLayerHiddenSvg from '../../../assets/img/traffic-layer-hidden.svg';
import { getUserPreferences, updateUserPreferences } from '../../../services/userPreferencesService';
import { getIdFromUrl } from '../../../util/appUtil';

export class TrafficBtn extends Component<TrafficBtnProps, TrafficBtnState> {
  constructor(props: TrafficBtnProps) {
    super(props);
    let trafficMouseOutImage = trafficLayerHiddenSvg;
    let trafficImage = trafficLayerHiddenSvg;
    let trafficHoverImage = trafficLayerHiddenHoverSvg;
    let tooltip = 'Show Traffic Layer';

    if (this.props.colorImg) {
      trafficHoverImage = trafficLayerEnabledHoverSvg;
      trafficMouseOutImage = trafficLayerEnabledSvg;
      trafficImage = trafficLayerEnabledSvg;
      tooltip = 'Hide Traffic Layer';
    }
    this.state = {
      trafficImage,
      trafficHoverImage,
      trafficMouseOutImage,
      tooltip,
    };
  }

  setTrafficLayerState = (isEnabled: boolean) => {
    const trafficImage = isEnabled ? trafficLayerEnabledSvg : trafficLayerHiddenSvg;
    const trafficHoverImage = isEnabled ? trafficLayerEnabledHoverSvg : trafficLayerHiddenHoverSvg;
    const trafficMouseOutImage = isEnabled ? trafficLayerEnabledSvg : trafficLayerHiddenSvg;
    const tooltip = isEnabled ? 'Hide Traffic Layer' : 'Show Traffic Layer';

    this.setState({
      trafficImage,
      trafficHoverImage,
      trafficMouseOutImage,
      tooltip,
    });
  };

  saveTrafficLayerState = async (viewLiveTraffic: boolean) => {
    const userPreferences = await getUserPreferences(this.props.userUrl);
    if (!userPreferences) {
      return;
    }

    const currentRegionId = getIdFromUrl(this.props.currentRegion);
    const currentProductLine = this.props.selectedProductLine;

    const existingPreferences = { ...userPreferences };
    const existingMapFilters = { ...existingPreferences.mapFilters };
    const existingMapFiltersForRegion = existingMapFilters[currentRegionId] || {};
    const existingMapFiltersForRegionandProductLine = existingMapFiltersForRegion[currentProductLine] || {};

    // ensure we don't overwrite map filters for combinations of region & product line other than the current one
    // also ensure we don't overwrite preferences other than map filters, if/when we add more preferences
    const updatedPreferences: UserPreferencesDto = {
      ...existingPreferences,
      mapFilters: {
        ...existingMapFilters,
        [currentRegionId]: {
          ...existingMapFiltersForRegion,
          [currentProductLine]: {
            ...existingMapFiltersForRegionandProductLine,
            viewLiveTraffic
          }
        },
      }
    };

    await updateUserPreferences(this.props.userUrl, updatedPreferences);
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const isEnabled = this.state.trafficImage === trafficLayerHiddenHoverSvg;
    this.saveTrafficLayerState(isEnabled);
    this.setTrafficLayerState(isEnabled);
    this.props.onClick(event, isEnabled);
  };

  getTrafficButton = () => (
    <div
      className={styles.trafficButtonContainer}
    >
      <Tooltip
        text={this.state.tooltip}
        placement="left"
      >
        <button
          className={styles.trafficButton}
          onClick={this.handleClick}
        >
          <img
            alt="Traffic"
            onMouseOver={this.mouseOver}
            onMouseOut={this.mouseOut}
            onFocus={this.mouseOver}
            onBlur={this.mouseOut}
            src={this.state.trafficImage}
          />
        </button>
      </Tooltip>
    </div>
  );

  mouseOver = () => {
    this.setState({ trafficImage: this.state.trafficHoverImage });
  };

  mouseOut = () => {
    this.setState({ trafficImage: this.state.trafficMouseOutImage });
  };

  render() {
    return this.getTrafficButton();
  }
}

export default TrafficBtn;

export interface TrafficBtnProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, isEnabled: boolean) => void;
  colorImg: boolean;
  userUrl: string;
  currentRegion: string;
  selectedProductLine: string;
}
export interface TrafficBtnState {
  trafficImage: string;
  trafficHoverImage: string;
  trafficMouseOutImage: string;
  tooltip: string;
}
