import React, { Component } from 'react';
import cx from 'classnames';
import { FloatingActionButton } from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import {
  UnitOfMeasure,
  VehicleTypeDto,
  VoidFunc,
} from '@trucktrax/trucktrax-ts-common';
import { onSubmitSuccess } from '../../../util/adminUtil';
import { createDataTableRecord } from '../../../services/dataTableService';
import AdminAddModal from '../../shared/admin/AdminAddModal';
import styles from './VehicleTypesAddModal.module.css';
import { ADD_VEHICLE_TYPE_ERROR_MESSAGE } from '../../../constants/errorConstants';
import {
  ADD_VEHICLE_TYPE_BUTTON_TEXT,
  ADD_VEHICLE_TYPE_MODAL_TITLE,
  ADMIN_KEYS,
  ADMIN_LABELS,
  DROPDOWN_FORM,
  INPUT_FORM,
  NUMERIC_INPUT_FORM,
} from '../../../constants/appConstants';
import { VEHICLE_TYPES_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ADD_VEHICLE_TYPE_SUCCESS_TEXT } from '../../../constants/successConstants';
import { getUnitOfMeasureLabel, noop } from '../../../util/appUtil';
import AddFeatureModal from '../../shared/AddFeatureModal';
import { checkNullInRequired } from '../../../util/validation';

export class VehicleTypesAddModal extends Component<VehicleTypesAddModalProps, VehicleTypesAddModalState> {
  constructor(props: VehicleTypesAddModalProps) {
    super(props);
    this.state = {
      currentUom: UnitOfMeasure[UnitOfMeasure.POUNDS],
    };
  }

  static unitOfMeasures = [
    {
      label: getUnitOfMeasureLabel(UnitOfMeasure.POUNDS),
      key: UnitOfMeasure[UnitOfMeasure.POUNDS],
      value: UnitOfMeasure[UnitOfMeasure.POUNDS],
    },
    {
      label: getUnitOfMeasureLabel(UnitOfMeasure.SHORT_TONS),
      key: UnitOfMeasure[UnitOfMeasure.SHORT_TONS],
      value: UnitOfMeasure[UnitOfMeasure.SHORT_TONS],
    },
    {
      label: getUnitOfMeasureLabel(UnitOfMeasure.KILOGRAMS),
      key: UnitOfMeasure[UnitOfMeasure.KILOGRAMS],
      value: UnitOfMeasure[UnitOfMeasure.KILOGRAMS],
    },
    {
      label: getUnitOfMeasureLabel(UnitOfMeasure.METRIC_TONS),
      key: UnitOfMeasure[UnitOfMeasure.METRIC_TONS],
      value: UnitOfMeasure[UnitOfMeasure.METRIC_TONS],
    },
  ];

  onSubmit = (dto: VehicleTypeDto, onError: any, onSuccess: any) => {
    if (dto.maxWeight) {
      dto.maxWeight = Math.trunc(dto.maxWeight * 100) / 100;
    }
    const { createAdminTableData: post } = this.props;
    const toastMessages = {
      success: ADD_VEHICLE_TYPE_SUCCESS_TEXT,
      fail: ADD_VEHICLE_TYPE_ERROR_MESSAGE,
    };

    const url = getGeotraxBaseUrl() + VEHICLE_TYPES_PATH;
    const currentRegionUrl = this.props.currentRegion.url;

    const vehicleTypeDto = {
      ...dto,
      region: { url: this.props.currentRegion.url },
    };
    const callbacks = [this.props.discardAcceptAction, onSuccess];
    post(url, vehicleTypeDto, () => onSubmitSuccess(callbacks), onError, toastMessages, true, currentRegionUrl);
  };

  uomAccessor = (row?: VehicleTypeDto) => {
    if (!row?.unitOfMeasure) {
      return null;
    }

    const { currentUom } = this.state;
    const rowUom = `${row.unitOfMeasure}`;

    if (!currentUom) {
      this.setState({ currentUom: rowUom });
      return rowUom;
    }

    if (rowUom === currentUom) {
      return rowUom;
    }

    this.setState({ currentUom: rowUom });

    row.maxWeight = 0;
    return rowUom;
  };

  config = () => [{
    type: INPUT_FORM,
    key: ADMIN_KEYS.NAME,
    label: `${ADMIN_LABELS.VEHICLE_TYPE} ${ADMIN_LABELS.NAME}`,
    maxLength: 50,
    dataTest: 'name-input-data-test',
    errorDataTest: 'name-input-missing-error',
    className: cx('widthSpace', 'baseMargin', styles.name),
    customValidation: checkNullInRequired,
    isRequired: true,
  }, {
    id: 'maxWeight',
    type: NUMERIC_INPUT_FORM,
    key: ADMIN_KEYS.MAXWEIGHT,
    label: ADMIN_LABELS.GROSS_MAX_WEIGHT,
    dataTest: 'maxWeight-input-data-test',
    errorDataTest: 'maxWeight-input-missing-error',
    className: styles.maxWeight,
    maxLength: 12,
    allowDecimal: true,
    thousandSeparator: true,
    customValidation: checkNullInRequired,
    isRequired: true,
  },
  {
    type: DROPDOWN_FORM,
    className: styles.uomDropDown,
    label: ADMIN_LABELS.DEFAULT_SCALE_UNIT,
    key: ADMIN_KEYS.UNIT_OF_MEASURE,
    accessor: this.uomAccessor,
    initialSelected: VehicleTypesAddModal.unitOfMeasures[0],
    items: VehicleTypesAddModal.unitOfMeasures,
    isRequired: true,
    dataTest: 'region-input-data-test',
  }];

  render() {
    return (
      <div>
        <FloatingActionButton onClick={this.props.openModal} />
        <AddFeatureModal
          title={ADD_VEHICLE_TYPE_MODAL_TITLE}
          isOpen={this.props.isOpen}
          onCancel={this.props.closeModal}
          style={styles.vehicleTypesAddModal}
        >
          <AdminAddModal
            config={this.config()}
            addButtonText={ADD_VEHICLE_TYPE_BUTTON_TEXT}
            addButtonDataTest="trucks-add-button-data-test"
            onRightBtnClick={this.onSubmit}
            discardAcceptAction={this.props.discardAcceptAction}
            discardRejectAction={this.props.discardRejectAction}
            isCloseRequest={this.props.isCloseRequest}
          />
        </AddFeatureModal>
      </div>
    );
  }
}

export interface VehicleTypesAddModalProps {
  createAdminTableData: typeof createDataTableRecord,
  discardAcceptAction: typeof noop,
  discardRejectAction: typeof noop,
  closeModal: VoidFunc,
  openModal: VoidFunc,
  currentRegion: { url: string },
  isCloseRequest: boolean,
  isOpen: boolean
}

export interface VehicleTypesAddModalState {
  currentUom: string
}

const mapStateToProps = (state: any) => ({
  currentRegion: state.currentRegion,
});

export default connect<any, any, any>(mapStateToProps, {
  createAdminTableData: createDataTableRecord,
})(VehicleTypesAddModal);
