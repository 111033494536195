import { VoidFunc } from '@trucktrax/trucktrax-ts-common';

interface TabNavigationEvent {
  keyCode: number,
  preventDefault: VoidFunc
}

export const disableTabNavigation = () => {
  const listener = (e: TabNavigationEvent) => {
    const tabKeyCode = 9;
    if (e.keyCode === tabKeyCode) e.preventDefault();
  };

  window.document.addEventListener('keydown', listener);
  return listener;
};

export const enableTabNavigation = (listener: (this: Document, ev: KeyboardEvent) => any) => {
  window.document.removeEventListener('keydown', listener);
};
