import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { vars } from '@trucktrax/trucktrax-common';
import { PermissionAccess } from '@trucktrax/trucktrax-ts-common';
import styles from './GeozoneDetailView.module.css';
import { getRequest } from '../../../services/requestService';
import AdminCard from '../../shared/admin/AdminCard';
import { updateDataTableRecord } from '../../../services/dataTableService';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  CIRCLE,
  EDIT_BTN,
  INPUT_FORM,
  MAP_FORM,
  OR,
  POLYGON,
  LOCATION,
  SAVE_BTN,
  TEXT_DISPLAY,
} from '../../../constants/appConstants';
import { devErrorAndLog } from '../../../util/errorUtil';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { GEOZONES_PATH } from '../../../constants/apiConstants';
import { PLANTS_TEXT } from '../../../constants/navConstants';
import { CARD_MAP_HEIGHT } from '../../../constants/mapConstants';
import HTTP_CODES from '../../../constants/httpConstants';

export class GeozoneDetailView extends Component<GeozoneDetailViewProps> {
  static defaultProps = {
    currentRegion: {
      url: '',
    },
  };

  state = {
    edit: false,
    // eslint-disable-next-line react/no-unused-state
    geoFenceData: {
      zone: {
        polygon: {},
        circle: {},
      },
    },
  };

  baseUrl = () => `${getGeotraxBaseUrl() + GEOZONES_PATH}/${this.props.match.params.id}`;

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  save = (dto: any, onSuccess: any, onError: any) => {
    const currentRegionUrl = this.props.currentRegion.url;
    const onSaveSuccess = (updatedDto: any) => {
      this.setState({ edit: false });
      onSuccess(updatedDto);
    };

    dto.zone = {
      circle: dto.circle,
      polygon: dto.polygon,
    };
    this.props.updateAdminTableData(this.baseUrl(), dto, onSaveSuccess, onError, false, currentRegionUrl);
  };

  static setGeoFenceData = (data: { circle: any; zone: { circle: any; polygon: any; }; polygon: any; }) => {
    // Copy zone.circle/polygon to immediate circle and polygon attribute for edit functionality
    data.circle = data.zone?.circle;
    data.polygon = data.zone?.polygon;
    return data;
  };

  fetchCustomGeozone = async () => {
    const params = {
      region: this.props.currentRegion.url,
    };

    try {
      const response = await getRequest(`${this.baseUrl()}`, params);
      const { zone } = response.data;
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        geoFenceData: {
          zone,
        },
      });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      this.props.devErrorAndLog('Unable to retrieve geozone', `GeozoneDetailView ${e.toString()}`, undefined, undefined, consoleOnly);
    }
  };

  getConfig = () => {
    const readConfigWithoutGeozoneEditPermission = [
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.NAME,
        id: ADMIN_KEYS.NAME,
        accessor: ADMIN_KEYS.NAME,
        className: cx(styles.name, 'large'),
        dataTest: 'geozone-drilldown-name',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.DESCRIPTION,
        accessor: ADMIN_KEYS.DESCRIPTION,
        className: cx(styles.description),
        dataTest: 'geozone-drilldown-description',
      },
      {
        type: MAP_FORM,
        label: ADMIN_LABELS.GEOZONE,
        className: styles.map,
        dataTest: 'geozone-drilldown',
        accessor: (x: any) => x,
        isEditable: false,
        showGeoZone: false,
      },
    ];
    const readConfigWithGeozoneEditPermission = [
      ...readConfigWithoutGeozoneEditPermission,
      {
        type: EDIT_BTN,
        name: 'Edit Geozone',
        iconClassName: 'icon-create',
        className: cx(styles.editBtn, 'large'),
        dataTest: 'geozone-drilldown-edit',
      },
    ];

    // this configuration will be useful when editing
    const editConfig = [
      readConfigWithoutGeozoneEditPermission[0],
      {
        type: INPUT_FORM,
        accessor: ADMIN_KEYS.NAME,
        className: cx(styles.nameEdit),
        dataTest: 'geozone-drilldown-name',
        key: ADMIN_KEYS.NAME,
        label: ADMIN_LABELS.NAME,
        maxLength: 25,
        errorDataTest: 'geozone-name-input-missing-error',
        isRequired: true,
      },
      {
        type: INPUT_FORM,
        accessor: ADMIN_KEYS.DESCRIPTION,
        className: cx(styles.descriptionEdit),
        dataTest: 'geozone-drilldown-description',
        key: ADMIN_KEYS.DESCRIPTION,
        label: ADMIN_LABELS.DESCRIPTION,
        maxLength: 50,
        errorDataTest: 'geozone-description-input-missing-error',
        isRequired: false,
      },
      {
        type: MAP_FORM,
        key: ADMIN_LABELS.GEOZONE,
        label: ADMIN_LABELS.GEOZONE,
        isEditable: true,
        showMarker: true,
        resetBtn: true,
        showGeoZone: true,
        accessor: (x: any) => x,
        className: styles.mapEdit,
        containerStyle: {
          height: CARD_MAP_HEIGHT,
          width: '100%',
          position: 'relative',
          border: `1px solid ${vars.gray200}`,
        },
      },
      {
        type: SAVE_BTN,
      }];

    if (this.props.geozonePermissionAccess !== PermissionAccess.Edit) {
      return readConfigWithoutGeozoneEditPermission;
    }

    if (!this.state.edit) {
      return readConfigWithGeozoneEditPermission;
    }

    return editConfig;
  };

  render() {
    const { location } = this.props;
    return (
      <AdminCard
        edit={this.state.edit}
        url={this.baseUrl()}
        options={{
          requiredKeys: [{
            keys: [CIRCLE, POLYGON, LOCATION],
            relationship: OR,
          }],
          callback: this.fetchCustomGeozone,
          alterData: GeozoneDetailView.setGeoFenceData,
        }}
        onToggleEdit={this.toggleEdit}
        save={this.save}
        config={this.getConfig()}
        className={cx(
          styles.wrapper,
          this.state.edit && styles.editWrapper
        )}
        pathName={location.pathname}
        headerAccessor="name"
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  geozonePermissionAccess: state.adminPermissionAccess[PLANTS_TEXT],
  currentRegion: state.currentRegion,
});

interface CurrentRegion {
  url: string;
}
interface Location {
  pathname: string;
}
interface Match {
  params: { id: string };
}
export interface GeozoneDetailViewProps {
  updateAdminTableData: typeof updateDataTableRecord,
  devErrorAndLog: typeof devErrorAndLog,
  geozonePermissionAccess?: string,
  match: Match,
  currentRegion: CurrentRegion,
  location: Location
}
export default connect<any, any, any>(mapStateToProps, {
  updateAdminTableData: updateDataTableRecord,
  devErrorAndLog,
})(GeozoneDetailView);
