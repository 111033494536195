import React, { Component } from 'react';
import Downshift from 'downshift';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import cx from 'classnames';
import styles from './AutoCompleteWithChip.module.css';

class AutoCompleteWithChip extends Component<AutoCompleteWithChipProps> {
  static defaultProps = {
    availableItems: [],
    onRemoveItem: () => { },
  };

  popoverInputNode: any;

  componentDidMount() {
    const hasNoParties = this.props.partiesList.length === 0;
    const hasInputNode = this.popoverInputNode;
    if (hasNoParties && hasInputNode) {
      this.popoverInputNode!.focus();
    }
  }

  // eslint-disable-next-line max-len
  static getSuggestions = (inputValue: string, itemList: DataItem[]) => itemList.filter(item => item.fullName.toLowerCase().includes(inputValue.toLowerCase()));

  renderChipList = (onRemoveItem: (item: any) => void) => (
    <div>
      {this.props.partiesList.length > 0
          && this.props.partiesList.map((item) => (
            <Chip
              data-test="chip-driver-name"
              key={`${item.url}${item.fullName}${item.id}`}
              classes={
                {
                  root: styles.chip,
                  label: styles.chipLabel,
                }
              }
              label={item.fullName}
              deleteIcon={(
                <div
                  data-test="chip-cancel-individual"
                  className={styles.chipRemove}
                >
                  <i
                    aria-hidden="true"
                    className="icon-close"
                  />
                </div>
              )}
              onDelete={() => onRemoveItem!(item)}
            />
          ))}
    </div>
  );

  renderSuggestion = (params: any) => {
    const {
      item,
      itemProps,
      selectedItem,
    } = params;
    const isSelected = selectedItem.indexOf(item.fullName) > -1;
    const isProductLineMenuItem = item.url === this.props.selectedProductLine;

    return (
      !isSelected && (
        <MenuItem
          {...itemProps}
          key={`${item.url}${item.fullName}${item.id}`}
          component="div"
          className={isProductLineMenuItem ? styles.productLineMenuItem : styles.menuItem}
        >
          <div className={styles.menuItemBodyContainer}>
            {isProductLineMenuItem
              && (
                <div
                  data-test="menu-product-line-icon"
                  className={styles.suggestedMenuItemIcon}
                >
                  <i
                    aria-hidden="true"
                    className="icon-people"
                  />
                </div>
              )}
            {item.fullName}
          </div>
        </MenuItem>
      )
    );
  };

  renderInput = (inputProps: any) => {
    const { InputProps, ref, availableItems } = inputProps;
    const allItemSelected = availableItems.length === 0;

    return (
      <TextField
        fullWidth
        label={
          allItemSelected ? '\u00A0\u00A0\u00A0\u00A0No more drivers to add' : ''
        } // \u00A0 represents the Unicode character for a non-breaking space
        disabled={allItemSelected}
        InputProps={{
          inputRef: ref,
          classes: {
            root: cx(
              styles.inputRoot,
              this.props.partiesList.length === 0 && styles.inputIndent
            ),
          },
          ...InputProps,
        }}
      />
    );
  };

  render() {
    const { availableItems, onRemoveItem, ...rest } = this.props;

    return (
      <div className={styles.autoCompleteWithChipContainer}>
        <Downshift
          itemToString={item => (item ? item.fullName : '')}
          {...(rest as any)}
        >
          {({
            getInputProps,
            getItemProps,
            inputValue,
            selectedItem,
            highlightedIndex,
            openMenu,
            closeMenu,
            isOpen,
          }) => (
            <div data-test="message-center-to-field">
              {this.renderChipList(onRemoveItem!)}
              {this.renderInput({
                availableItems,
                InputProps: {
                  ...getInputProps({
                    onClick: () => {
                      if (!isOpen) {
                        openMenu();
                      }
                    },
                    onBlur: () => closeMenu(),
                    onFocus: () => openMenu(),
                  }),
                },
                ref: (node: any) => {
                  this.popoverInputNode = node;
                },
              })}

              <Popper
                id="simple-popper"
                open={isOpen}
                anchorEl={this.popoverInputNode}
                className={styles.popper}
              >
                <Paper
                  data-test="message-recipient-dropdown-item"
                  square
                  classes={
                    {
                      root: styles.popperPaper,
                    }
                  }
                  style={
                    {
                      width: this.popoverInputNode ? this.popoverInputNode.clientWidth : null,
                    }
                  }
                >
                  {
                    AutoCompleteWithChip.getSuggestions(inputValue!, availableItems!).map((item, index) => this.renderSuggestion({
                      item,
                      index,
                      itemProps: getItemProps({
                        item,
                      }),
                      highlightedIndex,
                      selectedItem,
                    }))
                  }
                </Paper>
              </Popper>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

export default AutoCompleteWithChip;

export interface AutoCompleteWithChipProps {
  availableItems?: Array<DataItem>;
  onRemoveItem?: (item: any) => void;
  partiesList: Array<any>;
  onInputValueChange: (inputVal: string) => void;
  inputValue: string;
  selectedItem: DataItem[];
  onChange: (selectedItem: DataItem) => void;
  selectedProductLine?: string;
}

export interface DataItem {
  fullName: string;
  id?: number;
  url?: string;
}
