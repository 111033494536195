export const REFRESH_TOKEN_PENDING = 'REFRESH_TOKEN_PENDING';
export const ADD_ITEM_TO_ADMIN_TABLE_ITEMS = 'ADD_ITEM_TO_ADMIN_TABLE_ITEMS';
export const SET_TABLE_RECORDS = 'SET_TABLE_RECORDS';
export const SET_TABLE_SEARCH_TERM = 'SET_TABLE_SEARCH_TERM';
export const SET_TABLE_DATE_RANGE = 'SET_TABLE_DATE_RANGE';
export const SET_TABLE_NEEDS_REFRESH = 'SET_TABLE_NEEDS_REFRESH';
export const SET_DRIVER_LIST = 'SET_DRIVER_LIST';
export const SET_ROUTE_HISTOROUTE_HISTORY_LIST = 'CLEAN_SELECTED_ROUTE_HISTORY_LIST';
export const SET_ROUTE_HISTORY_LIST = 'SET_ROUTE_HISTORY_LIST';
export const CLEAN_ROUTE_HISTORY_LIST = 'CLEAN_ROUTE_HISTORY_LIST';
export const SET_SELECTED_ROUTE_HISTORY_LIST = 'SET_SELECTED_ROUTE_HISTORY_LIST';
export const CLEAN_SELECTED_ROUTE_HISTORY_LIST = 'CLEAN_SELECTED_ROUTE_HISTORY_LIST';
export const SET_MODAL_OPTIONS = 'SET_MODAL_OPTIONS';
export const SET_MODAL_DISABLED_STATE = 'SET_MODAL_DISABLED_STATE';
export const SET_ALL_FEATUREFLAGS = 'SET_ALL_FEATUREFLAGS';
export const SET_ALL_SUBSCRIPTIONS = 'SET_ALL_SUBSCRIPTIONS';
export const TOGGLE_MAP_FILTER = 'TOGGLE_MAP_FILTER';
export const OPEN_MAP_FILTERS = 'OPEN_MAP_FILTER';
export const RESET_MAP_FILTERS = 'RESET_MAP_FILTERS';
export const OVERRIDE_MAP_FILTER = 'OVERRIDE_MAP_FILTER';
export const FETCH_TABLE_DATA = 'FETCH_TABLE_DATA';
export const SET_POSITION_LIST = 'SET_POSITION_LIST';
export const UPDATE_POSITION = 'UPDATE_POSITION';
export const REFRESH_POSITIONS = 'REFRESH_POSITIONS';
export const SELECT_MARKER = 'SELECT_MARKER';
export const OPEN_POPUP = 'OPEN_POPUP';
export const CLEAR_SELECTED_MARKER = 'CLEAR_SELECTED_MARKER';
export const SET_IS_PANNED = 'SET_IS_PANNED';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const CLOSE_ALL_POPUPS = 'CLOSE_ALL_POPUPS';
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const ACKNOWLEDGE_CONVERSATION = 'ACKNOWLEDGE_CONVERSATION';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const UPDATE_TO_FIELD = 'UPDATE_TO_FIELD';
export const UPDATE_CURRENT_RECIPIENT_SEARCH_TEXT = 'UPDATE_CURRENT_RECIPIENT_SEARCH_TEXT';
export const UPDATE_MESSAGE_FIELD = 'UPDATE_MESSAGE_FIELD';
export const UPDATE_SUBJECT_FIELD = 'UPDATE_SUBJECT_FIELD';
export const SOCKET_SUBSCRIPTION = 'SOCKET_SUBSCRIPTION';
export const CURRENT_TAB = 'CURRENT_TAB';
export const UPDATE_REPLY_MAP = 'UPDATE_REPLY_MAP';
export const SET_ADMIN_PERMISSION_ACCESS = 'SET_ADMIN_PERMISSION_ACCESS';
export const SET_SELECTED_PRODUCT_LINE = 'SET_SELECTED_PRODUCT_LINE';
export const SET_PRIMARY_PRODUCT_LINE = 'SET_PRIMARY_PRODUCT_LINE';
export const SET_SECONDARY_PRODUCT_LINE = 'SET_SECONDARY_PRODUCT_LINE';
export const SET_SECONDARY_PRODUCT_LINES = 'SET_SECONDARY_PRODUCT_LINES';
export const SET_ROUTE_HISTORY_PRODUCT_LINES = 'SET_ROUTE_HISTORY_PRODUCT_LINES';
export const CLEAN_ROUTE_HISTORY_PRODUCT_LINES = 'CLEAN_ROUTE_HISTORY_PRODUCT_LINES';
export const SET_SIDEBAR_SELECTED = 'SET_SIDEBAR_SELECTED';
export const TOGGLE_DROPDOWN_IS_OPEN = 'TOGGLE_DROPDOWN_IS_OPEN';
export const TOGGLE_GUIDE_DROPDOWN_IS_OPEN = 'TOGGLE_GUIDE_DROPDOWN_IS_OPEN';
export const SET_OPEN_STATE_MAP = 'SET_OPEN_STATE_MAP';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const OPEN_SNACKBAR_NOTIFICATION = 'OPEN_SNACKBAR_NOTIFICATION';
export const CLOSE_SNACKBAR_NOTIFICATION = 'CLOSE_SNACKBAR_NOTIFICATION';
export const SET_TICKET_LIST = 'SET_TICKET_LIST';
export const SET_PAST_TICKET_LIST = 'SET_PAST_TICKET_LIST';
export const ADD_TICKETS = 'ADD_TICKETS';
export const SET_PAST_TICKET_CURRENT_PAGE = 'SET_PAST_TICKET_CURRENT_PAGE';
export const RESET_PAST_TICKET_DATES = 'RESET_PAST_TICKET_DATES';
export const SET_PAST_TICKET_MAX_PAGES = 'SET_PAST_TICKET_MAX_PAGES';
export const SET_PAST_TICKET_FILTERED_TOTAL = 'SET_PAST_TICKET_FILTERED_TOTAL';
export const EMPTY_PAST_TICKET = 'EMPTY_PAST_TICKET';
export const UPDATE_PAST_TICKET_START_DATE = 'UPDATE_PAST_TICKET_START_DATE';
export const UPDATE_PAST_TICKET_END_DATE = 'UPDATE_PAST_TICKET_END_DATE';
export const UPDATE_TICKET_TIMELINE = 'UPDATE_TICKET_TIMELINE';
export const SYNC_TICKET = 'SYNC_TICKET';
export const REMOVE_TICKET = 'REMOVE_TICKET';
export const TOGGLE_TICKETS_POPOVER = 'TOGGLE_TICKETS_POPOVER';
export const SET_SELECTED_TICKET = 'SET_SELECTED_TICKET';
export const CLEAR_SELECTED_TICKET = 'CLEAR_SELECTED_TICKET';
export const SET_TICKET_VIEW = 'SET_TICKET_VIEW';
export const SAVE_TICKET_LIST_FILTERS = 'SAVE_TICKET_LIST_FILTERS';
export const SET_CENTER_TICKET_PRINT_VIEW = 'SET_CENTER_TICKET_PRINT_VIEW';
export const IS_OPEN_TICKET = 'IS_OPEN_TICKET';
export const NEED_TO_REFRESH_DATA = 'NEED_TO_REFRESH_DATA';
export const RESET_TICKET_VIEW = 'RESET_TICKET_VIEW';
export const SET_CURRENT_TICKETS_COLUMNS = 'SET_CURRENT_TICKETS_COLUMNS';
export const RESET_CURRENT_TICKETS_COLUMNS = 'RESET_CURRENT_TICKETS_COLUMNS';
export const SET_PAST_TICKETS_COLUMNS = 'SET_PAST_TICKETS_COLUMNS';
export const RESET_PAST_TICKETS_COLUMNS = 'RESET_PAST_TICKETS_COLUMNS';
export const SET_TICKET_QUALITY_FACTORS = 'SET_TICKET_QUALITY_FACTORS';
export const SET_SCALETRAX_SEARCH_FILTERS = 'SET_SCALETRAX_SEARCH_FILTERS';
export const AUTH_USER = 'AUTH_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_OK = 'RESET_PASSWORD_OK';
export const AUTH_ERROR = 'AUTH_ERROR';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const SET_USER_PASSWORD_EXPIRED = 'SET_USER_PASSWORD_EXPIRED';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const TABLE_IS_LOADING = 'TABLE_IS_LOADING';
export const UPDATE_COMBINED_DATASOURCES = 'UPDATE_COMBINED_DATASOURCES';
export const ADD_DRIVER = 'ADD_DRIVER';
export const UPDATE_DRIVER = 'UPDATE_DRIVER';
export const FETCH_DRIVER = 'FETCH_DRIVER';
export const TIME_STAMP = 'TIME_STAMP';
export const SET_ACCOUNT_LOCKED = 'SET_ACCOUNT_LOCKED';
export const EMPTY_ALL_FIELDS = 'EMPTY_ALL_FIELDS';
export const TOGGLE_MESSAGES = 'TOGGLE_MESSAGES';
export const REMOVE_MESSAGE_FROM_REPLY_MAP = 'REMOVE_MESSAGE_FROM_REPLY_MAP';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const SET_CONVERSATION_LIST = 'SET_CONVERSATION_LIST';
export const SET_USER_PERMISSION_LIST = 'SET_USER_PERMISSION_LIST';
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
export const FETCH_PERMISSION_GROUPS = 'FETCH_PERMISSION_GROUPS';
export const FETCH_ASSIGNED_PERMISSIONS = 'FETCH_ASSIGNED_PERMISSIONS';
export const SET_PLANT_LIST = 'SET_PLANT_LIST';
export const ADD_PLANT = 'ADD_PLANT';
export const UPDATE_PLANT = 'UPDATE_PLANT';
export const SET_CURRENT_REGION = 'SET_CURRENT_REGION';
export const SET_CURRENT_GEOZONE = 'SET_CURRENT_GEOZONE';
export const SET_ASSIGNED_REGION_LIST = 'SET_ASSIGNED_REGION_LIST';
export const SET_REGION_LIST = 'SET_REGION_LIST';
export const ADD_REGION = 'ADD_REGION';
export const UPDATE_REGION = 'UPDATE_REGION';
export const SET_DEFAULT_REGION = 'SET_DEFAULT_REGION';
export const SET_SELECTED_REGION = 'SET_SELECTED_REGION';
export const SET_USER_OVERRIDE = 'SET_USER_OVERRIDE';
export const REMOVE_USER_OVERRIDE = 'REMOVE_USER_OVERRIDE';
export const SET_RELEASENOTES_DOCS_LIST = 'SET_RELEASENOTES_DOCS_LIST';
export const FETCH_TENANTS = 'FETCH_TENANTS';
export const FETCH_HAULERS = 'FETCH_HAULERS';
export const FETCH_SCALES = 'FETCH_SCALES';
export const FETCH_PRINTERS = 'FETCH_PRINTERS';
export const FETCH_TRUCKS = 'FETCH_TRUCKS';
export const FETCH_USERS = 'FETCH_USERS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const EXTERNAL_ID_TO_LOCATE = 'EXTERNAL_ID_TO_LOCATE';
export const SET_TOTAL_UNREAD_MESSAGES = 'SET_TOTAL_UNREAD_MESSAGES';
export const SET_MINUTES_TO_IDLE_TIME = 'SET_MINUTES_TO_IDLE_TIME';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_USER_URL = 'SET_USER_URL';
export const SET_AUTH_TENANT_NAME = 'SET_AUTH_TENANT_NAME';
export const CLEAR_AUTH_TENANT_NAME = 'CLEAR_AUTH_TENANT_NAME';
export const SET_TENANT_ID = 'SET_TENANT_ID';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_IS_PASSWORD_TEMPORARY = 'SET_IS_PASSWORD_TEMPORARY';
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_FEATURE_FLAG_OBJECT = 'SET_FEATURE_FLAG_OBJECT';
export const ADD_CHECKLIST = 'ADD_CHECKLIST';
export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST';
export const SET_USER_FULL_NAME = 'SET_USER_FULL_NAME';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_CITIES = 'FETCH_CITIES';
export const SET_APP_NAME = 'SET_APP_NAME';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const ADD_TARE = 'ADD_TARE';
export const SET_SCALEHUB_STATE = 'SET_SCALEHUB_STATE';
export const SCALE_WEIGHT_RECEIVED = 'SCALE_WEIGHT_RECEIVED';
export const SCALE_STATE_NOTIFIED = 'SCALE_STATE_NOTIFIED';
export const QUEUE_RECENT_PROJECT = 'QUEUE_RECENT_PROJECT';
export const SET_CURRENT_PLANT = 'SET_CURRENT_PLANT';
export const FETCH_OPERATOR_NOTES = 'FETCH_OPERATOR_NOTES';
export const FETCH_OPERATOR_NOTES_OPERATORS = 'FETCH_OPERATOR_NOTES_OPERATORS';
export const ACTIVE_PIN_MAP = 'ACTIVE_PIN_MAP';
export const SET_PIN_RADIUS = 'SET_PIN_RADIUS';
export const SET_PIN_BOUNDS = 'SET_PIN_BOUNDS';
export const INIT_ROUTE_HISTORY = 'INIT_ROUTE_HISTORY';
export const SET_ROUTE_HISTORY_HOVER_SELECTION = 'SET_ROUTE_HISTORY_HOVER_SELECTION';
export const SET_TELEMETRY_LOGGER = 'SET_TELEMETRY_LOGGER';
