/* eslint-disable no-param-reassign */
import {
  GeometryDto, RouteDto, TicketDto,
} from '@trucktrax/trucktrax-ts-common';
import { Dispatch } from 'redux';
import { getRequest } from './requestService';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { devErrorAndLog } from '../util/errorUtil';
import {
  CLEAN_ROUTE_HISTORY_LIST, CLEAN_SELECTED_ROUTE_HISTORY_LIST, SET_SELECTED_ROUTE_HISTORY_LIST, SET_ROUTE_HISTORY_LIST,
  SET_ROUTE_HISTORY_HOVER_SELECTION,
  CLEAN_ROUTE_HISTORY_PRODUCT_LINES,
} from '../constants/actionConstants';
import { getTicketsByOrderId } from './ticketsService';
import { getIdFromUrl } from '../util/appUtil';
import DateTimeRange from '../util/DateTimeRange';

export function selectRouteHistoryList(routes: number[] | undefined) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_ROUTE_HISTORY_LIST,
      payload: routes,
    });
  };
}

export function setRouteHistoryHoverSelection(route: RouteDto | null) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_ROUTE_HISTORY_HOVER_SELECTION,
      payload: route,
    });
  };
}

/** clears the route history list AND selected routes */
export function clearRouteHistoryList() {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAN_ROUTE_HISTORY_LIST,
    });
    dispatch({
      type: CLEAN_SELECTED_ROUTE_HISTORY_LIST,
    });
    dispatch({
      type: CLEAN_ROUTE_HISTORY_PRODUCT_LINES,
    });
  };
}

type FetchRouteHistoryListArgs = {
  regionUrl: string,
  timespan: DateTimeRange,
  productLines: any[],
  singleTicketUrl: string,
  orderUrl: string,
  truckUrl: string,
  driverUrl: string,
  plantUrl: string,
  hideDeactivatedTrucks: boolean,
  hideDeactivatedDrivers: boolean,
  zone?: GeometryDto,
  radius?: number,
  latitude?: number,
  longitude?: number
};

// eslint-disable-next-line import/prefer-default-export
export function fetchRouteHistoryList(args: Partial<FetchRouteHistoryListArgs>) {
  args.productLines ??= [];
  args.singleTicketUrl ??= '';
  args.orderUrl ??= '';
  args.truckUrl ??= '';
  args.driverUrl ??= '';
  args.plantUrl ??= '';
  return async (dispatch: Dispatch) => {
    // GET route history
    const url = `${getGeotraxBaseUrl()}/routehistory`;

    const baseparams = {
      regionUrl: args.regionUrl,
      timestampAfter: args.timespan!.start.toUTCString(),
      timestampBefore: args.timespan!.end.toUTCString(),
      productLines: args.productLines,
      ticketUrl: [args.singleTicketUrl],
      trackedObjectUrl: args.truckUrl,
      driverUrl: args.driverUrl,
      plantUrl: args.plantUrl,
      hideDeactivatedTrucks: args.hideDeactivatedTrucks,
      hideDeactivatedDrivers: args.hideDeactivatedDrivers,
      geozone: args.zone?.polygon ? JSON.stringify(args.zone) : '', // only pass it as a geozone if it's a polygon
    };

    // circle is either passed in as radius value from drawing on the map
    // or it's taken from the radius of the geozone circle if it isn't a polygon
    if (args.zone?.circle) {
      args.radius = args.zone?.circle?.radius;
      args.latitude = args.zone?.circle?.center?.latitude;
      args.longitude = args.zone?.circle?.center?.longitude;
    }

    const params = args.latitude
      ? {
        ...baseparams,
        latitude: args.latitude,
        longitude: args.longitude,
        radius: args.radius,
      }
      : baseparams;

    try {
      if (args.orderUrl) {
        const orderId = getIdFromUrl(args.orderUrl);
        const tickets = (await getTicketsByOrderId(orderId)) as TicketDto[];
        params.ticketUrl = tickets.map(t => t.url!);
      }

      const response = await getRequest(url, params);
      const routes = response.data as RouteDto[];

      if (routes.length > 1) { clearRouteHistoryList(); }

      // map the ticket and order info on each route to all the positions of the route for use on hover/click on the map
      // doing this here because ts-common PositionDto contains order and ticket Dtos
      // which the library-common PositionDto version doesn't have to minimize transfer bulk
      routes.forEach((route) => {
        if (route.ticket && route.order) {
          const { ticket, order } = route;
          route.route.forEach((position) => {
            position.ticket = ticket;
            position.order = order;
          });
        }
      });

      dispatch({
        type: SET_SELECTED_ROUTE_HISTORY_LIST,
        payload: routes.map(r => r.id),
      });

      dispatch({
        type: SET_ROUTE_HISTORY_LIST,
        payload: routes,
      });
    } catch (e: any) {
      devErrorAndLog(
        'Unable to retrieve routeHistory',
        `geolocationsService: url: ${url} + params: + ${JSON.stringify(params)}`,
        e.toString()
      );
    }
  };
}
