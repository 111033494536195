import { Dispatch } from 'redux';
import { PlantDto, ScaleDto } from '@trucktrax/trucktrax-ts-common';
import { AxiosResponse } from 'axios';
import { getRequest, putRequest } from './requestService';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { getIdFromUrl } from '../util/appUtil';
import { SCALES_PATH, PLANTS_PATH } from '../constants/apiConstants';
import { ERROR_TEXT_FETCH_SCALE, ERROR_TEXT_FETCH_SCALE_LIST, ERROR_TEXT_UPDATE_SCALE } from '../constants/errorConstants';
import { CANCEL_API_DUE_TO_PAGE_CHANGE } from '../constants/appConstants';
import { FETCH_SCALES } from '../constants/actionConstants';
import recordLog, { devErrorAndLog } from '../util/errorUtil';
import HTTP_CODES from '../constants/httpConstants';
import { openFailModal } from '../store/actions/errorModalActions';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export function fetchScaleList(plant?: PlantDto) {
  return async (dispatch: Dispatch) => {
    if (!plant?.url) {
      return;
    }
    // eslint-disable-next-line
    const url = getGeotraxBaseUrl() + PLANTS_PATH + '/' + getIdFromUrl(plant.url) + SCALES_PATH;
    const params: {
      isArchived: boolean,
      isDeleted: boolean,
    } = {
      isArchived: false,
      isDeleted: false,
    };
    try {
      const response = await getRequest(url, params);
      dispatch({
        type: FETCH_SCALES,
        payload: response.data,
      });
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          ERROR_TEXT_FETCH_SCALE_LIST,
          `scaleService: url: ${url} + params: + ${JSON.stringify(params)}`,
          e.toString(),
          undefined,
          consoleOnly
        ) as any);
      }
    }
  };
}

/* eslint-disable */
export async function getScale(url?: string) {
  try {
    const id = getIdFromUrl(url);
    if (id === "") {
      return undefined
    }
    const params = { scaleid: id };
    const response: AxiosResponse = await getRequest(`${getGeotraxBaseUrl() + PLANTS_PATH}${SCALES_PATH}/all`, params);
    const scale = response.data.items[0];
    return scale;
  } catch (e: any) {
    devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_SCALE, e);
    return undefined;
  }
}

export function updateScale(plantId: number, scale: ScaleDto) {
  return async (dispatch: Dispatch) => {
    const baseUrl = getGeotraxBaseUrl();
    const url = `${baseUrl}${PLANTS_PATH}/${plantId}${SCALES_PATH}/${scale.id}`;

    try {
      const response = await putRequest(url, scale);
      return response.data;
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_TEXT_UPDATE_SCALE));
      recordLog(
        'error',
        `${ERROR_TEXT_UPDATE_SCALE} - id:${scale.id}`,
        e
      );
      return undefined;
    }
  };
}
