/* eslint-disable */
/* eslint-disable react/no-unstable-nested-components */
import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DriverDto, PermissionAccess, UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { FormControlLabel, Checkbox } from '@mui/material';
import DataTableContent from '../../shared/DataTableContent';
import { DRIVERS_TEXT } from '../../../constants/navConstants';
import {
  addDataTest,
  addHeaderDataTest,
  getFullName,
  getRegionUrlToNameMap,
  getUrl,
} from '../../../util/adminUtil';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  GEOTRAX_LITE_FLAG,
  NOT_AVAILABLE
} from '../../../constants/appConstants';
import { DATASOURCE, DRIVERS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import DriversAddModal from './DriversAddModal';
import styles from './DriversView.module.css';
import { RegionAccessMap } from '../../../util/permissionUtil';
import { isFeatureFlagEnabled } from '../../../util/featureFlagUtil';

export class DriversView extends Component<DriversViewProps> {
  static defaultProps = {
    regions: [],
    currentRegion: { url: '' },
    driversPermissionAccess: '',
    tab: 0,
  };

  state = {
    isOpen: false,
    isCloseRequest: false,
    displayInternal: true,
    displayExternal: false,
  };

  baseUrl = getGeotraxBaseUrl() + DRIVERS_PATH;

  static getSsoId = (item: DriverDto) => (item?.employeeNumber ? item.employeeNumber : 'Not set');

  commonColumns = () => [
    {
      Header: addHeaderDataTest(null, 'sort-driver-by-name', ADMIN_LABELS.NAME),
      id: ADMIN_KEYS.LAST_NAME,
      accessor: getFullName,
      minWidth: 250,
      Cell: (props: { value: string; }) => addDataTest(null, 'admin-driver-name', props.value),
    }, {
      Header: addHeaderDataTest(null, 'sort-user-by-externalId', ADMIN_LABELS.EXTERNAL_ID),
      id: ADMIN_KEYS.EXTERNAL_ID,
      accessor: ADMIN_KEYS.EXTERNAL_ID,
      minWidth: 130,
      Cell: (props: any) => addDataTest(props, 'admin-user-externalId', props.value),
    }, {
      Header: addHeaderDataTest(null, 'sort-user-by-ssoId', ADMIN_LABELS.SSO_ID),
      id: ADMIN_KEYS.SSO_ID,
      accessor: DriversView.getSsoId,
      minWidth: 130,
      style: {
        fontStyle: 'italic',
        color: '#878E93',
      },
      Cell: (props: any) => addDataTest(props, 'admin-user-ssoId', props.value),
    }, {
      Header: addHeaderDataTest(null, 'sort-driver-by-pin', ADMIN_LABELS.PIN),
      accessor: ADMIN_KEYS.PIN,
      minWidth: 100,
      Cell: (props: { value: string; }) => addDataTest(null, 'admin-driver-pin', props.value),
    }, {
      Header: ADMIN_LABELS.REGION,
      id: ADMIN_KEYS.REGION,
      accessor: (row: any) => getRegionUrlToNameMap(this.props.regions)[getUrl(row)!],
      minWidth: 200,
      sortable: false,
    }
  ];

  activeTabColumns = () => [
    ...this.commonColumns(),
    {
      Header: addHeaderDataTest(null, 'sort-driver-by-plant', ADMIN_LABELS.PRIMARY_PLANT),
      id: ADMIN_KEYS.PRIMARY_PLANT,
      accessor: ADMIN_KEYS.PRIMARY_PLANT,
      minWidth: 190,
      Cell: (props: { value: any; }) => addDataTest(null, 'admin-driver-primary-plant', props.value && (props.value.name)),
    }, {
      Header: addHeaderDataTest(
        null,
        'sort-driver-by-primary-product-line',
        ADMIN_LABELS.PRIMARY_PRODUCT_LINE
      ),
      id: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
      accessor: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
      minWidth: 190,
      Cell: (props: { value: string; }) => addDataTest(
        null,
        'admin-driver-default-product-line',
        props.value || NOT_AVAILABLE
      ),
    }, {
      Header: addHeaderDataTest(
        null,
        'sort-driver-by-secondary-product-line',
        ADMIN_LABELS.SECONDARY_PRODUCT_LINE
      ),
      id: ADMIN_KEYS.SECONDARY_PRODUCT_LINE,
      accessor: ADMIN_KEYS.SECONDARY_PRODUCT_LINE,
      minWidth: 190,
      Cell: (props: { value: string; }) => addDataTest(
        null,
        'admin-driver-secondary-product-line',
        props.value || NOT_AVAILABLE
      ),
    },
    ...this.geoTraxLiteColumns(),
  ];

  deactivatedTabColumns = () => [
    ...this.commonColumns(),
    ...this.geoTraxLiteColumns(),
    {
      Header: addHeaderDataTest(null, 'sort-driver-by-deactivated-date', ADMIN_LABELS.DEACTIVATED_DATE),
      id: ADMIN_KEYS.ARCHIVED_DATE,
      accessor: ADMIN_KEYS.ARCHIVED_DATE,
      minWidth: 100,
      Cell:
        (props: { value: string; }) => addDataTest(null, 'admin-driver-deactivated-date', new DateWrapper(props.value).toUSDateString()),
    },
  ];

  geoTraxLiteColumns = () => {
    const isGeoTraxLiteEnabled = isFeatureFlagEnabled(
      this.props.userPermission,
      this.props.currentRegion?.url ?? '',
      GEOTRAX_LITE_FLAG,
    );

    return isGeoTraxLiteEnabled ? [
      {
        Header: addHeaderDataTest(null, 'sort-driver-by-geotrax-lite', ADMIN_LABELS.GEOTRAX_LITE_ACCESS),
        id: ADMIN_KEYS.GEOTRAX_LITE_ACCESS,
        accessor: ADMIN_KEYS.GEOTRAX_LITE_ACCESS,
        minWidth: 170,
        Cell: (props: { value: string; }) => {
          const className = cx(
            props.value && 'icon-check',
            !props.value && 'icon-close',
            !props.value && styles.notEnabled,
          );
          return <i aria-hidden="true" data-test="admin-driver-geotrax-lite" className={className} />;
        },
      }
    ] : [];
  };

  getColumns = () => (
    (this.props.tab === 1)
      ? this.deactivatedTabColumns()
      : this.activeTabColumns());

  requestCloseModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      isCloseRequest: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: false,
    });
  };

  handleTabChange = (_event: any, newValue: number) => {
    if (newValue === 1) {
      this.props.history.push('/admin/drivers/deactivated');
    } else if (newValue === 0) {
      this.props.history.push('/admin/drivers');
    }
  };

  handleDisplayCheckChange = (event: any) => {
    const { displayInternal, displayExternal } = this.state;
    const { target } = event;
    const { name, checked } = target;
    const reverseInternalValue = name === 'displayInternal' && !checked && !displayExternal;
    const reverseExternalValue = name === 'displayExternal' && !checked && !displayInternal;
    const displayList = ['displayInternal', 'displayExternal'];

    // this code is to handle the case when both checkboxes are unchecked and it will keep anyone of them checked always
    if (reverseInternalValue) {
      this.setState({
        displayInternal: checked,
        displayExternal: true,
      });
    } else if (reverseExternalValue) {
      this.setState({
        displayInternal: true,
        displayExternal: checked,
      });
    } else if (displayList.indexOf(name) !== -1) {
      this.setState({
        [name]: checked,
      });
    }
  };

  getQueryParams = () => {
    const {
      displayInternal,
      displayExternal
    } = this.state;
    let params = {};
    if (Number(displayInternal) + Number(displayExternal) === 1) {
      params = { isExternal: `${displayInternal ? 'false' : 'true'}` };
    }
    return params;
  };

  render() {
    const columns = this.getColumns();
    const hasPermissionAccess = this.props.driversPermissionAccess === PermissionAccess.Edit;
    const isGeotraxLiteEnabled = isFeatureFlagEnabled(
      this.props.userPermission,
      this.props.currentRegion?.url ?? '',
      GEOTRAX_LITE_FLAG
    );
    const tabs = (
      <div className={styles.headerContainer}>
        <Tabs
          value={this.props.tab}
          onChange={this.handleTabChange}
          classes={
            {
              root: styles.tabsContainer,
            }
          }
        >
          <Tab label="Active" />
          <Tab label="Deactivated" />
        </Tabs>

        {isGeotraxLiteEnabled
          && (
            <div className={styles.checkboxContainer}>
              <div className={styles.checkboxTitle}>
                Driver
              </div>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={this.state.displayInternal}
                    onChange={this.handleDisplayCheckChange}
                    name="displayInternal"
                    color="primary"
                  />
                )}
                label={<span className={styles.checkboxLabel}>Internal</span>}
                classes={{
                  root: styles.checkboxLabelContainer,
                }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={this.state.displayExternal}
                    onChange={this.handleDisplayCheckChange}
                    name="displayExternal"
                    color="primary"
                  />
                )}
                label={<span className={styles.checkboxLabel}>External</span>}
                classes={{
                  root: styles.checkboxLabelContainer,
                }}
              />
            </div>
          )}
      </div>
    );
    const props: any = {
      baseUrl: this.baseUrl,
      columns,
      header: DRIVERS_TEXT,
      renderTabs: tabs,
      isArchived: this.props.tab === 1,
      canAddNewItem: hasPermissionAccess,
      byPassRedisCache: true,
      queryParams: this.getQueryParams(),
      source: DATASOURCE.DRIVER
    };
    return (
      <div className={styles.container}>
        <DataTableContent {...props} />
        {hasPermissionAccess
          && (
            <DriversAddModal
              isOpen={this.state.isOpen}
              openModal={this.openModal}
              closeModal={this.requestCloseModal}
              regions={this.props.regions}
              discardAcceptAction={this.closeModal}
              discardRejectAction={this.openModal}
              isCloseRequest={this.state.isCloseRequest}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  regions: state.assignedRegionList,
  driversPermissionAccess: state.adminPermissionAccess[DRIVERS_TEXT],
  currentRegion: state.currentRegion ?? { url: '' },
  userPermission: state.userPermission || {},
});

interface History {
  push: (p: string) => void
}

export interface DriversViewProps {
  regions: any[];
  currentRegion?: UrlKeyDto;
  driversPermissionAccess?: string;
  tab: number;
  history: History;
  userPermission: RegionAccessMap;
}

export default connect(mapStateToProps)(DriversView);
