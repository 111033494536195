import { Dispatch } from 'redux';
import { CityDto, CustomerDto, ProjectDto } from '@trucktrax/trucktrax-ts-common';
import { VoidTicketDto } from '@trucktrax/trucktrax-ts-common/build/Dtos/Tickets/VoidTicketDto';
import {
  CUSTOMERS_PATH, PROJECTS_PATH, PRODUCTS_PATH, VOID_TICKET_PATH, CITIES_PATH,
} from '../constants/apiConstants';
import { getIntegrationBaseUrl } from '../util/apiUtil';
import { getRequest, postRequest } from './requestService';
import { devErrorAndLog } from '../util/errorUtil';
import {
  FETCH_CUSTOMERS, FETCH_PROJECTS, FETCH_PRODUCTS, FETCH_CITIES,
} from '../constants/actionConstants';
import {
  ERROR_TEXT_FETCH_CUSTOMER_LIST, ERROR_TEXT_FETCH_PROJECT_LIST, ERROR_TEXT_FETCH_PRODUCT_LIST, ERROR_TEXT_FETCH_CITY_LIST,
} from '../constants/errorConstants';
import { CANCEL_API_DUE_TO_PAGE_CHANGE } from '../constants/appConstants';
import * as projectActions from '../store/actions/projectActions';
import { ReduxState } from '../store';
import HTTP_CODES from '../constants/httpConstants';

export function fetchCustomerList(regionUrl?: string) {
  return async (dispatch: Dispatch) => {
    if (!regionUrl) {
      return;
    }

    const url = `${getIntegrationBaseUrl() + CUSTOMERS_PATH}`;
    const params = { regionUrl };

    try {
      const response = await getRequest(url, params);
      const data = (response.data ?? []) as CustomerDto[];

      dispatch({
        type: FETCH_CUSTOMERS,
        payload: data.sort((c1, c2) => {
          if (Number.isNaN(c1.customerExternalId) || Number.isNaN(c2.customerExternalId)) {
            return c1.customerExternalId.localeCompare(c2.customerExternalId);
          }

          const c1Number = Number(c1.customerExternalId);
          const c2Number = Number(c2.customerExternalId);
          return c1Number - c2Number;
        }),
      });
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          ERROR_TEXT_FETCH_CUSTOMER_LIST,
          `integrationService: url: ${url} + params: + ${JSON.stringify(params)}`,
          e.toString(),
          undefined,
          consoleOnly
        ) as any);
      }
    }
  };
}

export function fetchProjectList(regionUrl?: string, customerExternalId?: string) {
  return async (dispatch: Dispatch) => {
    if (!regionUrl) {
      return;
    }

    const url = `${getIntegrationBaseUrl() + PROJECTS_PATH}`;
    const params = { regionUrl, customerExternalId: customerExternalId ?? '' };

    try {
      const response = await getRequest(url, params);
      const data = (response.data ?? []) as ProjectDto[];

      dispatch({
        type: FETCH_PROJECTS,
        payload: data.sort((p1, p2) => {
          if (Number.isNaN(p1.projectExternalId) || Number.isNaN(p2.projectExternalId)) {
            return p1.projectExternalId.localeCompare(p2.projectExternalId);
          }

          const p1Number = Number(p1.projectExternalId);
          const p2Number = Number(p2.projectExternalId);
          return p1Number - p2Number;
        }),
      });
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          ERROR_TEXT_FETCH_PROJECT_LIST,
          `integrationService: url: ${url} + params: + ${JSON.stringify(params)}`,
          e.toString(),
          undefined,
          consoleOnly
        ) as any);
      }
    }
  };
}

export function fetchProductList(regionUrl?: string, projectExternalId?: string, plantExternalId?: string) {
  return async (dispatch: Dispatch) => {
    if (!regionUrl) {
      return;
    }

    const url = `${getIntegrationBaseUrl() + PRODUCTS_PATH}`;
    const params = { regionUrl, projectExternalId, plantExternalId };

    try {
      const response = await getRequest(url, params);

      dispatch({
        type: FETCH_PRODUCTS,
        payload: response.data,
      });
    } catch (e: any) {
      // don't store previous list if we error
      dispatch({
        type: FETCH_PRODUCTS,
        payload: undefined,
      });
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          ERROR_TEXT_FETCH_PRODUCT_LIST,
          `integrationService: url: ${url} + params: + ${JSON.stringify(params)}`,
          e.toString(),
          undefined,
          consoleOnly
        ) as any);
      }
    }
  };
}

export function fetchCityList(regionUrl?: string, state?: string) {
  return async (dispatch: Dispatch) => {
    if (!regionUrl) {
      return;
    }
    const url = `${getIntegrationBaseUrl() + CITIES_PATH}`;
    const params = { regionUrl, state: state ?? '' };

    try {
      const response = await getRequest(url, params);
      const data = (response.data ?? []) as CityDto[];
      dispatch({
        type: FETCH_CITIES,
        payload: data,
      });
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(
          ERROR_TEXT_FETCH_CITY_LIST,
          `integrationService: url: ${url} + params: + ${JSON.stringify(params)}`,
          e.toString(),
          undefined,
          consoleOnly
        ) as any);
      }
    }
  };
}

export function queueRecentProject(project?: ProjectDto) {
  return async (dispatch: Dispatch) => {
    if (!project) { return; }

    dispatch(projectActions.queueRecentProject(project));
  };
}

export function voidTicket(voidTicketDto: VoidTicketDto) {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const { currentRegion } = getState();

    const url = `${getIntegrationBaseUrl() + VOID_TICKET_PATH}`;

    try {
      await postRequest(url, voidTicketDto, { regionUrl: currentRegion.url });
      return true;
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(
        ERROR_TEXT_FETCH_PRODUCT_LIST,
        `integrationService: url: ${url} + params: + ${JSON.stringify(voidTicketDto)}`,
        e.toString(),
        undefined,
        consoleOnly
      ) as any);
      return false;
    }
  };
}
