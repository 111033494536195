import React, { Component } from 'react';
import { FolderDropdown } from '@trucktrax/trucktrax-common';
import { UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import styles from './DocsPanelContent.module.css';
import {
  categoriesForDocsGuides,
  categoriesForDocsRelease,
  categoriesForTrainingVideos,
  categoriesForGeotraxLiteDocsGuides
} from '../../../constants/navConstants';
import SidenavLink from '../SidenavLink';
import { ticketingActionItems, messagesActionItems } from '../../../util/navUtil';
import { GEOTRAX_LITE_FLAG } from '../../../constants/appConstants';
import { isFeatureFlagEnabled } from '../../../util/featureFlagUtil';
import { RegionAccessMap } from '../../../util/permissionUtil';

interface DocsPanelState {
  isVideoOpen: boolean
}

export class DocsPanelContent extends Component<DocsPanelContentProps, DocsPanelState> {
  constructor(props: DocsPanelContentProps) {
    super(props);

    this.state = {
      isVideoOpen: false,
    };
  }

  async componentDidMount() {
    await messagesActionItems.messages.onClose();
    await ticketingActionItems.ticketsPopover.onClose();
  }

  render() {
    const url = window.location.pathname;
    const dropdownIsActive = (url && url.includes('guide')) as boolean;
    const videoIsActive = (url && url.includes('video')) as boolean;
    const isGeotraxLiteEnabled = isFeatureFlagEnabled(
      this.props.userPermission,
      this.props.currentRegion?.url ?? '',
      GEOTRAX_LITE_FLAG
    );

    const userGuideTabs = categoriesForDocsGuides.map(config => (<SidenavLink {...config} key={config.name} />));
    if (isGeotraxLiteEnabled) {
      categoriesForGeotraxLiteDocsGuides.forEach(config => userGuideTabs.push(<SidenavLink {...config} key={config.name} />));
    }
    const releaseTabs = categoriesForDocsRelease.map(config => (<SidenavLink {...config} key={config.name} />));
    const videoTabs = categoriesForTrainingVideos.map(config => (<SidenavLink {...config} key={config.name} />));
    const count = isGeotraxLiteEnabled ? 3 : 2;

    return (
      <div className={styles.docsPanelContent}>
        <FolderDropdown
          dataTestName="leftnav-user-guides-dropdown-menu"
          icon="icon-directions"
          docsMode
          classes={styles.folder}
          contentClasses={styles.folderContent}
          count={count}
          isOpen={this.props.dropdownIsOpen}
          name="User Guides"
          onClick={this.props.toggleDropdownIsOpen}
          isActive={dropdownIsActive}
        >
          {userGuideTabs as any}
        </FolderDropdown>
        <div>
          {releaseTabs}
        </div>
        <FolderDropdown
          dataTestName="leftnav-training-videos-dropdown-menu"
          icon="icon-video"
          docsMode
          classes={styles.folder}
          contentClasses={styles.folderContent}
          count={count}
          isOpen={this.state.isVideoOpen}
          name="Training Videos"
          onClick={() => this.setState({ isVideoOpen: !this.state.isVideoOpen })}
          isActive={videoIsActive}
        >
          {videoTabs as any}
        </FolderDropdown>
      </div>
    );
  }
}

export interface DocsPanelContentProps {
  dropdownIsOpen: boolean;
  toggleDropdownIsOpen: () => void;
  userPermission: RegionAccessMap,
  currentRegion: UrlKeyDto,
}

export default DocsPanelContent;
