/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './MessagingHeader.module.css';

interface MessagingHeaderProps {
  author: string;
  icon: JSX.Element;
  button: JSX.Element | null;
  backButtonAction: () => void;
}
const MessagingHeader = ({
  author, icon, button, backButtonAction
}: MessagingHeaderProps) => (
  <div className={styles.header}>
    <span className={styles.nameWrapper}>
      { button === undefined ? null
        : (
          <button onClick={backButtonAction} className={styles.backButton}>
            <i aria-hidden="true" className="icon-arrow-back icon-md" />
          </button>
        )}
      {icon}
      <span className={cx(styles.title, 'nowrap-ellipsis')}>
        {author}
      </span>
    </span>
    {button}
  </div>
);

MessagingHeader.defaultProps = {
  button: null,
  icon: null,
};

MessagingHeader.propTypes = {
  /** Text that appears beside the truck icon on the left */
  author: PropTypes.string.isRequired,

  /** Icon element to be placed on the left side of the header, before the author */
  icon: PropTypes.node,

  /** Any element to be placed on the right side of the header */
  button: PropTypes.node,
};

export default MessagingHeader;
