import React, { Component, ReactNode } from 'react';
import { Label, vars } from '@trucktrax/trucktrax-common';
import cx from 'classnames';
import { connect } from 'react-redux';
import {
  DeviceDto, PermissionAccess, RegionDto, UrlKeyDto,
} from '@trucktrax/trucktrax-ts-common';
import styles from './DeviceDetailView.module.css';
import AdminCard from '../../shared/admin/AdminCard';
import { openModal, closeModal } from '../../../store/actions/errorModalActions';
import {
  exactFieldLength,
  getRegionUrlToNameMap,
  getDeviceTypeLabel,
  getUrl,
} from '../../../util/adminUtil';
import { updateDataTableRecord } from '../../../services/dataTableService';
import { DEVICES_TEXT } from '../../../constants/navConstants';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  ACCENT,
  CONFIRM_DEACTIVATE_DEVICE,
  ACTIVATE_GLINX_BODY1,
  DEACTIVATE_GLINX_BODY1,
  DEACTIVATE_GLINX_BODY2,
  DEACTIVATE_GLINX_POINT1,
  DEACTIVATE_GLINX_POINT2,
  DEACTIVATE_GLINX_POINT3,
  CONFIRM_ACTIVATE_DEVICE,
  ARE_YOU_SURE,
  TEXT_DISPLAY,
  CUSTOM_COMPONENT,
  CANCEL_LABEL,
} from '../../../constants/appConstants';

import {
  DEVICES_PATH, TRUCKS_PATH, ADMIN_TRUCKS_PATH,
} from '../../../constants/apiConstants';

import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import StatusContainer from '../status/StatusContainer';
import { noop, getIdFromUrl } from '../../../util/appUtil';

import { getRequest } from '../../../services/requestService';

export class DeviceDetailViewGLinx extends Component<DeviceDetailViewGLinxProps> {
  static defaultProps = {
    currentRegion: {
      url: '',
    },
  };

  state = {
    edit: false,
    deviceDetails: {
      archived: false,
      externalId: '',
    },
    truckAlias: '',
    truckNavUrl: '',
  };

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  baseUrl = () => `${getGeotraxBaseUrl() + DEVICES_PATH}/${this.props.match.params.id.replace('devices/glinx', 'devices')}`;

  regionUrlAccessor = (row: DeviceDto) => (getRegionUrlToNameMap(this.props.regions)[getUrl(row)!]);

  save = (dto: DeviceDto, onSuccess: any, onError: any, toastMessages: any) => {
    const onSaveSuccess = (updatedDto: { data: DeviceDto; }) => {
      const deviceDetails = updatedDto && updatedDto.data ? updatedDto.data : {};
      this.setState({
        edit: false,
        deviceDetails,
      });
      onSuccess(updatedDto);
    };
    const currentRegionUrl = this.props.currentRegion.url;
    this.props.updateAdminTableData(this.baseUrl(), dto, onSaveSuccess, onError, false, currentRegionUrl, toastMessages);
  };

  static getTruckById = async (truckBaseUrl: string, params: any) => {
    const responseTruck = await getRequest(truckBaseUrl, params);
    return responseTruck ? responseTruck.data : {};
  };

  handleCallback = async (response: { data: any }) => {
    const { data } = response;
    let truckAlias = 'None';
    const params = {
      tenant: data.tenant?.url,
    };
    try {
      const truckUrl = data.truck?.url;
      if (truckUrl) {
        const truckId = getIdFromUrl(truckUrl);
        const truckBaseUrl = `${getGeotraxBaseUrl()}${TRUCKS_PATH}/${truckId}`;
        const truckResponse = await DeviceDetailViewGLinx.getTruckById(truckBaseUrl, params);
        truckAlias = truckResponse.truckAlias || truckAlias;
        const currentURL = `${window.location.protocol}//${window.location.host}`;
        const truckNavUrl = () => `${currentURL}${ADMIN_TRUCKS_PATH}/${truckId}`;
        this.setState({ truckNavUrl: truckNavUrl() });
      } else {
        this.setState({ truckNavUrl: '' });
      }
    } catch (e) {
      truckAlias = 'None';
    }
    data.truckAlias = truckAlias;
    data.deviceType = 'GLINX';
    this.setState({ truckAlias });
    this.setData(data);
  };

  handleAlterData = (data: DeviceDto) => {
    this.setData(data);
    return data;
  };

  setData = (data: DeviceDto) => {
    this.setState({
      deviceDetails: { ...data },
    });
  };

  static checkLength = (value: any) => exactFieldLength(value, 15, ADMIN_LABELS.IMEINUM);

  getViewConfigWithoutEdit = () => [
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.NAME,
      accessor: ADMIN_KEYS.NAME,
      className: cx(styles.deviceNameGLinx, 'large'),
      dataTest: 'devices-drilldown-deviceType',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.DEVICE_TYPE,
      accessor: (row: { deviceType: any; }) => getDeviceTypeLabel(row.deviceType),
      className: styles.deviceTypeGLinx,
      dataTest: 'devices-drilldown-deviceType',
    },
    {
      type: CUSTOM_COMPONENT,
      key: ADMIN_LABELS.LAST_ASSIGNED_TRUCK,
      className: styles.truckAlias,
      component: () => this.createLink(this.state.truckAlias),
    },

    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.EXTERNAL_ID,
      accessor: ADMIN_KEYS.EXTERNAL_ID,
      className: styles.externalIdGlinx,
      dataTest: 'devices-drilldown-external-id',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.FIRMWARE_VERSION,
      accessor: ADMIN_KEYS.SOFTWARE_VERSION,
      className: styles.firmware,
      dataTest: 'devices-drilldown-android',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.HARDWARE_VERSION,
      accessor: ADMIN_KEYS.MODEL_NUMBER,
      className: styles.hardware,
      dataTest: 'devices-drilldown-model',
    },
    {
      type: TEXT_DISPLAY,
      label: ADMIN_LABELS.REGION,
      accessor: this.regionUrlAccessor,
      className: styles.regionGlinx,
      dataTest: 'devices-drilldown-region',
    },
  ];

  createLink = (truckAlias: {} | null | undefined) => (

    <div className={styles.truckAlias}>
      <Label className={styles.truckAliasLabel}>
        {ADMIN_LABELS.LAST_ASSIGNED_TRUCK}
      </Label>
      <p>
        {truckAlias === 'None' ? truckAlias
          : (
            <a
              style={{ color: `${vars.darkblue}`, minHeight: '4rem' }}
              href={this.state.truckNavUrl}
            >
              {truckAlias as ReactNode}
            </a>
          )}
      </p>
    </div>

  );

  viewOnlyItems() {
    if (!this.state.edit) {
      const hasEditPermission = this.props.devicesPermissionAccess === PermissionAccess.Edit;
      return (
        <StatusContainer
          category="device"
          isArchived={this.state.deviceDetails.archived}
          hasEditPermission={hasEditPermission}
          containerClassName={styles.statusCtr}
          onActivateClick={this.activateWarningModal}
          onDeactivateClick={this.deactivateWarningModal}
        />
      );
    }
    return null;
  }

  activateWarningModal = () => {
    const modalBody = (
      <span>
        <p className={styles.glinxModalText}>{ACTIVATE_GLINX_BODY1}</p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.activateDevice,
      acceptText: CONFIRM_ACTIVATE_DEVICE,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateWarningModal = () => {
    const modalBody = (
      <span>
        <p>{DEACTIVATE_GLINX_BODY1}</p>
        <p>
          <ul>
            <li>{DEACTIVATE_GLINX_POINT1}</li>
            <li>{DEACTIVATE_GLINX_POINT2}</li>
            <li>{DEACTIVATE_GLINX_POINT3}</li>
          </ul>
        </p>
        <p className={styles.glinxModalText}>{DEACTIVATE_GLINX_BODY2}</p>
      </span>
    );

    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.deactivateDevice,
      acceptText: CONFIRM_DEACTIVATE_DEVICE,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateDevice = () => {
    const dto = {
      ...this.state.deviceDetails,
      archived: true,
    } as DeviceDto;

    const toastMessages = {
      success: `Device “${dto.externalId}” has been successfully deactivated.`,
      fail: `Unable to deactivate “${dto.externalId}.”`,
    };

    this.props.closeModal();
    this.save(dto, noop, null, toastMessages);
  };

  activateDevice = () => {
    const dto = {
      ...this.state.deviceDetails,
      archived: false,
    } as DeviceDto;
    const toastMessages = {
      success: `Device "${dto.externalId}" has been successfully activated.`,
      fail: `Unable to activate “${dto.externalId}.”`,
    };

    this.props.closeModal();
    this.save(dto, noop, null, toastMessages);
  };

  getBreadcrumbPath() {
    if (!this.state.deviceDetails.archived) {
      return this.props.location.pathname.replace('/glinx', '');
    }
    // construct the breadcrumb path for deactivated devices
    const paths = this.props.location.pathname.replace('/glinx', '').split('/');
    const id = paths.pop();
    if (id) {
      paths.push('deactivated', id);
    }
    return paths.join('/');
  }

  getConfigValue() {
    const viewConfigWithoutEdit = this.getViewConfigWithoutEdit();
    return viewConfigWithoutEdit;
  }

  render() {
    const pathname = this.getBreadcrumbPath();
    const configValue = this.getConfigValue();

    return (
      <AdminCard
        edit={this.state.edit}
        url={this.baseUrl()}
        className={cx(
          styles.wrapper,
          this.state.edit && styles.editWrapper
        )}
        onToggleEdit={this.toggleEdit}
        save={this.save}
        config={configValue}
        pathName={pathname}
        headerAccessor="name"
        options={{
          callback: this.handleCallback,
          alterData: this.handleAlterData,
          requiredKeys: [],
        }}
      >
        {this.viewOnlyItems()}
      </AdminCard>
    );
  }
}

const mapStateToProps = (state: any) => ({
  regions: state.regionList,
  devicesPermissionAccess: state.adminPermissionAccess[DEVICES_TEXT],
  currentRegion: state.currentRegion,
});

type Location = {
  pathname: string;
};
type Match = {
  params: { id: string };
};
export interface DeviceDetailViewGLinxProps {
  updateAdminTableData: typeof updateDataTableRecord,
  match: Match,
  closeModal: typeof closeModal,
  openModal: typeof openModal,
  devicesPermissionAccess?: string,
  regions: RegionDto[],
  currentRegion: UrlKeyDto,
  location: Location
}
export default connect<any, any, any>(mapStateToProps, {
  updateAdminTableData: updateDataTableRecord,
  openModal,
  closeModal,
})(DeviceDetailViewGLinx);
