/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { AxiosResponse } from 'axios';
import config from 'react-global-configuration';
import cx from 'classnames';
import { connect } from 'react-redux';
import { LabeledDivider } from '@trucktrax/trucktrax-common';
import {
  PermissionAccess, ProductLine, RegionDto, CannedMessagesDto
} from '@trucktrax/trucktrax-ts-common';
import styles from './RegionDetailView.module.css';
import AdminCard from '../../shared/admin/AdminCard';
import TokenRefresh from './TokenRefresh';
import DispatcherCannedMessages from './DispatcherCannedMessages';
import { addToOrUpdateListInStore } from '../../../store/actions/dataTableActions';
import { ErrorMap, updateDataTableRecord } from '../../../services/dataTableService';
import { openModal, closeModal } from '../../../store/actions/errorModalActions';
import {
  defaultProductLineRadioOptions, expirationDate, formatProductLineString, validateSpacing
} from '../../../util/adminUtil';
import { REGIONS_TEXT } from '../../../constants/navConstants';
import { UPDATE_REGION } from '../../../constants/actionConstants';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  CHECKBOX_FORM,
  CUSTOM_COMPONENT,
  EDIT_BTN,
  FAIL,
  INPUT_FORM,
  MAP_FORM,
  REGISTRATION_TOKEN_EXPIRATION_DAYS,
  SAVE_BTN,
  TEXT_DISPLAY,
} from '../../../constants/appConstants';
import { REGIONS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ReduxState } from '../../../store';
import { ConnectedDispatchFunction, ConnectedFunction, ModalOptions } from '../../../types';
import { getRegionById } from '../../../services/regionsService';
import {
  getCannedMessages,
  postCannedMessages,
  putCannedMessages,
} from '../../../services/cannedMessagesService';
import { TENANT_ID } from '../../../constants/localStorageConstants';

export interface RegionDetailViewState {
  edit: boolean;
  initialSelectedProductLines: Array<{ value: string }>;
  selectedProductLines: Array<string> | null;
  cannedMessages: CannedMessagesDto[];
  tempCannedMessages: CannedMessagesDto[];
  regionId?: number;
}

const LabeledDividerWrapper = () => (
  <LabeledDivider
    className={styles.divider}
    label="Registration Token"
  />
);

const TokenRefreshWrapper = (props: any) => (
  <TokenRefresh {...props} />
);

export class RegionDetailView extends Component<RegionDetailViewProps, RegionDetailViewState> {
  state = {
    edit: false,
    initialSelectedProductLines: [],
    selectedProductLines: [],
    cannedMessages: [] as CannedMessagesDto[],
    tempCannedMessages: [] as CannedMessagesDto[], // temporary state for canned messages being edited
    regionId: undefined,
  };

  componentDidMount() {
    this.loadInitialSelectedProductLines();
  }

  async componentDidUpdate(prevProps: RegionDetailViewProps, prevState: RegionDetailViewState) {
    // fetch all canned messages once per regionId change
    if (this.state.regionId && typeof this.state.regionId === 'number' && this.state.regionId !== prevState.regionId) {
      const response = await getCannedMessages(this.state.regionId);
      const fetchedMessages = response?.data?.items;
      const transformedMessages = RegionDetailView.addCannedMessagesForAllProductLines(fetchedMessages, this.state.regionId);

      this.setState({
        cannedMessages: transformedMessages,
      });
    }
  }

  // initialize empty message lists so there is one for each product line
  static addCannedMessagesForAllProductLines = (messages: CannedMessagesDto[], regionId: number) => {
    const transformed = [...messages]; // return value

    [ProductLine.Aggregates, ProductLine.Cement, ProductLine.ReadyMix].forEach((p) => {
      if (!messages.find(m => m.productLine === p)) {
        transformed.push({
          productLine: p,
          id: 0,
          regionId,
          messages: [],
          deleted: false,
          archived: false,
        });
      }
    });

    return transformed;
  };

  requestTokenUrl = `${getGeotraxBaseUrl() + REGIONS_PATH}/registration/${this.props.match.params.id}`;

  loadInitialSelectedProductLines = async () => {
    const region = await getRegionById(this.props.match.params.id);
    let productLines: { value: string; }[] = [];
    if (region?.productLines && typeof region.productLines === 'string') {
      productLines = (region.productLines as string).split(',').map(pl => ({ value: pl }));
    }
    this.setState({ initialSelectedProductLines: productLines });
  };

  onFetchError = (err: any) => {
    const error: ModalOptions = {
      modalOpen: true,
      modalTitle: err.toString(),
      modalBody: 'Unable to create token',
      modalType: FAIL,
    };

    this.props.openModal(error);
  };

  toggleEdit = () => {
    this.setState({
      edit: !this.state.edit,
      tempCannedMessages: cloneDeep(this.state.cannedMessages),
    });
  };

  static regionCenterAccessor = (x: any) => x;

  saveCannedMessages = async () => {
    const promises = [];
    for (const cannedMessageData of this.state.tempCannedMessages) {
      if (!cannedMessageData.id || cannedMessageData.id === 0) {
        const body = {
          ...cannedMessageData,
          id: 0,
          tenant: { url: localStorage.getItem(TENANT_ID) as string },
          messages: cannedMessageData.messages.filter(m => !!(m.trim())),
        };

        promises.push(postCannedMessages(body));
      } else {
        const body = {
          ...cannedMessageData,
          messages: cannedMessageData.messages.filter(m => !!(m.trim())),
        };

        promises.push(putCannedMessages(cannedMessageData.id, body));
      }
    }

    // The only reason we actually need to replace local state with response from server
    // is so newly created CannedMessageDtos are assigned the correct db-issued non-zero ids.
    // (They need the correct id to decide between PUT or POST on each save)
    const results = await Promise.all(promises);
    const cannedMessagesResults = results.map(result => result.data);
    this.setState({
      cannedMessages: cannedMessagesResults,
    });
  };

  save = (dto: RegionDto, onSuccess: VoidFunction, onError: VoidFunction) => {
    this.saveCannedMessages();

    // Change type to match with DB format
    dto.productLines = dto.productLines?.toString() as any;
    this.updateRegion(dto, onSuccess, onError, this.baseUrl());
    // update initialSelectedProductLines to make it fit with the productLines selected
    if (dto.productLines && typeof dto.productLines === 'string') {
      const productLines = (dto.productLines as string).split(',').map(pl => ({ value: pl }));
      this.setState({ initialSelectedProductLines: productLines });
    }
  };

  deleteToken = (dto: RegionDto, onChange: VoidFunction) => {
    const newDto: RegionDto = {
      ...dto,
      registrationTokenExpiration: undefined,
      registrationToken: undefined,
    };
    this.updateRegion(newDto, onChange, this.onFetchError, this.baseUrl(), true);
  };

  requestToken = (dto: RegionDto, onChange: VoidFunction) => {
    const tokenExpiration = config.get(REGISTRATION_TOKEN_EXPIRATION_DAYS) as number;
    const expiration = expirationDate(tokenExpiration);
    dto.registrationTokenExpiration = expiration.toISOString();
    this.updateRegion(dto, onChange, this.onFetchError, this.requestTokenUrl, true);
  };

  updateRegion = (
    dto: RegionDto,
    onSuccess: (region: AxiosResponse<RegionDto>) => void,
    onError: (errorMap: ErrorMap) => void,
    url: string,
    hideSnackbar?: boolean
  ) => {
    const onRequestSuccess = (updatedDto: AxiosResponse<RegionDto>) => {
      this.setState({ edit: false });
      onSuccess(updatedDto);
      this.props.addToOrUpdateListInStore(updatedDto.data, UPDATE_REGION);
    };
    this.props.updateAdminTableData(url, dto, onRequestSuccess, onError, hideSnackbar);
  };

  static validateRegionName = (value: string) => validateSpacing(value);

  baseUrl = () => `${getGeotraxBaseUrl() + REGIONS_PATH}/${this.props.match.params.id}`;

  static formatProductLineText = (value: RegionDto) => {
    const products: string[] = (value.productLines as unknown as string).split(',');
    return products.map(x => formatProductLineString(x));
  };

  dispatcherCannedMessagesComponent = () => (
    <DispatcherCannedMessages
      isEditMode={this.state.edit}
      key={ADMIN_KEYS.DISPATCHER_CANNED_MESSAGES}
      messages={this.state.edit ? this.state.tempCannedMessages : this.state.cannedMessages}
      setMessages={(m: CannedMessagesDto[]) => {
        this.setState({ tempCannedMessages: m });
      }}
      productLines={this.state.selectedProductLines}
      openModal={this.props.openModal}
      closeModal={this.props.closeModal}
    />
  );

  render() {
    const { location } = this.props;
    const haveCannedMessagesChanged = !isEqual(this.state.cannedMessages, this.state.tempCannedMessages);

    const readConfigWithoutRegionEditPermission = [
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.REGION_NAME,
        accessor: ADMIN_KEYS.NAME,
        className: cx(styles.regionNameTitle, 'large'),
        dataTest: 'regions-drilldown-name',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.PRODUCT_LINES,
        accessor: RegionDetailView.formatProductLineText,
        className: cx(styles.productLines),
        dataTest: 'regions-drilldown-product-lines',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.DESCRIPTION,
        accessor: ADMIN_KEYS.DESCRIPTION,
        className: cx(styles.description),
        dataTest: 'regions-drilldown-description',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.LATITUDE,
        accessor: ADMIN_KEYS.LATITUDE,
        className: cx(styles.latitude),
        dataTest: 'regions-drilldown-latitude',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.LONGITUDE,
        accessor: ADMIN_KEYS.LONGITUDE,
        className: cx(styles.longitude),
        dataTest: 'regions-drilldown-longitude',
      },
      {
        type: MAP_FORM,
        label: ADMIN_LABELS.REGION_CENTER_POINT,
        accessor: RegionDetailView.regionCenterAccessor,
        className: styles.map,
        dataTest: 'regions-drilldown-map',
      },
      {
        type: CUSTOM_COMPONENT,
        component: LabeledDividerWrapper,
      },
      {
        type: CUSTOM_COMPONENT,
        className: styles.tokenDisplay,
        container: styles.container,
        registrationDisplayDataTest: 'registration-display-data-test',
        registrationExpirationDisplayDataTest: 'registration-expiration-display-data-test',
        innerDivStyle: styles.innerDivStyle,
        component: TokenRefreshWrapper,
        requestToken: this.requestToken,
        deleteToken: this.deleteToken,
        accessor: RegionDetailView.regionCenterAccessor,
      },
      {
        type: CUSTOM_COMPONENT,
        key: ADMIN_KEYS.DISPATCHER_CANNED_MESSAGES,
        label: ADMIN_LABELS.DISPATCHER_CANNED_MESSAGES,
        component: this.dispatcherCannedMessagesComponent,
      },
    ];

    const readConfigWithRegionEditPermission = [
      ...readConfigWithoutRegionEditPermission,
      {
        type: EDIT_BTN,
        name: 'Edit Region',
        iconClassName: 'icon-create',
        dataTest: 'regions-drilldown-edit',
      },
    ];

    // this configuration will be useful when editing
    const editConfig = [
      readConfigWithoutRegionEditPermission[0],
      {
        type: INPUT_FORM,
        label: ADMIN_LABELS.REGION_NAME,
        accessor: ADMIN_KEYS.NAME,
        className: cx(styles.regionName, 'large'),
        dataTest: 'regions-drilldown-name',
        key: ADMIN_KEYS.NAME,
        maxLength: 25,
        errorDataTest: 'name-input-missing-error',
        isRequired: true,
        customValidation: RegionDetailView.validateRegionName,
      },
      {
        type: CHECKBOX_FORM,
        key: ADMIN_KEYS.PRODUCT_LINES,
        label: ADMIN_LABELS.PRODUCT_LINES,
        className: styles.productLines,
        items: defaultProductLineRadioOptions,
        isRequired: true,
        errorDataTest: 'productLine-checkbox-error',
        initialSelected: this.state.initialSelectedProductLines
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.DESCRIPTION,
        label: ADMIN_LABELS.DESCRIPTION,
        accessor: ADMIN_KEYS.DESCRIPTION,
        maxLength: 50,
        dataTest: 'description-input-data-test',
        errorDataTest: 'description-input-missing-error',
        className: cx(styles.description),
        isRequired: true,
      }, {
        type: MAP_FORM,
        label: ADMIN_LABELS.REGION_CENTER_POINT,
        key: ADMIN_LABELS.REGION_CENTER_POINT,
        shouldAutoRefresh: false,
        isEditable: true,
        showDrawingManager: true,
        accessor: (value: any) => value,
        className: styles.map,
        editMarkerOnly: true,
        showGeoZone: false,
        showMarker: true,
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.LATITUDE,
        accessor: ADMIN_KEYS.LATITUDE,
        className: styles.latitude,
        dataTest: 'plants-drilldown-latitude',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.LONGITUDE,
        accessor: ADMIN_KEYS.LONGITUDE,
        className: styles.longitude,
        dataTest: 'plants-drilldown-longitude',
      },
      {
        type: CUSTOM_COMPONENT,
        key: ADMIN_KEYS.DISPATCHER_CANNED_MESSAGES,
        label: ADMIN_LABELS.DISPATCHER_CANNED_MESSAGES,
        component: this.dispatcherCannedMessagesComponent,
      },
      {
        type: SAVE_BTN,
        isParentChanged: haveCannedMessagesChanged,
      },
    ];

    let configValue;
    if (this.props.regionsPermissionAccess === PermissionAccess.Edit) {
      if (this.state.edit) {
        configValue = editConfig;
      } else {
        configValue = readConfigWithRegionEditPermission;
      }
    } else {
      configValue = readConfigWithoutRegionEditPermission;
    }

    return (
      <AdminCard
        edit={this.state.edit}
        url={this.baseUrl()}
        className={cx(
          styles.wrapper,
          this.state.edit && styles.editWrapper
        )}
        onToggleEdit={this.toggleEdit}
        save={this.save}
        config={configValue}
        pathName={location.pathname}
        headerAccessor="name"
        options={{
          alterData: (region: RegionDto) => {
            // we're not actually altering data, just accessing some state within AdminCard to copy it to local state
            // the region parameter will still have product lines as a comma separated string, instead of an array of ProductLines
            const productLines = (region.productLines as any).split(',');
            const regionId = region.id;
            this.setState({
              selectedProductLines: productLines,
              regionId,
            });

            return region;
          },
          requiredKeys: [],
        }}
        isParentChanged={haveCannedMessagesChanged}
        onChangeHandler={(data: RegionDto) => {
          // due to some seriously wonky logic in Region Admin Card, productLines will sometimes be an array, and other times be a string
          if (typeof data.productLines === 'object') {
            this.setState({
              selectedProductLines: data.productLines
            });
          } else if (typeof data.productLines === 'string') {
            this.setState({
              selectedProductLines: (data.productLines as any).split(','),
            });
          } else if (!data.productLines) { // added to handle case when no productLines are selected
            this.setState({
              selectedProductLines: null,
            });
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  regionsPermissionAccess: state.adminPermissionAccess[REGIONS_TEXT] ?? '',
});

type RegionDetailViewReduxStateProps = ReturnType<typeof mapStateToProps>;
type RegionDetailViewReduxDispatchProps = {
  updateAdminTableData: ConnectedDispatchFunction<typeof updateDataTableRecord>;
  addToOrUpdateListInStore: ConnectedDispatchFunction<typeof addToOrUpdateListInStore>;
  openModal: ConnectedFunction<typeof openModal>;
  closeModal: ConnectedFunction<typeof closeModal>;
};
type RegionDetailViewOwnProps = {
  match: {
    params: {
      id: string,
    }
  },
  location: {
    pathname: string
  }
};
export type RegionDetailViewProps = RegionDetailViewReduxStateProps & RegionDetailViewReduxDispatchProps & RegionDetailViewOwnProps;

export default connect(mapStateToProps, {
  updateAdminTableData: updateDataTableRecord,
  addToOrUpdateListInStore,
  openModal,
  closeModal,
})(RegionDetailView);
