import React from 'react';
import cx from 'classnames';
import styles from './feature.module.css';

export default function NoFeature() {
  return (
    <div
      className={styles.noFeatures}
      data-test="feature-flag-permissions-list"
    >
      <div className={styles.textBlocksContainer}>
        <div className={styles.iconBlock}>
          <i
            aria-hidden="true"
            className={cx(
              'icon-xlarge',
              'icon-lock-outline-no',
              'icon-gray-300'
            )}
          />
        </div>
        <div className={styles.textBlock}>
          There are currently no permissions added to this feature flag.
        </div>
        <div className={styles.textBlock}>
          Add one using the search bar above.
        </div>
      </div>
    </div>
  );
}
