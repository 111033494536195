import React, { Component } from 'react';
import { Paper, Tab, Tabs } from '@mui/material';
import { Button, Tooltip } from '@trucktrax/trucktrax-common';
import {
  PermissionAccess,
  ProductLine,
  TicketDto,
  VoidFunc
} from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import { connect } from 'react-redux';
import Popover from '@mui/material/Popover';
import ReactToPrint from 'react-to-print';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import styles from './Ticket.module.css';
import TicketDetails from './TicketDetails';
import RouteInfo from './RouteInfo';
import {
  centerTicketPrintView, updateTicketView, clearSelectedTicket, needToRefreshData
} from '../../../../store/actions/ticketActions';
import fetchReceiptPDF from '../../../../services/receiptService';
import {
  VIEW_TICKET_DETAILS,
  VIEW_TICKET_QUALITY_FACTORS,
  VIEW_TICKET_ROUTE,
  DOWNLOAD_RECEIPTS,
  READYMIX_VALUE,
  ADMIN_LABELS,
  TICKET_SINGLE_COSE,
  ACCENT,
  ARE_YOU_SURE,
  CONFIRM_CLOSE_SINGLE_TICKET,
  CANCEL_LABEL,
  TICKET_CLOSED_SUCCESS,
  TICKET_CLOSED_ERROR,
  SUCCESS,
  ERROR,
} from '../../../../constants/appConstants';
import TicketQualityFactors from './TicketQualityFactors';
import CardImage from '../../forms/CardImage';
import { userHasEditPermissionInAnyRegion } from '../../../../services/permissionsService';
import printerBlue from '../../../../assets/img/printer-blue.svg';
import { openModal, closeModal } from '../../../../store/actions/errorModalActions';
import { closeOpenTicket } from '../../../../services/ticketsService';
import { openSnackbar } from '../../../../store/actions/snackbarActions';
import { ConnectedFunction } from '../../../../types';
import { OPEN_TICKETS_TEXT } from '../../../../constants/navConstants';

const PRINT_TYPE = {
  SECTION: 'section',
  ENTIRE_TICKET: 'entire ticket',
};

const ButtonWrapper = (text: string) => <button>{text}</button>;
export const TicketRef = (state: any) => state.ticketRef as any;

export class Ticket extends Component<TicketProps, TicketState> {
  static defaultProps = {
    selectedTicket: {},
    ticketView: VIEW_TICKET_DETAILS,
    selectedProductLine: null,
    isOpenTicket: false,
  };

  eventTimeout?: NodeJS.Timeout;

  constructor(props: TicketProps) {
    super(props);
    this.state = {
      isPrintOpen: false,
      ticketRef: null,
      printType: PRINT_TYPE.SECTION,
      enableDownloadReceipts: false,
    };
  }

  componentDidMount() {
    // this triggers a resize event due to bug with Tab indicator with incorrect size
    // when inside a Popover (related with animation timing)
    this.eventTimeout = global.setTimeout(() => window.dispatchEvent(new CustomEvent('resize')), 0);
    this.updatePermissions();
  }

  componentDidUpdate() {
    this.updatePermissions();
  }

  updatePermissions = () => {
    const enableDownloadReceipts = userHasEditPermissionInAnyRegion(this.props.userPermission, DOWNLOAD_RECEIPTS);
    if (enableDownloadReceipts !== this.state.enableDownloadReceipts) {
      this.setState({ enableDownloadReceipts });
    }
  };

  componentWillUnmount() {
    clearTimeout(this.eventTimeout!);
  }

  handleChange = (event?: React.ChangeEvent<{}>, selected?: string) => {
    if (selected && this.props.ticketView !== selected) {
      this.props.updateTicketView(selected!);
    }
  };

  onPrintOpen = (event?: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      printButtonRef: event?.currentTarget,
      isPrintOpen: true,
    });
  };

  onPrintClose = () => {
    this.setState({
      printButtonRef: undefined,
      isPrintOpen: false,
    });
    this.props.centerTicketPrintView(false);
  };

  onBeforePrint = (printType: string) => new Promise((resolve: any) => {
    this.setState({ printType });
    const renderComponentTimeout = 650;
    const { ticketView } = this.props;
    const shouldChangeTab = (ticketView !== VIEW_TICKET_ROUTE)
      && (printType === PRINT_TYPE.ENTIRE_TICKET);

    this.props.centerTicketPrintView(true);

    if (shouldChangeTab) {
      // ticket has to be in the route tab to render the map.
      this.handleChange(undefined, VIEW_TICKET_ROUTE);
      global.setTimeout(resolve, 3000);
    } else {
      global.setTimeout(resolve, renderComponentTimeout);
    }
  });

  renderPrintButton = () => {
    const buttonStyle = this.state.isPrintOpen
      ? styles.printBtnClicked
      : styles.printBtn;

    return (
      <span>
        <Popover
          open={this.state.isPrintOpen}
          onClose={this.onPrintClose}
          anchorEl={this.state.printButtonRef}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <div className={styles.printPopover}>
            <ReactToPrint
              trigger={() => ButtonWrapper('Entire ticket')}
              content={TicketRef(this.state)}
              onBeforeGetContent={() => this.onBeforePrint(PRINT_TYPE.ENTIRE_TICKET)}
              onAfterPrint={() => this.onPrintClose()}
            />
            <ReactToPrint
              trigger={() => ButtonWrapper('Only this section')}
              content={TicketRef(this.state)}
              onBeforeGetContent={() => this.onBeforePrint(PRINT_TYPE.SECTION)}
              onAfterPrint={() => this.onPrintClose()}
            />
          </div>
        </Popover>
        <button className={buttonStyle} onClick={this.onPrintOpen}>
          <CardImage src={printerBlue} className={styles.printIcon} />
          <span>Print</span>
          <i className="icon-keyboard-arrow-down" />
        </button>
      </span>
    );
  };

  renderCloseTicketButton = () => {
    const buttonSectionClass = styles.enabledCloseSection;
    const hasPermissionAccess = this.props.ticketsPermissionAccess === PermissionAccess.Edit;

    return (
      hasPermissionAccess && (
        <div className={buttonSectionClass}>
          <div key="action-buttons">
            <button
              onClick={this.confirmCloseTicket}
            >
              <RemoveCircleOutline />
              <span>{ADMIN_LABELS.CLOSE_TICKET_SINGULAR}</span>
            </button>
          </div>
        </div>
      )
    );
  };

  confirmCloseTicket = () => {
    const modalBody = (
      <span>
        <p>{TICKET_SINGLE_COSE}</p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.closeTicket,
      acceptText: CONFIRM_CLOSE_SINGLE_TICKET,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  closeTicket = async () => {
    const toastMessages = {
      success: TICKET_CLOSED_SUCCESS,
      fail: TICKET_CLOSED_ERROR,
    };

    if (this.props.selectedTicket) {
      const ticket = { ...this.props.selectedTicket, closed: true };
      const update = await closeOpenTicket(ticket);
      if (update instanceof Error) {
        this.props.openSnackbar({
          snackbarBody: ` ${toastMessages.fail} ${update.message}`,
          dataTest: 'close-tickets-error-snackbar',
          snackbarType: ERROR,
        });
        this.props.closeModal();
      } else {
        this.props.openSnackbar({
          snackbarBody: toastMessages.success,
          dataTest: 'close-tickets-success-snackbar',
          snackbarType: SUCCESS,
        });
        this.props.closeModal();
        this.props.clearSelectedTicket();
        this.props.needToRefreshData(true);
        this.props.closePopover();
      }
    }
  };

  clickReceiptPDF = () => {
    const { selectedTicket } = this.props;
    const receiptUrl = selectedTicket?.receiptUrl;
    fetchReceiptPDF(receiptUrl!);
  };

  renderBodyElement = (element: JSX.Element, isVisible: boolean, breakPage?: boolean) => {
    if (isVisible) {
      return element;
    }

    if (this.state.isPrintOpen) {
      const { printType } = this.state;
      const renderEntireTicket = printType === PRINT_TYPE.ENTIRE_TICKET;

      let hiddenClass = renderEntireTicket
        ? styles.printVisibleOnly
        : styles.hidden;

      if (renderEntireTicket && breakPage) {
        hiddenClass = cx(hiddenClass, styles.pageBreak);
      }

      return (
        <span className={hiddenClass}>
          {element}
        </span>
      );
    }

    return null;
  };

  renderBody = () => {
    const {
      ticketView, selectedTicket, selectedProductLine, shrinkPopover, history,
    } = this.props;
    const shouldDisplayQFTab = selectedTicket?.closed && selectedProductLine === READYMIX_VALUE;

    const ticketDetailsElement = this.renderBodyElement(
      (<TicketDetails
        selectedTicket={selectedTicket}
        plant={selectedTicket?.plant}
        plantList={[]}
        shrinkPopover={shrinkPopover}
        history={history}
      />),
      (ticketView === VIEW_TICKET_DETAILS)
    );

    const routeInfoElement = this.renderBodyElement(
      (<RouteInfo />),
      (ticketView === VIEW_TICKET_ROUTE)
    );

    const qualityFactorsElement = this.renderBodyElement(
      (<TicketQualityFactors ticket={selectedTicket} />),
      (ticketView === VIEW_TICKET_QUALITY_FACTORS),
      true
    );

    return (
      <>
        <h1 className={styles.printHeader}>
          Ticket #
          {selectedTicket?.ticketNumber}
        </h1>
        {ticketDetailsElement}
        {routeInfoElement}
        {shouldDisplayQFTab && qualityFactorsElement}
      </>
    );
  };

  renderReceiptButton = () => {
    const { selectedTicket } = this.props;

    const hasReceipt = !!(selectedTicket?.receiptUrl);
    const buttonClassName = cx(styles.receiptBtn, hasReceipt ? 'tt-btn-secondary--accent' : styles.receiptBtnDisabled);
    const iconClassName = cx('icon-receipt', styles.receiptBtnIcon, hasReceipt ? {} : styles.receiptBtnIconDisabled);

    return (
      <Tooltip
        text="No receipt available"
        disableHoverListener={hasReceipt}
        disableTouchListener={hasReceipt}
      >
        <span>
          <Button
            dataTest="ticket-view-receipt"
            name="View Receipt"
            buttonClassName={buttonClassName}
            iconClassName={iconClassName}
            hidden={this.props.ticketView !== VIEW_TICKET_DETAILS
              || selectedTicket?.closed === false}
            onClick={this.clickReceiptPDF}
          />
        </span>
      </Tooltip>
    );
  };

  render() {
    const {
      ticketView, selectedTicket, selectedProductLine, isOpenTicket
    } = this.props;
    const shouldDisplayQFTab = selectedTicket?.closed && selectedProductLine === READYMIX_VALUE;
    let defaultView = ticketView;
    if (defaultView === VIEW_TICKET_QUALITY_FACTORS && !shouldDisplayQFTab) {
      defaultView = VIEW_TICKET_DETAILS;
      // Refresh redux ticket view
      this.props.updateTicketView(defaultView);
    }

    return (
      <Paper
        id="ticketPopover-paperElement"
        className={styles.paper}
      >
        <div className={styles.detailHeader}>
          <Tabs
            value={defaultView}
            onChange={this.handleChange}
          >
            <Tab
              label="Details"
              value={VIEW_TICKET_DETAILS}
            />
            {!isOpenTicket && (
              <Tab
                label="Route Info"
                value={VIEW_TICKET_ROUTE}
              />
            )}
            {shouldDisplayQFTab && (
              <Tab
                label="Quality Factors"
                value={VIEW_TICKET_QUALITY_FACTORS}
              />
            )}
          </Tabs>

          {isOpenTicket ? this.renderCloseTicketButton() : this.renderPrintButton()}
          {this.state.enableDownloadReceipts && this.renderReceiptButton()}
        </div>
        {this.renderBody()}
      </Paper>
    );
  }
}

export interface TicketProps {
  selectedTicket?: TicketDto,
  ticketView?: string,
  updateTicketView: (selected: string) => void,
  centerTicketPrintView: (isPrintView: boolean) => void,
  selectedProductLine?: ProductLine,
  userPermission: any,
  shrinkPopover?: () => void,
  history: {
    push: (path: string) => void
  };
  isOpenTicket: boolean,
  openModal: typeof openModal,
  closeModal: typeof closeModal,
  clearSelectedTicket: VoidFunc,
  closePopover: () => void,
  openSnackbar: ConnectedFunction<typeof openSnackbar>;
  needToRefreshData: (needToRefreshData: boolean) => void,
  ticketsPermissionAccess?: string
}

export interface TicketState {
  printButtonRef?: HTMLButtonElement,
  isPrintOpen: boolean,
  ticketRef?: React.Ref<unknown>,
  printType: typeof PRINT_TYPE.SECTION | typeof PRINT_TYPE.ENTIRE_TICKET,
  enableDownloadReceipts: boolean,
}

export default connect<any, any, any>(
  (state: any) => ({
    selectedProductLine: state.selectedProductLine,
    selectedTicket: state.selectedTicket,
    ticketView: state.ticketView,
    userPermission: state.userPermission,
    isOpenTicket: state.isOpenTicket,
    ticketsPermissionAccess: state.adminPermissionAccess[OPEN_TICKETS_TEXT] ?? '',
  }),
  {
    updateTicketView, centerTicketPrintView, openModal, closeModal, clearSelectedTicket, openSnackbar, needToRefreshData
  }
)(Ticket as any);
