import React from 'react';
import cx from 'classnames';
import { vars } from '@trucktrax/trucktrax-common';

export function StatusValue(props: StatusValueProps) {
  const { label, icon = '' } = props;
  const labelCapitalized = label.charAt(0).toUpperCase() + label.slice(1);

  const getIcon = () => {
    let iconClass;
    let iconColor;
    const labelValue = labelCapitalized;
    const iconProp = icon;
    const standardValues = ['Active', 'Deactivated', 'Inactive'];
    const notStandardType = !standardValues.includes(labelValue);

    if (notStandardType) {
      if (iconProp) {
        iconClass = `icon-${iconProp}`;
        iconColor = vars.blue;
      } else { return false; }
    }
    if (labelValue === 'Active') {
      iconClass = 'icon-check-circle';
      iconColor = vars.green;
    }
    if (labelValue === 'Deactivated' || labelValue === 'Inactive') {
      iconClass = 'icon-cancel';
      iconColor = vars.red;
    }

    return (
      <i
        className={cx(iconClass, 'margin-right-5')}
        style={{ color: iconColor, fontSize: 16 }}
      />
    );
  };

  return (
    <div className="flex-vertical-center margin-right-10">
      {getIcon()}
      <span
        style={{
          display: 'inline-block',
          margin: 0,
          minHeight: 0,
          lineHeight: 1,
          fontSize: '1.6rem',
          fontFamily: 'PublicSans Bold',
        }}
      >
        {labelCapitalized}
      </span>
    </div>
  );
}

export interface StatusValueProps {
  label: string;
  icon?: string;
}

export default StatusValue;
