import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';

import cx from 'classnames';
import { ChecklistDto, PermissionAccess } from '@trucktrax/trucktrax-ts-common';
import AdminCard, { AdminCardConfig, AdminErrors } from '../../shared/admin/AdminCard';
import { CHECKLISTS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { CHECKLISTS_TEXT } from '../../../constants/navConstants';
import { addToOrUpdateListInStore } from '../../../store/actions/dataTableActions';
import { updateDataTableRecord } from '../../../services/dataTableService';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  EDIT_BTN,
  SAVE_BTN,
  TEXT_DISPLAY,
  VALUE_MATCH_DISPLAY,
} from '../../../constants/appConstants';
import styles from './ChecklistDetailView.module.css';
import ChecklistQuestions from './ChecklistQuestions';
import { displayTriggerAccessor } from '../../../util/adminUtil';
import { UPDATE_CHECKLIST } from '../../../constants/actionConstants';
import getEditConfig from './ChecklistEditConfig';
import { ReduxState } from '../../../store';
import { ConnectedDispatchFunction } from '../../../types';

export interface ChecklistDetailViewState {
  edit: boolean;
  checklistDetails?: ChecklistDto;
}

interface ChecklistDetailUpdateObject extends Omit<ChecklistDto, 'regions'> {
  regions: { key: string }[]
}

export class ChecklistDetailView extends Component<ChecklistDetailViewProps, ChecklistDetailViewState> {
  constructor(props: ChecklistDetailViewProps) {
    super(props);

    this.state = {
      edit: false,
      checklistDetails: undefined,
    };
  }

  baseUrl = () => `${getGeotraxBaseUrl() + CHECKLISTS_PATH}/${this.props.match.params.id}`;

  handleCallback = (response: AxiosResponse<ChecklistDto>) => {
    const { data } = response;
    this.setData(data);
  };

  handleAlterData = (data: ChecklistDto) => {
    this.setData(data);
    return data;
  };

  setData = (data: ChecklistDto) => {
    this.setState({
      checklistDetails: { ...data },
    });
  };

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  save = (
    dto: ChecklistDetailUpdateObject,
    onSuccess: (response: AxiosResponse<ChecklistDto>) => void,
    onError: (errorMap: AdminErrors) => void
  ) => {
    dto.regions = dto.regions?.map((r) => ({
      ...r,
      region: {
        url: r.key,
      },
    }));

    this.updateChecklistDetails(dto, onSuccess, onError, this.baseUrl());
  };

  updateChecklistDetails = (
    dto: ChecklistDetailUpdateObject,
    onSuccess: (response: AxiosResponse<ChecklistDto>) => void,
    onError: (errorMap: AdminErrors) => void,
    url: string,
    hideSnackbar?: boolean
  ) => {
    const onRequestSuccess = (updatedDto: AxiosResponse<ChecklistDto>) => {
      this.setState({ edit: false });
      onSuccess(updatedDto);
      this.props.addToOrUpdateListInStore(updatedDto.data, UPDATE_CHECKLIST);
    };
    this.props.updateAdminTableData(url, dto, onRequestSuccess, onError, hideSnackbar);
  };

  render() {
    const { location, regionList, assignedRegionList } = this.props;
    const { checklistDetails } = this.state;

    const yesNoDisp = (
      <p className={styles.optionsCtr}>
        <span className={styles.optionItemCtr}>
          <i aria-hidden="true" className={cx('icon-close', styles.iconClose)} />
          <span> Yes </span>
        </span>
        <span className={styles.optionItemCtr}>
          <i aria-hidden="true" className={cx('icon-check', styles.iconCheck)} />
          <span> No </span>
        </span>
      </p>
    );
    const failPassDisp = (
      <p className={styles.optionsCtr}>
        <span className={styles.optionItemCtr}>
          <i aria-hidden="true" className={cx('icon-close', styles.iconClose)} />
          <span> Fail </span>
        </span>
        <span className={styles.optionItemCtr}>
          <i aria-hidden="true" className={cx('icon-check', styles.iconCheck)} />
          <span> Pass </span>
        </span>
      </p>
    );

    const readConfigWithoutChecklistsEditPermission: AdminCardConfig[] = [
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.CHECKLISTS_NAME,
        accessor: ADMIN_KEYS.NAME,
        className: styles.checklistsName,
        dataTest: 'checklists-drilldown-name',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.CHECKLISTS_PRODUCTLINES,
        accessor: ADMIN_KEYS.PRODUCT_LINES,
        className: styles.checklistsProductLines,
        dataTest: 'checklists-drilldown-productline',
        commaSeparated: true,
      },
      {
        type: VALUE_MATCH_DISPLAY,
        label: ADMIN_LABELS.CHECKLISTS_OPTIONS_FORMAT,
        accessor: ADMIN_KEYS.ANSWER_TYPE,
        className: styles.checklistsOptions,
        dataTest: 'checklists-drilldown-options-format',
        condition: 'YesNo',
        trueDisplay: yesNoDisp,
        falseDisplay: failPassDisp,
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.CHECKLISTS_OPTIONS_WHEN_TO_PRESENT,
        accessor: displayTriggerAccessor,
        className: styles.checklistsWhenPresent,
        dataTest: 'checklists-drilldown-display-trigger',
      },
    ];

    const readConfigWithChecklistsEditPermission: AdminCardConfig[] = [
      ...readConfigWithoutChecklistsEditPermission,
      {
        type: EDIT_BTN,
        name: 'Edit Details',
        iconClassName: 'icon-create',
        dataTest: 'checklists-drilldown-edit',
      },
    ];

    const editConfig: AdminCardConfig[] = [
      {
        type: SAVE_BTN,
      },
      ...getEditConfig(styles, regionList as any, assignedRegionList, checklistDetails),
    ];

    let configValue: AdminCardConfig[] = readConfigWithoutChecklistsEditPermission;
    let cardStyle = styles.wrapperDetails;

    if (this.props.checklistsPermissionAccess === PermissionAccess.Edit) {
      if (this.state.edit) {
        configValue = editConfig;
        cardStyle = styles.wrapperEdit;
      } else {
        configValue = readConfigWithChecklistsEditPermission;
      }
    }

    const additionalComponents = [
      (
        <div>
          <ChecklistQuestions
            checklistDetails={checklistDetails!}
            pathName={location.pathname}
            permissions={this.props.checklistsPermissionAccess}
            detailsCallback={this.handleAlterData}
          />
        </div>
      ),
    ];

    return (
      <AdminCard
        edit={this.state.edit}
        url={this.baseUrl()}
        className={cardStyle}
        onToggleEdit={this.toggleEdit}
        save={this.save}
        config={configValue}
        pathName={location.pathname}
        headerAccessor="name"
        additionalComponents={additionalComponents}
        options={{
          callback: this.handleCallback,
          alterData: this.handleAlterData,
          requiredKeys: [],
        }}
      />
    );
  }
}

export const mapStateToProps = (state: ReduxState) => ({
  checklistsPermissionAccess: state.adminPermissionAccess[CHECKLISTS_TEXT] ?? '',
  regionList: state.regionList,
  assignedRegionList: state.assignedRegionList ?? [],
});

type ChecklistDetailViewReduxStateProps = ReturnType<typeof mapStateToProps>;
type ChecklistDetailViewReduxsDispatchProps = {
  updateAdminTableData: ConnectedDispatchFunction<typeof updateDataTableRecord>;
  addToOrUpdateListInStore: typeof addToOrUpdateListInStore;
};
type ChecklistDetailViewOwnProps = {
  match: {
    params: {
      id: string
    }
  },
  location: { pathname: string },
};

export type ChecklistDetailViewProps = ChecklistDetailViewReduxStateProps
  & ChecklistDetailViewReduxsDispatchProps
  & ChecklistDetailViewOwnProps;

export default connect(mapStateToProps, {
  updateAdminTableData: updateDataTableRecord,
  addToOrUpdateListInStore,
})(ChecklistDetailView);
