import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FloatingActionButton, HeaderAndContainer } from '@trucktrax/trucktrax-common';
import { FeatureFlagDto } from '@trucktrax/trucktrax-ts-common';
import styles from './FeatureFlags.module.css';
import { closeModal, openModal } from '../../../store/actions/errorModalActions';
import { openSnackbar, SnackbarOptions } from '../../../store/actions/snackbarActions';
import { fetchUsers } from '../../../services/usersService';
import { fetchTenantList } from '../../../services/tenantsService';
import { replaceAllFlags, replaceAllSubscriptions } from '../../../store/actions/featureFlagsActions';
import {
  deleteFeatureFlag,
  FeatureFlagPostInfo,
  fetchSubscriptionsForFlagUrl,
  postAndDeleteForNewSubscriptionList,
  postFeatureFlagWithSubscriptions,
  toggleFeatureFlag,
} from '../../../services/featureFlagsService';
import Header from '../../shared/Header';
import {
  ADD_FLAG_TITLE,
  EDIT_FLAG_TITLE,
  ERROR,
  FAIL,
  FLAGS_DELETE_CONFIRM,
  FLAGS_DELETE_CONFIRM_POSTFIX,
  FLAGS_DELETE_CONFIRM_PREFIX,
  OK,
  CANCEL_LABEL,
} from '../../../constants/appConstants';
import {
  CREATE_FLAG_FAIL_MSG,
  FEATURE_FLAG_DELETE_FAIL_MODAL_MESSAGE,
  FEATURE_FLAG_TOGGLE_FAIL_MODAL_MESSAGE,
} from '../../../constants/errorConstants';
import {
  SUCCESSFULLY_REMOVED,
  ADD_FLAG_SUCCESS_TEXT_ONE,
  ADD_FLAG_SUCCESS_TEXT_TWO,
} from '../../../constants/successConstants';
import { FLAGS_TEXT } from '../../../constants/navConstants';
import { ReduxState } from '../../../store';
import { ConnectedDispatchFunction, ModalOptions } from '../../../types';
import CardWithButton from './feature/CardWithButton';
import FeatureFlagBody from './feature/FeatureFlagBody';
import AddFeatureModal from '../../shared/AddFeatureModal';
import { noop } from '../../../util/appUtil';

export class FeatureFlagsView extends Component<FeatureFlagsViewProps, FeatureFlagsViewState> {
  signal = axios.CancelToken.source();

  state = {
    isOpen: false,
    flagToEdit: {},
    dataForDisplay: {
      regionsData: [],
      driversData: [],
      usersData: [],
      tenantsData: [],
    },
    triggerTitleError: false,
    titleErrorMessage: '',
  };

  componentDidMount() {
    this.props.fetchUsers('', this.signal.token);
    this.props.fetchTenantList(this.signal.token);
  }

  static getDerivedStateFromProps(nextProps: FeatureFlagsViewProps, prevState: FeatureFlagsViewState) {
    if (nextProps.regionList !== prevState.dataForDisplay.regionsData
      || nextProps.drivers !== prevState.dataForDisplay.driversData
      || nextProps.usersList !== prevState.dataForDisplay.usersData
      || nextProps.tenantsList !== prevState.dataForDisplay.tenantsData
    ) {
      return {
        dataForDisplay: {
          regionsData: nextProps.regionList,
          driversData: nextProps.drivers,
          usersData: nextProps.usersList,
          tenantsData: nextProps.tenantsList,
        },
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.signal.cancel('Api is being canceled');
  }

  onPermissionClick = (flag: FeatureFlagDto) => {
    // Call Backend for list of Subscriptions for flag.id with Action
    this.props.fetchSubscriptionsForFlagUrl(flag.url!);
    // Set State so it opens modal with flag info, letting it know its 'edit'
    this.setState({
      isOpen: true,
      flagToEdit: flag,
    });
  };

  onToggleClick = (flag: FeatureFlagDto, reToggle: VoidFunction) => {
    this.props.toggleFeatureFlag(flag, this.props.flags.allFlags, this.onToggleFeatureFlagFailure, reToggle);
  };

  onToggleFeatureFlagFailure = (err: any) => {
    const error: ModalOptions = {
      modalOpen: true,
      modalTitle: FEATURE_FLAG_TOGGLE_FAIL_MODAL_MESSAGE,
      modalBody: err.toString(),
      modalType: FAIL,
      acceptDialog: noop,
      acceptText: '',
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    };

    this.props.openModal(error);
  };

  onDeleteClick = (flag: FeatureFlagDto) => {
    const warning = `${FLAGS_DELETE_CONFIRM_PREFIX} "${flag.name}" ${FLAGS_DELETE_CONFIRM_POSTFIX}`;
    const modal: ModalOptions = {
      acceptDialog: () => this.onFlagDeleteAccept(flag),
      modalType: ERROR,
      modalBody: warning,
      modalTitle: FLAGS_DELETE_CONFIRM,
      modalOpen: true,
      acceptText: OK,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    };

    this.props.openModal(modal);
  };

  onFlagDeleteAccept = (flag: FeatureFlagDto) => {
    this.props.deleteFeatureFlag(
      flag,
      this.onDeleteFeatureFlagSuccess,
      this.onDeleteFeatureFlagFailure
    );
  };

  onDeleteFeatureFlagSuccess = (flag: FeatureFlagDto) => {
    const options: SnackbarOptions = {
      snackbarBody: `${FLAGS_DELETE_CONFIRM_PREFIX} "${flag.name}" ${SUCCESSFULLY_REMOVED}`,
      dataTest: 'delete-feature-flag-snackbar',
      snackbarType: 'DEFAULT',
    };

    this.props.openSnackbar(options);

    this.props.closeModal();
  };

  onDeleteFeatureFlagFailure = (err: any) => {
    const error: ModalOptions = {
      modalOpen: true,
      modalTitle: FEATURE_FLAG_DELETE_FAIL_MODAL_MESSAGE,
      modalBody: err.toString(),
      modalType: FAIL,
      acceptDialog: noop,
      acceptText: '',
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    };

    this.props.openModal(error);
  };

  onPostFeatureFlagSuccess = (
    closeModalCallback: VoidFunction,
    enableButtonCallback: VoidFunction,
    info: FeatureFlagPostInfo,
    flag: FeatureFlagDto[]
  ) => {
    const snackbarOptions: SnackbarOptions = {
      snackbarBody: `${ADD_FLAG_SUCCESS_TEXT_ONE} ${info.featureFlag.name} ${ADD_FLAG_SUCCESS_TEXT_TWO}`,
      dataTest: 'create-feature-flag-snackbar',
      snackbarType: 'DEFAULT',
    };

    this.props.openSnackbar(snackbarOptions);
    enableButtonCallback();
    closeModalCallback();
    this.props.replaceAllFlags({ featureFlags: flag });
  };

  onPostFeatureFlagFailure = (err: any, enableButtonCallback: VoidFunction) => {
    const error: ModalOptions = {
      modalOpen: true,
      modalTitle: err.toString(),
      modalBody: CREATE_FLAG_FAIL_MSG,
      modalType: FAIL,
      acceptDialog: noop,
      acceptText: '',
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    };

    if (err.response !== undefined && err.response.status === 409) {
      if (err.response.data) {
        // do something
        this.setState({
          triggerTitleError: true,
          titleErrorMessage: err.response.data.message,
        });
      } else {
        // show error modal
        this.props.openModal(error);
      }
    } else {
      // show error modal
      this.props.openModal(error);
    }

    enableButtonCallback();
  };

  onAccept = async (info: FeatureFlagPostInfo, closeModalCallback: VoidFunction, enableButtonCallback: VoidFunction) => {
    await postFeatureFlagWithSubscriptions(
      info,
      (flags?: FeatureFlagDto[]) => this.onPostFeatureFlagSuccess(closeModalCallback, enableButtonCallback, info, flags ?? []),
      (err) => this.onPostFeatureFlagFailure(err, enableButtonCallback)
    );
  };

  flagItems = () => {
    if (this.props.flags.allFlags.length === 0) {
      return <h2>You do not have any Feature Flags currently available.</h2>;
    }

    let featureFlags = this.props.flags.allFlags;
    featureFlags = featureFlags.sort((a, b) => a.name!.toLowerCase().localeCompare(b.name!.toLowerCase()));

    return featureFlags.map((flag) => (
      <CardWithButton
        primaryText={flag.name}
        secondaryText={flag.description || ''}
        onPermissionClick={() => {
          this.onPermissionClick(flag);
        }}
        onDeleteClick={() => {
          this.onDeleteClick(flag);
        }}
        onToggleClick={(reToggle: VoidFunction) => {
          this.onToggleClick(flag, reToggle);
        }}
        archived={flag.archived}
        key={flag.id}
      />
    ));
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
      flagToEdit: {},
      triggerTitleError: false,
      titleErrorMessage: '',
    });
    // removes subscriptions from store
    this.props.replaceAllSubscriptions();
  };

  addFeature = () => {
    this.setState({
      isOpen: true,
      flagToEdit: {},
    });
    // removes subscriptions from store
    this.props.replaceAllSubscriptions();
  };

  handleTitleErrorReset = () => {
    this.setState({
      triggerTitleError: false,
      titleErrorMessage: '',
    });
  };

  render() {
    const isEditModal = Object.keys(this.state.flagToEdit).length > 0;
    const featureFlagRender = (
      <div className={styles.adminContainer}>
        {this.flagItems()}
        <AddFeatureModal
          title={isEditModal ? EDIT_FLAG_TITLE : ADD_FLAG_TITLE}
          isOpen={this.state.isOpen}
          onCancel={this.handleClose}
          style={styles.addFeatureFlagModal}
        >
          <FeatureFlagBody
            data={this.state.dataForDisplay}
            closeModal={this.handleClose}
            onAddAFeatureButtonClick={this.onAccept}
            onEditAFeatureButtonClick={this.props.postAndDeleteForNewSubscriptionList as any}
            flagToEdit={this.state.flagToEdit as FeatureFlagDto}
            flagToEditSubscriptions={this.props.flags.subscriptions}
            requestError={this.state.triggerTitleError}
            resetError={this.handleTitleErrorReset}
            requestErrorMessage={[this.state.titleErrorMessage]}
          />
        </AddFeatureModal>
      </div>
    );

    const elementsLeft = <Header title={FLAGS_TEXT} />;

    return (
      <div>
        <HeaderAndContainer
          elementsLeft={elementsLeft}
          containerComponent={featureFlagRender}
          containerStyle={styles.featureFlagContainer}
        />
        <FloatingActionButton onClick={this.addFeature} />
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    flags: state.flags,
    drivers: state.driverList.drivers,
    regionList: state.regionList,
    usersList: state.usersList.users,
    tenantsList: state.tenantsList.tenants,
  };
}

type FeatureFlagsViewReduxStateProps = ReturnType<typeof mapStateToProps>;

type FeatureFlagsViewDispatchProps = {
  openModal: typeof openModal,
  closeModal: typeof closeModal,
  openSnackbar: typeof openSnackbar;
  fetchUsers: ConnectedDispatchFunction<typeof fetchUsers>;
  fetchTenantList: ConnectedDispatchFunction<typeof fetchTenantList>;
  replaceAllFlags: typeof replaceAllFlags;
  toggleFeatureFlag: ConnectedDispatchFunction<typeof toggleFeatureFlag>,
  fetchSubscriptionsForFlagUrl: ConnectedDispatchFunction<typeof fetchSubscriptionsForFlagUrl>,
  replaceAllSubscriptions: typeof replaceAllSubscriptions;
  postAndDeleteForNewSubscriptionList: ConnectedDispatchFunction<typeof postAndDeleteForNewSubscriptionList>;
  deleteFeatureFlag: ConnectedDispatchFunction<typeof deleteFeatureFlag>;
};

type FeatureFlagsViewOwnProps = {
  // empty
};

export type FeatureFlagsViewProps = FeatureFlagsViewReduxStateProps & FeatureFlagsViewDispatchProps & FeatureFlagsViewOwnProps;

export interface FeatureFlagsViewState {
  isOpen: boolean;
  flagToEdit: FeatureFlagDto | {};
  dataForDisplay: {
    regionsData: any[];
    driversData: any[];
    usersData: any[];
    tenantsData: any[];
  };
  triggerTitleError: boolean;
  titleErrorMessage: string;
}

export default connect<any, any, any>(mapStateToProps as any, {
  openModal,
  closeModal,
  openSnackbar,
  fetchUsers,
  fetchTenantList,
  replaceAllFlags,
  toggleFeatureFlag,
  fetchSubscriptionsForFlagUrl,
  replaceAllSubscriptions,
  postAndDeleteForNewSubscriptionList,
  deleteFeatureFlag,
})(FeatureFlagsView);
