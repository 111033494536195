import React from 'react';
import cx from 'classnames';
import styles from './EmptyView.module.css';

export function EmptyView(props: EmptyViewProps) {
  const { icon, text } = props;

  return (
    <div className={styles.view}>
      <i className={cx(styles.icon, icon)} />
      <p>{text}</p>
    </div>
  );
}

export interface EmptyViewProps {
  icon: string;
  text: string;
}

export default EmptyView;
