import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import { devErrorAndLog } from '../util/errorUtil';
import { downloadBlobFromUrl, getFileNameFromHeaders } from '../util/reportsUtil';
import { noop } from '../util/appUtil';
import { getTokens } from '../util/authUtil';
import { CONTENT_TYPE_PDF } from '../constants/appConstants';
import { STATIC_PATH } from '../constants/apiConstants';
import { getGeotraxBaseUrl } from '../util/apiUtil';

export default async function fetchReceiptPDF(
  receiptUrl: string,
  download: boolean = false,
  onSuccess: VoidFunc = noop,
  onError: VoidFunc = noop
) {
  if (!receiptUrl) {
    return;
  }
  const { accessToken: tk } = getTokens();
  const url = `${getGeotraxBaseUrl() + STATIC_PATH}?file=${receiptUrl}&tk=${tk}`;
  try {
    const response = await fetch(url, { headers: { Accept: CONTENT_TYPE_PDF } });
    const filename = getFileNameFromHeaders(response);
    const blob = await response.blob();
    const contentType = response.headers.get('Content-Type');
    if (!blob.size || !contentType) {
      throw new Error('PDF does not exist.');
    }
    const isPdf = contentType.includes(CONTENT_TYPE_PDF);
    if (!isPdf) {
      throw new Error('Stored file is not a PDF.');
    }
    downloadBlobFromUrl(blob, filename, !download);
    onSuccess();
  } catch (e: any) {
    devErrorAndLog('Not found', `url: ${url}`, e.toString());
    onError();
  }
}
