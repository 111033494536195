import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PermissionAccess, UserRegionDto } from '@trucktrax/trucktrax-ts-common';
import { withRouter } from 'react-router-dom';
import DataTableContent from '../../shared/DataTableContent';
import { addDataTest, addHeaderDataTest } from '../../../util/adminUtil';
import RegionsAddModal from './RegionsAddModal';
import { REGIONS_TEXT } from '../../../constants/navConstants';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  AGGREGATE_VALUE,
  CEMENT_VALUE,
  NOT_AVAILABLE,
  READYMIX_VALUE,
} from '../../../constants/appConstants';
import { DATASOURCE, REGIONS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ReduxState } from '../../../store';
import { isUserWithAtleastOneOfGivenPermissions } from '../../../util/permissionUtil';
import { UserPermissionsByRegion } from '../../../types';
import { isEmptyObject } from '../../../util/appUtil';
import { devErrorAndLog } from '../../../util/errorUtil';

export class RegionsView extends Component<RegionsViewProps, RegionsViewState> {
  state = {
    isOpen: false,
    isCloseRequest: false,
  };

  static abbreviateProductLines = (sourceData: string) => {
    let data = sourceData;
    if (!data || data.length === 0) {
      return NOT_AVAILABLE;
    }

    try {
      if (Array.isArray(data)) {
        data = data.join();
      }
      const abbreviatedProductLines: string = data.split(',').map((item) => {
        switch (item) {
          case AGGREGATE_VALUE:
            return 'AGG';
          case CEMENT_VALUE:
            return 'CEM';
          case READYMIX_VALUE:
            return 'RM';
          default:
            return NOT_AVAILABLE;
        }
      })?.join(', ');
      return abbreviatedProductLines;
    } catch (err: any) {
      devErrorAndLog(
        'Encountered an error abbreviating and combining product lines',
        `Regions ${err.toString()}`,
        undefined,
        undefined,
        true
      );
      return NOT_AVAILABLE;
    }
  };

  static getColumns = () => [
    {
      Header: addHeaderDataTest(null, 'sort-region-by-name', ADMIN_LABELS.NAME),
      accessor: ADMIN_KEYS.NAME,
      minWidth: 150,
      Cell: (props: any) => addDataTest(props, 'admin-region-name', props.value),
    }, {
      Header: addHeaderDataTest(null, 'sort-region-by-product-lines', ADMIN_LABELS.PRODUCT_LINES),
      accessor: ADMIN_KEYS.PRODUCT_LINES,
      minWidth: 100,
      Cell: (props: { value: string; }) => addDataTest(
        null,
        'admin-region-product-lines',
        RegionsView.abbreviateProductLines(props.value)
      ),
    }, {
      Header: addHeaderDataTest(null, 'sort-region-by-description', ADMIN_LABELS.DESCRIPTION),
      accessor: ADMIN_KEYS.DESCRIPTION,
      minWidth: 200,
      sortable: false,
      Cell: (props: any) => addDataTest(props, 'admin-region-description', props.value),
    }, {
      Header: addHeaderDataTest(null, 'sort-region-by-latitude', ADMIN_LABELS.LATITUDE),
      accessor: ADMIN_KEYS.LATITUDE,
      minWidth: 200,
      sortable: false,
      Cell: (props: any) => addDataTest(props, 'admin-region-latitude', props.value),
    }, {
      Header: addHeaderDataTest(null, 'sort-region-by-longitude', ADMIN_LABELS.LONGITUDE),
      accessor: ADMIN_KEYS.LONGITUDE,
      minWidth: 200,
      sortable: false,
      Cell: (props: any) => addDataTest(props, 'admin-region-longitude', props.value),
    },
  ];

  requestCloseModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      isCloseRequest: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: false,
    });
  };

  render() {
    // wait until permissions object is passed
    if (isEmptyObject(this.props.userPermission)) {
      return <></>;
    }
    // only display the current user's assigned regions that they have VIEW or EDIT permissions for
    const regionList = this.props.regions.filter(
      region => isUserWithAtleastOneOfGivenPermissions(this.props.userPermission, region.url!, [REGIONS_TEXT])
    ).map(r => r.url).join(',');

    const baseUrl = `${getGeotraxBaseUrl() + REGIONS_PATH}/paged-assignment?destination=${regionList}`;
    const columns = RegionsView.getColumns();
    const hasPermissionAccess = this.props.regionsPermissionAccess === PermissionAccess.Edit;

    return (
      <div>
        <DataTableContent
          baseUrl={baseUrl}
          columns={columns}
          header={REGIONS_TEXT}
          noRegionFilter
          canAddNewItem={hasPermissionAccess}
          source={DATASOURCE.REGION}
        />
        {hasPermissionAccess && (
          <RegionsAddModal
            isOpen={this.state.isOpen}
            openModal={this.openModal}
            closeModal={this.requestCloseModal}
            discardAcceptAction={this.closeModal}
            discardRejectAction={this.openModal}
            isCloseRequest={this.state.isCloseRequest}
            history={this.props.history}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  regionsPermissionAccess: state.adminPermissionAccess[REGIONS_TEXT] ?? '',
  regions: state.assignedRegionList || {},
  userPermission: state.userPermission || {},
});

export interface RegionsViewProps {
  regionsPermissionAccess: string,
  regions: UserRegionDto[];
  userPermission: UserPermissionsByRegion;
  history: {
    push: (path: string) => void,
    location: {
      pathname: string
    }
  }
}

export interface RegionsViewState {
  isOpen: boolean;
  isCloseRequest: boolean;
}

export default withRouter(connect(mapStateToProps)(RegionsView) as any);
