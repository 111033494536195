import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import {
  scaleTraxDocsRouteNotFound,
  scaleTraxDocsRouteRedirect,
  scaleTraxRouteNotFound, scaleTraxRouteRedirect,
} from '../../routes';
import { setFavicon } from '../../util/appUtil';
import RedirectRoute from '../shared/routing/RedirectRoute';
import RouteWithSubRoutes from '../shared/routing/RouteWithSubRoutes';

export function ScaleTraxView(props: ScaleTraxViewProps) {
  useEffect(() => {
    document.title = 'ScaleTrax - TruckTrax';
    setFavicon('/favicon-scaletrax.ico');
  }, []);

  const configRoutes = props.routes.map((puRoute) => (
    <RouteWithSubRoutes
      key={puRoute.path}
      {...puRoute}
    />
  ));

  // add redirect for /scaletrax to /scaletrax/scaletraxticketing
  configRoutes.push(<RedirectRoute
    key={scaleTraxRouteRedirect.key}
    from={scaleTraxRouteRedirect.from}
    to={scaleTraxRouteRedirect.to}
  />);

  // add redirect for /scaletrax/* to 404
  configRoutes.push(<RedirectRoute
    key={scaleTraxRouteNotFound.key}
    from={scaleTraxRouteNotFound.from}
    to={scaleTraxRouteNotFound.to}
  />);

  // add redirect for /scaletrax/docs to /scaletrax/docs/guide/web
  configRoutes.push(<RedirectRoute
    key={scaleTraxDocsRouteRedirect.key}
    from={scaleTraxDocsRouteRedirect.from}
    to={scaleTraxDocsRouteRedirect.to}
  />);

  // add redirect for /scaletrax/docs/* to 404
  configRoutes.push(<RedirectRoute
    key={scaleTraxDocsRouteNotFound.key}
    from={scaleTraxDocsRouteNotFound.from}
    to={scaleTraxDocsRouteNotFound.to}
  />);

  return (
    <Switch>
      {configRoutes}
    </Switch>
  );
}

export interface Route {
  path?: string,
  component?: React.ComponentType<any>
}

export interface ScaleTraxViewProps {
  routes: Route[]
}

export default ScaleTraxView;
