import React, { Component } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import cx from 'classnames';
import { Tooltip } from '@trucktrax/trucktrax-common';
import styles from '@trucktrax/trucktrax-common/src/components/DropDownList.module.css';
import { PermissionAccess } from '@trucktrax/trucktrax-ts-common';

const IconChevronLeftWrapper = () => (
  <i aria-hidden="true" className={cx('icon-chevron-left', styles.iconRightCustom)} />
);

class PermissionsAccessDropDownList<TItem extends HasLabel>
  extends Component<PermissionsAccessDropDownListProps<TItem>> {
  static defaultProps = {
    items: [],
    initialSelected: {
      icon: 'icon',
      label: 'label',
    },
    dataTest: 'permission-drop-down',
    segmented: false,
    isDisabled: false,
  };

  dropDownRef: React.RefObject<any>;

  constructor(props: PermissionsAccessDropDownListProps<TItem>) {
    super(props);
    this.dropDownRef = React.createRef();
  }

  state = {
    open: false,
  };

  getItemsWidth = () => {
    const hasDropDownRef = !!(this.dropDownRef && this.dropDownRef.current);
    if (hasDropDownRef) {
      return getComputedStyle(this.dropDownRef.current).width;
    }
    return '100%';
  };

  handleChange = (event: any) => {
    const value = this.props.items.find(item => (item.label === event.target.value));
    if (value) {
      this.props.updateSelected(value!);
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  static withIcon = (selectedLabel: string, text: string) => (
    selectedLabel === text
      ? (
        <span className={styles.selectedText}>
          {text}
          <i aria-hidden="true" className={cx('icon-check', styles.checkmarkIcon)} />
        </span>
      )
      : text
  );

  static menuItemTextStyle = (text: string) => {
    // using item value to determine font's color.
    let txtColor = '';
    if (text) {
      if (text.toLowerCase() === 'none') {
        txtColor = styles.redBoldText;
      } else if (text.toLowerCase() === 'view') {
        txtColor = styles.blueText;
      } else if (text.toLowerCase() === 'edit') {
        txtColor = styles.greenText;
      }
    }
    return txtColor;
  };

  menuItem = (item: TItem, selectedLabel: string) => {
    const label = item.label.toUpperCase() === PermissionAccess.Deny.toUpperCase() ? 'NONE' : item.label;
    return (
      <MenuItem
        className={cx(styles.menuItem, PermissionsAccessDropDownList.menuItemTextStyle(label))}
        classes={{
          selected: styles.selected,
        }}
        data-test={`${this.props.dataTest}-menu-item`}
        key={label}
        value={item.label}
      >
        {PermissionsAccessDropDownList.withIcon(selectedLabel, item.label)}
      </MenuItem>
    );
  };

  render() {
    const { initialSelected, items, isDisabled } = this.props;
    initialSelected.label = initialSelected.label.toUpperCase() === PermissionAccess.Deny.toUpperCase() ? 'NONE' : initialSelected.label;
    const renderValue = (selected: string) => (
      selected.length && (selected));
    const selectElm = (
      <Select
        open={this.state.open}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        value={initialSelected.label}
        onChange={this.handleChange}
        className={styles.adjSelectContainer}
        disabled={isDisabled}
        inputProps={{
          className: cx(
            isDisabled ? styles.disabledSelect : styles.select,
            PermissionsAccessDropDownList.menuItemTextStyle(initialSelected.label),
            this.props.segmented ? styles.segmented : null,
            { open: this.state.open }
          ),
        }}
        renderValue={v => renderValue(v as string)}
        IconComponent={IconChevronLeftWrapper}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          style: {
            maxHeight: '450px',
            width: this.getItemsWidth(),
            zIndex: 1501,
          },
          // needed to prevent dynamic anchor
          // getContentAnchorEl: null,
        }}
      >
        {items.map(item => (this.menuItem(item, initialSelected.label)))}
      </Select>
    );
    if (isDisabled) {
      return (
        <Tooltip
          theme=""
          key={1}
          text="You do not have access to edit this permission"
          placement="top"
          enterDelay={600}
          classes={{
            popper: styles.lockPopper,
            tooltip: styles.lockTooltip,
          }}
        >
          {selectElm}
        </Tooltip>
      );
    }
    return selectElm;
  }
}

// TYPE \\
// type SelectChangeEvent = React.PointerEvent<{
//   target: {
//     name?: string | undefined;
//     value: unknown;
//   }
// }>;

interface HasLabel {
  label: string;
}

export interface PermissionsAccessDropDownListProps<TItem extends HasLabel> {
  items: TItem[];
  initialSelected: {
    icon?: string,
    label: string
  };
  updateSelected: (item: TItem) => void;
  dataTest: string;
  segmented: boolean;
  isDisabled: boolean;
}

export default PermissionsAccessDropDownList;
