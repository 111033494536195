import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MessageTabs } from '@trucktrax/trucktrax-common/build/constants';
import { ProductLine, UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import { Driver, SidebarSelection } from '@trucktrax/trucktrax-common';
import {
  ConversationToMessagesDto,
  Message,
  MessageCenterInfo,
  PartyMember,
} from './index.d';
import { MessageCenter } from './MessageCenter';

import {
  messageSocketSubscription,
  setCurrentTab,
  updateMessageFieldValue,
  updateReplyMap,
  updateSubjectFieldValue,
  updateToFieldValue,
  updateCurrentRecipientSearchText,
} from '../../../store/actions/messageActions';
import { noop } from '../../../util/appUtil';
import {
  getInboxMessages,
  sendMessage,
  updateMessage,
  markUnread,
  AcknowledgeDto,
} from '../../../services/messagesService';
import setSidebarSelected from '../../../store/actions/sidebarActions';
import {
  MESSAGE_SELECTED,
  USERS_RELATIVE_URL,
  TICKETS_SELECTED,
} from '../../../constants/appConstants';
import { ConnectedDispatchFunction, SidebarTitleType } from '../../../types';

export class MessageCenterContainer extends Component<MessageCenterContainerProps, MessageCenterContainerState> {
  static defaultProps: Partial<MessageCenterContainerProps> = {
    driverList: [],
    updateMessageFieldValue: noop,
    updateReplyMap: noop,
    replyMap: new Map<string, string>(),
    setCurrentTab: noop,
    updateMessage: noop,
    getInboxMessages: noop,
    messageList: undefined,
    sendMessage: () => () => Promise.resolve(),
    setSidebarSelected: noop,
    currentTab: undefined,
    currentRegion: undefined,
    sidebarSelection: undefined,
    userUrl: undefined,
    selectedProductLine: undefined,
  };

  constructor(props: MessageCenterContainerProps) {
    super(props);
    this.state = {
      isMessageCenterOpen: false,
      isTicketsOpen: false,
    };
  }

  componentDidMount() {
    this.updateIsOpen(this.props);
  }

  componentDidUpdate(prevProps: MessageCenterContainerProps) {
    this.updateIsOpen(prevProps);
  }

  updateIsOpen = (prevProps: MessageCenterContainerProps) => {
    const isMessagesSidebar = this.props.sidebarSelection?.sidebarTitle === MESSAGE_SELECTED;
    const messagesSidebarHasChanged = isMessagesSidebar && this.props.sidebarSelection.isSelected !== this.state.isMessageCenterOpen;
    const hasChangedProductLine = this.props?.selectedProductLine !== prevProps?.selectedProductLine;

    if (hasChangedProductLine) {
      this.setState({
        isMessageCenterOpen: false,
      });
    } else if (messagesSidebarHasChanged) {
      this.setState({
        isMessageCenterOpen: this.props.sidebarSelection.isSelected,
      });
    }

    const isTicketsSidebar = this.props.sidebarSelection?.sidebarTitle === TICKETS_SELECTED;
    const ticketsSidebarHasChanged = isTicketsSidebar && this.props.sidebarSelection.isSelected !== this.state.isTicketsOpen;
    if (ticketsSidebarHasChanged) {
      this.setState({
        isTicketsOpen: this.props.sidebarSelection.isSelected,
      });
    }
  };

  onSubmit = (messageCenter: MessageCenterInfo, optionalMessageId: string | number | undefined, disableButtonCallback?: () => void) => {
    const { userUrl: user } = this.props;
    const partiesList = messageCenter.partiesList.slice();

    // remove personal dispatcher urls from partiesList.
    partiesList.filter(p => !p.url.includes(USERS_RELATIVE_URL));
    // add region to parties
    partiesList.push({
      url: this.props.currentRegion?.url ?? '',
      firstName: '',
      fullName: '',
      lastName: '',
      conversation: '',
      fullToUrl: '',
    });

    const sendObject: SendObject = {
      from: { url: user ?? '' },
      type: 'BriefMessage',
      body: messageCenter.messageFieldValue,
      conversation: messageCenter.conversation,
      responseRequired: true,
      partiesList,
    };

    this.props.sendMessage!(sendObject, optionalMessageId, disableButtonCallback, this.props.selectedProductLine!);
  };

  closeMessageCenter = () => {
    this.props.setSidebarSelected!(MESSAGE_SELECTED, false);
  };

  markUnread = (ack: AcknowledgeDto) => {
    this.props.markUnread(ack);
  };

  render() {
    return (
      <MessageCenter
        currentTab={this.props.currentTab!}
        currentRegion={this.props.currentRegion}
        onToFieldChange={this.props.updateToFieldValue}
        onCloseMessageCenter={this.closeMessageCenter}
        driverList={this.props.driverList!.filter(item => (item.primaryProductLine === this.props.selectedProductLine
          || item?.secondaryProductLine === this.props.selectedProductLine) && !item.gtLightAccess)}
        onMessageFieldChange={this.props.updateMessageFieldValue!}
        onCurrentRecipientSearchTextChange={this.props.updateCurrentRecipientSearchText}
        onSubmit={this.onSubmit as any}
        messageCenter={this.props.messageCenter}
        replyMap={this.props.replyMap!}
        onTabChange={this.props.setCurrentTab! as any}
        onAcknowledge={this.props.updateMessage!}
        onMarkUnread={this.markUnread}
        onUpdateReplyMap={this.props.updateReplyMap!}
        onGetInboxMessages={this.props.getInboxMessages!}
        allMessages={this.props.messageList?.allMessages ?? []}
        unReadCount={this.props.messageList?.unReadCount ?? false}
        messagesOpen={this.state.isMessageCenterOpen}
        selectedProductLine={this.props.selectedProductLine}
        isExpand={this.props.isExpand}
      />
    );
  }
}

type MessageList = {
  allMessages: ConversationToMessagesDto[],
  allSentMessages: Message[],
  responseAfterSend: {} | {}[]
  unReadCount: boolean;
};

export interface DriverWithProductLine extends Driver {
  primaryProductLine: ProductLine;
  secondaryProductLine: ProductLine;
  gtLightAccess: boolean;
}

export interface MessageCenterContainerProps {
  messageCenter: MessageCenterInfo;
  messageList?: MessageList;
  updateToFieldValue: () => void;
  updateCurrentRecipientSearchText: (newSearchText: string) => void;
  driverList?: DriverWithProductLine[];
  updateMessageFieldValue?: (message: string) => void;
  updateReplyMap?: () => void;
  replyMap?: Map<string, string>;
  setCurrentTab?: (tab: MessageTabs) => void;
  updateMessage?: () => void;
  getInboxMessages?: () => void;
  sendMessage?: typeof sendMessage;
  setSidebarSelected?: (sidebarTitle: SidebarTitleType, isSelected: boolean) => void;
  currentTab?: MessageTabs;
  selectedProductLine?: ProductLine;
  currentRegion?: UrlKeyDto;
  sidebarSelection: SidebarSelection;
  userUrl?: string;
  markUnread: ConnectedDispatchFunction<typeof markUnread>;
  isExpand?: boolean;
}

interface MessageCenterContainerState {
  isMessageCenterOpen: boolean;
  isTicketsOpen: boolean;
}

type SendObject = {
  from: UrlKeyDto;
  type: 'BriefMessage';
  body: string;
  conversation: UrlKeyDto;
  responseRequired: boolean;
  partiesList: PartyMember[];
};

function mapStateToProps(state: any) {
  return {
    messageCenter: state.messages,
    messageList: {
      allMessages: state.messageList.allMessages,
      allSentMessages: state.messageList.allSentMessages,
      responseAfterSend: state.messageList.message,
      unReadCount: state.messageList.unreadNotice,
    },
    currentRegion: state.currentRegion,
    currentTab: state.currentTab.currentTab,
    replyMap: state.replyMap,
    currentSubscription: state.messageSubscription,
    sidebarSelection: state.sidebar,
    userUrl: state.userUrl,
    selectedProductLine: state.selectedProductLine,
  };
}

export default connect<any, any, any>(mapStateToProps, {
  updateToFieldValue,
  updateCurrentRecipientSearchText,
  updateMessageFieldValue,
  updateSubjectFieldValue,
  updateReplyMap,
  sendMessage,
  getInboxMessages,
  updateMessage,
  setCurrentTab,
  messageSocketSubscription,
  setSidebarSelected,
  markUnread,
})(MessageCenterContainer);
