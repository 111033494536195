import React, { Component } from 'react';
import cx from 'classnames';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CardImage from '../forms/CardImage';
import styles from '../../geotrax/docs/guides/UserGuidesCommon.module.css';
import { LoadingCircle } from '../LoadingCircle';

export interface ResourceRowState {
  isDownloading: boolean;
}

export class ResourceRow extends Component<ResourceRowProps, ResourceRowState> {
  static defaultProps = {
    openInNewTabs: false,
  };

  constructor(props: ResourceRowProps) {
    super(props);
    this.state = {
      isDownloading: false,
    };
  }

  onEdit = () => {
    const {
      name,
      filepath,
      onEdit,
    } = this.props;
    onEdit(name, filepath);
  };

  onDownload = async () => {
    const {
      name,
      filepath,
      onDownload,
    } = this.props;

    this.setState({ isDownloading: true });
    await onDownload(name, filepath);
    this.setState({ isDownloading: false });
  };

  render() {
    const {
      name,
      filepath,
      onOpen,
      iconSrc,
      hasEditPermission,
      openInNewTabs,
    } = this.props;
    const filename = name.substring(name.lastIndexOf('/') + 1);
    return (
      <TableRow
        key={`idx-${filepath}`}
        hover
      >
        <TableCell className={styles.openPdfButton}>
          <button onClick={() => onOpen(name, filepath)}>
            <CardImage
              src={iconSrc}
              className={styles.pdfLogo}
            />
            <span className={styles.itemNameCtr}>
              <span className={styles.itemName}>{filename}</span>
              {openInNewTabs && <i className={cx('icon-new-tab', 'margin-left-5')} />}
            </span>
          </button>
        </TableCell>
        <TableCell className={styles.downloadButton}>
          <button
            onClick={this.onDownload}
            className={this.state.isDownloading ? styles.buttonDownloading : ''}
          >
            {this.state.isDownloading
              ? (
                <span className={styles.downloadButtonLoading}>
                  <LoadingCircle size={17} className={styles.downloadButtonLoading} />
                </span>
              ) : <i className="icon-file-download" />}
            <span>
              {this.state.isDownloading
                ? 'Downloading...'
                : 'Download'}
            </span>
          </button>
          {
            hasEditPermission
            && (
              <button onClick={this.onEdit} data-test={name}>
                <i className="icon-create" />
                <span>Edit</span>
              </button>
            )
          }
        </TableCell>
      </TableRow>
    );
  }
}

export interface ResourceRowProps {
  name: string;
  filepath: string;
  onEdit: (name: string, filepath: string) => void;
  onOpen: (name: string, filepath: string) => void;
  onDownload: (name: string, filepath: string) => void;
  iconSrc: string;
  hasEditPermission: boolean;
  openInNewTabs?: boolean;
}

export default ResourceRow;
