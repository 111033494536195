import React, { Component } from 'react';
import cx from 'classnames';
import { FloatingActionButton, Label } from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  PlantDto, ProductLine, UnitOfMeasure, UrlKeyDto, VoidFunc,
} from '@trucktrax/trucktrax-ts-common';
import {
  FormControl,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import styles from './PlantsAddModal.module.css';
import { addToOrUpdateListInStore } from '../../../store/actions/dataTableActions';
import { createDataTableRecord } from '../../../services/dataTableService';
import AdminAddModal from '../../shared/admin/AdminAddModal';
import { ADD_PLANT } from '../../../constants/actionConstants';
import {
  ADD_PLANT_MODAL_TITLE,
  ADMIN_KEYS,
  CIRCLE,
  EXTERNAL,
  INTERNAL,
  OR,
  POLYGON,
  SHOW_NO,
  SHOW_YES,
  READYMIX_VALUE,
  GEOZONE_TYPE,
  PLANT_ADD_MODAL_ORDER,
  ADMIN_LABELS,
  AGGREGATE_VALUE, GEOTRAX_LITE_FLAG,
} from '../../../constants/appConstants';
import { GEOZONES_PATH, PLANTS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ADD_PLANT_ERROR_MESSAGE } from '../../../constants/errorConstants';
import { ADD_PLANT_SUCCESS_TEXT } from '../../../constants/successConstants';
import modalConfig from './PlantsAddModalConfigs';
import { noop } from '../../../util/appUtil';
import AddFeatureModal from '../../shared/AddFeatureModal';
import {
  defaultProductLineRadioOptions,
  defaultScaleUnitOptions,
  defaultLoadingTimeOptions,
} from '../../../util/adminUtil';
import { RegionAccessMap } from '../../../util/permissionUtil';
import { isFeatureFlagEnabled } from '../../../util/featureFlagUtil';

export class PlantsAddModal extends Component<PlantsAddModalProps> {
  static defaultProps = {
    currentRegion: {
      url: '',
    },
  };

  state = {
    firstModal: true,
    showingModal: 1,
    plantDetails: {
      name: '',
      geozones: [{}],
      archived: false,
      ownershipType: '',
      editableTicketWeights: true,
      productLine: '',
      defaultUom: undefined,
      backfillLoadedTimeDuration: undefined
    },
    editableTicketWeights: false,
    gtLiteParticipant: false,
    productLine: ProductLine.Aggregates,
    defaultUom: UnitOfMeasure.POUNDS,
    isToggleSwitchChecked: false, // Initial state for the toggle switch
    selectedDropdownOption: 10, // Initial state for the dropdown
  };

  isGeotraxLiteEnabled = () => isFeatureFlagEnabled(
    this.props.userPermission,
    this.props.currentRegion?.url ?? '',
    GEOTRAX_LITE_FLAG
  );

  componentDidUpdate(prevProps: PlantsAddModalProps) {
    const shouldReset = !this.props.isOpen && this.props !== prevProps;
    if (shouldReset) {
      this.resetModal();
    }
  }

  onChangeHandler = (newValue: PlantDto) => {
    // If ownership type is INTERNAL or product line is Ready-mix, change editableTicketWeights to NO
    if (
      (newValue.ownershipType === INTERNAL || newValue.productLine === READYMIX_VALUE)
      && this.state.editableTicketWeights
    ) {
      this.setState({ editableTicketWeights: false });
    }
    newValue.productLine = this.state.productLine;
    newValue.defaultUom = this.state.defaultUom;
    newValue.backfillLoadedTimeDuration = !this.state.isToggleSwitchChecked ? this.state.selectedDropdownOption : undefined;
    this.setState({ plantDetails: newValue });
  };

  onRightBtnClick = (dto: any, onError: any, buttonCallback: any) => {
    if (this.state.firstModal) {
      this.setState({ firstModal: false });
    }

    if (this.state.showingModal < Object.keys(PLANT_ADD_MODAL_ORDER).length) {
      this.setState({ showingModal: this.state.showingModal + 1 });
      buttonCallback();
    } else if (this.state.showingModal === Object.keys(PLANT_ADD_MODAL_ORDER).length) {
      this.onSubmit(dto, (error: {}) => {
        if (Object.keys(error).length > 0) {
          this.setState({ showingModal: 1 });
        }
        onError(error);
      });
    }
  };

  onSuccess = (plant: any) => {
    this.props.addToOrUpdateListInStore(plant, ADD_PLANT);
    this.props.discardAcceptAction();
  };

  onSubmit(dto: any, onError: any) {
    const { createAdminTableData: post } = this.props;
    const toastMessages = {
      success: ADD_PLANT_SUCCESS_TEXT,
      fail: ADD_PLANT_ERROR_MESSAGE,
    };
    const {
      circle,
      polygon,
      region,
      name,
      geozones,
      inqueueSameAsPlant,
      editableTicketWeights,
      gtLiteParticipant,
      ...rest
    } = dto;
    const plantDto = {
      name,
      region,
      editableTicketWeights: this.state.editableTicketWeights,
      gtLiteParticipant: this.state.gtLiteParticipant,
      productLine: this.state.productLine,
      defaultUom: this.state.defaultUom,
      ...rest,
    };
    const currentRegion = this.props.currentRegion.url === plantDto.region.url;
    const currentRegionUrl = this.props.currentRegion.url;
    const plantsUrl = getGeotraxBaseUrl() + PLANTS_PATH;
    plantDto.backfillLoadedTimeDuration = !this.state.isToggleSwitchChecked ? this.state.selectedDropdownOption : undefined;

    const onPlantSuccess = (plant: any) => {
      // create geozones after plant is created
      const { id, url } = plant;
      const newGeozones: any[] = [];
      const geoZoneUrl = `${plantsUrl}/${id}${GEOZONES_PATH}`;

      Object.values(GEOZONE_TYPE).forEach(type => {
        if (geozones[type]) {
          const zone = {
            name: type,
            zone: {
              circle: geozones[type].circle,
              polygon: geozones[type].polygon,
            },
            region,
            plant: { url },
          };

          newGeozones.push(post(
            geoZoneUrl,
            zone,
            this.onSuccess,
            onError,
            toastMessages,
            false,
            currentRegionUrl
          ));
        }
      });

      Promise.all(newGeozones)
        .then(() => {
          this.resetModal();
        });
    };
    plantDto.externalId = plantDto.externalId === '' ? null : plantDto.externalId;
    plantDto.scaleHostAddress = (plantDto.productLine === ProductLine.ReadyMix || plantDto.scaleHostAddress === '')
      ? null : plantDto.scaleHostAddress;
    post(plantsUrl, plantDto, onPlantSuccess, onError, toastMessages, currentRegion, currentRegionUrl);
  }

  uom = (radioBtn: string, radioBtnSelected: string, labelStyle: string, selectedUom: UnitOfMeasure) => {
    const selectedUomLabel = defaultScaleUnitOptions.find(o => o.value.toString() === selectedUom.toString())?.label;
    return (
      <div className={styles.uom}>
        <Label className={styles.uomLabel}>{ADMIN_LABELS.DEFAULT_SCALE_UNIT}</Label>
        <RadioGroup
          defaultValue={defaultScaleUnitOptions[0].label}
          name={ADMIN_KEYS.DEFAULT_SCALE_UNIT}
          onChange={this.handleUomChange()}
        >
          {defaultScaleUnitOptions.map((uom) => (
            <FormControlLabel
              key={uom.value}
              classes={{ label: labelStyle }}
              value={uom.label}
              control={<Radio checked={selectedUomLabel === uom.label} classes={{ root: radioBtn, checked: radioBtnSelected }} />}
              label={uom.label}
            />
          ))}
        </RadioGroup>
      </div>
    );
  };

  productLines = (
    isFirstModal: boolean,
    radioBtn: string,
    radioBtnSelected: string,
    labelStyle: string,
    selectedUom: UnitOfMeasure,
    selectedDropdownOption: number
  ) => {
    if (isFirstModal) {
      return (
        <FormControl className={styles.productLines}>
          <Label>
            {ADMIN_LABELS.PRODUCT_LINE}
            <span className={styles.asterisk}>*</span>
          </Label>
          <RadioGroup
            defaultValue={this.state.productLine}
            name={ADMIN_KEYS.PRODUCT_LINE}
            onChange={this.handleProductLineChange()}
          >
            <FormControlLabel
              classes={{ label: labelStyle }}
              value={defaultProductLineRadioOptions[0].value}
              control={<Radio classes={{ root: radioBtn, checked: radioBtnSelected }} />}
              label={defaultProductLineRadioOptions[0].label}
            />
            {this.state.productLine === ProductLine.Aggregates
              && (
                <>
                  {this.uom(radioBtn, radioBtnSelected, labelStyle, selectedUom)}
                  {this.toggleSwitch(styles.loadedTimeSwitch, this.state.isToggleSwitchChecked)}
                  {!this.state.isToggleSwitchChecked && this.dropdownBox(
                    styles.loadTimeSelect,
                    styles.LoadedTimeLabel,
                    selectedDropdownOption
                  )}
                </>
              )}

            <FormControlLabel
              classes={{ label: labelStyle }}
              value={defaultProductLineRadioOptions[1].value}
              control={<Radio classes={{ root: radioBtn, checked: radioBtnSelected }} />}
              label={defaultProductLineRadioOptions[1].label}
            />
            {this.state.productLine === ProductLine.Cement
              && this.uom(radioBtn, radioBtnSelected, labelStyle, selectedUom)}

            <FormControlLabel
              classes={{ label: labelStyle }}
              value={defaultProductLineRadioOptions[2].value}
              control={<Radio classes={{ root: radioBtn, checked: radioBtnSelected }} />}
              label={defaultProductLineRadioOptions[2].label}
            />

          </RadioGroup>
        </FormControl>
      );
    }
    return null;
  };

  handleToggleSwitchChange = (event: any) => {
    this.setState({ isToggleSwitchChecked: event.target.checked });
    this.setState({
      plantDetails: {
        ...this.state.plantDetails,
        backfillLoadedTimeDuration: !this.state.isToggleSwitchChecked ? 10 : undefined
      },
    });
  };

  toggleSwitch = (toggleSwitchStyle: string, toggleSwitchChecked: any) => (
    <div className={styles.LoadedTimeLabel}>
      <div className={styles.labelWithTooltip}>
        <Label className={styles.LoadedTimeLabel}>{ADMIN_LABELS.LOADED_TIME}</Label>
        <Tooltip title="When on, driver must enter loaded time manually" arrow placement="top">
          <InfoIcon className={styles.tooltipIcon} />
        </Tooltip>
      </div>
      <FormControlLabel
        classes={{ label: styles.LoadedTimeLabel }}
        control={
          <Switch
            classes={{ root: toggleSwitchStyle, checked: toggleSwitchChecked }}
            checked={this.state.isToggleSwitchChecked}
            onChange={this.handleToggleSwitchChange}
          />
        }
        label={ADMIN_LABELS.SHOW_LOADED_TIME}
      />
    </div>
  );

  dropdownBox = (dropdownStyle: string, labelStyle: string, selectedDropdownOption: any) => (
    <FormControl className={labelStyle}>
      <div className={styles.labelWithTooltip}>
        <Label className={labelStyle}>
          Auto-backfilled Loaded Time
          <span className={styles.asterisk}>*</span>
        </Label>
        <Tooltip title="Automatically sets loaded time based on selection below" arrow placement="top">
          <InfoIcon className={styles.tooltipIcon} />
        </Tooltip>
      </div>
      <Select
        className={`${dropdownStyle}`}
        defaultValue
        value={selectedDropdownOption}
        onChange={this.handleDropdownChange}
        MenuProps={{
          PaperProps: {
            style: {
              width: 'auto',
              minWidth: '100%',
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        fullWidth
      >
        {defaultLoadingTimeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  weightEditable = () => {
    const { plantDetails, firstModal, editableTicketWeights } = this.state;
    if (firstModal && plantDetails.ownershipType === EXTERNAL) {
      return (
        <div className={styles.editWeights} style={{ paddingLeft: 14 }}>
          <Label htmlFor="edit-weight">{ADMIN_KEYS.EDIT_WEIGHT_ALLOWED}</Label>
          <div className={styles.editWeightsSwitchContainer}>
            <FormControlLabel
              control={(
                <Switch
                  checked={editableTicketWeights}
                  onChange={this.handleSwitchChange()}
                  className={plantDetails.productLine === READYMIX_VALUE
                    ? styles.editWeightsNotAllowed
                    : ''}
                  disabled={plantDetails.productLine === READYMIX_VALUE}
                  color="primary"
                />
              )}
              label={editableTicketWeights ? SHOW_YES : SHOW_NO}
              classes={{ label: styles.editWeightsSwitchLabel }}
              disabled={plantDetails.productLine === READYMIX_VALUE}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  geotraxLiteParticipant = () => {
    const { firstModal, gtLiteParticipant } = this.state;
    if (firstModal) {
      return (
        <div className={styles.gtLiteParticipantM1}>
          <Label htmlFor="gt-lite-participant">{ADMIN_LABELS.GEOTRAX_LITE_PARTICIPANT}</Label>
          <div className={styles.gtLiteParticipantSwitchContainer}>
            <FormControlLabel
              control={(
                <Switch
                  checked={gtLiteParticipant}
                  onChange={this.handleGeotraxLiteParticipantChange()}
                  className={styles.gtLiteParticipantNotAllowed}
                  color="primary"
                />
              )}
              label={gtLiteParticipant ? SHOW_YES : SHOW_NO}
              classes={{ label: styles.gtLiteParticipantSwitchLabel }}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  handleSwitchChange = () => (event: any) => {
    this.setState({ editableTicketWeights: event.target.checked });
  };

  handleGeotraxLiteParticipantChange = () => (event: any) => {
    this.setState({ gtLiteParticipant: event.target.checked });
  };

  handleProductLineChange = () => (event: any) => {
    this.setState({ productLine: event.target.value });
    if (event.target.value === READYMIX_VALUE) {
      this.setState({ defaultUom: null });
    } else {
      this.setState({ defaultUom: defaultScaleUnitOptions[1].value });
    }

    if (event.target.value === AGGREGATE_VALUE) {
      this.setState({ selectedDropdownOption: defaultLoadingTimeOptions[1].value });
    }
    const newValue = this.state.plantDetails as unknown as PlantDto;
    newValue.productLine = event.target.value;
    newValue.backfillLoadedTimeDuration = defaultLoadingTimeOptions[1].value;
    this.setState({ plantDetails: newValue });
  };

  handleUomChange = () => (event: any) => {
    this.setState({ defaultUom: defaultScaleUnitOptions.find(o => o.label === event.target.value)?.value });
  };

  handleDropdownChange = (event: any) => {
    this.setState({ selectedDropdownOption: event.target.value });
  };

  resetModal = () => {
    this.setState({
      showingModal: 1,
      firstModal: true,
      defaultUom: defaultScaleUnitOptions[0].value,
      productLine: ProductLine.Aggregates,
      selectedDropdownOption: defaultScaleUnitOptions[1].value,
    });
  };

  goToPrevious = () => {
    if (!this.state.firstModal && this.state.showingModal === 2) {
      this.setState({ showingModal: 1, firstModal: true });
    } else if (!this.state.firstModal && this.state.showingModal > 1) {
      this.setState({ showingModal: this.state.showingModal - 1 });
    }
  };

  render() {
    const {
      isOpen,
      regions,
      discardAcceptAction,
      discardRejectAction,
      isCloseRequest,
      defaultRegionList,
    } = this.props;
    const {
      firstModal,
      showingModal,
      plantDetails,
      editableTicketWeights,
      gtLiteParticipant,
      defaultUom,
      selectedDropdownOption,
    } = this.state;
    const isLastModal = showingModal === Object.keys(PLANT_ADD_MODAL_ORDER).length;
    const isFirstModal = showingModal === 1;
    const modalOrderDescriptions: string[] = Object.values(PLANT_ADD_MODAL_ORDER);
    return (
      <div>
        <FloatingActionButton onClick={this.props.openModal} />
        <AddFeatureModal
          title={ADD_PLANT_MODAL_TITLE}
          isOpen={isOpen}
          onCancel={this.props.closeModal}
          style={styles.plantsAddModal}
        >
          <AdminAddModal
            config={modalConfig(showingModal, regions, plantDetails, editableTicketWeights, gtLiteParticipant, defaultRegionList)}
            className={cx(
              firstModal && styles.modal1,
              isLastModal && styles.plantSummaryModal,
              !firstModal && !isLastModal && styles.geozoneModal
            )}
            modalSize="large"
            firstModal={showingModal === 1}
            buttonClassName={isLastModal ? 'tt-btn--submit' : 'tt-btn'}
            addButtonText={isLastModal ? 'Add Plant' : 'Continue >'}
            leftBtnClassName="tt-btn-secondary"
            leftBtnText={showingModal === 2
              ? '< Edit plant information'
              : `< Edit ${modalOrderDescriptions[showingModal - 2]} Geozone`}
            addButtonDataTest="devices-add-button-data-test"
            externalKeys={[ADMIN_KEYS.EDIT_TICKET_WEIGHTS]}
            onChangeHandler={this.onChangeHandler}
            onRightBtnClick={this.onRightBtnClick}
            onLeftButtonClick={this.goToPrevious}
            hideRequiredFieldText={!this.state.firstModal}
            discardAcceptAction={discardAcceptAction}
            discardRejectAction={discardRejectAction}
            isCloseRequest={isCloseRequest}
            options={isLastModal ? {
              requiredKeys: [{
                keys: [ADMIN_KEYS.GEOZONES],
                relationship: OR,
              }],
            } : {
              requiredKeys: [{
                keys: [CIRCLE, POLYGON],
                relationship: OR,
              }],
            }}
          >
            {this.productLines(
              isFirstModal,
              styles.radioBtn,
              styles.radioBtnSelected,
              styles.radioLabel,
              defaultUom,
              selectedDropdownOption
            )}
            {this.weightEditable()}
            {
              this.isGeotraxLiteEnabled()
              && this.geotraxLiteParticipant()
            }
          </AdminAddModal>
        </AddFeatureModal>
      </div>
    );
  }
}

export interface PlantsAddModalProps {
  createAdminTableData: typeof createDataTableRecord,
  addToOrUpdateListInStore: typeof addToOrUpdateListInStore,
  discardAcceptAction: typeof noop,
  discardRejectAction: typeof noop,
  closeModal: VoidFunc,
  openModal: VoidFunc,
  regions?: any[],
  defaultRegionList: any[],
  currentRegion: UrlKeyDto,
  isCloseRequest: boolean,
  isOpen: boolean,
  userPermission: RegionAccessMap,
}
const mapStateToProps = (state: any) => ({
  currentRegion: state.currentRegion,
  defaultRegionList: state.defaultRegionList,
  userPermission: state.userPermission || {},
});

export default connect<any, any, any>(mapStateToProps, {
  createAdminTableData: createDataTableRecord,
  addToOrUpdateListInStore,
})(PlantsAddModal);
