import React, { Component } from 'react';
import cx from 'classnames';
import config from 'react-global-configuration';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Label, Tooltip } from '@trucktrax/trucktrax-common';
import axios, { AxiosResponse } from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  GroupDto,
  PermissionAccess,
  RegionDto,
  UserDto,
} from '@trucktrax/trucktrax-ts-common';
import { devErrorAndLog } from '../../../../util/errorUtil';
import styles from './UserPermissionsView.module.css';
import { noop, getIdFromUrl } from '../../../../util/appUtil';
import { createDefaultUserPermissionsTable, sortUserGroups } from '../../../../util/permissionUtil';
import { getFullName } from '../../../../util/adminUtil';
import AdminCard from '../../../shared/admin/AdminCard';
import {
  fetchPermissionList,
  getUserPermissions,
  userHasEditPermission,
  userHasEditPermissionInAnyRegion,
  userHasViewPermission,
} from '../../../../services/permissionsService';
import UserPermissionsTable from './UserPermissionsTable';
import { getRequest } from '../../../../services/requestService';
import { ERROR_TEXT_FETCH_USER_PERMISSIONS } from '../../../../constants/errorConstants';
import {
  ADMIN_LABELS,
  ADV_SECURITY_PERMS,
  CONFIG_URL,
  PERMISSION_NAMES,
  TEXT_DISPLAY,
  USERS_RELATIVE_URL,
  USERS_VERSION,
} from '../../../../constants/appConstants';
import { NOT_FOUND_PATH, USERS_PATH, GEOTRAX_URL_KEY } from '../../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../../util/apiUtil';
import { ConnectedDispatchFunction, HasId, HasName } from '../../../../types';
import { ReduxState } from '../../../../store';
import EmptyDataTableContent from '../../../shared/EmptyDataTableContent';
import { USERS_TEXT } from '../../../../constants/navConstants';
import { setSelectedRegion } from '../../../../store/actions/regionActions';
import noUsersSvg from '../../../../assets/img/noUsers.svg';
import HTTP_CODES from '../../../../constants/httpConstants';

export interface UserPermissionsViewState {
  isPending: boolean;
  userGroups: null | GroupDto[]
  userRegions: RegionDto[];
  selectedRegion: null | RegionDto;
  memberUrl: string;
}

export class UserPermissionsView extends Component<UserPermissionsViewProps, UserPermissionsViewState> {
  constructor(props: UserPermissionsViewProps) {
    super(props);

    this.state = {
      isPending: false,
      userGroups: null,
      userRegions: [],
      selectedRegion: null,
      memberUrl: '',
    };
  }

  async getParameterUrls() {
    try {
      const regionUrlsCsv = new URLSearchParams(window.location.search).get('regions');
      const regionUrls = regionUrlsCsv!.split(',');
      const urlParts = window.location.pathname.split('/');
      const userId = urlParts[urlParts.length - 1];

      const configs = await this.props.axiosGet(CONFIG_URL);
      const memberUrl = `${configs.data.HTTP_API_BASE_URL}${USERS_VERSION}${USERS_RELATIVE_URL}/${userId}`;

      return {
        memberUrl,
        regionUrls,
      };
    } catch (e) {
      return {
        regionUrls: [],
        memberUrl: '',
      };
    }
  }

  hasRegionPermission(regionUrl: string) {
    const userHasViewAccess = userHasViewPermission(
      this.props.userPermission,
      regionUrl,
      PERMISSION_NAMES.SECURITY_PERMISSIONS
    );
    const userHasEditAccess = userHasEditPermission(
      this.props.userPermission,
      regionUrl,
      PERMISSION_NAMES.SECURITY_PERMISSIONS
    );
    const userHasAdvAccess = userHasEditPermissionInAnyRegion(
      this.props.userPermission,
      ADV_SECURITY_PERMS
    );
    const userHasAccess = userHasViewAccess || userHasEditAccess || userHasAdvAccess;
    return userHasAccess;
  }

  async componentDidMount() {
    try {
      const { regionUrls, memberUrl } = await this.getParameterUrls();

      if (!memberUrl) {
        this.props.history.push(NOT_FOUND_PATH);
        throw new Error('Invalid parameters');
      }

      const regions = await UserPermissionsView.getUserRegions(memberUrl);
      let selectedRegionUrl = '';
      let selectedRegion: RegionDto = {
        id: 0,
        deleted: false,
        archived: false,
      };

      if (regionUrls && regions.length > 1) {
        // Select the first Viewable region
        regionUrls.forEach((regionUrl) => {
          if (selectedRegionUrl === '') {
            if (this.hasRegionPermission(regionUrl)) {
              const userRegion = regions.find(region => region.url === regionUrl)!;
              if (userRegion !== undefined) {
                selectedRegion = userRegion;
                selectedRegionUrl = regionUrl;
                return true;
              }
            }
          }
          return false;
        });
      } else {
        // eslint-disable-next-line prefer-destructuring
        selectedRegion = regions[0];
        selectedRegionUrl = selectedRegion.url!;
      }

      this.setState({
        userRegions: regions,
        memberUrl,
        selectedRegion,
      });

      if (selectedRegion !== undefined) { this.props.setSelectedRegion(selectedRegion); }

      await this.getUserPermissions({
        memberUrl,
        regionUrl: selectedRegionUrl,
      });

      if (this.props.permissionList.length <= 0) {
        await this.props.fetchPermissionList();
      }
    } catch (e) {
      this.onError(e);
    }
  }

  static getUserRegions = async (memberUrl: string) => {
    const userUrl = `${config.get(GEOTRAX_URL_KEY)}/geotrax/api/users-assignment/${getIdFromUrl(memberUrl)}`;
    const user: AxiosResponse<UserDto> = await getRequest(userUrl);

    const regionUrlsCsv = user.data.regions.map(r => r.url).join(',');

    const regionsUrl = `${config.get(GEOTRAX_URL_KEY)}/geotrax/api/regions/assignment?destination=${regionUrlsCsv}`;
    const assignedUserRegions: AxiosResponse<RegionDto[]> = await getRequest(regionsUrl);

    return assignedUserRegions.data;
  };

  onError = (e: any) => {
    const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
    this.props.devErrorAndLog(ERROR_TEXT_FETCH_USER_PERMISSIONS, `UserPermissionsView: ${e.toString()}`, undefined, undefined, consoleOnly);
  };

  usersBaseUrl = () => `${getGeotraxBaseUrl() + USERS_PATH}/${this.props.match.params.id}`;

  static renderMissingPermissions = () => (
    <EmptyDataTableContent
      img={{ alt: 'Missing Users Permissions', src: noUsersSvg }}
      component={(
        <div>
          <strong style={{ fontSize: '2rem' }}>Access denied</strong>
          <p>
            Users permissions are required to view this page.
            <br />
            Please contact an administrator to request access.
          </p>
        </div>
      )}
    />
  );

  getUserPermissions = async (params: any) => {
    this.setState({ isPending: true });
    const response = await this.props.getUserPermissions(params);
    let userGroups: GroupDto[] | null = null;
    if (response && response.groups) {
      userGroups = sortUserGroups(response.groups);
    }
    this.setState({
      userGroups,
      isPending: false,
    });
  };

  static formatGroupLink = (group: HasId & HasName, hasNext: boolean) => (
    <span className={styles.text} key={group.id}>
      <Link to={`/admin/permissions/groups/${group.id}`}>
        {group.name}
      </Link>
      {hasNext && (' | ')}
    </span>
  );

  async handleRegionChange(_event: any, newValue: number) {
    const { userRegions, memberUrl } = this.state;
    const newRegion = userRegions.find(r => r.id === newValue);
    this.setState({
      selectedRegion: newRegion!,
    });

    if (newRegion !== undefined) {
      this.props.setSelectedRegion(newRegion!);
    }

    await this.getUserPermissions({
      memberUrl,
      regionUrl: newRegion!.url,
    });
  }

  static disabledRegionTab = (region: RegionDto) => (
    <Tab
      key={`region-tab-${region.id}`}
      label={(
        <Tooltip
          key={region.name}
          text="You do not have access to view this region"
          placement="top"
          enterDelay={600}
          classes={{
            popper: styles.lockPopper,
            tooltip: styles.lockTooltip,
          }}
        >
          <span
            className={
              styles.disabledRegionName
            }
          >
            {region.name}
          </span>
        </Tooltip>
      )}
      value={region.id}
      className={styles.disabledClickIcon}
      disabled
      classes={{
        root: styles.btnContainerDisabled,
      }}
    />
  );

  static regionTab = (region: RegionDto) => (
    <Tab
      key={`region-tab-${region.id}`}
      label={region.name}
      value={region.id}
      classes={{
        root: styles.btnContainer,
      }}
    />
  );

  render() {
    const { location, usersAccess, permissionList } = this.props;
    const missingUsersPermission = usersAccess === PermissionAccess.Deny;
    if (missingUsersPermission) {
      return UserPermissionsView.renderMissingPermissions();
    }

    const {
      userGroups,
      isPending,
      selectedRegion,
      userRegions,
    } = this.state;

    const userPermissionsTable = createDefaultUserPermissionsTable(
      permissionList,
      userGroups ?? []
    );

    const readConfig = [
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.NAME,
        accessor: getFullName,
        className: cx(styles.fullName, 'large'),
        dataTest: 'security-permission-users-detail-user-name',
      },
    ];

    const hasRegions = userRegions && userRegions.length;
    if (!hasRegions || !selectedRegion) {
      return null;
    }

    const belongsTo = `Belongs to ${selectedRegion.name} Group(s):`;
    const permissionsName = `${selectedRegion.name} Permissions`;
    return (
      <AdminCard
        edit={false}
        url={this.usersBaseUrl()}
        className={styles.wrapper}
        pathName={location.pathname}
        config={readConfig}
        onToggleEdit={noop}
        save={noop}
        headerAccessor={getFullName}
        inactiveFields={[]}
      >
        <div className={styles.regionsTabContainer}>
          <Tabs
            className={styles.tabs}
            value={selectedRegion.id}
            onChange={(e, v) => this.handleRegionChange(e, v)}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {
              userRegions.map(r => (this.hasRegionPermission(r!.url!) ? UserPermissionsView.regionTab(r)
                : UserPermissionsView.disabledRegionTab(r)))
            }

          </Tabs>
        </div>
        <div className={cx(styles.belongsToGroupsContainer)}>
          <Label>{belongsTo}</Label>
          {userGroups && userGroups.length > 0
            ? userGroups.map((ug, i) => UserPermissionsView.formatGroupLink(ug, i < userGroups.length - 1))
            : <span className={styles.disabledText}>None</span>}
        </div>

        {/* Permission table  */}
        <div className={cx(styles.permissionsLabel)}>
          <span className={cx('txt-bold')}>
            <i
              aria-hidden="true"
              className={cx('icon-lock-solid')}
            />
            <span className={styles.marginLeft}>{permissionsName}</span>
          </span>
        </div>

        <div className={styles.permissionTableContainer}>
          {userPermissionsTable && !isPending
            ? (
              <UserPermissionsTable
                userPermissionsTable={userPermissionsTable}
              />
            )
            : (
              <div className={styles.emptyState}>
                <div className={styles.circleLoader} />
              </div>
            )}
        </div>
        {/* End Permission table */}

      </AdminCard>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    permissionList: state.permissionList.permissionList,
    userPermission: state.userPermission,
    currentRegion: state.currentRegion,
    usersAccess: state.adminPermissionAccess[USERS_TEXT] ?? '',
    axiosGet: axios.get,
  };
}
type UserPermissionsViewReduxStateProps = ReturnType<typeof mapStateToProps>;
type UserPermissionsViewReduxDispatchProps = {
  fetchPermissionList: ConnectedDispatchFunction<typeof fetchPermissionList>,
  getUserPermissions: ConnectedDispatchFunction<typeof getUserPermissions>,
  devErrorAndLog: ConnectedDispatchFunction<typeof devErrorAndLog>,
  setSelectedRegion: ConnectedDispatchFunction<typeof setSelectedRegion>
};
type UserPermissionsViewOwnProps = {
  match: { params: { id: string } },
  history: {
    location: {
      state: {
        region: {
          url: string,
          regionname: string
        },
        member: {
          url: string
        }
      },
    },
    push: (path: string) => void
  },
  location: {
    pathname: string,
    state: {
      region: {
        url: string,
        regionname: string
      },
      member: {
        url: string
      }
    }
  }
};

export type UserPermissionsViewProps = UserPermissionsViewOwnProps
  & UserPermissionsViewReduxDispatchProps
  & UserPermissionsViewReduxStateProps;

export default connect(mapStateToProps, {
  fetchPermissionList,
  getUserPermissions,
  devErrorAndLog,
  setSelectedRegion,
})(UserPermissionsView);
