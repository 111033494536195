import { Dispatch } from 'redux';
import { TruckDto } from '@trucktrax/trucktrax-ts-common/build/Dtos/Trucks/TruckDto';
import { ProductLine } from '@trucktrax/trucktrax-ts-common';
import { getRequest, putRequest } from './requestService';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { openFailModal } from '../store/actions/errorModalActions';
import recordLog, { devErrorAndLog } from '../util/errorUtil';
import { TRUCKS_PATH, VEHICLE_TYPES_PATH } from '../constants/apiConstants';
import { FETCH_TRUCKS } from '../constants/actionConstants';
import {
  ERROR_TEXT_FETCH_TRUCK_LIST,
  ERROR_TEXT_UPDATE_TRUCK,
} from '../constants/errorConstants';
import { CANCEL_API_DUE_TO_PAGE_CHANGE } from '../constants/appConstants';
import HTTP_CODES from '../constants/httpConstants';

export async function fetchTruckList(
  haulerUrl?: string,
  regionUrl?: string,
  productLine?: string,
  productLines?: string,
  includeDeactivated: boolean = false
): Promise<TruckDto[]> {
  const url = getGeotraxBaseUrl() + TRUCKS_PATH;

  const baseParams: {
    isArchived?: boolean,
    region?: string,
    primaryProductLine?: string,
    productLines?: string,
    sortBy?: string,
  } = {
    isArchived: false,
    region: '',
    primaryProductLine: '',
    productLines: '',
    sortBy: 'truckAlias',
  };

  if (includeDeactivated) {
    // the only way to get active AND deactivated (aka archived) trucks is to delete
    // the `isArchived` property entirely.  neither `null` nor `undefined` work.
    delete baseParams.isArchived;
  }

  const params = {
    ...baseParams,
    ...(haulerUrl && { hauler: haulerUrl }),
    ...(regionUrl && { region: regionUrl }),
    ...(productLine && { primaryProductLine: productLine }),
    ...(productLines && { productLines }),
  };

  try {
    const response = await getRequest(url, params);
    const trucks = response.data.items as TruckDto[];
    return trucks;
  } catch (e: any) {
    if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
      devErrorAndLog(ERROR_TEXT_FETCH_TRUCK_LIST, `url:${url}`, e.toString());
    }
    throw e;
  }
}

// TODO: remove the return values from this function; it should only set the redux state.
export function setReduxTruckList(
  haulerUrl?: string,
  regionUrl?: string,
  productLine?: string,
  productLines?: string,
) {
  return async (dispatch: Dispatch) => {
    try {
      const trucks = await fetchTruckList(haulerUrl, regionUrl, productLine, productLines);
      dispatch({
        type: FETCH_TRUCKS,
        payload: trucks,
      });
      return trucks;
    } catch (e: any) {
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(ERROR_TEXT_FETCH_TRUCK_LIST, e.toString(), undefined, undefined, consoleOnly) as any);
      }
    }
    return undefined;
  };
}

export function updateTruck(truck: TruckDto) {
  return async (dispatch: Dispatch) => {
    const baseUrl = getGeotraxBaseUrl();
    const url = `${baseUrl}${TRUCKS_PATH}/${truck.id}`;

    try {
      const response = await putRequest(url, truck);
      return response.data;
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_TEXT_UPDATE_TRUCK));
      recordLog(
        'error',
        `${ERROR_TEXT_UPDATE_TRUCK} updateTruck - id:${truck.id}`,
        e
      );
      return undefined;
    }
  };
}

export function hasTare(truck?: TruckDto) {
  if (!truck?.currentTare) {
    return false;
  }

  // The tare should be 0 if the truck does not have a current tare for Cement
  if (truck.currentTare.weight === 0 && truck.primaryProductLine === ProductLine.Cement) return true;

  return truck.currentTare.weight > 0;
}

export async function getVehicleTypes() {
  const url = `${getGeotraxBaseUrl()}${VEHICLE_TYPES_PATH}`;
  const response = await getRequest(url);
  return response.data.items;
}

export const fetchTruck = async (
  id: number
): Promise<TruckDto | undefined> => {
  const url = `${getGeotraxBaseUrl() + TRUCKS_PATH}/${id}`;
  const response = await getRequest(url);
  return response ? response.data : undefined;
};
