/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import cx from 'classnames';
import { Label } from '@trucktrax/trucktrax-common';
import { getEventFromTarget } from '../../../util/adminUtil';
import styles from '../admin/AdminAddModal.module.css';
import { noop } from '../../../util/appUtil';

export interface CardCheckboxState {
  selectedItems: any[];
}

export default class CardCheckbox extends Component<CardCheckboxProps, CardCheckboxState> {
  static defaultProps: Partial<CardCheckboxProps> = {
    className: '',
    onChange: noop,
    label: '',
    isRequired: false,
    items: [],
    initialSelected: [],
    disabledItems: [],
    errorMessage: undefined,
  };

  constructor(props: CardCheckboxProps) {
    super(props);
    if (this.props.initialSelected?.length) {
      this.state = {
        selectedItems: this.props.initialSelected.map(i => i.value),
      };
    } else if (this.props.isSelected) {
      // single checkbox
      this.state = {
        selectedItems: this.props.items,
      };
    } else {
      this.state = {
        selectedItems: [],
      };
    }
  }

  isChecked(item: any) {
    if (this.props.isSelectedDynamic !== undefined) {
      return this.props.isSelectedDynamic;
    }
    if (this.props.items.length === 1) {
      const checked = this.state.selectedItems.length === 1;
      return checked;
    }
    return this.state.selectedItems
      .filter(selected => selected === item.value).length > 0;
  }

  isDisabled(item: any): boolean {
    if (this.props.disabledItems) {
      return this.props.disabledItems.filter(i => i.value === item.value).length > 0;
    }
    return false;
  }

  onChange = (selectedItem: any, e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    let { selectedItems } = this.state;

    if (isChecked) {
      // single checkbox
      if (this.props.items.length === 1 && selectedItems.length === 0) {
        selectedItems.push(this.props.items[0]);
      } else {
        selectedItems.push(selectedItem);
      }
    } else {
      // single checkbox
      if (this.props.items.length === 1 && selectedItems.length === 1) {
        selectedItems.pop();
      }
      selectedItems = selectedItems.filter(s => s !== selectedItem);
    }

    this.setState({ selectedItems });

    const event = getEventFromTarget(e, this.props.id);
    // pass the selected items (productLines is hard coded for checklists)
    (event as any).checked = selectedItems;
    (event as any).productLines = selectedItems.length === 0 ? undefined : selectedItems;
    this.props.onChange!(event);
  };

  getCheckboxClass(item: any, hasError?: boolean) {
    if (hasError) {
      return styles.checkboxRequired;
    }
    if (this.isDisabled(item)) {
      return '';
    }
    if (this.isChecked(item)) {
      return styles.checkboxChecked;
    }
    return styles.checkboxUnchecked;
  }

  render() {
    const {
      className,
      label,
      isRequired,
      items,
      errorMessage,
      id,
    } = this.props;

    const errorString = errorMessage?.join(', ');
    const hasError = !!errorString;

    return (
      <div className={className}>
        <div>
          <div>
            <Label isRequired={isRequired}>{label!}</Label>
          </div>
        </div>
        <FormControl>
          {items.map((checkbox, index) => {
            const key = `checkbox${id}${index}`;

            return (
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={this.isChecked(checkbox)}
                    onChange={(e) => this.onChange(checkbox.value, e)}
                    name={key}
                    key={key}
                    color="primary"
                    className={this.getCheckboxClass(checkbox, hasError)}
                    disabled={this.isDisabled(checkbox)}
                  />
                )}
                label={checkbox.label}
                key={key}
                className={styles.checkboxLabel}
              />
            );
          })}
        </FormControl>
        {hasError && (
          <label
            className={cx(styles.textRed, styles.errorFieldMargin, 'tt-label--help')}
            data-test="card-radio-error"
          >
            {errorString}
          </label>
        )}
      </div>
    );
  }
}

export interface CardCheckboxProps {
  id: string;
  errorMessage?: string[];
  className?: string;
  onChange?: (event: any) => void;
  label?: string;
  isRequired?: boolean;
  items: { label: string, value: any, disabled?: boolean }[];
  initialSelected?: { value: any }[];
  isSelected?: boolean;
  isSelectedDynamic?: boolean;
  disabledItems?: { value: any }[];
}
