import React, { Component } from 'react';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import { DateWrapper, vars } from '@trucktrax/trucktrax-common';
import { ProductLine, TicketDto } from '@trucktrax/trucktrax-ts-common';
import styles from './OrderTicketList.module.css';
import { downloadBlobFromUrl } from '../../../../util/reportsUtil';
import { fetchOrderTicketList, fetchCurrentTicketQualityFactors } from '../../../../services/ticketsService';
import setSidebarSelected from '../../../../store/actions/sidebarActions';
import { clearSelectedTicket, selectTicket } from '../../../../store/actions/ticketActions';
import { TICKETS_SELECTED, } from '../../../../constants/appConstants';
import { ConnectedDispatchFunction, ConnectedFunction } from '../../../../types';
import { ReduxState } from '../../../../store';

const ReactTableFixedColumns = withFixedColumns(ReactTable);
const headerStyle = {
  fontWeight: 'bold',
  fontSize: '1.3em',
  fontFamily: vars.fontFamilyBold,
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'antialiased',
} as any;

const cellStyle = {
  fontSize: '1.5em',
  height: '3.1em',
  lineHeight: '2.5em',
  borderRadius: '4px',
} as any;

export interface OrderTicketListState {
  ticketList: TicketDto[]
}

const CurrentDriverWrapper = (value: { externalInfo: { driverName: string } }) => (
  value && value.externalInfo && value.externalInfo.driverName
  && (
    <div className="nowrap-ellipsis">
      {`${value.externalInfo.driverName}`}
    </div>
  )
);

export class OrderTicketList extends Component<OrderTicketListProps, OrderTicketListState> {
  constructor(props: OrderTicketListProps) {
    super(props);
    this.state = {
      ticketList: [],
    };
  }

  /* istanbul ignore next */
  static downloadFile = async (url: string, contentType: string, tenantUrl: string) => {
    const response = await fetch(url, { headers: { Accept: contentType, 'tenant-url': tenantUrl } });
    const blob = await response.blob();
    downloadBlobFromUrl(blob, 'receipts.zip', true);
  };

  async componentDidMount() {
    const { orderId } = this.props;
    if (orderId) {
      const orderTicketList = await this.props.fetchOrderTicketList(orderId);

      if (orderTicketList.length > 0) {
        const sortedTicketList = orderTicketList.sort((x: any, y: any) => x.loadNumber - y.loadNumber);
        this.setState({
          ticketList: sortedTicketList,
        });
      }
    }
  }

  rowClicked(ticket: any) {
    this.props.clearSelectedTicket();
    this.props.selectTicket(ticket);
    this.props.setSidebarSelected(TICKETS_SELECTED, true);

    // load Quality Factors for closed Ready Mix Tickets
    if (ticket?.closed && this.props.selectedProductLine === ProductLine.ReadyMix) {
      this.props.fetchCurrentTicketQualityFactors(ticket.id);
    }
  }

  rowConfiguration = (state: any, rowInfo: any) => {
    if (rowInfo) {
      return {
        onClick: (_e: any, originalCallback: () => void) => {
          this.rowClicked(rowInfo.original);

          if (originalCallback) {
            originalCallback();
          }
        },
      };
    }
    return {};
  };

  static noTicketsFound = () => (
    <div className={styles.noTicketsContainer}>
      <div className={styles.noTicketsShadow} />
      <span className={styles.noTicketsText}>No tickets found</span>
    </div>
  );

  render() {
    const { ticketList } = this.state;
    const items = ticketList;

    const columns = [
      {
        Header: 'Load #',
        accessor: 'loadNumber',
        minWidth: 90,
        style: {
          ...cellStyle,
        },
        headerStyle,
      },
      {
        Header: 'Ticket #',
        accessor: 'ticketNumber',
        minWidth: 95,
        style: {
          ...cellStyle,
          fontFamily: vars.fontFamilyBold,
          color: '#0A63F3',
          cursor: 'pointer',
        },
        headerStyle,
      },
      {
        Header: 'Timestamp',
        id: 'orderTicketTimestamp',
        accessor: (value: { ticketedTimestamp: string; }) => new DateWrapper(value.ticketedTimestamp).toString('MM/dd/yyyy h:mm:ss a'),
        minWidth: 200,
        style: {
          ...cellStyle,
        },
        headerStyle,
      },
      {
        Header: 'Assigned Truck',
        accessor: 'externalInfo.truckAlias',
        minWidth: 150,
        style: {
          ...cellStyle,
        },
        headerStyle,
      },
      {
        Header: 'Current/Last Driver',
        id: 'driverName',
        accessor: CurrentDriverWrapper,
        minWidth: 200,
        style: {
          ...cellStyle,
        },
        headerStyle,
      },
    ] as any;

    const tableProps = {
      data: items,
      getTrGroupProps: (state: any, rowInfo: any) => this.rowConfiguration(state, rowInfo),
      showPagination: false,
      pageSize: items.length,
      columns,
      minRows: 0,
      className: '-highlight',
      NoDataComponent: OrderTicketList.noTicketsFound,
      style: { maxHeight: '60em' },
    };

    return (
      <div className={styles.wrapper}>
        <Paper className={styles.ticketListContainer}>
          <div className={styles.ticketListHeader}>
            <strong className={styles.h4}>TICKETS</strong>
          </div>
          <ReactTableFixedColumns {...tableProps} />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  token: state.token,
  flags: state.flags,
  userPermission: state.userPermission,
  selectedProductLine: state.selectedProductLine,
});

type OrderTicketListReduxStateProps = ReturnType<typeof mapStateToProps>;

type OrderTicketListReduxDispatchProps = {
  fetchOrderTicketList: ConnectedDispatchFunction<typeof fetchOrderTicketList>
  setSidebarSelected: ConnectedFunction<typeof setSidebarSelected>,
  selectTicket: ConnectedFunction<typeof selectTicket>,
  clearSelectedTicket: ConnectedFunction<typeof clearSelectedTicket>,
  fetchCurrentTicketQualityFactors: ConnectedDispatchFunction<typeof fetchCurrentTicketQualityFactors>
};
export type OrderTicketListProps = OrderTicketListOwnProps & OrderTicketListReduxStateProps & OrderTicketListReduxDispatchProps;

type OrderTicketListOwnProps = {
  orderExternalId?: string,
  orderId?: number,
  tenantUrl?: string,
};

export default connect(mapStateToProps, {
  fetchOrderTicketList,
  setSidebarSelected,
  selectTicket,
  clearSelectedTicket,
  fetchCurrentTicketQualityFactors,
})(OrderTicketList);
