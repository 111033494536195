import { RouteDto } from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import {
  SET_ROUTE_HISTORY_LIST, SET_SELECTED_ROUTE_HISTORY_LIST, CLEAN_SELECTED_ROUTE_HISTORY_LIST,
  CLEAN_ROUTE_HISTORY_LIST, SET_ROUTE_HISTORY_HOVER_SELECTION,
} from '../../constants/actionConstants';

// eslint-disable-next-line import/prefer-default-export
export const routeHistoryList = (
  state: (RouteDto[] | null) = null,
  action: PayloadAction<RouteDto[], 'SET_ROUTE_HISTORY_LIST' | 'CLEAN_ROUTE_HISTORY_LIST'>
) => {
  switch (action.type) {
    case SET_ROUTE_HISTORY_LIST:
      return action.payload as RouteDto[];
    case CLEAN_ROUTE_HISTORY_LIST:
      return null;
    default:
      return state;
  }
};

export const routeHistoryHoverSelection = (
  state: (RouteDto | null) = null,
  action: PayloadAction<RouteDto, 'SET_ROUTE_HISTORY_HOVER_SELECTION'>
) => {
  switch (action.type) {
    case SET_ROUTE_HISTORY_HOVER_SELECTION:
      return action.payload as RouteDto;
    default:
      return state;
  }
};

export const selectedRouteHistoryList = (
  state: number[] = [],
  action: PayloadAction<number[], 'SET_SELECTED_ROUTE_HISTORY_LIST' | 'CLEAN_SELECTED_ROUTE_HISTORY_LIST'>
) => {
  switch (action.type) {
    case SET_SELECTED_ROUTE_HISTORY_LIST:
      return action.payload as number[] ?? [];
    case CLEAN_SELECTED_ROUTE_HISTORY_LIST:
      return [];
    default:
      return state;
  }
};
