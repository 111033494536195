import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { docsRouteNotFound, docsRouteRedirect } from '../../../routes';
import { setFavicon } from '../../../util/appUtil';
import RedirectRoute from '../../shared/routing/RedirectRoute';
import RouteWithSubRoutes from '../../shared/routing/RouteWithSubRoutes';

export function DocsView(props: DocsViewProps) {
  useEffect(() => {
    document.title = 'GeoTrax - TruckTrax';
    setFavicon('/favicon-geotrax.ico');
  }, []);

  const configRoutes = props.routes.map((puRoute) => (
    <RouteWithSubRoutes
      key={puRoute.path}
      {...puRoute}
    />
  ));

  // add redirect for /docs to /docs/guide/web
  configRoutes.push(<RedirectRoute
    key={docsRouteRedirect.key}
    from={docsRouteRedirect.from}
    to={docsRouteRedirect.to}
  />);

  // add redirect for /docs/* to 404
  configRoutes.push(<RedirectRoute
    key={docsRouteNotFound.key}
    from={docsRouteNotFound.from}
    to={docsRouteNotFound.to}
  />);

  return (
    <Switch>
      {configRoutes}
    </Switch>
  );
}

export interface Route {
  path?: string,
  component?: React.ComponentType<any>
}

export interface DocsViewProps {
  routes: Route[]
}

export default DocsView;
