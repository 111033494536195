import cx from 'classnames';
import React, { Component } from 'react';
import { Button } from '@trucktrax/trucktrax-common';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './CardWithButton.module.css';

class CardWithButton extends Component<CardWithButtonProps, CardWithButtonState> {
  static defaultProps = {
    iconClassName: 'icon-create',
    primaryText: null,
    secondaryText: null,
  };

  constructor(props: CardWithButtonProps) {
    super(props);
    this.state = {
      isArchived: this.props.archived,
    };
  }

  onToggleClick = () => {
    this.props.onToggleClick(this.reToggle);
  };

  reToggle = () => {
    this.setState({
      isArchived: !this.state.isArchived,
    });
  };

  render() {
    return (
      <div
        data-test="feature-flag-list-item"
        className={styles.card}
      >
        <div className={styles.text}>
          <div className={styles.primaryText}>
            {this.props.primaryText}
          </div>
          <div className={styles.secondaryText}>
            {this.props.secondaryText}
          </div>
        </div>
        <div className={styles.featureButtons}>
          <span data-test="feature-flag-enable-disable">
            <FormControl component="fieldset">
              <FormControlLabel
                control={(
                  <Switch
                    color="primary"
                    onChange={this.onToggleClick}
                    checked={!this.state.isArchived}
                  />
                )}
                classes={{ label: styles.switchLabel }}
                label={this.state.isArchived ? 'Off' : 'On'}
              />
            </FormControl>
          </span>
          <Button
            onClick={this.props.onPermissionClick}
            buttonClassName="tt-btn-secondary margin-right-10"
            name="Edit"
            iconClassName={cx(`${this.props.iconClassName}`)}
            dataTest="edit-feature-flag-list-item"
          />
          <Button
            onClick={this.props.onDeleteClick}
            buttonClassName="tt-btn-secondary--error"
            name="Delete"
            iconClassName={cx('icon-delete')}
            dataTest="feature-flag-delete"
          />
        </div>
      </div>
    );
  }
}

export interface CardWithButtonState {
  isArchived: boolean;
}

export interface CardWithButtonProps {
  primaryText: string | null;
  secondaryText: string | null;
  onDeleteClick: () => void;
  iconClassName?: string;
  onPermissionClick: () => void;
  onToggleClick: (
    fn: () => void
  ) => void;
  archived: boolean;
}

export default CardWithButton;
