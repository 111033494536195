import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Button, DiscardProgressCard } from '@trucktrax/trucktrax-common';
import { Prompt } from 'react-router-dom';
import { postFile } from '../../../services/requestService';
import { openSnackbar } from '../../../store/actions/snackbarActions';
import styles from './EditResourceModal.module.css';
import { STATIC_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import LoadingCircle from '../LoadingCircle';
import { DISCARD_PROMPT_TEXT, ERROR } from '../../../constants/appConstants';
import { FILE_REPLACE_INSTRUCTIONS } from '../../../constants/docsConstants';
import AddFeatureModal from '../AddFeatureModal';
import { noop } from '../../../util/appUtil';
import trash from '../../../assets/img/trash-illus.svg';

interface EditResourceModalState {
  isUploading: boolean;
  selectedFile?: File;
  fileName?: string;
  isCancelModalOpen: boolean;
}

export class EditResourceModal extends Component<EditResourceModalProps, EditResourceModalState> {
  static defaultProps: Partial<EditResourceModalProps> = {
    filepath: undefined,
  };

  constructor(props: EditResourceModalProps) {
    super(props);

    this.state = {
      isUploading: false,
      selectedFile: undefined,
      fileName: undefined,
      isCancelModalOpen: false,
    };
  }

  fileInput = React.createRef<HTMLInputElement>();

  componentDidUpdate(prevProps: EditResourceModalProps) {
    const closeModal = prevProps.isOpen && !this.props.isOpen;
    if (closeModal) {
      // prevents UI flicker
      setTimeout(() => {
        this.resetState();
      }, 500);
    }
  }

  static getFileName = (file?: string) => {
    if (!file) {
      return file;
    }
    const fileNameParts = file.split('/');
    return fileNameParts[fileNameParts.length - 1];
  };

  onChooseFile = () => {
    this.fileInput.current!.click();
  };

  onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    this.setState({
      selectedFile: file,
      fileName: file && file.name,
    });
  };

  onReplaceFile = async () => {
    const uploadUrl = getGeotraxBaseUrl() + STATIC_PATH;

    this.setState({ isUploading: true });
    try {
      await postFile(uploadUrl, this.state.selectedFile!, this.props.filepath!);
    } catch (e) {
      this.props.openSnackbar!({
        snackbarBody: 'Error replacing resource.',
        snackbarType: ERROR,
        dataTest: 'replace-file-error-snackbar',
      });
      this.props.onComplete();
      return;
    }
    this.props.openSnackbar!({
      snackbarBody: this.props.successText,
      snackbarType: 'DEFAULT',
      dataTest: 'replace-file-success-snackbar',
    });
    this.props.onComplete();
  };

  resetState() {
    this.setState({
      isUploading: false,
      selectedFile: undefined,
      fileName: undefined,
    });
  }

  onCancel = () => {
    if (!this.state.isUploading) {
      this.props.onCancel();
    }
  };

  toggleCancelModal = (isOpen: boolean) => {
    this.setState({
      isCancelModalOpen: isOpen,
    });
  };

  closeModalOnDiscard = () => {
    this.toggleCancelModal(false);
    this.onCancel();
  };

  render() {
    const {
      extension,
      uploadingText,
      typeIcon,
      filepath,
      submitText,
      title,
      isOpen,
    } = this.props;

    const {
      isUploading,
      fileName,
      selectedFile,
    } = this.state;

    const extensionTitle = extension.replace('.', '').toUpperCase();

    const originalFile = EditResourceModal.getFileName(filepath);
    const isValid = fileName === originalFile;

    const isValidClass = selectedFile && !isValid ? styles.invalid : '';
    const body = isUploading
      ? (
        <div className={cx(styles.docContainer, styles.loading)}>
          <LoadingCircle size={18} />
          <p>{uploadingText}</p>
        </div>
      )
      : (
        <div>
          <div className={styles.docContainer}>
            <p className={styles.pdfLabel}>{`${extensionTitle} File`}</p>
            <div className={styles.pdfDoc}>
              <i className={cx(typeIcon, isValidClass)} />
              <span className={isValidClass}>{fileName || originalFile}</span>
            </div>
            <input
              ref={this.fileInput}
              type="file"
              onChange={this.onFileChange}
              className={styles.fileUploader}
              accept={extension}
            />
            <Button
              buttonClassName={cx(styles.chooseNewFileButton, 'tt-btn-secondary')}
              name="Choose New File"
              dataTest="choose-file-btn"
              onClick={this.onChooseFile}
            />
            <div className={cx(styles.caption, 'margin-top-20')}>
              <div className={isValidClass}>
                {isValidClass && <i className="icon-warning margin-right-5" />}
                {FILE_REPLACE_INSTRUCTIONS}
              </div>
              <div>
                <span className={styles.pdfLabel}>Example: </span>
                {originalFile}
              </div>
            </div>
          </div>

          <div className={styles.actions}>
            <div className={styles.warning}>
              {
                selectedFile && isValid && (
                  <span>
                    <i className="icon-warning" />
                    <span>This will replace the current source file.</span>
                  </span>
                )
              }
            </div>
            <div>
              <Button
                buttonClassName={cx(styles.cancelButton, 'tt-btn-basic')}
                name="Cancel"
                dataTest="cancel-btn"
                onClick={() => this.toggleCancelModal(true)}
              />
              <Button
                buttonClassName={cx(styles.updateDocButton, 'tt-btn--submit')}
                name={submitText}
                dataTest="replace-file-btn"
                onClick={this.onReplaceFile}
                disabled={!isValid}
              />
            </div>
          </div>

        </div>
      );

    return (
      <div>
        <AddFeatureModal
          title={title}
          isOpen={isOpen}
          onCancel={() => this.toggleCancelModal(true)}
        >
          {body}
        </AddFeatureModal>
        <AddFeatureModal
          title=""
          isOpen={this.state.isCancelModalOpen}
          onCancel={noop}
        >
          <DiscardProgressCard
            discardAcceptAction={this.closeModalOnDiscard}
            discardRejectAction={() => this.toggleCancelModal(false)}
            imageSrc={trash}
            altText="Trash"
          />
        </AddFeatureModal>
        <Prompt
          when={this.state.isCancelModalOpen}
          message={DISCARD_PROMPT_TEXT}
        />
      </div>
    );
  }
}

export interface EditResourceModalProps {
  extension: string;
  typeIcon: string;
  uploadingText: string;
  successText: string;
  onCancel: () => void;
  onComplete: () => void;
  filepath?: string;
  openSnackbar?: typeof openSnackbar;
  submitText: string;
  title: string;
  isOpen: boolean;
}

function mapStateToProps(state: any) {
  return { ...state };
}

export default connect(mapStateToProps, { openSnackbar })(EditResourceModal);
