import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import {
  PermissionAccess, PlantDto, ScaleDto, ScaleType, UrlKeyDto,
} from '@trucktrax/trucktrax-ts-common';
import { AxiosResponse } from 'axios';
import styles from './ScalesDetailView.module.css';
import AdminCard from '../../shared/admin/AdminCard';
import {
  plantListForDropdown,
  scaleTypeRadioOptions,
  formatScaleMaxWeight,
  capitalizeFirst,
  getWeightOption,
} from '../../../util/adminUtil';
import { ToastMessagesType, updateDataTableRecord } from '../../../services/dataTableService';
import {
  SCALES_TEXT,
} from '../../../constants/navConstants';
import { fetchPlantList } from '../../../services/plantsService';
import { devErrorAndLog } from '../../../util/errorUtil';
import { fetchScaleList } from '../../../services/scalesService';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  DROPDOWN_FORM,
  EDIT_BTN,
  INPUT_FORM,
  RADIO_FORM,
  SAVE_BTN,
  TEXT_DISPLAY,
  ACCENT,
  NUMERIC_INPUT_FORM,
  ACTIVATE_SCALE_BODY,
  ARE_YOU_SURE,
  CONFIRM_ACTIVATE_SCALE,
  DEACTIVATE_SCALE_BODY,
  CONFIRM_DEACTIVATE_SCALE,
  CONFIRMTO_DEACTIVATE_SCALE,
  CONFIRMTO_ACTIVATE_SCALE,
  CANCEL_LABEL,
} from '../../../constants/appConstants';
import { PLANTS_PATH, SCALES_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { openModal, closeModal } from '../../../store/actions/errorModalActions';
import { getIdFromUrl, noop } from '../../../util/appUtil';
import StatusContainer from '../status/StatusContainer';
import { getRequest } from '../../../services/requestService';
import { ReduxState } from '../../../store';
import { ConnectedDispatchFunction } from '../../../types';
import { ERROR_TEXT_FETCH_SCALE } from '../../../constants/errorConstants';
import HTTP_CODES from '../../../constants/httpConstants';

export class ScalesDetailView extends Component<ScalesDetailViewProps> {
  state = {
    edit: false,
    scale: undefined as ScaleDto | undefined,
    errorObject: {
      error: false,
      errorMessage: '',
    },
    shouldRefetchData: false,
    maxWeightLabel: `${ADMIN_LABELS.ADD_SCALE_MAXWEIGHT}`,
    showNetAddress: false,
  };

  componentDidMount() {
    this.setPlantList();
    this.getScale();
  }

  save = (dto: any, onSuccess: any, onError: any, toasts: ToastMessagesType) => {
    const plantUrl = dto.plant?.url;
    const preUpdateDto = { ...dto };
    if (!plantUrl) {
      preUpdateDto.plant = null;
    }

    const onSaveSuccess = (updatedDto: any) => {
      const scaleDetails = updatedDto && updatedDto.data ? updatedDto.data : {};
      this.setState({ edit: false, scale: scaleDetails });
      onSuccess(updatedDto);
    };
    // use the plant from the scale before update when creating the url in case they changed plant
    const plantId = getIdFromUrl(this.state.scale?.plant?.url);
    const scaleId = dto?.id?.toString() || '';
    preUpdateDto.default = dto.checked ? dto.checked.length > 0 : false;
    preUpdateDto.netAddress = (preUpdateDto.scaleType === ScaleType.LIVE) ? preUpdateDto.netAddress : '';
    this.props.updateAdminTableData(
      ScalesDetailView.baseUrl(plantId, scaleId),
      preUpdateDto,
      onSaveSuccess,
      onError,
      false,
      undefined,
      toasts
    );
  };

  saveActiveandDefaultStatus = (dto: any, onSuccess: any, onError: any, toasts: ToastMessagesType) => {
    const plantUrl = dto.plant?.url;
    const preUpdateDto = { ...dto };
    if (!plantUrl) {
      preUpdateDto.plant = null;
    }

    const onSaveSuccess = (updatedDto: any) => {
      const scaleDetails = updatedDto && updatedDto.data ? updatedDto.data : {};
      this.setState({ edit: false, scale: scaleDetails, shouldRefetchData: true });
      onSuccess(updatedDto);
    };
    // use the plant from the scale before update when creating the url in case they changed plant
    const plantId = getIdFromUrl(this.state.scale?.plant?.url);
    const scaleId = dto?.id?.toString() || '';
    preUpdateDto.default = false;
    this.props.updateAdminTableData(
      ScalesDetailView.baseUrl(plantId, scaleId),
      preUpdateDto,
      onSaveSuccess,
      onError,
      false,
      undefined,
      toasts
    );
  };

  static baseUrl = (plantId: string, scaleId: string) => {
    const url = `${getGeotraxBaseUrl() + PLANTS_PATH}/${plantId}${SCALES_PATH}/${scaleId}`;
    return url;
  };

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  recordLoaded = async (response: any) => {
    const dto = response.data;
    this.setWeightAndNetAddressData(dto);
  };

  setWeightAndNetAddressData = (dto: any) => {
    const { defaultUom } = dto.plant;
    const maxWeightLabel = defaultUom
      ? `${ADMIN_LABELS.ADD_SCALE_MAXWEIGHT} in ${getWeightOption(defaultUom)?.label}` : ADMIN_LABELS.ADD_SCALE_MAXWEIGHT;
    const showNetAddress = dto?.scaleType?.toString().toUpperCase() === ScaleType.LIVE;
    this.setState({ maxWeightLabel, showNetAddress });
  };

  recordChanged = async (updatedDto: any) => {
    this.setWeightAndNetAddressData(updatedDto);
  };

  getScale = async () => {
    try {
      const params = { scaleid: this.props.match.params.id };
      const response: AxiosResponse = await getRequest(`${getGeotraxBaseUrl() + PLANTS_PATH}${SCALES_PATH}/all`, params);
      const scale = response.data.items[0];
      const showNetAddress = scale.scaleType.toString().toUpperCase() === ScaleType.LIVE;
      const { defaultUom } = scale.plant;
      const maxWeightLabel = defaultUom
        ? `${ADMIN_LABELS.ADD_SCALE_MAXWEIGHT} in ${getWeightOption(defaultUom)?.label}` : ADMIN_LABELS.ADD_SCALE_MAXWEIGHT;
      this.setState({ scale, maxWeightLabel, showNetAddress });
      await this.props.fetchScaleList(scale.plant);
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      this.props.devErrorAndLog(ERROR_TEXT_FETCH_SCALE, `Add Scale: ${e.toString()}`, undefined, undefined, consoleOnly);
    }
  };

  async setPlantList() {
    const { currentRegion } = this.props;
    this.props.fetchPlantList(currentRegion?.url, undefined, undefined, 'Aggregates,Cement');
  }

  getBreadcrumbPath() {
    const isArchived = this.state.scale?.archived === true;
    if (!isArchived) {
      return this.props.location.pathname;
    }
    // construct the breadcrumb path for deactivated drivers
    const paths = this.props.location.pathname.split('/');
    const id = paths.pop();
    if (id) {
      paths.push('deactivated', id);
    }
    return paths.join('/');
  }

  static defaultUrlAccessor = (row: { [x: string]: { url: string; }; }, rowName: string) => {
    if (!row[rowName]) {
      row[rowName] = { url: '' };
    }

    return `${rowName}.url`;
  };

  static getPlantName = (scale: ScaleDto) => (scale.plant.name);

  getReadConfig = () => {
    const readConfig = [
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.PLANT_NAME,
        accessor: ScalesDetailView.getPlantName,
        className: styles.plantName,
        dataTest: 'scales-drilldown-plantname',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.SCALE_NAME,
        accessor: ADMIN_KEYS.NAME,
        className: styles.label,
        dataTest: 'scales-drilldown-scalename',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.ADD_SCALE_MAXWEIGHT,
        accessor: formatScaleMaxWeight,
        className: styles.label,
        dataTest: 'scales-drilldown-maxweight',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.SCALE_TYPE,
        key: ADMIN_KEYS.SCALETYPE,
        accessor: (item: ScaleDto) => `${capitalizeFirst(item.scaleType)}`,
        className: styles.label,
        dataTest: 'scales-drilldown-scaletype',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.SCALE_HEADNETADDRESS,
        accessor: ADMIN_KEYS.NETADDRESS,
        className: styles.label,
        dataTest: 'scales-drilldown-netaddress',
      },
    ];

    if (this.props.scalesPermissionAccess === PermissionAccess.Edit) {
      readConfig.push({
        type: EDIT_BTN,
        name: 'Edit Scale',
        iconClassName: 'icon-create',
        dataTest: 'scales-drilldown-edit',
        disabled: false,
        category: 'scale',
      } as any);
    }

    return readConfig;
  };

  getEditConfig = () => {
    const plantsDropdownOptions = plantListForDropdown(this.props.plantsList);
    const scaleOptions = scaleTypeRadioOptions;
    scaleOptions.forEach(e => { e.disabled = false; });

    const editConfig = [
      {
        type: DROPDOWN_FORM,
        key: ADMIN_KEYS.PLANT,
        accessor: (row: { [x: string]: { url: string; }; }) => ScalesDetailView.defaultUrlAccessor(row, ADMIN_KEYS.PLANT),
        label: ADMIN_LABELS.SCALE_PLANT,
        className: 'baseDropDown',
        items: plantsDropdownOptions,
        allowEmpty: true,
        dataTest: 'defaultRegion-input-data-test',
        isRequired: true,
      },
      {
        type: INPUT_FORM,
        accessor: ADMIN_KEYS.NAME,
        className: 'fullWidthSpace',
        dataTest: 'trucks-drilldown-alias',
        key: ADMIN_KEYS.NAME,
        label: ADMIN_LABELS.SCALE_NAME,
        maxLength: 55,
        errorDataTest: 'truckAlias-input-missing-error',
        isRequired: true,
        hideCharacterCount: true,
      },
      {
        type: RADIO_FORM,
        accessor: ADMIN_KEYS.SCALETYPE,
        className: 'fullWidthSpace',
        dataTest: 'scale-drilldown-scaletype',
        key: ADMIN_KEYS.SCALETYPE,
        radioButtonGroupName: ADMIN_KEYS.SCALETYPE,
        label: ADMIN_LABELS.SCALE_TYPE,
        radioButtons: scaleOptions,
        isRequired: true,
      },
      {
        type: NUMERIC_INPUT_FORM,
        accessor: ADMIN_KEYS.MAXWEIGHT,
        className: 'fullWidthSpace',
        dataTest: 'scales-drilldown-maxweiht',
        key: ADMIN_KEYS.MAXWEIGHT,
        label: (
          <span className={styles.label}>
            {this.state.maxWeightLabel}
            <span className={styles.asterisk}> *</span>
          </span>
        ),
        maxLength: 55,
        errorDataTest: 'maxweight-input-missing-error',
        hideCharacterCount: true,
      },
    ];

    if (this.state.showNetAddress) {
      editConfig.push(
        {
          type: INPUT_FORM,
          accessor: ADMIN_KEYS.NETADDRESS,
          className: 'fullWidthSpace',
          dataTest: 'scales-drilldown-netaddress',
          key: ADMIN_KEYS.NETADDRESS,
          label: ADMIN_LABELS.SCALE_NETADDRESS,
          maxLength: 55,
          errorDataTest: 'netaddress-input-missing-error',
          isRequired: true,
          hideCharacterCount: true,
        }
      );
    }

    editConfig.push({ type: SAVE_BTN } as any);
    return editConfig;
  };

  viewOnlyItems() {
    if (this.state.edit) {
      return null;
    }

    const hasEditPermission = this.props.scalesPermissionAccess === PermissionAccess.Edit;
    return (
      <StatusContainer
        category="scale"
        isArchived={this.state.scale?.archived}
        isDisabled={false}
        hasEditPermission={hasEditPermission}
        containerClassName={styles.statusCtr}
        onActivateClick={this.activateWarningModal}
        onDeactivateClick={this.deactivateWarningModal}
      />
    );
  }

  activateWarningModal = () => {
    const modalBody = (
      <span>
        <p>{ACTIVATE_SCALE_BODY}</p>
        <p>{CONFIRMTO_ACTIVATE_SCALE}</p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.activateScale,
      acceptText: CONFIRM_ACTIVATE_SCALE,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateWarningModal = () => {
    const modalBody = (
      <span>
        <p>{DEACTIVATE_SCALE_BODY}</p>
        <p>{CONFIRMTO_DEACTIVATE_SCALE}</p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      modalOpen: true,
      acceptDialog: this.deactivateScale,
      acceptText: CONFIRM_DEACTIVATE_SCALE,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  deactivateScale = () => {
    const dto = {
      ...this.state.scale,
      archived: true,
    };

    const toastMessages = {
      success: `“${dto.name}” has been successfully deactivated.`,
      fail: `Unable to deactivate “${dto.name}.”`,
    };

    this.props.closeModal();
    this.saveActiveandDefaultStatus(dto, noop, null, toastMessages);
  };

  activateScale = () => {
    const dto = {
      ...this.state.scale,
      archived: false,
    } as ScaleDto;

    const toastMessages = {
      success: `"${dto.name}" has been successfully activated.`,
      fail: `Unable to activate “${dto.name}.”`,
    };
    this.props.closeModal();
    this.save(dto, noop, null, toastMessages);
  };

  render() {
    const pathname = this.getBreadcrumbPath();
    if (!this.state.scale || !this.state.scale.plant || !this.props.plantsList?.length) return null;

    const configValue = (this.state.edit)
      ? this.getEditConfig()
      : this.getReadConfig();
    const scaleId = this.state.scale.id.toString();
    const plantId = getIdFromUrl(this.state.scale.plant!.url);
    return (
      <AdminCard
        edit={this.state.edit}
        url={ScalesDetailView.baseUrl(plantId!, scaleId)}
        className={cx(
          styles.wrapper,
          this.state.edit && styles.editWrapper
        )}
        onToggleEdit={this.toggleEdit}
        save={this.save}
        config={configValue}
        errorObject={this.state.errorObject}
        pathName={pathname}
        headerAccessor="name"
        options={{
          requiredKeys: [],
          callback: this.recordLoaded,
          shouldRefetchData: this.state.shouldRefetchData,
        }}
        onChangeHandler={this.recordChanged}
        disableEdit={false}
      >
        {this.viewOnlyItems()}
      </AdminCard>
    );
  }
}

type Location = {
  pathname: string;
};

type Match = {
  params: { id: string };
};

export interface ScalesDetailViewOwnProps {
  match: Match,
  closeModal: typeof closeModal,
  openModal: typeof openModal,
  scalesPermissionAccess?: string,
  plantsList?: PlantDto[],
  currentRegion: UrlKeyDto,
  location: Location
}
export function mapStateToProps(state: ReduxState) {
  return {
    plantsList: state.plantList,
    scalesPermissionAccess: state.adminPermissionAccess[SCALES_TEXT],
    currentRegion: state.currentRegion,
    scalesList: state.scalesList?.scales ?? [],
  };
}
type ScalesDetailViewReduxProps = ReturnType<typeof mapStateToProps>;
type ScalesDetailViewDispatchProps = {
  devErrorAndLog: ConnectedDispatchFunction<typeof devErrorAndLog>,
  updateAdminTableData: ConnectedDispatchFunction<typeof updateDataTableRecord>,
  fetchPlantList: ConnectedDispatchFunction<typeof fetchPlantList>,
  fetchScaleList: ConnectedDispatchFunction<typeof fetchScaleList>,
};
export type ScalesDetailViewProps = ScalesDetailViewReduxProps & ScalesDetailViewDispatchProps & ScalesDetailViewOwnProps;

export default connect(mapStateToProps, {
  updateAdminTableData: updateDataTableRecord,
  devErrorAndLog,
  fetchPlantList,
  openModal,
  closeModal,
  fetchScaleList,
})(ScalesDetailView);
