import React, { Component } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import cx from 'classnames';
import { IconTitleUnderline, DateWrapper } from '@trucktrax/trucktrax-common';
import {
  PlantDto,
  ProductInfoDto,
  TicketDto,
  UnitOfMeasure,
  getMixDesignItem,
  ScaleDto,
  OrderDto,
  StatusEventDto,
  ProductLine,
} from '@trucktrax/trucktrax-ts-common';
import { connect } from 'react-redux';
import { random } from 'lodash';
import {
  formatWeight,
  formatWeightMetric,
  getQuantityValue,
  getScaleTypeLabel,
  getSlumpLabel,
  getUomLabel,
} from '../../../../util/ticketUtil';
import styles from './TicketDetails.module.css';
import {
  AGGREGATE_STRING,
  CEMENT_VALUE,
  READYMIX_VALUE,
  NOT_AVAILABLE,
  TICKET_STATUS_OPEN,
  TICKET_STATUS_CLOSED_DRIVER,
  TICKET_STATUS_CLOSED_SYSTEM
} from '../../../../constants/appConstants';
import { getIdFromUrl } from '../../../../util/appUtil';
import { getExtraProducts } from '../../../../services/ticketsService';
import { fetchStatusList } from '../../../../services/statusesService';
import { getScale } from '../../../../services/scalesService';
import { fetchPlant } from '../../../../services/plantsService';
import { fetchOrder } from '../../../../services/ordersService';

export class TicketDetails extends Component<TicketDetailsProps, TicketDetailsState> {
  static defaultProps = {
    selectedTicket: undefined,
    scaleTrax: false,
    plant: undefined,
  };

  constructor(props: TicketDetailsProps) {
    super(props);
    this.state = {
      extraProducts: [],
      scale: undefined,
      plant: undefined,
      order: undefined,
      driverClosed: undefined,
    };
  }

  async componentDidMount() {
    await this.assembleTicketInfo();
  }

  async componentDidUpdate(prevProps: TicketDetailsProps) {
    if (this.props.selectedTicket?.plant !== prevProps.selectedTicket?.plant) {
      const plantId = Number(getIdFromUrl(this.props.selectedTicket?.plant?.url));
      const plant = await fetchPlant(plantId);
      this.setState({ plant });
    }
  }

  async assembleTicketInfo() {
    const { selectedTicket } = this.props;
    if (selectedTicket?.id) {
      const extraProducts = await getExtraProducts(selectedTicket?.id);
      const scaleUrl = selectedTicket?.scale ? selectedTicket.scale?.url : undefined;
      const plantId = Number(getIdFromUrl(selectedTicket?.plant?.url));
      const orderId = Number(getIdFromUrl(selectedTicket?.order?.url));
      const plant = await fetchPlant(plantId);
      const order = await fetchOrder(orderId);
      const scale = await getScale(scaleUrl);
      const driverClosed = await this.getDriverClosed(plant?.productLine);

      this.setState({
        extraProducts,
        scale,
        plant,
        order,
        driverClosed,
      });
    }
  }

  async getDriverClosed(productLine: ProductLine) {
    const { selectedTicket } = this.props;
    if (!selectedTicket || productLine !== READYMIX_VALUE) return false;
    const statusList = await this.props.fetchStatusList(selectedTicket);
    const inYardStatus = statusList.filter(i => i.status === 'InYard').pop();
    return inYardStatus?.driverAttested ?? false;
  }

  static readyMixMaterialDescription = (ticket?: TicketDto) => ticket && (
    <div className={styles.gridOneThreeColumn}>
      <div className={cx(styles.materialNum)}>
        <div className={cx(styles.boldFont, styles.materialNum)}>Mix #</div>
        <div
          className={cx(styles.regularFont, styles.bottom15)}
          data-test="ticket-drilldown-product-num"
        >
          {getMixDesignItem(ticket)?.productNumber || NOT_AVAILABLE}
        </div>
      </div>
      <div className={cx(styles.mixDescription)}>
        <div className={cx(styles.boldFont)}>Mix Description</div>
        <div
          className={cx(styles.regularFont, styles.bottom15)}
          data-test="ticket-drilldown-product-description"
        >
          {getMixDesignItem(ticket)?.productDescription || NOT_AVAILABLE}
        </div>
      </div>
    </div>
  );

  static readyMixMaterialDetails = (ticket?: TicketDto) => ticket && (
    <div className={styles.gridFourColumn}>
      <div className={styles.quantity}>
        <div className={cx(styles.boldFont)}>Quantity</div>
        <div
          className={cx(styles.regularFont, styles.bottom15)}
          data-test="ticket-drilldown-quantity"
        >
          {getQuantityValue(ticket)}
        </div>
      </div>
      <div className={styles.uom}>
        <div className={cx(styles.boldFont)}>UOM</div>
        <div
          className={cx(styles.regularFont, styles.bottom15)}
          data-test="ticket-drilldown-unit-of-measurement"
        >
          {getUomLabel(ticket)}
        </div>
      </div>
      <div className={styles.slump}>
        <div className={cx(styles.boldFont)}>Slump</div>
        <div
          className={cx(styles.regularFont, styles.bottom15)}
          data-test="ticket-drilldown-slump"
        >
          {getSlumpLabel(ticket)}
        </div>
      </div>
    </div>
  );

  static haulerMaterialDescription = (ticket?: TicketDto) => ticket && (
    <div>
      <div className={cx(styles.boldFont)}>Material Description</div>
      <div
        className={cx(styles.regularFont, styles.bottom15)}
        data-test="ticket-drilldown-product-description"
      >
        {getMixDesignItem(ticket)?.productDescription}
      </div>
    </div>
  );

  static haulerMaterialDetails = (ticket?: TicketDto) => ticket && (
    <div className={styles.gridFiveColumn}>
      <div className={cx(styles.materialNum)}>
        <div className={cx(styles.boldFont, styles.materialNum)}>Material #</div>
        <div
          className={cx(styles.regularFont, styles.bottom15)}
          data-test="ticket-drilldown-product-num"
        >
          {getMixDesignItem(ticket)?.productNumber}
        </div>
      </div>
      <div className={styles.loadNum}>
        <div className={cx(styles.boldFont, styles.left12)}>Load #</div>
        <div
          className={cx(styles.regularFont, styles.bottom15, styles.left12)}
          data-test="ticket-drilldown-load-num"
        >
          {ticket.loadNumber}
        </div>
      </div>
    </div>
  );

  scaleDetails = (ticket?: TicketDto) => ticket && (
    <div className={styles.gridFiveColumn}>
      <div className={cx(styles.materialNum)}>
        <div className={cx(styles.boldFont, styles.materialNum)}>Scale Name</div>
        <div
          className={cx(styles.regularFont, styles.bottom15)}
          data-test="ticket-drilldown-scaleName"
        >
          {this.state.scale ? this.state.scale.name : 'N/A'}
        </div>
      </div>
      <div>
        <div className={cx(styles.boldFont, styles.left12)}>Scale Type</div>
        <div
          className={cx(styles.regularFont, styles.bottom15, styles.left12)}
          data-test="ticket-drilldown-scaleType"
        >
          {getScaleTypeLabel(ticket)}
        </div>
      </div>
    </div>
  );

  static formatExtraProductQuantity = (quantity?: string) => {
    try {
      if (!quantity) {
        return '';
      }

      const newQtyNumber = Number(quantity);

      const newQtyString = newQtyNumber.toFixed(2);
      if (newQtyString.toLowerCase() === 'nan') {
        return quantity;
      }

      return newQtyString;
    } catch {
      return quantity;
    }
  };

  renderExtraProducts = () => {
    const extraProducts = this.state.extraProducts ?? [];
    if (!extraProducts.length) {
      return null;
    }
    return (
      <div>
        <div className={cx(styles.boldFont, styles.top5)}>Additional Products</div>
        <Table className={styles.additionalProductsTable}>
          <TableHead>
            <TableCell>Qty</TableCell>
            <TableCell>UOM</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Description</TableCell>
          </TableHead>
          <TableBody>
            {extraProducts!.map(p => (
              // this exists to suppress a console warning that react throws about requiring a unique key, it appears to only affect tests
              <React.Fragment key={random()}>
                <TableRow>
                  <TableCell>{TicketDetails.formatExtraProductQuantity(p.quantity)}</TableCell>
                  <TableCell>{p.UOM}</TableCell>
                  <TableCell>{p.code}</TableCell>
                  <TableCell>{p.description}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  static renderScaleType = (ticket: TicketDto) => (
    <div className={cx(styles.scaleType)}>
      <div className={cx(styles.boldFont)}>Scale Type</div>
      <div
        className={cx(styles.regularFont)}
        data-test="ticket-drilldown-scale-type"
      >
        {getScaleTypeLabel(ticket)}
      </div>
    </div>
  );

  static notAvailable = () => (
    <div className={cx(styles.regularFont, styles.notAvailable, styles.bottom15)}>
      {NOT_AVAILABLE}
    </div>
  );

  onOrderClick = () => {
    const {
      selectedTicket, shrinkPopover, scaleTrax,
    } = this.props;
    const orderId = selectedTicket?.order ? getIdFromUrl(selectedTicket?.order?.url!) : '';
    if (!scaleTrax) {
      shrinkPopover();
    }
    const url = `/geotrax/orders/all/${orderId}`;
    const newTab = window.open(url, 'GeoTrax');
    if (newTab) {
      newTab.focus();
    }
  };

  static tbdOrWeight = (condition: boolean, falseString: string): string => ((condition)
    ? 'TBD'
    : falseString);

  render() {
    const { selectedTicket } = this.props;
    // wait for order, scale and plant to be in state
    if ((selectedTicket?.order && !this.state.order)
      || (selectedTicket?.plant && !this.state.plant)) {
      return null;
    }
    const {
      gross, tare, net, unitOfMeasure,
    } = getMixDesignItem(selectedTicket)?.quantity || {};
    const startPlant = this.state.plant;
    const projectId = this.state.order?.projectExternalId;

    const address = selectedTicket?.address || '';
    const address1 = address.substr(0, address.indexOf('\n'));
    const address2 = address.substr(address.indexOf('\n') + 1);

    const lotNumbers = selectedTicket?.lotNum?.replace(/,(?=[^\s])/g, ', ');
    const editableTicketWeights = startPlant?.editableTicketWeights || false;
    const isEditableAndOpen = editableTicketWeights && !selectedTicket?.closed;

    const orderNumber = selectedTicket?.order?.orderNumber;
    const totalCubicYards = selectedTicket?.order?.totalCubicYards;
    const deliveredQuantityRMTicket = getMixDesignItem(selectedTicket)?.quantity?.delivered ?? 0;

    /*
      for now only do bulk weight section and conversions if weights are POUNDS, KILOGrAMS, TONS or SHORT_TONS
      otherwise just show the values and the unit of measure as is
    */

    const isKG = (unitOfMeasure === 'METRIC_TONS' || unitOfMeasure === 'KILOGRAMS');
    const isPounds = (unitOfMeasure === 'SHORT_TONS' || unitOfMeasure === 'POUNDS');
    let weightLbl = unitOfMeasure ? unitOfMeasure.toString().replace('_', ' ') : '';
    let weightLblBulk = ''; // only used for tons and short tons

    let grossDisplay; let tareDisplay; let netDisplay; let grossBulkDisplay; let tareBulkDisplay; let
      netBulkDisplay;

    grossDisplay = gross;
    tareDisplay = tare;
    netDisplay = net;

    if (isPounds) {
      weightLbl = 'Weight (lbs)';
      weightLblBulk = 'Weight (tons)';
      grossDisplay = TicketDetails.tbdOrWeight(isEditableAndOpen, formatWeight(UnitOfMeasure.POUNDS, unitOfMeasure, gross ?? 0));
      tareDisplay = TicketDetails.tbdOrWeight(isEditableAndOpen, formatWeight(UnitOfMeasure.POUNDS, unitOfMeasure, tare ?? 0));
      netDisplay = TicketDetails.tbdOrWeight(isEditableAndOpen, formatWeight(UnitOfMeasure.POUNDS, unitOfMeasure, net ?? 0));
      grossBulkDisplay = TicketDetails.tbdOrWeight(isEditableAndOpen, formatWeight(UnitOfMeasure.SHORT_TONS, unitOfMeasure, gross ?? 0));
      tareBulkDisplay = TicketDetails.tbdOrWeight(isEditableAndOpen, formatWeight(UnitOfMeasure.SHORT_TONS, unitOfMeasure, tare ?? 0));
      netBulkDisplay = TicketDetails.tbdOrWeight(isEditableAndOpen, formatWeight(UnitOfMeasure.SHORT_TONS, unitOfMeasure, net ?? 0));
    }

    if (isKG) {
      weightLbl = 'Weight (kg)';
      weightLblBulk = 'Weight (mt)';
      grossDisplay = TicketDetails.tbdOrWeight(isEditableAndOpen, formatWeightMetric(UnitOfMeasure.KILOGRAMS, unitOfMeasure, gross ?? 0));
      tareDisplay = TicketDetails.tbdOrWeight(isEditableAndOpen, formatWeightMetric(UnitOfMeasure.KILOGRAMS, unitOfMeasure, tare ?? 0));
      netDisplay = TicketDetails.tbdOrWeight(isEditableAndOpen, formatWeightMetric(UnitOfMeasure.KILOGRAMS, unitOfMeasure, net ?? 0));
      grossBulkDisplay = TicketDetails.tbdOrWeight(
        isEditableAndOpen,
        formatWeightMetric(UnitOfMeasure.METRIC_TONS, unitOfMeasure, gross ?? 0)
      );
      tareBulkDisplay = TicketDetails.tbdOrWeight(
        isEditableAndOpen,
        formatWeightMetric(UnitOfMeasure.METRIC_TONS, unitOfMeasure, tare ?? 0)
      );
      netBulkDisplay = TicketDetails.tbdOrWeight(isEditableAndOpen, formatWeightMetric(UnitOfMeasure.METRIC_TONS, unitOfMeasure, net ?? 0));
    }

    const timestampWrapper = new DateWrapper(selectedTicket?.ticketedTimestamp);
    const ticketedDatestamp = timestampWrapper.toUSDateString();
    const ticketedTimestamp = timestampWrapper.to12HourClockString();

    const manuallyEditText = (
      editableTicketWeights
        ? (
          <span className={cx(styles.manuallyEditedWeights)}>
            *Manually edited weights
          </span>
        )
        : null
    );

    const productLine = startPlant?.productLine;
    let materialDescription = null;
    const isReadyMixOrAggregate = (productLine === READYMIX_VALUE || productLine === AGGREGATE_STRING);
    const showLotNumber = isReadyMixOrAggregate && selectedTicket?.lotNum;

    const isTicketClosed = !!selectedTicket?.closed;

    switch (productLine) {
      case READYMIX_VALUE:
        materialDescription = TicketDetails.readyMixMaterialDescription(selectedTicket);
        break;
      default:
        materialDescription = TicketDetails.haulerMaterialDescription(selectedTicket);
        break;
    }
    let materialDetails = null;
    switch (productLine) {
      case READYMIX_VALUE:
        materialDetails = TicketDetails.readyMixMaterialDetails(selectedTicket);
        break;
      default:
        materialDetails = TicketDetails.haulerMaterialDetails(selectedTicket);
        break;
    }
    const scaleDetails = this.scaleDetails(selectedTicket);

    let { deliveryReceivedBy } = selectedTicket || {};
    const checkBoxChecked = selectedTicket?.deliveryReceivedTimestamp
      && !selectedTicket.deliveryReceivedBy;
    const checkBoxWithCustomer = selectedTicket?.deliveryReceivedTimestamp
      && selectedTicket.deliveryReceivedBy;

    let deliveryReceivedByStyle = cx(styles.regularFont, styles.bottom15);
    if (!deliveryReceivedBy) {
      deliveryReceivedBy = '';
      deliveryReceivedByStyle = cx(styles.regularFont, styles.customerUnavailable, styles.bottom15);
    }
    if (checkBoxChecked) {
      deliveryReceivedBy = 'Customer unavailable';
      deliveryReceivedByStyle = cx(styles.regularFont, styles.customerUnavailable, styles.bottom15);
    }
    if (checkBoxWithCustomer) {
      deliveryReceivedBy = selectedTicket?.deliveryReceivedBy;
      deliveryReceivedByStyle = cx(styles.regularFont, styles.customer, styles.bottom15);
    }

    let ticketClosedByStyle = styles.ticketOpen;
    let ticketClosedByMessage = TICKET_STATUS_OPEN;
    if (isTicketClosed) {
      ticketClosedByStyle = styles.ticketClosedBy;
      if (this.state.driverClosed) {
        ticketClosedByMessage = TICKET_STATUS_CLOSED_DRIVER;
      } else {
        ticketClosedByMessage = TICKET_STATUS_CLOSED_SYSTEM;
      }
    }

    const orderNumberBtnTxt = `Order #${orderNumber}`;
    return (
      <Paper
        id="ticketPopover-paperElement"
        className={cx(styles.paper, selectedTicket?.voided && styles.voided)}
      >
        <h2 className={styles.ticketDetailsHeader}>Details</h2>
        <div className={cx(styles.ticketDetailsBody)}>
          <div className={cx(styles.customer)}>
            <IconTitleUnderline
              icon="icon-person"
              title="Customer"
              className={cx(styles.icon, styles.bottomPad10)}
            />
            <div className={cx(styles.bottom15, styles.topPad2)}>
              <span
                className={styles.boldFont}
                data-test="ticket-drilldown-customer-name"
              >
                {selectedTicket?.order?.customerName}
              </span>
            </div>
            <p
              className={styles.projectName}
              data-test="ticket-drilldown-project-name"
            >
              <span className={cx(styles.boldFont, 'margin-right-5')}>
                Project:
              </span>

              {selectedTicket?.order?.projectName
                ? (
                  <span className={styles.regularFont}>
                    {`${projectId} — ${selectedTicket!.order!.projectName}`}
                  </span>
                )
                : (
                  <span className={cx(styles.regularFont, styles.notAvailable)}>
                    {NOT_AVAILABLE}
                  </span>
                )}
            </p>
            <div>
              <p
                className={cx(styles.regularFont, styles.bottom0)}
                data-test="ticket-drilldown-delivery-address"
              >
                {address1}
              </p>
              <p className={cx(styles.regularFont, styles.bottom0)}>{address2}</p>
              {showLotNumber ? (
                <p
                  className={cx(styles.regularFont)}
                  data-test="ticket-lot-number"
                >
                  Lot #:
                  {' '}
                  {lotNumbers}
                </p>
              ) : null}
            </div>
          </div>
          <div className={cx(styles.driver)}>
            <IconTitleUnderline
              icon="icon-drive-eta"
              title="Vehicle"
              className={cx(styles.icon, styles.bottomPad10)}
            />
            {(productLine !== READYMIX_VALUE) && (
              <div>
                <div className={cx(styles.boldFont)}>Hauler</div>
                <div
                  className={cx(styles.regularFont, styles.bottom15)}
                  data-test="ticket-drilldown-hauler-name"
                >
                  {selectedTicket?.hauler}
                </div>
              </div>
            )}

            <div className={cx(styles.boldFont)}>Truck #</div>
            <div
              className={cx(styles.regularFont, styles.bottom15)}
              data-test="ticket-drilldown-vehicle-number"
            >
              {selectedTicket?.externalInfo?.truckAlias}
            </div>

            <div className={cx(styles.boldFont)}>Driver</div>

            {selectedTicket?.externalInfo?.driverName
              ? (
                <div
                  className={cx(styles.regularFont)}
                  data-test="ticket-drilldown-driver-name"
                >
                  {selectedTicket?.externalInfo?.driverName}
                </div>
              )
              : TicketDetails.notAvailable()}
          </div>

          <div className={productLine === READYMIX_VALUE ? (styles.statusesRMTicket) : styles.statusesNONRMTicket}>
            <IconTitleUnderline
              icon="icon-access-time"
              title="Statuses"
              className={cx(styles.icon, styles.bottomPad10)}
            />
            <div className={cx(styles.boldFont)}>Loading Plant</div>
            <div
              className={cx(styles.regularFont, styles.bottom15)}
              data-test="ticket-drilldown-loading-plant"
            >
              {startPlant && startPlant.name}
            </div>
            <div className={cx(styles.boldFont)}>Ticket Date</div>
            <div
              className={cx(styles.regularFont, styles.bottom15)}
              data-test="ticket-drilldown-ticketed-date"
            >
              {ticketedDatestamp}
            </div>
            <div className={cx(styles.boldFont)}>Ticket Time</div>
            <div
              className={cx(styles.regularFont, styles.bottom15)}
              data-test="ticket-drilldown-ticketed-time"
            >
              {ticketedTimestamp}
            </div>
            <div className={cx(styles.boldFont)}>Received By</div>
            {deliveryReceivedBy
              ? (
                <div
                  className={deliveryReceivedByStyle}
                  data-test="ticket-drilldown-received-by"
                >
                  {deliveryReceivedBy}
                </div>
              )
              : TicketDetails.notAvailable()}
            {productLine === READYMIX_VALUE && (
              <div
                className={ticketClosedByStyle}
                data-test="ticket-closed-by"
              >
                {ticketClosedByMessage}
              </div>
            )}
          </div>

          <div className={cx(styles.material)}>
            <IconTitleUnderline
              icon="icon-terrain"
              title="Material"
              className={cx(styles.icon, styles.bottomPad10)}
            />
            {materialDescription}
            {materialDetails}
            {(productLine !== READYMIX_VALUE) && scaleDetails}
            {this.renderExtraProducts()}
            {productLine !== READYMIX_VALUE
              ? (
                <div>
                  <div className={cx(styles.boldFont, styles.top5)}>Weighmaster</div>
                  <div className={cx(styles.regularFont, styles.bottom15)}>
                    {selectedTicket?.weighmaster ? selectedTicket?.weighmaster : TicketDetails.notAvailable()}
                  </div>
                </div>
              )
              : <></>}
            <div className={cx(styles.boldFont, styles.top5)}>Delivery Instructions</div>
            {selectedTicket?.deliveryInstructions
              ? (
                <div
                  className={cx(styles.regularFont, styles.bottom15)}
                  data-test="ticket-drilldown-delivery-instructions"
                >
                  {selectedTicket?.deliveryInstructions}
                </div>
              )
              : TicketDetails.notAvailable()}

            <div className={cx(styles.notes)}>
              <IconTitleUnderline
                icon="icon-create"
                title="Driver Notes"
                className={cx(styles.icon, styles.bottomPad10)}
              />
              {selectedTicket?.driverNotes
                ? (
                  <div
                    className={cx(styles.regularFont)}
                    data-test="ticket-drilldown-driver-notes"
                  >
                    {selectedTicket?.driverNotes}
                  </div>
                )
                : TicketDetails.notAvailable()}
            </div>
          </div>

          {/* Don't render weights section in Ready-mix tickets */}
          {(productLine !== READYMIX_VALUE) && (
            <div className={styles.weight}>
              <IconTitleUnderline
                icon="icon-weight"
                title={weightLbl}
                className={cx(styles.icon, styles.bottomPad10)}
              />
              <table className={styles.weightTable}>
                <tbody>
                  <tr>
                    <td className={styles.weightTableLabel}>Gross Weight</td>
                    <td className={styles.weightTableValue}>{grossDisplay}</td>
                  </tr>
                  <tr>
                    <td className={styles.weightTableLabel}>Tare Weight</td>
                    <td className={styles.weightTableValue}>{tareDisplay}</td>
                  </tr>
                  <tr>
                    <td className={styles.weightTableLabel}>Net Weight</td>
                    <td className={styles.weightTableValue}>{netDisplay}</td>
                  </tr>
                </tbody>
              </table>
              <IconTitleUnderline
                icon="icon-weight"
                title={weightLblBulk}
                className={cx(styles.icon, styles.bottomPad10)}
              />
              <table className={styles.weightTable}>
                <tbody>
                  <tr>
                    <td className={styles.weightTableLabel}>Gross Weight</td>
                    <td className={styles.weightTableValue}>{grossBulkDisplay}</td>
                  </tr>
                  <tr>
                    <td className={styles.weightTableLabel}>Tare Weight</td>
                    <td className={styles.weightTableValue}>{tareBulkDisplay}</td>
                  </tr>
                  <tr>
                    <td className={styles.weightTableLabel}>Net Weight</td>
                    <td className={styles.weightTableValue}>{netBulkDisplay}</td>
                  </tr>
                </tbody>
              </table>

              {manuallyEditText}

            </div>
          )}

          {/* Render empty flex element instead of weights section for Ready-mix tickets */}
          {(productLine === READYMIX_VALUE) && <div />}

          <div className={styles.orderScaleTrax}>
            <IconTitleUnderline
              icon="icon-orders"
              title="Order"
              className={cx(styles.icon, styles.bottomPad10)}
            />
            <button className={cx(styles.orderNumber, styles.boldFont)} onClick={this.onOrderClick}>
              {orderNumberBtnTxt}
            </button>

            <div className={cx(styles.boldFont, styles.top15)}>Job Number</div>
            {this.state.order?.jobNumber
              ? (
                <div
                  className={cx(styles.regularFont)}
                  data-test="ticket-drilldown-jobnumber"
                >
                  {this.state.order?.jobNumber ? this.state.order?.jobNumber : ''}
                </div>
              )
              : TicketDetails.notAvailable()}

            <div className={cx(styles.boldFont, styles.top20)}>PO Number</div>
            {this.state.order?.purchaseOrder
              ? (
                <div
                  className={cx(styles.regularFont)}
                  data-test="ticket-drilldown-ponumber"
                >
                  {this.state.order?.purchaseOrder ? this.state.order?.purchaseOrder : ''}
                </div>
              )
              : TicketDetails.notAvailable()}

            {(productLine === READYMIX_VALUE)
              && (
                <>
                  <div className={cx(styles.boldFont, styles.top20)}>Load #</div>
                  <div
                    className={cx(styles.regularFont)}
                    data-test="ticket-drilldown-load-num"
                  >
                    {selectedTicket?.loadNumber}
                  </div>
                  <div className={cx(styles.boldFont, styles.top20)}>Cubic Yards</div>
                  <div
                    className={cx(styles.regularFont)}
                    data-test="ticket-quantity-cubic-yard"
                  >
                    {deliveredQuantityRMTicket?.toFixed(2)}
                    {' '}
                    of
                    {' '}
                    {totalCubicYards?.toFixed(2)}
                    {' '}
                    total
                  </div>
                </>
              )}

            {(productLine !== READYMIX_VALUE)
              && (
                <>
                  <div className={cx(styles.boldFont, styles.top20)}>Delivery Type</div>
                  <div
                    className={cx(styles.regularFont)}
                    data-test="ticket-drilldown-deliverytype"
                  >
                    {this.state.order?.fob ? 'FOB' : 'Delivered'}
                  </div>
                </>
              )}

            {(productLine === CEMENT_VALUE)
              && (
                <>
                  <div className={cx(styles.boldFont, styles.top20)}>Cert ID</div>
                  {selectedTicket?.lotNum
                    ? (
                      <div
                        className={cx(styles.regularFont)}
                        data-test="ticket-drilldown-paymenttype"
                      >
                        {selectedTicket?.lotNum}
                      </div>
                    )
                    : TicketDetails.notAvailable()}
                </>
              )}

          </div>

        </div>
      </Paper>
    );
  }
}

export interface TicketDetailsProps {
  selectedTicket?: TicketDto,
  shrinkPopover: () => void,
  scaleTrax?: boolean,
  plant?: PlantDto,
  fetchStatusList: (ticket: TicketDto) => Promise<StatusEventDto[]>;
}

export interface TicketDetailsState {
  extraProducts?: ProductInfoDto[],
  scale?: ScaleDto,
  plant?: PlantDto,
  order?: OrderDto,
  driverClosed?: boolean
}

export function mapStateToProps(state: any) {
  return {
    selectedTicket: state.selectedTicket,
    plantList: state.plantList,
    plant: state.plant
  };
}

export default connect<any, any, any>(mapStateToProps, { fetchStatusList })(TicketDetails as any);
