import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { PermissionAccess, UserDto } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import DataTableContent from '../../shared/DataTableContent';
import {
  addDataTest,
  addHeaderDataTest,
  getDefaultRegion,
  getFullName,
  secondaryProductLineAccessor,
} from '../../../util/adminUtil';
import UsersAddModal from './UsersAddModal';
import { USERS_TEXT } from '../../../constants/navConstants';
import { ADMIN_KEYS, ADMIN_LABELS } from '../../../constants/appConstants';
import { DATASOURCE, USERS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import styles from './UsersView.module.css';

// TODO: Rename all these {type}AdminTable.js to {type}TablePage.js
export class UsersView extends Component<UsersViewProps> {
  static defaultProps = {
    regions: [],
    regionList: [],
    name: '',
    usersPermissionAccess: '',
    tab: 0,
  };

  state = {
    isOpen: false,
    isCloseRequest: false,
  };

  static getSsoId = (item: UserDto) => (item?.employeeNumber ? item.employeeNumber : 'Not set');

  getColumns() {
    if (this.props.tab === 1) {
      return [
        {
          Header: addHeaderDataTest(null, 'sort-user-by-fullname', ADMIN_LABELS.NAME),
          id: ADMIN_KEYS.LAST_NAME,
          accessor: getFullName,
          minWidth: 150,
          Cell: (props: any) => addDataTest(props, 'admin-user-fullname', props.value),
        },
        {
          Header: addHeaderDataTest(null, 'sort-user-by-primaryproductline', ADMIN_LABELS.PRIMARY_PRODUCT_LINE),
          accessor: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
          minWidth: 185,
          Cell: (props: any) => addDataTest(props, 'admin-user-primaryproductline', props.value),
        },
        {
          Header: ADMIN_LABELS.DEFAULT_REGION,
          id: ADMIN_KEYS.REGION,
          accessor: (row: any) => this.getDefaultRegion(row),
          minWidth: 150,
          sortable: false,
        },
        {
          Header: addHeaderDataTest(null, 'sort-user-by-secondaryproductline', ADMIN_LABELS.SECONDARY_PRODUCT_LINES),
          id: ADMIN_KEYS.SECONDARY_PRODUCT_LINES,
          accessor: secondaryProductLineAccessor,
          minWidth: 185,
          Cell: (props: any) => addDataTest(props, 'admin-user-secondaryproductline', props.value),
          sortable: false,
        },
        {
          Header: addHeaderDataTest(null, 'sort-user-by-username', ADMIN_LABELS.USERNAME),
          accessor: ADMIN_KEYS.USERNAME,
          minWidth: 150,
          Cell: (props: any) => addDataTest(props, 'admin-user-username', props.value),
        },
        {
          Header: addHeaderDataTest(null, 'sort-user-by-externalId', ADMIN_LABELS.EXTERNAL_ID),
          id: ADMIN_KEYS.EXTERNAL_ID,
          accessor: ADMIN_KEYS.EXTERNAL_ID,
          minWidth: 130,
          Cell: (props: any) => addDataTest(props, 'admin-user-externalId', props.value),
        },
        {
          Header: addHeaderDataTest(null, 'sort-user-by-ssoId', ADMIN_LABELS.SSO_ID),
          id: ADMIN_KEYS.SSO_ID,
          accessor: UsersView.getSsoId,
          minWidth: 130,
          style: {
            fontStyle: 'italic',
            color: '#878E93',
          },
          Cell: (props: any) => addDataTest(props, 'admin-user-ssoId', props.value),
        },
        {
          Header: addHeaderDataTest(null, 'sort-user-by-email', ADMIN_LABELS.EMAIL),
          accessor: ADMIN_KEYS.EMAIL,
          minWidth: 300,
          Cell: (props: any) => addDataTest(props, 'admin-user-email', props.value),
        },
        {
          Header: addHeaderDataTest(null, 'sort-user-by-phone', ADMIN_LABELS.PHONE),
          accessor: ADMIN_KEYS.PHONE,
          minWidth: 300,
          Cell: (props: any) => addDataTest(props, 'admin-user-phone', props.value),
        },
        {
          Header: addHeaderDataTest(null, 'sort-user-by-deactivated-date', ADMIN_LABELS.DEACTIVATED_DATE),
          id: ADMIN_KEYS.ARCHIVED_DATE,
          accessor: ADMIN_KEYS.ARCHIVED_DATE,
          minWidth: 110,
          Cell: (props: any) => addDataTest(
            null,
            'admin-user-deactivated-date',
            new DateWrapper(props.value).toUSDateString()
          ),
        },

      ];
    }
    return [
      {
        Header: addHeaderDataTest(null, 'sort-user-by-fullname', ADMIN_LABELS.NAME),
        id: ADMIN_KEYS.LAST_NAME,
        accessor: getFullName,
        minWidth: 150,
        Cell: (props: any) => addDataTest(props, 'admin-user-fullname', props.value),
      },
      {
        Header: addHeaderDataTest(null, 'sort-user-by-primaryproductline', ADMIN_LABELS.PRIMARY_PRODUCT_LINE),
        accessor: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
        minWidth: 185,
        Cell: (props: any) => addDataTest(props, 'admin-user-primaryproductline', props.value),
      },
      {
        Header: ADMIN_LABELS.DEFAULT_REGION,
        id: ADMIN_KEYS.REGION,
        accessor: (row: any) => this.getDefaultRegion(row),
        minWidth: 150,
        sortable: false,
      },
      {
        Header: addHeaderDataTest(null, 'sort-user-by-secondaryproductline', ADMIN_LABELS.SECONDARY_PRODUCT_LINES),
        id: ADMIN_KEYS.SECONDARY_PRODUCT_LINES,
        accessor: secondaryProductLineAccessor,
        minWidth: 185,
        Cell: (props: any) => addDataTest(props, 'admin-user-secondaryproductline', props.value),
        sortable: false,
      },
      {
        Header: addHeaderDataTest(null, 'sort-user-by-username', ADMIN_LABELS.USERNAME),
        accessor: ADMIN_KEYS.USERNAME,
        minWidth: 150,
        Cell: (props: any) => addDataTest(props, 'admin-user-username', props.value),
      },
      {
        Header: addHeaderDataTest(null, 'sort-user-by-externalId', ADMIN_LABELS.EXTERNAL_ID),
        id: ADMIN_KEYS.EXTERNAL_ID,
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        minWidth: 130,
        Cell: (props: any) => addDataTest(props, 'admin-user-externalId', props.value),
      },
      {
        Header: addHeaderDataTest(null, 'sort-user-by-ssoId', ADMIN_LABELS.SSO_ID),
        id: ADMIN_KEYS.SSO_ID,
        accessor: UsersView.getSsoId,
        style: {
          fontStyle: 'italic',
          color: '#878E93',
        },
        minWidth: 130,
        Cell: (props: any) => addDataTest(props, 'admin-user-ssoId', props.value),
      },
      {
        Header: addHeaderDataTest(null, 'sort-user-by-email', ADMIN_LABELS.EMAIL),
        accessor: ADMIN_KEYS.EMAIL,
        minWidth: 300,
        Cell: (props: any) => addDataTest(props, 'admin-user-email', props.value),
      },
      {
        Header: addHeaderDataTest(null, 'sort-user-by-phone', ADMIN_LABELS.PHONE),
        accessor: ADMIN_KEYS.PHONE,
        minWidth: 150,
        Cell: (props: any) => addDataTest(props, 'admin-user-phone', props.value),
      },
    ];
  }

  getDefaultRegion = (row: { regions: [any]; }) => (row.regions ? getDefaultRegion(row, this.props.regionList) : '');

  requestCloseModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      isCloseRequest: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: false,
    });
  };

  handleTabChange = (_event: any, newValue: number) => {
    if (newValue === 1) {
      this.props.history.push('/admin/users/deactivated');
    } else if (newValue === 0) {
      this.props.history.push('/admin/users');
    }
  };

  render() {
    const baseUrl = getGeotraxBaseUrl() + USERS_PATH;
    const columns = this.getColumns();
    const hasPermissionAccess = this.props.usersPermissionAccess === PermissionAccess.Edit;

    const tabs = (
      <Tabs
        value={this.props.tab}
        onChange={this.handleTabChange}
        classes={
          {
            root: styles.tabsContainer,
          }
        }
      >
        <Tab label="Active" />
        <Tab label="Deactivated" />
      </Tabs>
    );
    const props: any = {
      baseUrl,
      columns,
      header: USERS_TEXT,
      renderTabs: tabs,
      isArchived: this.props.tab === 1,
      canAddNewItem: hasPermissionAccess,
      byPassRedisCache: true,
      source: DATASOURCE.USER
    };
    return (
      <div className={styles.container}>
        <DataTableContent {...props} />
        {hasPermissionAccess
          && (
            <UsersAddModal
              isOpen={this.state.isOpen}
              openModal={this.openModal}
              regionList={this.props.regionList}
              closeModal={this.requestCloseModal}
              regions={this.props.regions}
              discardAcceptAction={this.closeModal}
              discardRejectAction={this.openModal}
              isCloseRequest={this.state.isCloseRequest}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  regionList: state.regionList,
  regions: state.assignedRegionList,
  usersPermissionAccess: state.adminPermissionAccess[USERS_TEXT],
});

type History = {
  push: (p: string) => void
};

export interface UsersViewProps {
  regionList: any[],
  regions: any[],
  name: string,
  usersPermissionAccess?: string
  tab: number,
  history: History
}

export default connect(mapStateToProps)(UsersView);
