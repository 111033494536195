import { ORDERS_HEADER } from './appConstants';

export const navItems = [
  {
    label: 'Region Map',
    icon: 'map',
    navRoute: '/map',
    dataTest: 'RegionMap',
  },
  {
    label: 'Docs',
    icon: 'docs',
    navRoute: '/docs',
    dataTest: 'Docs',
  },
];

export const VEHICLES_TEXT = 'Vehicles';
export const TRUCKS_TEXT = 'Trucks';
export const VEHICLE_TYPES_TEXT = 'Vehicle Types';
export const FLAGS_TEXT = 'Feature Flags';
export const REPORTS_TEXT = 'Reports';
export const DRIVERS_TEXT = 'Drivers';
export const PLANTS_TEXT = 'Plants';
export const DEVICES_TEXT = 'Devices';
export const HAULERS_TEXT = 'Haulers';
export const SCALES_TEXT = 'Scales';
export const TICKETS_TEXT = 'Tickets';
export const GEOZONES_TEXT = 'Geozones';
export const REGIONS_TEXT = 'Regions';
export const CHECKLISTS_TEXT = 'Checklists';
export const USERS_TEXT = 'Users';
export const PERMISSIONS_TEXT = 'Security Permissions';
export const GROUP_PERMISSIONS_TEXT = 'Security Permissions Groups';
export const USER_PERMISSIONS_TEXT = 'Security Permissions Users';
export const OPEN_TICKETS_TEXT = 'Open Tickets';
export const GLINX_DEVICE_ROUTE = '/admin/devices/glinx/';
export const TABLET_DEVICE_ROUTE = '/admin/devices/';

export interface AdminCategory {
  name: string;
  icon?: string;
  navRoute?: string;
  companyWide: boolean;
  subCategories?: AdminCategory[];
}

export interface NavItem {
  label?: string;
  icon?: string;
  navRoute?: string;
  dataTest?: string;
}

export const categoriesForAdmin: AdminCategory[] = [
  {
    name: DRIVERS_TEXT,
    icon: 'icon-wheel',
    navRoute: '/admin/drivers',
    companyWide: false,
  },
  {
    name: USERS_TEXT,
    icon: 'icon-headset-mic',
    navRoute: '/admin/users',
    companyWide: false,
  },
  {
    name: VEHICLES_TEXT,
    icon: 'icon-truck-dump',
    companyWide: false,
    navRoute: '/admin/trucks',
    subCategories: [
      {
        name: VEHICLES_TEXT,
        navRoute: '/admin/trucks',
        companyWide: false,
      },
      {
        name: VEHICLE_TYPES_TEXT,
        navRoute: '/admin/vehicletypes',
        companyWide: false,
      },
    ],
  },
  {
    name: PLANTS_TEXT,
    icon: 'icon-flag',
    navRoute: '/admin/plants',
    companyWide: false,
  },
  {
    name: SCALES_TEXT,
    icon: 'icon-scale',
    navRoute: '/admin/scales',
    companyWide: false,
  },
  {
    name: DEVICES_TEXT,
    icon: 'icon-tablet',
    navRoute: '/admin/devices',
    companyWide: false,
  },
  {
    name: HAULERS_TEXT,
    icon: 'icon-people',
    navRoute: '/admin/haulers',
    companyWide: false,
  },
  {
    name: GEOZONES_TEXT,
    icon: 'icon-zones',
    navRoute: '/admin/geozones',
    companyWide: false,
  },
  {
    name: REGIONS_TEXT,
    icon: 'icon-map',
    navRoute: '/admin/regions',
    companyWide: true,
  },
  {
    name: CHECKLISTS_TEXT,
    icon: 'icon-check-box-outline',
    navRoute: '/admin/checklists',
    companyWide: true,
  },
  {
    name: OPEN_TICKETS_TEXT,
    icon: 'icon-tickets',
    navRoute: '/admin/tickets',
    companyWide: true,
  },
  {
    name: PERMISSIONS_TEXT,
    icon: 'icon-lock-solid',
    navRoute: '/admin/permissions',
    companyWide: true,
  },
  {
    name: FLAGS_TEXT,
    icon: 'icon-toggle',
    navRoute: '/admin/featureflags',
    companyWide: true,
  },
  {
    name: REPORTS_TEXT,
    icon: 'icon-spreadsheet',
    navRoute: '/admin/reports',
    companyWide: true,
  },
];

/**
 * {@link https://trucktrax.atlassian.net/browse/DEV-2} introduced a change where vehicles and vehicle types
 * are separate nav items. However, this change is intended to be
 * used in conjunction with the feature flag function: @see {@link isExternalVehicleAddEditEnabled}
 */
export const featureFlagVehicleNavItem = {
  index: 2,
  itemsOffset: 1,
  items: [
    {
      name: VEHICLES_TEXT,
      icon: 'icon-truck-dump',
      companyWide: false,
      navRoute: '/admin/trucks',
    },
    {
      name: VEHICLE_TYPES_TEXT,
      icon: 'icon-view-list',
      navRoute: '/admin/vehicletypes',
      companyWide: false,
    },
  ],
};

export const categoriesForDocsGuides = [
  {
    name: 'GeoTrax Web',
    navRoute: '/geotrax/docs/guide/web',
    sub: true,
  },
  {
    name: 'GeoTrax Mobile',
    navRoute: '/geotrax/docs/guide/mobile',
    sub: true,
  },
];

export const categoriesForDocsRelease = [
  {
    name: 'Releases',
    icon: 'icon-launch',
    navRoute: '/geotrax/docs/releases',
    sub: false,
  },
];

export const categoriesForGeotraxLiteDocsGuides = [
  {
    name: 'GeoTrax Lite',
    navRoute: '/geotrax/docs/guide/geotraxlite',
    sub: true,
  }
];

export const categoriesForTrainingVideos = [
  {
    name: 'GeoTrax Web',
    navRoute: '/geotrax/docs/videos/web',
    sub: true,
  },
  {
    name: 'GeoTrax Mobile',
    navRoute: '/geotrax/docs/videos/mobile',
    sub: true,
  },
];

export const categoriesForScaleTraxGuides = [
  {
    name: 'ScaleTrax Web',
    navRoute: '/scaletrax/docs/guide/web',
    sub: true,
  },
];

export const categoriesForScaleTraxRelease = [
  {
    name: 'Releases',
    icon: 'icon-launch',
    navRoute: '/scaletrax/docs/releases',
    sub: false,
  },
];

export const categoriesForScaleTraxTrainingVideos = [
  {
    name: 'ScaleTrax Web',
    navRoute: '/scaletrax/docs/videos/web',
    sub: true,
  },
];

export const adminNavItems = [
  {
    label: 'Admin',
    icon: 'settings',
    navRoute: '/admin',
    dataTest: 'Admin',
  },
];

export const geotraxNavItems: NavItem[] = [
  {
    label: 'Region Map',
    icon: 'map',
    navRoute: '/geotrax/map',
    dataTest: 'RegionMap',
  },
  {
    label: 'Orders',
    icon: 'orders',
    navRoute: '/geotrax/orders',
    dataTest: 'Orders',
  },
  {
    label: 'Docs',
    icon: 'docs',
    navRoute: '/geotrax/docs',
    dataTest: 'Docs',
  },
  {
    label: 'Route History',
    icon: 'route-search',
    navRoute: '/geotrax/routeHistory',
    dataTest: 'RouteHistory',
  },
];

export const scaleTraxNavItems = [
  {
    label: 'ScaleTrax',
    icon: 'tickets',
    navRoute: '/scaletrax/ticketing',
    dataTest: 'scaletrax',
  },
  {
    label: 'Search Tickets',
    icon: 'search',
    navRoute: '/scaletrax/scaletraxticketslist',
    dataTest: 'searchtickets',
  },
  {
    label: 'Docs',
    icon: 'docs',
    navRoute: '/scaletrax/docs',
    dataTest: 'Docs',
  },
];

export const ordersLinks = [
  {
    name: ORDERS_HEADER,
    icon: 'icon-list',
    navRoute: '/geotrax/orders/all',
  },
  {
    name: 'Notifications Feed',
    icon: 'icon-notifications',
    navRoute: '/geotrax/orders/notifications',
  },
];
