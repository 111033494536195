import { Dispatch } from 'redux';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { getRequest, putRequest } from './requestService';
import { devErrorAndLog } from '../util/errorUtil';
import { NOTIFICATIONS_PATH } from '../constants/apiConstants';
import { ERROR_TEXT_FETCH_NOTIFICATION_LIST, UNABLE_TO_RESOLVE_NOTIFICATION } from '../constants/errorConstants';
import { getTicketingBaseUrl } from '../util/apiUtil';
import HTTP_CODES from '../constants/httpConstants';

interface NotificationDto {
  id?: number,
  resolved?: boolean
}

export async function getNotificationsList(region?: string, startDate?: string, endDate?: string) {
  const url = getTicketingBaseUrl() + NOTIFICATIONS_PATH;
  const params = {
    region,
    startDate: new DateWrapper(startDate).toISOString(),
    endDate: new DateWrapper(endDate).toISOString(),
    isResolved: false,
  };

  const response = await getRequest(url, params);
  return response.data.items;
}

export function getNotificationsForOrder(orderUrl: string, isResolved = false) {
  return async (dispatch: Dispatch) => {
    const url = getTicketingBaseUrl() + NOTIFICATIONS_PATH;
    const params = {
      orderUrl,
      isResolved,
    };
    try {
      const response = await getRequest(url, params);
      return response.data;
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_NOTIFICATION_LIST, e, undefined, consoleOnly) as any);
      return undefined;
    }
  };
}

export function resolveNotification(notification: NotificationDto) {
  return async (dispatch: Dispatch) => {
    const changedDto = {
      ...notification,
      resolved: true,
    };

    const url = getTicketingBaseUrl() + NOTIFICATIONS_PATH;
    try {
      const updatedDto = await putRequest(`${url}/${notification.id}`, changedDto);
      return updatedDto;
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), UNABLE_TO_RESOLVE_NOTIFICATION, e.toString(), undefined, consoleOnly) as any);
      return undefined;
    }
  };
}

export default getNotificationsList;
