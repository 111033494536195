import { TenantDto } from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import { FETCH_TENANTS } from '../../constants/actionConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const tenantsReducer = (
  state = { tenants: [] as TenantDto[] },
  action: PayloadAction<TenantDto[], 'FETCH_TENANTS'>
) => {
  switch (action.type) {
    case FETCH_TENANTS:
      return {
        ...state,
        tenants: action.payload,
      };
    default:
      return state;
  }
};
