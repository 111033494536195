// BASE URL KEYS (see public/trucktrax-config.json)
export const GEOTRAX_URL_KEY = 'HTTP_GEOTRAX_BASE_URL';
export const TICKETING_URL_KEY = 'HTTP_TICKETING_BASE_URL';
export const SECURITY_URL_KEY = 'HTTP_SECURITY_BASE_URL';
export const AUTH_URL_KEY = 'HTTP_AUTH_BASE_URL';
export const PROJECTIONS_URL_KEY = 'HTTP_PROJECTION_MANAGER_BASE_URL';
export const MESSAGING_URL_KEY = 'HTTP_MESSAGING_BASE_URL';
export const HTTP_BRIDGE_BASE_URL = 'HTTP_BRIDGE_BASE_URL';
export const INTEGRATION_URL_KEY = 'HTTP_INTEGRATION_BASE_URL';

export const API_PATH = '/api';

// BACKENDS
export const GEOTRAX_BE_PATH = `/geotrax${API_PATH}`;
export const TICKETING_BE_PATH = `/ticketing${API_PATH}`;
export const SECURITY_BE_PATH = `/security${API_PATH}`;
export const AUTH_BE_PATH = `/auth${API_PATH}`;
export const SAML_AUTH_BE_PATH = `/auth${API_PATH}/sso`;
export const PROJECTIONS_BE_PATH = `/projections-manager${API_PATH}`;
export const MESSAGING_BE_PATH = `/messaging${API_PATH}`;
export const INTEGRATION_BE_PATH = `/integration${API_PATH}`;

// PASSTHROUGHS
export const PRINTING_PATH = '/tickets/{ticketId}/print';
export const TENANTS_PATH = '/tenants';
export const HAULERS_PATH = '/haulers';
export const SCALES_PATH = '/scales';
export const DEVICES_PATH = '/devices';
export const DRIVERS_PATH = '/drivers';
export const REGIONS_PATH = '/regions';
export const TRUCKS_PATH = '/trucks';
export const PLANTS_PATH = '/plants';
export const GEOZONES_PATH = '/geozones';
export const ORDERS_PATH = '/orders';
export const STATUSES_PATH = '/statuses';
export const TICKETS_PATH = '/tickets';
export const QUALITY_FACTORS_PATH = '/qualityfactors';
export const EXTRAPRODUCTS_PATH = '/extraproducts';
export const USERS_PATH = '/users-assignment'; // 2.5 old route to permissions
export const USER_USING_ROLES_PATH = '/get-user-using-roles'; // 2.6 new route to permissions
export const FLAGS_PATH = '/flags';
export const NOTIFICATIONS_PATH = '/notifications';
export const CHECKLISTS_PATH = '/checklists';
export const CHECKLIST_QUESTIONS_PATH = '/checklistquestions';
export const ADMIN_TRUCKS_PATH = '/admin/trucks';
export const ADMIN_GLINXDEVICE_PATH = '/admin/devices/glinx';
export const GEOLOCATIONS_PATH = '/geolocations/historicalpositions';
export const MAPVIEWS_DETAILS_PATH = '/mapviews/details';
export const MAPVIEWS_TRACKED_OBJECT_ACTIVE_PATH = '/activefleet/is-tracked-object-active';
export const CUSTOMERS_PATH = '/ticketing/customers';
export const PROJECTS_PATH = '/ticketing/projects';
export const PRODUCTS_PATH = '/ticketing/projectproducts';
export const VOID_TICKET_PATH = '/update/voidticket';
export const CITIES_PATH = '/ticketing/cities';
export const OPERATOR_NOTES_PATH = '/operatornotes';
export const OPERATOR_NOTES_OPERATORS_PATH = '/operatornotes/operators';
export const VEHICLE_TYPES_PATH = '/vehicletypes';
export const ADMIN_VEHICLE_TYPES_PATH = '/admin/vehicletypes';
export const CANNED_MESSAGES_PATH = '/cannedmessages';
export const REPORTS_PATH = '/vehiclereports';

// PROJECTIONS
export const PROJECTIONS_PATH = '/projections';
export const TRUCK_PROJECTIONS = TRUCKS_PATH + PROJECTIONS_PATH;

// SOCKETS
export const MESSAGING_SOCKET = '/messaging-ws';
export const TRACKING_SOCKET = '/tracking-ws';
export const TICKETING_SOCKET = '/ticketing-ws';
export const ACKNOWLEDGE_MESSAGE_GROUP = '/group/acknowledgeMessage/';
export const NEW_MESSAGE_GROUP = '/group/newMessage/';
export const UPDATE_POSITION_GROUP = '/group/updatePosition/';
export const SYNC_TICKET_GROUP = '/group/syncTicket/';
export const CLOSE_TICKET_GROUP = '/group/closeTicket/';
export const SCALES_SOCKET = '/scale-ws';

// OTHER
export const STATIC_PATH = '/static';
export const DOCS_PATH = '/docs';
export const GROUP_MEMBERS_PATH = '/groupMembers';
export const DOWNLOAD_CSV_PATH = '/downloadCSV';
export const RESET_PASSWORD_PATH = '/reset-password';
export const PERMISSION_GROUPS_PATH = '/groups';
export const PERMISSION_GROUP_MEMBERS_FULL_PATH = '/groupMembersFull';
export const NOT_FOUND_PATH = '/holdonmytruck';
export const FEATURE_FLAG_ASSIGNMENT_PATH = '/featureflag-assignment';
export const FEATURE_FLAGS_FLAGS_PATH = 'featureflags/flags';
export const MESSAGE_WINDOW = '/geotrax/messages';

// DATA TABLE CONTENT DATASOURCES
export const DATASOURCE = {
  TICKET: 'ticket',
  TICKET_LIST: 'ticketList',
  CHECKLIST: 'checklist',
  REGION: 'region',
  REPORT: 'report',
  ORDER: 'order',
  PERMISSION_GROUP: 'permissionGroup',
  PERMISSION_USER: 'permissionUser',
  DEVICE: 'device',
  DRIVER: 'driver',
  HAULER: 'hauler',
  GEOZONE: 'geozone',
  PLANT: 'plant',
  SCALE: 'scale',
  VEHICLE: 'vehicle',
  VEHICLE_TYPE: 'vehicleType',
  USER: 'user',
};
