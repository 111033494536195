import { CityDto } from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import { FETCH_CITIES } from '../../constants/actionConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const citiesReducer = (
  state = { cities: [] as CityDto[] },
  action: PayloadAction<CityDto[], 'FETCH_CITIES'>
) => {
  switch (action.type) {
    case FETCH_CITIES:
      return {
        ...state,
        cities: action.payload.sort((a: CityDto, b: CityDto) => ((a?.displayName || 0) > (b?.displayName || 0) ? 1 : -1)),
      };
    default:
      return state;
  }
};
