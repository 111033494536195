import React, { Component } from 'react';
import { connect } from 'react-redux';

import cx from 'classnames';
import {
  MenuItem, MenuProps, Select
} from '@mui/material';
import { Label } from '@trucktrax/trucktrax-common';
import { RegionDto } from '@trucktrax/trucktrax-ts-common';
import { ADMIN_LABELS } from '../../../constants/appConstants';
import { noop } from '../../../util/appUtil';
import styles from './MapPanelContent.module.css';

interface RegionsSelectState {
  isOpen: boolean;
}

export class RegionsSelect extends Component<RegionsSelectProps, RegionsSelectState> {
  static defaultProps = {
    onSelectionChanged: noop,
    assignedRegionList: [],
  };

  constructor(props: RegionsSelectProps) {
    super(props);

    this.state = { isOpen: false, };
  }

  static sortByName = (r1: RegionDto, r2: RegionDto) => r1.name!.localeCompare(r2?.name!);

  renderAsMenuItem = (region: RegionDto) => {
    const { currentRegion } = this.props;
    return (
      <MenuItem
        data-test="region-select-dropdown-item"
        key={region.url}
        className={cx(styles.menuItem, {
          [styles.menuItemSelected]: currentRegion.url === region.url,
        })}
        value={region.name}
        selected={currentRegion.url === region.url}
      >
        {region.name}
      </MenuItem>
    );
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  static arrowIcon = () => (
    <i
      aria-hidden="true"
      className={cx(
        'icon-keyboard-arrow-down',
        styles.arrowIcon,
      )}
    />
  );

  static renderLabel = () => (
    <Label
      htmlFor="map-region"
      className={styles.label}
    >
      {ADMIN_LABELS.REGION}
    </Label>
  );

  renderRegionsDropdown = () => {
    const howMany = this.props.regions.length;
    switch (howMany) {
      case null:
      case undefined:
      case 0:
        return RegionsSelect.renderNoRegions();
      case 1:
        return this.renderSingleRegion();
      default:
        return this.renderManyRegions();
    }
  };

  static renderNoRegions = () => (null);

  renderSingleRegion = () => {
    const region = this.props.regions![0];
    return (
      <Label className={styles.singleRegion}>
        {region.name}
      </Label>);
  };

  selectionChanged = (event: { target: { value: string } }) => {
    const region = this.props.regions.find(r => r.name === event.target.value);
    if (region) {
      this.props.onSelectionChanged(region);
    }
  };

  renderManyRegions = () => {
    const { regions, currentRegion } = this.props;
    const menuProps = {
      classes: {
        paper: styles.menuStyle,
      },
      disablePortal: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    } as Partial<MenuProps>;

    return (
      <Select
        data-test="toggle-region-dropdown"
        id="map-region"
        className={cx(styles.selectContainer)}
        inputProps={{ className: cx(styles.selectField, { open: this.state.isOpen }), }}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        MenuProps={menuProps}
        value={currentRegion.name}
        onChange={this.selectionChanged}
        labelId="map-region-label"
        IconComponent={() => RegionsSelect.arrowIcon()}
      >
        {[...regions!]
          .sort(RegionsSelect.sortByName)
          .map(this.renderAsMenuItem)}
      </Select>
    );
  };

  render() {
    return (
      <div className={styles.regionSelect}>
        {RegionsSelect.renderLabel()}
        {this.renderRegionsDropdown()}
      </div>
    );
  }
}

export type RegionSelectionChangedEvent = { target: { value: RegionDto } };

export interface RegionsSelectProps {
  currentRegion: RegionDto;
  regions: RegionDto[];
  onSelectionChanged: (region: RegionDto) => void;
}

function mapStateToProps(state: any) {
  return {
    assignedRegionList: state.assignedRegionList,
    currentRegion: state.currentRegion,
  };
}

export default connect<any, any, any>(mapStateToProps, {
})(RegionsSelect);
