import logoTrucktraxWebVerticalSvg from '../assets/img/logos-truck-trax-web-vertical.svg';
import noResultsIconSvg from '../assets/img/noResultsIcon.svg';

export const DISPLAY_ENV_KEY = 'DISPLAY_ENV';
export const ENVIRONMENTS_LIST = ['PROD', 'LOCAL', 'DEV', 'DEMO', 'QA'];

export const IDLE_EVENTS = ['mousemove', 'click', 'keydown'];

export const NOT_AVAILABLE = 'N/A';

export const EXTERNAL_ID = 'externalId';
export const GRANT_TYPE = 'password';
export const CONFIG_URL = '/trucktrax-config.json';
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const WCS = process.env.REACT_APP_WCS;
export const ADD_DEVICE_BUTTON_TEXT = ' Add Device';
export const ADD_DEVICE_MODAL_TITLE = 'ADD A DEVICE';
export const NEW_MESSAGE_WINDOW = 'geoTraxMessagesWindow';

// Generic
export const ARE_YOU_SURE = 'Are you sure?';

// Route History
export const TIME_INVALID_MESSAGE = 'Start time must not be later than the end time.';
export const PRODUCTLINES_INVALID_MESSAGE = 'Please select a product line.';
export const TIME_RANGE_OVER_MESSAGE = 'Start and End Date/Time must be within a 24-hour range.';

// ADMIN FORMS
export const TEXT_DISPLAY = 'TEXT_DISPLAY';
export const IMAGE_DISPLAY = 'IMAGE_DISPLAY';
export const VALUE_MATCH_DISPLAY = 'VALUE_MATCH_DISPLAY';
export const INPUT_FORM = 'INPUT_FORM';
export const NUMERIC_INPUT_FORM = 'NUMERIC_INPUT_FORM';
export const DROPDOWN_FORM = 'DROPDOWN_FORM';
export const MULTIDROPDOWN_FORM = 'MULTIDROPDOWN_FORM';
export const RADIO_FORM = 'RADIO_FORM';
export const CHECKBOX_FORM = 'CHECKBOX_FORM';
export const CHECKBOX_STATELESS_FORM = 'CHECKBOX_STATELESS_FORM';
export const MAP_FORM = 'MAP_FORM';
export const MAP_MULTIZONES_FORM = 'MAP_MULTIZONES_FORM';
export const COMPOSITE = 'COMPOSITE';
export const CUSTOM_COMPONENT = 'CUSTOM_COMPONENT';
export const CARD_SWITCH = 'CARD_SWITCH';
export const MAP_LOCATION = 'MAP_LOCATION';

// FORM ELEMENT RELATIONSHIP
export const OR = 'or';

// GEOZONE PROPERTIES
export const CIRCLE = 'circle';
export const POLYGON = 'polygon';
export const LOCATION = 'location';

// INPUT TYPES FOR INPUT_FORM
export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_EMAIL = 'email';
export const INPUT_TYPE_TEL = 'tel';

// TYPES FOR ADMIN CARD
export const EDIT_BTN = 'EDIT_BTN';
export const SAVE_BTN = 'SAVE_BTN';
export const EDIT_USER_BTN = 'EDIT_USER_BTN';

export const ACCOUNT_LOCKED = 'User account is locked';
export const ACCOUNT_IN_USE = 'User already logged in.';

export const MESSAGE_SELECTED = 'messages';

export const NOTEBOOK_SELECTED = 'notebookPopover';
export const APP_SWITCHER_SELECTED = 'appPopover';

// TYPES FOR GEOTRAX
export const GEOTRAX_PAGE_SELECTED = 'GEOTRAX_PAGE_SELECTED';

// TICKETING
export const DOWNLOAD_AVAILABLE_RECEIPTS = 'Download Available Receipts';
export const TICKETS_SELECTED = 'ticketsPopover';
export const SEARCH = 'Search...';
export const VIEW_TICKET_DETAILS = 'details';
export const VIEW_TICKET_ROUTE = 'route';
export const VIEW_TICKET_QUALITY_FACTORS = 'qualityFactors';
export const TICKET_SEARCH_FIELDS = [
  'ticketNumber',
  'loadNumber',
  'customerName',
  'address',
  'truck',
  'driver',
  'plant',
  'order',
  'externalInfo',
];
export const TICKET_SEARCH_MAX_LENGTH = 1000;
export const WEIGHT_UNIT = {
  TONS: 'SHORT_TONS',
  POUNDS: 'POUNDS',
};
export const STATUSES_STRING = 'statuses';
export const TICKET_STATUS_OPEN = 'Ticket open';
export const TICKET_STATUS_CLOSED_DRIVER = 'Ticket closed by driver';
export const TICKET_STATUS_CLOSED_SYSTEM = 'Ticket auto-closed by system';

// STATUS EVENT
export const STATUS_EVENT_ORDER = {
  Ticketed: 0,
  ToJob: 1,
  OnJob: 2,
  Unloaded: 3,
  Returning: 4,
  InYard: 5,
};

// Array of statuses we expect to see a predictive route for
export const STATUSES_FOR_PREDICTIVE_ROUTE = [
  'Ticketed',
  'DeliveryStart',
  'ToJob',
];

// Product Lines
export const SELECTED_PRODUCT_LINE = 'SELECTED_PRODUCT_LINE';
export const PRIMARY_PRODUCT_LINE = 'primaryProductLine'; // jwt property
export const SECONDARY_PRODUCT_LINE = 'secondaryProductLine'; // jwt property
export const SECONDARY_PRODUCT_LINES = 'secondaryProductLines'; // jwt property
export const SET_PIN_MAP = 'SET_PIN_MAP';

export const REGION = 'region';
export const CURRENT_REGION = 'CURRENT_REGION';
export const LATITUDE = 'lat';
export const LONGITUDE = 'lng';
export const ADD_REGION_BUTTON_TEXT = 'Add Region';
export const ADD_REGION_MODAL_TITLE = 'ADD A REGION';
export const REGISTRATION_TOKEN_EXPIRATION_DAYS = 'REGISTRATION_TOKEN_EXPIRATION_DAYS';
export const ADD_CHECKLIST_MODAL_TITLE = 'ADD A CHECKLIST';

export const ADD_TRUCK_BUTTON_TEXT = 'Add Vehicle';
export const ADD_TRUCK_MODAL_TITLE = 'ADD A VEHICLE';

export const ADD_VEHICLE_TYPE_MODAL_TITLE = 'ADD A VEHICLE TYPE';
export const ADD_VEHICLE_TYPE_BUTTON_TEXT = 'Add Vehicle Type';
export const ADD_SCALE_BUTTON_TEXT = 'Add Scale';
export const ADD_SCALE_MODAL_TITLE = 'ADD A SCALE';

// Internationalization
export const IDLE_TIMEOUT_BODY = 'You have been logged out due to inactivity.';
export const BROWSER_TYPE_NOTIFICATIONNOLINK = 'You\'re using an unsupported web browser.'
  + ' Please use Google Chrome or Microsoft Edge';
export const BROWSER_TYPE_NOTIFICATION = 'You\'re using an unsupported web browser. Please use ';
export const BROWSER_WIDTH_TEXT = 'You need a larger browser size to use this application.';
export const BROWSER_WIDTH_ALT = 'Increase Screen Size';
export const NOTFOUND_STATUS = '404';
export const NOTFOUND_HEADER = ': Page Not Found';
export const NOTFOUND_NOTIFICATION = 'Looks like you\'re headed in the wrong direction.';
export const AGGREGATE_STRING = 'Aggregates';
export const CEMENT_STRING = 'Cement';
export const READYMIX_STRING = 'Ready-mix';
export const READYMIX_VALUE = 'ReadyMix';
export const CEMENT_VALUE = 'Cement';
export const AGGREGATE_VALUE = 'Aggregates';
export const NONE_STRING = 'None';
export const LIVE_SCALE = 'Live';
export const LIVE_SCALE_RADIO = 'LIVE';
export const LIVE_SCALE_RADIO_LABEL = 'Live';
export const MANUAL_SCALE_RADIO = 'MANUAL';
export const MANUAL_SCALE_RADIO_LABEL = 'Manual';
export const MANUAL_SCALE = 'Manual';
export const INTERNAL = 'Internal';
export const EXTERNAL = 'External';
export const PASSWORD_UPPERCASE_TEXT = 'Have at least one uppercase letter';
export const PASSWORD_NUMBER_TEXT = 'Have at least one number';
export const PASSWORD_SIZE_TEXT = 'Be at least 8 characters long';
export const PASSWORD_LOWERCASE_TEXT = 'Have at least one lowercase letter';

export const USERS_RELATIVE_URL = '/users';
export const USERS_VERSION = '/v0';
export const ADD_USER_BUTTON_TEXT = 'Add User';
export const ADD_USER_MODAL_TITLE = 'ADD A USER';
export const RESET_AND_SEND_USER_PASSWORD_MSG = 'This will reset the user\'s current password and '
  + 'send a temporary password to ';
export const RESET_AND_CREATE_NEW_PASSWORD_MSG = ' They will be asked to create a new password upon '
  + 'logging back in to GeoTrax.';
export const RESET_USER_PASSWORD_TITLE = 'Reset user password?';
export const CONTINUE_RESET_USER_PASSWORD = 'Reset Password';

export const ADD_DRIVER_BUTTON_TEXT = 'Add Driver';
export const ADD_DRIVER_MODAL_TITLE = 'ADD A DRIVER';

// PLANTS
export const ADD_PLANT_MODAL_TITLE = 'ADD A PLANT';
export const STEP_ONE_PLANT_MODAL_TITLE = 'Step 1: Enter plant information';
export const STEP_TWO_PLANT_MODAL_TITLE = 'Step 2 of 5: Plant Geozone';
export const STEP_THREE_PLANT_MODAL_TITLE = 'Step 3 of 5: In Queue Geozone';
export const STEP_FOUR_PLANT_MODAL_TITLE = 'Step 4 of 5: Return Geozone';
export const STEP_FIVE_PLANT_MODAL_TITLE = 'Step 5 of 5: Review plant information';
export const SHOW_YES = 'Yes';
export const SHOW_NO = 'No';
export const ONCE_ITS_DEACTIVATED = 'Once it\'s deactivated:';
export const ONCE_ITS_ACTIVATED = 'Once it\'s activated:';
export const TICKETS_CANNOT_BE_CREATED = 'Tickets cannot be created from this plant.';
export const TICKETS_CAN_BE_CREATED = 'Tickets can be created from this plant.';
export const TRUCKS_ENTERING_WILL_NOT_STATUS = 'Vehicles entering this plant\'s geozone '
  + 'will not trigger the status "In Yard."';
export const TRUCKS_ENTERING_WILL_STATUS = 'Vehicles entering this plant\'s geozone '
  + 'will trigger the status "In Yard."';
export const TRUCKS_EDIT_DISABLE_TEXT = 'Editing is temporarily disabled while the vehicle is logged in.';
export const ARE_YOU_SURE_DEACTIVATE = 'Are you sure you want to deactivate this plant?';
export const ARE_YOU_SURE_ACTIVATE = 'Are you sure you want to activate this plant?';
export const YES_DEACTIVATE_PLANT = 'Yes, Deactivate Plant';
export const YES_ACTIVATE_PLANT = 'Yes, Activate Plant';
export const PLANT_NO_GEOZONES = 'Plant has no geozones';
export const PLANT_NO_ACTIVATION = 'This plant cannot be activated until it has been given a default geozone. ';
export const PLEASE_CREATE_GEOZONE = 'Please create a geozone before proceeding.';

// CHECKLISTS
export const OPTION_PASS = 'Pass';
export const OPTION_FAIL = 'Fail';
export const OPTION_LOGIN_STRING = 'After login';
export const OPTION_LOGIN_VALUE = 'Login';
export const ADD_CHECKLIST_BUTTON_TEXT = 'Add Checklist';

export const ADD_PERMISSIONS_GROUP_MODAL_TITLE = 'ADD A PERMISSIONS GROUP';
export const SECURITY_NO_MEMBERS_IN_PERMISSIONS_GROUP_MESSAGE = 'There are currently no members in this group.\n'
  + 'Add one using the search bar above.';
export const PERMISSION_NAMES = {
  REPORTS: 'Reports',
  SECURITY_PERMISSIONS: 'SecurityPermissions',
  REGIONS: 'Regions',
  USERS: 'Users',
  FEATURE_FLAGS: 'FeatureFlags',
  DEVICES: 'Devices',
  TRUCKS: 'Trucks',
  PLANTS: 'Plants',
  DRIVERS: 'Drivers',
  PASSWORDS: 'Passwords',
  CHECKLISTS: 'Checklists',
  OPEN_TICKETS: 'OpenTickets',
  WEIGHMASTER: 'Weighmaster',
  SCALETRAX: 'ScaleTrax'
};

export const HIDDEN_PERMISSIONS = [PERMISSION_NAMES.WEIGHMASTER, PERMISSION_NAMES.SCALETRAX];

// DEVICE TYPE IDENTIFICATION
export const DEVICE_IDENTIFICATION = {
  TABLET: 'TABLET',
  GLINX: 'GLINX',
};

// DEVICE TYPE IDENTIFICATION
export const DEVICE_LABELS = {
  TABLET: 'Tablet',
  GLINX: 'G-Linx',
};

export const DISCARD_PROMPT_TEXT = 'Discard progress? If you leave now; any changes you\'ve made will be lost.';
export const COMPANY_NOT_FOUND = 'Company Name not found. ';
export const COMPANY_NOT_FOUND_MESSAGE = `${COMPANY_NOT_FOUND} `
  + 'Please check the spelling and ensure that you\'ve entered the complete name.';
export const USER_IS_DEACTIVATED = 'User has been deactivated.';

// 500 ERRORS
export const INVALID_CREDENTIALS_ERROR = 'TruckTrax.Library.Common.Exceptions.HttpException: Invalid username/password';
export const USER_LOCKED_OUT_ERROR = 'TruckTrax.Library.Common.Exceptions.HttpException: User account is locked';
export const USER_PASSWORD_EXPIRED_ERROR = 'TruckTrax.Library.Common.Exceptions.HttpException: User account has expired';
export const ACCOUNT_IN_USE_ERROR = 'TruckTrax.Library.Common.Exceptions.HttpException: user already logged in.';
export const USER_IS_DEACTIVATED_ERROR = 'TruckTrax.Library.Common.Exceptions.HttpException: User is not activated.';

// VALIDATION
export const PASSWORD_UPPERCASE = 'Password should contain at least one upper case character';
export const PASSWORD_NUMBER = 'Password should contain at least one number';
export const PASSWORD_SIZE = 'Password should contain at least 8 characters and less than 100';
export const PASSWORD_LOWERCASE = 'Password should contain at least one lower case character';
export const SAML_RESPONSE = 'sar';
export const SAML_RESPONSE_SUCCESS = 's';
export const SAML_RESPONSE_ERROR = 'e';
// IDLE TIME
export const LAST_ACTIVE = 'LAST_ACTIVE';

// NOTIFICATION TYPES
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const DEFAULT = 'DEFAULT';
export const ACCENT = 'ACCENT';
export const FAIL = 'FAIL';
export const PRINT = 'PRINT';

export type NotificationType = 'ERROR' | 'SUCCESS' | 'DEFAULT' | 'ACCENT' | 'FAIL';

// TABS
export const MESSAGES_TABS_INBOX = 'inbox';
export const MESSAGES_TABS_NEW = 'new';
export type MessagesTabs = 'inbox' | 'new';

export const SEARCH_HINT = 'Search vehicles by ID...';
export const SEARCH_VEHICLE_AND_ORDER_HINT = 'Search vehicles and orders...';

// SIDEBAR
export const DOWNLOAD_ALL_ZIP = 'Download All (.zip)';

export const FLAGS_DELETE_CONFIRM_PREFIX = 'The feature flag';
export const FLAGS_EDIT_SUCCESS_PREFIX = 'The permissions for feature flag';
export const FLAGS_DELETE_CONFIRM_POSTFIX = 'will be permanently deleted. '
  + 'Are you sure you want to continue?';
export const FLAGS_DELETE_CONFIRM = 'Delete feature flag?';

// Feature Flags
export const FEATURE_FLAG_ACCESS_FLAG = 'FeatureFlags';
export const GEOTRAX_LITE_FLAG = 'GeoTraxLite';
export const ADD_FLAG_TITLE = 'ADD A FEATURE FLAG';
export const EDIT_FLAG_TITLE = 'EDIT FEATURE FLAG PERMISSIONS';
export const REGION_LOGOUT = 'RegionLogout';
export const IMPORT_CSV = 'ImportCsv';
export const ADV_SECURITY_PERMS = 'AdvSecurityPerms';

// Permission Name
export const USER_DOCUMENTS = 'USER_DOCUMENTS';
export const DOWNLOAD_RECEIPTS = 'DownloadReceipts';

// CUSTOM GEOZONE
export const ADD_CUSTOM_GEOZONE_BUTTON_TEXT = 'Add Geozone';
export const ADD_CUSTOM_GEOZONE_MODAL_TITLE = 'ADD A GEOZONE';

// HAULER
export const ADD_HAULER_BUTTON_TEXT = 'Add Hauler';
export const ADD_HAULER_MODAL_TITLE = 'ADD A HAULER';

// Default page size for admin tables
export const DEFAULT_PAGE_SIZE = 100;

// ADMIN Headers
export const ADMIN_SEARCH_HINT = {
  Drivers: 'Search Drivers...',
  Plants: 'Search Plants...',
  Devices: 'Search Devices...',
  Users: 'Search Users...',
  Regions: 'Search Regions...',
  Trucks: 'Search Vehicles...',
  Haulers: 'Search Haulers...',
  Scales: 'Search Scales...',
  Geozones: 'Search Geozones...',
  Checklists: 'Search Checklists...',
  VehicleTypes: 'Search vehicle types...',
  Orders: 'Search Orders...',
  OpenTickets: 'Search any column...',
  'Security Permissions': 'Search Permissions...',
  'Security Permissions Groups': 'Search Groups...',
  'Security Permissions Users': 'Search Users...',
};
export const ORDERS_HEADER = 'All Orders';

export const STATE_LIST = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC',
  'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA',
  'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
  'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR',
  'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY',
];

const unitedStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];
const canadaStates = [
  'Alberta',
  'British Columbia',
];
const mexicoStates = [
  'Baja California',
];

const canadaStatesAbbv = ['AB', 'BC'];
const mexicoStatesAbbv = ['BN', 'BS'];

export const GROUPED_STATES = [
  ...canadaStates.map(state => ({ value: state, label: state, group: 'Canada' })),
  ...mexicoStates.map(state => ({ value: state, label: state, group: 'Mexico' })),
  ...unitedStates.map(state => ({ value: state, label: state, group: 'United States' })),
];
export const GROUPED_STATES_ABBV = [
  ...canadaStatesAbbv.map(state => ({ value: state, label: state, group: 'Canada' })),
  ...mexicoStatesAbbv.map(state => ({ value: state, label: state, group: 'Mexico' })),
  ...STATE_LIST.map(state => ({ value: state, label: state, group: 'United States' })),
];

// ADMIN KEYS AND LABELS
export const SAVE_CHANGES = 'Save Changes';
export const EDIT_USER = 'Edit User';
export const LOGOUT_USER = 'Log Out User';
export const USER_NOT_LOGGED_IN = 'User is not logged in.';
export const EDIT_DRIVER_LOGGED_IN = 'Editing is disabled while driver is logged in.';
export const EDIT_TRUCK_LOGGED_IN = 'Editing is disabled while vehicle is in use.';
export const EDIT_DEVICE_LOGGED_IN = 'Editing is temporarily disabled while the device is in use.';
export const EDIT_OBJECT_LOGGED_IN = 'Editing is disabled while the copy is being finalized.';
export const EDIT_USER_LOGGED_IN = 'Editing is disabled while user is logged in.';
export const COMPANY_ADMIN_TOOLS = 'Company Admin Tools';
export const DISPATCHER_CANNED_MESSAGES_TEXT = 'Canned messages can be added after the region has been created.';

export const ADMIN_KEYS = {
  IMEINUM: 'imeiNum',
  NAME: 'name',
  REGION: 'region',
  REGIONS: 'regions',
  SECONDARY_REGIONS: 'secondaryRegions',
  PIN: 'pin',
  DISPATCHER_CANNED_MESSAGES: 'dispatcherCannedMessages',
  ORDER_NUMBER: 'orderNumber',
  ORDER_ORDER_NUMBER: 'order.orderNumber',
  ORDER_CUSTOMER_NAME: 'order.customerName',
  SCHEDULED_TIME: 'scheduledTime',
  CUSTOMER_NAME: 'customerName',
  PROJECT_NAME: 'projectName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  DESCRIPTION: 'description',
  EXTERNAL_ID: 'externalId',
  IS_EXTERNAL: 'isExternal',
  SSO_ID: 'employeeNumber',
  TRUCK_ALIAS: 'truckAlias',
  LICENSE_PLATE: 'licensePlate',
  LICENSE_STATE: 'licenseState',
  PRIMARY_PLANT: 'primaryPlant',
  PLANT_GEOFENCE: 'plantGeozone',
  INQUEUE_GEOFENCE: 'inqueueGeozone',
  RETURN_GEOFENCE: 'returnGeozone',
  DEFAULT_PRODUCT_LINE: 'defaultProductLine',
  PRIMARY_PRODUCT_LINE: 'primaryProductLine',
  SECONDARY_PRODUCT_LINE: 'secondaryProductLine',
  SECONDARY_PRODUCT_LINES: 'secondaryProductLines',
  PRODUCT_LINES: 'productLines',
  PRODUCT_LINE: 'productLine',
  PRODUCT_LINE_WARNING: 'productLineWarning',
  OWNERSHIP_TYPE: 'ownershipType',
  EXTERNAL: 'external',
  EDIT_TICKET_WEIGHTS: 'editableTicketWeights',
  EDIT_WEIGHT_ALLOWED: 'Allow drivers to edit weights?',
  GEOTRAX_LITE_PARTICIPANT: 'gtLiteParticipant',
  LOADED_TIME: 'loadedTime',
  PLANT: 'plant',
  VEHICLE_TYPE: 'vehicleType',
  VEHICLE_TYPE_NAME: 'vehicleType.name',
  DEFAULT: 'default',
  PLANTS: 'plants',
  PLANT_NAME: 'plant.name',
  HOME_PLANT: 'homePlant',
  DRIVER: 'driver',
  DEFAULT_DRIVER: 'defaultDriver',
  DEFAULT_DRIVER_NAME: 'defaultDriver.name',
  DEFAULT_SCALE_UNIT: 'defaultUom',
  SCALETYPE: 'scaleType',
  SCALENAME: 'scaleName',
  MAXWEIGHT: 'maxWeight',
  NETADDRESS: 'netAddress',
  DEFAULT_SCALE: 'default',
  EMAIL: 'email',
  PHONE: 'phoneNumber',
  ADDRESS_LINE_ONE: 'addressLineOne',
  ADDRESS_LINE_TWO: 'addressLineTwo',
  CITY: 'city',
  STATE: 'state',
  ZIP_CODE: 'zipCode',
  USERNAME: 'username',
  LATITUDE: 'location.latitude',
  LONGITUDE: 'location.longitude',
  PASSWORD: 'password',
  MANUFACTURER: 'manufacturer',
  SOFTWARE_VERSION: 'softwareVersion',
  MODEL_NUMBER: 'modelNumber',
  SERIAL_NUMBER: 'serialNumber',
  INQUEUE_SAMEAS_PLANT: 'inqueueSameAsPlant',
  RETURN_SAMEAS_PLANT: 'returnSameAsPlant',
  ARCHIVED_DATE: 'archivedDate',
  ARCHIVED_TIMESTAMP: 'archivedTimestamp',
  CHECKLISTS_OPTIONS_FORMAT: 'answerType',
  CHECKLISTS_OPTIONS_WHEN_TO_PRESENT: 'displayTrigger',
  GEOZONES: 'geozones',
  ANSWER_TYPE: 'answerType',
  DISPLAY_TRIGGER: 'displayTrigger',
  DEVICE_TYPE: 'deviceType',
  LINKED_DEVICE: 'linkedDevice',
  LAST_ASSIGNED_TRUCK: 'truckAlias',
  SCALE_EXPIRATION_DAYS: 'scaleExpirationDays',
  STATUS_CONTAINER: 'statusContainer',
  DISABLED_TEXT: 'disabledText',
  DELIVERY_TYPE: 'deliveryType',
  UNIT_OF_MEASURE: 'unitOfMeasure',
  WEIGHMASTER: 'weighmaster',
  HAULERS: 'haulers',
  SCALE_HOST_ADDRESS: 'scaleHostAddress',
  IS_GLINX_ENABLED: 'isGlinxEnabled',
  IS_FLEX_SYSTEM: 'isFlexSystem',
  TICKET_NUMBER: 'ticketNumber',
  EXTERNAL_INFO_PRODUCT_LINE: 'externalInfo.plantProductLine',
  EXTERNAL_INFO_TRUCK_ALIAS: 'externalInfo.truckAlias',
  EXTERNAL_INFO_DRIVER_NAME: 'externalInfo.driverName',
  EXTERNAL_INFO_PLANT_NAME: 'externalInfo.plantName',
  TICKETED_TIMESTAMP: 'ticketedTimestamp',
  ADDRESS: 'address',
  ENGINE_HOURS: 'engineHours',
  ODOMETER: 'odometer',
  TOTAL_FUEL: 'totalFuel',
  TIMESTAMP: 'timestamp',
  GEOTRAX_LITE_ACCESS: 'gtLightAccess',
  DRIVER_IS_EXTERNAL: 'isExternal',
};
export const ADMIN_LABELS = {
  IMEINUM: 'IMEI Number',
  NAME: 'Name',
  REGION: 'Region',
  REGIONS: 'Regions',
  PIN: 'Pin',
  SCHEDULE_DATE: 'Sched Date',
  CUSTOMER_NAME: 'Customer Name',
  PROJECT_NAME: 'Project Name',
  ORDER_NUMBER: 'Order #',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  GEOFENCE: 'Geofence',
  GEOZONE: 'Geozone',
  GEOZONES: 'Geozones',
  ADDRESS: 'Address',
  DELIVERY_ADDRESS: 'Delivery Address',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  REGION_NAME: 'Region Name',
  DESCRIPTION: 'Description',
  REGION_CENTER_POINT: 'Region Center Point',
  ID: 'ID',
  EXTERNAL_ID: 'External ID',
  SSO_ID: 'Single Sign-On ID',
  SSO_ID_EN: 'Single Sign-On ID (Employee Number)',
  TRUCK_ALIAS: 'Truck Alias',
  VEHICLE_ALIAS: 'Vehicle Alias',
  VEHICLE_TYPE: 'Vehicle Type',
  LICENSE_PLATE: 'License Plate',
  LICENSE_STATE: 'License State',
  PLANT: 'Plant',
  PRIMARY_PLANT: 'Primary Plant',
  DEFAULT_PRODUCT_LINE: 'Default Product Line',
  DEFAULT_SCALE_UNIT: 'Default Scale Unit',
  DISPATCHER_CANNED_MESSAGES: 'Dispatcher Canned Messages',
  PRIMARY_PRODUCT_LINE: 'Primary Product Line',
  SECONDARY_PRODUCT_LINE: 'Secondary Product Line',
  SECONDARY_PRODUCT_LINES: 'Secondary Product Line(s)',
  PRODUCT_LINE: 'Product Line',
  PRODUCT_LINES: 'Product Lines',
  OWNERSHIP_TYPE: 'Ownership Type',
  OWNERSHIP: 'Ownership',
  EDIT_WEIGHT_ALLOWED: 'Allow drivers to edit weights?',
  GEOTRAX_LITE_PARTICIPANT: 'GeoTrax Lite Participant',
  LOADED_TIME: 'Loaded Time Modal',
  LOADED_TIME_LABEL: 'Auto-backfilled Loaded Time',

  SHOW_LOADED_TIME: 'Show driver loaded time modal',
  SHOW_DRIVER: 'Show driver loaded time modal',
  HOME_PLANT: 'Home Plant',
  DEFAULT_DRIVER: 'Default Driver',
  EMAIL: 'Email',
  PHONE: 'Phone',
  DEFAULT_REGION: 'Primary Region',
  SECONDARY_REGION: 'Secondary Region(s)',
  PLANT_NAME: 'Plant Name',
  ADDRESS_LINE_ONE: 'Address Line 1',
  ADDRESS_LINE_TWO: 'Address Line 2',
  CITY: 'City',
  STATE: 'State',
  ZIP_CODE: 'Zip Code',
  USERNAME: 'Username',
  PASSWORD: 'Password',
  MANUFACTURER: 'Manufacturer',
  SOFTWARE_VERSION: 'Software Version',
  ANDROID_VERSION: 'Android Version',
  MODEL_NUMBER: 'Model Number',
  SERIAL_NUMBER: 'Serial Number',
  DEACTIVATED_DATE: 'Date Deactivated',
  CHECKLISTS_OPTIONS_WHEN_TO_PRESENT: 'When to Present Checklist',
  CHECKLISTS_NAME: 'Name',
  CHECKLISTS_PRODUCTLINES: 'Product Lines',
  CHECKLISTS_OPTIONS_FORMAT: 'Options Format',
  DEVICE_TYPE: 'Type',
  LINKED_DEVICE: 'Linked Device',
  LAST_ASSIGNED_TRUCK: 'Last Assigned Vehicle',
  HARDWARE_VERSION: 'Hardware Version',
  FIRMWARE_VERSION: 'Firmware Version',
  LAST_ASSIGNED_GLINX: 'Last Assigned G-Linx',
  LAST_ASSIGNED_TABLET: 'Last Assigned Tablet',
  HAULER_NAME: 'Hauler Name',
  SCALE_NAME: 'Scale Name',
  SCALE_NETADDRESS: 'Head Net Address',
  SCALE_HEADNETADDRESS: 'Scale Head Net Address',
  SCALE_TYPE: 'Scale Type',
  SCALE_DEFAULT: 'Default scale',
  SCALE_PLANT: 'Plant',
  DEFAULT_PLANT: 'Scale Operator Primary Plant',
  SECONDARY_PLANTS: 'Scale Operator Secondary Plants',
  SCALE_MAXWEIGHT: 'Max Weight',
  ADD_SCALE_MAXWEIGHT: 'Scale Maximum Weight',
  SCALE_TARE_DATE: 'Tare Date',
  SCALE_EXPIRATION_DAYS: 'Tare Expiration (Days)',
  SCALE_EXPIRATION_DATE: 'Tare Expiration Date',
  DELIVERY_TYPE: 'Delivery Type',
  GROSS_MAX_WEIGHT: 'Gross Max Weight',
  WEIGHMASTER: 'Weighmaster',
  HAULER: 'Hauler',
  GLINX_ENABLED: 'G-Linx',
  FLEX_SYSTEM: 'Flex',
  SCALE_HOST_ADDRESS: 'Host Name / IP Address',
  FLEET: 'Fleet',
  TICKET_NUMBER: 'Ticket#',
  DATE: 'Date',
  TRUCK: 'Vehicle',
  CURRENT_LAST_DRIVER: 'Current/Last Driver',
  ORDER: 'Order',
  CUSTOMER: 'Customer',
  CLOSE_TICKETS_WARNING: 'Closing tickets cannot be undone!',
  CLOSE_TICKET_SINGULAR: 'Close Ticket',
  CLOSE_TICKET_PLURAL: 'Close Tickets',
  LOADING_PLANT: 'Loading Plant',
  TOOLTIP_PRIMARY_REGION: 'The Primary Product Line determines available Primary Regions',
  ENGINE_HOURS: 'Engine Hours',
  ODOMETER: 'Odometer',
  TOTAL_FUEL: 'Total Fuel',
  TIMESTAMP: 'Date',
  DRIVER_IS_EXTERNAL: 'Driver Configuration',
  GEOTRAX_LITE_ACCESS: 'GeoTrax Lite Access',
};

// Geozone color
export const GEOZONE_SHAPE_STYLE = {
  plant: {
    strokeColor: '#EE8000',
    fillColor: '#EE8000',
  },
  inqueue: {
    strokeColor: '#00B6BF',
    fillColor: '#00B6BF',
  },
  return: {
    strokeColor: '#717E87',
    fillColor: '#717E87',
  },
};

// Geozone types - use for Plants Geozones, changing order can affect PlantsAddModal.
export const GEOZONE_TYPE = {
  PLANT: 'plant',
  INQUEUE: 'inqueue',
  RETURN: 'return',
};

// Plant Add Modal Geozone Order.
export const PLANT_ADD_MODAL_ORDER = {
  0: 'plant information',
  1: 'Plant',
  2: 'In Queue',
  3: 'Return',
  4: 'Review plant information',
};

// Download Reports
export const DOWNLOAD_REPORTS_MODAL_TITLE = 'Download a Report';
export const DOWNLOAD_REPORTS_TITLE = 'Delivery Exceptions Report';
export const DOWNLOAD_REPORTS_MODAL_BTN_TITLE = 'Download Report';
export const DOWNLOAD_REPORTS_BTN_TEXT = 'Download a Report';
// Logout Region
export const LOGOUT_REGION_BTN_TEXT = 'Logout the Region';
export const CONTINUE_LOGOUT_TEXT = 'Yes, Continue Logout';
export const REGION_LOGOUT_MODAL_BODY_1 = 'Every ';
export const REGION_LOGOUT_MODAL_BODY_2 = ' driver and user will be logged out from the GeoTrax application. '
  + 'Are you sure you want to continue? This cannot be undone.';
export const LOGOUT_ALL_SUCCESS_TEXT = 'All drivers and users in the region have been successfully logged out.';
export const USER_LOGOUT_SUCCESS_TEXT = 'has been logged out of GeoTrax.';
export const OK = 'OK';
export const LOGOUT = 'Log Out';
export const REPORT_DONE_HTML = ['Your ', 'Delivery Exceptions', ' report', 'has been successfully built.'];
export const REPORT_DOWNLOADING_HTML = ['Please wait while your ', 'Delivery Exceptions', ' report is built.'];
export const CONTENT_TYPE_CSV = 'text/csv';
export const CONTENT_TYPE_PDF = 'application/pdf';
export const CONTENT_TYPE_MP4 = 'video/mp4';
export const CONTENT_TYPE_ZIP = 'application/zip; application/octet-stream';
export const ERROR_DOWNLOADING_CSV = 'Error downloading CSV file';

// Upload CSV
export const IMPORT_CSV_MODAL_TITLE = 'IMPORT CSV DATA';
export const IMPORT_CSV_ADMIN_BTN_TEXT = 'Import CSV Data';
export const IMPORT_CSV_BTN_TEXT = 'Import Data';

// Image Urls
export const LOGO_IMAGE = logoTrucktraxWebVerticalSvg;
export const NO_RESULTS_IMAGE = noResultsIconSvg;

// Websocket
export const RECEIVE_MESSAGE = 'ReceiveMessage';
export const JOIN_GROUP = 'JoinGroup';
export const RECONNECT_TIMEOUT = 10000;
export const RECEIVE_WEIGHT_MESSAGE = 'receiveWeight';
export const SCALE_STATE_NOTIFIED = 'notifyConnectionState';
export const CONNECT_TO_SCALE = 'connectToScale';
export const DISCONNECT_FROM_SCALE = 'disconnectFromScale';

export const SIDENAV_REFRESH_IN_MILLISECONDS = 60000;

export const CANCEL_API_DUE_TO_PAGE_CHANGE = 'USER_CHANGE_PAGE';
export const INVALID_STALE_TOKEN_LOGOUT = 'STALE_TOKEN_LOGOUT';

// Admin Sub routes regex
export const ADMIN_SUB_ROUTE_REGEX = '/admin/(drivers|users|trucks|plants|devices|regions)/[0-9]';
export const ADMIN_ROUTE = 'admin';
export const MAP_ROUTE = 'map';
export const DOCS_ROUTE = 'docs';
export const ORDERS_ROUTE = 'orders';
export const HISTORY_ROUTE = 'routeHistory';

export const ADD_NEW_VERSION_DOC = 'ADD NEW VERSION DOC';
export const RELEASENOTES_FOLDER = 'releasenotes/';
export const SCALETRAXRELEASENOTES_FOLDER = 'scaletraxreleasenotes/';

export const NOTIFICATION_TYPE_BAD_ADDRESS = 'Mapped Address May Be Inaccurate';
export const NOTIFICATION_TYPE_CLOSER_PLANT = 'Closer Plant Detected';

export const NOTIFICATIONS_CONFIG = {
  INACCURATE_ADDRESS: {
    icon: 'icon-notification-address',
    title: NOTIFICATION_TYPE_BAD_ADDRESS,
  },
  CLOSER_PLANT: {
    icon: 'icon-notification-plant',
    title: NOTIFICATION_TYPE_CLOSER_PLANT,
  },
};

export const NOTIFICATION_INACCURATE_ADDRESS = 'INACCURATE_ADDRESS';
export const NOTIFICATION_CLOSER_PLANT = 'CLOSER_PLANT';

export const EMPTY_NOTIFICATIONS = 'There are no current notifications\n'
  + 'within the specified date range.';

export const DATE_INPUT_FORMAT = 'MM/dd/yyyy';

// device activate and deactivate
export const CONFIRM_DEACTIVATE_DEVICE = 'Yes, Deactivate Device';
export const CONFIRM_UNASSIGN_DEVICE = 'Yes, Unassign G-Linx';
export const DEACTIVATE_DEVICE_BODY1 = 'Once it\'s deactivated, drivers will no longer be able to log in'
  + ' and receive tickets on this device.';
export const DEACTIVATE_DEVICE_BODY2 = 'Are you sure you want to deactivate this device?';
export const DEACTIVATE_GLINX_BODY1 = 'Once it\'s deactivated:';
export const DEACTIVATE_GLINX_POINT1 = 'This device will not be able to connect to any tablets.';
export const DEACTIVATE_GLINX_POINT2 = 'Any vehicle currently assigned to this device will'
  + ' no longer connect to it automatically.';
export const DEACTIVATE_GLINX_POINT3 = 'The Last Assigned Vehicle for this device will show as "None."';
export const DEACTIVATE_GLINX_BODY2 = 'Are you sure you want to deactivate this device?';
export const CONFIRM_ACTIVATE_DEVICE = 'Yes, Activate Device';
export const ACTIVATE_DEVICE_BODY1 = 'Once it\'s activated, drivers will be able to log in'
  + ' and receive tickets on this device.';
export const CANCEL_LABEL = 'Cancel';
export const PRINT_LABEL = 'Print';
export const ACTIVATE_DEVICE_BODY2 = 'Are you sure you want to activate this device?';
export const ACTIVATE_GLINX_BODY1 = 'Once it\'s activated, this device will be able to connect'
  + ' to tablets and be assigned to vehicles.';

// Driver activate and deactivate
export const CONFIRM_DEACTIVATE_DRIVER = 'Yes, Deactivate Driver';
export const DEACTIVATE_DRIVER_BODY = 'Once this driver is deactivated, they will not be able to'
  + ' sign in and use GeoTrax Mobile.';
export const CONFIRM_ACTIVATE_DRIVER = 'Yes, Activate Driver';
export const ACTIVATE_DRIVER_BODY = 'Once this driver is activated, they will be able to sign in'
  + ' and use GeoTrax Mobile.';

// Truck activate and deactivate
export const CONFIRM_DEACTIVATE_TRUCK = 'Yes, Deactivate Vehicle';
export const DEACTIVATE_TRUCK_BODY = 'Once this vehicle is marked inactive, tickets cannot be created with it.';
export const CONFIRM_ACTIVATE_TRUCK = 'Yes, Activate Vehicle';
export const ACTIVATE_TRUCK_BODY = 'Once this vehicle is marked activated, tickets can be created with it.';

// Scale activate and deactivate
export const CONFIRM_DEACTIVATE_SCALE = 'Yes, Deactivate Scale';
export const CONFIRM_DEFAULT_SCALE = 'Switch Default Scale';
export const DEACTIVATE_SCALE_BODY = 'Once this scale is deactivated, tickets cannot be created with this scale.';
export const CONFIRM_ACTIVATE_SCALE = 'Yes, Activate Scale';
export const ACTIVATE_SCALE_BODY = 'Once this scale is marked activated, tickets can be created with it.';
export const CONFIRMTO_DEACTIVATE_SCALE = 'Are you sure you want to deactivate this scale?';
export const CONFIRMTO_ACTIVATE_SCALE = 'Are you sure you want to activate this scale?';
export const DEACTIVATE_SCALE_TITLE = 'You are deactivating a default scale.';
export const PRINT_TICKET_TITLE = 'Print Ticket';
export const DEFAULT_SCALE_BODY = 'Before deactivating this scale, a default scale must be selected. ';

// User activate and deactivate
export const CONFIRM_DEACTIVATE_USER = 'Yes, Deactivate User';
export const DEACTIVATE_USER_BODY = 'Once this user is deactivated, they will not be able to'
  + ' sign in and use GeoTrax Web.';
export const CONFIRM_ACTIVATE_USER = 'Yes, Activate User';
export const ACTIVATE_USER_BODY = 'Once this user is activated, they will be able to sign in'
  + ' and use GeoTrax Web.';
export const CONFIRM_UNASSIGN_TRUCK = 'Yes, Unassign G-Linx';
export const CONFIRM_LOGOUT = 'Confirm logout';
export const LOGOUT_USER_BODY = 'will be immediately logged out of the GeoTrax app.';

export const SSOID_REMOVAL_WEB_USER_BODY = 'Without a Single Sign-On ID this user won\'t be able to login using Single Sign-On '
  + 'and will only be able to login with their password. ';
export const SSOID_REMOVAL_DRIVER_BODY = 'Without a Single Sign-On ID this user won\'t be able to login using Single Sign-On '
  + 'and will only be able to login with their PIN. ';
export const SSO_ACCEPT_LABEL = 'Yes, Remove Single Sign-On';

export const TICKET_MULTIPLE_COSE = 'Are you sure you want to close the selected tickets? This cannot be undone.';
export const TICKET_SINGLE_COSE = 'Are you sure you want to close the selected ticket? This cannot be undone.';

export const CONFIRM_CLOSE_TICKETS = 'Close Tickets';
export const CONFIRM_CLOSE_SINGLE_TICKET = 'Close Ticket';

export const TICKETS_CLOSE_SUCCESS = 'Tickets closed successfully.';
export const TICKET_ERROR_CLOSE = 'could not be closed.';
export const TICKET_CLOSED_SUCCESS = 'Ticket closed succesfully.';
export const TICKET_CLOSED_ERROR = 'This ticket could not be closed. Error: ';

export const REMOVE_DEFAULT_HOME_BUTTON_TEXT = 'Remove default map view';

export const TOOLTIP_SET_DEFAULT_MAPVIEW = 'Set Map View';
export const TOOLTIP_REMOVE_DEFAULT_MAPVIEW = 'Remove Map View';

// Open telemetry
export const OPEN_TELEMETRY_LOGS_URL = 'OPEN_TELEMETRY_LOGS_URL';
export const OPEN_TELEMETRY_TRACES_URL = 'OPEN_TELEMETRY_TRACES_URL';
export const OPEN_TELEMETRY_API_KEY = 'OPEN_TELEMETRY_API_KEY';
