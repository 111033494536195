import mapPinSvg from '../assets/img/mapPin.svg';

export const MARKER_TYPE_ORDER = 'Order';
export const MARKER_TYPE_TRUCK = 'Truck';
export const CARD_MAP_HEIGHT = '350px';

export type MarkerType = 'Order' | 'Truck';

// GOOGLE MAPS
// The anchorOffset value only works with a scale size of 1.
// This value is used by the icon key for the marker attributes.
export const GM_SCALE = 1;
// The value of 22 was derived from:
// https://developers.google.com/maps/documentation/javascript/reference/3.exp/marker#Symbol
// Specifically, the scale section of the documentation.
export const GM_ANCHOR = 22;
// Controls the size of the Map/Satellite buttons across all maps in the application
export const GM_CONTROLSIZE = 25;

export const LOGOUT_DRIVER = 'Log Out Driver';

// Number of meters per mile
export const METERS_PER_MILE = 1609;

// Default search radius
export const DEFAULT_RADIUS = 0.5;

// Drop pin icon
export const DROP_PIN_ICON = `url(${mapPinSvg}), auto`;
