/* eslint-disable no-console */
import config from 'react-global-configuration';
import { Dispatch } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Logger } from '@opentelemetry/api-logs';
import { DISPLAY_ENV_KEY, ENVIRONMENTS_LIST } from '../constants/appConstants';

import { openFailModal } from '../store/actions/errorModalActions';
import { store } from '../Main';
import { ReduxState } from '../store';
import { extractStoreDetailsForTelemetry } from '../telemetry/Tracer';

export default function recordLog(type: string, location: any, rawData?: any) {
  const logger = getLoggerFromStore();
  const processedData = removeSensitiveData(rawData);

  if (logger) {
    const details = extractStoreDetailsForTelemetry();
    (logger as Logger).emit({
      timestamp: Date.now(),
      body: { message: `${location}: ${processedData}`, extraInfo: details },
      severityText: type,
    });
  }

  /*
    browser-agent library and the snippet loaded on index.html allow to use noticeError().
    Via the newrelic window-level global object, we can get the error on new relic errors monitoring
    (window as any).NREUM.noticeError(error) => NREUM contains all the configurations and functionalities available
    */
  if ((window as any).NREUM) {
    const error = new Error(JSON.stringify({
      type,
      location,
      processedData,
    }));
    (window as any).NREUM.noticeError(error);
  }
}

function removeSensitiveData(rawData: any): string {
  let obj = '';
  if (rawData) {
    try {
      obj = JSON.parse(JSON.stringify(rawData));
      obj = obj.toString().replace(/Authorization:[a-zA-z0-9.-]+/, '');
    } catch (e) {
      obj = rawData.toString();
    }
  }
  return obj;
}

function getLoggerFromStore() {
  const state = store.getState() as ReduxState;
  return state.telemetryLogger;
}

/**
 * @param modalHeaderType is what the modal's header should read, also will be type
 * @param modalBodyLocation is what the modal's body should contain, also will be location
 * @param errorObj is object: (make sure it is JSON), usually the error.toString()
 * @param extraInfo is anything else you want to pass, it will be tacked onto the object: string
 * @param consoleOnly only allows logs to be sent to the console
 * @returns {Promise<void>} a promise that can be ignored (if preferred)
 */
export function devErrorAndLog(
  modalHeaderType: string,
  modalBodyLocation: any,
  errorObj?: any,
  extraInfo?: string,
  consoleOnly = false
) {
  return async (dispatch: Dispatch) => {
    // eslint-disable-next-line no-param-reassign
    let allInfo = errorObj;
    if (extraInfo) {
      allInfo = `${errorObj} - extraInfo:${extraInfo}`;
    }
    recordLog(modalHeaderType, modalBodyLocation, errorObj);

    // show modal error if not production or demo
    const envKey = config.get(DISPLAY_ENV_KEY)
      ? config.get(DISPLAY_ENV_KEY).toString().toUpperCase()
      : 'LOCAL';
    if (ENVIRONMENTS_LIST.find((env) => env === envKey)) {
      if (consoleOnly) {
        console.log(modalHeaderType + modalBodyLocation + allInfo + extraInfo);
        return;
      }
      if (envKey !== 'PROD' && envKey !== 'DEMO') {
        dispatch(
          openFailModal(
            modalHeaderType,
            modalBodyLocation.toString()
          )
        );
      }
    }
  };
}
