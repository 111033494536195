import React from 'react';
import cx from 'classnames';
import { UrlKeyDto, UserRegionDto } from '@trucktrax/trucktrax-ts-common';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  INPUT_FORM,
  MULTIDROPDOWN_FORM,
  CHECKBOX_FORM,
  RADIO_FORM,
  DROPDOWN_FORM,
  SHOW_YES,
  SHOW_NO,
  OPTION_PASS,
  OPTION_FAIL,
} from '../../../constants/appConstants';
import {
  defaultRegionListForDropdown,
  defaultProductLineRadioOptions,
  checklistOptionsWhenToPresent,
} from '../../../util/adminUtil';
import { AdminCardConfig } from '../../shared/admin/AdminCard';
import recordLog from '../../../util/errorUtil';

interface KeyedRegion {
  key?: string;
  region: UrlKeyDto;
}

export default function getEditConfig(
  styles: { [name: string]: string },
  regionList: UserRegionDto[],
  assignedRegionList: UserRegionDto[],
  dto?: any,
): AdminCardConfig[] {
  const checklistOptionsFormats = [
    {
      key: `${SHOW_YES}${SHOW_NO}`,
      label: (
        <div className={styles.addOptionsCtr}>
          <span className="margin-right-5">
            <i
              aria-hidden="true"
              className={cx('icon-close', 'margin-right-5', styles.iconClose)}
            />
            {SHOW_YES}
          </span>
          <span>
            <i
              aria-hidden="true"
              className={cx('icon-check', 'margin-right-5', 'margin-left-5', styles.iconCheck)}
            />
            {SHOW_NO}
          </span>
        </div>
      ),
      value: `${SHOW_YES}${SHOW_NO}`,
    },
    {
      key: `${OPTION_PASS}${OPTION_FAIL}`,
      label: (
        <div className={styles.addOptionsCtr}>
          <span className="margin-right-5">
            <i
              aria-hidden="true"
              className={cx('icon-close', 'margin-right-5', styles.iconClose)}
            />
            {OPTION_FAIL}
          </span>
          <span>
            <i
              aria-hidden="true"
              className={cx('icon-check', 'margin-right-5', 'margin-left-5', styles.iconCheck)}
            />
            {OPTION_PASS}
          </span>
        </div>
      ),
      value: `${OPTION_PASS}${OPTION_FAIL}`,
    },
  ];

  // admin card sends the data in different formats depending on if it is an initial
  // load, or an update from the user changing their selection.
  // TODO: Make admin card send the same data format regardless
  const regionAccessor = (checklist: { regions: { key: string, region: any }[] }) => {
    try {
      if (!checklist.regions) {
        return [];
      }
      const selectedKeysArray = checklist.regions
        .map(region => (region.key ? region.key : region.region.url));

      return selectedKeysArray.map((key: any) => ({ key }));
    } catch (e) {
      recordLog('Error in the region accessor', e);
      return [];
    }
  };

  const regionFilter = (
    items:
      {
        disabled: boolean,
        key: string,
        label: string,
        value: UserRegionDto[]
      }[],
    values: {
      regions: KeyedRegion[]
    }
  ) => {
    if (values && values.regions) {
      values.regions.forEach(region => {
        if (!region.key) {
          region.key = region.region.url;
        }
      });
    }
    return items;
  };

  let selectedWhenToPresent = checklistOptionsWhenToPresent[0];
  type RadioOption = typeof defaultProductLineRadioOptions[0];
  let selectedProductLines: RadioOption[] = [];

  if (dto) {
    // eslint-disable-next-line prefer-destructuring
    selectedWhenToPresent = checklistOptionsWhenToPresent
      .filter(c => c.value === dto.displayTrigger)[0];

    selectedProductLines = defaultProductLineRadioOptions.filter(
      p => dto.productLines && dto.productLines.includes(p.value)
    );
  }

  let dropDownRegions = defaultRegionListForDropdown(regionList, assignedRegionList);
  dropDownRegions = dropDownRegions.sort((a, b) => a.label!.localeCompare(b.label!));

  return [
    {
      type: INPUT_FORM,
      accessor: ADMIN_KEYS.NAME,
      key: ADMIN_KEYS.NAME,
      label: ADMIN_LABELS.NAME,
      dataTest: 'checklists-drilldown-name',
      errorDataTest: 'name-input-missing-error',
      maxLength: 40,
      isRequired: true,
      className: cx('fullWidthSpace', 'margin-top-0', styles.modalRow, styles.name),
    },
    {
      type: MULTIDROPDOWN_FORM,
      key: ADMIN_KEYS.REGIONS,
      label: ADMIN_LABELS.REGIONS,
      className: cx('widthSpace', styles.modalRow, styles.regions),
      items: dropDownRegions,
      isRequired: true,
      placeholder: 'Select region(s)...',
      lockMessage: 'You do not have access to edit this region',
      dataTest: 'region-input-data-test',
      accessor: regionAccessor,
      itemFilter: regionFilter,
    },
    {
      type: CHECKBOX_FORM,
      key: ADMIN_KEYS.PRODUCT_LINES,
      label: ADMIN_LABELS.PRODUCT_LINES,
      className: styles.productLines,
      items: defaultProductLineRadioOptions,
      isRequired: true,
      errorDataTest: 'primaryProductLine-duplication-error',
      initialSelected: selectedProductLines,
    },
    {
      type: RADIO_FORM,
      accessor: ADMIN_KEYS.CHECKLISTS_OPTIONS_FORMAT,
      dataTest: 'users-drilldown-answerType',
      key: ADMIN_KEYS.CHECKLISTS_OPTIONS_FORMAT,
      label: ADMIN_LABELS.CHECKLISTS_OPTIONS_FORMAT,
      className: styles.optionsFormats,
      radioButtonGroupName: ADMIN_KEYS.CHECKLISTS_OPTIONS_FORMAT,
      isRequired: true,
      radioButtons: checklistOptionsFormats,
      initialSelected: checklistOptionsFormats[0],
    },
    {
      type: DROPDOWN_FORM,
      key: ADMIN_KEYS.CHECKLISTS_OPTIONS_WHEN_TO_PRESENT,
      label: ADMIN_LABELS.CHECKLISTS_OPTIONS_WHEN_TO_PRESENT,
      className: cx('widthSpace', styles.modalRow, styles.whenToPresent),
      items: checklistOptionsWhenToPresent,
      initialSelected: selectedWhenToPresent,
      isRequired: true,
      dataTest: 'whenToPresent-input-data-test',
    },
  ];
}
