import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactList from 'react-list';
import { orderBy } from 'lodash';
import cx from 'classnames';
import { Tooltip } from '@trucktrax/trucktrax-common';
import { UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import constants, { MessageTabs } from '../../../../../constants/messagingConstants';
import {
  checkAckTime,
  checkBodyForIconString,
  formatTime,
  getRecipientNames,
} from '../../message';
import {
  ConversationMessagesDto, ConversationToMessagesDto, Driver,
} from '../../index.d';
import styles from './MessageList.module.css';

class MessageList extends Component<MessageListProps, MessageListState> {
  static defaultProps = {
    selectedMessage: null,
    currentRegion: { url: '' },
  };

  nodeRef: ReactList | null = null;

  constructor(props: MessageListProps) {
    super(props);

    this.state = {
      page: 1,
      sentpage: 0,
    };
  }

  // See TODO in FeaturesTable.js regarding componentWillReceiveProps
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: MessageListProps) {
    if (nextProps.currentTab !== this.props.currentTab) {
      this.nodeRef!.scrollTo(0);
    }
  }

  onClick = (message: ConversationMessagesDto) => {
    this.props.onItemClicked(message);
  };

  markUnread = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, message: ConversationMessagesDto) => {
    evt.stopPropagation();
    this.props.onMarkUnread(message);
  };

  acknowledgementIndicator = (latestMessageRead: boolean, latestIsFromDispatcher: boolean, conversations: ConversationMessagesDto) => {
    if (!latestMessageRead) {
      return <span className={styles.dot} />;
    }

    return latestIsFromDispatcher
      ? <span className={styles.acknowledgedDot} />
      : (
        <Tooltip text="Mark as Unread">
          <button
            className={styles.acknowledgedDot}
            onClick={(e) => this.markUnread(e, conversations)}
          />
        </Tooltip>
      );
  };

  getList(messages: ConversationToMessagesDto[]) {
    const { driverList, selectedProductLine } = this.props;
    const groupedMessages = MessageList.groupMessages(messages);

    return groupedMessages.map(conversations => {
      const latestMessageIdx = conversations.items.length - 1;
      const message = conversations.items[latestMessageIdx];
      let driverNum = 0;
      if (message.partiesList) {
        driverNum = conversations.latestMessage!.partiesList!.filter((obj) => obj.url.includes('drivers')).length;
      } else {
        driverNum = conversations.partiesList.filter((obj) => obj.url.includes('drivers')).length;
      }
      const author = conversations.commaSeparatedParties.replace(constants.DISPATCH_COMMA, '')
        || getRecipientNames(conversations);
      const authorOnly = `${author.replace(constants.DISPATCH, '')}`;

      // checking if parties list includes all the drivers plus the dispatcher.
      const isAllDriversInProductLine = conversations.partiesList.length === (driverList.length + 1);
      const productLineString = selectedProductLine === constants.READY_MIX_VALUE
        ? constants.READY_MIX_STRING
        : selectedProductLine;
      const acks = conversations.conversationAcknowledgements;
      const ackIndex = acks.findIndex(ack => ack.partyMember.url === this.props.currentRegion.url);
      const ack = acks[ackIndex];

      if (ack !== undefined) {
        const lastMessageIsAcknowledged = !checkAckTime(message.sent, ack.lastAcknowledged);
        const lastMessageIsFromDispatcher = message.from?.url.includes(constants.USERS) ?? false;
        const latestMessageRead = lastMessageIsAcknowledged || lastMessageIsFromDispatcher;

        let selected = false;

        if (this.props.selectedMessage) {
          const latestMessageIdxx = this.props.selectedMessage.items.length - 1;
          const latestMessage = this.props.selectedMessage.items[latestMessageIdxx];
          selected = (latestMessage.conversation.url === message.conversation.url);
        }

        return (
          <div
            data-test="message-center-messagelist-item"
            key={message.id}
            role="button"
            tabIndex={0}
            className={cx(styles.messageItem, { [styles.selected]: selected })}
            onClick={() => this.onClick(conversations)}
            onKeyPress={() => this.onClick(conversations)}
          >
            {this.acknowledgementIndicator(latestMessageRead, lastMessageIsFromDispatcher, conversations)}
            {/* header */}
            <div className={styles.header}>
              <span className={cx(styles.author, 'nowrap-ellipsis', { [styles.unreadText]: !latestMessageRead })}>
                {driverNum > 1
                  ? <i aria-hidden="true" className="icon-people icon-lg icon-people-margin" />
                  : <i />}
                {' '}
                {isAllDriversInProductLine ? `All ${productLineString} Drivers` : authorOnly}
              </span>
              {/* time stamp */}
              <span className={styles.time}>{formatTime(new Date(message.sent))}</span>
            </div>
            {/* preview */}
            <div className={styles.preview}>{checkBodyForIconString(message.body)}</div>
          </div>
        );
      }
      return null;
    });
  }

  // eslint-disable-next-line no-unused-vars
  itemRenderer = (index: number) => {
    const getSortProperty = (message: ConversationToMessagesDto) => {
      const latestMessageIdx = message.conversations.items.length - 1;
      const m = message.conversations.items[latestMessageIdx];
      return m.sent;
    };
    // sort inbox by read and sent
    const messageList = orderBy(this.props.messages, [(message) => getSortProperty(message)], ['desc']);
    const list = this.getList(messageList);

    return list[index];
  };

  handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const paperElement = document.getElementById('paperElement');
    const { scrollHeight, scrollTop } = (event.target as HTMLDivElement);
    const isBottom = scrollHeight <= scrollTop + (paperElement?.offsetHeight ?? 0);

    if (isBottom && this.props.messages.length % 29 === 0) {
      this.nextPage();
    }
  };

  nextPage = () => {
    const { messages } = this.props;
    if (this.props.currentTab === constants.MESSAGES_TABS_INBOX) {
      this.props.onNextPage(this.state.page + 1, messages, this.props.currentRegion.url!);
    } else {
      this.props.onNextPage(this.state.sentpage + 1, messages, this.props.currentRegion.url!);
    }
  };

  static groupMessages = (messages: ConversationToMessagesDto[]) => {
    const result = messages.filter(m => !!m).map(m => m.conversations);

    return result;
  };

  render() {
    return (
      <div
        className={styles.messageList}
        onScroll={e => this.handleScroll(e)}
      >
        <ReactList
          itemRenderer={(index) => this.itemRenderer(index) as any}
          length={this.props.messages.length}
          type="uniform"
          threshold={30}
          ref={(node) => {
            this.nodeRef = node;
          }}
        />
      </div>
    );
  }
}

export interface MessageListState {
  page: number,
  sentpage: number;
}
export interface MessageListProps {
  messages: ConversationToMessagesDto[];
  onItemClicked: (thread: ConversationMessagesDto) => void;
  onMarkUnread: (thread: ConversationMessagesDto) => void;
  selectedMessage?: ConversationMessagesDto;
  currentTab: MessageTabs;
  onNextPage: (page: number, messages: ConversationToMessagesDto[], regionUrl: string) => void;
  currentRegion: UrlKeyDto;
  driverList: Driver[];
  selectedProductLine?: string;
}

export default MessageList;
