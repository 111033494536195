import { Dispatch } from 'redux';
import { CancelToken } from 'axios';
import { UserDto } from '@trucktrax/trucktrax-ts-common';
import { getRequest, getRequestWhenTokenPresentInUrl, putRequest } from './requestService';
import { closeModal, openFailModal } from '../store/actions/errorModalActions';
import { devErrorAndLog } from '../util/errorUtil';
import { openSnackbar } from '../store/actions/snackbarActions';
import { ERROR_TEXT_FETCH_USER_LIST, ERROR_TEXT_RESET_USER_PASSWORD } from '../constants/errorConstants';
import { FETCH_USERS } from '../constants/actionConstants';
import {
  CANCEL_API_DUE_TO_PAGE_CHANGE,
  INVALID_STALE_TOKEN_LOGOUT,
  SUCCESS,
} from '../constants/appConstants';
import { RESET_PASSWORD_PATH, USERS_PATH, USER_USING_ROLES_PATH } from '../constants/apiConstants';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { RESET_PASSWORD_SUCCESS_TEXT } from '../constants/successConstants';
import { getIdFromUrl, getSourceIdFromUrl } from '../util/appUtil';
import HTTP_CODES from '../constants/httpConstants';

export async function getUserByUrl(userUrl: string) {
  const id = getIdFromUrl(userUrl);
  const url = `${getGeotraxBaseUrl()}${USERS_PATH}/${id}`;

  const response = await getRequest(url);

  return response.data;
}

export function fetchUsers(regionUrl?: string, cancelToken?: CancelToken) {
  const regionUrlParam = regionUrl ?? '';
  return async (dispatch: Dispatch) => {
    const userAssignmentUrl = getGeotraxBaseUrl() + USERS_PATH;

    let params = null;

    if (regionUrlParam && regionUrlParam !== '') {
      params = { region: regionUrlParam };
    }

    try {
      let response = await getRequest(userAssignmentUrl, params, cancelToken);
      let items: UserDto[] = [];

      if (response.data?.items?.length) {
        items = [...response.data.items];
      }

      while (response.data?.next && response.data?.next.length) {
        // eslint-disable-next-line no-await-in-loop
        response = await getRequestWhenTokenPresentInUrl(response.data.next);
        response.data.items.forEach((i: UserDto) => {
          items.push(i);
        });
      }

      dispatch({
        type: FETCH_USERS,
        payload: items,
      });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      if (e !== CANCEL_API_DUE_TO_PAGE_CHANGE && e !== INVALID_STALE_TOKEN_LOGOUT) {
        dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_USER_LIST, e, undefined, consoleOnly) as any);
      }
    }
  };
}

export function resetUserPassword(userDetail: UserDto, currentRegionUrl: string) {
  return async (dispatch: Dispatch) => {
    const url = `${getGeotraxBaseUrl()}${USERS_PATH}${RESET_PASSWORD_PATH}`
      + `?regions=${currentRegionUrl}`;
    let body = {};
    if (userDetail.tenant) {
      body = {
        username: userDetail.username,
        firstName: userDetail.firstName,
        lastName: userDetail.lastName,
        email: userDetail.email,
        primaryProductLine: userDetail.primaryProductLine,
        regions: userDetail.regions,
      };
    }
    try {
      await putRequest(url, body);
      dispatch(closeModal());
      dispatch(openSnackbar({
        snackbarBody: RESET_PASSWORD_SUCCESS_TEXT,
        dataTest: 'reset-password-success',
        snackbarType: SUCCESS,
      }));
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_TEXT_RESET_USER_PASSWORD));
    }
  };
}

// version 2.5 (old permissions)
export async function getUserRequest(id: number) {
  const userUrl = `${getGeotraxBaseUrl() + USERS_PATH}/${id}`;
  try {
    const userResponse = await getRequest(userUrl);
    return userResponse.data as UserDto;
  } catch (e) {
    return null;
  }
}

// version 2.6 (new permissions)
export async function getUserUsingRoles(id: number) {
  const userUrl = `${`${getGeotraxBaseUrl() + USERS_PATH}${USER_USING_ROLES_PATH}`}/${id}`;
  const userResponse = await getRequest(userUrl);
  return userResponse.data as UserDto;
}

// check if user is logged in
export async function isUserLoggedIn(userUrl: string) {
  const sourceId = getSourceIdFromUrl(userUrl);
  const url = `${getGeotraxBaseUrl()}/activefleet/is-user-logged-in`;
  const params = {
    sourceId,
  };

  try {
    const response = await getRequest(url, params);
    return response.data;
  } catch (e) {
    return false;
  }
}
