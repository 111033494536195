import { Dispatch } from 'redux';
import { TicketDto } from '@trucktrax/trucktrax-ts-common';
import { devErrorAndLog } from '../util/errorUtil';
import { getRequest } from './requestService';
import { ERROR_TEXT_FETCH_STATUS_LIST } from '../constants/errorConstants';
import { STATUSES_PATH } from '../constants/apiConstants';
import { getTicketingBaseUrl } from '../util/apiUtil';
import { TicketStatusMarker } from '../types';
import HTTP_CODES from '../constants/httpConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export function fetchStatusList(activeTicket: TicketDto | TicketStatusMarker) {
  return async (dispatch: Dispatch) => {
    const url = getTicketingBaseUrl() + STATUSES_PATH;
    const params = {
      region: activeTicket?.region?.url,
      ticket: activeTicket.url,
      isDeleted: false,
      isArchived: false,
    };
    try {
      const response = await getRequest(url, params);
      return response.data.items;
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_STATUS_LIST, e, undefined, consoleOnly) as any);
      return [];
    }
  };
}
