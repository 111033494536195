import React, { Component } from 'react';
import { TicketDto } from '@trucktrax/trucktrax-ts-common';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import cx from 'classnames';
import styles from './TicketQualityFactors.module.css';
import { fetchCurrentTicketQualityFactors } from '../../../../services/ticketsService';
import { getTwoDecimalStr, getIntStr, ouncesToGallons } from '../../../../util/appUtil';
import { qualityFactorDataConverter } from '../../../../util/ticketUtil';
import { NOT_AVAILABLE } from '../../../../constants/appConstants';

export class TicketQualityFactors extends Component<TicketQualityFactorsProps> {
  static defaultProps = {
    ticket: {},
    currentTicketQualityFactors: [],
  };

  componentDidMount() {
    if (this.props.ticket?.id) {
      this.props.fetchCurrentTicketQualityFactors(this.props.ticket.id);
    }
  }

  static renderTimestampListItem = (items?: TimestampListItem[], isDarkRowStyle = false) => {
    const rowStyle = isDarkRowStyle ? styles.darkBodyRowStyle : styles.bodyRowStyle;

    return items && (items.map(item => (
      <tr className={rowStyle} key={item.timestamp}>
        <td className={cx(styles.timestamp)}>{item.timestamp}</td>
        <td className={styles.value}>{getTwoDecimalStr(item.value) || NOT_AVAILABLE}</td>
      </tr>
    )));
  };

  render() {
    const { currentTicketQualityFactors, ticket } = this.props;
    const qf = qualityFactorDataConverter(currentTicketQualityFactors ?? []);

    return (
      <Paper
        id="ticketPopover-paperElement"
        className={styles.paper}
      >
        <h2 className={styles.ticketQualityFactorsHeader}>Quality Factors</h2>
        <div className={styles.tabContainer}>
          <div className={styles.leftSection}>
            <div>
              <table className={styles.tableContainer}>
                <thead>
                  <tr>
                    <th className={styles.headerStyle}>
                      Load Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={styles.darkBodyRowStyle}>
                    <td className={styles.label}>Slump on Arrival (in) </td>
                    <td className={styles.value}>{getTwoDecimalStr(qf.SLUMP) || NOT_AVAILABLE}</td>
                  </tr>
                  <tr className={styles.bodyRowStyle}>
                    <td className={styles.label}>Test Cylinders Taken?</td>
                    <td className={styles.value}>{ticket?.hasTestCylinders ? 'Yes' : 'No'}</td>
                  </tr>
                  <tr className={styles.darkBodyRowStyle}>
                    <td className={styles.label}>Water Added (gal)</td>
                    <td className={styles.value}>{getTwoDecimalStr(ouncesToGallons(qf.WATER_ADDED_Total)) || NOT_AVAILABLE}</td>
                  </tr>

                  {TicketQualityFactors.renderTimestampListItem(qf.WATER_ADDED.map((i): TimestampListItem => ({
                    value: ouncesToGallons(i.value).toString(),
                    timestamp: i.timestamp,
                  })), true)}

                  <tr className={styles.bodyRowStyle}>
                    <td className={cx(styles.label)}>Admixtures Added (oz)</td>
                    <td className={styles.value}>{getTwoDecimalStr(qf.ADMIXTURE_ADDED_TOTAL) || NOT_AVAILABLE}</td>
                  </tr>

                  {TicketQualityFactors.renderTimestampListItem(qf.ADMIXTURE_ADDED.map((i): TimestampListItem => ({
                    value: i.value.toString(),
                    timestamp: i.timestamp,
                  })), false)}

                  <tr className={styles.darkBodyRowStyle}>
                    <td className={cx(styles.label, styles.lastTrStyle)}>Leftover Concrete (yd&#179;)</td>
                    <td className={cx(styles.value, styles.lastTrStyle)}>
                      {
                        getTwoDecimalStr(qf.CONCRETE_REMAINING) || NOT_AVAILABLE
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.tableContainer}>
              <table className={styles.tableContainer}>
                <thead>
                  <tr>
                    <th className={styles.headerStyle}>
                      Drum Counter
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={styles.darkBodyRowStyle}>
                    <td className={styles.label}>Initial Rev Count</td>
                    <td className={styles.value}>{getIntStr(qf.INITIAL_DRUM_COUNT) || NOT_AVAILABLE}</td>
                  </tr>
                  <tr className={styles.bodyRowStyle}>
                    <td className={styles.label}>Final Rev Count</td>
                    <td className={styles.value}>{getIntStr(qf.FINAL_DRUM_COUNT) || NOT_AVAILABLE}</td>
                  </tr>
                  <tr className={styles.darkBodyRowStyle}>
                    <td className={cx(styles.label, styles.lastTrStyle)}>Final Rev Count for Load</td>
                    <td className={cx(styles.value, styles.lastTrStyle)}>
                      {
                        getIntStr(qf.FINAL_LOAD_COUNT) || NOT_AVAILABLE
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

export interface TicketQualityFactorsProps {
  fetchCurrentTicketQualityFactors: (id: number) => void,
  ticket?: TicketDto,
  currentTicketQualityFactors?: any[]
}

interface TimestampListItem {
  timestamp: string,
  value: string
}

function mapStateToProps(state: any) {
  return {
    currentTicketQualityFactors: state.currentTicketQualityFactors,
  };
}

export default connect<any, any, any>(mapStateToProps, {
  fetchCurrentTicketQualityFactors,
})(TicketQualityFactors as any);
