/* eslint-disable import/prefer-default-export */
import { ProductLine } from '@trucktrax/trucktrax-ts-common';
import {
  SET_PRIMARY_PRODUCT_LINE,
  SET_ROUTE_HISTORY_PRODUCT_LINES,
  SET_SECONDARY_PRODUCT_LINE,
  SET_SECONDARY_PRODUCT_LINES,
  SET_SELECTED_PRODUCT_LINE,
} from '../../constants/actionConstants';

export const setSelectedProductLine = (productLine: ProductLine) => ({
  type: SET_SELECTED_PRODUCT_LINE,
  payload: productLine,
});

export const setPrimaryProductLine = (productLine: ProductLine) => ({
  type: SET_PRIMARY_PRODUCT_LINE,
  payload: productLine,
});

export const setSecondaryProductLine = (productLine: ProductLine) => ({
  type: SET_SECONDARY_PRODUCT_LINE,
  payload: productLine,
});

export const setSecondaryProductLines = (productLines: ProductLine[]) => ({
  type: SET_SECONDARY_PRODUCT_LINES,
  payload: productLines,
});

export const setRouteHistoryProductLines = (productLines: string[]) => ({
  type: SET_ROUTE_HISTORY_PRODUCT_LINES,
  payload: productLines,
});
