import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import { TicketDto } from '@trucktrax/trucktrax-ts-common';
import styles from './ScaleTraxTicketsList.module.css';
import { TicketStateSwitcherType } from '../../../types';
import TicketList from '../../shared/tickets/TicketList';
import { ReduxState } from '../../../store';
import ScaleTraxTicket from './ScaleTraxTicket';
import ScaleTraxFooter from './Footer';
import { TICKET_SEARCH_MAX_LENGTH } from '../../../constants/appConstants';
import { setFavicon } from '../../../util/appUtil';

// eslint-disable-next-line react/prefer-stateless-function
export class ScaleTraxTicketsList extends Component<ScaleTraxTicketsListProps> {
  render() {
    const { selectedTicket } = this.props;
    const isScaleTrax = true;
    if (selectedTicket?.id) {
      return (
        <ScaleTraxTicket history={this.props.history} selectedTicket={selectedTicket} />
      );
    }
    return (
      <div>
        <div className={styles.container}>
          <div className={styles.header}>
            <h2>Review Tickets</h2>
          </div>
          <Paper
            id="ticketsPopover-paperElement"
            className={styles.paper}
          >
            <TicketList
              ticketStateSwitcherType={TicketStateSwitcherType.DropDown}
              searchPlaceholder="Search any column..."
              searchMaxLength={TICKET_SEARCH_MAX_LENGTH}
              isScaleTrax={isScaleTrax}
            />
          </Paper>
        </div>
        <div><ScaleTraxFooter /></div>
      </div>
    );
  }

  async componentDidMount() {
    document.title = 'ScaleTrax - TruckTrax';
    setFavicon('/favicon-scaletrax.ico');
  }
}

export interface ScaleTraxTicketsListProps {
  selectedTicket?: TicketDto,
  history: {
    push: () => void
  };
}

export default connect<any, any, any>((state: Partial<ReduxState>) => ({
  selectedTicket: state.selectedTicket,
}))(ScaleTraxTicketsList as any);
