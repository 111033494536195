import React, { Component, CSSProperties } from 'react';
import cx from 'classnames';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import styles from './feature.module.css';
import jStyle from './feature-style';
import NoFeature from './NoFeature';
import { ICON } from '../../../../constants/featureFlagConstants';
import { TableDataProp } from './FeatureFlagBody';

export const noFeature = (tableData: TableDataProp[] | undefined) => !tableData || (tableData && tableData.length === 0);

export default class FeaturesTable extends Component<FeaturesTableProps> {
  state = {
    tableData: this.props.tableData,
  };

  /*
  *  TODO: We will need to spend more time/effort in the future updating these.
  *  componentWillReceiveProps is deprecated since React 16.9.0,
  *  use UNSAFE_componentWillReceiveProps instead,
  *  see https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops.
  *  Use https://github.com/reactjs/react-codemod#rename-unsafe-lifecycles
  *  to automatically update your components
  */
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props: FeaturesTableProps) {
    this.setState({
      tableData: props.tableData,
    });
  }

  render() {
    if (noFeature(this.state.tableData)) {
      return (<NoFeature />);
    }
    return (
      <Table className={styles.table}>
        <TableHead style={jStyle.headerContainer}>
          <TableRow style={jStyle.headerRow}>
            <TableCell style={({ ...jStyle.headerStyle, ...jStyle.colType } as CSSProperties)}>
              <span className="txt-bold">Type</span>
            </TableCell>
            <TableCell style={({ ...jStyle.headerStyle, ...jStyle.colItem } as CSSProperties)}>
              <span className="txt-bold">Item</span>
            </TableCell>
            <TableCell style={({ ...jStyle.removeAllHeaderStyle, ...jStyle.colRemove } as CSSProperties)}>
              <button
                className={cx('tt-btn', 'tt-btn-text--small', styles.removeAllBtn)}
                data-test="remove-feature-flags-permissions-list"
                onClick={this.props.removeAllCallBack}
              >
                <i className="icon-delete margin-right-5" />
                <span>Remove All</span>
              </button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.tableData.map((row: TableDataProp, index: number) => {
            const selected = this.props.selectedRow ? false : (index === this.props.selectedRow![0]);
            const iconClassName = (ICON as any)[row.type!.toLowerCase()];
            return (
              <TableRow
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={cx(
                  selected ? styles.selectedRow : styles.row,
                  styles.table
                )}
                onClick={() => this.props.onRowSelection([index])}
              >
                <TableCell>
                  <span className={styles.cellText}>
                    <i
                      aria-hidden="true"
                      className={cx(iconClassName, 'icon-gray-500', styles.displayUnset)}
                    />
                    <span className={styles.marginLeft}>
                      {' '}
                      {row.type}
                    </span>
                  </span>
                </TableCell>
                <TableCell>
                  <span className={styles.cellText}>
                    {`${row.item} ${row.region}`}
                  </span>
                </TableCell>
                <TableCell style={(jStyle.closeButton as CSSProperties)}>
                  <span>
                    <button
                      id="removeButton"
                      className={cx('tt-btn', 'tt-btn-basic', styles.closeButton)}
                      data-test="remove-feature-flags-permissions-list-item"
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        this.props.onRowRemoveButtonClick(event, row, index);
                      }}
                    >
                      <i
                        aria-hidden="true"
                        className={cx('icon-close', styles.displayUnset)}
                      />
                    </button>
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export interface FeaturesTableProps {
  tableData: TableDataProp[],
  removeAllCallBack: VoidFunc,
  selectedRow?: number[],
  onRowRemoveButtonClick: (event: React.MouseEvent<HTMLElement>, row: TableDataProp, index: number) => void,
  onRowSelection: (items: number[]) => void
}
