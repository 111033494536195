import React, { Component } from 'react';
import { GroupMemberFullDto, PermissionAccess, UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import { FloatingActionButton } from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import DataTableContent from '../../shared/DataTableContent';
import SecurityPermissionAddModal from './PermissionsAddModal';
import { addDataTest } from '../../../util/adminUtil';
import { userHasEditPermissionInAnyRegion } from '../../../services/permissionsService';
import styles from './PermissionsView.module.css';
import TabPanel from '../../shared/TabPanel';
import {
  ADD_PERMISSIONS_GROUP_MODAL_TITLE,
  ADMIN_KEYS,
  ADV_SECURITY_PERMS,
} from '../../../constants/appConstants';
import { getSecurityBaseUrl } from '../../../util/apiUtil';
import { DATASOURCE, PERMISSION_GROUP_MEMBERS_FULL_PATH, PERMISSION_GROUPS_PATH } from '../../../constants/apiConstants';
import { GROUP_PERMISSIONS_TEXT, USER_PERMISSIONS_TEXT, REGIONS_TEXT } from '../../../constants/navConstants';
import { ReduxState } from '../../../store';
import AddFeatureModal from '../../shared/AddFeatureModal';
import EmptyDataTableContent from '../../shared/EmptyDataTableContent';
import noRegionSvg from '../../../assets/img/noRegion.svg';

export interface PermissionsViewState {
  isAddModalOpen: boolean;
}
type GroupMemberWithRegions = GroupMemberFullDto & { regions: UrlKeyDto[] };

/**
 * render PermissionsView component
 * @param {*} props props for PermissionsView
 */
export class PermissionsView extends Component<PermissionsViewProps, PermissionsViewState> {
  state = {
    isAddModalOpen: false,
  };

  handleChange = (_: any, newValue: number) => {
    let newRoute = '';
    if (newValue === 1) {
      newRoute = '/admin/permissions/users';
    } else if (newValue === 0) {
      newRoute = '/admin/permissions/groups';
    }

    this.props.history.push(newRoute);
  };

  handleAddModalOpen = () => {
    this.setState({ isAddModalOpen: true });
  };

  handleAddModalClose = () => {
    if (this.state.isAddModalOpen) {
      this.setState({ isAddModalOpen: false });
    }
  };

  static getSecurityGroupsColumns = () => ([
    {
      Header: 'Name',
      accessor: ADMIN_KEYS.NAME,
      minWidth: 250,
      sortable: true,
      Cell: (cellProps: any) => addDataTest('txt-bold', 'securityPermissions-group-name', cellProps.value),
    },
  ]);

  static getUserGroups = (row: GroupMemberWithRegions) => {
    const groupArr = row.groups;
    if (!groupArr) {
      return '';
    }
    const groupsNames = groupArr.map(r => r.name);
    groupsNames.sort();
    const outStr = groupsNames.join(', ');
    return outStr;
  };

  // Removes the unwanted "," between the retrieved first + last name
  static formatMemberName = (name: string) => {
    let formattedName = name;
    if (formattedName && formattedName.length > 0) {
      formattedName = name.replace(/,/g, '');
      return formattedName;
    }
    return formattedName;
  };

  static getSecurityUsersColumns = () => ([
    {
      Header: 'Name',
      accessor: 'membername',
      minWidth: 150,
      sortable: true,
      Cell: (cellProps: any) => addDataTest(
        'txt-bold',
        'securityPermissions-users-member',
        PermissionsView.formatMemberName(cellProps.value)
      ),
    },
    {
      Header: 'Region',
      accessor: 'regionname',
      minWidth: 125,
      sortable: false,
      Cell: (cellProps: any) => addDataTest('', 'securityPermissions-users-region', cellProps.value),
    },
    {
      Header: 'Belongs to Group(s)',
      id: 'groups',
      accessor: (row: GroupMemberWithRegions) => PermissionsView.getUserGroups(row),
      minWidth: 500,
      sortable: false,
      Cell: (cellProps: any) => addDataTest('', 'securityPermissions-users-groups', cellProps.value),
    },
  ]);

  resolveFn = (data: GroupMemberFullDto[]) => {
    const isMemberData = data && data.length && data[0].member && true;
    if (!isMemberData) {
      return [];
    }

    const uniqueUsers: GroupMemberWithRegions[] = [];
    data.forEach(user => {
      const alreadyAdded = uniqueUsers.some(u => u.member!.url === user.member!.url);
      if (alreadyAdded) return;

      uniqueUsers.push({ ...user, regions: this.props.regionList });
    });

    return uniqueUsers;
  };

  static renderMissingPermissions = () => (
    <EmptyDataTableContent
      img={{ alt: 'Missing Regions Permissions', src: noRegionSvg }}
      component={(
        <div>
          <strong style={{ fontSize: '2rem' }}>Access denied</strong>
          <p>
            Regional permissions are required to view this page.
            <br />
            Please contact an administrator to request access.
          </p>
        </div>
      )}
    />
  );

  render() {
    const { regionsAccess } = this.props;
    const missingRegionPermission = regionsAccess === PermissionAccess.Deny;
    if (missingRegionPermission) {
      return PermissionsView.renderMissingPermissions();
    }

    const permissionGroupsUrl = getSecurityBaseUrl() + PERMISSION_GROUPS_PATH;
    const securityGroupsColumns = PermissionsView.getSecurityGroupsColumns();
    const groupMembersUrl = getSecurityBaseUrl() + PERMISSION_GROUP_MEMBERS_FULL_PATH;
    const securityUsersColumns = PermissionsView.getSecurityUsersColumns();

    const tabs = (
      <Tabs
        value={this.props.tab}
        onChange={this.handleChange}
        classes={{ root: styles.tabsContainer }}
      >
        <Tab
          label="Groups"
          data-test="security-groups-tab"
        />
        <Tab
          label="Users"
          data-test="security-users-tab"
        />
      </Tabs>
    );

    return (
      <div className={styles.container}>
        {(this.props.tab === 0) && (
          <TabPanel value={this.props.tab} index={0}>
            <DataTableContent
              baseUrl={permissionGroupsUrl}
              columns={securityGroupsColumns}
              header={GROUP_PERMISSIONS_TEXT}
              renderTabs={tabs}
              byPassRedisCache
              source={DATASOURCE.PERMISSION_GROUP}
            />
          </TabPanel>
        )}
        {(this.props.tab === 1) && (
          <TabPanel value={this.props.tab} index={1}>
            <DataTableContent
              baseUrl={groupMembersUrl}
              columns={securityUsersColumns}
              header={USER_PERMISSIONS_TEXT}
              renderTabs={tabs}
              noRegionFilter
              resolveFn={(data: GroupMemberFullDto[]) => this.resolveFn(data)}
              byPassRedisCache
              source={DATASOURCE.PERMISSION_USER}
            />
          </TabPanel>
        )}
        {(userHasEditPermissionInAnyRegion(this.props.userPermission, ADV_SECURITY_PERMS) && this.props.tab === 0) && (
          <div>
            <FloatingActionButton onClick={this.handleAddModalOpen} />
            <AddFeatureModal
              title={ADD_PERMISSIONS_GROUP_MODAL_TITLE}
              isOpen={this.state.isAddModalOpen}
              onCancel={this.handleAddModalClose}
              style={styles.addPermissionsGroupModal}
            >
              <SecurityPermissionAddModal
                closeModal={this.handleAddModalClose}
              />
            </AddFeatureModal>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  userPermission: state.userPermission,
  regionsAccess: state.adminPermissionAccess[REGIONS_TEXT] ?? '',
  regionList: state.assignedRegionList,
});

type PermissionsViewReduxStateProps = ReturnType<typeof mapStateToProps>;
type PermissionsViewOwnProps = {
  history: {
    location: {
      state: {
        region: {
          url: string,
          regionname: string
        }
      },
      member: {
        url: string
      }
    },
    push: (path: string) => void;
  },
  tab: number;
};
export type PermissionsViewProps = PermissionsViewReduxStateProps & PermissionsViewOwnProps;

export default connect(mapStateToProps)(PermissionsView);
