import { ProductLine } from '@trucktrax/trucktrax-ts-common';
import { getPlantList } from '../../../services/plantsService';
import { getRegionByUrl } from '../../../services/regionsService';
import { DropDownListItem, MaybeDefaultRegion } from '../../../types';

export function isValidProductLineForPlants(productLines?: ProductLine[]) {
  return productLines && productLines.length > 0
    && (productLines.includes(ProductLine.Aggregates) || productLines.includes(ProductLine.Cement));
}

export function isDisabledPlants(primaryProductLine?: ProductLine, secondaryProductLines?: ProductLine[]) {
  const hasValidPrimary = isValidProductLineForPlants(primaryProductLine ? [primaryProductLine] : []);
  const hasValidSecondary = isValidProductLineForPlants(secondaryProductLines);
  return !hasValidPrimary && !hasValidSecondary;
}

export async function fetchPlants(
  primaryProductLine: ProductLine,
  secondaryProductLines: ProductLine[],
  regions: MaybeDefaultRegion[],
  secondaryRegions: DropDownListItem[]
) {
  if (isDisabledPlants(primaryProductLine, secondaryProductLines)) {
    return [];
  }

  const allRegionUrls = [
    ...regions.map(r => r.url),
    ...secondaryRegions.map(r => r.key),
  ].filter(r => !!r);

  const allRegions = await Promise.all(allRegionUrls.map(u => getRegionByUrl(u?.toString())));

  const allPlants = await Promise.all(allRegions.map(async region => {
    let plants = [] as DropDownListItem[];

    if (isValidProductLineForPlants([primaryProductLine])) {
      const primaryPlants = await getPlantList(region?.url ?? '', primaryProductLine, false, false);
      plants = [
        ...primaryPlants.map((p: any) => ({
          key: p.url,
          value: p.url,
          label: p.name,
          secondaryLabel: region.name,
        })).sort((p1: any, p2: any) => p1.label.localeCompare(p2.label)),
      ];
    }

    if (isValidProductLineForPlants(secondaryProductLines)) {
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < secondaryProductLines.length; i += 1) {
        const secondaryPlants = await getPlantList(region.url, secondaryProductLines[i], false, false);
        plants = [
          ...plants,
          ...secondaryPlants.map((p: any) => ({
            key: p.url,
            value: p.url,
            label: p.name,
            secondaryLabel: region.name,
          })).sort((p1: any, p2: any) => p1.label.localeCompare(p2.label)),
        ];
      }
    }

    return plants;
  }));

  const filteredPlants = [] as DropDownListItem[];
  allPlants.reduce((a, b) => a.concat(b), []).forEach(p => {
    if (filteredPlants.every(f => f.value !== p.value)) {
      filteredPlants.push(p);
    }
  });

  return filteredPlants;
}
