export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const IS_PASSWORD_TEMPORARY = 'IS_PASSWORD_TEMPORARY';
export const USER_ID = 'USER_ID';
export const USER_NAME = 'USER_NAME';
export const USER_FULL_NAME = 'USER_FULL_NAME';
export const SESSION_ID = 'SESSION_ID';
export const TENANT_ID = 'TENANT_ID';
export const ASSIGNED_REGIONS = 'ASSIGNED_REGIONS';
export const DEFAULT_REGION = 'DEFAULT_REGION';
export const USER_URL = 'USER_URL';
export const MINUTES_TO_IDLE_LOGOUT = 'MINUTES_TO_IDLE_LOGOUT';
export const PRIMARY_PRODUCT_LINE = 'PRIMARY_PRODUCT_LINE';
export const SECONDARY_PRODUCT_LINE = 'SECONDARY_PRODUCT_LINE';
export const AUTH_TENANT_NAME = 'AUTH_TENANT_NAME';
export const FEATURE_FLAG_OBJECT = 'FEATURE_FLAG_OBJECT';
export const FEATURE_FLAG_MAP = 'FEATURE_FLAG_MAP';
export const RECENT_PROJECTS_HISTORY = 'RECENT_PROJECTS_HISTORY';
export const IS_MESSAGE_WINDOW_OPEN = 'IS_MESSAGE_WINDOW_OPEN';
export const OPEN_SIDEBAR_MESSAGES = 'OPEN_SIDEBAR_MESSAGES';
export const USER_OVERRIDE = 'USER_OVERRIDE';
