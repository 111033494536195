import React, { Component, FormEventHandler } from 'react';
import cx from 'classnames';
import { Card } from '@mui/material';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Label, Tooltip } from '@trucktrax/trucktrax-common';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import { Field, reduxForm } from 'redux-form';
import styles from '../login/LoginView.module.css';
import { resetPassword } from '../../services/authService';
import { openSnackbar, SnackbarOptions } from '../../store/actions/snackbarActions';
import {
  SUCCESS,
  LOGO_IMAGE,
  PASSWORD_LOWERCASE,
  PASSWORD_LOWERCASE_TEXT,
  PASSWORD_NUMBER,
  PASSWORD_NUMBER_TEXT,
  PASSWORD_SIZE,
  PASSWORD_SIZE_TEXT,
  PASSWORD_UPPERCASE,
  PASSWORD_UPPERCASE_TEXT,
} from '../../constants/appConstants';
import { InputField } from '../../types';

const NEW_PASSWORD = 'newPassword';
const CONFIRM_PASSWORD = 'confirmPassword';

const noValidationIcon = (<i className={cx('margin-right-10', styles.circle)} />);
const validationErrorIcon = (<i className="icon-cancel margin-right-10" />);
const validationSuccessIcon = (<i className="icon-check-circle margin-right-10" />);

let resetPasswordHasValues = false;

export class ResetPasswordView extends Component<ResetPasswordViewProps, ResetPasswordViewState> {
  static defaultProps = {
    pristine: true,
    submitting: false,
    errorMessage: '',
  };

  constructor(props: ResetPasswordViewProps) {
    super(props);

    this.state = {
      newPasswordVisible: false,
      isCapsOn: false,
      confirmPasswordVisible: false,
    };

    if (!this.props.isPasswordTemporary) {
      this.props.history.push('/login');
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', this.inputListener);
    document.addEventListener('keydown', this.inputListener);
  }

  componentDidUpdate() {
    if (!this.props.authenticated) {
      return;
    }

    this.props.history.push('/welcome');
    this.props.openSnackbar({
      snackbarBody: 'Your password has been successfully updated.',
      dataTest: 'password-reset-success-snackbar',
      snackbarType: SUCCESS,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.inputListener);
    document.removeEventListener('keydown', this.inputListener);
  }

  static noErrorsIn = (errorMessage: any) => !errorMessage || Object.keys(errorMessage).length === 0;

  getValidationClass = (search: string) => {
    const { errorMessage } = this.props;
    if (ResetPasswordView.noErrorsIn(errorMessage)) {
      return '';
    }

    if (errorMessage!.includes(search)) {
      return styles.resetPasswordError;
    }

    return styles.resetPasswordSuccess;
  };

  getValidationIcon = (search: string) => {
    const { errorMessage } = this.props;
    if (ResetPasswordView.noErrorsIn(errorMessage)) {
      return noValidationIcon;
    }

    if (errorMessage!.includes(search)) {
      return validationErrorIcon;
    }

    return validationSuccessIcon;
  };

  inputListener = (event: React.MouseEvent<HTMLInputElement> | KeyboardEvent) => {
    const isCapsOn = event.getModifierState('CapsLock');
    this.setState({ isCapsOn }, () => this.forceUpdate);
  };

  togglePasswordVisibility = (fieldName: string) => {
    if (fieldName === NEW_PASSWORD) {
      this.setState({ newPasswordVisible: !this.state.newPasswordVisible });
    } else {
      this.setState({ confirmPasswordVisible: !this.state.confirmPasswordVisible });
    }
  };

  static errorIfTouched = (touched?: boolean, error?: string): string | undefined => (touched ? error : '');

  renderInputField = (field: InputField) => {
    const { type, meta: { touched, error }, input: { name } } = field;

    const passwordVisible = (name === NEW_PASSWORD)
      ? this.state.newPasswordVisible
      : this.state.confirmPasswordVisible;

    const isCapsOn = name === document!.activeElement!.id;

    const tooltipProps: any = {
      text: 'Caps Lock is on',
      placement: 'left',
      open: (isCapsOn && this.state.isCapsOn),
      theme: 'warning',
      PopperProps: { disablePortal: true },
      disableFocusListener: true,
      disableHoverListener: true,
      disableTouchListener: true,
    };

    const inputField = (
      <Tooltip {...tooltipProps}>
        <input
          id={name}
          className={cx('form-control', 'tt-input', touched && error ? 'tt-input-error' : '')}
          data-test={`${name}-input-field`}
          type={type}
          onMouseDown={this.inputListener}
          {...field.input}
        />
      </Tooltip>
    );

    const errorLabel = (
      <label
        className="tt-label--help"
        data-test={`${name}-required-error`}
        htmlFor={name}
      >
        {ResetPasswordView.errorIfTouched(touched, error)}
      </label>
    );

    const toolTipText = `${passwordVisible ? 'Hide' : 'Show'} password`;

    const iconVisibilityClass = passwordVisible ? 'icon-visibility-off' : 'icon-visibility';

    const passwordField = (
      <div className="tt-password--container">
        <Tooltip text={toolTipText}>
          <button
            type="button"
            className="tt-btn--show-hide"
            tabIndex={-1}
            onClick={() => {
              this.togglePasswordVisibility(name);
            }}
          >
            <i
              aria-hidden="true"
              className={iconVisibilityClass}
            />
          </button>
        </Tooltip>
        {inputField}
        {errorLabel}
      </div>
    );

    return (
      <div className="margin-top-20">
        <Label
          htmlFor={field.name}
          className="margin-top"
        >
          {field.label!}
        </Label>
        {passwordField}
      </div>
    );
  };

  static fieldType = (newPasswordVisible: boolean) => (newPasswordVisible ? 'text' : 'password');

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
    } = this.props;

    return (
      <div className={styles.background}>
        <Card className={styles.card}>
          <img
            className={cx(styles.logo, 'margin-top-30')}
            src={LOGO_IMAGE}
            alt="logo"
          />
          <form
            onSubmit={handleSubmit!(this.props.resetPassword)}
            className={cx(styles.form, styles.resetPassword)}
          >
            <h4>Set Your New Password</h4>
            <h5>Password must:</h5>
            <div>
              <ul>
                <li className={this.getValidationClass(PASSWORD_SIZE)}>
                  {this.getValidationIcon(PASSWORD_SIZE)}
                  <span>{PASSWORD_SIZE_TEXT}</span>
                </li>
                <li className={this.getValidationClass(PASSWORD_UPPERCASE)}>
                  {this.getValidationIcon(PASSWORD_UPPERCASE)}
                  <span>{PASSWORD_UPPERCASE_TEXT}</span>
                </li>
                <li className={this.getValidationClass(PASSWORD_LOWERCASE)}>
                  {this.getValidationIcon(PASSWORD_LOWERCASE)}
                  <span>{PASSWORD_LOWERCASE_TEXT}</span>
                </li>
                <li className={this.getValidationClass(PASSWORD_NUMBER)}>
                  {this.getValidationIcon(PASSWORD_NUMBER)}
                  <span>{PASSWORD_NUMBER_TEXT}</span>
                </li>
              </ul>
            </div>
            <Field
              className={styles.error}
              label="New Password"
              name={NEW_PASSWORD}
              type={ResetPasswordView.fieldType(this.state.newPasswordVisible)}
              isCapsOn={this.state.isCapsOn}
              component={this.renderInputField}
            />
            <Field
              label="Confirm New Password"
              name={CONFIRM_PASSWORD}
              type={ResetPasswordView.fieldType(this.state.confirmPasswordVisible)}
              isCapsOn={this.state.isCapsOn}
              component={this.renderInputField}
            />
            <div className="margin-top-30">
              <Button
                classes={{ root: styles.btnSubmit }}
                type="submit"
                data-test="submit"
                disabled={pristine || submitting || (!resetPasswordHasValues)}
              >
                Submit
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
}

export interface ResetPasswordViewProps {
  handleSubmit?: (func: VoidFunc) => FormEventHandler<HTMLFormElement>,
  openSnackbar: (props: SnackbarOptions) => void,
  resetPassword: VoidFunc,
  errorMessage?: string,
  pristine?: boolean,
  submitting?: boolean,
  authenticated: boolean,
  isPasswordTemporary: boolean,
  history: {
    push: (path: string) => void
  }
}

export interface ResetPasswordViewState {
  newPasswordVisible: boolean,
  isCapsOn: boolean,
  confirmPasswordVisible: boolean
}

interface ResetPasswordValidation {
  newPassword?: string,
  confirmPassword?: string
}

export function validate(values: ResetPasswordValidation) {
  const errors: ResetPasswordValidation = {};

  resetPasswordHasValues = (!!values.newPassword) && (!!values.confirmPassword);

  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'These passwords don\'t match.';
  }

  return errors;
}

export function mapStateToProps(state: any) {
  return {
    errorMessage: state.auth.fieldErrors,
    authenticated: state.auth.authenticated,
    isPasswordTemporary: state.auth.isPasswordTemporary,
  };
}

export const ReduxFormResetPassword = reduxForm<any, any, any>({
  validate,
  form: 'ResetPasswordForm',
})(ResetPasswordView);

export default withRouter(connect(mapStateToProps, {
  resetPassword,
  openSnackbar,
})(ReduxFormResetPassword));
