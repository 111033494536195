import React, { Component } from 'react';
import cx from 'classnames';
import { FloatingActionButton } from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import {
  DriverDto, PartialVehicleTypeDto, ProductLine, TruckDto, UrlKeyDto, VoidFunc,
} from '@trucktrax/trucktrax-ts-common';
import {
  defaultProductLineRadioOptions,
  productLineRadioOptionsNoScaleTrax,
  driverListAdminToDropDownOptions,
  haulerListToMultiSelectOptions,
  onSubmitSuccess,
  plantListForDropdown,
  vehicleTypesToDropDownOptions,
  DropDownOptions,
  vehicleOwnershipTypeRadioOptions
} from '../../../util/adminUtil';
import { createDataTableRecord } from '../../../services/dataTableService';
import { getPlantList } from '../../../services/plantsService';
import AdminAddModal, { AdminConfig } from '../../shared/admin/AdminAddModal';
import { devErrorAndLog } from '../../../util/errorUtil';
import styles from './TrucksAddModal.module.css';
import {
  ADD_TRUCK_ERROR_MESSAGE,
  ERROR_TEXT_FETCH_PLANT_LIST,
  ERROR_TEXT_FETCH_VEHICLE_TYPES,
} from '../../../constants/errorConstants';
import {
  ADD_TRUCK_BUTTON_TEXT,
  ADD_TRUCK_MODAL_TITLE,
  ADMIN_KEYS,
  ADMIN_LABELS,
  CARD_SWITCH,
  DROPDOWN_FORM,
  INPUT_FORM,
  MULTIDROPDOWN_FORM,
  NUMERIC_INPUT_FORM,
  RADIO_FORM,
  GROUPED_STATES_ABBV,
  TEXT_DISPLAY,
  GEOTRAX_LITE_FLAG
} from '../../../constants/appConstants';
import { TRUCKS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ADD_TRUCK_SUCCESS_TEXT } from '../../../constants/successConstants';
import AddFeatureModal from '../../shared/AddFeatureModal';
import { getVehicleTypes } from '../../../services/trucksService';
import { fetchHaulerList } from '../../../services/haulersService';
import { ConnectedDispatchFunction } from '../../../types';
import { ReduxState } from '../../../store';
import HTTP_CODES from '../../../constants/httpConstants';
import { checkNullInRequired } from '../../../util/validation';
import { noop } from '../../../util/appUtil';
import { isFeatureFlagEnabled } from '../../../util/featureFlagUtil';
import { RegionAccessMap } from '../../../util/permissionUtil';

export class TrucksAddModal extends Component<TrucksAddModalProps> {
  static defaultProps = {
  };

  readonly aggregatesType = 0;

  readonly readyMixType = 2;

  state = {
    primaryProductLine: undefined,
    plantList: [],
    vehicleTypes: [],
    shouldDisplayAdditionalFields: false,
    hideVehicleTypes: false,
    isGlinxEnabled: false,
    isFlexSystem: false,
  };

  async componentDidMount() {
    await this.props.fetchHaulerList(undefined, this.props.currentRegion.url, true);
    await this.setVehicleTypes();
  }

  async componentDidUpdate(prevProps: any) {
    if (!prevProps.isOpen && this.props.isOpen) {
      const vehicleTypesOptions = vehicleTypesToDropDownOptions(this.state.vehicleTypes || [], this.props.currentRegion);
      const primaryProductLine = (TrucksAddModal.regionHasVehicleTypes(vehicleTypesOptions))
        ? defaultProductLineRadioOptions[this.aggregatesType].value
        : productLineRadioOptionsNoScaleTrax[this.readyMixType].value;
      await this.recordChanged({
        primaryProductLine,
      });
      // reset toggles on modal open
      this.setState({ isGlinxEnabled: false, isFlexSystem: false });
    }
    if (prevProps.currentRegion !== this.props.currentRegion) {
      this.props.fetchHaulerList(undefined, this.props.currentRegion.url, true);
    }
  }

  onSubmit = (dto: any, onError: any, onSuccess: any) => {
    const { createAdminTableData: post } = this.props;
    const toastMessages = {
      success: ADD_TRUCK_SUCCESS_TEXT,
      fail: ADD_TRUCK_ERROR_MESSAGE,
    };

    const url = getGeotraxBaseUrl() + TRUCKS_PATH;
    const currentRegionUrl = this.props.currentRegion.url;

    let truckDto = {
      ...dto,
      externalId: dto.externalId?.trim() ?? null,
      secondaryProductLine: 'None',
      region: { url: this.props.currentRegion.url },
      isGlinxEnabled: this.state.isGlinxEnabled,
      isFlexSystem: this.state.isFlexSystem,
      // maps the 'none' field from a null to a string, as the truck entity cannot have a null url value
      plant: dto.plant && dto.plant.url === null ? '' : dto.plant,
      defaultDriver: dto.defaultDriver && dto.defaultDriver.url === null ? '' : dto.defaultDriver,
    };

    const vehicleTypeId = dto.vehicleType?.id;
    truckDto.vehicleType = vehicleTypeId
      ? this.state.vehicleTypes.find((vehicleType: PartialVehicleTypeDto) => vehicleType.id === vehicleTypeId)
      : null;

    truckDto = TrucksAddModal.updateHaulers(truckDto);

    // set externalId empty string to null.
    truckDto.externalId = truckDto.externalId === '' ? null : truckDto.externalId;
    const callbacks = [this.props.discardAcceptAction, onSuccess];
    post(url, truckDto, () => onSubmitSuccess(callbacks), onError, toastMessages, true, currentRegionUrl);
  };

  async setPlantList(primaryProductLine: any) {
    // call again, it's possible that we have changed region while still on the trucks list which is where TrucksAddModal has its state
    if (primaryProductLine !== this.state.primaryProductLine) {
      const { currentRegion } = this.props;
      try {
        const plantList = await getPlantList(currentRegion.url, primaryProductLine);
        this.setState({
          plantList,
          primaryProductLine,
        });
      } catch (e: any) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        this.props.devErrorAndLog(ERROR_TEXT_FETCH_PLANT_LIST, `TrucksAddModal ${e.toString()}`, undefined, undefined, consoleOnly);
      }
    }
  }

  async setVehicleTypes() {
    try {
      const vehicleTypes = await getVehicleTypes();
      this.setState({ vehicleTypes });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      this.props.devErrorAndLog(ERROR_TEXT_FETCH_VEHICLE_TYPES, `TrucksAddModal ${e.toString()}`, undefined, undefined, consoleOnly);
    }
  }

  static shouldDisplayAdditionalFields = (dto?: { primaryProductLine?: ProductLine }) => (
    dto?.primaryProductLine === ProductLine.Aggregates
    || dto?.primaryProductLine === ProductLine.Cement
  );

  recordChanged = async (updatedDto: any) => {
    updatedDto.vehicleType = updatedDto.primaryProductLine !== ProductLine.ReadyMix ? updatedDto.vehicleType : null;
    const hideVehicleTypes = updatedDto.primaryProductLine && updatedDto.primaryProductLine === ProductLine.ReadyMix;

    await this.setPlantList(updatedDto.primaryProductLine);
    await this.setVehicleTypes();
    const shouldDisplayAdditionalFields = TrucksAddModal.shouldDisplayAdditionalFields(updatedDto);

    this.setState({
      shouldDisplayAdditionalFields,
      hideVehicleTypes,
    });
  };

  static updateHaulers = (dto: any) => {
    if (!dto.haulers) {
      return dto;
    }
    dto.haulers = (dto.haulers ?? []).map((p: any) => ({ url: p.key ?? p.url }));
    return dto;
  };

  handleSwitchChange = (key: string) => (event: any) => {
    this.setState({ [key]: event[key] });
    // Automatically reset the flex system when glinx flag is disabled
    if (key === ADMIN_KEYS.IS_GLINX_ENABLED && !event[key]) {
      this.setState({ isFlexSystem: false });
    }
  };

  static regionHasVehicleTypes = (vehicleTypesDropDownOptions: DropDownOptions[]) => (
    vehicleTypesDropDownOptions && vehicleTypesDropDownOptions.length > 0
  );

  config = () => {
    const driversDropdownOptions = driverListAdminToDropDownOptions(
      this.props.drivers,
      this.state.primaryProductLine ?? ProductLine.Aggregates
    );
    const plantsDropdownOptions = plantListForDropdown(this.state.plantList || []);
    const vehicleTypesDropDownOptions = vehicleTypesToDropDownOptions(this.state.vehicleTypes || [], this.props.currentRegion);
    const { isGlinxEnabled, isFlexSystem } = this.state;

    const addConfig: AdminConfig[] = [
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.EXTERNAL_ID,
        label: ADMIN_LABELS.EXTERNAL_ID,
        maxLength: 50,
        dataTest: 'externalId-input-data-test',
        errorDataTest: 'externalId-input-missing-error',
        className: cx('widthSpace', 'baseMargin'),
        isRequired: false,
      }, {
        type: INPUT_FORM,
        key: ADMIN_KEYS.TRUCK_ALIAS,
        label: ADMIN_LABELS.VEHICLE_ALIAS,
        dataTest: 'truckAlias-input-data-test',
        errorDataTest: 'truckAlias-input-missing-error',
        className: 'halfWidthSpace',
        maxLength: 20,
        customValidation: checkNullInRequired,
        isRequired: true,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.LICENSE_PLATE,
        label: ADMIN_LABELS.LICENSE_PLATE,
        dataTest: 'licensePlate-input-data-test',
        errorDataTest: 'licensePlate-input-missing-error',
        className: styles.licensePlate,
        maxLength: 20,
      },
      {
        type: DROPDOWN_FORM,
        key: ADMIN_KEYS.LICENSE_STATE,
        label: ADMIN_LABELS.LICENSE_STATE,
        className: styles.licenseState,
        items: GROUPED_STATES_ABBV,
        initialSelected: { name: '' },
        dataTest: 'licensestate-input-data-test',
      }];

    const isGeotraxLiteEnabled = isFeatureFlagEnabled(
      this.props.userPermission,
      this.props.currentRegion?.url ?? '',
      GEOTRAX_LITE_FLAG
    );

    if (isGeotraxLiteEnabled) {
      addConfig.unshift({
        type: RADIO_FORM,
        key: ADMIN_KEYS.IS_EXTERNAL,
        label: ADMIN_LABELS.OWNERSHIP,
        radioButtonGroupName: ADMIN_KEYS.IS_EXTERNAL,
        className: styles.ownership,
        radioButtons: vehicleOwnershipTypeRadioOptions,
        initialSelected: vehicleOwnershipTypeRadioOptions[0],
        isRequired: true
      });
    }

    if (!TrucksAddModal.regionHasVehicleTypes(vehicleTypesDropDownOptions)) {
      addConfig.push({
        type: TEXT_DISPLAY,
        key: ADMIN_KEYS.PRODUCT_LINE_WARNING,
        label: 'A Vehicle Type must first be created to select the Aggregate or Cement Product Line.',
        className: cx('widthSpace', styles.productLineError),
      });
    }

    addConfig.push({
      type: RADIO_FORM,
      key: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
      label: ADMIN_LABELS.PRODUCT_LINE,
      radioButtonGroupName: ADMIN_KEYS.PRIMARY_PRODUCT_LINE,
      className: 'widthSpace',
      radioButtons: TrucksAddModal.regionHasVehicleTypes(vehicleTypesDropDownOptions)
        ? defaultProductLineRadioOptions : productLineRadioOptionsNoScaleTrax,
      initialSelected: TrucksAddModal.regionHasVehicleTypes(vehicleTypesDropDownOptions)
        ? defaultProductLineRadioOptions[this.aggregatesType] : productLineRadioOptionsNoScaleTrax[this.readyMixType],
    });

    if (!this.state.hideVehicleTypes) {
      addConfig.push({
        type: DROPDOWN_FORM,
        className: cx('baseDropDown', 'bottomWidthSpace'),
        dataTest: 'vehicleType-input-data-test',
        customValidation: checkNullInRequired,
        key: ADMIN_KEYS.VEHICLE_TYPE,
        label: ADMIN_LABELS.VEHICLE_TYPE,
        initialSelected: { label: 'Select a Vehicle Type...' },
        items: vehicleTypesDropDownOptions,
        isRequired: true,
        alwaysShowLabel: true,
      });
    }

    addConfig.push({
      type: DROPDOWN_FORM,
      key: ADMIN_KEYS.PLANT,
      label: ADMIN_LABELS.HOME_PLANT,
      className: 'baseDropDown',
      items: plantsDropdownOptions,
      allowEmpty: true,
      initialSelected: { label: 'Select a Plant...' },
      dataTest: 'homePlant-input-data-test',
    });

    if (this.state.shouldDisplayAdditionalFields) {
      addConfig.push({
        type: MULTIDROPDOWN_FORM,
        key: ADMIN_KEYS.HAULERS,
        label: ADMIN_LABELS.HAULER,
        className: cx('baseDropDown', 'bottomWidthSpace'),
        items: haulerListToMultiSelectOptions(this.props.haulersList),
        initialSelected: { value: [] },
        placeholder: 'Select One or Multiple Haulers...',
        dataTest: 'hauler-input-data-test',
      });
    }

    addConfig.push({
      type: DROPDOWN_FORM,
      key: ADMIN_KEYS.DEFAULT_DRIVER,
      label: ADMIN_LABELS.DEFAULT_DRIVER,
      className: cx('baseDropDown', 'bottomWidthSpace'),
      initialSelected: { name: 'Select a Driver...' },
      items: driversDropdownOptions,
      dataTest: 'defaultRegion-input-data-test',
    });

    if (this.state.shouldDisplayAdditionalFields) {
      addConfig.push({
        type: NUMERIC_INPUT_FORM,
        accessor: (row?: TruckDto) => (row?.scaleExpirationDays ?? 100),
        className: styles.tareExpirationDays,
        initialSelected: { value: 100 },
        dataTest: 'trucks-drilldown-scaleExpirationDays',
        key: ADMIN_KEYS.SCALE_EXPIRATION_DAYS,
        errorDataTest: 'scaleExpirationDays-input-missing-error',
        hideCharacterCount: true,
        label: (
          <span className={styles.label}>
            {ADMIN_LABELS.SCALE_EXPIRATION_DAYS}
            <span className={styles.asterisk}> *</span>
          </span>
        ),
        increment: {
          showIncrementButtons: true,
          incrementStep: 1,
          minValue: 0,
          maxValue: 300,
        },
      });
    } else {
      addConfig.push({
        id: ADMIN_KEYS.IS_GLINX_ENABLED,
        type: CARD_SWITCH,
        key: ADMIN_KEYS.IS_GLINX_ENABLED,
        label: ADMIN_LABELS.GLINX_ENABLED,
        checked: isGlinxEnabled,
        display: true,
        editing: true,
        onChange: this.handleSwitchChange(ADMIN_KEYS.IS_GLINX_ENABLED)
      });
      // Flex System is only relevant if the Glinx is enabled
      if (isGlinxEnabled) {
        addConfig.push({
          id: ADMIN_KEYS.IS_FLEX_SYSTEM,
          type: CARD_SWITCH,
          key: ADMIN_KEYS.IS_FLEX_SYSTEM,
          label: ADMIN_LABELS.FLEX_SYSTEM,
          checked: isFlexSystem,
          display: true,
          editing: true,
          onChange: this.handleSwitchChange(ADMIN_KEYS.IS_FLEX_SYSTEM)
        });
      }
    }

    return addConfig;
  };

  render() {
    return (
      <div>
        <FloatingActionButton onClick={this.props.openModal} />
        <AddFeatureModal
          title={ADD_TRUCK_MODAL_TITLE}
          isOpen={this.props.isOpen}
          onCancel={this.props.closeModal}
          style={styles.trucksAddModal}
        >
          <AdminAddModal
            config={this.config()}
            addButtonText={ADD_TRUCK_BUTTON_TEXT}
            addButtonDataTest="trucks-add-button-data-test"
            onChangeHandler={this.recordChanged}
            onRightBtnClick={this.onSubmit}
            discardAcceptAction={this.props.discardAcceptAction}
            discardRejectAction={this.props.discardRejectAction}
            isCloseRequest={this.props.isCloseRequest}
          />
        </AddFeatureModal>
      </div>
    );
  }
}

export function mapStateToProps(state: ReduxState) {
  return {
    currentRegion: state.currentRegion ?? { url: '' },
    haulersList: state.haulersList?.haulers,
    createAdminTableData: createDataTableRecord,
    devErrorAndLog,
    userPermission: state.userPermission || {},
  };
}

type TrucksAddModalReduxProps = ReturnType<typeof mapStateToProps>;

type TrucksAddModalDispatchProps = {
  fetchHaulerList: ConnectedDispatchFunction<typeof fetchHaulerList>;
};

type TrucksAddModalOwnProps = {
  createAdminTableData: typeof createDataTableRecord,
  devErrorAndLog: typeof devErrorAndLog,
  discardAcceptAction: typeof noop,
  discardRejectAction: typeof noop,
  closeModal: VoidFunc,
  openModal: VoidFunc,
  drivers: DriverDto[],
  isCloseRequest: boolean,
  isOpen: boolean,
  currentRegion: UrlKeyDto,
  userPermission: RegionAccessMap,
};

export type TrucksAddModalProps = TrucksAddModalReduxProps & TrucksAddModalDispatchProps & TrucksAddModalOwnProps;

export default connect(mapStateToProps, {
  currentRegion: { url: '' },
  fetchHaulerList,
  createAdminTableData: createDataTableRecord,
  devErrorAndLog,
})(TrucksAddModal);
