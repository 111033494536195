import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  FolderDropdown,
  FolderItem,
  FolderList,
} from '@trucktrax/trucktrax-common';
import { ProductLine, RegionDto } from '@trucktrax/trucktrax-ts-common';
import styles from './MapPanelContent.module.css';
import {
  mapAggTrucksForFolderList,
  mapCementTrucksForFolderList,
  mapReadyMixTrucksForFolderList,
  retrieveProductLineNameDiff,
} from '../../../util/statusesUtil';
import { fetchCurrentTicketList, selectTruckMarker } from '../../../services/ticketsService';
import { setReduxDriverList } from '../../../services/driversService';
import { clearSelectedTicket, resetTicketView } from '../../../store/actions/ticketActions';
import { fetchPlantList } from '../../../services/plantsService';
import { getMapview } from '../../../services/mapviewsService';
import {
  AGGREGATE_STRING,
  CEMENT_STRING,
  READYMIX_VALUE,
} from '../../../constants/appConstants';
import { messageSocketInit, getInboxMessages } from '../../../services/messagesService';
import { updateToFieldValue } from '../../../store/actions/messageActions';
import { TruckInfo } from '../../../types';
import { refreshGeoTraxState } from '../../../services/appService';
import ProductLineAndRegionManager from './ProductLineAndRegionManager';

type MapListFunction = typeof mapReadyMixTrucksForFolderList;
export type FolderListType = ReturnType<MapListFunction>;

export class MapPanelContent extends Component<MapPanelContentProps> {
  static defaultProps: Partial<MapPanelContentProps> = {
    activeTrucks: [],
    selectedMarker: undefined,
    selectedProductLine: ProductLine.None,
  };

  state = {};

  onItemClick = (item: FolderItem) => this.props.selectTruckMarker(item.data, item.data.url, item.name);

  renderTruckStatusDropdown = () => {
    const selectedItemId = this.props.selectedMarker && this.props.selectedMarker.markerId;
    const statusNameMap = retrieveProductLineNameDiff(this.props.selectedProductLine);

    let folderList: FolderListType;
    switch (this.props.selectedProductLine) {
      case READYMIX_VALUE:
        folderList = mapReadyMixTrucksForFolderList(this.props.activeTrucks);
        break;
      case CEMENT_STRING:
        folderList = mapCementTrucksForFolderList(this.props.activeTrucks);
        break;
      case AGGREGATE_STRING:
        folderList = mapAggTrucksForFolderList(this.props.activeTrucks);
        break;
      default:
        folderList = [];
    }

    this.expandTruckFolder(selectedItemId!, folderList, statusNameMap);

    return (
      <div className={styles.mapPanelLowerContent}>
        <FolderDropdown
          dataTestName="leftnav-trucks-dropdown-menu"
          dataTestCount="leftnav-trucks-counter"
          classes={styles.folder}
          isOpen={this.props.dropdownIsOpen}
          name="Trucks"
          count={this.props.activeTrucks.length}
          onClick={this.props.toggleDropdownIsOpen}
        >
          <FolderList
            folderList={folderList}
            onFolderClick={this.props.setOpenStateMap}
            onItemClick={this.onItemClick}
            selectedItemId={selectedItemId}
            isDarkTheme
            openStateMap={this.props.openStateMap}
          />
        </FolderDropdown>
      </div>
    );
  };

  expandTruckFolder(selectedItemId: string, folderList: FolderListType, statusNameMap: { [name: string]: string }) {
    if (!selectedItemId) {
      return;
    }

    try {
      const truck = this.props.activeTrucks.find(t => t.id === selectedItemId)!;
      const truckStatus = truck.data.status;
      const selectedStatusIdx = folderList.findIndex(f => statusNameMap[f.id] === statusNameMap[truck.data.status!]);
      const openStateMapCopy = { ...this.props.openStateMap };
      if (truckStatus) {
        openStateMapCopy[selectedStatusIdx] = true; // make copy and send copy instead of inplace replacement
      } else {
        openStateMapCopy[0] = true;
      }
      this.props.setOpenStateMap(openStateMapCopy);
    } catch (ex) {
      // swallow the exception
    }
  }

  // Temporarily disabled with card https://trucktrax.atlassian.net/browse/GTX-6655
  static hasDownloadReportPermission = () => false;
  // check if user has admin access to Reports in currently selected Region
  // True -> Display download button
  // False -> No download button

  render() {
    return (
      <div className={styles.mapPanelContent}>
        <ProductLineAndRegionManager
          regions={this.props.regions}
          onRegionChanged={this.props.onRegionChange}
        />
        {MapPanelContent.hasDownloadReportPermission()}
        {this.renderTruckStatusDropdown()}
      </div>
    );
  }
}

type OpenStateMap = { [index: number]: boolean };
type Marker = {
  markerId: string;
  lat: number;
  lng: number;
  isActive: boolean;
  status: string;
};

export interface MapPanelContentProps {
  activeTrucks: TruckInfo[];
  dropdownIsOpen: boolean;
  regions: RegionDto[];
  openStateMap: OpenStateMap;
  selectedMarker?: Marker;
  onRegionChange: (regionUrl: string) => void;
  selectTruckMarker: (
    data: any,
    url: string,
    name: string
  ) => void;
  setOpenStateMap: (map: OpenStateMap) => void;
  toggleDropdownIsOpen: () => void;
  selectedProductLine: ProductLine;
}

function mapStateToProps(state: any) {
  return {
    selectedProductLine: state.selectedProductLine,
    messageSubscription: state.messageSubscription,
    userUrl: state.userUrl ?? undefined,
  };
}

export default connect<any, any, any>(mapStateToProps, {
  selectTruckMarker,
  fetchPlantList,
  getMapview,
  fetchCurrentTicketList,
  setReduxDriverList,
  clearSelectedTicket,
  resetTicketView,
  messageSocketInit,
  getInboxMessages,
  updateToFieldValue,
  refreshGeoTraxState,
})(MapPanelContent);
