import React, { useState } from 'react';
import cx from 'classnames';
import { Button } from '@trucktrax/trucktrax-common';
import styles from './StatusButton.module.css';

export function StatusButton(props: StatusButtonProps) {
  const {
    name = '',
    dataTest = '',
    onClick,
    disabled = false,
  } = props;

  const style = !disabled ? cx(styles.buttonClass, 'tt-btn-basic') : cx(styles.buttonDisabled, 'tt-btn-basic');

  const [cursor, setCursor] = useState('pointer');

  const handleMouseEnter = () => {
    if (!disabled) return;
    setCursor('not-allowed');
  };

  const handleMouseLeave = () => {
    if (!disabled) return;
    setCursor('pointer');
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ cursor }}
    >
      <Button
        buttonClassName={style}
        name={name}
        dataTest={dataTest}
        onClick={onClick}
        disabled={disabled}
      />
    </div>
  );
}

export interface StatusButtonProps {
  name?: string;
  dataTest?: string;
  onClick: VoidFunction;
  disabled?: boolean;
}

export default StatusButton;
