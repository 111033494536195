import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PermissionAccess } from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import { HAULERS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import AdminCard from '../../shared/admin/AdminCard';
import { TRUCKS_TEXT } from '../../../constants/navConstants';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  COMPOSITE,
  DROPDOWN_FORM,
  EDIT_BTN,
  INPUT_FORM,
  NUMERIC_INPUT_FORM,
  SAVE_BTN,
  STATE_LIST,
  TEXT_DISPLAY,
} from '../../../constants/appConstants';
import styles from './HaulerDetailView.module.css';
import {
  getAddressPartOne,
  getAddressPartTwo,
  itemsToLabelValue,
} from '../../../util/adminUtil';
import { updateDataTableRecord } from '../../../services/dataTableService';
import {
  closeModal,
  openModal,
} from '../../../store/actions/errorModalActions';
import { HasAddress } from '../../../types';

export interface HaulerDetailViewState {
  edit: boolean,
  errorObject: {
    error: boolean,
    errorMessage: string
  }
}

export class HaulerDetailView extends Component<HaulerDetailViewProps, HaulerDetailViewState> {
  state = {
    edit: false,
    errorObject: {
      error: false,
      errorMessage: '',
    },
  };

  save = (dto: any, onSuccess: any, onError: any) => {
    const currentRegionUrl = this.props.currentRegion.url;

    const onSaveSuccess = (updatedDto: any) => {
      this.setState({
        edit: false,
      });
      onSuccess(updatedDto);
      this.props.closeModal();
    };

    this.props.updateAdminTableData(this.baseUrl(), dto, onSaveSuccess, onError, false, currentRegionUrl);
  };

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  static addressAccessorPartOne = (item: HasAddress) => (`${getAddressPartOne(item)}`);

  static addressAccessorPartTwo = (item: HasAddress) => (`${getAddressPartTwo(item)}`);

  baseUrl = () => `${getGeotraxBaseUrl() + HAULERS_PATH}/${this.props.match.params.id}`;

  render() {
    const { location } = this.props;

    const readConfigWithoutHaulerEditPermission = [
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.HAULER_NAME,
        accessor: ADMIN_KEYS.NAME,
        className: cx(styles.haulerName, 'large'),
        dataTest: 'haulers-drilldown-name',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.EXTERNAL_ID,
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        className: styles.externalId,
        dataTest: 'haulers-drilldown-id',
      },
      {
        type: TEXT_DISPLAY,
        label: ADMIN_LABELS.ADDRESS,
        accessor: HaulerDetailView.addressAccessorPartOne,
        dataTest: 'haulers-drilldown-address-part-one',
      },
      {
        type: TEXT_DISPLAY,
        className: styles.address,
        accessor: HaulerDetailView.addressAccessorPartTwo,
        dataTest: 'haulers-drilldown-address-part-two',
      }];

    const readConfigWithHaulerEditPermission = [
      ...readConfigWithoutHaulerEditPermission,
      {
        type: EDIT_BTN,
        name: 'Edit Hauler',
        iconClassName: 'icon-create',
        dataTest: 'haulers-drilldown-edit',
      },
    ];

    const dropDownStates = STATE_LIST.map(itemsToLabelValue);

    const editConfig = [
      {
        type: INPUT_FORM,
        accessor: ADMIN_KEYS.NAME,
        className: styles.haulerName,
        dataTest: 'haulers-drilldown-name',
        key: ADMIN_KEYS.NAME,
        label: ADMIN_LABELS.HAULER_NAME,
        maxLength: 255,
        errorDataTest: 'name-input-missing-error',
        isRequired: true,
      },
      {
        type: INPUT_FORM,
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        className: cx(styles.externalId, 'large'),
        dataTest: 'haulers-drilldown-id',
        key: ADMIN_KEYS.EXTERNAL_ID,
        label: ADMIN_LABELS.EXTERNAL_ID,
        maxLength: 50,
        errorDataTest: 'externalId-input-missing-error',
        isRequired: true,
      },
      {
        type: COMPOSITE,
        className: styles.addressGroup,
        components: [
          {
            type: INPUT_FORM,
            key: ADMIN_KEYS.ADDRESS_LINE_ONE,
            accessor: ADMIN_KEYS.ADDRESS_LINE_ONE,
            label: ADMIN_LABELS.ADDRESS_LINE_ONE,
            dataTest: 'addressLineOne-input-data-test',
            errorDataTest: 'addressLineOne-input-missing-error',
            className: cx(styles.addressLineOne),
            isRequired: false,
            maxLength: 255,
          },
          {
            type: INPUT_FORM,
            key: ADMIN_KEYS.ADDRESS_LINE_TWO,
            accessor: ADMIN_KEYS.ADDRESS_LINE_TWO,
            label: ADMIN_LABELS.ADDRESS_LINE_TWO,
            dataTest: 'addressLineTwo-input-data-test',
            errorDataTest: 'addressLineTwo-input-missing-error',
            className: cx(styles.addressLineTwo),
            isRequired: false,
            maxLength: 255,
          },
          {
            type: INPUT_FORM,
            key: ADMIN_KEYS.CITY,
            label: ADMIN_LABELS.CITY,
            accessor: ADMIN_KEYS.CITY,
            dataTest: 'city-input-data-test',
            errorDataTest: 'city-input-missing-error',
            className: styles.city,
            isRequired: false,
            maxLength: 255,
          },
          {
            type: DROPDOWN_FORM,
            key: ADMIN_KEYS.STATE,
            label: ADMIN_LABELS.STATE,
            accessor: ADMIN_KEYS.STATE,
            className: styles.state,
            items: dropDownStates,
            initialSelected: dropDownStates[0],
            isRequired: false,
            dataTest: 'region-input-data-test',
          },
          {
            type: NUMERIC_INPUT_FORM,
            key: ADMIN_KEYS.ZIP_CODE,
            label: ADMIN_LABELS.ZIP_CODE,
            accessor: ADMIN_KEYS.ZIP_CODE,
            maxLength: 10,
            dataTest: 'zipCode-input-data-test',
            errorDataTest: 'zipCode-input-missing-error',
            className: styles.zip,
            isRequired: false,
          }],
      },
      {
        type: SAVE_BTN,
      },
    ];

    let configValue;
    if (this.props.haulersPermissionAccess === PermissionAccess.Edit) {
      if (this.state.edit) {
        configValue = editConfig;
      } else {
        configValue = readConfigWithHaulerEditPermission;
      }
    } else {
      configValue = readConfigWithoutHaulerEditPermission;
    }

    return (
      <AdminCard
        edit={this.state.edit}
        url={this.baseUrl()}
        className={cx(
          styles.wrapper,
          this.state.edit && styles.editWrapper
        )}
        onToggleEdit={this.toggleEdit}
        save={this.save}
        config={configValue}
        errorObject={this.state.errorObject}
        pathName={location.pathname}
        headerAccessor="name"
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  haulersPermissionAccess: state.adminPermissionAccess[TRUCKS_TEXT],
  currentRegion: state.currentRegion,
});

interface CurrentRegion {
  url: string;
}

interface Location {
  pathname: string;
}

interface Match {
  params: { id: string };
}

export interface HaulerDetailViewProps {
  updateAdminTableData: typeof updateDataTableRecord,
  closeModal: typeof closeModal,
  haulersPermissionAccess?: string,
  match: Match,
  currentRegion: CurrentRegion,
  location: Location
}

export default connect<any, any, any>(mapStateToProps, {
  updateAdminTableData: updateDataTableRecord,
  openModal,
  closeModal,
})(HaulerDetailView);
