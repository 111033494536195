import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, SidebarSelection } from '@trucktrax/trucktrax-common';
import { TicketDto, VoidFunc } from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import styles from './TicketsPopover.module.css';
import {
  clearSelectedTicket,
  resetPastTicketDates,
  resetTicketView,
  updateTicketTimeline,
  isOpenTicket,
  needToRefreshData,
} from '../../../store/actions/ticketActions';
import setSidebarSelected from '../../../store/actions/sidebarActions';
import { HISTORY_ROUTE, MESSAGE_SELECTED, TICKETS_SELECTED } from '../../../constants/appConstants';
import { SidebarTitleType, TicketStateSwitcherType } from '../../../types';
import { ReduxState } from '../../../store';
import { AppName } from '../../../store/reducers/appReducer';
import Ticket from '../../shared/tickets/details/Ticket';
import TicketList from '../../shared/tickets/TicketList';
import geotraxPageSelected from '../../../store/actions/geotraxActions';
import { NavItem } from '../../../constants/navConstants';

export class TicketsPopover extends Component<TicketsPopoverProps, TicketsPopoverState> {
  static defaultProps = {
    selectedTicket: {},
    selected: null,
    isFromOpenTicket: false,
    setSidebarSelected: () => { },
  };

  constructor(props: TicketsPopoverProps) {
    super(props);
    this.state = {
      isExpanded: false,
      isTicketsOpen: false,
    };
  }

  componentDidMount() {
    this.updateTicketsOpen();
  }

  componentDidUpdate() {
    this.updateTicketsOpen();
  }

  updateTicketsOpen = () => {
    const isTicketsSidebar = this.props.sidebarSelection?.sidebarTitle === TICKETS_SELECTED;
    const ticketsSidebarHasChanged = isTicketsSidebar && this.props.sidebarSelection.isSelected !== this.state.isTicketsOpen;

    if (ticketsSidebarHasChanged) {
      this.setState({
        isTicketsOpen: this.props.sidebarSelection.isSelected,
      });
    }
  };

  closePopover = () => {
    this.props.setSidebarSelected!(TICKETS_SELECTED, false);
    this.props.resetPastTicketDates();
    this.props.resetTicketView();
    this.props.updateTicketTimeline('current');
    this.props.isOpenTicket(false);

    // allows smooth animation before closing expanded mode
    global.setTimeout(() => {
      this.props.clearSelectedTicket();
      this.setState({ isExpanded: false });
    }, 500);
  };

  handleClosePopover = () => {
    this.props.needToRefreshData(false);
    this.closePopover();
  };

  openTicketList = () => {
    this.props.clearSelectedTicket();
    this.props.resetTicketView();
  };

  expandClick = () => {
    const { isExpanded } = this.state;

    if (!isExpanded) {
      this.props.setSidebarSelected!(MESSAGE_SELECTED, false);
    }

    this.setState({ isExpanded: !isExpanded });
  };

  shrinkPopover = () => {
    const { isExpanded } = this.state;
    if (isExpanded) {
      this.expandClick();
    }
  };

  outerContainerClickHandler = (event: any) => {
    const targetIsExpandedOuterContainer = Array.from(event.target.classList).includes(styles.ticketsPopoverContainerExpanded);

    if (targetIsExpandedOuterContainer) {
      this.closePopover();
    }
  };

  leftHeader = () => {
    const { isFromOpenTicket } = this.props;
    return (
      !isFromOpenTicket ? <i
        aria-hidden="true"
        className={cx(
          styles.isSelected,
          'icon-arrow-back',
          'icon-small'
        )}
      />
        : ''
    );
  };

  render() {
    const { selectedTicket } = this.props;
    const { isExpanded, isTicketsOpen } = this.state;

    if (!isTicketsOpen) {
      return null;
    }

    const selectedTicketNumber = selectedTicket?.ticketNumber ? `TICKET  #${selectedTicket.ticketNumber}` : '';
    const isSelected = !!selectedTicket?.ticketNumber;

    let renderItem = null;
    if (selectedTicket?.id) {
      renderItem = <Ticket
        selectedTicket={selectedTicket}
        shrinkPopover={this.shrinkPopover}
        history={this.props.history}
        closePopover={this.closePopover}
      />;
    } else {
      renderItem = (
        <TicketList ticketStateSwitcherType={TicketStateSwitcherType.Tabs} />
      );
    }

    const isHistoryRoutePage = this.props.geotraxPage?.navRoute?.includes(HISTORY_ROUTE);

    let containerStyle = styles.ticketsPopoverContainer;
    if (isHistoryRoutePage) {
      containerStyle = styles.ticketsPopoverContainerRouteHistory;
    }
    if (isExpanded) {
      containerStyle = styles.ticketsPopoverContainerExpanded;
    }

    const useFullWidthBlock = this.props.currentAppName === 'scaletrax';
    const fullWidthClass = useFullWidthBlock ? styles.fullTicketBlockWidth : undefined;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className={cx(containerStyle, fullWidthClass)} onClick={this.outerContainerClickHandler}>
        <div
          id="innerDiv"
          className={cx(styles.ticketsPopover, {
            [styles.expandedPopover]: isExpanded,
          })}
        >
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <span
                onClick={this.openTicketList}
                data-test="datablock-breadcrumb-link"
                role="button"
                aria-hidden
              >
                {isSelected ? (
                  this.leftHeader()
                ) : (<span className={styles.isNotSelected}>{this.props.isFromOpenTicket ? '' : 'TICKETS'}</span>)}
              </span>
              <span className={cx(styles.ticketNumber)}>
                {selectedTicketNumber}
              </span>
            </div>
            <div>
              <Button
                onClick={this.expandClick}
                buttonClassName={cx(
                  styles['datablock-info-close'],
                  'tt-btn-basic'
                )}
                iconClassName={cx(
                  {
                    'icon-expand': !isExpanded,
                    'icon-collapse': isExpanded,
                  },
                  'icon-white'
                )}
                dataTest="dataBlock-expand-button"
              />
              <Button
                onClick={this.handleClosePopover}
                buttonClassName={cx(
                  styles['datablock-info-close'],
                  'tt-btn-basic'
                )}
                iconClassName={cx('icon-close', 'icon-white')}
                dataTest="dataBlock-close-button"
              />
            </div>
          </div>
          {renderItem}
        </div>
      </div>
    );
  }
}

export interface TicketsPopoverProps {
  setSidebarSelected?: (sidebarTitle: SidebarTitleType, isSelected: boolean) => void,
  clearSelectedTicket: VoidFunc,
  selectedTicket?: TicketDto,
  sidebarSelection?: SidebarSelection,
  updateTicketTimeline: (timeline: string) => void,
  resetPastTicketDates: VoidFunc,
  currentAppName?: AppName,
  resetTicketView: VoidFunc,
  history: {
    push: (path: string) => void
  };
  geotraxPage: NavItem,
  isOpenTicket: (isOpenTicket: boolean) => void,
  isFromOpenTicket: boolean,
  needToRefreshData: (needToRefreshData: boolean) => void,
}

export interface TicketsPopoverState {
  isExpanded: boolean,
  isTicketsOpen: boolean,
}

export default connect<any, any, any>((state: Partial<ReduxState>) => ({
  selectedTicket: state.selectedTicket,
  sidebarSelection: state.sidebar,
  currentAppName: state.currentAppName,
  geotraxPage: state.geotraxPage,
  isFromOpenTicket: state.isOpenTicket,
}), {
  setSidebarSelected,
  geotraxPageSelected,
  clearSelectedTicket,
  updateTicketTimeline,
  resetPastTicketDates,
  resetTicketView,
  isOpenTicket,
  needToRefreshData,
})(TicketsPopover as any);
