import {
  Checkbox, FormControl, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import React from 'react';
import { Label } from '@trucktrax/trucktrax-common';
import { ProductLine } from '@trucktrax/trucktrax-ts-common';
import { productLineOptions } from '../../../util/adminUtil';
import styles from './ProductLines.module.css';

const ProductLines: React.FC<ProductLinesProps> = ({
  errorMessage,
  primaryProductLine,
  secondaryProductLines,
  onPrimaryProductLineChange,
  onSecondaryProductLineChange,
}) => (
  <div>
    <FormControl className={styles.productLines}>
      <Label isRequired>Product Lines</Label>
      <TableContainer className={errorMessage ? styles.errorMessage : ''}>
        <Table>
          <TableHead>
            <TableRow className={styles.tableHeadRow}>
              <TableCell />
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">Primary</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {productLineOptions.map((pl) => (
              <TableRow className={styles.tableBodyRow} key={pl.label}>
                <TableCell>{pl.label}</TableCell>
                <TableCell className={styles.selector} align="center">
                  <Checkbox
                    checked={secondaryProductLines.includes(pl.value)}
                    onChange={(e) => onSecondaryProductLineChange(e, pl.value)}
                    name={pl.value}
                  />
                </TableCell>
                <TableCell className={styles.selector} align="center">
                  <Radio
                    checked={primaryProductLine === pl.value}
                    onChange={() => onPrimaryProductLineChange(pl.value)}
                    name="primaryProductLine"
                    disabled={!secondaryProductLines.includes(pl.value)}
                  />
                </TableCell>
                <TableCell className={styles.rest} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {errorMessage ? <p className={styles.errorMessage}>{errorMessage}</p> : <></>}
    </FormControl>
  </div>
);

export type ProductLinesProps = {
  errorMessage?: string,
  primaryProductLine: ProductLine | undefined,
  secondaryProductLines: ProductLine[],
  onPrimaryProductLineChange: (selectedProductLine: ProductLine) => void,
  onSecondaryProductLineChange: (event: React.ChangeEvent<HTMLInputElement>, selectedProductLine: ProductLine) => void
};

export default ProductLines;
