/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import cx from 'classnames';
import { Label } from '@trucktrax/trucktrax-common';
import styles from '../admin/AdminAddModal.module.css';
import { noop } from '../../../util/appUtil';
import { getEventFromTarget } from '../../../util/adminUtil';

export default class CardCheckboxStateless extends Component<CardCheckboxStatelessProps> {
  static defaultProps: Partial<CardCheckboxStatelessProps> = {
    className: '',
    onChange: noop,
    label: '',
    isRequired: false,
    items: [],
    selected: [],
    disabledItems: [],
    errorMessage: undefined,
  };

  isChecked(item: any) {
    if (!this.props.selected) {
      return false;
    }
    if (this.props.items.length === 1) {
      const checked = this.props.selected.length === 1;
      return checked;
    }
    return this.props.selected.filter(selected => selected === item.value).length > 0;
  }

  isDisabled(item: any): boolean {
    if (this.props.disabledItems) {
      return this.props.disabledItems.filter(i => i.value === item.value).length > 0;
    }
    return false;
  }

  onChange = (selectedItem: any, e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    let { selected } = this.props;
    if (!selected) {
      selected = [];
    }

    if (isChecked) {
      selected.push(selectedItem);
    } else {
      selected = selected.filter(s => s !== selectedItem);
    }

    const event = getEventFromTarget(e, this.props.id);
    (event as any)[this.props.id] = selected.length === 0 ? []
      : selected.sort((a, b) => String(a!).localeCompare(String(b!))) as any[];
    this.props.onChange!(event);
  };

  getCheckboxClass(item: any) {
    if (this.isDisabled(item)) {
      return '';
    }
    if (this.isChecked(item)) {
      return styles.checkboxChecked;
    }
    return styles.checkboxUnchecked;
  }

  render() {
    const {
      className,
      label,
      isRequired,
      items,
      errorMessage,
      id,
    } = this.props;

    const errorString = errorMessage?.join(', ');
    const hasError = !!errorString;

    return (
      <div className={className}>
        <div>
          <div>
            <Label isRequired={isRequired}>{label!}</Label>
          </div>
        </div>
        <FormControl>
          {items.map((checkbox, index) => {
            const key = `checkbox${id}${index}`;

            return (
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={this.isChecked(checkbox)}
                    onChange={(e) => this.onChange(checkbox.value, e)}
                    name={key}
                    key={key}
                    color="primary"
                    className={this.getCheckboxClass(checkbox)}
                    disabled={this.isDisabled(checkbox)}
                  />
                )}
                label={checkbox.label}
                key={key}
                className={styles.checkboxLabel}
              />
            );
          })}
        </FormControl>
        {hasError && (
          <label
            className={cx(styles.textRed, styles.errorFieldMargin, 'tt-label--help')}
            data-test="card-radio-error"
          >
            {errorString}
          </label>
        )}
      </div>
    );
  }
}

export interface CardCheckboxStatelessProps {
  id: string;
  errorMessage?: string[];
  className?: string;
  onChange?: (event: any) => void;
  label?: string;
  isRequired?: boolean;
  items: { label: string, value: any, disabled?: boolean }[];
  selected?: { value: any }[];
  disabledItems?: { value: any }[];
}
