// Google maps requires svg path. These paths can be copied from icomoon svg files.
export default {
  arrowPath: 'M21.438 10.476 14.31 3.24c-.378-.378-.864-.432-1.296-.27-.432.162-.702.594-.702 '
    + '1.08v3.132h-8.1c-.648 0-1.188.54-1.188 1.188v5.778c0 .648.54 1.188 1.188 1.188h8.1v3.186c0 .486.27 .918.702 '
    + '1.08.432 .162.972 .054 1.296-.27l7.128-7.236c.216-.216.324-.486.324-.81C21.762 11.016 21.654 10.692 21.438 10.476z',
  circlePath: 'M6.75 13.5a6.75 6.75 0 1 1 13.5 0 6.75 6.75 0 1 1-13.5 0',
  housePath: 'M9.124 21.215v-6.844h4.563v6.844h5.703v-9.124h3.422l-11.407-10.265-11.407 10.265h3.422v9.124z',
  placePath: 'M11.635 0c-4.493 0-8.064 3.571-8.064 8.064 0 5.99 8.064 14.976 8.064'
  + '14.976s8.064-8.986 8.064-14.976c0-4.493-3.571-8.064-8.064-8.064v0zM11.635 10.944c-1.613 '
  + '0-2.88-1.267-2.88-2.88s1.267-2.88 2.88-2.88 2.88 1.267 2.88 2.88-1.267 2.88-2.88 2.88v0z',
  flagPath: 'M13.081 3l-.48-2.4h-10.8v20.4h2.4v-8.4h6.72l.48 2.4h8.4v-12z',
  pinPath: 'M8 0C5.66093 0 3.76471 1.84865 3.76471 4.12903C3.76471 6.053 5.11448 7.66961 6.94118 8.12797L7.65542 '
  + '16L8.68536 13.9238L9.05883 8.12797C10.8855 7.66961 12.2353 6.05297 12.2353 4.12903C12.2353 1.84865 10.3391 '
  + '0 8 0ZM7.52942 3.76471C7.00962 3.76471 6.58824 3.34332 6.58824 2.82353C6.58824 2.30374 7.00962 1.88235 7.52942 '
  + '1.88235C8.04921 1.88235 8.47059 2.30374 8.47059 2.82353C8.47059 3.34332 8.04924 '
  + '3.76471 7.52942 3.76471Z',
};
