import { constants as C } from '@trucktrax/trucktrax-common';
import ARROW_MARKERS from '../constants/markerConstants';

const markersByStatus = {
  [C.STATUS_IDS.IN_QUEUE]: ARROW_MARKERS.IN_QUEUE,
  [C.STATUS_IDS.LOADING]: ARROW_MARKERS.LOADING,
  [C.STATUS_IDS.TICKETED]: ARROW_MARKERS.TICKETED,
  [C.STATUS_IDS.TO_JOB]: ARROW_MARKERS.TO_JOB,
  [C.STATUS_IDS.ON_JOB]: ARROW_MARKERS.ON_JOB,
  [C.STATUS_IDS.UNLOAD_START]: ARROW_MARKERS.UNLOAD_START,
  [C.STATUS_IDS.UNLOADED]: ARROW_MARKERS.UNLOADED,
  [C.STATUS_IDS.RETURNING]: ARROW_MARKERS.RETURNING,
  [C.STATUS_IDS.IN_YARD]: ARROW_MARKERS.IN_YARD,
  [C.STATUS_IDS.UNKNOWN]: ARROW_MARKERS.UNKNOWN,
};

export default function getArrow(bearing: number, status: string, stationary?: boolean): string {
  const dotIndex = 8;
  if (stationary) { return markersByStatus[status]?.[dotIndex] ?? ARROW_MARKERS.DEFAULT[dotIndex]; }
  const index = Math.round(bearing / 45) % 8;
  return markersByStatus[status]?.[index] ?? ARROW_MARKERS.DEFAULT[index];
}
