import React, { Component, ComponentType, ReactNode } from 'react';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  DiscardProgressCard, HeaderAndContainer, TTBreadCrumb,
} from '@trucktrax/trucktrax-common';
import { UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import { Link, Prompt, Redirect } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import styles from './AdminCard.module.css';
import {
  accessValue,
  getOtherRequiredFields,
  formatEditPlantGeozones,
  formatHaulerMultiSelectData,
  KeyRelationship,
} from '../../../util/adminUtil';
import Header from '../Header';
import { getRequest } from '../../../services/requestService';
import { devErrorAndLog } from '../../../util/errorUtil';
import DynamicForms from '../forms/DynamicForms';
import { isChangedValid, areAllRequiredValuesValid } from '../../../util/validation';
import { noop } from '../../../util/appUtil';
import {
  CHECKBOX_FORM,
  COMPOSITE,
  DISCARD_PROMPT_TEXT,
  DROPDOWN_FORM,
  INPUT_FORM,
  MAP_FORM,
  MAP_MULTIZONES_FORM,
  NONE_STRING,
  RADIO_FORM,
  NUMERIC_INPUT_FORM,
  INVALID_STALE_TOKEN_LOGOUT,
  CARD_SWITCH,
} from '../../../constants/appConstants';
import { NOT_FOUND_PATH } from '../../../constants/apiConstants';
import AddFeatureModal from '../AddFeatureModal';
import AdminActionButtons from './AdminActionButtons';
import trash from '../../../assets/img/trash-illus.svg';
import HTTP_CODES from '../../../constants/httpConstants';

type ErrorMessage = { message: string, zone?: Zone | null };
export type AdminErrors = { [key: string]: string | ErrorMessage };
export interface AdminCardState {
  isCloseRequest: boolean;
  disabled: boolean;
  value: any;
  details: any | null;
  errors: AdminErrors;
  notFound: boolean;
  inputTouch: { [inputId: string]: boolean },
  cardWidth: number | null,
  smallCardWidth: boolean
}

export class AdminCard extends Component<AdminCardProps, AdminCardState> {
  static defaultProps: Partial<AdminCardProps> = {
    launchToEdit: false,
    className: '',
    errorObject: {
      error: false,
      errorMessage: '',
    },
    pathName: '',
    options: { requiredKeys: [] },
    inactiveFields: [],
    externalKeys: [],
    onChangeHandler: noop,
    currentRegion: { url: '' },
    additionalComponents: [],
    disableEdit: false,
    isParentChanged: false,
  };

  adminCardRef: React.RefObject<HTMLDivElement>;

  constructor(props: AdminCardProps) {
    super(props);

    this.state = {
      isCloseRequest: false,
      disabled: true,
      inputTouch: {},
      value: {},
      details: null,
      errors: {},
      notFound: false,
      cardWidth: null,
      smallCardWidth: false,
    };

    this.adminCardRef = React.createRef();
  }

  checkComponentWidth = () => {
    const existingRef = this.adminCardRef.current;
    if (existingRef) {
      setTimeout(() => {
        const width = existingRef.clientWidth;
        const isSmallCardWidth = width < 560;
        if (this.state.cardWidth !== width) {
          this.setState({
            smallCardWidth: isSmallCardWidth,
          });
        }
      }, 300); // Delay allows for animations in the UI to complete before trying to calculate component width
    }
  };

  componentDidMount() {
    this.fetchAdminRecord();
    // Component can change size/appearance either on browser resize or clicking on UI elements,
    // such as expanding or collapsing the Message Center panel
    window.addEventListener('resize', this.checkComponentWidth);
    window.addEventListener('click', this.checkComponentWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkComponentWidth);
    window.removeEventListener('click', this.checkComponentWidth);
  }

  componentDidUpdate(prevProps: AdminCardProps) {
    if (this.props.launchToEdit && !prevProps.launchToEdit) {
      this.toggleEdit();
    }

    if (this.props.options?.shouldRefetchData) {
      this.fetchAdminRecord();
    }

    if (this.props !== prevProps) {
      this.checkComponentWidth();
    }
  }

  onChange = (changes: any) => {
    // TODO: remove this logic after adding primary plant,
    // primary and secondary product lines to the backend
    // prevents application from sending ignorable fields to the backend.
    const { inactiveFields } = this.props;
    inactiveFields!.forEach(f => {
      delete changes[f];
    });

    let newValue = { ...this.state.value };
    if (changes.geozones) {
      // Handle plant geozones update
      const geozones = formatEditPlantGeozones(this.state.value, changes.geozones);
      newValue.geozones = geozones;
    } else if (changes.haulers) {
      newValue.haulers = formatHaulerMultiSelectData(changes.haulers);
    } else {
      newValue = { ...newValue, ...changes };
    }

    this.setState({
      value: newValue,
      errors: {},
    });

    if (this.props.onChangeHandler) {
      this.props.onChangeHandler(newValue);
    }
  };

  onSave = () => {
    const { value } = this.state;
    const validationErrors: AdminErrors = {};

    (this.props.config).forEach((field) => {
      const key = field.key!;
      if (value[key] && typeof value[key] === 'object' && value[key].url === null) {
        delete value[key];
      }
      if (field.customValidation) {
        const validationError = field.customValidation(value[key]);
        if (validationError) {
          validationErrors[key] = validationError;
        }
      }
    });

    if (!(Object.keys(validationErrors).length > 0)) {
      this.setState({ disabled: true });
      this.props.save(this.state.value, this.setData, (errors) => {
        this.setState({ disabled: false });
        this.setState({ errors });
      });
    } else {
      this.setState({ disabled: false });
      this.setState({ errors: validationErrors });
    }
  };

  onError = (e: any) => {
    let consoleOnly = false;
    if (e.response) {
      const { status } = e.response;
      if (status === HTTP_CODES.not_found) {
        this.setState({ notFound: true });
        return;
      }
      if (status === HTTP_CODES.forbidden) {
        consoleOnly = true;
      }
    }
    if (e !== INVALID_STALE_TOKEN_LOGOUT) {
      this.props.devErrorAndLog('Unable to retrieve record', `AdminCard: ${e.toString()}`, undefined, undefined, consoleOnly);
    }
  };

  onSuccess = (response: AxiosResponse) => {
    if (this.props.options!.callback) {
      this.props.options!.callback(response);
    }
    this.setData(response);
  };

  onTouch = (_: unknown, inputId: string) => {
    const newValue = this.state.inputTouch;
    newValue[inputId] = true;
    this.setState({
      inputTouch: newValue,
    });
  };

  setData = (response: AxiosResponse) => {
    let { data } = response;
    if (this.props.options!.alterData) {
      data = this.props.options!.alterData(data);
    }
    this.setState({
      details: data,
      disabled: false,
    });
  };

  getForm(config: FormConfig, key: string, value: any) {
    let updatedValue = value;
    if (config.type === INPUT_FORM) {
      config.persistedObject = this.state.details;
      config.errorMessage = this.state.errors[config.key!] as string;
    }
    if (config.type === DROPDOWN_FORM) {
      updatedValue = this.state.value[key];
      config.persistedObject = this.state.details;
      config.initialSelected = config.initialSelected ?? { name: `Please Select ${config.label}` };
    }
    if (config.type === RADIO_FORM) {
      config.initialSelected = this.state.details[config.key!];
      config.errorMessage = this.state.errors[config.key!] as string;
    }
    if (config.type === CHECKBOX_FORM) {
      config.isSelected = this.state.details[config.key!];
      config.errorMessage = this.state.errors[config.key!] as string;
    }
    if (config.type === NUMERIC_INPUT_FORM) {
      config.isSelected = this.state.details[config.key!];
      config.errorMessage = this.state.errors[config.key!] as string;
    }
    if (config.type === CARD_SWITCH) {
      config.checked = this.state.value[config.key!];
    }
    if (config.type === MAP_FORM) {
      if (this.state.value.polygon !== undefined) {
        config.polygon = this.state.value.polygon;
      }
      if (this.state.value.circle !== undefined) {
        config.circle = this.state.value.circle;
      }
    }
    if (config.type === MAP_MULTIZONES_FORM) {
      const error = this.state.errors[config.key!] as ErrorMessage;
      if (error && error.message) {
        config.errorMessage = error.message;
        // switch to the tab/zone that the error happened on
        if (config.handleSelectedZoneChange) {
          // only change the selected zone if the error.zone is not null
          // and different than the config.selectedZone
          if (error.zone && config.selectedZone !== error.zone) {
            config.handleSelectedZoneChange(error.zone);
            // ensure that the tab/zone is only changed
            // once by setting error.zone to null
            error.zone = null;
          }
        }
      } else {
        config.errorMessage = error;
      }
    }

    return (
      <DynamicForms
        id={key}
        edit={this.props.edit}
        onChange={this.onChange}
        onTouch={this.onTouch}
        setData={this.setData}
        {...config}
        component={config.component!}
        className={config.className}
        key={key}
        type={config.type}
        value={updatedValue}
      />
    );
  }

  isChangedValid() {
    return isChangedValid(this.state.details, this.state.value) || this.props.isParentChanged;
  }

  isRequiredValid() {
    const compositeComponents = this.props.config.filter(form => form.type === COMPOSITE);
    const compositeForms = compositeComponents.reduce<CompositeComponentInfo[]>((acc, c) => [...acc, ...(c.components ?? [])], []);
    const allForms = [...this.props.config, ...compositeForms];
    const requiredComponents = allForms.filter(form => !!form.isRequired && !form.disabled);
    const keysArray = requiredComponents.map(form => form.key!);

    // add geozone data to validation list
    const { requiredKeys } = this.props.options!;
    getOtherRequiredFields(requiredKeys!, keysArray, this.state.value);
    return areAllRequiredValuesValid(keysArray, this.state.value);
  }

  fetchAdminRecord = async () => {
    let url = `${this.props.url}`;
    url = url.replace('/glinx', '');
    try {
      const currentRegionUrl = this.props.currentRegion!.url;
      const params = { region: currentRegionUrl };
      const response: AxiosResponse = await getRequest(url, params);
      this.onSuccess(response);
    } catch (e) {
      this.onError(e);
    }
  };

  static generateBreadcrumb = (pathName: string, header: string) => {
    const rtnVal = [];
    let backLink = '';
    let backLinkLabel = '';

    // generate back link
    const currPath = pathName;
    const arrPath = currPath.split('/');
    const arrPathLinkBack = [...arrPath.slice(0, 3)];
    backLink = arrPathLinkBack.join('/');

    if (backLink.includes('GLinx')) {
      backLink = backLink.replace('devices/glinx', 'devices');
    }
    // generate link label
    let initial = arrPath[2];

    if (initial) {
      const arrLabel = initial.split('-');

      if (arrLabel.length === 2) {
        backLinkLabel = `${arrLabel[0].charAt(0).toUpperCase()}${arrLabel[0].slice(1)} `;
        backLinkLabel += `${arrLabel[1].charAt(0).toUpperCase()}${arrLabel[1].slice(1)}`;
      } else {
        initial = initial.replaceAll('trucks', 'vehicles');
        backLinkLabel = initial.charAt(0).toUpperCase() + initial.slice(1);
      }
    }

    // constructing the object to pass to BreadCrumb
    // first breadcrumb link
    rtnVal.push(
      {
        toLink: backLink,
        linkLabel: backLinkLabel,
      }
    );

    // secondary breadcrumb link
    if (arrPath[4]) {
      let secondaryBackLinkLabel = '';
      let secondaryBackLink = '';
      const labelFromLink = arrPath[3];

      secondaryBackLinkLabel = labelFromLink.charAt(0).toUpperCase() + labelFromLink.slice(1);

      // generate secondary back link
      const arrPathLinkSecondaryBack = [...arrPath.slice(0, 4)];
      secondaryBackLink = arrPathLinkSecondaryBack.join('/');

      rtnVal.push(
        {
          toLink: secondaryBackLink,
          linkLabel: secondaryBackLinkLabel,
        }
      );
    }

    // last breadcrumb
    rtnVal.push(
      {
        linkLabel: header,
      }
    );

    return rtnVal;
  };

  requestToggleEdit = async () => {
    if (this.isChangedValid()) {
      this.setState({ isCloseRequest: !this.state.isCloseRequest });
    } else {
      await this.toggleEdit();
    }
  };

  toggleEdit = async () => {
    this.props.onToggleEdit();
    // fetchAdminRecord is needed to refresh state.value and state.details
    // for screens that activate and deactivate records (Drivers, Users,...)
    await this.fetchAdminRecord();
    this.setState({
      value: this.state.details,
      isCloseRequest: false,
      errors: {},
    });
  };

  dynamicForms = (config: AdminCardConfig, i: number) => {
    const {
      accessor,
      items,
      itemFilter,
    } = config;
    const accessed = this.props.edit ? this.state.value : this.state.details;
    const emptyText = this.props.edit ? '' : undefined;
    let value = accessValue(accessor!, accessed, undefined, emptyText);
    const key = config.key || String(i);
    config.items = itemFilter ? itemFilter(items, this.state.value) : items;

    if (this.props.externalKeys!.includes(key) && this.state.value[key] !== value) {
      if ((!Array.isArray(this.state.value[key]) || !Array.isArray(value))
        || !(JSON.stringify(this.state.value[key]) === JSON.stringify(value))) {
        this.setState({
          value: {
            ...this.state.value,
            [key]: value,
          },
        });
      }
    }

    if (config.type === COMPOSITE) {
      const components = config.components!.map(item => {
        const v = accessValue(item.accessor, this.state.value, undefined, '');
        return this.getForm(item, item.key, v);
      });

      value = accessValue(accessor!, accessed, NONE_STRING, NONE_STRING);
      return (
        <div key={key} className={config.className}>
          {components}
        </div>
      );
    }
    return this.getForm(config, key, value);
  };

  render() {
    if (this.state.notFound) {
      return (
        <Redirect to={NOT_FOUND_PATH} />
      );
    }

    const {
      children, pathName, config, additionalComponents, disableEdit, headerAccessor,
    } = this.props;

    // styles
    const cardStyle = {
      root: styles.card,
    };

    // determine header to display
    let header = '';

    if (this.state.details) {
      header = accessValue(this.props.headerAccessor as string, this.state.details);
      // this is an exception for devices only since name is not required
      header = header || 'No name';
    }

    // generate bread crumb data
    const breadcrumbData = AdminCard.generateBreadcrumb(pathName!, header);

    const card = (
      <div className={styles.bodyWrapper} ref={this.adminCardRef}>
        <div className={styles.cardWrapper}>
          <Card classes={cardStyle}>
            <CardContent>
              <div className={cx(
                this.props.className,
                styles.cardText,
                this.state.smallCardWidth && styles.cardTextSmall
              )}
              >
                {config.map(this.dynamicForms)}
                {children}
              </div>
            </CardContent>
          </Card>
        </div>
        {additionalComponents!.length > 0 ? additionalComponents!.map((additionalComponent: any) => (
          <div className={styles.additionalComponent}>
            {additionalComponent}
          </div>
        )) : null}
        <AddFeatureModal
          title=""
          isOpen={this.state.isCloseRequest}
          onCancel={noop}
        >
          <DiscardProgressCard
            discardAcceptAction={this.toggleEdit}
            discardRejectAction={this.requestToggleEdit}
            imageSrc={trash}
            altText="Trash"
          />
        </AddFeatureModal>
        <Prompt
          when={this.props.edit && this.isChangedValid()}
          message={DISCARD_PROMPT_TEXT}
        />
      </div>
    );

    const breadCrumb = (
      <TTBreadCrumb
        data={breadcrumbData}
        linkComponent={Link}
      />
    );

    const elementLeft = (
      <Header
        title={header}
        subtitle={breadCrumb}
      />
    );

    const elementsRight = (
      <AdminActionButtons
        configData={config}
        isDisabled={this.state.disabled}
        disableEdit={disableEdit || !this.state.details}
        overrideDisabledReason={!this.state.details ? '' : undefined}
        toggleEdit={this.toggleEdit}
        isChangedValid={this.isChangedValid()}
        isRequiredValid={this.isRequiredValid()}
        onSave={this.onSave}
        requestToggleEdit={this.requestToggleEdit}
        category={headerAccessor as string}
      />
    );

    return (
      <HeaderAndContainer
        elementsLeft={elementLeft}
        elementsRight={elementsRight}
        containerComponent={card}
        containerStyle={styles.headerAndContainer}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentRegion: state.currentRegion,
});

type Zone = any;
type CompositeComponentInfo = {
  type: string;
  key: string;
  accessor: string;
  label: string;
  dataTest: string;
  errorDataTest: string;
  className: string;
  isRequired: boolean,
  maxLength: number,
  disabled?: boolean
};

export type AdminCardConfig = {
  key?: string;
  className?: string;
  components?: CompositeComponentInfo[]
  component?: ComponentType<any>;
  isRequired?: boolean;
  type: string;
  accessor?: string | ((value: any) => any);
  items?: any[];
  itemFilter?: (item: any, value: any) => any[];
  customValidation?: (value: any) => string;
  [other: string]: any;
};

export interface FormConfig {
  type: string;
  key?: string;
  label?: string;
  persistedObject?: any;
  initialSelected?: any;
  errorMessage?: string | ErrorMessage;
  checked?: boolean;
  polygon?: any;
  circle?: any;
  selectedZone?: Zone | null;
  component?: ComponentType<any>;
  className?: string;
  isSelected?: boolean;
  handleSelectedZoneChange?: (zone: Zone) => void;
}

export type KeyInfo = {
  keys: string[];
  relationship: string;
};

export interface AdminCardProps {
  edit: boolean;
  launchToEdit?: boolean;
  url: string;
  onChangeHandler?: (newValue: any) => void;
  config: AdminCardConfig[]
  className?: string;
  onToggleEdit: () => void;
  save: (
    value: any,
    onData: (response: AxiosResponse) => void,
    onError: (errors: AdminErrors) => void
  ) => void;
  errorObject?: {
    error: boolean;
    errorMessage: string;
  };
  isParentChanged?: boolean;
  pathName?: string;
  devErrorAndLog: (modalTitle: string, modalBody: string, logObject?: any, extraLogInfo?: string, consoleOnly?: boolean) => void;
  externalKeys?: string[];
  headerAccessor: string | ((...args: any) => void);
  options?: {
    callback?: (response: AxiosResponse) => void;
    alterData?: (data: any) => any;
    additionalUrl?: string;
    requiredKeys?: KeyRelationship[];
    additionalData?: any;
    shouldRefetchData?: boolean;
  };
  inactiveFields?: string[];
  currentRegion?: UrlKeyDto;
  additionalComponents?: ComponentType[] | ReactNode[];
  disableEdit?: boolean;
  children?: ReactNode
}

export default connect<any, any, any>(mapStateToProps, {
  devErrorAndLog,
})(AdminCard);
