import React from 'react';
import { Circle } from '@react-google-maps/api';
import cx from 'classnames';
import { OrderDto, PlantDto, VoidFunc } from '@trucktrax/trucktrax-ts-common';
import { Button, Tooltip, vars } from '@trucktrax/trucktrax-common';
import icons from '../icons';
import TTMarker from '../TTMarker';
import styles from '../TTMap.module.css';
import { formatOrderScheduledTime } from '../../../../util/orderUtil';

export default function JobsiteMarker(props: JobsiteMarkerProps) {
  const {
    order,
    selectedItemId,
    showGeofence = false,
    markerColor = vars.gray700,
    orderDetailsCallback = () => { },
    messageDriversOnOrder = () => { },
    plantList = [],
    ...rest
  }: JobsiteMarkerProps = props;

  try {
    const { center, radius } = order.geoZone?.zone?.circle ?? {};

    if (!center?.latitude && !center?.longitude) {
      return null;
    }

    // order may not actually exist in some edge cases
    // should have jobsite location but no order-specific info
    const orderId = order.id?.toString();
    const orderNumber = order.orderNumber?.toString();

    const position = {
      latitude: center?.latitude,
      longitude: center?.longitude,
      lat: center?.latitude ?? 0,
      lng: center?.longitude ?? 0,
    };

    const flagIconAnchorX = 4.5;
    const flagIconAnchorY = 20;

    const icon = {
      path: icons.flagPath,
      fillColor: markerColor,
      fillOpacity: 1,
      strokeWeight: 0,
      // this anchors the image so the beginning of the flag pole is in the geolocation
      anchor: new (window as any).google.maps.Point(flagIconAnchorX, flagIconAnchorY),
    };

    const shadowIcon = {
      ...icon,
      fillColor: 'black',
      fillOpacity: 0.5,
      scale: 1.075,
      strokeWeight: 2,
      strokeColor: 'black',
      strokeOpacity: 0.15,
      anchor: new (window as any).google.maps.Point(flagIconAnchorX + 0.5, flagIconAnchorY - 1),
    };

    const getPlantName = (plantUrl?: string) => {
      if (!plantUrl) {
        return 'N/A';
      }

      const foundPlant = plantList.find(plant => plant.url === plantUrl);
      return foundPlant ? foundPlant.name : 'N/A';
    };

    const title = orderNumber && `Order #${orderNumber}`;
    const tooltipText = `Plant: ${getPlantName(order?.plant?.url)}`;
    const infoPaneTitle = (
      <div data-test="order-number" title={title}>
        {tooltipText && (
          <Tooltip text={tooltipText}>
            {markerColor && (
              <i
                className={cx(styles.flagIcon, 'icon-flag', 'margin-right-10', 'clickable')}
                style={{ color: markerColor }}
              />
            )}
          </Tooltip>
        )}
        <span className={cx(styles.titleText, 'nowrap-ellipsis')}>
          {title}
        </span>
      </div>
    );

    const scheduledDate = formatOrderScheduledTime(order);
    const infoPaneContent = (
      <div className={styles.orderContainer}>
        <div
          className={cx(styles.flexItem, 'margin-bottom-10')}
          data-test="infopane-customer-name"
        >
          <b className="margin-right-5 txt-bold">Customer:</b>
          <span
            className="nowrap-ellipsis"
            title={order.customerName}
          >
            {order.customerName}
          </span>
        </div>
        {order.scheduledTime && (
          <div
            className={cx(styles.flexItem, 'margin-bottom-10')}
            data-test="infopane-scheduled-date"
          >
            <b className="margin-right-5 txt-bold">Date:</b>
            <span
              className="nowrap-ellipsis"
              title={scheduledDate}
            >
              {scheduledDate}
            </span>
          </div>
        )}
        <div className={styles.orderFooter}>
          {order.id && (
            <Button
              buttonClassName={cx('tt-btn-basic', styles.orderDetailsBtn)}
              name="View Order Details"
              iconClassName="icon-orders"
              dataTest="view-order-details"
              onClick={orderDetailsCallback}
            />
          )}
          {order.id && (
            <Button
              buttonClassName={cx('tt-btn-basic', styles.orderDetailsBtn)}
              name="Message Drivers on Order"
              iconClassName="icon-messages"
              dataTest="message-drivers-on-order"
              onClick={() => messageDriversOnOrder(order)}
            />
          )}
        </div>
      </div>
    );

    const shapeStyles = {
      fillColor: markerColor,
      fillOpacity: 0.25,
      strokeColor: markerColor,
      strokeWeight: 2,
      clickable: false,
      zIndex: 1,
    };

    // the pixel offset adjusts the location of the infoPanel around the image
    const pixelOffset = new (window as any).google.maps.Size(9, -10);

    return (
      <div>
        <TTMarker
          position={position}
          icon={icon}
          shadowIcon={shadowIcon}
          infoPaneTitle={infoPaneTitle}
          infoPaneContent={infoPaneContent}
          isSelected={!!(orderId && selectedItemId && orderId === selectedItemId)}
          {...rest}
          pixelOffset={pixelOffset}
        />
        {showGeofence && (
          <Circle
            center={position}
            radius={radius!}
            options={shapeStyles}
          />
        )}
      </div>
    );
  } catch (e) {
    // this avoids rendering component on errors
    // to debug, log this exception
    return null;
  }
}

export interface JobsiteMarkerProps {
  order: OrderDto,
  plantList?: PlantDto[],
  selectedItemId?: string,
  showGeofence?: boolean,
  markerColor?: string,
  orderDetailsCallback?: VoidFunc,
  messageDriversOnOrder?: (order: OrderDto) => void,
  mapRef?: any,
  onClick?: VoidFunc,
  onCloseClick?: VoidFunc,
  isSelected?: boolean
}
