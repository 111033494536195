import { FeatureFlagDto, SubscriptionDto } from '@trucktrax/trucktrax-ts-common';
import { SET_ALL_FEATUREFLAGS, SET_ALL_SUBSCRIPTIONS, SET_FEATURE_FLAG_OBJECT } from '../../constants/actionConstants';

export const replaceAllFlags = ({ featureFlags }: { featureFlags: FeatureFlagDto[] }) => ({
  type: SET_ALL_FEATUREFLAGS,
  featureFlags,
});

export const replaceAllSubscriptions = (value?: SubscriptionDto[]) => ({
  type: SET_ALL_SUBSCRIPTIONS,
  subscriptions: value || [],
});

export const setFeatureFlagObject = (obj: FeatureFlagDto[]) => ({
  type: SET_FEATURE_FLAG_OBJECT,
  payload: obj,
});
