import React, { Component, ComponentType } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
export default class RouteWithSubRoutes extends Component<RouteWithSubRoutesProps> {
  static defaultProps: Partial<RouteWithSubRoutesProps> = {
    path: '',
  };

  renderComponent = (props: RouteComponentProps) => {
    const { component: RendComp, ...rest } = this.props;
    if (!RendComp) {
      return null;
    }

    return (
      // pass the sub-routes down to keep nesting
      <RendComp
        {...props}
        {...rest}
      />
    );
  };

  render() {
    return (
      <Route
        path={this.props.path}
        render={this.renderComponent}
      />
    );
  }
}

export interface RouteWithSubRoutesProps {
  path?: string;
  component?: ComponentType<any>;
}
