import React, { Component } from 'react';
import { ChecklistDto, ChecklistRegionDto } from '@trucktrax/trucktrax-ts-common';
import { FloatingActionButton } from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import AdminAddModal, { AdminAddModalErrors } from '../../shared/admin/AdminAddModal';
import { addToOrUpdateListInStore } from '../../../store/actions/dataTableActions';
import { createDataTableRecord } from '../../../services/dataTableService';
import { ADD_CHECKLIST } from '../../../constants/actionConstants';
import styles from './ChecklistAddModal.module.css';
import {
  ADD_CHECKLIST_BUTTON_TEXT,
  ADD_CHECKLIST_MODAL_TITLE,
} from '../../../constants/appConstants';
import { onSubmitSuccess } from '../../../util/adminUtil';
import { CHECKLISTS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ADD_CHECKLIST_ERROR_MESSAGE } from '../../../constants/errorConstants';
import { ADD_CHECKLIST_SUCCESS_TEXT } from '../../../constants/successConstants';
import getEditConfig from './ChecklistEditConfig';
import { ReduxState } from '../../../store';
import AddFeatureModal from '../../shared/AddFeatureModal';

type KeyedRegion = {
  key: string;
};

interface NewChecklist extends Omit<ChecklistDto, 'regions'> {
  regions: KeyedRegion[]
}

export class ChecklistAddModal extends Component<ChecklistAddModalProps> {
  onSuccess = (checklist: ChecklistDto) => {
    this.props.addToOrUpdateListInStore(checklist, ADD_CHECKLIST);
    this.props.discardAcceptAction(checklist);
  };

  onSubmit = (dto: NewChecklist, onError: (errors: AdminAddModalErrors) => void, buttonCallback: VoidFunction) => {
    const dtoToSubmit: ChecklistDto = {
      ...dto,
      regions: dto.regions.map((r): ChecklistRegionDto => ({
        active: true,
        id: 0,
        emails: [],
        region: {
          url: r.key,
        },
      })),
    };

    const { createAdminTableData: post } = this.props;

    const toastMessages = {
      success: ADD_CHECKLIST_SUCCESS_TEXT,
      fail: ADD_CHECKLIST_ERROR_MESSAGE,
    };

    const url = getGeotraxBaseUrl() + CHECKLISTS_PATH;
    const callbacks = [this.onSuccess, buttonCallback];
    post(
      url,
      dtoToSubmit,
      (r) => onSubmitSuccess(callbacks, r),
      onError,
      toastMessages,
      true
    );
  };

  render() {
    const { regionList, assignedRegionList } = this.props;
    const editConfig = getEditConfig(styles, regionList as any, assignedRegionList);

    return (
      <div>
        <FloatingActionButton onClick={this.props.openModal} />
        {
          this.props.isOpen
          && (
            <AddFeatureModal
              title={ADD_CHECKLIST_MODAL_TITLE}
              isOpen={this.props.isOpen}
              onCancel={this.props.closeModal}
              style={styles.checklistAddModal}
            >
              <AdminAddModal
                config={editConfig as any}
                className={styles.modal}
                addButtonText={ADD_CHECKLIST_BUTTON_TEXT}
                addButtonDataTest="regions-add-button-data-test"
                onRightBtnClick={this.onSubmit}
                discardAcceptAction={this.props.discardAcceptAction}
                discardRejectAction={this.props.discardRejectAction}
                isCloseRequest={this.props.isCloseRequest}
                modalSize="small"
              />
            </AddFeatureModal>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  regionList: state.regionList,
  assignedRegionList: state.assignedRegionList ?? [],
});

type ChecklistAddModalReduxStateProps = ReturnType<typeof mapStateToProps>;
type ChecklistAddModalReduxDispatchProps = {
  createAdminTableData: (...args: Parameters<typeof createDataTableRecord>) => void,
  addToOrUpdateListInStore: (...args: Parameters<typeof addToOrUpdateListInStore>) => void;
};
type ChecklistAddModalOwnProps = {
  isOpen: boolean;
  openModal: VoidFunction;
  closeModal: VoidFunction;
  discardAcceptAction: (checklist?: ChecklistDto) => void;
  discardRejectAction: VoidFunction;
  isCloseRequest: boolean;
};

export type ChecklistAddModalProps = ChecklistAddModalReduxStateProps & ChecklistAddModalReduxDispatchProps & ChecklistAddModalOwnProps;

export default connect(mapStateToProps, {
  createAdminTableData: createDataTableRecord,
  addToOrUpdateListInStore,
})(ChecklistAddModal);
