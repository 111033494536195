import { Dispatch } from 'redux';
import { ProductLine } from '@trucktrax/trucktrax-ts-common';
import * as productLineActions from '../store/actions/productLineActions';

export function setPrimaryProductLine(productLine: ProductLine) {
  return (dispatch: Dispatch) => {
    dispatch(productLineActions.setPrimaryProductLine(productLine));
  };
}

export function setSecondaryProductLine(productLine: ProductLine) {
  return (dispatch: Dispatch) => {
    dispatch(productLineActions.setSecondaryProductLine(productLine));
  };
}

export function setSecondaryProductLines(productLines: ProductLine[]) {
  return (dispatch: Dispatch) => {
    dispatch(productLineActions.setSecondaryProductLines(productLines));
  };
}

export function setSelectedProductLine(productLine: ProductLine) {
  return (dispatch: Dispatch) => {
    dispatch(productLineActions.setSelectedProductLine(productLine));
  };
}
