import {
  CLOSE_SNACKBAR,
  CLOSE_SNACKBAR_NOTIFICATION,
  OPEN_SNACKBAR,
  OPEN_SNACKBAR_NOTIFICATION,
} from '../../constants/actionConstants';
import { NotificationType, SUCCESS } from '../../constants/appConstants';

export interface SnackbarOptions {
  snackbarBody: string;
  dataTest?: string;
  manualClose?: boolean;
  snackbarType: NotificationType;
  snackbarBodyElement?: JSX.Element;
}
export const openSnackbar = (value: SnackbarOptions) => ({
  type: OPEN_SNACKBAR,
  payload: value.snackbarBody,
  dataTest: value.dataTest,
  snackbarType: value.snackbarType || SUCCESS,
  manualClose: value.manualClose || false,
  snackbarBodyElement: value.snackbarBodyElement,
});

export const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR,
  payload: {
    // do not add more properties to prevent content flicker
    snackbarOpen: false,
  },
});

export interface SnackbarNotificationOptions {
  payload: string | {
    firstName?: string;
    lastName?: string;
    truckAlias?: string;
  };
  dataTest?: string;
}

export const openSnackbarNotification = (value: SnackbarNotificationOptions) => ({
  type: OPEN_SNACKBAR_NOTIFICATION,
  payload: value.payload,
  dataTest: value.dataTest,
});

export const closeSnackbarNotification = () => ({
  type: CLOSE_SNACKBAR_NOTIFICATION,
  payload: {
    snackbarNotificationBody: '',
    snackbarNotificationOpen: false,
  },
});
