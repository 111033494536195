import React, { Component } from 'react';
import { render } from 'react-dom';
import {
  GoogleMap,
  Circle, Polygon,
} from '@react-google-maps/api';
import {
  LocationDto,
  OrderDto,
  PlantDto,
  UrlKeyDto,
  GeoZoneDto,
  RouteDto,
  StatusEventDto,
  PositionDto,
} from '@trucktrax/trucktrax-ts-common';
import { postGISToGoogleMapsPoints, vars } from '@trucktrax/trucktrax-common';

import { connect } from 'react-redux';
import { MapView, DefaultLocation } from './mapTypes';
import * as constants from '../../../constants/mapConstants';
import mapStyles from './map-style';
import RecenterMapBtn from './RecenterMapBtn';
import icons from './icons';
import { PinLocationInfo } from '../../../store/actions/mapviewActions';
import { DEFAULT_RADIUS, DROP_PIN_ICON, METERS_PER_MILE } from '../../../constants/mapConstants';

import { ZoneType } from '../../../types';
import { GEOZONE_TYPE, GEOZONE_SHAPE_STYLE } from '../../../constants/appConstants';
import LegendMapKey from './LegendMapKey';
import { retrieveProductLineNameDiff } from '../../../util/statusesUtil';
import { ReduxState } from '../../../store';
import styles from './RouteHistoryTTMap.module.css';
import TTMarker from './TTMarker';
import PositionMarker from './markers/PositionMarker';
import PlantMarkerWithInfoPane from './markers/PlantMarkerWithInfoPane';
import markerColors from './markers/MarkerColors';
import { noop } from '../../../util/appUtil';

interface MapChangeEvent {
  location?: LocationDto;
  circle?: {
    center: LocationDto;
    radius: number;
  };
  polygon?: {
    points: LocationDto[];
  }
}

type ShapeStyles = {
  fillColor: string;
  strokeColor: string;
  fillOpacity: number;
  strokeWeight: number;
  clickable: boolean;
  zIndex: number;
  draggable: boolean;
  editable: boolean;
};

type routeDataDto = {
  route: RouteDto,
  markerIdx: number
};

export class RouteHistoryTTMap extends Component<RouteHistoryTTMapProps, RouteHistoryTTMapState> {
  circleRef?: google.maps.Circle;

  defaultZoom = 9;

  listenersRef?: google.maps.MapsEventListener[];

  map?: google.maps.Map;

  polygonRef?: google.maps.Polygon;

  // Circle for the pin selection
  pinCircle: google.maps.Circle | null = null;

  // Pin circle marker
  pinMarker: google.maps.Marker | null = null;

  gmapControlPositions = (window as any).google.maps.ControlPosition;

  mapPositions = {
    leftBottom: this.gmapControlPositions.LEFT_BOTTOM,
    rightBottom: this.gmapControlPositions.RIGHT_BOTTOM,
    topRight: this.gmapControlPositions.TOP_RIGHT,
  };

  get hasRouteHistoryProductLines() {
    return this.props.isRouteHistory && (this.props.routeHistoryProductLines ?? []).length > 0;
  }

  constructor(props: RouteHistoryTTMapProps) {
    super(props);
    this.state = {
      isFirstMapRender: true,
      isMapHasBeenBounded: false,
    };
  }

  /** brings last selected to top and the rest of selected above unselected */
  static calculateZIndex = (lastMarkerSelected: string, truckId: string, isSelected: boolean, maxIdx: number) => {
    const [top, middle, bottom] = [100 + maxIdx, 50 + maxIdx, 10];
    if (lastMarkerSelected === truckId && isSelected) {
      return top;
    }
    return (isSelected) ? middle : bottom;
  };

  static mapsToLocationDto = (mapsLatLng: google.maps.LatLngLiteral | google.maps.LatLng | undefined | null) => {
    if (!mapsLatLng) {
      return {
        latitude: 0,
        longitude: 0,
      };
    }

    if (typeof mapsLatLng?.lat === 'function') {
      const latLng = mapsLatLng as google.maps.LatLng;
      return {
        latitude: latLng.lat(),
        longitude: latLng.lng(),
      };
    }

    const latLngLiteral = mapsLatLng as google.maps.LatLngLiteral;
    return {
      latitude: latLngLiteral.lat,
      longitude: latLngLiteral.lng,
    } as LocationDto;
  };

  componentDidMount() {
    this.reCenterMap();
  }

  componentDidUpdate(prevProps: RouteHistoryTTMapProps) {
    const {
      markers, currentRegion, routeHistoryProductLines, routeHistoryList, plantList
    } = this.props;

    if (plantList && prevProps.plantList && plantList.length !== prevProps.plantList.length) {
      // due to a poorly understood race condition bug
      // we need to force an update here or else the Plant Marker Tooltips will often render offscreen
      // specifically, the InfoBox component will apply "left" and "bottom" properties
      // which place them tens of thousands of pixels offscreen
      this.forceUpdate();
    }

    const currentRouteTimes = routeHistoryList?.map((r) => r.route?.[0]?.createdTimestamp ?? '0');
    const priorRouteTimes = prevProps.routeHistoryList?.map((r) => r.route?.[0]?.createdTimestamp ?? '0');
    if (routeHistoryList && routeHistoryList.length > 0
      && JSON.stringify(currentRouteTimes) !== JSON.stringify(priorRouteTimes)) {
      this.map?.setZoom(12);
    }
    if (markers?.length !== prevProps.markers?.length
      || (currentRegion?.url !== prevProps.currentRegion?.url)) {
      this.reCenterMap();
    }

    // add history legend updates
    if (routeHistoryProductLines !== prevProps.routeHistoryProductLines) {
      this.renderHistoryLegend();
    }

    // check the if the pin circle size changed
    if (this.props.isRouteHistory && this.pinCircle) {
      const storePinRadiusMeters = Number(this.props.pinRadiusMiles) * METERS_PER_MILE;
      const currentPinRadius = this.pinCircle.getRadius();
      if (storePinRadiusMeters !== currentPinRadius) {
        this.pinCircle.setRadius(storePinRadiusMeters);
      }
    }
  }

  renderPlants = () => this.props.plantList && this.props.plantList.map((plant) => {
    // the chosen color will be determined based on plant id, so that plants show up with a stable consistent color
    const colorIndex = plant.id % markerColors.length;
    return (
      <PlantMarkerWithInfoPane
        key={plant.id}
        plant={plant}
        mapRef={this.map}
        markerColor={markerColors[colorIndex]}
        updateToFieldValueWithPlantDrivers={noop}
        onClick={noop}
      />
    );
  });

  onLoad = (map: any) => {
    this.map = map;
    this.renderHistoryLegend();
    this.renderRecenterMapButton();
  };

  renderRecenterMapButton = () => {
    const controlButtonDiv = document.createElement('div');
    render(<RecenterMapBtn onClick={this.reCenterMap} />, controlButtonDiv);
    this.map!.controls[this.mapPositions.rightBottom].push(controlButtonDiv);
  };

  renderHistoryLegend = () => {
    const { routeHistoryProductLines } = this.props;
    if (!this.hasRouteHistoryProductLines) {
      this.map!.controls[this.mapPositions.leftBottom].clear();
      return;
    }
    // gets the Legend Map Key component
    const controlButtonDiv = document.createElement('div');
    render(<LegendMapKey productLines={routeHistoryProductLines!} />, controlButtonDiv);

    this.map!.controls[this.mapPositions.leftBottom].clear();
    this.map!.controls[this.mapPositions.leftBottom].push(controlButtonDiv);
  };

  validMarkerFilter = (item: any) => (
    item.active === true
    && item.trackedObject
    && item.region
    && item.region?.url === this.props.currentRegion.url
    && item.driver
  );

  zoomToBounds = () => {
    let boundsNumber = 0;
    const bounds = new (window as any).google.maps.LatLngBounds();

    const extendBounds = (lat?: number, lng?: number): number => {
      const point = new (window as any).google.maps.LatLng(lat, lng);
      bounds.extend(point);
      return 1;
    };

    const {
      markers, currentRegion,
    } = this.props;

    if (!this.state.isFirstMapRender) return;

    const validMarkerList = markers!.filter(this.validMarkerFilter);

    // This code shows the map center somehow
    const position = validMarkerList.length === 1 ? validMarkerList[0].location : currentRegion.location;
    const { latitude, longitude } = position!;
    boundsNumber += extendBounds(latitude, longitude);
    // no need to increment boundsNumber a second time
    extendBounds(latitude! + 0.0003, longitude! + 0.0003);

    if (this.map) {
      if (boundsNumber <= 1) {
        this.map.setCenter(bounds.getCenter());
        this.map.setZoom(this.defaultZoom);
      } else {
        this.map.fitBounds(bounds);
      }
      this.setState({ isFirstMapRender: false });
    }
  };

  reCenterMap = () => {
    this.setState({ isFirstMapRender: true }, this.zoomToBounds);
  };

  handleCenterChange = () => {
    if (this.map && this.map.getCenter && this.props.onCenterChange) {
      this.props.onCenterChange!(JSON.stringify(this.map.getCenter()?.toJSON()));
    }
  };

  prevChange = {};

  onChange(change: MapChangeEvent) {
    if (!this.map) return;
    if (JSON.stringify(this.prevChange) === JSON.stringify(change)) {
      return;
    }

    this.prevChange = change;
    if (this.props.onChange !== undefined) { this.props.onChange(change); }
  }

  onCircleLoad = (circle: google.maps.Circle) => {
    this.circleRef = circle;
    if (!this.state.isMapHasBeenBounded) {
      this.setState({ isMapHasBeenBounded: true }, () => this.zoomToBoundsCP());
    }
  };

  onCircleUnmount = () => {
    this.listenersRef = [];
    this.circleRef = undefined;
  };

  onCircleChanged = () => {
    if (!this.map) return;
    this.onCircleComplete(this.circleRef!);
  };

  onCircleComplete = (circle?: google.maps.Circle) => {
    if (!this.map) return;
    if (!circle) return;
    const center = RouteHistoryTTMap.mapsToLocationDto(circle.getCenter());
    this.onChange({
      circle: {
        center,
        radius: circle.getRadius(),
      },
      polygon: undefined,
    });
  };

  renderGeozoneCircle = (shapeStyles: ShapeStyles) => {
    const zone = this.props.currentGeozone?.zone;
    return (zone?.circle && zone?.circle.center)
      && (
        <Circle
          center={{
            lat: zone?.circle.center.latitude!,
            lng: zone?.circle.center.longitude!,
          }}
          onLoad={this.onCircleLoad}
          radius={zone?.circle.radius}
          onRadiusChanged={this.onCircleChanged}
          onCenterChanged={this.onCircleChanged}
          onUnmount={this.onCircleUnmount}
          options={shapeStyles}
        />
      );
  };

  onPolygonChanged = () => {
    this.onPolygonComplete(this.polygonRef!);
  };

  // Clean up refs
  onPolygonUnmount = () => {
    this.listenersRef!.forEach(lis => lis.remove());
    this.listenersRef = [];
    this.polygonRef = undefined;
  };

  onPolygonComplete = (polygon: google.maps.Polygon) => {
    const newPolygon = polygon.getPath().getArray().map(point => ({
      lat: point.lat(),
      lng: point.lng(),
    }));
    const points = RouteHistoryTTMap.googleMapsToPostGISPoints(newPolygon) ?? [];
    this.onChange({
      circle: undefined,
      polygon: {
        points,
      },
    });
  };

  static googleMapsToPostGISPoints = (points?: { lat: number, lng: number }[]) => {
    if (!points) return null;

    points.push(points[0]);
    return points.map(point => ({
      latitude: point.lat,
      longitude: point.lng,
    }));
  };

  getGeofenceStyle(zoneType?: ZoneType) {
    const shapeStyles: ShapeStyles = {
      fillColor: '#67B4F6',
      strokeColor: '#67B4F6',
      fillOpacity: 0.25,
      strokeWeight: 2,
      clickable: false,
      zIndex: 1,
      draggable: true,
      editable: this.props.isEditable ?? false,
    };

    type GeozoneType = typeof GEOZONE_SHAPE_STYLE;
    type GeozoneShapeStyle = {
      fillColor: string;
      strokeColor: string;
      zIndex: number;
    };

    const zone: { [geozoneName: string]: GeozoneShapeStyle } = {};

    zone[GEOZONE_TYPE.PLANT] = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.PLANT as keyof GeozoneType],
      zIndex: 3,

    };
    zone[GEOZONE_TYPE.INQUEUE] = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.INQUEUE as keyof GeozoneType],
      zIndex: 2,
    };
    zone[GEOZONE_TYPE.RETURN] = {
      ...GEOZONE_SHAPE_STYLE[GEOZONE_TYPE.RETURN as keyof GeozoneType],
      zIndex: 1,
    };

    return zoneType
      ? { ...shapeStyles, ...zone[zoneType] }
      : shapeStyles;
  }

  onPolygonLoad = (polygon: google.maps.Polygon) => {
    this.polygonRef = polygon;
    if (!this.listenersRef) this.listenersRef = [];
    const path = polygon.getPath();
    this.listenersRef.forEach(lis => lis.remove());
    this.listenersRef = [
      path.addListener('set_at', this.onPolygonChanged),
      path.addListener('insert_at', this.onPolygonChanged),
      path.addListener('remove_at', this.onPolygonChanged),
    ];

    if (!this.state.isMapHasBeenBounded) {
      this.setState({ isMapHasBeenBounded: true }, () => this.zoomToBoundsCP());
    }
  };

  isValidLocation = () => {
    const location = this.props.currentGeozone?.location;
    const lat = location?.latitude ?? 0;
    const lng = location?.longitude ?? 0;
    return (lat !== 0 || lng !== 0);
  };

  zoomToBoundsCP = () => {
    if (!this.map) return;
    const bounds = new window.google.maps.LatLngBounds();

    if (this.isValidLocation()) {
      const location = this.props.currentGeozone?.location;
      bounds.extend(new window.google.maps.LatLng(
        (location?.latitude ?? 0) - 0.001,
        (location?.longitude ?? 0) - 0.001
      ));
      bounds.extend(new window.google.maps.LatLng(
        (location?.latitude ?? 0) + 0.001,
        (location?.longitude ?? 0) + 0.001
      ));
    }

    if (this.circleRef) {
      bounds.union(this.circleRef.getBounds()!);
    }

    if (this.polygonRef) {
      this.polygonRef.getPath().getArray().forEach(point => {
        bounds.extend(point);
      });
    }

    this.map.fitBounds(bounds);
  };

  generateGeozonePolygon = (shapeStyles: ShapeStyles) => {
    const zone = this.props.currentGeozone?.zone;
    return (zone?.polygon && zone?.polygon.points) // && !this.props.editMarkerOnly)
      && (
        <Polygon
          path={postGISToGoogleMapsPoints(this.props.currentGeozone?.zone?.polygon?.points) as any}
          onLoad={this.onPolygonLoad}
          editable
          // Event used when dragging the whole Polygon
          onDragEnd={this.onPolygonChanged}
          // Event used when manipulating and adding points
          onMouseUp={this.onPolygonChanged}
          onUnmount={this.onPolygonUnmount}
          options={shapeStyles}
        />
      );
  };

  onRouteMarkerClick = (marker: MarkerType) => this.props.onRouteMarkerClick!(marker);

  onMarkerClose = (marker: MarkerType) => this.props.onMarkerClose!(marker);

  processGeocodeReply = (results: any, status: google.maps.GeocoderStatus) => {
    // eslint-disable-next-line no-undef
    if (status === google.maps.GeocoderStatus.OK) {
      if (results !== null && results[0]) {
        // Updates the value in the storage
        this.props.onPinChanged!({
          pinMode: false,
          pinDropped: true,
          location: results[0].formatted_address,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      }
    }
  };

  onRadiusChanged = () => {
    // Extracts the radius value in miles
    const radius = (this.pinCircle!.getRadius() / METERS_PER_MILE);
    // Updates the value in the storage
    this.props.onPinRadiusChanged!(radius.toString());
  };

  /**
  * Actives the pin mode in the map for the user
  * @param {any} mapsMouseEvent mouse map click event with the information of the location
  */
  dropPinMode = (mapsMouseEvent: any) => {
    const { map: mapRef } = this;
    // Icon to use in the marker
    // TODO Research if we can improve the look and feel of the zoom-in and zoom-out actions
    const icon = {
      path: icons.pinPath,
      fillColor: vars.blue,
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: vars.white,
      scale: 1,
      anchor: new (window as any).google.maps.Point(10, 10),
    };

    // Pin marker
    this.pinMarker = new (window as any).google.maps.Marker({
      position: mapsMouseEvent.latLng,
      map: mapRef,
      icon,
    });

    // Pin circle
    this.pinCircle = new (window as any).google.maps.Circle({
      center: this.pinMarker!.getPosition(),
      strokeColor: vars.orange,
      strokeWeight: 1,
      fillColor: vars.orange,
      fillOpacity: 0.1,
      radius: (DEFAULT_RADIUS * METERS_PER_MILE),
      map: mapRef,
      editable: true,
    });

    // Pin circle listener for radius changed event
    this.pinCircle!.addListener('radius_changed', this.onRadiusChanged);

    // Geocode location, this call will receive the lat and lng location values and return the string formatted address
    new (window as any).google.maps.Geocoder().geocode({ location: mapsMouseEvent.latLng }, this.processGeocodeReply);

    // Resets the map cursor
    mapRef!.setOptions({ draggableCursor: undefined });
  };

  static getRouteHistoryPositionStatus = (position: PositionDto, statuses: StatusEventDto[]) => {
    // if the statuses have only one, returns the position 0
    if (statuses.length === 1) return statuses[0].status;
    // gets the truckStatus
    const truckStatus = statuses.filter((s: StatusEventDto) => s.timestamp === position.timestamp);
    // if the truck status has the exact timestamp returns the status value
    if (truckStatus.length > 0) {
      return truckStatus[0].status;
    }

    // the timestamp can be in a range between all the statuses, loop through all of them to get the status
    for (let i = 0; i < statuses.length;) {
      if (statuses[i] !== undefined && statuses[i + 1] !== undefined) {
        const currentTime = statuses[i].timestamp as string;
        const nextTime = statuses[i + 1].timestamp as string;
        const positionTime = position.timestamp as string;
        if (currentTime < positionTime && nextTime > positionTime) {
          return statuses[i].status;
        }
      }
      i += 1;
    }

    // the for loop above will not apply a status for the final status in the list, because
    // statuses[i + 1] will always be undefined when statuses[i] is the final status in the list
    // so let's handle the final status below
    if (statuses.length > 0) {
      const lastStatusInList = statuses[statuses.length - 1];
      const lastStatusTime = lastStatusInList.timestamp;

      if (lastStatusTime && position.timestamp && position.timestamp > lastStatusTime) {
        return lastStatusInList.status;
      }
    }

    // Default status if don't find one
    return 'Unknown';
  };

  routeIsSelected = (route: RouteDto) => this.props.selectedRouteHistoryList?.includes(route.id) ?? false;

  routeIsHovered = (route: RouteDto) => this.props.routeHistoryHoverSelection?.id === route.id;

  hoveredRouteOrAllRoutes = (route: RouteDto) => (this.props.routeHistoryHoverSelection
    ? this.routeIsHovered(route)
    : true // no route is hovered, so all routes are returned
  );

  renderPositionMarkers = (route: RouteDto, startIdx: number, maxIdx: number) => {
    const positions = route.route;

    // if we need to display more than 500 markers in total, then let's show PNG icons instead of SVG icons to improve performance
    const numTotalMarkers = maxIdx;
    const maxNumOfSvgMarkers = 500;
    const shouldUsePng = numTotalMarkers > maxNumOfSvgMarkers;

    return (positions.map((position, idx) => {
      const trackedTruckStatuses = route.statuses.filter((s: StatusEventDto) => s.truck?.url === position.trackedObject.url);

      const truckDetails = {
        ...position,
        trackedObject: {
          id: position.trackedObject,
          truckAlias: route.truck.truckAlias,
        },
        status: RouteHistoryTTMap.getRouteHistoryPositionStatus(position, trackedTruckStatuses),
      };

      const positionId = position.id as string;
      const urlToArray = position.trackedObject!.url!.split('/');
      const truckId = urlToArray[urlToArray.length - 1];
      const isSelected = (this.props.popupToggleMap as PopupToggleMapType)[positionId];

      const { lastMarkerSelected } = this.props.popupToggleMap as PopupToggleObjectType;
      const zIndex = startIdx + idx + RouteHistoryTTMap.calculateZIndex(lastMarkerSelected, truckId, isSelected, maxIdx);
      const markerData: MarkerDataType = {
        markerId: positionId,
        type: 'Truck',
        driverDetails: position.driver,
        truckDetails,
        ticket: position.ticket!,
      };
      const marker: MarkerType = {
        markerId: positionId,
        type: 'Truck',
        markerData,
      };

      return (
        <PositionMarker
          displayPNG={shouldUsePng}
          parentRouteId={route.id}
          isRouteHistory
          key={position.id}
          zIndex={zIndex}
          mapRef={this.map}
          position={truckDetails}
          onClick={() => this.onRouteMarkerClick(marker)}
          onCloseClick={() => this.onMarkerClose(marker)}
          markerData={{
            driverDetails: {
              firstName: route.truck.driverName,
            },
            truckDetails,
            ticket: route.ticket,
            order: route.order,
          }}
          markerStatusMap={retrieveProductLineNameDiff(
            position.ProductLine
          )}
          hideLabel
          isSelected={isSelected}
        />
      );
    })
    );
  };

  static renderTableRow = (header: string, value: string | undefined) => {
    const val = value === '' ? undefined : value; // empty strings have no place here
    return (
      <tr>
        <th>{header}</th>
        <td>{val ?? 'N/A'}</td>
      </tr>
    );
  };

  static renderRouteInfoAsTable = (route: RouteDto) => {
    const { order, ticket, truck } = route;
    return (
      <table className={styles.routePopup}>
        <colgroup>
          <col className={styles.labelColumn} />
          <col className={styles.contentColumn} />
        </colgroup>
        <tbody>
          {RouteHistoryTTMap.renderTableRow('Order', order?.orderNumber)}
          {RouteHistoryTTMap.renderTableRow('Ticket', ticket?.ticketNumber)}
          {RouteHistoryTTMap.renderTableRow('Driver', truck?.driverName)}
        </tbody>
      </table>
    );
  };

  static findCenterPosition = (positions: PositionDto[]): PositionDto | null => {
    if (!positions || positions.length === 0) return null;
    const centerIndex = Math.floor(positions.length / 2);
    return positions[centerIndex!];
  };

  renderHoveredRouteInfo = (route: RouteDto) => {
    // exit if this route is not hovered
    if (!this.routeIsHovered(route)) return null;

    // get the "middle" position of the route as an anchor point
    const centerPosition = RouteHistoryTTMap.findCenterPosition(route.route);
    // exit if there aren't enough positions to use as an anchor
    if (centerPosition === null) return null;

    const { latitude, longitude } = centerPosition.location!;
    const position = {
      latitude, longitude, lat: latitude, lng: longitude,
    };

    const icon = {
      path: icons.circlePath,
      fillColor: vars.blue,
      fillOpacity: 0,
      strokeWeight: 0,
      strokeColor: vars.white,
      scale: 1,
    };

    const pixelOffset: google.maps.Size = {
      width: 0,
      height: 0,
      equals: (other) => other?.width === 0 && other.height === 0,
    };

    return (
      <TTMarker
        key={`marker${0}`}
        mapRef={this.map}
        zIndex={999}
        icon={icon}
        position={position}
        pixelOffset={pixelOffset}
        infoPaneTitle={`Truck #${route.truck.alias}`}
        infoPaneContent={RouteHistoryTTMap.renderRouteInfoAsTable(route)}
        isSelected
        isLabelHover
        isRouteHistory={this.props.isRouteHistory}
        manualInfoPosition
      />
    );
  };

  renderRouteHistory = () => {
    const routes = (this.props.routeHistoryList ?? [])
      .filter(this.routeIsSelected) // discard deselected routes first
      .filter(this.hoveredRouteOrAllRoutes);

    let totalMarkers = 0;
    const routeData: Array<routeDataDto> = [];
    routes.forEach((route) => {
      const positionCount = route.route.length;
      const routeDatum: routeDataDto = {
        route,
        markerIdx: totalMarkers
      };
      routeData.push(routeDatum);
      totalMarkers += positionCount;
    });

    return routeData.map((data) => (
      <RouteHistory>
        {this.renderHoveredRouteInfo(data.route) /* will only render if a route is hovered */}
        {this.renderPositionMarkers(data.route, data.markerIdx, totalMarkers)}
      </RouteHistory>
    ));
  };

  render() {
    const isPinActive = this.props.pinMap === true ? DROP_PIN_ICON : undefined;
    const onclickMode = this.props.pinMap === true ? this.dropPinMode : undefined;
    const containerStyle = {
      width: '100%',
      height: '100%',
    };
    const definedZoom = this.map?.getZoom() ?? this.defaultZoom;
    const geoFenceStyle = this.getGeofenceStyle(this.props.geofenceAreaType);

    const map = (
      <GoogleMap
        center={this.props.center}
        mapContainerStyle={containerStyle}
        options={{
          zoom: definedZoom,
          streetViewControl: false,
          mapTypeControl: true,
          fullscreenControl: false,
          rotateControl: false,
          controlSize: constants.GM_CONTROLSIZE,
          mapTypeControlOptions: {
            mapTypeIds: [
              (window as any).google.maps.MapTypeId.ROADMAP,
              (window as any).google.maps.MapTypeId.SATELLITE,
            ],
            style: (window as any).google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: this.mapPositions.topRight,
          },
          styles: mapStyles,
          disableDoubleClickZoom: false,
          draggableCursor: isPinActive,
        }}
        onLoad={this.onLoad}
        onDragEnd={this.handleCenterChange}
        onClick={onclickMode}
      >
        {this.renderGeozoneCircle(geoFenceStyle)}
        {this.generateGeozonePolygon(geoFenceStyle)}
        {this.renderRouteHistory()}
        {this.renderPlants()}
      </GoogleMap>
    );
    return map;
  }
}

export type PopupToggleMapType = { [key: string]: boolean };
type PopupToggleObjectType = { lastMarkerSelected: string };

interface RegionWithLocation extends UrlKeyDto {
  location?: LocationDto
}

export interface MarkerDataType {
  markerId: string,
  type: string,
  driverDetails: any,
  truckDetails?: MapView,
  ticket: UrlKeyDto,
  status?: string
}

export interface MarkerType {
  markerId: string,
  type: string,
  markerData: OrderDto | MarkerDataType | PlantDto
}

export function mapStateToProps(state: ReduxState) {
  return {
    routeHistoryHoverSelection: state.routeHistoryHoverSelection,
    pinRadiusMiles: state.pinRadiusMiles,
    plantList: state.plantList,
  };
}

type ReduxProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps {
  polygon?: {
    points: LocationDto[];
  };
  currentRegion: RegionWithLocation,
  markers?: MapView[],
  center?: DefaultLocation,
  onCenterChange?: (json: string) => void,
  popupToggleMap?: PopupToggleMapType | PopupToggleObjectType,
  onRouteMarkerClick?: (marker: MarkerType) => void,
  onMarkerClose?: (marker: MarkerType) => void,
  currentGeozone?: GeoZoneDto,
  geofenceAreaType?: ZoneType,
  isEditable?: boolean,
  onChange?: (evt: MapChangeEvent) => void;
  pinMap?: boolean,
  onPinChanged?: (pinInfo: PinLocationInfo) => void,
  onPinRadiusChanged?: (radius: string) => void,
  isRouteHistory?: boolean,
  routeHistoryProductLines?: string[],
  routeHistoryList: RouteDto[] | null,
  selectedRouteHistoryList?: number[]
}

export interface RouteHistoryTTMapState {
  isFirstMapRender: boolean,
  isMapHasBeenBounded: boolean
}

export type RouteHistoryTTMapProps = OwnProps & ReduxProps;

export default connect(mapStateToProps)(RouteHistoryTTMap);

export const RouteHistory: React.FunctionComponent<{ children?: React.ReactNode }> = props => (
  <div className="route-history">
    {props.children}
  </div>
);
