import React, { Component } from 'react';
import styles from './Footer.module.css';

// eslint-disable-next-line react/prefer-stateless-function
export class ScaleTraxFooter extends Component {
  render() {
    return (
      <div className={styles.footer}>
        <div className={styles.footerText} />
        <div className={styles.footerTextRight}>
          <b>MANUF: </b>
          {' '}
          TRUCKTRAX
        </div>
        <div className={styles.footerText}>
          <b>
            MOD.
            {' '}
            NUM:
            {' '}
          </b>
          {' '}
          SCALETRAX
        </div>
        <div className={styles.footerTextLeft}>
          <b>VERSION NUM: </b>
          {' '}
          {process.env.REACT_APP_VERSION}
        </div>
        <div className={styles.footerText} />
      </div>
    );
  }
}

export default ScaleTraxFooter;
