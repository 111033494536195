/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { Button, DateWrapper } from '@trucktrax/trucktrax-common';
import { RegionDto } from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import styles from './TokenRefresh.module.css';
import { noop } from '../../../util/appUtil';
import { isTokenExpired } from '../../../util/adminUtil';
import CardDisplay from '../../shared/forms/CardDisplay';

export default function TokenRefresh(props: TokenRefreshProps) {
  const {
    className = '',
    value = undefined,
    buttonClassName = '',
    registrationDisplayDataTest = '',
    registrationExpirationDisplayDataTest = '',
    container = '',
    setData = noop,
    requestToken,
    deleteToken,
  } = props;

  let expirationDate: JSX.Element | string = '';
  if (value && value.registrationTokenExpiration) {
    if (isTokenExpired(value.registrationTokenExpiration)) {
      expirationDate = <span className={styles.expired}>Expired</span>;
    } else {
      expirationDate = new DateWrapper(value.registrationTokenExpiration).toUSDateString();
    }
  }
  return (
    <div className={className}>
      {
        (value && value.registrationToken && value.registrationToken !== 'N/A')
          ? (
            <div className={container}>
              <CardDisplay
                value={value.registrationToken}
                label="Token"
                dataTest={registrationDisplayDataTest}
                className={styles.tokenDisplay}
              />
              <CardDisplay
                value={expirationDate}
                label="Expiration Date"
                dataTest={registrationExpirationDisplayDataTest}
                className={styles.expirationDateDisplay}
              />
              <div className={styles.buttonGroup}>
                <Button
                  buttonClassName={cx(styles.buttonClass, 'tt-btn-secondary')}
                  onClick={() => requestToken(value, setData)}
                  iconClassName="icon-refresh"
                  dataTest="replace-region-token-btn"
                  name="Replace Token"
                />
                <Button
                  buttonClassName={cx(styles.buttonClass, 'tt-btn-secondary--error', 'margin-left-10')}
                  onClick={() => deleteToken(value, setData)}
                  iconClassName="icon-delete"
                  dataTest="delete-region-token-btn"
                  name="Delete Token"
                />
              </div>
            </div>
          )
          : (
            <Button
              buttonClassName={buttonClassName}
              onClick={() => requestToken(value!, setData)}
              dataTest="create-region-token-btn"
              name="Create Token"
            />
          )
      }
    </div>
  );
}

type TokenInfo = Pick<RegionDto, 'registrationToken' | 'registrationTokenExpiration'>;
export interface TokenRefreshProps {
  className?: string;
  buttonClassName?: string;
  value?: TokenInfo;
  requestToken: (value: TokenInfo, setData: VoidFunction) => void;
  deleteToken: (value: TokenInfo, setData: VoidFunction) => void;
  setData?: VoidFunction;
  registrationExpirationDisplayDataTest?: string;
  registrationDisplayDataTest?: string;
  container?: string;
}
