import React, { Component } from 'react';
import cx from 'classnames';
import { includes } from 'lodash';
import { UrlKeyDto } from '@trucktrax/trucktrax-ts-common';

import { PanelToggle, Driver } from '@trucktrax/trucktrax-common';
import { MessageTabs } from '../../../constants/messagingConstants';
import { checkAckTime } from './message';

import styles from './SidebarContainer.module.css';
import LeftSidebar from './MessageCenter/LeftSidebar';
import RightSidebar from './MessageCenter/RightSidebar';
import {
  Acknowledgement,
  ConversationMessagesDto,
  ConversationToMessagesDto,
  MessageCenterInfo,
  SocketAcknowledgement,
} from './index.d';
import { AcknowledgeDto } from '../../../services/messagesService';

class SidebarContainer extends Component<SidebarContainerProps, SidebarContainerState> {
  constructor(props: SidebarContainerProps) {
    super(props);

    this.state = {
      panelOpen: true,
      retrievedMessages: [],
    };
  }

  // sent is default but hidden when new message tab is selected
  getSelectedMessage = (): ConversationMessagesDto | undefined => (
    this.isInbox()
      ? this.state.selectedInboxMessage
      : this.state.selectedSentMessage
  );

  clearSelectedMessage = () => {
    this.setState({
      selectedInboxMessage: undefined,
      panelOpen: false,
    });
    this.props.setIsPanelOpen(false);
  };

  markUnread = (selectedMessage: ConversationMessagesDto) => {
    const acknowledgementList = selectedMessage.conversationAcknowledgements;
    if (!acknowledgementList?.length) {
      return;
    }

    // acknowledgementList MUST contain party.
    const partyAck = acknowledgementList.find(ack => ack.partyMember.url === this.props.currentRegion.url);
    const socketAcknowledgement: SocketAcknowledgement = {
      ...partyAck!,
      region: { url: this.props.currentRegion.url! },
      url: partyAck!.conversation.url,
    };

    this.props.onMarkUnread(socketAcknowledgement);
    this.setState({ panelOpen: false });
    this.props.setIsPanelOpen(false);
  };

  // sent is default but hidden when new message tab is selected
  selectMessage = (selectedMessage: ConversationMessagesDto) => {
    // mark as read under these conditions
    if (this.props.currentTab === MessageTabs.Inbox) {
      const acknowledgementList = selectedMessage.conversationAcknowledgements;
      const latestMessageIdx = selectedMessage.items.length - 1;
      const latestMessage = selectedMessage.items[latestMessageIdx];
      if (acknowledgementList) {
        // acknowledgementList MUST contain party.
        const findIndex = (ack: Acknowledgement) => ack.partyMember.url === this.props.currentRegion.url;
        const partyIndex = acknowledgementList.findIndex(findIndex);
        const socketAcknowledgement: SocketAcknowledgement = {
          ...acknowledgementList[partyIndex],
          region: { url: this.props.currentRegion.url! },
          url: acknowledgementList[partyIndex].conversation.url,
        };

        const isRetrieveSuccessful = (isSuccess: boolean) => isSuccess;
        const isNotInitialLoad = includes(
          this.state.retrievedMessages,
          socketAcknowledgement.conversation.url
        );

        // acknowledge conversation and retrieve list of messages for the conversation
        if (socketAcknowledgement !== undefined) {
          this.props.onAcknowledge(
            socketAcknowledgement,
            checkAckTime(latestMessage.sent, socketAcknowledgement.lastAcknowledged),
            isNotInitialLoad,
            isRetrieveSuccessful
          );
        }

        const { retrievedMessages } = this.state;
        this.setState({
          retrievedMessages: [...retrievedMessages, socketAcknowledgement.conversation.url],
        });
      }
      this.setState({
        selectedInboxMessage: selectedMessage,
        panelOpen: true,
      });
      this.props.setIsPanelOpen(true);
    }
  };

  isNewMessage = () => this.props.currentTab === MessageTabs.New;

  isPanelOpen = () => (
    !this.isNewMessage() && this.getSelectedMessage()
      ? this.state.panelOpen
      : false
  );

  panelToggle() {
    return !this.isNewMessage() && this.getSelectedMessage() && this.state.panelOpen
      ? (
        <PanelToggle
          className={cx(styles.panel)}
          open={this.state.panelOpen}
          onClick={this.clearSelectedMessage}
          messageOpen="Open conversation"
          messageClose="Close conversation"
        />
      ) : null;
  }

  isInbox() {
    return this.props.currentTab === MessageTabs.Inbox;
  }

  render() {
    const selectedMessage = this.getSelectedMessage();
    const delayDriverFocus = !!this.state.selectedInboxMessage && !this?.props?.isExpand;
    const isPanelOpen = this.isPanelOpen();
    const isInbox = this.isInbox();

    return (
      <div className={styles.containerStyle} data-expanded={this?.props?.isExpand || false} data-showing={selectedMessage ? 'yes' : 'no'}>
        <div className={styles.selectionSide}>
          {this.panelToggle()}

          <LeftSidebar
            currentRegion={this.props.currentRegion}
            currentTab={this.props.currentTab}
            unreadCount={this.props.unReadCount}
            onSubmit={this.props.onSubmit}
            allMessages={this.props.allMessages}
            onGetInboxMessages={this.props.onGetInboxMessages}
            selectedMessage={selectedMessage}
            delayDriverFocus={delayDriverFocus}
            driverList={this.props.driverList}
            messageCenter={this.props.messageCenter}
            onToFieldChange={this.props.onToFieldChange}
            onCurrentRecipientSearchTextChange={this.props.onCurrentRecipientSearchTextChange}
            onMessageFieldChange={this.props.onMessageFieldChange}
            onItemClicked={this.selectMessage}
            onTabChange={this.props.onTabChange}
            selectedProductLine={this.props.selectedProductLine}
            onMarkUnread={this.markUnread}
          />

        </div>
        <div className={styles.contentSide}>

          <RightSidebar
            selectedMessage={selectedMessage}
            backButtonAction={this.clearSelectedMessage}
            replyMap={this.props.replyMap}
            isPanelOpen={isPanelOpen}
            isInbox={isInbox}
            onUpdateReplyMap={this.props.onUpdateReplyMap}
            onSubmit={this.props.onSubmit}
            driverList={this.props.driverList}
            selectedProductLine={this.props.selectedProductLine}
            isExpand={this.props.isExpand}
            currentRegion={this.props.currentRegion}
            onMessageFieldChange={this.props.onMessageFieldChange}
            messageCenter={this.props.messageCenter}
          />

        </div>
      </div>
    );
  }
}

export interface SidebarContainerState {
  panelOpen: boolean;
  selectedInboxMessage?: ConversationMessagesDto;
  selectedSentMessage?: ConversationMessagesDto;
  retrievedMessages: string[];
}

export interface SidebarContainerProps {
  currentTab: MessageTabs;
  allMessages: ConversationToMessagesDto[];
  unReadCount: boolean;
  replyMap: Map<string, string>;
  driverList: Driver[];
  messageCenter: MessageCenterInfo;
  currentRegion: UrlKeyDto;
  onToFieldChange: () => void;
  onCurrentRecipientSearchTextChange: (newSearchText: string) => void;
  onMessageFieldChange: (message: string) => void;
  onAcknowledge: (
    ack: SocketAcknowledgement,
    ackTime: boolean,
    isNotInitialLoad: boolean,
    retrievalSuccessfulFn: (success: boolean) => boolean
  ) => void;
  onMarkUnread: (ack: AcknowledgeDto) => void;
  onTabChange: () => void;
  onUpdateReplyMap: () => void;
  onGetInboxMessages: () => void;
  onSubmit: () => void;
  selectedProductLine?: string;
  setIsPanelOpen: (panelOpen: boolean) => void;
  isExpand?: boolean;
}

export default SidebarContainer;
