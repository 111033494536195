/* eslint-disable jsx-a11y/label-has-for,no-undef */
import React, { Component } from 'react';
import cx from 'classnames';
import { GoogleMap } from '@react-google-maps/api';

import { connect } from 'react-redux';
import { constants } from '@trucktrax/trucktrax-common';
import {
  OrderDto, PlantDto, PositionDto, ProductLine, TicketDto, VoidFunc,
}
  from '@trucktrax/trucktrax-ts-common';
import routeStyles from './RouteInfoMap.module.css';
import { fetchStatusList } from '../../../../services/statusesService';
import { retrieveProductLineNameDiff } from '../../../../util/statusesUtil';
import JobsiteMarker from '../../../geotrax/map/markers/JobsiteMarker';
import PositionMarker from '../../../geotrax/map/markers/PositionMarker';
import PlantMarker from '../../../geotrax/map/markers/PlantMarker';
import { getOrder } from '../../../../services/ordersService';
import { ConnectedDispatchFunction } from '../../../../types';
import { getIdFromUrl } from '../../../../util/appUtil';

export class RouteInfoMap extends Component<RouteInfoMapProps, RouteInfoMapState> {
  static defaultProps = {
    selectedItemId: undefined,
    selectedProductLine: undefined,
  };

  defaultZoom = 10;

  mapRef?: google.maps.Map;

  constructor(props: RouteInfoMapProps) {
    super(props);
    this.state = {
      preventMapRecenter: false,
    };
  }

  zeroPosition = new google.maps.LatLng(0, 0);

  async componentDidMount() {
    const { selectedTicket } = this.props;

    const orderId = getIdFromUrl(selectedTicket.order?.url);
    const order = await this.props.getOrder(
      Number(orderId),
      selectedTicket.region?.url
    );

    this.setState({
      order,
    });

    this.generateBounds();
  }

  componentDidUpdate(prevProps: RouteInfoMapProps) {
    this.recenterMap(prevProps);

    // Center the map when the ticket is on print mode
    this.printRecenterMap(prevProps);
  }

  recenterMap(prevProps: RouteInfoMapProps) {
    if (this.props && this.props.mapCentered !== prevProps.mapCentered) {
      this.zoomToBounds();
    }
  }

  /**
   * Recenter the map when the user wants to print the route info map
   * @param {RouteInfoMapProps} prevProps previews map props
   */
  printRecenterMap(prevProps: RouteInfoMapProps) {
    if (this.props.isTicketPrintViewCenter === true && this.props.isTicketPrintViewCenter !== prevProps.isTicketPrintViewCenter) {
      this.props.recenterMap();
      // Reset the zoom to fit the route in the print map view
      if (this.mapRef) {
        if (Number(this.mapRef.getZoom()) > this.defaultZoom) {
          this.mapRef!.setZoom(this.defaultZoom);
        }
      }
    }
  }

  onMapLoad = (map: google.maps.Map) => {
    this.mapRef = map;
  };

  zoomToBounds = () => {
    if (this.mapRef) {
      this.mapRef.fitBounds(this.state.mapBounds!, 50);
      this.mapRef!.setZoom(this.defaultZoom);
    }
    this.props.recenterMap();
  };

  renderPositionMarkers = () => {
    const { selectedTicket } = this.props;
    const positions = this.props.positionsList.data.items;
    const maximumNumberOfSVG = 500;

    return positions.map((position: PositionDto) => {
      const truckDetails = {
        ...position,
        trackedObject: {
          ...position.trackedObject,
          truckAlias: selectedTicket.externalInfo?.truckAlias,
        },
      };

      return (
        <PositionMarker
          displayPNG={positions.length > maximumNumberOfSVG}
          isRouteInfo
          key={position.id}
          position={truckDetails}
          mapRef={this.mapRef}
          selectedItemId={this.props.selectedItemId}
          onClick={() => this.props.updateSelectedItem(position)}
          onCloseClick={this.onPositionMarkerTooltipClose}
          markerStatusMap={retrieveProductLineNameDiff(
            this.props.selectedProductLine!
          )}
          markerData={{
            driverDetails: {
              firstName: selectedTicket.externalInfo?.driverName,
            },
            truckDetails,
            ticket: selectedTicket,
          }}
          hideLabel
        />
      );
    });
  };

  onPositionMarkerTooltipClose = () => {
    this.setState({
      preventMapRecenter: true,
    }, () => {
      this.props.clearSelectedItem();
    });
  };

  renderPlantMarker = () => {
    const { plantList, selectedTicket } = this.props;
    const plant = plantList?.find(p => p.url === selectedTicket.plant?.url);
    return plant && (
      <PlantMarker
        key={plant && plant.id}
        plant={plant}
        mapRef={this.mapRef}
      />
    );
  };

  // we can reconstitute most of the order information if we are missing an order for some reason
  // orders are sometimes not present on a ticket if there was an issue sending it from clearview
  static generateDummyOrder = (ticket: TicketDto) => ({
    geoZone: ticket.geoZone,
    id: null,
    orderNumber: null,
    customerName: ticket.order?.customerName,
  });

  renderJobsiteMarker = () => {
    const { order } = this.state;
    const { selectedTicket } = this.props;

    if (order) {
      return <JobsiteMarker mapRef={this.mapRef} order={order} />;
    }

    return (
      selectedTicket && (
        <JobsiteMarker
          mapRef={this.mapRef}
          order={RouteInfoMap.generateDummyOrder(selectedTicket) as any}
        />
      )
    );
  };

  generateBounds = () => {
    const bounds = new google.maps.LatLngBounds();
    const { plantList, selectedTicket, positionsList } = this.props;

    const allBounds = [
      ...positionsList.data.items.map(position => this.createLatLng(() => ({ ...position.location }))),
      this.ticketBounds(selectedTicket),
      this.plantBounds(plantList, selectedTicket),
    ];

    allBounds.forEach(bound => bounds.extend(bound));

    this.setState({ mapBounds: bounds }, this.zoomToBounds);
    this?.mapRef?.fitBounds(bounds);
  };

  ticketBounds(ticket: TicketDto): google.maps.LatLng {
    return this.createLatLng(
      () => ({ ...ticket.driverAcknowledgementLocation })
    );
  }

  plantBounds(plantList: PlantDto[], selectedTicket: TicketDto): google.maps.LatLng {
    return this.createLatLng(
      () => ({ ...selectedTicket.driverAcknowledgementLocation })
    );
  }

  createLatLng = (func: Function): google.maps.LatLng => {
    const location = func();
    if (location) return new google.maps.LatLng(location.latitude ?? 20, location.longitude ?? 20);
    return this.zeroPosition;
  };

  hasMapAvailable = () => {
    const mapCenter = this.getMapCenter();
    const { order } = this.state;

    const positions = this.props.positionsList.data.items;

    const hasPosition = (positions?.length ?? 0) > 0
      && positions?.some(p => p.location?.latitude || p.location?.longitude);

    return mapCenter
      || order?.geoZone?.zone.circle?.center?.latitude
      || hasPosition;
  };

  getMapCenter = () => {
    if (this.state.preventMapRecenter) {
      return undefined;
    }

    // Use the selected item as the center point if available
    if (this.props.selectedItemId) {
      const selectedPosition = this.props.positionsList.data.items.find((p) => p.id.toString() === this.props.selectedItemId);
      if (selectedPosition) {
        const selectedLocation = {
          lat: selectedPosition.location?.latitude as number,
          lng: selectedPosition.location?.longitude as number,
        };
        return selectedLocation;
      }
    }

    // Calculate the centerpoint from the positionsList data
    const positionBounds = {
      latMin: 90,
      latMax: -90,
      lngMin: 180,
      lngMax: -180,
    };

    // All the positions, the the order location too
    this.props.positionsList.data.items.forEach((position) => {
      if (position.location?.latitude && position.location?.longitude) {
        positionBounds.latMin = Math.min(positionBounds.latMin, position.location.latitude);
        positionBounds.latMax = Math.max(positionBounds.latMax, position.location.latitude);
        positionBounds.lngMin = Math.min(positionBounds.lngMin, position.location.longitude);
        positionBounds.lngMax = Math.max(positionBounds.lngMax, position.location.longitude);
      }
    });
    positionBounds.latMin = Math.min(positionBounds.latMin, (this?.state?.order?.geoZone?.zone?.circle?.center?.latitude ?? 90));
    positionBounds.latMax = Math.max(positionBounds.latMax, (this?.state?.order?.geoZone?.zone?.circle?.center?.latitude ?? -90));
    positionBounds.lngMin = Math.min(positionBounds.lngMin, (this?.state?.order?.geoZone?.zone?.circle?.center?.longitude ?? 180));
    positionBounds.lngMax = Math.max(positionBounds.lngMax, (this?.state?.order?.geoZone?.zone?.circle?.center?.longitude ?? -180));

    // This simply average min/max for the midpoint
    const latMid = (positionBounds.latMin + positionBounds.latMax) / 2;
    const lngMid = (positionBounds.lngMin + positionBounds.lngMax) / 2;

    return { lat: latMid, lng: lngMid };
  };

  render() {
    const definedZoom = this.mapRef?.getZoom() ?? this.defaultZoom;
    if (!this.hasMapAvailable()) {
      return (
        <div className={cx(routeStyles.noRouteContainer)}>
          <div className={cx(routeStyles.noRouteMessage)}>
            <i className={cx('icon-directions', routeStyles.noRouteIcon)} />
            <p className={cx(routeStyles.noRouteText)}>Route info unavailable</p>
          </div>
        </div>
      );
    }

    const containerStyle = {
      width: '100%',
      height: '100%',
    };

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={{
          center: this.getMapCenter(),
          zoom: definedZoom,
          draggable: true,
          streetViewControl: false,
          scaleControl: true,
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.SATELLITE,
            ],
            position: google.maps.ControlPosition.TOP_RIGHT,
          },
          controlSize: constants.GM_CONTROLSIZE,
          panControl: false,
          zoomControl: true,
          rotateControl: false,
          fullscreenControl: false,
        }}
        onLoad={this.onMapLoad}
      >
        {this.renderPositionMarkers()}
        {this.renderPlantMarker()}
        {this.renderJobsiteMarker()}
      </GoogleMap>
    );
  }
}

export interface RouteInfoMapProps {
  mapCentered: boolean;
  plantList: PlantDto[];
  positionsList: {
    data: {
      items: PositionDto[];
    };
  };
  selectedItemId?: string;
  selectedProductLine?: ProductLine;
  selectedTicket: TicketDto;
  isTicketPrintViewCenter: boolean;
  clearSelectedItem: VoidFunc;
  getOrder: ConnectedDispatchFunction<typeof getOrder>;
  recenterMap: VoidFunc;
  updateSelectedItem: (position: PositionDto) => void;
}

export interface RouteInfoMapState {
  mapBounds?: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral;
  order?: OrderDto;
  preventMapRecenter: boolean;
}

export default connect<any, any, any>(
  (state: any) => ({
    plantList: state.plantList,
    selectedProductLine: state.selectedProductLine,
    selectedTicket: state.selectedTicket,
    ticketTimeline: state.ticketTimeline,
    isTicketPrintViewCenter: state.isTicketPrintViewCenter,
  }),
  {
    fetchStatusList,
    getOrder,
  }
)(RouteInfoMap);
