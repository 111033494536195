import { Dispatch } from 'redux';
import {
  ChecklistDto, ChecklistQuestionDto, DriverDto, HttpErrorResponse, PagedEntityDto, ProductLine,
} from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import recordLog, { devErrorAndLog } from '../util/errorUtil';
import {
  getRequest,
  putRequest,
  postRequest,
  deleteRequest,
} from './requestService';
import { SET_DRIVER_LIST, TIME_STAMP } from '../constants/actionConstants';
import {
  ERROR_TEXT_FETCH_DRIVER_LIST,
  ERROR_TEXT_FETCH_CHECKLIST_QUESTIONS_LIST,
  ERROR_TEXT_UPDATE_CHECKLIST_DETAILS,
  ERROR_TEXT_POST_UPDATE_CHECKLIST_QUESTION,
  ERROR_TEXT_REMOVE_CHECKLIST_QUESTION,
} from '../constants/errorConstants';
import { CHECKLISTS_PATH, CHECKLIST_QUESTIONS_PATH } from '../constants/apiConstants';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import { getSourceIdFromUrl } from '../util/appUtil';
import { openFailModal } from '../store/actions/errorModalActions';

export async function fetchDriverList(
  region?: string,
  productLines?: ProductLine[],
  byPassRedisCache: boolean = false,
  includeDeactivated: boolean = false
) {
  const url = `${getGeotraxBaseUrl()}/drivers`;
  const baseParams: {
    isArchived?: boolean,
    isDeleted: boolean,
    region: string,
    byPassRedisCache: boolean
  } = {
    isArchived: false,
    isDeleted: false,
    region: '',
    byPassRedisCache,
  };

  if (includeDeactivated) {
    // the only way to get active AND deactivated (aka archived) drivers is to delete the isArchived property entirely.
    // `null` and `undefined` get translated into`isArchived = false`.
    delete baseParams.isArchived;
  }

  const params = {
    ...baseParams,
    ...(productLines && productLines.length > 0 && { productLines }),
    ...(region && { region }),
  };

  try {
    const response = await getRequest(url, params);
    const drivers = response.data.items as DriverDto[];
    drivers.sort(byDriverNameSort);
    return drivers as DriverDto[];
  } catch (e: any) {
    devErrorAndLog(
      ERROR_TEXT_FETCH_DRIVER_LIST,
      `${ERROR_TEXT_FETCH_DRIVER_LIST} driversService: url: ${url} + params: + ${JSON.stringify(params)}`,
      e.toString()
    );
    throw e;
  }
}

// TODO: remove the return values from this function; it should only set the redux state.
export function setReduxDriverList(region?: string, productLines?: ProductLine[], byPassRedisCache: boolean = false) {
  return async (dispatch: Dispatch) => {
    try {
      const driversList = await fetchDriverList(region, productLines, byPassRedisCache);
      const lastFetchTimeStamp = DateWrapper.now.toString('h:mm    MM/dd/yyyy');
      dispatch({
        type: TIME_STAMP,
        payload: lastFetchTimeStamp,
      });
      dispatch({
        type: SET_DRIVER_LIST,
        payload: { items: driversList },
      });
      return driversList;
    } catch (e: any) {
      const err = (e as HttpErrorResponse);
      // if you have permissions removed or have switched regions without permissions
      // clear out the driverlist; there may be valid paging errors or cancel errors
      if (err.response && err.response.status === 403) {
        dispatch({
          type: SET_DRIVER_LIST,
          payload: [],
        });
      }
    }
    return [];
  };
}

// check if driver is logged in
export async function isLoggedIn(dto: any) {
  const sourceId = getSourceIdFromUrl(dto.url);
  const url = `${getGeotraxBaseUrl()}/activefleet/is-driver-logged-in`;
  const params = {
    sourceId,
  };

  try {
    const response = await getRequest(url, params);
    return response.data;
  } catch (e) {
    return false;
  }
}

// sorts by lastname first, so DOEBOB, DOEJOHN, DOEALICE
export function byDriverNameSort(driverA: DriverDto, driverB: DriverDto): -1 | 0 | 1 {
  const nameA = `${driverA.lastName}${driverA.firstName}`.toUpperCase();
  const nameB = `${driverB.lastName}${driverB.firstName}`.toUpperCase();
  if (nameA === nameB) return 0;
  return (nameA > nameB) ? 1 : -1;
}

export function fetchChecklistQuestions(id: number, region: string) {
  return async (dispatch: Dispatch): Promise<PagedEntityDto<ChecklistQuestionDto> | undefined> => {
    const url = `${getGeotraxBaseUrl()}${CHECKLISTS_PATH}/${id}${CHECKLIST_QUESTIONS_PATH}`;
    const params = {
      region,
      isArchived: false,
      isDeleted: false,
    };

    try {
      const response = await getRequest(url, params);
      return response.data;
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_TEXT_FETCH_CHECKLIST_QUESTIONS_LIST));
      recordLog('error', `${ERROR_TEXT_FETCH_CHECKLIST_QUESTIONS_LIST} id:${id} region: ${region}`, e);
      return undefined;
    }
  };
}

export function updateChecklistDetails(checklistDetails: ChecklistDto) {
  return async (dispatch: Dispatch) => {
    const url = `${getGeotraxBaseUrl()}${CHECKLISTS_PATH}/${checklistDetails.id}`;

    try {
      const response = await putRequest(url, checklistDetails);
      return response.data;
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_TEXT_UPDATE_CHECKLIST_DETAILS));
      recordLog('error', ERROR_TEXT_UPDATE_CHECKLIST_DETAILS, e);
      return undefined;
    }
  };
}

export function postOrUpdateChecklistQuestion(checklistId: number, question: ChecklistQuestionDto) {
  return async (dispatch: Dispatch): Promise<ChecklistQuestionDto | undefined> => {
    let url = `${getGeotraxBaseUrl()}${CHECKLISTS_PATH}/${checklistId}${CHECKLIST_QUESTIONS_PATH}`;
    const param = { region: question.region.url };

    try {
      // determine if it is an existing or new question
      if (question.id) {
        url = url.concat(`/${question.id}`);

        const response = await putRequest(url, question, param);
        return response.data;
      }

      const response = await postRequest(url, question, param);
      return response.data;
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_TEXT_POST_UPDATE_CHECKLIST_QUESTION));
      recordLog('error', `${ERROR_TEXT_POST_UPDATE_CHECKLIST_QUESTION} checklistId:${checklistId}`, e);
      return undefined;
    }
  };
}

export function deleteChecklistQuestion(checklistId: number, question: ChecklistQuestionDto) {
  return async (dispatch: Dispatch) => {
    let url = `${getGeotraxBaseUrl()}${CHECKLISTS_PATH}/${checklistId}${CHECKLIST_QUESTIONS_PATH}`;
    const param = { region: question.region.url };
    try {
      url = url.concat(`/${question.id}`);
      const response = await deleteRequest(url, param);
      return response.data;
    } catch (e: any) {
      dispatch(openFailModal(e.toString(), ERROR_TEXT_REMOVE_CHECKLIST_QUESTION));
      recordLog('error', `${ERROR_TEXT_REMOVE_CHECKLIST_QUESTION} checklistid:${checklistId}`, e);
      return undefined;
    }
  };
}
