import {
  ADD_ITEM_TO_ADMIN_TABLE_ITEMS,
  SET_TABLE_DATE_RANGE,
  SET_TABLE_RECORDS,
  SET_TABLE_SEARCH_TERM,
  SET_TABLE_NEEDS_REFRESH,
} from '../../constants/actionConstants';

export const addItemToAdminTableItems = (item: any) => ({
  type: ADD_ITEM_TO_ADMIN_TABLE_ITEMS,
  payload: item,
});

export const setAdminRecord = (data: any) => ({
  type: SET_TABLE_RECORDS,
  payload: { details: data },
});

export const setSearchTerm = (searchTerm: string) => ({
  type: SET_TABLE_SEARCH_TERM,
  payload: searchTerm,
});

export const setDateRange = (
  startDate: Date,
  endDate: Date,
  maxPast?: Date,
  maxFuture?: Date
) => ({
  type: SET_TABLE_DATE_RANGE,
  payload: {
    startDate,
    endDate,
    maxPast,
    maxFuture,
  },
});

export const clearSearchTerm = () => ({
  type: SET_TABLE_SEARCH_TERM,
  payload: '',
});

export const clearDateRange = () => ({
  type: SET_TABLE_DATE_RANGE,
  payload: null,
});

export const addToOrUpdateListInStore = (adminObject: any, destination: string) => ({
  type: destination,
  payload: adminObject.data || adminObject,
});

export const setDataTableNeedsRefresh = (needsRefresh: boolean) => ({
  type: SET_TABLE_NEEDS_REFRESH,
  payload: needsRefresh,
});
