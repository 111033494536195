import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  HeaderAndContainer, Loader, DateWrapper, Tooltip
} from '@trucktrax/trucktrax-common';
import {
  DriverDto,
  OrderDto,
  ProductLine,
  TicketDto,
  UrlKeyDto,
} from '@trucktrax/trucktrax-ts-common';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { IconButton, Stack } from '@mui/material';
import { setCurrentTab, updateToFieldValue } from '../../../store/actions/messageActions';
import { selectTicket } from '../../../store/actions/ticketActions';
import { selectTruckMarker, fetchOrderTicketList } from '../../../services/ticketsService';
import { fetchPlantListAndMapFilters } from '../../../services/plantsService';
import { setTicketsToPositions } from '../../../util/ticketUtil';
import { setCurrentRegion } from '../../../services/regionsService';
import GeotraxMap from './MapWrapper';
import { DriverWithTruck } from '../../../util/positionsUtil';
import { messageDrivers, truncateDriver } from '../../../util/messageUtil';
import VehicleAndOrderSearch from '../../shared/header/VehicleAndOrderSearch';
import { getOrdersByOrderUrls, getOrdersForMap } from '../../../services/ordersService';
import setSidebarSelected from '../../../store/actions/sidebarActions';
import MapFiltersDropdown from './MapFilters';
import style from './MapView.module.css';
import {
  READYMIX_STRING,
  READYMIX_VALUE, SUCCESS,
  TICKETS_SELECTED, TOOLTIP_REMOVE_DEFAULT_MAPVIEW, TOOLTIP_SET_DEFAULT_MAPVIEW,
} from '../../../constants/appConstants';
import { Marker, PositionDetailsDto, ConnectedDispatchFunction } from '../../../types';
import { MarkerType } from './TTMap';
import { setFavicon } from '../../../util/appUtil';
import { removeUserOverride, setUserOverride } from '../../../store/actions/regionActions';
import { openSnackbar } from '../../../store/actions/snackbarActions';
import { ADD_USER_DEFAULT_LOCATION, REMOVE_USER_DEFAULT_LOCATION } from '../../../constants/successConstants';
import { UNABLE_TO_SET_HOME_LOCATION } from '../../../constants/errorConstants';
import { openFailModal } from '../../../store/actions/errorModalActions';

export class MapView extends Component<MapViewProps, MapViewState> {
  static defaultProps = {
    drivers: [],
    currentRegion: {
      url: '',
      location: {
        latitude: 0,
        longitude: 0,
      },
      name: '',
    },
    messages: { partiesList: [] },
    positions: [],
    showExternalTrucks: true,
    showInternalTrucks: true,
    ticketsList: [],
    selectedProductLine: ProductLine.None,
  };

  constructor(props: MapViewProps) {
    super(props);
    this.state = {
      ordersListTickets: [],
      ordersListMap: [],
      ordersUrlList: '',
      mounted: false,
      lat: this.props.currentRegion?.location.latitude || -1,
      lng: this.props.currentRegion?.location.longitude || -1,
      zoom: -1
    };
  }

  async componentDidMount() {
    if (this.props.currentRegion && this.props.selectedProductLine) {
      this.props.fetchPlantListAndMapFilters(
        this.props.currentRegion.url,
        this.props.selectedProductLine,
        this.props.userUrl
      );
    }

    document.title = 'GeoTrax - TruckTrax';
    setFavicon('/favicon-geotrax.ico');
    this.setState({ mounted: true });
    await this.fetchOrdersBasedOnTickets();
    if (this.props.showJobsites) {
      await this.orderListOn(this.props.ordersStartDate, this.props.ordersEndDate);
    }
  }

  async componentDidUpdate() {
    await this.fetchOrdersBasedOnTickets();
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  fetchOrdersBasedOnTickets = async () => {
    const hasTickets = this.props.ticketsList && this.props.ticketsList.length > 0;
    if (!hasTickets && this.state.ordersListTickets.length !== 0) {
      if (this.state.mounted) {
        this.setState({
          ordersListTickets: [],
          ordersUrlList: '',
        });
      }
      return;
    }

    const orderUrls = this.props.ticketsList!.filter(ticket => ticket.order && ticket.order.url)
      .map(ticket => ticket.order?.url)
      .join(',');

    if (orderUrls !== this.state.ordersUrlList) {
      this.setState({
        ordersUrlList: orderUrls,
      }, async () => {
        const ordersListTickets = await this.props.getOrdersByOrderUrls(orderUrls);
        if (this.state.mounted) {
          this.setState({
            ordersListTickets,
          });
        }
      });
    }
  };

  orderListOn = async (startDate: Date, endDate: Date) => {
    const {
      currentRegion,
      selectedProductLine
    } = this.props;
    const ordersListMap = await this.props.getOrdersForMap(
      currentRegion!.url,
      new DateWrapper(startDate).startOfDay.date,
      new DateWrapper(endDate).endOfDay.date,
      selectedProductLine!
    );

    if (this.state.mounted) {
      this.setState({
        ordersListMap,
      });
    }
  };

  orderListOff = async () => {
    this.setState({
      ordersListMap: [],
    });
  };

  driverInfoMessageCallback = (driver: DriverDto) => {
    const driverDto = truncateDriver(driver);
    this.props.messageDrivers([driverDto]);
    return true;
  };

  updateToFieldValueWithPlantDrivers = (url: string) => {
    const filteredDrivers = this.props.drivers?.filter(driver => driver?.primaryPlant?.url === url);
    if (filteredDrivers && filteredDrivers.length > 0) {
      const truncatedDrivers = filteredDrivers.map(filteredDriver => truncateDriver(filteredDriver));
      this.props.messageDrivers(truncatedDrivers);
    }
    return true;
  };

  selectTicket = (markerData: Marker) => {
    this.props.selectTicket(markerData.ticket);
    this.props.setSidebarSelected(TICKETS_SELECTED);
    this.props.selectTruckMarker(markerData);
    return true;
  };

  orderDetailsCallback = (targetMarker: MarkerType) => {
    const orderId = (targetMarker.markerData as OrderDto).id;
    this.props.history.push(`/geotrax/orders/all/${orderId}`);
  };

  messageDriversOnOrder = async (order?: OrderDto) => {
    if (!order?.id) {
      return;
    }

    const orderTicketList: TicketDto[] = await this.props.fetchOrderTicketList(order.id);
    const drivers = orderTicketList
      .filter(
        ticket => !!ticket.driver?.url
          && !!ticket.externalInfo?.driverName
          && !ticket.closed
      )
      .map(ticket => ({
        fullName: ticket.externalInfo?.driverName,
        url: ticket.driver?.url,
      }));

    this.props.messageDrivers(drivers);
  };

  render() {
    const {
      currentRegion,
      ticketsList,
      positions,
      selectedProductLine,
    } = this.props;

    const titleRegion = currentRegion != null ? currentRegion.name : '';
    const renderProductLine = (productLine: ProductLine) => {
      if (productLine === READYMIX_VALUE) {
        return READYMIX_STRING;
      }
      return productLine;
    };
    const title = `${titleRegion} ${renderProductLine(selectedProductLine!) ?? ''} Trucks`;
    setTicketsToPositions(ticketsList ?? [], positions ?? []);
    const elementsLeft = (
      <div className={style.flexGroup}>
        <MapFiltersDropdown />
        <h5 className={style.h5}>{title}</h5>
      </div>
    );
    const {
      ordersListTickets,
      ordersListMap
    } = this.state;
    const ordersList = (ordersListMap && ordersListMap.length > 0) ? ordersListMap : ordersListTickets;

    if (currentRegion?.name === undefined) {
      return <Loader />;
    }
    return (
      <HeaderAndContainer
        elementsLeft={elementsLeft}
        elementsRight={
          <Stack direction="row">
            <VehicleAndOrderSearch ordersList={ordersList} />
            <Tooltip title={this.props.userOverrides ? TOOLTIP_REMOVE_DEFAULT_MAPVIEW : TOOLTIP_SET_DEFAULT_MAPVIEW}>
              <IconButton onClick={this.toggleDefaultLocation}>
                <AddLocationIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        }
        containerComponent={
          (
            <GeotraxMap
              handleZoomChange={this.handleZoomChange}
              handleCenterChange={this.handleCenterChange}
              lat={currentRegion!.location.latitude}
              lng={currentRegion.location.longitude}
              driverInfoMessageCallback={(driver: any) => {
                this.driverInfoMessageCallback(driver);
              }}
              updateToFieldValueWithPlantDrivers={(url: string) => {
                this.updateToFieldValueWithPlantDrivers(url);
              }}
              ticketInfoTicketsCallBack={(ticket: Marker) => {
                this.selectTicket(ticket);
              }}
              orderDetailsCallback={this.orderDetailsCallback}
              messageDriversOnOrder={this.messageDriversOnOrder}
              ordersList={ordersList}
              orderListOn={this.orderListOn}
              orderListOff={this.orderListOff}
            />
          )
        }
      />
    );
  }

  handleRemoveHome = () => {
    this.props.removeUserOverride();
    this.props.openSnackbar({
      snackbarBody: REMOVE_USER_DEFAULT_LOCATION,
      dataTest: 'home-location-removed',
      snackbarType: SUCCESS,
    });
  };

  handleZoomChange = (zoom: number) => {
    if (this.state.zoom === zoom) return;
    this.setState({
      zoom
    });
  };

  handleCenterChange = (center: any) => {
    const centerObject = JSON.parse(center);
    const { lat, lng } = centerObject;
    if (this.state.lat === lat && this.state.lng === lng) return;
    this.setState({
      lat,
      lng
    });
  };

  toggleDefaultLocation = () => {
    const { lat, lng, zoom } = this.state;

    if (this.props.userOverrides) {
      this.handleRemoveHome();
      return;
    }
    try {
      this.props.setUserOverride({
        lat,
        lng,
        zoom
      });
      this.props.openSnackbar({
        snackbarBody: ADD_USER_DEFAULT_LOCATION,
        dataTest: 'home-location-success',
        snackbarType: SUCCESS,
      });
    } catch (e: any) {
      this.props.openFailModal(e.toString(), UNABLE_TO_SET_HOME_LOCATION);
    }
  };
}

function mapStateToProps(state: any) {
  return {
    currentRegion: state.currentRegion,
    drivers: state.driverList.drivers,
    messages: state.messages,
    currentTab: state.currentTab.currentTab,
    positions: state.positionList,
    ticketsList: state.ticketsList,
    selectedProductLine: state.selectedProductLine,
    showJobsites: state.mapFilters.showJobsites,
    ordersStartDate: state.mapFilters.ordersStartDate,
    ordersEndDate: state.mapFilters.ordersEndDate,
    userUrl: state.userUrl,
    userOverrides: state.userOverrides
  };
}

export interface Location {
  latitude: number;
  longitude: number;
}

export interface Region {
  url: string;
  location: Location;
  name: string;
}

export interface Message {
  partiesList: any[];
}

export interface History {
  push: (p: string) => void
}

export interface MapViewProps {
  currentRegion?: Region,
  drivers?: DriverWithTruck[],
  selectTicket: (ticket: UrlKeyDto) => void,
  selectTruckMarker: (truck: Marker) => void,
  positions?: PositionDetailsDto[],
  ticketsList?: TicketDto[],
  setSidebarSelected: (sideBar: string) => void,
  getOrdersByOrderUrls: (orderUrl: string) => any[],
  selectedProductLine?: ProductLine,
  history: History,
  showJobsites: boolean,
  ordersStartDate: Date,
  ordersEndDate: Date,
  userUrl: string,
  getOrdersForMap: (
    currentRegionUrl: string,
    startDate: Date,
    endDate: Date,
    selectedProductLine: ProductLine
  ) => any[],
  fetchOrderTicketList: ConnectedDispatchFunction<typeof fetchOrderTicketList>,
  fetchPlantListAndMapFilters: ConnectedDispatchFunction<typeof fetchPlantListAndMapFilters>,
  messageDrivers: ConnectedDispatchFunction<typeof messageDrivers>,
  setUserOverride: ConnectedDispatchFunction<typeof setUserOverride>,
  removeUserOverride: ConnectedDispatchFunction<typeof removeUserOverride>,
  openSnackbar: ConnectedDispatchFunction<typeof openSnackbar>,
  openFailModal: ConnectedDispatchFunction<typeof openFailModal>,
  userOverrides: { lat: number; lng: number; zoom: number }
}

export interface MapViewState {
  ordersListTickets: any[],
  ordersListMap: any[],
  ordersUrlList: string,
  mounted: boolean,
  lat: number,
  lng: number,
  zoom: number
}

export default connect<any>(mapStateToProps, {
  updateToFieldValue,
  setCurrentRegion,
  setCurrentTab,
  selectTicket,
  selectTruckMarker,
  setSidebarSelected,
  getOrdersByOrderUrls,
  getOrdersForMap,
  fetchOrderTicketList,
  fetchPlantListAndMapFilters,
  messageDrivers,
  setUserOverride,
  removeUserOverride,
  openSnackbar,
  openFailModal
})(MapView);
