import { Dispatch } from 'redux';
import {
  ProductInfoDto,
  ProductLine,
  TicketDto,
  UrlKeyDto,
  ScaleTraxTicketDto,
  TicketResponseDto,
  RouteDto,
} from '@trucktrax/trucktrax-ts-common';
import { DisposalMethodDto } from '@trucktrax/trucktrax-ts-common/build/Dtos/Tickets/DisposalMethodDto';
import { VoidReasonDto } from '@trucktrax/trucktrax-ts-common/build/Dtos/Tickets/VoidReasonDto';
import { getRequest, putRequest, postRequest } from './requestService';
import TicketingSocket from './sockets/ticketingSocket';
import recordLog, { devErrorAndLog } from '../util/errorUtil';
import {
  saveTicketListFilters,
  setCurrentTicketQualityFactors,
  setTicketList,
} from '../store/actions/ticketActions';
import { fetchStatusList } from './statusesService';
import { updatePositionWithTruckEta } from './mapviewsService';
import { getLatestStatus } from '../util/ticketUtil';
import { selectMarker } from '../store/actions/mapviewActions';
import {
  ERROR_TEXT_FETCH_CURRENT_TICKET_LIST,
  ERROR_TEXT_FETCH_ORDER_TICKET_LIST,
  ERROR_TEXT_FETCH_TICKET_QUALITY_FACTORS,
} from '../constants/errorConstants';
import { EXTRAPRODUCTS_PATH, QUALITY_FACTORS_PATH, TICKETS_PATH } from '../constants/apiConstants';
import { getTicketingBaseUrl } from '../util/apiUtil';
import { PositionDetailsDto, TicketListFilters } from '../types';
import { MarkerType } from '../components/geotrax/map/TTMap';
import { store } from '../Main';
import { getTokens } from '../util/authUtil';
import {
  OPEN_POPUP, SELECT_MARKER,
} from '../constants/actionConstants';
import DateTimeRange from '../util/DateTimeRange';
import { INVALID_STALE_TOKEN_LOGOUT } from '../constants/appConstants';
import HTTP_CODES from '../constants/httpConstants';

export async function getTicketsByOrderId(orderId: number | string) {
  const url = getTicketingBaseUrl() + TICKETS_PATH;
  const params = {
    orderId,
    deleted: false
  };
  const response = await getRequest(url, params);
  return response.data.items;
}

export function fetchOrderTicketList(orderId: number | string) {
  return async (dispatch: Dispatch): Promise<any[]> => {
    try {
      return await getTicketsByOrderId(orderId);
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_ORDER_TICKET_LIST, e.toString(), undefined, consoleOnly) as any);
      return [];
    }
  };
}

export const fetchPreviousTicket = async (
  plantUrl: string,
  truckUrl?: string
): Promise<TicketDto | undefined> => {
  const url = getTicketingBaseUrl() + TICKETS_PATH;
  const params = {
    plantUrl,
    sortBy: 'ticketedTimestamp,DESC',
    size: 1,
  };

  const withTruckParams = truckUrl ? { ...params, truckUrl } : params;
  const response = await getRequest(url, withTruckParams);
  return response.data.items[0];
};

export async function updateTicket(ticket: TicketDto) {
  const url = `${getTicketingBaseUrl()}${TICKETS_PATH}/${ticket.id}`;
  try {
    const response = await putRequest(url, ticket);
    return response.data as TicketDto;
  } catch (e: any) {
    recordLog('error', `failed to update ticket - dto: ${JSON.stringify(ticket)} `, e.toString());
    return e;
  }
}

export async function closeOpenTicket(ticket: TicketDto) {
  const url = `${getTicketingBaseUrl()}${TICKETS_PATH}/close-open-ticket/${ticket.id}`;
  try {
    const response = await putRequest(url, ticket);
    return response.data as TicketDto;
  } catch (e: any) {
    recordLog('error', `failed to update ticket - dto: ${JSON.stringify(ticket)} `, e.toString());
    return e;
  }
}

export const fetchTicket = async (
  id: number
): Promise<TicketDto | undefined> => {
  const url = `${getTicketingBaseUrl() + TICKETS_PATH}/${id}`;
  const response = await getRequest(url);
  return response.data;
};

export const fetchDisposalMethods = async (
  regionUrl: string
): Promise<DisposalMethodDto[]> => {
  const url = `${getTicketingBaseUrl()}/disposalmethods`;
  const params = { regionUrl };
  const response = await getRequest(url, params);
  return response.data;
};

export const fetchVoidReasons = async (
  regionUrl: string
): Promise<VoidReasonDto[]> => {
  const url = `${getTicketingBaseUrl()}/voidreasons`;
  const params = { regionUrl };
  const response = await getRequest(url, params);
  return response.data;
};

export function fetchCurrentTicketQualityFactors(ticketId: number) {
  return async (dispatch: Dispatch) => {
    const url = `${getTicketingBaseUrl()}${TICKETS_PATH}/${ticketId}${QUALITY_FACTORS_PATH}`;
    try {
      const response = await getRequest(url);
      dispatch(setCurrentTicketQualityFactors(response.data));
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(ERROR_TEXT_FETCH_TICKET_QUALITY_FACTORS, `url:${url}`, e.toString(), undefined, consoleOnly) as any);
    }
  };
}

export async function fetchCurrentTicketForTruck(
  truckUrl: string,
  region?: string,
  selectedProductLine?: string,
) {
  const url = getTicketingBaseUrl() + TICKETS_PATH;
  const params: any = {
    truckUrl,
    regionUrl: region,
    plantProductLine: selectedProductLine,
    currentTicketOnly: true,
    deleted: false,
    isArchived: false,
    size: 1,
  };

  try {
    const response = await getRequest(url, params);
    const ticket = response.data.items[0];
    return ticket;
  } catch (e: any) {
    if (e !== INVALID_STALE_TOKEN_LOGOUT) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_CURRENT_TICKET_LIST, e.toString(), undefined, consoleOnly);
    }
    return null;
  }
}

export function fetchCurrentTicketList(
  region?: string,
  selectedProductLine?: string,
  plantUrl?: string,
  plantsFilter?: string[]
) {
  return async (dispatch: Dispatch) => {
    const url = getTicketingBaseUrl() + TICKETS_PATH;
    const params: any = {
      regionUrl: region,
      isArchived: false,
      plantProductLine: selectedProductLine,
      currentTicketOnly: true,
      deleted: false
    };

    const withPlantParams = plantUrl ? { ...params, plant: plantUrl } : params;
    if (plantsFilter) {
      withPlantParams.plants = plantsFilter;
    }
    try {
      const response = await getRequest(url, withPlantParams);
      dispatch(setTicketList(response.data.items));
    } catch (e: any) {
      if (e !== INVALID_STALE_TOKEN_LOGOUT) {
        const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
        dispatch(devErrorAndLog(e.toString(), ERROR_TEXT_FETCH_CURRENT_TICKET_LIST, e.toString(), undefined, consoleOnly) as any);
      }
    }
  };
}

export async function fetchTicketList(
  regionUrl?: string,
  inProductLines?: string,
  timespan?: DateTimeRange
) {
  const url = getTicketingBaseUrl() + TICKETS_PATH;
  const dateTimestampBefore = new Date(timespan?.start || '');
  const dateTimestampAfter = new Date(timespan?.end || '');
  const params = {
    regionUrl,
    inProductLines,
    timestampBefore: dateTimestampAfter.toUTCString(),
    timestampAfter: dateTimestampBefore.toUTCString(),
    deleted: false
  };
  try {
    const response = await getRequest(url, params);
    return response.data.items;
  } catch (e) {
    if (e !== INVALID_STALE_TOKEN_LOGOUT) {
      throw e;
    }
    return undefined;
  }
}

export async function getExtraProducts(ticketId?: number) {
  const url = `${getTicketingBaseUrl()}${TICKETS_PATH}/${ticketId}${EXTRAPRODUCTS_PATH}`;
  const response = await getRequest(url);
  return response.data as ProductInfoDto[];
}

export async function createTicket(ticket: ScaleTraxTicketDto) {
  const url = `${getTicketingBaseUrl()}${TICKETS_PATH}/createclearviewticket`;
  const { currentRegion } = store.getState();
  const tenantUrl = getTokens().tenantUrl!;
  const regionUrl = currentRegion?.url;
  const params = { tenantUrl, regionUrl };
  let response;
  try {
    response = await postRequest(url, ticket, params);
    return response.data as TicketResponseDto;
  } catch (e: any) {
    // eslint-disable-next-line max-len
    recordLog(
      'error',
      `ScaleTraxTicketing failed to createTicket - dto: ${JSON.stringify(ticket)} `,
      `${e.toString()}Error Message: ${e.response.data.message}`
    );
    return e.response;
  }
}

export async function ticketSocketDisconnect() {
  await TicketingSocket.disconnect(true);
}

export async function ticketSocketInit(
  currentRegion: UrlKeyDto,
  selectedProductLine: ProductLine
) {
  // Avoiding a race condition in completion of websocket disconnect
  await ticketSocketDisconnect();
  TicketingSocket.connect(currentRegion, selectedProductLine);
}

type GetState = () => {
  positionList: PositionDetailsDto[],
  currentRegion?: UrlKeyDto,
  routeHistoryList: RouteDto[]
};

export const selectRouteMarker = (
  marker: MarkerType
) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: SELECT_MARKER,
    payload: marker,
  });
  dispatch({
    type: OPEN_POPUP,
    payload: marker.markerId,
  });
};

export const selectTruckMarker = (
  marker: MarkerType,
  ticketUrl: string,
  truckAlias: string
) => async (dispatch: Dispatch<any>, getState: GetState) => {
  const state = getState();

  const position = state.positionList
    .filter(pos => pos.trackedObject && pos.trackedObject.truckAlias === truckAlias);

  const ticketInfo: any = {
    region: { url: state.currentRegion?.url },
    url: ticketUrl,
  };

  if (state.currentRegion?.url && ticketUrl) {
    const statuses = await fetchStatusList(ticketInfo)(dispatch);
    const ticketStatus = getLatestStatus(statuses);
    const positionWithStatus = {
      ...position[0],
      ticketStatus,
    } as any;
    dispatch(updatePositionWithTruckEta(positionWithStatus));
  } else {
    dispatch(updatePositionWithTruckEta(position[0] as any));
  }

  dispatch(selectMarker(marker as any));
};

export const selectOrderMarker = (marker: MarkerType) => (dispatch: Dispatch) => {
  dispatch(selectMarker(marker as any) as any);
};

export const saveFilters = (filters: TicketListFilters) => (dispatch: Dispatch) => {
  dispatch(saveTicketListFilters(filters));
};
