/* eslint-disable */
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import React from 'react';
import { Label } from '@trucktrax/trucktrax-common';
import { ProductLine } from '@trucktrax/trucktrax-ts-common';
import styles from './ProductLineRegionDetails.module.css';
import { UserRegionWithName } from '../../../types';

const ProductLineRegionDetails: React.FC<ProductLineRegionDetailsProps> =
  ({
    primaryProductLine,
    secondaryProductLines,
    primaryRegion,
    secondaryRegions,
  }) => {

    // group regions by product line
    const groupRegionsByProductLine = (productLine: ProductLine) => {
      return secondaryRegions
        ?.filter(region => region.productLines?.includes(productLine))
        .map(region => region.name)
        .join(', ');
    };

    // Generate rows for each secondary product line that has associated regions
    const secondaryProductLineRows = secondaryProductLines?.toSorted()?.map(productLine => {
      const regions = groupRegionsByProductLine(productLine);
      return regions ? (
        <TableRow key={productLine}>
          <TableCell component="th" scope="row" className={styles.noBorder}>
            {productLine}
          </TableCell>
          <TableCell align="left" className={styles.noBorder}>
            {regions}
          </TableCell>
        </TableRow>
      ) : null;
    }).filter(row => row !== null);

    return (
      <div className={styles.fullWidthContainer}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.labelBold}>Product Lines</TableCell>
                <TableCell className={styles.labelBold} align="left">Regions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Primary Product Line and Primary Region */}
              <TableRow>
                <TableCell component="th" scope="row" className={styles.noPadding}>
                  <Label className={styles.primaryLabel}>Primary</Label>
                  {primaryProductLine ?? ''}
                </TableCell>
                <TableCell align="left" className={styles.noPadding}>
                  <Label className={styles.primaryLabel}>Primary</Label>
                  {primaryRegion?.name ?? ''}
                </TableCell>
              </TableRow>

              {/* Dynamically created Secondary Product Lines and Regions */}
              <TableRow>
                <TableCell component="th" scope="row" className={styles.noBorderSecondaryHeader}>
                  <Label className={styles.primaryLabel}>Secondary</Label>
                </TableCell>
                <TableCell align="left" className={styles.noBorderSecondaryHeader}>
                  <Label className={styles.primaryLabel}>Secondary</Label>
                </TableCell>
              </TableRow>
              {secondaryProductLineRows}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

export type ProductLineRegionDetailsProps = {
  primaryProductLine: ProductLine | undefined,
  secondaryProductLines: ProductLine[] | undefined,
  primaryRegion: UserRegionWithName | undefined,
  secondaryRegions: UserRegionWithName[] | undefined,
};

export default ProductLineRegionDetails;
