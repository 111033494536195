/* eslint-disable */

import React from 'react';
import styles from './ThumbsUpSvg.module.css';

const ThumbsUpSvg = (dataTest: any) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="25px"
    y="25px"
    viewBox="25 25 500 500"
    className={styles.svgStyle}
    data-test={dataTest.props || 'ThumbsUpSvgDataTestTag'}
  >
    <title>thumbs-up</title>
    <g id="f4UIEC">
      <path
        className={styles.st0}
        d="M421.6,363c-1.1-3.9-1.3-7.7,1.9-11c2.1-1.3,4.5-0.9,6.8-1c15.4-0.6,28-10.6,31.4-24.7
        c5-20.9-8.3-40.1-30.4-44.3c-4.2-0.8-8.7-0.1-12.7-1.9c-2.6-2.6-2.6-5.7-1.6-8.9c10.4-1.3,19.4-5,24.8-14.6
        c11.3-20.2-0.2-45-23-48.6c-25.9-4.1-52-3.8-78.1-1.4c-8.8,0.8-17.4,2.4-26.1,3.6l-2-3.3c0.1-9.9,4.3-18.8,7.9-27.7
        c9.8-24,20.9-47.5,28.7-72.4c7.3-23.3,6.9-46.7,1.3-70C346.7,20.9,337.4,9,321,4.6c-27.6-7.5-40.7-0.5-51.5,23
        c-6.1,13.3-10.4,27.4-14.9,41.3c-9,28.2-20.4,55.3-37.7,79.6c-14.5,20.4-31.5,37.9-55.6,47c-7.9,3-15.8,6.3-23.7,9.3
        c-22.9,8.7-46,16.8-68,27.6c-10.3,5.1-16.6,13-19.9,23.7c-3.4,11.4-5.1,23.2-5,35c-0.2,22.5-0.2,45,0.2,67.4
        c0.3,12.1,1.2,24.2,4.6,36.1c4,14,11.4,25,25.6,30.5c5.3,2.1,9.7,5.8,13.9,9.6c6.5,6,13.1,11.9,19.2,18.2
        c15.6,15.9,34.5,25.9,55.7,32c41.5,11.9,84,13,126.8,11.9c26.1,2.5,52.1,0.5,78.1-2.1c12.9-1.2,25.4-3.5,36.3-11.1
        c9.9-6.9,13.2-16.8,11.6-28.4c-1.4-10-8.1-15.2-17.1-18.2c-0.8-0.3-1.5-0.7-2.2-1.1c-1.3-3.1-0.4-6.6,2.1-8.7
        c9.5,0.1,18.9-0.3,27.8-4.3c12.3-5.4,19.7-14.5,20.1-28.2c0.4-12.6-7.2-23.6-19.8-29C425.4,365,423.5,363.9,421.6,363z"
      />
      <path
        className={styles.st1}
        d="M290.5,497c-42.7,1.1-85.3,0-126.8-11.9c-21.2-6.1-40.1-16.1-55.7-32c-6.2-6.3-12.8-12.2-19.3-18.2
        c-4.2-3.8-8.6-7.5-13.9-9.6c-14.2-5.6-21.6-16.5-25.6-30.5c-3.4-11.9-4.3-23.9-4.5-36.1c-0.4-22.5-0.4-45-0.2-67.4
        c-0.1-11.8,1.6-23.6,5-35c3.3-10.7,9.6-18.6,19.9-23.7c22-10.8,45.1-19,68-27.7c7.9-3,15.8-6.4,23.7-9.3
        c24.1-9.1,41.1-26.6,55.6-47c17.2-24.2,28.6-51.3,37.6-79.5c4.5-14,8.7-28,14.9-41.3c10.8-23.5,23.9-30.4,51.5-23
        c16.5,4.3,25.8,16.2,29.6,32.1c5.6,23.4,6,46.7-1.3,70c-7.8,24.9-18.9,48.3-28.7,72.4c-3.6,8.9-7.8,17.7-7.9,27.7
        c-13.9-0.1-25.5,5.8-35.7,14.5c-16.4,13.8-16.1,36.1-8.7,53.6c1.2,2.5,2.1,5.2,2.6,7.9c1.4,8.7,0.1,17.2-1.1,25.7
        c-4.7,30.9-20.4,55.3-44.5,74.7c-4.5,3.6-9.8,6.4-13.4,11.1c-1.3,1.7-2.9,3.5-1.2,5.8s3.5,3.3,6.4,2.2c2.5-0.9,4.9-2,7.2-3.4
        c14-9.5,26.3-21.5,36.1-35.3c5.8-8,10.7-16.7,14.7-25.8c3.5,4.3,5.8,8.8,10.2,11.4c1,0.6,2.2,1.3,2,2.6c-0.3,1.5-1.9,0.8-3,1.1
        c-13.2,3.6-23.6,11-27,24.6c-3.8,15.1,0.8,28.9,11.2,40.5c2.2,2.2,4.6,4.4,7,6.4c-2.7,1.7-5.4,2.7-7.5,4.7
        c-9.3,8.7-11.8,19.2-10.4,31.6c1.5,13.6,7.8,23.7,19.4,30.7C281,494.2,285.9,495,290.5,497z"
      />
      <path
        className={styles.st2}
        d="M290.5,497c-4.6-1.9-9.5-2.8-13.8-5.4c-11.6-7-17.9-17.1-19.4-30.7c-1.4-12.4,1.2-23,10.4-31.6
        c2.1-2,4.8-3,7.5-4.7c-2.4-2-4.8-4.1-7-6.4c-10.4-11.6-15-25.3-11.2-40.5c3.4-13.6,13.8-21,27-24.6c1-0.3,2.6,0.4,3-1.1
        c0.3-1.3-1-2-2-2.6c-4.4-2.6-6.8-7.1-10.2-11.4c-4,9.1-8.9,17.7-14.7,25.8c-9.8,13.8-22.1,25.8-36.1,35.3c-2.3,1.4-4.7,2.6-7.2,3.4
        c-2.9,1.1-4.8,0-6.4-2.2s-0.1-4.1,1.2-5.8c3.6-4.7,8.9-7.4,13.4-11.1c24.1-19.4,39.9-43.8,44.5-74.7c1.3-8.5,2.6-17,1.1-25.7
        c-0.5-2.7-1.4-5.4-2.6-7.9c-7.4-17.5-7.7-39.8,8.7-53.6c10.2-8.6,21.8-14.5,35.7-14.5l2,3.3c-0.3,2.2-2.5,2-3.9,2.6
        c-12.7,6.5-22.9,15.1-24.6,30.3c-1.2,10.6-1.9,21.6,7.7,29.4c6.7,5.4,15,7,23.4,7.4c18.1,0.9,36.1-1.2,54-3.8
        c15.2-2.2,30.6-3.4,45.9-5c-1,3.2-1,6.3,1.6,8.9c-3.2,1.5-6.7,1.5-10.1,1.9c-13.2,1.5-26.3,2.8-39.5,4.4c-12,1.4-24,3-35.9,4.6
        c-10,1.4-20,2.5-29.7,5.8c-8.4,2.9-13.7,8.1-15.2,17.3c-0.6,3.5,0.2,6.5,0.4,9.8c0.6,10.9,5.9,18.8,15.5,23.8
        c6.7,3.4,14,5.2,21.5,5.2c15.3,0.3,30.6-0.3,45.9-1c10-0.4,19.9,0.1,29.9-0.8c7.3-0.6,14.9-1.5,22.2,0.7c-3.1,3.2-3,7-1.9,11
        c-0.5,0.7-1.3,1-2.1,0.9c-8.4-1.1-16.8-1.7-25.3-1.8c-21.8,0-43.6-0.2-65.4,1c-8,0.4-16,0.4-23.9,1.1c-6,0.3-11.9,1.9-17.2,4.6
        c-5.9,3.2-10.3,7.7-11.2,14.5c-2.6,20.5,11.1,33.6,27.5,36.6c18.5,3.4,37.3,3.6,55.9,4.9c13.1,0.9,26.2,1.7,39.3,2.4
        c-2.5,2.1-3.4,5.7-2.1,8.7c-14.8-1.9-29.7-2.9-44.6-3c-17.1-0.2-34.2-2.1-51.2,0.1c-7,0.9-14,2-19.3,7.1c-8,7.7-11.7,17-7.9,28
        c3.8,11,11.8,18.1,23.1,19.7c17.2,2.4,34.6,3.9,52,4.4c6.3,0.2,12.5,1.3,18.9,0.9c2.6-0.1,1.1,0.9,0.5,1.8
        C342.6,497.5,316.6,499.5,290.5,497z"
      />
      <path
        className={styles.st1}
        d="M423.5,352c-7.3-2.1-14.9-1.3-22.2-0.7c-10,0.9-20,0.3-29.9,0.8c-15.3,0.7-30.6,1.3-45.9,1
        c-7.5,0-14.9-1.8-21.6-5.2c-9.6-5-14.9-12.9-15.5-23.8c-0.2-3.3-0.9-6.3-0.4-9.8c1.5-9.1,6.8-14.4,15.2-17.3
        c9.6-3.3,19.7-4.4,29.7-5.8c12-1.7,23.9-3.2,35.9-4.6c13.1-1.6,26.3-2.9,39.5-4.4c3.4-0.4,6.9-0.4,10.1-1.9
        c4.1,1.8,8.5,1.1,12.7,1.9c22.1,4.1,35.4,23.3,30.4,44.3c-3.4,14.2-15.9,24.1-31.4,24.7C428,351.1,425.6,350.7,423.5,352z"
      />
      <path
        className={styles.st1}
        d="M416.9,271.2c-15.3,1.6-30.6,2.9-45.9,5c-17.9,2.6-35.9,4.6-54,3.8c-8.4-0.4-16.7-2-23.4-7.4
        c-9.6-7.8-8.9-18.7-7.7-29.4c1.7-15.2,11.9-23.8,24.6-30.3c1.4-0.7,3.5-0.4,3.9-2.6c8.7-1.2,17.4-2.8,26.1-3.6
        c26.1-2.4,52.2-2.7,78.1,1.4c22.8,3.6,34.3,28.4,23,48.6C436.3,266.3,427.3,269.9,416.9,271.2z"
      />
      <path
        className={styles.st1}
        d="M399.2,427.3c-13.1-0.7-26.2-1.5-39.3-2.4c-18.7-1.2-37.5-1.5-55.9-4.9c-16.4-3-30.1-16.1-27.5-36.6
        c0.9-6.7,5.3-11.2,11.2-14.5c5.3-2.8,11.2-4.4,17.2-4.7c7.9-0.7,15.9-0.7,23.9-1.1c21.8-1.2,43.6-1,65.4-1
        c8.5,0.1,16.9,0.7,25.3,1.8c0.8,0.1,1.7-0.2,2.2-0.9c1.9,1,3.8,2,5.8,2.9c12.6,5.4,20.2,16.3,19.8,29c-0.4,13.8-7.8,22.8-20.1,28.2
        C418.1,427,408.7,427.4,399.2,427.3z"
      />
      <path
        className={styles.st1}
        d="M368.5,494.9c0.6-0.8,2.1-1.9-0.5-1.8c-6.3,0.3-12.6-0.7-18.9-0.9c-17.4-0.5-34.8-1.9-52-4.4
        c-11.3-1.6-19.3-8.7-23.1-19.7c-3.8-11,0-20.3,7.9-28c5.3-5.1,12.3-6.2,19.3-7.1c17.1-2.1,34.2-0.3,51.2-0.1
        c14.9,0.1,29.8,1.2,44.6,3.1c0.7,0.4,1.4,0.8,2.2,1.1c9,3,15.7,8.2,17.1,18.2c1.6,11.6-1.7,21.5-11.6,28.4
        C394,491.4,381.4,493.6,368.5,494.9z"
      />
    </g>
  </svg>
);
export default ThumbsUpSvg;
