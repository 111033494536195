import { OrderDto } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';

export function formatOrderScheduledTime(order: OrderDto) {
  return order && new DateWrapper(order.scheduledTime).toUSDateString();
}

export function getValidOrdersListInDateRange(
  ordersList: OrderDto[],
  start: Date = DateWrapper.now.startOfDay.date,
  end: Date = DateWrapper.now.endOfDay.nextDay.date
) {
  const startDate = new DateWrapper(start).startOfDay.date;
  const endDate = new DateWrapper(end).endOfDay.date;

  return ordersList.filter(order => {
    const orderDate = new DateWrapper(order.scheduledTime);
    const isWithinDateRange = orderDate.isAfterOrEqual(startDate)
      && orderDate.isBefore(endDate);

    if (!isWithinDateRange) {
      return false;
    }

    return order.geoZone?.zone.circle?.radius;
  });
}

export const statusLabels = {
  WILL_CALL: 'Will Call',
  SHIP: 'Ship',
  HOLD: 'Hold',
  FOB: 'FOB',
  CANCELED: 'Canceled',
};
