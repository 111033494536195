import React from 'react';
import cx from 'classnames';
import { Label, Tooltip } from '@trucktrax/trucktrax-common';
import StatusValue from './StatusValue';
import StatusButton from './StatusButton';
import styles from './StatusContainer.module.css';

export function StatusContainer(props: StatusContainerProps) {
  const {
    category,
    isArchived = false,
    isDisabled = false,
    hasEditPermission = false,
    containerClassName = '',
    onActivateClick,
    onDeactivateClick,
  } = props;

  const actionText = isArchived ? 'activate' : 'deactivate';
  const getWarningText = () => {
    switch (category) {
      case 'driver': return 'driver is logged in';
      case 'plant': return 'plant has no geozones';
      case 'device': return 'the tablet is in use.';
      case 'truck': return 'the vehicle is in use.';
      default: return 'Geotrax is in use';
    }
  };

  const getActiveIndicator = () => {
    if (isArchived) {
      return <StatusValue label="deactivated" />;
    } return <StatusValue label="active" />;
  };

  const getActivateButton = () => {
    if (isArchived) {
      return (
        <StatusButton
          name="Activate"
          dataTest={`activate-${category}`}
          onClick={onActivateClick}
          disabled={isDisabled}
        />
      );
    }

    return (
      <StatusButton
        name="Deactivate"
        dataTest={`deactivate-${category}`}
        onClick={onDeactivateClick}
        disabled={isDisabled}
      />
    );
  };

  const getToolTip = (content?: any) => {
    if (!isDisabled) {
      return content;
    }

    return (
      <Tooltip
        key={category}
        text={`Cannot ${actionText} while ${getWarningText()}`}
        placement="bottom"
        classes={{
          tooltip: styles.lockTooltip,
          popper: styles.lockPopper,
        }}
      >
        <span>
          {content}
        </span>
      </Tooltip>
    );
  };

  return (
    <section
      className={cx(
        hasEditPermission ? styles.statusContainer : styles.statusContainerNoEdit,
        isArchived && styles.statusCtrDeactivated,
        containerClassName
      )}
    >
      <div className={styles.statusContentContainer}>
        <Label htmlFor="status">Current Status:</Label>
        <div
          id="status"
          className={styles.statusContent}
        >
          {getActiveIndicator()}
          {hasEditPermission && getToolTip(getActivateButton())}
        </div>
      </div>
    </section>
  );
}

export interface StatusContainerProps {
  category: string;
  isArchived?: boolean;
  isDisabled?: boolean;
  hasEditPermission?: boolean;
  containerClassName?: string;
  onActivateClick: VoidFunction;
  onDeactivateClick: VoidFunction;
}

export default StatusContainer;
