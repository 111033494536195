import React, { Component } from 'react';
import { FloatingActionButton } from '@trucktrax/trucktrax-common';
import { HaulerDto, UrlKeyDto, VoidFunc } from '@trucktrax/trucktrax-ts-common';
import { connect } from 'react-redux';
import { createDataTableRecord } from '../../../services/dataTableService';
import { HAULERS_PATH } from '../../../constants/apiConstants';
import { itemsToLabelValue, onSubmitSuccess } from '../../../util/adminUtil';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import AdminAddModal from '../../shared/admin/AdminAddModal';
import { ADD_HAULER_ERROR_MESSAGE } from '../../../constants/errorConstants';
import { ADD_HAULER_SUCCESS_TEXT } from '../../../constants/successConstants';
import {
  ADMIN_KEYS,
  ADMIN_LABELS,
  INPUT_FORM,
  ADD_HAULER_MODAL_TITLE,
  ADD_HAULER_BUTTON_TEXT,
  STATE_LIST,
  DROPDOWN_FORM,
  NUMERIC_INPUT_FORM,
} from '../../../constants/appConstants';
import styles from './HaulersAddModal.module.css';
import { noop } from '../../../util/appUtil';
import AddFeatureModal from '../../shared/AddFeatureModal';
import { checkNullInRequired } from '../../../util/validation';

export const DELIVERY_TYPE_LABELS = {
  FOB: 'FOB',
  DELIVERED: 'Delivered',
};

export enum DeliveryType {
  FOB,
  Delivered
}

export const deliveryTypeOptions = [
  {
    label: DELIVERY_TYPE_LABELS.FOB,
    value: DELIVERY_TYPE_LABELS.FOB,
    text: DELIVERY_TYPE_LABELS.FOB,
    key: DeliveryType.FOB,
  },
  {
    label: DELIVERY_TYPE_LABELS.DELIVERED,
    value: DELIVERY_TYPE_LABELS.DELIVERED,
    text: DELIVERY_TYPE_LABELS.DELIVERED,
    key: DeliveryType.Delivered,
  },
];

export class HaulersAddModal extends Component<HaulersAddModalProps> {
  static defaultProps = {
    currentRegion: {
      url: '',
    },
  };

  onSubmit = (dto: HaulerDto, onError: any, onSuccess: any) => {
    const toastMessages = {
      success: ADD_HAULER_SUCCESS_TEXT,
      fail: ADD_HAULER_ERROR_MESSAGE,
    };
    const url = getGeotraxBaseUrl() + HAULERS_PATH;
    const callbacks = [this.props.discardAcceptAction, onSuccess];
    dto.region = this.props.currentRegion;

    this.props.createDataTableRecord(
      url,
      dto,
      () => onSubmitSuccess(callbacks),
      onError,
      toastMessages,
      true
    );
  };

  static config = () => {
    const dropDownStates = STATE_LIST.map(itemsToLabelValue);

    return [
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.NAME,
        label: ADMIN_LABELS.HAULER_NAME,
        maxLength: 255,
        dataTest: 'hauler-name-input-data-test',
        errorDataTest: 'hauler-name-input-missing-error',
        className: styles.fullWidth,
        customValidation: checkNullInRequired,
        isRequired: true,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.EXTERNAL_ID,
        label: ADMIN_LABELS.EXTERNAL_ID,
        dataTest: 'externalid-input-data-test',
        errorDataTest: 'externalid-input-missing-error',
        className: styles.fullWidth,
        maxLength: 50,
        customValidation: checkNullInRequired,
        isRequired: true,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.ADDRESS_LINE_ONE,
        label: ADMIN_LABELS.ADDRESS_LINE_ONE,
        dataTest: 'addressLineOne-input-data-test',
        errorDataTest: 'addressLineOne-input-missing-error',
        className: styles.fullWidth,
        maxLength: 255,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.ADDRESS_LINE_TWO,
        label: ADMIN_LABELS.ADDRESS_LINE_TWO,
        dataTest: 'addressLineTwo-input-data-test',
        errorDataTest: 'addressLineTwo-input-missing-error',
        className: styles.fullWidth,
        maxLength: 255,
      },
      {
        type: INPUT_FORM,
        key: ADMIN_KEYS.CITY,
        label: ADMIN_LABELS.CITY,
        dataTest: 'city-input-data-test',
        errorDataTest: 'city-input-missing-error',
        className: styles.city,
        maxLength: 255,
      },
      {
        type: DROPDOWN_FORM,
        key: ADMIN_KEYS.STATE,
        label: ADMIN_LABELS.STATE,
        className: styles.state,
        items: dropDownStates,
        initialSelected: dropDownStates[0],
        dataTest: 'region-input-data-test',
      },
      {
        type: NUMERIC_INPUT_FORM,
        key: ADMIN_KEYS.ZIP_CODE,
        label: ADMIN_LABELS.ZIP_CODE,
        maxLength: 10,
        dataTest: 'zipCode-input-data-test',
        errorDataTest: 'zipCode-input-missing-error',
        className: styles.zip,
      },
    ];
  };

  render() {
    return (
      <div>
        <FloatingActionButton onClick={this.props.openModal} />
        <AddFeatureModal
          title={ADD_HAULER_MODAL_TITLE}
          isOpen={this.props.isOpen}
          onCancel={this.props.closeModal}
          style={styles.haulersAddModal}
        >
          <AdminAddModal
            config={HaulersAddModal.config()}
            className={styles.formContainer}
            addButtonText={ADD_HAULER_BUTTON_TEXT}
            addButtonDataTest="hauler-add-button-data-test"
            onRightBtnClick={this.onSubmit}
            discardAcceptAction={this.props.discardAcceptAction}
            discardRejectAction={this.props.discardRejectAction}
            isCloseRequest={this.props.isCloseRequest}
          />
        </AddFeatureModal>
      </div>
    );
  }
}

export interface HaulersAddModalProps {
  createDataTableRecord: typeof createDataTableRecord,
  openModal: VoidFunc,
  closeModal: VoidFunc,
  discardAcceptAction: typeof noop,
  discardRejectAction: typeof noop,
  isOpen: boolean,
  isCloseRequest: boolean,
  currentRegion: UrlKeyDto
}

const mapStateToProps = (state: any) => ({
  currentRegion: state.currentRegion,
});

export default connect<any, any, any>(mapStateToProps, {
  createDataTableRecord,
})(HaulersAddModal);
