import React, { Component, ComponentType } from 'react';
import { Button, DiscardProgressCard } from '@trucktrax/trucktrax-common';
import { GeoZoneDto } from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import { Prompt } from 'react-router-dom';
import styles from './AdminAddModal.module.css';
import DynamicForms from '../forms/DynamicForms';
import { noop } from '../../../util/appUtil';
import { accessValue, KeyRelationship } from '../../../util/adminUtil';
import { isChangedValid, valueHasChanged } from '../../../util/validation';
import {
  DISCARD_PROMPT_TEXT,
  GEOZONE_TYPE,
  ADMIN_LABELS,
  CIRCLE,
  POLYGON,
  CHECKBOX_FORM
} from '../../../constants/appConstants';
import FooterMessage from '../forms/FooterMessage';
import trash from '../../../assets/img/trash-illus.svg';

export type AdminAddModalErrors = { [key: string]: string | string[] };

export interface AdminAddModalState {
  inputTouch: { [inputId: string]: boolean };
  startValue: any;
  value: any;
  errors: AdminAddModalErrors;
}

interface ValidationErrors {
  [key: string]: string;
}

export class AdminAddModal extends Component<AdminAddModalProps, AdminAddModalState> {
  static defaultProps: Partial<AdminAddModalProps> = {
    className: '',
    buttonClassName: 'tt-btn tt-btn--submit',
    defaultState: {},
    firstModal: true,
    onLeftButtonClick: noop,
    leftBtnClassName: '',
    leftBtnText: '',
    inactiveFields: [],
    options: { requiredKeys: [] },
    externalKeys: [],
    hideRequiredFieldText: false,
    onChangeHandler: noop,
    modalSize: 'default',
  };

  constructor(props: AdminAddModalProps) {
    super(props);

    this.state = {
      inputTouch: {},
      startValue: {},
      value: {},
      errors: {},
    };
  }

  componentDidMount() {
    this.initializeValues();
    window.onbeforeunload = AdminAddModal.beforeUnloadListener;
  }

  componentDidUpdate(prevProps: AdminAddModalProps) {
    if (this.props.isCloseRequest === true
      && this.props.isCloseRequest !== prevProps.isCloseRequest) {
      if (!valueHasChanged(this.state.startValue, this.state.value)) {
        this.props.discardAcceptAction();
      }
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  static beforeUnloadListener = (event: any) => {
    event.preventDefault();
    event.returnValue = 'Are you sure you want to exit?';
    return event;
  };

  onTouch = (_: unknown, inputId: string) => {
    const newValue = this.state.inputTouch;
    newValue[inputId] = true;
    this.setState({
      inputTouch: newValue,
    });
  };

  resetErrorsForChangedValues = (newValue: any) => {
    const { value: originalValue, errors } = this.state;
    const updatedErrors = { ...errors };

    // Get all keys from both objects and remove duplicates
    const allKeys = Array.from(new Set([...Object.keys(newValue), ...Object.keys(originalValue)]));

    allKeys.forEach(key => {
      if (originalValue[key] !== newValue[key]) {
        delete updatedErrors[key];
      }
    });

    this.setState({ errors: updatedErrors });
  };

  onChange = (changes: any) => {
    const newValue = { ...this.state.value, ...changes };

    this.resetErrorsForChangedValues(newValue);

    // If changes are related to Plant Geozones, update accordingly.
    const plantGeozone = this.props.config.find(c => Object.values(GEOZONE_TYPE).includes(c.key));
    if (plantGeozone) {
      const hasPlantGeozonesChanged = ['location', CIRCLE, POLYGON].some(key => Object.keys(changes).includes(key));
      type GeozoneType = typeof GEOZONE_TYPE;
      type GeozoneTypeKey = keyof GeozoneType;

      const zoneType = GEOZONE_TYPE[plantGeozone.key.toUpperCase() as GeozoneTypeKey];
      if (hasPlantGeozonesChanged) {
        newValue.geozones[zoneType] = { ...newValue.geozones[zoneType], ...changes };
        // modal geozone to always match showing geozone modal.
        if (changes.location) {
          newValue.circle = newValue.geozones[zoneType].circle;
          newValue.polygon = newValue.geozones[zoneType].polygon;
        }

        if (newValue.inqueueSameAsPlant === 'true'
          && zoneType === GEOZONE_TYPE.PLANT) {
          newValue.geozones[GEOZONE_TYPE.INQUEUE] = { ...newValue.geozones.plant };
        }

        if (newValue.returnSameAsPlant === 'true'
          && zoneType === GEOZONE_TYPE.PLANT) {
          newValue.geozones[GEOZONE_TYPE.RETURN] = { ...newValue.geozones.plant };
        }
      }

      // handle toggle between custom geozone and plant's geozone
      if (changes.inqueueSameAsPlant === 'true' || changes.returnSameAsPlant === 'true') {
        newValue.geozones[zoneType] = { ...newValue.geozones.plant };
        newValue.circle = newValue.geozones.plant.circle;
        newValue.polygon = newValue.geozones.plant.polygon;
      }
      // clear any geozone errors
      this.setState({ errors: {} });
    }

    // If changes are related to Stand-alone Geozones, update accordingly.
    const forGeofence = this.props.config.find(c => c.key === ADMIN_LABELS.GEOFENCE);
    if (forGeofence) {
      const geofence: Pick<GeoZoneDto, 'zone'> = {
        zone: {
          circle: changes.circle,
          polygon: changes.polygon,
        },
      };
      newValue[ADMIN_LABELS.GEOFENCE] = geofence;
    }

    this.setState({
      value: newValue,
    });
    if (this.props.onChangeHandler) {
      this.props.onChangeHandler(newValue);
    }
  };

  static getNestedPropertyValue(obj: any, path: string): any {
    return path.split('.').reduce(
      (acc, part) => (
        acc && Object.hasOwnProperty.call(acc, part) ? acc[part] : undefined),
      obj
    );
  }

  onSubmit = () => {
    // TODO: remove this logic after adding primary plant,
    // primary and secondary product lines to the backend
    // prevents application from sending ignorable fields to the backend.
    const { inactiveFields } = this.props;
    const value = { ...this.state.value };
    inactiveFields.forEach(f => {
      delete value[f];
    });

    const validationErrors = (this.props.config)
      .filter(field => field.customValidation)
      .reduce((errors, field) => {
        const value = AdminAddModal.getNestedPropertyValue(this.state.value, field.key);
        const validationError = field.customValidation!(value);
        if (validationError) {
          errors[field.key] = validationError;
        }
        return errors;
      }, {} as ValidationErrors);

    if (Object.keys(validationErrors).length === 0) {
      this.props.onRightBtnClick(
        this.state.value,
        (errors) => {
          this.setState({ errors });
        },
        () => {
        }
      );
    } else {
      this.setState({ errors: validationErrors });
    }
  };

  onLeftButtonClick = () => {
    this.props.onLeftButtonClick();
  };

  dynamicForms = (config: AdminConfig) => {
    const {
      key,
      accessor,
      items,
      itemFilter,
    } = config;
    const value = accessor ? accessValue(accessor, this.state.value) : this.state.value[key];
    const filteredItems = itemFilter ? itemFilter(items, this.state.value) : items;

    if (this.props.externalKeys.includes(key) && this.state.value[key] !== value) {
      if ((!Array.isArray(this.state.value[key]) || !Array.isArray(value))
        || !(JSON.stringify(this.state.value[key]) === JSON.stringify(value))) {
        this.setState({
          value: {
            ...this.state.value,
            [key]: value,
          },
        });
      }
    }

    // TODO: refactor to not mutate props
    config.errorMessage = (config.type === CHECKBOX_FORM)
      ? [this.state.errors[config.key]] as any
      : this.state.errors[config.key] as string;

    return (
      <DynamicForms
        id={config.key}
        value={value}
        onChange={this.onChange}
        onTouch={this.onTouch}
        {...config}
        type={config.type}
        items={filteredItems}
      />
    );
  };

  initializeValues = () => {
    const initValues: { [key: string]: any } = {};
    this.props.config.forEach(form => {
      const {
        initialSelected,
        key,
      } = form;
      if (
        (initialSelected && initialSelected.value)
        || (initialSelected && initialSelected.value === false)
      ) {
        initValues[key] = initialSelected.value;
      } else {
        initValues[key] = '';
      }
    });
    this.setState({
      value: initValues,
      startValue: initValues,
    });
  };

  render() {
    const { config, children } = this.props;
    const showLeftBtn = (!this.props.firstModal && this.props.hideRequiredFieldText);
    const displayDiscardPrompt = this.props.isCloseRequest && valueHasChanged(this.state.startValue, this.state.value);

    return (
      displayDiscardPrompt
        ? (
          <DiscardProgressCard
            discardAcceptAction={this.props.discardAcceptAction}
            discardRejectAction={this.props.discardRejectAction}
            imageSrc={trash}
            altText="Trash"
            modalSize={this.props.modalSize}
          />
        )
        : (
          <div className={styles.addModal}>
            <Prompt
              when={isChangedValid(this.state.startValue, this.state.value)}
              message={DISCARD_PROMPT_TEXT}
            />
            <div className={cx(this.props.className, styles.forms)}>
              {config.map(this.dynamicForms)}
              {children}
            </div>
            <div className={styles.featureFooter}>
              {
                showLeftBtn
                  ? (
                    <Button
                      onClick={this.onLeftButtonClick}
                      buttonClassName={this.props.leftBtnClassName}
                      name={this.props.leftBtnText}
                      dataTest="left-modal-btn"
                    />
                  )
                  : <FooterMessage showAsterisk text="Required fields" />
              }
              <Button
                onClick={this.onSubmit}
                buttonClassName={this.props.buttonClassName}
                name={this.props.addButtonText}
                dataTest={this.props.addButtonDataTest}
              />
            </div>
          </div>
        )
    );
  }
}

export type AdminConfig = {
  key: string;
  component?: ComponentType;
  isRequired?: boolean;
  label?: string | Element | JSX.Element | React.Component;
  dataTest?: string;
  errorDataTest?: string;
  maxLength?: number;
  className?: any;
  type: string;
  accessor?: string | ((item: any) => any);
  items?: any[];
  itemFilter?: (item: any, value: any) => any[];
  initialSelected?: any;
  errorMessage?: string;
  customValidation?: (value: any) => string;
  [other: string]: any;
};

export interface AdminAddModalProps {
  onRightBtnClick: (
    value: any,
    errorCallback: (errors: AdminAddModalErrors) => void,
    successCallback: () => void
  ) => void;
  addButtonText: string;
  addButtonDataTest: string;
  onChangeHandler: (newValue: any) => void;
  config: AdminConfig[],
  className: string;
  buttonClassName: string;
  defaultState: any;
  externalKeys: string[];
  firstModal: boolean;
  onLeftButtonClick: () => void;
  leftBtnClassName: string;
  leftBtnText: string;
  discardAcceptAction: () => void;
  discardRejectAction: () => void;
  isCloseRequest: boolean;
  inactiveFields: string[];
  options: {
    requiredKeys: KeyRelationship[]
  };
  hideRequiredFieldText: boolean;
  modalSize: string;
  children?: React.ReactNode;
}

export default AdminAddModal;
