import React, { Component } from 'react';
import { FloatingActionButton } from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  DeviceDto,
  RegionDto,
  UrlKeyDto,
  VoidFunc,
} from '@trucktrax/trucktrax-ts-common';
import { exactFieldLength, nameAndUrlItemToDropdownOptions, onSubmitSuccess } from '../../../util/adminUtil';
import AdminAddModal, { AdminConfig } from '../../shared/admin/AdminAddModal';
import { createDataTableRecord } from '../../../services/dataTableService';
import styles from './DevicesAddModal.module.css';
import {
  ADD_DEVICE_BUTTON_TEXT,
  ADD_DEVICE_MODAL_TITLE,
  ADMIN_KEYS,
  ADMIN_LABELS,
  DROPDOWN_FORM,
  INPUT_FORM,
  INPUT_TYPE_TEL,
  NUMERIC_INPUT_FORM,
} from '../../../constants/appConstants';
import { DEVICES_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ADD_DEVICE_SUCCESS_TEXT } from '../../../constants/successConstants';
import { ADD_DEVICE_ERROR_MESSAGE } from '../../../constants/errorConstants';
import { noop } from '../../../util/appUtil';
import AddFeatureModal from '../../shared/AddFeatureModal';
import { checkNullInRequired } from '../../../util/validation';

export class DevicesAddModal extends Component<DevicesAddModalProps> {
  onSubmit = (dto: DeviceDto, onError: any, onSuccess: any) => {
    const { createAdminTableData: post } = this.props;
    const toastMessages = {
      success: ADD_DEVICE_SUCCESS_TEXT,
      fail: ADD_DEVICE_ERROR_MESSAGE,
    };

    const currentRegion = this.props.currentRegion.url === dto.region?.url;
    const currentRegionUrl = this.props.currentRegion.url;
    const url = getGeotraxBaseUrl() + DEVICES_PATH;
    const callbacks = [this.props.discardAcceptAction, onSuccess];
    post(url, dto, () => onSubmitSuccess(callbacks), onError, toastMessages, currentRegion, currentRegionUrl);
  };

  config = (): AdminConfig[] => {
    const dropDownRegions = nameAndUrlItemToDropdownOptions(this.props.regions);
    const { currentRegion } = this.props;
    const displayRegion = dropDownRegions.find((item: { value: UrlKeyDto }) => item.value.url === currentRegion.url);

    return [{
      type: INPUT_FORM,
      key: ADMIN_KEYS.EXTERNAL_ID,
      label: ADMIN_LABELS.EXTERNAL_ID,
      maxLength: 50,
      dataTest: 'external-id-input-data-test',
      errorDataTest: 'external-id-input-missing-error',
      className: 'widthSpace',
      customValidation: checkNullInRequired,
      isRequired: true,
    }, {
      type: NUMERIC_INPUT_FORM,
      key: ADMIN_KEYS.IMEINUM,
      label: ADMIN_LABELS.IMEINUM,
      maxLength: 15,
      dataTest: 'imei-input-data-test',
      errorDataTest: 'imei-input-missing-error',
      className: 'halfWidthSpace',
      customValidation: (value: any) => exactFieldLength(value, 15, ADMIN_LABELS.IMEINUM)!,
    }, {
      type: INPUT_FORM,
      key: ADMIN_KEYS.SERIAL_NUMBER,
      label: ADMIN_LABELS.SERIAL_NUMBER,
      maxLength: 255,
      dataTest: 'serial-input-data-test',
      errorDataTest: 'serial-input-missing-error',
      className: 'widthSpace',
      isRequired: false,
    }, {
      type: INPUT_FORM,
      key: ADMIN_KEYS.NAME,
      label: ADMIN_LABELS.NAME,
      maxLength: 25,
      dataTest: 'name-input-data-test',
      errorDataTest: 'name-input-missing-error',
      className: 'halfWidthSpace',
      isRequired: false,
    }, {
      type: INPUT_FORM,
      key: ADMIN_KEYS.MANUFACTURER,
      label: ADMIN_LABELS.MANUFACTURER,
      maxLength: 50,
      dataTest: 'manufacturer-input-data-test',
      errorDataTest: 'manufacturer-input-missing-error',
      className: 'widthSpace',
      isRequired: false,
    }, {
      type: INPUT_FORM,
      key: ADMIN_KEYS.MODEL_NUMBER,
      label: ADMIN_LABELS.MODEL_NUMBER,
      maxLength: 20,
      dataTest: 'model-input-data-test',
      errorDataTest: 'model-input-missing-error',
      className: 'halfWidthSpace',
      isRequired: false,
    }, {
      type: INPUT_FORM,
      key: ADMIN_KEYS.SOFTWARE_VERSION,
      label: ADMIN_LABELS.SOFTWARE_VERSION,
      maxLength: 20,
      dataTest: 'android-input-data-test',
      errorDataTest: 'android-input-missing-error',
      className: 'halfWidthSpace',
      isRequired: false,
    }, {
      type: NUMERIC_INPUT_FORM,
      key: ADMIN_KEYS.PHONE,
      label: ADMIN_LABELS.PHONE,
      maxLength: 20,
      inputType: INPUT_TYPE_TEL,
      dataTest: 'phone-input-data-test',
      errorDataTest: 'phone-input-missing-error',
      className: cx('halfWidthSpace', 'baseMargin'),
      customValidation: checkNullInRequired,
      isRequired: true,
    }, {
      type: DROPDOWN_FORM,
      key: ADMIN_KEYS.REGION,
      label: ADMIN_LABELS.REGION,
      className: cx('baseDropDown', 'gBottomSpace'),
      items: dropDownRegions,
      initialSelected: displayRegion,
      isRequired: true,
      disabled: true,
      dataTest: 'region-input-data-test',
    }];
  };

  render() {
    return (
      <div>
        <FloatingActionButton onClick={this.props.openModal} />
        <AddFeatureModal
          title={ADD_DEVICE_MODAL_TITLE}
          isOpen={this.props.isOpen}
          onCancel={this.props.closeModal}
          style={styles.devicesAddModal}
        >
          <AdminAddModal
            config={this.config()}
            addButtonText={ADD_DEVICE_BUTTON_TEXT}
            addButtonDataTest="devices-add-button-data-test"
            onRightBtnClick={this.onSubmit}
            discardAcceptAction={this.props.discardAcceptAction}
            discardRejectAction={this.props.discardRejectAction}
            isCloseRequest={this.props.isCloseRequest}
          />
        </AddFeatureModal>
      </div>
    );
  }
}

export interface DevicesAddModalProps {
  createAdminTableData: typeof createDataTableRecord,
  discardAcceptAction: typeof noop,
  discardRejectAction: typeof noop,
  closeModal: VoidFunc,
  openModal: VoidFunc,
  regions: RegionDto[],
  currentRegion: UrlKeyDto,
  isCloseRequest: boolean,
  isOpen: boolean
}
const mapStateToProps = (state: any) => ({
  currentRegion: state.currentRegion,
});

export default connect<any, any, any>(mapStateToProps, {
  createAdminTableData: createDataTableRecord,
})(DevicesAddModal);
