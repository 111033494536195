export const SUCCESSFULLY_ADDED = ' successfully added.';
export const ADD_DEVICE_SUCCESS_TEXT = `Device${SUCCESSFULLY_ADDED}`;
export const ADD_REGION_SUCCESS_TEXT = `Region${SUCCESSFULLY_ADDED}`;
export const ADD_CHECKLIST_SUCCESS_TEXT = `Checklist${SUCCESSFULLY_ADDED}`;
export const ADD_TRUCK_SUCCESS_TEXT = `Vehicle${SUCCESSFULLY_ADDED}`;
export const ADD_SCALE_SUCCESS_TEXT = `${SUCCESSFULLY_ADDED}`;
export const ADD_DRIVER_SUCCESS_TEXT = `Driver${SUCCESSFULLY_ADDED}`;
export const ADD_PLANT_SUCCESS_TEXT = `Plant${SUCCESSFULLY_ADDED}`;
export const ADD_PERMISSIONS_GROUP_SUCCESS_TEXT = `Permissions group${SUCCESSFULLY_ADDED}`;
export const ADD_FLAG_SUCCESS_TEXT_ONE = 'The feature flag';
export const ADD_FLAG_SUCCESS_TEXT_TWO = `has been${SUCCESSFULLY_ADDED}`;
export const ADD_USER_SUCCESS_TEXT = 'User successfully added and a temporary password'
  + ' has been sent to the email provided.';
export const ADD_GEOZONE_SUCCESS_TEXT = `Geozone${SUCCESSFULLY_ADDED}`;
export const ADD_HAULER_SUCCESS_TEXT = `Hauler${SUCCESSFULLY_ADDED}`;
export const ADD_VEHICLE_TYPE_SUCCESS_TEXT = `Vehicle type${SUCCESSFULLY_ADDED}`;

export const RESET_PASSWORD_SUCCESS_TEXT = 'User’s password has been successfully reset.';
export const SUCCESSFULLY_REMOVED = 'has been successfully removed.';
export const SUCCESSFULLY_MODIFIED = 'have been successfully modified.';
export const ADMIN_UPDATE_SUCCESS_TOAST_TEXT = 'All changes saved.';
export const SUCCESSFULLY_UPDATED = ' has been successfully updated.';
export const REPLACE_GUIDE_SUCCESS = `User guide${SUCCESSFULLY_UPDATED}`;
export const REPLACE_VIDEO_SUCCESS = `Training video${SUCCESSFULLY_UPDATED}`;
export const ADD_USER_DEFAULT_LOCATION = 'Map view has been successfully set.';
export const REMOVE_USER_DEFAULT_LOCATION = 'Map view has been reset to default region view.';
