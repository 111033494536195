/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import styles from './NotificationsCard.module.css';
import { statusLabels } from '../../../../util/orderUtil';

class NotificationCard extends PureComponent<NotificationCardProps> {
  static defaultProps = {
    status: null,
  };

  orderStatus = () => {
    const styleMap = {
      WILL_CALL: styles.willCall,
      SHIP: styles.ship,
      HOLD: styles.hold,
      FOB: styles.fob,
      CANCELED: styles.canceled,
    };
    type StyleMapType = typeof styleMap;
    type OrderStatusStyle = keyof StyleMapType;
    const status = this.props.status! as OrderStatusStyle;
    if (!status) {
      return null;
    }
    return <span className={cx(styles.orderStatus, styleMap[status])}>{statusLabels[status]}</span>;
  };

  render() {
    const {
      icon, title, message, link,
    } = this.props;
    return (
      <Link
        style={{ textDecoration: 'none' }}
        to={link}
      >
        <div className={styles.wrapper}>
          <i
            className={cx(
              icon,
              styles.icon,
              styles.notificationTypeIcon
            )}
          />
          <div>
            <p className={styles.title}>{title}</p>
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: message }}
            />
            {this.orderStatus()}
          </div>
          <i
            className={cx(
              'icon-arrow-forward',
              'icon-md',
              styles.icon,
              styles.notificationArrowIcon
            )}
          />
        </div>
      </Link>
    );
  }
}

export interface NotificationCardProps {
  icon: string,
  title: string,
  message: string,
  link: string,
  status?: string
}

export default NotificationCard;
