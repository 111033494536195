import {
  createTheme, ThemeProvider, adaptV4Theme
} from '@mui/material/styles';
import React, { Component } from 'react';
import config from 'react-global-configuration';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { detect } from 'detect-browser';
import { Loader, Overlay, vars } from '@trucktrax/trucktrax-common';
import { ConnectedFlagsProvider } from './util/flagUtil';
import PrivateRoute from './components/shared/routing/PrivateRoute';
import { rootReducer } from './store';
import styles from './Main.module.css';
import { privateRoutes, publicRoutes } from './routes';
import RouteWithSubRoutes from './components/shared/routing/RouteWithSubRoutes';
import {
  BROWSER_TYPE_NOTIFICATION, BROWSER_WIDTH_ALT, BROWSER_WIDTH_TEXT, CONFIG_URL, BROWSER_TYPE_NOTIFICATIONNOLINK,
} from './constants/appConstants';
import bgOverlayJpg from './assets/img/bgOverlay.jpg';
import browserIconsPng from './assets/img/chrome-edge-browser-icons.png';
import browserSizeSvg from './assets/img/browserSize.svg';
import muiStyleOverrides from './styles/MuiStyleOverrides';

config.set({}, { freeze: false, assign: false });

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const baseTheme = createTheme(adaptV4Theme({
  /* theme for v1.x */
  typography: {
    body1: {
      fontFamily: vars.fontFamilyRegular
    },
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: '#008EF3',
    },
  },
}));

const appTheme = createTheme(baseTheme, muiStyleOverrides);
interface MainProps {
  tracer: any;
}

class Main extends Component<MainProps> {
  state = {
    renderLoaderScreen: true,
  };

  handleLoaderCompletion = () => {
    this.setState({ renderLoaderScreen: false });
  };

  static handleNewConfiguration = (configObject: any) => {
    config.set(configObject, { assign: true, freeze: false });
  };

  // just so we can change the value easily in tests
  static getWindowUrl = () => window.location.href;

  static browserOverlay = () => {
    let browserOverlay = null;
    const browser = detect();
    const chromeLink = <a style={{ color: '#17A9FF' }} href="https://www.google.com/chrome/"> Google Chrome </a>;
    const edgeLink = <a style={{ color: '#17A9FF' }} href="https://www.microsoft.com/en-us/edge">Microsoft Edge.</a>;

    if (browser && (browser.name === 'chrome' || browser.name === 'edge-chromium')) {
      // don't use the size warning overlay for the messages window
      if (!Main.getWindowUrl().includes('/geotrax/messages')) {
        browserOverlay = (
          <Overlay
            bgImageSrc={bgOverlayJpg}
            imageSrc={browserSizeSvg}
            text={BROWSER_WIDTH_TEXT}
            isSizeOverlay
            altText={BROWSER_WIDTH_ALT}
          />
        );
      }
    } else {
      browserOverlay = (
        <Overlay
          bgImageSrc={bgOverlayJpg}
          imageSrc={browserIconsPng}
          text={(
            <div>
              {BROWSER_TYPE_NOTIFICATION}
              {' '}
              {chromeLink}
              {' '}
              {' '}
              or
              {' '}
              {edgeLink}
            </div>
          )}
          altText={BROWSER_TYPE_NOTIFICATIONNOLINK}
        />
      );
    }
    return browserOverlay;
  };

  AppProvider = (
    /* @ts-ignore */
    <Provider store={store}>
      {/* @ts-ignore */}
      <ConnectedFlagsProvider>
        <div className={styles.overlayContainer}>
          {Main.browserOverlay()}
          <Router>
            <Switch>
              {publicRoutes.map(publicRoute => (
                <RouteWithSubRoutes
                  key={publicRoute.path}
                  {...publicRoute}
                />
              ))}
              {privateRoutes.map(privateRoute => (
                <PrivateRoute
                  key={privateRoute.path}
                  {...privateRoute as any}
                />
              ))}
              <Redirect to="/404" />
            </Switch>
          </Router>
        </div>
      </ConnectedFlagsProvider>
    </Provider>
  );

  render() {
    let renderElements = this.AppProvider;

    if (this.state.renderLoaderScreen) {
      renderElements = (
        <Loader
          configPath={CONFIG_URL}
          onLoadingCompletion={this.handleLoaderCompletion}
          onNewConfiguration={Main.handleNewConfiguration}
        />
      );
    }

    return (
      <ThemeProvider theme={appTheme}>
        {renderElements}
      </ThemeProvider>
    );
  }
}

export default Main;
