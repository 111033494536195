import brownDefaultNorth from '../assets/img/map_icons/brown_default_top.png';
import brownDefaultNorthEast from '../assets/img/map_icons/brown_default_top_right.png';
import brownDefaultEast from '../assets/img/map_icons/brown_default_right.png';
import brownDefaultSouthEast from '../assets/img/map_icons/brown_default_bottom_right.png';
import brownDefaultSouth from '../assets/img/map_icons/brown_default_bottom.png';
import brownDefaultSouthWest from '../assets/img/map_icons/brown_default_bottom_left.png';
import brownDefaultWest from '../assets/img/map_icons/brown_default_left.png';
import brownDefaultNorthWest from '../assets/img/map_icons/brown_default_top_left.png';
import brownDotDefault from '../assets/img/map_icons/brown_dot_default.png';
import aquaDefaultNorth from '../assets/img/map_icons/aqua_default_top.png';
import aquaDefaultNorthEast from '../assets/img/map_icons/aqua_default_top_right.png';
import aquaDefaultEast from '../assets/img/map_icons/aqua_default_right.png';
import aquaDefaultSouthEast from '../assets/img/map_icons/aqua_default_bottom_right.png';
import aquaDefaultSouth from '../assets/img/map_icons/aqua_default_bottom.png';
import aquaDefaultSouthWest from '../assets/img/map_icons/aqua_default_bottom_left.png';
import aquaDefaultWest from '../assets/img/map_icons/aqua_default_left.png';
import aquaDefaultNorthWest from '../assets/img/map_icons/aqua_default_top_left.png';
import aquaDotDefault from '../assets/img/map_icons/aqua_dot_default.png';
import blueDefaultNorth from '../assets/img/map_icons/blue_default_top.png';
import blueDefaultNorthEast from '../assets/img/map_icons/blue_default_top_right.png';
import blueDefaultEast from '../assets/img/map_icons/blue_default_right.png';
import blueDefaultSouthEast from '../assets/img/map_icons/blue_default_bottom_right.png';
import blueDefaultSouth from '../assets/img/map_icons/blue_default_bottom.png';
import blueDefaultSouthWest from '../assets/img/map_icons/blue_default_bottom_left.png';
import blueDefaultWest from '../assets/img/map_icons/blue_default_left.png';
import blueDefaultNorthWest from '../assets/img/map_icons/blue_default_top_left.png';
import blueDotDefault from '../assets/img/map_icons/blue_dot_default.png';
import greenDefaultNorth from '../assets/img/map_icons/green_default_top.png';
import greenDefaultNorthEast from '../assets/img/map_icons/green_default_top_right.png';
import greenDefaultEast from '../assets/img/map_icons/green_default_right.png';
import greenDefaultSouthEast from '../assets/img/map_icons/green_default_bottom_right.png';
import greenDefaultSouth from '../assets/img/map_icons/green_default_bottom.png';
import greenDefaultSouthWest from '../assets/img/map_icons/green_default_bottom_left.png';
import greenDefaultWest from '../assets/img/map_icons/green_default_left.png';
import greenDefaultNorthWest from '../assets/img/map_icons/green_default_top_left.png';
import greenDotDefault from '../assets/img/map_icons/green_dot_default.png';
import orangeDefaultNorth from '../assets/img/map_icons/orange_default_top.png';
import orangeDefaultNorthEast from '../assets/img/map_icons/orange_default_top_right.png';
import orangeDefaultEast from '../assets/img/map_icons/orange_default_right.png';
import orangeDefaultSouthEast from '../assets/img/map_icons/orange_default_bottom_right.png';
import orangeDefaultSouth from '../assets/img/map_icons/orange_default_bottom.png';
import orangeDefaultSouthWest from '../assets/img/map_icons/orange_default_bottom_left.png';
import orangeDefaultWest from '../assets/img/map_icons/orange_default_left.png';
import orangeDefaultNorthWest from '../assets/img/map_icons/orange_default_top_left.png';
import orangeDotDefault from '../assets/img/map_icons/orange_dot_default.png';
import purpleDefaultNorth from '../assets/img/map_icons/purple_default_top.png';
import purpleDefaultNorthEast from '../assets/img/map_icons/purple_default_top_right.png';
import purpleDefaultEast from '../assets/img/map_icons/purple_default_right.png';
import purpleDefaultSouthEast from '../assets/img/map_icons/purple_default_bottom_right.png';
import purpleDefaultSouth from '../assets/img/map_icons/purple_default_bottom.png';
import purpleDefaultSouthWest from '../assets/img/map_icons/purple_default_bottom_left.png';
import purpleDefaultWest from '../assets/img/map_icons/purple_default_left.png';
import purpleDefaultNorthWest from '../assets/img/map_icons/purple_default_top_left.png';
import purpleDotDefault from '../assets/img/map_icons/purple_dot_default.png';
import pinkDefaultNorth from '../assets/img/map_icons/pink_default_top.png';
import pinkDefaultNorthEast from '../assets/img/map_icons/pink_default_top_right.png';
import pinkDefaultEast from '../assets/img/map_icons/pink_default_right.png';
import pinkDefaultSouthEast from '../assets/img/map_icons/pink_default_bottom_right.png';
import pinkDefaultSouth from '../assets/img/map_icons/pink_default_bottom.png';
import pinkDefaultSouthWest from '../assets/img/map_icons/pink_default_bottom_left.png';
import pinkDefaultWest from '../assets/img/map_icons/pink_default_left.png';
import pinkDefaultNorthWest from '../assets/img/map_icons/pink_default_top_left.png';
import pinkDotDefault from '../assets/img/map_icons/pink_dot_default.png';
import redDefaultNorth from '../assets/img/map_icons/red_default_top.png';
import redDefaultNorthEast from '../assets/img/map_icons/red_default_top_right.png';
import redDefaultEast from '../assets/img/map_icons/red_default_right.png';
import redDefaultSouthEast from '../assets/img/map_icons/red_default_bottom_right.png';
import redDefaultSouth from '../assets/img/map_icons/red_default_bottom.png';
import redDefaultSouthWest from '../assets/img/map_icons/red_default_bottom_left.png';
import redDefaultWest from '../assets/img/map_icons/red_default_left.png';
import redDefaultNorthWest from '../assets/img/map_icons/red_default_top_left.png';
import redDotDefault from '../assets/img/map_icons/red_dot_default.png';
import yellowDefaultNorth from '../assets/img/map_icons/yellow_default_top.png';
import yellowDefaultNorthEast from '../assets/img/map_icons/yellow_default_top_right.png';
import yellowDefaultEast from '../assets/img/map_icons/yellow_default_right.png';
import yellowDefaultSouthEast from '../assets/img/map_icons/yellow_default_bottom_right.png';
import yellowDefaultSouth from '../assets/img/map_icons/yellow_default_bottom.png';
import yellowDefaultSouthWest from '../assets/img/map_icons/yellow_default_bottom_left.png';
import yellowDefaultWest from '../assets/img/map_icons/yellow_default_left.png';
import yellowDefaultNorthWest from '../assets/img/map_icons/yellow_default_top_left.png';
import yellowDotDefault from '../assets/img/map_icons/yellow_dot_default.png';
import grayDefaultNorth from '../assets/img/map_icons/gray_default_top.png';
import grayDefaultNorthEast from '../assets/img/map_icons/gray_default_top_right.png';
import grayDefaultEast from '../assets/img/map_icons/gray_default_right.png';
import grayDefaultSouthEast from '../assets/img/map_icons/gray_default_bottom_right.png';
import grayDefaultSouth from '../assets/img/map_icons/gray_default_bottom.png';
import grayDefaultSouthWest from '../assets/img/map_icons/gray_default_bottom_left.png';
import grayDefaultWest from '../assets/img/map_icons/gray_default_left.png';
import grayDefaultNorthWest from '../assets/img/map_icons/gray_default_top_left.png';
import grayDotDefault from '../assets/img/map_icons/gray_dot_default.png';

// DO NOT CHANGE THE ORDER OF THE FOLLOWING ARRAYS - THEY ARE ACCESSED BY INDEX
const ARROW_MARKERS = {
  DEFAULT: [
    brownDefaultNorth,
    brownDefaultNorthEast,
    brownDefaultEast,
    brownDefaultSouthEast,
    brownDefaultSouth,
    brownDefaultSouthWest,
    brownDefaultWest,
    brownDefaultNorthWest,
    brownDotDefault,
  ],
  IN_QUEUE: [
    aquaDefaultNorth,
    aquaDefaultNorthEast,
    aquaDefaultEast,
    aquaDefaultSouthEast,
    aquaDefaultSouth,
    aquaDefaultSouthWest,
    aquaDefaultWest,
    aquaDefaultNorthWest,
    aquaDotDefault,
  ],
  TICKETED: [
    blueDefaultNorth,
    blueDefaultNorthEast,
    blueDefaultEast,
    blueDefaultSouthEast,
    blueDefaultSouth,
    blueDefaultSouthWest,
    blueDefaultWest,
    blueDefaultNorthWest,
    blueDotDefault,
  ],
  LOADING: [
    greenDefaultNorth,
    greenDefaultNorthEast,
    greenDefaultEast,
    greenDefaultSouthEast,
    greenDefaultSouth,
    greenDefaultSouthWest,
    greenDefaultWest,
    greenDefaultNorthWest,
    greenDotDefault,
  ],
  TO_JOB: [
    orangeDefaultNorth,
    orangeDefaultNorthEast,
    orangeDefaultEast,
    orangeDefaultSouthEast,
    orangeDefaultSouth,
    orangeDefaultSouthWest,
    orangeDefaultWest,
    orangeDefaultNorthWest,
    orangeDotDefault,
  ],
  ON_JOB: [
    purpleDefaultNorth,
    purpleDefaultNorthEast,
    purpleDefaultEast,
    purpleDefaultSouthEast,
    purpleDefaultSouth,
    purpleDefaultSouthWest,
    purpleDefaultWest,
    purpleDefaultNorthWest,
    purpleDotDefault,
  ],
  UNLOAD_START: [
    pinkDefaultNorth,
    pinkDefaultNorthEast,
    pinkDefaultEast,
    pinkDefaultSouthEast,
    pinkDefaultSouth,
    pinkDefaultSouthWest,
    pinkDefaultWest,
    pinkDefaultNorthWest,
    pinkDotDefault,
  ],
  UNLOADED: [
    redDefaultNorth,
    redDefaultNorthEast,
    redDefaultEast,
    redDefaultSouthEast,
    redDefaultSouth,
    redDefaultSouthWest,
    redDefaultWest,
    redDefaultNorthWest,
    redDotDefault,
  ],
  RETURNING: [
    yellowDefaultNorth,
    yellowDefaultNorthEast,
    yellowDefaultEast,
    yellowDefaultSouthEast,
    yellowDefaultSouth,
    yellowDefaultSouthWest,
    yellowDefaultWest,
    yellowDefaultNorthWest,
    yellowDotDefault,
  ],
  IN_YARD: [
    grayDefaultNorth,
    grayDefaultNorthEast,
    grayDefaultEast,
    grayDefaultSouthEast,
    grayDefaultSouth,
    grayDefaultSouthWest,
    grayDefaultWest,
    grayDefaultNorthWest,
    grayDotDefault,
  ],
  UNKNOWN: [
    brownDefaultNorth,
    brownDefaultNorthEast,
    brownDefaultEast,
    brownDefaultSouthEast,
    brownDefaultSouth,
    brownDefaultSouthWest,
    brownDefaultWest,
    brownDefaultNorthWest,
    brownDotDefault,
  ],
};

export default ARROW_MARKERS;
