import React, { Component } from 'react';
import {
  ContentHeader, HeaderAndContainer, Tooltip, TTBreadCrumb,
} from '@trucktrax/trucktrax-common';
import { Link } from 'react-router-dom';
import { getGeotraxBaseUrl } from '../../../../util/apiUtil';
import { STATIC_PATH } from '../../../../constants/apiConstants';
import { getTokens } from '../../../../util/authUtil';
import { CONTENT_TYPE_MP4 } from '../../../../constants/appConstants';
import styles from './VideoDetailView.module.css';
import AdminCardStyles from '../../../shared/admin/AdminCard.module.css';
import Header from '../../../shared/Header';
import { videoFilepathToName } from '../../../../util/doctUtil';
import { downloadFile } from '../../../../services/requestService';
import { LoadingCircle } from '../../../shared/LoadingCircle';

class VideoDetailView extends Component<VideoDetailViewProps, VideoDetailViewState> {
  constructor(props: VideoDetailViewProps) {
    super(props);

    this.state = {
      filepath: this.props.location.search.replace('?filepath=', ''),
      isDownloading: false,
    };
  }

  onDownload = async () => {
    this.setState({ isDownloading: true });
    const { filepath } = this.state;
    const url = `${getGeotraxBaseUrl() + STATIC_PATH}`;
    const params = { file: filepath };
    const filename = filepath.replace(this.props.folder, '');
    await downloadFile(url, params, filename, CONTENT_TYPE_MP4, false);
    this.setState({ isDownloading: false });
  };

  render() {
    const src = `${getGeotraxBaseUrl() + STATIC_PATH}?tk=${getTokens().accessToken}&file=${this.state.filepath}`;

    const { filepath } = this.state;
    const title = videoFilepathToName(filepath, this.props.folder + this.props.prefix);
    const breadCrumb = (
      <TTBreadCrumb
        data={[
          {
            toLink: this.props.link,
            linkLabel: this.props.breadcrumb,
          },
          {
            linkLabel: title,
          },
        ]}
        linkComponent={Link}
      />
    );

    const elementsLeft = (
      <Header
        title="Training Videos"
        subtitle={breadCrumb}
      />
    );

    const tooltipText = this.state.isDownloading ? 'Downloading...' : 'Download Video (.mp4)';

    const elementRight = (
      <Tooltip text={tooltipText}>
        <button
          className={styles.download}
          onClick={this.onDownload}
        >
          {this.state.isDownloading
            ? <LoadingCircle size={18} />
            : <i className="icon-file-download" />}
        </button>
      </Tooltip>
    );

    const containerComponent = (
      <div className={AdminCardStyles.bodyWrapper}>
        <div className={AdminCardStyles.cardWrapper}>
          <div className={styles.videoContainer}>
            <ContentHeader
              title={title}
              elementRight={elementRight}
              onClose={() => ''}
            />
            <video className={styles.video} width="100%" controls>
              <source src={src} type={CONTENT_TYPE_MP4} />
              <track kind="captions" />
            </video>
          </div>
        </div>
      </div>
    );

    return (
      <HeaderAndContainer
        elementsLeft={elementsLeft}
        containerComponent={containerComponent}
      />
    );
  }
}

export interface Location {
  search: string;
}

export interface VideoDetailViewProps {
  location: Location,
  folder: string,
  link: string,
  prefix: string,
  breadcrumb: string,
}

export interface VideoDetailViewState {
  filepath: string,
  isDownloading: boolean
}

export default VideoDetailView;
