import axios from 'axios';
import { HttpErrorResponse } from '@trucktrax/trucktrax-ts-common';
import recordLog from '../util/errorUtil';
import { store } from '../Main';
import { getTokens, isExpired, setTokens } from '../util/authUtil';
import { noop } from '../util/appUtil';
import { newAccessToken } from '../store/actions/authActions';
import { logOut } from './authService';
import { AUTH_ERROR, AUTH_USER } from '../constants/actionConstants';
import { GENERAL_ERROR_HANDLER_TYPE, SESSION_EXPIRED, USER_UNAUTHORIZED } from '../constants/errorConstants';
import {
  CANCEL_API_DUE_TO_PAGE_CHANGE,
  INVALID_STALE_TOKEN_LOGOUT,
  CLIENT_ID,
  WCS
} from '../constants/appConstants';
import { getAuthBaseUrl } from '../util/apiUtil';

interface DownloadFileResult { data: BlobPart }

export function forceLogOut(message: string) {
  store.dispatch(logOut() as any);
  store.dispatch({
    type: AUTH_ERROR,
    payload: message,
  });
  store.dispatch(newAccessToken(false));
  throw INVALID_STALE_TOKEN_LOGOUT;
}

async function waitForNewToken() {
  return new Promise(p => { setTimeout(p, 1000); });
}

export async function refreshAccessToken(
  args: any,
  retry: (...args: any) => void = noop,
): Promise<any> {
  const isPending = store.getState().newTokenRequest.pending;
  if (isPending) {
    let retryCount = 0;
    while (retryCount < 10) {
      retryCount += 1;
      // eslint-disable-next-line no-await-in-loop
      await waitForNewToken();
      if (!store.getState().newTokenRequest.pending) {
        return retry(...args);
      }
    }
  }

  store.dispatch(newAccessToken(true));

  const { refreshToken: rtk } = getTokens();
  if (isExpired(rtk ?? '')) return forceLogOut(SESSION_EXPIRED);

  const url = getAuthBaseUrl();
  const { sessionId, authTenantName } = store.getState();

  try {
    const { data } = await axios.post(url, {}, {
      params: {
        client_id: CLIENT_ID,
        grant_type: 'refresh_token',
        refresh_token: rtk,
        userType: 'user',
        tenant_name: authTenantName,
      },
      headers: {
        Authorization: `Basic ${WCS}`,
        sessionId: sessionId || '',
      },
    });
    setTokens((action: any) => store.dispatch(action), data, sessionId ?? '');
    store.dispatch({ type: AUTH_USER });
    store.dispatch(newAccessToken(false));
    return retry(...args);
  } catch (e) {
    if (e === INVALID_STALE_TOKEN_LOGOUT) { throw e; }
    return forceLogOut(USER_UNAUTHORIZED);
  }
}

function errorHandler(url: string) {
  return (error: HttpErrorResponse) => {
    const status = error.response && error.response.status;
    if (status === 401) {
      forceLogOut(USER_UNAUTHORIZED);
    } else if (axios.isCancel(error)) {
      throw CANCEL_API_DUE_TO_PAGE_CHANGE;
    } else {
      recordLog(GENERAL_ERROR_HANDLER_TYPE, url, error);
      throw error;
    }
  };
}

export const request = axios.create({
  paramsSerializer: params => Object.keys(params).map(k => `${k}=${params[k]}`).join('&'),
});

request.interceptors.request.use(axiosConfig => {
  const { tenantUrl } = getTokens();
  if (axiosConfig.headers) {
    axiosConfig.headers.Authorization = axiosConfig.params.tk;
    axiosConfig.headers['tenant-url'] = tenantUrl || '';
  }
  return axiosConfig;
});

export function getRequest(
  url: string,
  params?: any,
  cancelToken?: any
) {
  const { accessToken: tk } = getTokens();
  if (isExpired(tk ?? '')) return refreshAccessToken(arguments, getRequest);
  return request.get(url, {
    params: { tk, ...params },
    cancelToken,
  })
    .catch(errorHandler(url));
}

export function getRequestWhenTokenPresentInUrl(url: string, params = {}) {
  return request.get(url, { params })
    .catch(errorHandler(url));
}

export function postRequest(
  url: string,
  body?: any,
  params?: any
) {
  const { accessToken: tk } = getTokens();
  if (isExpired(tk ?? '')) return refreshAccessToken(arguments, postRequest);
  return request.post(url, body, { params: { tk, ...params } })
    .catch(errorHandler(url));
}

export function putRequest(
  url: string,
  body?: any,
  params?: any,
  options = {}
) {
  const { accessToken: tk } = getTokens();
  if (isExpired(tk ?? '')) return refreshAccessToken(arguments, putRequest);
  return request.put(url, body, { params: { tk, ...params }, ...options })
    .catch(errorHandler(url));
}

export function deleteRequest(url: string, params?: any) {
  const { accessToken: tk } = getTokens();
  if (isExpired(tk ?? '')) return refreshAccessToken(arguments, deleteRequest);
  return request.delete(url, { params: { tk, ...params } })
    .catch(errorHandler(url));
}

export async function downloadFile(
  url: string,
  params?: any,
  filename?: string,
  contentType?: string,
  openInBrowser?: boolean
) {
  const { accessToken: tk } = getTokens();
  if (tk && isExpired(tk)) {
    return refreshAccessToken(
      [url, params, filename, contentType, openInBrowser],
      downloadFile
    );
  }

  const response = await request.get(url, {
    responseType: 'blob',
    params: { tk, ...params },
  }).catch(errorHandler(url)) as DownloadFileResult;

  const blob = new Blob([response.data], { type: contentType });
  const blobUrl = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  if (!openInBrowser) {
    anchor.download = filename || url;
  } else {
    anchor.target = '_blank';
  }
  anchor.href = blobUrl;
  anchor.click();
  return response.data;
}

export function postFile(
  url: string,
  value: Blob,
  fileName: string,
  params?: any
) {
  const { accessToken: tk } = getTokens();
  const { currentRegion } = store.getState();
  const currentRegionUrl = currentRegion.url;

  if (tk && isExpired(tk)) {
    return refreshAccessToken(arguments, postFile);
  }

  const formData = new FormData();
  formData.append('file', value, fileName);

  return request.post(url, formData, {
    params: {
      tk,
      region: currentRegionUrl,
      ...params,
    },
  });
}
