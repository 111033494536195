import React from 'react';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import { connect } from 'react-redux';
import styles from '../../geotrax/docs/guides/UserGuidesCommon.module.css';
import ResourceRow, { ResourceRowProps } from './ResourceRow';
import LoadingCircle from '../LoadingCircle';
import { userHasEditPermissionInAnyRegion } from '../../../services/permissionsService';
import { USER_DOCUMENTS } from '../../../constants/appConstants';
import { UserPermissionsByRegion } from '../../../types';

export function ResourceTable(props: ResourceTableProps) {
  const {
    resources,
    userPermission,
    ...resourceRowProps
  } = props;

  const hasEditPermission = userHasEditPermissionInAnyRegion(props.userPermission, USER_DOCUMENTS);

  return (
    <div className={styles.tableContainer}>
      {!resources.length
        ? <LoadingCircle className="transform-center" />
        : (
          <Table>
            <TableBody>
              {
                resources.map(({ name, filepath }) => (
                  <ResourceRow
                    key={filepath}
                    {...resourceRowProps}
                    name={name}
                    filepath={filepath}
                    hasEditPermission={hasEditPermission}
                  />
                ))
              }
            </TableBody>
          </Table>
        )}
    </div>
  );
}

export type ResourceInfo = {
  name: string;
  filepath: string;
};

export interface ResourceTableProps extends Pick<ResourceRowProps, 'name' | 'onEdit' | 'onOpen' | 'onDownload' | 'iconSrc'> {
  resources: ResourceInfo[];
  userPermission: UserPermissionsByRegion;
}

function mapStateToProps(state: any) {
  return {
    userPermission: state.userPermission,
  };
}

export default connect(mapStateToProps, {})(ResourceTable);
