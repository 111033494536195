import { reducer as formReducer } from 'redux-form';
import { AnyAction, combineReducers } from 'redux';

import {
  dataTableDateRange,
  dataTableReducer,
  dataTableSearch,
  dataTableNeedsRefresh,
  combinedDataSources
} from './reducers/dataTableReducers';
import {
  authReducer,
  newTokenRequest,
  minutesToIdleLogout,
  sessionId,
  userUrl,
  authTenantName,
  tenantId,
  token,
  refreshToken,
  hasTemporaryPassword,
  username,
  userFullName,
} from './reducers/authReducers';
import { currentDriverReducer, driversReducer } from './reducers/driverReducers';
import { flagReducerCombined, featureFlagObj } from './reducers/featureFlagReducers';
import {
  modalReducer,
  searchReducer,
  snackbarNotificationReducer,
  snackbarReducer,
  timeStampReducer,
} from './reducers/globalComponentsReducers';
import {
  activePinMap, popupToggleMap, positionList, selectedMarker, pinRadiusMiles,
} from './reducers/mapReducers';
import { currentTabReducer, inboxReplyMessageReducer, messageCenterReducer } from './reducers/messageCenterReducers';
import { messageList } from './reducers/messagingReducers';
import {
  dropdownIsOpen, geotraxPage, guideDropdownIsOpen, openStateMap, sidebar,
} from './reducers/navReducers';
import { plantList, currentPlant } from './reducers/plantReducers';
import { currentAppNameReducer } from './reducers/appReducer';
import releaseDocsReducer from './reducers/staticResourceReducers';
import {
  assignedRegionList,
  currentRegion,
  regionList,
  defaultRegionList,
  selectedRegion,
  userOverrides
} from './reducers/regionReducers';
import {
  primaryProductLine, routeHistoryProductLines, secondaryProductLine, secondaryProductLines, selectedProductLine,
} from './reducers/productLineReducers';
import { messageSubscriptionReducer } from './reducers/subscriptionReducers';
import { tenantsReducer } from './reducers/tenantReducers';
import {
  currentTicketsColumns,
  pastTicketEndDate,
  pastTicketList,
  pastTicketStartDate,
  selectedTicket,
  ticketsReducer,
  currentTicketQualityFactors,
  ticketTimeline,
  ticketView,
  scaleTraxSearchFilters,
  ticketListFilters,
  isTicketPrintViewCenter,
  isOpenTicket,
  needToRefreshData,
} from './reducers/ticketReducers';
import { usersReducer } from './reducers/userReducers';
import {
  adminPermissionAccessReducer,
  assignedPermissionsReducer,
  permissionGroupsReducer,
  permissionsReducer,
  userPermissionReducer,
} from './reducers/permissionReducers';
import { mapFilters } from './reducers/mapFilterReducers';
import { currentGeozone } from './reducers/geolocationReducers';
import { LOGOUT_USER } from '../constants/actionConstants';
import { customersReducer } from './reducers/customersReducer';
import { projectsReducer, recentProjectsReducer } from './reducers/projectsReducer';
import { productsReducer } from './reducers/productsReducer';
import { haulersReducer } from './reducers/haulerReducers';
import { scaleReducer, receivedWeightReducer, scaleHubStatusReducer } from './reducers/scaleReducers';
import { trucksReducer } from './reducers/truckReducers';
import { ordersReducer } from './reducers/ordersReducer';
import { notebookOperatorsReducer, operatorNotesReducer } from './reducers/operatorNotesReducer';
import { citiesReducer } from './reducers/citiesReducer';
import { printerReducer } from './reducers/printersReducers';
import { routeHistoryHoverSelection, routeHistoryList, selectedRouteHistoryList } from './reducers/routeHistoryReducers';
import { centerTicketPrintView } from './actions/ticketActions';
import { telemetryLogger } from './reducers/telemetryReducer';

const appReducer = combineReducers({
  currentAppName: currentAppNameReducer,
  snackbar: snackbarReducer,
  snackbarNotification: snackbarNotificationReducer,
  messages: messageCenterReducer,
  replyMap: inboxReplyMessageReducer,
  auth: authReducer,
  positionList,
  assignedRegionList,
  regionList,
  currentRegion,
  selectedProductLine,
  currentTicketsColumns,
  driverList: driversReducer,
  dataTableList: dataTableReducer,
  usersList: usersReducer,
  tenantsList: tenantsReducer,
  haulersList: haulersReducer,
  scalesList: scaleReducer,
  printerList: printerReducer,
  scaleHub: scaleHubStatusReducer,
  customersList: customersReducer,
  projectsList: projectsReducer,
  allProducts: productsReducer,
  citiesList: citiesReducer,
  trucksList: trucksReducer,
  ticketsList: ticketsReducer,
  currentTicketQualityFactors,
  permissionList: permissionsReducer,
  permissionGroupList: permissionGroupsReducer,
  assignedPermissionList: assignedPermissionsReducer,
  receivedWeight: receivedWeightReducer,
  ordersList: ordersReducer,
  pastTicketList,
  pastTicketStartDate,
  pastTicketEndDate,
  ticketTimeline,
  ticketListFilters,
  currentDriver: currentDriverReducer,
  messageList,
  openModal: modalReducer,
  searchReducer,
  popupToggleMap,
  selectedMarker,
  selectedTicket,
  isOpenTicket,
  needToRefreshData,
  currentTab: currentTabReducer,
  ticketView,
  timeStamp: timeStampReducer,
  messageSubscription: messageSubscriptionReducer,
  form: formReducer,
  flags: flagReducerCombined,
  newTokenRequest,
  dataTableSearch,
  dataTableDateRange,
  dataTableNeedsRefresh,
  sidebar,
  plantList,
  currentPlant,
  dropdownIsOpen,
  guideDropdownIsOpen,
  openStateMap,
  mapFilters,
  userPermission: userPermissionReducer,
  adminPermissionAccess: adminPermissionAccessReducer,
  releasenotes: releaseDocsReducer,
  minutesToIdleLogout,
  sessionId,
  userUrl,
  defaultRegionList,
  userOverrides,
  authTenantName,
  primaryProductLine,
  secondaryProductLine,
  secondaryProductLines,
  tenantId,
  token,
  refreshToken,
  hasTemporaryPassword,
  username,
  featureFlagObj,
  userFullName,
  recentProjects: recentProjectsReducer,
  operatorNotes: operatorNotesReducer,
  notebookOperators: notebookOperatorsReducer,
  scaleTraxSearchFilters,
  geotraxPage,
  currentGeozone,
  activePinMap,
  pinRadiusMiles,
  routeHistoryProductLines,
  routeHistoryList,
  selectedRouteHistoryList,
  routeHistoryHoverSelection,
  selectedRegion,
  centerTicketPrintView,
  isTicketPrintViewCenter,
  combinedDataSources,
  telemetryLogger
});

type AppReducer = typeof appReducer;
export type ReduxState = ReturnType<AppReducer>;

// eslint-disable-next-line import/prefer-default-export
export const rootReducer = (state?: ReduxState, action?: AnyAction) => {
  let newState = state;
  if (action?.type === LOGOUT_USER) {
    newState = undefined;
  }
  const result = appReducer(newState, action!);
  return result;
};
