import { vars } from '@trucktrax/trucktrax-common';

const markerColors = [
  vars.blue,
  vars.orange,
  vars.green,
  vars.red,
  vars.gray600,
  vars.yellow,
  vars.purple,
  vars.brown,
  vars.aqua,
  vars.pink,
  vars.darkblue,
  vars.darkorange,
  vars.darkgreen,
  vars.darkred,
];

export default markerColors;
