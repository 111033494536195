import React, { Component } from 'react';
import { RegionDto } from '@trucktrax/trucktrax-ts-common';
import cx from 'classnames';
import { FloatingActionButton } from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import styles from './RegionsAddModal.module.css';
import { defaultProductLineRadioOptions, validateSpacing } from '../../../util/adminUtil';
import AdminAddModal, { AdminConfig } from '../../shared/admin/AdminAddModal';
import { addToOrUpdateListInStore } from '../../../store/actions/dataTableActions';
import { createDataTableRecord } from '../../../services/dataTableService';
import { handleRegionAdded } from '../../../services/regionsService';

import { ADD_REGION } from '../../../constants/actionConstants';
import {
  ADD_REGION_BUTTON_TEXT,
  ADD_REGION_MODAL_TITLE,
  ADMIN_KEYS,
  ADMIN_LABELS,
  CHECKBOX_FORM,
  DISPATCHER_CANNED_MESSAGES_TEXT,
  INPUT_FORM,
  MAP_FORM,
  SUCCESS,
  TEXT_DISPLAY,
} from '../../../constants/appConstants';
import { REGIONS_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';
import { ADD_REGION_SUCCESS_TEXT } from '../../../constants/successConstants';
import { CARD_MAP_HEIGHT } from '../../../constants/mapConstants';
import { ReduxState } from '../../../store';
import { ConnectedDispatchFunction, ConnectedFunction } from '../../../types';
import AddFeatureModal from '../../shared/AddFeatureModal';
import { store } from '../../../Main';
import { openSnackbar } from '../../../store/actions/snackbarActions';
import { checkNullInRequired } from '../../../util/validation';

export class RegionsAddModal extends Component<RegionsAddModalProps> {
  onSubmit = (dto: RegionDto, onError: any) => {
    // Changing type to match with DB format
    dto.productLines = dto.productLines?.toString() as any;
    const { createAdminTableData: post } = this.props;
    const url = getGeotraxBaseUrl() + REGIONS_PATH;
    post(
      url,
      dto,
      r => this.onSuccess(r),
      onError
    );
  };

  onSuccess = async (region: RegionDto) => {
    this.props.addToOrUpdateListInStore(region, ADD_REGION);
    await this.props.handleRegionAdded(region);
    this.redirectBack();
  };

  redirectBack = () => {
    let hasUpdated = false;
    const updateInterval = global.setInterval(() => {
      if (hasUpdated && this.props.history.location.pathname === '/admin/regions') {
        global.clearInterval(updateInterval);

        store.dispatch(openSnackbar({
          snackbarBody: ADD_REGION_SUCCESS_TEXT,
          dataTest: 'device-actions-createDevice-success-snackbar',
          snackbarType: SUCCESS,
        }));

        return;
      }

      this.props.history.push('/admin/regions');
      hasUpdated = true;
    }, 200);
  };

  static validateRegionName = (value: string) => validateSpacing(value) ?? '';

  static cannedMessagesAccessor = (value: any) => DISPATCHER_CANNED_MESSAGES_TEXT;

  static config = (): AdminConfig[] => [{
    type: INPUT_FORM,
    key: ADMIN_KEYS.NAME,
    label: ADMIN_LABELS.NAME,
    dataTest: 'name-input-data-test',
    errorDataTest: 'name-input-missing-error',
    maxLength: 25,
    className: cx('halfWidthSpace', 'baseMargin'),
    isRequired: true,
    customValidation: RegionsAddModal.validateRegionName,
  }, {
    type: CHECKBOX_FORM,
    key: ADMIN_KEYS.PRODUCT_LINES,
    label: ADMIN_LABELS.PRODUCT_LINES,
    className: cx('fullWidthSpace', 'baseMargin'),
    items: defaultProductLineRadioOptions,
    isRequired: true,
    errorDataTest: 'primaryProductLine-missing-error',
    customValidation: checkNullInRequired,
  }, {
    type: INPUT_FORM,
    key: ADMIN_KEYS.DESCRIPTION,
    label: ADMIN_LABELS.DESCRIPTION,
    maxLength: 50,
    dataTest: 'description-input-data-test',
    errorDataTest: 'description-input-missing-error',
    className: cx('fullWidthSpace', 'baseMargin'),
    isRequired: true,
    customValidation: checkNullInRequired,
  }, {
    type: MAP_FORM,
    label: ADMIN_LABELS.REGION_CENTER_POINT,
    key: 'location', // this makes it so AdminAddModal will pass its this.state.value.location to the customValidation function below
    isEditable: true,
    accessor: (value: any) => value,
    className: styles.map,
    showDrawingManager: true,
    editMarkerOnly: true,
    defaultMapZoom: 1,
    containerStyle: {
      height: '100%',
      minHeight: CARD_MAP_HEIGHT,
      width: '100%',
    },
    showGeoZone: false,
    showMarker: true,
    customValidation: (location) => {
      if (!location.latitude || !location.longitude) {
        return 'This field is required.';
      }

      return '';
    },
  }, {
    type: TEXT_DISPLAY,
    label: ADMIN_LABELS.LATITUDE,
    key: ADMIN_KEYS.LATITUDE,
    accessor: ADMIN_KEYS.LATITUDE,
    className: cx('halfWidthSpace', 'baseMargin', styles.latitude),
  }, {
    type: TEXT_DISPLAY,
    label: ADMIN_LABELS.LONGITUDE,
    key: ADMIN_KEYS.LONGITUDE,
    accessor: ADMIN_KEYS.LONGITUDE,
    className: cx('halfWidthSpace', 'baseMargin', styles.longitude),
  }, {
    type: TEXT_DISPLAY,
    label: ADMIN_LABELS.DISPATCHER_CANNED_MESSAGES,
    key: ADMIN_KEYS.DISPATCHER_CANNED_MESSAGES,
    accessor: RegionsAddModal.cannedMessagesAccessor,
    className: cx('fullWidthSpace', styles.cannedMessages),
  }, {
    // Sets initial state for location
    type: 'default',
    key: 'location',
    initialSelected: {
      value: {
        latitude: 0,
        longitude: 0,
      },
    },
  }];

  render() {
    return (
      <div>
        <FloatingActionButton onClick={this.props.openModal} />
        <AddFeatureModal
          title={ADD_REGION_MODAL_TITLE}
          isOpen={this.props.isOpen}
          onCancel={this.props.closeModal}
          style={styles.regionsAddModal}
        >
          <AdminAddModal
            config={RegionsAddModal.config()}
            addButtonText={ADD_REGION_BUTTON_TEXT}
            addButtonDataTest="regions-add-button-data-test"
            onRightBtnClick={this.onSubmit}
            discardAcceptAction={this.props.discardAcceptAction}
            discardRejectAction={this.props.discardRejectAction}
            isCloseRequest={this.props.isCloseRequest}
          />
        </AddFeatureModal>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
});

type RegionsAddModalReduxStateProps = ReturnType<typeof mapStateToProps>;
type RegionsAddModalReduxDispatchProps = {
  createAdminTableData: ConnectedDispatchFunction<typeof createDataTableRecord>,
  addToOrUpdateListInStore: ConnectedFunction<typeof addToOrUpdateListInStore>,
  handleRegionAdded: ConnectedDispatchFunction<typeof handleRegionAdded>
};
type RegionsAddModalOwnProps = {
  isOpen: boolean,
  openModal: VoidFunction,
  closeModal: VoidFunction,

  discardAcceptAction: VoidFunction,
  discardRejectAction: VoidFunction,
  history: {
    push: (path: string) => void,
    location: {
      pathname: string
    }
  },
  isCloseRequest: boolean,
};
export type RegionsAddModalProps = RegionsAddModalReduxStateProps & RegionsAddModalReduxDispatchProps & RegionsAddModalOwnProps;

export default connect(mapStateToProps, {
  createAdminTableData: createDataTableRecord,
  addToOrUpdateListInStore,
  handleRegionAdded,
})(RegionsAddModal);
