import * as signalR from '@microsoft/signalr';
import config from 'react-global-configuration';
import recordLog from '../../util/errorUtil';
import { store } from '../../Main';
import { clearSelectedMarker, closePopup, updatePosition } from '../../store/actions/mapviewActions';
import { updatePositionWithTruckEta } from '../mapviewsService';
import { getIdFromUrl } from '../../util/appUtil';
import {
  JOIN_GROUP,
  RECEIVE_MESSAGE,
  RECONNECT_TIMEOUT,
} from '../../constants/appConstants';
import { HTTP_BRIDGE_BASE_URL, TRACKING_SOCKET, UPDATE_POSITION_GROUP } from '../../constants/apiConstants';
import { getTokens } from '../../util/authUtil';

export class TrackingSocket {
  connected: boolean;

  hubConnection: signalR.HubConnection | null;

  constructor() {
    this.connected = false;
    this.hubConnection = null;
  }

  static onResponse = (response: string) => {
    const { message: position } = JSON.parse(response);
    if (!position?.trackedObject?.url) {
      return;
    }

    const { selectedMarker, currentRegion, selectedProductLine } = store.getState();
    const truckId = getIdFromUrl(position.trackedObject?.url);

    // Update selected truck to null if truck becomes inactive
    if (selectedMarker
      && truckId === selectedMarker.markerId
      && position.active === false) {
      store.dispatch(clearSelectedMarker());
      store.dispatch(closePopup(truckId));
    }

    if (position.region?.url === currentRegion.url
      && position.productLine === selectedProductLine) {
      store.dispatch(updatePosition(position));
      const action = updatePositionWithTruckEta(position);
      store.dispatch<any>(action);
    }
  };

  async connect(regionUrl: string) {
    const { tenantId: tenant } = store.getState();
    const { accessToken } = getTokens();

    // do not try to connect if we're not logged in
    if (!accessToken) {
      return;
    }
    if (this.connected) {
      return;
    }
    const TRACKING_SOCKET_URL = `${config.get(HTTP_BRIDGE_BASE_URL)}${TRACKING_SOCKET}`;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${TRACKING_SOCKET_URL}?tk=${accessToken}`, { accessTokenFactory: () => accessToken! })
      .configureLogging(signalR.LogLevel.None)
      .build();

    this.hubConnection.on(RECEIVE_MESSAGE, message => {
      TrackingSocket.onResponse(message);
    });

    this.hubConnection.onclose(async () => {
      this.disconnect();
      if (this.hubConnection) {
        this.hubConnection!.off(RECEIVE_MESSAGE);
        this.connect(regionUrl);
      }
    });

    try {
      await this.hubConnection.start();
      await Promise.all([`${UPDATE_POSITION_GROUP}${tenant}`]
        .map(group => (this.hubConnection!.invoke(JOIN_GROUP, group))));
      this.connected = true;
    } catch (err) {
      recordLog('warn', 'Tracking Socket', err);
      this.reconnect();
    }
  }

  async reconnect() {
    setTimeout(() => {
      const { currentRegion } = store.getState();
      this.connect(currentRegion ? currentRegion.url as string : '');
    }, RECONNECT_TIMEOUT);
  }

  disconnect(destroyHub?: boolean) {
    if (!this.hubConnection) {
      return;
    }
    this.hubConnection.stop();
    this.connected = false;
    if (destroyHub) {
      this.hubConnection = null;
    }
  }
}

export default new TrackingSocket();
