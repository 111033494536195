import { DriverDto } from '@trucktrax/trucktrax-ts-common';
import { Dispatch } from 'redux';
import {
  ConversationToMessagesDto,
  Message,
  Acknowledgement,
  PartyMember,
} from '../components/shared/messages/index.d';
import {
  MESSAGES_TABS_NEW,
  MESSAGE_SELECTED,
  REGION,
  USERS_RELATIVE_URL,
} from '../constants/appConstants';
import { checkAckTime, getRecipientNames } from '../components/shared/messages/message';
import { HasId } from '../types';
import setSidebarSelected from '../store/actions/sidebarActions';
import { setCurrentTab, updateToFieldValue } from '../store/actions/messageActions';

export function getNewMessage(messages: (ConversationToMessagesDto & HasId)[], newMessageObject: Message) {
  const allMessages = messages;
  let newMessage = newMessageObject;
  const conversationIndex = allMessages.findIndex(m => {
    newMessage = Array.isArray(newMessage) ? newMessage[0] : newMessage;
    return newMessage && m.conversationsUrl === newMessage.conversation.url;
  });
  if (conversationIndex === -1) {
    // Will add the logic in gtx-1542 after POST response changes

  } else {
    const message = allMessages[conversationIndex];
    const { conversations } = message;
    conversations.latestMessage = newMessage;

    const { items } = conversations;
    const messageIndex = messages.findIndex(m => newMessage && m.id === newMessage.id);
    if (messageIndex === -1) {
      items.push(newMessage);
    } else {
      items[messageIndex] = newMessage;
    }

    conversations.items = items;
    message.conversations = conversations;
    allMessages[conversationIndex] = message;
  }
  return allMessages;
}

export function updateAcknowledgement(messages: ConversationToMessagesDto[], ackObject: Acknowledgement) {
  const updatedMessages = messages;
  const conversationIndex = updatedMessages
    .findIndex((c) => c.conversationsUrl === ackObject.conversation.url);
  const m = updatedMessages[conversationIndex];

  if (m !== undefined) {
    const acks = m.conversations.conversationAcknowledgements;
    let ackIndex = acks.findIndex((a) => a.url === ackObject.url);
    // find ack index by party member if not found
    if (ackIndex === -1) {
      ackIndex = acks.findIndex((a) => a.partyMember.url === ackObject.partyMember.url);
    }

    acks[ackIndex] = ackObject;

    m.conversations.conversationAcknowledgements = acks;
    updatedMessages[conversationIndex] = m;
  }

  return updatedMessages;
}

export function generateConversationAcknowledgements(
  newMessage: Message & { tenant: { url: string } },
  regionUrl: string
): Acknowledgement[] {
  if (newMessage.partiesList) {
    return newMessage.partiesList.map((partyMember): Acknowledgement => ({
      id: -1,
      url: '',
      conversation: { url: newMessage.conversation.url },
      partyMember: { url: partyMember.url },
      lastAcknowledged: '',
    }));
  }
  return [{
    id: -1,
    url: '',
    conversation: { url: newMessage.conversation.url },
    partyMember: { url: newMessage.from.url },
    lastAcknowledged: '',
  }, {
    id: -1,
    url: '',
    conversation: { url: newMessage.conversation.url },
    partyMember: { url: regionUrl },
    lastAcknowledged: '',
  }] as Acknowledgement[];
}

export function updateMessage(messages: ConversationToMessagesDto[], newMessage: Message & { tenant: { url: string } }, regionUrl: string) {
  let updatedMessages = messages;
  const existingThreadIndex = updatedMessages
    .findIndex((m) => m.conversationsUrl === newMessage.conversation.url);

  if (existingThreadIndex !== -1) {
    const includes = newMessage.from.url.includes(USERS_RELATIVE_URL);
    updatedMessages[existingThreadIndex].containsUnreadMessages = !includes;
    const cSize = updatedMessages[existingThreadIndex].conversations.items.length;
    updatedMessages[existingThreadIndex].conversations.items[cSize] = newMessage;
    updatedMessages[existingThreadIndex].conversations.latestMessage = newMessage;

    // reset acknowledgement.
    const acks = updatedMessages[existingThreadIndex].conversations.conversationAcknowledgements;
    const ackIndex = acks.findIndex((a) => a.conversation.url === newMessage.conversation.url);
    const ack = acks[ackIndex];
    ack.lastAcknowledged = '';
    acks[ackIndex] = ack;
    updatedMessages[existingThreadIndex].conversations.conversationAcknowledgements = acks;
  } else {
    const newMessageToAdd: ConversationToMessagesDto = {
      containsUnreadMessages: !newMessage.from.url.includes(REGION),
      conversationsUrl: newMessage.conversation.url,
      conversations: {
        commaSeparatedParties: getRecipientNames(newMessage as any),
        items: [newMessage],
        latestMessage: newMessage,
        partiesList: newMessage.partiesList ? (newMessage.partiesList as PartyMember[]) : [{
          conversation: '',
          firstName: '',
          fullName: '',
          fullToUrl: '',
          lastName: '',
          url: newMessage.from.url,
        }, {
          url: regionUrl,
          conversation: '',
          firstName: '',
          fullName: '',
          fullToUrl: '',
          lastName: '',
        }],
        conversationAcknowledgements: generateConversationAcknowledgements(newMessage, regionUrl),
      },
    };
    updatedMessages = updatedMessages.concat([newMessageToAdd as any]);
  }
  return updatedMessages;
}

export function checkUnread(allMessages: ConversationToMessagesDto[]) {
  try {
    return allMessages.some(m => {
      const convo = m.conversations;
      const latestMessageIdx = convo.items.length - 1;
      const latestMessage = convo.items[latestMessageIdx];
      const acknowledgements = convo.conversationAcknowledgements;
      const ack = acknowledgements.filter(a => a.partyMember.url.includes(REGION));
      const unread = checkAckTime(latestMessage.sent, ack[0].lastAcknowledged);
      if (latestMessage.from && latestMessage.from.url) {
        return (latestMessage.from.url.includes(REGION)
          || latestMessage.from.url.includes(USERS_RELATIVE_URL)) ? false : unread;
      }
      return checkAckTime(latestMessage.sent, ack[0].lastAcknowledged);
    });
  } catch (error) {
    return false;
  }
}

export function replaceMessagesInConversation(allMessages: ConversationToMessagesDto[], messages: ConversationToMessagesDto) {
  const updatedMessages = allMessages;
  const index = allMessages.findIndex((m => m.conversationsUrl === messages.conversationsUrl));
  if (index !== -1) {
    updatedMessages[index].conversations.items = messages.conversations.items;
  }
  return updatedMessages;
}

export function truncateDriver(driver: DriverDto) {
  return {
    fullName: `${driver.firstName} ${driver.lastName}`,
    firstName: driver.firstName,
    lastName: driver.lastName,
    url: driver.url,
  };
}

export function messageDrivers(drivers: any[]) {
  return (dispatch: Dispatch, getState: any) => {
    if (!drivers) {
      return;
    }

    const distinctDrivers: any[] = getState().messages?.partiesList.slice() ?? [];

    drivers.forEach(driver => {
      if (driver && distinctDrivers.filter(d => d.url === driver?.url).length === 0) {
        distinctDrivers.push(driver);
      }
    });

    dispatch(setSidebarSelected(MESSAGE_SELECTED, true));
    dispatch(setCurrentTab(MESSAGES_TABS_NEW));
    dispatch(updateToFieldValue(distinctDrivers as any));
  };
}
