/* eslint-disable no-param-reassign */
import React from 'react';
import config from 'react-global-configuration';
import cx from 'classnames';
import { GroupDto, PermissionAccess } from '@trucktrax/trucktrax-ts-common';
import { Link } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import styles from './UserPermissionsView.module.css';
import { PERMISSION_NAMES, HIDDEN_PERMISSIONS, DISPLAY_ENV_KEY } from '../../../../constants/appConstants';
import { UserPermissionRow } from '../../../../util/permissionUtil';

/**
 * render UserPermissionsTable component
 * @param {*} props props for PermissionsView
 */
export function UserPermissionsTable({
  userPermissionsTable = [],
}: UserPermissionsTableProps) {
  const env = (config.get(DISPLAY_ENV_KEY) || '').toUpperCase();
  const showHidden = (env === 'DEV' || env === 'LOCAL');
  const accessText = (access: string | PermissionAccess) => {
    let txtColor = '';
    // using access value to determine font's color.
    if (access) {
      if (access.toUpperCase() === PermissionAccess.Deny.toUpperCase()) {
        txtColor = styles.redBoldText;
      } else if (access.toUpperCase() === PermissionAccess.View.toUpperCase()) {
        txtColor = styles.blueText;
      } else if (access.toUpperCase() === PermissionAccess.Edit.toUpperCase()) {
        txtColor = styles.greenText;
      }
    }
    access = access.toUpperCase() === PermissionAccess.Deny.toUpperCase() ? 'NONE' : access;
    return (
      <span className={cx(styles.cellText, styles.accessText, txtColor)}>
        {access}
      </span>
    );
  };

  const formatGroupLink = (group: GroupDto, hasNext: boolean) => (
    <span key={group.id}>
      <Link to={`/admin/permissions/groups/${group.id}`} key={group.id}>
        {group.name}
      </Link>
      {hasNext && (', ')}
    </span>
  );

  const displayInheritFromGroup = (inheritance: string | GroupDto[]) => {
    if (Array.isArray(inheritance)) {
      return inheritance.map((g, i) => formatGroupLink(g, i < inheritance.length - 1));
    }

    return (
      <span className={cx(styles.cellText, styles.disabledText)}>
        {inheritance}
      </span>
    );
  };

  return (
    <Table>
      <TableHead className={styles.headerContainer}>
        <TableRow className={styles.headerRow}>
          <TableCell className={styles.headerStyle}>
            <span className={cx('txt-bold')}>Description</span>
          </TableCell>
          <TableCell className={styles.headerStyle}>
            <span className={cx('txt-bold')}>Access</span>
          </TableCell>
          <TableCell className={styles.headerStyle}>
            <span className={cx('txt-bold')}>Inherited from Group(s)</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {userPermissionsTable.map(p => {
          let access = p.access
            ? p.access.toUpperCase()
            : PermissionAccess.Deny.toUpperCase();

          if (access === PermissionAccess.View.toUpperCase()
            && p.permission === PERMISSION_NAMES.REPORTS) {
            access = PermissionAccess.Deny.toUpperCase();
          }
          return (p.permission && (showHidden || !HIDDEN_PERMISSIONS.includes(p.permission)) && (
            <TableRow
              key={p.permission}
              className={cx(styles.row)}
            >
              <TableCell className={cx(styles.borderLeft, styles.column)}>
                <span className={styles.cellText}>
                  {p.permission}
                </span>
              </TableCell>
              <TableCell className={styles.column}>
                {accessText(p.access)}
              </TableCell>
              <TableCell className={cx(styles.borderRight, styles.column)}>
                <span className={styles.cellText}>
                  {displayInheritFromGroup(p.inheritance)}
                </span>
              </TableCell>
            </TableRow>
          )
          );
        })}
      </TableBody>
    </Table>
  );
}

export interface UserPermissionsTableProps {
  userPermissionsTable?: UserPermissionRow[];
}

export default UserPermissionsTable;
