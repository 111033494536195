import React from 'react';
import { store } from '../Main';
import { updateTicketTimeline } from '../store/actions/ticketActions';
import UnreadNotice from '../components/shared/UnreadNotice';
import setSidebarSelected from '../store/actions/sidebarActions';
import {
  APP_SWITCHER_SELECTED,
  MESSAGE_SELECTED,
  NEW_MESSAGE_WINDOW,
  NOTEBOOK_SELECTED,
  TICKETS_SELECTED,
} from '../constants/appConstants';
import AppSwitcher from '../components/shared/AppSwitcher';
import { IS_MESSAGE_WINDOW_OPEN } from '../constants/localStorageConstants';

export const messagesActionItems = {
  messages: {
    tooltip: 'Messages',
    onOpen: () => {
      const isWindowOpen = window.localStorage.getItem(IS_MESSAGE_WINDOW_OPEN);
      if (isWindowOpen === 'true') {
        window.open('', NEW_MESSAGE_WINDOW);
      } else {
        store.dispatch(setSidebarSelected(MESSAGE_SELECTED, true));
      }
    },
    onClose: () => {
      store.dispatch(setSidebarSelected(MESSAGE_SELECTED, false));
    },
    icon: 'icon-messages',
    element: <UnreadNotice />,
    styles: [],
  },
};

export const ticketingActionItems = {
  ticketsPopover: {
    tooltip: 'Tickets',
    onOpen: () => {
      store.dispatch(setSidebarSelected(TICKETS_SELECTED, true));
    },
    onClose: () => {
      store.dispatch(setSidebarSelected(TICKETS_SELECTED, false));
      store.dispatch(updateTicketTimeline());
    },
    icon: 'icon-tickets',
    styles: [],
  },
};

export const notebookActionItems = {
  notebookPopover: {
    tooltip: 'Notebook',
    onOpen: () => {
      store.dispatch(setSidebarSelected(NOTEBOOK_SELECTED, true));
    },
    onClose: () => {
      store.dispatch(setSidebarSelected(NOTEBOOK_SELECTED, false));
      store.dispatch(updateTicketTimeline());
    },
    icon: 'icon-translate',
    styles: [],
  },
};

export const appSelectorActionItems = {
  appPopover: {
    tooltip: 'Apps Menu',
    hideTooltipWhenOpen: true,
    onOpen: () => {
      store.dispatch(setSidebarSelected(APP_SWITCHER_SELECTED, true));
    },
    onClose: () => {
      store.dispatch(setSidebarSelected(APP_SWITCHER_SELECTED, false));
    },
    icon: 'icon-menu',
    element: <AppSwitcher />,
    styles: [],
  },
};
