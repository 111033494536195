import React from 'react';
import { AGGREGATE_VALUE, CEMENT_VALUE, READYMIX_VALUE } from '../../../constants/appConstants';
import styles from './TicketsView.module.scss';

// eslint-disable-next-line import/prefer-default-export
export const addProductLinePill = (productLine: string) => {
  switch (productLine) {
    case AGGREGATE_VALUE:
      return generatePill(styles.pillAggregated, 'AGG');
    case CEMENT_VALUE:
      return generatePill(styles.pillCement, 'CEM');
    case READYMIX_VALUE:
      return generatePill(styles.pillReadyMix, 'RM');
    default:
      return '';
  }
};

const generatePill = (classPill: string, productLine: string) => (
  <div className={styles.centering}>
    <div className={`${styles.pill} ${classPill}`}>
      <div className={styles.pillText}>{productLine}</div>
    </div>
  </div>);
