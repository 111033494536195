import React, { Component } from 'react';
import { connect } from 'react-redux';
import findKey from 'lodash/findKey';
import { UrlKeyDto, OrderDto } from '@trucktrax/trucktrax-ts-common';
import { getTrucksInCurrentRegion } from '../../../util/positionsUtil';
import { getValidOrdersListInDateRange } from '../../../util/orderUtil';
import { closeAllPopups, openPopup, selectMarker } from '../../../store/actions/mapviewActions';
import styles from './VehicleSearch.module.css';
import { SEARCH_VEHICLE_AND_ORDER_HINT } from '../../../constants/appConstants';
import { MARKER_TYPE_ORDER, MARKER_TYPE_TRUCK } from '../../../constants/mapConstants';
import { PositionDetailsDto } from '../../../types';
import Autocomplete from '../forms/Autocomplete';

export interface VehicleAndOrderSearchState {
  dataSource: any[];
  searchText: string;
  hintText: string;
  errorText?: string;
  dataSourceConfig: {
    text: string;
    value: string;
    label: string;
    icon: string;
  }
}

export class VehicleAndOrderSearch extends Component<VehicleAndOrderSearchProps, VehicleAndOrderSearchState> {
  static defaultProps: Partial<VehicleAndOrderSearchProps> = {
    currentRegion: undefined,
    positions: [],
    ordersList: [],
  };

  constructor(props: VehicleAndOrderSearchProps) {
    super(props);

    this.state = {
      dataSource: [],
      searchText: '',
      hintText: SEARCH_VEHICLE_AND_ORDER_HINT,
      errorText: '',
      dataSourceConfig: {
        text: 'objectId',
        value: 'markerId',
        label: 'type',
        icon: 'icon',
      },
    };
  }

  componentDidUpdate(prevProps: VehicleAndOrderSearchProps) {
    const hasDataSourceRequiredData = this.props.positions
      && this.props.currentRegion
      && this.props.ordersList;

    const newProps = prevProps !== this.props;

    if (newProps && hasDataSourceRequiredData) {
      const truckList = getTrucksInCurrentRegion(this.props.positions!, this.props.currentRegion!.url!);

      const truckData = truckList.map(truck => ({
        objectId: truck.name,
        icon: 'icon-truck-dump',
        type: MARKER_TYPE_TRUCK,
        ...truck.data,
        markerData: null as any,
        isExternal: truck.data.isExternal,
      }));

      type TruckData = typeof truckData[0];
      const orderData = getValidOrdersListInDateRange(this.props.ordersList!).map((order: any): TruckData => ({
        isActive: true,
        status: null,
        ticketUrl: '',
        objectId: order.orderNumber.toString(),
        markerId: order.id.toString(),
        icon: 'icon-orders',
        type: MARKER_TYPE_ORDER,
        lat: order.geoZone.zone.circle.center.latitude,
        lng: order.geoZone.zone.circle.center.longitude,
        markerData: order,
        isExternal: true,
      }));

      const dataSource = truckData.concat(orderData);

      this.setState({
        dataSource,
        errorText: undefined,
      });
    }
  }

  handleUpdateInput = (searchText: string) => {
    this.setState({
      searchText,
      errorText: undefined,
    });
  };

  handleNewRequest = (objectToPopup: any, reset: () => void) => {
    const isSelected = objectToPopup && objectToPopup.objectId;
    const key = findKey(this.state.dataSource, o => o.objectId === objectToPopup)!;
    const isEnter = this.state.dataSource[key as any];

    this.props.closeAllPopups();

    this.setState({
      hintText: SEARCH_VEHICLE_AND_ORDER_HINT,
    }, () => {
      if (isEnter) {
        this.props.selectMarker(isEnter);
        this.setState({
          errorText: undefined,
        });
      } else if (isSelected) {
        if (objectToPopup.type === MARKER_TYPE_TRUCK) {
          this.props.selectMarker(objectToPopup);
          this.setState({
            errorText: undefined,
          });
        } else if (objectToPopup.type === MARKER_TYPE_ORDER) {
          this.props.selectMarker(objectToPopup);
          this.setState({
            errorText: undefined,
          });
        }
      } else {
        this.setState({
          errorText: 'No results found.',
          searchText: '',
        });
        reset();
      }
    });
  };

  clearSearch = () => {
    this.setState({ searchText: '' });
  };

  render() {
    return (
      <div className={styles.searchWrapper}>
        <Autocomplete
          dataTest="header-truck-and-order-search"
          hintText={this.state.hintText}
          onUpdateInput={this.handleUpdateInput}
          onNewRequest={this.handleNewRequest}
          searchText={this.state.searchText}
          dataSource={this.state.dataSource}
          dataSourceConfig={this.state.dataSourceConfig}
          errorText={this.state.errorText}
          openOnFocus={false}
          autocompleteHeight="500px"
          clearSearch={this.clearSearch}
          itemToString={(item: any) => (item === null ? '' : item.objectId)}
        />
      </div>
    );
  }
}

export interface VehicleAndOrderSearchProps {
  currentRegion?: UrlKeyDto;
  selectMarker: (marker: any) => void;
  closeAllPopups: () => void;
  positions?: PositionDetailsDto[];
  ordersList?: OrderDto[];
}

function mapStateToProps(state: any) {
  return {
    currentRegion: state.currentRegion,
    positions: state.positionList,
    selectedMarker: state.selectedMarker,
  };
}

export default connect(mapStateToProps, {
  selectMarker,
  closeAllPopups,
  openPopup,
})(VehicleAndOrderSearch);
