import React from 'react';
import { connect } from 'react-redux';
import Proptypes from 'prop-types';
import style from './UnreadNotice.module.css';

export function UnreadNotice(props: { unreadNotice?: boolean }) {
  const { unreadNotice } = props;
  if (unreadNotice) {
    return (
      <span className={style.unreadNotice} />
    );
  }
  return null;
}

UnreadNotice.defaultProps = {
  unreadNotice: false,
};

UnreadNotice.propTypes = {
  unreadNotice: Proptypes.bool,
};

export default connect<any, any, any>((state: any) => ({
  unreadNotice: state.messageList.unreadNotice,
}), {})(UnreadNotice);
