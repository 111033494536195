import {
  SCALE_STATE_NOTIFIED,
  SCALE_WEIGHT_RECEIVED,
  SET_SCALEHUB_STATE,
} from '../../constants/actionConstants';
import { ScaleHubState, ScaleState } from '../../types';

// eslint-disable-next-line import/prefer-default-export
export const scaleWeightReceived = (
  scaleId: string,
  weight: number,
  isMoving = false
) => ({
  type: SCALE_WEIGHT_RECEIVED,
  payload: {
    scaleId,
    weight,
    isMoving,
  },
});

export const scaleStateNotified = (
  scaleId: string,
  scaleState: ScaleState
) => ({
  type: SCALE_STATE_NOTIFIED,
  payload: {
    scaleId,
    scaleState,
  },
});

export const setScaleHubState = (state: ScaleHubState) => ({
  type: SET_SCALEHUB_STATE,
  payload: state,
});
