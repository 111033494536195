import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Button } from '@trucktrax/trucktrax-common';
import { postFile } from '../../../../services/requestService';
import { openSnackbar } from '../../../../store/actions/snackbarActions';
import styles from './ReleaseDocAddModal.module.css';
import { STATIC_PATH } from '../../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../../util/apiUtil';

export class ReleaseDocAddModal extends Component<ReleaseDocAddModalProps, ReleaseDocAddModalState> {
  hasError = false;

  constructor(props: ReleaseDocAddModalProps) {
    super(props);

    this.state = {
      enableSubmit: false,
      uploading: false,
      selectedFile: '',
      fileName: '',
      fileNameWithoutExtension: '',
    };
  }

  chooseNewFile = () => {
    const myElement: HTMLElement = document.querySelector('#fileUploader') as HTMLElement;
    myElement.click();
    this.hasError = false;
  };

  addNewReleaseDoc = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      this.setState({
        selectedFile: file,
        enableSubmit: true,
        fileName: file.name,
        fileNameWithoutExtension: file.name.replace('.pdf', ''),
      });
    }
  };

  uploadReleaseDoc = async () => {
    const uploadUrl = getGeotraxBaseUrl() + STATIC_PATH;

    this.setState({ uploading: true });
    try {
      await postFile(uploadUrl, this.state.selectedFile as Blob, this.props.folder + this.state.fileName);

      this.props.onSubmit();
      this.props.openSnackbar!({
        snackbarBody: 'New version doc has been successfully added.',
        dataTest: 'added-release-doc-snackbar',
      });
    } catch (error: any) {
      if (error.response
        && error.response.data
        && error.response.data.status === 409) {
        this.hasError = true;
        this.setState({
          enableSubmit: false,
          uploading: false,
        });
      }
    }
  };

  render() {
    return (
      <div>
        <div>
          <div className={styles.docContainer}>
            <p className={styles.pdfLabel}>PDF File</p>
            <div
              className={this.hasError
                ? styles.errorPdfDoc
                : styles.pdfDoc}
            >
              {this.hasError && (<i className={cx('icon-drive-file', styles.fileUploadError)} />)}

              {this.state.enableSubmit ? <i className={cx('icon-drive-file', styles.pdfDoc)} /> : ''}
              <span>{this.state.fileNameWithoutExtension}</span>
            </div>
            <div className={styles.pdfDoc}>
              {this.hasError
                && (
                  <div className={styles.errorList}>
                    <span className={styles.listWarning}>
                      <i
                        aria-hidden="true"
                        className={cx('icon-warning', styles.warningIcon)}
                      />
                      <span className={styles.listWarningText}>
                        Filename already exists in the system.
                      </span>
                    </span>
                    <span className={styles.listWarningTextNextLine}>
                      Please revise filename and re-submit.
                    </span>
                  </div>
                )}

            </div>

            <input
              id="fileUploader"
              type="file"
              onChange={this.addNewReleaseDoc}
              className={styles.fileUploader}
              accept=".pdf"
              disabled={this.state.uploading}
            />
            {this.state.enableSubmit || this.hasError ? (
              <button
                onClick={this.chooseNewFile}
                className={!this.hasError ? styles.chooseNewFileButton : styles.chooseNewFileButtonWhenHasError}
                disabled={this.state.uploading}
              >
                Choose New File
              </button>
            ) : (
              <button
                onClick={this.chooseNewFile}
                className={styles.chooseFileButton}
                disabled={this.state.uploading}
              >
                Choose File
              </button>
            )}

          </div>

          <div className={styles.actions}>
            <Button
              buttonClassName={cx(styles.cancelButton, 'tt-btn-basic')}
              name="Cancel"
              dataTest="cancel-upload-release-doc"
              onClick={this.props.onCancel}
              disabled={this.state.uploading}
            />
            <Button
              buttonClassName={cx(styles.addReleaseDocButton, 'tt-btn--submit')}
              name="Add New Doc"
              dataTest="upload-release-doc"
              disabled={!this.state.enableSubmit}
              onClick={this.uploadReleaseDoc}
            />
          </div>
        </div>

      </div>
    );
  }
}

export type SnackBar = { [key: string]: string };

export interface ReleaseDocAddModalProps {
  onCancel: () => void,
  onSubmit: () => void,
  openSnackbar?: (snackbar: { snackbarBody: string, dataTest: string }) => void,
  folder: string
}

export interface ReleaseDocAddModalState {
  enableSubmit: boolean,
  uploading: boolean,
  selectedFile: Blob | string,
  fileName: string,
  fileNameWithoutExtension: string
}

function mapStateToProps(state: any) {
  return { ...state };
}

export default connect(mapStateToProps, { openSnackbar })(ReleaseDocAddModal);
