/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import cx from 'classnames';
import { Label } from '@trucktrax/trucktrax-common';
import styles from '../admin/AdminAddModal.module.css';
import { generateRadioButtons, getEventFromTarget } from '../../../util/adminUtil';
import { noop } from '../../../util/appUtil';
import { ADMIN_LABELS, NONE_STRING, NOT_AVAILABLE } from '../../../constants/appConstants';

export default class CardRadio extends Component<CardRadioProps> {
  static defaultProps: Partial<CardRadioProps> = {
    value: '',
    className: '',
    cardRadioClassName: '',
    id: '',
    onChange: noop,
    label: '',
    isRequired: false,
    radioButtonGroupName: '',
    initialSelected: undefined,
    radioButtons: [],
    errorMessage: undefined,
    row: false,
    display: true,
    disabled: false,
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const event: Record<string, string | boolean> = getEventFromTarget(e, this.props.id!);
    const value = event[this.props.id!];
    // mui RadioGroup will convert booleans to strings
    // let's convert them back to boolean if needed so we can support radio buttons with boolean values
    if (value === 'false') {
      event[this.props.id!] = false;
    } else if (value === 'true') {
      event[this.props.id!] = true;
    }

    this.props.onChange!(event);
  };

  render() {
    const {
      value,
      className,
      cardRadioClassName,
      cardRadioLabelClassName,
      label,
      isRequired,
      radioButtonGroupName,
      initialSelected,
      radioButtons,
      errorMessage,
      row,
      display,
    } = this.props;
    const hasError = !!errorMessage;
    const errorString = Array.isArray(errorMessage) ? errorMessage.join(', ') : errorMessage;

    const hasValue = !!value || typeof value === 'boolean';

    if (!display) {
      return null;
    }

    let selectedValue = '';

    if (value !== undefined) {
      selectedValue = value;
    } else if (typeof initialSelected === 'string') {
      selectedValue = initialSelected;
    } else if (initialSelected?.value !== undefined) {
      selectedValue = initialSelected.value;
    }

    // Fix bug with Secondary Product Line radio in Driver Edit modal
    if (label === ADMIN_LABELS.SECONDARY_PRODUCT_LINE && selectedValue === NOT_AVAILABLE) {
      selectedValue = NONE_STRING;
    }

    return (
      <div className={className}>
        {hasValue
          ? (
            <div className={cardRadioClassName}>
              <Label
                isRequired={isRequired}
                className={this.props.disabled ? styles.radioLabelDisabled : cardRadioLabelClassName}
              >
                {label!}
              </Label>
              <FormControl>
                <RadioGroup
                  row={row}
                  name={radioButtonGroupName}
                  value={selectedValue}
                  onChange={this.onChange}
                >
                  {generateRadioButtons(
                    radioButtons!,
                    !hasError ? styles.radioBtn : styles.radioBtnError,
                    styles.radioBtnSelected,
                    styles.radioLabel,
                    styles.radioBtnDisabled
                  )}
                </RadioGroup>
              </FormControl>
              {(hasError && errorString !== '')
                ? (
                  <label
                    className={cx(styles.textRed, styles.errorFieldMargin, 'tt-label--help')}
                    data-test="card-radio-error"
                  >
                    {errorString}
                  </label>
                )
                : <div />}
            </div>
          )
          : <div />}
      </div>
    );
  }
}

type RadioButton = {
  label: string | JSX.Element;
  value: any;
};

export interface CardRadioProps {
  value?: string;
  errorMessage?: string[];
  className?: string;
  cardRadioClassName?: string;
  cardRadioLabelClassName?: string;
  onChange?: (item: { [name: string]: any }) => void;
  id?: string;
  label?: string;
  isRequired?: boolean;
  radioButtonGroupName?: string;
  initialSelected?: string | { label: string | JSX.Element, value: any };
  radioButtons?: RadioButton[];
  row: boolean;
  display: boolean;
  disabled: boolean;
}
