/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Label } from '@trucktrax/trucktrax-common';

export default function CardMatch({
  value = undefined,
  label = '',
  className = '',
  dataTest = '',
  condition,
  trueDisplay = 'TRUE',
  falseDisplay = 'FALSE',
}: CardMatchProps) {
  return (
    <div className={className}>
      <Label>{label}</Label>
      {(value === condition) ? (
        <p
          data-test={dataTest}
          title={value}
        >
          {trueDisplay}
        </p>
      ) : (
        <p
          data-test={dataTest}
          title={value}
        >
          {falseDisplay}
        </p>
      )}
    </div>
  );
}

export interface CardMatchProps {
  value?: any;
  label?: string | JSX.Element;
  className?: string;
  dataTest?: string;
  condition: string;
  trueDisplay?: string | JSX.Element;
  falseDisplay?: string | JSX.Element;
}
