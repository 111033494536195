import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import cx from 'classnames';
import {
  HeaderAndContainer, DateWrapper, Timespan, vars
} from '@trucktrax/trucktrax-common';
import {
  PlantDto, PositionDto, ProductLine, TicketDto, UrlKeyDto, VoidFunc,
} from '@trucktrax/trucktrax-ts-common';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Paper } from '@mui/material';
import styles from './TicketList.module.css';
import TicketSearch from './TicketSearch';
import TicketStateSwitcher from './TicketStateSwitcher';
import { filterBySearchTerm } from '../../../util/ticketUtil';
import {
  clearSelectedTicket,
  selectTicket,
  setCurrentTicketsColumns,
} from '../../../store/actions/ticketActions';
import { openPopup, selectMarker } from '../../../store/actions/mapviewActions';
import {
  getDefaultPlant, setCurrentPlant,
} from '../../../services/plantsService';
import {
  fetchCurrentTicketList,
  saveFilters,
  selectTruckMarker,
} from '../../../services/ticketsService';
import currentTicketColumns from './ticketListConfigurations/currentTickets';
import pastTicketColumns from './ticketListConfigurations/pastTickets';
import { getIdFromUrl } from '../../../util/appUtil';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import {
  ConnectedDispatchFunction,
  TicketListFilters,
  TicketStateSwitcherType,
  TicketStateValue,
} from '../../../types';
import {
  RowInfo,
} from './ticketListConfigurations/ticketListConfigurations.types';
import DateRangePicker, { DateRangePickerState } from '../DateRangePicker';
import { RefreshCurrentTicketList } from './RefreshCurrentTicketList';
import { getTicketingBaseUrl } from '../../../util/apiUtil';
import { DATASOURCE, TICKETS_PATH } from '../../../constants/apiConstants';
import DataTableContent from '../DataTableContent';

const ReactTableFixedColumns = withFixedColumns(ReactTable) as any;

/* TSLU = Time Since Last Update */
// 15 hours for the time being. Need clarification on this one
export const MAX_TSLU_IN_SECS = 15 * 60 * 60;
export const ALERT_THRESHOLD_TSLU_IN_SECS = 5 * 60; // 5 minutes
const TIMER_REFRESH_PERIOD_MS = 5000; // 5 seconds

const headerStyle = {
  fontWeight: 'bold',
  fontSize: '1.2em',
  fontFamily: vars.fontFamilyBold,
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'antialiased',
};

const cellStyle = {
  fontSize: '1.5em',
  height: '3.1em',
  lineHeight: '2.5em',
};

export class TicketList extends Component<TicketListProps, TicketListState> {
  static defaultProps = {
    positions: [],
    plantList: [],
    plantMapFilter: []
  };

  baseUrl = getTicketingBaseUrl() + TICKETS_PATH;

  constructor(props: TicketListProps) {
    super(props);

    const {
      currentRegion,
      ticketsList,
      selectedProductLine,
      ticketListFilters,
      isScaleTrax,
      currentPlant,
    } = this.props;

    if (!ticketsList || !ticketsList.length) {
      if (isScaleTrax) {
        this.props.fetchCurrentTicketList(currentRegion?.url, selectedProductLine, currentPlant?.url);
      } else {
        const plantsFilter = this.getPlantsFilter();
        // check if plantFilteris has no entry then dont send the plantFilter param in fetchCurrentTicketList
        if (plantsFilter.length > 0) {
          this.props.fetchCurrentTicketList(currentRegion?.url, selectedProductLine, undefined, plantsFilter);
        } else {
          this.props.fetchCurrentTicketList(currentRegion?.url, selectedProductLine);
        }
      }
    }

    // Timer to update TSLU every TIMER_REFRESH_PERIOD_MS seconds
    const timeSinceLastUpdateTimer = global.setInterval(() => this.refreshTimeSinceLastUpdate(), TIMER_REFRESH_PERIOD_MS);

    let ticketStateValue = props.ticketStateSwitcherType === TicketStateSwitcherType.Tabs
      ? TicketStateValue.Active
      : TicketStateValue.AllTickets;

    if (ticketListFilters?.ticketStateValue) {
      ticketStateValue = ticketListFilters.ticketStateValue;
    }

    const startOfToday = DateWrapper.now.startOfDay;

    this.state = {
      // if you don't use .startOf, the date will be wrong depending on the time.
      filterDateEnd: ticketListFilters?.filterDateEnd ?? startOfToday.date,
      filterDateStart: ticketListFilters?.filterDateStart ?? startOfToday.previousDay.date,
      searchTerm: ticketListFilters?.searchTerm ?? '',
      selected: null,
      ticketStateValue,
      timeSinceLastUpdateCounter: 0,
      timeSinceLastUpdateTimer,
    };
  }

  async componentDidMount() {
    if (!this.props.currentPlant) {
      const currentPlant = this.props.currentPlant ?? (await this.props.getDefaultPlant(this.props.currentUser!));
      // set the current plant as the currentPlant in redux from the user's default if it doesn't exist
      this.props.setCurrentPlant(currentPlant);
    }
  }

  componentDidUpdate(prevProps: TicketListProps) {
    const {
      currentRegion,
      selectedProductLine,
      currentPlant,
      plantMapFilters,
    } = this.props;
    const { ticketStateValue } = this.state;

    if (prevProps?.currentRegion?.url !== currentRegion?.url
      || prevProps?.selectedProductLine !== selectedProductLine
      || prevProps?.currentPlant !== currentPlant) {
      this.clearSearchText();
    }
    if (prevProps.plantMapFilters !== plantMapFilters && ticketStateValue === TicketStateValue.Active) {
      const plantsFilter = this.getPlantsFilter();
      if (plantsFilter.length > 0) {
        this.props.fetchCurrentTicketList(currentRegion?.url, selectedProductLine, undefined, plantsFilter);
      } else {
        this.props.fetchCurrentTicketList(currentRegion?.url, selectedProductLine);
      }
    }
  }

  // Clear Time Since Last Update Timer on unmount
  componentWillUnmount() {
    clearInterval(this.state.timeSinceLastUpdateTimer);
  }

  getPlantsFilter = () => {
    const {
      plantMapFilters
    } = this.props;
    let newPlantFilterList: string[] = [];
    if (plantMapFilters) {
      newPlantFilterList = plantMapFilters?.filter((plant) => plant.isSelected === true).map(y => y.url) ?? [];
    }
    return newPlantFilterList;
  };

  saveFilters = () => {
    const {
      ticketStateValue,
      filterDateStart,
      filterDateEnd,
      searchTerm,
    } = this.state;

    this.props.saveFilters({
      ticketStateValue,
      filterDateStart,
      filterDateEnd,
      searchTerm,
    });
  };

  static getSecondsSinceLastUpdate = (row: TicketDto, positions: PositionDto[]) => {
    const filteredPositionByTruck = positions.filter(
      p => (p.trackedObject && (p.trackedObject.url === row.truck?.url))
    );

    if (filteredPositionByTruck.length === 0) {
      return 0;
    }

    const lastUpdateTS = filteredPositionByTruck[0].timestamp;
    let timeDiffInSecs = DateWrapper.differenceBetween(DateWrapper.now, lastUpdateTS).asSeconds();
    timeDiffInSecs = timeDiffInSecs > MAX_TSLU_IN_SECS
      ? MAX_TSLU_IN_SECS : timeDiffInSecs;
    return timeDiffInSecs;
  };

  static renderTimeSinceLastUpdate = (timeDiffInSecs: number) => {
    if (timeDiffInSecs && timeDiffInSecs > 0) {
      const timeDiffFormatted = new Timespan(timeDiffInSecs * 1000).toString('HH:mm:ss');
      return TicketList.countUpTimer(
        timeDiffFormatted,
        timeDiffInSecs > ALERT_THRESHOLD_TSLU_IN_SECS
      );
    }
    return '';
  };

  linkToOrderDetails = (row: TicketDto) => {
    if (!row.order?.url) {
      return '';
    }

    const orderId = getIdFromUrl(row.order.url);
    return (
      <span className={styles.text} key={row.id} title={`${row.order.orderNumber}`}>
        <Link to={`/geotrax/orders/all/${orderId}`} onClick={this.props.closePopover} className={styles.orderLink}>
          {row.order.orderNumber}
        </Link>
      </span>
    ) as JSX.Element;
  };

  getPlantName = (plantUrl: string) => {
    const foundPlant = this.props.plantList!.find(plant => plant.url === plantUrl);
    return (foundPlant ? foundPlant.name : 'N/A') as string;
  };

  getColumns = () => {
    const { ticketStateValue } = this.state;
    if (ticketStateValue === TicketStateValue.Active) {
      return currentTicketColumns({
        positions: this.props.positions!,
        getSecondsSinceLastUpdate: TicketList.getSecondsSinceLastUpdate,
        renderTimeSinceLastUpdate: TicketList.renderTimeSinceLastUpdate,
        getPlantName: this.getPlantName,
        columnWidths: [],
        orderDetails: this.linkToOrderDetails,
        headerStyle,
        cellStyle,
        isScaleTrax: this.props.isScaleTrax,
      });
    }
    return pastTicketColumns({
      positions: this.props.positions!,
      getSecondsSinceLastUpdate: TicketList.getSecondsSinceLastUpdate,
      renderTimeSinceLastUpdate: TicketList.renderTimeSinceLastUpdate,
      getPlantName: this.getPlantName,
      columnWidths: [],
      orderDetails: this.linkToOrderDetails,
      headerStyle,
      cellStyle,
    });
  };

  setSearchTerm = (searchTerm: string) => {
    this.setState(
      { searchTerm },
      this.saveFilters
    );
  };

  static countUpTimer = (timeDiffFormatted: string, isThresholdTSLUExceeded: boolean) => {
    if (isThresholdTSLUExceeded) {
      return (
        <div style={{ fontWeight: 'bold', color: '#BD0029' }}>
          <i className={cx('icon-warning')} style={{ marginRight: '5px' }} />
          {timeDiffFormatted}
        </div>
      );
    }
    return timeDiffFormatted;
  };

  // Forced rerender to refresh Time Since Last Update
  refreshTimeSinceLastUpdate() {
    this.setState({
      timeSinceLastUpdateCounter: this.state.timeSinceLastUpdateCounter + 1,
    });
  }

  ticketSelectHandler = (selectedTicket?: TicketDto) => {
    this.props.clearSelectedTicket();
    this.props.selectTicket(selectedTicket);
  };

  clearSearchText = () => {
    this.setState(
      { searchTerm: '' },
      this.saveFilters
    );
  };

  ticketPopUp = (rowInfo: RowInfo) => {
    if (rowInfo?.original?.position?.location) {
      const truckToPop = {
        lat: rowInfo.original.position.location.latitude!,
        lng: rowInfo.original.position.location.longitude!,
        markerId: rowInfo?.original?.position?.trackedObject?.id?.toString(),
        truckId: rowInfo?.original?.position?.trackedObject?.truckAlias,
      };

      this.props.selectTruckMarker(
        truckToPop,
        rowInfo?.original?.url,
        rowInfo?.original?.position?.trackedObject?.truckAlias
      );
    }
  };

  rowConfiguration = (state: any, rowInfo: RowInfo) => {
    const { selected } = this.state;

    if (!rowInfo) {
      return {};
    }

    const classNames = [];
    if (rowInfo.viewIndex === selected) {
      classNames.push('selected');
    }

    return {
      onClick: () => {
        this.ticketSelectHandler(rowInfo.original);
        this.ticketPopUp(rowInfo);
        this.setState({
          selected: rowInfo.viewIndex,
        });
      },
      className: cx(classNames),
    };
  };

  renderDateRange = () => {
    const {
      ticketStateValue,
      filterDateStart,
      filterDateEnd,
    } = this.state;

    const { ticketStateSwitcherType } = this.props;

    if (ticketStateValue === TicketStateValue.Active) {
      return null;
    }

    const dateRangePickerClass = (ticketStateSwitcherType === TicketStateSwitcherType.DropDown)
      ? styles.dateRangePickerBlock
      : undefined;

    return (
      <div className={styles.dateRangePicker}>
        {
          ticketStateSwitcherType === TicketStateSwitcherType.DropDown
          && (
            <label htmlFor="dateRangePicker">
              Date Range
            </label>
          )
        }
        <div className={dateRangePickerClass}>
          <DateRangePicker
            startDate={filterDateStart}
            endDate={filterDateEnd}
            shouldDisableFuture
            onChange={this.onDateRangeChange}
            minDate="1920-01-01"
            maxDate={DateWrapper.now.startOfDay.toString()}
            hideLabel={ticketStateSwitcherType === TicketStateSwitcherType.DropDown}
          />
        </div>
      </div>
    );
  };

  onDateRangeChange = (drpState: DateRangePickerState) => {
    const { startDate, endDate } = drpState;

    if (startDate && endDate) {
      this.setState(
        {
          filterDateStart: startDate,
          filterDateEnd: endDate,
        },
        this.saveFilters
      );
    }
  };

  renderSearchTerm = () => {
    const {
      ticketStateSwitcherType,
      searchPlaceholder,
      searchMaxLength,
    } = this.props;
    return (
      <div>
        {
          ticketStateSwitcherType === TicketStateSwitcherType.DropDown
          && (
            <label htmlFor="searchTerm">
              &nbsp;
            </label>
          )
        }
        <TicketSearch
          setTerm={this.setSearchTerm}
          clearSearchText={this.clearSearchText}
          searchTerm={this.state.searchTerm}
          placeholder={searchPlaceholder}
          maxLength={searchMaxLength}
        />
      </div>
    );
  };

  renderRefreshList = () => {
    const { ticketStateSwitcherType } = this.props;
    const refreshClass = ticketStateSwitcherType === TicketStateSwitcherType.DropDown
      ? styles.refreshBlock
      : undefined;

    return (
      <div className={refreshClass}>
        <RefreshCurrentTicketList
          onClick={this.onRefreshList}
        />
      </div>
    );
  };

  onRefreshList = () => {
    const {
      currentRegion, selectedProductLine, currentPlant, isScaleTrax,
    } = this.props;
    if (isScaleTrax) {
      this.props.fetchCurrentTicketList(currentRegion?.url, selectedProductLine, currentPlant?.url);
    } else {
      const plantsFilter = this.getPlantsFilter();
      this.props.fetchCurrentTicketList(currentRegion?.url, selectedProductLine, undefined, plantsFilter);
    }
    this.forceUpdate();
  };

  onChangeTicketState = (ticketStateValue: TicketStateValue) => {
    this.setState(
      { ticketStateValue },
      this.saveFilters
    );
  };

  renderActiveTickets = () => {
    const { searchTerm } = this.state;
    const { ticketsList } = this.props;
    const defaultSorted = [{
      id: 'truckAliasCurrent',
      asc: true,
    }];

    const data = filterBySearchTerm(ticketsList ?? [], searchTerm);
    const columns = data?.length > 0 ? this.getColumns() : [];

    return (
      <ReactTableFixedColumns
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        getTrGroupProps={(state: any, rowInfo: RowInfo) => this.rowConfiguration(state, rowInfo)}
        onResizedChange={() => { }}
        showPagination={false}
        pageSize={data.length}
        minRows={0}
        className="-highlight"
        noDataText="No results found"
      />
    );
  };

  backendRowClick = (rowInfo?: any) => {
    this.ticketSelectHandler(rowInfo.original);
    this.ticketPopUp(rowInfo);
    this.setState({
      selected: rowInfo.viewIndex,
    });
  };

  static dateTimeFormatter = (inputString: string): string => inputString
    .replace(/T/, ' ')
    .replace(/\.000Z/, '');

  renderBackendTickets = () => {
    const {
      selectedProductLine,
      ticketStateSwitcherType,
      plantMapFilters
    } = this.props;
    const {
      searchTerm,
      ticketStateValue,
      filterDateStart,
      filterDateEnd,
    } = this.state;

    const props: any = {
      backendTickets: true,
      baseUrl: this.baseUrl,
      columns: this.getColumns(),
      queryParams: {},
      header: 'Tickets',
      customHeader: (<></>),
      onRowClick: this.backendRowClick,
      source: DATASOURCE.TICKET_LIST
    };

    if (selectedProductLine && ticketStateSwitcherType === TicketStateSwitcherType.Tabs) {
      props.queryParams.plantProductLine = selectedProductLine;
    }

    if (filterDateStart) {
      props.queryParams.startDate = TicketList.dateTimeFormatter(new DateWrapper(filterDateStart).toISOString());
    }
    if (filterDateEnd) {
      props.queryParams.endDate = TicketList.dateTimeFormatter(new DateWrapper(filterDateEnd).toISOString());
    }
    if (searchTerm) { props.queryParams.searchTerm = searchTerm; }

    if (ticketStateValue === TicketStateValue.Closed) {
      props.queryParams.closed = true;
    }
    if (ticketStateValue === TicketStateValue.Voided) {
      props.queryParams.voided = true;
    }
    if (ticketStateValue === TicketStateValue.Open) {
      props.queryParams.closed = false;
      props.queryParams.voided = false;
    }

    if (this.props.isScaleTrax) {
      props.defaultSortedId = 'ticketedTimestamp';
      props.queryParams.plant = this.props.currentPlant?.url;
    } else if (plantMapFilters) {
      props.queryParams.plants = this.getPlantsFilter();
    }

    return (
      <div className={styles.backendTicketsTable}>
        <DataTableContent {...props} />
      </div>
    );
  };

  renderLeftSearchFields = () => {
    const { ticketStateSwitcherType } = this.props;

    if (ticketStateSwitcherType === TicketStateSwitcherType.Tabs) {
      return null;
    }

    return (
      <>
        {this.renderDateRange()}
        {this.renderRefreshList()}
      </>
    );
  };

  renderRightSearchFields = () => {
    const { ticketStateSwitcherType } = this.props;

    if (ticketStateSwitcherType === TicketStateSwitcherType.Tabs) {
      return (
        <>
          {this.renderDateRange()}
          {this.renderSearchTerm()}
          {this.renderRefreshList()}
        </>
      );
    }

    return this.renderSearchTerm();
  };

  render() {
    const { ticketStateValue } = this.state;
    const { ticketStateSwitcherType } = this.props;

    const searchDivClass = ticketStateSwitcherType === TicketStateSwitcherType.DropDown
      ? cx(styles.searchDiv, styles.searchDivSpace)
      : styles.searchDiv;

    return (
      <div className={cx(styles.ticketListContainer)}>
        <div className={cx(searchDivClass)}>
          <HeaderAndContainer
            elementsLeft={(
              <div className={styles.leftSearchContainer}>
                <TicketStateSwitcher
                  onChange={this.onChangeTicketState}
                  switcherType={ticketStateSwitcherType}
                  initialValue={ticketStateValue}
                />
                {this.renderLeftSearchFields()}
              </div>
            )}
            elementsRight={this.renderRightSearchFields()}
          />
        </div>
        <Paper
          id="ticketsPopover-paperElement"
          className={styles.paper}
        >
          {
            (ticketStateValue === TicketStateValue.Active)
            && (this.renderActiveTickets())
          }
          {
            (ticketStateValue !== TicketStateValue.Active)
            && (this.renderBackendTickets())
          }
        </Paper>
      </div>
    );
  }
}

interface TruckToPop {
  lat: number,
  lng: number,
  markerId?: string,
  truckId?: string
}

interface plantListFilter {
  id: number;
  url: string;
  isSelected: boolean;
  name: string;
}

export interface TicketListProps {
  positions?: PositionDto[],
  clearSelectedTicket: VoidFunc,
  selectTicket: (ticket?: TicketDto) => void,
  currentRegion: UrlKeyDto,
  currentPlant?: PlantDto,
  currentUser?: string,
  plantList?: PlantDto[],
  selectTruckMarker: (truckToPop: TruckToPop, ticketUrl?: string, truckAlias?: string) => void,
  selectedProductLine: ProductLine,
  closePopover: VoidFunc,
  selectedStartDate?: Date,
  selectedEndDate?: Date,
  updateSelectedDate?: (dateRange: DateRangePickerState) => void,
  ticketStateSwitcherType: TicketStateSwitcherType,
  ticketsList: TicketDto[],
  fetchCurrentTicketList: (regionUrl?: string, selectedProductLine?: string, plantUrl?: string, plantsFilter?: string[]) => void,
  getDefaultPlant: ConnectedDispatchFunction<typeof getDefaultPlant>;
  setCurrentPlant: ConnectedDispatchFunction<typeof setCurrentPlant>;
  saveFilters: (filters: TicketListFilters) => void,
  ticketListFilters: TicketListFilters | null,
  searchPlaceholder?: string,
  searchMaxLength?: number,
  isScaleTrax?: boolean,
  plantMapFilters?: plantListFilter[]
}

export interface TicketListState {
  timeSinceLastUpdateCounter: number,
  searchTerm: string,
  selected: any,
  timeSinceLastUpdateTimer: NodeJS.Timeout,
  ticketStateValue: TicketStateValue,
  filterDateStart: Date,
  filterDateEnd: Date
}

const mapStateToProps = (state: any) => ({
  currentRegion: state.currentRegion,
  currentPlant: state.currentPlant,
  currentUser: state.userUrl,
  selectedProductLine: state.selectedProductLine,
  positions: state.positionList,
  plantList: state.plantList,
  ticketsList: state.ticketsList,
  ticketListFilters: state.ticketListFilters,
  plantMapFilters: state.mapFilters.plantList,
});

export default connect<any, any, any>(mapStateToProps, {
  selectTicket,
  clearSelectedTicket,
  selectMarker,
  openPopup,
  fetchCurrentTicketList,
  setCurrentTicketsColumns,
  setCurrentPlant,
  getDefaultPlant,
  selectTruckMarker,
  saveFilters,
})(TicketList);
