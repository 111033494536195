import React, { Component } from 'react';
import { Tooltip } from '@trucktrax/trucktrax-common';
import { RegionDto } from '@trucktrax/trucktrax-ts-common';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import styles from '../ChecklistQuestions.module.css';
import sortRegionsAlpha from '../../../../util/regionUtil';

export default class ChecklistRegionsTabs extends Component<ChecklistRegionsTabsProps> {
  static generateTabs = (regionList: RegionDto[], assignedRegionUrls: string[]) => {
    if (!regionList.length) {
      return [];
    }

    // alphabetize region names
    const regionTabs = sortRegionsAlpha(regionList);

    const generatedTabs = regionTabs.map((region) => {
      const isDisabled = assignedRegionUrls
        .filter(url => url === region.url).length === 0;

      if (isDisabled) {
        return (
          <Tab
            key={region.name}
            classes={
              {
                root: styles.btnContainerDisabled,
              }
            }
            className={styles.disabledClickIcon}
            disabled
            label={(
              <Tooltip
                key={region.name}
                text="You do not have access to edit this region"
                placement="top"
                enterDelay={600}
                classes={{
                  popper: styles.lockPopper,
                  tooltip: styles.lockTooltip,
                }}
              >
                <span
                  className={
                    styles.disabledRegionName
                  }
                >
                  {region.name}
                </span>
              </Tooltip>
            )}

          />
        );
      }

      return (
        <Tab
          key={region.name}
          label={region.name}
          classes={
            {
              root: styles.btnContainer,
            }
          }
        />
      );
    });

    return (generatedTabs);
  };

  render() {
    const {
      regionList,
      assignedRegionUrls,
      regionValue,
      handleTabChange,
    } = this.props;

    return (
      <div className={styles.regionsTabContainer}>
        <Tabs
          value={regionValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {ChecklistRegionsTabs.generateTabs(regionList, assignedRegionUrls)}
        </Tabs>
      </div>
    );
  }
}

export interface ChecklistRegionsTabsProps {
  regionList: RegionDto[];
  assignedRegionUrls: string[];
  regionValue: number;
  handleTabChange: (evt: React.ChangeEvent<{}>, index: number) => void;
}
