/* eslint-disable no-nested-ternary */
/* eslint-disable */
import React, { Component, ReactNode } from 'react';
import { parseISO, format } from 'date-fns';
import cx from 'classnames';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { match, withRouter } from 'react-router-dom';
import withFixedColumns, { ColumnFixed } from 'react-table-hoc-fixed-columns';
import { HeaderAndContainer, DateWrapper, Button } from '@trucktrax/trucktrax-common';
import { ProductLine, UrlKeyDto, UserRegionDto } from '@trucktrax/trucktrax-ts-common';
import * as ReactDOM from 'react-dom';
import styles from './DataTableContent.module.css';
import EmptyDataTableContent from './EmptyDataTableContent';
import { clearDateRange, clearSearchTerm } from '../../store/actions/dataTableActions';
import { fetchDataTableData, SortData, fetchDataTableDataForExport } from '../../services/dataTableService';
import GeoTraxPagination from './pagination/Pagination';
import { getLastOfUrlPath, getLastWord } from '../../util/appUtil';
import {
  REGIONS_TEXT, GROUP_PERMISSIONS_TEXT, USER_PERMISSIONS_TEXT
} from '../../constants/navConstants';
import Header from './Header';
import DataTableSearch from './header/DataTableSearch';
import DataTableDateRange from './header/DataTableDateRange';
import { DEFAULT_PAGE_SIZE, NO_RESULTS_IMAGE, ORDERS_HEADER } from '../../constants/appConstants';
import { getAdminLabelByKey } from '../../util/adminUtil';
import { ReduxState } from '../../store';
import { store } from '../../Main';
import { devErrorAndLog } from '../../util/errorUtil';

const ReactTableFixedColumns = withFixedColumns(ReactTable) as any;
export interface DataTableContentColumn {
  id: string;
  accessor: string | ((item: any) => string)
};

export class DataTableContent extends Component<DataTableContentProps> {
  static defaultProps: Partial<DataTableContentProps> = {
    pageInfo: {
      pageCount: 0,
      pageStart: 1,
    },
    resolveFn: (d: any) => d.map((row: any) => row),
    items: [],
    id: '',
    dataSources: {},
    source: undefined,
    currentRegion: { url: '', productLines: [], defaultRegion: false },
    noRegionFilter: false,
    isLoading: true,
    renderTabs: undefined,
    includeDateRangePicker: false,
    dataTableDateRange: undefined,
    isArchived: false,
    selectedProductLine: undefined,
    canAddNewItem: false,
    dataTableNeedsRefresh: false,
    queryParams: {},
    backendTickets: false,
  };

  signal = axios.CancelToken.source();

  sortedData: any;

  lastStateFetched = null;

  tableRef: React.RefObject<any>;

  constructor(props: DataTableContentProps) {
    super(props);
    this.tableRef = React.createRef();
  }

  componentDidUpdate(prevProps: DataTableContentProps) {
    if (prevProps.pageInfo!.pageStart !== this.props.pageInfo!.pageStart && prevProps.isLoading) {
      // eslint-disable-next-line
      const element = ReactDOM.findDOMNode(this.tableRef.current)! as Element;
      element.children[0].scrollTop = 0;
    }
    const {
      selectedProductLine: oldProductLine,
      currentRegion: oldRegion,
      isArchived: oldArchived,
      queryParams: oldDeviceType,
    } = prevProps;
    const {
      selectedProductLine: newProductLine,
      currentRegion: newRegion,
      isArchived: newArchived,
      queryParams: newDeviceType,
    } = this.props;

    const hasOldRegionUrl = oldRegion && oldRegion.url;
    const hasNewRegionUrl = newRegion && newRegion.url;
    const hasValidRegion = hasOldRegionUrl && hasNewRegionUrl;
    if (!hasValidRegion) {
      return;
    }

    const { dataTableNeedsRefresh } = this.props;
    if (dataTableNeedsRefresh && this.lastStateFetched) {
      this.fetchData(this.lastStateFetched);
      return;
    }

    const productLineChanged = oldProductLine !== newProductLine;

    const oldRegionUrl = hasOldRegionUrl ? oldRegion!.url : '';
    const newRegionUrl = hasNewRegionUrl ? newRegion!.url : '';
    const regionChanged = !!oldRegion && (oldRegionUrl !== newRegionUrl);
    const archivedChanged = oldArchived !== newArchived;
    const queryChanged = JSON.stringify(oldDeviceType) !== JSON.stringify(newDeviceType);

    const hasChange = productLineChanged
      || regionChanged
      || archivedChanged
      || queryChanged;
    if (!hasChange) {
      return;
    }

    const currentRegionUrl = this.props.currentRegion && this.props.currentRegion.url
      ? this.props.currentRegion.url
      : '';
    this.props.fetchDataTableData(
      this.props.baseUrl,
      this.props.noRegionFilter ? '' : currentRegionUrl,
      DEFAULT_PAGE_SIZE,
      1, // trucktrax microservices are not zero-based.,
      this.sortedData,
      this.signal.token,
      this.props.isArchived ?? false,
      newDeviceType,
      this.props.byPassRedisCache,
      false,
      this.props.source,
      this.props.columns
    );
  }

  componentWillUnmount() {
    this.signal.cancel('Api is being canceled');
    this.props.clearSearchTerm();
    this.props.clearDateRange();
  }

  onRowClick = (rowInfo?: any) => () => {
    if (!rowInfo) {
      return;
    }

    if (this.props.onRowClick) {
      this.props.onRowClick(rowInfo);
      return;
    }

    const { header, baseUrl } = this.props;
    let generatedUrl = `${this.props.match.url}`;
    if (header
      && (header === GROUP_PERMISSIONS_TEXT || header === USER_PERMISSIONS_TEXT)) {
      const ending = getLastOfUrlPath(baseUrl);
      if (ending === 'groups') {
        generatedUrl += `/${rowInfo.original.id}`;
        this.props.history.push(generatedUrl);
      } else if (ending === 'groupMembersFull') {
        // split last segment out of url
        const newUserUrl = `${generatedUrl.split('/').slice(0, -1).join('/')}/`;

        const hasRegions = rowInfo && rowInfo.original && rowInfo.original.regions;
        const regionUrls = hasRegions
          ? rowInfo.original.regions.map((region: UrlKeyDto) => region.url)
          : null;

        generatedUrl = `${newUserUrl}users/${getLastOfUrlPath(rowInfo.original.member.url)}?regions=${regionUrls}`;

        const userDetail = {
          region: {
            ...rowInfo.original.region,
            regionname: rowInfo.original.regionname,
          },
          member: { ...rowInfo.original.member },
        };
        this.props.history.push(generatedUrl, userDetail);
      }
    } else if (header && header === ORDERS_HEADER) {
      generatedUrl = generatedUrl.replace(/\/all/g, '');
      generatedUrl += `/${rowInfo.original.id}`;
      this.props.history.push(generatedUrl);
    } else if (getLastOfUrlPath(baseUrl) === 'orders'
      && this.props.dataTableDateRange
      && this.props.dataTableDateRange.dateRange) {
      // store previously used daterange value, for when user navigate back to order all page
      const { startDate, endDate } = this.props.dataTableDateRange.dateRange;
      const prevDateRangeFilter = {
        startDate: new DateWrapper(startDate).toUSDateString(),
        endDate: new DateWrapper(endDate).toUSDateString(),
      };
      generatedUrl += `/${rowInfo.original.id}`;
      this.props.history.push(generatedUrl, prevDateRangeFilter);
    } else if (getLastOfUrlPath(generatedUrl) === 'deactivated') {
      if (rowInfo.original.deviceType === 'GLINX') {
        generatedUrl = generatedUrl.replace('/deactivated', `/glinx/${rowInfo.original.id}`);
      } else generatedUrl = generatedUrl.replace(/deactivated$/, rowInfo.original.id);
      this.props.history.push(generatedUrl);
    } else if (getLastOfUrlPath(generatedUrl) === 'inactive') {
      generatedUrl = generatedUrl.replace(/inactive$/, rowInfo.original.id);
      this.props.history.push(generatedUrl);
    } else {
      if (rowInfo.original.deviceType === 'GLINX') {
        generatedUrl += `/glinx/${rowInfo.original.id}`;
      } else {
        generatedUrl += `/${rowInfo.original.id}`;
      }
      this.props.history.push(generatedUrl);
    }
  };

  fetchData = (state: any) => {
    this.lastStateFetched = state;
    this.sortedData = state.sorted;
    this.props.fetchDataTableData(
      this.props.baseUrl,
      this.props.noRegionFilter ? '' : this.props.currentRegion!.url!,
      state.pageSize,
      state.page + 1, // Micro services are not zero-based
      state.sorted,
      this.signal.token,
      this.props.isArchived ?? false,
      this.props.queryParams,
      this.props.byPassRedisCache,
      false,
      this.props.source,
      this.props.columns
    );
  };

  isTargetDataSourceLoading = (state: any, target: string) => {
    const currentSource = state?.dataTableList?.source;
    if (!state || !state.dataTableList || !target || currentSource !== target) {
      return false;
    }
    return state.dataTableList?.isLoading ?? false;
  }

  static getFormattedDate = (isoDateStr: string) => format(parseISO(isoDateStr), 'MM/dd/yyyy HH:mm');

  getTableDataForExport = async () => {
    const currentRegionUrl = this.props.currentRegion?.url
      ? this.props.currentRegion.url
      : '';

    // make a new API call using the same query params, but with a much larger page size
    const fetchedData = await this.props.fetchDataTableDataForExport(
      this.props.baseUrl,
      this.props.noRegionFilter ? '' : currentRegionUrl,
      this.sortedData,
      this.props.isArchived ?? false,
      this.props.queryParams,
      this.props.byPassRedisCache
    );

    const { items } = fetchedData;
    const { columns } = this.props;
    if (items && columns && items.length > 0 && columns.length > 0) {
      return items.map((item: any) => {
        const rowData: Record<string, any> = {};

        columns.forEach((column) => {
          let columnKey = '';
          if (typeof column.accessor === 'string') {
            columnKey = column.accessor;
          } else if (column.id) {
            columnKey = column.id;
          }

          const label = getAdminLabelByKey(columnKey);

          if (typeof column.accessor === 'function') {
            rowData[label] = column.accessor(item);
          } else if (columnKey === 'timestamp') {
            rowData[label] = DataTableContent.getFormattedDate(item[column.accessor]);
          } else {
            rowData[label] = item[column.accessor];
          }

          // if the value is an object, get the name property
          // (this is necessary on columns that represent nested data, like Plant and Default Driver in the Trucks table)
          if (typeof rowData[label] === 'object' && 'name' in rowData[label]) {
            rowData[label] = rowData[label].name;
          }
        });

        return rowData;
      });
    }

    // TODO: handle error case better (e.g. show a toast)
    return [];
  };

  exportToExcel = async () => {
    const fileName = this.props.exportedFileName || 'exported_table_data';
    // replace colons with dashes to make it more file-system-friendly
    const currentDateTime = new Date().toISOString().replace(/:/g, '-');
    const fileNameWithDateTime = `${fileName}_${currentDateTime}.xlsx`;

    // Method taken from: https://medium.com/an-idea/export-excel-files-client-side-5b3cc5153cf7
    const tableData = await this.getTableDataForExport();
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const xlsxMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const data = new Blob([excelBuffer], { type: xlsxMimeType });
    saveAs(data, fileNameWithDateTime);
  };

  render() {
    const {
      pageInfo,
      columns,
      source,
      dataSources,
      currentRegion,
      header,
      includeDateRangePicker,
      noTextSearch,
      resolveFn,
      isArchived,
      queryParams
    } = this.props;
    if (!source) {
      devErrorAndLog('DataTableContent: source is required for rendering', undefined, undefined, undefined, true);
      return;
    }
    const isOrdersPage = (header ?? '').includes('Orders');
    const firstColumn = columns[0];
    const noItemsFoundPage = () => (
      <EmptyDataTableContent
        img={{ src: NO_RESULTS_IMAGE, alt: 'No checklists' }}
        component={(
          <div className={styles.noItemContentCt}>
            <h3
              className={cx(
                'txt-bold',
                'margin-bottom-0'
              )}
            >
              No
              {' '}
              {getLastWord(header)!.toLowerCase()}
              {' '}
              found.
            </h3>
            {!this.props.canAddNewItem && (
              <p>
                Try modifying your search query or adjusting your filters.
              </p>
            )}
            {this.props.canAddNewItem && (
              <p>
                Try modifying your search query, or create a new one by clicking the
                <span>
                  <i aria-hidden="true" className={cx('icon-add-circle', styles.icon)} />
                </span>
                button below.
              </p>
            )}
          </div>
        )}
      />
    );

    // when ReactTable fetches data...
    // If data, display data
    // If NO data, hide columns, pagination
    //   and display no data content UI.
    // geotraxTable960 class forces the entire table to be 960px
    // with auto margins on left and right to account for browser resizing
    // currently only a control that passes an entirely custom header will get this
    const tableHasContent = dataSources[source] ? (dataSources[source]?.items && (dataSources[source].items?.length ?? 0) > 0) : false;

    const tableClass = tableHasContent
      ? (this.props.is960 ? styles.geotraxTable960 : this.props.customHeader ? styles.geotraxTableCustom : styles.geotraxTable)
      : styles.geotraxTableNoData;

    let defaultSortedColumn;
    if (this.props.defaultSortedId !== undefined) {
      defaultSortedColumn = [{ id: this.props.defaultSortedId, desc: true }];
    }
    const isLoading = this.isTargetDataSourceLoading(store.getState(), this.props.source);
    const table = (
      <div className={this.props.backendTickets ? '' : tableClass}>
        {(this.props.renderTabs)}
        <div className={styles.tableContainer}>
          {this.props.enableExport && (
            <div className={styles.exportButtonContainer}>
              <Button
                disabled={!tableHasContent}
                buttonClassName="tt-btn-primary"
                name="Export"
                onClick={this.exportToExcel}
                iconClassName="icon-receipt-download"
                dataTest="export-button-data-test"
              />
            </div>
          )}
          <ReactTableFixedColumns
            PaginationComponent={GeoTraxPagination}
            ref={this.tableRef}
            data={dataSources[source] ? dataSources[source].items ?? [] : []}
            resolveData={resolveFn}
            onFetchData={this.fetchData}
            columns={tableHasContent ? (dataSources[source].columns ?? [] as ColumnFixed[]) : []}
            manual
            defaultSorted={defaultSortedColumn !== undefined ? defaultSortedColumn : firstColumn?.id
              ? [{ id: firstColumn?.id }]
              : [{ id: firstColumn?.accessor }]} // By default, sort table by the first column alphabetically
            autoResetSortBy={false} // Prevent table from losing current sort criteria on re-render
            pages={pageInfo!.pageCount} // Display the total number of pages
            loading={isLoading} // Display the loading overlay when we need it
            defaultPageSize={DEFAULT_PAGE_SIZE}
            width={450}
            minRows={0}
            noDataText="No results found."
            NoDataComponent={noItemsFoundPage}
            getTrProps={(_: unknown, rowInfo: any) => ({
              onClick: this.onRowClick(rowInfo),
            })}
            style={{
              maxHeight: '100%', // This will force the table body to overflow and scroll, since there is not enough room
            }}
            showPagination={tableHasContent}
          />
        </div>
      </div>
    );

    const elementLeft = (
      <Header title={header} />
    );

    const rightElements = [];

    if (includeDateRangePicker) {
      const key = `date-range-${source}`;
      rightElements.push(
        <DataTableDateRange
          key={key}
          noRegionFilter={REGIONS_TEXT === header}
          startDate={this.props.startDate}
          source={source}
          columns={columns}
        />
      );
    }
    if (noTextSearch !== true) {
      rightElements.push(
        <DataTableSearch
          key="search-input"
          header={header}
          noRegionFilter={REGIONS_TEXT === header}
          isArchived={isArchived}
          queryParams={queryParams}
          source={source}
          columns={columns}
        />
      );
    }

    if (!this.props.customHeader) {
      return (
        <HeaderAndContainer
          elementsLeft={elementLeft}
          elementsRight={rightElements}
          containerComponent={currentRegion && table}
          isVertical={isOrdersPage}
          containerStyle={styles.headerAndContainer}
        />
      );
    }
    return (
      <div className={styles.headerAndContainer}>
        {this.props.customHeader}
        {currentRegion && table}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  items: listenToSource(state),
  itemSource: state.dataTableList.source,
  pageInfo: state.dataTableList.pageInfo,
  currentRegion: state.currentRegion,
  isLoading: state.dataTableList.isLoading,
  dataTableDateRange: state.dataTableDateRange,
  selectedProductLine: state.selectedProductLine,
  dataTableNeedsRefresh: state.dataTableNeedsRefresh,
  dataSources: state.combinedDataSources?.dataSources,
});

export const listenToSource = (state: ReduxState) => {
  const sources = state?.combinedDataSources?.dataSources;
  const target = state?.dataTableList?.source;
  if (!state.dataTableList || !sources || !target) {
    return [];
  }

  return sources[target]?.items ?? [];
};


export default withRouter(connect<any, any, any>(mapStateToProps, {
  fetchDataTableData,
  clearSearchTerm,
  clearDateRange,
  fetchDataTableDataForExport,
})(DataTableContent));

export interface DataTableContentProps {
  resolveFn?: (data: any) => any[];
  id?: string;
  items?: any[];
  dataSources: { [key: string]: { columns: any[], items: any[], isLoading: boolean } }
  source: string;
  pageInfo?: {
    pageCount: number;
    pageStart: number;
  };
  fetchDataTableData: (
    url: string,
    region: string,
    size: number,
    page: number,
    sort: SortData[],
    cancelToken: any | null,
    isArchived: boolean,
    queryParams: any,
    byPassRedisCache?: boolean,
    backendTickets?: boolean,
    source?: string,
    columns?: DataTableContentColumn[]
  ) => void;
  fetchDataTableDataForExport: (
    url: string,
    region: string | null | undefined,
    sort: SortData[],
    isArchived?: boolean,
    queryParams?: any,
    byPassRedisCache?: boolean
  ) => Promise<any>;
  baseUrl: string;
  currentRegion?: UserRegionDto;
  columns: DataTableContentColumn[];
  noRegionFilter?: boolean;
  clearSearchTerm: () => void;
  clearDateRange: () => void;
  header: string;
  isLoading?: boolean;
  history: {
    push: (url: string, params?: any) => void;
  };
  match: match;
  renderTabs?: ReactNode,
  includeDateRangePicker?: boolean;
  noTextSearch?: boolean;
  dataTableDateRange: {
    dateRange: {
      startDate: Date;
      endDate: Date;
    };
  };
  isArchived?: boolean;
  selectedProductLine?: ProductLine;
  canAddNewItem?: boolean;
  dataTableNeedsRefresh?: boolean;
  queryParams?: any;
  customHeader?: JSX.Element;
  startDate?: Date;
  onRowClick?: (rowInfo?: any) => void;
  defaultSortedId?: string;
  byPassRedisCache?: boolean;
  backendTickets?: boolean;
  is960?: boolean;
  enableExport?: boolean;
  exportedFileName?: string;
}
