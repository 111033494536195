import React, { Component } from 'react';
import { Card, CardContent } from '@mui/material';
import cx from 'classnames';
import { DiscardProgressCard, Button } from '@trucktrax/trucktrax-common';
import Switch from '@mui/material/Switch';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  ChecklistDto,
  ChecklistQuestionDto,
  PermissionAccess,
  RegionDto,
} from '@trucktrax/trucktrax-ts-common';
import { noop } from '../../../util/appUtil';
import { isChangedValid } from '../../../util/validation';
import styles from './ChecklistQuestions.module.css';
import sortRegionsAlpha from '../../../util/regionUtil';
import {
  fetchChecklistQuestions,
  updateChecklistDetails,
  postOrUpdateChecklistQuestion,
  deleteChecklistQuestion,
} from '../../../services/driversService';
import { openFailModal } from '../../../store/actions/errorModalActions';
import {
  SAVE_CHANGES, DISCARD_PROMPT_TEXT, PERMISSION_NAMES,
} from '../../../constants/appConstants';
import {
  ERROR_TEXT_POST_UPDATE_CHECKLIST_QUESTION,
  ERROR_TEXT_POST_BLANK_CHECKLISTS_QUESTION,
} from '../../../constants/errorConstants';
import { openSnackbar, openSnackbarNotification } from '../../../store/actions/snackbarActions';
import ChecklistRegionsTabs from './ChecklistQuestions/ChecklistRegionsTabs';
import ChecklistQuestionsTable from './ChecklistQuestions/ChecklistQuestionsTable';
import ChecklistAlertEmails from './ChecklistQuestions/ChecklistAlertEmails';
import {
  userHasEditPermission,
} from '../../../services/permissionsService';
import { ReduxState } from '../../../store';
import { ConnectedDispatchFunction, ConnectedFunction } from '../../../types';
import AddFeatureModal from '../../shared/AddFeatureModal';
import EmptyDataTableContent from '../../shared/EmptyDataTableContent';
import trashCanSvg from '../../../assets/img/trash-illus.svg';
import noRegionSvg from '../../../assets/img/noRegion.svg';

export interface EditableQuestion extends ChecklistQuestionDto {
  temp?: string;
}

export interface ChecklistQuestionState {
  regionValue: number;
  editContent: boolean;
  isCloseRequest: boolean;

  questions: EditableQuestion[];
  questionsRemoved: ChecklistQuestionDto[];
  questionsSelected: ChecklistQuestionDto[];
  questionsByRegion: { [regionUrl: string]: EditableQuestion[] };
  questionsByRegionOrig: { [regionUrl: string]: ChecklistQuestionDto[] };

  questionsByIdOrig: { [id: number]: ChecklistQuestionDto };
  questionFocus: number;

  emailList: string[];
  newEmail: string;

  details?: ChecklistDto;
}

export class ChecklistQuestions extends Component<ChecklistQuestionsProps, ChecklistQuestionState> {
  tempId: number;

  constructor(props: ChecklistQuestionsProps) {
    super(props);
    this.tempId = 0;

    const assignedRegionUrls = this.getAssignedRegionUrls();
    const initRegion = ChecklistQuestions.calcInitRegion(props.regionList, assignedRegionUrls);
    let copyDetails: ChecklistDto | undefined;
    let emailList: string[] = [];
    if (props.checklistDetails && props.checklistDetails.regions) {
      copyDetails = ChecklistQuestions.getChecklistDetailsCopy(props.checklistDetails);
      emailList = ChecklistQuestions.calcEmailList(props.regionList[initRegion], copyDetails);
    }

    this.state = {
      regionValue: initRegion < 0 ? 0 : initRegion,
      editContent: false,
      isCloseRequest: false,
      questions: [],
      questionsRemoved: [],
      questionsSelected: [],
      questionsByRegion: {},
      questionsByRegionOrig: {},
      questionsByIdOrig: {},
      questionFocus: -1,
      emailList,
      newEmail: '',
      details: copyDetails,
    };
  }

  static getChecklistDetailsCopy = (details: ChecklistDto) => {
    const copyDetails: ChecklistDto = {
      ...details,
      productLines: details.productLines?.slice(),
      regions: details.regions?.map((r) => ({
        ...r,
        // copy arrays/objects
        emails: r.emails?.slice(),
        region: { url: r.region.url },
      })),
    };
    return copyDetails;
  };

  static copyQuestions = (questions: ChecklistQuestionDto[]) => {
    const copyQuestions = questions.map(q => ({
      ...q,
    }));

    return copyQuestions;
  };

  async componentDidUpdate(prevProps: ChecklistQuestionsProps) {
    const assignedRegionsUrls = this.getAssignedRegionUrls();
    const prevPropsAssignedRegionsUrls = this.getAssignedRegionUrls(prevProps);

    const sameRegions = this.props.regionList.join(',') === prevProps.regionList.join(',');
    const sameAssignedRegions = assignedRegionsUrls.join(',') === prevPropsAssignedRegionsUrls.join(',');
    if (!sameRegions || !sameAssignedRegions) {
      this.loadInitRegion(assignedRegionsUrls);
    }

    const sameDetails = this.props.checklistDetails === prevProps.checklistDetails;
    if (sameDetails) {
      return;
    }

    if (this.props.checklistDetails) {
      const copyDetails = ChecklistQuestions.getChecklistDetailsCopy(this.props.checklistDetails);
      const emailList = ChecklistQuestions.calcEmailList(this.props.regionList[this.state.regionValue], copyDetails);
      this.setState({
        details: copyDetails,
        emailList,
      });
    }
  }

  loadInitRegion = (assignedRegionsUrls: string[]) => {
    const selectedRegionIdx = ChecklistQuestions.calcInitRegion(this.props.regionList, assignedRegionsUrls);
    if (selectedRegionIdx < 0) {
      return;
    }

    this.setState({ regionValue: selectedRegionIdx });

    const regionUrl = this.getRegionUrl(selectedRegionIdx);
    if (!regionUrl) {
      return;
    }

    this.fetchRegionQuestions(regionUrl);
  };

  async componentDidMount() {
    const selectedRegionIdx = ChecklistQuestions.calcInitRegion(this.props.regionList, this.getAssignedRegionUrls());
    if (selectedRegionIdx < 0) {
      return;
    }

    const regionUrl = this.getRegionUrl(selectedRegionIdx);
    await this.fetchRegionQuestions(regionUrl!);
  }

  getAssignedRegionUrls = (props?: ChecklistQuestionsProps): string[] => {
    try {
      const localProps = props || this.props;
      const detailsRegionUrls = localProps.checklistDetails.regions?.map(r => r.region.url);
      const assignedRegionUrls = localProps.assignedRegionList.map(r => r.url);

      return detailsRegionUrls?.filter(detailRegion => assignedRegionUrls
        .some(assignedRegion => assignedRegion === detailRegion))
        .sort((a, b) => a!.localeCompare(b!)) as string[];
    } catch {
      return [];
    }
  };

  getRegionUrl = (index: number) => {
    try {
      const sortedRegions = sortRegionsAlpha(this.props.regionList);
      return sortedRegions[index].url;
    } catch {
      return '';
    }
  };

  parseIdFromPath = () => {
    const pathSplit = this.props.pathName.split('/');
    const pathLength = pathSplit.length;
    const id = pathSplit[pathLength - 1];

    return Number(id);
  };

  static calcInitRegion = (regions: RegionDto[], assignedRegionUrls: string[]) => {
    try {
      const sortedRegions = sortRegionsAlpha(regions);
      const firstRegion = sortedRegions
        .filter(region => assignedRegionUrls.some(assignedRegion => assignedRegion === region.url))[0];
      return sortedRegions.findIndex(region => region.url === firstRegion.url);
    } catch {
      return -1;
    }
  };

  static calcEmailList = (region: RegionDto, details: ChecklistDto) => {
    let emailList: string[] = [];
    if (region && details && details.regions) {
      // Find list of applicable emails
      for (let i = 0; i < details.regions.length; i += 1) {
        const current = details.regions[i];
        if (region.url === current.region.url) {
          if (current.emails) {
            emailList = current.emails;
          }
          break;
        }
      }
    }
    return emailList;
  };

  requestCancelEditQuestions = () => {
    this.setState({ isCloseRequest: !this.state.isCloseRequest });
  };

  editQuestions = () => {
    if (this.state.editContent) {
      const regionUrl = this.getRegionUrl(this.state.regionValue);
      this.resetRegionQuestions(regionUrl!);
      this.props.detailsCallback(this.state.details!);
      this.setState({
        editContent: false,
        isCloseRequest: false,
        questionsSelected: [],
        questionsRemoved: [],
        newEmail: '',
        questionFocus: -1,
      });
      return;
    }

    const copyDetails = ChecklistQuestions.getChecklistDetailsCopy(this.state.details!);

    const sortedRegions = sortRegionsAlpha(this.props.regionList);
    const emails = ChecklistQuestions.calcEmailList(sortedRegions[this.state.regionValue], copyDetails);
    const regionUrl = this.getRegionUrl(this.state.regionValue);
    this.resetRegionQuestions(regionUrl!);

    this.setState({
      editContent: true,
      isCloseRequest: false,
      questionsSelected: [],
      questionsRemoved: [],
      newEmail: '',
      emailList: emails,
      details: copyDetails,
      questionFocus: -1,
    });
  };

  cancelEditQuestions = () => {
    if (!this.state.editContent) {
      return;
    }

    const copyDetails = ChecklistQuestions.getChecklistDetailsCopy(this.props.checklistDetails);
    const sortedRegions = sortRegionsAlpha(this.props.regionList);
    const emails = ChecklistQuestions.calcEmailList(sortedRegions[this.state.regionValue], copyDetails);
    const regionUrl = this.getRegionUrl(this.state.regionValue);
    this.resetRegionQuestions(regionUrl!);
    this.setState({
      editContent: false,
      isCloseRequest: false,
      questionsSelected: [],
      questionsRemoved: [],
      newEmail: '',
      emailList: emails,
      details: copyDetails,
      questionFocus: -1,
    });
  };

  fetchRegionQuestionsFromService = async (
    regionUrl: string,
    questionsByRegion: { [regionUrl: string]: ChecklistQuestionDto[] },
    questionsByRegionOrig: { [regionUrl: string]: ChecklistQuestionDto[] },
    questionsByIdOrig?: { [id: number]: ChecklistQuestionDto }
  ) => {
    // Retrieve questions and add to collection
    const checkListId = this.parseIdFromPath();
    const hasPermissions = this.props.userPermission[regionUrl]?.Checklists?.access !== PermissionAccess.Deny;
    const [data] = hasPermissions ? await Promise.all(
      [
        this.props.fetchChecklistQuestions(checkListId, regionUrl),
      ]
    ) : [{ items: [] }];

    // display them in the order they were entered (first entered last in the list)
    let questions: ChecklistQuestionDto[] = data?.items?.reverse() ?? [];

    if (questionsByRegion[regionUrl] && questionsByRegion[regionUrl].length > 0) {
      // make sure we combine the existing copied questions with the fetched questions here for questionsByRegion list
      questionsByRegion[regionUrl] = ChecklistQuestions.copyQuestions(questions).concat(questionsByRegion[regionUrl]);
      questions = ChecklistQuestions.copyQuestions(questionsByRegion[regionUrl]);
    } else {
      // no existing questions so don't need to combine
      questionsByRegion[regionUrl] = ChecklistQuestions.copyQuestions(questions);
    }

    questionsByRegionOrig[regionUrl] = ChecklistQuestions.copyQuestions(questions);
    if (questionsByIdOrig) {
      questionsByRegionOrig[regionUrl].forEach(question => {
        const copyQuestion: ChecklistQuestionDto = {
          ...question,
        };
        questionsByIdOrig[question.id] = copyQuestion;
      });
      this.setState({
        questionsByRegion,
        questions,
        questionsByRegionOrig,
        questionsByIdOrig,
      });
    } else {
      this.setState({
        questionsByRegion,
        questionsByRegionOrig,
      });
    }
  };

  fetchRegionQuestions = async (regionUrl: string) => {
    const { questionsByRegion, questionsByRegionOrig, questionsByIdOrig } = this.state;
    if (regionUrl in questionsByRegion && regionUrl in questionsByRegionOrig) {
      const questions = questionsByRegion[regionUrl];
      this.setState({ questions });
      return;
    }
    await this.fetchRegionQuestionsFromService(regionUrl, questionsByRegion, questionsByRegionOrig, questionsByIdOrig);
  };

  resetRegionQuestions = async (regionUrl: string) => {
    const questionsByRegion = {};
    const questionsByRegionOrig = {};
    const questionsByIdOrig = {};
    await this.fetchRegionQuestionsFromService(regionUrl, questionsByRegion, questionsByRegionOrig, questionsByIdOrig);
  };

  handleTabChange = async (_: any, newValue: number) => {
    const regionUrl = this.getRegionUrl(newValue);
    await this.fetchRegionQuestions(regionUrl!);

    const newEmail = '';
    const newEmailList = ChecklistQuestions.calcEmailList(this.props.regionList[newValue], this.state.details!);

    this.setState({
      regionValue: newValue,
      emailList: newEmailList,
      questionsSelected: [],
      questionFocus: -1,
      newEmail,
    });
  };

  handleEmailChange = (email: string) => {
    const newEmail = email.replace(',', '');
    this.setState({ newEmail });
  };

  handleAddQuestion = () => {
    const {
      questions,
      regionValue,
      questionsByRegion,
      details,
    } = this.state;
    const regionUrl = this.props.regionList[regionValue].url;
    const temp = this.getTempId();
    questions.push({
      question: '',
      temp: `T${temp}`,
      checklist: { url: details!.url },
      tenant: { url: details!.tenant!.url },
      region: { url: regionUrl },
      archived: false,
      deleted: false,
      id: 0,
    });
    questionsByRegion[regionUrl!] = questions;
    this.setState({ questions, questionsByRegion });
  };

  getTempId = () => {
    this.tempId += 1;
    return this.tempId;
  };

  handleSelectAll = (hasSelectAll: boolean) => {
    if (hasSelectAll) {
      this.setState({ questionsSelected: [] });
    } else {
      this.setState({ questionsSelected: [...this.state.questions] });
    }
  };

  handleSelectCheckbox = (item: ChecklistQuestionDto, isSelectedCheckbox: boolean) => {
    let { questionsSelected } = this.state;

    if (isSelectedCheckbox) {
      questionsSelected = questionsSelected.filter(q => q !== item);
    } else {
      questionsSelected = questionsSelected || [];
      questionsSelected.push(item);
    }

    this.setState({ questionsSelected });
  };

  handleEmailDelete = (email: string) => {
    const { emailList } = this.state;
    // Find and remove existing email
    for (let i = 0; i < emailList.length; i += 1) {
      if (emailList[i] === email) {
        emailList.splice(i, 1);
      }
    }
    this.updateEmails(emailList);
  };

  handleEmailCreated = () => {
    const { emailList, newEmail } = this.state;
    if (newEmail) {
      emailList.push(newEmail);
      this.updateEmails(emailList);
    }
  };

  handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const idx = Number(event.target.id.split('-')[1]);
    const { questions, regionValue, questionsByRegion } = this.state;
    const question = questions[idx];
    question.question = event.target.value;
    const regionUrl = this.props.regionList[regionValue].url;
    questions[idx] = question;
    questionsByRegion[regionUrl!] = questions;
    this.setState({ questions, questionsByRegion });
  };

  handleQuestionFocus = (idx: number) => {
    this.setState({ questionFocus: idx });
  };

  handleRemoveQuestions = (questionsToRemove: EditableQuestion[]) => {
    let { questions, questionsSelected } = this.state;
    const { regionValue, questionsByRegion, questionsRemoved } = this.state;
    const regionUrl = this.getRegionUrl(regionValue);

    // All questions that are not in the questionsToRemove list.
    questions = questions.filter(question => !questionsToRemove.some(toRemove => toRemove === question));
    questionsToRemove.forEach(q => { if (!q.temp) { questionsRemoved.push(q); } });

    questionsByRegion[regionUrl!] = questions;
    questionsSelected = questionsSelected.filter(
      question => !questionsToRemove.some(toRemove => toRemove === question)
    );

    this.setState({
      questions,
      questionsByRegion,
      questionsSelected,
      questionFocus: -1,
    });
  };

  handleActiveChange = () => {
    const { details, regionValue } = this.state;
    const { regionList } = this.props;
    const region = regionList[regionValue];
    for (let i = 0; i < details!.regions!.length; i += 1) {
      const current = details!.regions![i];
      if (region.url === current.region.url) {
        details!.regions![i].active = !current.active;
        this.setState({ details });
        break;
      }
    }
  };

  loadRegionQuestionsOnly = async (
    selectedRegions: RegionDto[],
    questionsByRegion: { [regionUrl: string]: ChecklistQuestionDto[] },
    questionsByRegionOrig: { [regionUrl: string]: ChecklistQuestionDto[] }
  ) => {
    // make sure the questions for each selected region are loaded but don't want to
    // alter the state of the currently selected region in the ui, so omit the questionsByIdOrig
    // parameter so state.questionsByIdOrig and state.questions will not get changed
    const tasks = selectedRegions.filter(r => !questionsByRegion[r.url!])
      .map((r) => this.fetchRegionQuestionsFromService(r.url!, questionsByRegion, questionsByRegionOrig));

    await Promise.all(tasks);
  };

  // copy the selected questions to the selected region(s)
  handleCopyingQuestions = async (selectedRegions: RegionDto[]) => {
    const { questionsSelected, questionsByRegion, questionsByRegionOrig } = this.state;
    const selectedRegionNames: string[] = [];

    await this.loadRegionQuestionsOnly(selectedRegions, questionsByRegion, questionsByRegionOrig);

    // copy questions each to region if that question doesn't exist already
    selectedRegions.forEach(region => {
      let copyCount = 0;
      const qbr = questionsByRegion[region.url!];
      questionsSelected.forEach(q => {
        if (q && q.question) {
          const exists = qbr.find(e => e.question === q.question) !== undefined;
          if (!exists) {
            qbr.push({
              question: q.question,
              temp: `T${this.getTempId()}`,
              checklist: q.checklist,
              tenant: q.tenant,
              region: { url: region.url },
              archived: false,
              deleted: false,
              id: 0,
            });
            copyCount += 1;
          }
        }
      });
      selectedRegionNames.push(`${region.name}: ${copyCount}`);
    });

    this.setState({
      questionsByRegion,
    }, () => this.props.openSnackbarNotification({
      payload: `Item(s) copied: ${selectedRegionNames.join(', ')}`,
      dataTest: 'snackbar-copy-to-region-notification',
    }));
  };

  containsBlankQuestions = (regionKeys: string[], questionsByRegion: { [regionUrl: string]: ChecklistQuestionDto[] }) => {
    const blankQuestions = regionKeys
      .flatMap(r => questionsByRegion[r])
      .filter(q => !q.question || !q.question.trim());

    if (blankQuestions && blankQuestions.length) {
      this.props.openFailModal(
        ERROR_TEXT_POST_BLANK_CHECKLISTS_QUESTION,
        ERROR_TEXT_POST_UPDATE_CHECKLIST_QUESTION
      );
      return true;
    }

    return false;
  };

  onSave = async () => {
    // in case there's an email address in the field that hasn't been added to the array yet
    this.handleEmailCreated();

    const {
      details,
      questionsByRegion,
      questionsRemoved,
      questionsByIdOrig,
    } = this.state;
    let postResults: (ChecklistQuestionDto | undefined | boolean)[];
    let deleteResults: (ChecklistQuestionDto | undefined)[];

    const detailsResults = await this.props.updateChecklistDetails(details!);

    const regionKeys = Object.keys(questionsByRegion);

    if (this.containsBlankQuestions(regionKeys, questionsByRegion)) {
      return;
    }

    const postPromises: Promise<boolean | ChecklistQuestionDto | undefined>[] = regionKeys.flatMap(region => {
      const questions = questionsByRegion[region];
      return questions.map(question => {
        const qId = question.id;

        const isExistingQuestion = qId in questionsByIdOrig;
        const hasChanges = isChangedValid(questionsByIdOrig[qId], question);
        if (isExistingQuestion && !hasChanges) { return Promise.resolve(true); }

        if (question.temp) { delete question.temp; }
        return this.props.postOrUpdateChecklistQuestion(details!.id, question);
      });
    });
    await Promise.all(postPromises).then((r) => { postResults = r; });

    const deletePromises = questionsRemoved.map(
      question => this.props.deleteChecklistQuestion(details!.id, question)
    );
    await Promise.all(deletePromises).then((r) => { deleteResults = r; });

    const failPost = postResults!.includes(undefined);
    const failDelete = deleteResults!.includes(undefined);

    // show appropriate snackbar message
    if ((detailsResults === undefined) || failPost || failDelete) {
      this.props.openSnackbar({
        snackbarBody: 'Error while saving checklist content.',
        dataTest: 'checklist-questions-doc-snackbar',
        snackbarType: 'ERROR',
      });
    } else {
      this.props.openSnackbar({
        snackbarBody: 'Checklist content saved.',
        dataTest: 'checklist-questions-doc-snackbar',
        snackbarType: 'DEFAULT',
      });

      this.editQuestions();
    }
  };

  updateEmails = (emailList: string[]) => {
    const { details, regionValue } = this.state;
    const { regionList } = this.props;
    const region = regionList[regionValue];
    for (let i = 0; i < details!.regions!.length; i += 1) {
      const current = details!.regions![i];
      if (region.url === current.region.url) {
        details!.regions![i].emails = emailList;
        this.setState({ details, emailList, newEmail: '' });
        break;
      }
    }
  };

  regionNameDisplay = () => {
    try {
      return this.props.regionList[this.state.regionValue].name;
    } catch {
      return '';
    }
  };

  renderContentHeader() {
    const { regionValue } = this.state;
    const { regionList, userPermission } = this.props;
    const currentRegion = regionList[regionValue];
    let hasRegionEditPermission;
    if (currentRegion && currentRegion.url) {
      hasRegionEditPermission = userHasEditPermission(userPermission, currentRegion.url, PERMISSION_NAMES.CHECKLISTS);
    }

    const editPermission = this.props.permissions === PermissionAccess.Edit;
    const editBtn = (!this.state.editContent && editPermission && hasRegionEditPermission) ? (
      <button className={cx(styles.checklistContentEditBtn)} onClick={this.editQuestions}>
        <i className={cx('icon-create', 'margin-right-5', styles.checklistContentHeaderIcon)} />
        <span className={cx(styles.checklistContentEditBtnText)}>Edit</span>
      </button>
    ) : null;
    return (
      <div className={cx(styles.checklistContentHeader)}>
        <p className={cx(styles.checklistContentTitle, 'margin-0')}>Checklist Content</p>
        {editBtn}
      </div>
    );
  }

  renderContentBody = () => {
    const { regionList } = this.props;
    const {
      details,
      emailList,
      regionValue,
      editContent,
    } = this.state;
    const region = regionList[regionValue];
    let questionsActive: 'Yes' | 'No' = 'No';

    if (region && region.url && details && details.regions) {
      for (let i = 0; i < details.regions.length; i += 1) {
        const current = details.regions[i];
        if (region.url === current.region.url) {
          if (current.active) {
            questionsActive = 'Yes';
          }
          break;
        }
      }
    }

    const assignedRegionUrls = this.getAssignedRegionUrls();

    const hasPermissions = this.props.userPermission[region?.url ?? '']?.Checklists?.access !== PermissionAccess.Deny;
    return (
      <div className={cx(styles.checklistContentBody)}>
        <ChecklistRegionsTabs
          regionList={regionList}
          assignedRegionUrls={assignedRegionUrls}
          regionValue={regionValue}
          handleTabChange={this.handleTabChange}
        />
        <ChecklistAlertEmails
          editContent={editContent}
          emailList={emailList}
          handleEmailDelete={this.handleEmailDelete}
          handleEmailChange={this.handleEmailChange}
          handleEmailCreated={this.handleEmailCreated}
          newEmail={this.state.newEmail}
        />
        {hasPermissions
          ? this.renderQuestions(questionsActive)
          : ChecklistQuestions.renderMissingPermissions()}
      </div>
    );
  };

  renderQuestionList = () => (
    <ChecklistQuestionsTable
      editContent={this.state.editContent}
      questionsSelected={this.state.questionsSelected}
      questions={this.state.questions}
      checklistDetails={this.state.details!}
      questionFocus={this.state.questionFocus}
      regionValue={this.state.regionValue}
      regionList={this.props.regionList as any}
      assignedRegionList={this.props.assignedRegionList as any}
      handleAddQuestion={this.handleAddQuestion}
      handleRemoveQuestions={this.handleRemoveQuestions}
      handleSelectAll={this.handleSelectAll}
      handleSelectCheckbox={this.handleSelectCheckbox}
      handleQuestionChange={this.handleQuestionChange}
      handleQuestionFocus={this.handleQuestionFocus}
      handleCopyingQuestions={this.handleCopyingQuestions}
    />
  );

  static renderMissingPermissions = () => (
    <EmptyDataTableContent
      img={{ alt: 'Missing Regions Permissions', src: noRegionSvg }}
      component={(
        <div>
          <strong style={{ fontSize: '2rem' }}>Access denied</strong>
          <p>
            Regional permissions are required to view this section.
            <br />
            Please contact an administrator to request access.
          </p>
        </div>
      )}
    />
  );

  renderQuestions = (questionsActive: 'Yes' | 'No') => (
    <div className={styles.questionsContainer}>
      <div className={styles.questionsHeader}>
        <span className={styles.questionsHeaderTxt}>
          {`${this.regionNameDisplay()} Questions`}
        </span>
        {this.renderActive(questionsActive)}
      </div>
      <div className={styles.questionsBody}>
        {this.renderQuestionList()}
      </div>
    </div>
  );

  renderActive = (questionsActive: 'Yes' | 'No') => {
    const activeIcon = (questionsActive === 'Yes')
      ? <span className={cx('icon-visibility', styles.questionsActiveDispIconYes)} />
      : <span className={cx('icon-visibility-off', styles.questionsActiveDispIconNo)} />;
    if (this.state.editContent) {
      return (
        <div className={styles.questionsActiveDispContainerEdit}>
          <span className={cx(styles.questionsActiveDisp, 'margin-right-10')}>
            Active:
          </span>
          <span className={styles.questionsActiveDispLabel}>   No</span>
          <Switch
            value="ActiveSwitch"
            checked={questionsActive === 'Yes'}
            onChange={this.handleActiveChange}
            color="primary"
          />
          <span className={styles.questionsActiveDispLabel}>Yes</span>
        </div>
      );
    }
    return (
      <div
        className={cx(
          styles.questionsActiveDispContainer,
          this.state.questions.length > 5 && styles.questionsOverflowPadding
        )}
      >
        <span className={styles.questionsActiveDisp}>
          Active:
        </span>
        {activeIcon}
        <span className={styles.questionsActiveDispVal}>{questionsActive}</span>
      </div>
    );
  };

  renderSaveButtons() {
    if (this.state.editContent) {
      return (
        <div className={styles.featureFooter}>
          <Button
            buttonClassName="tt-btn-secondary margin-right-10"
            name="Cancel"
            onClick={this.requestCancelEditQuestions}
            dataTest="cancel-button-data-test"
          />
          <Button
            name={SAVE_CHANGES}
            buttonClassName={cx(styles.saveBtn, 'tt-btn--submit')}
            onClick={this.onSave}
            dataTest="save-changes-data-test"
          />
        </div>
      );
    }
    return <div />;
  }

  render() {
    const cardStyle = {
      root: styles.cardContent,
    };
    const { isCloseRequest } = this.state;

    return (
      <div>
        <div className={styles.cardWrapper}>
          <Card className={styles.cardContainer}>
            <CardContent classes={cardStyle}>
              <div>
                {this.renderContentHeader()}
                {this.renderContentBody()}
                {this.renderSaveButtons()}
              </div>
            </CardContent>
          </Card>
        </div>
        <div>
          <AddFeatureModal
            title=""
            isOpen={isCloseRequest}
            onCancel={noop}
          >
            <DiscardProgressCard
              discardAcceptAction={this.cancelEditQuestions}
              discardRejectAction={this.requestCancelEditQuestions}
              imageSrc={trashCanSvg}
              altText="Trash"
            />
          </AddFeatureModal>
          <Prompt
            when={this.state.editContent}
            message={DISCARD_PROMPT_TEXT}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  regionList: state.regionList,
  assignedRegionList: state.assignedRegionList,
  userPermission: state.userPermission,
});

type ChecklistQuestionsOwnProps = {
  detailsCallback: (checklist: ChecklistDto) => void,
  pathName: string,
  permissions: string,
  checklistDetails: ChecklistDto;
};

type ChecklistQuestionsReduxStateProps = ReturnType<typeof mapStateToProps>;

type ChecklistQuestionsReduxDispatchProps = {
  fetchChecklistQuestions: ConnectedDispatchFunction<typeof fetchChecklistQuestions>,
  updateChecklistDetails: ConnectedDispatchFunction<typeof updateChecklistDetails>,
  postOrUpdateChecklistQuestion: ConnectedDispatchFunction<typeof postOrUpdateChecklistQuestion>,
  deleteChecklistQuestion: ConnectedDispatchFunction<typeof deleteChecklistQuestion>,
  openSnackbar: ConnectedFunction<typeof openSnackbar>,
  openSnackbarNotification: ConnectedFunction<typeof openSnackbarNotification>,
  openFailModal: ConnectedFunction<typeof openFailModal>
};
export type ChecklistQuestionsProps = ChecklistQuestionsOwnProps & ChecklistQuestionsReduxStateProps & ChecklistQuestionsReduxDispatchProps;

export default connect(mapStateToProps, {
  fetchChecklistQuestions,
  updateChecklistDetails,
  postOrUpdateChecklistQuestion,
  deleteChecklistQuestion,
  openSnackbar,
  openSnackbarNotification,
  openFailModal,
})(ChecklistQuestions);
