import React, { Component, MouseEvent } from 'react';
import cx from 'classnames';
import debounce from 'lodash/debounce';

import { DebouncedFunc } from 'lodash';
import { RegionDto } from '@trucktrax/trucktrax-ts-common';
import constants from '../../../../../constants/messagingConstants';
import AutoCompleteWithChip, { DataItem } from '../../AutoCompleteWithChip';
import { Driver, MessageCenterInfo } from '../../index.d';
import styles from './NewMessage.module.css';
import ComposeMessage from '../ComposeMessage';

class NewMessage extends Component<NewMessageProps, NewMessageState> {
  messageContainer: Element | null;

  debounceShadowDisplay: DebouncedFunc<any>;

  constructor(props: NewMessageProps) {
    super(props);

    this.messageContainer = null;
    this.debounceShadowDisplay = debounce(this.handleShadowDisplay, 300);

    this.state = {
      dataSource: [],
      isPending: false,
      hideTopShadow: true,
      hideBottomShadow: true,
      items: [],
      selectedItems: [],
      inputValue: '',
      initiallySelected: false,
    };
  }

  // might be an issue when a new truck is active
  // See TODO in Autocomplete.js regarding componentWillMount
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const newDataSource = this.props.driverList.map(driver => {
      const firstName = driver && driver.firstName ? driver.firstName : '';
      const lastName = driver && driver.lastName ? driver.lastName : '';
      return { fullName: `${firstName} ${lastName}`, ...driver };
    });

    // dataSource is the source of truth for drivers list,
    // while items is the dataSource for the AutoCompleteWithChip component
    this.setState({
      dataSource: newDataSource,
    }, this.updateStateItems);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.debounceShadowDisplay);
    this.setState({ initiallySelected: false });
  }

  componentDidUpdate(prevProps: NewMessageProps) {
    const { messageCenter } = this.props;
    if (prevProps.messageCenter.partiesList !== messageCenter.partiesList
      || prevProps.driverList !== this.props.driverList) {
      this.updateStateItems();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceShadowDisplay);
  }

  onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState({ isPending: true });
    this.props.onSubmit(this.props.messageCenter, null, this.disableButtonCallback);
  };

  // method to be called to update the current recipients via changes to the store.
  updateStateItems = () => {
    const { driverList, messageCenter, selectedProductLine } = this.props;
    let stateItems = driverList.map(driver => {
      const firstName = driver && driver.firstName ? driver.firstName.trim() : '';
      const lastName = driver && driver.lastName ? driver.lastName.trim() : '';
      return {
        fullName: `${firstName} ${lastName}`,
        id: driver.id,
        url: driver.url,
      };
    }).sort((a, b) => a.fullName.localeCompare(b.fullName));

    const productLineString = selectedProductLine === constants.READY_MIX_VALUE
      ? constants.READY_MIX_STRING
      : selectedProductLine;
    const allDriversInRegionItem = {
      fullName: selectedProductLine ? `All ${productLineString} Drivers` : '',
      id: 1,
      url: selectedProductLine || 'Product Line',
    };

    if ((messageCenter.partiesList).length > 0) {
      messageCenter.partiesList.forEach((party: { url: any; }) => {
        stateItems = stateItems.filter(driver => driver.url !== party.url);
      });
    }
    // add "add all drivers in product line" to stateItems if not all drivers have been added
    if (stateItems.length > 0) {
      stateItems.unshift(allDriversInRegionItem);
    }
    this.setState({
      items: stateItems,
    });
  };

  clearChips = () => {
    const availableItems = this.state.dataSource.slice();
    this.setState({
      inputValue: '',
      selectedItems: [],
      items: availableItems,
    });

    // clear redux state for partyList
    this.props.onToFieldChange([]);
  };

  handleMessageFieldChange = (event: any) => {
    this.props.onMessageFieldChange(event.target.value);
  };

  disableButtonCallback = () => {
    this.setState({ isPending: false });
  };

  handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const element = (e.target as HTMLInputElement);
    const { className } = element;

    // This needs to match the div with the ref below
    // this.messageContainer
    // otherwise it will render the shadow when scrolling the
    // recipient suggestion list
    if (className.includes('NewMessage_newMessageContainer')) {
      this.renderShadow(element);
    }
  };

  handleShadowDisplay = () => {
    this.renderShadow(this.messageContainer!);
  };

  static handleFocus = (element: AutoCompleteWithChip) => {
    element?.popoverInputNode?.focus();
  };

  handleChangeInput = (inputVal: string) => {
    if (inputVal !== undefined) {
      const isString = !inputVal.includes('[object Object]');
      const t = inputVal.split(',');
      if (isString
        && JSON.stringify(t) !== JSON.stringify(this.state.selectedItems)) {
        this.props.onCurrentRecipientSearchTextChange(inputVal);
        this.setState({ inputValue: inputVal });
      }
    }
  };

  handleChange = (selectedItem: DataItem) => {
    if (this.state.selectedItems.includes(selectedItem)) {
      this.removeSelectedItem(selectedItem);
    } else {
      this.addSelectedItem(selectedItem);
    }
  };

  addSelectedItem = (item: DataItem) => {
    if (item.url === this.props.selectedProductLine) {
      this.addAllItems();
    } else {
      this.addSingleSelectedItem(item);
    }
  };

  addAllItems = () => {
    this.setState(
      ({ items }) => ({
        inputValue: '',
        selectedItems: items,
        items: [],
      }),
      () => {
        this.handleShadowDisplay();

        // add item to redux state
        const chips = this.state.dataSource.map(i => ({
          fullName: i!.fullName,
          url: i!.url,
        }));
        if (chips[0].url === this.props.selectedProductLine) {
          chips.shift();
        }
        this.props.onToFieldChange(chips);
      }
    );
  };

  addSingleSelectedItem = (item: DataItem) => {
    this.setState(
      ({ selectedItems: selectedItem, items }) => ({
        inputValue: '',
        selectedItems: [...selectedItem, item],
        items: items.filter(i => i.url !== item.url),
      }),
      () => {
        this.handleShadowDisplay();

        // add item to redux state
        const itemFromDataS = this.state.dataSource.filter(i => i.url === item.url);
        const firstInArr = itemFromDataS.shift();
        const chipToAdd = {
          fullName: firstInArr!.fullName,
          url: firstInArr!.url,
        };
        const newChips = this.props.messageCenter.partiesList.slice() as DataItem[];

        newChips.push(chipToAdd);
        this.props.onToFieldChange(newChips);
      }
    );
  };

  removeSelectedItem = (item: DataItem) => {
    this.setState(
      ({ selectedItems: selectedItem, items, dataSource }) => {
        const itemFromDataS = dataSource.filter(i => i.url === item.url);
        const firstInArr = itemFromDataS.shift() || null;

        const newItems = [...items];

        if (firstInArr?.fullName && firstInArr?.id && firstInArr?.url) {
          newItems.push(firstInArr);
        }

        return ({
          inputValue: '',
          selectedItems: selectedItem.filter(i => i.url !== item.url),
          items: newItems,
        });
      },
      () => {
        this.handleShadowDisplay();

        // delete item from redux state
        const currentChips = this.props.messageCenter.partiesList;
        const newChips = currentChips.filter((i: { url: any; }) => i.url !== item.url);
        this.props.onToFieldChange(newChips);
      }
    );
  };

  renderShadow = (element: Element) => {
    if (element && element.scrollHeight - element.clientHeight !== 0) {
      if (element.scrollTop === 0) {
        this.setState({
          hideTopShadow: true,
          hideBottomShadow: false,
        });
      } else if (element.scrollTop === element.scrollHeight - element.clientHeight) {
        this.setState({
          hideTopShadow: false,
          hideBottomShadow: true,
        });
      } else {
        this.setState({
          hideTopShadow: false,
          hideBottomShadow: false,
        });
      }
    } else {
      this.setState({
        hideTopShadow: true,
        hideBottomShadow: true,
      });
    }
  };

  render() {
    const { messageFieldValue, partiesList, currentRecipientSearchText } = this.props.messageCenter;
    const chipInput = (
      <div className={styles.chipInputContainer}>
        <label htmlFor="toLabel" className={styles.label}>To:</label>
        <AutoCompleteWithChip
          ref={(element) => {
            if (
              element?.popoverInputNode !== undefined
              && element?.props?.partiesList?.length === 0
              && this.state.initiallySelected === false
            ) {
              this.setState({ initiallySelected: true });
              if (this.props.delayDriverFocus) {
                setTimeout(() => NewMessage.handleFocus(element), 300);
              } else {
                NewMessage.handleFocus(element);
              }
            }
          }}
          data-test="message-center-to-field"
          onInputValueChange={this.handleChangeInput}
          inputValue={this.state.inputValue || currentRecipientSearchText}
          availableItems={this.state.items}
          selectedItem={this.state.selectedItems}
          onChange={this.handleChange}
          onRemoveItem={this.removeSelectedItem}
          partiesList={partiesList}
          selectedProductLine={this.props.selectedProductLine}
        />
        {partiesList.length
          ? (
            <button
              data-test="clear-chips-button"
              className={styles.clearChips}
              onClick={this.clearChips}
            >
              <i
                aria-hidden="true"
                className="icon-cancel"
              />
            </button>
          ) : null}
      </div>
    );
    const submitDisabled = !messageFieldValue
      || messageFieldValue.trim() === ''
      || (partiesList.length === 0)
      || this.state.isPending;
    const textField = (
      <ComposeMessage
        value={messageFieldValue}
        onChange={this.handleMessageFieldChange}
        submitDisabled={submitDisabled}
        onSubmit={this.onSubmit}
        placeholder={constants.NEW_PLACEHOLDER}
        selectedProductLine={this.props.selectedProductLine}
        currentRegion={this.props.currentRegion}
        onMessageFieldChange={this.props.onMessageFieldChange}
      />
    );

    const spanClassName = cx('txt-bold', styles.title, (this.state.hideTopShadow ? styles.hideTopShadow : ''));

    return (
      <div className={styles.container}>
        <span className={spanClassName}>New message</span>
        <div
          data-test="new-message-container"
          className={styles.newMessageContainer}
          onScroll={this.handleScroll}
          ref={(element) => { this.messageContainer = element; }}
        >
          <div className={styles.chipsContainer}>
            <div className={styles.recipientsContainer}>
              {chipInput}
            </div>
          </div>
        </div>
        <div className={cx(styles.textFieldContainer, (this.state.hideBottomShadow ? styles.hideBottomShadow : ''))}>
          {textField}
        </div>
      </div>
    );
  }
}

interface NewMessageState {
  dataSource: DataItem[];
  isPending: boolean;
  hideTopShadow: boolean;
  hideBottomShadow: boolean;
  items: DataItem[];
  selectedItems: DataItem[];
  inputValue: string;
  initiallySelected: boolean;
}

export interface NewMessageProps {
  messageCenter: MessageCenterInfo;
  driverList: Driver[];
  onToFieldChange: (partyMembers: DataItem[]) => void;
  onCurrentRecipientSearchTextChange: (newSearchText: string) => void;
  onMessageFieldChange: (message: string) => void;
  onSubmit: (
    messageCenter: MessageCenterInfo,
    messageId: number | null,
    callback?: () => void
  ) => void;
  selectedProductLine?: string;
  delayDriverFocus?: boolean;
  currentRegion: RegionDto;
}

export default NewMessage;
