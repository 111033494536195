import { DataItem as ChipDataItem } from '../../components/shared/messages/AutoCompleteWithChip';
import { ConversationToMessagesDto } from '../../components/shared/messages/index.d';
import {
  ACKNOWLEDGE_CONVERSATION,
  CLEAR_MESSAGES,
  CURRENT_TAB,
  SOCKET_SUBSCRIPTION,
  UPDATE_MESSAGE_FIELD,
  UPDATE_MESSAGES,
  UPDATE_REPLY_MAP,
  UPDATE_SUBJECT_FIELD,
  UPDATE_TO_FIELD,
  UPDATE_CURRENT_RECIPIENT_SEARCH_TEXT,
} from '../../constants/actionConstants';
import { MessagesTabs } from '../../constants/appConstants';

export const updateMessages = (newMessage: ConversationToMessagesDto, regionUrl: string) => ({
  type: UPDATE_MESSAGES,
  payload: {
    newMessage,
    regionUrl,
  },
});

export const acknowledgeMessage = (ackObject: ConversationToMessagesDto) => ({
  type: ACKNOWLEDGE_CONVERSATION,
  payload: ackObject,
});

export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});

export const updateToFieldValue = (value: ChipDataItem[]) => ({
  type: UPDATE_TO_FIELD,
  payload: value,
});

export const updateCurrentRecipientSearchText = (newSearchText: string) => ({
  type: UPDATE_CURRENT_RECIPIENT_SEARCH_TEXT,
  payload: newSearchText,
});

export const updateMessageFieldValue = (value: string) => ({
  type: UPDATE_MESSAGE_FIELD,
  payload: value,
});

export const updateSubjectFieldValue = (value: string) => ({
  type: UPDATE_SUBJECT_FIELD,
  payload: value,
});

export const messageSocketSubscription = (subcriptionId: string) => ({
  type: SOCKET_SUBSCRIPTION,
  payload: subcriptionId,
});

export const setCurrentTab = (value: MessagesTabs) => ({
  type: CURRENT_TAB,
  payload: value,
});

export const updateReplyMap = (value: Map<string, string>) => ({
  type: UPDATE_REPLY_MAP,
  payload: value,
});
