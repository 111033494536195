import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PermissionAccess } from '@trucktrax/trucktrax-ts-common';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import DataTableContent from '../../shared/DataTableContent';
import styles from './DevicesView.module.css';
import {
  addDataTest, addHeaderDataTest, getRegionUrlToNameMap, getDeviceTypeLabel, getUrl,
} from '../../../util/adminUtil';
import DevicesAddModal from './DevicesAddModal';
import { DEVICES_TEXT } from '../../../constants/navConstants';
import {
  ADMIN_KEYS, ADMIN_LABELS, DEVICE_IDENTIFICATION,
} from '../../../constants/appConstants';
import { DATASOURCE, DEVICES_PATH } from '../../../constants/apiConstants';
import { getGeotraxBaseUrl } from '../../../util/apiUtil';

export class DevicesView extends Component<DevicesViewProps> {
  static defaultProps = {
    regions: [],
    devicesPermissionAccess: '',
    tab: 0,
  };

  state = {
    isOpen: false,
    isCloseRequest: false,
    displayTablet: true,
    displayGLinx: true,
  };

  getColumns = () => {
    const columns = [
      {
        Header: addHeaderDataTest('header', 'sort-device-by-external-id', ADMIN_LABELS.EXTERNAL_ID),
        accessor: ADMIN_KEYS.EXTERNAL_ID,
        minWidth: 200,
        Cell: (props: { value: string; }) => addDataTest('cell', 'admin-device-external-id', props.value),
      },
      {
        Header: addHeaderDataTest('header', 'sort-device-by-type', ADMIN_LABELS.DEVICE_TYPE),
        accessor: ADMIN_KEYS.DEVICE_TYPE,
        minWidth: 100,
        Cell: (props: any) => addDataTest('cell', 'admin-device-type', getDeviceTypeLabel(props.value)),
      },
      {
        Header: addHeaderDataTest(null, 'sort-device-by-name', ADMIN_LABELS.NAME),
        accessor: ADMIN_KEYS.NAME,
        minWidth: 150,
        Cell: (props: any) => addDataTest(props, 'admin-device-name', props.value),
      }, {
        Header: ADMIN_LABELS.REGION,
        id: ADMIN_KEYS.REGION,
        accessor: (row: any) => getRegionUrlToNameMap(this.props.regions)[getUrl(row)!],
        minWidth: 150,
        sortable: false,
      },
    ];

    const phoneCol = {
      Header: addHeaderDataTest(null, 'sort-device-by-phone', ADMIN_LABELS.PHONE),
      accessor: ADMIN_KEYS.PHONE,
      minWidth: 150,
      Cell: (props: any) => addDataTest(props, 'admin-device-phone', props.value),
    };

    if (this.props.tab === 1) {
      const deactivatedDateCol = {
        Header: addHeaderDataTest(null, 'sort-device-by-deactivated-date', ADMIN_LABELS.DEACTIVATED_DATE),
        id: ADMIN_KEYS.ARCHIVED_TIMESTAMP,
        accessor: ADMIN_KEYS.ARCHIVED_TIMESTAMP,
        minWidth: 110,
        Cell: (props: { value: string; }) => addDataTest(
          null,
          'admin-devic-deactivated-date',
          new DateWrapper(props.value).toUSDateString()
        ),
      };
      return [...columns, deactivatedDateCol];
    }

    return [...columns, phoneCol];
  };

  requestCloseModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      isCloseRequest: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      isCloseRequest: false,
    });
  };

  handleTabChange = (_event: any, newValue: number) => {
    if (newValue === 1) {
      this.props.history.push('/admin/devices/deactivated');
    } else if (newValue === 0) {
      this.props.history.push('/admin/devices');
    }
  };

  handleDisplayCheckChange = (event: any) => {
    const { displayTablet, displayGLinx } = this.state;
    const { target } = event;
    const { name, checked } = target;
    const reverseGlinxValue = name === 'displayGLinx' && !checked && !displayTablet;
    const reverseTabletValue = name === 'displayTablet' && !checked && !displayGLinx;

    if (reverseGlinxValue) {
      this.setState({
        displayGLinx: checked,
        displayTablet: true,
      });
    } else if (reverseTabletValue) {
      this.setState({
        displayGLinx: true,
        displayTablet: checked,
      });
    } else {
      this.setState({
        [name]: checked,
      });
    }
  };

  getQueryParams = () => {
    const { displayTablet, displayGLinx } = this.state;

    if (displayTablet && displayGLinx) {
      return {};
    }
    if (!displayTablet && !displayGLinx) {
      return { deviceType: 'none' };
    }
    return { deviceType: `${displayTablet ? DEVICE_IDENTIFICATION.TABLET : DEVICE_IDENTIFICATION.GLINX}` };
  };

  render() {
    const columns = this.getColumns();
    const baseUrl = getGeotraxBaseUrl() + DEVICES_PATH;
    const hasPermissionAccess = this.props.devicesPermissionAccess === PermissionAccess.Edit;

    const tabs = (
      <div className={styles.headerContainer}>
        <Tabs
          value={this.props.tab}
          onChange={this.handleTabChange}
          classes={
            {
              root: styles.tabsContainer,
            }
          }
        >
          <Tab
            label="Active"
            data-test="devices-active-tab"
          />
          <Tab
            label="Deactivated"
            data-test="devices-deactivated-tab"
          />
        </Tabs>
        <div className={styles.checkboxContainer}>
          <div className={styles.checkboxTitle}>
            Device type:
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.displayTablet}
                onChange={this.handleDisplayCheckChange}
                name="displayTablet"
                color="primary"
              />
            )}
            label={<span className={styles.checkboxLabel}>Tablets</span>}
            classes={{
              root: styles.checkboxLabelContainer,
            }}
          />

          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.displayGLinx}
                onChange={this.handleDisplayCheckChange}
                name="displayGLinx"
                color="primary"
              />
            )}
            label={<span className={styles.checkboxLabel}>G-Linxes</span>}
            classes={{
              root: styles.checkboxLabelContainer,
            }}
          />
        </div>
      </div>
    );
    const props: any = {
      baseUrl,
      columns,
      renderTabs: tabs,
      header: DEVICES_TEXT,
      isArchived: this.props.tab === 1,
      canAddNewItem: hasPermissionAccess,
      queryParams: this.getQueryParams(),
      byPassRedisCache: true,
      source: DATASOURCE.DEVICE
    };
    return (
      <div className={styles.container}>
        <DataTableContent {...props} />
        {hasPermissionAccess && (
          <DevicesAddModal
            isOpen={this.state.isOpen}
            openModal={this.openModal}
            closeModal={this.requestCloseModal}
            regions={this.props.regions}
            discardAcceptAction={this.closeModal}
            discardRejectAction={this.openModal}
            isCloseRequest={this.state.isCloseRequest}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  regions: state.regionList,
  devicesPermissionAccess: state.adminPermissionAccess[DEVICES_TEXT],
});

export interface History {
  push: (p: string) => void
}

export interface DevicesViewProps {
  regions: any[],
  devicesPermissionAccess?: string
  tab: number
  history: History
}

export default connect(mapStateToProps)(DevicesView);
