import {
  CLOSE_SNACKBAR,
  CLOSE_SNACKBAR_NOTIFICATION,
  EXTERNAL_ID_TO_LOCATE,
  OPEN_SNACKBAR,
  OPEN_SNACKBAR_NOTIFICATION,
  SET_ACCOUNT_LOCKED,
  SET_MODAL_DISABLED_STATE,
  SET_MODAL_OPTIONS,
  TIME_STAMP,
} from '../../constants/actionConstants';
import { NotificationType, SUCCESS } from '../../constants/appConstants';
import { TOO_MANY_LOGIN_ATTEMPTS } from '../../constants/errorConstants';
import { ModalOptions, PayloadAction } from '../../types';

export const timeStampReducer = (state = '', action: PayloadAction<string, 'TIME_STAMP'>) => {
  switch (action.type) {
    case TIME_STAMP:
      return action.payload;
    default:
      return state;
  }
};

interface SnackbarOptions {
  dataTest?: string;
  snackbarType?: NotificationType | undefined;
  manualClose?: boolean;
  snackbarBodyElement?: JSX.Element;
}

export interface SnackbarState extends SnackbarOptions {
  snackbarBody?: string,
  snackbarOpen: boolean
}

const initialSnackbarState: SnackbarState = {
  snackbarOpen: false,
  snackbarType: SUCCESS as NotificationType,
  manualClose: false,
  dataTest: '',
  snackbarBody: '',
  snackbarBodyElement: undefined,
};

export const snackbarReducer = (
  state = initialSnackbarState,
  action: SnackbarOptions & PayloadAction<(string | undefined), 'OPEN_SNACKBAR' | 'CLOSE_SNACKBAR'>
): SnackbarState => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        snackbarBody: action.payload,
        dataTest: action.dataTest,
        snackbarType: action.snackbarType || SUCCESS,
        manualClose: action.manualClose || false,
        snackbarBodyElement: action.snackbarBodyElement,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
        snackbarBody: '',
        dataTest: '',
      };
    default:
      return state;
  }
};

interface HasDataTest {
  dataTest?: string;
}

export interface SnackbarNotificationState {
  snackbarNotificationOpen: boolean;
  snackbarNotificationBody: {
    firstName?: string;
    lastName?: string;
    truckAlias?: string;
  },
  dataTest?: string
}

const initialSnackbarNotificationState: SnackbarNotificationState = {
  snackbarNotificationOpen: false,
  snackbarNotificationBody: {},
  dataTest: '',
};

export const snackbarNotificationReducer = (
  state = initialSnackbarNotificationState,
  action: HasDataTest & PayloadAction<string, 'OPEN_SNACKBAR_NOTIFICATION' | 'CLOSE_SNACKBAR_NOTIFICATION'>
): SnackbarNotificationState => {
  switch (action.type) {
    case OPEN_SNACKBAR_NOTIFICATION:
      return {
        ...state,
        snackbarNotificationOpen: true,
        snackbarNotificationBody: action.payload as {},
        dataTest: action.dataTest,
      };
    case CLOSE_SNACKBAR_NOTIFICATION:
      return {
        ...state,
        snackbarNotificationOpen: false,
        snackbarNotificationBody: {},
        dataTest: '',
      };
    default:
      return state;
  }
};

export interface ModalState {
  modalTitle?: string,
  modalBody: string | JSX.Element,
  modalOpen: boolean,
  isDeleteConfirmation?: boolean;
  closeDialog?: () => void;
  acceptDialog?: () => void;
  dataTestCancel?: string;
  dataTestOk?: string;
  acceptText?: string;
  modalType: NotificationType;
  cancelText?: string;
  disabled?: boolean;
  noActions?: boolean;
}

const initialModal: ModalState = {
  modalBody: '',
  modalOpen: false,
  modalType: 'DEFAULT',
};

export const modalReducer = (
  state: ModalState = initialModal,
  action: PayloadAction<
  ModalOptions | undefined | boolean,
  'SET_ACCOUNT_LOCKED' | 'SET_MODAL_OPTIONS' | 'SET_MODAL_DISABLED_STATE'>
): ModalState => {
  switch (action.type) {
    case SET_ACCOUNT_LOCKED:
      return {
        ...state,
        modalOpen: true,
        modalTitle: 'Login Fails',
        modalBody: TOO_MANY_LOGIN_ATTEMPTS,
      };
    case SET_MODAL_OPTIONS:
      return { ...state, ...action.payload as any };
    case SET_MODAL_DISABLED_STATE:
      return { ...state, disabled: action.payload as boolean };
    default:
      return state;
  }
};

export const searchReducer = (
  state = { locateExternalId: undefined },
  action: PayloadAction<number, 'EXTERNAL_ID_TO_LOCATE'>
) => {
  switch (action.type) {
    case EXTERNAL_ID_TO_LOCATE:
      return {
        ...state,
        locateExternalId: action.payload,
      };
    default:
      return state;
  }
};
