import { DeviceDto } from '@trucktrax/trucktrax-ts-common';
import { DEVICES_PATH } from '../constants/apiConstants';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import deviceConstants from '../constants/deviceConstants';
import { getRequest } from './requestService';

export interface GLinxDeviceResponse {
  data?: {
    items?: DeviceDto[]
  }
}

export async function getGlinxDevices(truckUrl: string, region: string) {
  const url = `${getGeotraxBaseUrl() + DEVICES_PATH}`;
  const params = {
    region,
    isDeleted: false,
    isArchived: false,
    deviceType: deviceConstants.DEVICE_TYPES.GLINX,
    truck: truckUrl,
  };
  const response = await getRequest(url, params);
  return response;
}

// check if tablet is logged in
export async function isLoggedIn(dto: any) {
  const url = `${getGeotraxBaseUrl()}/activefleet/is-tablet-logged-in`;
  const params = {
    sourceUrl: dto.url,
    isActive: true,
    isArchived: false,
    isDeleted: false,
  };

  try {
    const response = await getRequest(url, params);
    return response.data;
  } catch (e) {
    return false;
  }
}
