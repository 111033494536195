import React from 'react';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import C from '../../../constants/messagingConstants';
import { ConversationMessagesDto, Message, PartyMember } from './index.d';
import ThumbsUpSvg from './MessageCenter/RightSidebar/ThumbsUpSvg';

export interface LatestMessageDto {
  latestMessage?: Message | '';
  from: {
    firstName: string;
    lastName: string;
    url: string;
  }
}

export interface TrucksToDriverMap {
  url: string;
  truckDto: {
    truckAlias: string
  }
}

export interface RecipientNameDto extends Message {
  latestMessage?: Message | '';
  partiesList: PartyMember[];
}

// return 'from' field of the message
export function getAuthor(message?: Message, partiesList?: PartyMember[] | [], direction?: 'to' | 'from') {
  // TODO: modify to use region url after gtx-1542
  // List of party members EXCLUDING dispatch
  const partiesWithoutMe = partiesList && partiesList
    .filter((party: PartyMember) => !party.url.includes('/regions'));

  // First check, If there is a list of parties, We only want last names
  if (partiesWithoutMe && partiesWithoutMe.length > 1) {
    const commaSeparateParties = partiesWithoutMe
      .filter((p: PartyMember) => p.lastName)
      .map((p: PartyMember) => p.lastName)
      .join(', ');
    return commaSeparateParties;
    // Second check, if the list is Exactly 1, then we want LastName, FirstName
  } if (partiesWithoutMe && partiesWithoutMe.length === 1) {
    const commaSeparateParties = partiesWithoutMe
      .filter((p: PartyMember) => `${p.lastName}, ${p.firstName}`)
      .map((p: PartyMember) => `${p.lastName}, ${p.firstName}`)
      .join(', ');
    return commaSeparateParties;
    // Last Check, if the message 'direction' exists (To or From)
  } if (message && direction && message[direction]
    && message[direction].firstName && message[direction].lastName) {
    const author = `${message[direction].lastName}, ${message[direction].firstName}`;
    return author;
  }
  return '';
}

export function getRecipientNames(message: ConversationMessagesDto) {
  if (message && message.latestMessage && message.partiesList) {
    return getAuthor(message.latestMessage, message.partiesList, 'from');
  } if (message && !message.latestMessage) {
    return getAuthor(message.message, message.partiesList, 'from');
  }
  return '';
}

export function getAuthorWithDriverId(message?: LatestMessageDto) {
  const latestMessage = message && message.latestMessage;
  if (latestMessage) {
    return `${latestMessage.from.lastName}, ${latestMessage.from.firstName}`;
  } if (message && message.from) {
    return message.from.firstName ? `${message.from.lastName}, ${message.from.firstName}`
      : `${message.from.lastName}`;
  }
  return '';
}

export function formatTime(time: Date) {
  if (time && DateWrapper.isValidDate(time)) {
    const today = DateWrapper.now.startOfDay;
    const yesterday = today.previousDay;
    const now = new DateWrapper(time);
    const timeString = now.toString('h:mma');

    if (now.isAfterOrEqual(today)) {
      // today show time
      return timeString;
    } if (now.isBetween(yesterday, today) || now.isEqual(yesterday)) {
      // yesterday show string
      return `Yesterday, ${timeString}`;
    } if (now.isBefore(yesterday)) {
      // before yesterday show string
      const dateString = now.toString('M/d/yy');
      return `${dateString}, ${timeString}`;
    }
  }
  // unknown format, show nothing
  return '';
}

export function checkAckTime(sentDateString?: string, acknowledgedDateString?: string) {
  const sent = new DateWrapper(sentDateString);
  const acknowledged = new DateWrapper(acknowledgedDateString);
  const defaultAckTimeInTheFuture = new DateWrapper('9999-12-31T00:00:00.000Z');
  if (sentDateString && acknowledgedDateString) {
    return acknowledged.isEqual(defaultAckTimeInTheFuture) || sent.isAfter(acknowledged);
  }
  return true;
}

export function getTruckId(trucksToDriversMap: TrucksToDriverMap, message: Message) {
  const map = new Map(Object.entries(trucksToDriversMap));
  let truckId = '';
  if (trucksToDriversMap && message) {
    map.forEach((value: TrucksToDriverMap) => {
      if (message.from && value.url === message.from.url) {
        truckId = value.truckDto.truckAlias;
        return false;
      }
      return true;
    });
  }
  return truckId;
}

export function checkBodyForIconString(body: string, dataTest?: string | null) {
  return body === C.THUMB_UP_ICON_STRING ? <ThumbsUpSvg props={dataTest} /> : body;
}
