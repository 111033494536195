import {
  OVERRIDE_MAP_FILTER,
  OPEN_MAP_FILTERS,
  RESET_MAP_FILTERS,
  TOGGLE_MAP_FILTER,
} from '../../constants/actionConstants';
import { mapFilterInitialState } from '../reducers/mapFilterReducers';

type MapFilters = Partial<typeof mapFilterInitialState>;
// filterObject should have the shape { [filter]: boolean }
export const toggleMapFilter = (filterObject: MapFilters) => ({
  type: TOGGLE_MAP_FILTER,
  payload: filterObject,
});

export const openMapFilters = () => ({
  type: OPEN_MAP_FILTERS,
});

export const resetMapFilters = () => ({
  type: RESET_MAP_FILTERS,
});

export const overrideMapFilter = (filterObject: MapFilters) => ({
  type: OVERRIDE_MAP_FILTER,
  payload: filterObject,
});
