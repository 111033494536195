import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import {
  ProductLine, TruckDto, DriverDto, PlantDto, TicketDto, OrderDto, GeoZoneDto, UserRegionDto, UrlKeyDto,
} from '@trucktrax/trucktrax-ts-common';
import {
  DropDownList, Label, DateWrapper,
} from '@trucktrax/trucktrax-common';
import cx from 'classnames';
import {
  Box, Checkbox, CircularProgress, FormControlLabel, Switch
} from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ReactGoogleAutocomplete, { ReactGoogleAutocompleteInputProps } from 'react-google-autocomplete';
import { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import {
  createTheme, ThemeProvider, StyledEngineProvider
} from '@mui/material/styles';
import {
  ADMIN_LABELS, ERROR, PRODUCTLINES_INVALID_MESSAGE, TIME_INVALID_MESSAGE, TIME_RANGE_OVER_MESSAGE
} from '../../../constants/appConstants';
import { ReduxState } from '../../../store';
import { getFullName, productLineOptions } from '../../../util/adminUtil';
import styles from './RouteHistoryPanelSearchForm.module.css';
import {
  setRouteHistoryProductLines,
  setSelectedProductLine,
} from '../../../store/actions/productLineActions';
import CardRadio from '../../shared/forms/CardRadio';
import CardNumericInput from '../../shared/forms/CardNumericInput';
import { ScaleTraxAutocomplete } from '../../shared/forms/ScaleTraxAutocomplete';
import { fetchTruckList } from '../../../services/trucksService';
import { fetchDriverList } from '../../../services/driversService';
import { fetchPlantList, fetchGeozoneList, setPlantList } from '../../../services/plantsService';
import { fetchTicketList } from '../../../services/ticketsService';
import { fetchOrdersList } from '../../../services/ordersService';
import { fetchRouteHistoryList, selectRouteHistoryList } from '../../../services/routeHistoryService';
import { activatePinMap, updatePinRadius } from '../../../services/mapviewsService';
import {
  ConnectedDispatchFunction,
  ConnectedFunction,
} from '../../../types';
import { DEFAULT_RADIUS, METERS_PER_MILE } from '../../../constants/mapConstants';
import { setCurrentGeozone } from '../../../store/actions/geolocationActions';
import DateTimeRange from '../../../util/DateTimeRange';
import { noop } from '../../../util/appUtil';
import { openSnackbar, closeSnackbar } from '../../../store/actions/snackbarActions';

export class RouteHistoryPanelSearchForm extends Component<RouteHistoryPanelSearchFormProps, RouteHistoryPanelSearchFormState> {
  // eslint-disable-next-line class-methods-use-this
  get today() { return DateWrapper.now.startOfDay; }

  get productLineSelectionIsValid() { return this.state.productLinesErrorMessage.length === 0; }

  constructor(props: RouteHistoryPanelSearchFormProps) {
    super(props);
    this.state = {
      currentDriver: undefined,
      currentOrder: undefined,
      currentPlant: undefined,
      currentTicket: undefined,
      currentTruck: undefined,
      endState: new Date().getTime(),
      driverList: [],
      filteredDriverList: [],
      geozonesList: [],
      orderList: [],
      productLines: [this.props.primaryProductLine],
      productLinesErrorMessage: '',
      searchByValue: 'address',
      searchInProgress: false,
      searchRegion: this.props.currentRegion,
      searchText: '',
      startState: this.today.startOfDay.date.getTime(),
      showSearchRequirements: false,
      ticketList: [],
      timeErrorMessage: '',
      errorMessage: '',
      truckList: [],
      filteredTruckList: [],
      location: '',
      latitude: undefined,
      longitude: undefined,
      showDeactivatedTrucks: false,
      showDeactivatedDrivers: false,
      dateChanged: false
    };

    // Sets the default values for the pin action
    this.props.updatePinRadius(DEFAULT_RADIUS.toString());
    this.props.activatePinMap({
      pinMode: false, pinDropped: false, location: '',
    });
  }

  geozoneRef = React.createRef<ScaleTraxAutocomplete<GeoZoneDto>>();

  truckRef = React.createRef<ScaleTraxAutocomplete<TruckDto>>();

  driverRef = React.createRef<ScaleTraxAutocomplete<DriverDto>>();

  plantRef = React.createRef<ScaleTraxAutocomplete<PlantDto>>();

  ticketRef = React.createRef<ScaleTraxAutocomplete<TicketDto>>();

  orderRef = React.createRef<ScaleTraxAutocomplete<OrderDto>>();

  componentDidUpdate(prevProps: RouteHistoryPanelSearchFormProps): void {
    if (prevProps.activePinMap.pinMode === true && this.props.activePinMap.pinMode === false) {
      // If we are inside this conditional, that means a pin was just dropped
      // So let's manually set the searchText state so the location matches the dropped pin's location

      // at this point activePinMap.location should always have a value, but we still need this if check to make the TS compiler happy
      if (this.props.activePinMap.location) {
        this.setState({
          searchText: this.props.activePinMap.location,
        });
      }
    }

    if (!this.state.showSearchRequirements) {
      return;
    }

    // detect if any of the fields which are required for search are present
    if (
      this.state.currentTruck
      || this.state.currentDriver
      || this.state.currentPlant
      || this.state.currentTicket
      || this.state.currentOrder
      || this.props.activePinMap.location
      || (this.props.currentGeozone && this.props.currentGeozone.id !== 0)
    ) {
      this.setState({
        showSearchRequirements: false,
      });
      this.props.closeSnackbar();
    }
  }

  refreshParameters() {
    const {
      searchRegion, startState, endState,
    } = this.state;
    const start = new Date(startState);
    const end = new Date(endState);

    return {
      url: searchRegion ? searchRegion.url! : this.props.currentRegion!.url,
      timespan: new DateTimeRange(start, end),
      products: this.state.productLines.join(','),
    };
  }

  async refreshAllLists() {
    await Promise.all([
      this.refreshDriverList(),
      this.refreshGeozoneList(),
      this.refreshOrderList(),
      this.refreshPlantList(),
      this.refreshTicketList(),
      this.refreshTruckList(),
    ]);
  }

  renderErrorMessage = () => (
    <div className={styles.errorMessage}>
      {this.state.errorMessage}
    </div>
  );

  async refreshDateTimeDependentLists() {
    await Promise.all([
      this.refreshOrderList(),
      this.refreshTicketList(),
    ]);
  }

  refreshPlantList() {
    const { url, products } = this.refreshParameters();
    this.props.fetchPlantList(url, undefined, undefined, products);
  }

  async refreshTruckList() {
    const { url, products } = this.refreshParameters();
    const { showDeactivatedTrucks } = this.state;
    const includeDeactivated = true;
    const truckList = await fetchTruckList(undefined, url, undefined, products, includeDeactivated);
    const filteredTruckList = this.getFilteredTruckList(truckList, showDeactivatedTrucks);
    this.setState({ truckList, filteredTruckList });
  }

  // eslint-disable-next-line class-methods-use-this
  getFilteredTruckList(truckList: TruckDto[], showDeactivatedTrucks: boolean) {
    if (showDeactivatedTrucks) {
      return truckList;
    }
    return truckList?.filter(truck => truck.archived === false);
  }

  static getFilteredDriverList = (driverList: DriverDto[], showDeactivatedDrivers: boolean) => {
    if (showDeactivatedDrivers) {
      return driverList;
    }
    return driverList?.filter(driver => driver.archived === false);
  };

  async refreshDriverList() {
    const { url } = this.refreshParameters();
    const { showDeactivatedDrivers } = this.state;
    const includeDeactivated = true;
    const driverList = await fetchDriverList(url, this.state.productLines, undefined, includeDeactivated);
    const filteredDriverList = RouteHistoryPanelSearchForm.getFilteredDriverList(driverList, showDeactivatedDrivers);
    this.setState({ driverList, filteredDriverList });
  }

  async refreshGeozoneList() {
    const { url, products } = this.refreshParameters();

    const geozonesList = await fetchGeozoneList(url, false, false, products);
    this.setState({ geozonesList });
  }

  async refreshOrderList() {
    const {
      url, timespan, products,
    } = this.refreshParameters();
    const orderList = await fetchOrdersList(url!, timespan, products, ProductLine.None);
    this.setState({ orderList });
  }

  async refreshTicketList() {
    const {
      url, timespan, products,
    } = this.refreshParameters();
    const ticketList = await fetchTicketList(url, products, timespan);
    this.setState({ ticketList });
  }

  handleTruckChanged(value: TruckDto) { this.setState({ currentTruck: value }); }

  static updateInput = (
    selectAction: Function,
    clearAction: Function,
    reason?: AutocompleteChangeReason
  ) => {
    switch (reason) {
      case 'selectOption': {
        selectAction();
        break;
      }
      case 'clear': {
        clearAction();
        break;
      }
      default:
        break;
    }
  };

  updateInputTruck = (event: ChangeEvent<{}> | undefined, value?: TruckDto, reason?: AutocompleteChangeReason) => {
    const selectAction = () => this.handleTruckChanged(value!);
    const clearAction = () => this.setState({ currentTruck: undefined });
    RouteHistoryPanelSearchForm.updateInput(selectAction, clearAction, reason);
  };

  updateInputDriver = (event: ChangeEvent<{}> | undefined, value?: DriverDto, reason?: AutocompleteChangeReason) => {
    const selectAction = () => this.handleDriverChanged(value!);
    const clearAction = () => {
      this.refreshDriverList();
      this.handleDriverChanged(undefined);
    };
    RouteHistoryPanelSearchForm.updateInput(selectAction, clearAction, reason);
  };

  updateInputPlant = (event: ChangeEvent<{}> | undefined, value?: PlantDto, reason?: AutocompleteChangeReason) => {
    const selectAction = () => this.handlePlantChanged(value!);
    const clearAction = () => {
      this.props.fetchPlantList(this.state.searchRegion!.url, undefined, undefined, this.state.productLines.join(','));
      this.setState({ currentPlant: undefined });
    };
    RouteHistoryPanelSearchForm.updateInput(selectAction, clearAction, reason);
  };

  updateInputTicket = (event: ChangeEvent<{}> | undefined, value?: TicketDto, reason?: AutocompleteChangeReason) => {
    const selectAction = () => this.handleTicketChanged(value!);
    const clearAction = () => {
      this.refreshTicketList();
      this.handleTicketChanged(undefined);
    };
    RouteHistoryPanelSearchForm.updateInput(selectAction, clearAction, reason);
  };

  updateInputOrder = (event: ChangeEvent<{}> | undefined, value?: OrderDto, reason?: AutocompleteChangeReason) => {
    const selectAction = () => this.handleOrderChanged(value!);
    const clearAction = () => {
      this.refreshOrderList();
      this.handleOrderChanged(undefined);
    };
    RouteHistoryPanelSearchForm.updateInput(selectAction, clearAction, reason);
  };

  updateInputGeoZone = (event: ChangeEvent<{}> | undefined, value?: GeoZoneDto, reason?: AutocompleteChangeReason) => {
    const selectAction = () => this.handleGeoZoneChanged(value!);
    const clearAction = () => {
      this.refreshGeozoneList();
      this.handleGeoZoneChanged({
        id: 0,
        deleted: false,
        archived: false,
      });
    };
    RouteHistoryPanelSearchForm.updateInput(selectAction, clearAction, reason);
  };

  handleDriverChanged(value: DriverDto | undefined) { this.setState({ currentDriver: value }); }

  handlePlantChanged(value: PlantDto) { this.setState({ currentPlant: value }); this.props.setPlantList([value]); }

  handleTicketChanged(value: TicketDto | undefined) { this.setState({ currentTicket: value }); }

  handleGeoZoneChanged(value: GeoZoneDto) { this.props.setCurrentGeozone(value); }

  handleOrderChanged(value: OrderDto | undefined) { this.setState({ currentOrder: value }); }

  validateSelectedProductLines = () => {
    const { productLines } = this.state;
    const noProductLinesSelected = (productLines.length === 0);
    if (noProductLinesSelected) {
      this.setState({
        productLinesErrorMessage: PRODUCTLINES_INVALID_MESSAGE,
      });
      return false;
    }
    this.setState({
      productLinesErrorMessage: '',
    });
    return true;
  };

  async componentDidMount() {
    await this.refreshAllLists();
  }

  onRegionChangeHandler = (region: any) => {
    const searchRegion = {
      url: region.value,
    };
    this.setState({ searchRegion }, this.refreshAllLists);
  };

  handlePlaceSelected = (chosenPlace: any) => {
    this.props.activatePinMap({
      pinMode: false,
      pinDropped: false,
      location: chosenPlace.formatted_address,
      lat: chosenPlace.geometry.location.lat(),
      lng: chosenPlace.geometry.location.lng(),
    });
    this.setState({
      searchText: chosenPlace.formatted_address,
      location: chosenPlace.formatted_address,
      latitude: chosenPlace.geometry.location.lat(),
      longitude: chosenPlace.geometry.location.lng()
    });
  };

  checkSearchRequirements = () => {
    const {
      currentDriver, currentOrder, currentPlant, currentTicket, currentTruck, searchByValue
    } = this.state;
    const { currentGeozone, activePinMap } = this.props;
    const { location, lat, lng } = activePinMap;

    // even when no geozone is selected, currentGeozone variable points to an empty object with an id of 0
    const isAddressOrGeozonePresent = searchByValue === 'address'
      ? !!(location || (lat && lng))
      : !!(currentGeozone && currentGeozone.id !== 0);

    this.validateDateTime();

    // eslint-disable-next-line max-len
    const areSearchRequirementsMet = !!(currentDriver || currentOrder || currentPlant || currentTicket || currentTruck || isAddressOrGeozonePresent);

    return areSearchRequirementsMet;
  };

  onSearchButtonClickHandler = async () => {
    const areSearchRequirementsMet = this.checkSearchRequirements();

    if (!areSearchRequirementsMet) {
      this.setState({
        showSearchRequirements: true,
      });
      this.props.openSnackbar({
        snackbarBody: 'Please select at least one more filter to perform a search.',
        snackbarType: ERROR,
        manualClose: true,
      });
      return;
    }

    if (this.validateDateTime() && this.validateSelectedProductLines()) {
      this.executeSearch();
      // can't have anything selected if there's nothing to select
      if (!this.props.routeHistoryList || this.props.routeHistoryList.length === 0) { this.props.setRouteHistoryProductLines([]); }
    }
  };

  executeSearch = async () => {
    try {
      this.searchInProgress(true);

      const {
        currentTruck, currentDriver, currentPlant, currentTicket, currentOrder,
        searchRegion, productLines, showDeactivatedTrucks, showDeactivatedDrivers
      } = this.state;

      const { timespan } = this.refreshParameters();

      const regionUrl = searchRegion ? searchRegion.url! : this.props.currentRegion!.url!;

      const { currentGeozone } = this.props;
      const { lat, lng } = this.props.activePinMap;
      const { pinRadiusMiles } = this.props;
      const radius = pinRadiusMiles ? Number(pinRadiusMiles) * METERS_PER_MILE : undefined;

      await this.props.fetchRouteHistoryList(
        {
          regionUrl,
          timespan,
          productLines,
          singleTicketUrl: currentTicket?.url,
          orderUrl: currentOrder?.url,
          truckUrl: currentTruck?.url,
          driverUrl: currentDriver?.url,
          plantUrl: currentPlant?.url,
          hideDeactivatedTrucks: !showDeactivatedTrucks,
          hideDeactivatedDrivers: !showDeactivatedDrivers,
          zone: currentGeozone?.zone,
          radius,
          latitude: lat,
          longitude: lng
        }
      );
    } finally {
      this.props.selectRouteHistoryList(this.props.routeHistoryList?.map(r => r.id));
      this.searchInProgress(false);
    }
  };

  searchInProgress = (isInProgress: boolean) => { this.setState({ searchInProgress: isInProgress }); };

  onProductLineChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, selectedProductLine: ProductLine) => {
    const { productLines } = this.state;
    if (event.target.checked) {
      productLines.push(selectedProductLine);
    } else {
      productLines.splice(productLines.indexOf(selectedProductLine, 0), 1);
    }
    this.setState({ productLines }, this.refreshAllLists);
    this.validateSelectedProductLines();
  };

  setNewStartDateTime = (newDate?: Date | null) => {
    let startDateTime;
    this.setState({ errorMessage: '' });
    if (newDate) {
      startDateTime = newDate.getTime();
      this.setState({
        startState: startDateTime,
        dateChanged: true
      });
    }
    return startDateTime;
  };

  setNewEndDateTime = (newDate?: Date | null) => {
    let endDateTime;
    this.setState({ errorMessage: '' });
    if (newDate) {
      endDateTime = newDate.getTime();
      this.setState({
        endState: endDateTime,
        dateChanged: true
      });
    }
    return endDateTime;
  };

  checkForDateTimeRefresh = () => {
    const { startState, endState, dateChanged } = this.state;
    if (startState && endState && (endState - startState < 86400000) && (endState >= startState)) {
      this.refreshDateTimeDependentLists();
    }
    if (dateChanged) {
      this.setState({ currentTicket: undefined, currentOrder: undefined, dateChanged: false });
    }
  };

  validateDateTime = (error?: string | null) => {
    const startTimeInMS = this.state.startState;
    const endTimeInMS = this.state.endState;
    this.setState({
      errorMessage: '',
    });
    if (endTimeInMS && startTimeInMS) {
      if ((endTimeInMS - startTimeInMS) > 86400000) {
        this.setState({
          errorMessage: TIME_RANGE_OVER_MESSAGE,
        });
        return false;
      }
      if ((endTimeInMS < startTimeInMS)) {
        this.setState({
          errorMessage: TIME_INVALID_MESSAGE,
        });
        return false;
      }
      return true;
    }
    return false;
  };

  isDisabled(item: any): boolean {
    return !this.getAssignedProductLines().map(pl => pl.value).includes(item.value);
  }

  isChecked(item: any) {
    return this.state.productLines.includes(item.value);
  }

  getCheckboxClass(item: any) {
    // commented to check later

    if (this.isDisabled(item)) {
      return '';
    }
    if (!this.productLineSelectionIsValid) {
      return styles.checkboxInvalid;
    }
    if (this.isChecked(item)) {
      return styles.checkboxChecked;
    }
    return styles.checkboxUnchecked;
  }

  getAssignedProductLines() {
    const {
      primaryProductLine,
      secondaryProductLines,
    } = this.props;
    return productLineOptions
      .filter(pl => pl.value === primaryProductLine || secondaryProductLines.includes(pl.value));
  }

  renderProductLines = () => (
    <div className={cx(styles.searchContainer, styles.productContainer)}>
      <Label className={styles.productLabel}>
        {ADMIN_LABELS.PRODUCT_LINE}
      </Label>
      {productLineOptions.map((pl, index) => {
        const key = `checkbox${pl.label}${index}`;
        return (
          <FormControlLabel
            control={(
              <Checkbox
                name={key}
                key={key}
                className={this.getCheckboxClass(pl)}
                checked={this.isChecked(pl)}
                onChange={(e) => this.onProductLineChangeHandler(e, pl.value)}
                disabled={this.isDisabled(pl)}
              />
            )}
            label={pl.label}
            key={key}
            className={styles.checkboxLabel}
          />
        );
      })}
      <span id="productLinesError" className={styles.errorMessageSpan}>{this.state.productLinesErrorMessage}</span>
    </div>
  );

  /*
      The autocomplete needs a function to get the option label, it provides whatever option it was given.
      These functions simple grab the appropriate value for the given autocomplete.
  */
  static getTruckOption = (option: TruckDto) => `${option?.truckAlias}`;// || '';

  static getDriverOption = (option: DriverDto) => {
    if (option?.firstName && !option?.lastName) {
      return option.firstName;
    }

    if (!option?.firstName && option?.lastName) {
      return option.lastName;
    }

    if (option?.firstName && option?.lastName) {
      return `${option.lastName}, ${option.firstName}`;
    }

    return '';
  };

  static getPlantOption = (option: PlantDto) => `${option?.name}`;

  static getTicketNumberOption = (option: TicketDto) => `${option?.ticketNumber}`;

  static getOrderNumberOption = (option: OrderDto) => `${option?.orderNumber}`;

  static getGeozoneOption = (option: GeoZoneDto) => `${option?.name}`;

  renderSearchButton = () => {
    const { searchInProgress } = this.state;
    return (
      <div className={styles.searchButton}>
        <button
          onClick={this.onSearchButtonClickHandler}
          className={cx('tt-btn', 'margin-top-30', 'margin-bottom-10')}
          data-test="route-history-search"
          disabled={searchInProgress}
        >
          {/* have to set the box width so that the button doesn't collapse when content changes */}
          <Box width="76px">
            {searchInProgress
              && (
                <CircularProgress
                  className={styles.searchLoadingIcon}
                  color="inherit"
                  size="2.0rem"
                  thickness={8.0}
                />
              )}
            {!searchInProgress && 'Search'}
          </Box>
        </button>
      </div>
    );
  };

  renderEndDateTime = () => {
    const endDateTime = new Date();
    const theme = createTheme({
      typography: {
        fontSize: 20,
      },
    });
    return (
      <div className={styles.dateSearchContainer}>
        <div className={styles.dateSearch}>
          <Label className={styles.label}>
            <span className={styles.dateLabel}>End Date </span>
            <span className={styles.required}>*</span>
          </Label>
        </div>
        <div className={styles.dateSearch}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div>
                  <MobileDateTimePicker
                    className={this.state.errorMessage ? styles.dateTimeError : styles.dateTimeCalendar}
                    disableFuture
                    format="MM/dd/yyyy, HH:mm"
                    defaultValue={endDateTime}
                    slotProps={{
                      actionBar: {
                        actions: ['cancel', 'accept'],
                      },
                      textField: {
                        error: !!this.state.errorMessage,
                      },
                    }}
                    ampm={false}
                    onChange={(newState) => this.setNewEndDateTime(newState)}
                    onClose={this.checkForDateTimeRefresh}
                    onError={(errorState) => this.validateDateTime(errorState)}
                    sx={{ border: 'none', '& fieldset': { border: 'none' } }}
                  />
                </div>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </div>
        <span id="timeError" className={styles.errorMessageSpan}>{this.state.timeErrorMessage}</span>
      </div>
    );
  };

  /**
  * Activates the pin mode
  */
  dropPin = () => {
    this.props.activatePinMap({ pinMode: true });
  };

  /**
  * Handles the container click when the user enters the pin mode
  */
  containerClick = () => {
    // If pin mode isn't active returns
    if (!this.props.activePinMap.pinMode) return;
    // Disables pin mode
    if (this.state.location) {
      this.props.activatePinMap({
        pinMode: false,
        pinDropped: false,
        location: this.state.location,
        lat: this.state.latitude,
        lng: this.state.longitude,
      });
      return;
    }
    this.props.activatePinMap({ pinMode: false, pinDropped: false });
  };

  /**
  * Removes the pin mode
  */
  removePin = () => {
    // Resets the pin value to the default
    this.props.activatePinMap({
      pinMode: false, pinDropped: false, removePin: true, location: '',
    });
    this.props.updatePinRadius(DEFAULT_RADIUS.toString());
    this.setState({
      searchText: '',
    });
  };

  regionsDropdownListItems = () => {
    const { regions } = this.props;
    return regions ? regions.map((region) => ({
      label: region.display,
      text: region.display,
      value: region.value,
    })) : [];
  };

  onSearchByChanged = (item: any) => {
    this.setState({ searchByValue: item[''] });
  };

  onSearchTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;

    this.setState({
      searchText: text,
    });

    if (text === '') {
      // if autocomplete text has been manually completely cleared out, clear the location state as well
      this.setState({
        location: '',
        latitude: undefined,
        longitude: undefined,
      });

      this.props.activatePinMap({
        pinMode: false,
        pinDropped: false,
        location: '',
        lat: undefined,
        lng: undefined,
      });
    }
  };

  /**
   * This will validate that the input value for the pin is valid
   * and then update the pin
   * @param {string} milesRadius miles radius value from the input field
   */
  onPinValue = (evt: { [x: string]: string; }) => {
    if (Number.isNaN(Number(evt.milesRadius))) return;
    this.props.updatePinRadius(evt.milesRadius);
  };

  onTruckDeactivatedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const { truckList, currentTruck } = this.state;
    const filteredTruckList = this.getFilteredTruckList(truckList, checked);
    this.setState({
      showDeactivatedTrucks: checked,
      filteredTruckList
    });
    if (currentTruck?.archived && !checked) {
      this.setState({
        currentTruck: undefined
      });
    }
  };

  onDriverDeactivatedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const { driverList, currentDriver } = this.state;
    const filteredDriverList = RouteHistoryPanelSearchForm.getFilteredDriverList(driverList, checked);
    this.setState({
      showDeactivatedDrivers: checked,
      filteredDriverList,
    });
    if (currentDriver?.archived && !checked) {
      this.setState({
        currentDriver: undefined
      });
    }
  };

  static renderTruckAutocompleteOption = (props: any, option: TruckDto) => (
    <div {...props}>
      <span>{`${option.truckAlias}`}</span>
      <span className={styles.deactivatedText}>{`${option.archived ? 'Deactivated' : ''}`}</span>
    </div>
  );

  static renderDriverAutocompleteOption = (props: any, driver: DriverDto) => (
    <div {...props}>
      <span>{`${getFullName(driver)}`}</span>
      <span className={styles.deactivatedText}>{`${driver.archived ? 'Deactivated' : ''}`}</span>
    </div>
  );

  render() {
    const { currentGeozone, regions } = this.props;
    const {
      currentTruck, currentDriver, currentPlant, currentTicket, currentOrder, searchRegion,
    } = this.state;
    const disabled = regions?.length === 1;

    // Checks if the container class is in Pin Mode
    const containerClass = this.props.activePinMap.pinMode === true
      ? cx(styles.contentContainer, styles.dropPinMode) : styles.contentContainer;

    // Drop Pin button action to create a new pin
    const dropPin = {
      text: 'Drop Pin',
      click: this.dropPin,
    };

    // Remove Pin button action to remove the current pin
    const removePin = {
      text: 'Remove Pin',
      click: this.removePin,
    };

    // Pin button
    const dropPinButton = this.props.activePinMap.pinDropped
      ? removePin : dropPin;

    // Hide the description when the list has items; show it otherwise
    const descriptionClass = this.props.routeHistoryList && this.props.routeHistoryList.length > 0
      ? styles.descriptionHide
      : styles.description;

    const regionsDropdownListItems = this.regionsDropdownListItems();
    const selectedRegion = this.regionsDropdownListItems().find(r => r.value === searchRegion?.url);
    const radioButtons = [{
      label: 'Address',
      value: 'address',
    },
    {
      label: 'Geozone',
      value: 'geozone',
    }];
    const startDateTime = this.today.startOfDay.date;

    const theme = createTheme({
      typography: {
        fontSize: 20,
      },
    });

    return (
      <div className={containerClass} onClick={this.containerClick} aria-hidden="true">
        <h3 className={styles.title}>Route History</h3>

        <div className={cx(styles.searchContainer)}>
          <div>
            <Label className={styles.label}>
              Region
            </Label>
          </div>
          <DropDownList
            items={regionsDropdownListItems}
            initialSelected={selectedRegion}
            updateSelected={this.onRegionChangeHandler}
            disabled={disabled}
          />
        </div>

        {this.renderProductLines()}

        <div className={cx(styles.searchContainer, styles.time)}>
          <div className={styles.dateSearchContainer}>
            <div className={styles.dateSearch}>
              <div>
                <Label className={styles.label}>
                  <span className={styles.dateLabel}>Start Date </span>
                  <span className={styles.required}>*</span>
                </Label>
              </div>
              <div className={styles.dateSearch}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <div>
                        <MobileDateTimePicker
                          className={this.state.errorMessage ? styles.dateTimeError : styles.dateTimeCalendar}
                          format="MM/dd/yyyy, HH:mm"
                          disableFuture
                          defaultValue={startDateTime}
                          slotProps={{
                            actionBar: {
                              actions: ['cancel', 'accept'],
                            },
                            textField: {
                              error: !!this.state.errorMessage,
                            },
                          }}
                          ampm={false}
                          onError={(errorState) => this.validateDateTime(errorState)}
                          onChange={(newDateTimeState) => this.setNewStartDateTime(newDateTimeState)}
                          onClose={this.checkForDateTimeRefresh}
                          sx={{ border: 'none', '& fieldset': { border: 'none' } }}
                        />
                      </div>
                    </LocalizationProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </div>
            </div>
          </div>
          {this.renderEndDateTime()}
        </div>
        <div className={this.state.errorMessage ? styles.errorMessage : styles.defaultMessage}>
          {this.state.errorMessage ? this.renderErrorMessage()
            : <div className={styles.innerDefaultMessage}>
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8
                  16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8
                  0V0ZM8.8 12H7.2V7.2H8.8V12V12ZM8.8 5.6H
                  7.2V4H8.8V5.6V5.6Z"
                  fill="#878E93"
                />
              </svg>
              <div className={styles.message}>
                Search criteria must be within a 24-hour time range.
              </div>
              {/* eslint-disable-next-line react/jsx-closing-tag-location, react/jsx-no-comment-textnodes */}
            </div>}
        </div>
        <div className={styles.searchContainer}>
          <CardRadio
            onChange={this.onSearchByChanged}
            label="Search by"
            className={styles.cardRadio}
            initialSelected={radioButtons[0]}
            radioButtons={radioButtons}
            value={this.state.searchByValue}
            row
          />
        </div>

        {this.state.searchByValue === 'address'
          ? (
            <div className={cx(styles.searchContainer, styles.searchAutocomplete, styles.address)}>
              <div className={styles.addressInput}>
                <Label className={styles.label}>
                  Address
                  <button type="button" className={styles.dropPin} onClick={dropPinButton.click}>{dropPinButton.text}</button>
                </Label>
                <div>
                  <ModifiedReactGoogleAutocomplete
                    onChange={this.onSearchTextChange}
                    value={this.state.searchText}
                    onPlaceSelected={this.handlePlaceSelected}
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    className={cx(styles.autocompletePlace, this.state.showSearchRequirements && styles.hasError)}
                    options={{ types: ['geocode'] }}
                  />
                </div>
              </div>
              <div className={styles.radius}>
                <CardNumericInput
                  id="milesRadius"
                  label="Radius"
                  className={styles.radiusContent}
                  hideCharacterCount
                  value={this.props.pinRadiusMiles}
                  onTouch={noop}
                  onChange={this.onPinValue}
                  allowDecimal
                />
                <div>
                  <Label className={cx(styles.label, styles.miles)}>
                    miles
                  </Label>
                </div>
              </div>
            </div>
          )
          : (
            <div id="divGeoZone" className={styles.searchAutocomplete}>
              <Label className={styles.label}>
                Geozone
              </Label>
              <div>
                <ScaleTraxAutocomplete
                  hasError={this.state.showSearchRequirements}
                  ref={this.geozoneRef}
                  options={this.state.geozonesList}
                  getOptionLabel={RouteHistoryPanelSearchForm.getGeozoneOption}
                  onChange={this.updateInputGeoZone}
                  value={currentGeozone.id === 0 ? undefined : currentGeozone}
                />
              </div>
            </div>
          )}
        <div className={styles.searchAutocomplete}>
          <Label className={styles.label}>
            Truck
            <Switch
              id="allowDeactivated"
              color="primary"
              checked={this.state.showDeactivatedTrucks}
              onChange={this.onTruckDeactivatedChange}
            />
            <span>
              Include Deactivated Trucks
            </span>
          </Label>
          <div>
            <ScaleTraxAutocomplete
              hasError={this.state.showSearchRequirements}
              ref={this.truckRef}
              options={this.state.filteredTruckList}
              getOptionLabel={RouteHistoryPanelSearchForm.getTruckOption}
              onChange={this.updateInputTruck}
              value={currentTruck}
              renderOption={RouteHistoryPanelSearchForm.renderTruckAutocompleteOption}
            />
          </div>
        </div>

        <div className={styles.searchAutocomplete}>
          <Label className={styles.label}>
            Driver
            <Switch
              id="allowDeactivatedDrivers"
              color="primary"
              checked={this.state.showDeactivatedDrivers}
              onChange={this.onDriverDeactivatedChange}
            />
            <span>
              Include Deactivated Drivers
            </span>
          </Label>
          <ScaleTraxAutocomplete
            hasError={this.state.showSearchRequirements}
            ref={this.driverRef}
            options={this.state.filteredDriverList}
            getOptionLabel={RouteHistoryPanelSearchForm.getDriverOption}
            onChange={this.updateInputDriver}
            value={currentDriver}
            renderOption={RouteHistoryPanelSearchForm.renderDriverAutocompleteOption}
          />
        </div>

        <div className={styles.searchAutocomplete}>
          <Label className={styles.label}>
            Plant
          </Label>
          <ScaleTraxAutocomplete
            hasError={this.state.showSearchRequirements}
            ref={this.plantRef}
            options={this.props.plantList}
            getOptionLabel={RouteHistoryPanelSearchForm.getPlantOption}
            onChange={this.updateInputPlant}
            value={currentPlant}
          />
        </div>

        <div className={styles.searchAutocomplete}>
          <Label className={styles.label}>
            Ticket Number
          </Label>
          <ScaleTraxAutocomplete
            hasError={this.state.showSearchRequirements}
            ref={this.ticketRef}
            options={this.state.ticketList}
            getOptionLabel={RouteHistoryPanelSearchForm.getTicketNumberOption}
            // getOptionLabel={(t) => {
            //   const cons = console;
            //   cons.log(t);
            //   return 't';
            // }}
            onChange={this.updateInputTicket}
            value={currentTicket}
          />
        </div>
        <div className={styles.searchAutocomplete}>
          <Label className={styles.label}>
            Order Number
          </Label>
          <ScaleTraxAutocomplete
            hasError={this.state.showSearchRequirements}
            ref={this.orderRef}
            options={this.state.orderList}
            getOptionLabel={RouteHistoryPanelSearchForm.getOrderNumberOption}
            onChange={this.updateInputOrder}
            value={currentOrder}
          />
        </div>
        {this.renderSearchButton()}
        <div className={descriptionClass}>
          Use the filters above
          <br />
          to find routes
        </div>
      </div>
    );
  }
}

export interface ModifiedReactGoogleAutocompleteProps extends ReactGoogleAutocompleteInputProps {
  value?: string;
}

// It's hardly modified, it just allows one additonal prop ("value"), thus allowing it to be used as a fully controlled component
// "value" prop already works with the original ReactGoogleAutocomplete, but TS compiler isn't aware of the support
export const ModifiedReactGoogleAutocomplete: React.FC<ModifiedReactGoogleAutocompleteProps> = (props) => (
  <ReactGoogleAutocomplete {...props} />
);

export interface KeyboardDatePickerProps {
  dataTest?: string;
  value: any;
  label: string;
  onBlurCallback: (event: any) => void;
  onChangeCallback: (event: any) => void;
  disabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  shouldDisableFuture?: boolean;
  minDate?: string;
  maxDate?: string;
  style?: string;
  keyboardIcon?: React.ReactNode;
}

export interface RouteHistoryPanelSearchFormState {
  currentDriver?: DriverDto,
  currentOrder?: OrderDto,
  currentPlant?: PlantDto,
  currentTicket?: TicketDto,
  currentTruck?: TruckDto,
  driverList: DriverDto[],
  filteredDriverList: DriverDto[],
  geozonesList: GeoZoneDto[],
  hour?: number;
  lat?: number,
  lng?: number,
  minute?: number;
  orderList: OrderDto[]
  productLines: any[];
  productLinesErrorMessage: string;
  radius?: number,
  searchByValue: string;
  searchInProgress: boolean;
  searchRegion: UrlKeyDto;
  searchText: string;
  showSearchRequirements: boolean;
  ticketList: TicketDto[],
  timeErrorMessage: string,
  startState: number,
  endState: number,
  errorMessage?: string,
  truckList: TruckDto[],
  filteredTruckList: TruckDto[],
  location: string | undefined,
  latitude: number | undefined,
  longitude: number | undefined,
  showDeactivatedTrucks: boolean,
  showDeactivatedDrivers: boolean,
  dateChanged: boolean
}

export interface RouteHistoryPanelContentOwnProps {
  regions?: {
    display?: string;
    value?: string;
  }[];
  currentRegion?: UserRegionDto;
}

export function mapStateToProps(state: ReduxState) {
  return {
    activePinMap: state.activePinMap,
    currentGeozone: state.currentGeozone,
    pinRadiusMiles: state.pinRadiusMiles,
    plantList: state.plantList,
    primaryProductLine: state.primaryProductLine,
    routeHistoryList: state.routeHistoryList,
    secondaryProductLines: state.secondaryProductLines,
    selectedProductLine: state.selectedProductLine,
    selectedRouteHistoryList: state.selectedRouteHistoryList,
    trucksList: state.trucksList?.trucks,
    currentRegion: state.currentRegion,
  };
}

type RouteHistoryPanelContentDispatchProps = {
  activatePinMap: ConnectedDispatchFunction<typeof activatePinMap>;
  fetchPlantList: ConnectedDispatchFunction<typeof fetchPlantList>;
  fetchRouteHistoryList: ConnectedDispatchFunction<typeof fetchRouteHistoryList>;
  openSnackbar: ConnectedFunction<typeof openSnackbar>;
  closeSnackbar: ConnectedFunction<typeof closeSnackbar>;
  setRouteHistoryProductLines: ConnectedDispatchFunction<typeof setRouteHistoryProductLines>;
  selectRouteHistoryList: ConnectedDispatchFunction<typeof selectRouteHistoryList>;
  setCurrentGeozone: ConnectedDispatchFunction<typeof setCurrentGeozone>;
  updatePinRadius: ConnectedDispatchFunction<typeof updatePinRadius>;
  setPlantList: ConnectedDispatchFunction<typeof setPlantList>;
};

export default connect(mapStateToProps, {
  activatePinMap,
  fetchPlantList,
  fetchRouteHistoryList,
  openSnackbar,
  closeSnackbar,
  selectRouteHistoryList,
  setCurrentGeozone,
  setSelectedProductLine,
  updatePinRadius,
  setRouteHistoryProductLines,
  setPlantList,
})(RouteHistoryPanelSearchForm);

type RouteHistoryPanelContentReduxProps = ReturnType<typeof mapStateToProps>;

export type RouteHistoryPanelSearchFormProps =
  RouteHistoryPanelContentOwnProps
  & RouteHistoryPanelContentReduxProps
  & RouteHistoryPanelContentDispatchProps;
