import React, { Component } from 'react';
import { Circle, Polygon } from '@react-google-maps/api';
import { PlantDto, VoidFunc } from '@trucktrax/trucktrax-ts-common';
import {
  Button, postGISToGoogleMapsPoints, vars,
} from '@trucktrax/trucktrax-common';
import cx from 'classnames';
import messagingConstants from '../../../../constants/messagingConstants';
import icons from '../icons';
import TTMarker from '../TTMarker';
import styles from '../TTMap.module.css';
import MarkerLabel from './MarkerLabel';

export default class PlantMarkerWithInfoPane extends Component<PlantMarkerWithInfoPaneProps> {
  static defaultProps = {
    showGeofence: false,
    markerColor: vars.gray700,
    mapRef: undefined,
  };

  renderGeoZones = () => {
    const {
      plant, showGeofence, markerColor,
    } = this.props;
    if (showGeofence && plant.geozones && plant.geozones.length > 0) {
      const shapeStyles = {
        fillColor: markerColor,
        fillOpacity: 0.25,
        strokeColor: markerColor,
        strokeWeight: 2,
        clickable: false,
        zIndex: 1,
      };

      const geozoneMarkers = [];

      for (let i = 0; i < plant.geozones.length; i += 1) {
        const geozone = plant.geozones[i].zone;
        const geozoneUrl = plant.geozones[i].url;
        if (geozone?.circle) {
          const position = {
            lat: geozone?.circle?.center?.latitude ?? 0,
            lng: geozone?.circle?.center?.longitude ?? 0,
          };

          geozoneMarkers.push((
            <Circle
              center={position}
              radius={geozone.circle.radius}
              options={shapeStyles}
              key={geozoneUrl}
            />
          ));
        } else if (geozone?.polygon) {
          geozoneMarkers.push((
            <Polygon
              path={postGISToGoogleMapsPoints(geozone!.polygon!.points!) as google.maps.LatLngLiteral[]}
              options={shapeStyles}
              key={geozoneUrl}
            />
          ));
        }
      }
      return geozoneMarkers;
    }
    return null;
  };

  markerRef: any;

  generatePlantInfoContent = () => {
    const sendMessageButton = (
      <div className={styles.buttonHover}>
        <Button
          buttonClassName={cx('tt-btn-basic', styles.plantSendMessageBtn)}
          onClick={() => {
            this.props.updateToFieldValueWithPlantDrivers(this.props.plant.url || '');
          }}
          iconClassName="icon-messages"
          dataTest="plant-infopane-new-message-button"
          name={messagingConstants.MESSAGE_ALL_PLANTS_DRIVERS}
        />
      </div>
    );

    const buttons = (
      <div className={styles.plantPaneBtns}>
        {sendMessageButton}
      </div>
    );

    return (
      <div className={styles.plantPaneBtnContainer}>
        <div>{buttons}</div>
      </div>
    );
  };

  render() {
    const {
      plant, markerColor, mapRef, isSelected, ...rest
    } = this.props;
    if (mapRef && this.markerRef) {
      this.markerRef.setMap(mapRef);
    }
    try {
      const position = {
        lat: plant.location!.latitude ?? 0,
        lng: plant.location!.longitude ?? 0,
      };

      const plantIconAnchorX = 11;
      const plantIconAnchorY = 17;

      const icon = {
        path: icons.housePath,
        fillColor: markerColor,
        fillOpacity: 1,
        strokeWeight: 0,
        anchor: new (window as any).google.maps.Point(plantIconAnchorX, plantIconAnchorY),
      };

      const shadowIcon = {
        ...icon,
        fillColor: 'black',
        fillOpacity: 0.5,
        scale: 1.075,
        strokeWeight: 2,
        strokeColor: 'black',
        strokeOpacity: 0.15,
        anchor: new (window as any).google.maps.Point(plantIconAnchorX + 0.5, plantIconAnchorY - 1),
      };

      const infoPaneTitle = (
        <div data-test="plant-title" title={plant.name}>
          <span className={cx(styles.titleText, 'nowrap-ellipsis')}>
            {plant.name}
          </span>
        </div>
      );
      const label = (plant.name && (
        <MarkerLabel
          color={markerColor}
          name={plant.name}
        />
        // eslint-disable-next-line no-undef
      )) as JSX.Element | undefined;
      // pixelOffset improves centering of infoPane around marker
      const pixelOffset: google.maps.Size = {
        width: 1.5,
        height: 7,
        equals: (other) => other?.width === 1.5 && other.height === 7,
      };
      return (
        <div key={plant.url}>
          <TTMarker
            position={position}
            icon={icon}
            infoPaneTitle={infoPaneTitle}
            infoPaneContent={this.generatePlantInfoContent()}
            shadowIcon={shadowIcon}
            label={label}
            pixelOffset={pixelOffset}
            isSelected={isSelected}
            mapRef={mapRef}
            {...rest}
            isLabelHover
            isPlantMarker
          />
          {this.renderGeoZones()}
        </div>
      );
    } catch (e) {
      // this avoids rendering component on errors
      // to debug, log this exception
      return null;
    }
  }
}

export interface PlantMarkerWithInfoPaneProps {
  plant: PlantDto,
  showGeofence?: boolean,
  markerColor: string,
  mapRef?: any
  updateToFieldValueWithPlantDrivers: (url: string) => void,
  onClick?: VoidFunc,
  onCloseClick?: VoidFunc,
  isSelected?: boolean,
  zIndex?: number
}
