import { ScaleDto } from '@trucktrax/trucktrax-ts-common';
import {
  FETCH_SCALES,
  SCALE_STATE_NOTIFIED,
  SCALE_WEIGHT_RECEIVED,
  SET_SCALEHUB_STATE,
} from '../../constants/actionConstants';
import {
  ReceivedWeight,
  ScaleHubState,
  ScaleState,
  PayloadAction,
} from '../../types';

export const scaleReducer = (
  state = { scales: [] as ScaleDto[] },
  action: PayloadAction<ScaleDto[], 'FETCH_SCALES'>
) => {
  switch (action.type) {
    case FETCH_SCALES:
      return {
        ...state,
        scales: action.payload,
      };
    default:
      return state;
  }
};

export const scaleHubStatusReducer = (
  state: ScaleHubState = { connected: false },
  action: PayloadAction<ScaleHubState>
) => {
  switch (action.type) {
    case SET_SCALEHUB_STATE:
      return action.payload;
    default:
      return state;
  }
};

export const receivedWeightReducer = (
  state = {
    scaleId: '',
    weight: 0,
    isMoving: false,
    scaleState: ScaleState.unknown,
  },
  action: PayloadAction<
    ReceivedWeight,
    'SCALE_WEIGHT_RECEIVED' | 'SCALE_STATE_NOTIFIED'
  >
) => {
  switch (action.type) {
    case SCALE_WEIGHT_RECEIVED:
      return {
        ...action.payload,
        scaleState: state.scaleState,
      } as ReceivedWeight;
    case SCALE_STATE_NOTIFIED:
      return {
        ...state,
        scaleState: action.payload.scaleState,
      } as ReceivedWeight;
    default:
      return { ...state } as ReceivedWeight;
  }
};
