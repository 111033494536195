import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from '@trucktrax/trucktrax-common';
import { GeoZoneDto } from '@trucktrax/trucktrax-ts-common';
import { setCurrentRegion } from '../../../services/regionsService';
import { messageDrivers } from '../../../util/messageUtil';
import { setFavicon } from '../../../util/appUtil';
import RouteHistoryMap from '../map/RouteHistoryMapWrapper';
import { setCurrentGeozone } from '../../../store/actions/geolocationActions';

export class RouteHistoryView extends Component<RouteHistoryProps> {
  static defaultProps = {
    currentRegion: {
      url: '',
      location: {
        latitude: 0,
        longitude: 0,
      },
      name: '',
    },
    messages: { partiesList: [] },
    currentGeozone: {
      id: 0,
      deleted: false,
      archived: false,
      location: {
        latitude: 0,
        longitude: 0,
      },
    },
  };

  async componentDidMount() {
    document.title = 'GeoTrax - TruckTrax';
    setFavicon('/favicon-geotrax.ico');
  }

  render() {
    const {
      currentRegion, currentGeozone,
    } = this.props;

    if (currentRegion?.name === undefined) {
      return <Loader />;
    }

    if (currentGeozone?.id === undefined) {
      return <Loader />;
    }

    return (
      <div key={currentGeozone.url}>
        <RouteHistoryMap
          lat={currentGeozone?.location?.latitude || 0}
          lng={currentGeozone?.location?.longitude || 0}
          currentGeozone={currentGeozone}
          isRouteHistory
        />
      </div>

    );
  }
}

export function mapStateToProps(state: any) {
  return {
    currentRegion: state.currentRegion,
    messages: state.messages,
    currentGeozone: state.currentGeozone,
  };
}

export interface Location {
  latitude: number;
  longitude: number;
}

export interface Region {
  url: string;
  location: Location;
  name: string;
}

export interface Message {
  partiesList: any[];
}

export interface RouteHistoryProps {
  currentRegion?: Region,
  currentGeozone?: GeoZoneDto,
}

export default connect(mapStateToProps, {
  setCurrentRegion,
  messageDrivers,
  setCurrentGeozone,
})(RouteHistoryView);
