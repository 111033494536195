import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ChecklistRegionDto, RegionDto } from '@trucktrax/trucktrax-ts-common';
import Checkbox from '@mui/material/Checkbox';
import { Button, Tooltip } from '@trucktrax/trucktrax-common';
import cx from 'classnames';

import styles from './CopyToRegionsComponent.module.css';
import sortRegionsAlpha from '../../../util/regionUtil';
import { PERMISSION_NAMES } from '../../../constants/appConstants';
import { userHasEditPermission } from '../../../services/permissionsService';
import { ReduxState } from '../../../store';

interface SelectableRegion extends RegionDto {
  checked?: boolean;
  disabled?: boolean;
}
export interface CopyToRegionsComponentState {
  checklist: SelectableRegion[];
}

export class CopyToRegionsComponent extends Component<CopyToRegionsProps, CopyToRegionsComponentState> {
  constructor(props: CopyToRegionsProps) {
    super(props);
    const sortedRegions = sortRegionsAlpha(props.regions);
    const regionsWithChecklistEditPermissions = this.getRegionsWithChecklistEditPermission(props.assignedRegions);
    const regionsChecklist = this.generateChecklist(
      sortedRegions,
      props.assignedRegions,
      props.selectedRegion,
      props.checklistRegions,
      regionsWithChecklistEditPermissions
    );

    this.state = {
      checklist: regionsChecklist,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  generateChecklist = (
    regions: RegionDto[],
    assignedRegions: RegionDto[],
    selectedRegion: RegionDto,
    checklistRegions: ChecklistRegionDto[],
    regionsWithChecklistEditPermission: RegionDto[]
  ) => regions.filter(r => {
    const isAssignedRegion = assignedRegions.some(a => a.name === r.name);

    return isAssignedRegion;
  }).map((r): SelectableRegion => {
    const isSelected = r.name === selectedRegion.name;
    const isAlreadyInChecklistRegions = checklistRegions.some(d => d.region.url === r.url);
    const userHasEditPermissionForRegion = regionsWithChecklistEditPermission.some(d => d.url === r.url);
    const disableRegion = isSelected || !isAlreadyInChecklistRegions || !userHasEditPermissionForRegion;
    return {
      ...r,
      checked: false,
      disabled: disableRegion,
    };
  });

  getRegionsWithChecklistEditPermission = (regionListToCheck: RegionDto[]) => {
    const { userPermission } = this.props;
    return regionListToCheck.filter(region => userHasEditPermission(userPermission, region.url!, PERMISSION_NAMES.CHECKLISTS));
  };

  checkIsAllChecked = () => {
    const { checklist: oldChecklist } = this.state;
    const notDisabledItems = oldChecklist.filter(item => (item.disabled === false));
    const checkedItems = oldChecklist.filter(item => (item.checked === true));

    return checkedItems.length === notDisabledItems.length;
  };

  handleAllCheck = () => {
    const { checklist: oldChecklist } = this.state;
    const allChecked = this.checkIsAllChecked();
    const newChecklist = oldChecklist.map(item => {
      if (allChecked && item.disabled === false) {
        item.checked = false;
      } else if (item.disabled === false) {
        item.checked = true;
      }

      return item;
    });

    this.setState({ checklist: newChecklist });
  };

  handleSingleCheck = (regionName: string) => () => {
    const { checklist: oldChecklist } = this.state;
    const newChecklist = oldChecklist.map(item => {
      if (item.name === regionName) {
        item.checked = !item.checked;
      }

      return item;
    });

    this.setState({ checklist: newChecklist });
  };

  handleSavingQuestions = () => {
    const { checklist } = this.state;
    const filterChecked = checklist.filter((item) => (item.checked === true));

    const regionList: SelectableRegion[] = JSON.parse(JSON.stringify(filterChecked));
    const returnChecklist = regionList.map((item: SelectableRegion): RegionDto => {
      delete item.checked;
      delete item.disabled;

      return item;
    });

    this.props.copyQuestionsToRegionsCallback(returnChecklist);
  };

  render() {
    const shouldDisableCopyToRegionBtn = !this.state.checklist.some(item => item.checked);
    const shouldDisplaySelectUnselectAllBtn = !this.state.checklist.every(item => item.disabled);
    const selectAllText = this.checkIsAllChecked() ? 'Unselect All' : 'Select All';

    return (
      <div className={styles.copyToRegionContainer}>
        <div className={styles.selectAllContainer}>
          <Checkbox
            checked={shouldDisplaySelectUnselectAllBtn ? this.checkIsAllChecked() : false}
            onChange={this.handleAllCheck}
            color="primary"
            disabled={!shouldDisplaySelectUnselectAllBtn}
          />
          <span className={styles.selectAllLabel}>
            {shouldDisplaySelectUnselectAllBtn ? selectAllText : (
              <span className={styles.selectAllLabel}>
                None Selectable
                {' '}
                <Tooltip
                  theme="info"
                  text="You do not have access to any of the available regions"
                >
                  <i
                    aria-hidden="true"
                    className={cx('icon-info', styles.icon)}
                  />
                </Tooltip>
              </span>
            )}
          </span>
        </div>
        <div className={styles.selectSingleContainer}>
          {this.state.checklist.map((region) => (
            <div key={`${region.name}-checkbox-container`}>
              <Checkbox
                checked={region.checked}
                onChange={this.handleSingleCheck(region.name!)}
                color="primary"
                disabled={region.disabled}
              />
              <span
                className={cx(styles.selectSingleLabel, (region.disabled ? styles.disabledLabel : null))}
              >
                {region.name}
              </span>
            </div>
          ))}
        </div>
        <div className={styles.copyToRegionBtnContainer}>
          <Button
            key="Copy To Region(s)"
            name="Copy To Region(s)"
            onClick={this.handleSavingQuestions}
            dataTest="copy-to-regions-btn"
            disabled={shouldDisableCopyToRegionBtn}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  userPermission: state.userPermission,
});

type CopyToRegionsReduxStateProps = ReturnType<typeof mapStateToProps>;
type CopyToRegionsOwnProps = {
  regions: RegionDto[],
  checklistRegions: ChecklistRegionDto[],
  assignedRegions: RegionDto[],
  selectedRegion: RegionDto,
  copyQuestionsToRegionsCallback: (regions: RegionDto[]) => void;
};

export type CopyToRegionsProps = CopyToRegionsReduxStateProps & CopyToRegionsOwnProps;

export default connect(mapStateToProps)(CopyToRegionsComponent);
