import { UserDto } from '@trucktrax/trucktrax-ts-common';
import { replaceOrUpdateListById } from '../../util/adminUtil';
import { ADD_USER, FETCH_USERS, UPDATE_USER } from '../../constants/actionConstants';
import { PayloadAction } from '../../types';

type MultipleOrSingle<T> = T | T[];

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const usersReducer = (
  state = { users: [] as UserDto[] },
  action: PayloadAction<MultipleOrSingle<UserDto>, 'FETCH_USERS' | 'ADD_USER' | 'UPDATE_USER'>
): { users: UserDto[] } => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        users: action.payload as UserDto[],
      };
    case ADD_USER: {
      const { users } = state;
      const user = action.payload as UserDto;
      users.push(user);
      return state;
    }
    case UPDATE_USER: {
      const { users } = state;
      replaceOrUpdateListById(action.payload as UserDto, users);
      return state;
    }
    default:
      return state;
  }
};
