import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AxiosError } from 'axios';

import cx from 'classnames';
import { RegionDto, UserRegionDto } from '@trucktrax/trucktrax-ts-common';
import styles from './AdminPanelContent.module.css';
import { openFailModal, openModal } from '../../../store/actions/errorModalActions';
import { logoutAll } from '../../../services/authService';
import { RegionsSelect } from './RegionsSelect';
import ImportCsvModal, { CsvErrror, CsvType } from './ImportCsvModal';
import { openSnackbar } from '../../../store/actions/snackbarActions';
import { addItemToAdminTableItems } from '../../../store/actions/dataTableActions';
import { DataTableContentProps } from '../DataTableContent';
import { postCsv } from '../../../services/csvService';
import { hasAdminAccessInGivenRegion } from '../../../util/permissionUtil';
import { userHasEditPermissionInAnyRegion } from '../../../services/permissionsService';
import SidenavLink from '../SidenavLink';
import {
  ACCENT,
  COMPANY_ADMIN_TOOLS,
  CONTINUE_LOGOUT_TEXT,
  IMPORT_CSV,
  IMPORT_CSV_ADMIN_BTN_TEXT,
  IMPORT_CSV_MODAL_TITLE,
  LOGOUT_REGION_BTN_TEXT,
  REGION_LOGOUT,
  REGION_LOGOUT_MODAL_BODY_1,
  REGION_LOGOUT_MODAL_BODY_2,
  ARE_YOU_SURE,
  CANCEL_LABEL,
} from '../../../constants/appConstants';
import { UserPermissionsByRegion } from '../../../types';
import AddFeatureModal from '../AddFeatureModal';
import { AdminCategory } from '../../../constants/navConstants';
import SideNavFolderDropdown from './SideNavFolderDropdown';
import { ADMIN_TRUCKS_PATH } from '../../../constants/apiConstants';
import { sleep } from '../../../util/appUtil';
import { ReduxState } from '../../../store';

export interface AdminPanelContentState {
  isReportsModalOpen: boolean;
  csvErrors: CsvErrror[];
  csvButtonDisabled: boolean;
  regionsWithAdminAccess: RegionDto[];
  hasLogoutRegionPermission: boolean;
  hasImportCsvPermission: boolean;
}
export class AdminPanelContent extends Component<AdminPanelContentProps, AdminPanelContentState> {
  static defaultProps: Partial<AdminPanelContentProps> = {
    categories: {
      admin: [],
    },
    currentRegionId: '',
    regions: [],
    assignedRegionList: [],
    dataTableList: {
      isLoading: false,
      items: [],
      pageInfo: {
        pageCount: 0,
        pageStart: 0,
      },
      baseUrl: '',
      source: ''
    },
    userPermission: {},
  };

  constructor(props: AdminPanelContentProps) {
    super(props);

    this.state = {
      isReportsModalOpen: false,
      csvErrors: [],
      csvButtonDisabled: false,
      regionsWithAdminAccess: [],
      hasLogoutRegionPermission: false,
      hasImportCsvPermission: false,
    };
  }

  componentDidMount() {
    this.findAdminRegions();
    const regionList = this.props.regions.filter(region => hasAdminAccessInGivenRegion(this.props.userPermission, region.url!));
    this.updatePermissions(regionList);
  }

  componentDidUpdate(prevProps: AdminPanelContentProps) {
    const regionAdded = this.props.assignedRegionList.length > prevProps.assignedRegionList.length;
    const regionsChanged = this.props.regions !== prevProps.regions;

    // permissions enter state late
    const permsLoadedOrChanged = this.props.userPermission !== prevProps.userPermission;

    if (regionAdded || regionsChanged || permsLoadedOrChanged) {
      this.findAdminRegions();
    }

    const regionList = this.props.regions.filter(region => hasAdminAccessInGivenRegion(this.props.userPermission, region.url!));
    this.updatePermissions(regionList);
  }

  findAdminRegions() {
    const regionList = this.props.regions.filter(region => hasAdminAccessInGivenRegion(this.props.userPermission, region.url!));
    this.setState({ regionsWithAdminAccess: regionList });

    // if current region doesn't have admin permissions, try default region
    const foundCurrent = regionList.filter(region => region.url === this.props.currentRegion.url);

    if (foundCurrent.length === 0 && regionList.length > 0) {
      const foundDefault = regionList.filter(region => region.url === this.props.defaultRegionUrl);
      // if default region doesn't have admin permissions, pick first one in the list
      if (foundDefault.length > 0) {
        this.onRegionChange(foundDefault[0]);
      } else {
        this.onRegionChange(regionList[0]);
      }
      window.location.reload();
    }
  }

  updatePermissions = (regionList: any[]) => {
    const hasLogoutRegionPermission = userHasEditPermissionInAnyRegion(this.props.userPermission, REGION_LOGOUT);
    const hasImportCsvPermission = userHasEditPermissionInAnyRegion(this.props.userPermission, IMPORT_CSV);

    if (this.state.hasLogoutRegionPermission === hasLogoutRegionPermission
      && this.state.hasImportCsvPermission === hasImportCsvPermission) {
      return;
    }

    this.setState({ hasLogoutRegionPermission, hasImportCsvPermission, regionsWithAdminAccess: regionList });
  };

  onRegionChange = (region: RegionDto) => {
    this.props.onRegionChange(region.url!);
  };

  static companyToolHeader = (companyTabs: JSX.Element[]) => {
    const companyHeader = (
      <div className={styles.reportsBtnContainer}>
        <span className={styles.companyToolHeader}>
          {COMPANY_ADMIN_TOOLS}
        </span>
      </div>
    );
    return (
      (companyTabs.length > 0) ? companyHeader : null
    );
  };

  clearErrors = () => {
    this.setState({ csvErrors: [] });
  };

  openImportCsvModal = () => {
    this.setState({
      isReportsModalOpen: true,
    });
  };

  closeImportCsvModal = () => {
    this.setState({
      isReportsModalOpen: false,
      csvErrors: [],
    });
  };

  importCsvSection = () => (
    <div className={styles.reportsBtnContainer}>
      <button
        data-test="open-import-csv-modal"
        className={styles.reportsBtn}
        onClick={this.openImportCsvModal}
      >
        <i
          aria-hidden="true"
          className={cx('icon-spreadsheet', styles.reportsBtnIcon)}
        />
        <span>{IMPORT_CSV_ADMIN_BTN_TEXT}</span>
      </button>
    </div>
  );

  postCsv = async (file: File, type: CsvType) => {
    this.setState({ csvButtonDisabled: true });
    const region = this.props.currentRegion.url!;
    await postCsv(file, type, region, this.onSuccess, this.onError);
  };

  onSuccess = (data: any[], type?: string) => {
    let counter = 0;
    data.forEach(item => {
      counter += 1;
      // add data to admin table
      if (this.props.dataTableList.baseUrl.includes(`/${type}/`)) {
        item.highlight = true;
        this.props.addItemToAdminTableItems(item);
      }
    });

    const formattedType = type === 'externalDrivers' ? 'external drivers' : type;

    // open snackbar
    this.props.openSnackbar({
      snackbarBody: `${counter} new ${formattedType} have been successfully uploaded.`,
      dataTest: 'CSV-SUCCESS-SNACKBAR',
    });
    // close modal
    this.setState({
      isReportsModalOpen: false,
      csvButtonDisabled: false,
    });
    // checks if we need to refresh the page when the user import a csv
    AdminPanelContent.csvRefreshRequired(type);
  };

  /**
   * Validate if the current path and the type of csv is the same and refresh the page
   */
  static csvRefreshRequired = (type?: string) => {
    sleep(1200);
    // Only for trucks at this time, if need to add more, use switch
    if (window.location.pathname === ADMIN_TRUCKS_PATH) {
      window.location.reload();
    }

    if (type === 'trucks') {
      window.location.pathname = ADMIN_TRUCKS_PATH;
    }
  };

  onError = (error: AxiosError) => {
    // 400 errors will come as [{field,message}] in error.response.data.errors
    // other errors will have a message in error.message
    try {
      const { response } = error;
      if (response && response.data) {
        const { errors, message } = response.data as CsvImportResponse;
        if (errors) {
          this.setState({ csvErrors: errors });
        } else if (message) {
          this.setState({
            csvErrors: [{
              field: '',
              message,
            }],
          });
        } else {
          const { message: responseMessage } = response as any;
          this.props.openFailModal(responseMessage, responseMessage);
        }
      } else {
        const responseMessage = error.message ?? 'Error importing CSV';
        this.props.openFailModal(responseMessage, responseMessage);
      }
    } finally {
      this.setState({ csvButtonDisabled: false });
    }
  };

  openRegionLogoutModal = () => {
    const currentRegionName = this.props.currentRegion.name;
    const modalBody = (
      <p className={styles.logoutRegionBody}>
        {REGION_LOGOUT_MODAL_BODY_1}
        <b>{currentRegionName}</b>
        {REGION_LOGOUT_MODAL_BODY_2}
      </p>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: ARE_YOU_SURE,
      modalBody,
      acceptDialog: this.props.logoutAll,
      acceptText: CONTINUE_LOGOUT_TEXT,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  regionLogoutSection = () => (
    <div className={styles.reportsBtnContainer}>
      <button
        data-test="open-logout-region-modal"
        className={styles.reportsBtn}
        onClick={this.openRegionLogoutModal}
      >
        <i
          aria-hidden="true"
          className={cx('icon-logout', styles.reportsBtnIcon)}
        />
        <span>{LOGOUT_REGION_BTN_TEXT}</span>
      </button>
    </div>
  );

  getTabsSection = (categories: AdminCategory[], companyWide: boolean) => categories
    .filter(category => category.companyWide === companyWide)
    .map(category => {
      if (category.subCategories) {
        const isOpen = category.subCategories.some(
          s => !!s.navRoute
            && window.location.pathname.toLowerCase().includes(s.navRoute.toLowerCase())
        );
        const subCategories = this.getTabsSection(category.subCategories, companyWide);

        return (
          <SideNavFolderDropdown
            name={category.name}
            icon={category.icon}
            isOpen={isOpen}
            key={`dropdown-${category.name}`}
          >
            {subCategories as any}
          </SideNavFolderDropdown>
        );
      }
      return (<SidenavLink {...category} key={`sidenavlink-${category.name}`} />);
    });

  render() {
    const regionTabs = this.getTabsSection(this.props.categories.admin, false);
    const companyTabs = this.getTabsSection(this.props.categories.admin, true);
    const companyHeader = AdminPanelContent.companyToolHeader(companyTabs);

    return (
      <div className={styles.adminPanelContent}>
        <AddFeatureModal
          title={IMPORT_CSV_MODAL_TITLE}
          isOpen={this.state.isReportsModalOpen}
          onCancel={this.closeImportCsvModal}
          style={styles.reportsModal}
        >
          <ImportCsvModal
            postCsv={this.postCsv}
            errors={this.state.csvErrors}
            clearErrors={this.clearErrors}
            disabled={this.state.csvButtonDisabled}
          />
        </AddFeatureModal>
        {this.props.currentRegionId && (
          <RegionsSelect
            onSelectionChanged={this.onRegionChange}
            regions={this.state.regionsWithAdminAccess}
            currentRegion={this.props.currentRegion}
          />
        )}
        <div className={styles.adminPanelLowerContent}>
          {regionTabs}

          {this.state.hasLogoutRegionPermission && this.regionLogoutSection()}
          {this.state.hasImportCsvPermission && this.importCsvSection()}
          {companyHeader}
          {companyTabs}
        </div>
      </div>
    );
  }
}

type CsvImportResponse = {
  errors: [],
  message: string
};

type DataTableList = Pick<DataTableContentProps, 'baseUrl' | 'isLoading' | 'items' | 'pageInfo' | 'source'> & { items: any[] };

export interface AdminPanelContentProps {
  categories: {
    admin: AdminCategory[]
  };
  defaultRegionUrl: string;
  onRegionChange: (regionUrl: string) => void;
  currentRegionId: string;
  regions: RegionDto[];
  assignedRegionList: UserRegionDto[];
  openModal: (modalOptions: any) => void;
  openFailModal: (title: string, body: string) => void;
  openSnackbar: (options: any) => void;
  addItemToAdminTableItems: (item: any) => void;
  logoutAll: () => void;
  currentRegion: RegionDto,
  flags: {
    featuresList: {
      features: {
        isDeleted: boolean;
        defaultValue: boolean;
        failedCalls: boolean;
        ADMIN: boolean;
      }
    },
    allFlags: any[]
  },
  dataTableList: DataTableList,
  userPermission: UserPermissionsByRegion;
}

function mapStateToProps(state: ReduxState) {
  return {
    currentRegion: state.currentRegion,
    flags: state.flags,
    dataTableList: state.dataTableList,
    userPermission: state.userPermission || {},
    assignedRegionList: state.assignedRegionList,
    defaultRegionUrl: state.defaultRegionList[0].url,
  };
}

export default connect<any, any, any, ReduxState>(mapStateToProps, {
  openModal,
  openFailModal,
  openSnackbar,
  addItemToAdminTableItems,
  logoutAll,
})(AdminPanelContent);
