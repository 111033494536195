import React, { Component } from 'react';
import cx from 'classnames';
import { PermissionDto } from '@trucktrax/trucktrax-ts-common';
import Divider from '@mui/material/Divider';
import { connect } from 'react-redux';
import { Button, TTInput } from '@trucktrax/trucktrax-common';
import { createDataTableRecord, ErrorMap } from '../../../services/dataTableService';
import { fetchPermissionList, GROUPS_PATH } from '../../../services/permissionsService';
import Table from '../../shared/admin/PermissionsTable';
import styles from './PermissionsAddModal.module.css';
import { createAssignedPermissionList, createDefaultAssignedPermissionList } from '../../../util/permissionUtil';
import { getSecurityBaseUrl } from '../../../util/apiUtil';
import { ADD_PERMISSIONS_GROUP_SUCCESS_TEXT } from '../../../constants/successConstants';
import { ADD_PERMISSIONS_GROUP_ERROR_MESSAGE } from '../../../constants/errorConstants';
import { ReduxState } from '../../../store';
import { ConnectedDispatchFunction } from '../../../types';
import FooterMessage from '../../shared/forms/FooterMessage';

export interface PermissionsAddModalState {
  isPending: boolean;
  nameTouch: boolean;
  name: string;
  errors: any;
  tableData: any[];
}
export class PermissionsAddModal extends Component<PermissionsAddModalProps, PermissionsAddModalState> {
  constructor(props: PermissionsAddModalProps) {
    super(props);

    this.state = {
      isPending: false,
      nameTouch: false,
      name: '',
      errors: {},
      tableData: [],
    };
  }

  componentDidMount() {
    this.props.fetchPermissionList();
  }

  componentWillReceiveProps(nextProps: PermissionsAddModalProps) {
    if (this.props.permissionList !== nextProps.permissionList) {
      this.setPermissionGroupData(nextProps.permissionList);
    }
  }

  onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: e.target.value,
      errors: {},
    });

    if (this.state.isPending) {
      this.setState({
        isPending: false,
      });
    }
  };

  onSuccess = () => {
    this.setState({
      isPending: false,
      nameTouch: false,
      name: '',
      errors: {},
      tableData: [],
    });
    this.props.closeModal();
  };

  onError = (error: ErrorMap) => {
    const name = this.state.name.trim();
    this.setState({
      isPending: false,
      name,
      nameTouch: false,
      errors: error,
    });
  };

  onSubmit = () => {
    this.setState({ isPending: true });
    const { createAdminTableData: post, currentRegion } = this.props;

    const toastMessages = {
      success: ADD_PERMISSIONS_GROUP_SUCCESS_TEXT,
      fail: ADD_PERMISSIONS_GROUP_ERROR_MESSAGE,
    };

    const url = getSecurityBaseUrl() + GROUPS_PATH;
    const name = this.state.name.trim();
    const assignedPermissions = createAssignedPermissionList(this.state.tableData, this.props.regionList);
    const body = {
      name,
      assignedPermissions,
    };

    post(
      url,
      body,
      this.onSuccess,
      this.onError,
      toastMessages,
      true,
      currentRegion.url
    );
  };

  setPermissionGroupData = (permissionList: PermissionDto[]) => {
    const assignedPermissions = createDefaultAssignedPermissionList(permissionList);

    this.setState({
      tableData: assignedPermissions,
    });
  };

  clearStateAndCloseModal = () => {
    this.setState({
      name: '',
      errors: {},
      isPending: false,
    });
    this.props.closeModal();
  };

  handleNameTouch = () => {
    if (!this.state.nameTouch) {
      this.setState({
        nameTouch: true,
      });
    }
  };

  render() {
    const {
      name,
      nameTouch,
      errors,
      tableData,
      isPending,
    } = this.state;
    const isNotValid = !name || name.trim() === '';
    const isNameEmpty = (nameTouch && isNotValid);
    const nameError = errors && errors.Name;

    return (
      <div>
        <TTInput
          id="Name"
          style={styles.titleInput}
          maxLength={40}
          value={name}
          onChangeCallback={this.onNameChange}
          onBlurCallback={this.handleNameTouch}
          dataTest="add-permissions-group-name"
          errorDataTest="add-permissions-group-name-missing-error"
          isRequired
          isEmpty={isNameEmpty}
          error={nameError && nameError.length > 0}
          errorMessage={nameError}
        />
        <div className={cx(styles.marginTop, styles.permissionsLabel)}>
          <span className={cx('txt-bold')}>
            <i
              aria-hidden="true"
              className={cx('icon-lock-solid', styles.icon)}
            />
            <span className={styles.marginLeft}>Permissions</span>
          </span>
        </div>
        <div className={styles.flagsDivider}>
          <Divider />
        </div>
        <div className={styles.tableContainer}>
          <Table
            tableData={tableData}
          />
        </div>
        <div className={styles.featureFooter}>
          <FooterMessage showAsterisk text="Required fields" />
          <div>
            <Button
              onClick={this.clearStateAndCloseModal}
              buttonClassName="tt-btn-secondary margin-right-10"
              name="Cancel"
              dataTest="cancel-feature-flag-creation"
            />
            <Button
              name="Add Permissions Group"
              buttonClassName="tt-btn--submit"
              onClick={this.onSubmit}
              dataTest="add-feature-flag-button"
              disabled={isPending || isNotValid}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    permissionList: state.permissionList.permissionList ?? [],
    regionList: state.regionList ?? [],
    currentRegion: state.currentRegion,
  };
}
type PermissionsAddModalReduxStateProps = ReturnType<typeof mapStateToProps>;
type PermissionsAddModalReduxDispatchProps = {
  fetchPermissionList: ConnectedDispatchFunction<typeof fetchPermissionList>,
  createAdminTableData: ConnectedDispatchFunction<typeof createDataTableRecord>
};
type PermissionsAddModalOwnProps = {
  closeModal: VoidFunction;
};
export type PermissionsAddModalProps = PermissionsAddModalOwnProps
  & PermissionsAddModalReduxStateProps
  & PermissionsAddModalReduxDispatchProps;

export default connect(mapStateToProps, {
  fetchPermissionList,
  createAdminTableData: createDataTableRecord,
})(PermissionsAddModal);
