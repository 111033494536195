import React, { Component } from 'react';
import cx from 'classnames';
import { Button, Tooltip } from '@trucktrax/trucktrax-common';
import { connect } from 'react-redux';
import {
  ACCENT,
  CONFIRM_LOGOUT,
  EDIT_BTN,
  EDIT_USER_BTN,
  LOGOUT_USER,
  LOGOUT_USER_BODY,
  SAVE_BTN,
  SAVE_CHANGES,
  USER_NOT_LOGGED_IN,
  EDIT_DRIVER_LOGGED_IN,
  EDIT_TRUCK_LOGGED_IN,
  EDIT_OBJECT_LOGGED_IN,
  EDIT_USER_LOGGED_IN,
  CANCEL_LABEL,
  EDIT_DEVICE_LOGGED_IN,
} from '../../../constants/appConstants';
import styles from './AdminActionButtons.module.css';
import { openModal } from '../../../store/actions/errorModalActions';
import { logoutUser } from '../../../services/authService';

export interface AdminActionButtonsState {
  userLastName: string,
  userFirstName: string,
  id: string,
  isUserLoggedIn: boolean
}

export class AdminActionButtons extends Component<AdminActionButtonsAllProps, AdminActionButtonsState> {
  static defaultProps: Partial<AdminActionButtonsAllProps> = {
    configData: [],
    isDisabled: false,
    disableEdit: false,
    category: '',
  };

  constructor(props: AdminActionButtonsAllProps) {
    super(props);

    this.state = {
      userLastName: '',
      userFirstName: '',
      id: '0',
      isUserLoggedIn: false,
    };
  }

  logOutWarningModal = () => {
    const userFullName = `${this.state.userLastName}, ${this.state.userFirstName}`;
    const userInfoObj = {
      firstName: this.state.userFirstName,
      lastName: this.state.userLastName,
      id: this.state.id,
    };

    const modalBody = (
      <span>
        <p className={styles.logOutModalBody}>
          User
          {' '}
          <b>
            {userFullName}
            {' '}
          </b>
          {LOGOUT_USER_BODY}
        </p>
      </span>
    );
    this.props.openModal({
      modalType: ACCENT,
      modalTitle: CONFIRM_LOGOUT,
      modalBody,
      modalOpen: true,
      acceptDialog: () => this.props.logoutUser(userInfoObj),
      acceptText: LOGOUT_USER,
      cancelText: CANCEL_LABEL,
      disabled: false,
      noActions: false,
    });
  };

  getActionBtns = (config: AdminActionButtonsConfig, i: number) => {
    const key = config.key || String(i);

    if (config.type === EDIT_BTN) {
      let warningText = EDIT_OBJECT_LOGGED_IN;
      switch (config.category) {
        case 'driver': warningText = EDIT_DRIVER_LOGGED_IN;
          break;
        case 'truck': warningText = EDIT_TRUCK_LOGGED_IN;
          break;
        case 'device': warningText = EDIT_DEVICE_LOGGED_IN;
          break;
        default: warningText = EDIT_OBJECT_LOGGED_IN;
      }

      return (
        <div
          key={key}
          className={styles.btnGroup}
        >
          <Button
            {...config}
            key={key}
            buttonClassName={styles.editBtn}
            disabled={this.props.disableEdit}
            onClick={this.props.toggleEdit}
            dataTest="admin-card-edit-btn"
          />
          {this.props.disableEdit && (
            <span className={styles.disabledEditText}>
              {this.props.overrideDisableReason ?? warningText}
            </span>
          )}
        </div>
      );
    }

    if (config.type === EDIT_USER_BTN) {
      const userDetails = config.key?.split(',');
      const userLoginStatus = userDetails![3] === 'true';

      if (this.state.isUserLoggedIn !== userLoginStatus || this.state.userFirstName !== userDetails![1]) {
        this.setState({
          userLastName: userDetails![0],
          userFirstName: userDetails![1],
          id: userDetails![2],
          isUserLoggedIn: userLoginStatus,
        });
      }

      return (
        <div
          key={key}
          className={styles.btnGroup}
        >
          {!this.state.isUserLoggedIn && (
            <Tooltip
              text={USER_NOT_LOGGED_IN}
            >
              <span>
                <Button
                  buttonClassName={cx(styles.cancelBtn, styles.logOutBtnDisabled)}
                  name={LOGOUT_USER}
                  onClick={() => null}
                  dataTest="logout-user-button-disabled-data-test"
                />
              </span>
            </Tooltip>
          )}
          {this.state.isUserLoggedIn && (
            <Button
              buttonClassName={cx(styles.cancelBtn, 'tt-btn-secondary')}
              name={LOGOUT_USER}
              onClick={this.logOutWarningModal}
              disabled={false}
              dataTest="logout-user-button-enabled-data-test"
            />

          )}
          <Button
            {...config}
            key={key}
            name="Edit User"
            buttonClassName={styles.editBtn}
            disabled={this.props.disableEdit || this.state.isUserLoggedIn}
            onClick={this.props.toggleEdit}
            dataTest="admin-card-edit-btn"
          />
          {(this.props.disableEdit || this.state.isUserLoggedIn) && (
            <span className={styles.disabledEditText}>
              {this.props.overrideDisableReason ?? EDIT_USER_LOGGED_IN}
            </span>
          )}
        </div>

      );
    }

    if (config.type === SAVE_BTN) {
      const isChanged = this.props.isChangedValid || config.isParentChanged;
      const disabled = this.props.isDisabled || !isChanged || !this.props.isRequiredValid;

      return (
        <div
          key={key}
          className={styles.btnGroup}
        >
          <Button
            buttonClassName={cx(styles.cancelBtn, 'tt-btn-secondary')}
            name="Cancel"
            onClick={this.props.requestToggleEdit}
            dataTest="cancel-button-data-test"
          />
          <Button
            {...config}
            name={SAVE_CHANGES}
            buttonClassName={cx(styles.editBtn, 'tt-btn--submit')}
            onClick={this.props.onSave}
            disabled={disabled}
            dataTest="save-changes-data-test"
          />
        </div>
      );
    }

    return null;
  };

  render() {
    const { configData } = this.props;
    return (
      <div className={styles.container}>
        {configData.map(this.getActionBtns)}
      </div>
    );
  }
}

export type AdminActionButtonsConfig = {
  key?: string;
  type: string;
  category?: string;
  isParentChanged?: boolean;
};

export interface AdminActionButtonsProps {
  configData: Array<any>,
  isDisabled?: boolean | undefined,
  overrideDisableReason?: string,
  toggleEdit: () => void,
  isChangedValid: boolean,
  isRequiredValid: boolean,
  onSave: () => void,
  requestToggleEdit: () => void,
  disableEdit?: boolean | undefined,
  category: string
}

interface AdminActionButtonsDispatchProps {
  openModal: typeof openModal,
  logoutUser: (logout: object) => void,
  logOutWarningModal: () => void
}

export type AdminActionButtonsAllProps = AdminActionButtonsProps & AdminActionButtonsDispatchProps;

export default connect<any, any, any>(null, {
  openModal,
  logoutUser,
})(AdminActionButtons);
