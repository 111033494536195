import React, { ChangeEvent, Component } from 'react';
import cx from 'classnames';
import {
  Button,
  DropDownList,
  Label,
  TTInput,
} from '@trucktrax/trucktrax-common';
import { FeatureFlagDto, SubscriptionDto, VoidFunc } from '@trucktrax/trucktrax-ts-common';
import { isEqual, sortBy } from 'lodash';
import styles from './feature.module.css';
import Table from './FeaturesTable';
import { constants, FEATURES_DROPDOWN_LIST, FEATURE_ITEM_EXAMPLES } from '../../../../constants/featureFlagConstants';
import FooterMessage from '../../../shared/forms/FooterMessage';
import Autocomplete from '../../../shared/forms/Autocomplete';
import { flattenDataForDisplay, getCommaSeparatedRegions, sort } from '../../../../util/featureFlagUtil';

class FeatureFlagBody extends Component<FeatureFlagBodyProps, FeatureFlagBodyState> {
  searchRef?: HTMLDivElement | null;

  constructor(props: FeatureFlagBodyProps) {
    super(props);
    this.state = {
      title: this.props.flagToEdit?.name || '',
      desc: this.props.flagToEdit?.description || '',
      searchText: '',
      selectedDataSet: FEATURES_DROPDOWN_LIST[0].dataSource,
      hintText: FEATURE_ITEM_EXAMPLES.driversData,
      tableData: [],
      isPending: false,
      dataForDisplay: {},
      selectedRow: [],
      flattenedList: [],
      unchangedSubscriptionList: [],
      dropDownSelection: FEATURES_DROPDOWN_LIST[0],
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps: FeatureFlagBodyProps) {
    if (this.props.flagToEditSubscriptions !== prevProps.flagToEditSubscriptions) {
      this.parseExistingDataAndRemoveFromAutocomplete();
    }
  }

  onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      title: e.target.value,
    });

    // clear error
    if (this.props.requestError && this.props.resetError) {
      this.props.resetError();
    }

    // clear pending state
    if (this.state.isPending) {
      this.setState({
        isPending: false,
      });
    }
  };

  onDescChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      desc: e.target.value,
    });
  };

  onRowSelection = (row: number[]) => {
    this.setState({ selectedRow: row });
  };

  onRowRemoveButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, row: TableDataProp, index: number) => {
    event.stopPropagation();
    this.removeItem(row, index);
    this.onRowSelection([]);
  };

  onAddAFeatureButtonClick = () => {
    if (!this.props.onAddAFeatureButtonClick) {
      return;
    }

    const subscriberUrls: string[] = this.state.tableData.map((item: TableItem) => item.itemUrl!);

    const featureFlagPostInfo = {
      featureFlag: {
        name: this.state.title,
        description: this.state.desc,
      },
      subscriberUrls,
    };

    this.props.onAddAFeatureButtonClick(
      featureFlagPostInfo,
      this.clearStateAndCloseModal,
      this.enableSendButton
    );

    this.setState({ isPending: true });
  };

  onEditAFeatureButtonClick = () => {
    if (!this.props.onEditAFeatureButtonClick) {
      return;
    }
    this.props.onEditAFeatureButtonClick(
      this.props.flagToEdit,
      this.props.flagToEditSubscriptions,
      this.state.unchangedSubscriptionList,
      this.state.tableData,
      this.enableSendButton,
      this.clearStateAndCloseModal
    );
    this.setState({ isPending: true });
  };

  getData() {
    const dataListMapping = this.state.dataForDisplay;
    // add the mappings to an object for filtering selection
    const {
      regionsData, usersData, driversData, tenantsData,
    } = this.props.data || {};
    let driversMap = [];
    let regionsMap = {};
    let regionsDataMap = {};
    let usersMap = [];
    let tenantsMap = {};

    // map the regionsData so they can be referenced below for the driver region
    if (regionsData) {
      const arrayToObject = (regionList: any) => regionList.reduce((acc: any, region: any) => {
        acc[region.url] = region;
        return acc;
      }, {});

      regionsMap = arrayToObject(regionsData);

      regionsDataMap = regionsData.map((region: any) => ({
        display: region.name,
        item: region.name,
        value: region.url,
        type: 'Regions',
        region: '',
        itemUrl: region.url,
        label: 'regionsData',
      }));

      // add regionsData to the mapping
      dataListMapping.regionsData = regionsDataMap;
    }

    if (tenantsData) {
      tenantsMap = tenantsData.map((tenant: any) => ({
        display: tenant.name,
        item: tenant.name,
        value: tenant.url,
        type: 'Tenants',
        region: '',
        itemUrl: tenant.url,
        label: 'tenantsData',
      }));

      // add tenants to the mapping
      dataListMapping.tenantsData = tenantsMap;
    }

    if (driversData) {
      // map driversData to display in autocomplete
      driversMap = driversData.map((driver: any) => {
        const url = driver?.region?.url;
        const regionsMapElement = (regionsMap as any)[url];
        const regionString = (regionsMapElement && regionsMapElement.name)
          ? `(${regionsMapElement.name})`
          : '';
        return {
          display: `${driver.firstName} ${driver.lastName}`,
          item: `${driver.firstName} ${driver.lastName}`,
          value: driver.id,
          type: 'Drivers',
          region: regionString || '',
          itemUrl: driver.url,
          label: 'driversData',
        };
      });

      // add drivers to the mapping
      dataListMapping.driversData = driversMap;
    }

    if (usersData) {
      // map driversData to display in autocomplete
      usersMap = usersData.map((user: any) => {
        const commaSeparateRegions = getCommaSeparatedRegions(regionsMap, user.regions);
        return {
          display: `${user.firstName} ${user.lastName}`,
          item: `${user.firstName} ${user.lastName}`,
          value: user.id,
          type: 'Users',
          itemUrl: user.url,
          region: commaSeparateRegions || '',
          label: 'usersData',
        };
      });

      // add users to the mapping
      dataListMapping.usersData = usersMap;
    }

    // other data mappings can be added here for tenants/regionsData etc.
    this.setState({
      dataForDisplay: dataListMapping,
    }, this.parseExistingDataAndRemoveFromAutocomplete);
  }

  setSearchRef = (el: HTMLDivElement | null) => {
    this.searchRef = el;
  };

  parseExistingDataAndRemoveFromAutocomplete = () => {
    const flagToEdit = this.props.flagToEdit || {};
    if (Object.keys(flagToEdit).length === 0) {
      return;
    }

    const listOfSubscriptions = this.props.flagToEditSubscriptions;
    let { flattenedList } = this.state;
    let itemToAddToTable;
    const unchangedSubscriptionList: any = [];

    if (flattenedList.length < 1) {
      // flatten dataForDisplay to be more easily iterated over
      flattenedList = flattenDataForDisplay(this.state.dataForDisplay);
      // sets state so flattenDataForDisplay is called only once per mount
      this.setState({ flattenedList });
    }

    if (!listOfSubscriptions) {
      return;
    }

    // iterate through list to remove from datasource/add to tableData.
    listOfSubscriptions.forEach(subscription => {
      itemToAddToTable = flattenedList.find(
        (list: TableItem) => list.itemUrl === subscription.subscriber?.url
      );

      if (itemToAddToTable !== undefined) {
        this.addToTable(itemToAddToTable);
        unchangedSubscriptionList.push(itemToAddToTable);
      }
    });

    // set subscription list used to compare against subscription updates
    this.setState({
      unchangedSubscriptionList,
    });
  };

  updateInput = (searchText: string) => {
    this.setState({
      searchText,
    });
  };

  addToTable = (chosenRequest: TableItem) => {
    if (!chosenRequest?.value) {
      return;
    }

    const data = this.state.tableData;

    this.removeItemFromDataSource(chosenRequest.label!, chosenRequest.itemUrl!);
    data.push(chosenRequest);
    this.setState({
      tableData: data,
      searchText: '',
    });
  };

  removeItemFromDataSource(label: string, url: string) {
    const data = this.state.dataForDisplay[label];
    if (!data) {
      return;
    }

    const index = data.findIndex((subscriber: any) => subscriber.itemUrl === url);
    if (index < 0) {
      // this means we have a feature flag relationship, but the subscriber is deleted/doesn't exist
      // nothing to remove from the dropdown (which alreayd filters deleted entities), skip
      return;
    }
    data.splice(index, 1);
    const { dataForDisplay } = this.state;
    dataForDisplay[label] = data;

    this.setState({
      dataForDisplay,
    });
  }

  addItemBackToDataSource(item: TableDataProp) {
    if (!item.label) {
      return;
    }

    const data = this.state.dataForDisplay[item.label] || [];
    data.push(item);
    const { dataForDisplay } = this.state;
    dataForDisplay[item.label] = data;

    this.setState({
      dataForDisplay,
    });
  }

  addAllItemBackToDataSource(items: TableItem[]) {
    if (!items) {
      return;
    }

    const data = this.state.dataForDisplay;
    const arrayToObject = (array: any) => array.reduce((acc: any, item: any) => {
      const itemList = data[item.label] || [];
      itemList.push(item);
      acc[item.label] = itemList;
      return acc;
    }, []);

    const dataForDisplay = arrayToObject(items);
    const everything = { ...data, ...dataForDisplay };
    this.setState({
      dataForDisplay: everything,
    });
  }

  updateDataSource = (item: any) => {
    // update the data set that the textInput is using
    if (item?.dataSource) {
      this.setState({
        selectedDataSet: item.dataSource,
        hintText: FEATURE_ITEM_EXAMPLES[item.dataSource],
        dropDownSelection: item,
      });
    }
  };

  // might be better as a callback method
  removeItem = (row: TableDataProp, index: number) => {
    const tableDataSpliced = this.state.tableData;
    tableDataSpliced.splice(index, 1);

    // add item back to datasource
    this.addItemBackToDataSource(row);

    this.setState({
      tableData: tableDataSpliced,
    });
  };

  // might be better as a callback method
  removeAllItems = () => {
    this.addAllItemBackToDataSource(this.state.tableData);
    this.setState({
      tableData: [],
      selectedRow: [],
    });
  };

  clearStateAndCloseModal = () => {
    this.removeAllItems();
    this.setState({
      title: '',
      desc: '',
    });
    this.enableSendButton();

    if (this.props.closeModal) {
      this.props.closeModal();
    }
  };

  enableSendButton = () => {
    this.setState({ isPending: false });
  };

  render() {
    const isEditModal = Object.keys(this.props.flagToEdit || {}).length > 0;
    const isSubscriptionsChanged = !isEqual(
      sortBy(this.state.unchangedSubscriptionList, ['itemUrl']),
      sortBy(this.state.tableData, ['itemUrl'])
    );
    const isNotValid = !this.state.title || !this.state.desc;
    // calculates ref distance to bottom of view window, so that the dropdown sizes correctly
    const distanceToBottomFromRef = this.searchRef
      ? window.innerHeight - (this.searchRef.offsetTop + this.searchRef.offsetHeight) : '';

    const input1Props = {
      id: 'Title',
      style: styles.titleInput,
      htmlFor: 'title',
      maxLength: 40,
      touch: this.state.touch,
      value: this.state.title,
      onChangeCallback: this.onTitleChange,
      dataTest: 'new-feature-flag-title',
      placeholder: 'e.g. "AdminAccess"...',
      errorDataTest: 'feature-flag-title-missing-error',
      label: 'Use camel case',
      isRequired: true,
      disabled: isEditModal,
      error: this.props.requestError,
      errorMessage: this.props.requestErrorMessage,
    };

    const input2Props = {
      id: 'Description',
      style: cx(styles.descInput, 'margin-top-30'),
      htmlFor: 'desc',
      maxLength: 100,
      touch: this.state.descTouch,
      value: this.state.desc,
      onChangeCallback: this.onDescChange,
      dataTest: 'new-feature-flag-description',
      placeholder: 'e.g. "Access to Admin functionality"...',
      isRequired: true,
      errorDataTest: 'feature-flag-desc-missing-error',
      disabled: isEditModal,
    };

    return (
      <div>
        <TTInput {...input1Props} />
        <TTInput {...input2Props} />
        <Label className={cx(styles.permissionsLabel, 'margin-top-30')}>
          Give permission to:
        </Label>
        <div className={styles.permissionsContainer}>
          <div className={styles.dropDownSearchContainer}>
            <div className={styles.dropDownListContainer}>
              <DropDownList
                items={FEATURES_DROPDOWN_LIST}
                initialSelected={this.state.dropDownSelection}
                updateSelected={this.updateDataSource}
                data-test="permission-drop-down-menu"
                segmented
              />
            </div>
            <div ref={el => this.setSearchRef(el)} className={styles.searchContainer}>
              <Autocomplete
                dataTest="permission-drop-down-menu-search"
                hintText={`e.g. "${this.state.hintText}"...`}
                onUpdateInput={this.updateInput}
                onNewRequest={this.addToTable}
                searchText={this.state.searchText}
                dataSource={sort(this.state.dataForDisplay[this.state.selectedDataSet])}
                dataSourceConfig={{
                  text: 'display',
                  value: 'value',
                }}
                openOnFocus={false}
                fullWidth
                showClearIcon={false}
                autocompleteHeight={distanceToBottomFromRef
                  ? `${distanceToBottomFromRef - 100}px`
                  : '400px'}
                segmented
              />
            </div>
          </div>
          <div className={styles.tableContainer}>
            <Table
              tableData={this.state.tableData}
              removeAllCallBack={this.removeAllItems}
              onRowSelection={this.onRowSelection}
              onRowRemoveButtonClick={this.onRowRemoveButtonClick}
              selectedRow={this.state.selectedRow}
            />
          </div>
        </div>
        <div className={styles.featureFooter}>
          {isEditModal ? <span /> : <FooterMessage showAsterisk text="Required fields" />}
          <div>
            <Button
              onClick={this.clearStateAndCloseModal}
              buttonClassName="tt-btn-secondary margin-right-10"
              name={(isSubscriptionsChanged && isEditModal) ? constants.DISCARD_LABEL : constants.CANCEL_LABEL}
              dataTest={isEditModal ? 'edit-feature-flag-discard-changes' : 'cancel-feature-flag-creation'}
            />
            <Button
              name={isEditModal ? constants.APPLY_CHANGES : constants.ADD_FEATURE_FLAG}
              buttonClassName="tt-btn--submit"
              onClick={isEditModal ? this.onEditAFeatureButtonClick : this.onAddAFeatureButtonClick}
              dataTest={isEditModal ? 'edit-feature-flag-apply-changes' : 'add-feature-flag-button'}
              disabled={this.state.isPending
                || (!isSubscriptionsChanged && isEditModal)
                || isNotValid}
            />
          </div>
        </div>
      </div>
    );
  }
}

interface TableItem {
  itemUrl?: string,
  label?: string,
  value?: string
}

interface FeatureFlagPostInfo {
  featureFlag: {
    name: string,
    description: string
  },
  subscriberUrls: string[]
}

export interface FeatureFlagBodyProps {
  flagToEdit?: FeatureFlagDto,
  flagToEditSubscriptions?: SubscriptionDto[],
  requestError?: boolean,
  resetError?: VoidFunc,
  onAddAFeatureButtonClick?: (
    featureFlagInfo: FeatureFlagPostInfo,
    clearStateAndCloseModal: VoidFunc,
    enableSendButton: VoidFunc
  ) => void,
  onEditAFeatureButtonClick?: (
    flagToEdit?: FeatureFlagDto,
    flagToEditSubscriptions?: SubscriptionDto[],
    unchangedSubscriptionList?: SubscriptionDto[],
    tableData?: any,
    enableSendButton?: VoidFunc,
    clearStateAndCloseModal?: VoidFunc
  ) => void,
  data?: {
    regionsData?: any[],
    usersData?: any[],
    driversData?: any[],
    tenantsData?: any[]
  },
  closeModal?: VoidFunc,
  requestErrorMessage?: string[]
}

export interface FeatureFlagBodyState {
  title: string,
  desc: string,
  searchText: string,
  selectedDataSet: string,
  hintText: string,
  tableData: TableItem[],
  isPending: boolean,
  dataForDisplay: any,
  selectedRow: number[],
  flattenedList: TableItem[],
  unchangedSubscriptionList: [],
  dropDownSelection: {
    icon: string,
    label: string,
    dataSource: string
  },
  touch?: any,
  descTouch?: any
}

export interface TableDataProp {
  id?: number,
  type?: string,
  item?: string,
  region?: string,
  itemUrl?: string,
  label?: string,
  value?: string
}

export default FeatureFlagBody;
