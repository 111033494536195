import React, { Component } from 'react';
import { Tooltip } from '@trucktrax/trucktrax-common';
import styles from './RecenterMapBtn.module.css';

export class RecenterMapBtn extends Component<RecenterMapBtnProps> {
  getRecenterButton = () => (
    <div
      className={styles.recenterButtonContainer}
    >
      <Tooltip
        text="Re-Center Map"
        placement="left"
      >
        <button
          className={styles.recenterButton}
          onClick={this.props.onClick}
        >
          <i className="icon-reset-map" />
        </button>
      </Tooltip>
    </div>
  );

  render() {
    return this.getRecenterButton();
  }
}

export default RecenterMapBtn;

export interface RecenterMapBtnProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
