import { TruckDto } from '@trucktrax/trucktrax-ts-common';
import { PayloadAction } from '../../types';
import { FETCH_TRUCKS } from '../../constants/actionConstants';

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const trucksReducer = (
  state = { trucks: [] as TruckDto[] },
  action: PayloadAction<TruckDto[], any>
) => {
  switch (action.type) {
    case FETCH_TRUCKS:
      return {
        ...state,
        trucks: action.payload,
      };
    default:
      return state;
  }
};
