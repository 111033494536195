import config from 'react-global-configuration';
import {
  AUTH_BE_PATH,
  SAML_AUTH_BE_PATH,
  AUTH_URL_KEY,
  GEOTRAX_BE_PATH,
  GEOTRAX_URL_KEY,
  MESSAGING_BE_PATH,
  MESSAGING_URL_KEY,
  PROJECTIONS_BE_PATH,
  PROJECTIONS_URL_KEY,
  SECURITY_BE_PATH,
  SECURITY_URL_KEY,
  TICKETING_BE_PATH,
  TICKETING_URL_KEY,
  INTEGRATION_URL_KEY,
  INTEGRATION_BE_PATH,
  HTTP_BRIDGE_BASE_URL,
} from '../constants/apiConstants';

export const getProjectionsBaseUrl = () => config.get(PROJECTIONS_URL_KEY) + PROJECTIONS_BE_PATH;
export const getSecurityBaseUrl = () => config.get(SECURITY_URL_KEY) + SECURITY_BE_PATH;
export const getAuthBaseUrl = () => config.get(AUTH_URL_KEY) + AUTH_BE_PATH;
export const getSamlAuthBaseUrl = () => config.get(AUTH_URL_KEY) + SAML_AUTH_BE_PATH;
export const getGeotraxBaseUrl = () => {
  const keyUrl = GEOTRAX_URL_KEY;
  const path = GEOTRAX_BE_PATH;
  const key = config.get(keyUrl);
  return key + path;
};
export const getMessagingBaseUrl = () => config.get(MESSAGING_URL_KEY) + MESSAGING_BE_PATH;
export const getTicketingBaseUrl = () => config.get(TICKETING_URL_KEY) + TICKETING_BE_PATH;
export const getIntegrationBaseUrl = () => config.get(INTEGRATION_URL_KEY) + INTEGRATION_BE_PATH;
export const getBridgeBaseUrl = () => config.get(HTTP_BRIDGE_BASE_URL);
