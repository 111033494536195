const styles = {
  headerContainer: {
    background: '#F4F7F9',
    padding: 0,
  },
  headerRow: {
    padding: 0,
    height: 35,
  },
  headerStyle: {
    fontSize: '1.3rem',
    color: '#2F323A',
    fontWeight: 'inherit',
    textAlign: 'left',
    lineHeight: 1,
    paddingTop: 0,
    paddingBottom: 0,
  },
  colType: {
    width: '115px',
  },
  colItem: {
    width: '100%',
  },
  colRemove: {
    width: '60px',
    whiteSpace: 'nowrap',
    overflow: 'visible',
  },
  removeAllTextStyle: {
    fontSize: '1.3rem',
    fontFamily: 'PublicSans Bold',
    lineHeight: 1,
    paddingTop: 0,
    paddingBottom: 0,
  },
  removeAllHeaderStyle: {
    paddingRight: 10,
    textAlign: 'right',
    paddingTop: 0,
    paddingBottom: 0,
  },
  closeButton: {
    fontSize: '12px',
    paddingRight: 10,
    textAlign: 'right',
  },
};

export default styles;
