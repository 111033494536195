import React from 'react';
import cx from 'classnames';
import { vars } from '@trucktrax/trucktrax-common';
import styles from './LoadingCircle.module.css';

export function LoadingCircle(props: LoadingCircleProps) {
  const {
    size = 0,
    className = null,
  } = props;
  return (
    <div
      className={cx(styles.loading, className)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        border: `${size / 7}px solid ${vars.gray200}`,
        borderLeftColor: vars.green,
      }}
    />
  );
}

export interface LoadingCircleProps {
  size?: number;
  className?: string;
}

export default LoadingCircle;
