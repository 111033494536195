import React, { Component } from 'react';
import { isEqual } from 'lodash';
import cx from 'classnames';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Popover, { PopoverReference } from '@mui/material/Popover';
import Switch from '@mui/material/Switch';
import {
  List,
} from '@mui/material';
import { connect } from 'react-redux';
import {
  Button, Tooltip, TTDatePicker, DateWrapper,
} from '@trucktrax/trucktrax-common';
import { RegionDto, UserPreferencesDto } from '@trucktrax/trucktrax-ts-common';
import styles from './MapFilters.module.css';
import {
  resetMapFilters,
  toggleMapFilter,
  openMapFilters,
} from '../../../store/actions/mapFilterActions';
import { noop } from '../../../util/appUtil';
import { getUserPreferences, updateUserPreferences } from '../../../services/userPreferencesService';
import { MapFilterState } from '../../../store/reducers/mapFilterReducers';

export class MapFiltersDropdown extends Component<MapFiltersDropdownProps, MapFiltersDropdownState> {
  static defaultProps = {
    toggleMapFilter: noop,
    resetMapFilters: noop,
    openMapFilters: noop,
  };

  constructor(props: MapFiltersDropdownProps) {
    super(props);
    this.state = {
      anchorElement: null,
      anchorReference: 'anchorPosition',
      anchorPosition: {
        top: 75,
        left: 318,
      },
      isOpen: false,
      startDate: props.mapFilters.ordersStartDate || DateWrapper.now.startOfDay.date,
      endDate: props.mapFilters.ordersEndDate || DateWrapper.now.startOfDay.date,
    };
  }

  onOpen = (event: any) => {
    this.setState({
      anchorElement: event.currentTarget,
      isOpen: true,
    });
    this.props.openMapFilters!();
  };

  onClose = () => {
    this.setState({
      anchorElement: null,
      isOpen: false,
    });
  };

  onChange = (name: string) => (event: any) => {
    this.props.toggleMapFilter!({
      [name]: event.target.checked,
      shouldSave: true,
    });
  };

  onChangePlants = (id: number) => {
    const list = this.props.mapFilters.plantList.map((plant: any) => {
      if (plant.id === id) {
        return {
          ...plant,
          isSelected: !plant.isSelected,
        };
      }
      return plant;
    });
    this.props.toggleMapFilter!({
      plantList: list,
      shouldSave: true,
    });
  };

  onReset = () => {
    this.setState({
      startDate: DateWrapper.now.startOfDay.date,
      endDate: DateWrapper.now.startOfDay.date,
    }, async () => {
      await this.resetSavedMapFilters();
      this.props.resetMapFilters!();
    });
  };

  minDate = DateWrapper.now.startOfDay.date;

  maxDate = DateWrapper.now.addDays(7).endOfDay.date;

  onStartDateChange = (event: Date) => {
    const { minDate, maxDate } = this;

    if (DateWrapper.isValidDate(event) && new DateWrapper(event).isBetween(minDate, maxDate)) {
      const { ordersEndDate } = this.props.mapFilters;
      if (event > ordersEndDate) {
        this.setState({ endDate: event }, () => {
          this.props.toggleMapFilter!({
            ordersEndDate: event,
          });
        });
      }

      this.setState({ startDate: event }, () => {
        this.props.toggleMapFilter!({
          ordersStartDate: event,
        });
      });
    } else {
      this.setState(prevState => ({ startDate: new Date(prevState.startDate) }));
    }
  };

  onEndDateChange = (event: any) => {
    const { minDate, maxDate } = this;

    if (DateWrapper.isValidDate(event) && new DateWrapper(event).isBetween(minDate, maxDate)) {
      const { ordersStartDate } = this.props.mapFilters;
      if (ordersStartDate > event) {
        this.setState({ startDate: event }, () => {
          this.props.toggleMapFilter!({
            ordersStartDate: event,
          });
        });
      }

      this.setState({ endDate: event }, () => {
        this.props.toggleMapFilter!({
          ordersEndDate: event,
        });
      });
    } else {
      this.setState(prevState => ({ endDate: new Date(prevState.endDate) }));
    }
  };

  // The MaterialUI/DatePicker doesn't fire an onChanged event if
  //  the date text field is blank, so handle the blank date case here.
  // Note: The Blur event fires before the onChanged event
  onStartDateBlur = (event: any) => {
    if (event.target.value === '') {
      event.target.value = this.state.startDate;
    }
  };

  onEndDateBlur = (event: any) => {
    if (event.target.value === '') {
      event.target.value = this.state.endDate;
    }
  };

  fetchUserPreferences = async () => {
    const userPreferences = await getUserPreferences(this.props.currentUserUrl);

    if (userPreferences) {
      this.setState({
        userPreferences,
      });
    }
  };

  async componentDidUpdate(prevProps: MapFiltersDropdownProps) {
    if (!this.state.userPreferences) {
      await this.fetchUserPreferences();
      return;
    }

    if (this.props.mapFilters.shouldSave && !isEqual(prevProps.mapFilters, this.props.mapFilters)) {
      const currentRegionId = this.props.currentRegion.id;
      const currentProductLine = this.props.selectedProductLine;

      const existingPreferences = { ...this.state.userPreferences };
      const existingMapFilters = { ...existingPreferences.mapFilters };
      const existingMapFiltersForRegion = existingMapFilters[currentRegionId] || {};
      const existingMapFiltersForRegionandProductLine = existingMapFiltersForRegion[currentProductLine] || {};
      const {
        showTrucks, showPlants, showPlantsGeofences, showJobsites, showJobsitesGeofences, plantList, showExtTrucks
      } = this.props.mapFilters;

      // ensure we don't overwrite map filters for combinations of region & product line other than the current one
      // also ensure we don't overwrite preferences other than map filters, if/when we add more preferences
      const preferences: UserPreferencesDto = {
        ...existingPreferences,
        mapFilters: {
          ...existingMapFilters,
          [currentRegionId]: {
            ...existingMapFiltersForRegion,
            [currentProductLine]: {
              ...existingMapFiltersForRegionandProductLine,
              showTrucks,
              showExtTrucks,
              showPlants,
              showPlantsGeofences,
              showJobsites,
              showJobsitesGeofences,
              selectedPlantIds:
                plantList.filter((plant: any) => plant.isSelected).map((plant: any) => plant.id),
            }
          },
        }
      };

      const updatedUserPreferences = await updateUserPreferences(this.props.currentUserUrl, preferences);

      if (updatedUserPreferences) {
        this.setState({
          userPreferences: updatedUserPreferences,
        }, () => {
          this.props.toggleMapFilter!({
            shouldSave: false,
          });
        });
      }
    }
  }

  resetSavedMapFilters = async () => {
    if (!this.state.userPreferences) {
      return;
    }

    const existingPreferences = { ...this.state.userPreferences };

    const preferences: UserPreferencesDto = {
      ...existingPreferences,
      // clear out mapFilters completely (for all regions & product lines)
      mapFilters: {},
    };

    const updatedUserPreferences = await updateUserPreferences(this.props.currentUserUrl, preferences);
    if (updatedUserPreferences) {
      this.setState({
        userPreferences: updatedUserPreferences,
      });
    }
  };

  render() {
    const minDate = this.minDate ? new DateWrapper(this.minDate).toUSDateString() : undefined;
    const maxDate = this.maxDate ? new DateWrapper(this.maxDate).toUSDateString() : undefined;

    const {
      wasFilterOverridden,
      plantList,
      showTrucks,
      showPlants,
      showJobsites,
      showPlantsGeofences,
      showJobsitesGeofences,
      showExtTrucks,
    } = this.props.mapFilters;

    return (
      <div className={styles.wrapper}>
        <Tooltip text="Map Filters">
          <div>
            {/* red dot */}
            {wasFilterOverridden ? (
              <span className={styles.dot} />
            ) : null}
            <button
              className={cx(styles.button, {
                [styles.isOpen]: this.state.isOpen,
              })}
              onClick={this.onOpen}
              data-test="map-filter-button"
            >
              <i className={cx('icon-map-filters', styles.icon)} />
            </button>
          </div>
        </Tooltip>
        <Popover
          classes={{ paper: styles.paper }}
          open={this.state.isOpen}
          anchorEl={this.state.anchorElement}
          onClose={this.onClose}
          anchorPosition={this.state.anchorPosition}
          anchorReference={this.state.anchorReference}
        // autoFocus={false} // TODO
        >
          {/* Header with arrow */}
          <div className={styles.header}>
            <span className={styles.headerText}>MAP FILTERS</span>
            <span className={styles.arrow} />
          </div>
          <FormGroup classes={{ root: styles.formGroup }}>
            <div className={styles.subtitle}>Plants</div>
            <Divider className={styles.topDivider} />
            <div className={styles.listContainer}>
              <List className={styles.list}>
                {plantList.map((item: any) => (
                  <FormControlLabel
                    key={item.id}
                    className={cx(
                      styles.formControlSectionEnd
                    )}
                    classes={{
                      label: styles.controlLabelCheckboxPlants,
                      disabled: styles.controlLabelDisabled,
                    }}
                    control={
                      <Checkbox
                        checked={item.isSelected}
                        onChange={() => this.onChangePlants(item.id)}
                        color="primary"
                        classes={{ disabled: styles.controlLabelDisabled }}
                        className={styles.controlCheckboxPlants}
                      />
                    }
                    label={item.name}
                    data-test="map-filter-plants-geofences-checkbox"
                  />
                ))}
              </List>
            </div>
          </FormGroup>
          <FormGroup classes={{ root: styles.formGroup }}>
            <div className={styles.subtitle}>Items on Map</div>
            <Divider className={styles.topDivider} />
            <FormControlLabel
              className={styles.formControlLabel}
              classes={{
                label: styles.controlLabel,
                disabled: styles.controlLabelDisabled,
              }}
              control={(
                <Switch
                  value="PlantsSwitch"
                  checked={showPlants}
                  onChange={e => {
                    this.onChange('showPlants')(e);
                    this.props.toggleMapFilter!({
                      showPlantsGeofences: false,
                    });
                  }}
                  color="primary"
                />
              )}
              label="Plants"
              data-test="map-filter-plants-switch"
            />
            <FormControlLabel
              className={cx(
                styles.indentedFormControlLabel,
                styles.formControlSectionEnd
              )}
              classes={{
                label: styles.checkboxControlLabel,
                disabled: styles.controlLabelDisabled,
              }}
              control={(
                <Checkbox
                  checked={showPlantsGeofences}
                  onChange={this.onChange('showPlantsGeofences')}
                  disabled={!showPlants}
                  color="primary"
                  classes={{ disabled: styles.controlLabelDisabled }}
                />
              )}
              label="Show geofences"
              data-test="map-filter-plants-geofences-checkbox"
            />
            <FormControlLabel
              className={styles.formControlLabel}
              classes={{
                label: styles.controlLabel,
                disabled: styles.controlLabelDisabled,
              }}
              control={(
                <Switch
                  value="TrucksSwitch"
                  checked={showTrucks}
                  onChange={this.onChange('showTrucks')}
                  color="primary"
                />
              )}
              label="Internal Trucks"
              data-test="map-filter-trucks-switch"
            />
            <FormControlLabel
              className={styles.formControlLabel}
              classes={{
                label: styles.controlLabel,
                disabled: styles.controlLabelDisabled,
              }}
              control={(
                <Switch
                  value="ExtTrucksSwitch"
                  checked={showExtTrucks}
                  onChange={this.onChange('showExtTrucks')}
                  color="primary"
                />
              )}
              label="External Trucks"
              data-test="map-filter-trucks-switch"
            />
            <FormControlLabel
              className={styles.formControlLabel}
              classes={{
                label: styles.controlLabel,
                disabled: styles.controlLabelDisabled,
              }}
              control={(
                <Switch
                  value="OrdersSwitch"
                  checked={showJobsites}
                  onChange={e => {
                    this.onChange('showJobsites')(e);
                    this.props.toggleMapFilter!({
                      showJobsitesGeofences: false,
                    });
                  }}
                  color="primary"
                />
              )}
              label="Orders"
              data-test="map-filter-orders-switch"
            />
            <FormControlLabel
              className={cx(
                styles.indentedFormControlLabel,
                styles.formControlSectionEnd
              )}
              classes={{
                label: styles.checkboxControlLabel,
                disabled: styles.controlLabelDisabled,
              }}
              control={(
                <Checkbox
                  checked={showJobsitesGeofences}
                  onChange={this.onChange('showJobsitesGeofences')}
                  disabled={!showJobsites}
                  color="primary"
                  classes={{
                    root: styles.checkboxBase,
                    disabled: styles.controlLabelDisabled,
                  }}
                />
              )}
              label="Show geofences"
              data-test="map-filter-jobsites-geofences-checkbox"
            />
            <div
              className={cx(
                styles.datePickerContainer,
                !showJobsites && styles.datePickerDisabled
              )}
            >
              <TTDatePicker
                dataTest="mapFilters-startDate-picker"
                value={this.state.startDate}
                label="Start Date"
                onChangeCallback={this.onStartDateChange}
                onBlurCallback={this.onStartDateBlur}
                placeholder="MM/dd/yyyy"
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
            <div
              className={cx(
                styles.datePickerContainer,
                !showJobsites && styles.datePickerDisabled
              )}
            >
              <TTDatePicker
                dataTest="mapFilters-endDate-picker"
                value={this.state.endDate}
                label="End Date"
                onChangeCallback={this.onEndDateChange}
                onBlurCallback={this.onEndDateBlur}
                placeholder="MM/dd/yyyy"
                minDate={minDate}
                maxDate={maxDate}
              />
              <span className={styles.endDateMessage}>
                *Maximum: 7 days from today
              </span>
            </div>
          </FormGroup>
          <Divider />
          <Button
            buttonClassName={cx(styles.resetButton, 'tt-btn-secondary')}
            onClick={this.onReset}
            name="Reset to defaults"
            dataTest="map-filter-reset-defaults"
          />
        </Popover>
      </div>
    );
  }
}

export interface MapFiltersDropdownProps {
  toggleMapFilter?: (toggle: any) => void,
  resetMapFilters?: () => void,
  openMapFilters?: () => void,
  mapFilters: MapFilterState,
  currentRegion: RegionDto,
  currentUserUrl: string,
  selectedProductLine: string,
}

export interface AnchorPosition {
  top: number;
  left: number;
}

export interface MapFiltersDropdownState {
  anchorElement: null;
  anchorReference: PopoverReference;
  anchorPosition: AnchorPosition;
  isOpen: boolean;
  startDate: Date;
  endDate: Date;
  userPreferences?: UserPreferencesDto;
}

function mapStateToProps(state: any) {
  return {
    mapFilters: state.mapFilters,
    currentRegion: state.currentRegion,
    selectedProductLine: state.selectedProductLine,
    currentUserUrl: state.userUrl,
  };
}

export default connect(mapStateToProps, {
  toggleMapFilter,
  resetMapFilters,
  openMapFilters,
})(MapFiltersDropdown);
