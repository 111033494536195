import cx from 'classnames';
import { Card } from '@mui/material';
import Button from '@mui/material/Button';
import React, { Component, FormEventHandler } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Label, Modal, Snackbar, Tooltip, Button as TruckTraxButton
} from '@trucktrax/trucktrax-common';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import { LoginData, logIn, setTenantName } from '../../services/authService';
import { closeModal, openModal } from '../../store/actions/errorModalActions';
import { closeSnackbar, openSnackbar } from '../../store/actions/snackbarActions';
import { clearAuthTenantName } from '../../store/actions/authActions';
import styles from './LoginView.module.css';
import {
  COMPANY_NOT_FOUND, LOGO_IMAGE, ERROR, SAML_RESPONSE, SAML_RESPONSE_SUCCESS, SAML_RESPONSE_ERROR
} from '../../constants/appConstants';
import { TOO_MANY_LOGIN_ATTEMPTS, WINDOWS_AUTH_ERROR } from '../../constants/errorConstants';
import { ConnectedDispatchFunction, InputField, TruckTraxApps } from '../../types';
import { getSamlAuthBaseUrl } from '../../util/apiUtil';
import { RegionAccessMap, hasAppAccessInAnyRegion } from '../../util/permissionUtil';
import { fetchPermissionsListByUserUrl } from '../../services/permissionsService';
import { apps } from '../../util/appUtil';

export class LoginView extends Component<LoginViewProps, LoginViewState> {
  static defaultProps = {
    logInPending: false,
    modalOpen: false,
    modalTitle: '',
    modalBody: '',
    modalType: '',
    snackbarOpen: false,
    snackbarBody: '',
    snackbarType: '',
    authTenantName: ''
  };

  state = {
    passwordVisible: false,
    isSamlError: false,
  };

  componentDidMount() {
    const doSaml = (new URLSearchParams(window.location.search)).get(SAML_RESPONSE);

    if (doSaml === SAML_RESPONSE_SUCCESS) {
      // they clicked login with saml and we got a successful response
      // do the request for the tokens and log in
      this.props.logIn({ isSaml: true });
    }

    if (doSaml === SAML_RESPONSE_ERROR) {
      // something in the SAML login process failed, show a generic windows auth error,
      // most likely the user was not found in our system (employeeId not mapped to user)
      // could be microsoftonline.com error, a timeout, or something in our auth backend
      // auth-backend should have logged it
      this.setState({ isSamlError: true });
    }
  }

  get permissionsInitialized() {
    const hasUserPermissions = this.props.userPermission && Object.keys(this.props.userPermission).length > 0;
    return hasUserPermissions;
  }

  initPermissions() {
    if (this.permissionsInitialized) {
      return;
    }

    this.props.fetchPermissionsListByUserUrl(this.props.userUrl!);
  }

  /*
  Redirect rules copied from card sgtx-1910:
  If has access to GeoTrax only > GeoTrax
  If has access to Admin only > Admin
  If has access to ScaleTrax only > ScaleTrax

  If has access to all > ScaleTrax
  If has access to ScaleTrax AND GeoTrax > ScaleTrax
  If has access to ScaleTrax AND Admin > ScaleTrax

  If has access to GeoTrax AND Admin > GeoTrax
  */
  componentDidUpdate() {
    if (this.props.isPasswordTemporary) {
      this.props.history.push('/resetPassword');
    } else if (this.props.authenticated) {
      if (!this.permissionsInitialized) {
        this.initPermissions();
      } else {
        const validApps = apps.filter(app => hasAppAccessInAnyRegion(app.type, this.props.userPermission));
        const hasApp = (name: number) => validApps.some(app => app.name.toLowerCase() === TruckTraxApps[name].toLowerCase());

        // if they only have one app, send them to it
        if (validApps.length === 1) {
          this.props.history.push(`/${validApps[0].name}`);
          return;
        }
        // if they have two apps and they are Admin and GeoTrax then send them to GeoTrax
        if (validApps.length === 2 && hasApp(TruckTraxApps.Admin) && hasApp(TruckTraxApps.GeoTrax)) {
          this.props.history.push(`/${TruckTraxApps[TruckTraxApps.GeoTrax].toString()}`);
          return;
        }
        // always go to ScaleTrax otherwise
        this.props.history.push(`/${TruckTraxApps[TruckTraxApps.ScaleTrax].toString()}`);
      }
    }
  }

  authError = () => (this.props.errorMessage && !this.props.authenticated ? (
    <label
      htmlFor="password"
      className={cx('tt-label--help', styles.error)}
      data-test="incorrect-authentication-input-error"
    >
      {this.props.errorMessage}
    </label>
  ) : null);

  togglePasswordVisibility = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  openModal = () => {
    if (this.props.modalBody === TOO_MANY_LOGIN_ATTEMPTS) {
      return (
        <Modal
          onCancel={this.props.closeModal}
          type={'FAIL' as any}
          body={this.props.modalBody}
          title={this.props.modalTitle}
          isOpen={this.props.modalOpen}
          onOK={this.props.closeModal}
        />
      );
    }
    return null;
  };

  openSnackbar = (body?: string, open?: true, type?: string) => (
    <Snackbar
      snackbarBody={body ?? this.props.snackbarBody ?? ''}
      snackbarOpen={open ?? this.props.snackbarOpen}
      dataTest="login-error-snackbar"
      handleSnackbarClose={this.props.closeModal}
      snackbarType={type ?? this.props.snackbarType ?? ''}
    />
  );

  // bounces the browser to our auth-backend to do cert exchange with azure and get the user (login.microsoftonline.com)
  doSamlRedirect = () => {
    const { authTenantName } = this.props;
    const url = `${getSamlAuthBaseUrl()}/login?tenant_name=${authTenantName}&client_id=client&grant_type=sso&user_type=user`;
    window.location.href = url;
  };

  renderTenantName() {
    const { authTenantName: localTenantName } = this.props;
    const isTenantError = this.props.errorMessage.toUpperCase().trim().indexOf(COMPANY_NOT_FOUND.toUpperCase().trim()) >= 0;
    if (localTenantName && !isTenantError) {
      return null;
    }

    if (isTenantError) {
      return (
        <Field
          label="Company Name"
          name="tenantName"
          type="text"
          meta={{
            touched: false,
            error: ' ',
          }}
          component={this.renderInputField}
        />
      );
    }

    return (
      <Field
        label="Company Name"
        name="tenantName"
        type="text"
        component={this.renderInputField}
      />
    );
  }

  renderInputField = (field: InputField) => {
    const { type, meta: { submitFailed, error } } = field;

    const inputField = (
      <input
        id={field.label}
        className={cx(
          'form-control',
          'tt-input',
          error && submitFailed ? 'tt-input-error' : ''
        )}
        data-test={`${field.label}-input-field`}
        type={type}
        {...field.input}
        // Chrome tries to infer autofill and ignores autocomplete='off'
        // a random name and autocomplete='new-password' makes it harder to autofill
        name={Math.random()}
        autoComplete="new-password"
      />
    );

    const errorLabel = (
      /* eslint-disable */
      // Label has no associated Input field
      <label
        className="tt-label--help"
        data-test={`${field.label}-required-error`}
      >
        {error && submitFailed ? error : ''}
      </label>
      /* eslint-enable */
    );

    const toolTipText = `${this.state.passwordVisible ? 'Hide' : 'Show'} password`;

    const iconVisibilityClass = this.state.passwordVisible ? 'icon-visibility-off' : 'icon-visibility';

    const tooltipProps = {
      className: styles.passwordTooltip,
      text: toolTipText,
    };

    const passwordField = (
      <div className="tt-password--container">
        <Tooltip {...tooltipProps}>
          <button
            type="button"
            className="tt-btn--show-hide"
            tabIndex={-1}
            onClick={() => {
              this.togglePasswordVisibility();
            }}
          >
            <i
              aria-hidden="true"
              className={iconVisibilityClass}
            />
          </button>
        </Tooltip>
        {inputField}
        {errorLabel}
      </div>
    );

    const regularField = (
      <div>
        {inputField}
        {errorLabel}
      </div>
    );

    return (
      <div className="margin-top-20">
        <Label
          htmlFor={field.label}
          className="margin-top"
        >
          {field.label!}
        </Label>
        {field.label === 'Password' ? passwordField : regularField}
      </div>
    );
  };

  tenantReset = () => {
    const { authTenantName: localTenantName } = this.props;
    const tenantResetBtnStyles = {
      root: styles.btnResetTenant,
    };
    if (!localTenantName) {
      return null;
    }

    return (
      <div className={styles.resetTenantContainer}>
        <Button
          classes={tenantResetBtnStyles}
          type="reset"
          data-test="reset-tenant"
          onClick={this.props.clearAuthTenantName}
        >
          Clear cookie
        </Button>
      </div>
    );
  };

  render() {
    const { handleSubmit } = this.props;
    const { authTenantName: localTenantName } = this.props;
    const { isSamlError } = this.state;

    const isTenantError = this.props.errorMessage.toUpperCase().trim().indexOf(COMPANY_NOT_FOUND.toUpperCase().trim()) >= 0;
    if (localTenantName && !isTenantError) {
      return (
        <div
          className={styles.background}
        >
          <Card className={styles.card}>
            <img
              className={cx(styles.logo, 'margin-top-30')}
              src={LOGO_IMAGE}
              alt="logo"
            />
            <form
              onSubmit={handleSubmit!(this.props.logIn)}
              className={styles.form}
            >
              <Field
                className={styles.error}
                label="Username"
                name="email"
                type="text"
                component={this.renderInputField}
              />
              <Field
                label="Password"
                name="password"
                type={this.state.passwordVisible ? 'text' : 'password'}
                component={this.renderInputField}
              />
              {this.authError()}
              <div
                className="margin-top-30"
              >
                <Button
                  classes={{
                    root: styles.btnSubmit,
                  }}
                  type="submit"
                  data-test="submit"
                  disabled={this.props.logInPending}
                >
                  Login
                </Button>
              </div>
            </form>
            <div className={cx(styles.orWrapper)}>
              <div
                className={cx('margin-top-30; margin-bottom-30')}
              >
                <div className={cx(styles.orSpan)}>
                  <span>OR</span>
                </div>
              </div>
            </div>
            <div
              className={styles.samlButtonWrapper}
            >
              <TruckTraxButton
                buttonClassName={styles.samlButton}
                dataTest="submit"
                disabled={this.props.logInPending}
                onClick={this.doSamlRedirect}
                name="Login with Active Directory"
              />
            </div>
          </Card>
          {this.tenantReset()}
          {this.openModal()}
          {isSamlError ? this.openSnackbar(WINDOWS_AUTH_ERROR, true, ERROR) : this.openSnackbar()}
        </div>
      );
    }
    // no tenant name yet, collect it
    return (
      <div
        className={styles.background}
      >
        <Card className={styles.card}>
          <img
            className={cx(styles.logo, 'margin-top-30')}
            src={LOGO_IMAGE}
            alt="logo"
          />
          <form
            onSubmit={handleSubmit!(this.props.setTenantName)}
            className={styles.form}
          >
            {this.renderTenantName()}
            <div
              className={cx('margin-top-30')}
            >
              <Button
                classes={{ root: styles.btnSubmit }}
                type="submit"
                data-test="submit"
              >
                Continue
              </Button>
            </div>
          </form>
          {isTenantError && this.openSnackbar(this.props.errorMessage, true, ERROR)}
        </Card>
      </div>
    );
  }
}

export interface LoginViewProps {
  handleSubmit?: (func: VoidFunc) => FormEventHandler<HTMLFormElement>,
  errorMessage: string,
  logIn: (data?: LoginData) => void;
  logInPending?: boolean,
  authenticated: boolean,
  isPasswordTemporary: boolean,
  history: {
    push: (path: string) => void
  },
  modalOpen?: boolean,
  modalTitle?: string,
  modalBody?: string,
  modalType?: string,
  closeModal: VoidFunc,
  snackbarOpen?: boolean,
  snackbarBody?: string,
  snackbarType?: string,
  closeSnackbar: VoidFunc,
  authTenantName?: string,
  clearAuthTenantName: VoidFunc,
  setTenantName: VoidFunc,
  userFullName: string,
  userPermission: RegionAccessMap,
  userUrl: string,
  fetchPermissionsListByUserUrl: ConnectedDispatchFunction<typeof fetchPermissionsListByUserUrl>
}

export interface LoginViewState {
  passwordVisible: boolean,
  isSamlError: boolean,
}

interface LoginViewValidation {
  email?: string,
  password?: string,
  tenantName?: string,
}

export function validate(values: LoginViewValidation) {
  const errors: LoginViewValidation = {};
  if (!values.email) {
    errors.email = 'Enter Username';
  }
  if (!values.password) {
    errors.password = 'Enter Password';
  }
  if (!values.tenantName) {
    errors.tenantName = 'Enter Company Name';
  }
  return errors;
}

export function mapStateToProps(state: any) {
  return {
    errorMessage: state.auth.error,
    authenticated: state.auth.authenticated,
    isPasswordTemporary: state.auth.isPasswordTemporary,
    logInPending: state.auth.logInPending,
    modalOpen: state.openModal.modalOpen,
    modalTitle: state.openModal.modalTitle,
    modalBody: state.openModal.modalBody,
    modalType: state.openModal.modalType,
    openSnackbar: state.snackbar.openSnackbar,
    snackbarOpen: state.snackbar.snackbarOpen,
    snackbarBody: state.snackbar.snackbarBody,
    snackbarType: state.snackbar.snackbarType,
    closeSnackbar: state.snackbar.closeSnackbar,
    authTenantName: state.authTenantName,
    userFullName: state.auth.userFullName,
    userPermission: state.userPermission,
    userUrl: state.userUrl,
  };
}

export const ReduxFormSignIn = reduxForm<any, any, any>({
  validate,
  form: 'LogInForm',
})(LoginView);

export default withRouter(connect(mapStateToProps, {
  openModal,
  closeModal,
  openSnackbar,
  closeSnackbar,
  logIn,
  clearAuthTenantName,
  setTenantName,
  fetchPermissionsListByUserUrl
})(ReduxFormSignIn));
