import React, { Component } from 'react';
import { constants, ProductStatusInfo, Tooltip } from '@trucktrax/trucktrax-common';
import { AGGREGATE_STRING, CEMENT_STRING, READYMIX_VALUE } from '../../../constants/appConstants';
import styles from './LegendMapKey.module.scss';

export class LegendMapKey extends Component<LegendMapKeyProps> {
  static bySequenceNumber = (a: ProductStatusInfo, b: ProductStatusInfo) => a.sequenceNumber - b.sequenceNumber;

  static productStatusInfoToListItem = (psi: ProductStatusInfo, index: string): JSX.Element => LegendMapKey.renderListItem(psi, index);

  static productStatusInfosToListItems = (psiList: ProductStatusInfo[], listKey: string) => Object.values(psiList)
    .sort(LegendMapKey.bySequenceNumber) // musn't forget to sort them
    // this list is static, and will not change, so using an index is fine
    .map((psi, index) => LegendMapKey.productStatusInfoToListItem(psi, `${listKey}-${index}`));

  static renderListItem = (psi: ProductStatusInfo, key: string) => (
    <li key={key} className={styles.statusKey}>
      <Tooltip
        theme="info"
        text={psi.name}
        classes={{
          popper: styles.popper,
          tooltip: styles.tooltip,
        }}
      >
        <span>
          <i className="icon-dot" style={{ color: psi.color }} />
          {psi.abbreviation}
        </span>
      </Tooltip>
    </li>
  );

  static renderProductStatusList = (key: string, title: string, psiList: ProductStatusInfo[]) => {
    const listKey = `list-${key}`;
    return (
      <div key={`product-${key}`} className={styles.productColumn}>
        <div className={styles.title}>{title}</div>
        <ul>
          {LegendMapKey.productStatusInfosToListItems(psiList, listKey)}
        </ul>
      </div>
    );
  };

  renderMapStateKeys = () => {
    const { productLines } = this.props;
    const { aggregateStatuses, cementStatuses, readyMixStatuses } = constants.PRODUCT_STATUSES;
    const aggregate = productLines.includes(AGGREGATE_STRING)
      ? LegendMapKey.renderProductStatusList('aggregate', 'Aggr.', aggregateStatuses) : null;
    const cement = productLines.includes(CEMENT_STRING)
      ? LegendMapKey.renderProductStatusList('cement', 'Cement', cementStatuses) : null;
    const readyMix = productLines.includes(READYMIX_VALUE)
      ? LegendMapKey.renderProductStatusList('readymix', 'R.Mix', readyMixStatuses) : null;

    // they need to be in this order in the legend
    return [aggregate, cement, readyMix];
  };

  render() {
    return (
      <div className={styles.legend}>
        {this.renderMapStateKeys()}
      </div>
    );
  }
}

export default LegendMapKey;

export interface LegendMapKeyProps {
  productLines: string[];
}
