import React from 'react';
import svgpath from 'svgpath';
import cx from 'classnames';
import { VoidFunc } from '@trucktrax/trucktrax-ts-common';
import {
  Tooltip, Button, constants as C, DateWrapper,
} from '@trucktrax/trucktrax-common';
import { Link } from 'react-router-dom';
import icons from '../icons';
import TTMarker from '../TTMarker';
import MarkerLabel from './MarkerLabel';
import styles from '../TTMap.module.css';
import { MapView } from '../mapTypes';
import { getIdFromUrl, noop } from '../../../../util/appUtil';
import { getStatusNameFromMapView } from '../../../../util/statusesUtil';
import getArrow from '../../../../util/markerUtil';

const US_DATE_TIME_COMPACT: string = 'MM/dd/yy h:mma';

export const getEtaAsString = (milliseconds?: number): string => {
  const etaFormat = milliseconds && milliseconds > C.DAYS_IN_MILLISECONDS
    ? US_DATE_TIME_COMPACT : 'h:mma';
  const etaString = (milliseconds && DateWrapper.now.addSeconds(milliseconds).toString(etaFormat)) || '';
  return etaString;
};

export const getHoursAndMinutesAsString = (milliseconds: number): string => {
  const hours = Math.floor(milliseconds / (60 * 60 * 1000));
  const minutes = Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000));
  return `${hours} hr ${minutes} min`;
};

export default function PositionMarker(props: PositionMarkerProps) {
  const {
    position,
    selectedItemId,
    markerStatusMap = {},
    markerData,
    hideLabel,
    ticketInfoTicketsCallBack = noop,
    driverInfoMessageCallback = noop,
    isRouteHistory,
    isRouteInfo,
    parentRouteId,
    displayPNG,
    ...rest
  }: PositionMarkerProps = props;
  try {
    let { status } = position;
    if (position.productLine !== C.READY_MIX_VALUE && status === C.STATUS_IDS.LOADING) {
      status = C.STATUS_IDS.TICKETED;
    }

    const statusText = markerStatusMap[status!] || markerStatusMap[C.STATUS_IDS.UNKNOWN];
    const positionId = position.id.toString();

    const bearing = position.bearing || 0; // just in case bearing is somehow null or undefined

    // arrow icon is 22x22
    // so 11,11 is the center point of the icon, and therefore the point that should be used as the center of rotation
    const rotatedArrowPath = svgpath(icons.arrowPath).rotate(bearing - 90, 11, 11).toString();

    let stationary = !(
      ((position.status === C.STATUS_IDS.TO_JOB || status === C.STATUS_IDS.RETURNING)
        && !position.historical)
    );

    if (isRouteHistory || isRouteInfo) {
      // ordinarily, bearing will be 0 if and only if speed is also 0
      // however, we've observed at least one instance where a speed was recorded but bearing was 0.
      // It should also be noted that technically a bearing of 0 would mean the user was travelling due north
      // However, in our actual system, north-bound bearings will have near 0 values (e.g. 0.10000000149011612)
      // and missing bearing data will be recorded as 0
      stationary = position.speed === 0 || position.bearing === 0;
    }

    const path = stationary
      ? icons.circlePath
      : rotatedArrowPath;

    const statusColor = (C.STATUS_COLORS as any)[status!] || C.STATUS_COLORS.Unknown;

    const markerPosition = {
      lat: position.location?.latitude,
      lng: position.location?.longitude,
      bearing: position.bearing,
      statusText,
      statusColor,
    };

    let icon: any = {
      path,
      fillColor: statusColor,
      fillOpacity: 1,
      strokeWeight: 1,
      stroke: 'black',
      strokeOpacity: 0.5,
      // anchor point of image relative to geolocation
      anchor: new (window as any).google.maps.Point(C.GM_ANCHOR / 2, C.GM_ANCHOR / 2),
    };

    if (displayPNG) {
      icon = {
        url: getArrow(position.bearing, status, stationary),
        scaledSize: new (window as any).google.maps.Size(30, 30),
        // anchor point of image relative to geolocation
        anchor: new (window as any).google.maps.Point(15, 15),
      };
    }

    const shadowIcon = {
      ...icon,
      fillColor: 'black',
      fillOpacity: position.trackedObject?.isExternal ? 2.0 : 0.4,
      scale: 1.075,
      strokeWeight: position.trackedObject?.isExternal ? 4 : 2,
      strokeColor: 'black',
      strokeOpacity: position.trackedObject?.isExternal ? 1.0 : 0.1,
      anchor: new (window as any).google.maps.Point(C.GM_ANCHOR / 2 + 0.5, C.GM_ANCHOR / 2 - 1),
    };

    const showIconHighlight = selectedItemId === positionId && isRouteInfo && !displayPNG;
    const iconHighlight = showIconHighlight ? {
      ...icon,
      scale: 1,
      strokeWeight: 5,
      strokeColor: '#3a8eec',
      strokeOpacity: 0.9,
      anchor: new (window as any).google.maps.Point(C.GM_ANCHOR / 2, C.GM_ANCHOR / 2),
    } : undefined;

    const name = position.trackedObject && position.trackedObject.truckAlias;
    const label = (name && !hideLabel)
      ? (
        <MarkerLabel
          color={statusColor}
          name={name}
        />
      )
      : undefined;

    const title = `Truck #${name}`;
    const tooltipText = `Status: ${getStatusNameFromMapView(position)}`;
    const infoPaneTitle = (
      <div data-test="vehicle-number" title={title}>
        {tooltipText && (
          <Tooltip text={tooltipText}>
            {statusColor && (
              <i
                className={cx(styles.dotIcon, 'icon-check-circle-blank', 'margin-right-10', 'clickable')}
                style={{ color: statusColor }}
              />
            )}
          </Tooltip>
        )}
        <span className={cx(styles.titleText, 'nowrap-ellipsis')}>
          {title}
        </span>
      </div>
    );

    const getDriverName = () => {
      const { firstName, lastName } = markerData.driverDetails || { firstName: '', lastName: '' };

      let driverName = '';
      if (lastName) {
        driverName = lastName;
      }

      if (firstName) {
        if (driverName) {
          driverName = `${driverName}, `;
        }
        driverName = `${driverName}${firstName}`;
      }

      return driverName;
    };

    const generateTruckInfoContent = () => {
      const lastUpdate = new DateWrapper(markerData.truckDetails.timestamp).toString(US_DATE_TIME_COMPACT);
      const driverName = getDriverName();
      const mailObjectToSend = { ...markerData.driverDetails, fullName: driverName };
      const hasTicket = markerData.ticket;
      const isInternalDriver = !markerData.driverDetails?.gtLightAccess;
      const ticketStatus = markerData.truckDetails.status
        ? markerData.truckDetails.status
        : null;
      const hasDestination = ['Ticketed', 'ToJob'].includes(ticketStatus) && markerData?.ticket?.geoZone;
      const { speed } = markerData.truckDetails;
      const sendMessageButton = (
        <Button
          onClick={() => {
            driverInfoMessageCallback(mailObjectToSend);
          }}
          buttonClassName={cx('tt-btn-basic', styles.truckDetailsBtn)}
          iconClassName="icon-mail"
          dataTest="infopane-new-message-button"
          name={C.SEND_MESSAGE}
        />
      );

      const viewTicketButton = (
        <Button
          onClick={() => {
            ticketInfoTicketsCallBack(markerData);
          }}
          buttonClassName={cx('tt-btn-basic', styles.truckDetailsBtn)}
          iconClassName="icon-tickets"
          dataTest="infopane-ticket-display-button"
          name="View Ticket"
        />
      );

      const buttons = (
        <div className={styles.truckFooter}>
          {isInternalDriver && sendMessageButton}
          {hasTicket && viewTicketButton}
        </div>
      );

      const orderNumber = markerData?.ticket?.order?.orderNumber;
      const orderLinkText = orderNumber ? `Order #${orderNumber}` : '';
      const orderId = markerData?.ticket?.order ? getIdFromUrl(markerData?.ticket?.order?.url!) : '';

      return (
        <div>
          <div className={styles.details}>
            {(markerData.truckDetails.distance && hasDestination)
              && (
                <div className={styles.eta}>
                  <strong className={cx(styles.flexItem, 'txt-bold')}>ETA:</strong>
                  <div
                    className={styles.flexItem}
                  >
                    {getEtaAsString(markerData?.truckDetails?.duration?.value)}
                  </div>
                  <div
                    data-test="infopane-eta-time"
                    className={styles.flexItem}
                  >
                    {markerData.truckDetails.duration.value > C.DAYS_IN_MILLISECONDS
                      ? getHoursAndMinutesAsString(markerData.truckDetails.duration.value)
                      : `${markerData.truckDetails.duration.text}.`}
                  </div>
                </div>
              )}
            <div className={styles.metrics}>
              <div
                className={styles.flexItem}
                data-test="infopane-driver-name"
              >
                <b className="margin-right-5 txt-bold">Driver:</b>
                <span
                  className="nowrap-ellipsis"
                  title={driverName}
                >
                  {driverName}
                </span>
              </div>
              <div
                className={styles.flexItem}
                data-test="infopane-device-speed"
              >
                <b className="margin-right-5 txt-bold">
                  {C.DEVICE_SPEED}
                  :
                </b>
                <span>
                  {speed}
                  {' '}
                  mph
                </span>
              </div>
              <div
                className={styles.flexItem}
                data-test="infopane-time-stamp"
              >
                <b className="margin-right-5 txt-bold">
                  {C.DATE_TIME}
                  :
                </b>
                <span>{lastUpdate}</span>
              </div>
              <div>
                <Link className={cx('margin-right-5', 'txt-bold')} to={`/geotrax/orders/all/${orderId}`}>
                  {orderLinkText}
                </Link>
              </div>
            </div>
          </div>
          <div>{buttons}</div>
        </div>
      );
    };

    const generateTruckRouteInfoContent = () => {
      const nbsp = '\xa0'; // non-breaking space
      const timestampFormat = `MM/dd/yy'${nbsp}${nbsp}'h:mma`;
      const lastUpdate = new DateWrapper(markerData.truckDetails.timestamp)
        .toString(timestampFormat);
      const driverName = getDriverName();
      const { speed } = markerData.truckDetails;
      const orderNumber = markerData?.order?.orderNumber;
      const orderNumberText = orderNumber || '';
      const ticketNumber = markerData.ticket?.ticketNumber;
      const ticketNumberText = ticketNumber || '';

      return (
        <div>
          <div className={styles.details}>
            <div className={styles.metrics}>
              {isRouteHistory && (
                <div
                  className={styles.popupItem}
                  data-test="infopane-order"
                >
                  <div
                    className={styles.leftColumn}
                  >
                    {'Order '}
                  </div>
                  <div className="right-column">{orderNumberText}</div>
                </div>
              )}
              <div
                className={styles.popupItem}
                data-test="infopane-ticket"
              >
                <div className={styles.leftColumn}>
                  Ticket
                </div>
                <div className="right-column">{ticketNumberText}</div>
              </div>
              <div
                className={styles.popupItem}
                data-test="infopane-driver-name"
              >
                <div className={styles.leftColumn}>Driver</div>
                <div
                  className="nowrap-ellipsis, right-column"
                  title={driverName}
                >
                  {driverName}
                </div>
              </div>
              <div
                className={styles.popupItem}
                data-test="infopane-device-speed"
              >
                <div className={styles.leftColumn}>
                  Speed
                </div>
                <div className="right-column">
                  {speed}
                  {' '}
                  mph
                </div>
              </div>
              {isRouteHistory && (
                <div
                  className={styles.popupItem}
                  data-test="infopane-location"
                >
                  <div className={styles.leftColumn}>
                    Location
                  </div>
                  <div className="right-column">
                    {`${(markerPosition.lat!).toFixed(5)}`}
                    &nbsp;&nbsp;
                    {`${markerPosition.lng!.toFixed(5)}`}
                  </div>
                </div>
              )}
              <div
                className={styles.popupItem}
                data-test="infopane-time-stamp"
              >
                <div className={styles.leftColumn}>
                  Time
                </div>
                <div className="right-column">{lastUpdate}</div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    // pixelOffset improves centering of infoPane around marker
    const pixelOffset: google.maps.Size = {
      width: 1,
      height: 10,
      equals: (other) => other?.width === 1 && other.height === 10,
    };

    return (
      <TTMarker
        position={markerPosition}
        icon={icon}
        infoPaneTitle={infoPaneTitle}
        infoPaneContent={(isRouteHistory || isRouteInfo) ? generateTruckRouteInfoContent() : generateTruckInfoContent()}
        shadowIcon={!displayPNG ? shadowIcon : undefined}
        iconHighlight={iconHighlight}
        label={label}
        pixelOffset={pixelOffset}
        isSelected={positionId === selectedItemId}
        isRouteHover={isRouteHistory}
        isRouteHistory={isRouteHistory}
        {...rest}
      />
    );
  } catch (e) {
    // this avoids rendering component on errors
    // to debug, log this exception
    return null;
  }
}

export interface PositionMarkerProps {
  position: MapView,
  selectedItemId?: string,
  markerStatusMap?: any,
  markerData?: any,
  ticketInfoTicketsCallBack?: (obj: any) => void,
  driverInfoMessageCallback?: (obj: any) => void,
  onClick?: VoidFunc,
  onCloseClick?: VoidFunc,
  zIndex?: number,
  mapRef?: any,
  isSelected?: boolean,
  truckHasTicket?: boolean,
  onLogoutDriver?: VoidFunc,
  hideLabel?: boolean,
  isRouteHistory?: boolean,
  isRouteInfo?: boolean,
  parentRouteId?: (number | null),
  displayPNG?: boolean,
}
