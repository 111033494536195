import React from 'react';

export default function CardImage({ src = '', alt = '', className = '' }: CardImageProps) {
  return (
    <div className={className}>
      <img
        data-test="larger-browser-image"
        alt={alt}
        src={src}
      />
    </div>
  );
}

export interface CardImageProps {
  src?: string;
  alt?: string;
  className?: string;
}
