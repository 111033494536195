import { DataItem } from '../../components/shared/messages/AutoCompleteWithChip';
import { PayloadAction } from '../../types';
import {
  CURRENT_TAB,
  EMPTY_ALL_FIELDS,
  REMOVE_MESSAGE_FROM_REPLY_MAP,
  TOGGLE_MESSAGES,
  UPDATE_MESSAGE_FIELD,
  UPDATE_REPLY_MAP,
  UPDATE_SUBJECT_FIELD,
  UPDATE_TO_FIELD,
  UPDATE_CURRENT_RECIPIENT_SEARCH_TEXT,
} from '../../constants/actionConstants';
import { MESSAGES_TABS_INBOX } from '../../constants/appConstants';

const messageCenterReducerInitialState = {
  partiesList: [] as any[],
  currentRecipientSearchText: '',
  messageFieldValue: '',
  subjectFieldValue: '',
  messagesOpen: true,
};

const initialCurrentTab = { currentTab: MESSAGES_TABS_INBOX };
export type MessageCenterState = typeof messageCenterReducerInitialState;

type MessageCenterPayload = boolean | string | DataItem[];

export const messageCenterReducer = (
  state = messageCenterReducerInitialState,
  action: PayloadAction<MessageCenterPayload | undefined>
): MessageCenterState => {
  switch (action.type) {
    case UPDATE_TO_FIELD:
      return {
        ...state,
        currentRecipientSearchText: '', // want to clear the text when a new recipient is chosen
        partiesList: action.payload as any[],
      };
    case UPDATE_CURRENT_RECIPIENT_SEARCH_TEXT:
      return {
        ...state,
        currentRecipientSearchText: action.payload as string,
      };
    case UPDATE_MESSAGE_FIELD:
      return {
        ...state,
        messageFieldValue: action.payload as string,
      };
    case UPDATE_SUBJECT_FIELD:
      return {
        ...state,
        subjectFieldValue: action.payload as string,
      };
    case EMPTY_ALL_FIELDS:
      return {
        ...state,
        partiesList: [],
        currentRecipientSearchText: '',
        messageFieldValue: '',
        subjectFieldValue: '',
      };
    case TOGGLE_MESSAGES:
      return {
        ...state,
        messagesOpen: action.payload as boolean,
      };
    default:
      return state;
  }
};

export const inboxReplyMessageReducer = (
  state = new Map<string, string>(),
  action: PayloadAction<Map<string, string> | string>
) => {
  switch (action.type) {
    case UPDATE_REPLY_MAP:
      return new Map([...state, ...(action.payload as Map<string, string>)]);
    case REMOVE_MESSAGE_FROM_REPLY_MAP: {
      const newMap = new Map(state);
      newMap.delete(action.payload as string);
      return newMap;
    }
    default:
      return state;
  }
};

export const currentTabReducer = (
  state = initialCurrentTab,
  action: PayloadAction<string, 'CURRENT_TAB'>
) => {
  switch (action.type) {
    case CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    default:
      return state;
  }
};
