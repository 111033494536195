export const DOCS_VIDEOS_WEB_TITLE = 'GeoTrax Web';
export const DOCS_VIDEOS_MOBILE_TITLE = 'GeoTrax Mobile';
export const DOCS_VIDEOS_MOBILE_PATH = '/geotrax/docs/videos/mobile';
export const DOCS_VIDEOS_MOBILE_FOLDER = 'videos/mobile/';
export const DOCS_VIDEOS_MOBILE_PREFIX = 'videos_mobile';
export const DOCS_VIDEOS_WEB_PREFIX = 'videos_web';
export const DOCS_VIDEOS_WEB_FOLDER = 'videos/web/';
export const DOCS_VIDEOS_WEB_PATH = '/geotrax/docs/videos/web';
export const SCALETRAX_DOCS_VIDEOS_WEB_TITLE = 'ScaleTrax Web';
export const SCALETRAX_DOCS_VIDEOS_WEB_PATH = '/scaletrax/docs/videos/web';
export const SCALETRAX_DOCS_VIDEOS_WEB_PREFIX = 'scaletrax_videos_web';
export const SCALETRAX_DOCS_VIDEOS_WEB_FOLDER = 'scaletraxvideos/web/';
export const SCALETRAX_USERGUIDES_FOLDER = 'scaletraxuserguides/';
export const MP4_EXTENSION = '.mp4';
export const PDF_EXTENSION = '.pdf';
export const GUIDE_UPLOADING = 'Guide file uploading';
export const VIDEO_UPLOADING = 'Video file uploading';
export const SUBMIT_GUIDE_TEXT = 'Update Doc';
export const SUBMIT_VIDEO_TEXT = 'Update Video';
export const FILE_REPLACE_INSTRUCTIONS = 'New file must have the exact same name as the file being replaced.';
