import React from 'react';
import { connect } from 'react-redux';

import { ProductLine, RegionDto, UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import { useLocation, useHistory } from 'react-router-dom';

import styles from './OrdersPanelContent.module.css';
import SidenavLink from '../../../shared/SidenavLink';
import { ordersLinks } from '../../../../constants/navConstants';
import { getOrdersByProductLine } from '../../../../services/ordersService';
import { fetchCurrentTicketList } from '../../../../services/ticketsService';
import { clearSelectedTicket, resetTicketView } from '../../../../store/actions/ticketActions';
import { messageSocketInit } from '../../../../services/messagesService';
import { updateToFieldValue } from '../../../../store/actions/messageActions';

import { ConnectedDispatchFunction, ConnectedFunction } from '../../../../types';
import { ReduxState } from '../../../../store';
import ProductLineAndRegionManager from '../../../shared/sidenav/ProductLineAndRegionManager';

export const OrdersPanelContent = (props: OrdersPanelContentProps) => {
  const location = useLocation();
  const history = useHistory();

  const isTicketDetailsScreenOpen = () => {
    const ordersAllUrlRegExp = /\/orders\/all\/\d+/;
    const currentUrl = location.pathname;
    return ordersAllUrlRegExp.test(currentUrl);
  };

  const onRegionChangeHandler = (regionUrl: string) => {
    if (isTicketDetailsScreenOpen()) {
      // when details is open, changing regions takes you to ticketList
      props.fetchCurrentTicketList(regionUrl, props.selectedProductLine);
      history.push('/geotrax/orders/all');
    }
    props.onRegionChange(regionUrl!);
  };

  const productLineChangeHandler = (pl: ProductLine) => {
    const { currentRegion, messageSubscription } = props;
    // fetch in new filtered orders list and update Orders table
    props.getOrdersByProductLine(currentRegion.url);
    props.clearSelectedTicket();
    props.resetTicketView();
    props.updateToFieldValue([]);
    // Update Messages
    props.messageSocketInit(currentRegion, messageSubscription);
  };

  return (
    <div className={styles.contentContainer}>
      <ProductLineAndRegionManager
        productLinesDisabled={isTicketDetailsScreenOpen()}
        regions={props.regions}
        onProductLineChanged={productLineChangeHandler}
        onRegionChanged={onRegionChangeHandler}
      />
      {ordersLinks.map(config => <SidenavLink {...config} key={config.name} />)}
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  selectedProductLine: state.selectedProductLine,
  plantList: state.plantList,
  currentRegion: state.currentRegion,
  primaryProductLine: state.primaryProductLine,
  secondaryProductLines: state.secondaryProductLines,
  messageSubscription: state.messageSubscription,
});

type OrdersPanelContentReduxStateProps = ReturnType<typeof mapStateToProps>;

export type OrdersPanelContentProps = OrdersPanelContentOwnProps & OrdersPanelContentReduxStateProps & OrdersPanelContentReduxDispatchProps;

type OrdersPanelContentOwnProps = {
  onRegionChange: (regionUrl: string) => void;
  updateToFieldValue: (field: any) => void;
  messageSubscription?: { unsubscribe: () => void }[],
  regions?: RegionDto[],
  currentRegionId: string,
  currentRegion?: UrlKeyDto,
};

type OrdersPanelContentReduxDispatchProps = {
  getOrdersByProductLine: ConnectedDispatchFunction<typeof getOrdersByProductLine>,
  fetchCurrentTicketList: ConnectedDispatchFunction<typeof fetchCurrentTicketList>,
  messageSocketInit: ConnectedDispatchFunction<typeof messageSocketInit>,
  clearSelectedTicket: ConnectedFunction<typeof clearSelectedTicket>,
  resetTicketView: ConnectedFunction<typeof resetTicketView>,
};
export default connect(mapStateToProps, {
  getOrdersByProductLine,
  fetchCurrentTicketList,
  messageSocketInit,
  clearSelectedTicket,
  resetTicketView,
  updateToFieldValue,
})(OrdersPanelContent);
