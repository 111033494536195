import React, { Component, ReactNode } from 'react';

import cx from 'classnames';
import { Link, Route, RouteChildrenProps } from 'react-router-dom';
import styles from './SideLink.module.css';

export class SidenavLink extends Component<SidenavLinkProps> {
  static defaultProps: Partial<SidenavLinkProps> = {
    sub: false,
    icon: undefined,
  };

  link = ({ match }: RouteChildrenProps<boolean>) => {
    const {
      sub, navRoute, icon, name,
    } = this.props;
    const navStyle = sub ? 'tt-nav-secondary--docs' : 'tt-nav-secondary--feature';
    const selected = cx('nav-item-list--title', navStyle, { selected: match });
    const dataTest = `sidenav-link-${name}`.replace(' ', '-').toLowerCase();
    const secondaryTitle = !sub ? 'tt-nav-secondary--title' : '';
    return (
      <Link
        className={styles.categoryLink}
        to={navRoute}
        data-test={dataTest}
        tabIndex={-1}
      >
        <p className={selected}>
          {icon && <i className={icon} />}
          <span className={secondaryTitle}>{name}</span>
        </p>
      </Link>
    );
  };

  render() {
    const { navRoute } = this.props;

    return (
      <Route path={navRoute}>
        {this.link as unknown as ReactNode}
      </Route>
    );
  }
}

export interface SidenavLinkProps {
  navRoute: string;
  name: string;
  icon?: string;
  sub?: boolean
}

export default SidenavLink;
