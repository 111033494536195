import * as signalR from '@microsoft/signalr';
import { UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import recordLog from '../../util/errorUtil';
import { acknowledgeMessage, updateMessages } from '../../store/actions/messageActions';
import { store } from '../../Main';
import { getInboxMessages } from '../messagesService';
import {
  JOIN_GROUP,
  RECEIVE_MESSAGE,
  RECONNECT_TIMEOUT,
} from '../../constants/appConstants';
import {
  ACKNOWLEDGE_MESSAGE_GROUP,
  NEW_MESSAGE_GROUP,
  MESSAGING_SOCKET,
} from '../../constants/apiConstants';
import { getBridgeBaseUrl } from '../../util/apiUtil';
import { getTokens } from '../../util/authUtil';

export class MessagingSocket {
  connected: boolean;

  hubConnection: signalR.HubConnection | null;

  selectedProductLine?: string;

  constructor() {
    this.connected = false;
    this.hubConnection = null;
  }

  static onResponse = (response: string, regionUrl?: string) => {
    const { message, group } = JSON.parse(response);
    const { selectedProductLine } = store.getState();
    if (group === ACKNOWLEDGE_MESSAGE_GROUP) {
      store.dispatch(acknowledgeMessage(message));
    } else if (group === NEW_MESSAGE_GROUP) {
      if (message.productLine !== selectedProductLine) {
        return;
      }
      store.dispatch(updateMessages(message, regionUrl ?? ''));
    }
  };

  async connect(
    dispatcherId: string,
    currentRegion?: UrlKeyDto,
    currentSubscriptions?: { url?: string, unsubscribe: () => void }[]
  ) {
    const { tenantId: tenant, selectedProductLine } = store.getState();

    const { accessToken } = getTokens();
    // do not try to connect if we're not logged in
    if (!accessToken) {
      return;
    }
    if (this.connected) {
      return;
    }
    if (!currentRegion?.url) {
      return;
    }

    const regionUrl = currentRegion.url.trim();
    const MESSAGING_SOCKET_URL = `${getBridgeBaseUrl()}${MESSAGING_SOCKET}`;

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${MESSAGING_SOCKET_URL}?tk=${accessToken}`, { accessTokenFactory: () => accessToken! })
      .configureLogging(signalR.LogLevel.None)
      .build();

    this.hubConnection.on(RECEIVE_MESSAGE, (message: string) => {
      MessagingSocket.onResponse(message, regionUrl);
    });

    this.hubConnection.onclose(async () => {
      this.disconnect();
      if (this.hubConnection) {
        this.hubConnection!.off(RECEIVE_MESSAGE);
        // get region from the store in case we have changed region
        const { currentRegion: region } = store.getState();
        this.connect(dispatcherId, region, currentSubscriptions);
      }
    });

    try {
      await this.hubConnection.start();
      // TODO: Remove tenant from websocket group (work is done in future card)
      await Promise.all([ACKNOWLEDGE_MESSAGE_GROUP, NEW_MESSAGE_GROUP]
        .map(group => (`${group}${tenant}/${regionUrl}`))
        .map(group => (this.hubConnection!.invoke(JOIN_GROUP, group))));
      this.connected = true;
      // retrieving all messages after connection to account for new messages received
      // from app refresh to websocket connection success
      store.dispatch<any>(getInboxMessages(undefined, undefined, currentRegion, selectedProductLine));
    } catch (err) {
      recordLog('warn', 'Messaging Socket', err);
      this.reconnect(dispatcherId, currentSubscriptions);
    }
  }

  reconnect(
    dispatcherId: string,
    currentSubscriptions?: { url?: string, unsubscribe: () => void }[]
  ) {
    setTimeout(() => {
      // get region from the store in case we have changed region
      const { currentRegion: region } = store.getState();
      this.connect(dispatcherId, region, currentSubscriptions);
    }, RECONNECT_TIMEOUT);
  }

  disconnect(destroyHub?: boolean) {
    if (!this.hubConnection) {
      return;
    }
    this.hubConnection.stop();
    this.connected = false;
    if (destroyHub) {
      this.hubConnection = null;
    }
  }
}

export default new MessagingSocket();
