import React, { ChangeEvent, Component, MouseEvent } from 'react';
import cx from 'classnames';
import {
  constants, SlideList,
} from '@trucktrax/trucktrax-common';
import { RegionDto, UrlKeyDto } from '@trucktrax/trucktrax-ts-common';
import {
  checkBodyForIconString,
  formatTime,
  getAuthorWithDriverId,
  getRecipientNames,
} from '../message';
import styles from './RightSidebar.module.css';
import ComposeMessage from './ComposeMessage';
import Bubble from './RightSidebar/Bubble';
import MessagingHeader from './RightSidebar/MessagingHeader';
import {
  ConversationMessagesDto, Driver, MessageCenterInfo,
} from '../index.d';

class RightSidebar extends Component<RightSidebarProps, RightSidebarState> {
  prevListLength: number;

  buttonRef?: HTMLElement;

  messagesEnd?: HTMLElement;

  constructor(props: RightSidebarProps) {
    super(props);
    this.prevListLength = 0;

    this.state = {
      isPending: false,
      isSlideListOpen: false,
    };
  }

  componentDidMount() {
    this.handleScroll();
  }

  componentDidUpdate(prevProps: RightSidebarProps, prevSate: RightSidebarState, snapshot: any) {
    if (snapshot) {
      this.handleScroll();
    }
  }

  onCannedMessageChanged = (message: string) => {
    this.props.onMessageFieldChange(message);
    const latestMessageIdx = this.props.selectedMessage!.items.length - 1;
    const latestMessage = this.props.selectedMessage!.items[latestMessageIdx];
    const messageMap = this.props.replyMap;
    messageMap.set(latestMessage.conversation.url, message);
    this.props.onUpdateReplyMap(messageMap);
  };

  onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const latestMessageIdx = this.props.selectedMessage!.items.length - 1;
    const latestMessage = this.props.selectedMessage!.items[latestMessageIdx];
    this.handleMessageFieldChange(event, latestMessage.conversation.url);
  };

  onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.onMessageFieldChange('');
    this.setState(
      { isPending: true },
      () => {
        const latestMessageIdx = this.props.selectedMessage!.items.length - 1;
        const latestMessage = this.props.selectedMessage!.items[latestMessageIdx];
        const conversationUrl = latestMessage.conversation.url;
        const messageBody = this.props.replyMap.get(conversationUrl);
        const filter = (p: { url: string }) => !p.url.includes(constants.REGIONS);
        const partiesList = this.props.selectedMessage!.partiesList.filter(filter);
        // messageToSend puts the information in the format
        // that the sendMessage actions is expecting
        const messageToSend: MessageCenterInfo = {
          subjectFieldValue: '',
          conversation: { url: conversationUrl },
          currentRecipientSearchText: '',
          messageFieldValue: messageBody || '',
          partiesList,
          messagesOpen: false,
        };
        this.props.onSubmit(messageToSend, conversationUrl, this.disableButtonCallback);
      }
    );
  };

  getSnapshotBeforeUpdate(prevProps: RightSidebarProps) {
    // Get reference to previous list
    const newList = prevProps.selectedMessage && prevProps.selectedMessage.items;

    // Get length of list if list is not null
    const newLength = newList ? newList.length : 0;

    // Retrieve length of last list
    const oldLength = this.prevListLength;

    // Set length of list so that it can be compared next time
    this.prevListLength = newLength;

    // Compare length of lists, return true to scroll component.
    if (newLength !== oldLength) {
      return true;
    }
    return null;
  }

  setButtonRef = (el: HTMLElement) => {
    this.buttonRef = el;
  };

  disableButtonCallback = () => {
    this.setState({ isPending: false });
  };

  handleMessageFieldChange = (event: ChangeEvent<HTMLTextAreaElement>, url: string) => {
    const messageMap = this.props.replyMap;
    messageMap.set(url, (event.target as HTMLTextAreaElement).value);
    this.props.onUpdateReplyMap(messageMap);
    if (event.target.value.length === 0) {
      this.props.onMessageFieldChange('');
    }
  };

  handleScroll = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView(false);
    }
  };

  displayBubble = () => {
    let allBubbles = null;
    if (this.props.selectedMessage) {
      const messages = this.props.selectedMessage.items;
      const bubbles = messages.map(m => {
        const author = getAuthorWithDriverId(m);
        const time = formatTime(new Date(m.sent));
        return (
          <Bubble
            key={m.id}
            dataTest={`message-thread-item${m.id}`}
            message={checkBodyForIconString(m.body, 'acknowledgement-thumb') as string}
            author={`${author}: ${time}`}
            isFrom={m.from.url.includes(constants.USERS) || m.from.url.includes(constants.REGIONS)}
            isIcon={m.body === constants.THUMB_UP_ICON_STRING}
          />
        );
      });

      allBubbles = (
        <div data-test="message-thread" className={styles.bubbleContainer}>
          <div>
            {bubbles}
            {/* add more bubbles here */}
          </div>
          <div
            style={{ float: 'left', clear: 'both' }}
            ref={(el) => {
              this.messagesEnd = el || undefined;
            }}
          />
        </div>
      );
    }
    return allBubbles;
  };

  displayReplyTextBox() {
    if (this.props.isInbox && this.props.selectedMessage) {
      const latestMessageIdx = this.props.selectedMessage.items.length - 1;
      const latestMessage = this.props.selectedMessage.items[latestMessageIdx];
      const selectedMessageId = latestMessage.conversation.url;
      const value = this.props.replyMap.get(selectedMessageId) || '';
      const { messageFieldValue } = this.props.messageCenter;
      const submitDisabled = ((!value || value.trim() === '')
        && (!messageFieldValue || messageFieldValue.trim() === '')) || this.state.isPending;
      return (
        <ComposeMessage
          value={value.length > 0 ? value : messageFieldValue}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          submitDisabled={submitDisabled}
          placeholder={constants.INBOX_PLACEHOLDER}
          selectedMessageId={selectedMessageId}
          selectedProductLine={this.props.selectedProductLine}
          currentRegion={this.props.currentRegion as RegionDto}
          onMessageFieldChange={this.onCannedMessageChanged}
        />
      );
    }
    return null;
  }

  static generateListContent = (driversList: Driver[]) => {
    const listToDisplay = driversList.map(driver => (
      <li className={styles.listItemStyle} key={driver.url}>{`${driver.lastName}, ${driver.firstName}`}</li>
    ));
    return <ul className={styles.listStyle}>{listToDisplay}</ul>;
  };

  slideListToggle = () => {
    this.setState({
      isSlideListOpen: !this.state.isSlideListOpen,
    });
  };

  render() {
    const rightSidebarClasses = cx(this.props.isExpand === true ? styles.rightSidebarWindow : styles.rightSidebar, {
      [styles.open]: this.props.isPanelOpen,
    });
    const { selectedMessage, selectedProductLine, driverList } = this.props;
    let listOfDriverObjects: Driver[] = [];
    if (selectedMessage && selectedMessage.partiesList) {
      listOfDriverObjects = selectedMessage.partiesList.filter((party) => !party.url.includes('/region'))
        .map(p => {
          const driver: Driver = {
            firstName: p.firstName,
            lastName: p.lastName,
            url: p.url,
          };
          return driver;
        });
    }
    const chevClass = this.state.isSlideListOpen ? styles['dropDownList-chev-up'] : styles['dropDownList-chev'];
    const recipients = selectedMessage && selectedMessage.commaSeparatedParties
      ? selectedMessage.commaSeparatedParties.replace(constants.DISPATCH_COMMA, '')
      : getRecipientNames(selectedMessage!);
    const productLineString = selectedProductLine === constants.READY_MIX_VALUE
      ? constants.READY_MIX_STRING
      : selectedProductLine;
    // checking if parties list includes all the drivers plus the dispatcher.
    const isAllDriversInProductLine = selectedMessage
      && selectedMessage.partiesList.length === (driverList.length + 1);
    const headerAuthor = isAllDriversInProductLine
      ? `All ${productLineString} drivers`
      : recipients.replace(constants.DISPATCH, '');
    return (
      <div
        data-test="message-center-right-sidebar"
        className={rightSidebarClasses}
      >
        <div>
          <MessagingHeader
            author={headerAuthor}
            backButtonAction={this.props.backButtonAction}
            icon={listOfDriverObjects.length > 1
              ? <i aria-hidden="true" className="icon-people icon-md margin-right-10" />
              : <i className="icon-wheel icon-small margin-right-10" />}
            button={listOfDriverObjects.length > 1
              ? (
                <i
                  onClick={this.slideListToggle}
                  aria-hidden="true"
                  className={cx('icon-chevron-left', chevClass)}
                  ref={this.setButtonRef}
                />
              ) : null}
          />
          <SlideList
            isPanelOpen={this.state.isSlideListOpen}
            listContent={RightSidebar.generateListContent(listOfDriverObjects)}
            listContainerClassName={styles.listContainer}
            listContentClassName={styles.listContent}
            toggleFunction={this.slideListToggle}
            referenceToToggleButton={this.buttonRef}
          />
        </div>
        {this.displayBubble()}
        {this.displayReplyTextBox()}
      </div>
    );
  }
}

export interface RightSidebarState {
  isPending: boolean,
  isSlideListOpen: boolean
}

type ReplyMapType = Map<string, string>;

export interface RightSidebarProps {
  selectedMessage?: ConversationMessagesDto | null;
  replyMap: Map<string, string>;
  isPanelOpen: boolean;
  isInbox: boolean;
  onUpdateReplyMap: (map: ReplyMapType) => void;
  onSubmit: (
    messageCenter: MessageCenterInfo,
    messageId: string | null,
    callback?: () => void
  ) => void;
  driverList: Driver[];
  selectedProductLine?: string;
  isExpand?: boolean;
  backButtonAction: () => void;
  currentRegion: UrlKeyDto;
  onMessageFieldChange: (message: string) => void;
  messageCenter: MessageCenterInfo;
}

export default RightSidebar;
