import { MP4_EXTENSION } from '../constants/docsConstants';

export function videoFilepathToName(filepath: string, prefix: string) {
  return filepath
    .replace(prefix, '')
    .replace(MP4_EXTENSION, '')
    .replace(/_/g, ' ');
}

export default videoFilepathToName;
