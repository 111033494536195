import React, { Component } from 'react';
import { Label } from '@trucktrax/trucktrax-common';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import styles from '../ChecklistQuestions.module.css';

export default class ChecklistAlertEmails extends Component<ChecklistAlertEmailsProps> {
  renderAlertEmails = (editContent: boolean, emailList: string[]) => {
    // Edit
    if (editContent) {
      return this.renderAlertEmailsEdit(emailList);
    }
    // View
    return ChecklistAlertEmails.renderAlertEmailsView(emailList);
  };

  static renderAlertEmailsView = (emailList: string[]) => {
    const vals = emailList.length > 0 ? (emailList.map((val, i) => {
      const delim = i > 0 && <span className={styles.checklistEmailDelim}> | </span>;
      return (
        <span key={val}>
          {delim}
          {val}
        </span>
      );
    })) : <span className={styles.checklistEmailNoneLabel}>None</span>;
    return (
      <div className={styles.checklistEmailsCtr}>
        <Label className={styles.checklistEmailLabel}>Alert Notification Emails</Label>
        <p className={styles.checklistEmailText}>{vals}</p>
      </div>
    );
  };

  renderAlertEmailsEdit = (emailList: string[]) => (
    <div className={styles.checklistEditingEmailsCtr}>
      <Label className={styles.checklistEmailLabel}>Alert Notification Emails</Label>
      <div className={styles.checklistEmailDiv}>
        {this.renderChipList(emailList)}
        {this.renderEmailInput()}
      </div>
    </div>
  );

  renderChipList = (emailList: string[]) => (
    emailList.length > 0
    && emailList.map((email) => (
      <Chip
        data-test="chip-email-name"
        key={email}
        classes={
          {
            root: styles.chip,
            label: styles.chipLabel,
          }
        }
        label={email}
        deleteIcon={(
          <div
            data-test="chip-cancel-individual"
            className={styles.chipRemove}
          >
            <i
              aria-hidden="true"
              className="icon-close"
            />
          </div>
        )}
        onDelete={() => this.props.handleEmailDelete(email)}
      />
    ))
  );

  renderEmailInput = () => (
    <TextField
      fullWidth
      value={this.props.newEmail}
      onChange={(event) => this.handleTextFieldChange(event)}
      placeholder="Type email here..."
      InputProps={{
        classes: {
          root: styles.textField,
          input: styles.textFieldInput,
        },
      }}
      onKeyDown={(event) => this.handleKeyDown(event)}
    />
  );

  handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.props.handleEmailChange(event.target.value);
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Keydown logic goes here
    if (event.key === 'Enter' || event.key === ',') {
      this.props.handleEmailCreated();
    }
  };

  render() {
    const { editContent, emailList } = this.props;
    return this.renderAlertEmails(editContent, emailList);
  }
}

export interface ChecklistAlertEmailsProps {
  editContent: boolean;
  emailList: string[];
  handleEmailDelete: (email: string) => void;
  handleEmailChange: (text: string) => void;
  handleEmailCreated: VoidFunction;
  newEmail: string;
}
