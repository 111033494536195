import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../../store';
import styles from './RouteHistoryPanelContainer.module.css';
import RouteHistorySearchForm from './RouteHistoryPanelSearchForm';
import RouteHistorySearchResults from './RouteHistoryPanelSearchResults';
import { clearRouteHistoryList } from '../../../services/routeHistoryService';
import { ConnectedDispatchFunction } from '../../../types';
import VerticalSplitPane from '../../shared/VerticalSplitPane';

export class RouteHistorySearchPanel extends Component<RouteHistoryPanelContainerProps> {
  componentDidMount() {
    this.props.clearRouteHistoryList();
  }

  componentWillUnmount() {
    this.props.clearRouteHistoryList();
  }

  render() {
    return (
      <div className={styles.contentContainer}>
        <VerticalSplitPane
          upperPane={(
            <RouteHistorySearchForm regions={this.props.regions} />
          )}
          lowerPane={(
            <RouteHistorySearchResults />
          )}
          showLowerPane={!!this.props.routeHistoryList}
        />
      </div>
    );
  }
}

export interface RouteHistoryPanelContainerOwnProps {
  regions: {
    display: string | undefined,
    value: string | undefined
  }[] | undefined,
}

export function mapStateToProps(state: ReduxState) {
  return {
    routeHistoryList: state.routeHistoryList,
    selectedRouteHistoryList: state.selectedRouteHistoryList,
  };
}

type RouteHistoryPanelContainerDispatchProps = {
  clearRouteHistoryList: ConnectedDispatchFunction<
  typeof clearRouteHistoryList
  >;
};

type RouteHistoryPanelContainerReduxStateProps = ReturnType<typeof mapStateToProps>;

export type RouteHistoryPanelContainerProps =
  RouteHistoryPanelContainerOwnProps
  & RouteHistoryPanelContainerReduxStateProps & RouteHistoryPanelContainerDispatchProps;

export default connect(mapStateToProps, {
  clearRouteHistoryList,
})(RouteHistorySearchPanel);
