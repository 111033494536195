import { Acknowledgement, ConversationToMessagesDto, Message } from '../../components/shared/messages/index.d';
import {
  checkUnread,
  replaceMessagesInConversation,
  updateAcknowledgement,
  updateMessage,
} from '../../util/messageUtil';
import {
  ACKNOWLEDGE_CONVERSATION,
  CLEAR_MESSAGES,
  SET_CONVERSATION_LIST,
  SET_TOTAL_UNREAD_MESSAGES,
  UPDATE_MESSAGE,
  UPDATE_MESSAGES,
} from '../../constants/actionConstants';
import { PayloadAction } from '../../types';

type MessageListActions
  = 'UPDATE_MESSAGE' |
  'SET_CONVERSATION_LIST' |
  'ACKNOWLEDGE_CONVERSATION' |
  'SET_TOTAL_UNREAD_MESSAGES' |
  'UPDATE_MESSAGES' |
  'CLEAR_MESSAGES';

interface UpdateMessageActionPayload {
  newMessage: Message & { tenant: { url: string } };
  regionUrl: string;
}

type MessageListPayload = ConversationToMessagesDto[] | ConversationToMessagesDto | UpdateMessageActionPayload | Acknowledgement;

const initialState = {
  allSentMessages: [] as ConversationToMessagesDto[],
  allMessages: [] as ConversationToMessagesDto[],
  unreadNotice: false,
};
type MessageListState = typeof initialState;

// disabling due to chance of more functions in file
// eslint-disable-next-line import/prefer-default-export
export const messageList = (
  state = initialState,
  action: PayloadAction<MessageListPayload | boolean, MessageListActions>
): MessageListState => {
  const newMessage = action.payload;
  switch (action.type) {
    case UPDATE_MESSAGE: {
      const messages = replaceMessagesInConversation(state.allMessages, (newMessage as ConversationToMessagesDto[])[0]);
      return {
        ...state,
        allMessages: messages,
      };
    }
    case SET_CONVERSATION_LIST: {
      const allMessages = action.payload as ConversationToMessagesDto[];
      return {
        ...state,
        allMessages,
        unreadNotice: checkUnread(allMessages as ConversationToMessagesDto[]),
      };
    }
    case ACKNOWLEDGE_CONVERSATION:
      return {
        ...state,
        allMessages: updateAcknowledgement(state.allMessages, action.payload as Acknowledgement),
        unreadNotice: checkUnread(state.allMessages),
      };
    case SET_TOTAL_UNREAD_MESSAGES:
      return {
        ...state,
        unreadNotice: action.payload as boolean,
      };
    case UPDATE_MESSAGES: {
      const update = action.payload as UpdateMessageActionPayload;
      const allMessages = updateMessage(
        state.allMessages,
        update.newMessage,
        update.regionUrl
      );
      return {
        ...state,
        allMessages,
        unreadNotice: checkUnread(allMessages),
      };
    }
    case CLEAR_MESSAGES:
      return {
        ...state,
        allMessages: [],
        allSentMessages: [],
        unreadNotice: false,
      };
    default:
      return state;
  }
};
