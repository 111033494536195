import { PayloadAction } from '../../types';
import {
  ADD_ITEM_TO_ADMIN_TABLE_ITEMS,
  FETCH_TABLE_DATA,
  SET_TABLE_DATE_RANGE,
  SET_TABLE_NEEDS_REFRESH,
  SET_TABLE_RECORDS,
  SET_TABLE_SEARCH_TERM,
  TABLE_IS_LOADING,
  UPDATE_COMBINED_DATASOURCES,
} from '../../constants/actionConstants';

export interface DataTableOptions {
  pageCount: number;
  pageStart: number;
  pageSize: number;
  baseUrl: string;
  details?: any;
  items?: any;
}
export interface DataTableState {
  baseUrl?: string;
  isLoading: boolean;
  items: any[];
  details?: any;
  pageInfo?: {
    pageCount: number;
    pageStart: number;
    pageSize: number;
  };
  source: string;
}
export const dataTableReducer = (
  state: DataTableState = { isLoading: true, items: [], source: 'default' },
  action: PayloadAction<DataTableOptions | any>
): DataTableState => {
  const {
    pageCount, pageStart, pageSize, details
  } = action.payload || {};
  switch (action.type) {
    case TABLE_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        source: action.payload.source,
      };
    case FETCH_TABLE_DATA:
      return {
        ...state,
        items: action.payload.items ? action.payload.items : action.payload,
        pageInfo: {
          pageCount,
          pageStart,
          pageSize,
        },
        baseUrl: action.payload.baseUrl,
        isLoading: false,
        source: action.payload?.extras?.source,
      };
    case ADD_ITEM_TO_ADMIN_TABLE_ITEMS:
      return {
        ...state,
        items: [...state.items, action.payload],
        source: action.payload?.source
      };
    case SET_TABLE_RECORDS:
      return {
        ...state,
        details,
        source: action.payload?.source
      };
    default:
      return state;
  }
};

export const dataTableSearch = (
  state = { searchTerm: '' },
  action: PayloadAction<string, 'SET_TABLE_SEARCH_TERM'>
): { searchTerm: string } => {
  switch (action.type) {
    case SET_TABLE_SEARCH_TERM:
      return {
        searchTerm: action.payload,
      };
    default:
      return state;
  }
};

interface DataTableDateRangePayload {
  startDate: Date;
  endDate: Date;
  maxPast?: Date;
  maxFuture?: Date;
  source?: string;
}

export const dataTableDateRange = (
  state: { dateRange: DataTableDateRangePayload } | null = null,
  action: PayloadAction<DataTableDateRangePayload, 'SET_TABLE_DATE_RANGE'>
): { dateRange: DataTableDateRangePayload } | null => {
  switch (action.type) {
    case SET_TABLE_DATE_RANGE:
      return {
        dateRange: action.payload,
      };
    default:
      return state;
  }
};

export const dataTableNeedsRefresh = (
  state = false,
  action: PayloadAction<boolean, 'SET_TABLE_NEEDS_REFRESH'>
): boolean => {
  switch (action.type) {
    case SET_TABLE_NEEDS_REFRESH:
      return action.payload;
    default:
      return state;
  }
};

export const combinedDataSources = (
  state: any = null,
  action: PayloadAction<any, 'UPDATE_COMBINED_DATASOURCES'>
): any => {
  switch (action.type) {
    case UPDATE_COMBINED_DATASOURCES:
      return updateDataSourceData(state, action);
    default:
      return state;
  }
};

function updateDataSourceData(state: any, action: any) {
  const mutatedState = { ...state };
  if (!action.payload || !action.payload.extras.source) {
    return state;
  }
  if (!mutatedState.dataSources) {
    mutatedState.dataSources = {};
  }

  mutatedState.dataSources[action.payload.extras.source] = {
    dataTableDateRange: action.payload.extras?.dataTableDateRange,
    dataTableSearch: action.payload.extras?.dataTableSearch,
    dataTableStatusFilter: action.payload.extras?.dataTableStatusFilter,
    items: action.payload.items,
    columns: action.payload.extras.columns,
    baseUrl: action.payload.baseUrl,
    isLoading: false,
    source: action.payload.extras.source,
  };
  return mutatedState;
}
