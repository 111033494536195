import { Dispatch } from 'redux';
import { getRequest } from './requestService';
import { devErrorAndLog } from '../util/errorUtil';
import { ERROR_TEXT_FETCH_FILE_LIST } from '../constants/errorConstants';
import { SET_RELEASENOTES_DOCS_LIST } from '../constants/actionConstants';
import { DOCS_PATH, STATIC_PATH } from '../constants/apiConstants';
import { getGeotraxBaseUrl } from '../util/apiUtil';
import HTTP_CODES from '../constants/httpConstants';

export default function fetchFileList(params: any) {
  return async (dispatch: Dispatch) => {
    const url = getGeotraxBaseUrl() + STATIC_PATH + DOCS_PATH;

    try {
      const response = await getRequest(url, params);
      dispatch({
        type: SET_RELEASENOTES_DOCS_LIST,
        payload: response.data,
      });
    } catch (e: any) {
      const consoleOnly = (e.response?.status === HTTP_CODES.forbidden);
      dispatch(devErrorAndLog(ERROR_TEXT_FETCH_FILE_LIST, `url: ${url} `, e.toString(), undefined, consoleOnly) as any);
    }
  };
}
