import forEach from 'lodash/forEach';
import { DriverDto } from '@trucktrax/trucktrax-ts-common';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import { PositionDetailsDto, TruckInfo } from '../types';
import { getIdFromUrl } from './appUtil';

interface DriverMap {
  [truckId: number]: DriverDto;
}

export interface DriverWithTruck extends DriverDto {
  truckDto?: { id: number }
}

export function getTruckToDriverMap(listOfDrivers: DriverWithTruck[]) {
  const driverMap: DriverMap = {};
  forEach(listOfDrivers, driver => {
    const truck = driver.truckDto;
    if (truck && truck.id) {
      driverMap[truck.id] = driver;
    }
  });
  return driverMap;
}

// I patched this bug but they issue seems to comes from the arguments
// TODO fix the bug in the data instead of patching it here
export function getDriversInCurrentRegion(positions: PositionDetailsDto[], selectedRegionUrl: string, truckToDriverMap: DriverMap) {
  const driverSet: DriverDto[] = [];

  if (!positions) {
    return driverSet;
  }

  forEach(positions, position => {
    if (position.trackedObject != null) {
      const regionUrl = position.region.url;
      const id = Number.parseInt(getIdFromUrl(position.trackedObject.url!), 10);
      const driver = truckToDriverMap[id];
      if (regionUrl === selectedRegionUrl && driver) {
        driverSet.push(driver);
      }
    }
  });

  return driverSet;
}

export function formatTimeSince(timestamp: string) {
  const duration = DateWrapper.differenceBetween(DateWrapper.now, timestamp);
  return `${duration.hours}h ${duration.minutes}m`;
}

export function getTrucksInCurrentRegion(positions: PositionDetailsDto[], selectedRegionUrl: string) {
  const trucks: TruckInfo[] = [];

  if (!positions) {
    return trucks;
  }

  positions.forEach(position => {
    const regionUrl = position.region.url;

    if (regionUrl === selectedRegionUrl
      && position.trackedObject) {
      const id = getIdFromUrl(position.trackedObject.url!);
      const name = position.trackedObject.truckAlias;
      const isActive = position.active;
      const lat = position.location.latitude;
      const lng = position.location.longitude;
      const { trackedObject } = position;
      const { status, statusTimestamp } = position;

      if (!name || !isActive) {
        return;
      }

      let ticketUrl = '';

      if (position && position.ticket && position.ticket.url) {
        // eslint-disable-next-line prefer-destructuring
        ticketUrl = position.ticket.url;
      }

      const data = {
        markerId: id,
        ticketUrl,
        lat: lat ?? 0,
        lng: lng ?? 0,
        isActive,
        status,
        isExternal: trackedObject.isExternal,
      };

      const text = status && statusTimestamp && formatTimeSince(statusTimestamp);

      const truck = {
        id,
        name,
        text,
        data,
      };
      trucks.push(truck);
    }
  });

  return trucks;
}
