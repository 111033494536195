import React from 'react';
import { DateWrapper } from '@trucktrax/trucktrax-common';
import {
  CellInfo,
  RowInfo,
  ColumnProjection,
  GetColumnsProps,
} from './ticketListConfigurations.types';
import styles from '../TicketList.module.css';
import voidedTicket from '../../../../assets/img/voidedTicket.png';

export default function getColumns(props: GetColumnsProps): ColumnProjection[] {
  const { cellStyle, headerStyle } = props;

  const voidedAccessor = (isVoided?: boolean, content?: any) => {
    if (!isVoided) {
      return `${content}`;
    }

    return (
      <div className={styles.voidedCellStyle}>
        {`${content}`}
      </div>
    );
  };

  const ticketNumberAccessor = (row: RowInfo) => (
    <div className={row.voided ? styles.voidedCellStyle : undefined}>
      {`${row.ticketNumber}`}
      {row.voided && (
        <img src={voidedTicket} alt="voided" />
      )}
    </div>
  );

  const ticketedTimestampAccessor = (row: RowInfo) => voidedAccessor(row?.voided, new DateWrapper(row.ticketedTimestamp).toUSDateString());

  const truckAliasAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row.externalInfo?.truckAlias ?? '');

  const orderNumberAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row?.order?.orderNumber ?? 0);

  const customerAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row?.order?.customerName ?? '');

  const projectAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row?.order?.projectName ?? '');

  const orderNumberCell = (cellInfo: CellInfo) => (
    cellInfo && cellInfo.original ? props.orderDetails!(cellInfo.original) : '');

  const addressAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row.address ?? '');

  const loadingPlantAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row.externalInfo?.plantName ?? '');

  const currentDriverAccessor = (row: RowInfo) => voidedAccessor(row?.voided, row.externalInfo?.driverName ?? '');

  const lastTickets = [
    {
      Header: 'Ticket #',
      id: 'ticketNumber',
      accessor: ticketNumberAccessor,
      fixed: 'left' as const,
      style: cellStyle,
      width: 110,
      headerStyle,
    },
    {
      Header: 'Date',
      id: 'ticketedTimestamp',
      accessor: ticketedTimestampAccessor,
      fixed: 'left' as const,
      style: cellStyle,
      width: 120,
      headerStyle,
    },
    {
      Header: 'Truck',
      id: 'truckAlias',
      accessor: truckAliasAccessor,
      className: 'vehicle-cell',
      headerClassName: 'vehicle-header',
      width: 155,
      style: cellStyle,
      headerStyle,
    },
    {
      Header: 'Current/ Last Driver',
      id: 'currentDriver',
      accessor: currentDriverAccessor,
      className: 'ticket-cell',
      headerClassName: 'ticket-header',
      style: cellStyle,
      width: 300,
      headerStyle,
    },
    {
      Header: 'Order #',
      id: 'orderNumber',
      accessor: orderNumberAccessor,
      Cell: orderNumberCell,
      className: 'order-cell',
      headerClassName: 'order-header',
      style: cellStyle,
      width: 80,
      headerStyle,
    },
    {
      Header: 'Customer',
      id: 'customerName',
      accessor: customerAccessor,
      className: 'name-cell',
      headerClassName: 'name-header',
      style: cellStyle,
      width: 200,
      headerStyle,
    },
    {
      Header: 'Project',
      id: 'ProjectName',
      accessor: projectAccessor,
      className: 'name-cell',
      headerClassName: 'name-header',
      style: cellStyle,
      width: 200,
      headerStyle,
    },
    {
      Header: 'Delivery Address',
      id: 'address',
      accessor: addressAccessor,
      className: 'address-cell',
      headerClassName: 'address-header',
      width: 350,
      style: cellStyle,
      headerStyle,
    },
    {
      Header: 'Loading Plant',
      id: 'loadingPlant',
      accessor: loadingPlantAccessor,
      className: 'ticket-cell',
      headerClassName: 'ticket-header',
      style: cellStyle,
      width: 964,
      headerStyle,
    }
  ];

  return lastTickets;
}
